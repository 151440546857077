import { ContentText } from 'pdfmake/interfaces';

import {
  Parent_Type_Enum,
  useGetCustomAttributeSchemasByParentTypeLazyQuery,
} from '@/generated/graphql';
import { JSONObject } from '@/types/types';

import { getCustomAttributeDataForExport } from './getCustomAttributeDataForExport';

const useCustomAttributeDataForExport = <
  T extends {
    CustomAttributeData?: JSONObject | null;
  },
>(
  parentType: Parent_Type_Enum
): [(item: T) => Promise<(string | ContentText)[]>, boolean] => {
  const [getSchema, getSchemasResult] =
    useGetCustomAttributeSchemasByParentTypeLazyQuery({
      variables: {
        parentType,
      },
    });

  return [
    async (item: T) => {
      const { data: schemaData } = await getSchema();

      return getCustomAttributeDataForExport(
        item,
        schemaData?.form_configuration?.[0]?.customAttributeSchema
      );
    },
    getSchemasResult.loading,
  ];
};
export default useCustomAttributeDataForExport;
