import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMultiParentFileUpdate } from 'src/data/rest/useFileUpdate';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import { ModalBodyWrapper } from '@/components/Form/Form/ModalBodyWrapper';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useGetObligationAssessmentResultByIdQuery,
  useInsertObligationAssessmentResultMutation,
  useUpdateObligationAssessmentResultMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from '../useAssessmentTypeConfig';
import ObligationAssessmentResultForm from './ObligationAssessmentResultForm';
import {
  defaultValues,
  ObligationAssessmentResultFormDataFields,
} from './obligationAssessmentResultSchema';

type Props = {
  assessmentMode: AssessmentTypeEnum;
  readonly: boolean;
  navigateToResults: boolean;
  isModalForm: boolean;
  assessmentId?: string;
  assessedItem?: ObjectWithContributors;
  id?: string;
  onDismiss?: (saved: boolean) => void;
  beforeFieldsSlot?: ReactNode;
  showAssessmentSelector?: boolean;
  obligationIds?: string[];
  header?: string;
};

const ConnectedObligationAssessmentResultForm: FC<Props> = ({
  readonly,
  assessmentId,
  assessedItem,
  id,
  onDismiss,
  beforeFieldsSlot,
  showAssessmentSelector,
  navigateToResults,
  isModalForm,
  obligationIds,
  assessmentMode,
  header,
}) => {
  const navigate = useNavigate();
  const { data } = useGetObligationAssessmentResultByIdQuery({
    variables: {
      Id: id!,
      Assessment: assessmentMode === 'rating',
      InternalAudit: assessmentMode === 'internal_audit_report',
      ComplianceMonitoring:
        assessmentMode === 'compliance_monitoring_assessment',
    },
    fetchPolicy: 'no-cache',
    skip: !id,
  });
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const obligationAssessmentResult = data?.obligation_assessment_result?.[0];
  const canUpdateObligationAssessmentResult = useHasPermission(
    'update:obligation_assessment_result'
  );
  const { updateFiles } = useMultiParentFileUpdate();
  const [insertObligationAssessmentResult] =
    useInsertObligationAssessmentResultMutation({
      update: (cache) => {
        evictField(cache, 'obligation_assessment_result');
        evictField(cache, 'assessment');
        evictField(cache, 'internal_audit_report');
        evictField(cache, 'compliance_monitoring_assessment');
        evictField(cache, 'obligation_assessment_result_aggregate');
      },
    });

  const [updateObligationAssessmentResult] =
    useUpdateObligationAssessmentResultMutation({
      update: (cache) => {
        evictField(cache, 'obligation_assessment_result');
        evictField(cache, 'assessment');
        evictField(cache, 'internal_audit_report');
        evictField(cache, 'compliance_monitoring_assessment');
        evictField(cache, 'obligation_assessment_result_aggregate');
      },
    });

  obligationIds = obligationIds ?? [];
  const parentObligation = obligationAssessmentResult?.parents.find(
    (p) => p.obligation
  );
  if (parentObligation?.obligation?.Id) {
    obligationIds.push(parentObligation.obligation.Id!);
  } else if (assessedItem?.Id) {
    obligationIds.push(assessedItem.Id!);
  }

  const onSave = async (values: ObligationAssessmentResultFormDataFields) => {
    const { files, newFiles } = values;
    const obligationAssessmentResultIds: string[] = [];
    if (obligationAssessmentResult) {
      const result = await updateObligationAssessmentResult({
        variables: {
          ...values,
          CustomAttributeData: values.CustomAttributeData ?? null,
          Id: obligationAssessmentResult.Id,
        },
      });

      if (
        result.data?.update_obligation_assessment_result?.affected_rows === 0
      ) {
        throw new Error('Obligation assessment result update failed');
      }
      obligationAssessmentResultIds.push(id!);
    } else {
      const result = await insertObligationAssessmentResult({
        variables: {
          ...values,
          CustomAttributeData: values.CustomAttributeData ?? null,
          AssessmentId:
            assessmentMode == 'rating'
              ? (assessmentId ?? values.AssessmentId)
              : undefined,
          ComplianceMonitoringAssessmentId:
            assessmentMode == 'compliance_monitoring_assessment'
              ? (assessmentId ?? values.ComplianceMonitoringAssessmentId)
              : undefined,
          InternalAuditReportId:
            assessmentMode == 'internal_audit_report'
              ? (assessmentId ?? values.InternalAuditReportId)
              : undefined,
          ObligationIds: values?.ObligationIds?.map((r) => r.value),
        },
      });

      if (!result.data?.insertChildObligationAssessmentResult?.Ids) {
        throw new Error('Obligation assessment result id is missing');
      }
      obligationAssessmentResultIds.push(
        ...result.data.insertChildObligationAssessmentResult.Ids
      );
    }

    await updateFiles({
      parentIds: obligationAssessmentResultIds,
      parentType: Parent_Type_Enum.ObligationAssessmentResult,
      newFiles,
      selectedFiles: files,
      originalFiles: obligationAssessmentResult?.files ?? [],
    });
    if (navigateToResults && assessmentId) {
      navigate(resultsRegisterUrl(assessmentId));
    }
  };

  return (
    <ObligationAssessmentResultForm
      header={header}
      onDismiss={onDismiss}
      defaultValues={{
        ...defaultValues,
        ObligationIds: obligationIds.map((c) => ({ value: c })),
        AssessmentId: assessmentId,
      }}
      // Cast required as fields potentially have incorrect nullablity value in db or schema..
      values={{
        Rating: obligationAssessmentResult?.Rating ?? 1,
        Rationale: obligationAssessmentResult?.Rationale ?? '',
        AssessmentId:
          obligationAssessmentResult?.parents.find((p) => p.assessment)
            ?.assessment?.Id ?? null,
        ComplianceMonitoringAssessmentId:
          obligationAssessmentResult?.parents.find(
            (p) => p.complianceMonitoringAssessment
          )?.complianceMonitoringAssessment?.Id ?? null,
        InternalAuditReportId:
          obligationAssessmentResult?.parents.find((p) => p.internalAuditReport)
            ?.internalAuditReport?.Id ?? null,
        ObligationIds: obligationIds.map((c) => ({ value: c })),
        TestDate: obligationAssessmentResult?.TestDate,
        CustomAttributeData: obligationAssessmentResult?.CustomAttributeData,
        files: obligationAssessmentResult?.files,
      }}
      onSave={onSave}
      readOnly={readonly && !canUpdateObligationAssessmentResult}
      renderTemplate={(renderProps) =>
        isModalForm ? (
          <ModalBodyWrapper {...renderProps} />
        ) : (
          <PageWrapper {...renderProps} />
        )
      }
      disableObligationSelector={id != undefined}
      beforeFieldsSlot={beforeFieldsSlot}
      showSelector={showAssessmentSelector ? assessmentMode : undefined}
      assessmentMode={assessmentMode}
    />
  );
};

export default ConnectedObligationAssessmentResultForm;
