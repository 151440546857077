import { useContext } from 'react';

import { DashboardFilterContext } from './DashboardFilterContext';
import { DashboardFilterContextProps } from './types';

export const useDashboardFilter = (): DashboardFilterContextProps => {
  const context = useContext(DashboardFilterContext);

  if (!context) {
    throw new Error(
      'useDashboardFilter must be used within a DashboardFilterProvider'
    );
  }

  const {
    filters,
    setFilters,
    widgets,
    setWidgets,
    setDashboardPreferences,
    setId,
    id,
  } = context;

  return {
    filters,
    setFilters,
    widgets,
    setWidgets,
    setDashboardPreferences,
    setId,
    id,
  };
};
