
    import './styles.scoped.css';
    export default {
  "icon-shake": "awsui_icon-shake_1cbgc_17u36_149",
  "awsui-motion-shake-horizontally": "awsui_awsui-motion-shake-horizontally_1cbgc_17u36_1",
  "container-fade-in": "awsui_container-fade-in_1cbgc_17u36_177",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1cbgc_17u36_1",
  "root": "awsui_root_1cbgc_17u36_199",
  "status-error": "awsui_status-error_1cbgc_17u36_208",
  "status-warning": "awsui_status-warning_1cbgc_17u36_211",
  "status-success": "awsui_status-success_1cbgc_17u36_214",
  "status-info": "awsui_status-info_1cbgc_17u36_217",
  "status-stopped": "awsui_status-stopped_1cbgc_17u36_220",
  "status-pending": "awsui_status-pending_1cbgc_17u36_223",
  "status-in-progress": "awsui_status-in-progress_1cbgc_17u36_226",
  "status-loading": "awsui_status-loading_1cbgc_17u36_229",
  "color-override-red": "awsui_color-override-red_1cbgc_17u36_232",
  "color-override-grey": "awsui_color-override-grey_1cbgc_17u36_235",
  "color-override-blue": "awsui_color-override-blue_1cbgc_17u36_238",
  "color-override-green": "awsui_color-override-green_1cbgc_17u36_241",
  "color-override-yellow": "awsui_color-override-yellow_1cbgc_17u36_244",
  "container": "awsui_container_1cbgc_17u36_177",
  "display-inline": "awsui_display-inline_1cbgc_17u36_248",
  "icon": "awsui_icon_1cbgc_17u36_149",
  "display-inline-block": "awsui_display-inline-block_1cbgc_17u36_256",
  "overflow-ellipsis": "awsui_overflow-ellipsis_1cbgc_17u36_265"
};
  