import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';

import { useStorage } from '../state/useStorage';

export const NavMenuContext = createContext<
  [boolean, Dispatch<SetStateAction<boolean>>]
>([true, () => null]);

type Props = {
  children: ReactNode;
};

export const NavMenuProvider: FC<Props> = ({ children }) => {
  const state = useStorage<boolean>(true, {
    localStorageKey: 'NavMenu-Preferences',
  });

  return (
    <NavMenuContext.Provider value={state}>{children}</NavMenuContext.Provider>
  );
};

export const useNavMenu = () => useContext(NavMenuContext);
