import { ControlElement, UISchemaElement } from '@jsonforms/core';
import { ErrorBoundary } from '@sentry/react';
import { useFormContext } from 'react-hook-form';

import {
  Parent_Type_Enum,
  useGetCustomAttributeSchemasByParentTypeQuery,
  useGetFormFieldOptionsByParentTypeQuery,
} from '@/generated/graphql';

import { Controller } from '../FieldController/Controller';
import { CustomFieldProvider } from './Context/CustomFieldContext';
import { FieldType } from './EditFields/NewFieldSchema';
import { CustomAttributeDateInput } from './Renderers/FieldLayouts/CustomAttributeDateInput';
import { CustomAttributeInput } from './Renderers/FieldLayouts/CustomAttributeInput';
import { CustomAttributeLinkInput } from './Renderers/FieldLayouts/CustomAttributeLinkInput';
import { CustomAttributeMultiSelect } from './Renderers/FieldLayouts/CustomAttributeMultiSelect';
import { CustomAttributeProps } from './Renderers/FieldLayouts/CustomAttributeProps';
import { CustomAttributeSelect } from './Renderers/FieldLayouts/CustomAttributeSelect';
import { CustomAttributeTextarea } from './Renderers/FieldLayouts/CustomAttributeTextarea';

type Props = {
  name?: string;
  parentType: Parent_Type_Enum;
  readOnly?: boolean;
};

const jsonRefToFieldName = (jsonRef: string) => {
  if (!jsonRef.startsWith('#/properties/')) {
    throw new Error(
      `Invalid json ref: ${jsonRef}. Must start with #/properties/`
    );
  }

  return jsonRef.replace('#/properties/', '').split('/').join('.');
};

export const FieldTypesSupportingCustomDefaultValue: FieldType[] = [
  FieldType.Link,
  FieldType.Select,
  FieldType.Text,
  FieldType.Textarea,
];

function useControlledCustomAttributes({
  name = 'CustomAttributeData',
  parentType,
  readOnly,
}: Props) {
  const { data, loading } = useGetCustomAttributeSchemasByParentTypeQuery({
    variables: { parentType },
  });
  const { Schema, UiSchema } =
    data?.form_configuration?.[0]?.customAttributeSchema || {};
  const { data: formConfig } = useGetFormFieldOptionsByParentTypeQuery({
    variables: { parentType },
  });

  const elements = ((UiSchema?.elements as UISchemaElement[]) ?? []).filter(
    (el) => el.type === 'Control'
  ) as ControlElement[];
  const { control } = useFormContext();

  if (loading || !elements) {
    return [];
  }

  return elements.map((elementSchema) => {
    const fieldName = jsonRefToFieldName(elementSchema.scope);
    const fieldId = `${name}.${fieldName}`;
    const fieldReadOnly = formConfig?.form_field_configuration?.find(
      (f) => f.FieldId === fieldId
    )?.ReadOnly;
    const property = Schema.properties[fieldName];

    let type: FieldType = FieldType.Text;

    if (elementSchema.scope.match(/_textarea$/)) {
      type = FieldType.Textarea;
    }
    if (elementSchema.scope.match(/_text$/)) {
      type = FieldType.Text;
    }
    if (elementSchema.scope.match(/_date$/)) {
      type = FieldType.Date;
    }
    if (elementSchema.scope.match(/_select$/)) {
      type = FieldType.Select;
    }
    if (elementSchema.scope.match(/_multiselect$/)) {
      type = FieldType.MultiSelect;
    }
    if (elementSchema.scope.match(/_link$/)) {
      type = FieldType.Link;
    }

    return (
      <ErrorBoundary
        key={elementSchema.scope}
        onError={(error) =>
          console.error('failed to render custom attributes', error)
        }
        fallback={<p>Failed to load custom field</p>}
      >
        <Controller
          defaultRequired={false}
          forceRequired={false}
          allowDefaultValue={FieldTypesSupportingCustomDefaultValue.includes(
            type
          )}
          name={`${name}.${fieldName}`}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            const props: CustomAttributeProps = {
              value,
              onChange,
              label: elementSchema.label as string,
              error: error?.message,
              disabled: fieldReadOnly || readOnly,
              description: property.description,
            };

            let ControlledInputComp;

            switch (type) {
              case FieldType.Textarea:
                ControlledInputComp = <CustomAttributeTextarea {...props} />;
                break;
              case FieldType.Text:
                ControlledInputComp = <CustomAttributeInput {...props} />;
                break;
              case FieldType.Date:
                ControlledInputComp = <CustomAttributeDateInput {...props} />;
                break;
              case FieldType.Select:
                ControlledInputComp = (
                  <CustomAttributeSelect {...props} schema={property} />
                );
                break;
              case FieldType.MultiSelect:
                ControlledInputComp = (
                  <CustomAttributeMultiSelect
                    {...(props as unknown as CustomAttributeProps<string[]>)}
                    schema={property}
                  />
                );
                break;
              case FieldType.Link:
                ControlledInputComp = <CustomAttributeLinkInput {...props} />;
                break;
            }

            return (
              <CustomFieldProvider
                fieldPath={fieldName}
                currentField={{
                  Label: props.label,
                  Type: type,
                  Options: [FieldType.Select, FieldType.MultiSelect].includes(
                    type
                  )
                    ? property.enum
                    : undefined,
                  Required: false,
                  ReadOnly: fieldReadOnly || readOnly || false,
                  Hidden: false,
                  Description: property.description,
                }}
              >
                {ControlledInputComp}
              </CustomFieldProvider>
            );
          }}
        />
      </ErrorBoundary>
    );
  });
}

export default useControlledCustomAttributes;
