import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { getCurrentDayDate } from '@/utils/dateUtils';

import { ControlTestFlatFields } from './types';

type TestSummaryProps = {
  items: readonly ControlTestFlatFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

const dueDaysOffset = 30;

export const TestSummary: FC<TestSummaryProps> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'testResults.summary_category_titles',
  });
  const { getByValue } = useRating('effectiveness');
  const currentDate = new Date();
  const currentDateWithOffset = getCurrentDayDate(dueDaysOffset);

  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'NextTestDate',
              value: currentDateWithOffset.toISOString(),
              operator: '<',
            },
            {
              propertyKey: 'NextTestDate',
              value: currentDate.toISOString(),
              operator: '>',
            },
          ],
          operation: 'and',
        }}
        title={t('due')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'Overdue',
              value: 'true',
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('overdue')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'OverallEffectivenessLabelled',
              value: getByValue(0)?.label || '',
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        title={t('not_effective')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
