import { createContext, useContext } from 'react';

export type FormStateContextType = {
  isFormDirty: boolean;
  setIsFormDirty: (isFormDirty: boolean) => void;
};

export const FormStateContext = createContext<FormStateContextType | null>(
  null
);

export const useFormState = () => {
  const context = useContext(FormStateContext);

  if (!context) {
    throw new Error('No form state context provided');
  }

  const { isFormDirty, setIsFormDirty } = context;

  return {
    isFormDirty,
    setIsFormDirty,
  };
};
