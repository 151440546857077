import _ from 'lodash';

import { Indicator_Result } from '@/generated/graphql';
import { Indicator } from '@/generated/graphql.typed';

import {
  ConformanceIndicatorRating,
  ConformanceTrend,
  IndicatorFlatFields,
} from './types';

export const conformanceIndicatorRating = (
  indicator: Pick<
    Indicator,
    | 'LowerToleranceNum'
    | 'UpperToleranceNum'
    | 'TargetValueTxt'
    | 'UpperAppetiteNum'
    | 'LowerAppetiteNum'
  >,
  indicatorResult: Partial<
    Pick<Indicator_Result, 'TargetValueTxt' | 'TargetValueNum'>
  > = {}
): ConformanceIndicatorRating => {
  const { TargetValueNum = null, TargetValueTxt = null } = indicatorResult;
  const {
    TargetValueTxt: targetTxt = '',
    UpperToleranceNum,
    LowerToleranceNum,
    UpperAppetiteNum,
    LowerAppetiteNum,
  } = indicator;

  let result = ConformanceIndicatorRating.NotSet;

  if (TargetValueNum !== null) {
    if (
      UpperToleranceNum === null &&
      LowerToleranceNum === null &&
      LowerAppetiteNum === null &&
      UpperAppetiteNum === null
    ) {
      return ConformanceIndicatorRating.NotSet;
    }

    if (!_.isNil(LowerToleranceNum)) {
      if (TargetValueNum < LowerToleranceNum) {
        return ConformanceIndicatorRating.Outside;
      }
    }
    if (!_.isNil(LowerAppetiteNum)) {
      if (TargetValueNum < LowerAppetiteNum) {
        return ConformanceIndicatorRating.OutsideAppetite;
      }
    }
    if (!_.isNil(UpperToleranceNum)) {
      if (TargetValueNum > UpperToleranceNum) {
        return ConformanceIndicatorRating.Outside;
      }
    }
    if (!_.isNil(UpperAppetiteNum)) {
      if (TargetValueNum > UpperAppetiteNum) {
        return ConformanceIndicatorRating.OutsideAppetite;
      }
    }

    return ConformanceIndicatorRating.Within;
  }
  if (TargetValueTxt) {
    // compare txt stripped whitespace and check case insensitive.
    result =
      TargetValueTxt.replace(/\s+/g, '').localeCompare(
        (targetTxt || '').replace(/\s+/g, ''),
        undefined,
        { sensitivity: 'base' }
      ) === 0
        ? ConformanceIndicatorRating.Within
        : ConformanceIndicatorRating.Outside;
  }

  return result;
};

export const getConformanceTrendRating = (
  indicator: Pick<
    Indicator,
    | 'LowerToleranceNum'
    | 'UpperToleranceNum'
    | 'UpperAppetiteNum'
    | 'LowerAppetiteNum'
    | 'TargetValueTxt'
  >,
  results: Partial<
    Pick<Indicator_Result, 'TargetValueTxt' | 'TargetValueNum'>
  >[]
): ConformanceTrend | null => {
  if (results.length < 2) {
    return null;
  }
  const currentRating = conformanceIndicatorRating(indicator, results[0]);
  const previousRating = conformanceIndicatorRating(indicator, results[1]);
  if (currentRating === previousRating) {
    return ConformanceTrend.Stable;
  }

  const order = [
    ConformanceIndicatorRating.Outside,
    ConformanceIndicatorRating.OutsideAppetite,
    ConformanceIndicatorRating.Within,
  ];

  return order.indexOf(currentRating) > order.indexOf(previousRating)
    ? ConformanceTrend.Improving
    : ConformanceTrend.Deteriorating;
};

export const conformanceRatingFromResults = (
  data: Pick<
    IndicatorFlatFields,
    | 'latestResults'
    | 'LowerToleranceNum'
    | 'UpperToleranceNum'
    | 'TargetValueTxt'
  >
) => {
  return conformanceIndicatorRating(data, data.latestResults[0]);
};
