import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal';
import TabHeader from '@/components/TabHeader';
import {
  GetUserGroupByIdQuery,
  useDeleteUserGroupUsersMutation,
  useGetUsersByGroupIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import AddMembersModal from './AddMembersModal';
import { GroupMembersTableFields, useGetCollectionTableProps } from './config';

type Props = {
  userGroup: GetUserGroupByIdQuery['user_group'][number];
};

const Tab: FC<Props> = ({ userGroup }) => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'userGroupMembers',
  });
  const { t } = useTranslation(['common']);
  const { addNotification } = useNotifications();
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<GroupMembersTableFields[]>(
    []
  );

  const [deleteUserGroupUsers, deleteResult] = useDeleteUserGroupUsersMutation({
    update: (cache) => {
      evictField(cache, 'user_group_user');
    },
  });

  const { data, loading, refetch } = useGetUsersByGroupIdQuery({
    variables: {
      GroupId: userGroup.Id,
    },
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.user_group[0].users ?? []
  );

  const existingUserIds =
    data?.user_group[0].users
      .filter((u) => u.authUsers)
      .map((u) => u.authUsers.Id) ?? [];

  const handleAddGroupModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddGroupModalClose = () => {
    setIsAddModalOpen(false);
    refetch();
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteUserGroupUsers({
        variables: {
          UserIds: selectedUsers.map((s) => s.Id),
          UserGroupId: userGroup.Id,
        },
      });
      setSelectedUsers([]);
      setIsDeleteModalVisible(false);
      refetch();

      return true;
    },
  });

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    formAction="none"
                    variant="normal"
                    disabled={!selectedUsers.length}
                    onClick={() => setIsDeleteModalVisible(true)}
                  >
                    {st('remove_button')}
                  </Button>
                  <Button
                    variant="primary"
                    formAction="none"
                    onClick={handleAddGroupModalOpen}
                  >
                    {st('add_button')}
                  </Button>
                </SpaceBetween>
              }
            >
              {st('membersTableTitle')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        selectionType="multi"
        selectedItems={selectedUsers}
        onSelectionChange={({ detail }) => {
          setSelectedUsers(detail.selectedItems);
        }}
        trackBy="Id"
      />
      {isAddModalOpen && (
        <AddMembersModal
          onDismiss={handleAddGroupModalClose}
          userGroupId={userGroup.Id}
          existingUserIds={existingUserIds}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('remove')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setSelectedUsers([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_remove_message')}
      </DeleteModal>
    </>
  );
};

export default Tab;
