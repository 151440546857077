import { TableProps } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';
import { getAllOwnersCellValue } from 'src/rbac/contributorHelper';

import CommaSeparatedCell from '@/components/CommaSeparatedCell';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import TagsPopover from '@/components/TagsPopover';
import { GetActionsByParentIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { CollectionData } from '@/utils/collectionUtils';
import { toLocalDate } from '@/utils/dateUtils';

type ActionFields = CollectionData<GetActionsByParentIdQuery['action'][number]>;

export const useActionsColumnDefinitions = () => {
  const { getByValue } = useRating('action_status');
  const { getByValue: getPriority } = useRating('priority');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actions.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const columnDefinitions: TableProps<ActionFields>['columnDefinitions'] = [
    {
      id: 'title',
      header: st('title'),
      cell: (item) => <Link href={`${item.Id}`}>{item.Title}</Link>,
      sortingField: 'Title',
      isRowHeader: true,
    },

    {
      id: 'owner',
      header: t('owners'),
      cell: (item) => (
        <CommaSeparatedCell items={getAllOwnersCellValue(item)} />
      ),
      sortingField: 'owners',
    },

    {
      id: 'dateRaised',
      header: st('date_raised'),
      cell: (item) => toLocalDate(item.DateRaised),
      sortingField: 'DateRaised',
    },

    {
      id: 'dueDate',
      header: st('due_date'),
      cell: (item) => toLocalDate(item.DateDue),
      sortingField: 'DateDue',
    },

    {
      id: 'status',
      header: st('status'),
      cell: (item) => <SimpleRatingBadge rating={getByValue(item.Status)} />,
      sortingField: 'Status',
    },

    {
      id: 'priority',
      header: st('priority'),
      cell: (item) => <SimpleRatingBadge rating={getPriority(item.Priority)} />,
      sortingField: 'Priority',
    },
    {
      id: 'tags',
      header: t('tags'),
      cell: (item) => <TagsPopover id={item.Id} tags={item.tags} />,
      sortingField: 'tags',
    },
  ];

  return columnDefinitions;
};
