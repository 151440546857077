import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ownerAndContributorIds } from 'src/components/Form';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import IssueModal from 'src/pages/issues/IssueModal';
import { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  namedOperations,
  Parent_Type_Enum,
  useDeleteIssuesMutation,
  useGetIssuesByParentIdQuery,
  useInsertChildIssueMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import {
  IssueTableFields,
  useGetIssueColumnDefinitions,
} from './issueTabConfig';

type Props = {
  parent: ObjectWithContributors;
};

const IssuesTab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('issues.tabHelp');
  const { updateFiles } = useFileUpdate();
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'issues' });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const issueColumnDefinitions = useGetIssueColumnDefinitions();
  const [selectedIssues, setSelectedIssues] = useState<IssueTableFields[]>([]);
  const canDeleteIssues = useHasPermission('delete:issue', parent);

  const [insertIssue] = useInsertChildIssueMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'issue_aggregate');
      evictField(cache, 'internal_audit_entity');
    },
    refetchQueries: [namedOperations.Query.getIssues],
  });

  const onSave = async (data: IssueFormDataFields) => {
    const { newFiles, files, ...issueData } = data;
    const result = await insertIssue({
      variables: {
        ...issueData,
        ParentId: parent.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
      },
    });
    const issueId = result.data?.insertChildIssue?.Id;
    if (!issueId) {
      throw new Error('Issue id not returned');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Issue,
      parentId: issueId,
      newFiles,
      originalFiles: [], // This is always an insert so no original files
      selectedFiles: files,
    });
  };

  const { data, loading } = useGetIssuesByParentIdQuery({
    variables: {
      ParentId: parent.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [deleteIssues, deleteResult] = useDeleteIssuesMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'issue_aggregate');
    },
  });

  const handleIssueModalOpen = () => {
    setIsCreateOpen(true);
  };

  const handleIssueModalClose = () => {
    setIsCreateOpen(false);
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteIssues({
        variables: {
          Ids: selectedIssues.map((s) => s.Id),
        },
      });
      setSelectedIssues([]);
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  const labelledFields = useMemo<IssueTableFields[]>(() => {
    return (
      data?.issue.map((i) => ({
        ...i,
        TargetCloseDate: i.assessment?.TargetCloseDate ?? null,
        Severity: i.assessment?.Severity ?? null,
        Status: i.assessment?.Status ?? null,
      })) || []
    );
  }, [data?.issue]);

  const { items, collectionProps } = useCollection(labelledFields, {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:issue" parentObject={parent}>
              <Button formAction="none" onClick={handleIssueModalOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: issueColumnDefinitions[4],
        isDescending: true,
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteIssues ? 'multi' : undefined}
        selectedItems={selectedIssues}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedIssues(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission permission="delete:issue" parentObject={parent}>
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedIssues.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission permission="insert:issue" parentObject={parent}>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleIssueModalOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={issueColumnDefinitions}
        items={items}
        loadingText={st('loading_message') ?? ''}
        sortingDisabled={false}
      />
      {isCreateOpen && (
        <IssueModal onDismiss={handleIssueModalClose} onSaving={onSave} />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          setSelectedIssues([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default IssuesTab;
