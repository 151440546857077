
    import './styles.scoped.css';
    export default {
  "dialog": "awsui_dialog_1d2i7_1xjtx_165",
  "modal-slide-up": "awsui_modal-slide-up_1d2i7_1xjtx_1",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_1d2i7_1xjtx_1",
  "refresh": "awsui_refresh_1d2i7_1xjtx_188",
  "awsui-motion-scale-popup": "awsui_awsui-motion-scale-popup_1d2i7_1xjtx_1",
  "root": "awsui_root_1d2i7_1xjtx_221",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_1d2i7_1xjtx_1",
  "hidden": "awsui_hidden_1d2i7_1xjtx_301",
  "focus-lock": "awsui_focus-lock_1d2i7_1xjtx_305",
  "small": "awsui_small_1d2i7_1xjtx_325",
  "medium": "awsui_medium_1d2i7_1xjtx_328",
  "large": "awsui_large_1d2i7_1xjtx_331",
  "max": "awsui_max_1d2i7_1xjtx_334",
  "breakpoint-xs": "awsui_breakpoint-xs_1d2i7_1xjtx_334",
  "container": "awsui_container_1d2i7_1xjtx_340",
  "content": "awsui_content_1d2i7_1xjtx_384",
  "no-paddings": "awsui_no-paddings_1d2i7_1xjtx_389",
  "header": "awsui_header_1d2i7_1xjtx_394",
  "header--text": "awsui_header--text_1d2i7_1xjtx_406",
  "footer": "awsui_footer_1d2i7_1xjtx_410",
  "footer--stuck": "awsui_footer--stuck_1d2i7_1xjtx_419",
  "dismiss-control": "awsui_dismiss-control_1d2i7_1xjtx_431",
  "modal-open": "awsui_modal-open_1d2i7_1xjtx_435"
};
  