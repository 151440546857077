import {
  CustomAttributeDataSchema,
  FilesSchema,
  NullableStringDateSchema,
} from 'src/schemas/global';
import { z } from 'zod';

export const DocumentAssessmentResultSchema = z
  .object({
    AssessmentId: z.string().uuid().nullable(),
    ComplianceMonitoringAssessmentId: z.string().uuid().nullable(),
    InternalAuditReportId: z.string().uuid().nullable(),
    DocumentIds: z
      .array(
        z.object({
          value: z
            .string({ required_error: 'Required' })
            .uuid({ message: 'Required' }),
        })
      )
      .min(1),
    Rating: z.number().int({ message: 'Required' }),
    Rationale: z.string().optional(),
    TestDate: NullableStringDateSchema,
  })
  .and(FilesSchema)
  .and(CustomAttributeDataSchema);

export type DocumentAssessmentResultFormDataFields = Omit<
  z.infer<typeof DocumentAssessmentResultSchema>,
  'resultType'
>;

export const defaultValues: DocumentAssessmentResultFormDataFields = {
  AssessmentId: null,
  ComplianceMonitoringAssessmentId: null,
  InternalAuditReportId: null,
  DocumentIds: [],
  Rating: 1,
  files: [],
  newFiles: [],
  CustomAttributeData: null,
  Rationale: '',
  TestDate: new Date().toISOString(),
};
