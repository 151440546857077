import Button from '@risksmart-app/components/Button';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import Table from '@risksmart-app/components/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import TabHeader from '@/components/TabHeader';
import { useGetQuestionnaireInvitesQuery } from '@/generated/graphql';

import { SpaceBetween } from '../../../../../../../theme/risksmart-themed/components';
import { useGetCollectionTableProps } from './config';

const QuestionnairesTab = () => {
  const thirdPartyId = useGetGuidParam('id');

  const { data, loading } = useGetQuestionnaireInvitesQuery({
    variables: { thirdPartyId },
  });
  const tableProps = useGetCollectionTableProps(
    data?.questionnaire_invite,
    thirdPartyId
  );
  const { t } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_invite',
  });
  const navigate = useNavigate();

  return (
    <div>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant={'primary'}
                    onClick={() => navigate('invite')}
                  >
                    {t('actions.plan')}
                  </Button>
                  <Button>{t('actions.recall')}</Button>
                </SpaceBetween>
              }
            >
              {t('register_title')}
            </TabHeader>
          </SpaceBetween>
        }
        loading={loading}
        variant={'embedded'}
      />
    </div>
  );
};

export default QuestionnairesTab;
