import { useMemo } from 'react';

import {
  QuestionnaireInviteFields,
  QuestionnaireInviteRegisterFields,
} from './types';

export const useLabelledFields = (
  data: QuestionnaireInviteFields[] | undefined
): QuestionnaireInviteRegisterFields[] => {
  return useMemo(() => {
    return (
      data?.map((record) => ({
        ...record,
        Questionnaire: record.questionnaireTemplateVersion.parent?.Title || '-',
        QuestionnaireVersion:
          record.questionnaireTemplateVersion.Version || '-',
        CreatedByFriendlyName: record.createdByUser?.FriendlyName || '-',
        ModifiedByFriendlyName: record.modifiedByUser?.FriendlyName || '-',
        StartDate: record.parent.StartDate || '-',
        ExpiresAt: record.parent.ExpiresAt || '-',
      })) ?? []
    );
  }, [data]);
};
