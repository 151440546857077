import { BoardItemDefinition } from '@cloudscape-design/board-components/internal/interfaces';
import { Alert } from '@cloudscape-design/components';
import { ErrorBoundary } from '@sentry/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonDropdown from '@/components/ButtonDropdown';

import BoardItem from '../BoardItem/BoardItem';
import { StoredWidgetDefinition, WidgetRef } from '../types';
import { WidgetContextProvider } from '../WidgetContext/WidgetContext';

type Props = {
  item: BoardItemDefinition<StoredWidgetDefinition>;
  onRemove?: () => void;
};

export const Widget = ({ item, onRemove }: Props) => {
  const { t } = useTranslation(['common']);
  const [ref, setRef] = useState<WidgetRef | null>(null);

  const widgetTranslations = t(item.data.translationKeyPrefix, {
    returnObjects: true,
  });

  const menuItems = [{ id: 'remove', text: t('dashboard.remove_button') }];

  if (ref?.openSettings) {
    menuItems.push({ id: 'settings', text: 'Settings' });
  }

  const setWidgetRef = (widgetRef: WidgetRef) => {
    if (!!widgetRef && (!ref || ref.key !== widgetRef.key)) {
      setRef(widgetRef);
    }
  };

  return (
    <WidgetContextProvider value={{ widgetId: item.id }}>
      <BoardItem
        title={widgetTranslations.title}
        centerAlignHeader={item.data.centerAlignHeader}
        disableContentPaddings={item.data.disableContentPaddings}
        headerVariant={
          item.rowSpan === 2 || item.columnSpan === 1 ? 'h4' : 'h3'
        }
        settings={
          <div className={'flex items-center h-[32px]'}>
            <ButtonDropdown
              // expandToViewport
              items={menuItems}
              noPadding
              ariaLabel={t('dashboard.widget_settings')}
              variant="icon"
              onItemClick={(e) => {
                if (e.detail.id === 'remove') {
                  onRemove?.();
                } else if (e.detail.id === 'settings') {
                  ref?.openSettings?.();
                }
              }}
            />
          </div>
        }
      >
        <ErrorBoundary
          fallback={
            <Alert header="Error" type="error">
              An error has occurred
            </Alert>
          }
        >
          <item.data.content ref={setWidgetRef} />
        </ErrorBoundary>
      </BoardItem>
    </WidgetContextProvider>
  );
};
