import { FC } from 'react';

type DashboardItemProps = {
  title: string;
  value: number;
  selected?: boolean;
  onClick?: () => void;
};

export const DashboardItem: FC<DashboardItemProps> = ({
  title,
  value,
  selected,
  onClick,
}) => {
  const color = selected ? 'text-navy_mid' : 'text-teal';

  return (
    <button
      data-testid="dashboard-item"
      disabled={selected}
      className="group flex w-full h-full mr-[20px] min-w-[150px] flex-grow border-none bg-transparent p-[10px] m-0 text-start cursor-pointer disabled:cursor-default disabled:text-navy_mid"
      onClick={onClick}
    >
      <div className="flex flex-col gap-y-4 flex-shrink-0">
        <h5
          className={
            'm-0 truncate max-w-[200px] text-[14px] font-semibold text-navy_mid'
          }
        >
          {title}
        </h5>
        <h1
          className={`m-0 w-min text-5xl transition-all duration-200 group-enabled:group-hover:scale-[108%] group-enabled:group-active:scale-[92%] group-enabled:group-hover:text-teal2 font-extrabold ${color}`}
        >
          {value}
        </h1>
      </div>
    </button>
  );
};
