import { SideNavigationProps } from '@cloudscape-design/components';
import {
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { NavLink, useLocation, useMatches } from 'react-router-dom';

import { useTools } from '../Tools/ToolsProvider';
import { CountRenderer } from './Count';
import { LinkProps } from './Link';
import { SectionProps } from './Section';
import { SectionGroupProps } from './SectionGroup';

type Props =
  | SectionProps
  | (LinkProps & {
      count?: string;
    })
  | SectionGroupProps;
const ItemCollapsed: FC<
  Props & { renderCount?: CountRenderer; altTheme?: boolean }
> = ({ item, renderCount, altTheme }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    placement: 'right-start',
    strategy: 'fixed',
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
  });

  const matches = useMatches();
  const location = useLocation();

  const dismiss = useDismiss(context, { ancestorScroll: true });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  const items = item.type === 'section' ? item.items : null;
  const link = item.type === 'link' ? item : null;

  const [toolsContent, setToolsContent] = useTools();

  const onClick =
    link?.href === '#notifications'
      ? () => {
          setToolsContent(
            toolsContent === 'notifications' ? undefined : 'notifications'
          );

          return false;
        }
      : () => setIsMenuOpen(!isMenuOpen);

  const linksInSection =
    items
      ?.filter((item): item is SideNavigationProps.Link => item.type === 'link')
      .map((item) => item.href) || [];

  const urlMatches = matches
    .map((match) => match.pathname)
    .filter((match) => {
      if (location.pathname === '/' && match === '/') {
        return true;
      }
      if (match !== '/') {
        return true;
      }
    });

  const hasMatch = Boolean(
    linksInSection?.filter((x) => urlMatches.includes(x))?.filter(Boolean)
      ?.length > 0
  );

  const buttonClassNames =
    'relative border-0 mx-auto px-[10px] rounded-md h-[40px] flex items-center justify-center cursor-pointer';

  const isNotification = link?.count === 'notification';

  const Icon = () => (
    <>
      {link && !isNotification ? (
        <NavLink
          className={({ isActive }) =>
            `${buttonClassNames} ${
              isActive && link.href !== '#notifications'
                ? ' text-navy bg-teal hover:text-navy hover:bg-teal'
                : 'text-teal bg-transparent hover:bg-navy_light'
            }`
          }
          to={link.href}
        >
          {item.icon}
        </NavLink>
      ) : (
        <button
          className={`${buttonClassNames}  ${
            hasMatch
              ? 'bg-teal text-navy'
              : 'bg-transparent text-teal hover:bg-navy_light'
          }`}
          onClick={onClick}
        >
          {item.icon}
          {link?.count === 'notification' && (
            <div className="absolute top-[3px] right-[13px]">
              {renderCount?.({
                isActive: false,
                badge: true,
                countName: link.count,
              })}
            </div>
          )}
        </button>
      )}
    </>
  );

  return (
    <li
      ref={refs.setReference}
      {...getReferenceProps()}
      className="list-none px-4 py-1"
    >
      {items && isMenuOpen && (
        <FloatingPortal>
          <div
            style={floatingStyles}
            {...getFloatingProps()}
            className="pb-3 ml-3 w-[275px] max-w-lg"
            ref={refs.setFloating}
          >
            <div
              className={clsx(
                'rounded-[10px] font-sans font-semibold text-xs text-white p-3',
                { 'bg-navy_mid': !altTheme, 'bg-dark_green': altTheme }
              )}
            >
              <ul className="list-none p-[0px] m-[0px]">
                {items
                  ?.filter(
                    (item): item is SideNavigationProps.Link =>
                      item.type === 'link'
                  )
                  .map((item, index) => (
                    <li key={`item-${index}`}>
                      <NavLink
                        to={item.href}
                        className=" block p-3 rounded-md text-white text-[14px] no-underline hover:bg-navy_light"
                      >
                        {item.text}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </FloatingPortal>
      )}

      <Icon />
    </li>
  );
};

export default ItemCollapsed;
