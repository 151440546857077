import { FC } from 'react';

import { useGetAcceptanceByIdQuery } from '@/generated/graphql';

type Props = {
  acceptanceId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ acceptanceId }) => {
  const { data } = useGetAcceptanceByIdQuery({
    variables: {
      _eq: acceptanceId!,
    },
    skip: !acceptanceId,
  });

  return <>{data?.acceptance?.[0]?.Title || ''}</>;
};

export default Breadcrumb;
