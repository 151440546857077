import { useGetRiskAssessmentResultAuditByIdQuery } from '@/generated/graphql';

import { getAuditItems } from './auditEntityFinder';
import AuditViewComponent from './AuditViewComponent';
import { AuditEntityRetrieverInput } from './types';

const RiskAssessmentResultAudit = (input: AuditEntityRetrieverInput) => {
  const { data: result } = useGetRiskAssessmentResultAuditByIdQuery({
    variables: {
      Id: input.id,
    },
  });
  const { current, previous } = getAuditItems(
    result?.risk_assessment_result_audit,
    input.operationDate
  );

  return (
    <AuditViewComponent
      operation={input.operation}
      current={JSON.stringify(current, null, 2)}
      previous={JSON.stringify(previous, null, 2)}
    />
  );
};

export default RiskAssessmentResultAudit;
