import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import { useGetAppetitesLatestForRiskQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { useGetCollectionTableProps } from './config';

const Page: FC = () => {
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'appetites' });
  const title = st('register_title');

  const { addNotification } = useNotifications();
  const { data, loading } = useGetAppetitesLatestForRiskQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.risk,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <PageLayout
      helpTranslationKey="appetites.help"
      title={title}
      counter={getCounter(tableProps.totalItemsCount, loading)}
      actions={
        <Button iconName="download" onClick={tableProps.exportToCsv}>
          {t('export')}
        </Button>
      }
    >
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default Page;
