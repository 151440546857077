import { z } from 'zod';

import {
  CustomAttributeDataSchema,
  FilesSchema,
  InheritedContributorSchema,
  StringDateSchema,
  TagsAndDepartmentsSchema,
  UserOrGroupsSchema,
} from '../../../../schemas/global';

export const IssueFormSchema = z
  .object({
    Id: z.string().optional(),
    Title: z.string().min(1, { message: 'Required' }),
    Details: z.string(),
    ImpactsCustomer: z.boolean().nullish(),
    IsExternalIssue: z.boolean().nullish(),
    DateOccurred: StringDateSchema,
    DateIdentified: StringDateSchema,
    TagTypeIds: z.array(z.string()),
    DepartmentTypeIds: z.array(z.string()),

    Contributors: UserOrGroupsSchema,
    Owners: UserOrGroupsSchema,
    ancestorContributors: InheritedContributorSchema,
  })
  .and(TagsAndDepartmentsSchema)
  .and(CustomAttributeDataSchema)
  .and(FilesSchema);

export type IssueFormDataFields = z.infer<typeof IssueFormSchema>;

export const defaultValues: IssueFormDataFields = {
  DateIdentified: '',
  DateOccurred: '',
  ImpactsCustomer: null,
  IsExternalIssue: null,
  Title: '',
  Details: '',
  TagTypeIds: [],
  DepartmentTypeIds: [],
  tags: [],
  departments: [],
  files: [],
  newFiles: [],
  CustomAttributeData: null,
  Contributors: [],
  Owners: [],
  ancestorContributors: [],
};
