import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import { QuestionnaireTemplateFormFields } from './QuestionnaireTemplateFormFields';
import {
  defaultValues,
  QuestionnaireTemplateFormData,
  questionnaireTemplateFormSchema,
} from './questionnaireTemplateSchema';

export type Props = Omit<
  FormContextProps<QuestionnaireTemplateFormData>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'defaultValues'
  | 'renderTemplate'
  | 'i18n'
  | 'mapPreviewedChanges'
>;

export const QuestionnaireTemplateForm = ({ ...props }: Props) => {
  const { t } = useTranslation();

  return (
    <CustomisableFormContext
      {...props}
      formId={'questionnaire-template-form'}
      defaultValues={defaultValues}
      i18n={t('questionnaire_templates')}
      header={t('details')}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      parentType={Parent_Type_Enum.QuestionnaireTemplate}
      schema={questionnaireTemplateFormSchema}
    >
      <QuestionnaireTemplateFormFields />
    </CustomisableFormContext>
  );
};
