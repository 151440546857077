import { PropsWithChildren, useState } from 'react';
import { ZodSchema } from 'zod';

import { Parent_Type_Enum } from '@/generated/graphql';

import { RiskSmartFormContext } from './RiskSmartFormContext';

type Props = {
  onSave?: () => Promise<void>;
  parentType?: Parent_Type_Enum;
  setOnSave: React.Dispatch<
    React.SetStateAction<(() => Promise<void>) | undefined>
  >;
  setCustomFormValidation: React.Dispatch<
    React.SetStateAction<(schema: ZodSchema) => ZodSchema>
  >;
  previewChanges: Record<string, { from: unknown; to: unknown }> | null;
  readOnly?: boolean;
  defaultOnSave: () => Promise<void>;
  beforeSaveHooks: (() => Promise<boolean>)[];
  setBeforeSaveHooks: React.Dispatch<
    React.SetStateAction<(() => Promise<boolean>)[]>
  >;
};

export const RiskSmartFormProvider = ({
  onSave,
  setOnSave,
  setCustomFormValidation,
  parentType,
  previewChanges,
  readOnly,
  children,
  beforeSaveHooks,
  setBeforeSaveHooks,
  defaultOnSave,
}: PropsWithChildren<Props>) => {
  const [editMode, setEditMode] = useState(false);
  const [allFieldIds, setAllFieldIds] = useState<string[]>([]);
  const [defaultRequiredFields, setDefaultRequiredFields] = useState<string[]>(
    []
  );
  const [forcedRequiredFields, setForcedRequiredFields] = useState<string[]>(
    []
  );

  const [allowDefaultValueFields, setAllowDefaultValueFields] = useState<
    string[]
  >([]);

  const addFieldId = (name: string) =>
    setAllFieldIds((fields) => Array.from(new Set([...fields, name])));

  const removeFieldId = (name: string) =>
    setAllFieldIds((allFields) => allFields.filter((f) => f !== name));

  const toggleEditMode = () => setEditMode((prev) => !prev);

  const addDefaultRequiredField = (fieldId: string) => {
    setDefaultRequiredFields((prev) => Array.from(new Set([...prev, fieldId])));
  };

  const removeDefaultRequiredField = (fieldId: string) => {
    setDefaultRequiredFields((prev) =>
      prev.filter((requiredField) => requiredField !== fieldId)
    );
  };

  const addForcedRequiredField = (fieldId: string) => {
    setForcedRequiredFields((prev) => Array.from(new Set([...prev, fieldId])));
  };

  const removeForcedRequiredField = (fieldId: string) => {
    setForcedRequiredFields((prev) =>
      prev.filter((requiredField) => requiredField !== fieldId)
    );
  };

  const addAllowDefaultValueField = (fieldId: string) => {
    setAllowDefaultValueFields((prev) =>
      Array.from(new Set([...prev, fieldId]))
    );
  };

  const removeAllowDefaultValueField = (fieldId: string) => {
    setAllowDefaultValueFields((prev) =>
      prev.filter((requiredField) => requiredField !== fieldId)
    );
  };

  return (
    <RiskSmartFormContext.Provider
      value={{
        allowDefaultValueFields,
        addAllowDefaultValueField,
        removeAllowDefaultValueField,
        parentType: parentType ?? null,
        editMode,
        onSave,
        toggleEditMode,
        setOnSave,
        setCustomFormValidation,
        defaultRequiredFields,
        forcedRequiredFields,
        addForcedRequiredField,
        removeForcedRequiredField,
        addDefaultRequiredField,
        removeDefaultRequiredField,
        allFieldIds,
        addFieldId,
        removeFieldId,
        previewChanges,
        readOnly,
        beforeSaveHooks,
        setBeforeSaveHooks,
        defaultOnSave,
      }}
    >
      {children}
    </RiskSmartFormContext.Provider>
  );
};
