import { SideNavigationProps } from '@cloudscape-design/components';
import { Fragment } from 'react';

import { CountRenderer } from './Count';
import { navItemTypeReducer } from './navItemReducer';

export const NavItems = ({
  items,
  isChild,
  collapsed,
  renderCount,
  altTheme,
}: {
  items: Readonly<SideNavigationProps.Item[]>;
  isChild?: boolean;
  collapsed?: boolean;
  renderCount?: CountRenderer;
  altTheme?: boolean;
}) => {
  return items.map((item, i) => (
    <Fragment key={`list-item-${i}`}>
      {navItemTypeReducer({
        item,
        isChild,
        collapsed,
        renderCount,
        altTheme,
      })}
    </Fragment>
  ));
};
