
    import './styles.scoped.css';
    export default {
  "help-panel": "awsui_help-panel_1d237_1v003_177",
  "loading": "awsui_loading_1d237_1v003_347",
  "header": "awsui_header_1d237_1v003_352",
  "with-toolbar": "awsui_with-toolbar_1d237_1v003_369",
  "content": "awsui_content_1d237_1v003_387",
  "footer": "awsui_footer_1d237_1v003_438"
};
  