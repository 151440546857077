import { SelectProps } from '@cloudscape-design/components';
import {
  OptionDefinition,
  OptionGroup,
} from '@cloudscape-design/components/internal/components/option/interfaces';
import { useMemo } from 'react';
import { FieldValues, Noop, RefCallBack } from 'react-hook-form';

import { FormField } from '@/components/Form/Form/FormField';
import HelpLink from '@/components/HelpPanel/HelpLink';
import { Content } from '@/components/HelpPanel/HelpProvider';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import Select from '../Select';
import { ControlledBaseProps } from '../types';

export type StatusType = 'pending' | 'loading' | 'finished' | 'error';

interface SelectInputProps {
  label: string;
  value: string | null;
  disableBottomPadding?: boolean;
  options: SelectProps.Options;
  onChange: (value: string | number | null | undefined) => void;
  onBlur?: Noop;
  filteringType?: SelectProps.FilteringType;
  type?: 'string' | 'number';
  constraintText?: React.ReactNode;
  disabled?: boolean;
  statusType?: StatusType;
  placeholder?: string;
  innerRef?: RefCallBack;
  errorMessage?: string;
  testId?: string;
  description?: Content;
  className?: string;
}

export const SelectInput = ({
  label,
  value,
  onChange,
  onBlur,
  type,
  placeholder,
  innerRef,
  constraintText,
  options,
  errorMessage,
  testId,
  disableBottomPadding,
  description,
  ...props
}: SelectInputProps) => {
  const coercedValue = type === 'number' ? String(value) : value;
  const currentSelectedOption = getSelectedOption(coercedValue, [...options]);

  return (
    <FormField
      disableBottomPadding={disableBottomPadding}
      constraintText={constraintText}
      label={label}
      errorText={errorMessage}
      stretch
      testId={testId}
      info={
        description && (
          <HelpLink title={label} content={description} id={label} />
        )
      }
    >
      <Select
        virtualScroll={options.length >= 500}
        ref={innerRef}
        selectedOption={currentSelectedOption}
        onBlur={onBlur}
        onChange={(e) => {
          const val =
            e.detail.selectedOption.value === ''
              ? null
              : e.detail.selectedOption.value;
          const coercedValue =
            type === 'number' && val !== null ? Number(val) : val;
          onChange(coercedValue);
        }}
        options={options}
        placeholder={placeholder ?? 'Enter value'}
        empty="No matches found"
        {...props}
      />
    </FormField>
  );
};

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  options: SelectProps.Options | undefined;
  filteringType?: SelectProps.FilteringType;
  addEmptyOption?: boolean;
  type?: 'string' | 'number';
  constraintText?: React.ReactNode;
  disabled?: boolean;
  statusType?: StatusType;
  onChange?: (value: string | number | null | undefined) => void;
  testId?: string;
  className?: string;
  onBlur?: () => void;
}

export const ControlledSelect = <T extends FieldValues>({
  name,
  control,
  label,
  options,
  addEmptyOption,
  type,
  constraintText,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
  description,
  className,
  onBlur,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const optionItems = useGetOptionsWithEmptyOption(options, addEmptyOption);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      defaultRequired={defaultRequired}
      name={name}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      defaultValueOptions={optionItems}
      control={control}
      render={({
        field: { ref, onChange, onBlur: onControllerBlur, value },
      }) => {
        return (
          <SelectInput
            className={className}
            type={type}
            innerRef={ref}
            label={label}
            description={description}
            value={value}
            options={optionItems}
            errorMessage={error?.message}
            constraintText={constraintText}
            {...props}
            disabled={readOnly || props.disabled}
            onBlur={() => {
              onControllerBlur();
              onBlur?.();
            }}
            onChange={(e) => {
              onChange(e);
              props.onChange?.(e);
            }}
          />
        );
      }}
    />
  );
};

const useGetOptionsWithEmptyOption = (
  options: SelectProps.Options | undefined,
  addEmptyOption: boolean | undefined
) => {
  const optionItems = useMemo<(OptionDefinition | OptionGroup)[]>(
    () => [
      ...(addEmptyOption
        ? [
            {
              value: '',
              label: '-',
            },
          ]
        : []),
      ...(options || []),
    ],
    [options, addEmptyOption]
  );

  return optionItems;
};

export const getSelectedOption = (
  value: string | undefined | null,
  optionItems: (OptionDefinition | OptionGroup)[]
) => {
  if (value === null) {
    return value;
  }

  const matchingItem: OptionDefinition | null = null;
  for (const option of optionItems) {
    if ('value' in option && option.value === value) {
      return option;
    }
    if ('options' in option) {
      for (const childOption of option.options) {
        if ('value' in childOption && childOption.value === value) {
          return childOption;
        }
      }
    }
  }
  if (!matchingItem) {
    // This can be the case for "Standard" role users, where they don't have permission to view the currently
    // selected option e.g. a parent risk
    return {
      value,
    };
  }

  return matchingItem;
};
