import { useApolloClient } from '@apollo/client';
import { Alert, Button, HelpPanel } from '@cloudscape-design/components';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useHasPermission } from 'src/rbac/Permission';
import { z } from 'zod';

import Loading from '@/components/Loading';
import {
  Approval_Status_Enum,
  ChangeRequestPartsFragment,
  useGetApprovalLevelsQuery,
  useGetChangeRequestByParentIdSubscription,
  useGetUsersQuery,
  useSubmitChangeRequestResponseMutation,
} from '@/generated/graphql';
import { getOwners, useChangeRequests } from '@/hooks/useChangeRequests';
import { toLocalDate, toLocalDateTime } from '@/utils/dateUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import { useGetDetailParentPath } from '../../routes/useGetDetailParentPath';
import ButtonDropdown from '../ButtonDropdown';
import ChangeRequestOverrideModal from '../ChangeRequestOverrideModal/ChangeRequestOverrideModal';
import { TextareaInput } from '../Form/ControlledTextarea/ControlledTextarea';
import Select from '../Form/Select';
import styles from './style.module.scss';
import { UserPreview } from './UserPreview';

type ChangeRequestLevelsProps = {
  changeRequestId?: string;
  parentId: string;
};

export const ChangeRequestLevels = ({
  changeRequestId,
  parentId,
}: ChangeRequestLevelsProps) => {
  const navigate = useNavigate();
  const { data } = useGetChangeRequestByParentIdSubscription({
    variables: {
      Id: parentId,
    },
    skip: !parentId,
  });
  const client = useApolloClient();
  const [_searchParams, setSearchParams] = useSearchParams();

  const { user, isLoading } = useRisksmartUser();

  const { t } = useTranslation('common', { keyPrefix: 'approvals' });
  const statuses = t('status', { returnObjects: true });

  const hasPermissionToOverride = useHasPermission('delete:change_request');

  const clearParentFromCache = () => {
    const cacheData = client.cache.extract();
    const cacheShape = z.object({
      ROOT_QUERY: z.record(
        z.string(),
        z.object({ Id: z.string().optional() }).array()
      ),
    });
    const rootCache = cacheShape.safeParse(cacheData);
    if (rootCache.success) {
      const keys = Object.entries(rootCache.data.ROOT_QUERY).filter(
        ([_, value]) => {
          return !!value.find((item) => item.Id === parentId);
        }
      );
      keys.forEach(([key]) => {
        client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: key,
        });
        client.cache.gc();
      });
    }
  };

  const parentPath = useGetDetailParentPath(parentId ?? '', true);

  const [previousStatus, setPreviousStatus] =
    useState<Approval_Status_Enum | null>(null);
  const { addNotification } = useNotifications();

  const [selectedChangeRequest, setSelectedChangeRequest] =
    useState<ChangeRequestPartsFragment | null>();

  const [previousSelectedChangeRequest, setPreviousSelectedChangeRequest] =
    useState<ChangeRequestPartsFragment | null>();

  const [showChangeRequestOverrideModal, setShowChangeRequestOverrideModal] =
    useState(false);

  const [activeComment, setActiveComment] = useState<string>('');

  useEffect(() => {
    if (data?.change_request) {
      const selected = changeRequestId
        ? data.change_request.find((cr) => cr.Id === changeRequestId)
        : (data.change_request.filter(
            (cr) => cr.ChangeRequestStatus === Approval_Status_Enum.Pending
          )[0] ?? data.change_request[0]);
      setSelectedChangeRequest(selected);
    }
  }, [data?.change_request, changeRequestId]);

  const changeRequestOptions = useMemo(() => {
    if (!data?.change_request) {
      return [];
    }

    return data.change_request.map((cr) => ({
      label: `${getFriendlyId(
        'change_request',
        cr.SequentialId
      )} - ${toLocalDate(cr.CreatedAtTimestamp)} - ${
        statuses[cr.ChangeRequestStatus]
      }`,
      value: cr.Id,
    }));
  }, [data?.change_request, statuses]);

  useEffect(() => {
    const newStatus = selectedChangeRequest?.ChangeRequestStatus;
    if (newStatus) {
      if (
        previousStatus === Approval_Status_Enum.Pending &&
        newStatus !== Approval_Status_Enum.Pending &&
        selectedChangeRequest?.Id === previousSelectedChangeRequest?.Id
      ) {
        clearParentFromCache();
        if (
          selectedChangeRequest?.Type === 'delete' &&
          newStatus === Approval_Status_Enum.Approved
        ) {
          navigate(parentPath);
        }
        addNotification({
          type: newStatus === 'approved' ? 'success' : 'error',
          content: `Request ${newStatus}`,
        });
      }
      setPreviousStatus(newStatus);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChangeRequest, client]);

  const workflow =
    selectedChangeRequest?.responses[0]?.approver?.level?.approval?.Workflow;
  const ParentId = selectedChangeRequest?.responses.find(
    (response) => response.approver.level?.approval?.ParentId !== null
  )?.approver.level?.approval?.ParentId;

  const { data: levelData, loading } = useGetApprovalLevelsQuery({
    variables: {
      Workflow: workflow!,
      ParentId: ParentId ?? '00000000-0000-0000-0000-000000000000',
    },
    skip: !workflow,
  });

  const [updateResponse, { loading: updating }] =
    useSubmitChangeRequestResponseMutation({
      update: (cache) => {
        evictField(cache, 'change_request_by_pk');
      },
    });

  const {
    isActiveApprover,
    activeLevelId,
    loading: useChangeRequestLoading,
  } = useChangeRequests(undefined);

  const { data: users, loading: usersLoading } = useGetUsersQuery();

  const changeRequest = selectedChangeRequest;

  const activeLevel = useMemo(() => {
    if (changeRequest) {
      return activeLevelId(changeRequest);
    }

    return null;
  }, [changeRequest, activeLevelId]);

  const overriddenBy = useMemo(() => {
    if (changeRequest?.OverriddenByUser) {
      return users?.user.find(
        (user) => user.Id === changeRequest.OverriddenByUser
      );
    }

    return null;
  }, [changeRequest, users]);

  const submitResponse = async (
    approved: boolean,
    comment: string,
    override?: boolean
  ) => {
    if (!selectedChangeRequest) {
      throw new Error('No change request selected');
    }

    await updateResponse({
      variables: {
        changeRequestId: selectedChangeRequest?.Id,
        approverIds:
          levelData?.levels
            .find((level) => level.Id === activeLevel)
            ?.approvers.filter(
              (approver) =>
                approver.OwnerApprover ||
                override ||
                approver.UserId === user?.userId ||
                approver.group?.users?.find((u) => u.UserId === user?.userId)
            )
            .map((approver) => approver.Id) ?? [],
        response: approved,
        comment: !override
          ? comment
          : `Overridden by ${
              user?.['claims_username'] ?? 'a risk manager'
            }. Rationale: ${comment ?? 'n/a'}`,
      },
    });

    setActiveComment('');
  };

  if (
    !changeRequest ||
    loading ||
    usersLoading ||
    useChangeRequestLoading ||
    isLoading
  ) {
    return <Loading />;
  }

  const levels =
    levelData?.levels.filter((level) =>
      changeRequest.responses
        .map((response) => response.approver.level?.Id)
        .includes(level.Id)
    ) ?? [];

  const responsesWithNoLevel = changeRequest.responses.filter(
    (response) =>
      !levels.map((lvl) => lvl.Id).includes(response.approver.level?.Id ?? '')
  );

  const activeApprover = isActiveApprover(
    changeRequest,
    getOwners(selectedChangeRequest)
  );

  return (
    <HelpPanel
      header={
        <div className="w-48">
          <div className="flex flex-col gap-6">
            <h2>
              Approval
              {changeRequest && (
                <span
                  className="ml-3 text-grey font-normal"
                  data-testid="change-request-id"
                >
                  ({getFriendlyId('change_request', changeRequest.SequentialId)}
                  )
                </span>
              )}
            </h2>
            {changeRequestOptions.length > 1 && (
              <Select
                selectedOption={{
                  value: selectedChangeRequest.Id,
                  label: `${getFriendlyId(
                    'change_request',
                    selectedChangeRequest.SequentialId
                  )} - ${toLocalDate(
                    selectedChangeRequest.CreatedAtTimestamp
                  )}`,
                }}
                data-testid="change-request-select"
                options={changeRequestOptions}
                onChange={(evt) => {
                  setPreviousSelectedChangeRequest(selectedChangeRequest);
                  setSearchParams((prev) => {
                    prev.set(
                      'requestId',
                      evt.detail.selectedOption.value as string
                    );
                    prev.set('showRequest', 'true');

                    return prev;
                  });
                }}
              />
            )}
          </div>
          {hasPermissionToOverride &&
            changeRequest.ChangeRequestStatus ===
              Approval_Status_Enum.Pending && (
              <ButtonDropdown
                className="absolute top-4 right-7"
                expandToViewport
                ariaLabel="Change request settings"
                items={[
                  {
                    text: 'Override change request',
                    id: 'add',
                    disabled: false,
                  },
                ]}
                variant="icon"
                onItemClick={(e) => {
                  if (e.detail.id === 'add') {
                    setShowChangeRequestOverrideModal(true);
                  }
                }}
              />
            )}
        </div>
      }
    >
      <div className={'flex flex-col gap-6'}>
        {changeRequest.createdBy ? (
          <div>
            <h4>Requesters</h4>
            <div className={'flex flex-col gap-3'}>
              <UserPreview user={changeRequest.createdBy} />
              {changeRequest.contributors.map((contributor, i) =>
                contributor.user ? (
                  <UserPreview user={contributor.user} key={i} />
                ) : null
              )}
              <span
                className="text-xs text-gray-400 text-right"
                key={`request-date`}
              >
                {toLocalDateTime(changeRequest.CreatedAtTimestamp)}
              </span>
            </div>
          </div>
        ) : null}
        {changeRequest.OverriddenAtTimestamp &&
          changeRequest.OverriddenByUser && (
            <div>
              <Alert type="warning" test-id="override-alert">
                The change request was{' '}
                <b>{changeRequest.ChangeRequestStatus}</b> by{' '}
                {overriddenBy?.FriendlyName} on{' '}
                {toLocalDateTime(changeRequest.OverriddenAtTimestamp)}.
                <div className="mt-6">
                  <b>Rationale: </b>
                  {changeRequest.Comment}
                </div>
              </Alert>
            </div>
          )}
        <div>
          <h4>Approval Steps</h4>
          {levels.map((level, i) => (
            <div
              key={level.Id}
              className={`mb-4 ${
                level.Id === activeLevel && !changeRequest.OverriddenAtTimestamp
                  ? 'opacity-100'
                  : 'opacity-50'
              }`}
            >
              <div
                className={`flex flex-col p-3 border-solid rounded-lg ${
                  level.Id === activeLevel
                    ? 'border animate-border-pulse'
                    : 'border border-grey150'
                }`}
              >
                <h5>Level {i + 1}</h5>
                <div className={'flex flex-col gap-3'}>
                  {hasPermissionToOverride &&
                    level.Id === activeLevel &&
                    changeRequest.ChangeRequestStatus ===
                      Approval_Status_Enum.Pending && (
                      <div className="!mb-3">
                        <Alert type="error" className="!mb-3">
                          {t('override_step_alert')}
                        </Alert>
                        <TextareaInput
                          label="Rationale"
                          value={activeComment ?? ''}
                          onChange={(val) => {
                            setActiveComment?.(val);
                          }}
                          inferenceDisabled={true}
                        />
                        <div className={'flex gap-3'}>
                          <Button
                            loading={updating}
                            onClick={async (e) => {
                              e.preventDefault();
                              await submitResponse?.(true, activeComment, true);
                            }}
                            variant={'primary'}
                          >
                            Approve
                          </Button>
                          <Button
                            loading={updating}
                            onClick={async (e) => {
                              e.preventDefault();
                              await submitResponse?.(
                                false,
                                activeComment,
                                true
                              );
                            }}
                            {...{ className: styles.dangerButton }}
                          >
                            Reject
                          </Button>
                        </div>
                      </div>
                    )}
                  {changeRequest.responses
                    .filter(
                      (response) => response.approver.level?.Id === level.Id
                    )
                    .map((response, j) =>
                      response.approver.user || response.approver.group ? (
                        <div key={j}>
                          <UserPreview
                            user={response.approver.user}
                            group={response.approver.group}
                            response={
                              response.Approved === null &&
                              level.Id !== activeLevel
                                ? undefined
                                : response.Approved
                            }
                            showApproveReject={
                              activeApprover &&
                              changeRequest.ChangeRequestStatus ===
                                Approval_Status_Enum.Pending &&
                              level.Id === activeLevel
                            }
                            submitResponse={submitResponse}
                            updating={updating}
                            comment={response.Comment}
                            hasPermissionToOverride={hasPermissionToOverride}
                          />
                          {!(activeApprover && level.Id === activeLevel) &&
                            response.ModifiedAtTimestamp !==
                              response.CreatedAtTimestamp && (
                              <div className="text-xs text-gray-400 text-right !my-4">
                                {toLocalDateTime(response.ModifiedAtTimestamp)}
                              </div>
                            )}
                        </div>
                      ) : (
                        <div key={j}>
                          <UserPreview
                            owner
                            response={
                              response.Approved === null &&
                              level.Id !== activeLevel
                                ? undefined
                                : response.Approved
                            }
                            showApproveReject={
                              activeApprover &&
                              changeRequest.ChangeRequestStatus ===
                                Approval_Status_Enum.Pending &&
                              level.Id === activeLevel
                            }
                            submitResponse={submitResponse}
                            updating={updating}
                            comment={response.Comment}
                            hasPermissionToOverride={hasPermissionToOverride}
                          />
                          {!(activeApprover && level.Id === activeLevel) &&
                            response.ModifiedAtTimestamp !==
                              response.CreatedAtTimestamp && (
                              <div className="text-xs text-gray-400 text-right !my-4">
                                {toLocalDateTime(response.ModifiedAtTimestamp)}
                              </div>
                            )}
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          ))}
          {responsesWithNoLevel.length > 0 && (
            <div className={'mb-4'}>
              <h5>No Level</h5>
              <div className={'flex flex-col gap-3'}>
                {responsesWithNoLevel.map((response, i) =>
                  response.approver.user ? (
                    <UserPreview
                      key={i}
                      user={response.approver.user}
                      response={
                        response.Approved === null
                          ? undefined
                          : response.Approved
                      }
                    />
                  ) : (
                    <UserPreview
                      key={i}
                      owner
                      response={
                        response.Approved === null
                          ? undefined
                          : response.Approved
                      }
                    />
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <ChangeRequestOverrideModal
        changeRequestId={selectedChangeRequest.Id}
        onDismiss={() => {
          setShowChangeRequestOverrideModal(false);
        }}
        visible={showChangeRequestOverrideModal}
      />
    </HelpPanel>
  );
};
