import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DeleteModal from '@/components/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  useDeleteDepartmentTypesMutation,
  useGetDepartmentsQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { DepartmentsTableFields, useGetCollectionTableProps } from './config';
import DepartmentTypeModal from './DepartmentTypeModal';

const DepartmentsTab: FC = () => {
  useI18NSummaryHelpContent('departments.help');
  const { t } = useTranslation(['common'], { keyPrefix: 'departments' });
  const { t: st } = useTranslation(['common']);
  const { addNotification } = useNotifications();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedDepartmentTypes, setSelectedDepartmentTypes] = useState<
    DepartmentsTableFields[]
  >([]);
  const [selectedDepartmentTypeId, setSelectedDepartmentTypeId] = useState<
    string | undefined
  >(undefined);

  const { data, loading, refetch } = useGetDepartmentsQuery({
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const tableProps = useGetCollectionTableProps(
    data?.department_type ?? [],
    (departmentType) => {
      setSelectedDepartmentTypeId(departmentType.DepartmentTypeId);
      setIsEditOpen(true);
    }
  );

  const [deleteDepartmentTypes, deleteResult] =
    useDeleteDepartmentTypesMutation({
      update: (cache) => {
        evictField(cache, 'department_type');
      },
    });

  const handleDepartmentTypeModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleDepartmentTypeModalClose = () => {
    setSelectedDepartmentTypeId(undefined);
    setIsEditOpen(false);
    refetch();
  };

  const onDelete = useDeleteResultNotification({
    entityName: t('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteDepartmentTypes({
        variables: {
          DepartmentTypeIds: selectedDepartmentTypes.map(
            (s) => s.DepartmentTypeId
          ),
        },
      });
      setSelectedDepartmentTypes([]);
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  return (
    <>
      <Table
        {...tableProps}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    formAction="none"
                    variant="normal"
                    disabled={!selectedDepartmentTypes.length}
                    onClick={() => setIsDeleteModalVisible(true)}
                  >
                    {t('delete')}
                  </Button>
                  <Button
                    variant="primary"
                    formAction="none"
                    onClick={handleDepartmentTypeModalOpen}
                  >
                    {t('add_button')}
                  </Button>
                  <Button iconName="download" onClick={tableProps.exportToCsv}>
                    {st('export')}
                  </Button>
                </SpaceBetween>
              }
            >
              {t('departmentsTableTitle')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        selectionType="multi"
        selectedItems={selectedDepartmentTypes}
        onSelectionChange={({ detail }) => {
          setSelectedDepartmentTypes(detail.selectedItems);
        }}
        trackBy="DepartmentTypeId"
      />
      {isEditOpen && (
        <DepartmentTypeModal
          id={selectedDepartmentTypeId}
          onDismiss={handleDepartmentTypeModalClose}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setSelectedDepartmentTypeId(undefined);
          setSelectedDepartmentTypes([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {t('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default DepartmentsTab;
