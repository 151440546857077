import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledInput from '@/components/Form/ControlledInput';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FormRow from '@/components/Form/Form/FormRow';
import TagSelector from '@/components/Form/TagSelector';
import { Contributor_Type_Enum } from '@/generated/graphql';

import { QuestionnaireTemplateFormData } from './questionnaireTemplateSchema';

interface Props {
  readOnly?: boolean;
}

export const QuestionnaireTemplateFormFields = ({ readOnly }: Props) => {
  const { control } = useFormContext<QuestionnaireTemplateFormData>();
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_templates.fields',
  });

  return (
    <CustomisableForm>
      <ControlledInput
        key={'title'}
        name={'title'}
        label={st('title')}
        description={st('title_help')}
        placeholder={st('title_placeholder')}
        control={control}
        forceRequired
      />

      <ControlledTextarea
        key={'description'}
        name={'description'}
        label={st('description')}
        description={st('description_help')}
        placeholder={st('description_placeholder')}
        control={control}
        forceRequired
      />

      <FormRow key="owners">
        <ControlledGroupAndUserContributorMultiSelect
          forceRequired={true}
          control={control}
          includeGroups={true}
          inheritedContributorsName="ancestorContributors"
          label={st('owner')}
          name="Owners"
          description={st('owner_help')}
          placeholder={st('owner_placeholder')}
          disabled={readOnly}
          contributorType={Contributor_Type_Enum.Owner}
        />
      </FormRow>

      <FormRow key="contributors">
        <ControlledGroupAndUserContributorMultiSelect
          key="contributors"
          control={control}
          includeGroups={true}
          inheritedContributorsName="ancestorContributors"
          label={st('contributor')}
          name="Contributors"
          description={st('contributor_help')}
          placeholder={st('contributor_placeholder')}
          disabled={readOnly}
          contributorType={Contributor_Type_Enum.Contributor}
        />
      </FormRow>

      <FormRow size="xl" key="tags">
        <TagSelector disabled={readOnly} name="tags" control={control} />
      </FormRow>

      <DepartmentSelector
        key="departments"
        disabled={readOnly}
        name="departments"
        control={control}
      />
    </CustomisableForm>
  );
};
