import { SpaceBetween } from '@cloudscape-design/components';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import _ from 'lodash';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Appetite_Type_Enum,
  namedOperations,
  Parent_Type_Enum,
  useGetAppetitesByParentIdQuery,
  useGetLatestComplianceMonitoringAssessmentRiskAssessmentResultsByRiskIdQuery,
  useGetLatestImpactRatingsForRatedImpactsByRatedItemIdQuery,
  useGetLatestInternalAuditReportRiskAssessmentResultsByRiskIdQuery,
  useGetRiskByIdQuery,
  useUpdateRiskMutation,
} from 'src/generated/graphql';
import AssessmentResultModal from 'src/pages/assessments/modals/AssessmentResultModal';
import { getPerformanceRatingFromRatingAndAppetite } from 'src/pages/impacts/ratings/performanceCalculation';
import RiskForm from 'src/pages/risks/forms/RiskForm';
import {
  defaultValues,
  RiskFormDataFields,
} from 'src/pages/risks/forms/riskSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/Permission';

import { contributorUpdateFields, ownerUpdateFields } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import LatestRatingsPreview, {
  LatestRiskRatingsPreview,
} from '@/components/LatestRatingsPreview';
import { ResultProps } from '@/components/LatestRatingsPreview/LatestRatingsPreview';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { evictField } from '@/utils/graphqlUtils';

import { AssessmentTypeEnum } from '../../../../assessments/useAssessmentTypeConfig';
import ImpactRatingModal from '../../../../impacts/ratings/ImpactRatingModal';
import { getActiveAppetites } from '../appetites/useLabelledFields';

const Tab: FC = () => {
  useI18NSummaryHelpContent('risks.help');
  const { t } = useTranslation('common');
  const riskId = useGetGuidParam('riskId');
  const navigate = useNavigate();
  const { data, error } = useGetRiskByIdQuery({
    variables: { _eq: riskId },
  });
  if (error) {
    throw error;
  }
  const [selectedAssessmentMode, setSelectedAssessmentMode] =
    useState<AssessmentTypeEnum>('rating');
  const [selectedAssessmentResultId, setSelectedAssessmentResultId] = useState<
    string | undefined
  >();
  const [selectedImpactRatingId, setSelectedImpactRatingId] = useState<
    string | undefined
  >();
  const [showAssessmentResultModal, setShowAssessmentResultModal] =
    useState<boolean>(false);
  const [showImpactRatingModal, setShowImpactRatingModal] =
    useState<boolean>(false);
  const risk = data?.risk[0];
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    risk
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    risk
  );
  const skipInternalAudit = !internalAuditEnabled || !canViewInternalAudit;
  const skipComplianceMonitoring =
    !complianceMonitoringEnabled || !canViewCompliance;

  const { data: complianceMonitoringResults } =
    useGetLatestComplianceMonitoringAssessmentRiskAssessmentResultsByRiskIdQuery(
      {
        variables: {
          RiskId: riskId,
        },
        skip: skipComplianceMonitoring,
      }
    );
  const { data: internalAuditResults } =
    useGetLatestInternalAuditReportRiskAssessmentResultsByRiskIdQuery({
      variables: {
        RiskId: riskId,
      },
      skip: skipInternalAudit,
    });

  const { data: impactRatingResults } =
    useGetLatestImpactRatingsForRatedImpactsByRatedItemIdQuery({
      variables: {
        RatedItemId: riskId,
      },
    });
  const { data: appetiteData } = useGetAppetitesByParentIdQuery({
    variables: {
      parentId: riskId,
    },
  });
  const activeAppetiteIds = getActiveAppetites(appetiteData?.appetite);
  const activeAppetites = appetiteData?.appetite.filter((a) =>
    activeAppetiteIds.includes(a.Id)
  );

  const canEditRisk = useHasPermission('update:risk', risk);
  const [updateRisk] = useUpdateRiskMutation({
    update: (cache) => {
      evictField(cache, 'risk');
      evictField(cache, 'risk_score');
    },
    refetchQueries: [
      namedOperations.Query.getRiskById,
      namedOperations.Query.getRisksByTier,
    ],
  });

  const onSave = async (data: RiskFormDataFields) => {
    if (!risk) {
      throw new Error('Missing risk');
    }
    await updateRisk({
      variables: {
        ...data,
        Id: risk.Id,
        ParentRiskId: data.ParentRiskId || undefined,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...contributorUpdateFields(data, risk.Id),
        ...ownerUpdateFields(data, risk.Id),
        TagTypeIds: data.TagTypeIds,
        DepartmentTypeIds: data.DepartmentTypeIds,
      },
    });
  };
  const onDismiss = () => navigate(-1);

  return (
    <>
      <RiskForm
        parentRiskNode={risk?.parentNode}
        onSave={onSave}
        onDismiss={onDismiss}
        values={{
          ...defaultValues,
          ...risk,
          Description: risk?.Description ?? '',
          Tier: risk?.Tier as 1 | 2 | 3,
          Owners: getOwners(risk),
          Contributors: getContributors(risk),
          ancestorContributors: risk?.ancestorContributors ?? [],
        }}
        aside={
          <SpaceBetween size="m">
            <LatestRiskRatingsPreview
              ratingsTitle={t('ratings.riskRatingSubheading')}
              riskId={riskId}
              onClick={(id) => {
                setSelectedAssessmentMode('rating');
                setSelectedAssessmentResultId(id);
                setShowAssessmentResultModal(true);
              }}
            />
            {complianceMonitoringResults &&
              (complianceMonitoringResults.controlled.length > 0 ||
                complianceMonitoringResults.uncontrolled.length > 0) && (
                <LatestRatingsPreview
                  ratingsTitle={t('ratings.complianceRatingSubheading')}
                  assessmentResults={[
                    ...complianceMonitoringResults.controlled.map<ResultProps>(
                      (c) => ({
                        id: c.Id,
                        title: t('assessmentResults.controlTypes.controlled'),
                        rating: c.Rating,
                        ratingType: 'risk_controlled',
                        completionDate: c.TestDate,
                      })
                    ),
                    ...complianceMonitoringResults.uncontrolled.map<ResultProps>(
                      (c) => ({
                        id: c.Id,
                        title: t('assessmentResults.controlTypes.uncontrolled'),
                        rating: c.Rating,
                        ratingType: 'risk_uncontrolled',
                        completionDate: c.TestDate,
                      })
                    ),
                  ]}
                  onClick={(id) => {
                    setSelectedAssessmentMode(
                      'compliance_monitoring_assessment'
                    );
                    setSelectedAssessmentResultId(id);
                    setShowAssessmentResultModal(true);
                  }}
                />
              )}
            {internalAuditResults &&
              (internalAuditResults.controlled.length > 0 ||
                internalAuditResults.uncontrolled.length > 0) && (
                <LatestRatingsPreview
                  ratingsTitle={t('ratings.internalAuditRatingSubheading')}
                  assessmentResults={[
                    ...internalAuditResults.controlled.map<ResultProps>(
                      (c) => ({
                        id: c.Id,
                        title: t('assessmentResults.controlTypes.controlled'),
                        rating: c.Rating,
                        ratingType: 'risk_controlled',
                        completionDate: c.TestDate,
                      })
                    ),
                    ...internalAuditResults.uncontrolled.map<ResultProps>(
                      (c) => ({
                        id: c.Id,
                        title: t('assessmentResults.controlTypes.uncontrolled'),
                        rating: c.Rating,
                        ratingType: 'risk_uncontrolled',
                        completionDate: c.TestDate,
                      })
                    ),
                  ]}
                  onClick={(id) => {
                    setSelectedAssessmentMode('internal_audit_report');
                    setSelectedAssessmentResultId(id);
                    setShowAssessmentResultModal(true);
                  }}
                />
              )}
            {impactRatingResults && impactRatingResults.impact.length > 0 && (
              <>
                <LatestRatingsPreview
                  ratingsTitle={''}
                  assessmentResults={[
                    ...impactRatingResults.impact
                      .slice(0, 1)
                      .filter((c) => c.ratings.length > 0)
                      .map<ResultProps>((c) => ({
                        id: c.ratings[0].Id,
                        title: t('impactRatings.fields.Likelihood'),
                        rating: c.ratings[0].Likelihood,
                        ratingType: 'likelihood',
                        completionDate: c.ratings[0].TestDate,
                      })),
                  ]}
                  onClick={(id) => {
                    setSelectedImpactRatingId(id);
                    setShowImpactRatingModal(true);
                  }}
                />
                <LatestRatingsPreview
                  ratingsTitle={t('impacts.tab_title')}
                  assessmentResults={[
                    ...impactRatingResults.impact
                      .filter((c) => c.ratings.length > 0)
                      .map((c) => ({
                        ...c,
                        performanceRating:
                          getPerformanceRatingFromRatingAndAppetite({
                            rating: c.ratings[0].Rating,
                            impactAppetite: activeAppetites?.find(
                              (a) =>
                                a.AppetiteType === Appetite_Type_Enum.Impact &&
                                a.parents.filter(
                                  (p) => p.impact?.Id === c.ratings[0].ImpactId
                                ).length > 0
                            )?.ImpactAppetite,
                          }),
                      }))
                      .filter((c) => !_.isNil(c.performanceRating))
                      .map<ResultProps>((c) => ({
                        id: c.ratings[0].Id,
                        title: c.Name,
                        rating: c.performanceRating!,
                        ratingType: 'impact_performance_rating',
                        completionDate: c.ratings[0].TestDate,
                      })),
                  ]}
                  onClick={(id) => {
                    setSelectedImpactRatingId(id);
                    setShowImpactRatingModal(true);
                  }}
                />
              </>
            )}
          </SpaceBetween>
        }
        readOnly={!canEditRisk}
        riskId={risk?.Id}
      />
      {showAssessmentResultModal && (
        <AssessmentResultModal
          i18n={t('assessmentResults')}
          id={selectedAssessmentResultId}
          resultType={Parent_Type_Enum.RiskAssessmentResult}
          onDismiss={() => setShowAssessmentResultModal(false)}
          assessmentMode={selectedAssessmentMode}
        />
      )}
      {showImpactRatingModal && (
        <ImpactRatingModal
          impactRatingId={selectedImpactRatingId}
          onDismiss={() => setShowImpactRatingModal(false)}
          onSaving={() => Promise.resolve()}
        />
      )}
    </>
  );
};

export default Tab;
