import { FC } from 'react';

import { useGetComplianceMonitoringAssessmentByIdQuery } from '@/generated/graphql';

type Props = {
  assessmentId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ assessmentId }) => {
  if (!assessmentId) {
    throw new Error('Missing assessmentId param');
  }
  const { data } = useGetComplianceMonitoringAssessmentByIdQuery({
    variables: {
      Id: assessmentId,
    },
  });

  return <>{data?.compliance_monitoring_assessment?.[0]?.Title || ''}</>;
};

export default Breadcrumb;
