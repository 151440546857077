import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { MyItemFields, useGetTypes } from './config';

type Props = {
  items: readonly MyItemFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const MyItemsSummary: FC<Props> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const types = useGetTypes();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'myItems.dashboard',
  });
  const policyModuleEnabled = useIsFeatureVisibleToOrg('policy');
  const complianceModuleEnabled = useIsFeatureVisibleToOrg('compliance');

  if (!policyModuleEnabled) {
    delete types['document'];
  }
  if (!complianceModuleEnabled) {
    delete types['obligation'];
  }

  const typeLabels = Object.values(types);

  return (
    <RegisterDashboard>
      {typeLabels.map((label) => (
        <FilterPropertyDashboardItem
          key={label}
          onClick={onFilterQueryChanged}
          tableFilterQuery={propertyFilterQuery}
          itemFilterQuery={{
            tokens: [
              {
                propertyKey: 'Type',
                value: label,
                operator: '=',
              },
            ],
            operation: 'and',
          }}
          title={label}
          items={items ?? []}
          filteringProperties={filteringProperties}
        />
      ))}
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
