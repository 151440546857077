import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import {
  defaultValues,
  IssueFormDataFields,
} from 'src/pages/issues/update/forms/issueSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { useHasPermission } from 'src/rbac/Permission';

import {
  contributorUpdateFields,
  departmentInsertInputBuilder,
  ownerUpdateFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  Parent_Type_Enum,
  useGetIssueByIdQuery,
  useUpdateIssueMutation,
} from '@/generated/graphql';

import IssueForm from '../../forms/IssueForm';

const Tab: FC = () => {
  useI18NSummaryHelpContent('issues.help');
  const issueId = useGetGuidParam('issueId');
  const { t } = useTranslation(['common']);
  const { data, refetch, error } = useGetIssueByIdQuery({
    variables: {
      _eq: issueId,
    },
  });
  if (error) {
    throw error;
  }
  const navigate = useNavigate();
  const issue = data?.issue?.[0];
  const canEditIssue = useHasPermission('update:issue', issue);
  const canCreateIssue = useHasPermission('insert:issue', issue);
  const { updateFiles } = useFileUpdate();

  const values: IssueFormDataFields = {
    ...defaultValues,
    ...issue,
    Owners: getOwners(issue),
    Contributors: getContributors(issue),
    ancestorContributors: issue?.ancestorContributors ?? [],
  };

  const [updateIssue] = useUpdateIssueMutation();
  const canModify = issue ? canEditIssue : canCreateIssue;

  const onSave = async (data: IssueFormDataFields) => {
    const { files, newFiles } = data;
    if (!issue) {
      throw new Error('Missing issue');
    }
    await updateIssue({
      variables: {
        ...data,
        OriginalTimestamp: issue.ModifiedAtTimestamp,
        Id: issue.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        tags: tagInsertInputBuilder(data.TagTypeIds, issue.Id),
        departments: departmentInsertInputBuilder(
          data.DepartmentTypeIds,
          issue.Id
        ),
        ...contributorUpdateFields(data, issue.Id),
        ...ownerUpdateFields(data, issue.Id),
      },
    });
    await updateFiles({
      parentType: Parent_Type_Enum.Issue,
      parentId: issue?.Id,
      newFiles,
      originalFiles: issue?.files,
      selectedFiles: files,
    });
    await refetch();
  };

  const onDismiss = () => {
    return navigate(-1);
  };

  return (
    <IssueForm
      readOnly={!canModify}
      header={t('details')}
      onDismiss={onDismiss}
      onSave={onSave}
      values={values}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default Tab;
