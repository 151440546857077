import { CustomAttributeDataSchema } from 'src/schemas/global';
import { z } from 'zod';

import {
  Questionnaire_Template_Version_Status_Enum,
  Version_Status_Enum,
} from '@/generated/graphql';

export const QuestionnaireTemplateVersionFormSchema = z
  .object({
    Version: z.string().min(1, { message: 'Required' }),
    Status: z.nativeEnum(Questionnaire_Template_Version_Status_Enum),
    Schema: z.any(),
    UISchema: z.any(),
  })
  .and(CustomAttributeDataSchema);

export type QuestionnaireTemplateVersionFormFieldData = z.infer<
  typeof QuestionnaireTemplateVersionFormSchema
>;

export const defaultValues: QuestionnaireTemplateVersionFormFieldData = {
  Version: '0.1',
  Status: Version_Status_Enum.Draft,
  Schema: {},
  UISchema: {},
};
