import { TabsProps } from '@cloudscape-design/components';
import i18n from '@risksmart-app/components/providers/i18n';
import { Trans, useTranslation } from 'react-i18next';
import ActionsTab from 'src/pages/actions/ActionsTab';
import IssuesTab from 'src/pages/issues/IssueTab';
import LinkedItemsTab from 'src/pages/linked-items/LinkedItemsTab';
import { useHasPermission } from 'src/rbac/Permission';

import { GetObligationByIdQuery, Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { filterEmptyTabs } from '@/utils/tabUtils';

import ControlsTab from '../../../controls/tab/Tab';
import AssessmentTab from './tabs/assessment/Tab';
import DetailsTab from './tabs/details/Tab';
import ImpactTab from './tabs/impact/Tab';

export const useTabs = (
  detailsPath: string,
  obligation?: GetObligationByIdQuery['obligation'][0]
) => {
  const { t } = useTranslation(['common']);
  const linkedItemsEnabled = useIsFeatureVisibleToOrg('linked-items');
  const canViewLinkedItems = useHasPermission('read:linked_item', obligation);

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: obligation && <DetailsTab obligation={obligation} />,
      href: detailsPath,
    },
    {
      label: i18n.format(t('impact'), 'capitalize'),
      id: 'impact',
      content: obligation && <ImpactTab obligation={obligation} />,
      href: `${detailsPath}/impact`,
    },
    {
      label: t('controls.tab_title'),
      id: 'controls',
      content: obligation && <ControlsTab parent={obligation} />,
      href: `${detailsPath}/controls`,
    },
    {
      label: i18n.format(t('rating_other'), 'capitalize'),
      id: 'ratings',
      content: obligation && <AssessmentTab parent={obligation} />,
      href: `${detailsPath}/ratings`,
    },
    {
      label: t('actions.tab_title'),
      id: 'actions',
      content: obligation && <ActionsTab parent={obligation} />,
      href: `${detailsPath}/actions`,
    },
    {
      label: t('issues.tab_title'),
      id: 'issues',
      content: obligation && <IssuesTab parent={obligation} />,
      href: `${detailsPath}/issues`,
    },
  ];

  if (linkedItemsEnabled && canViewLinkedItems) {
    tabs.push({
      label: <Trans>linkedItems.tab_title</Trans>,
      id: 'linkedItems',
      content: obligation && (
        <LinkedItemsTab
          parent={obligation}
          parentType={Parent_Type_Enum.Obligation}
        />
      ),
      href: `${detailsPath}/linked-items`,
    });
  }

  return filterEmptyTabs(tabs);
};
