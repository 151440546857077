
    import './styles.scoped.css';
    export default {
  "column-layout": "awsui_column-layout_vvxn7_e3jbb_177",
  "grid": "awsui_grid_vvxn7_e3jbb_212",
  "grid-no-gutters": "awsui_grid-no-gutters_vvxn7_e3jbb_218",
  "grid-variant-text-grid": "awsui_grid-variant-text-grid_vvxn7_e3jbb_222",
  "grid-breakpoint-default": "awsui_grid-breakpoint-default_vvxn7_e3jbb_230",
  "grid-columns-1": "awsui_grid-columns-1_vvxn7_e3jbb_233",
  "grid-breakpoint-xxs": "awsui_grid-breakpoint-xxs_vvxn7_e3jbb_233",
  "grid-breakpoint-xs": "awsui_grid-breakpoint-xs_vvxn7_e3jbb_236",
  "grid-columns-2": "awsui_grid-columns-2_vvxn7_e3jbb_239",
  "grid-columns-3": "awsui_grid-columns-3_vvxn7_e3jbb_245",
  "grid-columns-4": "awsui_grid-columns-4_vvxn7_e3jbb_251",
  "grid-vertical-borders": "awsui_grid-vertical-borders_vvxn7_e3jbb_267",
  "grid-horizontal-borders": "awsui_grid-horizontal-borders_vvxn7_e3jbb_300"
};
  