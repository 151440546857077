import { FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserOrGroup } from 'src/schemas/global';

import { FormField } from '@/components/Form/Form/FormField';
import { Filter } from '@/components/UserSearchPreferences/useGroupAndUserOptions';
import { GetUserGroupsQuery, GetUsersQuery } from '@/generated/graphql';

import UserSearchPreferences from '../../UserSearchPreferences/UserSearchPreferences';
import { getSelectedOption } from '../ControlledSelect/ControlledSelect';
import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import HiddenOptionSelect from '../Select/HideableSelect';
import { ControlledBaseProps } from '../types';
import styles from './style.module.scss';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  constraintText?: React.ReactNode;
  includeGroups: boolean;
  testId?: string;
  userFilter?: Filter<GetUsersQuery['user'][number]>;
  groupFilter?: Filter<GetUserGroupsQuery['user_group'][number]>;
}

export const ControlledGroupAndUserSelect = <T extends FieldValues>({
  control,
  constraintText,
  name,
  label,
  forceRequired,
  addEmptyOption,
  defaultRequired,
  allowDefaultValue,
  includeGroups,
  userFilter,
  groupFilter,
  testId,
  ...props
}: Props<T>) => {
  const { t } = useTranslation(['common']);
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <UserSearchPreferences
      includeGroups={includeGroups}
      userFilter={userFilter}
      groupFilter={groupFilter}
      addEmptyOption={addEmptyOption}
    >
      {({
        preferencesButton,
        statusType,
        options,
        onBlur: onBlurPreferences,
        onChange: onChangePreferences,
      }) => (
        <Controller
          defaultRequired={defaultRequired}
          forceRequired={forceRequired}
          allowDefaultValue={allowDefaultValue}
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => {
            const option: UserOrGroup | undefined = value;

            const currentSelectedOption = getSelectedOption(
              option?.value,
              options
            );

            return (
              <FormField
                constraintText={constraintText}
                label={label}
                errorText={error?.message}
                stretch
                testId={testId}
                previewChangesFormatter={(val: string | undefined | null) =>
                  getSelectedOption(val, options)?.label ?? '-'
                }
              >
                <div className="flex flex-row">
                  <div className="flex-grow">
                    <HiddenOptionSelect
                      statusType={statusType}
                      className={styles.root}
                      selectedOption={currentSelectedOption}
                      placeholder={t('enterAValue')}
                      empty={t('noMatchedFound')}
                      {...props}
                      onChange={(e) => {
                        const selectedOption = e.detail.selectedOption;
                        let userOrGroup: UserOrGroup | undefined = undefined;
                        if ('type' in selectedOption) {
                          userOrGroup = {
                            value: (e.detail.selectedOption as UserOrGroup)
                              .value,
                            type: (e.detail.selectedOption as UserOrGroup).type,
                          };
                        }
                        if (userOrGroup) {
                          onChangePreferences([userOrGroup]);
                        }
                        onChange?.(userOrGroup || null);
                      }}
                      onBlur={() => {
                        onBlurPreferences();
                        onBlur?.();
                      }}
                      options={options}
                      disabled={readOnly || props.readOnly || props.disabled}
                    />
                  </div>
                  {preferencesButton}
                </div>
              </FormField>
            );
          }}
        />
      )}
    </UserSearchPreferences>
  );
};
