import { TableProps } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { GetConsequencesByParentIssueIdQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

export type ConsequencesFields =
  GetConsequencesByParentIssueIdQuery['consequence'][0];

export function useConsequencesColumnDefinitions(
  onEdit: (consequences: ConsequencesFields) => void
) {
  const { getByValue } = useRating('criticality');
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'consequences.columns',
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'consequences',
  });
  const consequenceColumnDefinitions: TableProps<ConsequencesFields>['columnDefinitions'] =
    [
      {
        id: 'title',
        header: st('title'),
        cell: (item) => (
          <Link variant="secondary" href="#" onFollow={() => onEdit(item)}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        maxWidth: MAX_COL_WIDTH,
        isRowHeader: true,
      },
      {
        id: 'costType',
        cell: (data) => (data.CostType ? t('costType')[data.CostType] : '-'),
        header: st('costType'),
        sortingField: 'CostType',
        maxWidth: MAX_COL_WIDTH,
      },
      {
        id: 'costValue',
        cell: (data) => data.CostValue,
        header: st('costValue'),
        sortingField: 'CostValue',
        maxWidth: MAX_COL_WIDTH,
      },
      {
        id: 'criticality',
        header: st('criticality'),
        cell: (item) => {
          return <SimpleRatingBadge rating={getByValue(item.Criticality)} />;
        },
        sortingField: 'Criticality',
        maxWidth: MAX_COL_WIDTH,
      },
      {
        id: 'description',
        header: st('description'),
        cell: (item) => item.Description,
        sortingField: 'Description',
        maxWidth: MAX_COL_WIDTH,
      },
    ];

  return consequenceColumnDefinitions;
}
