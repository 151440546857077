import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Link from '@/components/Link';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import {
  createQuestionnaireInviteUrl,
  questionnaireTemplateDetailsUrl,
} from '@/utils/urls';

import { Permission } from '../../../../../rbac/Permission';
import {
  QuestionnaireInviteFields,
  QuestionnaireInviteRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig =
  (): TableFields<QuestionnaireInviteRegisterFields> => {
    const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
    const { t: ct } = useTranslation(['common'], {
      keyPrefix: 'questionnaire_invite.columns',
    });

    return {
      Questionnaire: {
        header: ct('questionnaire'),
        cell: (item) => (
          <Link
            variant="secondary"
            href={questionnaireTemplateDetailsUrl(item.Id)}
          >
            {item.Questionnaire}
          </Link>
        ),
      },
      QuestionnaireVersion: {
        header: ct('version'),
        cell: (item) => item.QuestionnaireVersion,
      },
      UserEmail: {
        header: ct('userEmail'),
      },
      StartDate: dateColumn(ct('startDate'), 'StartDate'),
      ExpiresAt: dateColumn(ct('expireBy'), 'ExpiresAt'),
      CreatedAtTimestamp: dateColumn(t('created_on'), 'CreatedAtTimestamp'),
      ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
      CreatedByFriendlyName: {
        header: t('created_by_username'),
      },
      ModifiedByFriendlyName: {
        header: t('updated_by_username'),
      },
    };
  };

export const useGetQuestionnaireTemplatesTableProps = (
  records: QuestionnaireInviteFields[] | undefined,
  thirdPartyId?: string
): UseGetTablePropsOptions<QuestionnaireInviteRegisterFields> => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_invite',
  });
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(() => {
    return {
      data,
      fields,
      customAttributeSchema: null,
      entityLabel: st('entity_name'),
      emptyCollectionAction: (
        <Permission permission="insert:third_party">
          <Button href={createQuestionnaireInviteUrl(thirdPartyId ?? '')}>
            {st('create_new_button')}
          </Button>
        </Permission>
      ),
      enableFiltering: true,
      initialColumns: [
        'Questionnaire',
        'QuestionnaireVersion',
        'UserEmail',
        'StartDate',
        'ExpiresAt',
      ],
      storageKey: 'QuestionnaireInvitesTable-PreferencesV1',
    };
  }, [st, data, fields, thirdPartyId]);
};

export const useGetCollectionTableProps = (
  records: QuestionnaireInviteFields[] | undefined,
  thirdPartyId?: string
): TablePropsWithActions<QuestionnaireInviteRegisterFields> => {
  const props = useGetQuestionnaireTemplatesTableProps(records, thirdPartyId);

  return useGetTableProps(props);
};
