import {
  PropertyFilterQuery,
  SortingState,
} from '@cloudscape-design/collection-hooks/cjs/interfaces';
import { useState } from 'react';
import { useStorage } from 'src/state/useStorage';

import { TablePreferences, TablePropsWithActions } from '../types';
import {
  useGetStatelessTableProps,
  UseGetTablePropsOptions,
} from './useGetStatelessTableProps';

export function useGetTablePropsWithoutUrlHash<
  T extends Record<string, unknown>,
>(options: UseGetTablePropsOptions<T>): TablePropsWithActions<T> {
  const [propertyFilter, setPropertyFilter] = useState<PropertyFilterQuery>();
  const [sortingState, setSortingState] = useState<SortingState<T>>();

  const [preferences, setPreferences] = useStorage<
    TablePreferences<T> | undefined
  >(undefined, {
    localStorageKey: options.storageKey,
  });

  return useGetStatelessTableProps({
    ...options,
    sortingState,
    setSortingState,
    propertyFilter,
    setPropertyFilter,
    preferences,
    setPreferences,
  });
}
