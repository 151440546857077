import { JsonSchema7 } from '@jsonforms/core';
import { JsonForms } from '@jsonforms/react';
import { ErrorObject } from 'ajv';
import { FC, useState } from 'react';

import {
  Modal,
  SpaceBetween,
  TextContent,
} from '../../theme/risksmart-themed/components';
import Button from '../Button';
import DeleteButton from '../DeleteButton';
import DeleteModal from '../DeleteModal';
import rendererRegistry from '../renderers/registry';
import {
  SectionData,
  sectionSchema,
  sectionUISchema,
} from './form-configs/section';
import {
  CustomUISchema,
  CustomUISchemaElement,
  useFormBuilderContext,
} from './useFormBuilderContext';
import { useFormState } from './useFormState';

export type EditFormSectionModalProps = {
  action: 'add' | 'edit';
  sectionData: SectionData;
  setSectionData: (sectionData: SectionData) => void;
  isEditingSection: boolean;
  setIsEditingSection: (isEditingSection: boolean) => void;
  initialSectionData: SectionData;
  uiSchema: CustomUISchema | CustomUISchemaElement;
};

export const FormSectionModal: FC<EditFormSectionModalProps> = ({
  action,
  sectionData,
  setSectionData,
  isEditingSection,
  setIsEditingSection,
  initialSectionData,
  uiSchema,
}) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | undefined>([]);

  const {
    isFormCustomisable,
    uiSchema: fullUISchema,
    schema,
    setSchema,
    setUISchema,
  } = useFormBuilderContext();

  const { setIsFormDirty } = useFormState();

  const uuid = `section_${crypto.randomUUID()}`;

  const resetModal = () => {
    setSectionData(initialSectionData);
    setIsEditingSection(false);
    setIsFormDirty(false);
  };

  const handleEditSectionCancel = () => {
    resetModal();
  };

  const handleAddNewSection = () => {
    setUISchema({
      ...fullUISchema,
      elements: [
        ...fullUISchema.elements,
        {
          type: 'Group',
          label: sectionData.sectionTitle,
          id: uuid,
          elements: [],
        },
      ],
    });

    resetModal();
  };

  const handleUpdateSection = () => {
    const modifiedElementsCopy = fullUISchema.elements.map(
      (element: CustomUISchemaElement) => {
        if (element.id === uiSchema?.id) {
          return {
            ...element,
            label: sectionData.sectionTitle,
          };
        }

        return element;
      }
    );

    setUISchema({
      ...fullUISchema,
      elements: modifiedElementsCopy,
    });

    resetModal();
  };

  const handleSaveSection = () => {
    if (errors?.length) {
      setIsFormDirty(true);

      return;
    }

    if (action === 'add') {
      handleAddNewSection();
    }

    if (action === 'edit') {
      handleUpdateSection();
    }
  };

  const handleDeleteSection = () => {
    if (!uiSchema?.id) {
      console.log('No id found in uiSchema');

      return;
    }

    if (!schema?.properties) {
      console.log('No properties found in schema');

      return;
    }

    if (!uiSchema?.elements?.length) {
      console.log('No elements found in uiSchema');

      return;
    }

    const modifiedUISchemaElementsCopy = fullUISchema.elements.filter(
      (element: CustomUISchemaElement) => element.id !== uiSchema.id
    );

    setUISchema({
      ...fullUISchema,
      elements: modifiedUISchemaElementsCopy,
    });

    const modifiedSchemaPropertiesCopy = Object.keys(
      schema.properties || {}
    ).reduce(
      (acc, key) => {
        console.log({ key, id: uiSchema.id });

        if (schema?.properties === undefined) {
          return acc;
        }

        const entity = schema.properties![key] as {
          [property: string]: JsonSchema7 & { parentId?: string };
        };

        if (entity?.parentId !== uiSchema.id) {
          acc![key] = schema.properties![key];
        }

        return acc;
      },
      {} as JsonSchema7['properties']
    );

    setSchema({
      ...schema,
      properties: { ...modifiedSchemaPropertiesCopy },
    });

    resetModal();
  };

  return (
    <>
      {isEditingSection ? (
        <>
          <Modal
            onDismiss={resetModal}
            visible={isEditingSection}
            header={action === 'add' ? 'Add Section' : 'Edit Section'} // TODO: Translation
            footer={
              <div className={'flex justify-between'}>
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    onClick={handleSaveSection}
                    formAction={'submit'}
                  >
                    Save {/* TODO: Translation */}
                  </Button>
                  <Button variant="normal" onClick={handleEditSectionCancel}>
                    Cancel {/* TODO: Translation */}
                  </Button>
                </SpaceBetween>
                {action === 'edit' ? (
                  <DeleteButton onClick={() => setIsDeleteModalVisible(true)}>
                    Delete {/* TODO: Translation */}
                  </DeleteButton>
                ) : null}
              </div>
            }
          >
            <JsonForms
              data={sectionData}
              readonly={!isFormCustomisable}
              schema={sectionSchema}
              uischema={sectionUISchema}
              renderers={rendererRegistry}
              onChange={({ data, errors }) => {
                const formattedErrors = errors?.map((error) => ({
                  ...error,
                  dataPath: '',
                })) as ErrorObject[] | undefined;

                setErrors(formattedErrors);
                setSectionData(data);
              }}
              validationMode={'ValidateAndShow'}
            />
          </Modal>
          <DeleteModal
            loading={false}
            isVisible={isDeleteModalVisible}
            onDelete={handleDeleteSection}
            onDismiss={() => setIsDeleteModalVisible(false)}
            header={'Delete'}
          >
            <TextContent>
              <p>Are you sure you want to delete this section?</p>
              <p>Any questions within this section will also be deleted.</p>
              <p>This action cannot be undone.</p>
            </TextContent>
          </DeleteModal>
        </>
      ) : null}
    </>
  );
};
