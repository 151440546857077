import ErrorPage from '@risksmart-app/components/ErrorPages/ErrorPage';
import { FC } from 'react';
import { AuthenticatedAppLayout } from 'src/layouts/AuthenticatedAppLayout';

interface Props {}

const Page: FC<Props> = () => {
  return (
    <AuthenticatedAppLayout>
      <ErrorPage />
    </AuthenticatedAppLayout>
  );
};

export default Page;
