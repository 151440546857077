import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useControlTypeLabel } from 'src/pages/assessments/forms/RiskAssessmentResultFormFields';
import { UNRATED } from 'src/pages/controls/lookupData';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { useRiskScore } from '@/hooks/useRiskScore';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { internalAuditReportDetailsUrl } from '@/utils/urls';

import {
  InternalAuditRiskAssessmentResultFlatFields,
  InternalAuditRiskAssessmentResultRegisterFields,
} from './types';
import { useInternalAuditRatingLabelledFields } from './useInternalAuditRatingLabelledFields';

const useGetFieldConfig = (
  onOpenResult: (id: string) => void,
  riskId: string
): TableFields<InternalAuditRiskAssessmentResultRegisterFields> => {
  const { t: at } = useTranslation(['common'], {
    keyPrefix: 'internalAuditReports',
  });
  const { t: ar } = useTranslation(['common'], {
    keyPrefix: 'assessmentResults',
  });
  const { t: rt } = useTranslation(['common'], {
    keyPrefix: 'ratings',
  });
  const getControlTypeLabel = useControlTypeLabel();
  const riskScores = useRiskScore(riskId);
  const { getByValue: getUncontrolledByValue } = useRating('risk_uncontrolled');
  const { getByValue: getControlledByValue } = useRating('risk_controlled');
  const { getByValue: getLikelihoodByValue } = useRating('likelihood');
  const { getByValue: getImpactByValue } = useRating('impact');
  const { getByValue: statusGetByValue } = useRating('assessment_status');

  return useMemo(
    () => ({
      TestDate: dateColumn(rt('columns.TestDate'), 'TestDate', (item) =>
        onOpenResult(item.Id)
      ),
      ParentTitle: {
        header: rt('columns.InternalAuditReportTitle'),
        cell: (item) =>
          item.parents.find((p) => p.internalAuditReport)
            ?.internalAuditReport ? (
            <Link
              variant="secondary"
              href={internalAuditReportDetailsUrl(
                item.parents.find((p) => p.internalAuditReport)!
                  .internalAuditReport!.Id
              )}
            >
              {
                item.parents.find((p) => p.internalAuditReport)
                  ?.internalAuditReport?.Title
              }
            </Link>
          ) : (
            '-'
          ),
        isRowHeader: true,
      },
      ControlTypeLabelled: {
        header: ar('fields.ControlType'),
        cell: (item) => getControlTypeLabel(item.ControlType),
        sortingField: 'ControlType',
      },
      RatingLabelled: {
        header: rt('columns.Rating'),
        sortingField: 'Rating',
        cell: (item) => {
          if (!riskScores.showScore) {
            const result =
              item.ControlType ===
              Risk_Assessment_Result_Control_Type_Enum.Controlled
                ? getControlledByValue(item.Rating)
                : getUncontrolledByValue(item.Rating);

            return <SimpleRatingBadge rating={result ?? UNRATED} />;
          }

          return '-';
        },
      },
      ImpactLabelled: {
        header: rt('columns.Impact'),
        cell: (item) =>
          item.Impact ? (
            <SimpleRatingBadge rating={getImpactByValue(item.Impact)} />
          ) : (
            '-'
          ),
      },
      LikelihoodLabelled: {
        header: rt('columns.Likelihood'),
        cell: (item) =>
          item.Likelihood ? (
            <SimpleRatingBadge rating={getLikelihoodByValue(item.Likelihood)} />
          ) : (
            '-'
          ),
      },
      StatusLabelled: {
        header: rt('columns.InternalAuditReportStatus'),
        cell: (item) => {
          const status = item.parents.find((p) => p.internalAuditReport)
            ?.internalAuditReport?.Status;

          return <SimpleRatingBadge rating={statusGetByValue(status)} />;
        },
      },
      CompletionDate: dateColumn(
        at('columns.CompletionDate'),
        'ActualCompletionDate'
      ),
      NextTestDate: dateColumn(at('columns.NextTestDate'), 'NextTestDate'),
    }),
    [
      ar,
      at,
      getControlTypeLabel,
      getControlledByValue,
      getImpactByValue,
      getLikelihoodByValue,
      getUncontrolledByValue,
      onOpenResult,
      riskScores.showScore,
      rt,
      statusGetByValue,
    ]
  );
};

const useGetInternalAuditRatingTableProps = (
  riskId: string,
  onOpenResult: (id: string) => void,
  records: InternalAuditRiskAssessmentResultFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields[] | null
): UseGetTablePropsOptions<InternalAuditRiskAssessmentResultRegisterFields> => {
  const labelledFields = useInternalAuditRatingLabelledFields(riskId, records);
  const fields = useGetFieldConfig(onOpenResult, riskId);

  return {
    customAttributeSchema: customAttributeSchema,
    data: labelledFields,
    enableFiltering: true,
    entityLabel: 'rating',
    defaultSortingState: {
      sortingColumn: 'TestDate',
      sortingDirection: 'desc',
    },
    initialColumns: [
      'TestDate',
      'ParentTitle',
      'ControlTypeLabelled',
      'RatingLabelled',
      'ImpactLabelled',
      'LikelihoodLabelled',
      'StatusLabelled',
    ],
    storageKey: 'InternalAuditRiskRatingRegisterTable-Preferences',
    fields,
  };
};

export const useGetCollectionTableProps = (
  riskId: string,
  onOpenResult: (id: string) => void,
  records: InternalAuditRiskAssessmentResultFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields[] | null
): TablePropsWithActions<InternalAuditRiskAssessmentResultRegisterFields> => {
  const props = useGetInternalAuditRatingTableProps(
    riskId,
    onOpenResult,
    records,
    customAttributeSchema
  );

  return useGetTablePropsWithoutUrlHash(props);
};
