import { Header } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import Modal from '@risksmart-app/components/Modal';
import { Check } from '@untitled-ui/icons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Attestation_Record_Status_Enum,
  useAttestMutation,
  useGetAttestationStatusQuery,
} from '@/generated/graphql';

import styles from './styles.module.scss';

type Props = {
  parentId: string;
};

export const AttestButton: FC<Props> = ({ parentId }) => {
  const { user, isLoading } = useRisksmartUser();
  const [modalVisible, setModalVisible] = useState(false);
  const { data, loading, refetch } = useGetAttestationStatusQuery({
    variables: { ParentId: parentId, UserId: user!.userId },
    skip: isLoading || !user,
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'attestations',
  });

  const [attest, { loading: mutating }] = useAttestMutation({
    onCompleted: () => refetch(),
  });

  const handleAttest = async () => {
    await attest({ variables: { Id: parentId } });
    setModalVisible(false);
  };

  const attested =
    data?.attestation_record[0]?.AttestationStatus ===
    Attestation_Record_Status_Enum.Attested;

  const notRequired =
    data?.attestation_record[0]?.AttestationStatus ===
      Attestation_Record_Status_Enum.NotRequired ||
    data?.attestation_record[0]?.AttestationStatus ===
      Attestation_Record_Status_Enum.Expired ||
    data?.attestation_record[0] === undefined;

  return (
    <>
      <Button
        disabled={notRequired || attested}
        loading={loading || mutating}
        onClick={() => setModalVisible(true)}
        iconSvg={attested ? <Check viewBox={'0 0 24 24'} /> : null}
        {...{ className: attested ? styles.successButton : '' }}
      >
        {attested ? t('buttonText.attested') : t('buttonText.pending')}
      </Button>
      {modalVisible && (
        <Modal
          visible={true}
          header={<Header>{t('prompt.title')}</Header>}
          onDismiss={() => setModalVisible(false)}
        >
          <p className={'mt-0'}>
            {data?.attestation_record[0]?.config?.PromptText ??
              t('prompt.default')}
          </p>
          <div className="flex flex-row gap-3">
            <Button
              loading={loading || mutating}
              variant="primary"
              onClick={handleAttest}
            >
              {t('prompt.confirm')}
            </Button>
            <Button onClick={() => setModalVisible(false)}>
              {t('prompt.cancel')}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};
