import { Forbidden } from '@risksmart-app/components/errors/errors';
import { Feature } from '@risksmart-app/shared/organisation/Feature';
import { FC, ReactNode } from 'react';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

type Props = {
  children: ReactNode;
  featureFlag: Feature;
  fallback?: ReactNode;
};

const OrgFeatureFlaggedRoute: FC<Props> = ({
  children,
  featureFlag,
  fallback,
}) => {
  const enabled = useIsFeatureVisibleToOrg(featureFlag);

  // still loading
  if (enabled === null) {
    return <></>;
  }

  if (enabled) {
    return children;
  }

  if (fallback) {
    return fallback;
  }

  throw new Forbidden(`Access to ${featureFlag} denied`);
};

export default OrgFeatureFlaggedRoute;
