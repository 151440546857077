import { Auth0ContextInterface } from '@auth0/auth0-react';
import { LayoutLeft } from '@untitled-ui/icons-react';
import clsx from 'clsx';
import { Context, FC } from 'react';

import { CountRenderer } from './Count';
import Logo from './Logo';
import NavigationCollapsed from './NavigationCollapsed';
import { NavItems } from './NavItems';
import { NavItemWithIcon } from './NavItemWithIcon';
import UserMenu from './UserMenu';

export interface Props {
  setNavigationOpen: (open: boolean) => void;
  navigationOpen: boolean;
  logoutUrl: string;
  navItems: NavItemWithIcon[];
  renderCount?: CountRenderer;
  showUserMenu: boolean;
  customLogoUrl?: () => Promise<string>;
  altTheme?: boolean;
  authContext?: Context<Auth0ContextInterface>;
}
export type Icon = { icon?: JSX.Element };

const Navigation: FC<Props> = ({
  navigationOpen,
  setNavigationOpen,
  logoutUrl,
  navItems,
  renderCount,
  showUserMenu,
  customLogoUrl,
  altTheme,
  authContext,
}) => {
  return navigationOpen ? (
    <div
      data-testid="navigation"
      className={clsx('h-screen relative print:hidden', {
        'bg-navy_mid': !altTheme,
        'bg-dark_green': altTheme,
      })}
    >
      <div
        className={clsx(
          'min-h-screen text-white font-sans text-[14px] font-semibold flex flex-col relative',
          {
            'bg-navy_mid': !altTheme,
            'bg-dark_green': altTheme,
          }
        )}
      >
        <div
          className={clsx(
            'py-4 px-6 border-0 border-b border-solid  border-navy_light flex sticky grow-0 z-20 justify-between items-center',
            {
              'bg-navy_mid': !altTheme,
              'bg-dark_green': altTheme,
            }
          )}
          style={{
            top: 0,
          }}
        >
          <Logo small={!navigationOpen} />
          <button className="bg-transparent border-0 hover:bg-navy_light text-grey500 flex items-center justify-center text-center p-1 rounded-md cursor-pointer w-[40px] h-[40px] rs-nav-button -right-3 relative">
            <LayoutLeft onClick={() => setNavigationOpen(!navigationOpen)} />
          </button>
        </div>

        <div className="grow overflow-y-auto no-scrollbar">
          <ul className="list-none p-0 my-3 ps-[0]">
            <NavItems items={navItems} renderCount={renderCount} />
          </ul>
        </div>
        {showUserMenu && (
          <UserMenu
            logoutUrl={logoutUrl}
            customLogoUrl={customLogoUrl}
            authContext={authContext}
          />
        )}
      </div>
    </div>
  ) : (
    <NavigationCollapsed
      altTheme={altTheme}
      showUserMenu={showUserMenu}
      renderCount={renderCount}
      navItems={navItems}
      logoutUrl={logoutUrl}
      setNavigationOpen={setNavigationOpen}
      navigationOpen={navigationOpen}
      customLogoUrl={customLogoUrl}
    />
  );
};

export default Navigation;
