import Table from '@risksmart-app/components/Table';

import { useGetPolicyAttestationRecordsForDocumentQuery } from '@/generated/graphql';

import { useGetRegisterTableProps } from '../../../../../../../attestations/config';

type TabProps = {
  documentFileId: string;
};

const Tab = ({ documentFileId }: TabProps) => {
  const { data, loading } = useGetPolicyAttestationRecordsForDocumentQuery({
    fetchPolicy: 'no-cache',
    variables: { documentFileId },
  });
  const tableProps = useGetRegisterTableProps(data?.attestation_record);

  return <Table {...tableProps} variant="embedded" loading={loading} />;
};

export default Tab;
