import { Table as CSTable, TableProps } from '@cloudscape-design/components';
import { FC, forwardRef } from 'react';

import style from './style.module.scss';

const Table: FC<TableProps> = forwardRef<TableProps.Ref, TableProps>(
  (props, ref) => {
    return (
      <div className={style.root}>
        <CSTable {...props} ref={ref} />
      </div>
    );
  }
);
Table.displayName = 'Table';

export default Table;
