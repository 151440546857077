import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import {
  Questionnaire_Template_Version_Status_Enum,
  useGetQuestionnaireTemplateByIdQuery,
  useGetQuestionnaireTemplateVersionByIdQuery,
  useInsertQuestionnaireTemplateVersionMutation,
  usePublishQuestionnaireTemplateVersionMutation,
  useUpdateQuestionnaireTemplateVersionMutation,
  Version_Status_Enum,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import { useHasPermission } from '../../../../../../../../rbac/Permission';
import QuestionnaireTemplateVersionForm from '../../../forms/QuestionnaireTemplateVersionForm';
import {
  defaultValues,
  QuestionnaireTemplateVersionFormFieldData,
} from '../../../forms/questionnaireTemplateVersionSchema';
import { useNextTemplateVersion } from '../../../useNextTemplateVersion';

type TabProps = {
  parentId: string;
  questionnaireTemplateVersionId: string;
};

const Tab = ({ parentId, questionnaireTemplateVersionId }: TabProps) => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions.confirm_close_modal',
  });

  const [confirmCloseVisible, setConfirmCloseVisible] = useState(false);

  const [insert] = useInsertQuestionnaireTemplateVersionMutation({
    update: (cache) => {
      evictField(cache, 'questionnaire_template_version');
    },
  });

  const [update] = useUpdateQuestionnaireTemplateVersionMutation({
    update: (cache) => {
      evictField(cache, 'questionnaire_template_version');
    },
  });

  const [publish] = usePublishQuestionnaireTemplateVersionMutation({
    update: (cache) => {
      evictField(cache, 'questionnaire_template_version');
    },
  });

  const { data: questionnaireTemplate, loading: questionnaireTemplateLoading } =
    useGetQuestionnaireTemplateByIdQuery({
      variables: { Id: parentId },
    });

  const [{ nextVersion }, { loading: loadingNextTemplateVersion }] =
    useNextTemplateVersion(parentId);

  const navigate = useNavigate();
  const canEditPermission = useHasPermission(
    'update:questionnaire_template_version',
    questionnaireTemplate?.questionnaire_template,
    true
  );
  const canCreatePermission = useHasPermission(
    'insert:questionnaire_template_version',
    questionnaireTemplate?.questionnaire_template,
    true
  );

  const {
    data: questionnaireTemplateVersionData,
    loading,
    error,
  } = useGetQuestionnaireTemplateVersionByIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      Id: questionnaireTemplateVersionId!,
    },
    skip: !questionnaireTemplateVersionId,
  });

  if (error) {
    throw error;
  }

  const questionnaireTemplateVersion =
    questionnaireTemplateVersionData?.questionnaire_template_version;

  const canModify = questionnaireTemplateVersion
    ? canEditPermission
    : canCreatePermission;

  const isDocumentFileDraft = questionnaireTemplateVersion?.Status
    ? questionnaireTemplateVersion?.Status === Version_Status_Enum.Draft
    : true;

  const onPublish = async () => {
    await publish({
      variables: {
        questionnaireTemplateId: parentId,
        questionnaireTemplateVersionId: questionnaireTemplateVersionId,
      },
    });
  };

  const onSave = async (data: QuestionnaireTemplateVersionFormFieldData) => {
    if (questionnaireTemplateVersion) {
      await update({
        variables: {
          Id: questionnaireTemplateVersion.Id,
          object: data,
        },
        context: {
          headers: {
            'x-confirm-change-request': 'true',
          },
        },
      });
    } else {
      await insert({
        variables: {
          object: {
            ...data,
            Status: Questionnaire_Template_Version_Status_Enum.Draft,
          },
        },
      });
    }
  };

  const values: QuestionnaireTemplateVersionFormFieldData | undefined =
    questionnaireTemplateVersion
      ? {
          ...defaultValues,
          ...questionnaireTemplateVersion,
        }
      : undefined;

  const defaultValuesWithVersion: QuestionnaireTemplateVersionFormFieldData = {
    ...defaultValues,
    Version: nextVersion,
  };

  const readOnly = !canModify;

  if (
    !questionnaireTemplateVersion ||
    loading ||
    loadingNextTemplateVersion ||
    questionnaireTemplateLoading
  ) {
    return null;
  }

  const onDismiss = () => {
    navigate(`/third-party/questionnaire-templates/${parentId}/versions`);
  };

  return (
    <>
      <QuestionnaireTemplateVersionForm
        // Disable status field as we have buttons which allow correct status transitions
        disableStatus={true}
        defaultValues={defaultValuesWithVersion}
        values={values}
        onSave={onSave}
        onPublish={onPublish}
        onDismiss={(saved) => {
          if (saved || readOnly || !isDocumentFileDraft) {
            onDismiss();
          }

          setConfirmCloseVisible(true);
        }}
        readOnly={readOnly}
        isCreatingNewEntity={!!questionnaireTemplateVersion}
        readonly={readOnly}
        savedStatus={questionnaireTemplateVersion.Status}
        parentId={parentId}
      />

      {confirmCloseVisible && (
        <ConfirmModal
          isVisible={true}
          onConfirm={onDismiss}
          onDismiss={() => setConfirmCloseVisible(false)}
          header={st('title')}
        >
          {st('message')}
        </ConfirmModal>
      )}
    </>
  );
};

export default Tab;
