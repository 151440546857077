import { InputProps } from '@cloudscape-design/components';
import { FC } from 'react';

import { TextInput } from '@/components/Form/ControlledInput/ControlledInput';
import HelpLink from '@/components/HelpPanel/HelpLink';

import { CustomAttributeProps } from './CustomAttributeProps';

interface CustomAttributeInputProps extends CustomAttributeProps {
  type?: InputProps.Type;
}

export const CustomAttributeInput: FC<CustomAttributeInputProps> = ({
  value,
  onChange,
  label,
  type,
  disabled,
  error,
  description,
}) => (
  <TextInput
    info={
      description && <HelpLink id={label} title={label} content={description} />
    }
    label={label}
    disabled={disabled}
    type={type}
    value={value}
    errorMessage={error}
    onChange={(val) => onChange(`${val}`)}
  />
);
