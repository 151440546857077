import { ControlElement, JsonSchema7 } from '@jsonforms/core';
import { uniq } from 'lodash';
import { z } from 'zod';

import { toSentenceCase } from '@/utils/utils';

export enum FieldType {
  Text = 'text',
  Date = 'date',
  Textarea = 'textarea',
  Select = 'select',
  MultiSelect = 'multiselect',
  Link = 'link',
}

const message = 'Required';
const defaultEmptyOptionCount = 5;

export const fieldOptionsSchema = z.object({
  Required: z.boolean(),
  Hidden: z.boolean(),
  ReadOnly: z.boolean(),
  DefaultValue: z.string().nullish(),
});

export const newFieldSchema = z
  .object({
    Label: z.string().min(1, { message }),
    Description: z.string().nullish(),
    Type: z.nativeEnum(FieldType, { invalid_type_error: message }),
    Options: z
      .array(z.object({ value: z.string(), generatedId: z.string() }))
      .optional(),
  })
  .merge(fieldOptionsSchema)
  .superRefine((values, ctx) => {
    if (values.Type === FieldType.Select) {
      const options = values.Options?.filter((val) => val);
      if (!options || !options.length) {
        ctx.addIssue({
          message: 'Options are required.',
          code: z.ZodIssueCode.custom,
          path: ['global'],
        });
      }
      if (options && uniq(options).length !== options.length) {
        ctx.addIssue({
          message: 'Options must be unique.',
          code: z.ZodIssueCode.custom,
          path: ['global'],
        });
      }
    }
  });

export type NewFieldFormFields = z.infer<typeof newFieldSchema>;
export type FormFieldOptions = z.infer<typeof fieldOptionsSchema>;

export const defaultValues: NewFieldFormFields = {
  Label: '',
  Type: FieldType.Text,
  Options: Array.from({ length: defaultEmptyOptionCount }, () => ({
    value: '',
    generatedId: crypto.randomUUID(),
  })),
  Required: false,
  Hidden: false,
  ReadOnly: false,
  DefaultValue: null,
};

enum JsonSchemaType {
  String = 'string',
  Number = 'number',
  Object = 'object',
  Array = 'array',
  Boolean = 'boolean',
  Null = 'null',
}
enum JsonSchemaFormat {
  Date = 'date',
  Link = 'uri',
}
export interface JsonSchemaField {
  schema: JsonSchema7;
  control: ControlElement;
  attributeName: string;
}
export const fieldToJsonSchema = (
  data: NewFieldFormFields,
  attributeName?: string
): JsonSchemaField => {
  const attributeId = attributeName
    ? attributeName
    : `${Date.now()}_${data.Type}`;
  const schema: JsonSchema7 = {};
  schema.description = data.Description ?? '';
  switch (data.Type) {
    case FieldType.Text:
      schema.type = JsonSchemaType.String;
      break;
    case FieldType.Date:
      schema.type = JsonSchemaType.String;
      schema.format = JsonSchemaFormat.Date;
      break;
    case FieldType.Textarea:
      schema.type = JsonSchemaType.String;
      break;
    case FieldType.Select:
      schema.type = JsonSchemaType.String;
      schema.enum = uniq(data.Options?.map((o) => o.value)).filter(
        (item) => item
      );
      break;
    case FieldType.MultiSelect:
      schema.type = JsonSchemaType.Array;
      // https://jsonforms.io/docs/multiple-choice/
      schema.uniqueItems = true;
      schema.enum = uniq(data.Options?.map((o) => o.value)).filter(
        (item) => item
      );
      break;
    case FieldType.Link:
      schema.type = JsonSchemaType.String;
      schema.format = JsonSchemaFormat.Link;
  }
  const control: ControlElement = {
    type: 'Control',
    scope: `#/properties/${attributeId}`,
    label: toSentenceCase(data.Label.trim()),
  };

  return {
    attributeName: attributeId,
    schema,
    control,
  };
};
