import { ParseKeys } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { HelpContentSchema } from './helpContentSchema';
import { HelpContent, useHelpContent } from './HelpProvider';

export const useSummaryHelpContent = (
  translationKey: string,
  summaryHelpContent: HelpContent | null
) => {
  const { setSummaryHelpContent, setTranslationKey } = useHelpContent();

  useEffect(() => {
    setSummaryHelpContent(summaryHelpContent);
    setTranslationKey(translationKey);

    return () => {
      setSummaryHelpContent(null);
      setTranslationKey('');
    };
    // will get same summaryHelpContent for a translationKey
  }, [
    setSummaryHelpContent,
    setTranslationKey,
    translationKey,
    summaryHelpContent,
  ]);
};

export const useI18NSummaryHelpContent = (
  translationKey: ParseKeys<'common'>
) => {
  const { t } = useTranslation('common');
  const summaryHelpContent = useMemo(
    () => t(translationKey),
    [translationKey, t]
  );

  const result = useMemo(() => {
    // Check if translation is not set (or set to null)
    if (summaryHelpContent === translationKey) {
      return null;
    }
    const result = HelpContentSchema.safeParse(summaryHelpContent);
    if (!result.success) {
      console.error(`Help  content not in correct format: ${translationKey}`);
    }

    return result;
    // Should always get same content for a translations key
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translationKey]);

  return useSummaryHelpContent(
    translationKey,
    result?.success ? result.data : null
  );
};
