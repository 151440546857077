import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';

import { ModalWrapper } from '../Form/Form/ModalWrapper';
import { FormContextProps } from '../Form/Form/types';
import UserSearchPreferencesFormFields from './UserSearchPreferencesFormFields';
import {
  defaultValues,
  UserSearchPreferencesSchema,
  UserSearchPreferencesSchemaFieldData,
} from './userSearchPreferencesSchema';

export type Props = Omit<
  FormContextProps<UserSearchPreferencesSchemaFieldData>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'defaultValues'
  | 'renderTemplate'
  | 'i18n'
> & {
  showJobTitleToggle: boolean;
  showDirectoryDepartmentsToggle: boolean;
  showUserLocationToggle: boolean;
};

const UserSearchPreferencesForm: FC<Props> = ({
  showJobTitleToggle,
  showDirectoryDepartmentsToggle,
  showUserLocationToggle,
  ...props
}) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={UserSearchPreferencesSchema}
      defaultValues={defaultValues}
      i18n={t('userSearchPreferences')}
      formId={'user-search-preferences-form'}
      renderTemplate={(renderProps) => (
        <ModalWrapper {...renderProps} visible={true} />
      )}
    >
      <UserSearchPreferencesFormFields
        showDirectoryDepartmentsToggle={showDirectoryDepartmentsToggle}
        showJobTitleToggle={showJobTitleToggle}
        showUserLocationToggle={showUserLocationToggle}
      />
    </CustomisableFormContext>
  );
};

export default UserSearchPreferencesForm;
