import {
  ControlProps,
  isStringControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core';
import { withJsonFormsControlProps } from '@jsonforms/react';

import { InputControl } from './InputControl';

export const TextControl = (props: ControlProps) => {
  return <InputControl {...props} />;
};

export const textControlTester: RankedTester = rankWith(1, isStringControl);

export default withJsonFormsControlProps(TextControl);
