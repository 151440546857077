import {
  OptionDefinition,
  OptionGroup,
} from '@cloudscape-design/components/internal/components/option/interfaces';
import { useMemo } from 'react';

import { GetTagsQuery, useGetTagsQuery } from '@/generated/graphql';
import { TagType } from '@/types/types';

import { StatusType } from '../ControlledSelect/ControlledSelect';
import { sortByLabel } from '../form-utils';

const cleanRawTagTypes = (rawTags?: GetTagsQuery): Array<TagType> => {
  return (rawTags?.tag_type || []).map(
    ({ Name, Description, TagTypeId, tag_type_group }) => ({
      Name: Name,
      Description: Description || '',
      TagTypeId: TagTypeId,
      TagTypeGroupName: tag_type_group?.Name,
    })
  );
};

const tagsToGroupDefinition = (groupedTags: { [key: string]: TagType[] }) =>
  Object.keys(groupedTags)
    .sort()
    .map<OptionGroup>((groupName) => ({
      label: groupName,
      options: groupedTags[groupName]
        .map((tag) => ({
          value: tag.TagTypeId,
          label: tag.Name || '',
          description: tag.Description || '',
        }))
        .sort(sortByLabel),
    }));

export const useTagOptions = () => {
  const { data, loading, error } = useGetTagsQuery({});
  const tags = cleanRawTagTypes(data);

  const optionItems = useMemo<(OptionDefinition | OptionGroup)[]>(() => {
    const noGroup = 'No group';
    const groupedTags = tags.reduce<{ [key: string]: TagType[] }>(
      (acc, obj) => {
        const groupName = obj.TagTypeGroupName ?? noGroup;
        acc[groupName] = acc[groupName] || [];
        acc[groupName].push(obj);

        return acc;
      },
      {}
    );

    const tagOptions = tagsToGroupDefinition(groupedTags);

    const groupedTagOptions = tagOptions.filter((x) => x.label !== noGroup);
    const ungroupedTagOptions = tagOptions.filter((x) => x.label === noGroup);

    if (groupedTagOptions.length <= 0 && ungroupedTagOptions.length > 0) {
      return ungroupedTagOptions[0].options as OptionDefinition[];
    }

    return [...groupedTagOptions, ...ungroupedTagOptions];
  }, [tags]);

  let statusType: StatusType | undefined = undefined;
  if (loading) {
    statusType = 'loading';
  } else if (error) {
    statusType = 'error';
  }

  return { tags, optionItems, statusType };
};
