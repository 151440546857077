
    import './styles.scoped.css';
    export default {
  "marker": "awsui_marker_1kjc7_1ljni_149",
  "root": "awsui_root_1kjc7_1ljni_163",
  "title": "awsui_title_1kjc7_1ljni_201",
  "list": "awsui_list_1kjc7_1ljni_205",
  "marker--dimmed": "awsui_marker--dimmed_1kjc7_1ljni_254",
  "marker--highlighted": "awsui_marker--highlighted_1kjc7_1ljni_257"
};
  