import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { createContext, FC, ReactNode, useContext } from 'react';

import {
  Appetite_Model_Enum,
  Risk_Scoring_Model_Enum,
  useGetAggregationSettingsForOrgQuery,
} from '@/generated/graphql';

type AggregationContext = {
  loading: boolean;
  name: string;
  riskScoringModel: Risk_Scoring_Model_Enum;
  appetiteAggregation: Appetite_Model_Enum;
};

const AggregationContext = createContext<AggregationContext | null>(null);

export const AggregationProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated } = useRisksmartUser();

  const { data, loading } = useGetAggregationSettingsForOrgQuery({
    skip: !isAuthenticated,
  });

  return (
    <AggregationContext.Provider
      value={{
        loading,
        name: 'default',
        riskScoringModel:
          data?.aggregation_org?.[0]?.RiskScoringModel ??
          Risk_Scoring_Model_Enum.Default,
        appetiteAggregation:
          data?.aggregation_org?.[0]?.Appetite ?? Appetite_Model_Enum.Default,
      }}
    >
      {children}
    </AggregationContext.Provider>
  );
};

export const useAggregation = () => {
  const context = useContext(AggregationContext);
  if (context === null) {
    throw new Error('useAggregation must be used within a AggregationProvider');
  }

  return {
    loading: context.loading,
    riskModel: context.riskScoringModel,
    appetiteAggregation: context.appetiteAggregation,
  };
};
