import { useMemo } from 'react';

import {
  QuestionnaireTemplateFields,
  QuestionnaireTemplateRegisterFields,
} from './types';

export const useLabelledFields = (
  data: QuestionnaireTemplateFields[] | undefined
): QuestionnaireTemplateRegisterFields[] => {
  return useMemo(() => {
    return (
      data?.map((record) => ({
        ...record,
        CreatedByFriendlyName: record.createdByUser?.FriendlyName || '-',
        ModifiedByFriendlyName: record.modifiedByUser?.FriendlyName || '-',
      })) ?? []
    );
  }, [data]);
};
