
    import './styles.scoped.css';
    export default {
  "axis": "awsui_axis_f0fot_3eync_9",
  "vertical-marker": "awsui_vertical-marker_f0fot_3eync_10",
  "axis--emphasized": "awsui_axis--emphasized_f0fot_3eync_15",
  "axis-label": "awsui_axis-label_f0fot_3eync_19",
  "axis-label--x": "awsui_axis-label--x_f0fot_3eync_24",
  "axis-label--y": "awsui_axis-label--y_f0fot_3eync_25",
  "grid": "awsui_grid_f0fot_3eync_29",
  "ticks__line": "awsui_ticks__line_f0fot_3eync_34",
  "ticks__text": "awsui_ticks__text_f0fot_3eync_39",
  "ticks--y": "awsui_ticks--y_f0fot_3eync_44",
  "ticks--x": "awsui_ticks--x_f0fot_3eync_45",
  "ticks--bottom": "awsui_ticks--bottom_f0fot_3eync_49",
  "labels-inline-start": "awsui_labels-inline-start_f0fot_3eync_54",
  "ticks": "awsui_ticks_f0fot_3eync_34",
  "labels-inline-start__label": "awsui_labels-inline-start__label_f0fot_3eync_69",
  "labels-inline-start--hidden": "awsui_labels-inline-start--hidden_f0fot_3eync_73",
  "labels-block-end": "awsui_labels-block-end_f0fot_3eync_81",
  "vertical-marker-circle": "awsui_vertical-marker-circle_f0fot_3eync_92",
  "vertical-marker-circle-active": "awsui_vertical-marker-circle-active_f0fot_3eync_98",
  "focus-outline": "awsui_focus-outline_f0fot_3eync_103",
  "chart-container": "awsui_chart-container_f0fot_3eync_111",
  "fit-height": "awsui_fit-height_f0fot_3eync_116",
  "chart-container-outer": "awsui_chart-container-outer_f0fot_3eync_121",
  "has-filters": "awsui_has-filters_f0fot_3eync_127",
  "chart-container-inner": "awsui_chart-container-inner_f0fot_3eync_131",
  "chart-container-plot-wrapper": "awsui_chart-container-plot-wrapper_f0fot_3eync_138",
  "chart-container-plot": "awsui_chart-container-plot_f0fot_3eync_138",
  "chart-container-bottom-labels": "awsui_chart-container-bottom-labels_f0fot_3eync_150"
};
  