import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledInput from '@/components/Form/ControlledInput';
import {
  ControlledBooleanRadioGroup,
  yesNoOptions,
} from '@/components/Form/ControlledRadioGroup/ControlledBooleanRadioGroup';

import { UserGroupFormFieldsSchema } from './userGroupSchema';

interface Props {
  readOnly?: boolean;
}

const UserGroupFormFields: FC<Props> = ({ readOnly }) => {
  const { control } = useFormContext<UserGroupFormFieldsSchema>();
  const { t } = useTranslation(['common'], {
    keyPrefix: 'userGroups',
  });

  return (
    <>
      <ControlledInput
        key="name"
        forceRequired={true}
        name="Name"
        label={t('fields.nameField')}
        placeholder={t('fields.placeholders.name')}
        control={control}
        disabled={readOnly}
      />
      <ControlledInput
        key="description"
        name="Description"
        label={t('fields.descriptionField')}
        placeholder={t('fields.placeholders.description')}
        control={control}
        disabled={readOnly}
      />
      <ControlledInput
        key="email"
        name="Email"
        label={t('fields.emailField')}
        placeholder={t('fields.placeholders.email')}
        control={control}
        disabled={readOnly}
      />
      <ControlledBooleanRadioGroup
        label={t('fields.OwnerContributorField')}
        name="OwnerContributor"
        control={control}
        items={yesNoOptions}
        disabled={readOnly}
      />
    </>
  );
};

export default UserGroupFormFields;
