import { AnalyticsBrowser } from '@segment/analytics-next';
import React from 'react';

const AnalyticsContext = React.createContext<AnalyticsBrowser>(undefined!);

interface AnalyticsProviderProps {
  writeKey: string;
  children: React.ReactNode;
}
export const AnalyticsProvider = (
  props: AnalyticsProviderProps
): JSX.Element => {
  const analytics = React.useMemo(
    () => AnalyticsBrowser.load({ writeKey: props.writeKey }),
    [props.writeKey]
  );

  return (
    <AnalyticsContext.Provider value={analytics}>
      {props.children}
    </AnalyticsContext.Provider>
  );
};

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }

  return result;
};
