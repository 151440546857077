import ExpandableSection from '@cloudscape-design/components/expandable-section';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssessmentResultModal from 'src/pages/assessments/modals/AssessmentResultModal';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  Parent_Type_Enum,
  useGetInternalAuditReportObligationAssessmentResultsByObligationIdQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { ObjectWithContributors } from '../../../../../../rbac/Permission';
import { useGetCollectionTableProps } from './internalAuditObligationRatingConfig';

interface Props {
  parent: ObjectWithContributors;
}

const InternalAuditObligationRatingRegister: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('obligationsAssessments.tabHelp');
  const { t } = useTranslation(['common']);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();

  const [openAssessmentResultId, setOpenAssessmentResultId] = useState<
    string | undefined
  >();

  const { data, loading } =
    useGetInternalAuditReportObligationAssessmentResultsByObligationIdQuery({
      variables: {
        ObligationId: parent.Id,
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });

  const handleAssessmentResultModalClose = () => {
    setOpenAssessmentResultId(undefined);
    setIsEditOpen(false);
  };

  const onOpenResult = (id: string) => {
    setOpenAssessmentResultId(id);
    setIsEditOpen(true);
  };

  const tableProps = useGetCollectionTableProps(
    data?.obligation_assessment_result,
    onOpenResult,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>
              {t('obligationsAssessments.internalAuditRatingSubheading')}
            </span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <Table
          {...tableProps}
          resizableColumns={true}
          variant="embedded"
          loading={loading}
          loadingText={t('obligationsAssessments.loading_message') ?? ''}
        />
      </ExpandableSection>
      {isEditOpen && (
        <AssessmentResultModal
          id={openAssessmentResultId}
          onDismiss={handleAssessmentResultModalClose}
          assessedItem={parent}
          resultType={Parent_Type_Enum.ObligationAssessmentResult}
          hideTypeSelector={true}
          i18n={t('ratings')}
          assessmentMode={'internal_audit_report'}
        />
      )}
    </>
  );
};

export default InternalAuditObligationRatingRegister;
