
    import './styles.scoped.css';
    export default {
  "layout": "awsui_layout_5gtk3_1pn2f_141",
  "background": "awsui_background_5gtk3_1pn2f_155",
  "is-overlap-disabled": "awsui_is-overlap-disabled_5gtk3_1pn2f_160",
  "header-background": "awsui_header-background_5gtk3_1pn2f_163",
  "notifications": "awsui_notifications_5gtk3_1pn2f_167",
  "breadcrumbs": "awsui_breadcrumbs_5gtk3_1pn2f_172",
  "default-padding": "awsui_default-padding_5gtk3_1pn2f_177",
  "header-wrapper": "awsui_header-wrapper_5gtk3_1pn2f_181",
  "with-divider": "awsui_with-divider_5gtk3_1pn2f_186",
  "content": "awsui_content_5gtk3_1pn2f_189",
  "has-header": "awsui_has-header_5gtk3_1pn2f_193",
  "is-visual-refresh": "awsui_is-visual-refresh_5gtk3_1pn2f_200",
  "has-default-background": "awsui_has-default-background_5gtk3_1pn2f_200",
  "has-notifications": "awsui_has-notifications_5gtk3_1pn2f_204"
};
  