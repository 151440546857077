import { FC, useRef } from 'react';

import { getColorStyles } from '@/utils/colours';

import { Box } from '../../../../../../theme/risksmart-themed/components';
import styles from './style.module.scss';
import { HeatmapCellProps } from './types';

export const HeatmapCell: FC<HeatmapCellProps> = ({
  data,
  onMouseOver,
  onClick,
  className,
}) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const style = getColorStyles(data.background);

  return (
    <div
      ref={popoverRef}
      className={className ?? styles.cell}
      onClick={() => onClick?.(data)}
      onMouseOver={() => onMouseOver(popoverRef, data)}
      {...{ style }}
    >
      <div className={styles.innerCell}>
        <Box variant="span">{data.value}</Box>
      </div>
    </div>
  );
};
