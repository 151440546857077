export const allowedFileExtensions: string[] = [
  '.pdf',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.csv',
  '.txt',
  '.rtf',
  '.html',
  '.zip',
  '.mp3',
  '.wma',
  '.mpg',
  '.flv',
  '.avi',
  '.jpg',
  '.jpeg',
  '.png',
  '.gif',
  '.heic',
  '.pptx',
  '.ppsx',
  '.odp',
  '.msg',
  '.eml',
  '.edb',
  '.emlx',
  '.mbox',
  '.olm',
];
