import { z } from 'zod';

import { CustomAttributeDataSchema, FilesSchema } from './global';

export const IssueUpdatesSchema = z
  .object({
    Title: z.coerce.string().min(1, { message: 'Required' }),
    Description: z.coerce.string(),
  })
  .and(FilesSchema)
  .and(CustomAttributeDataSchema);

export type IssueUpdatesFields = z.infer<typeof IssueUpdatesSchema>;

export const defaultValues: IssueUpdatesFields = {
  Description: '',
  Title: '',
  files: [],
  newFiles: [],
  CustomAttributeData: null,
};
