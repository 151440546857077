export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  float8: { input: any; output: any; }
  interval: { input: any; output: any; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  numeric: { input: number; output: number; }
  smallint: { input: number; output: number; }
  timestamptz: { input: string; output: string; }
  uuid: { input: string; output: string; }
  _FieldSet: { input: any; output: any; }
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type BooleanComparisonExp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type DataImportStartImportOutput = {
  __typename?: 'DataImportStartImportOutput';
  message: Scalars['String']['output'];
};

export type DataImportValidateOutput = {
  __typename?: 'DataImportValidateOutput';
  message: Scalars['String']['output'];
};

export type DisconnectSlackOutput = {
  __typename?: 'DisconnectSlackOutput';
  message: Scalars['String']['output'];
};

export type GenericMutationOutput = {
  __typename?: 'GenericMutationOutput';
  affected_rows: Scalars['Int']['output'];
};

export type InsertAssessmentActivityOutput = {
  __typename?: 'InsertAssessmentActivityOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildAcceptanceOutput = {
  __typename?: 'InsertChildAcceptanceOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildActionOutput = {
  __typename?: 'InsertChildActionOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildAppetiteOutput = {
  __typename?: 'InsertChildAppetiteOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildControlOutput = {
  __typename?: 'InsertChildControlOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildDashboardOutput = {
  __typename?: 'InsertChildDashboardOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildDocumentAssessmentResultOutput = {
  __typename?: 'InsertChildDocumentAssessmentResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertChildImpactOutput = {
  __typename?: 'InsertChildImpactOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildIndicatorOutput = {
  __typename?: 'InsertChildIndicatorOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildIssueOutput = {
  __typename?: 'InsertChildIssueOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertChildObligationAssessmentResultOutput = {
  __typename?: 'InsertChildObligationAssessmentResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertChildRiskAssessmentResultOutput = {
  __typename?: 'InsertChildRiskAssessmentResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertChildRiskOutput = {
  __typename?: 'InsertChildRiskOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertDocumentVersionOutput = {
  __typename?: 'InsertDocumentVersionOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertImpactRatingPairInput = {
  ImpactId: Scalars['uuid']['input'];
  Rating: Scalars['Int']['input'];
};

export type InsertInternalAuditInput = {
  BusinessArea: Scalars['String']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};

export type InsertInternalAuditOutput = {
  __typename?: 'InsertInternalAuditOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertIssueAssessmentOutput = {
  __typename?: 'InsertIssueAssessmentOutput';
  Id: Scalars['uuid']['output'];
};

export type InsertReferenceUserOutput = {
  __typename?: 'InsertReferenceUserOutput';
  Id: Scalars['uuid']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IntComparisonExp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type LinkItemRecordOutput = {
  __typename?: 'LinkItemRecordOutput';
  RelationshipType: Scalars['String']['output'];
  Source: Scalars['uuid']['output'];
  Target: Scalars['uuid']['output'];
};

export type LinkItemsOutput = {
  __typename?: 'LinkItemsOutput';
  Links: Array<LinkItemRecordOutput>;
};

export type NotificationPreferencesOutput = {
  __typename?: 'NotificationPreferencesOutput';
  categories?: Maybe<Scalars['jsonb']['output']>;
  channel_types?: Maybe<Scalars['jsonb']['output']>;
  id: Scalars['String']['output'];
  workflows?: Maybe<Scalars['jsonb']['output']>;
};

export type OverrideChangeRequestOutput = {
  __typename?: 'OverrideChangeRequestOutput';
  Id: Scalars['uuid']['output'];
};

export type PublishQuestionnaireTemplateVersionOutput = {
  __typename?: 'PublishQuestionnaireTemplateVersionOutput';
  affected_rows: Scalars['Int']['output'];
};

export type RecalculateAppetitesOutput = {
  __typename?: 'RecalculateAppetitesOutput';
  message?: Maybe<Scalars['String']['output']>;
};

export type RecalculateRiskScoresOutput = {
  __typename?: 'RecalculateRiskScoresOutput';
  message?: Maybe<Scalars['String']['output']>;
};

export type RoleOutput = {
  __typename?: 'RoleOutput';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type SlackNotificationConnectionOutput = {
  __typename?: 'SlackNotificationConnectionOutput';
  connected: Scalars['Boolean']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringArrayComparisonExp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']['input']>>;
  _eq?: InputMaybe<Array<Scalars['String']['input']>>;
  _gt?: InputMaybe<Array<Scalars['String']['input']>>;
  _gte?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Array<Scalars['String']['input']>>;
  _lte?: InputMaybe<Array<Scalars['String']['input']>>;
  _neq?: InputMaybe<Array<Scalars['String']['input']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type StringComparisonExp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type UnlinkItemsOutput = {
  __typename?: 'UnlinkItemsOutput';
  Ids?: Maybe<Array<Maybe<Scalars['uuid']['output']>>>;
};

export type UpdateAcceptanceOutput = {
  __typename?: 'UpdateAcceptanceOutput';
  affected_rows: Scalars['Int']['output'];
};

export type UpdateChildActionOutput = {
  __typename?: 'UpdateChildActionOutput';
  affected_rows: Scalars['Int']['output'];
};

export type UpdateChildControlOutput = {
  __typename?: 'UpdateChildControlOutput';
  affected_rows: Scalars['Int']['output'];
};

export type UpdateChildRiskAssessmentResultOutput = {
  __typename?: 'UpdateChildRiskAssessmentResultOutput';
  affected_rows: Scalars['Int']['output'];
};

export type UpdateChildRiskOutput = {
  __typename?: 'UpdateChildRiskOutput';
  Id: Scalars['uuid']['output'];
};

export type UpdateDocumentVersionOutput = {
  __typename?: 'UpdateDocumentVersionOutput';
  affected_rows: Scalars['Int']['output'];
};

export type UpdateInternalAuditInput = {
  BusinessArea: Scalars['String']['input'];
  BusinessAreaId: Scalars['uuid']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};

export type UpdateInternalAuditOutput = {
  __typename?: 'UpdateInternalAuditOutput';
  Id: Scalars['uuid']['output'];
};

export type UpdateIssueAssessmentOutput = {
  __typename?: 'UpdateIssueAssessmentOutput';
  Id: Scalars['uuid']['output'];
};

export type UpdateNotificationPreferencesInput = {
  categories?: InputMaybe<Scalars['jsonb']['input']>;
  channel_types?: InputMaybe<Scalars['jsonb']['input']>;
  id: Scalars['String']['input'];
  workflows?: InputMaybe<Scalars['jsonb']['input']>;
};

export type UpdateNotificationPreferencesOutput = {
  __typename?: 'UpdateNotificationPreferencesOutput';
  message: Scalars['String']['output'];
};

export type UpdateUserRolesMutationOutput = {
  __typename?: 'UpdateUserRolesMutationOutput';
  roles: Array<Maybe<RoleOutput>>;
};

/** columns and relationships of "risksmart.acceptance" */
export type Acceptance = {
  __typename?: 'acceptance';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateAcceptedFrom: Scalars['timestamptz']['output'];
  DateAcceptedTo: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: AcceptanceStatusEnum;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  approvedByUser?: Maybe<User>;
  /** An object relationship */
  approvedByUserGroup?: Maybe<UserGroup>;
  /** An array relationship */
  changeRequests: Array<ChangeRequest>;
  /** An aggregate relationship */
  changeRequests_aggregate: ChangeRequestAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  parents: Array<AcceptanceParent>;
  /** An aggregate relationship */
  parents_aggregate: AcceptanceParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  requestedByUser?: Maybe<User>;
  /** An object relationship */
  requestedByUserGroup?: Maybe<UserGroup>;
  /** An object relationship */
  status: AcceptanceStatus;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceChangeRequestsArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceChangeRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceParentsArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptanceParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptancePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.acceptance" */
export type AcceptancePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.acceptance" */
export type AcceptanceAggregate = {
  __typename?: 'acceptance_aggregate';
  aggregate?: Maybe<AcceptanceAggregateFields>;
  nodes: Array<Acceptance>;
};

/** aggregate fields of "risksmart.acceptance" */
export type AcceptanceAggregateFields = {
  __typename?: 'acceptance_aggregate_fields';
  avg?: Maybe<AcceptanceAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AcceptanceMaxFields>;
  min?: Maybe<AcceptanceMinFields>;
  stddev?: Maybe<AcceptanceStddevFields>;
  stddev_pop?: Maybe<AcceptanceStddevPopFields>;
  stddev_samp?: Maybe<AcceptanceStddevSampFields>;
  sum?: Maybe<AcceptanceSumFields>;
  var_pop?: Maybe<AcceptanceVarPopFields>;
  var_samp?: Maybe<AcceptanceVarSampFields>;
  variance?: Maybe<AcceptanceVarianceFields>;
};


/** aggregate fields of "risksmart.acceptance" */
export type AcceptanceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AcceptanceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AcceptanceAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.acceptance_audit" */
export type AcceptanceAudit = {
  __typename?: 'acceptance_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateAcceptedFrom: Scalars['timestamptz']['output'];
  DateAcceptedTo: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.acceptance_audit" */
export type AcceptanceAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.acceptance_audit" */
export type AcceptanceAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.acceptance_audit" */
export type AcceptanceAuditAggregate = {
  __typename?: 'acceptance_audit_aggregate';
  aggregate?: Maybe<AcceptanceAuditAggregateFields>;
  nodes: Array<AcceptanceAudit>;
};

/** aggregate fields of "risksmart.acceptance_audit" */
export type AcceptanceAuditAggregateFields = {
  __typename?: 'acceptance_audit_aggregate_fields';
  avg?: Maybe<AcceptanceAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AcceptanceAuditMaxFields>;
  min?: Maybe<AcceptanceAuditMinFields>;
  stddev?: Maybe<AcceptanceAuditStddevFields>;
  stddev_pop?: Maybe<AcceptanceAuditStddevPopFields>;
  stddev_samp?: Maybe<AcceptanceAuditStddevSampFields>;
  sum?: Maybe<AcceptanceAuditSumFields>;
  var_pop?: Maybe<AcceptanceAuditVarPopFields>;
  var_samp?: Maybe<AcceptanceAuditVarSampFields>;
  variance?: Maybe<AcceptanceAuditVarianceFields>;
};


/** aggregate fields of "risksmart.acceptance_audit" */
export type AcceptanceAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AcceptanceAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AcceptanceAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type AcceptanceAuditAvgFields = {
  __typename?: 'acceptance_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_audit". All fields are combined with a logical 'AND'. */
export type AcceptanceAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ApprovedByUser?: InputMaybe<StringComparisonExp>;
  ApprovedByUserGroup?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DateAcceptedFrom?: InputMaybe<TimestamptzComparisonExp>;
  DateAcceptedTo?: InputMaybe<TimestamptzComparisonExp>;
  Details?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RequestedByUser?: InputMaybe<StringComparisonExp>;
  RequestedByUserGroup?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AcceptanceAuditBoolExp>>;
  _not?: InputMaybe<AcceptanceAuditBoolExp>;
  _or?: InputMaybe<Array<AcceptanceAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.acceptance_audit" */
export enum AcceptanceAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  AcceptanceAuditPkey = 'acceptance_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AcceptanceAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AcceptanceAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AcceptanceAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.acceptance_audit" */
export type AcceptanceAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.acceptance_audit" */
export type AcceptanceAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AcceptanceAuditMaxFields = {
  __typename?: 'acceptance_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateAcceptedFrom?: Maybe<Scalars['timestamptz']['output']>;
  DateAcceptedTo?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AcceptanceAuditMinFields = {
  __typename?: 'acceptance_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateAcceptedFrom?: Maybe<Scalars['timestamptz']['output']>;
  DateAcceptedTo?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.acceptance_audit" */
export type AcceptanceAuditMutationResponse = {
  __typename?: 'acceptance_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AcceptanceAudit>;
};

/** on_conflict condition type for table "risksmart.acceptance_audit" */
export type AcceptanceAuditOnConflict = {
  constraint: AcceptanceAuditConstraint;
  update_columns?: Array<AcceptanceAuditUpdateColumn>;
  where?: InputMaybe<AcceptanceAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.acceptance_audit". */
export type AcceptanceAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ApprovedByUser?: InputMaybe<OrderBy>;
  ApprovedByUserGroup?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DateAcceptedFrom?: InputMaybe<OrderBy>;
  DateAcceptedTo?: InputMaybe<OrderBy>;
  Details?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RequestedByUser?: InputMaybe<OrderBy>;
  RequestedByUserGroup?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.acceptance_audit */
export type AcceptanceAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AcceptanceAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.acceptance_audit" */
export enum AcceptanceAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ApprovedByUser = 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup = 'ApprovedByUserGroup',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom = 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo = 'DateAcceptedTo',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RequestedByUser = 'RequestedByUser',
  /** column name */
  RequestedByUserGroup = 'RequestedByUserGroup',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.acceptance_audit" */
export type AcceptanceAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AcceptanceAuditStddevFields = {
  __typename?: 'acceptance_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type AcceptanceAuditStddevPopFields = {
  __typename?: 'acceptance_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type AcceptanceAuditStddevSampFields = {
  __typename?: 'acceptance_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "acceptance_audit" */
export type AcceptanceAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AcceptanceAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AcceptanceAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AcceptanceAuditSumFields = {
  __typename?: 'acceptance_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.acceptance_audit" */
export enum AcceptanceAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ApprovedByUser = 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup = 'ApprovedByUserGroup',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom = 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo = 'DateAcceptedTo',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RequestedByUser = 'RequestedByUser',
  /** column name */
  RequestedByUserGroup = 'RequestedByUserGroup',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

export type AcceptanceAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AcceptanceAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AcceptanceAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AcceptanceAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AcceptanceAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AcceptanceAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AcceptanceAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AcceptanceAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AcceptanceAuditBoolExp;
};

/** aggregate var_pop on columns */
export type AcceptanceAuditVarPopFields = {
  __typename?: 'acceptance_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type AcceptanceAuditVarSampFields = {
  __typename?: 'acceptance_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AcceptanceAuditVarianceFields = {
  __typename?: 'acceptance_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type AcceptanceAvgFields = {
  __typename?: 'acceptance_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance". All fields are combined with a logical 'AND'. */
export type AcceptanceBoolExp = {
  ApprovedByUser?: InputMaybe<StringComparisonExp>;
  ApprovedByUserGroup?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DateAcceptedFrom?: InputMaybe<TimestamptzComparisonExp>;
  DateAcceptedTo?: InputMaybe<TimestamptzComparisonExp>;
  Details?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RequestedByUser?: InputMaybe<StringComparisonExp>;
  RequestedByUserGroup?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<AcceptanceStatusEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AcceptanceBoolExp>>;
  _not?: InputMaybe<AcceptanceBoolExp>;
  _or?: InputMaybe<Array<AcceptanceBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  approvedByUser?: InputMaybe<UserBoolExp>;
  approvedByUserGroup?: InputMaybe<UserGroupBoolExp>;
  changeRequests?: InputMaybe<ChangeRequestBoolExp>;
  changeRequests_aggregate?: InputMaybe<ChangeRequestAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parents?: InputMaybe<AcceptanceParentBoolExp>;
  parents_aggregate?: InputMaybe<AcceptanceParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  requestedByUser?: InputMaybe<UserBoolExp>;
  requestedByUserGroup?: InputMaybe<UserGroupBoolExp>;
  status?: InputMaybe<AcceptanceStatusBoolExp>;
};

/** unique or primary key constraints on table "risksmart.acceptance" */
export enum AcceptanceConstraint {
  /** unique or primary key constraint on columns "Id" */
  AcceptancePkey = 'acceptance_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxAcceptanceOrgkeySequentialid = 'idx_acceptance_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AcceptanceDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AcceptanceDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AcceptanceDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.acceptance" */
export type AcceptanceIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.acceptance" */
export type AcceptanceInsertInput = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<AcceptanceStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  approvedByUser?: InputMaybe<UserObjRelInsertInput>;
  approvedByUserGroup?: InputMaybe<UserGroupObjRelInsertInput>;
  changeRequests?: InputMaybe<ChangeRequestArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parents?: InputMaybe<AcceptanceParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  requestedByUser?: InputMaybe<UserObjRelInsertInput>;
  requestedByUserGroup?: InputMaybe<UserGroupObjRelInsertInput>;
  status?: InputMaybe<AcceptanceStatusObjRelInsertInput>;
};

/** aggregate max on columns */
export type AcceptanceMaxFields = {
  __typename?: 'acceptance_max_fields';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateAcceptedFrom?: Maybe<Scalars['timestamptz']['output']>;
  DateAcceptedTo?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AcceptanceMinFields = {
  __typename?: 'acceptance_min_fields';
  ApprovedByUser?: Maybe<Scalars['String']['output']>;
  ApprovedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateAcceptedFrom?: Maybe<Scalars['timestamptz']['output']>;
  DateAcceptedTo?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RequestedByUser?: Maybe<Scalars['String']['output']>;
  RequestedByUserGroup?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.acceptance" */
export type AcceptanceMutationResponse = {
  __typename?: 'acceptance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Acceptance>;
};

/** input type for inserting object relation for remote table "risksmart.acceptance" */
export type AcceptanceObjRelInsertInput = {
  data: AcceptanceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AcceptanceOnConflict>;
};

/** on_conflict condition type for table "risksmart.acceptance" */
export type AcceptanceOnConflict = {
  constraint: AcceptanceConstraint;
  update_columns?: Array<AcceptanceUpdateColumn>;
  where?: InputMaybe<AcceptanceBoolExp>;
};

/** Ordering options when selecting data from "risksmart.acceptance". */
export type AcceptanceOrderBy = {
  ApprovedByUser?: InputMaybe<OrderBy>;
  ApprovedByUserGroup?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DateAcceptedFrom?: InputMaybe<OrderBy>;
  DateAcceptedTo?: InputMaybe<OrderBy>;
  Details?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RequestedByUser?: InputMaybe<OrderBy>;
  RequestedByUserGroup?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  approvedByUser?: InputMaybe<UserOrderBy>;
  approvedByUserGroup?: InputMaybe<UserGroupOrderBy>;
  changeRequests_aggregate?: InputMaybe<ChangeRequestAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parents_aggregate?: InputMaybe<AcceptanceParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  requestedByUser?: InputMaybe<UserOrderBy>;
  requestedByUserGroup?: InputMaybe<UserGroupOrderBy>;
  status?: InputMaybe<AcceptanceStatusOrderBy>;
};

/** columns and relationships of "risksmart.acceptance_parent" */
export type AcceptanceParent = {
  __typename?: 'acceptance_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.acceptance_parent" */
export type AcceptanceParentAggregate = {
  __typename?: 'acceptance_parent_aggregate';
  aggregate?: Maybe<AcceptanceParentAggregateFields>;
  nodes: Array<AcceptanceParent>;
};

export type AcceptanceParentAggregateBoolExp = {
  count?: InputMaybe<AcceptanceParentAggregateBoolExpCount>;
};

export type AcceptanceParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AcceptanceParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.acceptance_parent" */
export type AcceptanceParentAggregateFields = {
  __typename?: 'acceptance_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AcceptanceParentMaxFields>;
  min?: Maybe<AcceptanceParentMinFields>;
};


/** aggregate fields of "risksmart.acceptance_parent" */
export type AcceptanceParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.acceptance_parent" */
export type AcceptanceParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AcceptanceParentMaxOrderBy>;
  min?: InputMaybe<AcceptanceParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.acceptance_parent" */
export type AcceptanceParentArrRelInsertInput = {
  data: Array<AcceptanceParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AcceptanceParentOnConflict>;
};

/** columns and relationships of "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAudit = {
  __typename?: 'acceptance_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditAggregate = {
  __typename?: 'acceptance_parent_audit_aggregate';
  aggregate?: Maybe<AcceptanceParentAuditAggregateFields>;
  nodes: Array<AcceptanceParentAudit>;
};

export type AcceptanceParentAuditAggregateBoolExp = {
  count?: InputMaybe<AcceptanceParentAuditAggregateBoolExpCount>;
};

export type AcceptanceParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AcceptanceParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditAggregateFields = {
  __typename?: 'acceptance_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AcceptanceParentAuditMaxFields>;
  min?: Maybe<AcceptanceParentAuditMinFields>;
};


/** aggregate fields of "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AcceptanceParentAuditMaxOrderBy>;
  min?: InputMaybe<AcceptanceParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditArrRelInsertInput = {
  data: Array<AcceptanceParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AcceptanceParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_parent_audit". All fields are combined with a logical 'AND'. */
export type AcceptanceParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<AcceptanceParentAuditBoolExp>>;
  _not?: InputMaybe<AcceptanceParentAuditBoolExp>;
  _or?: InputMaybe<Array<AcceptanceParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.acceptance_parent_audit" */
export enum AcceptanceParentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "Id", "ModifiedAtTimestamp" */
  AcceptanceParentAuditPkey = 'acceptance_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AcceptanceParentAuditMaxFields = {
  __typename?: 'acceptance_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AcceptanceParentAuditMinFields = {
  __typename?: 'acceptance_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditMutationResponse = {
  __typename?: 'acceptance_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AcceptanceParentAudit>;
};

/** on_conflict condition type for table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditOnConflict = {
  constraint: AcceptanceParentAuditConstraint;
  update_columns?: Array<AcceptanceParentAuditUpdateColumn>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.acceptance_parent_audit". */
export type AcceptanceParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.acceptance_parent_audit */
export type AcceptanceParentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.acceptance_parent_audit" */
export enum AcceptanceParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.acceptance_parent_audit" */
export type AcceptanceParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "acceptance_parent_audit" */
export type AcceptanceParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AcceptanceParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AcceptanceParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.acceptance_parent_audit" */
export enum AcceptanceParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type AcceptanceParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AcceptanceParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AcceptanceParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_parent". All fields are combined with a logical 'AND'. */
export type AcceptanceParentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<AcceptanceParentBoolExp>>;
  _not?: InputMaybe<AcceptanceParentBoolExp>;
  _or?: InputMaybe<Array<AcceptanceParentBoolExp>>;
  acceptance?: InputMaybe<AcceptanceBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.acceptance_parent" */
export enum AcceptanceParentConstraint {
  /** unique or primary key constraint on columns "ParentId", "Id" */
  AcceptanceParentPkey = 'acceptance_parent_pkey'
}

/** input type for inserting data into table "risksmart.acceptance_parent" */
export type AcceptanceParentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  acceptance?: InputMaybe<AcceptanceObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type AcceptanceParentMaxFields = {
  __typename?: 'acceptance_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.acceptance_parent" */
export type AcceptanceParentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AcceptanceParentMinFields = {
  __typename?: 'acceptance_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.acceptance_parent" */
export type AcceptanceParentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.acceptance_parent" */
export type AcceptanceParentMutationResponse = {
  __typename?: 'acceptance_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AcceptanceParent>;
};

/** on_conflict condition type for table "risksmart.acceptance_parent" */
export type AcceptanceParentOnConflict = {
  constraint: AcceptanceParentConstraint;
  update_columns?: Array<AcceptanceParentUpdateColumn>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.acceptance_parent". */
export type AcceptanceParentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  acceptance?: InputMaybe<AcceptanceOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.acceptance_parent */
export type AcceptanceParentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.acceptance_parent" */
export enum AcceptanceParentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.acceptance_parent" */
export type AcceptanceParentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "acceptance_parent" */
export type AcceptanceParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AcceptanceParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AcceptanceParentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.acceptance_parent" */
export enum AcceptanceParentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type AcceptanceParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AcceptanceParentSetInput>;
  /** filter the rows which have to be updated */
  where: AcceptanceParentBoolExp;
};

/** primary key columns input for table: risksmart.acceptance */
export type AcceptancePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AcceptancePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.acceptance" */
export enum AcceptanceSelectColumn {
  /** column name */
  ApprovedByUser = 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup = 'ApprovedByUserGroup',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom = 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo = 'DateAcceptedTo',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RequestedByUser = 'RequestedByUser',
  /** column name */
  RequestedByUserGroup = 'RequestedByUserGroup',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.acceptance" */
export type AcceptanceSetInput = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<AcceptanceStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.acceptance_status" */
export type AcceptanceStatus = {
  __typename?: 'acceptance_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.acceptance_status" */
export type AcceptanceStatusAggregate = {
  __typename?: 'acceptance_status_aggregate';
  aggregate?: Maybe<AcceptanceStatusAggregateFields>;
  nodes: Array<AcceptanceStatus>;
};

/** aggregate fields of "risksmart.acceptance_status" */
export type AcceptanceStatusAggregateFields = {
  __typename?: 'acceptance_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AcceptanceStatusMaxFields>;
  min?: Maybe<AcceptanceStatusMinFields>;
};


/** aggregate fields of "risksmart.acceptance_status" */
export type AcceptanceStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AcceptanceStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.acceptance_status". All fields are combined with a logical 'AND'. */
export type AcceptanceStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AcceptanceStatusBoolExp>>;
  _not?: InputMaybe<AcceptanceStatusBoolExp>;
  _or?: InputMaybe<Array<AcceptanceStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.acceptance_status" */
export enum AcceptanceStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  AcceptanceStatusPkey = 'acceptance_status_pkey'
}

export enum AcceptanceStatusEnum {
  /** awaiting closure */
  Awaitingclosure = 'awaitingclosure',
  /** closed */
  Closed = 'closed',
  /** declined */
  Declined = 'declined',
  /** 1st line approval */
  Firstlineapproval = 'firstlineapproval',
  /** open */
  Open = 'open',
  /** pending */
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "acceptance_status_enum". All fields are combined with logical 'AND'. */
export type AcceptanceStatusEnumComparisonExp = {
  _eq?: InputMaybe<AcceptanceStatusEnum>;
  _in?: InputMaybe<Array<AcceptanceStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AcceptanceStatusEnum>;
  _nin?: InputMaybe<Array<AcceptanceStatusEnum>>;
};

/** input type for inserting data into table "risksmart.acceptance_status" */
export type AcceptanceStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AcceptanceStatusMaxFields = {
  __typename?: 'acceptance_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AcceptanceStatusMinFields = {
  __typename?: 'acceptance_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.acceptance_status" */
export type AcceptanceStatusMutationResponse = {
  __typename?: 'acceptance_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AcceptanceStatus>;
};

/** input type for inserting object relation for remote table "risksmart.acceptance_status" */
export type AcceptanceStatusObjRelInsertInput = {
  data: AcceptanceStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AcceptanceStatusOnConflict>;
};

/** on_conflict condition type for table "risksmart.acceptance_status" */
export type AcceptanceStatusOnConflict = {
  constraint: AcceptanceStatusConstraint;
  update_columns?: Array<AcceptanceStatusUpdateColumn>;
  where?: InputMaybe<AcceptanceStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.acceptance_status". */
export type AcceptanceStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.acceptance_status */
export type AcceptanceStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.acceptance_status" */
export enum AcceptanceStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.acceptance_status" */
export type AcceptanceStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "acceptance_status" */
export type AcceptanceStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AcceptanceStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AcceptanceStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.acceptance_status" */
export enum AcceptanceStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AcceptanceStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AcceptanceStatusSetInput>;
  /** filter the rows which have to be updated */
  where: AcceptanceStatusBoolExp;
};

/** aggregate stddev on columns */
export type AcceptanceStddevFields = {
  __typename?: 'acceptance_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type AcceptanceStddevPopFields = {
  __typename?: 'acceptance_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type AcceptanceStddevSampFields = {
  __typename?: 'acceptance_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "acceptance" */
export type AcceptanceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AcceptanceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AcceptanceStreamCursorValueInput = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom?: InputMaybe<Scalars['timestamptz']['input']>;
  DateAcceptedTo?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<AcceptanceStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AcceptanceSumFields = {
  __typename?: 'acceptance_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.acceptance" */
export enum AcceptanceUpdateColumn {
  /** column name */
  ApprovedByUser = 'ApprovedByUser',
  /** column name */
  ApprovedByUserGroup = 'ApprovedByUserGroup',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateAcceptedFrom = 'DateAcceptedFrom',
  /** column name */
  DateAcceptedTo = 'DateAcceptedTo',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RequestedByUser = 'RequestedByUser',
  /** column name */
  RequestedByUserGroup = 'RequestedByUserGroup',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

export type AcceptanceUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AcceptanceAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AcceptanceDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AcceptanceDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AcceptanceDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AcceptanceIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AcceptancePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AcceptanceSetInput>;
  /** filter the rows which have to be updated */
  where: AcceptanceBoolExp;
};

/** aggregate var_pop on columns */
export type AcceptanceVarPopFields = {
  __typename?: 'acceptance_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type AcceptanceVarSampFields = {
  __typename?: 'acceptance_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AcceptanceVarianceFields = {
  __typename?: 'acceptance_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.access_type" */
export type AccessType = {
  __typename?: 'access_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.access_type" */
export type AccessTypeAggregate = {
  __typename?: 'access_type_aggregate';
  aggregate?: Maybe<AccessTypeAggregateFields>;
  nodes: Array<AccessType>;
};

/** aggregate fields of "risksmart.access_type" */
export type AccessTypeAggregateFields = {
  __typename?: 'access_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AccessTypeMaxFields>;
  min?: Maybe<AccessTypeMinFields>;
};


/** aggregate fields of "risksmart.access_type" */
export type AccessTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AccessTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.access_type". All fields are combined with a logical 'AND'. */
export type AccessTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AccessTypeBoolExp>>;
  _not?: InputMaybe<AccessTypeBoolExp>;
  _or?: InputMaybe<Array<AccessTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.access_type" */
export enum AccessTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  AccessTypePkey = 'access_type_pkey'
}

export enum AccessTypeEnum {
  /** Delete */
  Delete = 'delete',
  /** Insert */
  Insert = 'insert',
  /** Read */
  Read = 'read',
  /** Update */
  Update = 'update'
}

/** Boolean expression to compare columns of type "access_type_enum". All fields are combined with logical 'AND'. */
export type AccessTypeEnumComparisonExp = {
  _eq?: InputMaybe<AccessTypeEnum>;
  _in?: InputMaybe<Array<AccessTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AccessTypeEnum>;
  _nin?: InputMaybe<Array<AccessTypeEnum>>;
};

/** input type for inserting data into table "risksmart.access_type" */
export type AccessTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AccessTypeMaxFields = {
  __typename?: 'access_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AccessTypeMinFields = {
  __typename?: 'access_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.access_type" */
export type AccessTypeMutationResponse = {
  __typename?: 'access_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AccessType>;
};

/** on_conflict condition type for table "risksmart.access_type" */
export type AccessTypeOnConflict = {
  constraint: AccessTypeConstraint;
  update_columns?: Array<AccessTypeUpdateColumn>;
  where?: InputMaybe<AccessTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.access_type". */
export type AccessTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.access_type */
export type AccessTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.access_type" */
export enum AccessTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.access_type" */
export type AccessTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "access_type" */
export type AccessTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AccessTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AccessTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.access_type" */
export enum AccessTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AccessTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AccessTypeSetInput>;
  /** filter the rows which have to be updated */
  where: AccessTypeBoolExp;
};

/** columns and relationships of "risksmart.action" */
export type Action = {
  __typename?: 'action';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateDue: Scalars['timestamptz']['output'];
  DateRaised: Scalars['timestamptz']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: ActionStatusEnum;
  Title: Scalars['String']['output'];
  /** An object relationship */
  actionUpdateSummary?: Maybe<ActionUpdateSummary>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  parents: Array<ActionParent>;
  /** An aggregate relationship */
  parents_aggregate: ActionParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  status: ActionStatus;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An array relationship */
  updates: Array<ActionUpdate>;
  /** An aggregate relationship */
  updates_aggregate: ActionUpdateAggregate;
};


/** columns and relationships of "risksmart.action" */
export type ActionCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action" */
export type ActionMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action" */
export type ActionAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionParentsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionUpdatesArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateOrderBy>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};


/** columns and relationships of "risksmart.action" */
export type ActionUpdatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateOrderBy>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};

/** aggregated selection of "risksmart.action" */
export type ActionAggregate = {
  __typename?: 'action_aggregate';
  aggregate?: Maybe<ActionAggregateFields>;
  nodes: Array<Action>;
};

/** aggregate fields of "risksmart.action" */
export type ActionAggregateFields = {
  __typename?: 'action_aggregate_fields';
  avg?: Maybe<ActionAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ActionMaxFields>;
  min?: Maybe<ActionMinFields>;
  stddev?: Maybe<ActionStddevFields>;
  stddev_pop?: Maybe<ActionStddevPopFields>;
  stddev_samp?: Maybe<ActionStddevSampFields>;
  sum?: Maybe<ActionSumFields>;
  var_pop?: Maybe<ActionVarPopFields>;
  var_samp?: Maybe<ActionVarSampFields>;
  variance?: Maybe<ActionVarianceFields>;
};


/** aggregate fields of "risksmart.action" */
export type ActionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ActionAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.action_audit" */
export type ActionAudit = {
  __typename?: 'action_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateDue: Scalars['timestamptz']['output'];
  DateRaised: Scalars['timestamptz']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.action_audit" */
export type ActionAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action_audit" */
export type ActionAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.action_audit" */
export type ActionAuditAggregate = {
  __typename?: 'action_audit_aggregate';
  aggregate?: Maybe<ActionAuditAggregateFields>;
  nodes: Array<ActionAudit>;
};

/** aggregate fields of "risksmart.action_audit" */
export type ActionAuditAggregateFields = {
  __typename?: 'action_audit_aggregate_fields';
  avg?: Maybe<ActionAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ActionAuditMaxFields>;
  min?: Maybe<ActionAuditMinFields>;
  stddev?: Maybe<ActionAuditStddevFields>;
  stddev_pop?: Maybe<ActionAuditStddevPopFields>;
  stddev_samp?: Maybe<ActionAuditStddevSampFields>;
  sum?: Maybe<ActionAuditSumFields>;
  var_pop?: Maybe<ActionAuditVarPopFields>;
  var_samp?: Maybe<ActionAuditVarSampFields>;
  variance?: Maybe<ActionAuditVarianceFields>;
};


/** aggregate fields of "risksmart.action_audit" */
export type ActionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ActionAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ActionAuditAvgFields = {
  __typename?: 'action_audit_avg_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_audit". All fields are combined with a logical 'AND'. */
export type ActionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ClosedDate?: InputMaybe<TimestamptzComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DateDue?: InputMaybe<TimestamptzComparisonExp>;
  DateRaised?: InputMaybe<TimestamptzComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Priority?: InputMaybe<IntComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionAuditBoolExp>>;
  _not?: InputMaybe<ActionAuditBoolExp>;
  _or?: InputMaybe<Array<ActionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.action_audit" */
export enum ActionAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ActionAuditPkey = 'action_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ActionAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ActionAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ActionAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.action_audit" */
export type ActionAuditIncInput = {
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.action_audit" */
export type ActionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ActionAuditMaxFields = {
  __typename?: 'action_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ActionAuditMinFields = {
  __typename?: 'action_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.action_audit" */
export type ActionAuditMutationResponse = {
  __typename?: 'action_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionAudit>;
};

/** on_conflict condition type for table "risksmart.action_audit" */
export type ActionAuditOnConflict = {
  constraint: ActionAuditConstraint;
  update_columns?: Array<ActionAuditUpdateColumn>;
  where?: InputMaybe<ActionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action_audit". */
export type ActionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ClosedDate?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DateDue?: InputMaybe<OrderBy>;
  DateRaised?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Priority?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.action_audit */
export type ActionAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ActionAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.action_audit" */
export enum ActionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ClosedDate = 'ClosedDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateDue = 'DateDue',
  /** column name */
  DateRaised = 'DateRaised',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Priority = 'Priority',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.action_audit" */
export type ActionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ActionAuditStddevFields = {
  __typename?: 'action_audit_stddev_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ActionAuditStddevPopFields = {
  __typename?: 'action_audit_stddev_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ActionAuditStddevSampFields = {
  __typename?: 'action_audit_stddev_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action_audit" */
export type ActionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ActionAuditSumFields = {
  __typename?: 'action_audit_sum_fields';
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.action_audit" */
export enum ActionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ClosedDate = 'ClosedDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateDue = 'DateDue',
  /** column name */
  DateRaised = 'DateRaised',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Priority = 'Priority',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

export type ActionAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ActionAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ActionAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ActionAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ActionAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActionAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ActionAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ActionAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ActionAuditVarPopFields = {
  __typename?: 'action_audit_var_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ActionAuditVarSampFields = {
  __typename?: 'action_audit_var_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ActionAuditVarianceFields = {
  __typename?: 'action_audit_variance_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ActionAvgFields = {
  __typename?: 'action_avg_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.action". All fields are combined with a logical 'AND'. */
export type ActionBoolExp = {
  ClosedDate?: InputMaybe<TimestamptzComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DateDue?: InputMaybe<TimestamptzComparisonExp>;
  DateRaised?: InputMaybe<TimestamptzComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Priority?: InputMaybe<IntComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<ActionStatusEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionBoolExp>>;
  _not?: InputMaybe<ActionBoolExp>;
  _or?: InputMaybe<Array<ActionBoolExp>>;
  actionUpdateSummary?: InputMaybe<ActionUpdateSummaryBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parents?: InputMaybe<ActionParentBoolExp>;
  parents_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  status?: InputMaybe<ActionStatusBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  updates?: InputMaybe<ActionUpdateBoolExp>;
  updates_aggregate?: InputMaybe<ActionUpdateAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.action" */
export enum ActionConstraint {
  /** unique or primary key constraint on columns "Id" */
  ActionPkey = 'action_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxActionOrgkeySequentialid = 'idx_action_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ActionDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ActionDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ActionDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.action" */
export type ActionIncInput = {
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.action" */
export type ActionInsertInput = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<ActionStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actionUpdateSummary?: InputMaybe<ActionUpdateSummaryObjRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parents?: InputMaybe<ActionParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  status?: InputMaybe<ActionStatusObjRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  updates?: InputMaybe<ActionUpdateArrRelInsertInput>;
};

/** aggregate max on columns */
export type ActionMaxFields = {
  __typename?: 'action_max_fields';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ActionMinFields = {
  __typename?: 'action_min_fields';
  ClosedDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateDue?: Maybe<Scalars['timestamptz']['output']>;
  DateRaised?: Maybe<Scalars['timestamptz']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.action" */
export type ActionMutationResponse = {
  __typename?: 'action_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Action>;
};

/** input type for inserting object relation for remote table "risksmart.action" */
export type ActionObjRelInsertInput = {
  data: ActionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionOnConflict>;
};

/** on_conflict condition type for table "risksmart.action" */
export type ActionOnConflict = {
  constraint: ActionConstraint;
  update_columns?: Array<ActionUpdateColumn>;
  where?: InputMaybe<ActionBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action". */
export type ActionOrderBy = {
  ClosedDate?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DateDue?: InputMaybe<OrderBy>;
  DateRaised?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Priority?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  actionUpdateSummary?: InputMaybe<ActionUpdateSummaryOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parents_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  status?: InputMaybe<ActionStatusOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  updates_aggregate?: InputMaybe<ActionUpdateAggregateOrderBy>;
};

/** columns and relationships of "risksmart.action_parent" */
export type ActionParent = {
  __typename?: 'action_parent';
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: ParentTypeEnum;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  complianceMonitoringAssessment?: Maybe<ComplianceMonitoringAssessment>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  internalAuditEntity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  internalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.action_parent" */
export type ActionParentAggregate = {
  __typename?: 'action_parent_aggregate';
  aggregate?: Maybe<ActionParentAggregateFields>;
  nodes: Array<ActionParent>;
};

export type ActionParentAggregateBoolExp = {
  count?: InputMaybe<ActionParentAggregateBoolExpCount>;
};

export type ActionParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ActionParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ActionParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.action_parent" */
export type ActionParentAggregateFields = {
  __typename?: 'action_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ActionParentMaxFields>;
  min?: Maybe<ActionParentMinFields>;
};


/** aggregate fields of "risksmart.action_parent" */
export type ActionParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.action_parent" */
export type ActionParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActionParentMaxOrderBy>;
  min?: InputMaybe<ActionParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.action_parent" */
export type ActionParentArrRelInsertInput = {
  data: Array<ActionParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionParentOnConflict>;
};

/** columns and relationships of "risksmart.action_parent_audit" */
export type ActionParentAudit = {
  __typename?: 'action_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.action_parent_audit" */
export type ActionParentAuditAggregate = {
  __typename?: 'action_parent_audit_aggregate';
  aggregate?: Maybe<ActionParentAuditAggregateFields>;
  nodes: Array<ActionParentAudit>;
};

export type ActionParentAuditAggregateBoolExp = {
  count?: InputMaybe<ActionParentAuditAggregateBoolExpCount>;
};

export type ActionParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ActionParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.action_parent_audit" */
export type ActionParentAuditAggregateFields = {
  __typename?: 'action_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ActionParentAuditMaxFields>;
  min?: Maybe<ActionParentAuditMinFields>;
};


/** aggregate fields of "risksmart.action_parent_audit" */
export type ActionParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.action_parent_audit" */
export type ActionParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActionParentAuditMaxOrderBy>;
  min?: InputMaybe<ActionParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.action_parent_audit" */
export type ActionParentAuditArrRelInsertInput = {
  data: Array<ActionParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.action_parent_audit". All fields are combined with a logical 'AND'. */
export type ActionParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActionId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionParentAuditBoolExp>>;
  _not?: InputMaybe<ActionParentAuditBoolExp>;
  _or?: InputMaybe<Array<ActionParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.action_parent_audit" */
export enum ActionParentAuditConstraint {
  /** unique or primary key constraint on columns "ActionId", "ParentId", "ModifiedAtTimestamp" */
  ActionParentAuditPkey = 'action_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.action_parent_audit" */
export type ActionParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ActionParentAuditMaxFields = {
  __typename?: 'action_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.action_parent_audit" */
export type ActionParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ActionParentAuditMinFields = {
  __typename?: 'action_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.action_parent_audit" */
export type ActionParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.action_parent_audit" */
export type ActionParentAuditMutationResponse = {
  __typename?: 'action_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionParentAudit>;
};

/** on_conflict condition type for table "risksmart.action_parent_audit" */
export type ActionParentAuditOnConflict = {
  constraint: ActionParentAuditConstraint;
  update_columns?: Array<ActionParentAuditUpdateColumn>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action_parent_audit". */
export type ActionParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.action_parent_audit */
export type ActionParentAuditPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.action_parent_audit" */
export enum ActionParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.action_parent_audit" */
export type ActionParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "action_parent_audit" */
export type ActionParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.action_parent_audit" */
export enum ActionParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

export type ActionParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ActionParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.action_parent". All fields are combined with a logical 'AND'. */
export type ActionParentBoolExp = {
  ActionId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<ActionParentBoolExp>>;
  _not?: InputMaybe<ActionParentBoolExp>;
  _or?: InputMaybe<Array<ActionParentBoolExp>>;
  action?: InputMaybe<ActionBoolExp>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
  control?: InputMaybe<ControlBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  document?: InputMaybe<DocumentBoolExp>;
  internalAuditEntity?: InputMaybe<InternalAuditEntityBoolExp>;
  internalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  obligation?: InputMaybe<ObligationBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.action_parent" */
export enum ActionParentConstraint {
  /** unique or primary key constraint on columns "ActionId", "ParentId" */
  ActionParentPkey = 'action_parent_pkey'
}

/** input type for inserting data into table "risksmart.action_parent" */
export type ActionParentInsertInput = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  action?: InputMaybe<ActionObjRelInsertInput>;
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentObjRelInsertInput>;
  control?: InputMaybe<ControlObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  internalAuditEntity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  internalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  obligation?: InputMaybe<ObligationObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type ActionParentMaxFields = {
  __typename?: 'action_parent_max_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.action_parent" */
export type ActionParentMaxOrderBy = {
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ActionParentMinFields = {
  __typename?: 'action_parent_min_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.action_parent" */
export type ActionParentMinOrderBy = {
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.action_parent" */
export type ActionParentMutationResponse = {
  __typename?: 'action_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionParent>;
};

/** on_conflict condition type for table "risksmart.action_parent" */
export type ActionParentOnConflict = {
  constraint: ActionParentConstraint;
  update_columns?: Array<ActionParentUpdateColumn>;
  where?: InputMaybe<ActionParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action_parent". */
export type ActionParentOrderBy = {
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  action?: InputMaybe<ActionOrderBy>;
  assessment?: InputMaybe<AssessmentOrderBy>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentOrderBy>;
  control?: InputMaybe<ControlOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  internalAuditEntity?: InputMaybe<InternalAuditEntityOrderBy>;
  internalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  obligation?: InputMaybe<ObligationOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.action_parent */
export type ActionParentPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.action_parent" */
export enum ActionParentSelectColumn {
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.action_parent" */
export type ActionParentSetInput = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** Streaming cursor of the table "action_parent" */
export type ActionParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionParentStreamCursorValueInput = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** update columns of table "risksmart.action_parent" */
export enum ActionParentUpdateColumn {
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

export type ActionParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionParentSetInput>;
  /** filter the rows which have to be updated */
  where: ActionParentBoolExp;
};

/** primary key columns input for table: risksmart.action */
export type ActionPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ActionPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.action" */
export enum ActionSelectColumn {
  /** column name */
  ClosedDate = 'ClosedDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateDue = 'DateDue',
  /** column name */
  DateRaised = 'DateRaised',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Priority = 'Priority',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.action" */
export type ActionSetInput = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<ActionStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.action_status" */
export type ActionStatus = {
  __typename?: 'action_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.action_status" */
export type ActionStatusAggregate = {
  __typename?: 'action_status_aggregate';
  aggregate?: Maybe<ActionStatusAggregateFields>;
  nodes: Array<ActionStatus>;
};

/** aggregate fields of "risksmart.action_status" */
export type ActionStatusAggregateFields = {
  __typename?: 'action_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ActionStatusMaxFields>;
  min?: Maybe<ActionStatusMinFields>;
};


/** aggregate fields of "risksmart.action_status" */
export type ActionStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_status". All fields are combined with a logical 'AND'. */
export type ActionStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionStatusBoolExp>>;
  _not?: InputMaybe<ActionStatusBoolExp>;
  _or?: InputMaybe<Array<ActionStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.action_status" */
export enum ActionStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  ActionStatusPkey = 'action_status_pkey'
}

export enum ActionStatusEnum {
  /** Closed */
  Closed = 'closed',
  /** Open */
  Open = 'open',
  /** Pending */
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "action_status_enum". All fields are combined with logical 'AND'. */
export type ActionStatusEnumComparisonExp = {
  _eq?: InputMaybe<ActionStatusEnum>;
  _in?: InputMaybe<Array<ActionStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ActionStatusEnum>;
  _nin?: InputMaybe<Array<ActionStatusEnum>>;
};

/** input type for inserting data into table "risksmart.action_status" */
export type ActionStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ActionStatusMaxFields = {
  __typename?: 'action_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ActionStatusMinFields = {
  __typename?: 'action_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.action_status" */
export type ActionStatusMutationResponse = {
  __typename?: 'action_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionStatus>;
};

/** input type for inserting object relation for remote table "risksmart.action_status" */
export type ActionStatusObjRelInsertInput = {
  data: ActionStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionStatusOnConflict>;
};

/** on_conflict condition type for table "risksmart.action_status" */
export type ActionStatusOnConflict = {
  constraint: ActionStatusConstraint;
  update_columns?: Array<ActionStatusUpdateColumn>;
  where?: InputMaybe<ActionStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action_status". */
export type ActionStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.action_status */
export type ActionStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.action_status" */
export enum ActionStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.action_status" */
export type ActionStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "action_status" */
export type ActionStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.action_status" */
export enum ActionStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ActionStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ActionStatusBoolExp;
};

/** aggregate stddev on columns */
export type ActionStddevFields = {
  __typename?: 'action_stddev_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ActionStddevPopFields = {
  __typename?: 'action_stddev_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ActionStddevSampFields = {
  __typename?: 'action_stddev_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action" */
export type ActionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionStreamCursorValueInput = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue?: InputMaybe<Scalars['timestamptz']['input']>;
  DateRaised?: InputMaybe<Scalars['timestamptz']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<ActionStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ActionSumFields = {
  __typename?: 'action_sum_fields';
  Priority?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.action_update" */
export type ActionUpdate = {
  __typename?: 'action_update';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentActionId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.action_update" */
export type ActionUpdateCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action_update" */
export type ActionUpdateMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action_update" */
export type ActionUpdateFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.action_update" */
export type ActionUpdateFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.action_update" */
export type ActionUpdatePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.action_update" */
export type ActionUpdatePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.action_update" */
export type ActionUpdateAggregate = {
  __typename?: 'action_update_aggregate';
  aggregate?: Maybe<ActionUpdateAggregateFields>;
  nodes: Array<ActionUpdate>;
};

export type ActionUpdateAggregateBoolExp = {
  count?: InputMaybe<ActionUpdateAggregateBoolExpCount>;
};

export type ActionUpdateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ActionUpdateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.action_update" */
export type ActionUpdateAggregateFields = {
  __typename?: 'action_update_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ActionUpdateMaxFields>;
  min?: Maybe<ActionUpdateMinFields>;
};


/** aggregate fields of "risksmart.action_update" */
export type ActionUpdateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.action_update" */
export type ActionUpdateAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ActionUpdateMaxOrderBy>;
  min?: InputMaybe<ActionUpdateMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ActionUpdateAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.action_update" */
export type ActionUpdateArrRelInsertInput = {
  data: Array<ActionUpdateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionUpdateOnConflict>;
};

/** columns and relationships of "risksmart.action_update_audit" */
export type ActionUpdateAudit = {
  __typename?: 'action_update_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentActionId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.action_update_audit" */
export type ActionUpdateAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.action_update_audit" */
export type ActionUpdateAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.action_update_audit" */
export type ActionUpdateAuditAggregate = {
  __typename?: 'action_update_audit_aggregate';
  aggregate?: Maybe<ActionUpdateAuditAggregateFields>;
  nodes: Array<ActionUpdateAudit>;
};

/** aggregate fields of "risksmart.action_update_audit" */
export type ActionUpdateAuditAggregateFields = {
  __typename?: 'action_update_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ActionUpdateAuditMaxFields>;
  min?: Maybe<ActionUpdateAuditMinFields>;
};


/** aggregate fields of "risksmart.action_update_audit" */
export type ActionUpdateAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionUpdateAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ActionUpdateAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_update_audit". All fields are combined with a logical 'AND'. */
export type ActionUpdateAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentActionId?: InputMaybe<UuidComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionUpdateAuditBoolExp>>;
  _not?: InputMaybe<ActionUpdateAuditBoolExp>;
  _or?: InputMaybe<Array<ActionUpdateAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.action_update_audit" */
export enum ActionUpdateAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ActionUpdateAuditPkey = 'action_update_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ActionUpdateAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ActionUpdateAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ActionUpdateAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.action_update_audit" */
export type ActionUpdateAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ActionUpdateAuditMaxFields = {
  __typename?: 'action_update_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentActionId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ActionUpdateAuditMinFields = {
  __typename?: 'action_update_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentActionId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.action_update_audit" */
export type ActionUpdateAuditMutationResponse = {
  __typename?: 'action_update_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionUpdateAudit>;
};

/** on_conflict condition type for table "risksmart.action_update_audit" */
export type ActionUpdateAuditOnConflict = {
  constraint: ActionUpdateAuditConstraint;
  update_columns?: Array<ActionUpdateAuditUpdateColumn>;
  where?: InputMaybe<ActionUpdateAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action_update_audit". */
export type ActionUpdateAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentActionId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.action_update_audit */
export type ActionUpdateAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ActionUpdateAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.action_update_audit" */
export enum ActionUpdateAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentActionId = 'ParentActionId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.action_update_audit" */
export type ActionUpdateAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "action_update_audit" */
export type ActionUpdateAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionUpdateAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionUpdateAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.action_update_audit" */
export enum ActionUpdateAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentActionId = 'ParentActionId',
  /** column name */
  Title = 'Title'
}

export type ActionUpdateAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ActionUpdateAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ActionUpdateAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ActionUpdateAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ActionUpdateAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ActionUpdateAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionUpdateAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ActionUpdateAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.action_update". All fields are combined with a logical 'AND'. */
export type ActionUpdateBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentActionId?: InputMaybe<UuidComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionUpdateBoolExp>>;
  _not?: InputMaybe<ActionUpdateBoolExp>;
  _or?: InputMaybe<Array<ActionUpdateBoolExp>>;
  action?: InputMaybe<ActionBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** update columns of table "risksmart.action" */
export enum ActionUpdateColumn {
  /** column name */
  ClosedDate = 'ClosedDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateDue = 'DateDue',
  /** column name */
  DateRaised = 'DateRaised',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Priority = 'Priority',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title'
}

/** unique or primary key constraints on table "risksmart.action_update" */
export enum ActionUpdateConstraint {
  /** unique or primary key constraint on columns "Id" */
  ActionUpdatePkey = 'action_update_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ActionUpdateDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ActionUpdateDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ActionUpdateDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.action_update" */
export type ActionUpdateInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  action?: InputMaybe<ActionObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ActionUpdateMaxFields = {
  __typename?: 'action_update_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentActionId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.action_update" */
export type ActionUpdateMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentActionId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ActionUpdateMinFields = {
  __typename?: 'action_update_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentActionId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.action_update" */
export type ActionUpdateMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentActionId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.action_update" */
export type ActionUpdateMutationResponse = {
  __typename?: 'action_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ActionUpdate>;
};

/** input type for inserting object relation for remote table "risksmart.action_update" */
export type ActionUpdateObjRelInsertInput = {
  data: ActionUpdateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ActionUpdateOnConflict>;
};

/** on_conflict condition type for table "risksmart.action_update" */
export type ActionUpdateOnConflict = {
  constraint: ActionUpdateConstraint;
  update_columns?: Array<ActionUpdateUpdateColumn>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};

/** Ordering options when selecting data from "risksmart.action_update". */
export type ActionUpdateOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentActionId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  action?: InputMaybe<ActionOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.action_update */
export type ActionUpdatePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ActionUpdatePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.action_update" */
export enum ActionUpdateSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentActionId = 'ParentActionId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.action_update" */
export type ActionUpdateSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "action_update" */
export type ActionUpdateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionUpdateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionUpdateStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentActionId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.action_update_summary_view" */
export type ActionUpdateSummary = {
  __typename?: 'action_update_summary';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  Count?: Maybe<Scalars['bigint']['output']>;
  LatestCreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  LatestDescription?: Maybe<Scalars['String']['output']>;
  LatestTitle?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryAggregate = {
  __typename?: 'action_update_summary_aggregate';
  aggregate?: Maybe<ActionUpdateSummaryAggregateFields>;
  nodes: Array<ActionUpdateSummary>;
};

/** aggregate fields of "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryAggregateFields = {
  __typename?: 'action_update_summary_aggregate_fields';
  avg?: Maybe<ActionUpdateSummaryAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ActionUpdateSummaryMaxFields>;
  min?: Maybe<ActionUpdateSummaryMinFields>;
  stddev?: Maybe<ActionUpdateSummaryStddevFields>;
  stddev_pop?: Maybe<ActionUpdateSummaryStddevPopFields>;
  stddev_samp?: Maybe<ActionUpdateSummaryStddevSampFields>;
  sum?: Maybe<ActionUpdateSummarySumFields>;
  var_pop?: Maybe<ActionUpdateSummaryVarPopFields>;
  var_samp?: Maybe<ActionUpdateSummaryVarSampFields>;
  variance?: Maybe<ActionUpdateSummaryVarianceFields>;
};


/** aggregate fields of "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ActionUpdateSummarySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ActionUpdateSummaryAvgFields = {
  __typename?: 'action_update_summary_avg_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.action_update_summary_view". All fields are combined with a logical 'AND'. */
export type ActionUpdateSummaryBoolExp = {
  ActionId?: InputMaybe<UuidComparisonExp>;
  Count?: InputMaybe<BigintComparisonExp>;
  LatestCreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  LatestDescription?: InputMaybe<StringComparisonExp>;
  LatestTitle?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ActionUpdateSummaryBoolExp>>;
  _not?: InputMaybe<ActionUpdateSummaryBoolExp>;
  _or?: InputMaybe<Array<ActionUpdateSummaryBoolExp>>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** input type for inserting data into table "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryInsertInput = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  Count?: InputMaybe<Scalars['bigint']['input']>;
  LatestCreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  LatestDescription?: InputMaybe<Scalars['String']['input']>;
  LatestTitle?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ActionUpdateSummaryMaxFields = {
  __typename?: 'action_update_summary_max_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  Count?: Maybe<Scalars['bigint']['output']>;
  LatestCreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  LatestDescription?: Maybe<Scalars['String']['output']>;
  LatestTitle?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ActionUpdateSummaryMinFields = {
  __typename?: 'action_update_summary_min_fields';
  ActionId?: Maybe<Scalars['uuid']['output']>;
  Count?: Maybe<Scalars['bigint']['output']>;
  LatestCreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  LatestDescription?: Maybe<Scalars['String']['output']>;
  LatestTitle?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "risksmart.action_update_summary_view" */
export type ActionUpdateSummaryObjRelInsertInput = {
  data: ActionUpdateSummaryInsertInput;
};

/** Ordering options when selecting data from "risksmart.action_update_summary_view". */
export type ActionUpdateSummaryOrderBy = {
  ActionId?: InputMaybe<OrderBy>;
  Count?: InputMaybe<OrderBy>;
  LatestCreatedAtTimestamp?: InputMaybe<OrderBy>;
  LatestDescription?: InputMaybe<OrderBy>;
  LatestTitle?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** select columns of table "risksmart.action_update_summary_view" */
export enum ActionUpdateSummarySelectColumn {
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  Count = 'Count',
  /** column name */
  LatestCreatedAtTimestamp = 'LatestCreatedAtTimestamp',
  /** column name */
  LatestDescription = 'LatestDescription',
  /** column name */
  LatestTitle = 'LatestTitle',
  /** column name */
  OrgKey = 'OrgKey'
}

/** aggregate stddev on columns */
export type ActionUpdateSummaryStddevFields = {
  __typename?: 'action_update_summary_stddev_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ActionUpdateSummaryStddevPopFields = {
  __typename?: 'action_update_summary_stddev_pop_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ActionUpdateSummaryStddevSampFields = {
  __typename?: 'action_update_summary_stddev_samp_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action_update_summary" */
export type ActionUpdateSummaryStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ActionUpdateSummaryStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ActionUpdateSummaryStreamCursorValueInput = {
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  Count?: InputMaybe<Scalars['bigint']['input']>;
  LatestCreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  LatestDescription?: InputMaybe<Scalars['String']['input']>;
  LatestTitle?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ActionUpdateSummarySumFields = {
  __typename?: 'action_update_summary_sum_fields';
  Count?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_pop on columns */
export type ActionUpdateSummaryVarPopFields = {
  __typename?: 'action_update_summary_var_pop_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ActionUpdateSummaryVarSampFields = {
  __typename?: 'action_update_summary_var_samp_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ActionUpdateSummaryVarianceFields = {
  __typename?: 'action_update_summary_variance_fields';
  Count?: Maybe<Scalars['Float']['output']>;
};

/** update columns of table "risksmart.action_update" */
export enum ActionUpdateUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentActionId = 'ParentActionId',
  /** column name */
  Title = 'Title'
}

export type ActionUpdateUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ActionUpdateAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ActionUpdateDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ActionUpdateDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ActionUpdateDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ActionUpdatePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionUpdateSetInput>;
  /** filter the rows which have to be updated */
  where: ActionUpdateBoolExp;
};

export type ActionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ActionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ActionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ActionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ActionDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ActionIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ActionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ActionSetInput>;
  /** filter the rows which have to be updated */
  where: ActionBoolExp;
};

/** aggregate var_pop on columns */
export type ActionVarPopFields = {
  __typename?: 'action_var_pop_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ActionVarSampFields = {
  __typename?: 'action_var_samp_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ActionVarianceFields = {
  __typename?: 'action_variance_fields';
  Priority?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.aggregation_org" */
export type AggregationOrg = {
  __typename?: 'aggregation_org';
  Appetite?: Maybe<AppetiteModelEnum>;
  Config?: Maybe<Scalars['jsonb']['output']>;
  OrgKey: Scalars['String']['output'];
  RiskScoringModel?: Maybe<RiskScoringModelEnum>;
  /** An object relationship */
  organisation: AuthOrganisation;
};


/** columns and relationships of "risksmart.aggregation_org" */
export type AggregationOrgConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.aggregation_org" */
export type AggregationOrgAggregate = {
  __typename?: 'aggregation_org_aggregate';
  aggregate?: Maybe<AggregationOrgAggregateFields>;
  nodes: Array<AggregationOrg>;
};

/** aggregate fields of "risksmart.aggregation_org" */
export type AggregationOrgAggregateFields = {
  __typename?: 'aggregation_org_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AggregationOrgMaxFields>;
  min?: Maybe<AggregationOrgMinFields>;
};


/** aggregate fields of "risksmart.aggregation_org" */
export type AggregationOrgAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AggregationOrgSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AggregationOrgAppendInput = {
  Config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.aggregation_org". All fields are combined with a logical 'AND'. */
export type AggregationOrgBoolExp = {
  Appetite?: InputMaybe<AppetiteModelEnumComparisonExp>;
  Config?: InputMaybe<JsonbComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RiskScoringModel?: InputMaybe<RiskScoringModelEnumComparisonExp>;
  _and?: InputMaybe<Array<AggregationOrgBoolExp>>;
  _not?: InputMaybe<AggregationOrgBoolExp>;
  _or?: InputMaybe<Array<AggregationOrgBoolExp>>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
};

/** unique or primary key constraints on table "risksmart.aggregation_org" */
export enum AggregationOrgConstraint {
  /** unique or primary key constraint on columns "OrgKey" */
  AggregationOrgPkey = 'aggregation_org_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AggregationOrgDeleteAtPathInput = {
  Config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AggregationOrgDeleteElemInput = {
  Config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AggregationOrgDeleteKeyInput = {
  Config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.aggregation_org" */
export type AggregationOrgInsertInput = {
  Appetite?: InputMaybe<AppetiteModelEnum>;
  Config?: InputMaybe<Scalars['jsonb']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskScoringModel?: InputMaybe<RiskScoringModelEnum>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
};

/** aggregate max on columns */
export type AggregationOrgMaxFields = {
  __typename?: 'aggregation_org_max_fields';
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AggregationOrgMinFields = {
  __typename?: 'aggregation_org_min_fields';
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.aggregation_org" */
export type AggregationOrgMutationResponse = {
  __typename?: 'aggregation_org_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AggregationOrg>;
};

/** on_conflict condition type for table "risksmart.aggregation_org" */
export type AggregationOrgOnConflict = {
  constraint: AggregationOrgConstraint;
  update_columns?: Array<AggregationOrgUpdateColumn>;
  where?: InputMaybe<AggregationOrgBoolExp>;
};

/** Ordering options when selecting data from "risksmart.aggregation_org". */
export type AggregationOrgOrderBy = {
  Appetite?: InputMaybe<OrderBy>;
  Config?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RiskScoringModel?: InputMaybe<OrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
};

/** primary key columns input for table: risksmart.aggregation_org */
export type AggregationOrgPkColumnsInput = {
  OrgKey: Scalars['String']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AggregationOrgPrependInput = {
  Config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.aggregation_org" */
export enum AggregationOrgSelectColumn {
  /** column name */
  Appetite = 'Appetite',
  /** column name */
  Config = 'Config',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RiskScoringModel = 'RiskScoringModel'
}

/** input type for updating data in table "risksmart.aggregation_org" */
export type AggregationOrgSetInput = {
  Appetite?: InputMaybe<AppetiteModelEnum>;
  Config?: InputMaybe<Scalars['jsonb']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskScoringModel?: InputMaybe<RiskScoringModelEnum>;
};

/** Streaming cursor of the table "aggregation_org" */
export type AggregationOrgStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AggregationOrgStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AggregationOrgStreamCursorValueInput = {
  Appetite?: InputMaybe<AppetiteModelEnum>;
  Config?: InputMaybe<Scalars['jsonb']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskScoringModel?: InputMaybe<RiskScoringModelEnum>;
};

/** update columns of table "risksmart.aggregation_org" */
export enum AggregationOrgUpdateColumn {
  /** column name */
  Appetite = 'Appetite',
  /** column name */
  Config = 'Config',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RiskScoringModel = 'RiskScoringModel'
}

export type AggregationOrgUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AggregationOrgAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AggregationOrgDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AggregationOrgDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AggregationOrgDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AggregationOrgPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AggregationOrgSetInput>;
  /** filter the rows which have to be updated */
  where: AggregationOrgBoolExp;
};

/** columns and relationships of "risksmart.ancestor_contributor_view" */
export type AncestorContributor = {
  __typename?: 'ancestor_contributor';
  AncestorId?: Maybe<Scalars['uuid']['output']>;
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  appetite?: Maybe<Appetite>;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  dashboard?: Maybe<Dashboard>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  document_assessment_result?: Maybe<DocumentAssessmentResult>;
  /** An object relationship */
  impact?: Maybe<Impact>;
  /** An object relationship */
  indicator?: Maybe<Indicator>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  node?: Maybe<Node>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  obligationAssessmentResult?: Maybe<ObligationAssessmentResult>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  risk_assessment_result?: Maybe<RiskAssessmentResult>;
  /** An object relationship */
  user?: Maybe<User>;
  /** An object relationship */
  user_group?: Maybe<UserGroup>;
};

/** aggregated selection of "risksmart.ancestor_contributor_view" */
export type AncestorContributorAggregate = {
  __typename?: 'ancestor_contributor_aggregate';
  aggregate?: Maybe<AncestorContributorAggregateFields>;
  nodes: Array<AncestorContributor>;
};

export type AncestorContributorAggregateBoolExp = {
  count?: InputMaybe<AncestorContributorAggregateBoolExpCount>;
};

export type AncestorContributorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AncestorContributorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.ancestor_contributor_view" */
export type AncestorContributorAggregateFields = {
  __typename?: 'ancestor_contributor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AncestorContributorMaxFields>;
  min?: Maybe<AncestorContributorMinFields>;
};


/** aggregate fields of "risksmart.ancestor_contributor_view" */
export type AncestorContributorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.ancestor_contributor_view" */
export type AncestorContributorAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AncestorContributorMaxOrderBy>;
  min?: InputMaybe<AncestorContributorMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.ancestor_contributor_view" */
export type AncestorContributorArrRelInsertInput = {
  data: Array<AncestorContributorInsertInput>;
};

/** Boolean expression to filter rows from the table "risksmart.ancestor_contributor_view". All fields are combined with a logical 'AND'. */
export type AncestorContributorBoolExp = {
  AncestorId?: InputMaybe<UuidComparisonExp>;
  ContributorType?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ObjectType?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AncestorContributorBoolExp>>;
  _not?: InputMaybe<AncestorContributorBoolExp>;
  _or?: InputMaybe<Array<AncestorContributorBoolExp>>;
  acceptance?: InputMaybe<AcceptanceBoolExp>;
  action?: InputMaybe<ActionBoolExp>;
  appetite?: InputMaybe<AppetiteBoolExp>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  control?: InputMaybe<ControlBoolExp>;
  dashboard?: InputMaybe<DashboardBoolExp>;
  document?: InputMaybe<DocumentBoolExp>;
  document_assessment_result?: InputMaybe<DocumentAssessmentResultBoolExp>;
  impact?: InputMaybe<ImpactBoolExp>;
  indicator?: InputMaybe<IndicatorBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  node?: InputMaybe<NodeBoolExp>;
  obligation?: InputMaybe<ObligationBoolExp>;
  obligationAssessmentResult?: InputMaybe<ObligationAssessmentResultBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
  risk_assessment_result?: InputMaybe<RiskAssessmentResultBoolExp>;
  user?: InputMaybe<UserBoolExp>;
  user_group?: InputMaybe<UserGroupBoolExp>;
};

/** input type for inserting data into table "risksmart.ancestor_contributor_view" */
export type AncestorContributorInsertInput = {
  AncestorId?: InputMaybe<Scalars['uuid']['input']>;
  ContributorType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  acceptance?: InputMaybe<AcceptanceObjRelInsertInput>;
  action?: InputMaybe<ActionObjRelInsertInput>;
  appetite?: InputMaybe<AppetiteObjRelInsertInput>;
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  control?: InputMaybe<ControlObjRelInsertInput>;
  dashboard?: InputMaybe<DashboardObjRelInsertInput>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  document_assessment_result?: InputMaybe<DocumentAssessmentResultObjRelInsertInput>;
  impact?: InputMaybe<ImpactObjRelInsertInput>;
  indicator?: InputMaybe<IndicatorObjRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  node?: InputMaybe<NodeObjRelInsertInput>;
  obligation?: InputMaybe<ObligationObjRelInsertInput>;
  obligationAssessmentResult?: InputMaybe<ObligationAssessmentResultObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
  risk_assessment_result?: InputMaybe<RiskAssessmentResultObjRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
  user_group?: InputMaybe<UserGroupObjRelInsertInput>;
};

/** aggregate max on columns */
export type AncestorContributorMaxFields = {
  __typename?: 'ancestor_contributor_max_fields';
  AncestorId?: Maybe<Scalars['uuid']['output']>;
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.ancestor_contributor_view" */
export type AncestorContributorMaxOrderBy = {
  AncestorId?: InputMaybe<OrderBy>;
  ContributorType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AncestorContributorMinFields = {
  __typename?: 'ancestor_contributor_min_fields';
  AncestorId?: Maybe<Scalars['uuid']['output']>;
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.ancestor_contributor_view" */
export type AncestorContributorMinOrderBy = {
  AncestorId?: InputMaybe<OrderBy>;
  ContributorType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "risksmart.ancestor_contributor_view". */
export type AncestorContributorOrderBy = {
  AncestorId?: InputMaybe<OrderBy>;
  ContributorType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  acceptance?: InputMaybe<AcceptanceOrderBy>;
  action?: InputMaybe<ActionOrderBy>;
  appetite?: InputMaybe<AppetiteOrderBy>;
  assessment?: InputMaybe<AssessmentOrderBy>;
  control?: InputMaybe<ControlOrderBy>;
  dashboard?: InputMaybe<DashboardOrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  document_assessment_result?: InputMaybe<DocumentAssessmentResultOrderBy>;
  impact?: InputMaybe<ImpactOrderBy>;
  indicator?: InputMaybe<IndicatorOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  node?: InputMaybe<NodeOrderBy>;
  obligation?: InputMaybe<ObligationOrderBy>;
  obligationAssessmentResult?: InputMaybe<ObligationAssessmentResultOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
  risk_assessment_result?: InputMaybe<RiskAssessmentResultOrderBy>;
  user?: InputMaybe<UserOrderBy>;
  user_group?: InputMaybe<UserGroupOrderBy>;
};

/** select columns of table "risksmart.ancestor_contributor_view" */
export enum AncestorContributorSelectColumn {
  /** column name */
  AncestorId = 'AncestorId',
  /** column name */
  ContributorType = 'ContributorType',
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

/** Streaming cursor of the table "ancestor_contributor" */
export type AncestorContributorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AncestorContributorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AncestorContributorStreamCursorValueInput = {
  AncestorId?: InputMaybe<Scalars['uuid']['input']>;
  ContributorType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.appetite" */
export type Appetite = {
  __typename?: 'appetite';
  AppetiteType: AppetiteTypeEnum;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  parents: Array<AppetiteParent>;
  /** An aggregate relationship */
  parents_aggregate: AppetiteParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteParentsArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetiteParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetitePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.appetite" */
export type AppetitePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.appetite" */
export type AppetiteAggregate = {
  __typename?: 'appetite_aggregate';
  aggregate?: Maybe<AppetiteAggregateFields>;
  nodes: Array<Appetite>;
};

/** aggregate fields of "risksmart.appetite" */
export type AppetiteAggregateFields = {
  __typename?: 'appetite_aggregate_fields';
  avg?: Maybe<AppetiteAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AppetiteMaxFields>;
  min?: Maybe<AppetiteMinFields>;
  stddev?: Maybe<AppetiteStddevFields>;
  stddev_pop?: Maybe<AppetiteStddevPopFields>;
  stddev_samp?: Maybe<AppetiteStddevSampFields>;
  sum?: Maybe<AppetiteSumFields>;
  var_pop?: Maybe<AppetiteVarPopFields>;
  var_samp?: Maybe<AppetiteVarSampFields>;
  variance?: Maybe<AppetiteVarianceFields>;
};


/** aggregate fields of "risksmart.appetite" */
export type AppetiteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppetiteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AppetiteAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.appetite_audit" */
export type AppetiteAudit = {
  __typename?: 'appetite_audit';
  Action?: Maybe<Scalars['String']['output']>;
  AppetiteType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement: Scalars['String']['output'];
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};


/** columns and relationships of "risksmart.appetite_audit" */
export type AppetiteAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.appetite_audit" */
export type AppetiteAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.appetite_audit" */
export type AppetiteAuditAggregate = {
  __typename?: 'appetite_audit_aggregate';
  aggregate?: Maybe<AppetiteAuditAggregateFields>;
  nodes: Array<AppetiteAudit>;
};

/** aggregate fields of "risksmart.appetite_audit" */
export type AppetiteAuditAggregateFields = {
  __typename?: 'appetite_audit_aggregate_fields';
  avg?: Maybe<AppetiteAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AppetiteAuditMaxFields>;
  min?: Maybe<AppetiteAuditMinFields>;
  stddev?: Maybe<AppetiteAuditStddevFields>;
  stddev_pop?: Maybe<AppetiteAuditStddevPopFields>;
  stddev_samp?: Maybe<AppetiteAuditStddevSampFields>;
  sum?: Maybe<AppetiteAuditSumFields>;
  var_pop?: Maybe<AppetiteAuditVarPopFields>;
  var_samp?: Maybe<AppetiteAuditVarSampFields>;
  variance?: Maybe<AppetiteAuditVarianceFields>;
};


/** aggregate fields of "risksmart.appetite_audit" */
export type AppetiteAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppetiteAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AppetiteAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type AppetiteAuditAvgFields = {
  __typename?: 'appetite_audit_avg_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_audit". All fields are combined with a logical 'AND'. */
export type AppetiteAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  AppetiteType?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  EffectiveDate?: InputMaybe<TimestamptzComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactAppetite?: InputMaybe<IntComparisonExp>;
  LikelihoodAppetite?: InputMaybe<IntComparisonExp>;
  LowerAppetite?: InputMaybe<IntComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Statement?: InputMaybe<StringComparisonExp>;
  UpperAppetite?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<AppetiteAuditBoolExp>>;
  _not?: InputMaybe<AppetiteAuditBoolExp>;
  _or?: InputMaybe<Array<AppetiteAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.appetite_audit" */
export enum AppetiteAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  AppetiteAuditPkey = 'appetite_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AppetiteAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AppetiteAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AppetiteAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.appetite_audit" */
export type AppetiteAuditIncInput = {
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.appetite_audit" */
export type AppetiteAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AppetiteType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type AppetiteAuditMaxFields = {
  __typename?: 'appetite_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AppetiteType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type AppetiteAuditMinFields = {
  __typename?: 'appetite_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AppetiteType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.appetite_audit" */
export type AppetiteAuditMutationResponse = {
  __typename?: 'appetite_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AppetiteAudit>;
};

/** on_conflict condition type for table "risksmart.appetite_audit" */
export type AppetiteAuditOnConflict = {
  constraint: AppetiteAuditConstraint;
  update_columns?: Array<AppetiteAuditUpdateColumn>;
  where?: InputMaybe<AppetiteAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.appetite_audit". */
export type AppetiteAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  AppetiteType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  EffectiveDate?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactAppetite?: InputMaybe<OrderBy>;
  LikelihoodAppetite?: InputMaybe<OrderBy>;
  LowerAppetite?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Statement?: InputMaybe<OrderBy>;
  UpperAppetite?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.appetite_audit */
export type AppetiteAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AppetiteAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.appetite_audit" */
export enum AppetiteAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AppetiteType = 'AppetiteType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  EffectiveDate = 'EffectiveDate',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  LowerAppetite = 'LowerAppetite',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Statement = 'Statement',
  /** column name */
  UpperAppetite = 'UpperAppetite'
}

/** input type for updating data in table "risksmart.appetite_audit" */
export type AppetiteAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AppetiteType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type AppetiteAuditStddevFields = {
  __typename?: 'appetite_audit_stddev_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type AppetiteAuditStddevPopFields = {
  __typename?: 'appetite_audit_stddev_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type AppetiteAuditStddevSampFields = {
  __typename?: 'appetite_audit_stddev_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "appetite_audit" */
export type AppetiteAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AppetiteAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppetiteAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AppetiteType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type AppetiteAuditSumFields = {
  __typename?: 'appetite_audit_sum_fields';
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.appetite_audit" */
export enum AppetiteAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AppetiteType = 'AppetiteType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  EffectiveDate = 'EffectiveDate',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  LowerAppetite = 'LowerAppetite',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Statement = 'Statement',
  /** column name */
  UpperAppetite = 'UpperAppetite'
}

export type AppetiteAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AppetiteAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AppetiteAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AppetiteAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AppetiteAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AppetiteAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AppetiteAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppetiteAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AppetiteAuditBoolExp;
};

/** aggregate var_pop on columns */
export type AppetiteAuditVarPopFields = {
  __typename?: 'appetite_audit_var_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type AppetiteAuditVarSampFields = {
  __typename?: 'appetite_audit_var_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AppetiteAuditVarianceFields = {
  __typename?: 'appetite_audit_variance_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type AppetiteAvgFields = {
  __typename?: 'appetite_avg_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite". All fields are combined with a logical 'AND'. */
export type AppetiteBoolExp = {
  AppetiteType?: InputMaybe<AppetiteTypeEnumComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  EffectiveDate?: InputMaybe<TimestamptzComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactAppetite?: InputMaybe<IntComparisonExp>;
  LikelihoodAppetite?: InputMaybe<IntComparisonExp>;
  LowerAppetite?: InputMaybe<IntComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Statement?: InputMaybe<StringComparisonExp>;
  UpperAppetite?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<AppetiteBoolExp>>;
  _not?: InputMaybe<AppetiteBoolExp>;
  _or?: InputMaybe<Array<AppetiteBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parents?: InputMaybe<AppetiteParentBoolExp>;
  parents_aggregate?: InputMaybe<AppetiteParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.appetite" */
export enum AppetiteConstraint {
  /** unique or primary key constraint on columns "Id" */
  AppetitePkey = 'appetite_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxAppetiteOrgkeySequentialid = 'idx_appetite_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AppetiteDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AppetiteDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AppetiteDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.appetite" */
export type AppetiteIncInput = {
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.appetite" */
export type AppetiteInsertInput = {
  AppetiteType?: InputMaybe<AppetiteTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parents?: InputMaybe<AppetiteParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type AppetiteMaxFields = {
  __typename?: 'appetite_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type AppetiteMinFields = {
  __typename?: 'appetite_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  EffectiveDate?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Statement?: Maybe<Scalars['String']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.appetite_model" */
export type AppetiteModel = {
  __typename?: 'appetite_model';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.appetite_model" */
export type AppetiteModelAggregate = {
  __typename?: 'appetite_model_aggregate';
  aggregate?: Maybe<AppetiteModelAggregateFields>;
  nodes: Array<AppetiteModel>;
};

/** aggregate fields of "risksmart.appetite_model" */
export type AppetiteModelAggregateFields = {
  __typename?: 'appetite_model_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AppetiteModelMaxFields>;
  min?: Maybe<AppetiteModelMinFields>;
};


/** aggregate fields of "risksmart.appetite_model" */
export type AppetiteModelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppetiteModelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_model". All fields are combined with a logical 'AND'. */
export type AppetiteModelBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AppetiteModelBoolExp>>;
  _not?: InputMaybe<AppetiteModelBoolExp>;
  _or?: InputMaybe<Array<AppetiteModelBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.appetite_model" */
export enum AppetiteModelConstraint {
  /** unique or primary key constraint on columns "Value" */
  AppetiteModelPkey = 'appetite_model_pkey'
}

export enum AppetiteModelEnum {
  /** Default model */
  Default = 'default',
  /** Top down cascade */
  TopDownCascade = 'top_down_cascade'
}

/** Boolean expression to compare columns of type "appetite_model_enum". All fields are combined with logical 'AND'. */
export type AppetiteModelEnumComparisonExp = {
  _eq?: InputMaybe<AppetiteModelEnum>;
  _in?: InputMaybe<Array<AppetiteModelEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AppetiteModelEnum>;
  _nin?: InputMaybe<Array<AppetiteModelEnum>>;
};

/** input type for inserting data into table "risksmart.appetite_model" */
export type AppetiteModelInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AppetiteModelMaxFields = {
  __typename?: 'appetite_model_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AppetiteModelMinFields = {
  __typename?: 'appetite_model_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.appetite_model" */
export type AppetiteModelMutationResponse = {
  __typename?: 'appetite_model_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AppetiteModel>;
};

/** on_conflict condition type for table "risksmart.appetite_model" */
export type AppetiteModelOnConflict = {
  constraint: AppetiteModelConstraint;
  update_columns?: Array<AppetiteModelUpdateColumn>;
  where?: InputMaybe<AppetiteModelBoolExp>;
};

/** Ordering options when selecting data from "risksmart.appetite_model". */
export type AppetiteModelOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.appetite_model */
export type AppetiteModelPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.appetite_model" */
export enum AppetiteModelSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.appetite_model" */
export type AppetiteModelSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "appetite_model" */
export type AppetiteModelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AppetiteModelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppetiteModelStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.appetite_model" */
export enum AppetiteModelUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AppetiteModelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppetiteModelSetInput>;
  /** filter the rows which have to be updated */
  where: AppetiteModelBoolExp;
};

/** response of any mutation on the table "risksmart.appetite" */
export type AppetiteMutationResponse = {
  __typename?: 'appetite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Appetite>;
};

/** input type for inserting object relation for remote table "risksmart.appetite" */
export type AppetiteObjRelInsertInput = {
  data: AppetiteInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AppetiteOnConflict>;
};

/** on_conflict condition type for table "risksmart.appetite" */
export type AppetiteOnConflict = {
  constraint: AppetiteConstraint;
  update_columns?: Array<AppetiteUpdateColumn>;
  where?: InputMaybe<AppetiteBoolExp>;
};

/** Ordering options when selecting data from "risksmart.appetite". */
export type AppetiteOrderBy = {
  AppetiteType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  EffectiveDate?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactAppetite?: InputMaybe<OrderBy>;
  LikelihoodAppetite?: InputMaybe<OrderBy>;
  LowerAppetite?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Statement?: InputMaybe<OrderBy>;
  UpperAppetite?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parents_aggregate?: InputMaybe<AppetiteParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** columns and relationships of "risksmart.appetite_parent" */
export type AppetiteParent = {
  __typename?: 'appetite_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  appetite?: Maybe<Appetite>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  impact?: Maybe<Impact>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.appetite_parent" */
export type AppetiteParentAggregate = {
  __typename?: 'appetite_parent_aggregate';
  aggregate?: Maybe<AppetiteParentAggregateFields>;
  nodes: Array<AppetiteParent>;
};

export type AppetiteParentAggregateBoolExp = {
  count?: InputMaybe<AppetiteParentAggregateBoolExpCount>;
};

export type AppetiteParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AppetiteParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.appetite_parent" */
export type AppetiteParentAggregateFields = {
  __typename?: 'appetite_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AppetiteParentMaxFields>;
  min?: Maybe<AppetiteParentMinFields>;
};


/** aggregate fields of "risksmart.appetite_parent" */
export type AppetiteParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.appetite_parent" */
export type AppetiteParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AppetiteParentMaxOrderBy>;
  min?: InputMaybe<AppetiteParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.appetite_parent" */
export type AppetiteParentArrRelInsertInput = {
  data: Array<AppetiteParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AppetiteParentOnConflict>;
};

/** columns and relationships of "risksmart.appetite_parent_audit" */
export type AppetiteParentAudit = {
  __typename?: 'appetite_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditAggregate = {
  __typename?: 'appetite_parent_audit_aggregate';
  aggregate?: Maybe<AppetiteParentAuditAggregateFields>;
  nodes: Array<AppetiteParentAudit>;
};

export type AppetiteParentAuditAggregateBoolExp = {
  count?: InputMaybe<AppetiteParentAuditAggregateBoolExpCount>;
};

export type AppetiteParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AppetiteParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditAggregateFields = {
  __typename?: 'appetite_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AppetiteParentAuditMaxFields>;
  min?: Maybe<AppetiteParentAuditMinFields>;
};


/** aggregate fields of "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AppetiteParentAuditMaxOrderBy>;
  min?: InputMaybe<AppetiteParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditArrRelInsertInput = {
  data: Array<AppetiteParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AppetiteParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_parent_audit". All fields are combined with a logical 'AND'. */
export type AppetiteParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<AppetiteParentAuditBoolExp>>;
  _not?: InputMaybe<AppetiteParentAuditBoolExp>;
  _or?: InputMaybe<Array<AppetiteParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.appetite_parent_audit" */
export enum AppetiteParentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "Id", "ModifiedAtTimestamp" */
  AppetiteParentAuditPkey = 'appetite_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type AppetiteParentAuditMaxFields = {
  __typename?: 'appetite_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AppetiteParentAuditMinFields = {
  __typename?: 'appetite_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditMutationResponse = {
  __typename?: 'appetite_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AppetiteParentAudit>;
};

/** on_conflict condition type for table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditOnConflict = {
  constraint: AppetiteParentAuditConstraint;
  update_columns?: Array<AppetiteParentAuditUpdateColumn>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.appetite_parent_audit". */
export type AppetiteParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.appetite_parent_audit */
export type AppetiteParentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.appetite_parent_audit" */
export enum AppetiteParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.appetite_parent_audit" */
export type AppetiteParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "appetite_parent_audit" */
export type AppetiteParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AppetiteParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppetiteParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.appetite_parent_audit" */
export enum AppetiteParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type AppetiteParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppetiteParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AppetiteParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_parent". All fields are combined with a logical 'AND'. */
export type AppetiteParentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<AppetiteParentBoolExp>>;
  _not?: InputMaybe<AppetiteParentBoolExp>;
  _or?: InputMaybe<Array<AppetiteParentBoolExp>>;
  appetite?: InputMaybe<AppetiteBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  impact?: InputMaybe<ImpactBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.appetite_parent" */
export enum AppetiteParentConstraint {
  /** unique or primary key constraint on columns "ParentId", "Id" */
  AppetiteParentPkey = 'appetite_parent_pkey'
}

/** input type for inserting data into table "risksmart.appetite_parent" */
export type AppetiteParentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  appetite?: InputMaybe<AppetiteObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  impact?: InputMaybe<ImpactObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type AppetiteParentMaxFields = {
  __typename?: 'appetite_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.appetite_parent" */
export type AppetiteParentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AppetiteParentMinFields = {
  __typename?: 'appetite_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.appetite_parent" */
export type AppetiteParentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.appetite_parent" */
export type AppetiteParentMutationResponse = {
  __typename?: 'appetite_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AppetiteParent>;
};

/** on_conflict condition type for table "risksmart.appetite_parent" */
export type AppetiteParentOnConflict = {
  constraint: AppetiteParentConstraint;
  update_columns?: Array<AppetiteParentUpdateColumn>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.appetite_parent". */
export type AppetiteParentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  appetite?: InputMaybe<AppetiteOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  impact?: InputMaybe<ImpactOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.appetite_parent */
export type AppetiteParentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.appetite_parent" */
export enum AppetiteParentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.appetite_parent" */
export type AppetiteParentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "appetite_parent" */
export type AppetiteParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AppetiteParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppetiteParentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.appetite_parent" */
export enum AppetiteParentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type AppetiteParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppetiteParentSetInput>;
  /** filter the rows which have to be updated */
  where: AppetiteParentBoolExp;
};

/** primary key columns input for table: risksmart.appetite */
export type AppetitePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AppetitePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.appetite" */
export enum AppetiteSelectColumn {
  /** column name */
  AppetiteType = 'AppetiteType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  EffectiveDate = 'EffectiveDate',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  LowerAppetite = 'LowerAppetite',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Statement = 'Statement',
  /** column name */
  UpperAppetite = 'UpperAppetite'
}

/** input type for updating data in table "risksmart.appetite" */
export type AppetiteSetInput = {
  AppetiteType?: InputMaybe<AppetiteTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type AppetiteStddevFields = {
  __typename?: 'appetite_stddev_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type AppetiteStddevPopFields = {
  __typename?: 'appetite_stddev_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type AppetiteStddevSampFields = {
  __typename?: 'appetite_stddev_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "appetite" */
export type AppetiteStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AppetiteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppetiteStreamCursorValueInput = {
  AppetiteType?: InputMaybe<AppetiteTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type AppetiteSumFields = {
  __typename?: 'appetite_sum_fields';
  ImpactAppetite?: Maybe<Scalars['Int']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Int']['output']>;
  LowerAppetite?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  UpperAppetite?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.appetite_type" */
export type AppetiteType = {
  __typename?: 'appetite_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.appetite_type" */
export type AppetiteTypeAggregate = {
  __typename?: 'appetite_type_aggregate';
  aggregate?: Maybe<AppetiteTypeAggregateFields>;
  nodes: Array<AppetiteType>;
};

/** aggregate fields of "risksmart.appetite_type" */
export type AppetiteTypeAggregateFields = {
  __typename?: 'appetite_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AppetiteTypeMaxFields>;
  min?: Maybe<AppetiteTypeMinFields>;
};


/** aggregate fields of "risksmart.appetite_type" */
export type AppetiteTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AppetiteTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.appetite_type". All fields are combined with a logical 'AND'. */
export type AppetiteTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AppetiteTypeBoolExp>>;
  _not?: InputMaybe<AppetiteTypeBoolExp>;
  _or?: InputMaybe<Array<AppetiteTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.appetite_type" */
export enum AppetiteTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  AppetiteTypePkey = 'appetite_type_pkey'
}

export enum AppetiteTypeEnum {
  /** Impact */
  Impact = 'impact',
  /** Likelihood */
  Likelihood = 'likelihood',
  /** Risk */
  Risk = 'risk'
}

/** Boolean expression to compare columns of type "appetite_type_enum". All fields are combined with logical 'AND'. */
export type AppetiteTypeEnumComparisonExp = {
  _eq?: InputMaybe<AppetiteTypeEnum>;
  _in?: InputMaybe<Array<AppetiteTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AppetiteTypeEnum>;
  _nin?: InputMaybe<Array<AppetiteTypeEnum>>;
};

/** input type for inserting data into table "risksmart.appetite_type" */
export type AppetiteTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AppetiteTypeMaxFields = {
  __typename?: 'appetite_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AppetiteTypeMinFields = {
  __typename?: 'appetite_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.appetite_type" */
export type AppetiteTypeMutationResponse = {
  __typename?: 'appetite_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AppetiteType>;
};

/** on_conflict condition type for table "risksmart.appetite_type" */
export type AppetiteTypeOnConflict = {
  constraint: AppetiteTypeConstraint;
  update_columns?: Array<AppetiteTypeUpdateColumn>;
  where?: InputMaybe<AppetiteTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.appetite_type". */
export type AppetiteTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.appetite_type */
export type AppetiteTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.appetite_type" */
export enum AppetiteTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.appetite_type" */
export type AppetiteTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "appetite_type" */
export type AppetiteTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AppetiteTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AppetiteTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.appetite_type" */
export enum AppetiteTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AppetiteTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppetiteTypeSetInput>;
  /** filter the rows which have to be updated */
  where: AppetiteTypeBoolExp;
};

/** update columns of table "risksmart.appetite" */
export enum AppetiteUpdateColumn {
  /** column name */
  AppetiteType = 'AppetiteType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  EffectiveDate = 'EffectiveDate',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  LowerAppetite = 'LowerAppetite',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Statement = 'Statement',
  /** column name */
  UpperAppetite = 'UpperAppetite'
}

export type AppetiteUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AppetiteAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AppetiteDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AppetiteDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AppetiteDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AppetiteIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AppetitePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AppetiteSetInput>;
  /** filter the rows which have to be updated */
  where: AppetiteBoolExp;
};

/** aggregate var_pop on columns */
export type AppetiteVarPopFields = {
  __typename?: 'appetite_var_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type AppetiteVarSampFields = {
  __typename?: 'appetite_var_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AppetiteVarianceFields = {
  __typename?: 'appetite_variance_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  LowerAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetite?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.approval" */
export type Approval = {
  __typename?: 'approval';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  InFlightEditRule: ApprovalInFlightEditRuleEnum;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow: Scalars['String']['output'];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  levels: Array<ApprovalLevel>;
  /** An aggregate relationship */
  levels_aggregate: ApprovalLevelAggregate;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  org?: Maybe<AuthOrganisation>;
  /** An object relationship */
  parent?: Maybe<Node>;
};


/** columns and relationships of "risksmart.approval" */
export type ApprovalLevelsArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelOrderBy>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};


/** columns and relationships of "risksmart.approval" */
export type ApprovalLevelsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelOrderBy>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};

/** aggregated selection of "risksmart.approval" */
export type ApprovalAggregate = {
  __typename?: 'approval_aggregate';
  aggregate?: Maybe<ApprovalAggregateFields>;
  nodes: Array<Approval>;
};

export type ApprovalAggregateBoolExp = {
  count?: InputMaybe<ApprovalAggregateBoolExpCount>;
};

export type ApprovalAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ApprovalSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApprovalBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.approval" */
export type ApprovalAggregateFields = {
  __typename?: 'approval_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalMaxFields>;
  min?: Maybe<ApprovalMinFields>;
};


/** aggregate fields of "risksmart.approval" */
export type ApprovalAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.approval" */
export type ApprovalAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ApprovalMaxOrderBy>;
  min?: InputMaybe<ApprovalMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.approval" */
export type ApprovalArrRelInsertInput = {
  data: Array<ApprovalInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ApprovalOnConflict>;
};

/** columns and relationships of "risksmart.approval_audit" */
export type ApprovalAudit = {
  __typename?: 'approval_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  InFlightEditRule: ApprovalInFlightEditRuleEnum;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.approval_audit" */
export type ApprovalAuditAggregate = {
  __typename?: 'approval_audit_aggregate';
  aggregate?: Maybe<ApprovalAuditAggregateFields>;
  nodes: Array<ApprovalAudit>;
};

/** aggregate fields of "risksmart.approval_audit" */
export type ApprovalAuditAggregateFields = {
  __typename?: 'approval_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalAuditMaxFields>;
  min?: Maybe<ApprovalAuditMinFields>;
};


/** aggregate fields of "risksmart.approval_audit" */
export type ApprovalAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_audit". All fields are combined with a logical 'AND'. */
export type ApprovalAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnumComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  Workflow?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApprovalAuditBoolExp>>;
  _not?: InputMaybe<ApprovalAuditBoolExp>;
  _or?: InputMaybe<Array<ApprovalAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approval_audit" */
export enum ApprovalAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ApprovalAuditPkey = 'approval_audit_pkey'
}

/** input type for inserting data into table "risksmart.approval_audit" */
export type ApprovalAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ApprovalAuditMaxFields = {
  __typename?: 'approval_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ApprovalAuditMinFields = {
  __typename?: 'approval_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.approval_audit" */
export type ApprovalAuditMutationResponse = {
  __typename?: 'approval_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalAudit>;
};

/** on_conflict condition type for table "risksmart.approval_audit" */
export type ApprovalAuditOnConflict = {
  constraint: ApprovalAuditConstraint;
  update_columns?: Array<ApprovalAuditUpdateColumn>;
  where?: InputMaybe<ApprovalAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval_audit". */
export type ApprovalAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  InFlightEditRule?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Workflow?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approval_audit */
export type ApprovalAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.approval_audit" */
export enum ApprovalAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  InFlightEditRule = 'InFlightEditRule',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Workflow = 'Workflow'
}

/** input type for updating data in table "risksmart.approval_audit" */
export type ApprovalAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approval_audit" */
export type ApprovalAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approval_audit" */
export enum ApprovalAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  InFlightEditRule = 'InFlightEditRule',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Workflow = 'Workflow'
}

export type ApprovalAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.approval". All fields are combined with a logical 'AND'. */
export type ApprovalBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnumComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  Workflow?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApprovalBoolExp>>;
  _not?: InputMaybe<ApprovalBoolExp>;
  _or?: InputMaybe<Array<ApprovalBoolExp>>;
  createdBy?: InputMaybe<UserBoolExp>;
  levels?: InputMaybe<ApprovalLevelBoolExp>;
  levels_aggregate?: InputMaybe<ApprovalLevelAggregateBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  org?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.approval" */
export enum ApprovalConstraint {
  /** unique or primary key constraint on columns "Id" */
  ApprovalPkey = 'approval_pkey',
  /** unique or primary key constraint on columns "Workflow", "ParentId" */
  IdxApprovalParentParentType = 'idx_approval_parent_parent_type'
}

/** columns and relationships of "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRule = {
  __typename?: 'approval_in_flight_edit_rule';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleAggregate = {
  __typename?: 'approval_in_flight_edit_rule_aggregate';
  aggregate?: Maybe<ApprovalInFlightEditRuleAggregateFields>;
  nodes: Array<ApprovalInFlightEditRule>;
};

/** aggregate fields of "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleAggregateFields = {
  __typename?: 'approval_in_flight_edit_rule_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalInFlightEditRuleMaxFields>;
  min?: Maybe<ApprovalInFlightEditRuleMinFields>;
};


/** aggregate fields of "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalInFlightEditRuleSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_in_flight_edit_rule". All fields are combined with a logical 'AND'. */
export type ApprovalInFlightEditRuleBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApprovalInFlightEditRuleBoolExp>>;
  _not?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
  _or?: InputMaybe<Array<ApprovalInFlightEditRuleBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approval_in_flight_edit_rule" */
export enum ApprovalInFlightEditRuleConstraint {
  /** unique or primary key constraint on columns "Value" */
  ApprovalInFlightEditRulePkey = 'approval_in_flight_edit_rule_pkey'
}

export enum ApprovalInFlightEditRuleEnum {
  /** Only approvers can edit */
  Approvers = 'approvers',
  /** Everyone who has access can edit */
  Everyone = 'everyone',
  /** No one can edit */
  Noone = 'noone'
}

/** Boolean expression to compare columns of type "approval_in_flight_edit_rule_enum". All fields are combined with logical 'AND'. */
export type ApprovalInFlightEditRuleEnumComparisonExp = {
  _eq?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  _in?: InputMaybe<Array<ApprovalInFlightEditRuleEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  _nin?: InputMaybe<Array<ApprovalInFlightEditRuleEnum>>;
};

/** input type for inserting data into table "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ApprovalInFlightEditRuleMaxFields = {
  __typename?: 'approval_in_flight_edit_rule_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ApprovalInFlightEditRuleMinFields = {
  __typename?: 'approval_in_flight_edit_rule_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleMutationResponse = {
  __typename?: 'approval_in_flight_edit_rule_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalInFlightEditRule>;
};

/** on_conflict condition type for table "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleOnConflict = {
  constraint: ApprovalInFlightEditRuleConstraint;
  update_columns?: Array<ApprovalInFlightEditRuleUpdateColumn>;
  where?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval_in_flight_edit_rule". */
export type ApprovalInFlightEditRuleOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approval_in_flight_edit_rule */
export type ApprovalInFlightEditRulePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.approval_in_flight_edit_rule" */
export enum ApprovalInFlightEditRuleSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approval_in_flight_edit_rule" */
export type ApprovalInFlightEditRuleStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalInFlightEditRuleStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalInFlightEditRuleStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approval_in_flight_edit_rule" */
export enum ApprovalInFlightEditRuleUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ApprovalInFlightEditRuleUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalInFlightEditRuleSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalInFlightEditRuleBoolExp;
};

/** input type for inserting data into table "risksmart.approval" */
export type ApprovalInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  levels?: InputMaybe<ApprovalLevelArrRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  org?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
};

/** columns and relationships of "risksmart.approval_level" */
export type ApprovalLevel = {
  __typename?: 'approval_level';
  ApprovalId: Scalars['uuid']['output'];
  ApprovalRuleType: ApprovalRuleTypeEnum;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  SequenceOrder: Scalars['Int']['output'];
  /** An object relationship */
  approval?: Maybe<Approval>;
  /** An array relationship */
  approvers: Array<Approver>;
  /** An aggregate relationship */
  approvers_aggregate: ApproverAggregate;
  /** An object relationship */
  ruleType: ApprovalRuleType;
};


/** columns and relationships of "risksmart.approval_level" */
export type ApprovalLevelApproversArgs = {
  distinct_on?: InputMaybe<Array<ApproverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverOrderBy>>;
  where?: InputMaybe<ApproverBoolExp>;
};


/** columns and relationships of "risksmart.approval_level" */
export type ApprovalLevelApproversAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverOrderBy>>;
  where?: InputMaybe<ApproverBoolExp>;
};

/** aggregated selection of "risksmart.approval_level" */
export type ApprovalLevelAggregate = {
  __typename?: 'approval_level_aggregate';
  aggregate?: Maybe<ApprovalLevelAggregateFields>;
  nodes: Array<ApprovalLevel>;
};

export type ApprovalLevelAggregateBoolExp = {
  count?: InputMaybe<ApprovalLevelAggregateBoolExpCount>;
};

export type ApprovalLevelAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApprovalLevelBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.approval_level" */
export type ApprovalLevelAggregateFields = {
  __typename?: 'approval_level_aggregate_fields';
  avg?: Maybe<ApprovalLevelAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalLevelMaxFields>;
  min?: Maybe<ApprovalLevelMinFields>;
  stddev?: Maybe<ApprovalLevelStddevFields>;
  stddev_pop?: Maybe<ApprovalLevelStddevPopFields>;
  stddev_samp?: Maybe<ApprovalLevelStddevSampFields>;
  sum?: Maybe<ApprovalLevelSumFields>;
  var_pop?: Maybe<ApprovalLevelVarPopFields>;
  var_samp?: Maybe<ApprovalLevelVarSampFields>;
  variance?: Maybe<ApprovalLevelVarianceFields>;
};


/** aggregate fields of "risksmart.approval_level" */
export type ApprovalLevelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.approval_level" */
export type ApprovalLevelAggregateOrderBy = {
  avg?: InputMaybe<ApprovalLevelAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ApprovalLevelMaxOrderBy>;
  min?: InputMaybe<ApprovalLevelMinOrderBy>;
  stddev?: InputMaybe<ApprovalLevelStddevOrderBy>;
  stddev_pop?: InputMaybe<ApprovalLevelStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ApprovalLevelStddevSampOrderBy>;
  sum?: InputMaybe<ApprovalLevelSumOrderBy>;
  var_pop?: InputMaybe<ApprovalLevelVarPopOrderBy>;
  var_samp?: InputMaybe<ApprovalLevelVarSampOrderBy>;
  variance?: InputMaybe<ApprovalLevelVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.approval_level" */
export type ApprovalLevelArrRelInsertInput = {
  data: Array<ApprovalLevelInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ApprovalLevelOnConflict>;
};

/** columns and relationships of "risksmart.approval_level_audit" */
export type ApprovalLevelAudit = {
  __typename?: 'approval_level_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ApprovalId: Scalars['uuid']['output'];
  ApprovalRuleType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  SequenceOrder: Scalars['Int']['output'];
};

/** aggregated selection of "risksmart.approval_level_audit" */
export type ApprovalLevelAuditAggregate = {
  __typename?: 'approval_level_audit_aggregate';
  aggregate?: Maybe<ApprovalLevelAuditAggregateFields>;
  nodes: Array<ApprovalLevelAudit>;
};

/** aggregate fields of "risksmart.approval_level_audit" */
export type ApprovalLevelAuditAggregateFields = {
  __typename?: 'approval_level_audit_aggregate_fields';
  avg?: Maybe<ApprovalLevelAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalLevelAuditMaxFields>;
  min?: Maybe<ApprovalLevelAuditMinFields>;
  stddev?: Maybe<ApprovalLevelAuditStddevFields>;
  stddev_pop?: Maybe<ApprovalLevelAuditStddevPopFields>;
  stddev_samp?: Maybe<ApprovalLevelAuditStddevSampFields>;
  sum?: Maybe<ApprovalLevelAuditSumFields>;
  var_pop?: Maybe<ApprovalLevelAuditVarPopFields>;
  var_samp?: Maybe<ApprovalLevelAuditVarSampFields>;
  variance?: Maybe<ApprovalLevelAuditVarianceFields>;
};


/** aggregate fields of "risksmart.approval_level_audit" */
export type ApprovalLevelAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalLevelAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ApprovalLevelAuditAvgFields = {
  __typename?: 'approval_level_audit_avg_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_level_audit". All fields are combined with a logical 'AND'. */
export type ApprovalLevelAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ApprovalId?: InputMaybe<UuidComparisonExp>;
  ApprovalRuleType?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequenceOrder?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<ApprovalLevelAuditBoolExp>>;
  _not?: InputMaybe<ApprovalLevelAuditBoolExp>;
  _or?: InputMaybe<Array<ApprovalLevelAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approval_level_audit" */
export enum ApprovalLevelAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ApprovalLevelAuditPkey = 'approval_level_audit_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.approval_level_audit" */
export type ApprovalLevelAuditIncInput = {
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.approval_level_audit" */
export type ApprovalLevelAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ApprovalLevelAuditMaxFields = {
  __typename?: 'approval_level_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ApprovalId?: Maybe<Scalars['uuid']['output']>;
  ApprovalRuleType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequenceOrder?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type ApprovalLevelAuditMinFields = {
  __typename?: 'approval_level_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ApprovalId?: Maybe<Scalars['uuid']['output']>;
  ApprovalRuleType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequenceOrder?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.approval_level_audit" */
export type ApprovalLevelAuditMutationResponse = {
  __typename?: 'approval_level_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalLevelAudit>;
};

/** on_conflict condition type for table "risksmart.approval_level_audit" */
export type ApprovalLevelAuditOnConflict = {
  constraint: ApprovalLevelAuditConstraint;
  update_columns?: Array<ApprovalLevelAuditUpdateColumn>;
  where?: InputMaybe<ApprovalLevelAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval_level_audit". */
export type ApprovalLevelAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ApprovalId?: InputMaybe<OrderBy>;
  ApprovalRuleType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approval_level_audit */
export type ApprovalLevelAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.approval_level_audit" */
export enum ApprovalLevelAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ApprovalId = 'ApprovalId',
  /** column name */
  ApprovalRuleType = 'ApprovalRuleType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequenceOrder = 'SequenceOrder'
}

/** input type for updating data in table "risksmart.approval_level_audit" */
export type ApprovalLevelAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ApprovalLevelAuditStddevFields = {
  __typename?: 'approval_level_audit_stddev_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ApprovalLevelAuditStddevPopFields = {
  __typename?: 'approval_level_audit_stddev_pop_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ApprovalLevelAuditStddevSampFields = {
  __typename?: 'approval_level_audit_stddev_samp_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "approval_level_audit" */
export type ApprovalLevelAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalLevelAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalLevelAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ApprovalLevelAuditSumFields = {
  __typename?: 'approval_level_audit_sum_fields';
  SequenceOrder?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.approval_level_audit" */
export enum ApprovalLevelAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ApprovalId = 'ApprovalId',
  /** column name */
  ApprovalRuleType = 'ApprovalRuleType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequenceOrder = 'SequenceOrder'
}

export type ApprovalLevelAuditUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ApprovalLevelAuditIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalLevelAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalLevelAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ApprovalLevelAuditVarPopFields = {
  __typename?: 'approval_level_audit_var_pop_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ApprovalLevelAuditVarSampFields = {
  __typename?: 'approval_level_audit_var_samp_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ApprovalLevelAuditVarianceFields = {
  __typename?: 'approval_level_audit_variance_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ApprovalLevelAvgFields = {
  __typename?: 'approval_level_avg_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.approval_level" */
export type ApprovalLevelAvgOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_level". All fields are combined with a logical 'AND'. */
export type ApprovalLevelBoolExp = {
  ApprovalId?: InputMaybe<UuidComparisonExp>;
  ApprovalRuleType?: InputMaybe<ApprovalRuleTypeEnumComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequenceOrder?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<ApprovalLevelBoolExp>>;
  _not?: InputMaybe<ApprovalLevelBoolExp>;
  _or?: InputMaybe<Array<ApprovalLevelBoolExp>>;
  approval?: InputMaybe<ApprovalBoolExp>;
  approvers?: InputMaybe<ApproverBoolExp>;
  approvers_aggregate?: InputMaybe<ApproverAggregateBoolExp>;
  ruleType?: InputMaybe<ApprovalRuleTypeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.approval_level" */
export enum ApprovalLevelConstraint {
  /** unique or primary key constraint on columns "Id" */
  ApprovalLevelPkey = 'approval_level_pkey',
  /** unique or primary key constraint on columns "SequenceOrder", "ApprovalId" */
  IdxApprovalLevelSeqApprovalId = 'idx_approval_level_seq_approval_id'
}

/** input type for incrementing numeric columns in table "risksmart.approval_level" */
export type ApprovalLevelIncInput = {
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.approval_level" */
export type ApprovalLevelInsertInput = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<ApprovalRuleTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
  approval?: InputMaybe<ApprovalObjRelInsertInput>;
  approvers?: InputMaybe<ApproverArrRelInsertInput>;
  ruleType?: InputMaybe<ApprovalRuleTypeObjRelInsertInput>;
};

/** aggregate max on columns */
export type ApprovalLevelMaxFields = {
  __typename?: 'approval_level_max_fields';
  ApprovalId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequenceOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "risksmart.approval_level" */
export type ApprovalLevelMaxOrderBy = {
  ApprovalId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ApprovalLevelMinFields = {
  __typename?: 'approval_level_min_fields';
  ApprovalId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequenceOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "risksmart.approval_level" */
export type ApprovalLevelMinOrderBy = {
  ApprovalId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.approval_level" */
export type ApprovalLevelMutationResponse = {
  __typename?: 'approval_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalLevel>;
};

/** input type for inserting object relation for remote table "risksmart.approval_level" */
export type ApprovalLevelObjRelInsertInput = {
  data: ApprovalLevelInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ApprovalLevelOnConflict>;
};

/** on_conflict condition type for table "risksmart.approval_level" */
export type ApprovalLevelOnConflict = {
  constraint: ApprovalLevelConstraint;
  update_columns?: Array<ApprovalLevelUpdateColumn>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval_level". */
export type ApprovalLevelOrderBy = {
  ApprovalId?: InputMaybe<OrderBy>;
  ApprovalRuleType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequenceOrder?: InputMaybe<OrderBy>;
  approval?: InputMaybe<ApprovalOrderBy>;
  approvers_aggregate?: InputMaybe<ApproverAggregateOrderBy>;
  ruleType?: InputMaybe<ApprovalRuleTypeOrderBy>;
};

/** primary key columns input for table: risksmart.approval_level */
export type ApprovalLevelPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.approval_level" */
export enum ApprovalLevelSelectColumn {
  /** column name */
  ApprovalId = 'ApprovalId',
  /** column name */
  ApprovalRuleType = 'ApprovalRuleType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequenceOrder = 'SequenceOrder'
}

/** input type for updating data in table "risksmart.approval_level" */
export type ApprovalLevelSetInput = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<ApprovalRuleTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ApprovalLevelStddevFields = {
  __typename?: 'approval_level_stddev_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.approval_level" */
export type ApprovalLevelStddevOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ApprovalLevelStddevPopFields = {
  __typename?: 'approval_level_stddev_pop_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.approval_level" */
export type ApprovalLevelStddevPopOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ApprovalLevelStddevSampFields = {
  __typename?: 'approval_level_stddev_samp_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.approval_level" */
export type ApprovalLevelStddevSampOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "approval_level" */
export type ApprovalLevelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalLevelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalLevelStreamCursorValueInput = {
  ApprovalId?: InputMaybe<Scalars['uuid']['input']>;
  ApprovalRuleType?: InputMaybe<ApprovalRuleTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequenceOrder?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ApprovalLevelSumFields = {
  __typename?: 'approval_level_sum_fields';
  SequenceOrder?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.approval_level" */
export type ApprovalLevelSumOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.approval_level" */
export enum ApprovalLevelUpdateColumn {
  /** column name */
  ApprovalId = 'ApprovalId',
  /** column name */
  ApprovalRuleType = 'ApprovalRuleType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequenceOrder = 'SequenceOrder'
}

export type ApprovalLevelUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ApprovalLevelIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalLevelSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalLevelBoolExp;
};

/** aggregate var_pop on columns */
export type ApprovalLevelVarPopFields = {
  __typename?: 'approval_level_var_pop_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.approval_level" */
export type ApprovalLevelVarPopOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ApprovalLevelVarSampFields = {
  __typename?: 'approval_level_var_samp_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.approval_level" */
export type ApprovalLevelVarSampOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ApprovalLevelVarianceFields = {
  __typename?: 'approval_level_variance_fields';
  SequenceOrder?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.approval_level" */
export type ApprovalLevelVarianceOrderBy = {
  SequenceOrder?: InputMaybe<OrderBy>;
};

/** aggregate max on columns */
export type ApprovalMaxFields = {
  __typename?: 'approval_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.approval" */
export type ApprovalMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Workflow?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ApprovalMinFields = {
  __typename?: 'approval_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Workflow?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.approval" */
export type ApprovalMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Workflow?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.approval" */
export type ApprovalMutationResponse = {
  __typename?: 'approval_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Approval>;
};

/** input type for inserting object relation for remote table "risksmart.approval" */
export type ApprovalObjRelInsertInput = {
  data: ApprovalInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ApprovalOnConflict>;
};

/** on_conflict condition type for table "risksmart.approval" */
export type ApprovalOnConflict = {
  constraint: ApprovalConstraint;
  update_columns?: Array<ApprovalUpdateColumn>;
  where?: InputMaybe<ApprovalBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval". */
export type ApprovalOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  InFlightEditRule?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Workflow?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  levels_aggregate?: InputMaybe<ApprovalLevelAggregateOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  org?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
};

/** primary key columns input for table: risksmart.approval */
export type ApprovalPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** columns and relationships of "risksmart.approval_rule_type" */
export type ApprovalRuleType = {
  __typename?: 'approval_rule_type';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.approval_rule_type" */
export type ApprovalRuleTypeAggregate = {
  __typename?: 'approval_rule_type_aggregate';
  aggregate?: Maybe<ApprovalRuleTypeAggregateFields>;
  nodes: Array<ApprovalRuleType>;
};

/** aggregate fields of "risksmart.approval_rule_type" */
export type ApprovalRuleTypeAggregateFields = {
  __typename?: 'approval_rule_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalRuleTypeMaxFields>;
  min?: Maybe<ApprovalRuleTypeMinFields>;
};


/** aggregate fields of "risksmart.approval_rule_type" */
export type ApprovalRuleTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalRuleTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_rule_type". All fields are combined with a logical 'AND'. */
export type ApprovalRuleTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApprovalRuleTypeBoolExp>>;
  _not?: InputMaybe<ApprovalRuleTypeBoolExp>;
  _or?: InputMaybe<Array<ApprovalRuleTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approval_rule_type" */
export enum ApprovalRuleTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ApprovalRuleTypePkey = 'approval_rule_type_pkey'
}

export enum ApprovalRuleTypeEnum {
  /** All approve */
  AllApprove = 'all_approve',
  /** Any one approval */
  AnyOneApprove = 'any_one_approve',
  /** Majority approves */
  MajorityApprove = 'majority_approve'
}

/** Boolean expression to compare columns of type "approval_rule_type_enum". All fields are combined with logical 'AND'. */
export type ApprovalRuleTypeEnumComparisonExp = {
  _eq?: InputMaybe<ApprovalRuleTypeEnum>;
  _in?: InputMaybe<Array<ApprovalRuleTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ApprovalRuleTypeEnum>;
  _nin?: InputMaybe<Array<ApprovalRuleTypeEnum>>;
};

/** input type for inserting data into table "risksmart.approval_rule_type" */
export type ApprovalRuleTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ApprovalRuleTypeMaxFields = {
  __typename?: 'approval_rule_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ApprovalRuleTypeMinFields = {
  __typename?: 'approval_rule_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.approval_rule_type" */
export type ApprovalRuleTypeMutationResponse = {
  __typename?: 'approval_rule_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalRuleType>;
};

/** input type for inserting object relation for remote table "risksmart.approval_rule_type" */
export type ApprovalRuleTypeObjRelInsertInput = {
  data: ApprovalRuleTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ApprovalRuleTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.approval_rule_type" */
export type ApprovalRuleTypeOnConflict = {
  constraint: ApprovalRuleTypeConstraint;
  update_columns?: Array<ApprovalRuleTypeUpdateColumn>;
  where?: InputMaybe<ApprovalRuleTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval_rule_type". */
export type ApprovalRuleTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approval_rule_type */
export type ApprovalRuleTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.approval_rule_type" */
export enum ApprovalRuleTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.approval_rule_type" */
export type ApprovalRuleTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approval_rule_type" */
export type ApprovalRuleTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalRuleTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalRuleTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approval_rule_type" */
export enum ApprovalRuleTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ApprovalRuleTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalRuleTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalRuleTypeBoolExp;
};

/** select columns of table "risksmart.approval" */
export enum ApprovalSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  InFlightEditRule = 'InFlightEditRule',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Workflow = 'Workflow'
}

/** input type for updating data in table "risksmart.approval" */
export type ApprovalSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.approval_status" */
export type ApprovalStatus = {
  __typename?: 'approval_status';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.approval_status" */
export type ApprovalStatusAggregate = {
  __typename?: 'approval_status_aggregate';
  aggregate?: Maybe<ApprovalStatusAggregateFields>;
  nodes: Array<ApprovalStatus>;
};

/** aggregate fields of "risksmart.approval_status" */
export type ApprovalStatusAggregateFields = {
  __typename?: 'approval_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApprovalStatusMaxFields>;
  min?: Maybe<ApprovalStatusMinFields>;
};


/** aggregate fields of "risksmart.approval_status" */
export type ApprovalStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApprovalStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approval_status". All fields are combined with a logical 'AND'. */
export type ApprovalStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApprovalStatusBoolExp>>;
  _not?: InputMaybe<ApprovalStatusBoolExp>;
  _or?: InputMaybe<Array<ApprovalStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approval_status" */
export enum ApprovalStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  ApprovalStatusPkey = 'approval_status_pkey'
}

export enum ApprovalStatusEnum {
  /** Approved */
  Approved = 'approved',
  /** The parent node has been deleted */
  Deleted = 'deleted',
  /** failed */
  Failed = 'failed',
  /** Pending */
  Pending = 'pending',
  /** Rejected */
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "approval_status_enum". All fields are combined with logical 'AND'. */
export type ApprovalStatusEnumComparisonExp = {
  _eq?: InputMaybe<ApprovalStatusEnum>;
  _in?: InputMaybe<Array<ApprovalStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ApprovalStatusEnum>;
  _nin?: InputMaybe<Array<ApprovalStatusEnum>>;
};

/** input type for inserting data into table "risksmart.approval_status" */
export type ApprovalStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ApprovalStatusMaxFields = {
  __typename?: 'approval_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ApprovalStatusMinFields = {
  __typename?: 'approval_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.approval_status" */
export type ApprovalStatusMutationResponse = {
  __typename?: 'approval_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApprovalStatus>;
};

/** on_conflict condition type for table "risksmart.approval_status" */
export type ApprovalStatusOnConflict = {
  constraint: ApprovalStatusConstraint;
  update_columns?: Array<ApprovalStatusUpdateColumn>;
  where?: InputMaybe<ApprovalStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approval_status". */
export type ApprovalStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approval_status */
export type ApprovalStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.approval_status" */
export enum ApprovalStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.approval_status" */
export type ApprovalStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approval_status" */
export type ApprovalStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approval_status" */
export enum ApprovalStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ApprovalStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalStatusBoolExp;
};

/** Streaming cursor of the table "approval" */
export type ApprovalStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApprovalStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApprovalStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  InFlightEditRule?: InputMaybe<ApprovalInFlightEditRuleEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Workflow?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approval" */
export enum ApprovalUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  InFlightEditRule = 'InFlightEditRule',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Workflow = 'Workflow'
}

export type ApprovalUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApprovalSetInput>;
  /** filter the rows which have to be updated */
  where: ApprovalBoolExp;
};

/** columns and relationships of "risksmart.approver" */
export type Approver = {
  __typename?: 'approver';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LevelId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OwnerApprover?: Maybe<Scalars['Boolean']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<UserGroup>;
  /** An object relationship */
  level?: Maybe<ApprovalLevel>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  org?: Maybe<AuthOrganisation>;
  /** An array relationship */
  responses: Array<ApproverResponse>;
  /** An aggregate relationship */
  responses_aggregate: ApproverResponseAggregate;
  /** An object relationship */
  user?: Maybe<User>;
};


/** columns and relationships of "risksmart.approver" */
export type ApproverResponsesArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


/** columns and relationships of "risksmart.approver" */
export type ApproverResponsesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};

/** aggregated selection of "risksmart.approver" */
export type ApproverAggregate = {
  __typename?: 'approver_aggregate';
  aggregate?: Maybe<ApproverAggregateFields>;
  nodes: Array<Approver>;
};

export type ApproverAggregateBoolExp = {
  bool_and?: InputMaybe<ApproverAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ApproverAggregateBoolExpBoolOr>;
  count?: InputMaybe<ApproverAggregateBoolExpCount>;
};

export type ApproverAggregateBoolExpBoolAnd = {
  arguments: ApproverSelectColumnApproverAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApproverBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ApproverAggregateBoolExpBoolOr = {
  arguments: ApproverSelectColumnApproverAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApproverBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ApproverAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ApproverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApproverBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.approver" */
export type ApproverAggregateFields = {
  __typename?: 'approver_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApproverMaxFields>;
  min?: Maybe<ApproverMinFields>;
};


/** aggregate fields of "risksmart.approver" */
export type ApproverAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApproverSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.approver" */
export type ApproverAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ApproverMaxOrderBy>;
  min?: InputMaybe<ApproverMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.approver" */
export type ApproverArrRelInsertInput = {
  data: Array<ApproverInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ApproverOnConflict>;
};

/** columns and relationships of "risksmart.approver_audit" */
export type ApproverAudit = {
  __typename?: 'approver_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LevelId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OwnerApprover?: Maybe<Scalars['Boolean']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.approver_audit" */
export type ApproverAuditAggregate = {
  __typename?: 'approver_audit_aggregate';
  aggregate?: Maybe<ApproverAuditAggregateFields>;
  nodes: Array<ApproverAudit>;
};

/** aggregate fields of "risksmart.approver_audit" */
export type ApproverAuditAggregateFields = {
  __typename?: 'approver_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApproverAuditMaxFields>;
  min?: Maybe<ApproverAuditMinFields>;
};


/** aggregate fields of "risksmart.approver_audit" */
export type ApproverAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApproverAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approver_audit". All fields are combined with a logical 'AND'. */
export type ApproverAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LevelId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OwnerApprover?: InputMaybe<BooleanComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApproverAuditBoolExp>>;
  _not?: InputMaybe<ApproverAuditBoolExp>;
  _or?: InputMaybe<Array<ApproverAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approver_audit" */
export enum ApproverAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ApproverAuditPkey = 'approver_audit_pkey'
}

/** input type for inserting data into table "risksmart.approver_audit" */
export type ApproverAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ApproverAuditMaxFields = {
  __typename?: 'approver_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LevelId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ApproverAuditMinFields = {
  __typename?: 'approver_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LevelId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.approver_audit" */
export type ApproverAuditMutationResponse = {
  __typename?: 'approver_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApproverAudit>;
};

/** on_conflict condition type for table "risksmart.approver_audit" */
export type ApproverAuditOnConflict = {
  constraint: ApproverAuditConstraint;
  update_columns?: Array<ApproverAuditUpdateColumn>;
  where?: InputMaybe<ApproverAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approver_audit". */
export type ApproverAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LevelId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OwnerApprover?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approver_audit */
export type ApproverAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.approver_audit" */
export enum ApproverAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  LevelId = 'LevelId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerApprover = 'OwnerApprover',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.approver_audit" */
export type ApproverAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approver_audit" */
export type ApproverAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApproverAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApproverAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approver_audit" */
export enum ApproverAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  LevelId = 'LevelId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerApprover = 'OwnerApprover',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

export type ApproverAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApproverAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ApproverAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.approver". All fields are combined with a logical 'AND'. */
export type ApproverBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LevelId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OwnerApprover?: InputMaybe<BooleanComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApproverBoolExp>>;
  _not?: InputMaybe<ApproverBoolExp>;
  _or?: InputMaybe<Array<ApproverBoolExp>>;
  createdBy?: InputMaybe<UserBoolExp>;
  group?: InputMaybe<UserGroupBoolExp>;
  level?: InputMaybe<ApprovalLevelBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  org?: InputMaybe<AuthOrganisationBoolExp>;
  responses?: InputMaybe<ApproverResponseBoolExp>;
  responses_aggregate?: InputMaybe<ApproverResponseAggregateBoolExp>;
  user?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.approver" */
export enum ApproverConstraint {
  /** unique or primary key constraint on columns "Id" */
  ApproverPkey = 'approver_pkey',
  /** unique or primary key constraint on columns "LevelId", "UserId" */
  IdxApproverUserLevel = 'idx_approver_user_level'
}

/** input type for inserting data into table "risksmart.approver" */
export type ApproverInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  group?: InputMaybe<UserGroupObjRelInsertInput>;
  level?: InputMaybe<ApprovalLevelObjRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  org?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  responses?: InputMaybe<ApproverResponseArrRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type ApproverMaxFields = {
  __typename?: 'approver_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LevelId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.approver" */
export type ApproverMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LevelId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ApproverMinFields = {
  __typename?: 'approver_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LevelId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.approver" */
export type ApproverMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LevelId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.approver" */
export type ApproverMutationResponse = {
  __typename?: 'approver_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Approver>;
};

/** input type for inserting object relation for remote table "risksmart.approver" */
export type ApproverObjRelInsertInput = {
  data: ApproverInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ApproverOnConflict>;
};

/** on_conflict condition type for table "risksmart.approver" */
export type ApproverOnConflict = {
  constraint: ApproverConstraint;
  update_columns?: Array<ApproverUpdateColumn>;
  where?: InputMaybe<ApproverBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approver". */
export type ApproverOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LevelId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OwnerApprover?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  group?: InputMaybe<UserGroupOrderBy>;
  level?: InputMaybe<ApprovalLevelOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  org?: InputMaybe<AuthOrganisationOrderBy>;
  responses_aggregate?: InputMaybe<ApproverResponseAggregateOrderBy>;
  user?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.approver */
export type ApproverPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** columns and relationships of "risksmart.approver_response" */
export type ApproverResponse = {
  __typename?: 'approver_response';
  Approved?: Maybe<Scalars['Boolean']['output']>;
  ApproverId: Scalars['uuid']['output'];
  ChangeRequestId: Scalars['uuid']['output'];
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  approver: Approver;
  /** An object relationship */
  changeRequest?: Maybe<ChangeRequest>;
  /** An object relationship */
  overriddenBy?: Maybe<User>;
};

/** aggregated selection of "risksmart.approver_response" */
export type ApproverResponseAggregate = {
  __typename?: 'approver_response_aggregate';
  aggregate?: Maybe<ApproverResponseAggregateFields>;
  nodes: Array<ApproverResponse>;
};

export type ApproverResponseAggregateBoolExp = {
  bool_and?: InputMaybe<ApproverResponseAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<ApproverResponseAggregateBoolExpBoolOr>;
  count?: InputMaybe<ApproverResponseAggregateBoolExpCount>;
};

export type ApproverResponseAggregateBoolExpBoolAnd = {
  arguments: ApproverResponseSelectColumnApproverResponseAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApproverResponseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ApproverResponseAggregateBoolExpBoolOr = {
  arguments: ApproverResponseSelectColumnApproverResponseAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApproverResponseBoolExp>;
  predicate: BooleanComparisonExp;
};

export type ApproverResponseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ApproverResponseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.approver_response" */
export type ApproverResponseAggregateFields = {
  __typename?: 'approver_response_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ApproverResponseMaxFields>;
  min?: Maybe<ApproverResponseMinFields>;
};


/** aggregate fields of "risksmart.approver_response" */
export type ApproverResponseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.approver_response" */
export type ApproverResponseAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ApproverResponseMaxOrderBy>;
  min?: InputMaybe<ApproverResponseMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.approver_response" */
export type ApproverResponseArrRelInsertInput = {
  data: Array<ApproverResponseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ApproverResponseOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.approver_response". All fields are combined with a logical 'AND'. */
export type ApproverResponseBoolExp = {
  Approved?: InputMaybe<BooleanComparisonExp>;
  ApproverId?: InputMaybe<UuidComparisonExp>;
  ChangeRequestId?: InputMaybe<UuidComparisonExp>;
  Comment?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OverriddenAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OverriddenByUser?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ApproverResponseBoolExp>>;
  _not?: InputMaybe<ApproverResponseBoolExp>;
  _or?: InputMaybe<Array<ApproverResponseBoolExp>>;
  approver?: InputMaybe<ApproverBoolExp>;
  changeRequest?: InputMaybe<ChangeRequestBoolExp>;
  overriddenBy?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.approver_response" */
export enum ApproverResponseConstraint {
  /** unique or primary key constraint on columns "Id" */
  ApproverResponsePkey = 'approver_response_pkey'
}

/** input type for inserting data into table "risksmart.approver_response" */
export type ApproverResponseInsertInput = {
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  approver?: InputMaybe<ApproverObjRelInsertInput>;
  changeRequest?: InputMaybe<ChangeRequestObjRelInsertInput>;
  overriddenBy?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type ApproverResponseMaxFields = {
  __typename?: 'approver_response_max_fields';
  ApproverId?: Maybe<Scalars['uuid']['output']>;
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.approver_response" */
export type ApproverResponseMaxOrderBy = {
  ApproverId?: InputMaybe<OrderBy>;
  ChangeRequestId?: InputMaybe<OrderBy>;
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ApproverResponseMinFields = {
  __typename?: 'approver_response_min_fields';
  ApproverId?: Maybe<Scalars['uuid']['output']>;
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.approver_response" */
export type ApproverResponseMinOrderBy = {
  ApproverId?: InputMaybe<OrderBy>;
  ChangeRequestId?: InputMaybe<OrderBy>;
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.approver_response" */
export type ApproverResponseMutationResponse = {
  __typename?: 'approver_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ApproverResponse>;
};

/** on_conflict condition type for table "risksmart.approver_response" */
export type ApproverResponseOnConflict = {
  constraint: ApproverResponseConstraint;
  update_columns?: Array<ApproverResponseUpdateColumn>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approver_response". */
export type ApproverResponseOrderBy = {
  Approved?: InputMaybe<OrderBy>;
  ApproverId?: InputMaybe<OrderBy>;
  ChangeRequestId?: InputMaybe<OrderBy>;
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
  approver?: InputMaybe<ApproverOrderBy>;
  changeRequest?: InputMaybe<ChangeRequestOrderBy>;
  overriddenBy?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.approver_response */
export type ApproverResponsePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.approver_response" */
export enum ApproverResponseSelectColumn {
  /** column name */
  Approved = 'Approved',
  /** column name */
  ApproverId = 'ApproverId',
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser'
}

/** select "approver_response_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risksmart.approver_response" */
export enum ApproverResponseSelectColumnApproverResponseAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Approved = 'Approved'
}

/** select "approver_response_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risksmart.approver_response" */
export enum ApproverResponseSelectColumnApproverResponseAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Approved = 'Approved'
}

/** input type for updating data in table "risksmart.approver_response" */
export type ApproverResponseSetInput = {
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approver_response" */
export type ApproverResponseStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApproverResponseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApproverResponseStreamCursorValueInput = {
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approver_response" */
export enum ApproverResponseUpdateColumn {
  /** column name */
  Approved = 'Approved',
  /** column name */
  ApproverId = 'ApproverId',
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser'
}

export type ApproverResponseUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApproverResponseSetInput>;
  /** filter the rows which have to be updated */
  where: ApproverResponseBoolExp;
};

/** select columns of table "risksmart.approver" */
export enum ApproverSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  LevelId = 'LevelId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerApprover = 'OwnerApprover',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

/** select "approver_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risksmart.approver" */
export enum ApproverSelectColumnApproverAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  OwnerApprover = 'OwnerApprover'
}

/** select "approver_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risksmart.approver" */
export enum ApproverSelectColumnApproverAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  OwnerApprover = 'OwnerApprover'
}

/** input type for updating data in table "risksmart.approver" */
export type ApproverSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "approver" */
export type ApproverStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ApproverStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ApproverStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LevelId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerApprover?: InputMaybe<Scalars['Boolean']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approver" */
export enum ApproverUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  LevelId = 'LevelId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerApprover = 'OwnerApprover',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

export type ApproverUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ApproverSetInput>;
  /** filter the rows which have to be updated */
  where: ApproverBoolExp;
};

/** columns and relationships of "risksmart.assessment" */
export type Assessment = {
  __typename?: 'assessment';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: AssessmentStatusEnum;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  assessmentActions: Array<ActionParent>;
  /** An aggregate relationship */
  assessmentActions_aggregate: ActionParentAggregate;
  /** An array relationship */
  assessmentActivities: Array<AssessmentActivity>;
  /** An aggregate relationship */
  assessmentActivities_aggregate: AssessmentActivityAggregate;
  /** An array relationship */
  assessmentIssues: Array<IssueParent>;
  /** An aggregate relationship */
  assessmentIssues_aggregate: IssueParentAggregate;
  /** An array relationship */
  assessmentResults: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: AssessmentResultParentAggregate;
  /** An object relationship */
  completedByUser?: Maybe<User>;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  originatingItem?: Maybe<Node>;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActivitiesArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentActivitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentAssessmentResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.assessment" */
export type AssessmentTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivity = {
  __typename?: 'assessment_activity';
  ActivityType: AssessmentActivityTypeEnum;
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Status?: Maybe<AssessmentActivityStatusEnum>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  assignedUser?: Maybe<User>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentComplianceMonitoringAssessment?: Maybe<ComplianceMonitoringAssessment>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<InternalAuditReport>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.assessment_activity" */
export type AssessmentActivityPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.assessment_activity" */
export type AssessmentActivityAggregate = {
  __typename?: 'assessment_activity_aggregate';
  aggregate?: Maybe<AssessmentActivityAggregateFields>;
  nodes: Array<AssessmentActivity>;
};

export type AssessmentActivityAggregateBoolExp = {
  count?: InputMaybe<AssessmentActivityAggregateBoolExpCount>;
};

export type AssessmentActivityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AssessmentActivityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.assessment_activity" */
export type AssessmentActivityAggregateFields = {
  __typename?: 'assessment_activity_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentActivityMaxFields>;
  min?: Maybe<AssessmentActivityMinFields>;
};


/** aggregate fields of "risksmart.assessment_activity" */
export type AssessmentActivityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.assessment_activity" */
export type AssessmentActivityAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssessmentActivityMaxOrderBy>;
  min?: InputMaybe<AssessmentActivityMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AssessmentActivityAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.assessment_activity" */
export type AssessmentActivityArrRelInsertInput = {
  data: Array<AssessmentActivityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssessmentActivityOnConflict>;
};

/** columns and relationships of "risksmart.assessment_activity_audit" */
export type AssessmentActivityAudit = {
  __typename?: 'assessment_activity_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActivityType: Scalars['String']['output'];
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditAggregate = {
  __typename?: 'assessment_activity_audit_aggregate';
  aggregate?: Maybe<AssessmentActivityAuditAggregateFields>;
  nodes: Array<AssessmentActivityAudit>;
};

/** aggregate fields of "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditAggregateFields = {
  __typename?: 'assessment_activity_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentActivityAuditMaxFields>;
  min?: Maybe<AssessmentActivityAuditMinFields>;
};


/** aggregate fields of "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentActivityAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AssessmentActivityAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_activity_audit". All fields are combined with a logical 'AND'. */
export type AssessmentActivityAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActivityType?: InputMaybe<StringComparisonExp>;
  AssignedUser?: InputMaybe<StringComparisonExp>;
  CompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentActivityAuditBoolExp>>;
  _not?: InputMaybe<AssessmentActivityAuditBoolExp>;
  _or?: InputMaybe<Array<AssessmentActivityAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.assessment_activity_audit" */
export enum AssessmentActivityAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  AssessmentActivityAuditPkey = 'assessment_activity_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AssessmentActivityAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AssessmentActivityAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AssessmentActivityAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActivityType?: InputMaybe<Scalars['String']['input']>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssessmentActivityAuditMaxFields = {
  __typename?: 'assessment_activity_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActivityType?: Maybe<Scalars['String']['output']>;
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssessmentActivityAuditMinFields = {
  __typename?: 'assessment_activity_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActivityType?: Maybe<Scalars['String']['output']>;
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditMutationResponse = {
  __typename?: 'assessment_activity_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentActivityAudit>;
};

/** on_conflict condition type for table "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditOnConflict = {
  constraint: AssessmentActivityAuditConstraint;
  update_columns?: Array<AssessmentActivityAuditUpdateColumn>;
  where?: InputMaybe<AssessmentActivityAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_activity_audit". */
export type AssessmentActivityAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActivityType?: InputMaybe<OrderBy>;
  AssignedUser?: InputMaybe<OrderBy>;
  CompletionDate?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.assessment_activity_audit */
export type AssessmentActivityAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AssessmentActivityAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.assessment_activity_audit" */
export enum AssessmentActivityAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActivityType = 'ActivityType',
  /** column name */
  AssignedUser = 'AssignedUser',
  /** column name */
  CompletionDate = 'CompletionDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.assessment_activity_audit" */
export type AssessmentActivityAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActivityType?: InputMaybe<Scalars['String']['input']>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "assessment_activity_audit" */
export type AssessmentActivityAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentActivityAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentActivityAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActivityType?: InputMaybe<Scalars['String']['input']>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.assessment_activity_audit" */
export enum AssessmentActivityAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActivityType = 'ActivityType',
  /** column name */
  AssignedUser = 'AssignedUser',
  /** column name */
  CompletionDate = 'CompletionDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Title = 'Title'
}

export type AssessmentActivityAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AssessmentActivityAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AssessmentActivityAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AssessmentActivityAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AssessmentActivityAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AssessmentActivityAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentActivityAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentActivityAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_activity". All fields are combined with a logical 'AND'. */
export type AssessmentActivityBoolExp = {
  ActivityType?: InputMaybe<AssessmentActivityTypeEnumComparisonExp>;
  AssignedUser?: InputMaybe<StringComparisonExp>;
  CompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  Status?: InputMaybe<AssessmentActivityStatusEnumComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentActivityBoolExp>>;
  _not?: InputMaybe<AssessmentActivityBoolExp>;
  _or?: InputMaybe<Array<AssessmentActivityBoolExp>>;
  assignedUser?: InputMaybe<UserBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentComplianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.assessment_activity" */
export enum AssessmentActivityConstraint {
  /** unique or primary key constraint on columns "Id" */
  AssessmentActivityPkey = 'assessment_activity_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AssessmentActivityDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AssessmentActivityDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AssessmentActivityDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.assessment_activity" */
export type AssessmentActivityInsertInput = {
  ActivityType?: InputMaybe<AssessmentActivityTypeEnum>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<AssessmentActivityStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<UserObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentComplianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentObjRelInsertInput>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type AssessmentActivityMaxFields = {
  __typename?: 'assessment_activity_max_fields';
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.assessment_activity" */
export type AssessmentActivityMaxOrderBy = {
  AssignedUser?: InputMaybe<OrderBy>;
  CompletionDate?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssessmentActivityMinFields = {
  __typename?: 'assessment_activity_min_fields';
  AssignedUser?: Maybe<Scalars['String']['output']>;
  CompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.assessment_activity" */
export type AssessmentActivityMinOrderBy = {
  AssignedUser?: InputMaybe<OrderBy>;
  CompletionDate?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.assessment_activity" */
export type AssessmentActivityMutationResponse = {
  __typename?: 'assessment_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentActivity>;
};

/** input type for inserting object relation for remote table "risksmart.assessment_activity" */
export type AssessmentActivityObjRelInsertInput = {
  data: AssessmentActivityInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AssessmentActivityOnConflict>;
};

/** on_conflict condition type for table "risksmart.assessment_activity" */
export type AssessmentActivityOnConflict = {
  constraint: AssessmentActivityConstraint;
  update_columns?: Array<AssessmentActivityUpdateColumn>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_activity". */
export type AssessmentActivityOrderBy = {
  ActivityType?: InputMaybe<OrderBy>;
  AssignedUser?: InputMaybe<OrderBy>;
  CompletionDate?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  assignedUser?: InputMaybe<UserOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentComplianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentOrderBy>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.assessment_activity */
export type AssessmentActivityPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AssessmentActivityPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.assessment_activity" */
export enum AssessmentActivitySelectColumn {
  /** column name */
  ActivityType = 'ActivityType',
  /** column name */
  AssignedUser = 'AssignedUser',
  /** column name */
  CompletionDate = 'CompletionDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.assessment_activity" */
export type AssessmentActivitySetInput = {
  ActivityType?: InputMaybe<AssessmentActivityTypeEnum>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<AssessmentActivityStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.assessment_activity_status" */
export type AssessmentActivityStatus = {
  __typename?: 'assessment_activity_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusAggregate = {
  __typename?: 'assessment_activity_status_aggregate';
  aggregate?: Maybe<AssessmentActivityStatusAggregateFields>;
  nodes: Array<AssessmentActivityStatus>;
};

/** aggregate fields of "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusAggregateFields = {
  __typename?: 'assessment_activity_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentActivityStatusMaxFields>;
  min?: Maybe<AssessmentActivityStatusMinFields>;
};


/** aggregate fields of "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentActivityStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_activity_status". All fields are combined with a logical 'AND'. */
export type AssessmentActivityStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentActivityStatusBoolExp>>;
  _not?: InputMaybe<AssessmentActivityStatusBoolExp>;
  _or?: InputMaybe<Array<AssessmentActivityStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.assessment_activity_status" */
export enum AssessmentActivityStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  AssessmentActivityStatusPkey = 'assessment_activity_status_pkey'
}

export enum AssessmentActivityStatusEnum {
  /** Complete */
  Complete = 'complete',
  /** In Progress */
  Inprogress = 'inprogress',
  /** Not Started */
  Notstarted = 'notstarted'
}

/** Boolean expression to compare columns of type "assessment_activity_status_enum". All fields are combined with logical 'AND'. */
export type AssessmentActivityStatusEnumComparisonExp = {
  _eq?: InputMaybe<AssessmentActivityStatusEnum>;
  _in?: InputMaybe<Array<AssessmentActivityStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AssessmentActivityStatusEnum>;
  _nin?: InputMaybe<Array<AssessmentActivityStatusEnum>>;
};

/** input type for inserting data into table "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssessmentActivityStatusMaxFields = {
  __typename?: 'assessment_activity_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssessmentActivityStatusMinFields = {
  __typename?: 'assessment_activity_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusMutationResponse = {
  __typename?: 'assessment_activity_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentActivityStatus>;
};

/** on_conflict condition type for table "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusOnConflict = {
  constraint: AssessmentActivityStatusConstraint;
  update_columns?: Array<AssessmentActivityStatusUpdateColumn>;
  where?: InputMaybe<AssessmentActivityStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_activity_status". */
export type AssessmentActivityStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.assessment_activity_status */
export type AssessmentActivityStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.assessment_activity_status" */
export enum AssessmentActivityStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.assessment_activity_status" */
export type AssessmentActivityStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "assessment_activity_status" */
export type AssessmentActivityStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentActivityStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentActivityStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.assessment_activity_status" */
export enum AssessmentActivityStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AssessmentActivityStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentActivityStatusSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentActivityStatusBoolExp;
};

/** Streaming cursor of the table "assessment_activity" */
export type AssessmentActivityStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentActivityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentActivityStreamCursorValueInput = {
  ActivityType?: InputMaybe<AssessmentActivityTypeEnum>;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<AssessmentActivityStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.assessment_activity_type" */
export type AssessmentActivityType = {
  __typename?: 'assessment_activity_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeAggregate = {
  __typename?: 'assessment_activity_type_aggregate';
  aggregate?: Maybe<AssessmentActivityTypeAggregateFields>;
  nodes: Array<AssessmentActivityType>;
};

/** aggregate fields of "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeAggregateFields = {
  __typename?: 'assessment_activity_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentActivityTypeMaxFields>;
  min?: Maybe<AssessmentActivityTypeMinFields>;
};


/** aggregate fields of "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentActivityTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_activity_type". All fields are combined with a logical 'AND'. */
export type AssessmentActivityTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentActivityTypeBoolExp>>;
  _not?: InputMaybe<AssessmentActivityTypeBoolExp>;
  _or?: InputMaybe<Array<AssessmentActivityTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.assessment_activity_type" */
export enum AssessmentActivityTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  AssessmentActivityTypePkey = 'assessment_activity_type_pkey'
}

export enum AssessmentActivityTypeEnum {
  /** Interview */
  Interview = 'interview',
  /** Meeting */
  Meeting = 'meeting',
  /** Reminder */
  Reminder = 'reminder',
  /** Review */
  Review = 'review',
  /** Task */
  Task = 'task'
}

/** Boolean expression to compare columns of type "assessment_activity_type_enum". All fields are combined with logical 'AND'. */
export type AssessmentActivityTypeEnumComparisonExp = {
  _eq?: InputMaybe<AssessmentActivityTypeEnum>;
  _in?: InputMaybe<Array<AssessmentActivityTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AssessmentActivityTypeEnum>;
  _nin?: InputMaybe<Array<AssessmentActivityTypeEnum>>;
};

/** input type for inserting data into table "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssessmentActivityTypeMaxFields = {
  __typename?: 'assessment_activity_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssessmentActivityTypeMinFields = {
  __typename?: 'assessment_activity_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeMutationResponse = {
  __typename?: 'assessment_activity_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentActivityType>;
};

/** on_conflict condition type for table "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeOnConflict = {
  constraint: AssessmentActivityTypeConstraint;
  update_columns?: Array<AssessmentActivityTypeUpdateColumn>;
  where?: InputMaybe<AssessmentActivityTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_activity_type". */
export type AssessmentActivityTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.assessment_activity_type */
export type AssessmentActivityTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.assessment_activity_type" */
export enum AssessmentActivityTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.assessment_activity_type" */
export type AssessmentActivityTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "assessment_activity_type" */
export type AssessmentActivityTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentActivityTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentActivityTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.assessment_activity_type" */
export enum AssessmentActivityTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AssessmentActivityTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentActivityTypeSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentActivityTypeBoolExp;
};

/** update columns of table "risksmart.assessment_activity" */
export enum AssessmentActivityUpdateColumn {
  /** column name */
  ActivityType = 'ActivityType',
  /** column name */
  AssignedUser = 'AssignedUser',
  /** column name */
  CompletionDate = 'CompletionDate',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Title = 'Title'
}

export type AssessmentActivityUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AssessmentActivityAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AssessmentActivityDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AssessmentActivityDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AssessmentActivityDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AssessmentActivityPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentActivitySetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentActivityBoolExp;
};

/** aggregated selection of "risksmart.assessment" */
export type AssessmentAggregate = {
  __typename?: 'assessment_aggregate';
  aggregate?: Maybe<AssessmentAggregateFields>;
  nodes: Array<Assessment>;
};

/** aggregate fields of "risksmart.assessment" */
export type AssessmentAggregateFields = {
  __typename?: 'assessment_aggregate_fields';
  avg?: Maybe<AssessmentAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentMaxFields>;
  min?: Maybe<AssessmentMinFields>;
  stddev?: Maybe<AssessmentStddevFields>;
  stddev_pop?: Maybe<AssessmentStddevPopFields>;
  stddev_samp?: Maybe<AssessmentStddevSampFields>;
  sum?: Maybe<AssessmentSumFields>;
  var_pop?: Maybe<AssessmentVarPopFields>;
  var_samp?: Maybe<AssessmentVarSampFields>;
  variance?: Maybe<AssessmentVarianceFields>;
};


/** aggregate fields of "risksmart.assessment" */
export type AssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AssessmentAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.assessment_audit" */
export type AssessmentAudit = {
  __typename?: 'assessment_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.assessment_audit" */
export type AssessmentAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.assessment_audit" */
export type AssessmentAuditAggregate = {
  __typename?: 'assessment_audit_aggregate';
  aggregate?: Maybe<AssessmentAuditAggregateFields>;
  nodes: Array<AssessmentAudit>;
};

/** aggregate fields of "risksmart.assessment_audit" */
export type AssessmentAuditAggregateFields = {
  __typename?: 'assessment_audit_aggregate_fields';
  avg?: Maybe<AssessmentAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentAuditMaxFields>;
  min?: Maybe<AssessmentAuditMinFields>;
  stddev?: Maybe<AssessmentAuditStddevFields>;
  stddev_pop?: Maybe<AssessmentAuditStddevPopFields>;
  stddev_samp?: Maybe<AssessmentAuditStddevSampFields>;
  sum?: Maybe<AssessmentAuditSumFields>;
  var_pop?: Maybe<AssessmentAuditVarPopFields>;
  var_samp?: Maybe<AssessmentAuditVarSampFields>;
  variance?: Maybe<AssessmentAuditVarianceFields>;
};


/** aggregate fields of "risksmart.assessment_audit" */
export type AssessmentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type AssessmentAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type AssessmentAuditAvgFields = {
  __typename?: 'assessment_audit_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_audit". All fields are combined with a logical 'AND'. */
export type AssessmentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActualCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  CompletedBy?: InputMaybe<StringComparisonExp>;
  CompletedByUser?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OriginatingItemId?: InputMaybe<UuidComparisonExp>;
  Outcome?: InputMaybe<IntComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  StartDate?: InputMaybe<TimestamptzComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  TargetCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentAuditBoolExp>>;
  _not?: InputMaybe<AssessmentAuditBoolExp>;
  _or?: InputMaybe<Array<AssessmentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.assessment_audit" */
export enum AssessmentAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  AssessmentAuditPkey = 'assessment_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AssessmentAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AssessmentAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AssessmentAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.assessment_audit" */
export type AssessmentAuditIncInput = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.assessment_audit" */
export type AssessmentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssessmentAuditMaxFields = {
  __typename?: 'assessment_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssessmentAuditMinFields = {
  __typename?: 'assessment_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment_audit" */
export type AssessmentAuditMutationResponse = {
  __typename?: 'assessment_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentAudit>;
};

/** on_conflict condition type for table "risksmart.assessment_audit" */
export type AssessmentAuditOnConflict = {
  constraint: AssessmentAuditConstraint;
  update_columns?: Array<AssessmentAuditUpdateColumn>;
  where?: InputMaybe<AssessmentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_audit". */
export type AssessmentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActualCompletionDate?: InputMaybe<OrderBy>;
  CompletedBy?: InputMaybe<OrderBy>;
  CompletedByUser?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OriginatingItemId?: InputMaybe<OrderBy>;
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  TargetCompletionDate?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.assessment_audit */
export type AssessmentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AssessmentAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.assessment_audit" */
export enum AssessmentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedBy = 'CompletedBy',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.assessment_audit" */
export type AssessmentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type AssessmentAuditStddevFields = {
  __typename?: 'assessment_audit_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type AssessmentAuditStddevPopFields = {
  __typename?: 'assessment_audit_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type AssessmentAuditStddevSampFields = {
  __typename?: 'assessment_audit_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "assessment_audit" */
export type AssessmentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AssessmentAuditSumFields = {
  __typename?: 'assessment_audit_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.assessment_audit" */
export enum AssessmentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedBy = 'CompletedBy',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

export type AssessmentAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AssessmentAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AssessmentAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AssessmentAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AssessmentAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssessmentAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AssessmentAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentAuditBoolExp;
};

/** aggregate var_pop on columns */
export type AssessmentAuditVarPopFields = {
  __typename?: 'assessment_audit_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type AssessmentAuditVarSampFields = {
  __typename?: 'assessment_audit_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AssessmentAuditVarianceFields = {
  __typename?: 'assessment_audit_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type AssessmentAvgFields = {
  __typename?: 'assessment_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment". All fields are combined with a logical 'AND'. */
export type AssessmentBoolExp = {
  ActualCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  CompletedByUser?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OriginatingItemId?: InputMaybe<UuidComparisonExp>;
  Outcome?: InputMaybe<IntComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  StartDate?: InputMaybe<TimestamptzComparisonExp>;
  Status?: InputMaybe<AssessmentStatusEnumComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  TargetCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentBoolExp>>;
  _not?: InputMaybe<AssessmentBoolExp>;
  _or?: InputMaybe<Array<AssessmentBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  assessmentActions?: InputMaybe<ActionParentBoolExp>;
  assessmentActions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  assessmentActivities?: InputMaybe<AssessmentActivityBoolExp>;
  assessmentActivities_aggregate?: InputMaybe<AssessmentActivityAggregateBoolExp>;
  assessmentIssues?: InputMaybe<IssueParentBoolExp>;
  assessmentIssues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  assessmentResults?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  completedByUser?: InputMaybe<UserBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  originatingItem?: InputMaybe<NodeBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.assessment" */
export enum AssessmentConstraint {
  /** unique or primary key constraint on columns "Id" */
  AssessmentPkey = 'assessment_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxAssessmentOrgkeySequentialid = 'idx_assessment_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type AssessmentDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type AssessmentDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type AssessmentDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.assessment" */
export type AssessmentIncInput = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.assessment" */
export type AssessmentInsertInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  assessmentActions?: InputMaybe<ActionParentArrRelInsertInput>;
  assessmentActivities?: InputMaybe<AssessmentActivityArrRelInsertInput>;
  assessmentIssues?: InputMaybe<IssueParentArrRelInsertInput>;
  assessmentResults?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  completedByUser?: InputMaybe<UserObjRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  originatingItem?: InputMaybe<NodeObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
};

/** aggregate max on columns */
export type AssessmentMaxFields = {
  __typename?: 'assessment_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssessmentMinFields = {
  __typename?: 'assessment_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment" */
export type AssessmentMutationResponse = {
  __typename?: 'assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Assessment>;
};

/** input type for inserting object relation for remote table "risksmart.assessment" */
export type AssessmentObjRelInsertInput = {
  data: AssessmentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AssessmentOnConflict>;
};

/** on_conflict condition type for table "risksmart.assessment" */
export type AssessmentOnConflict = {
  constraint: AssessmentConstraint;
  update_columns?: Array<AssessmentUpdateColumn>;
  where?: InputMaybe<AssessmentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment". */
export type AssessmentOrderBy = {
  ActualCompletionDate?: InputMaybe<OrderBy>;
  CompletedByUser?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OriginatingItemId?: InputMaybe<OrderBy>;
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  TargetCompletionDate?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  assessmentActions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  assessmentActivities_aggregate?: InputMaybe<AssessmentActivityAggregateOrderBy>;
  assessmentIssues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  completedByUser?: InputMaybe<UserOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  originatingItem?: InputMaybe<NodeOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.assessment */
export type AssessmentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type AssessmentPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.assessment_result_parent" */
export type AssessmentResultParent = {
  __typename?: 'assessment_result_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: ParentTypeEnum;
  ResultType: ParentTypeEnum;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  assessment_result?: Maybe<Node>;
  /** An object relationship */
  complianceMonitoringAssessment?: Maybe<ComplianceMonitoringAssessment>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  documentAssessmentResult?: Maybe<DocumentAssessmentResult>;
  /** An object relationship */
  impactRating?: Maybe<ImpactRating>;
  /** An object relationship */
  internalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  node?: Maybe<Node>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  obligationAssessmentResult?: Maybe<ObligationAssessmentResult>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
  /** An object relationship */
  riskAssessmentResult?: Maybe<RiskAssessmentResult>;
  /** An object relationship */
  testResult?: Maybe<TestResult>;
};

/** aggregated selection of "risksmart.assessment_result_parent" */
export type AssessmentResultParentAggregate = {
  __typename?: 'assessment_result_parent_aggregate';
  aggregate?: Maybe<AssessmentResultParentAggregateFields>;
  nodes: Array<AssessmentResultParent>;
};

export type AssessmentResultParentAggregateBoolExp = {
  count?: InputMaybe<AssessmentResultParentAggregateBoolExpCount>;
};

export type AssessmentResultParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AssessmentResultParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.assessment_result_parent" */
export type AssessmentResultParentAggregateFields = {
  __typename?: 'assessment_result_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentResultParentMaxFields>;
  min?: Maybe<AssessmentResultParentMinFields>;
};


/** aggregate fields of "risksmart.assessment_result_parent" */
export type AssessmentResultParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.assessment_result_parent" */
export type AssessmentResultParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssessmentResultParentMaxOrderBy>;
  min?: InputMaybe<AssessmentResultParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.assessment_result_parent" */
export type AssessmentResultParentArrRelInsertInput = {
  data: Array<AssessmentResultParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssessmentResultParentOnConflict>;
};

/** columns and relationships of "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAudit = {
  __typename?: 'assessment_result_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: Scalars['String']['output'];
  ResultType: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditAggregate = {
  __typename?: 'assessment_result_parent_audit_aggregate';
  aggregate?: Maybe<AssessmentResultParentAuditAggregateFields>;
  nodes: Array<AssessmentResultParentAudit>;
};

export type AssessmentResultParentAuditAggregateBoolExp = {
  count?: InputMaybe<AssessmentResultParentAuditAggregateBoolExpCount>;
};

export type AssessmentResultParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AssessmentResultParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditAggregateFields = {
  __typename?: 'assessment_result_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentResultParentAuditMaxFields>;
  min?: Maybe<AssessmentResultParentAuditMinFields>;
};


/** aggregate fields of "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AssessmentResultParentAuditMaxOrderBy>;
  min?: InputMaybe<AssessmentResultParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditArrRelInsertInput = {
  data: Array<AssessmentResultParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AssessmentResultParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_result_parent_audit". All fields are combined with a logical 'AND'. */
export type AssessmentResultParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<StringComparisonExp>;
  ResultType?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentResultParentAuditBoolExp>>;
  _not?: InputMaybe<AssessmentResultParentAuditBoolExp>;
  _or?: InputMaybe<Array<AssessmentResultParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.assessment_result_parent_audit" */
export enum AssessmentResultParentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "Id", "ModifiedAtTimestamp" */
  AssessmentResultParentAuditPkey = 'assessment_result_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
  ResultType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssessmentResultParentAuditMaxFields = {
  __typename?: 'assessment_result_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
  ResultType?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  ResultType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssessmentResultParentAuditMinFields = {
  __typename?: 'assessment_result_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
  ResultType?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  ResultType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditMutationResponse = {
  __typename?: 'assessment_result_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentResultParentAudit>;
};

/** on_conflict condition type for table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditOnConflict = {
  constraint: AssessmentResultParentAuditConstraint;
  update_columns?: Array<AssessmentResultParentAuditUpdateColumn>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_result_parent_audit". */
export type AssessmentResultParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  ResultType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.assessment_result_parent_audit */
export type AssessmentResultParentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.assessment_result_parent_audit" */
export enum AssessmentResultParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType',
  /** column name */
  ResultType = 'ResultType'
}

/** input type for updating data in table "risksmart.assessment_result_parent_audit" */
export type AssessmentResultParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
  ResultType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "assessment_result_parent_audit" */
export type AssessmentResultParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentResultParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentResultParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
  ResultType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.assessment_result_parent_audit" */
export enum AssessmentResultParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType',
  /** column name */
  ResultType = 'ResultType'
}

export type AssessmentResultParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentResultParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentResultParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_result_parent". All fields are combined with a logical 'AND'. */
export type AssessmentResultParentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  ResultType?: InputMaybe<ParentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<AssessmentResultParentBoolExp>>;
  _not?: InputMaybe<AssessmentResultParentBoolExp>;
  _or?: InputMaybe<Array<AssessmentResultParentBoolExp>>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  assessment_result?: InputMaybe<NodeBoolExp>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  document?: InputMaybe<DocumentBoolExp>;
  documentAssessmentResult?: InputMaybe<DocumentAssessmentResultBoolExp>;
  impactRating?: InputMaybe<ImpactRatingBoolExp>;
  internalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  node?: InputMaybe<NodeBoolExp>;
  obligation?: InputMaybe<ObligationBoolExp>;
  obligationAssessmentResult?: InputMaybe<ObligationAssessmentResultBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
  riskAssessmentResult?: InputMaybe<RiskAssessmentResultBoolExp>;
  testResult?: InputMaybe<TestResultBoolExp>;
};

/** unique or primary key constraints on table "risksmart.assessment_result_parent" */
export enum AssessmentResultParentConstraint {
  /** unique or primary key constraint on columns "ParentId", "Id" */
  AssessmentResultParentPkey = 'assessment_result_parent_pkey'
}

/** input type for inserting data into table "risksmart.assessment_result_parent" */
export type AssessmentResultParentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  ResultType?: InputMaybe<ParentTypeEnum>;
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  assessment_result?: InputMaybe<NodeObjRelInsertInput>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentAssessmentResult?: InputMaybe<DocumentAssessmentResultObjRelInsertInput>;
  impactRating?: InputMaybe<ImpactRatingObjRelInsertInput>;
  internalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  node?: InputMaybe<NodeObjRelInsertInput>;
  obligation?: InputMaybe<ObligationObjRelInsertInput>;
  obligationAssessmentResult?: InputMaybe<ObligationAssessmentResultObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
  riskAssessmentResult?: InputMaybe<RiskAssessmentResultObjRelInsertInput>;
  testResult?: InputMaybe<TestResultObjRelInsertInput>;
};

/** aggregate max on columns */
export type AssessmentResultParentMaxFields = {
  __typename?: 'assessment_result_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.assessment_result_parent" */
export type AssessmentResultParentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AssessmentResultParentMinFields = {
  __typename?: 'assessment_result_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.assessment_result_parent" */
export type AssessmentResultParentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.assessment_result_parent" */
export type AssessmentResultParentMutationResponse = {
  __typename?: 'assessment_result_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentResultParent>;
};

/** on_conflict condition type for table "risksmart.assessment_result_parent" */
export type AssessmentResultParentOnConflict = {
  constraint: AssessmentResultParentConstraint;
  update_columns?: Array<AssessmentResultParentUpdateColumn>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_result_parent". */
export type AssessmentResultParentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  ResultType?: InputMaybe<OrderBy>;
  assessment?: InputMaybe<AssessmentOrderBy>;
  assessment_result?: InputMaybe<NodeOrderBy>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  documentAssessmentResult?: InputMaybe<DocumentAssessmentResultOrderBy>;
  impactRating?: InputMaybe<ImpactRatingOrderBy>;
  internalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  node?: InputMaybe<NodeOrderBy>;
  obligation?: InputMaybe<ObligationOrderBy>;
  obligationAssessmentResult?: InputMaybe<ObligationAssessmentResultOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
  riskAssessmentResult?: InputMaybe<RiskAssessmentResultOrderBy>;
  testResult?: InputMaybe<TestResultOrderBy>;
};

/** primary key columns input for table: risksmart.assessment_result_parent */
export type AssessmentResultParentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.assessment_result_parent" */
export enum AssessmentResultParentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType',
  /** column name */
  ResultType = 'ResultType'
}

/** input type for updating data in table "risksmart.assessment_result_parent" */
export type AssessmentResultParentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  ResultType?: InputMaybe<ParentTypeEnum>;
};

/** Streaming cursor of the table "assessment_result_parent" */
export type AssessmentResultParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentResultParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentResultParentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  ResultType?: InputMaybe<ParentTypeEnum>;
};

/** update columns of table "risksmart.assessment_result_parent" */
export enum AssessmentResultParentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType',
  /** column name */
  ResultType = 'ResultType'
}

export type AssessmentResultParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentResultParentSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentResultParentBoolExp;
};

/** select columns of table "risksmart.assessment" */
export enum AssessmentSelectColumn {
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.assessment" */
export type AssessmentSetInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.assessment_status" */
export type AssessmentStatus = {
  __typename?: 'assessment_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.assessment_status" */
export type AssessmentStatusAggregate = {
  __typename?: 'assessment_status_aggregate';
  aggregate?: Maybe<AssessmentStatusAggregateFields>;
  nodes: Array<AssessmentStatus>;
};

/** aggregate fields of "risksmart.assessment_status" */
export type AssessmentStatusAggregateFields = {
  __typename?: 'assessment_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AssessmentStatusMaxFields>;
  min?: Maybe<AssessmentStatusMinFields>;
};


/** aggregate fields of "risksmart.assessment_status" */
export type AssessmentStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AssessmentStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.assessment_status". All fields are combined with a logical 'AND'. */
export type AssessmentStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AssessmentStatusBoolExp>>;
  _not?: InputMaybe<AssessmentStatusBoolExp>;
  _or?: InputMaybe<Array<AssessmentStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.assessment_status" */
export enum AssessmentStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  AssessmentStatusPkey = 'assessment_status_pkey'
}

export enum AssessmentStatusEnum {
  /** Complete */
  Complete = 'complete',
  /** In Progress */
  Inprogress = 'inprogress',
  /** Not Started */
  Notstarted = 'notstarted'
}

/** Boolean expression to compare columns of type "assessment_status_enum". All fields are combined with logical 'AND'. */
export type AssessmentStatusEnumComparisonExp = {
  _eq?: InputMaybe<AssessmentStatusEnum>;
  _in?: InputMaybe<Array<AssessmentStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AssessmentStatusEnum>;
  _nin?: InputMaybe<Array<AssessmentStatusEnum>>;
};

/** input type for inserting data into table "risksmart.assessment_status" */
export type AssessmentStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AssessmentStatusMaxFields = {
  __typename?: 'assessment_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AssessmentStatusMinFields = {
  __typename?: 'assessment_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.assessment_status" */
export type AssessmentStatusMutationResponse = {
  __typename?: 'assessment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AssessmentStatus>;
};

/** on_conflict condition type for table "risksmart.assessment_status" */
export type AssessmentStatusOnConflict = {
  constraint: AssessmentStatusConstraint;
  update_columns?: Array<AssessmentStatusUpdateColumn>;
  where?: InputMaybe<AssessmentStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.assessment_status". */
export type AssessmentStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.assessment_status */
export type AssessmentStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.assessment_status" */
export enum AssessmentStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.assessment_status" */
export type AssessmentStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "assessment_status" */
export type AssessmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.assessment_status" */
export enum AssessmentStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AssessmentStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentStatusSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentStatusBoolExp;
};

/** aggregate stddev on columns */
export type AssessmentStddevFields = {
  __typename?: 'assessment_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type AssessmentStddevPopFields = {
  __typename?: 'assessment_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type AssessmentStddevSampFields = {
  __typename?: 'assessment_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "assessment" */
export type AssessmentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AssessmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AssessmentStreamCursorValueInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type AssessmentSumFields = {
  __typename?: 'assessment_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.assessment" */
export enum AssessmentUpdateColumn {
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

export type AssessmentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<AssessmentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<AssessmentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<AssessmentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<AssessmentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<AssessmentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<AssessmentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AssessmentSetInput>;
  /** filter the rows which have to be updated */
  where: AssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type AssessmentVarPopFields = {
  __typename?: 'assessment_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type AssessmentVarSampFields = {
  __typename?: 'assessment_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type AssessmentVarianceFields = {
  __typename?: 'assessment_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.attestation_config" */
export type AttestationConfig = {
  __typename?: 'attestation_config';
  AttestationTimeLimit?: Maybe<Scalars['interval']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  PromptText?: Maybe<Scalars['String']['output']>;
  RequireGlobalAttestation: Scalars['Boolean']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  groups: Array<AttestationGroup>;
  /** An aggregate relationship */
  groups_aggregate: AttestationGroupAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent: Node;
  /** An array relationship */
  records: Array<AttestationRecord>;
  /** An aggregate relationship */
  records_aggregate: AttestationRecordAggregate;
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type AttestationConfigGroupsArgs = {
  distinct_on?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationGroupOrderBy>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type AttestationConfigGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationGroupOrderBy>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type AttestationConfigRecordsArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


/** columns and relationships of "risksmart.attestation_config" */
export type AttestationConfigRecordsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};

/** aggregated selection of "risksmart.attestation_config" */
export type AttestationConfigAggregate = {
  __typename?: 'attestation_config_aggregate';
  aggregate?: Maybe<AttestationConfigAggregateFields>;
  nodes: Array<AttestationConfig>;
};

/** aggregate fields of "risksmart.attestation_config" */
export type AttestationConfigAggregateFields = {
  __typename?: 'attestation_config_aggregate_fields';
  avg?: Maybe<AttestationConfigAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<AttestationConfigMaxFields>;
  min?: Maybe<AttestationConfigMinFields>;
  stddev?: Maybe<AttestationConfigStddevFields>;
  stddev_pop?: Maybe<AttestationConfigStddevPopFields>;
  stddev_samp?: Maybe<AttestationConfigStddevSampFields>;
  sum?: Maybe<AttestationConfigSumFields>;
  var_pop?: Maybe<AttestationConfigVarPopFields>;
  var_samp?: Maybe<AttestationConfigVarSampFields>;
  variance?: Maybe<AttestationConfigVarianceFields>;
};


/** aggregate fields of "risksmart.attestation_config" */
export type AttestationConfigAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttestationConfigSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type AttestationConfigAvgFields = {
  __typename?: 'attestation_config_avg_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_config". All fields are combined with a logical 'AND'. */
export type AttestationConfigBoolExp = {
  AttestationTimeLimit?: InputMaybe<IntervalComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  PromptText?: InputMaybe<StringComparisonExp>;
  RequireGlobalAttestation?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<AttestationConfigBoolExp>>;
  _not?: InputMaybe<AttestationConfigBoolExp>;
  _or?: InputMaybe<Array<AttestationConfigBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  groups?: InputMaybe<AttestationGroupBoolExp>;
  groups_aggregate?: InputMaybe<AttestationGroupAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  records?: InputMaybe<AttestationRecordBoolExp>;
  records_aggregate?: InputMaybe<AttestationRecordAggregateBoolExp>;
  timeLimitMs?: InputMaybe<BigintComparisonExp>;
};

/** unique or primary key constraints on table "risksmart.attestation_config" */
export enum AttestationConfigConstraint {
  /** unique or primary key constraint on columns "ParentId" */
  AttestationConfigPkey = 'attestation_config_pkey'
}

/** input type for inserting data into table "risksmart.attestation_config" */
export type AttestationConfigInsertInput = {
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  PromptText?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation?: InputMaybe<Scalars['Boolean']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  groups?: InputMaybe<AttestationGroupArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  records?: InputMaybe<AttestationRecordArrRelInsertInput>;
};

/** aggregate max on columns */
export type AttestationConfigMaxFields = {
  __typename?: 'attestation_config_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  PromptText?: Maybe<Scalars['String']['output']>;
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type AttestationConfigMinFields = {
  __typename?: 'attestation_config_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  PromptText?: Maybe<Scalars['String']['output']>;
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "risksmart.attestation_config" */
export type AttestationConfigMutationResponse = {
  __typename?: 'attestation_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AttestationConfig>;
};

/** input type for inserting object relation for remote table "risksmart.attestation_config" */
export type AttestationConfigObjRelInsertInput = {
  data: AttestationConfigInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AttestationConfigOnConflict>;
};

/** on_conflict condition type for table "risksmart.attestation_config" */
export type AttestationConfigOnConflict = {
  constraint: AttestationConfigConstraint;
  update_columns?: Array<AttestationConfigUpdateColumn>;
  where?: InputMaybe<AttestationConfigBoolExp>;
};

/** Ordering options when selecting data from "risksmart.attestation_config". */
export type AttestationConfigOrderBy = {
  AttestationTimeLimit?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  PromptText?: InputMaybe<OrderBy>;
  RequireGlobalAttestation?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  groups_aggregate?: InputMaybe<AttestationGroupAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  records_aggregate?: InputMaybe<AttestationRecordAggregateOrderBy>;
  timeLimitMs?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.attestation_config */
export type AttestationConfigPkColumnsInput = {
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.attestation_config" */
export enum AttestationConfigSelectColumn {
  /** column name */
  AttestationTimeLimit = 'AttestationTimeLimit',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  PromptText = 'PromptText',
  /** column name */
  RequireGlobalAttestation = 'RequireGlobalAttestation'
}

/** input type for updating data in table "risksmart.attestation_config" */
export type AttestationConfigSetInput = {
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  PromptText?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate stddev on columns */
export type AttestationConfigStddevFields = {
  __typename?: 'attestation_config_stddev_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate stddev_pop on columns */
export type AttestationConfigStddevPopFields = {
  __typename?: 'attestation_config_stddev_pop_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate stddev_samp on columns */
export type AttestationConfigStddevSampFields = {
  __typename?: 'attestation_config_stddev_samp_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** Streaming cursor of the table "attestation_config" */
export type AttestationConfigStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AttestationConfigStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttestationConfigStreamCursorValueInput = {
  AttestationTimeLimit?: InputMaybe<Scalars['interval']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  PromptText?: InputMaybe<Scalars['String']['input']>;
  RequireGlobalAttestation?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate sum on columns */
export type AttestationConfigSumFields = {
  __typename?: 'attestation_config_sum_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "risksmart.attestation_config" */
export enum AttestationConfigUpdateColumn {
  /** column name */
  AttestationTimeLimit = 'AttestationTimeLimit',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  PromptText = 'PromptText',
  /** column name */
  RequireGlobalAttestation = 'RequireGlobalAttestation'
}

export type AttestationConfigUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AttestationConfigSetInput>;
  /** filter the rows which have to be updated */
  where: AttestationConfigBoolExp;
};

/** aggregate var_pop on columns */
export type AttestationConfigVarPopFields = {
  __typename?: 'attestation_config_var_pop_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate var_samp on columns */
export type AttestationConfigVarSampFields = {
  __typename?: 'attestation_config_var_samp_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate variance on columns */
export type AttestationConfigVarianceFields = {
  __typename?: 'attestation_config_variance_fields';
  timeLimitMs?: Maybe<Scalars['bigint']['output']>;
};

/** columns and relationships of "risksmart.attestation_group" */
export type AttestationGroup = {
  __typename?: 'attestation_group';
  ConfigId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  GroupId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An object relationship */
  config: AttestationConfig;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group: UserGroup;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
};

/** aggregated selection of "risksmart.attestation_group" */
export type AttestationGroupAggregate = {
  __typename?: 'attestation_group_aggregate';
  aggregate?: Maybe<AttestationGroupAggregateFields>;
  nodes: Array<AttestationGroup>;
};

export type AttestationGroupAggregateBoolExp = {
  count?: InputMaybe<AttestationGroupAggregateBoolExpCount>;
};

export type AttestationGroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AttestationGroupBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.attestation_group" */
export type AttestationGroupAggregateFields = {
  __typename?: 'attestation_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AttestationGroupMaxFields>;
  min?: Maybe<AttestationGroupMinFields>;
};


/** aggregate fields of "risksmart.attestation_group" */
export type AttestationGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.attestation_group" */
export type AttestationGroupAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AttestationGroupMaxOrderBy>;
  min?: InputMaybe<AttestationGroupMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.attestation_group" */
export type AttestationGroupArrRelInsertInput = {
  data: Array<AttestationGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AttestationGroupOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_group". All fields are combined with a logical 'AND'. */
export type AttestationGroupBoolExp = {
  ConfigId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  GroupId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AttestationGroupBoolExp>>;
  _not?: InputMaybe<AttestationGroupBoolExp>;
  _or?: InputMaybe<Array<AttestationGroupBoolExp>>;
  config?: InputMaybe<AttestationConfigBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  group?: InputMaybe<UserGroupBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
};

/** unique or primary key constraints on table "risksmart.attestation_group" */
export enum AttestationGroupConstraint {
  /** unique or primary key constraint on columns "ConfigId", "GroupId" */
  AttestationGroupPkey = 'attestation_group_pkey'
}

/** input type for inserting data into table "risksmart.attestation_group" */
export type AttestationGroupInsertInput = {
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<AttestationConfigObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  group?: InputMaybe<UserGroupObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
};

/** aggregate max on columns */
export type AttestationGroupMaxFields = {
  __typename?: 'attestation_group_max_fields';
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  GroupId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.attestation_group" */
export type AttestationGroupMaxOrderBy = {
  ConfigId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  GroupId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AttestationGroupMinFields = {
  __typename?: 'attestation_group_min_fields';
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  GroupId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.attestation_group" */
export type AttestationGroupMinOrderBy = {
  ConfigId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  GroupId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.attestation_group" */
export type AttestationGroupMutationResponse = {
  __typename?: 'attestation_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AttestationGroup>;
};

/** on_conflict condition type for table "risksmart.attestation_group" */
export type AttestationGroupOnConflict = {
  constraint: AttestationGroupConstraint;
  update_columns?: Array<AttestationGroupUpdateColumn>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.attestation_group". */
export type AttestationGroupOrderBy = {
  ConfigId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  GroupId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  config?: InputMaybe<AttestationConfigOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  group?: InputMaybe<UserGroupOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
};

/** primary key columns input for table: risksmart.attestation_group */
export type AttestationGroupPkColumnsInput = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.attestation_group" */
export enum AttestationGroupSelectColumn {
  /** column name */
  ConfigId = 'ConfigId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.attestation_group" */
export type AttestationGroupSetInput = {
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "attestation_group" */
export type AttestationGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AttestationGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttestationGroupStreamCursorValueInput = {
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.attestation_group" */
export enum AttestationGroupUpdateColumn {
  /** column name */
  ConfigId = 'ConfigId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type AttestationGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AttestationGroupSetInput>;
  /** filter the rows which have to be updated */
  where: AttestationGroupBoolExp;
};

/** columns and relationships of "risksmart.attestation_record" */
export type AttestationRecord = {
  __typename?: 'attestation_record';
  Active: Scalars['Boolean']['output'];
  AttestationStatus: AttestationRecordStatusEnum;
  AttestedAt?: Maybe<Scalars['timestamptz']['output']>;
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NodeId: Scalars['uuid']['output'];
  OrgKey: Scalars['String']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  config?: Maybe<AttestationConfig>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  node: Node;
  /** An object relationship */
  user: AuthUser;
};

/** aggregated selection of "risksmart.attestation_record" */
export type AttestationRecordAggregate = {
  __typename?: 'attestation_record_aggregate';
  aggregate?: Maybe<AttestationRecordAggregateFields>;
  nodes: Array<AttestationRecord>;
};

export type AttestationRecordAggregateBoolExp = {
  bool_and?: InputMaybe<AttestationRecordAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<AttestationRecordAggregateBoolExpBoolOr>;
  count?: InputMaybe<AttestationRecordAggregateBoolExpCount>;
};

export type AttestationRecordAggregateBoolExpBoolAnd = {
  arguments: AttestationRecordSelectColumnAttestationRecordAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AttestationRecordBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AttestationRecordAggregateBoolExpBoolOr = {
  arguments: AttestationRecordSelectColumnAttestationRecordAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AttestationRecordBoolExp>;
  predicate: BooleanComparisonExp;
};

export type AttestationRecordAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AttestationRecordBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.attestation_record" */
export type AttestationRecordAggregateFields = {
  __typename?: 'attestation_record_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AttestationRecordMaxFields>;
  min?: Maybe<AttestationRecordMinFields>;
};


/** aggregate fields of "risksmart.attestation_record" */
export type AttestationRecordAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.attestation_record" */
export type AttestationRecordAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AttestationRecordMaxOrderBy>;
  min?: InputMaybe<AttestationRecordMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.attestation_record" */
export type AttestationRecordArrRelInsertInput = {
  data: Array<AttestationRecordInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AttestationRecordOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_record". All fields are combined with a logical 'AND'. */
export type AttestationRecordBoolExp = {
  Active?: InputMaybe<BooleanComparisonExp>;
  AttestationStatus?: InputMaybe<AttestationRecordStatusEnumComparisonExp>;
  AttestedAt?: InputMaybe<TimestamptzComparisonExp>;
  ConfigId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ExpiresAt?: InputMaybe<TimestamptzComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NodeId?: InputMaybe<UuidComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AttestationRecordBoolExp>>;
  _not?: InputMaybe<AttestationRecordBoolExp>;
  _or?: InputMaybe<Array<AttestationRecordBoolExp>>;
  config?: InputMaybe<AttestationConfigBoolExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  node?: InputMaybe<NodeBoolExp>;
  user?: InputMaybe<AuthUserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.attestation_record" */
export enum AttestationRecordConstraint {
  /** unique or primary key constraint on columns "Id" */
  AttestationRecordPkey = 'attestation_record_pkey',
  /** unique or primary key constraint on columns "Active", "UserId", "NodeId" */
  IdxAttestationRecordNodeidUseridActive = 'idx_attestation_record_nodeid_userid_active'
}

/** input type for inserting data into table "risksmart.attestation_record" */
export type AttestationRecordInsertInput = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AttestationStatus?: InputMaybe<AttestationRecordStatusEnum>;
  AttestedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NodeId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  config?: InputMaybe<AttestationConfigObjRelInsertInput>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  node?: InputMaybe<NodeObjRelInsertInput>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
};

/** aggregate max on columns */
export type AttestationRecordMaxFields = {
  __typename?: 'attestation_record_max_fields';
  AttestedAt?: Maybe<Scalars['timestamptz']['output']>;
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NodeId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.attestation_record" */
export type AttestationRecordMaxOrderBy = {
  AttestedAt?: InputMaybe<OrderBy>;
  ConfigId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ExpiresAt?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NodeId?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AttestationRecordMinFields = {
  __typename?: 'attestation_record_min_fields';
  AttestedAt?: Maybe<Scalars['timestamptz']['output']>;
  ConfigId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NodeId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.attestation_record" */
export type AttestationRecordMinOrderBy = {
  AttestedAt?: InputMaybe<OrderBy>;
  ConfigId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ExpiresAt?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NodeId?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.attestation_record" */
export type AttestationRecordMutationResponse = {
  __typename?: 'attestation_record_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AttestationRecord>;
};

/** on_conflict condition type for table "risksmart.attestation_record" */
export type AttestationRecordOnConflict = {
  constraint: AttestationRecordConstraint;
  update_columns?: Array<AttestationRecordUpdateColumn>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};

/** Ordering options when selecting data from "risksmart.attestation_record". */
export type AttestationRecordOrderBy = {
  Active?: InputMaybe<OrderBy>;
  AttestationStatus?: InputMaybe<OrderBy>;
  AttestedAt?: InputMaybe<OrderBy>;
  ConfigId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ExpiresAt?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NodeId?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  config?: InputMaybe<AttestationConfigOrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  node?: InputMaybe<NodeOrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
};

/** primary key columns input for table: risksmart.attestation_record */
export type AttestationRecordPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.attestation_record" */
export enum AttestationRecordSelectColumn {
  /** column name */
  Active = 'Active',
  /** column name */
  AttestationStatus = 'AttestationStatus',
  /** column name */
  AttestedAt = 'AttestedAt',
  /** column name */
  ConfigId = 'ConfigId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ExpiresAt = 'ExpiresAt',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NodeId = 'NodeId',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

/** select "attestation_record_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risksmart.attestation_record" */
export enum AttestationRecordSelectColumnAttestationRecordAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Active = 'Active'
}

/** select "attestation_record_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risksmart.attestation_record" */
export enum AttestationRecordSelectColumnAttestationRecordAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Active = 'Active'
}

/** input type for updating data in table "risksmart.attestation_record" */
export type AttestationRecordSetInput = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AttestationStatus?: InputMaybe<AttestationRecordStatusEnum>;
  AttestedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NodeId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.attestation_record_status" */
export type AttestationRecordStatus = {
  __typename?: 'attestation_record_status';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.attestation_record_status" */
export type AttestationRecordStatusAggregate = {
  __typename?: 'attestation_record_status_aggregate';
  aggregate?: Maybe<AttestationRecordStatusAggregateFields>;
  nodes: Array<AttestationRecordStatus>;
};

/** aggregate fields of "risksmart.attestation_record_status" */
export type AttestationRecordStatusAggregateFields = {
  __typename?: 'attestation_record_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AttestationRecordStatusMaxFields>;
  min?: Maybe<AttestationRecordStatusMinFields>;
};


/** aggregate fields of "risksmart.attestation_record_status" */
export type AttestationRecordStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AttestationRecordStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.attestation_record_status". All fields are combined with a logical 'AND'. */
export type AttestationRecordStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AttestationRecordStatusBoolExp>>;
  _not?: InputMaybe<AttestationRecordStatusBoolExp>;
  _or?: InputMaybe<Array<AttestationRecordStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.attestation_record_status" */
export enum AttestationRecordStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  AttestationRecordStatusPkey = 'attestation_record_status_pkey'
}

export enum AttestationRecordStatusEnum {
  /** User has attested */
  Attested = 'attested',
  /** The due date has expired */
  Expired = 'expired',
  /** Attestation is cancelled */
  NotRequired = 'not_required',
  /** Waiting for attestation */
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "attestation_record_status_enum". All fields are combined with logical 'AND'. */
export type AttestationRecordStatusEnumComparisonExp = {
  _eq?: InputMaybe<AttestationRecordStatusEnum>;
  _in?: InputMaybe<Array<AttestationRecordStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AttestationRecordStatusEnum>;
  _nin?: InputMaybe<Array<AttestationRecordStatusEnum>>;
};

/** input type for inserting data into table "risksmart.attestation_record_status" */
export type AttestationRecordStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AttestationRecordStatusMaxFields = {
  __typename?: 'attestation_record_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AttestationRecordStatusMinFields = {
  __typename?: 'attestation_record_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.attestation_record_status" */
export type AttestationRecordStatusMutationResponse = {
  __typename?: 'attestation_record_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AttestationRecordStatus>;
};

/** on_conflict condition type for table "risksmart.attestation_record_status" */
export type AttestationRecordStatusOnConflict = {
  constraint: AttestationRecordStatusConstraint;
  update_columns?: Array<AttestationRecordStatusUpdateColumn>;
  where?: InputMaybe<AttestationRecordStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.attestation_record_status". */
export type AttestationRecordStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.attestation_record_status */
export type AttestationRecordStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.attestation_record_status" */
export enum AttestationRecordStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.attestation_record_status" */
export type AttestationRecordStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "attestation_record_status" */
export type AttestationRecordStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AttestationRecordStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttestationRecordStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.attestation_record_status" */
export enum AttestationRecordStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type AttestationRecordStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AttestationRecordStatusSetInput>;
  /** filter the rows which have to be updated */
  where: AttestationRecordStatusBoolExp;
};

/** Streaming cursor of the table "attestation_record" */
export type AttestationRecordStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AttestationRecordStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AttestationRecordStreamCursorValueInput = {
  Active?: InputMaybe<Scalars['Boolean']['input']>;
  AttestationStatus?: InputMaybe<AttestationRecordStatusEnum>;
  AttestedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  ConfigId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NodeId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.attestation_record" */
export enum AttestationRecordUpdateColumn {
  /** column name */
  Active = 'Active',
  /** column name */
  AttestationStatus = 'AttestationStatus',
  /** column name */
  AttestedAt = 'AttestedAt',
  /** column name */
  ConfigId = 'ConfigId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ExpiresAt = 'ExpiresAt',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NodeId = 'NodeId',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

export type AttestationRecordUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AttestationRecordSetInput>;
  /** filter the rows which have to be updated */
  where: AttestationRecordBoolExp;
};

/** columns and relationships of "risksmart.audit_log_view" */
export type AuditLogView = {
  __typename?: 'audit_log_view';
  Action?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Item?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  PerformedByUser?: Maybe<User>;
};

/** aggregated selection of "risksmart.audit_log_view" */
export type AuditLogViewAggregate = {
  __typename?: 'audit_log_view_aggregate';
  aggregate?: Maybe<AuditLogViewAggregateFields>;
  nodes: Array<AuditLogView>;
};

/** aggregate fields of "risksmart.audit_log_view" */
export type AuditLogViewAggregateFields = {
  __typename?: 'audit_log_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuditLogViewMaxFields>;
  min?: Maybe<AuditLogViewMinFields>;
};


/** aggregate fields of "risksmart.audit_log_view" */
export type AuditLogViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuditLogViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.audit_log_view". All fields are combined with a logical 'AND'. */
export type AuditLogViewBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<StringComparisonExp>;
  Item?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  ObjectType?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  PerformedByUser?: InputMaybe<UserBoolExp>;
  _and?: InputMaybe<Array<AuditLogViewBoolExp>>;
  _not?: InputMaybe<AuditLogViewBoolExp>;
  _or?: InputMaybe<Array<AuditLogViewBoolExp>>;
};

/** aggregate max on columns */
export type AuditLogViewMaxFields = {
  __typename?: 'audit_log_view_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Item?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuditLogViewMinFields = {
  __typename?: 'audit_log_view_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  Item?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.audit_log_view". */
export type AuditLogViewOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Item?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  PerformedByUser?: InputMaybe<UserOrderBy>;
};

/** select columns of table "risksmart.audit_log_view" */
export enum AuditLogViewSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Id = 'Id',
  /** column name */
  Item = 'Item',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey'
}

/** Streaming cursor of the table "audit_log_view" */
export type AuditLogViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuditLogViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuditLogViewStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  Item?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "auth.organisation" */
export type AuthOrganisation = {
  __typename?: 'auth_organisation';
  AuthTenant: Scalars['String']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn: Scalars['timestamptz']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An array relationship */
  organisationusers: Array<AuthOrganisationuser>;
  /** An aggregate relationship */
  organisationusers_aggregate: AuthOrganisationuserAggregate;
};


/** columns and relationships of "auth.organisation" */
export type AuthOrganisationMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "auth.organisation" */
export type AuthOrganisationOrganisationusersArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


/** columns and relationships of "auth.organisation" */
export type AuthOrganisationOrganisationusersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};

/** aggregated selection of "auth.organisation" */
export type AuthOrganisationAggregate = {
  __typename?: 'auth_organisation_aggregate';
  aggregate?: Maybe<AuthOrganisationAggregateFields>;
  nodes: Array<AuthOrganisation>;
};

/** aggregate fields of "auth.organisation" */
export type AuthOrganisationAggregateFields = {
  __typename?: 'auth_organisation_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthOrganisationMaxFields>;
  min?: Maybe<AuthOrganisationMinFields>;
};


/** aggregate fields of "auth.organisation" */
export type AuthOrganisationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthOrganisationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "auth.organisation_audit" */
export type AuthOrganisationAudit = {
  __typename?: 'auth_organisation_audit';
  Action?: Maybe<Scalars['String']['output']>;
  AuthTenant?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn: Scalars['timestamptz']['output'];
  Meta?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "auth.organisation_audit" */
export type AuthOrganisationAuditAggregate = {
  __typename?: 'auth_organisation_audit_aggregate';
  aggregate?: Maybe<AuthOrganisationAuditAggregateFields>;
  nodes: Array<AuthOrganisationAudit>;
};

/** aggregate fields of "auth.organisation_audit" */
export type AuthOrganisationAuditAggregateFields = {
  __typename?: 'auth_organisation_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthOrganisationAuditMaxFields>;
  min?: Maybe<AuthOrganisationAuditMinFields>;
};


/** aggregate fields of "auth.organisation_audit" */
export type AuthOrganisationAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthOrganisationAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.organisation_audit". All fields are combined with a logical 'AND'. */
export type AuthOrganisationAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  AuthTenant?: InputMaybe<StringComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CreatedOn?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthOrganisationAuditBoolExp>>;
  _not?: InputMaybe<AuthOrganisationAuditBoolExp>;
  _or?: InputMaybe<Array<AuthOrganisationAuditBoolExp>>;
};

/** unique or primary key constraints on table "auth.organisation_audit" */
export enum AuthOrganisationAuditConstraint {
  /** unique or primary key constraint on columns "OrgKey", "ModifiedAtTimestamp" */
  OrganisationAuditPkey = 'organisation_audit_pkey'
}

/** input type for inserting data into table "auth.organisation_audit" */
export type AuthOrganisationAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthOrganisationAuditMaxFields = {
  __typename?: 'auth_organisation_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AuthTenant?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthOrganisationAuditMinFields = {
  __typename?: 'auth_organisation_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AuthTenant?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.organisation_audit" */
export type AuthOrganisationAuditMutationResponse = {
  __typename?: 'auth_organisation_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthOrganisationAudit>;
};

/** on_conflict condition type for table "auth.organisation_audit" */
export type AuthOrganisationAuditOnConflict = {
  constraint: AuthOrganisationAuditConstraint;
  update_columns?: Array<AuthOrganisationAuditUpdateColumn>;
  where?: InputMaybe<AuthOrganisationAuditBoolExp>;
};

/** Ordering options when selecting data from "auth.organisation_audit". */
export type AuthOrganisationAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  AuthTenant?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CreatedOn?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.organisation_audit */
export type AuthOrganisationAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "auth.organisation_audit" */
export enum AuthOrganisationAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AuthTenant = 'AuthTenant',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "auth.organisation_audit" */
export type AuthOrganisationAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_organisation_audit" */
export type AuthOrganisationAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthOrganisationAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthOrganisationAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.organisation_audit" */
export enum AuthOrganisationAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AuthTenant = 'AuthTenant',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type AuthOrganisationAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthOrganisationAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AuthOrganisationAuditBoolExp;
};

/** Boolean expression to filter rows from the table "auth.organisation". All fields are combined with a logical 'AND'. */
export type AuthOrganisationBoolExp = {
  AuthTenant?: InputMaybe<StringComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CreatedOn?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthOrganisationBoolExp>>;
  _not?: InputMaybe<AuthOrganisationBoolExp>;
  _or?: InputMaybe<Array<AuthOrganisationBoolExp>>;
  organisationusers?: InputMaybe<AuthOrganisationuserBoolExp>;
  organisationusers_aggregate?: InputMaybe<AuthOrganisationuserAggregateBoolExp>;
};

/** unique or primary key constraints on table "auth.organisation" */
export enum AuthOrganisationConstraint {
  /** unique or primary key constraint on columns "OrgKey" */
  OrganisationPkey = 'organisation_pkey'
}

/** input type for inserting data into table "auth.organisation" */
export type AuthOrganisationInsertInput = {
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  organisationusers?: InputMaybe<AuthOrganisationuserArrRelInsertInput>;
};

/** aggregate max on columns */
export type AuthOrganisationMaxFields = {
  __typename?: 'auth_organisation_max_fields';
  AuthTenant?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthOrganisationMinFields = {
  __typename?: 'auth_organisation_min_fields';
  AuthTenant?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.organisation" */
export type AuthOrganisationMutationResponse = {
  __typename?: 'auth_organisation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthOrganisation>;
};

/** input type for inserting object relation for remote table "auth.organisation" */
export type AuthOrganisationObjRelInsertInput = {
  data: AuthOrganisationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthOrganisationOnConflict>;
};

/** on_conflict condition type for table "auth.organisation" */
export type AuthOrganisationOnConflict = {
  constraint: AuthOrganisationConstraint;
  update_columns?: Array<AuthOrganisationUpdateColumn>;
  where?: InputMaybe<AuthOrganisationBoolExp>;
};

/** Ordering options when selecting data from "auth.organisation". */
export type AuthOrganisationOrderBy = {
  AuthTenant?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CreatedOn?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  organisationusers_aggregate?: InputMaybe<AuthOrganisationuserAggregateOrderBy>;
};

/** primary key columns input for table: auth.organisation */
export type AuthOrganisationPkColumnsInput = {
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "auth.organisation" */
export enum AuthOrganisationSelectColumn {
  /** column name */
  AuthTenant = 'AuthTenant',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "auth.organisation" */
export type AuthOrganisationSetInput = {
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_organisation" */
export type AuthOrganisationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthOrganisationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthOrganisationStreamCursorValueInput = {
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.organisation" */
export enum AuthOrganisationUpdateColumn {
  /** column name */
  AuthTenant = 'AuthTenant',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type AuthOrganisationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthOrganisationSetInput>;
  /** filter the rows which have to be updated */
  where: AuthOrganisationBoolExp;
};

/** columns and relationships of "auth.organisationuser" */
export type AuthOrganisationuser = {
  __typename?: 'auth_organisationuser';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<UserStatusEnum>;
  User_Id: Scalars['String']['output'];
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  user: AuthUser;
};

/** aggregated selection of "auth.organisationuser" */
export type AuthOrganisationuserAggregate = {
  __typename?: 'auth_organisationuser_aggregate';
  aggregate?: Maybe<AuthOrganisationuserAggregateFields>;
  nodes: Array<AuthOrganisationuser>;
};

export type AuthOrganisationuserAggregateBoolExp = {
  count?: InputMaybe<AuthOrganisationuserAggregateBoolExpCount>;
};

export type AuthOrganisationuserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<AuthOrganisationuserBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "auth.organisationuser" */
export type AuthOrganisationuserAggregateFields = {
  __typename?: 'auth_organisationuser_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthOrganisationuserMaxFields>;
  min?: Maybe<AuthOrganisationuserMinFields>;
};


/** aggregate fields of "auth.organisationuser" */
export type AuthOrganisationuserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "auth.organisationuser" */
export type AuthOrganisationuserAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<AuthOrganisationuserMaxOrderBy>;
  min?: InputMaybe<AuthOrganisationuserMinOrderBy>;
};

/** input type for inserting array relation for remote table "auth.organisationuser" */
export type AuthOrganisationuserArrRelInsertInput = {
  data: Array<AuthOrganisationuserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthOrganisationuserOnConflict>;
};

/** columns and relationships of "auth.organisationuser_audit" */
export type AuthOrganisationuserAudit = {
  __typename?: 'auth_organisationuser_audit';
  Action?: Maybe<Scalars['String']['output']>;
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  User_Id: Scalars['String']['output'];
};

/** aggregated selection of "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditAggregate = {
  __typename?: 'auth_organisationuser_audit_aggregate';
  aggregate?: Maybe<AuthOrganisationuserAuditAggregateFields>;
  nodes: Array<AuthOrganisationuserAudit>;
};

/** aggregate fields of "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditAggregateFields = {
  __typename?: 'auth_organisationuser_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthOrganisationuserAuditMaxFields>;
  min?: Maybe<AuthOrganisationuserAuditMinFields>;
};


/** aggregate fields of "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthOrganisationuserAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.organisationuser_audit". All fields are combined with a logical 'AND'. */
export type AuthOrganisationuserAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  AuthConnection?: InputMaybe<StringComparisonExp>;
  AuthConnection_Id?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  LastSeen?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RoleKey?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  User_Id?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthOrganisationuserAuditBoolExp>>;
  _not?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
  _or?: InputMaybe<Array<AuthOrganisationuserAuditBoolExp>>;
};

/** unique or primary key constraints on table "auth.organisationuser_audit" */
export enum AuthOrganisationuserAuditConstraint {
  /** unique or primary key constraint on columns "User_Id", "OrgKey", "ModifiedAtTimestamp" */
  OrganisationuserAuditPkey = 'organisationuser_audit_pkey'
}

/** input type for inserting data into table "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthOrganisationuserAuditMaxFields = {
  __typename?: 'auth_organisationuser_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  User_Id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthOrganisationuserAuditMinFields = {
  __typename?: 'auth_organisationuser_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  User_Id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditMutationResponse = {
  __typename?: 'auth_organisationuser_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthOrganisationuserAudit>;
};

/** on_conflict condition type for table "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditOnConflict = {
  constraint: AuthOrganisationuserAuditConstraint;
  update_columns?: Array<AuthOrganisationuserAuditUpdateColumn>;
  where?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
};

/** Ordering options when selecting data from "auth.organisationuser_audit". */
export type AuthOrganisationuserAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  AuthConnection?: InputMaybe<OrderBy>;
  AuthConnection_Id?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  LastSeen?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  User_Id?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.organisationuser_audit */
export type AuthOrganisationuserAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};

/** select columns of table "auth.organisationuser_audit" */
export enum AuthOrganisationuserAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AuthConnection = 'AuthConnection',
  /** column name */
  AuthConnectionId = 'AuthConnection_Id',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserId = 'User_Id'
}

/** input type for updating data in table "auth.organisationuser_audit" */
export type AuthOrganisationuserAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_organisationuser_audit" */
export type AuthOrganisationuserAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthOrganisationuserAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthOrganisationuserAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.organisationuser_audit" */
export enum AuthOrganisationuserAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AuthConnection = 'AuthConnection',
  /** column name */
  AuthConnectionId = 'AuthConnection_Id',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserId = 'User_Id'
}

export type AuthOrganisationuserAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthOrganisationuserAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AuthOrganisationuserAuditBoolExp;
};

/** Boolean expression to filter rows from the table "auth.organisationuser". All fields are combined with a logical 'AND'. */
export type AuthOrganisationuserBoolExp = {
  AuthConnection?: InputMaybe<StringComparisonExp>;
  AuthConnection_Id?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  LastSeen?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RoleKey?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<UserStatusEnumComparisonExp>;
  User_Id?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthOrganisationuserBoolExp>>;
  _not?: InputMaybe<AuthOrganisationuserBoolExp>;
  _or?: InputMaybe<Array<AuthOrganisationuserBoolExp>>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  user?: InputMaybe<AuthUserBoolExp>;
};

/** unique or primary key constraints on table "auth.organisationuser" */
export enum AuthOrganisationuserConstraint {
  /** unique or primary key constraint on columns "User_Id", "OrgKey" */
  OrganisationUserPkey = 'organisationUser_pkey'
}

/** input type for inserting data into table "auth.organisationuser" */
export type AuthOrganisationuserInsertInput = {
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<UserStatusEnum>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
};

/** aggregate max on columns */
export type AuthOrganisationuserMaxFields = {
  __typename?: 'auth_organisationuser_max_fields';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  User_Id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "auth.organisationuser" */
export type AuthOrganisationuserMaxOrderBy = {
  AuthConnection?: InputMaybe<OrderBy>;
  AuthConnection_Id?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  LastSeen?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  User_Id?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type AuthOrganisationuserMinFields = {
  __typename?: 'auth_organisationuser_min_fields';
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  User_Id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "auth.organisationuser" */
export type AuthOrganisationuserMinOrderBy = {
  AuthConnection?: InputMaybe<OrderBy>;
  AuthConnection_Id?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  LastSeen?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  User_Id?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "auth.organisationuser" */
export type AuthOrganisationuserMutationResponse = {
  __typename?: 'auth_organisationuser_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthOrganisationuser>;
};

/** on_conflict condition type for table "auth.organisationuser" */
export type AuthOrganisationuserOnConflict = {
  constraint: AuthOrganisationuserConstraint;
  update_columns?: Array<AuthOrganisationuserUpdateColumn>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};

/** Ordering options when selecting data from "auth.organisationuser". */
export type AuthOrganisationuserOrderBy = {
  AuthConnection?: InputMaybe<OrderBy>;
  AuthConnection_Id?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  LastSeen?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  User_Id?: InputMaybe<OrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
};

/** primary key columns input for table: auth.organisationuser */
export type AuthOrganisationuserPkColumnsInput = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};

/** select columns of table "auth.organisationuser" */
export enum AuthOrganisationuserSelectColumn {
  /** column name */
  AuthConnection = 'AuthConnection',
  /** column name */
  AuthConnectionId = 'AuthConnection_Id',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserId = 'User_Id'
}

/** input type for updating data in table "auth.organisationuser" */
export type AuthOrganisationuserSetInput = {
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<UserStatusEnum>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_organisationuser" */
export type AuthOrganisationuserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthOrganisationuserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthOrganisationuserStreamCursorValueInput = {
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<UserStatusEnum>;
  User_Id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.organisationuser" */
export enum AuthOrganisationuserUpdateColumn {
  /** column name */
  AuthConnection = 'AuthConnection',
  /** column name */
  AuthConnectionId = 'AuthConnection_Id',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserId = 'User_Id'
}

export type AuthOrganisationuserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthOrganisationuserSetInput>;
  /** filter the rows which have to be updated */
  where: AuthOrganisationuserBoolExp;
};

/** columns and relationships of "auth.user" */
export type AuthUser = {
  __typename?: 'auth_user';
  AuthClientName?: Maybe<Scalars['String']['output']>;
  AuthClient_Id?: Maybe<Scalars['String']['output']>;
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  AuthTenant?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  IsCustomerSupport?: Maybe<Scalars['Boolean']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status: UserStatusEnum;
  UserName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  organisationusers: Array<AuthOrganisationuser>;
  /** An aggregate relationship */
  organisationusers_aggregate: AuthOrganisationuserAggregate;
  /** An array relationship */
  userGroupUsers: Array<UserGroupUser>;
  /** An aggregate relationship */
  userGroupUsers_aggregate: UserGroupUserAggregate;
};


/** columns and relationships of "auth.user" */
export type AuthUserMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "auth.user" */
export type AuthUserOrganisationusersArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserOrganisationusersAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserUserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


/** columns and relationships of "auth.user" */
export type AuthUserUserGroupUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};

/** columns and relationships of "auth.user_activity_audit" */
export type AuthUserActivityAudit = {
  __typename?: 'auth_user_activity_audit';
  Action: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "auth.user_activity_audit" */
export type AuthUserActivityAuditAggregate = {
  __typename?: 'auth_user_activity_audit_aggregate';
  aggregate?: Maybe<AuthUserActivityAuditAggregateFields>;
  nodes: Array<AuthUserActivityAudit>;
};

/** aggregate fields of "auth.user_activity_audit" */
export type AuthUserActivityAuditAggregateFields = {
  __typename?: 'auth_user_activity_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserActivityAuditMaxFields>;
  min?: Maybe<AuthUserActivityAuditMinFields>;
};


/** aggregate fields of "auth.user_activity_audit" */
export type AuthUserActivityAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserActivityAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user_activity_audit". All fields are combined with a logical 'AND'. */
export type AuthUserActivityAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthUserActivityAuditBoolExp>>;
  _not?: InputMaybe<AuthUserActivityAuditBoolExp>;
  _or?: InputMaybe<Array<AuthUserActivityAuditBoolExp>>;
};

/** unique or primary key constraints on table "auth.user_activity_audit" */
export enum AuthUserActivityAuditConstraint {
  /** unique or primary key constraint on columns "ModifiedByUser", "OrgKey", "ModifiedAtTimestamp" */
  UserActivityAuditPkey = 'user_activity_audit_pkey'
}

/** input type for inserting data into table "auth.user_activity_audit" */
export type AuthUserActivityAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthUserActivityAuditMaxFields = {
  __typename?: 'auth_user_activity_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthUserActivityAuditMinFields = {
  __typename?: 'auth_user_activity_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.user_activity_audit" */
export type AuthUserActivityAuditMutationResponse = {
  __typename?: 'auth_user_activity_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserActivityAudit>;
};

/** on_conflict condition type for table "auth.user_activity_audit" */
export type AuthUserActivityAuditOnConflict = {
  constraint: AuthUserActivityAuditConstraint;
  update_columns?: Array<AuthUserActivityAuditUpdateColumn>;
  where?: InputMaybe<AuthUserActivityAuditBoolExp>;
};

/** Ordering options when selecting data from "auth.user_activity_audit". */
export type AuthUserActivityAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user_activity_audit */
export type AuthUserActivityAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ModifiedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "auth.user_activity_audit" */
export enum AuthUserActivityAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "auth.user_activity_audit" */
export type AuthUserActivityAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_user_activity_audit" */
export type AuthUserActivityAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthUserActivityAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserActivityAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.user_activity_audit" */
export enum AuthUserActivityAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type AuthUserActivityAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserActivityAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserActivityAuditBoolExp;
};

/** aggregated selection of "auth.user" */
export type AuthUserAggregate = {
  __typename?: 'auth_user_aggregate';
  aggregate?: Maybe<AuthUserAggregateFields>;
  nodes: Array<AuthUser>;
};

/** aggregate fields of "auth.user" */
export type AuthUserAggregateFields = {
  __typename?: 'auth_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserMaxFields>;
  min?: Maybe<AuthUserMinFields>;
};


/** aggregate fields of "auth.user" */
export type AuthUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "auth.user_audit" */
export type AuthUserAudit = {
  __typename?: 'auth_user_audit';
  Action?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id: Scalars['String']['output'];
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "auth.user_audit" */
export type AuthUserAuditAggregate = {
  __typename?: 'auth_user_audit_aggregate';
  aggregate?: Maybe<AuthUserAuditAggregateFields>;
  nodes: Array<AuthUserAudit>;
};

/** aggregate fields of "auth.user_audit" */
export type AuthUserAuditAggregateFields = {
  __typename?: 'auth_user_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<AuthUserAuditMaxFields>;
  min?: Maybe<AuthUserAuditMinFields>;
};


/** aggregate fields of "auth.user_audit" */
export type AuthUserAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<AuthUserAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user_audit". All fields are combined with a logical 'AND'. */
export type AuthUserAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  AuthUser_Id?: InputMaybe<StringComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CreatedOn?: InputMaybe<TimestamptzComparisonExp>;
  Department?: InputMaybe<StringComparisonExp>;
  DisplayName?: InputMaybe<StringComparisonExp>;
  Email?: InputMaybe<StringComparisonExp>;
  External_Id?: InputMaybe<StringComparisonExp>;
  FirstName?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<StringComparisonExp>;
  JobTitle?: InputMaybe<StringComparisonExp>;
  LastName?: InputMaybe<StringComparisonExp>;
  Meta?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OfficeLocation?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  UserName?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthUserAuditBoolExp>>;
  _not?: InputMaybe<AuthUserAuditBoolExp>;
  _or?: InputMaybe<Array<AuthUserAuditBoolExp>>;
};

/** unique or primary key constraints on table "auth.user_audit" */
export enum AuthUserAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  UserAuditPkey = 'user_audit_pkey'
}

/** input type for inserting data into table "auth.user_audit" */
export type AuthUserAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type AuthUserAuditMaxFields = {
  __typename?: 'auth_user_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthUserAuditMinFields = {
  __typename?: 'auth_user_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.user_audit" */
export type AuthUserAuditMutationResponse = {
  __typename?: 'auth_user_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUserAudit>;
};

/** on_conflict condition type for table "auth.user_audit" */
export type AuthUserAuditOnConflict = {
  constraint: AuthUserAuditConstraint;
  update_columns?: Array<AuthUserAuditUpdateColumn>;
  where?: InputMaybe<AuthUserAuditBoolExp>;
};

/** Ordering options when selecting data from "auth.user_audit". */
export type AuthUserAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  AuthUser_Id?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CreatedOn?: InputMaybe<OrderBy>;
  Department?: InputMaybe<OrderBy>;
  DisplayName?: InputMaybe<OrderBy>;
  Email?: InputMaybe<OrderBy>;
  External_Id?: InputMaybe<OrderBy>;
  FirstName?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  JobTitle?: InputMaybe<OrderBy>;
  LastName?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OfficeLocation?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  UserName?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user_audit */
export type AuthUserAuditPkColumnsInput = {
  Id: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "auth.user_audit" */
export enum AuthUserAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AuthUserId = 'AuthUser_Id',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Department = 'Department',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  Email = 'Email',
  /** column name */
  ExternalId = 'External_Id',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  Id = 'Id',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OfficeLocation = 'OfficeLocation',
  /** column name */
  Status = 'Status',
  /** column name */
  UserName = 'UserName'
}

/** input type for updating data in table "auth.user_audit" */
export type AuthUserAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_user_audit" */
export type AuthUserAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthUserAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.user_audit" */
export enum AuthUserAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  AuthUserId = 'AuthUser_Id',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Department = 'Department',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  Email = 'Email',
  /** column name */
  ExternalId = 'External_Id',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  Id = 'Id',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OfficeLocation = 'OfficeLocation',
  /** column name */
  Status = 'Status',
  /** column name */
  UserName = 'UserName'
}

export type AuthUserAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserAuditSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserAuditBoolExp;
};

/** Boolean expression to filter rows from the table "auth.user". All fields are combined with a logical 'AND'. */
export type AuthUserBoolExp = {
  AuthClientName?: InputMaybe<StringComparisonExp>;
  AuthClient_Id?: InputMaybe<StringComparisonExp>;
  AuthConnection?: InputMaybe<StringComparisonExp>;
  AuthConnection_Id?: InputMaybe<StringComparisonExp>;
  AuthTenant?: InputMaybe<StringComparisonExp>;
  AuthUser_Id?: InputMaybe<StringComparisonExp>;
  BusinessUnit_Id?: InputMaybe<UuidComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CreatedOn?: InputMaybe<TimestamptzComparisonExp>;
  Department?: InputMaybe<StringComparisonExp>;
  DisplayName?: InputMaybe<StringComparisonExp>;
  Email?: InputMaybe<StringComparisonExp>;
  External_Id?: InputMaybe<StringComparisonExp>;
  FirstName?: InputMaybe<StringComparisonExp>;
  FriendlyName?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<StringComparisonExp>;
  IsCustomerSupport?: InputMaybe<BooleanComparisonExp>;
  JobTitle?: InputMaybe<StringComparisonExp>;
  LastName?: InputMaybe<StringComparisonExp>;
  LastSeen?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OfficeLocation?: InputMaybe<StringComparisonExp>;
  RoleKey?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<UserStatusEnumComparisonExp>;
  UserName?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<AuthUserBoolExp>>;
  _not?: InputMaybe<AuthUserBoolExp>;
  _or?: InputMaybe<Array<AuthUserBoolExp>>;
  organisationusers?: InputMaybe<AuthOrganisationuserBoolExp>;
  organisationusers_aggregate?: InputMaybe<AuthOrganisationuserAggregateBoolExp>;
  userGroupUsers?: InputMaybe<UserGroupUserBoolExp>;
  userGroupUsers_aggregate?: InputMaybe<UserGroupUserAggregateBoolExp>;
};

/** unique or primary key constraints on table "auth.user" */
export enum AuthUserConstraint {
  /** unique or primary key constraint on columns "Email" */
  IdxUserEmail = 'idx_user_email',
  /** unique or primary key constraint on columns "Id" */
  UserPkey = 'user_pkey'
}

/** input type for inserting data into table "auth.user" */
export type AuthUserInsertInput = {
  AuthClientName?: InputMaybe<Scalars['String']['input']>;
  AuthClient_Id?: InputMaybe<Scalars['String']['input']>;
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<UserStatusEnum>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  organisationusers?: InputMaybe<AuthOrganisationuserArrRelInsertInput>;
  userGroupUsers?: InputMaybe<UserGroupUserArrRelInsertInput>;
};

/** aggregate max on columns */
export type AuthUserMaxFields = {
  __typename?: 'auth_user_max_fields';
  AuthClientName?: Maybe<Scalars['String']['output']>;
  AuthClient_Id?: Maybe<Scalars['String']['output']>;
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  AuthTenant?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type AuthUserMinFields = {
  __typename?: 'auth_user_min_fields';
  AuthClientName?: Maybe<Scalars['String']['output']>;
  AuthClient_Id?: Maybe<Scalars['String']['output']>;
  AuthConnection?: Maybe<Scalars['String']['output']>;
  AuthConnection_Id?: Maybe<Scalars['String']['output']>;
  AuthTenant?: Maybe<Scalars['String']['output']>;
  AuthUser_Id?: Maybe<Scalars['String']['output']>;
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CreatedOn?: Maybe<Scalars['timestamptz']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  DisplayName?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  External_Id?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.user" */
export type AuthUserMutationResponse = {
  __typename?: 'auth_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthUser>;
};

/** input type for inserting object relation for remote table "auth.user" */
export type AuthUserObjRelInsertInput = {
  data: AuthUserInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<AuthUserOnConflict>;
};

/** on_conflict condition type for table "auth.user" */
export type AuthUserOnConflict = {
  constraint: AuthUserConstraint;
  update_columns?: Array<AuthUserUpdateColumn>;
  where?: InputMaybe<AuthUserBoolExp>;
};

/** Ordering options when selecting data from "auth.user". */
export type AuthUserOrderBy = {
  AuthClientName?: InputMaybe<OrderBy>;
  AuthClient_Id?: InputMaybe<OrderBy>;
  AuthConnection?: InputMaybe<OrderBy>;
  AuthConnection_Id?: InputMaybe<OrderBy>;
  AuthTenant?: InputMaybe<OrderBy>;
  AuthUser_Id?: InputMaybe<OrderBy>;
  BusinessUnit_Id?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CreatedOn?: InputMaybe<OrderBy>;
  Department?: InputMaybe<OrderBy>;
  DisplayName?: InputMaybe<OrderBy>;
  Email?: InputMaybe<OrderBy>;
  External_Id?: InputMaybe<OrderBy>;
  FirstName?: InputMaybe<OrderBy>;
  FriendlyName?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IsCustomerSupport?: InputMaybe<OrderBy>;
  JobTitle?: InputMaybe<OrderBy>;
  LastName?: InputMaybe<OrderBy>;
  LastSeen?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OfficeLocation?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  UserName?: InputMaybe<OrderBy>;
  organisationusers_aggregate?: InputMaybe<AuthOrganisationuserAggregateOrderBy>;
  userGroupUsers_aggregate?: InputMaybe<UserGroupUserAggregateOrderBy>;
};

/** primary key columns input for table: auth.user */
export type AuthUserPkColumnsInput = {
  Id: Scalars['String']['input'];
};

/** select columns of table "auth.user" */
export enum AuthUserSelectColumn {
  /** column name */
  AuthClientName = 'AuthClientName',
  /** column name */
  AuthClientId = 'AuthClient_Id',
  /** column name */
  AuthConnection = 'AuthConnection',
  /** column name */
  AuthConnectionId = 'AuthConnection_Id',
  /** column name */
  AuthTenant = 'AuthTenant',
  /** column name */
  AuthUserId = 'AuthUser_Id',
  /** column name */
  BusinessUnitId = 'BusinessUnit_Id',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Department = 'Department',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  Email = 'Email',
  /** column name */
  ExternalId = 'External_Id',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FriendlyName = 'FriendlyName',
  /** column name */
  Id = 'Id',
  /** column name */
  IsCustomerSupport = 'IsCustomerSupport',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OfficeLocation = 'OfficeLocation',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserName = 'UserName'
}

/** input type for updating data in table "auth.user" */
export type AuthUserSetInput = {
  AuthClientName?: InputMaybe<Scalars['String']['input']>;
  AuthClient_Id?: InputMaybe<Scalars['String']['input']>;
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<UserStatusEnum>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "auth_user" */
export type AuthUserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: AuthUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthUserStreamCursorValueInput = {
  AuthClientName?: InputMaybe<Scalars['String']['input']>;
  AuthClient_Id?: InputMaybe<Scalars['String']['input']>;
  AuthConnection?: InputMaybe<Scalars['String']['input']>;
  AuthConnection_Id?: InputMaybe<Scalars['String']['input']>;
  AuthTenant?: InputMaybe<Scalars['String']['input']>;
  AuthUser_Id?: InputMaybe<Scalars['String']['input']>;
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedOn?: InputMaybe<Scalars['timestamptz']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  DisplayName?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  External_Id?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FriendlyName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  IsCustomerSupport?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<UserStatusEnum>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.user" */
export enum AuthUserUpdateColumn {
  /** column name */
  AuthClientName = 'AuthClientName',
  /** column name */
  AuthClientId = 'AuthClient_Id',
  /** column name */
  AuthConnection = 'AuthConnection',
  /** column name */
  AuthConnectionId = 'AuthConnection_Id',
  /** column name */
  AuthTenant = 'AuthTenant',
  /** column name */
  AuthUserId = 'AuthUser_Id',
  /** column name */
  BusinessUnitId = 'BusinessUnit_Id',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CreatedOn = 'CreatedOn',
  /** column name */
  Department = 'Department',
  /** column name */
  DisplayName = 'DisplayName',
  /** column name */
  Email = 'Email',
  /** column name */
  ExternalId = 'External_Id',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  Id = 'Id',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OfficeLocation = 'OfficeLocation',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserName = 'UserName'
}

export type AuthUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<AuthUserSetInput>;
  /** filter the rows which have to be updated */
  where: AuthUserBoolExp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type BigintComparisonExp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "risksmart.business_area" */
export type BusinessArea = {
  __typename?: 'business_area';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  SequentialId: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  internalAuditEntities: Array<InternalAuditEntity>;
  /** An aggregate relationship */
  internalAuditEntities_aggregate: InternalAuditEntityAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.business_area" */
export type BusinessAreaInternalAuditEntitiesArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditEntityOrderBy>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


/** columns and relationships of "risksmart.business_area" */
export type BusinessAreaInternalAuditEntitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditEntityOrderBy>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


/** columns and relationships of "risksmart.business_area" */
export type BusinessAreaPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.business_area" */
export type BusinessAreaPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.business_area" */
export type BusinessAreaAggregate = {
  __typename?: 'business_area_aggregate';
  aggregate?: Maybe<BusinessAreaAggregateFields>;
  nodes: Array<BusinessArea>;
};

/** aggregate fields of "risksmart.business_area" */
export type BusinessAreaAggregateFields = {
  __typename?: 'business_area_aggregate_fields';
  avg?: Maybe<BusinessAreaAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<BusinessAreaMaxFields>;
  min?: Maybe<BusinessAreaMinFields>;
  stddev?: Maybe<BusinessAreaStddevFields>;
  stddev_pop?: Maybe<BusinessAreaStddevPopFields>;
  stddev_samp?: Maybe<BusinessAreaStddevSampFields>;
  sum?: Maybe<BusinessAreaSumFields>;
  var_pop?: Maybe<BusinessAreaVarPopFields>;
  var_samp?: Maybe<BusinessAreaVarSampFields>;
  variance?: Maybe<BusinessAreaVarianceFields>;
};


/** aggregate fields of "risksmart.business_area" */
export type BusinessAreaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<BusinessAreaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type BusinessAreaAvgFields = {
  __typename?: 'business_area_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.business_area". All fields are combined with a logical 'AND'. */
export type BusinessAreaBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<BusinessAreaBoolExp>>;
  _not?: InputMaybe<BusinessAreaBoolExp>;
  _or?: InputMaybe<Array<BusinessAreaBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  internalAuditEntities?: InputMaybe<InternalAuditEntityBoolExp>;
  internalAuditEntities_aggregate?: InputMaybe<InternalAuditEntityAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.business_area" */
export enum BusinessAreaConstraint {
  /** unique or primary key constraint on columns "OrgKey", "Title" */
  BusinessAreaOrgkeyTitle = 'business_area_orgkey_title',
  /** unique or primary key constraint on columns "Id" */
  BusinessAreaPkey = 'business_area_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxBusinessAreaOrgkeySequentialid = 'idx_business_area_orgkey_sequentialid'
}

/** input type for incrementing numeric columns in table "risksmart.business_area" */
export type BusinessAreaIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.business_area" */
export type BusinessAreaInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  internalAuditEntities?: InputMaybe<InternalAuditEntityArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type BusinessAreaMaxFields = {
  __typename?: 'business_area_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type BusinessAreaMinFields = {
  __typename?: 'business_area_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.business_area" */
export type BusinessAreaMutationResponse = {
  __typename?: 'business_area_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<BusinessArea>;
};

/** input type for inserting object relation for remote table "risksmart.business_area" */
export type BusinessAreaObjRelInsertInput = {
  data: BusinessAreaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<BusinessAreaOnConflict>;
};

/** on_conflict condition type for table "risksmart.business_area" */
export type BusinessAreaOnConflict = {
  constraint: BusinessAreaConstraint;
  update_columns?: Array<BusinessAreaUpdateColumn>;
  where?: InputMaybe<BusinessAreaBoolExp>;
};

/** Ordering options when selecting data from "risksmart.business_area". */
export type BusinessAreaOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  internalAuditEntities_aggregate?: InputMaybe<InternalAuditEntityAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.business_area */
export type BusinessAreaPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.business_area" */
export enum BusinessAreaSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.business_area" */
export type BusinessAreaSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type BusinessAreaStddevFields = {
  __typename?: 'business_area_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type BusinessAreaStddevPopFields = {
  __typename?: 'business_area_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type BusinessAreaStddevSampFields = {
  __typename?: 'business_area_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "business_area" */
export type BusinessAreaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: BusinessAreaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type BusinessAreaStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type BusinessAreaSumFields = {
  __typename?: 'business_area_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.business_area" */
export enum BusinessAreaUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

export type BusinessAreaUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<BusinessAreaIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<BusinessAreaSetInput>;
  /** filter the rows which have to be updated */
  where: BusinessAreaBoolExp;
};

/** aggregate var_pop on columns */
export type BusinessAreaVarPopFields = {
  __typename?: 'business_area_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type BusinessAreaVarSampFields = {
  __typename?: 'business_area_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type BusinessAreaVarianceFields = {
  __typename?: 'business_area_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.cause" */
export type Cause = {
  __typename?: 'cause';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Significance?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.cause" */
export type CauseCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.cause" */
export type CauseMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.cause" */
export type CausePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.cause" */
export type CausePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.cause" */
export type CauseAggregate = {
  __typename?: 'cause_aggregate';
  aggregate?: Maybe<CauseAggregateFields>;
  nodes: Array<Cause>;
};

export type CauseAggregateBoolExp = {
  count?: InputMaybe<CauseAggregateBoolExpCount>;
};

export type CauseAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CauseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CauseBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.cause" */
export type CauseAggregateFields = {
  __typename?: 'cause_aggregate_fields';
  avg?: Maybe<CauseAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CauseMaxFields>;
  min?: Maybe<CauseMinFields>;
  stddev?: Maybe<CauseStddevFields>;
  stddev_pop?: Maybe<CauseStddevPopFields>;
  stddev_samp?: Maybe<CauseStddevSampFields>;
  sum?: Maybe<CauseSumFields>;
  var_pop?: Maybe<CauseVarPopFields>;
  var_samp?: Maybe<CauseVarSampFields>;
  variance?: Maybe<CauseVarianceFields>;
};


/** aggregate fields of "risksmart.cause" */
export type CauseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CauseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.cause" */
export type CauseAggregateOrderBy = {
  avg?: InputMaybe<CauseAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CauseMaxOrderBy>;
  min?: InputMaybe<CauseMinOrderBy>;
  stddev?: InputMaybe<CauseStddevOrderBy>;
  stddev_pop?: InputMaybe<CauseStddevPopOrderBy>;
  stddev_samp?: InputMaybe<CauseStddevSampOrderBy>;
  sum?: InputMaybe<CauseSumOrderBy>;
  var_pop?: InputMaybe<CauseVarPopOrderBy>;
  var_samp?: InputMaybe<CauseVarSampOrderBy>;
  variance?: InputMaybe<CauseVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CauseAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.cause" */
export type CauseArrRelInsertInput = {
  data: Array<CauseInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CauseOnConflict>;
};

/** columns and relationships of "risksmart.cause_audit" */
export type CauseAudit = {
  __typename?: 'cause_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Significance?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.cause_audit" */
export type CauseAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.cause_audit" */
export type CauseAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.cause_audit" */
export type CauseAuditAggregate = {
  __typename?: 'cause_audit_aggregate';
  aggregate?: Maybe<CauseAuditAggregateFields>;
  nodes: Array<CauseAudit>;
};

/** aggregate fields of "risksmart.cause_audit" */
export type CauseAuditAggregateFields = {
  __typename?: 'cause_audit_aggregate_fields';
  avg?: Maybe<CauseAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<CauseAuditMaxFields>;
  min?: Maybe<CauseAuditMinFields>;
  stddev?: Maybe<CauseAuditStddevFields>;
  stddev_pop?: Maybe<CauseAuditStddevPopFields>;
  stddev_samp?: Maybe<CauseAuditStddevSampFields>;
  sum?: Maybe<CauseAuditSumFields>;
  var_pop?: Maybe<CauseAuditVarPopFields>;
  var_samp?: Maybe<CauseAuditVarSampFields>;
  variance?: Maybe<CauseAuditVarianceFields>;
};


/** aggregate fields of "risksmart.cause_audit" */
export type CauseAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CauseAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CauseAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type CauseAuditAvgFields = {
  __typename?: 'cause_audit_avg_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.cause_audit". All fields are combined with a logical 'AND'. */
export type CauseAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  Significance?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<CauseAuditBoolExp>>;
  _not?: InputMaybe<CauseAuditBoolExp>;
  _or?: InputMaybe<Array<CauseAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.cause_audit" */
export enum CauseAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  CauseAuditPkey = 'cause_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CauseAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CauseAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CauseAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.cause_audit" */
export type CauseAuditIncInput = {
  Significance?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.cause_audit" */
export type CauseAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CauseAuditMaxFields = {
  __typename?: 'cause_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Significance?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CauseAuditMinFields = {
  __typename?: 'cause_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Significance?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.cause_audit" */
export type CauseAuditMutationResponse = {
  __typename?: 'cause_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CauseAudit>;
};

/** on_conflict condition type for table "risksmart.cause_audit" */
export type CauseAuditOnConflict = {
  constraint: CauseAuditConstraint;
  update_columns?: Array<CauseAuditUpdateColumn>;
  where?: InputMaybe<CauseAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.cause_audit". */
export type CauseAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Significance?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.cause_audit */
export type CauseAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CauseAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.cause_audit" */
export enum CauseAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Significance = 'Significance',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.cause_audit" */
export type CauseAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type CauseAuditStddevFields = {
  __typename?: 'cause_audit_stddev_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type CauseAuditStddevPopFields = {
  __typename?: 'cause_audit_stddev_pop_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type CauseAuditStddevSampFields = {
  __typename?: 'cause_audit_stddev_samp_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "cause_audit" */
export type CauseAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CauseAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CauseAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CauseAuditSumFields = {
  __typename?: 'cause_audit_sum_fields';
  Significance?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.cause_audit" */
export enum CauseAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Significance = 'Significance',
  /** column name */
  Title = 'Title'
}

export type CauseAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CauseAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CauseAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CauseAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CauseAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CauseAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CauseAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CauseAuditSetInput>;
  /** filter the rows which have to be updated */
  where: CauseAuditBoolExp;
};

/** aggregate var_pop on columns */
export type CauseAuditVarPopFields = {
  __typename?: 'cause_audit_var_pop_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type CauseAuditVarSampFields = {
  __typename?: 'cause_audit_var_samp_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type CauseAuditVarianceFields = {
  __typename?: 'cause_audit_variance_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type CauseAvgFields = {
  __typename?: 'cause_avg_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.cause" */
export type CauseAvgOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.cause". All fields are combined with a logical 'AND'. */
export type CauseBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  Significance?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<CauseBoolExp>>;
  _not?: InputMaybe<CauseBoolExp>;
  _or?: InputMaybe<Array<CauseBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.cause" */
export enum CauseConstraint {
  /** unique or primary key constraint on columns "Id" */
  CausePkey = 'cause_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CauseDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CauseDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CauseDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.cause" */
export type CauseIncInput = {
  Significance?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.cause" */
export type CauseInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type CauseMaxFields = {
  __typename?: 'cause_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Significance?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.cause" */
export type CauseMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Significance?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CauseMinFields = {
  __typename?: 'cause_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Significance?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.cause" */
export type CauseMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Significance?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.cause" */
export type CauseMutationResponse = {
  __typename?: 'cause_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Cause>;
};

/** input type for inserting object relation for remote table "risksmart.cause" */
export type CauseObjRelInsertInput = {
  data: CauseInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CauseOnConflict>;
};

/** on_conflict condition type for table "risksmart.cause" */
export type CauseOnConflict = {
  constraint: CauseConstraint;
  update_columns?: Array<CauseUpdateColumn>;
  where?: InputMaybe<CauseBoolExp>;
};

/** Ordering options when selecting data from "risksmart.cause". */
export type CauseOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Significance?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.cause */
export type CausePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CausePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.cause" */
export enum CauseSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Significance = 'Significance',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.cause" */
export type CauseSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type CauseStddevFields = {
  __typename?: 'cause_stddev_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.cause" */
export type CauseStddevOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type CauseStddevPopFields = {
  __typename?: 'cause_stddev_pop_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.cause" */
export type CauseStddevPopOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type CauseStddevSampFields = {
  __typename?: 'cause_stddev_samp_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.cause" */
export type CauseStddevSampOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "cause" */
export type CauseStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CauseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CauseStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Significance?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type CauseSumFields = {
  __typename?: 'cause_sum_fields';
  Significance?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.cause" */
export type CauseSumOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.cause" */
export enum CauseUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Significance = 'Significance',
  /** column name */
  Title = 'Title'
}

export type CauseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CauseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CauseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CauseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CauseDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<CauseIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CausePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CauseSetInput>;
  /** filter the rows which have to be updated */
  where: CauseBoolExp;
};

/** aggregate var_pop on columns */
export type CauseVarPopFields = {
  __typename?: 'cause_var_pop_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.cause" */
export type CauseVarPopOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type CauseVarSampFields = {
  __typename?: 'cause_var_samp_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.cause" */
export type CauseVarSampOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type CauseVarianceFields = {
  __typename?: 'cause_variance_fields';
  Significance?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.cause" */
export type CauseVarianceOrderBy = {
  Significance?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.change_request" */
export type ChangeRequest = {
  __typename?: 'change_request';
  ChangeRequestStatus: ApprovalStatusEnum;
  Changes: Scalars['jsonb']['output'];
  Comment: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  RequestedChanges?: Maybe<Scalars['jsonb']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type: Scalars['String']['output'];
  /** An array relationship */
  contributors: Array<ChangeRequestContributor>;
  /** An aggregate relationship */
  contributors_aggregate: ChangeRequestContributorAggregate;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  overriddenBy?: Maybe<User>;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An array relationship */
  parentOwnerAndContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  parentOwnerAndContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  responses: Array<ApproverResponse>;
  /** An aggregate relationship */
  responses_aggregate: ApproverResponseAggregate;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestChangesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestRequestedChangesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestContributorsArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestContributorOrderBy>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestContributorOrderBy>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestParentOwnerAndContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestParentOwnerAndContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestResponsesArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


/** columns and relationships of "risksmart.change_request" */
export type ChangeRequestResponsesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};

/** aggregated selection of "risksmart.change_request" */
export type ChangeRequestAggregate = {
  __typename?: 'change_request_aggregate';
  aggregate?: Maybe<ChangeRequestAggregateFields>;
  nodes: Array<ChangeRequest>;
};

export type ChangeRequestAggregateBoolExp = {
  count?: InputMaybe<ChangeRequestAggregateBoolExpCount>;
};

export type ChangeRequestAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ChangeRequestBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.change_request" */
export type ChangeRequestAggregateFields = {
  __typename?: 'change_request_aggregate_fields';
  avg?: Maybe<ChangeRequestAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ChangeRequestMaxFields>;
  min?: Maybe<ChangeRequestMinFields>;
  stddev?: Maybe<ChangeRequestStddevFields>;
  stddev_pop?: Maybe<ChangeRequestStddevPopFields>;
  stddev_samp?: Maybe<ChangeRequestStddevSampFields>;
  sum?: Maybe<ChangeRequestSumFields>;
  var_pop?: Maybe<ChangeRequestVarPopFields>;
  var_samp?: Maybe<ChangeRequestVarSampFields>;
  variance?: Maybe<ChangeRequestVarianceFields>;
};


/** aggregate fields of "risksmart.change_request" */
export type ChangeRequestAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.change_request" */
export type ChangeRequestAggregateOrderBy = {
  avg?: InputMaybe<ChangeRequestAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChangeRequestMaxOrderBy>;
  min?: InputMaybe<ChangeRequestMinOrderBy>;
  stddev?: InputMaybe<ChangeRequestStddevOrderBy>;
  stddev_pop?: InputMaybe<ChangeRequestStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ChangeRequestStddevSampOrderBy>;
  sum?: InputMaybe<ChangeRequestSumOrderBy>;
  var_pop?: InputMaybe<ChangeRequestVarPopOrderBy>;
  var_samp?: InputMaybe<ChangeRequestVarSampOrderBy>;
  variance?: InputMaybe<ChangeRequestVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ChangeRequestAppendInput = {
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.change_request" */
export type ChangeRequestArrRelInsertInput = {
  data: Array<ChangeRequestInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChangeRequestOnConflict>;
};

/** columns and relationships of "risksmart.change_request_audit" */
export type ChangeRequestAudit = {
  __typename?: 'change_request_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ChangeRequestStatus: Scalars['String']['output'];
  Changes: Scalars['jsonb']['output'];
  Comment: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  RequestedChanges?: Maybe<Scalars['jsonb']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.change_request_audit" */
export type ChangeRequestAuditChangesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.change_request_audit" */
export type ChangeRequestAuditRequestedChangesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.change_request_audit" */
export type ChangeRequestAuditAggregate = {
  __typename?: 'change_request_audit_aggregate';
  aggregate?: Maybe<ChangeRequestAuditAggregateFields>;
  nodes: Array<ChangeRequestAudit>;
};

/** aggregate fields of "risksmart.change_request_audit" */
export type ChangeRequestAuditAggregateFields = {
  __typename?: 'change_request_audit_aggregate_fields';
  avg?: Maybe<ChangeRequestAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ChangeRequestAuditMaxFields>;
  min?: Maybe<ChangeRequestAuditMinFields>;
  stddev?: Maybe<ChangeRequestAuditStddevFields>;
  stddev_pop?: Maybe<ChangeRequestAuditStddevPopFields>;
  stddev_samp?: Maybe<ChangeRequestAuditStddevSampFields>;
  sum?: Maybe<ChangeRequestAuditSumFields>;
  var_pop?: Maybe<ChangeRequestAuditVarPopFields>;
  var_samp?: Maybe<ChangeRequestAuditVarSampFields>;
  variance?: Maybe<ChangeRequestAuditVarianceFields>;
};


/** aggregate fields of "risksmart.change_request_audit" */
export type ChangeRequestAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChangeRequestAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ChangeRequestAuditAppendInput = {
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ChangeRequestAuditAvgFields = {
  __typename?: 'change_request_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request_audit". All fields are combined with a logical 'AND'. */
export type ChangeRequestAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ChangeRequestStatus?: InputMaybe<StringComparisonExp>;
  Changes?: InputMaybe<JsonbComparisonExp>;
  Comment?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OverriddenAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OverriddenByUser?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  RequestedChanges?: InputMaybe<JsonbComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ChangeRequestAuditBoolExp>>;
  _not?: InputMaybe<ChangeRequestAuditBoolExp>;
  _or?: InputMaybe<Array<ChangeRequestAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.change_request_audit" */
export enum ChangeRequestAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ChangeRequestAuditPkey = 'change_request_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ChangeRequestAuditDeleteAtPathInput = {
  Changes?: InputMaybe<Array<Scalars['String']['input']>>;
  RequestedChanges?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ChangeRequestAuditDeleteElemInput = {
  Changes?: InputMaybe<Scalars['Int']['input']>;
  RequestedChanges?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ChangeRequestAuditDeleteKeyInput = {
  Changes?: InputMaybe<Scalars['String']['input']>;
  RequestedChanges?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.change_request_audit" */
export type ChangeRequestAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.change_request_audit" */
export type ChangeRequestAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestStatus?: InputMaybe<Scalars['String']['input']>;
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ChangeRequestAuditMaxFields = {
  __typename?: 'change_request_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ChangeRequestStatus?: Maybe<Scalars['String']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ChangeRequestAuditMinFields = {
  __typename?: 'change_request_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ChangeRequestStatus?: Maybe<Scalars['String']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.change_request_audit" */
export type ChangeRequestAuditMutationResponse = {
  __typename?: 'change_request_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChangeRequestAudit>;
};

/** on_conflict condition type for table "risksmart.change_request_audit" */
export type ChangeRequestAuditOnConflict = {
  constraint: ChangeRequestAuditConstraint;
  update_columns?: Array<ChangeRequestAuditUpdateColumn>;
  where?: InputMaybe<ChangeRequestAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.change_request_audit". */
export type ChangeRequestAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ChangeRequestStatus?: InputMaybe<OrderBy>;
  Changes?: InputMaybe<OrderBy>;
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  RequestedChanges?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.change_request_audit */
export type ChangeRequestAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ChangeRequestAuditPrependInput = {
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.change_request_audit" */
export enum ChangeRequestAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ChangeRequestStatus = 'ChangeRequestStatus',
  /** column name */
  Changes = 'Changes',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  RequestedChanges = 'RequestedChanges',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.change_request_audit" */
export type ChangeRequestAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestStatus?: InputMaybe<Scalars['String']['input']>;
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ChangeRequestAuditStddevFields = {
  __typename?: 'change_request_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ChangeRequestAuditStddevPopFields = {
  __typename?: 'change_request_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ChangeRequestAuditStddevSampFields = {
  __typename?: 'change_request_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "change_request_audit" */
export type ChangeRequestAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChangeRequestAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChangeRequestAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestStatus?: InputMaybe<Scalars['String']['input']>;
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ChangeRequestAuditSumFields = {
  __typename?: 'change_request_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.change_request_audit" */
export enum ChangeRequestAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ChangeRequestStatus = 'ChangeRequestStatus',
  /** column name */
  Changes = 'Changes',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  RequestedChanges = 'RequestedChanges',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Type = 'Type'
}

export type ChangeRequestAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ChangeRequestAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ChangeRequestAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ChangeRequestAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ChangeRequestAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChangeRequestAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ChangeRequestAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChangeRequestAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ChangeRequestAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ChangeRequestAuditVarPopFields = {
  __typename?: 'change_request_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ChangeRequestAuditVarSampFields = {
  __typename?: 'change_request_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ChangeRequestAuditVarianceFields = {
  __typename?: 'change_request_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ChangeRequestAvgFields = {
  __typename?: 'change_request_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.change_request" */
export type ChangeRequestAvgOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request". All fields are combined with a logical 'AND'. */
export type ChangeRequestBoolExp = {
  ChangeRequestStatus?: InputMaybe<ApprovalStatusEnumComparisonExp>;
  Changes?: InputMaybe<JsonbComparisonExp>;
  Comment?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OverriddenAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OverriddenByUser?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  RequestedChanges?: InputMaybe<JsonbComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ChangeRequestBoolExp>>;
  _not?: InputMaybe<ChangeRequestBoolExp>;
  _or?: InputMaybe<Array<ChangeRequestBoolExp>>;
  contributors?: InputMaybe<ChangeRequestContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ChangeRequestContributorAggregateBoolExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  overriddenBy?: InputMaybe<UserBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  parentOwnerAndContributors?: InputMaybe<AncestorContributorBoolExp>;
  parentOwnerAndContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  responses?: InputMaybe<ApproverResponseBoolExp>;
  responses_aggregate?: InputMaybe<ApproverResponseAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.change_request" */
export enum ChangeRequestConstraint {
  /** unique or primary key constraint on columns "Id" */
  ChangeRequestPkey = 'change_request_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxChangerequestOrgkeySequentialid = 'idx_changerequest_orgkey_sequentialid'
}

/** columns and relationships of "risksmart.change_request_contributor" */
export type ChangeRequestContributor = {
  __typename?: 'change_request_contributor';
  ChangeRequestId: Scalars['uuid']['output'];
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  changeRequest: ChangeRequest;
  /** An object relationship */
  user?: Maybe<User>;
};

/** aggregated selection of "risksmart.change_request_contributor" */
export type ChangeRequestContributorAggregate = {
  __typename?: 'change_request_contributor_aggregate';
  aggregate?: Maybe<ChangeRequestContributorAggregateFields>;
  nodes: Array<ChangeRequestContributor>;
};

export type ChangeRequestContributorAggregateBoolExp = {
  count?: InputMaybe<ChangeRequestContributorAggregateBoolExpCount>;
};

export type ChangeRequestContributorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ChangeRequestContributorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.change_request_contributor" */
export type ChangeRequestContributorAggregateFields = {
  __typename?: 'change_request_contributor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ChangeRequestContributorMaxFields>;
  min?: Maybe<ChangeRequestContributorMinFields>;
};


/** aggregate fields of "risksmart.change_request_contributor" */
export type ChangeRequestContributorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.change_request_contributor" */
export type ChangeRequestContributorAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ChangeRequestContributorMaxOrderBy>;
  min?: InputMaybe<ChangeRequestContributorMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.change_request_contributor" */
export type ChangeRequestContributorArrRelInsertInput = {
  data: Array<ChangeRequestContributorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ChangeRequestContributorOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request_contributor". All fields are combined with a logical 'AND'. */
export type ChangeRequestContributorBoolExp = {
  ChangeRequestId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ChangeRequestContributorBoolExp>>;
  _not?: InputMaybe<ChangeRequestContributorBoolExp>;
  _or?: InputMaybe<Array<ChangeRequestContributorBoolExp>>;
  changeRequest?: InputMaybe<ChangeRequestBoolExp>;
  user?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.change_request_contributor" */
export enum ChangeRequestContributorConstraint {
  /** unique or primary key constraint on columns "ChangeRequestId", "UserId" */
  ChangeRequestContributorChangeRequestIdUserIdKey = 'change_request_contributor_ChangeRequestId_UserId_key',
  /** unique or primary key constraint on columns "Id" */
  ChangeRequestContributorPkey = 'change_request_contributor_pkey'
}

/** input type for inserting data into table "risksmart.change_request_contributor" */
export type ChangeRequestContributorInsertInput = {
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  changeRequest?: InputMaybe<ChangeRequestObjRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type ChangeRequestContributorMaxFields = {
  __typename?: 'change_request_contributor_max_fields';
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.change_request_contributor" */
export type ChangeRequestContributorMaxOrderBy = {
  ChangeRequestId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChangeRequestContributorMinFields = {
  __typename?: 'change_request_contributor_min_fields';
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.change_request_contributor" */
export type ChangeRequestContributorMinOrderBy = {
  ChangeRequestId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.change_request_contributor" */
export type ChangeRequestContributorMutationResponse = {
  __typename?: 'change_request_contributor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChangeRequestContributor>;
};

/** on_conflict condition type for table "risksmart.change_request_contributor" */
export type ChangeRequestContributorOnConflict = {
  constraint: ChangeRequestContributorConstraint;
  update_columns?: Array<ChangeRequestContributorUpdateColumn>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};

/** Ordering options when selecting data from "risksmart.change_request_contributor". */
export type ChangeRequestContributorOrderBy = {
  ChangeRequestId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  changeRequest?: InputMaybe<ChangeRequestOrderBy>;
  user?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.change_request_contributor */
export type ChangeRequestContributorPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.change_request_contributor" */
export enum ChangeRequestContributorSelectColumn {
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.change_request_contributor" */
export type ChangeRequestContributorSetInput = {
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "change_request_contributor" */
export type ChangeRequestContributorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChangeRequestContributorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChangeRequestContributorStreamCursorValueInput = {
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.change_request_contributor" */
export enum ChangeRequestContributorUpdateColumn {
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

export type ChangeRequestContributorUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChangeRequestContributorSetInput>;
  /** filter the rows which have to be updated */
  where: ChangeRequestContributorBoolExp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ChangeRequestDeleteAtPathInput = {
  Changes?: InputMaybe<Array<Scalars['String']['input']>>;
  RequestedChanges?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ChangeRequestDeleteElemInput = {
  Changes?: InputMaybe<Scalars['Int']['input']>;
  RequestedChanges?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ChangeRequestDeleteKeyInput = {
  Changes?: InputMaybe<Scalars['String']['input']>;
  RequestedChanges?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.change_request" */
export type ChangeRequestIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.change_request" */
export type ChangeRequestInsertInput = {
  ChangeRequestStatus?: InputMaybe<ApprovalStatusEnum>;
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  contributors?: InputMaybe<ChangeRequestContributorArrRelInsertInput>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  overriddenBy?: InputMaybe<UserObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  parentOwnerAndContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  responses?: InputMaybe<ApproverResponseArrRelInsertInput>;
};

/** aggregate max on columns */
export type ChangeRequestMaxFields = {
  __typename?: 'change_request_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.change_request" */
export type ChangeRequestMaxOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ChangeRequestMinFields = {
  __typename?: 'change_request_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.change_request" */
export type ChangeRequestMinOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.change_request" */
export type ChangeRequestMutationResponse = {
  __typename?: 'change_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ChangeRequest>;
};

/** input type for inserting object relation for remote table "risksmart.change_request" */
export type ChangeRequestObjRelInsertInput = {
  data: ChangeRequestInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ChangeRequestOnConflict>;
};

/** on_conflict condition type for table "risksmart.change_request" */
export type ChangeRequestOnConflict = {
  constraint: ChangeRequestConstraint;
  update_columns?: Array<ChangeRequestUpdateColumn>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};

/** Ordering options when selecting data from "risksmart.change_request". */
export type ChangeRequestOrderBy = {
  ChangeRequestStatus?: InputMaybe<OrderBy>;
  Changes?: InputMaybe<OrderBy>;
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  RequestedChanges?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  contributors_aggregate?: InputMaybe<ChangeRequestContributorAggregateOrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  overriddenBy?: InputMaybe<UserOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  parentOwnerAndContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  responses_aggregate?: InputMaybe<ApproverResponseAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.change_request */
export type ChangeRequestPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ChangeRequestPrependInput = {
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.change_request" */
export enum ChangeRequestSelectColumn {
  /** column name */
  ChangeRequestStatus = 'ChangeRequestStatus',
  /** column name */
  Changes = 'Changes',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  RequestedChanges = 'RequestedChanges',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.change_request" */
export type ChangeRequestSetInput = {
  ChangeRequestStatus?: InputMaybe<ApprovalStatusEnum>;
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ChangeRequestStddevFields = {
  __typename?: 'change_request_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.change_request" */
export type ChangeRequestStddevOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ChangeRequestStddevPopFields = {
  __typename?: 'change_request_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.change_request" */
export type ChangeRequestStddevPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ChangeRequestStddevSampFields = {
  __typename?: 'change_request_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.change_request" */
export type ChangeRequestStddevSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "change_request" */
export type ChangeRequestStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ChangeRequestStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ChangeRequestStreamCursorValueInput = {
  ChangeRequestStatus?: InputMaybe<ApprovalStatusEnum>;
  Changes?: InputMaybe<Scalars['jsonb']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  RequestedChanges?: InputMaybe<Scalars['jsonb']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ChangeRequestSumFields = {
  __typename?: 'change_request_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.change_request" */
export type ChangeRequestSumOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.change_request" */
export enum ChangeRequestUpdateColumn {
  /** column name */
  ChangeRequestStatus = 'ChangeRequestStatus',
  /** column name */
  Changes = 'Changes',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  RequestedChanges = 'RequestedChanges',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Type = 'Type'
}

export type ChangeRequestUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ChangeRequestAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ChangeRequestDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ChangeRequestDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ChangeRequestDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ChangeRequestIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ChangeRequestPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ChangeRequestSetInput>;
  /** filter the rows which have to be updated */
  where: ChangeRequestBoolExp;
};

/** aggregate var_pop on columns */
export type ChangeRequestVarPopFields = {
  __typename?: 'change_request_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.change_request" */
export type ChangeRequestVarPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ChangeRequestVarSampFields = {
  __typename?: 'change_request_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.change_request" */
export type ChangeRequestVarSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ChangeRequestVarianceFields = {
  __typename?: 'change_request_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.change_request" */
export type ChangeRequestVarianceOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.comment" */
export type Comment = {
  __typename?: 'comment';
  Content: Scalars['String']['output'];
  ConversationId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An object relationship */
  conversation?: Maybe<Conversation>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
};

/** aggregated selection of "risksmart.comment" */
export type CommentAggregate = {
  __typename?: 'comment_aggregate';
  aggregate?: Maybe<CommentAggregateFields>;
  nodes: Array<Comment>;
};

export type CommentAggregateBoolExp = {
  count?: InputMaybe<CommentAggregateBoolExpCount>;
};

export type CommentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<CommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<CommentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.comment" */
export type CommentAggregateFields = {
  __typename?: 'comment_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CommentMaxFields>;
  min?: Maybe<CommentMinFields>;
};


/** aggregate fields of "risksmart.comment" */
export type CommentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CommentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.comment" */
export type CommentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<CommentMaxOrderBy>;
  min?: InputMaybe<CommentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.comment" */
export type CommentArrRelInsertInput = {
  data: Array<CommentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<CommentOnConflict>;
};

/** columns and relationships of "risksmart.comment_audit" */
export type CommentAudit = {
  __typename?: 'comment_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Content: Scalars['String']['output'];
  ConversationId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.comment_audit" */
export type CommentAuditAggregate = {
  __typename?: 'comment_audit_aggregate';
  aggregate?: Maybe<CommentAuditAggregateFields>;
  nodes: Array<CommentAudit>;
};

/** aggregate fields of "risksmart.comment_audit" */
export type CommentAuditAggregateFields = {
  __typename?: 'comment_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CommentAuditMaxFields>;
  min?: Maybe<CommentAuditMinFields>;
};


/** aggregate fields of "risksmart.comment_audit" */
export type CommentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CommentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.comment_audit". All fields are combined with a logical 'AND'. */
export type CommentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Content?: InputMaybe<StringComparisonExp>;
  ConversationId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<CommentAuditBoolExp>>;
  _not?: InputMaybe<CommentAuditBoolExp>;
  _or?: InputMaybe<Array<CommentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.comment_audit" */
export enum CommentAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  CommentAuditPkey = 'comment_audit_pkey'
}

/** input type for inserting data into table "risksmart.comment_audit" */
export type CommentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CommentAuditMaxFields = {
  __typename?: 'comment_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Content?: Maybe<Scalars['String']['output']>;
  ConversationId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CommentAuditMinFields = {
  __typename?: 'comment_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Content?: Maybe<Scalars['String']['output']>;
  ConversationId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.comment_audit" */
export type CommentAuditMutationResponse = {
  __typename?: 'comment_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CommentAudit>;
};

/** on_conflict condition type for table "risksmart.comment_audit" */
export type CommentAuditOnConflict = {
  constraint: CommentAuditConstraint;
  update_columns?: Array<CommentAuditUpdateColumn>;
  where?: InputMaybe<CommentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.comment_audit". */
export type CommentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Content?: InputMaybe<OrderBy>;
  ConversationId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.comment_audit */
export type CommentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.comment_audit" */
export enum CommentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Content = 'Content',
  /** column name */
  ConversationId = 'ConversationId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.comment_audit" */
export type CommentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "comment_audit" */
export type CommentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CommentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CommentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.comment_audit" */
export enum CommentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Content = 'Content',
  /** column name */
  ConversationId = 'ConversationId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type CommentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: CommentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.comment". All fields are combined with a logical 'AND'. */
export type CommentBoolExp = {
  Content?: InputMaybe<StringComparisonExp>;
  ConversationId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<CommentBoolExp>>;
  _not?: InputMaybe<CommentBoolExp>;
  _or?: InputMaybe<Array<CommentBoolExp>>;
  conversation?: InputMaybe<ConversationBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
};

/** unique or primary key constraints on table "risksmart.comment" */
export enum CommentConstraint {
  /** unique or primary key constraint on columns "Id" */
  CommentPkey = 'comment_pkey'
}

/** input type for inserting data into table "risksmart.comment" */
export type CommentInsertInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  conversation?: InputMaybe<ConversationObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
};

/** aggregate max on columns */
export type CommentMaxFields = {
  __typename?: 'comment_max_fields';
  Content?: Maybe<Scalars['String']['output']>;
  ConversationId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.comment" */
export type CommentMaxOrderBy = {
  Content?: InputMaybe<OrderBy>;
  ConversationId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type CommentMinFields = {
  __typename?: 'comment_min_fields';
  Content?: Maybe<Scalars['String']['output']>;
  ConversationId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.comment" */
export type CommentMinOrderBy = {
  Content?: InputMaybe<OrderBy>;
  ConversationId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.comment" */
export type CommentMutationResponse = {
  __typename?: 'comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment>;
};

/** on_conflict condition type for table "risksmart.comment" */
export type CommentOnConflict = {
  constraint: CommentConstraint;
  update_columns?: Array<CommentUpdateColumn>;
  where?: InputMaybe<CommentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.comment". */
export type CommentOrderBy = {
  Content?: InputMaybe<OrderBy>;
  ConversationId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  conversation?: InputMaybe<ConversationOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
};

/** primary key columns input for table: risksmart.comment */
export type CommentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.comment" */
export enum CommentSelectColumn {
  /** column name */
  Content = 'Content',
  /** column name */
  ConversationId = 'ConversationId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.comment" */
export type CommentSetInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "comment" */
export type CommentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CommentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CommentStreamCursorValueInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
  ConversationId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.comment" */
export enum CommentUpdateColumn {
  /** column name */
  Content = 'Content',
  /** column name */
  ConversationId = 'ConversationId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type CommentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CommentSetInput>;
  /** filter the rows which have to be updated */
  where: CommentBoolExp;
};

/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessment = {
  __typename?: 'compliance_monitoring_assessment';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: AssessmentStatusEnum;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  assessmentActions: Array<ActionParent>;
  /** An aggregate relationship */
  assessmentActions_aggregate: ActionParentAggregate;
  /** An array relationship */
  assessmentActivities: Array<AssessmentActivity>;
  /** An aggregate relationship */
  assessmentActivities_aggregate: AssessmentActivityAggregate;
  /** An array relationship */
  assessmentIssues: Array<IssueParent>;
  /** An aggregate relationship */
  assessmentIssues_aggregate: IssueParentAggregate;
  /** An array relationship */
  assessmentResults: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: AssessmentResultParentAggregate;
  /** An object relationship */
  completedByUser?: Maybe<User>;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  originatingItem?: Maybe<Node>;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentActivitiesArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentActivitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAssessmentResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** aggregated selection of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAggregate = {
  __typename?: 'compliance_monitoring_assessment_aggregate';
  aggregate?: Maybe<ComplianceMonitoringAssessmentAggregateFields>;
  nodes: Array<ComplianceMonitoringAssessment>;
};

/** aggregate fields of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAggregateFields = {
  __typename?: 'compliance_monitoring_assessment_aggregate_fields';
  avg?: Maybe<ComplianceMonitoringAssessmentAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ComplianceMonitoringAssessmentMaxFields>;
  min?: Maybe<ComplianceMonitoringAssessmentMinFields>;
  stddev?: Maybe<ComplianceMonitoringAssessmentStddevFields>;
  stddev_pop?: Maybe<ComplianceMonitoringAssessmentStddevPopFields>;
  stddev_samp?: Maybe<ComplianceMonitoringAssessmentStddevSampFields>;
  sum?: Maybe<ComplianceMonitoringAssessmentSumFields>;
  var_pop?: Maybe<ComplianceMonitoringAssessmentVarPopFields>;
  var_samp?: Maybe<ComplianceMonitoringAssessmentVarSampFields>;
  variance?: Maybe<ComplianceMonitoringAssessmentVarianceFields>;
};


/** aggregate fields of "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ComplianceMonitoringAssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ComplianceMonitoringAssessmentAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ComplianceMonitoringAssessmentAvgFields = {
  __typename?: 'compliance_monitoring_assessment_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.compliance_monitoring_assessment". All fields are combined with a logical 'AND'. */
export type ComplianceMonitoringAssessmentBoolExp = {
  ActualCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  CompletedByUser?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OriginatingItemId?: InputMaybe<UuidComparisonExp>;
  Outcome?: InputMaybe<IntComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  StartDate?: InputMaybe<TimestamptzComparisonExp>;
  Status?: InputMaybe<AssessmentStatusEnumComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  TargetCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ComplianceMonitoringAssessmentBoolExp>>;
  _not?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
  _or?: InputMaybe<Array<ComplianceMonitoringAssessmentBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  assessmentActions?: InputMaybe<ActionParentBoolExp>;
  assessmentActions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  assessmentActivities?: InputMaybe<AssessmentActivityBoolExp>;
  assessmentActivities_aggregate?: InputMaybe<AssessmentActivityAggregateBoolExp>;
  assessmentIssues?: InputMaybe<IssueParentBoolExp>;
  assessmentIssues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  assessmentResults?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  completedByUser?: InputMaybe<UserBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  originatingItem?: InputMaybe<NodeBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.compliance_monitoring_assessment" */
export enum ComplianceMonitoringAssessmentConstraint {
  /** unique or primary key constraint on columns "Id" */
  ComplianceMonitoringAssessmentPkey = 'compliance_monitoring_assessment_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxComplianceMonitoringAssessmentOrgkeySequentialid = 'idx_compliance_monitoring_assessment_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ComplianceMonitoringAssessmentDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ComplianceMonitoringAssessmentDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ComplianceMonitoringAssessmentDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentIncInput = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentInsertInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  assessmentActions?: InputMaybe<ActionParentArrRelInsertInput>;
  assessmentActivities?: InputMaybe<AssessmentActivityArrRelInsertInput>;
  assessmentIssues?: InputMaybe<IssueParentArrRelInsertInput>;
  assessmentResults?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  completedByUser?: InputMaybe<UserObjRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  originatingItem?: InputMaybe<NodeObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
};

/** aggregate max on columns */
export type ComplianceMonitoringAssessmentMaxFields = {
  __typename?: 'compliance_monitoring_assessment_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ComplianceMonitoringAssessmentMinFields = {
  __typename?: 'compliance_monitoring_assessment_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentMutationResponse = {
  __typename?: 'compliance_monitoring_assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ComplianceMonitoringAssessment>;
};

/** input type for inserting object relation for remote table "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentObjRelInsertInput = {
  data: ComplianceMonitoringAssessmentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ComplianceMonitoringAssessmentOnConflict>;
};

/** on_conflict condition type for table "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentOnConflict = {
  constraint: ComplianceMonitoringAssessmentConstraint;
  update_columns?: Array<ComplianceMonitoringAssessmentUpdateColumn>;
  where?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.compliance_monitoring_assessment". */
export type ComplianceMonitoringAssessmentOrderBy = {
  ActualCompletionDate?: InputMaybe<OrderBy>;
  CompletedByUser?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OriginatingItemId?: InputMaybe<OrderBy>;
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  TargetCompletionDate?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  assessmentActions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  assessmentActivities_aggregate?: InputMaybe<AssessmentActivityAggregateOrderBy>;
  assessmentIssues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  completedByUser?: InputMaybe<UserOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  originatingItem?: InputMaybe<NodeOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.compliance_monitoring_assessment */
export type ComplianceMonitoringAssessmentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ComplianceMonitoringAssessmentPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.compliance_monitoring_assessment" */
export enum ComplianceMonitoringAssessmentSelectColumn {
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentSetInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ComplianceMonitoringAssessmentStddevFields = {
  __typename?: 'compliance_monitoring_assessment_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ComplianceMonitoringAssessmentStddevPopFields = {
  __typename?: 'compliance_monitoring_assessment_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ComplianceMonitoringAssessmentStddevSampFields = {
  __typename?: 'compliance_monitoring_assessment_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "compliance_monitoring_assessment" */
export type ComplianceMonitoringAssessmentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ComplianceMonitoringAssessmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ComplianceMonitoringAssessmentStreamCursorValueInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ComplianceMonitoringAssessmentSumFields = {
  __typename?: 'compliance_monitoring_assessment_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.compliance_monitoring_assessment" */
export enum ComplianceMonitoringAssessmentUpdateColumn {
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

export type ComplianceMonitoringAssessmentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ComplianceMonitoringAssessmentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ComplianceMonitoringAssessmentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ComplianceMonitoringAssessmentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ComplianceMonitoringAssessmentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ComplianceMonitoringAssessmentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ComplianceMonitoringAssessmentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ComplianceMonitoringAssessmentSetInput>;
  /** filter the rows which have to be updated */
  where: ComplianceMonitoringAssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type ComplianceMonitoringAssessmentVarPopFields = {
  __typename?: 'compliance_monitoring_assessment_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ComplianceMonitoringAssessmentVarSampFields = {
  __typename?: 'compliance_monitoring_assessment_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ComplianceMonitoringAssessmentVarianceFields = {
  __typename?: 'compliance_monitoring_assessment_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "config.env" */
export type ConfigEnv = {
  __typename?: 'config_env';
  Name: Scalars['String']['output'];
  ValueInteger?: Maybe<Scalars['Int']['output']>;
  ValueString?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "config.env" */
export type ConfigEnvAggregate = {
  __typename?: 'config_env_aggregate';
  aggregate?: Maybe<ConfigEnvAggregateFields>;
  nodes: Array<ConfigEnv>;
};

/** aggregate fields of "config.env" */
export type ConfigEnvAggregateFields = {
  __typename?: 'config_env_aggregate_fields';
  avg?: Maybe<ConfigEnvAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ConfigEnvMaxFields>;
  min?: Maybe<ConfigEnvMinFields>;
  stddev?: Maybe<ConfigEnvStddevFields>;
  stddev_pop?: Maybe<ConfigEnvStddevPopFields>;
  stddev_samp?: Maybe<ConfigEnvStddevSampFields>;
  sum?: Maybe<ConfigEnvSumFields>;
  var_pop?: Maybe<ConfigEnvVarPopFields>;
  var_samp?: Maybe<ConfigEnvVarSampFields>;
  variance?: Maybe<ConfigEnvVarianceFields>;
};


/** aggregate fields of "config.env" */
export type ConfigEnvAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConfigEnvSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type ConfigEnvAvgFields = {
  __typename?: 'config_env_avg_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "config.env". All fields are combined with a logical 'AND'. */
export type ConfigEnvBoolExp = {
  Name?: InputMaybe<StringComparisonExp>;
  ValueInteger?: InputMaybe<IntComparisonExp>;
  ValueString?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ConfigEnvBoolExp>>;
  _not?: InputMaybe<ConfigEnvBoolExp>;
  _or?: InputMaybe<Array<ConfigEnvBoolExp>>;
};

/** unique or primary key constraints on table "config.env" */
export enum ConfigEnvConstraint {
  /** unique or primary key constraint on columns "Name" */
  EnvPkey = 'env_pkey'
}

/** input type for incrementing numeric columns in table "config.env" */
export type ConfigEnvIncInput = {
  ValueInteger?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "config.env" */
export type ConfigEnvInsertInput = {
  Name?: InputMaybe<Scalars['String']['input']>;
  ValueInteger?: InputMaybe<Scalars['Int']['input']>;
  ValueString?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ConfigEnvMaxFields = {
  __typename?: 'config_env_max_fields';
  Name?: Maybe<Scalars['String']['output']>;
  ValueInteger?: Maybe<Scalars['Int']['output']>;
  ValueString?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ConfigEnvMinFields = {
  __typename?: 'config_env_min_fields';
  Name?: Maybe<Scalars['String']['output']>;
  ValueInteger?: Maybe<Scalars['Int']['output']>;
  ValueString?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "config.env" */
export type ConfigEnvMutationResponse = {
  __typename?: 'config_env_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ConfigEnv>;
};

/** on_conflict condition type for table "config.env" */
export type ConfigEnvOnConflict = {
  constraint: ConfigEnvConstraint;
  update_columns?: Array<ConfigEnvUpdateColumn>;
  where?: InputMaybe<ConfigEnvBoolExp>;
};

/** Ordering options when selecting data from "config.env". */
export type ConfigEnvOrderBy = {
  Name?: InputMaybe<OrderBy>;
  ValueInteger?: InputMaybe<OrderBy>;
  ValueString?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: config.env */
export type ConfigEnvPkColumnsInput = {
  Name: Scalars['String']['input'];
};

/** select columns of table "config.env" */
export enum ConfigEnvSelectColumn {
  /** column name */
  Name = 'Name',
  /** column name */
  ValueInteger = 'ValueInteger',
  /** column name */
  ValueString = 'ValueString'
}

/** input type for updating data in table "config.env" */
export type ConfigEnvSetInput = {
  Name?: InputMaybe<Scalars['String']['input']>;
  ValueInteger?: InputMaybe<Scalars['Int']['input']>;
  ValueString?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ConfigEnvStddevFields = {
  __typename?: 'config_env_stddev_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ConfigEnvStddevPopFields = {
  __typename?: 'config_env_stddev_pop_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ConfigEnvStddevSampFields = {
  __typename?: 'config_env_stddev_samp_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "config_env" */
export type ConfigEnvStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConfigEnvStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConfigEnvStreamCursorValueInput = {
  Name?: InputMaybe<Scalars['String']['input']>;
  ValueInteger?: InputMaybe<Scalars['Int']['input']>;
  ValueString?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ConfigEnvSumFields = {
  __typename?: 'config_env_sum_fields';
  ValueInteger?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "config.env" */
export enum ConfigEnvUpdateColumn {
  /** column name */
  Name = 'Name',
  /** column name */
  ValueInteger = 'ValueInteger',
  /** column name */
  ValueString = 'ValueString'
}

export type ConfigEnvUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConfigEnvIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConfigEnvSetInput>;
  /** filter the rows which have to be updated */
  where: ConfigEnvBoolExp;
};

/** aggregate var_pop on columns */
export type ConfigEnvVarPopFields = {
  __typename?: 'config_env_var_pop_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ConfigEnvVarSampFields = {
  __typename?: 'config_env_var_samp_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ConfigEnvVarianceFields = {
  __typename?: 'config_env_variance_fields';
  ValueInteger?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.consequence" */
export type Consequence = {
  __typename?: 'consequence';
  CostType: CostTypeEnum;
  CostValue: Scalars['numeric']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  Type?: Maybe<ConsequenceTypeEnum>;
  /** An object relationship */
  costType: CostType;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.consequence" */
export type ConsequenceCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.consequence" */
export type ConsequenceMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.consequence" */
export type ConsequencePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.consequence" */
export type ConsequencePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.consequence" */
export type ConsequenceAggregate = {
  __typename?: 'consequence_aggregate';
  aggregate?: Maybe<ConsequenceAggregateFields>;
  nodes: Array<Consequence>;
};

export type ConsequenceAggregateBoolExp = {
  count?: InputMaybe<ConsequenceAggregateBoolExpCount>;
};

export type ConsequenceAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ConsequenceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ConsequenceBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.consequence" */
export type ConsequenceAggregateFields = {
  __typename?: 'consequence_aggregate_fields';
  avg?: Maybe<ConsequenceAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ConsequenceMaxFields>;
  min?: Maybe<ConsequenceMinFields>;
  stddev?: Maybe<ConsequenceStddevFields>;
  stddev_pop?: Maybe<ConsequenceStddevPopFields>;
  stddev_samp?: Maybe<ConsequenceStddevSampFields>;
  sum?: Maybe<ConsequenceSumFields>;
  var_pop?: Maybe<ConsequenceVarPopFields>;
  var_samp?: Maybe<ConsequenceVarSampFields>;
  variance?: Maybe<ConsequenceVarianceFields>;
};


/** aggregate fields of "risksmart.consequence" */
export type ConsequenceAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsequenceSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.consequence" */
export type ConsequenceAggregateOrderBy = {
  avg?: InputMaybe<ConsequenceAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ConsequenceMaxOrderBy>;
  min?: InputMaybe<ConsequenceMinOrderBy>;
  stddev?: InputMaybe<ConsequenceStddevOrderBy>;
  stddev_pop?: InputMaybe<ConsequenceStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ConsequenceStddevSampOrderBy>;
  sum?: InputMaybe<ConsequenceSumOrderBy>;
  var_pop?: InputMaybe<ConsequenceVarPopOrderBy>;
  var_samp?: InputMaybe<ConsequenceVarSampOrderBy>;
  variance?: InputMaybe<ConsequenceVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ConsequenceAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.consequence" */
export type ConsequenceArrRelInsertInput = {
  data: Array<ConsequenceInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ConsequenceOnConflict>;
};

/** columns and relationships of "risksmart.consequence_audit" */
export type ConsequenceAudit = {
  __typename?: 'consequence_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CostType: Scalars['String']['output'];
  CostValue: Scalars['numeric']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  Type?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.consequence_audit" */
export type ConsequenceAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.consequence_audit" */
export type ConsequenceAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.consequence_audit" */
export type ConsequenceAuditAggregate = {
  __typename?: 'consequence_audit_aggregate';
  aggregate?: Maybe<ConsequenceAuditAggregateFields>;
  nodes: Array<ConsequenceAudit>;
};

/** aggregate fields of "risksmart.consequence_audit" */
export type ConsequenceAuditAggregateFields = {
  __typename?: 'consequence_audit_aggregate_fields';
  avg?: Maybe<ConsequenceAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ConsequenceAuditMaxFields>;
  min?: Maybe<ConsequenceAuditMinFields>;
  stddev?: Maybe<ConsequenceAuditStddevFields>;
  stddev_pop?: Maybe<ConsequenceAuditStddevPopFields>;
  stddev_samp?: Maybe<ConsequenceAuditStddevSampFields>;
  sum?: Maybe<ConsequenceAuditSumFields>;
  var_pop?: Maybe<ConsequenceAuditVarPopFields>;
  var_samp?: Maybe<ConsequenceAuditVarSampFields>;
  variance?: Maybe<ConsequenceAuditVarianceFields>;
};


/** aggregate fields of "risksmart.consequence_audit" */
export type ConsequenceAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsequenceAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ConsequenceAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ConsequenceAuditAvgFields = {
  __typename?: 'consequence_audit_avg_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.consequence_audit". All fields are combined with a logical 'AND'. */
export type ConsequenceAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CostType?: InputMaybe<StringComparisonExp>;
  CostValue?: InputMaybe<NumericComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Criticality?: InputMaybe<IntComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ConsequenceAuditBoolExp>>;
  _not?: InputMaybe<ConsequenceAuditBoolExp>;
  _or?: InputMaybe<Array<ConsequenceAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.consequence_audit" */
export enum ConsequenceAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ConsequenceAuditPkey = 'consequence_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ConsequenceAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ConsequenceAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ConsequenceAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.consequence_audit" */
export type ConsequenceAuditIncInput = {
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.consequence_audit" */
export type ConsequenceAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CostType?: InputMaybe<Scalars['String']['input']>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ConsequenceAuditMaxFields = {
  __typename?: 'consequence_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CostType?: Maybe<Scalars['String']['output']>;
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ConsequenceAuditMinFields = {
  __typename?: 'consequence_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CostType?: Maybe<Scalars['String']['output']>;
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.consequence_audit" */
export type ConsequenceAuditMutationResponse = {
  __typename?: 'consequence_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ConsequenceAudit>;
};

/** on_conflict condition type for table "risksmart.consequence_audit" */
export type ConsequenceAuditOnConflict = {
  constraint: ConsequenceAuditConstraint;
  update_columns?: Array<ConsequenceAuditUpdateColumn>;
  where?: InputMaybe<ConsequenceAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.consequence_audit". */
export type ConsequenceAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CostType?: InputMaybe<OrderBy>;
  CostValue?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.consequence_audit */
export type ConsequenceAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ConsequenceAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.consequence_audit" */
export enum ConsequenceAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CostType = 'CostType',
  /** column name */
  CostValue = 'CostValue',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.consequence_audit" */
export type ConsequenceAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CostType?: InputMaybe<Scalars['String']['input']>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ConsequenceAuditStddevFields = {
  __typename?: 'consequence_audit_stddev_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ConsequenceAuditStddevPopFields = {
  __typename?: 'consequence_audit_stddev_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ConsequenceAuditStddevSampFields = {
  __typename?: 'consequence_audit_stddev_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "consequence_audit" */
export type ConsequenceAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConsequenceAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsequenceAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CostType?: InputMaybe<Scalars['String']['input']>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ConsequenceAuditSumFields = {
  __typename?: 'consequence_audit_sum_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.consequence_audit" */
export enum ConsequenceAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CostType = 'CostType',
  /** column name */
  CostValue = 'CostValue',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ConsequenceAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ConsequenceAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ConsequenceAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ConsequenceAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ConsequenceAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsequenceAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ConsequenceAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsequenceAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ConsequenceAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ConsequenceAuditVarPopFields = {
  __typename?: 'consequence_audit_var_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ConsequenceAuditVarSampFields = {
  __typename?: 'consequence_audit_var_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ConsequenceAuditVarianceFields = {
  __typename?: 'consequence_audit_variance_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ConsequenceAvgFields = {
  __typename?: 'consequence_avg_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.consequence" */
export type ConsequenceAvgOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.consequence". All fields are combined with a logical 'AND'. */
export type ConsequenceBoolExp = {
  CostType?: InputMaybe<CostTypeEnumComparisonExp>;
  CostValue?: InputMaybe<NumericComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Criticality?: InputMaybe<IntComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<ConsequenceTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<ConsequenceBoolExp>>;
  _not?: InputMaybe<ConsequenceBoolExp>;
  _or?: InputMaybe<Array<ConsequenceBoolExp>>;
  costType?: InputMaybe<CostTypeBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.consequence" */
export enum ConsequenceConstraint {
  /** unique or primary key constraint on columns "Id" */
  ConsequencePkey = 'consequence_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ConsequenceDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ConsequenceDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ConsequenceDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.consequence" */
export type ConsequenceIncInput = {
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.consequence" */
export type ConsequenceInsertInput = {
  CostType?: InputMaybe<CostTypeEnum>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ConsequenceTypeEnum>;
  costType?: InputMaybe<CostTypeObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ConsequenceMaxFields = {
  __typename?: 'consequence_max_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.consequence" */
export type ConsequenceMaxOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ConsequenceMinFields = {
  __typename?: 'consequence_min_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.consequence" */
export type ConsequenceMinOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.consequence" */
export type ConsequenceMutationResponse = {
  __typename?: 'consequence_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Consequence>;
};

/** input type for inserting object relation for remote table "risksmart.consequence" */
export type ConsequenceObjRelInsertInput = {
  data: ConsequenceInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ConsequenceOnConflict>;
};

/** on_conflict condition type for table "risksmart.consequence" */
export type ConsequenceOnConflict = {
  constraint: ConsequenceConstraint;
  update_columns?: Array<ConsequenceUpdateColumn>;
  where?: InputMaybe<ConsequenceBoolExp>;
};

/** Ordering options when selecting data from "risksmart.consequence". */
export type ConsequenceOrderBy = {
  CostType?: InputMaybe<OrderBy>;
  CostValue?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  costType?: InputMaybe<CostTypeOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.consequence */
export type ConsequencePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ConsequencePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.consequence" */
export enum ConsequenceSelectColumn {
  /** column name */
  CostType = 'CostType',
  /** column name */
  CostValue = 'CostValue',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.consequence" */
export type ConsequenceSetInput = {
  CostType?: InputMaybe<CostTypeEnum>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ConsequenceTypeEnum>;
};

/** aggregate stddev on columns */
export type ConsequenceStddevFields = {
  __typename?: 'consequence_stddev_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.consequence" */
export type ConsequenceStddevOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ConsequenceStddevPopFields = {
  __typename?: 'consequence_stddev_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.consequence" */
export type ConsequenceStddevPopOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ConsequenceStddevSampFields = {
  __typename?: 'consequence_stddev_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.consequence" */
export type ConsequenceStddevSampOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "consequence" */
export type ConsequenceStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConsequenceStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsequenceStreamCursorValueInput = {
  CostType?: InputMaybe<CostTypeEnum>;
  CostValue?: InputMaybe<Scalars['numeric']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ConsequenceTypeEnum>;
};

/** aggregate sum on columns */
export type ConsequenceSumFields = {
  __typename?: 'consequence_sum_fields';
  CostValue?: Maybe<Scalars['numeric']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.consequence" */
export type ConsequenceSumOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.consequence_type" */
export type ConsequenceType = {
  __typename?: 'consequence_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.consequence_type" */
export type ConsequenceTypeAggregate = {
  __typename?: 'consequence_type_aggregate';
  aggregate?: Maybe<ConsequenceTypeAggregateFields>;
  nodes: Array<ConsequenceType>;
};

/** aggregate fields of "risksmart.consequence_type" */
export type ConsequenceTypeAggregateFields = {
  __typename?: 'consequence_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ConsequenceTypeMaxFields>;
  min?: Maybe<ConsequenceTypeMinFields>;
};


/** aggregate fields of "risksmart.consequence_type" */
export type ConsequenceTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConsequenceTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.consequence_type". All fields are combined with a logical 'AND'. */
export type ConsequenceTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ConsequenceTypeBoolExp>>;
  _not?: InputMaybe<ConsequenceTypeBoolExp>;
  _or?: InputMaybe<Array<ConsequenceTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.consequence_type" */
export enum ConsequenceTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ConsequenceTypePkey = 'consequence_type_pkey'
}

export enum ConsequenceTypeEnum {
  /** Customer */
  Customer = 'customer',
  /** Financial */
  Financial = 'financial',
  /** Legal & Regulatory */
  LegalAndRegulatory = 'legal_and_regulatory',
  /** Operational */
  Operational = 'operational',
  /** Reputational */
  Reputational = 'reputational'
}

/** Boolean expression to compare columns of type "consequence_type_enum". All fields are combined with logical 'AND'. */
export type ConsequenceTypeEnumComparisonExp = {
  _eq?: InputMaybe<ConsequenceTypeEnum>;
  _in?: InputMaybe<Array<ConsequenceTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ConsequenceTypeEnum>;
  _nin?: InputMaybe<Array<ConsequenceTypeEnum>>;
};

/** input type for inserting data into table "risksmart.consequence_type" */
export type ConsequenceTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ConsequenceTypeMaxFields = {
  __typename?: 'consequence_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ConsequenceTypeMinFields = {
  __typename?: 'consequence_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.consequence_type" */
export type ConsequenceTypeMutationResponse = {
  __typename?: 'consequence_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ConsequenceType>;
};

/** on_conflict condition type for table "risksmart.consequence_type" */
export type ConsequenceTypeOnConflict = {
  constraint: ConsequenceTypeConstraint;
  update_columns?: Array<ConsequenceTypeUpdateColumn>;
  where?: InputMaybe<ConsequenceTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.consequence_type". */
export type ConsequenceTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.consequence_type */
export type ConsequenceTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.consequence_type" */
export enum ConsequenceTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.consequence_type" */
export type ConsequenceTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "consequence_type" */
export type ConsequenceTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConsequenceTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConsequenceTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.consequence_type" */
export enum ConsequenceTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ConsequenceTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsequenceTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ConsequenceTypeBoolExp;
};

/** update columns of table "risksmart.consequence" */
export enum ConsequenceUpdateColumn {
  /** column name */
  CostType = 'CostType',
  /** column name */
  CostValue = 'CostValue',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ConsequenceUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ConsequenceAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ConsequenceDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ConsequenceDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ConsequenceDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ConsequenceIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ConsequencePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConsequenceSetInput>;
  /** filter the rows which have to be updated */
  where: ConsequenceBoolExp;
};

/** aggregate var_pop on columns */
export type ConsequenceVarPopFields = {
  __typename?: 'consequence_var_pop_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.consequence" */
export type ConsequenceVarPopOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ConsequenceVarSampFields = {
  __typename?: 'consequence_var_samp_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.consequence" */
export type ConsequenceVarSampOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ConsequenceVarianceFields = {
  __typename?: 'consequence_variance_fields';
  CostValue?: Maybe<Scalars['Float']['output']>;
  Criticality?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.consequence" */
export type ConsequenceVarianceOrderBy = {
  CostValue?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.contributor" */
export type Contributor = {
  __typename?: 'contributor';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  user?: Maybe<User>;
};


/** columns and relationships of "risksmart.contributor" */
export type ContributorPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.contributor" */
export type ContributorPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.contributor" */
export type ContributorAggregate = {
  __typename?: 'contributor_aggregate';
  aggregate?: Maybe<ContributorAggregateFields>;
  nodes: Array<Contributor>;
};

export type ContributorAggregateBoolExp = {
  count?: InputMaybe<ContributorAggregateBoolExpCount>;
};

export type ContributorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContributorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContributorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.contributor" */
export type ContributorAggregateFields = {
  __typename?: 'contributor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ContributorMaxFields>;
  min?: Maybe<ContributorMinFields>;
};


/** aggregate fields of "risksmart.contributor" */
export type ContributorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContributorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor" */
export type ContributorAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContributorMaxOrderBy>;
  min?: InputMaybe<ContributorMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.contributor" */
export type ContributorArrRelInsertInput = {
  data: Array<ContributorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContributorOnConflict>;
};

/** columns and relationships of "risksmart.contributor_audit" */
export type ContributorAudit = {
  __typename?: 'contributor_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.contributor_audit" */
export type ContributorAuditAggregate = {
  __typename?: 'contributor_audit_aggregate';
  aggregate?: Maybe<ContributorAuditAggregateFields>;
  nodes: Array<ContributorAudit>;
};

export type ContributorAuditAggregateBoolExp = {
  count?: InputMaybe<ContributorAuditAggregateBoolExpCount>;
};

export type ContributorAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContributorAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.contributor_audit" */
export type ContributorAuditAggregateFields = {
  __typename?: 'contributor_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ContributorAuditMaxFields>;
  min?: Maybe<ContributorAuditMinFields>;
};


/** aggregate fields of "risksmart.contributor_audit" */
export type ContributorAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor_audit" */
export type ContributorAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContributorAuditMaxOrderBy>;
  min?: InputMaybe<ContributorAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.contributor_audit" */
export type ContributorAuditArrRelInsertInput = {
  data: Array<ContributorAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContributorAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_audit". All fields are combined with a logical 'AND'. */
export type ContributorAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ContributorAuditBoolExp>>;
  _not?: InputMaybe<ContributorAuditBoolExp>;
  _or?: InputMaybe<Array<ContributorAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.contributor_audit" */
export enum ContributorAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "ModifiedAtTimestamp", "UserId" */
  ContributorAuditPkey = 'contributor_audit_pkey'
}

/** input type for inserting data into table "risksmart.contributor_audit" */
export type ContributorAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ContributorAuditMaxFields = {
  __typename?: 'contributor_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.contributor_audit" */
export type ContributorAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContributorAuditMinFields = {
  __typename?: 'contributor_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.contributor_audit" */
export type ContributorAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.contributor_audit" */
export type ContributorAuditMutationResponse = {
  __typename?: 'contributor_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ContributorAudit>;
};

/** on_conflict condition type for table "risksmart.contributor_audit" */
export type ContributorAuditOnConflict = {
  constraint: ContributorAuditConstraint;
  update_columns?: Array<ContributorAuditUpdateColumn>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.contributor_audit". */
export type ContributorAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.contributor_audit */
export type ContributorAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};

/** select columns of table "risksmart.contributor_audit" */
export enum ContributorAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.contributor_audit" */
export type ContributorAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contributor_audit" */
export type ContributorAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContributorAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContributorAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.contributor_audit" */
export enum ContributorAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

export type ContributorAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContributorAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ContributorAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.contributor". All fields are combined with a logical 'AND'. */
export type ContributorBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ContributorBoolExp>>;
  _not?: InputMaybe<ContributorBoolExp>;
  _or?: InputMaybe<Array<ContributorBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parentAction?: InputMaybe<ActionBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityBoolExp>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  parentIssue?: InputMaybe<IssueBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  user?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.contributor" */
export enum ContributorConstraint {
  /** unique or primary key constraint on columns "ParentId", "UserId" */
  ContributorPkey = 'contributor_pkey'
}

/** columns and relationships of "risksmart.contributor_group" */
export type ContributorGroup = {
  __typename?: 'contributor_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<UserGroup>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.contributor_group" */
export type ContributorGroupPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.contributor_group" */
export type ContributorGroupPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.contributor_group" */
export type ContributorGroupAggregate = {
  __typename?: 'contributor_group_aggregate';
  aggregate?: Maybe<ContributorGroupAggregateFields>;
  nodes: Array<ContributorGroup>;
};

export type ContributorGroupAggregateBoolExp = {
  count?: InputMaybe<ContributorGroupAggregateBoolExpCount>;
};

export type ContributorGroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContributorGroupBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.contributor_group" */
export type ContributorGroupAggregateFields = {
  __typename?: 'contributor_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ContributorGroupMaxFields>;
  min?: Maybe<ContributorGroupMinFields>;
};


/** aggregate fields of "risksmart.contributor_group" */
export type ContributorGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor_group" */
export type ContributorGroupAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContributorGroupMaxOrderBy>;
  min?: InputMaybe<ContributorGroupMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.contributor_group" */
export type ContributorGroupArrRelInsertInput = {
  data: Array<ContributorGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContributorGroupOnConflict>;
};

/** columns and relationships of "risksmart.contributor_group_audit" */
export type ContributorGroupAudit = {
  __typename?: 'contributor_group_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.contributor_group_audit" */
export type ContributorGroupAuditAggregate = {
  __typename?: 'contributor_group_audit_aggregate';
  aggregate?: Maybe<ContributorGroupAuditAggregateFields>;
  nodes: Array<ContributorGroupAudit>;
};

export type ContributorGroupAuditAggregateBoolExp = {
  count?: InputMaybe<ContributorGroupAuditAggregateBoolExpCount>;
};

export type ContributorGroupAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ContributorGroupAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.contributor_group_audit" */
export type ContributorGroupAuditAggregateFields = {
  __typename?: 'contributor_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ContributorGroupAuditMaxFields>;
  min?: Maybe<ContributorGroupAuditMinFields>;
};


/** aggregate fields of "risksmart.contributor_group_audit" */
export type ContributorGroupAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ContributorGroupAuditMaxOrderBy>;
  min?: InputMaybe<ContributorGroupAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditArrRelInsertInput = {
  data: Array<ContributorGroupAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ContributorGroupAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_group_audit". All fields are combined with a logical 'AND'. */
export type ContributorGroupAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ContributorGroupAuditBoolExp>>;
  _not?: InputMaybe<ContributorGroupAuditBoolExp>;
  _or?: InputMaybe<Array<ContributorGroupAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.contributor_group_audit" */
export enum ContributorGroupAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "UserGroupId", "ModifiedAtTimestamp" */
  ContributorGroupAuditPkey = 'contributor_group_audit_pkey'
}

/** input type for inserting data into table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ContributorGroupAuditMaxFields = {
  __typename?: 'contributor_group_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContributorGroupAuditMinFields = {
  __typename?: 'contributor_group_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditMutationResponse = {
  __typename?: 'contributor_group_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ContributorGroupAudit>;
};

/** on_conflict condition type for table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditOnConflict = {
  constraint: ContributorGroupAuditConstraint;
  update_columns?: Array<ContributorGroupAuditUpdateColumn>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.contributor_group_audit". */
export type ContributorGroupAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.contributor_group_audit */
export type ContributorGroupAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.contributor_group_audit" */
export enum ContributorGroupAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

/** input type for updating data in table "risksmart.contributor_group_audit" */
export type ContributorGroupAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "contributor_group_audit" */
export type ContributorGroupAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContributorGroupAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContributorGroupAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.contributor_group_audit" */
export enum ContributorGroupAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

export type ContributorGroupAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContributorGroupAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ContributorGroupAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_group". All fields are combined with a logical 'AND'. */
export type ContributorGroupBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ContributorGroupBoolExp>>;
  _not?: InputMaybe<ContributorGroupBoolExp>;
  _or?: InputMaybe<Array<ContributorGroupBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  group?: InputMaybe<UserGroupBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parentAction?: InputMaybe<ActionBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityBoolExp>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  parentIssue?: InputMaybe<IssueBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.contributor_group" */
export enum ContributorGroupConstraint {
  /** unique or primary key constraint on columns "ParentId", "UserGroupId" */
  ContributorGroupPkey = 'contributor_group_pkey'
}

/** input type for inserting data into table "risksmart.contributor_group" */
export type ContributorGroupInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  group?: InputMaybe<UserGroupObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parentAction?: InputMaybe<ActionObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  parentIssue?: InputMaybe<IssueObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ContributorGroupMaxFields = {
  __typename?: 'contributor_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.contributor_group" */
export type ContributorGroupMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContributorGroupMinFields = {
  __typename?: 'contributor_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.contributor_group" */
export type ContributorGroupMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.contributor_group" */
export type ContributorGroupMutationResponse = {
  __typename?: 'contributor_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ContributorGroup>;
};

/** on_conflict condition type for table "risksmart.contributor_group" */
export type ContributorGroupOnConflict = {
  constraint: ContributorGroupConstraint;
  update_columns?: Array<ContributorGroupUpdateColumn>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.contributor_group". */
export type ContributorGroupOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  group?: InputMaybe<UserGroupOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parentAction?: InputMaybe<ActionOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityOrderBy>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  parentIssue?: InputMaybe<IssueOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.contributor_group */
export type ContributorGroupPkColumnsInput = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.contributor_group" */
export enum ContributorGroupSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

/** input type for updating data in table "risksmart.contributor_group" */
export type ContributorGroupSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "contributor_group" */
export type ContributorGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContributorGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContributorGroupStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.contributor_group" */
export enum ContributorGroupUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

export type ContributorGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContributorGroupSetInput>;
  /** filter the rows which have to be updated */
  where: ContributorGroupBoolExp;
};

/** input type for inserting data into table "risksmart.contributor" */
export type ContributorInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parentAction?: InputMaybe<ActionObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  parentIssue?: InputMaybe<IssueObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type ContributorMaxFields = {
  __typename?: 'contributor_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.contributor" */
export type ContributorMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ContributorMinFields = {
  __typename?: 'contributor_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.contributor" */
export type ContributorMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.contributor" */
export type ContributorMutationResponse = {
  __typename?: 'contributor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Contributor>;
};

/** on_conflict condition type for table "risksmart.contributor" */
export type ContributorOnConflict = {
  constraint: ContributorConstraint;
  update_columns?: Array<ContributorUpdateColumn>;
  where?: InputMaybe<ContributorBoolExp>;
};

/** Ordering options when selecting data from "risksmart.contributor". */
export type ContributorOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parentAction?: InputMaybe<ActionOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityOrderBy>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  parentIssue?: InputMaybe<IssueOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  user?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.contributor */
export type ContributorPkColumnsInput = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};

/** select columns of table "risksmart.contributor" */
export enum ContributorSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.contributor" */
export type ContributorSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contributor" */
export type ContributorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContributorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContributorStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.contributor_type" */
export type ContributorType = {
  __typename?: 'contributor_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.contributor_type" */
export type ContributorTypeAggregate = {
  __typename?: 'contributor_type_aggregate';
  aggregate?: Maybe<ContributorTypeAggregateFields>;
  nodes: Array<ContributorType>;
};

/** aggregate fields of "risksmart.contributor_type" */
export type ContributorTypeAggregateFields = {
  __typename?: 'contributor_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ContributorTypeMaxFields>;
  min?: Maybe<ContributorTypeMinFields>;
};


/** aggregate fields of "risksmart.contributor_type" */
export type ContributorTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ContributorTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_type". All fields are combined with a logical 'AND'. */
export type ContributorTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ContributorTypeBoolExp>>;
  _not?: InputMaybe<ContributorTypeBoolExp>;
  _or?: InputMaybe<Array<ContributorTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.contributor_type" */
export enum ContributorTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ContributorTypePkey = 'contributor_type_pkey'
}

export enum ContributorTypeEnum {
  /** Any */
  Any = 'any',
  /** Contributor */
  Contributor = 'contributor',
  /** Owner */
  Owner = 'owner'
}

/** Boolean expression to compare columns of type "contributor_type_enum". All fields are combined with logical 'AND'. */
export type ContributorTypeEnumComparisonExp = {
  _eq?: InputMaybe<ContributorTypeEnum>;
  _in?: InputMaybe<Array<ContributorTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ContributorTypeEnum>;
  _nin?: InputMaybe<Array<ContributorTypeEnum>>;
};

/** input type for inserting data into table "risksmart.contributor_type" */
export type ContributorTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ContributorTypeMaxFields = {
  __typename?: 'contributor_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ContributorTypeMinFields = {
  __typename?: 'contributor_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.contributor_type" */
export type ContributorTypeMutationResponse = {
  __typename?: 'contributor_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ContributorType>;
};

/** on_conflict condition type for table "risksmart.contributor_type" */
export type ContributorTypeOnConflict = {
  constraint: ContributorTypeConstraint;
  update_columns?: Array<ContributorTypeUpdateColumn>;
  where?: InputMaybe<ContributorTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.contributor_type". */
export type ContributorTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.contributor_type */
export type ContributorTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.contributor_type" */
export enum ContributorTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.contributor_type" */
export type ContributorTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "contributor_type" */
export type ContributorTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ContributorTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ContributorTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.contributor_type" */
export enum ContributorTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ContributorTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContributorTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ContributorTypeBoolExp;
};

/** update columns of table "risksmart.contributor" */
export enum ContributorUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

export type ContributorUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ContributorSetInput>;
  /** filter the rows which have to be updated */
  where: ContributorBoolExp;
};

/** columns and relationships of "risksmart.control" */
export type Control = {
  __typename?: 'control';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<ControlTestFrequencyEnum>;
  Title: Scalars['String']['output'];
  Type: ControlTypeEnum;
  /** An array relationship */
  actions: Array<ActionParent>;
  /** An aggregate relationship */
  actions_aggregate: ActionParentAggregate;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  indicators: Array<IndicatorParent>;
  /** An aggregate relationship */
  indicators_aggregate: IndicatorParentAggregate;
  /** An array relationship */
  issues: Array<IssueParent>;
  /** An aggregate relationship */
  issues_aggregate: IssueParentAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  parents: Array<ControlParent>;
  /** An aggregate relationship */
  parents_aggregate: ControlParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An object relationship */
  testFrequency?: Maybe<ControlTestFrequency>;
  /** An array relationship */
  testResults: Array<TestResult>;
  /** An aggregate relationship */
  testResults_aggregate: TestResultAggregate;
};


/** columns and relationships of "risksmart.control" */
export type ControlCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control" */
export type ControlMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control" */
export type ControlActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIndicatorsArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIndicatorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlParentsArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTestResultsArgs = {
  distinct_on?: InputMaybe<Array<TestResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultOrderBy>>;
  where?: InputMaybe<TestResultBoolExp>;
};


/** columns and relationships of "risksmart.control" */
export type ControlTestResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultOrderBy>>;
  where?: InputMaybe<TestResultBoolExp>;
};

/** aggregated selection of "risksmart.control" */
export type ControlAggregate = {
  __typename?: 'control_aggregate';
  aggregate?: Maybe<ControlAggregateFields>;
  nodes: Array<Control>;
};

/** aggregate fields of "risksmart.control" */
export type ControlAggregateFields = {
  __typename?: 'control_aggregate_fields';
  avg?: Maybe<ControlAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ControlMaxFields>;
  min?: Maybe<ControlMinFields>;
  stddev?: Maybe<ControlStddevFields>;
  stddev_pop?: Maybe<ControlStddevPopFields>;
  stddev_samp?: Maybe<ControlStddevSampFields>;
  sum?: Maybe<ControlSumFields>;
  var_pop?: Maybe<ControlVarPopFields>;
  var_samp?: Maybe<ControlVarSampFields>;
  variance?: Maybe<ControlVarianceFields>;
};


/** aggregate fields of "risksmart.control" */
export type ControlAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ControlAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.control_audit" */
export type ControlAudit = {
  __typename?: 'control_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  GroupId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
  /** An array relationship */
  actionAudits: Array<ActionParentAudit>;
  /** An aggregate relationship */
  actionAudits_aggregate: ActionParentAuditAggregate;
  /** An array relationship */
  contributorAudits: Array<ContributorAudit>;
  /** An aggregate relationship */
  contributorAudits_aggregate: ContributorAuditAggregate;
  /** An array relationship */
  contributorGroupAudits: Array<ContributorGroupAudit>;
  /** An aggregate relationship */
  contributorGroupAudits_aggregate: ContributorGroupAuditAggregate;
  /** An array relationship */
  departmentAudits: Array<DepartmentAudit>;
  /** An aggregate relationship */
  departmentAudits_aggregate: DepartmentAuditAggregate;
  /** An array relationship */
  indicatorAudits: Array<RisksmartIndicatorParentAudit>;
  /** An aggregate relationship */
  indicatorAudits_aggregate: RisksmartIndicatorParentAuditAggregate;
  /** An array relationship */
  issueAudits: Array<IssueParentAudit>;
  /** An aggregate relationship */
  issueAudits_aggregate: IssueParentAuditAggregate;
  /** An array relationship */
  ownerAudits: Array<OwnerAudit>;
  /** An aggregate relationship */
  ownerAudits_aggregate: OwnerAuditAggregate;
  /** An array relationship */
  ownerGroupAudits: Array<OwnerGroupAudit>;
  /** An aggregate relationship */
  ownerGroupAudits_aggregate: OwnerGroupAuditAggregate;
  /** An array relationship */
  tagAudits: Array<TagAudit>;
  /** An aggregate relationship */
  tagAudits_aggregate: TagAuditAggregate;
  /** An array relationship */
  testResultAudits: Array<TestResultAudit>;
  /** An aggregate relationship */
  testResultAudits_aggregate: TestResultAuditAggregate;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditActionAuditsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditActionAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditContributorAuditsArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditContributorAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditContributorGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditContributorGroupAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditDepartmentAuditsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditDepartmentAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditIndicatorAuditsArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditIndicatorAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditIssueAuditsArgs = {
  distinct_on?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentAuditOrderBy>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditIssueAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentAuditOrderBy>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditOwnerAuditsArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditOwnerAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditOwnerGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditOwnerGroupAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditTagAuditsArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditTagAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditTestResultAuditsArgs = {
  distinct_on?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultAuditOrderBy>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};


/** columns and relationships of "risksmart.control_audit" */
export type ControlAuditTestResultAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultAuditOrderBy>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};

/** aggregated selection of "risksmart.control_audit" */
export type ControlAuditAggregate = {
  __typename?: 'control_audit_aggregate';
  aggregate?: Maybe<ControlAuditAggregateFields>;
  nodes: Array<ControlAudit>;
};

/** aggregate fields of "risksmart.control_audit" */
export type ControlAuditAggregateFields = {
  __typename?: 'control_audit_aggregate_fields';
  avg?: Maybe<ControlAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ControlAuditMaxFields>;
  min?: Maybe<ControlAuditMinFields>;
  stddev?: Maybe<ControlAuditStddevFields>;
  stddev_pop?: Maybe<ControlAuditStddevPopFields>;
  stddev_samp?: Maybe<ControlAuditStddevSampFields>;
  sum?: Maybe<ControlAuditSumFields>;
  var_pop?: Maybe<ControlAuditVarPopFields>;
  var_samp?: Maybe<ControlAuditVarSampFields>;
  variance?: Maybe<ControlAuditVarianceFields>;
};


/** aggregate fields of "risksmart.control_audit" */
export type ControlAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ControlAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ControlAuditAvgFields = {
  __typename?: 'control_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_audit". All fields are combined with a logical 'AND'. */
export type ControlAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  GroupId?: InputMaybe<UuidComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentObligationId?: InputMaybe<UuidComparisonExp>;
  ParentRiskId?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestFrequency?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ControlAuditBoolExp>>;
  _not?: InputMaybe<ControlAuditBoolExp>;
  _or?: InputMaybe<Array<ControlAuditBoolExp>>;
  actionAudits?: InputMaybe<ActionParentAuditBoolExp>;
  actionAudits_aggregate?: InputMaybe<ActionParentAuditAggregateBoolExp>;
  contributorAudits?: InputMaybe<ContributorAuditBoolExp>;
  contributorAudits_aggregate?: InputMaybe<ContributorAuditAggregateBoolExp>;
  contributorGroupAudits?: InputMaybe<ContributorGroupAuditBoolExp>;
  contributorGroupAudits_aggregate?: InputMaybe<ContributorGroupAuditAggregateBoolExp>;
  departmentAudits?: InputMaybe<DepartmentAuditBoolExp>;
  departmentAudits_aggregate?: InputMaybe<DepartmentAuditAggregateBoolExp>;
  indicatorAudits?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
  indicatorAudits_aggregate?: InputMaybe<RisksmartIndicatorParentAuditAggregateBoolExp>;
  issueAudits?: InputMaybe<IssueParentAuditBoolExp>;
  issueAudits_aggregate?: InputMaybe<IssueParentAuditAggregateBoolExp>;
  ownerAudits?: InputMaybe<OwnerAuditBoolExp>;
  ownerAudits_aggregate?: InputMaybe<OwnerAuditAggregateBoolExp>;
  ownerGroupAudits?: InputMaybe<OwnerGroupAuditBoolExp>;
  ownerGroupAudits_aggregate?: InputMaybe<OwnerGroupAuditAggregateBoolExp>;
  tagAudits?: InputMaybe<TagAuditBoolExp>;
  tagAudits_aggregate?: InputMaybe<TagAuditAggregateBoolExp>;
  testResultAudits?: InputMaybe<TestResultAuditBoolExp>;
  testResultAudits_aggregate?: InputMaybe<TestResultAuditAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.control_audit" */
export enum ControlAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ControlAuditPkey = 'control_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ControlAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ControlAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ControlAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.control_audit" */
export type ControlAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.control_audit" */
export type ControlAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  actionAudits?: InputMaybe<ActionParentAuditArrRelInsertInput>;
  contributorAudits?: InputMaybe<ContributorAuditArrRelInsertInput>;
  contributorGroupAudits?: InputMaybe<ContributorGroupAuditArrRelInsertInput>;
  departmentAudits?: InputMaybe<DepartmentAuditArrRelInsertInput>;
  indicatorAudits?: InputMaybe<RisksmartIndicatorParentAuditArrRelInsertInput>;
  issueAudits?: InputMaybe<IssueParentAuditArrRelInsertInput>;
  ownerAudits?: InputMaybe<OwnerAuditArrRelInsertInput>;
  ownerGroupAudits?: InputMaybe<OwnerGroupAuditArrRelInsertInput>;
  tagAudits?: InputMaybe<TagAuditArrRelInsertInput>;
  testResultAudits?: InputMaybe<TestResultAuditArrRelInsertInput>;
};

/** aggregate max on columns */
export type ControlAuditMaxFields = {
  __typename?: 'control_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  GroupId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ControlAuditMinFields = {
  __typename?: 'control_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  GroupId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_audit" */
export type ControlAuditMutationResponse = {
  __typename?: 'control_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlAudit>;
};

/** on_conflict condition type for table "risksmart.control_audit" */
export type ControlAuditOnConflict = {
  constraint: ControlAuditConstraint;
  update_columns?: Array<ControlAuditUpdateColumn>;
  where?: InputMaybe<ControlAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_audit". */
export type ControlAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  GroupId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentObligationId?: InputMaybe<OrderBy>;
  ParentRiskId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  actionAudits_aggregate?: InputMaybe<ActionParentAuditAggregateOrderBy>;
  contributorAudits_aggregate?: InputMaybe<ContributorAuditAggregateOrderBy>;
  contributorGroupAudits_aggregate?: InputMaybe<ContributorGroupAuditAggregateOrderBy>;
  departmentAudits_aggregate?: InputMaybe<DepartmentAuditAggregateOrderBy>;
  indicatorAudits_aggregate?: InputMaybe<RisksmartIndicatorParentAuditAggregateOrderBy>;
  issueAudits_aggregate?: InputMaybe<IssueParentAuditAggregateOrderBy>;
  ownerAudits_aggregate?: InputMaybe<OwnerAuditAggregateOrderBy>;
  ownerGroupAudits_aggregate?: InputMaybe<OwnerGroupAuditAggregateOrderBy>;
  tagAudits_aggregate?: InputMaybe<TagAuditAggregateOrderBy>;
  testResultAudits_aggregate?: InputMaybe<TestResultAuditAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.control_audit */
export type ControlAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ControlAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.control_audit" */
export enum ControlAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentObligationId = 'ParentObligationId',
  /** column name */
  ParentRiskId = 'ParentRiskId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.control_audit" */
export type ControlAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ControlAuditStddevFields = {
  __typename?: 'control_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ControlAuditStddevPopFields = {
  __typename?: 'control_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ControlAuditStddevSampFields = {
  __typename?: 'control_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "control_audit" */
export type ControlAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  GroupId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ControlAuditSumFields = {
  __typename?: 'control_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.control_audit" */
export enum ControlAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  GroupId = 'GroupId',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentObligationId = 'ParentObligationId',
  /** column name */
  ParentRiskId = 'ParentRiskId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ControlAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ControlAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ControlAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ControlAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ControlAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ControlAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ControlAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ControlAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ControlAuditVarPopFields = {
  __typename?: 'control_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ControlAuditVarSampFields = {
  __typename?: 'control_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ControlAuditVarianceFields = {
  __typename?: 'control_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ControlAvgFields = {
  __typename?: 'control_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.control". All fields are combined with a logical 'AND'. */
export type ControlBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestFrequency?: InputMaybe<ControlTestFrequencyEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<ControlTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<ControlBoolExp>>;
  _not?: InputMaybe<ControlBoolExp>;
  _or?: InputMaybe<Array<ControlBoolExp>>;
  actions?: InputMaybe<ActionParentBoolExp>;
  actions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  indicators?: InputMaybe<IndicatorParentBoolExp>;
  indicators_aggregate?: InputMaybe<IndicatorParentAggregateBoolExp>;
  issues?: InputMaybe<IssueParentBoolExp>;
  issues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parents?: InputMaybe<ControlParentBoolExp>;
  parents_aggregate?: InputMaybe<ControlParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  testFrequency?: InputMaybe<ControlTestFrequencyBoolExp>;
  testResults?: InputMaybe<TestResultBoolExp>;
  testResults_aggregate?: InputMaybe<TestResultAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.control" */
export enum ControlConstraint {
  /** unique or primary key constraint on columns "Id" */
  ControlPkey = 'control_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxControlOrgkeySequentialid = 'idx_control_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ControlDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ControlDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ControlDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.control_group" */
export type ControlGroup = {
  __typename?: 'control_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Owner: Scalars['String']['output'];
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  controls: Array<ControlParent>;
  /** An aggregate relationship */
  controls_aggregate: ControlParentAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  owner?: Maybe<User>;
};


/** columns and relationships of "risksmart.control_group" */
export type ControlGroupCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_group" */
export type ControlGroupMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_group" */
export type ControlGroupAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.control_group" */
export type ControlGroupAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.control_group" */
export type ControlGroupControlsArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.control_group" */
export type ControlGroupControlsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};

/** aggregated selection of "risksmart.control_group" */
export type ControlGroupAggregate = {
  __typename?: 'control_group_aggregate';
  aggregate?: Maybe<ControlGroupAggregateFields>;
  nodes: Array<ControlGroup>;
};

/** aggregate fields of "risksmart.control_group" */
export type ControlGroupAggregateFields = {
  __typename?: 'control_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ControlGroupMaxFields>;
  min?: Maybe<ControlGroupMinFields>;
};


/** aggregate fields of "risksmart.control_group" */
export type ControlGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ControlGroupAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.control_group_audit" */
export type ControlGroupAudit = {
  __typename?: 'control_group_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  Owner: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.control_group_audit" */
export type ControlGroupAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.control_group_audit" */
export type ControlGroupAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.control_group_audit" */
export type ControlGroupAuditAggregate = {
  __typename?: 'control_group_audit_aggregate';
  aggregate?: Maybe<ControlGroupAuditAggregateFields>;
  nodes: Array<ControlGroupAudit>;
};

/** aggregate fields of "risksmart.control_group_audit" */
export type ControlGroupAuditAggregateFields = {
  __typename?: 'control_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ControlGroupAuditMaxFields>;
  min?: Maybe<ControlGroupAuditMinFields>;
};


/** aggregate fields of "risksmart.control_group_audit" */
export type ControlGroupAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ControlGroupAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_group_audit". All fields are combined with a logical 'AND'. */
export type ControlGroupAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Owner?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ControlGroupAuditBoolExp>>;
  _not?: InputMaybe<ControlGroupAuditBoolExp>;
  _or?: InputMaybe<Array<ControlGroupAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.control_group_audit" */
export enum ControlGroupAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ControlGroupAuditPkey = 'control_group_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ControlGroupAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ControlGroupAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ControlGroupAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.control_group_audit" */
export type ControlGroupAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ControlGroupAuditMaxFields = {
  __typename?: 'control_group_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ControlGroupAuditMinFields = {
  __typename?: 'control_group_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_group_audit" */
export type ControlGroupAuditMutationResponse = {
  __typename?: 'control_group_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlGroupAudit>;
};

/** on_conflict condition type for table "risksmart.control_group_audit" */
export type ControlGroupAuditOnConflict = {
  constraint: ControlGroupAuditConstraint;
  update_columns?: Array<ControlGroupAuditUpdateColumn>;
  where?: InputMaybe<ControlGroupAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_group_audit". */
export type ControlGroupAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Owner?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.control_group_audit */
export type ControlGroupAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ControlGroupAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.control_group_audit" */
export enum ControlGroupAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Owner = 'Owner',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.control_group_audit" */
export type ControlGroupAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_group_audit" */
export type ControlGroupAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlGroupAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlGroupAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.control_group_audit" */
export enum ControlGroupAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Owner = 'Owner',
  /** column name */
  Title = 'Title'
}

export type ControlGroupAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ControlGroupAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ControlGroupAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ControlGroupAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ControlGroupAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ControlGroupAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlGroupAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ControlGroupAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.control_group". All fields are combined with a logical 'AND'. */
export type ControlGroupBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Owner?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ControlGroupBoolExp>>;
  _not?: InputMaybe<ControlGroupBoolExp>;
  _or?: InputMaybe<Array<ControlGroupBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  controls?: InputMaybe<ControlParentBoolExp>;
  controls_aggregate?: InputMaybe<ControlParentAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  owner?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.control_group" */
export enum ControlGroupConstraint {
  /** unique or primary key constraint on columns "Id" */
  ControlGroupPkey = 'control_group_pkey',
  /** unique or primary key constraint on columns "OrgKey", "Title" */
  IxControlGroupOrgkeyTitle = 'ix_control_group_orgkey_title'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ControlGroupDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ControlGroupDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ControlGroupDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.control_group" */
export type ControlGroupInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  controls?: InputMaybe<ControlParentArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  owner?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type ControlGroupMaxFields = {
  __typename?: 'control_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ControlGroupMinFields = {
  __typename?: 'control_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Owner?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_group" */
export type ControlGroupMutationResponse = {
  __typename?: 'control_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlGroup>;
};

/** input type for inserting object relation for remote table "risksmart.control_group" */
export type ControlGroupObjRelInsertInput = {
  data: ControlGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ControlGroupOnConflict>;
};

/** on_conflict condition type for table "risksmart.control_group" */
export type ControlGroupOnConflict = {
  constraint: ControlGroupConstraint;
  update_columns?: Array<ControlGroupUpdateColumn>;
  where?: InputMaybe<ControlGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_group". */
export type ControlGroupOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Owner?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  controls_aggregate?: InputMaybe<ControlParentAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  owner?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.control_group */
export type ControlGroupPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ControlGroupPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.control_group" */
export enum ControlGroupSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Owner = 'Owner',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.control_group" */
export type ControlGroupSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_group" */
export type ControlGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlGroupStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Owner?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.control_group" */
export enum ControlGroupUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Owner = 'Owner',
  /** column name */
  Title = 'Title'
}

export type ControlGroupUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ControlGroupAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ControlGroupDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ControlGroupDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ControlGroupDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ControlGroupPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlGroupSetInput>;
  /** filter the rows which have to be updated */
  where: ControlGroupBoolExp;
};

/** input type for incrementing numeric columns in table "risksmart.control" */
export type ControlIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.control" */
export type ControlInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<ControlTestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ControlTypeEnum>;
  actions?: InputMaybe<ActionParentArrRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  indicators?: InputMaybe<IndicatorParentArrRelInsertInput>;
  issues?: InputMaybe<IssueParentArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parents?: InputMaybe<ControlParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  testFrequency?: InputMaybe<ControlTestFrequencyObjRelInsertInput>;
  testResults?: InputMaybe<TestResultArrRelInsertInput>;
};

/** aggregate max on columns */
export type ControlMaxFields = {
  __typename?: 'control_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ControlMinFields = {
  __typename?: 'control_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control" */
export type ControlMutationResponse = {
  __typename?: 'control_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control>;
};

/** input type for inserting object relation for remote table "risksmart.control" */
export type ControlObjRelInsertInput = {
  data: ControlInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ControlOnConflict>;
};

/** on_conflict condition type for table "risksmart.control" */
export type ControlOnConflict = {
  constraint: ControlConstraint;
  update_columns?: Array<ControlUpdateColumn>;
  where?: InputMaybe<ControlBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control". */
export type ControlOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  actions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  indicators_aggregate?: InputMaybe<IndicatorParentAggregateOrderBy>;
  issues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parents_aggregate?: InputMaybe<ControlParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  testFrequency?: InputMaybe<ControlTestFrequencyOrderBy>;
  testResults_aggregate?: InputMaybe<TestResultAggregateOrderBy>;
};

/** columns and relationships of "risksmart.control_parent" */
export type ControlParent = {
  __typename?: 'control_parent';
  ControlId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<ControlGroup>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.control_parent" */
export type ControlParentAggregate = {
  __typename?: 'control_parent_aggregate';
  aggregate?: Maybe<ControlParentAggregateFields>;
  nodes: Array<ControlParent>;
};

export type ControlParentAggregateBoolExp = {
  count?: InputMaybe<ControlParentAggregateBoolExpCount>;
};

export type ControlParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ControlParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ControlParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.control_parent" */
export type ControlParentAggregateFields = {
  __typename?: 'control_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ControlParentMaxFields>;
  min?: Maybe<ControlParentMinFields>;
};


/** aggregate fields of "risksmart.control_parent" */
export type ControlParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.control_parent" */
export type ControlParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ControlParentMaxOrderBy>;
  min?: InputMaybe<ControlParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.control_parent" */
export type ControlParentArrRelInsertInput = {
  data: Array<ControlParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ControlParentOnConflict>;
};

/** columns and relationships of "risksmart.control_parent_audit" */
export type ControlParentAudit = {
  __typename?: 'control_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ControlId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.control_parent_audit" */
export type ControlParentAuditAggregate = {
  __typename?: 'control_parent_audit_aggregate';
  aggregate?: Maybe<ControlParentAuditAggregateFields>;
  nodes: Array<ControlParentAudit>;
};

export type ControlParentAuditAggregateBoolExp = {
  count?: InputMaybe<ControlParentAuditAggregateBoolExpCount>;
};

export type ControlParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ControlParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.control_parent_audit" */
export type ControlParentAuditAggregateFields = {
  __typename?: 'control_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ControlParentAuditMaxFields>;
  min?: Maybe<ControlParentAuditMinFields>;
};


/** aggregate fields of "risksmart.control_parent_audit" */
export type ControlParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.control_parent_audit" */
export type ControlParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ControlParentAuditMaxOrderBy>;
  min?: InputMaybe<ControlParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.control_parent_audit" */
export type ControlParentAuditArrRelInsertInput = {
  data: Array<ControlParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ControlParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.control_parent_audit". All fields are combined with a logical 'AND'. */
export type ControlParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ControlId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ControlParentAuditBoolExp>>;
  _not?: InputMaybe<ControlParentAuditBoolExp>;
  _or?: InputMaybe<Array<ControlParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.control_parent_audit" */
export enum ControlParentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "ControlId", "ModifiedAtTimestamp" */
  ControlParentAuditPkey = 'control_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.control_parent_audit" */
export type ControlParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ControlParentAuditMaxFields = {
  __typename?: 'control_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.control_parent_audit" */
export type ControlParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ControlParentAuditMinFields = {
  __typename?: 'control_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.control_parent_audit" */
export type ControlParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.control_parent_audit" */
export type ControlParentAuditMutationResponse = {
  __typename?: 'control_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlParentAudit>;
};

/** on_conflict condition type for table "risksmart.control_parent_audit" */
export type ControlParentAuditOnConflict = {
  constraint: ControlParentAuditConstraint;
  update_columns?: Array<ControlParentAuditUpdateColumn>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_parent_audit". */
export type ControlParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.control_parent_audit */
export type ControlParentAuditPkColumnsInput = {
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.control_parent_audit" */
export enum ControlParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ControlId = 'ControlId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.control_parent_audit" */
export type ControlParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "control_parent_audit" */
export type ControlParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.control_parent_audit" */
export enum ControlParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ControlId = 'ControlId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type ControlParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ControlParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.control_parent". All fields are combined with a logical 'AND'. */
export type ControlParentBoolExp = {
  ControlId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ControlParentBoolExp>>;
  _not?: InputMaybe<ControlParentBoolExp>;
  _or?: InputMaybe<Array<ControlParentBoolExp>>;
  control?: InputMaybe<ControlBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  group?: InputMaybe<ControlGroupBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  obligation?: InputMaybe<ObligationBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.control_parent" */
export enum ControlParentConstraint {
  /** unique or primary key constraint on columns "ParentId", "ControlId" */
  ControlParentPkey = 'control_parent_pkey'
}

/** input type for inserting data into table "risksmart.control_parent" */
export type ControlParentInsertInput = {
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<ControlObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  group?: InputMaybe<ControlGroupObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  obligation?: InputMaybe<ObligationObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type ControlParentMaxFields = {
  __typename?: 'control_parent_max_fields';
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.control_parent" */
export type ControlParentMaxOrderBy = {
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ControlParentMinFields = {
  __typename?: 'control_parent_min_fields';
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.control_parent" */
export type ControlParentMinOrderBy = {
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.control_parent" */
export type ControlParentMutationResponse = {
  __typename?: 'control_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlParent>;
};

/** on_conflict condition type for table "risksmart.control_parent" */
export type ControlParentOnConflict = {
  constraint: ControlParentConstraint;
  update_columns?: Array<ControlParentUpdateColumn>;
  where?: InputMaybe<ControlParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_parent". */
export type ControlParentOrderBy = {
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  control?: InputMaybe<ControlOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  group?: InputMaybe<ControlGroupOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  obligation?: InputMaybe<ObligationOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.control_parent */
export type ControlParentPkColumnsInput = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.control_parent" */
export enum ControlParentSelectColumn {
  /** column name */
  ControlId = 'ControlId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.control_parent" */
export type ControlParentSetInput = {
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "control_parent" */
export type ControlParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlParentStreamCursorValueInput = {
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.control_parent" */
export enum ControlParentUpdateColumn {
  /** column name */
  ControlId = 'ControlId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type ControlParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlParentSetInput>;
  /** filter the rows which have to be updated */
  where: ControlParentBoolExp;
};

/** primary key columns input for table: risksmart.control */
export type ControlPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ControlPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.control" */
export enum ControlSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.control" */
export type ControlSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<ControlTestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ControlTypeEnum>;
};

/** aggregate stddev on columns */
export type ControlStddevFields = {
  __typename?: 'control_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ControlStddevPopFields = {
  __typename?: 'control_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ControlStddevSampFields = {
  __typename?: 'control_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "control" */
export type ControlStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<ControlTestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ControlTypeEnum>;
};

/** aggregate sum on columns */
export type ControlSumFields = {
  __typename?: 'control_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.control_test_frequency" */
export type ControlTestFrequency = {
  __typename?: 'control_test_frequency';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.control_test_frequency" */
export type ControlTestFrequencyAggregate = {
  __typename?: 'control_test_frequency_aggregate';
  aggregate?: Maybe<ControlTestFrequencyAggregateFields>;
  nodes: Array<ControlTestFrequency>;
};

/** aggregate fields of "risksmart.control_test_frequency" */
export type ControlTestFrequencyAggregateFields = {
  __typename?: 'control_test_frequency_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ControlTestFrequencyMaxFields>;
  min?: Maybe<ControlTestFrequencyMinFields>;
};


/** aggregate fields of "risksmart.control_test_frequency" */
export type ControlTestFrequencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlTestFrequencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_test_frequency". All fields are combined with a logical 'AND'. */
export type ControlTestFrequencyBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ControlTestFrequencyBoolExp>>;
  _not?: InputMaybe<ControlTestFrequencyBoolExp>;
  _or?: InputMaybe<Array<ControlTestFrequencyBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.control_test_frequency" */
export enum ControlTestFrequencyConstraint {
  /** unique or primary key constraint on columns "Value" */
  ControlTestFrequencyPkey = 'control_test_frequency_pkey'
}

export enum ControlTestFrequencyEnum {
  /** Ad Hoc */
  Adhoc = 'adhoc',
  /** Annually */
  Annually = 'annually',
  /** Bi-Annually */
  Biannually = 'biannually',
  /** Daily */
  Daily = 'daily',
  /** Fortnightly */
  Fortnightly = 'fortnightly',
  /** Four Weekly */
  Fourweekly = 'fourweekly',
  /** Monthly */
  Monthly = 'monthly',
  /** Quarterly */
  Quarterly = 'quarterly',
  /** Weekly */
  Weekly = 'weekly'
}

/** Boolean expression to compare columns of type "control_test_frequency_enum". All fields are combined with logical 'AND'. */
export type ControlTestFrequencyEnumComparisonExp = {
  _eq?: InputMaybe<ControlTestFrequencyEnum>;
  _in?: InputMaybe<Array<ControlTestFrequencyEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ControlTestFrequencyEnum>;
  _nin?: InputMaybe<Array<ControlTestFrequencyEnum>>;
};

/** input type for inserting data into table "risksmart.control_test_frequency" */
export type ControlTestFrequencyInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ControlTestFrequencyMaxFields = {
  __typename?: 'control_test_frequency_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ControlTestFrequencyMinFields = {
  __typename?: 'control_test_frequency_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_test_frequency" */
export type ControlTestFrequencyMutationResponse = {
  __typename?: 'control_test_frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlTestFrequency>;
};

/** input type for inserting object relation for remote table "risksmart.control_test_frequency" */
export type ControlTestFrequencyObjRelInsertInput = {
  data: ControlTestFrequencyInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ControlTestFrequencyOnConflict>;
};

/** on_conflict condition type for table "risksmart.control_test_frequency" */
export type ControlTestFrequencyOnConflict = {
  constraint: ControlTestFrequencyConstraint;
  update_columns?: Array<ControlTestFrequencyUpdateColumn>;
  where?: InputMaybe<ControlTestFrequencyBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_test_frequency". */
export type ControlTestFrequencyOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.control_test_frequency */
export type ControlTestFrequencyPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.control_test_frequency" */
export enum ControlTestFrequencySelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.control_test_frequency" */
export type ControlTestFrequencySetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_test_frequency" */
export type ControlTestFrequencyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlTestFrequencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlTestFrequencyStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.control_test_frequency" */
export enum ControlTestFrequencyUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ControlTestFrequencyUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlTestFrequencySetInput>;
  /** filter the rows which have to be updated */
  where: ControlTestFrequencyBoolExp;
};

/** columns and relationships of "risksmart.control_type" */
export type ControlType = {
  __typename?: 'control_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.control_type" */
export type ControlTypeAggregate = {
  __typename?: 'control_type_aggregate';
  aggregate?: Maybe<ControlTypeAggregateFields>;
  nodes: Array<ControlType>;
};

/** aggregate fields of "risksmart.control_type" */
export type ControlTypeAggregateFields = {
  __typename?: 'control_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ControlTypeMaxFields>;
  min?: Maybe<ControlTypeMinFields>;
};


/** aggregate fields of "risksmart.control_type" */
export type ControlTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ControlTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_type". All fields are combined with a logical 'AND'. */
export type ControlTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ControlTypeBoolExp>>;
  _not?: InputMaybe<ControlTypeBoolExp>;
  _or?: InputMaybe<Array<ControlTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.control_type" */
export enum ControlTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ControlTypePkey = 'control_type_pkey'
}

export enum ControlTypeEnum {
  /** Corrective */
  Corrective = 'Corrective',
  /** Detective */
  Detective = 'Detective',
  /** Directive */
  Directive = 'Directive',
  /** Preventive */
  Preventive = 'Preventive'
}

/** Boolean expression to compare columns of type "control_type_enum". All fields are combined with logical 'AND'. */
export type ControlTypeEnumComparisonExp = {
  _eq?: InputMaybe<ControlTypeEnum>;
  _in?: InputMaybe<Array<ControlTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ControlTypeEnum>;
  _nin?: InputMaybe<Array<ControlTypeEnum>>;
};

/** input type for inserting data into table "risksmart.control_type" */
export type ControlTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ControlTypeMaxFields = {
  __typename?: 'control_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ControlTypeMinFields = {
  __typename?: 'control_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_type" */
export type ControlTypeMutationResponse = {
  __typename?: 'control_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ControlType>;
};

/** on_conflict condition type for table "risksmart.control_type" */
export type ControlTypeOnConflict = {
  constraint: ControlTypeConstraint;
  update_columns?: Array<ControlTypeUpdateColumn>;
  where?: InputMaybe<ControlTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_type". */
export type ControlTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.control_type */
export type ControlTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.control_type" */
export enum ControlTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.control_type" */
export type ControlTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "control_type" */
export type ControlTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ControlTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ControlTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.control_type" */
export enum ControlTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ControlTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ControlTypeBoolExp;
};

/** update columns of table "risksmart.control" */
export enum ControlUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ControlUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ControlAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ControlDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ControlDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ControlDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ControlIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ControlPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ControlSetInput>;
  /** filter the rows which have to be updated */
  where: ControlBoolExp;
};

/** aggregate var_pop on columns */
export type ControlVarPopFields = {
  __typename?: 'control_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ControlVarSampFields = {
  __typename?: 'control_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ControlVarianceFields = {
  __typename?: 'control_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.conversation" */
export type Conversation = {
  __typename?: 'conversation';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  IsResolved?: Maybe<Scalars['Boolean']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments: Array<Comment>;
  /** An aggregate relationship */
  comments_aggregate: CommentAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.conversation" */
export type ConversationCommentsArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


/** columns and relationships of "risksmart.conversation" */
export type ConversationCommentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


/** columns and relationships of "risksmart.conversation" */
export type ConversationPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.conversation" */
export type ConversationPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.conversation" */
export type ConversationAggregate = {
  __typename?: 'conversation_aggregate';
  aggregate?: Maybe<ConversationAggregateFields>;
  nodes: Array<Conversation>;
};

/** aggregate fields of "risksmart.conversation" */
export type ConversationAggregateFields = {
  __typename?: 'conversation_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ConversationMaxFields>;
  min?: Maybe<ConversationMinFields>;
};


/** aggregate fields of "risksmart.conversation" */
export type ConversationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConversationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.conversation_audit" */
export type ConversationAudit = {
  __typename?: 'conversation_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  IsResolved?: Maybe<Scalars['Boolean']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregated selection of "risksmart.conversation_audit" */
export type ConversationAuditAggregate = {
  __typename?: 'conversation_audit_aggregate';
  aggregate?: Maybe<ConversationAuditAggregateFields>;
  nodes: Array<ConversationAudit>;
};

/** aggregate fields of "risksmart.conversation_audit" */
export type ConversationAuditAggregateFields = {
  __typename?: 'conversation_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ConversationAuditMaxFields>;
  min?: Maybe<ConversationAuditMinFields>;
};


/** aggregate fields of "risksmart.conversation_audit" */
export type ConversationAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ConversationAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.conversation_audit". All fields are combined with a logical 'AND'. */
export type ConversationAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  IsResolved?: InputMaybe<BooleanComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ConversationAuditBoolExp>>;
  _not?: InputMaybe<ConversationAuditBoolExp>;
  _or?: InputMaybe<Array<ConversationAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.conversation_audit" */
export enum ConversationAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ConversationAuditPkey = 'conversation_audit_pkey'
}

/** input type for inserting data into table "risksmart.conversation_audit" */
export type ConversationAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ConversationAuditMaxFields = {
  __typename?: 'conversation_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ConversationAuditMinFields = {
  __typename?: 'conversation_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.conversation_audit" */
export type ConversationAuditMutationResponse = {
  __typename?: 'conversation_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ConversationAudit>;
};

/** on_conflict condition type for table "risksmart.conversation_audit" */
export type ConversationAuditOnConflict = {
  constraint: ConversationAuditConstraint;
  update_columns?: Array<ConversationAuditUpdateColumn>;
  where?: InputMaybe<ConversationAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.conversation_audit". */
export type ConversationAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IsResolved?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.conversation_audit */
export type ConversationAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.conversation_audit" */
export enum ConversationAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  IsResolved = 'IsResolved',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.conversation_audit" */
export type ConversationAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "conversation_audit" */
export type ConversationAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConversationAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConversationAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.conversation_audit" */
export enum ConversationAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  IsResolved = 'IsResolved',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type ConversationAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConversationAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ConversationAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.conversation". All fields are combined with a logical 'AND'. */
export type ConversationBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  IsResolved?: InputMaybe<BooleanComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ConversationBoolExp>>;
  _not?: InputMaybe<ConversationBoolExp>;
  _or?: InputMaybe<Array<ConversationBoolExp>>;
  comments?: InputMaybe<CommentBoolExp>;
  comments_aggregate?: InputMaybe<CommentAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.conversation" */
export enum ConversationConstraint {
  /** unique or primary key constraint on columns "Id" */
  ConversationPkey = 'conversation_pkey'
}

/** input type for inserting data into table "risksmart.conversation" */
export type ConversationInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  comments?: InputMaybe<CommentArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ConversationMaxFields = {
  __typename?: 'conversation_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ConversationMinFields = {
  __typename?: 'conversation_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.conversation" */
export type ConversationMutationResponse = {
  __typename?: 'conversation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Conversation>;
};

/** input type for inserting object relation for remote table "risksmart.conversation" */
export type ConversationObjRelInsertInput = {
  data: ConversationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ConversationOnConflict>;
};

/** on_conflict condition type for table "risksmart.conversation" */
export type ConversationOnConflict = {
  constraint: ConversationConstraint;
  update_columns?: Array<ConversationUpdateColumn>;
  where?: InputMaybe<ConversationBoolExp>;
};

/** Ordering options when selecting data from "risksmart.conversation". */
export type ConversationOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IsResolved?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  comments_aggregate?: InputMaybe<CommentAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.conversation */
export type ConversationPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.conversation" */
export enum ConversationSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  IsResolved = 'IsResolved',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.conversation" */
export type ConversationSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "conversation" */
export type ConversationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ConversationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ConversationStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IsResolved?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.conversation" */
export enum ConversationUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  IsResolved = 'IsResolved',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type ConversationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ConversationSetInput>;
  /** filter the rows which have to be updated */
  where: ConversationBoolExp;
};

/** columns and relationships of "risksmart.cost_type" */
export type CostType = {
  __typename?: 'cost_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.cost_type" */
export type CostTypeAggregate = {
  __typename?: 'cost_type_aggregate';
  aggregate?: Maybe<CostTypeAggregateFields>;
  nodes: Array<CostType>;
};

/** aggregate fields of "risksmart.cost_type" */
export type CostTypeAggregateFields = {
  __typename?: 'cost_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CostTypeMaxFields>;
  min?: Maybe<CostTypeMinFields>;
};


/** aggregate fields of "risksmart.cost_type" */
export type CostTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CostTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.cost_type". All fields are combined with a logical 'AND'. */
export type CostTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<CostTypeBoolExp>>;
  _not?: InputMaybe<CostTypeBoolExp>;
  _or?: InputMaybe<Array<CostTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.cost_type" */
export enum CostTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  CostTypePkey = 'cost_type_pkey'
}

export enum CostTypeEnum {
  /** Customers impacted */
  CustomersImpacted = 'customers_impacted',
  /** Hours */
  Hours = 'hours',
  /** Number */
  Number = 'number',
  /** Pounds */
  Pounds = 'pounds'
}

/** Boolean expression to compare columns of type "cost_type_enum". All fields are combined with logical 'AND'. */
export type CostTypeEnumComparisonExp = {
  _eq?: InputMaybe<CostTypeEnum>;
  _in?: InputMaybe<Array<CostTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<CostTypeEnum>;
  _nin?: InputMaybe<Array<CostTypeEnum>>;
};

/** input type for inserting data into table "risksmart.cost_type" */
export type CostTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CostTypeMaxFields = {
  __typename?: 'cost_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CostTypeMinFields = {
  __typename?: 'cost_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.cost_type" */
export type CostTypeMutationResponse = {
  __typename?: 'cost_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CostType>;
};

/** input type for inserting object relation for remote table "risksmart.cost_type" */
export type CostTypeObjRelInsertInput = {
  data: CostTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CostTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.cost_type" */
export type CostTypeOnConflict = {
  constraint: CostTypeConstraint;
  update_columns?: Array<CostTypeUpdateColumn>;
  where?: InputMaybe<CostTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.cost_type". */
export type CostTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.cost_type */
export type CostTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.cost_type" */
export enum CostTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.cost_type" */
export type CostTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "cost_type" */
export type CostTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CostTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CostTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.cost_type" */
export enum CostTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type CostTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CostTypeSetInput>;
  /** filter the rows which have to be updated */
  where: CostTypeBoolExp;
};

/** ordering argument of a cursor */
export enum CursorOrdering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "risksmart.custom_attribute_schema" */
export type CustomAttributeSchema = {
  __typename?: 'custom_attribute_schema';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Schema: Scalars['jsonb']['output'];
  Title: Scalars['String']['output'];
  UiSchema: Scalars['jsonb']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  form?: Maybe<FormConfiguration>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaAggregate = {
  __typename?: 'custom_attribute_schema_aggregate';
  aggregate?: Maybe<CustomAttributeSchemaAggregateFields>;
  nodes: Array<CustomAttributeSchema>;
};

/** aggregate fields of "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaAggregateFields = {
  __typename?: 'custom_attribute_schema_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CustomAttributeSchemaMaxFields>;
  min?: Maybe<CustomAttributeSchemaMinFields>;
};


/** aggregate fields of "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CustomAttributeSchemaSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomAttributeSchemaAppendInput = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAudit = {
  __typename?: 'custom_attribute_schema_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Schema: Scalars['jsonb']['output'];
  Title: Scalars['String']['output'];
  UiSchema: Scalars['jsonb']['output'];
};


/** columns and relationships of "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditAggregate = {
  __typename?: 'custom_attribute_schema_audit_aggregate';
  aggregate?: Maybe<CustomAttributeSchemaAuditAggregateFields>;
  nodes: Array<CustomAttributeSchemaAudit>;
};

/** aggregate fields of "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditAggregateFields = {
  __typename?: 'custom_attribute_schema_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CustomAttributeSchemaAuditMaxFields>;
  min?: Maybe<CustomAttributeSchemaAuditMinFields>;
};


/** aggregate fields of "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CustomAttributeSchemaAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomAttributeSchemaAuditAppendInput = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.custom_attribute_schema_audit". All fields are combined with a logical 'AND'. */
export type CustomAttributeSchemaAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Schema?: InputMaybe<JsonbComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  UiSchema?: InputMaybe<JsonbComparisonExp>;
  _and?: InputMaybe<Array<CustomAttributeSchemaAuditBoolExp>>;
  _not?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
  _or?: InputMaybe<Array<CustomAttributeSchemaAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.custom_attribute_schema_audit" */
export enum CustomAttributeSchemaAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  CustomAttributeSchemaAuditPkey = 'custom_attribute_schema_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomAttributeSchemaAuditDeleteAtPathInput = {
  Schema?: InputMaybe<Array<Scalars['String']['input']>>;
  UiSchema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomAttributeSchemaAuditDeleteElemInput = {
  Schema?: InputMaybe<Scalars['Int']['input']>;
  UiSchema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomAttributeSchemaAuditDeleteKeyInput = {
  Schema?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type CustomAttributeSchemaAuditMaxFields = {
  __typename?: 'custom_attribute_schema_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CustomAttributeSchemaAuditMinFields = {
  __typename?: 'custom_attribute_schema_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditMutationResponse = {
  __typename?: 'custom_attribute_schema_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomAttributeSchemaAudit>;
};

/** on_conflict condition type for table "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditOnConflict = {
  constraint: CustomAttributeSchemaAuditConstraint;
  update_columns?: Array<CustomAttributeSchemaAuditUpdateColumn>;
  where?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.custom_attribute_schema_audit". */
export type CustomAttributeSchemaAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Schema?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  UiSchema?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.custom_attribute_schema_audit */
export type CustomAttributeSchemaAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomAttributeSchemaAuditPrependInput = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.custom_attribute_schema_audit" */
export enum CustomAttributeSchemaAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Title = 'Title',
  /** column name */
  UiSchema = 'UiSchema'
}

/** input type for updating data in table "risksmart.custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "custom_attribute_schema_audit" */
export type CustomAttributeSchemaAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomAttributeSchemaAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomAttributeSchemaAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "risksmart.custom_attribute_schema_audit" */
export enum CustomAttributeSchemaAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Title = 'Title',
  /** column name */
  UiSchema = 'UiSchema'
}

export type CustomAttributeSchemaAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomAttributeSchemaAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomAttributeSchemaAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomAttributeSchemaAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomAttributeSchemaAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomAttributeSchemaAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomAttributeSchemaAuditSetInput>;
  /** filter the rows which have to be updated */
  where: CustomAttributeSchemaAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.custom_attribute_schema". All fields are combined with a logical 'AND'. */
export type CustomAttributeSchemaBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Schema?: InputMaybe<JsonbComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  UiSchema?: InputMaybe<JsonbComparisonExp>;
  _and?: InputMaybe<Array<CustomAttributeSchemaBoolExp>>;
  _not?: InputMaybe<CustomAttributeSchemaBoolExp>;
  _or?: InputMaybe<Array<CustomAttributeSchemaBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  form?: InputMaybe<FormConfigurationBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.custom_attribute_schema" */
export enum CustomAttributeSchemaConstraint {
  /** unique or primary key constraint on columns "Id" */
  CustomAttributeSchemaPkey = 'custom_attribute_schema_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomAttributeSchemaDeleteAtPathInput = {
  Schema?: InputMaybe<Array<Scalars['String']['input']>>;
  UiSchema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomAttributeSchemaDeleteElemInput = {
  Schema?: InputMaybe<Scalars['Int']['input']>;
  UiSchema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomAttributeSchemaDeleteKeyInput = {
  Schema?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  form?: InputMaybe<FormConfigurationObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type CustomAttributeSchemaMaxFields = {
  __typename?: 'custom_attribute_schema_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CustomAttributeSchemaMinFields = {
  __typename?: 'custom_attribute_schema_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaMutationResponse = {
  __typename?: 'custom_attribute_schema_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomAttributeSchema>;
};

/** input type for inserting object relation for remote table "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaObjRelInsertInput = {
  data: CustomAttributeSchemaInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<CustomAttributeSchemaOnConflict>;
};

/** on_conflict condition type for table "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaOnConflict = {
  constraint: CustomAttributeSchemaConstraint;
  update_columns?: Array<CustomAttributeSchemaUpdateColumn>;
  where?: InputMaybe<CustomAttributeSchemaBoolExp>;
};

/** Ordering options when selecting data from "risksmart.custom_attribute_schema". */
export type CustomAttributeSchemaOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Schema?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  UiSchema?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  form?: InputMaybe<FormConfigurationOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.custom_attribute_schema */
export type CustomAttributeSchemaPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomAttributeSchemaPrependInput = {
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.custom_attribute_schema" */
export enum CustomAttributeSchemaSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Title = 'Title',
  /** column name */
  UiSchema = 'UiSchema'
}

/** input type for updating data in table "risksmart.custom_attribute_schema" */
export type CustomAttributeSchemaSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "custom_attribute_schema" */
export type CustomAttributeSchemaStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomAttributeSchemaStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomAttributeSchemaStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  UiSchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "risksmart.custom_attribute_schema" */
export enum CustomAttributeSchemaUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Title = 'Title',
  /** column name */
  UiSchema = 'UiSchema'
}

export type CustomAttributeSchemaUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomAttributeSchemaAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomAttributeSchemaDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomAttributeSchemaDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomAttributeSchemaDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomAttributeSchemaPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomAttributeSchemaSetInput>;
  /** filter the rows which have to be updated */
  where: CustomAttributeSchemaBoolExp;
};

/** columns and relationships of "risksmart.custom_ribbon" */
export type CustomRibbon = {
  __typename?: 'custom_ribbon';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Filters: Scalars['jsonb']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentType: ParentTypeEnum;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.custom_ribbon" */
export type CustomRibbonFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.custom_ribbon" */
export type CustomRibbonAggregate = {
  __typename?: 'custom_ribbon_aggregate';
  aggregate?: Maybe<CustomRibbonAggregateFields>;
  nodes: Array<CustomRibbon>;
};

/** aggregate fields of "risksmart.custom_ribbon" */
export type CustomRibbonAggregateFields = {
  __typename?: 'custom_ribbon_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CustomRibbonMaxFields>;
  min?: Maybe<CustomRibbonMinFields>;
};


/** aggregate fields of "risksmart.custom_ribbon" */
export type CustomRibbonAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CustomRibbonSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomRibbonAppendInput = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.custom_ribbon_audit" */
export type CustomRibbonAudit = {
  __typename?: 'custom_ribbon_audit';
  Action: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Filters: Scalars['jsonb']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentType: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditAggregate = {
  __typename?: 'custom_ribbon_audit_aggregate';
  aggregate?: Maybe<CustomRibbonAuditAggregateFields>;
  nodes: Array<CustomRibbonAudit>;
};

/** aggregate fields of "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditAggregateFields = {
  __typename?: 'custom_ribbon_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<CustomRibbonAuditMaxFields>;
  min?: Maybe<CustomRibbonAuditMinFields>;
};


/** aggregate fields of "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<CustomRibbonAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type CustomRibbonAuditAppendInput = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.custom_ribbon_audit". All fields are combined with a logical 'AND'. */
export type CustomRibbonAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Filters?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentType?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<CustomRibbonAuditBoolExp>>;
  _not?: InputMaybe<CustomRibbonAuditBoolExp>;
  _or?: InputMaybe<Array<CustomRibbonAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.custom_ribbon_audit" */
export enum CustomRibbonAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  CustomRibbonAuditPkey = 'custom_ribbon_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomRibbonAuditDeleteAtPathInput = {
  Filters?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomRibbonAuditDeleteElemInput = {
  Filters?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomRibbonAuditDeleteKeyInput = {
  Filters?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type CustomRibbonAuditMaxFields = {
  __typename?: 'custom_ribbon_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CustomRibbonAuditMinFields = {
  __typename?: 'custom_ribbon_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditMutationResponse = {
  __typename?: 'custom_ribbon_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomRibbonAudit>;
};

/** on_conflict condition type for table "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditOnConflict = {
  constraint: CustomRibbonAuditConstraint;
  update_columns?: Array<CustomRibbonAuditUpdateColumn>;
  where?: InputMaybe<CustomRibbonAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.custom_ribbon_audit". */
export type CustomRibbonAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Filters?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.custom_ribbon_audit */
export type CustomRibbonAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomRibbonAuditPrependInput = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.custom_ribbon_audit" */
export enum CustomRibbonAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Filters = 'Filters',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.custom_ribbon_audit" */
export type CustomRibbonAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "custom_ribbon_audit" */
export type CustomRibbonAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomRibbonAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomRibbonAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.custom_ribbon_audit" */
export enum CustomRibbonAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Filters = 'Filters',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

export type CustomRibbonAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomRibbonAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomRibbonAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomRibbonAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomRibbonAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomRibbonAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomRibbonAuditSetInput>;
  /** filter the rows which have to be updated */
  where: CustomRibbonAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.custom_ribbon". All fields are combined with a logical 'AND'. */
export type CustomRibbonBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Filters?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<CustomRibbonBoolExp>>;
  _not?: InputMaybe<CustomRibbonBoolExp>;
  _or?: InputMaybe<Array<CustomRibbonBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.custom_ribbon" */
export enum CustomRibbonConstraint {
  /** unique or primary key constraint on columns "Id" */
  CustomRibbonPkey = 'custom_ribbon_pkey',
  /** unique or primary key constraint on columns "OrgKey", "ParentType" */
  IdxCustomribbonOrgkeyParenttype = 'idx_customribbon_orgkey_parenttype'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type CustomRibbonDeleteAtPathInput = {
  Filters?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type CustomRibbonDeleteElemInput = {
  Filters?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type CustomRibbonDeleteKeyInput = {
  Filters?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.custom_ribbon" */
export type CustomRibbonInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type CustomRibbonMaxFields = {
  __typename?: 'custom_ribbon_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type CustomRibbonMinFields = {
  __typename?: 'custom_ribbon_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.custom_ribbon" */
export type CustomRibbonMutationResponse = {
  __typename?: 'custom_ribbon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<CustomRibbon>;
};

/** on_conflict condition type for table "risksmart.custom_ribbon" */
export type CustomRibbonOnConflict = {
  constraint: CustomRibbonConstraint;
  update_columns?: Array<CustomRibbonUpdateColumn>;
  where?: InputMaybe<CustomRibbonBoolExp>;
};

/** Ordering options when selecting data from "risksmart.custom_ribbon". */
export type CustomRibbonOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Filters?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.custom_ribbon */
export type CustomRibbonPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type CustomRibbonPrependInput = {
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.custom_ribbon" */
export enum CustomRibbonSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Filters = 'Filters',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.custom_ribbon" */
export type CustomRibbonSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** Streaming cursor of the table "custom_ribbon" */
export type CustomRibbonStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: CustomRibbonStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type CustomRibbonStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Filters?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** update columns of table "risksmart.custom_ribbon" */
export enum CustomRibbonUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Filters = 'Filters',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

export type CustomRibbonUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<CustomRibbonAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<CustomRibbonDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<CustomRibbonDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<CustomRibbonDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<CustomRibbonPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<CustomRibbonSetInput>;
  /** filter the rows which have to be updated */
  where: CustomRibbonBoolExp;
};

/** Saved dashboards */
export type Dashboard = {
  __typename?: 'dashboard';
  Content?: Maybe<Scalars['jsonb']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Sharing: DashboardSharingTypeEnum;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** Saved dashboards */
export type DashboardContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** Saved dashboards */
export type DashboardAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** Saved dashboards */
export type DashboardAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** Saved dashboards */
export type DashboardContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** Saved dashboards */
export type DashboardContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** Saved dashboards */
export type DashboardContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** Saved dashboards */
export type DashboardContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** Saved dashboards */
export type DashboardOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** Saved dashboards */
export type DashboardOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** Saved dashboards */
export type DashboardOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** Saved dashboards */
export type DashboardOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** Saved dashboards */
export type DashboardPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** Saved dashboards */
export type DashboardPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.dashboard" */
export type DashboardAggregate = {
  __typename?: 'dashboard_aggregate';
  aggregate?: Maybe<DashboardAggregateFields>;
  nodes: Array<Dashboard>;
};

/** aggregate fields of "risksmart.dashboard" */
export type DashboardAggregateFields = {
  __typename?: 'dashboard_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DashboardMaxFields>;
  min?: Maybe<DashboardMinFields>;
};


/** aggregate fields of "risksmart.dashboard" */
export type DashboardAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DashboardSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DashboardAppendInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.dashboard_audit" */
export type DashboardAudit = {
  __typename?: 'dashboard_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Content?: Maybe<Scalars['jsonb']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  Sharing?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.dashboard_audit" */
export type DashboardAuditContentArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.dashboard_audit" */
export type DashboardAuditAggregate = {
  __typename?: 'dashboard_audit_aggregate';
  aggregate?: Maybe<DashboardAuditAggregateFields>;
  nodes: Array<DashboardAudit>;
};

/** aggregate fields of "risksmart.dashboard_audit" */
export type DashboardAuditAggregateFields = {
  __typename?: 'dashboard_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DashboardAuditMaxFields>;
  min?: Maybe<DashboardAuditMinFields>;
};


/** aggregate fields of "risksmart.dashboard_audit" */
export type DashboardAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DashboardAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DashboardAuditAppendInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.dashboard_audit". All fields are combined with a logical 'AND'. */
export type DashboardAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Content?: InputMaybe<JsonbComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Sharing?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DashboardAuditBoolExp>>;
  _not?: InputMaybe<DashboardAuditBoolExp>;
  _or?: InputMaybe<Array<DashboardAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.dashboard_audit" */
export enum DashboardAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DashboardAuditPkey = 'dashboard_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DashboardAuditDeleteAtPathInput = {
  Content?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DashboardAuditDeleteElemInput = {
  Content?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DashboardAuditDeleteKeyInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.dashboard_audit" */
export type DashboardAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DashboardAuditMaxFields = {
  __typename?: 'dashboard_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Sharing?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DashboardAuditMinFields = {
  __typename?: 'dashboard_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Sharing?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.dashboard_audit" */
export type DashboardAuditMutationResponse = {
  __typename?: 'dashboard_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DashboardAudit>;
};

/** on_conflict condition type for table "risksmart.dashboard_audit" */
export type DashboardAuditOnConflict = {
  constraint: DashboardAuditConstraint;
  update_columns?: Array<DashboardAuditUpdateColumn>;
  where?: InputMaybe<DashboardAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.dashboard_audit". */
export type DashboardAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Content?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Sharing?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.dashboard_audit */
export type DashboardAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DashboardAuditPrependInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.dashboard_audit" */
export enum DashboardAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Sharing = 'Sharing'
}

/** input type for updating data in table "risksmart.dashboard_audit" */
export type DashboardAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "dashboard_audit" */
export type DashboardAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.dashboard_audit" */
export enum DashboardAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Sharing = 'Sharing'
}

export type DashboardAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DashboardAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DashboardAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DashboardAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DashboardAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DashboardAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DashboardAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DashboardAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.dashboard". All fields are combined with a logical 'AND'. */
export type DashboardBoolExp = {
  Content?: InputMaybe<JsonbComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Sharing?: InputMaybe<DashboardSharingTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<DashboardBoolExp>>;
  _not?: InputMaybe<DashboardBoolExp>;
  _or?: InputMaybe<Array<DashboardBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.dashboard" */
export enum DashboardConstraint {
  /** unique or primary key constraint on columns "Id" */
  DashboardPkey = 'dashboard_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DashboardDeleteAtPathInput = {
  Content?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DashboardDeleteElemInput = {
  Content?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DashboardDeleteKeyInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.dashboard" */
export type DashboardInsertInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<DashboardSharingTypeEnum>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type DashboardMaxFields = {
  __typename?: 'dashboard_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DashboardMinFields = {
  __typename?: 'dashboard_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.dashboard" */
export type DashboardMutationResponse = {
  __typename?: 'dashboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Dashboard>;
};

/** input type for inserting object relation for remote table "risksmart.dashboard" */
export type DashboardObjRelInsertInput = {
  data: DashboardInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DashboardOnConflict>;
};

/** on_conflict condition type for table "risksmart.dashboard" */
export type DashboardOnConflict = {
  constraint: DashboardConstraint;
  update_columns?: Array<DashboardUpdateColumn>;
  where?: InputMaybe<DashboardBoolExp>;
};

/** Ordering options when selecting data from "risksmart.dashboard". */
export type DashboardOrderBy = {
  Content?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Sharing?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.dashboard */
export type DashboardPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DashboardPrependInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.dashboard" */
export enum DashboardSelectColumn {
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Sharing = 'Sharing'
}

/** input type for updating data in table "risksmart.dashboard" */
export type DashboardSetInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<DashboardSharingTypeEnum>;
};

/** columns and relationships of "risksmart.dashboard_sharing_type" */
export type DashboardSharingType = {
  __typename?: 'dashboard_sharing_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeAggregate = {
  __typename?: 'dashboard_sharing_type_aggregate';
  aggregate?: Maybe<DashboardSharingTypeAggregateFields>;
  nodes: Array<DashboardSharingType>;
};

/** aggregate fields of "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeAggregateFields = {
  __typename?: 'dashboard_sharing_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DashboardSharingTypeMaxFields>;
  min?: Maybe<DashboardSharingTypeMinFields>;
};


/** aggregate fields of "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DashboardSharingTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.dashboard_sharing_type". All fields are combined with a logical 'AND'. */
export type DashboardSharingTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DashboardSharingTypeBoolExp>>;
  _not?: InputMaybe<DashboardSharingTypeBoolExp>;
  _or?: InputMaybe<Array<DashboardSharingTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.dashboard_sharing_type" */
export enum DashboardSharingTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  DashboardSharingTypePkey = 'dashboard_sharing_type_pkey'
}

export enum DashboardSharingTypeEnum {
  /** Custom */
  Custom = 'custom',
  /** Organisation */
  Organisation = 'organisation',
  /** Private */
  UserOnly = 'user_only'
}

export enum DashboardSharingTypeEnumAction {
  /** Custom */
  Custom = 'custom',
  /** Organisation */
  Organisation = 'organisation',
  /** Private */
  UserOnly = 'user_only'
}

/** Boolean expression to compare columns of type "dashboard_sharing_type_enum". All fields are combined with logical 'AND'. */
export type DashboardSharingTypeEnumComparisonExp = {
  _eq?: InputMaybe<DashboardSharingTypeEnum>;
  _in?: InputMaybe<Array<DashboardSharingTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<DashboardSharingTypeEnum>;
  _nin?: InputMaybe<Array<DashboardSharingTypeEnum>>;
};

/** input type for inserting data into table "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DashboardSharingTypeMaxFields = {
  __typename?: 'dashboard_sharing_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DashboardSharingTypeMinFields = {
  __typename?: 'dashboard_sharing_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeMutationResponse = {
  __typename?: 'dashboard_sharing_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DashboardSharingType>;
};

/** on_conflict condition type for table "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeOnConflict = {
  constraint: DashboardSharingTypeConstraint;
  update_columns?: Array<DashboardSharingTypeUpdateColumn>;
  where?: InputMaybe<DashboardSharingTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.dashboard_sharing_type". */
export type DashboardSharingTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.dashboard_sharing_type */
export type DashboardSharingTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.dashboard_sharing_type" */
export enum DashboardSharingTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.dashboard_sharing_type" */
export type DashboardSharingTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "dashboard_sharing_type" */
export type DashboardSharingTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardSharingTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardSharingTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.dashboard_sharing_type" */
export enum DashboardSharingTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type DashboardSharingTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DashboardSharingTypeSetInput>;
  /** filter the rows which have to be updated */
  where: DashboardSharingTypeBoolExp;
};

/** Streaming cursor of the table "dashboard" */
export type DashboardStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DashboardStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DashboardStreamCursorValueInput = {
  Content?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Sharing?: InputMaybe<DashboardSharingTypeEnum>;
};

/** update columns of table "risksmart.dashboard" */
export enum DashboardUpdateColumn {
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Sharing = 'Sharing'
}

export type DashboardUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DashboardAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DashboardDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DashboardDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DashboardDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DashboardPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DashboardSetInput>;
  /** filter the rows which have to be updated */
  where: DashboardBoolExp;
};

/** columns and relationships of "risksmart.data_import" */
export type DataImport = {
  __typename?: 'data_import';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Status: DataImportStatusEnum;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};


/** columns and relationships of "risksmart.data_import" */
export type DataImportFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.data_import" */
export type DataImportFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};

/** aggregated selection of "risksmart.data_import" */
export type DataImportAggregate = {
  __typename?: 'data_import_aggregate';
  aggregate?: Maybe<DataImportAggregateFields>;
  nodes: Array<DataImport>;
};

/** aggregate fields of "risksmart.data_import" */
export type DataImportAggregateFields = {
  __typename?: 'data_import_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DataImportMaxFields>;
  min?: Maybe<DataImportMinFields>;
};


/** aggregate fields of "risksmart.data_import" */
export type DataImportAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DataImportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.data_import_audit" */
export type DataImportAudit = {
  __typename?: 'data_import_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Status: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.data_import_audit" */
export type DataImportAuditAggregate = {
  __typename?: 'data_import_audit_aggregate';
  aggregate?: Maybe<DataImportAuditAggregateFields>;
  nodes: Array<DataImportAudit>;
};

/** aggregate fields of "risksmart.data_import_audit" */
export type DataImportAuditAggregateFields = {
  __typename?: 'data_import_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DataImportAuditMaxFields>;
  min?: Maybe<DataImportAuditMinFields>;
};


/** aggregate fields of "risksmart.data_import_audit" */
export type DataImportAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DataImportAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.data_import_audit". All fields are combined with a logical 'AND'. */
export type DataImportAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DataImportAuditBoolExp>>;
  _not?: InputMaybe<DataImportAuditBoolExp>;
  _or?: InputMaybe<Array<DataImportAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.data_import_audit" */
export enum DataImportAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DataImportAuditPkey = 'data_import_audit_pkey'
}

/** input type for inserting data into table "risksmart.data_import_audit" */
export type DataImportAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DataImportAuditMaxFields = {
  __typename?: 'data_import_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DataImportAuditMinFields = {
  __typename?: 'data_import_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.data_import_audit" */
export type DataImportAuditMutationResponse = {
  __typename?: 'data_import_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DataImportAudit>;
};

/** on_conflict condition type for table "risksmart.data_import_audit" */
export type DataImportAuditOnConflict = {
  constraint: DataImportAuditConstraint;
  update_columns?: Array<DataImportAuditUpdateColumn>;
  where?: InputMaybe<DataImportAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.data_import_audit". */
export type DataImportAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.data_import_audit */
export type DataImportAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.data_import_audit" */
export enum DataImportAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Status = 'Status'
}

/** input type for updating data in table "risksmart.data_import_audit" */
export type DataImportAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "data_import_audit" */
export type DataImportAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DataImportAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DataImportAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.data_import_audit" */
export enum DataImportAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Status = 'Status'
}

export type DataImportAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataImportAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DataImportAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.data_import". All fields are combined with a logical 'AND'. */
export type DataImportBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<DataImportStatusEnumComparisonExp>;
  _and?: InputMaybe<Array<DataImportBoolExp>>;
  _not?: InputMaybe<DataImportBoolExp>;
  _or?: InputMaybe<Array<DataImportBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.data_import" */
export enum DataImportConstraint {
  /** unique or primary key constraint on columns "Id" */
  DataImportPkey = 'data_import_pkey'
}

/** columns and relationships of "risksmart.data_import_error" */
export type DataImportError = {
  __typename?: 'data_import_error';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  ImportObject: Scalars['String']['output'];
  Message: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RowNumber: Scalars['Int']['output'];
};

/** aggregated selection of "risksmart.data_import_error" */
export type DataImportErrorAggregate = {
  __typename?: 'data_import_error_aggregate';
  aggregate?: Maybe<DataImportErrorAggregateFields>;
  nodes: Array<DataImportError>;
};

/** aggregate fields of "risksmart.data_import_error" */
export type DataImportErrorAggregateFields = {
  __typename?: 'data_import_error_aggregate_fields';
  avg?: Maybe<DataImportErrorAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DataImportErrorMaxFields>;
  min?: Maybe<DataImportErrorMinFields>;
  stddev?: Maybe<DataImportErrorStddevFields>;
  stddev_pop?: Maybe<DataImportErrorStddevPopFields>;
  stddev_samp?: Maybe<DataImportErrorStddevSampFields>;
  sum?: Maybe<DataImportErrorSumFields>;
  var_pop?: Maybe<DataImportErrorVarPopFields>;
  var_samp?: Maybe<DataImportErrorVarSampFields>;
  variance?: Maybe<DataImportErrorVarianceFields>;
};


/** aggregate fields of "risksmart.data_import_error" */
export type DataImportErrorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DataImportErrorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.data_import_error_audit" */
export type DataImportErrorAudit = {
  __typename?: 'data_import_error_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  ImportObject: Scalars['String']['output'];
  Message: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RowNumber: Scalars['Int']['output'];
};

/** aggregated selection of "risksmart.data_import_error_audit" */
export type DataImportErrorAuditAggregate = {
  __typename?: 'data_import_error_audit_aggregate';
  aggregate?: Maybe<DataImportErrorAuditAggregateFields>;
  nodes: Array<DataImportErrorAudit>;
};

/** aggregate fields of "risksmart.data_import_error_audit" */
export type DataImportErrorAuditAggregateFields = {
  __typename?: 'data_import_error_audit_aggregate_fields';
  avg?: Maybe<DataImportErrorAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DataImportErrorAuditMaxFields>;
  min?: Maybe<DataImportErrorAuditMinFields>;
  stddev?: Maybe<DataImportErrorAuditStddevFields>;
  stddev_pop?: Maybe<DataImportErrorAuditStddevPopFields>;
  stddev_samp?: Maybe<DataImportErrorAuditStddevSampFields>;
  sum?: Maybe<DataImportErrorAuditSumFields>;
  var_pop?: Maybe<DataImportErrorAuditVarPopFields>;
  var_samp?: Maybe<DataImportErrorAuditVarSampFields>;
  variance?: Maybe<DataImportErrorAuditVarianceFields>;
};


/** aggregate fields of "risksmart.data_import_error_audit" */
export type DataImportErrorAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DataImportErrorAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type DataImportErrorAuditAvgFields = {
  __typename?: 'data_import_error_audit_avg_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.data_import_error_audit". All fields are combined with a logical 'AND'. */
export type DataImportErrorAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DataImportId?: InputMaybe<UuidComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImportObject?: InputMaybe<StringComparisonExp>;
  Message?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RowNumber?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<DataImportErrorAuditBoolExp>>;
  _not?: InputMaybe<DataImportErrorAuditBoolExp>;
  _or?: InputMaybe<Array<DataImportErrorAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.data_import_error_audit" */
export enum DataImportErrorAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DataImportErrorAuditPkey = 'data_import_error_audit_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.data_import_error_audit" */
export type DataImportErrorAuditIncInput = {
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.data_import_error_audit" */
export type DataImportErrorAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type DataImportErrorAuditMaxFields = {
  __typename?: 'data_import_error_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImportObject?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type DataImportErrorAuditMinFields = {
  __typename?: 'data_import_error_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImportObject?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.data_import_error_audit" */
export type DataImportErrorAuditMutationResponse = {
  __typename?: 'data_import_error_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DataImportErrorAudit>;
};

/** on_conflict condition type for table "risksmart.data_import_error_audit" */
export type DataImportErrorAuditOnConflict = {
  constraint: DataImportErrorAuditConstraint;
  update_columns?: Array<DataImportErrorAuditUpdateColumn>;
  where?: InputMaybe<DataImportErrorAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.data_import_error_audit". */
export type DataImportErrorAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DataImportId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImportObject?: InputMaybe<OrderBy>;
  Message?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RowNumber?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.data_import_error_audit */
export type DataImportErrorAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.data_import_error_audit" */
export enum DataImportErrorAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DataImportId = 'DataImportId',
  /** column name */
  Id = 'Id',
  /** column name */
  ImportObject = 'ImportObject',
  /** column name */
  Message = 'Message',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RowNumber = 'RowNumber'
}

/** input type for updating data in table "risksmart.data_import_error_audit" */
export type DataImportErrorAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type DataImportErrorAuditStddevFields = {
  __typename?: 'data_import_error_audit_stddev_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DataImportErrorAuditStddevPopFields = {
  __typename?: 'data_import_error_audit_stddev_pop_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DataImportErrorAuditStddevSampFields = {
  __typename?: 'data_import_error_audit_stddev_samp_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "data_import_error_audit" */
export type DataImportErrorAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DataImportErrorAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DataImportErrorAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type DataImportErrorAuditSumFields = {
  __typename?: 'data_import_error_audit_sum_fields';
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.data_import_error_audit" */
export enum DataImportErrorAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DataImportId = 'DataImportId',
  /** column name */
  Id = 'Id',
  /** column name */
  ImportObject = 'ImportObject',
  /** column name */
  Message = 'Message',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RowNumber = 'RowNumber'
}

export type DataImportErrorAuditUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DataImportErrorAuditIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataImportErrorAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DataImportErrorAuditBoolExp;
};

/** aggregate var_pop on columns */
export type DataImportErrorAuditVarPopFields = {
  __typename?: 'data_import_error_audit_var_pop_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DataImportErrorAuditVarSampFields = {
  __typename?: 'data_import_error_audit_var_samp_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DataImportErrorAuditVarianceFields = {
  __typename?: 'data_import_error_audit_variance_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type DataImportErrorAvgFields = {
  __typename?: 'data_import_error_avg_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.data_import_error". All fields are combined with a logical 'AND'. */
export type DataImportErrorBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DataImportId?: InputMaybe<UuidComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImportObject?: InputMaybe<StringComparisonExp>;
  Message?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RowNumber?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<DataImportErrorBoolExp>>;
  _not?: InputMaybe<DataImportErrorBoolExp>;
  _or?: InputMaybe<Array<DataImportErrorBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.data_import_error" */
export enum DataImportErrorConstraint {
  /** unique or primary key constraint on columns "Id" */
  DataImportErrorPkey = 'data_import_error_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.data_import_error" */
export type DataImportErrorIncInput = {
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.data_import_error" */
export type DataImportErrorInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type DataImportErrorMaxFields = {
  __typename?: 'data_import_error_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImportObject?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type DataImportErrorMinFields = {
  __typename?: 'data_import_error_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DataImportId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImportObject?: Maybe<Scalars['String']['output']>;
  Message?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.data_import_error" */
export type DataImportErrorMutationResponse = {
  __typename?: 'data_import_error_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DataImportError>;
};

/** on_conflict condition type for table "risksmart.data_import_error" */
export type DataImportErrorOnConflict = {
  constraint: DataImportErrorConstraint;
  update_columns?: Array<DataImportErrorUpdateColumn>;
  where?: InputMaybe<DataImportErrorBoolExp>;
};

/** Ordering options when selecting data from "risksmart.data_import_error". */
export type DataImportErrorOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DataImportId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImportObject?: InputMaybe<OrderBy>;
  Message?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RowNumber?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.data_import_error */
export type DataImportErrorPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.data_import_error" */
export enum DataImportErrorSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DataImportId = 'DataImportId',
  /** column name */
  Id = 'Id',
  /** column name */
  ImportObject = 'ImportObject',
  /** column name */
  Message = 'Message',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RowNumber = 'RowNumber'
}

/** input type for updating data in table "risksmart.data_import_error" */
export type DataImportErrorSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type DataImportErrorStddevFields = {
  __typename?: 'data_import_error_stddev_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DataImportErrorStddevPopFields = {
  __typename?: 'data_import_error_stddev_pop_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DataImportErrorStddevSampFields = {
  __typename?: 'data_import_error_stddev_samp_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "data_import_error" */
export type DataImportErrorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DataImportErrorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DataImportErrorStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DataImportId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImportObject?: InputMaybe<Scalars['String']['input']>;
  Message?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RowNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type DataImportErrorSumFields = {
  __typename?: 'data_import_error_sum_fields';
  RowNumber?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.data_import_error" */
export enum DataImportErrorUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DataImportId = 'DataImportId',
  /** column name */
  Id = 'Id',
  /** column name */
  ImportObject = 'ImportObject',
  /** column name */
  Message = 'Message',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RowNumber = 'RowNumber'
}

export type DataImportErrorUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DataImportErrorIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataImportErrorSetInput>;
  /** filter the rows which have to be updated */
  where: DataImportErrorBoolExp;
};

/** aggregate var_pop on columns */
export type DataImportErrorVarPopFields = {
  __typename?: 'data_import_error_var_pop_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DataImportErrorVarSampFields = {
  __typename?: 'data_import_error_var_samp_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DataImportErrorVarianceFields = {
  __typename?: 'data_import_error_variance_fields';
  RowNumber?: Maybe<Scalars['Float']['output']>;
};

/** input type for inserting data into table "risksmart.data_import" */
export type DataImportInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<DataImportStatusEnum>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type DataImportMaxFields = {
  __typename?: 'data_import_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DataImportMinFields = {
  __typename?: 'data_import_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.data_import" */
export type DataImportMutationResponse = {
  __typename?: 'data_import_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DataImport>;
};

/** on_conflict condition type for table "risksmart.data_import" */
export type DataImportOnConflict = {
  constraint: DataImportConstraint;
  update_columns?: Array<DataImportUpdateColumn>;
  where?: InputMaybe<DataImportBoolExp>;
};

/** Ordering options when selecting data from "risksmart.data_import". */
export type DataImportOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.data_import */
export type DataImportPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.data_import" */
export enum DataImportSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Status = 'Status'
}

/** input type for updating data in table "risksmart.data_import" */
export type DataImportSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<DataImportStatusEnum>;
};

/** columns and relationships of "risksmart.data_import_status" */
export type DataImportStatus = {
  __typename?: 'data_import_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.data_import_status" */
export type DataImportStatusAggregate = {
  __typename?: 'data_import_status_aggregate';
  aggregate?: Maybe<DataImportStatusAggregateFields>;
  nodes: Array<DataImportStatus>;
};

/** aggregate fields of "risksmart.data_import_status" */
export type DataImportStatusAggregateFields = {
  __typename?: 'data_import_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DataImportStatusMaxFields>;
  min?: Maybe<DataImportStatusMinFields>;
};


/** aggregate fields of "risksmart.data_import_status" */
export type DataImportStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DataImportStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.data_import_status". All fields are combined with a logical 'AND'. */
export type DataImportStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DataImportStatusBoolExp>>;
  _not?: InputMaybe<DataImportStatusBoolExp>;
  _or?: InputMaybe<Array<DataImportStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.data_import_status" */
export enum DataImportStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  DataImportStatusPkey = 'data_import_status_pkey'
}

export enum DataImportStatusEnum {
  /** Complete */
  Complete = 'complete',
  /** Failed */
  Failed = 'failed',
  /** Importing */
  Importing = 'importing',
  /** Initiating import */
  InitiatingImport = 'initiatingImport',
  /** Not Started */
  Notstarted = 'notstarted',
  /** Valid */
  Valid = 'valid',
  /** Validating */
  Validating = 'validating'
}

/** Boolean expression to compare columns of type "data_import_status_enum". All fields are combined with logical 'AND'. */
export type DataImportStatusEnumComparisonExp = {
  _eq?: InputMaybe<DataImportStatusEnum>;
  _in?: InputMaybe<Array<DataImportStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<DataImportStatusEnum>;
  _nin?: InputMaybe<Array<DataImportStatusEnum>>;
};

/** input type for inserting data into table "risksmart.data_import_status" */
export type DataImportStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DataImportStatusMaxFields = {
  __typename?: 'data_import_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DataImportStatusMinFields = {
  __typename?: 'data_import_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.data_import_status" */
export type DataImportStatusMutationResponse = {
  __typename?: 'data_import_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DataImportStatus>;
};

/** on_conflict condition type for table "risksmart.data_import_status" */
export type DataImportStatusOnConflict = {
  constraint: DataImportStatusConstraint;
  update_columns?: Array<DataImportStatusUpdateColumn>;
  where?: InputMaybe<DataImportStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.data_import_status". */
export type DataImportStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.data_import_status */
export type DataImportStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.data_import_status" */
export enum DataImportStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.data_import_status" */
export type DataImportStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "data_import_status" */
export type DataImportStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DataImportStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DataImportStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.data_import_status" */
export enum DataImportStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type DataImportStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataImportStatusSetInput>;
  /** filter the rows which have to be updated */
  where: DataImportStatusBoolExp;
};

/** Streaming cursor of the table "data_import" */
export type DataImportStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DataImportStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DataImportStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<DataImportStatusEnum>;
};

/** update columns of table "risksmart.data_import" */
export enum DataImportUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Status = 'Status'
}

export type DataImportUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DataImportSetInput>;
  /** filter the rows which have to be updated */
  where: DataImportBoolExp;
};

/** columns and relationships of "risksmart.department" */
export type Department = {
  __typename?: 'department';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  type?: Maybe<DepartmentType>;
};


/** columns and relationships of "risksmart.department" */
export type DepartmentPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.department" */
export type DepartmentPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.department" */
export type DepartmentAggregate = {
  __typename?: 'department_aggregate';
  aggregate?: Maybe<DepartmentAggregateFields>;
  nodes: Array<Department>;
};

export type DepartmentAggregateBoolExp = {
  count?: InputMaybe<DepartmentAggregateBoolExpCount>;
};

export type DepartmentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DepartmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DepartmentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.department" */
export type DepartmentAggregateFields = {
  __typename?: 'department_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentMaxFields>;
  min?: Maybe<DepartmentMinFields>;
};


/** aggregate fields of "risksmart.department" */
export type DepartmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepartmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.department" */
export type DepartmentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DepartmentMaxOrderBy>;
  min?: InputMaybe<DepartmentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.department" */
export type DepartmentArrRelInsertInput = {
  data: Array<DepartmentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DepartmentOnConflict>;
};

/** columns and relationships of "risksmart.department_audit" */
export type DepartmentAudit = {
  __typename?: 'department_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.department_audit" */
export type DepartmentAuditAggregate = {
  __typename?: 'department_audit_aggregate';
  aggregate?: Maybe<DepartmentAuditAggregateFields>;
  nodes: Array<DepartmentAudit>;
};

export type DepartmentAuditAggregateBoolExp = {
  count?: InputMaybe<DepartmentAuditAggregateBoolExpCount>;
};

export type DepartmentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DepartmentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.department_audit" */
export type DepartmentAuditAggregateFields = {
  __typename?: 'department_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentAuditMaxFields>;
  min?: Maybe<DepartmentAuditMinFields>;
};


/** aggregate fields of "risksmart.department_audit" */
export type DepartmentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.department_audit" */
export type DepartmentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DepartmentAuditMaxOrderBy>;
  min?: InputMaybe<DepartmentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.department_audit" */
export type DepartmentAuditArrRelInsertInput = {
  data: Array<DepartmentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DepartmentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.department_audit". All fields are combined with a logical 'AND'. */
export type DepartmentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DepartmentTypeId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<DepartmentAuditBoolExp>>;
  _not?: InputMaybe<DepartmentAuditBoolExp>;
  _or?: InputMaybe<Array<DepartmentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.department_audit" */
export enum DepartmentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "DepartmentTypeId", "ModifiedAtTimestamp" */
  DepartmentAuditPkey = 'department_audit_pkey'
}

/** input type for inserting data into table "risksmart.department_audit" */
export type DepartmentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type DepartmentAuditMaxFields = {
  __typename?: 'department_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.department_audit" */
export type DepartmentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DepartmentAuditMinFields = {
  __typename?: 'department_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.department_audit" */
export type DepartmentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.department_audit" */
export type DepartmentAuditMutationResponse = {
  __typename?: 'department_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DepartmentAudit>;
};

/** on_conflict condition type for table "risksmart.department_audit" */
export type DepartmentAuditOnConflict = {
  constraint: DepartmentAuditConstraint;
  update_columns?: Array<DepartmentAuditUpdateColumn>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.department_audit". */
export type DepartmentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.department_audit */
export type DepartmentAuditPkColumnsInput = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.department_audit" */
export enum DepartmentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.department_audit" */
export type DepartmentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "department_audit" */
export type DepartmentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DepartmentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepartmentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.department_audit" */
export enum DepartmentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type DepartmentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DepartmentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.department". All fields are combined with a logical 'AND'. */
export type DepartmentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DepartmentTypeId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<DepartmentBoolExp>>;
  _not?: InputMaybe<DepartmentBoolExp>;
  _or?: InputMaybe<Array<DepartmentBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  parentAction?: InputMaybe<ActionBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentIssue?: InputMaybe<IssueBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  type?: InputMaybe<DepartmentTypeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.department" */
export enum DepartmentConstraint {
  /** unique or primary key constraint on columns "ParentId", "DepartmentTypeId" */
  DepartmentPkey = 'department_pkey'
}

/** input type for inserting data into table "risksmart.department" */
export type DepartmentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  parentAction?: InputMaybe<ActionObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentIssue?: InputMaybe<IssueObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  type?: InputMaybe<DepartmentTypeObjRelInsertInput>;
};

/** aggregate max on columns */
export type DepartmentMaxFields = {
  __typename?: 'department_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.department" */
export type DepartmentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DepartmentMinFields = {
  __typename?: 'department_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.department" */
export type DepartmentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.department" */
export type DepartmentMutationResponse = {
  __typename?: 'department_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Department>;
};

/** on_conflict condition type for table "risksmart.department" */
export type DepartmentOnConflict = {
  constraint: DepartmentConstraint;
  update_columns?: Array<DepartmentUpdateColumn>;
  where?: InputMaybe<DepartmentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.department". */
export type DepartmentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  parentAction?: InputMaybe<ActionOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentIssue?: InputMaybe<IssueOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  type?: InputMaybe<DepartmentTypeOrderBy>;
};

/** primary key columns input for table: risksmart.department */
export type DepartmentPkColumnsInput = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.department" */
export enum DepartmentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.department" */
export type DepartmentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "department" */
export type DepartmentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DepartmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepartmentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "risksmart.department_type" */
export type DepartmentType = {
  __typename?: 'department_type';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  department_type_group?: Maybe<DepartmentTypeGroup>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
};

/** aggregated selection of "risksmart.department_type" */
export type DepartmentTypeAggregate = {
  __typename?: 'department_type_aggregate';
  aggregate?: Maybe<DepartmentTypeAggregateFields>;
  nodes: Array<DepartmentType>;
};

export type DepartmentTypeAggregateBoolExp = {
  count?: InputMaybe<DepartmentTypeAggregateBoolExpCount>;
};

export type DepartmentTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DepartmentTypeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.department_type" */
export type DepartmentTypeAggregateFields = {
  __typename?: 'department_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentTypeMaxFields>;
  min?: Maybe<DepartmentTypeMinFields>;
};


/** aggregate fields of "risksmart.department_type" */
export type DepartmentTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.department_type" */
export type DepartmentTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DepartmentTypeMaxOrderBy>;
  min?: InputMaybe<DepartmentTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.department_type" */
export type DepartmentTypeArrRelInsertInput = {
  data: Array<DepartmentTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DepartmentTypeOnConflict>;
};

/** columns and relationships of "risksmart.department_type_audit" */
export type DepartmentTypeAudit = {
  __typename?: 'department_type_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId: Scalars['uuid']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.department_type_audit" */
export type DepartmentTypeAuditAggregate = {
  __typename?: 'department_type_audit_aggregate';
  aggregate?: Maybe<DepartmentTypeAuditAggregateFields>;
  nodes: Array<DepartmentTypeAudit>;
};

/** aggregate fields of "risksmart.department_type_audit" */
export type DepartmentTypeAuditAggregateFields = {
  __typename?: 'department_type_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentTypeAuditMaxFields>;
  min?: Maybe<DepartmentTypeAuditMinFields>;
};


/** aggregate fields of "risksmart.department_type_audit" */
export type DepartmentTypeAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepartmentTypeAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.department_type_audit". All fields are combined with a logical 'AND'. */
export type DepartmentTypeAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DepartmentTypeGroupId?: InputMaybe<UuidComparisonExp>;
  DepartmentTypeId?: InputMaybe<UuidComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DepartmentTypeAuditBoolExp>>;
  _not?: InputMaybe<DepartmentTypeAuditBoolExp>;
  _or?: InputMaybe<Array<DepartmentTypeAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.department_type_audit" */
export enum DepartmentTypeAuditConstraint {
  /** unique or primary key constraint on columns "DepartmentTypeId", "ModifiedAtTimestamp" */
  DepartmentTypeAuditPkey = 'department_type_audit_pkey'
}

/** input type for inserting data into table "risksmart.department_type_audit" */
export type DepartmentTypeAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DepartmentTypeAuditMaxFields = {
  __typename?: 'department_type_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DepartmentTypeAuditMinFields = {
  __typename?: 'department_type_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.department_type_audit" */
export type DepartmentTypeAuditMutationResponse = {
  __typename?: 'department_type_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DepartmentTypeAudit>;
};

/** on_conflict condition type for table "risksmart.department_type_audit" */
export type DepartmentTypeAuditOnConflict = {
  constraint: DepartmentTypeAuditConstraint;
  update_columns?: Array<DepartmentTypeAuditUpdateColumn>;
  where?: InputMaybe<DepartmentTypeAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.department_type_audit". */
export type DepartmentTypeAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeGroupId?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.department_type_audit */
export type DepartmentTypeAuditPkColumnsInput = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.department_type_audit" */
export enum DepartmentTypeAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeGroupId = 'DepartmentTypeGroupId',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.department_type_audit" */
export type DepartmentTypeAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "department_type_audit" */
export type DepartmentTypeAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DepartmentTypeAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepartmentTypeAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.department_type_audit" */
export enum DepartmentTypeAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeGroupId = 'DepartmentTypeGroupId',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DepartmentTypeAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentTypeAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DepartmentTypeAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.department_type". All fields are combined with a logical 'AND'. */
export type DepartmentTypeBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DepartmentTypeGroupId?: InputMaybe<UuidComparisonExp>;
  DepartmentTypeId?: InputMaybe<UuidComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DepartmentTypeBoolExp>>;
  _not?: InputMaybe<DepartmentTypeBoolExp>;
  _or?: InputMaybe<Array<DepartmentTypeBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  department_type_group?: InputMaybe<DepartmentTypeGroupBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
};

/** unique or primary key constraints on table "risksmart.department_type" */
export enum DepartmentTypeConstraint {
  /** unique or primary key constraint on columns "DepartmentTypeId" */
  DepartmentTypePkey = 'department_type_pkey',
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  IxDepartmentTypeOrgkeyTitle = 'ix_department_type_orgkey_title'
}

/** columns and relationships of "risksmart.department_type_group" */
export type DepartmentTypeGroup = {
  __typename?: 'department_type_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An array relationship */
  department_types: Array<DepartmentType>;
  /** An aggregate relationship */
  department_types_aggregate: DepartmentTypeAggregate;
};


/** columns and relationships of "risksmart.department_type_group" */
export type DepartmentTypeGroupDepartmentTypesArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeOrderBy>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};


/** columns and relationships of "risksmart.department_type_group" */
export type DepartmentTypeGroupDepartmentTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeOrderBy>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};

/** aggregated selection of "risksmart.department_type_group" */
export type DepartmentTypeGroupAggregate = {
  __typename?: 'department_type_group_aggregate';
  aggregate?: Maybe<DepartmentTypeGroupAggregateFields>;
  nodes: Array<DepartmentTypeGroup>;
};

/** aggregate fields of "risksmart.department_type_group" */
export type DepartmentTypeGroupAggregateFields = {
  __typename?: 'department_type_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentTypeGroupMaxFields>;
  min?: Maybe<DepartmentTypeGroupMinFields>;
};


/** aggregate fields of "risksmart.department_type_group" */
export type DepartmentTypeGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepartmentTypeGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAudit = {
  __typename?: 'department_type_group_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditAggregate = {
  __typename?: 'department_type_group_audit_aggregate';
  aggregate?: Maybe<DepartmentTypeGroupAuditAggregateFields>;
  nodes: Array<DepartmentTypeGroupAudit>;
};

/** aggregate fields of "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditAggregateFields = {
  __typename?: 'department_type_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DepartmentTypeGroupAuditMaxFields>;
  min?: Maybe<DepartmentTypeGroupAuditMinFields>;
};


/** aggregate fields of "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DepartmentTypeGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.department_type_group_audit". All fields are combined with a logical 'AND'. */
export type DepartmentTypeGroupAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DepartmentTypeGroupAuditBoolExp>>;
  _not?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
  _or?: InputMaybe<Array<DepartmentTypeGroupAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.department_type_group_audit" */
export enum DepartmentTypeGroupAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DepartmentTypeGroupAuditPkey = 'department_type_group_audit_pkey'
}

/** input type for inserting data into table "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DepartmentTypeGroupAuditMaxFields = {
  __typename?: 'department_type_group_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DepartmentTypeGroupAuditMinFields = {
  __typename?: 'department_type_group_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditMutationResponse = {
  __typename?: 'department_type_group_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DepartmentTypeGroupAudit>;
};

/** on_conflict condition type for table "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditOnConflict = {
  constraint: DepartmentTypeGroupAuditConstraint;
  update_columns?: Array<DepartmentTypeGroupAuditUpdateColumn>;
  where?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.department_type_group_audit". */
export type DepartmentTypeGroupAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.department_type_group_audit */
export type DepartmentTypeGroupAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.department_type_group_audit" */
export enum DepartmentTypeGroupAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.department_type_group_audit" */
export type DepartmentTypeGroupAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "department_type_group_audit" */
export type DepartmentTypeGroupAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DepartmentTypeGroupAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepartmentTypeGroupAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.department_type_group_audit" */
export enum DepartmentTypeGroupAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DepartmentTypeGroupAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentTypeGroupAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DepartmentTypeGroupAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.department_type_group". All fields are combined with a logical 'AND'. */
export type DepartmentTypeGroupBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DepartmentTypeGroupBoolExp>>;
  _not?: InputMaybe<DepartmentTypeGroupBoolExp>;
  _or?: InputMaybe<Array<DepartmentTypeGroupBoolExp>>;
  department_types?: InputMaybe<DepartmentTypeBoolExp>;
  department_types_aggregate?: InputMaybe<DepartmentTypeAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.department_type_group" */
export enum DepartmentTypeGroupConstraint {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  DepartmentTypeGroupPkey = 'DepartmentTypeGroup_pkey',
  /** unique or primary key constraint on columns "Id" */
  DepartmentTypeGroupIdKey = 'department_type_group_Id_key'
}

/** input type for inserting data into table "risksmart.department_type_group" */
export type DepartmentTypeGroupInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  department_types?: InputMaybe<DepartmentTypeArrRelInsertInput>;
};

/** aggregate max on columns */
export type DepartmentTypeGroupMaxFields = {
  __typename?: 'department_type_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DepartmentTypeGroupMinFields = {
  __typename?: 'department_type_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.department_type_group" */
export type DepartmentTypeGroupMutationResponse = {
  __typename?: 'department_type_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DepartmentTypeGroup>;
};

/** input type for inserting object relation for remote table "risksmart.department_type_group" */
export type DepartmentTypeGroupObjRelInsertInput = {
  data: DepartmentTypeGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DepartmentTypeGroupOnConflict>;
};

/** on_conflict condition type for table "risksmart.department_type_group" */
export type DepartmentTypeGroupOnConflict = {
  constraint: DepartmentTypeGroupConstraint;
  update_columns?: Array<DepartmentTypeGroupUpdateColumn>;
  where?: InputMaybe<DepartmentTypeGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.department_type_group". */
export type DepartmentTypeGroupOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  department_types_aggregate?: InputMaybe<DepartmentTypeAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.department_type_group */
export type DepartmentTypeGroupPkColumnsInput = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.department_type_group" */
export enum DepartmentTypeGroupSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.department_type_group" */
export type DepartmentTypeGroupSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "department_type_group" */
export type DepartmentTypeGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DepartmentTypeGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepartmentTypeGroupStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.department_type_group" */
export enum DepartmentTypeGroupUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DepartmentTypeGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentTypeGroupSetInput>;
  /** filter the rows which have to be updated */
  where: DepartmentTypeGroupBoolExp;
};

/** input type for inserting data into table "risksmart.department_type" */
export type DepartmentTypeInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  department_type_group?: InputMaybe<DepartmentTypeGroupObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
};

/** aggregate max on columns */
export type DepartmentTypeMaxFields = {
  __typename?: 'department_type_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.department_type" */
export type DepartmentTypeMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeGroupId?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DepartmentTypeMinFields = {
  __typename?: 'department_type_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DepartmentTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  DepartmentTypeId?: Maybe<Scalars['uuid']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.department_type" */
export type DepartmentTypeMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeGroupId?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.department_type" */
export type DepartmentTypeMutationResponse = {
  __typename?: 'department_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DepartmentType>;
};

/** input type for inserting object relation for remote table "risksmart.department_type" */
export type DepartmentTypeObjRelInsertInput = {
  data: DepartmentTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DepartmentTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.department_type" */
export type DepartmentTypeOnConflict = {
  constraint: DepartmentTypeConstraint;
  update_columns?: Array<DepartmentTypeUpdateColumn>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.department_type". */
export type DepartmentTypeOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DepartmentTypeGroupId?: InputMaybe<OrderBy>;
  DepartmentTypeId?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  department_type_group?: InputMaybe<DepartmentTypeGroupOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
};

/** primary key columns input for table: risksmart.department_type */
export type DepartmentTypePkColumnsInput = {
  DepartmentTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.department_type" */
export enum DepartmentTypeSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeGroupId = 'DepartmentTypeGroupId',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.department_type" */
export type DepartmentTypeSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "department_type" */
export type DepartmentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DepartmentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DepartmentTypeStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DepartmentTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  DepartmentTypeId?: InputMaybe<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.department_type" */
export enum DepartmentTypeUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeGroupId = 'DepartmentTypeGroupId',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DepartmentTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentTypeSetInput>;
  /** filter the rows which have to be updated */
  where: DepartmentTypeBoolExp;
};

/** update columns of table "risksmart.department" */
export enum DepartmentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DepartmentTypeId = 'DepartmentTypeId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type DepartmentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DepartmentSetInput>;
  /** filter the rows which have to be updated */
  where: DepartmentBoolExp;
};

/** columns and relationships of "risksmart.document" */
export type Document = {
  __typename?: 'document';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DocumentType: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<TestFrequencyEnum>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  actions: Array<ActionParent>;
  /** An aggregate relationship */
  actions_aggregate: ActionParentAggregate;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  approvals: Array<Approval>;
  /** An aggregate relationship */
  approvals_aggregate: ApprovalAggregate;
  /** An array relationship */
  assessmentResults: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: AssessmentResultParentAggregate;
  /** An object relationship */
  attestationConfig?: Maybe<AttestationConfig>;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  documentFiles: Array<DocumentFile>;
  /** An aggregate relationship */
  documentFiles_aggregate: DocumentFileAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An array relationship */
  issues: Array<IssueParent>;
  /** An aggregate relationship */
  issues_aggregate: IssueParentAggregate;
  /** An array relationship */
  linkedDocuments: Array<DocumentLinkedDocument>;
  /** An aggregate relationship */
  linkedDocuments_aggregate: DocumentLinkedDocumentAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An object relationship */
  parent?: Maybe<Document>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An object relationship */
  testFrequency?: Maybe<TestFrequency>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentApprovalsArgs = {
  distinct_on?: InputMaybe<Array<ApprovalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalOrderBy>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentApprovalsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalOrderBy>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentAssessmentResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentDocumentFilesArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileOrderBy>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentDocumentFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileOrderBy>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentLinkedDocumentsArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentLinkedDocumentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.document" */
export type DocumentTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** columns and relationships of "risksmart.document_action_audit" */
export type DocumentActionAudit = {
  __typename?: 'document_action_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DocumentId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.document_action_audit" */
export type DocumentActionAuditAggregate = {
  __typename?: 'document_action_audit_aggregate';
  aggregate?: Maybe<DocumentActionAuditAggregateFields>;
  nodes: Array<DocumentActionAudit>;
};

/** aggregate fields of "risksmart.document_action_audit" */
export type DocumentActionAuditAggregateFields = {
  __typename?: 'document_action_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentActionAuditMaxFields>;
  min?: Maybe<DocumentActionAuditMinFields>;
};


/** aggregate fields of "risksmart.document_action_audit" */
export type DocumentActionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentActionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_action_audit". All fields are combined with a logical 'AND'. */
export type DocumentActionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActionId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DocumentId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentActionAuditBoolExp>>;
  _not?: InputMaybe<DocumentActionAuditBoolExp>;
  _or?: InputMaybe<Array<DocumentActionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_action_audit" */
export enum DocumentActionAuditConstraint {
  /** unique or primary key constraint on columns "ActionId", "DocumentId", "ModifiedAtTimestamp" */
  DocumentActionAuditPkey = 'document_action_audit_pkey'
}

/** input type for inserting data into table "risksmart.document_action_audit" */
export type DocumentActionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentActionAuditMaxFields = {
  __typename?: 'document_action_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentActionAuditMinFields = {
  __typename?: 'document_action_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_action_audit" */
export type DocumentActionAuditMutationResponse = {
  __typename?: 'document_action_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentActionAudit>;
};

/** on_conflict condition type for table "risksmart.document_action_audit" */
export type DocumentActionAuditOnConflict = {
  constraint: DocumentActionAuditConstraint;
  update_columns?: Array<DocumentActionAuditUpdateColumn>;
  where?: InputMaybe<DocumentActionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_action_audit". */
export type DocumentActionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DocumentId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_action_audit */
export type DocumentActionAuditPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  DocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.document_action_audit" */
export enum DocumentActionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.document_action_audit" */
export type DocumentActionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_action_audit" */
export type DocumentActionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentActionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentActionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_action_audit" */
export enum DocumentActionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DocumentActionAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentActionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentActionAuditBoolExp;
};

/** aggregated selection of "risksmart.document" */
export type DocumentAggregate = {
  __typename?: 'document_aggregate';
  aggregate?: Maybe<DocumentAggregateFields>;
  nodes: Array<Document>;
};

/** aggregate fields of "risksmart.document" */
export type DocumentAggregateFields = {
  __typename?: 'document_aggregate_fields';
  avg?: Maybe<DocumentAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentMaxFields>;
  min?: Maybe<DocumentMinFields>;
  stddev?: Maybe<DocumentStddevFields>;
  stddev_pop?: Maybe<DocumentStddevPopFields>;
  stddev_samp?: Maybe<DocumentStddevSampFields>;
  sum?: Maybe<DocumentSumFields>;
  var_pop?: Maybe<DocumentVarPopFields>;
  var_samp?: Maybe<DocumentVarSampFields>;
  variance?: Maybe<DocumentVarianceFields>;
};


/** aggregate fields of "risksmart.document" */
export type DocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResult = {
  __typename?: 'document_assessment_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  parents: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  parents_aggregate: AssessmentResultParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultParentsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultAggregate = {
  __typename?: 'document_assessment_result_aggregate';
  aggregate?: Maybe<DocumentAssessmentResultAggregateFields>;
  nodes: Array<DocumentAssessmentResult>;
};

/** aggregate fields of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultAggregateFields = {
  __typename?: 'document_assessment_result_aggregate_fields';
  avg?: Maybe<DocumentAssessmentResultAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentAssessmentResultMaxFields>;
  min?: Maybe<DocumentAssessmentResultMinFields>;
  stddev?: Maybe<DocumentAssessmentResultStddevFields>;
  stddev_pop?: Maybe<DocumentAssessmentResultStddevPopFields>;
  stddev_samp?: Maybe<DocumentAssessmentResultStddevSampFields>;
  sum?: Maybe<DocumentAssessmentResultSumFields>;
  var_pop?: Maybe<DocumentAssessmentResultVarPopFields>;
  var_samp?: Maybe<DocumentAssessmentResultVarSampFields>;
  variance?: Maybe<DocumentAssessmentResultVarianceFields>;
};


/** aggregate fields of "risksmart.document_assessment_result" */
export type DocumentAssessmentResultAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentAssessmentResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentAssessmentResultAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAudit = {
  __typename?: 'document_assessment_result_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditAggregate = {
  __typename?: 'document_assessment_result_audit_aggregate';
  aggregate?: Maybe<DocumentAssessmentResultAuditAggregateFields>;
  nodes: Array<DocumentAssessmentResultAudit>;
};

/** aggregate fields of "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditAggregateFields = {
  __typename?: 'document_assessment_result_audit_aggregate_fields';
  avg?: Maybe<DocumentAssessmentResultAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentAssessmentResultAuditMaxFields>;
  min?: Maybe<DocumentAssessmentResultAuditMinFields>;
  stddev?: Maybe<DocumentAssessmentResultAuditStddevFields>;
  stddev_pop?: Maybe<DocumentAssessmentResultAuditStddevPopFields>;
  stddev_samp?: Maybe<DocumentAssessmentResultAuditStddevSampFields>;
  sum?: Maybe<DocumentAssessmentResultAuditSumFields>;
  var_pop?: Maybe<DocumentAssessmentResultAuditVarPopFields>;
  var_samp?: Maybe<DocumentAssessmentResultAuditVarSampFields>;
  variance?: Maybe<DocumentAssessmentResultAuditVarianceFields>;
};


/** aggregate fields of "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentAssessmentResultAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentAssessmentResultAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type DocumentAssessmentResultAuditAvgFields = {
  __typename?: 'document_assessment_result_audit_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_assessment_result_audit". All fields are combined with a logical 'AND'. */
export type DocumentAssessmentResultAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<DocumentAssessmentResultAuditBoolExp>>;
  _not?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
  _or?: InputMaybe<Array<DocumentAssessmentResultAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_assessment_result_audit" */
export enum DocumentAssessmentResultAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DocumentAssessmentResultAuditPkey = 'document_assessment_result_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentAssessmentResultAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentAssessmentResultAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentAssessmentResultAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditIncInput = {
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type DocumentAssessmentResultAuditMaxFields = {
  __typename?: 'document_assessment_result_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type DocumentAssessmentResultAuditMinFields = {
  __typename?: 'document_assessment_result_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditMutationResponse = {
  __typename?: 'document_assessment_result_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentAssessmentResultAudit>;
};

/** on_conflict condition type for table "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditOnConflict = {
  constraint: DocumentAssessmentResultAuditConstraint;
  update_columns?: Array<DocumentAssessmentResultAuditUpdateColumn>;
  where?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_assessment_result_audit". */
export type DocumentAssessmentResultAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_assessment_result_audit */
export type DocumentAssessmentResultAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentAssessmentResultAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document_assessment_result_audit" */
export enum DocumentAssessmentResultAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.document_assessment_result_audit" */
export type DocumentAssessmentResultAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type DocumentAssessmentResultAuditStddevFields = {
  __typename?: 'document_assessment_result_audit_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DocumentAssessmentResultAuditStddevPopFields = {
  __typename?: 'document_assessment_result_audit_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DocumentAssessmentResultAuditStddevSampFields = {
  __typename?: 'document_assessment_result_audit_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "document_assessment_result_audit" */
export type DocumentAssessmentResultAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentAssessmentResultAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentAssessmentResultAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type DocumentAssessmentResultAuditSumFields = {
  __typename?: 'document_assessment_result_audit_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.document_assessment_result_audit" */
export enum DocumentAssessmentResultAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

export type DocumentAssessmentResultAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentAssessmentResultAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentAssessmentResultAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentAssessmentResultAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentAssessmentResultAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentAssessmentResultAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentAssessmentResultAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentAssessmentResultAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentAssessmentResultAuditBoolExp;
};

/** aggregate var_pop on columns */
export type DocumentAssessmentResultAuditVarPopFields = {
  __typename?: 'document_assessment_result_audit_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DocumentAssessmentResultAuditVarSampFields = {
  __typename?: 'document_assessment_result_audit_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DocumentAssessmentResultAuditVarianceFields = {
  __typename?: 'document_assessment_result_audit_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type DocumentAssessmentResultAvgFields = {
  __typename?: 'document_assessment_result_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_assessment_result". All fields are combined with a logical 'AND'. */
export type DocumentAssessmentResultBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<DocumentAssessmentResultBoolExp>>;
  _not?: InputMaybe<DocumentAssessmentResultBoolExp>;
  _or?: InputMaybe<Array<DocumentAssessmentResultBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parents?: InputMaybe<AssessmentResultParentBoolExp>;
  parents_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.document_assessment_result" */
export enum DocumentAssessmentResultConstraint {
  /** unique or primary key constraint on columns "Id" */
  DocumentAssessmentResultPkey = 'document_assessment_result_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentAssessmentResultDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentAssessmentResultDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentAssessmentResultDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.document_assessment_result" */
export type DocumentAssessmentResultIncInput = {
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.document_assessment_result" */
export type DocumentAssessmentResultInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parents?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type DocumentAssessmentResultMaxFields = {
  __typename?: 'document_assessment_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type DocumentAssessmentResultMinFields = {
  __typename?: 'document_assessment_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.document_assessment_result" */
export type DocumentAssessmentResultMutationResponse = {
  __typename?: 'document_assessment_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentAssessmentResult>;
};

/** input type for inserting object relation for remote table "risksmart.document_assessment_result" */
export type DocumentAssessmentResultObjRelInsertInput = {
  data: DocumentAssessmentResultInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentAssessmentResultOnConflict>;
};

/** on_conflict condition type for table "risksmart.document_assessment_result" */
export type DocumentAssessmentResultOnConflict = {
  constraint: DocumentAssessmentResultConstraint;
  update_columns?: Array<DocumentAssessmentResultUpdateColumn>;
  where?: InputMaybe<DocumentAssessmentResultBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_assessment_result". */
export type DocumentAssessmentResultOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parents_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.document_assessment_result */
export type DocumentAssessmentResultPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentAssessmentResultPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document_assessment_result" */
export enum DocumentAssessmentResultSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.document_assessment_result" */
export type DocumentAssessmentResultSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type DocumentAssessmentResultStddevFields = {
  __typename?: 'document_assessment_result_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DocumentAssessmentResultStddevPopFields = {
  __typename?: 'document_assessment_result_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DocumentAssessmentResultStddevSampFields = {
  __typename?: 'document_assessment_result_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "document_assessment_result" */
export type DocumentAssessmentResultStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentAssessmentResultStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentAssessmentResultStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type DocumentAssessmentResultSumFields = {
  __typename?: 'document_assessment_result_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.document_assessment_result" */
export enum DocumentAssessmentResultUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

export type DocumentAssessmentResultUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentAssessmentResultAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentAssessmentResultDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentAssessmentResultDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentAssessmentResultDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentAssessmentResultIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentAssessmentResultPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentAssessmentResultSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentAssessmentResultBoolExp;
};

/** aggregate var_pop on columns */
export type DocumentAssessmentResultVarPopFields = {
  __typename?: 'document_assessment_result_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DocumentAssessmentResultVarSampFields = {
  __typename?: 'document_assessment_result_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DocumentAssessmentResultVarianceFields = {
  __typename?: 'document_assessment_result_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.document_assessment_status" */
export type DocumentAssessmentStatus = {
  __typename?: 'document_assessment_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusAggregate = {
  __typename?: 'document_assessment_status_aggregate';
  aggregate?: Maybe<DocumentAssessmentStatusAggregateFields>;
  nodes: Array<DocumentAssessmentStatus>;
};

/** aggregate fields of "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusAggregateFields = {
  __typename?: 'document_assessment_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentAssessmentStatusMaxFields>;
  min?: Maybe<DocumentAssessmentStatusMinFields>;
};


/** aggregate fields of "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentAssessmentStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_assessment_status". All fields are combined with a logical 'AND'. */
export type DocumentAssessmentStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentAssessmentStatusBoolExp>>;
  _not?: InputMaybe<DocumentAssessmentStatusBoolExp>;
  _or?: InputMaybe<Array<DocumentAssessmentStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_assessment_status" */
export enum DocumentAssessmentStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  DocumentAssessmentStatusPkey = 'document_assessment_status_pkey'
}

/** input type for inserting data into table "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentAssessmentStatusMaxFields = {
  __typename?: 'document_assessment_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentAssessmentStatusMinFields = {
  __typename?: 'document_assessment_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusMutationResponse = {
  __typename?: 'document_assessment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentAssessmentStatus>;
};

/** on_conflict condition type for table "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusOnConflict = {
  constraint: DocumentAssessmentStatusConstraint;
  update_columns?: Array<DocumentAssessmentStatusUpdateColumn>;
  where?: InputMaybe<DocumentAssessmentStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_assessment_status". */
export type DocumentAssessmentStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_assessment_status */
export type DocumentAssessmentStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.document_assessment_status" */
export enum DocumentAssessmentStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.document_assessment_status" */
export type DocumentAssessmentStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_assessment_status" */
export type DocumentAssessmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentAssessmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentAssessmentStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_assessment_status" */
export enum DocumentAssessmentStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type DocumentAssessmentStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentAssessmentStatusSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentAssessmentStatusBoolExp;
};

/** columns and relationships of "risksmart.document_audit" */
export type DocumentAudit = {
  __typename?: 'document_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DocumentType: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.document_audit" */
export type DocumentAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_audit" */
export type DocumentAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.document_audit" */
export type DocumentAuditAggregate = {
  __typename?: 'document_audit_aggregate';
  aggregate?: Maybe<DocumentAuditAggregateFields>;
  nodes: Array<DocumentAudit>;
};

/** aggregate fields of "risksmart.document_audit" */
export type DocumentAuditAggregateFields = {
  __typename?: 'document_audit_aggregate_fields';
  avg?: Maybe<DocumentAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentAuditMaxFields>;
  min?: Maybe<DocumentAuditMinFields>;
  stddev?: Maybe<DocumentAuditStddevFields>;
  stddev_pop?: Maybe<DocumentAuditStddevPopFields>;
  stddev_samp?: Maybe<DocumentAuditStddevSampFields>;
  sum?: Maybe<DocumentAuditSumFields>;
  var_pop?: Maybe<DocumentAuditVarPopFields>;
  var_samp?: Maybe<DocumentAuditVarSampFields>;
  variance?: Maybe<DocumentAuditVarianceFields>;
};


/** aggregate fields of "risksmart.document_audit" */
export type DocumentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type DocumentAuditAvgFields = {
  __typename?: 'document_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_audit". All fields are combined with a logical 'AND'. */
export type DocumentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DocumentType?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentDocument?: InputMaybe<UuidComparisonExp>;
  Purpose?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestFrequency?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentAuditBoolExp>>;
  _not?: InputMaybe<DocumentAuditBoolExp>;
  _or?: InputMaybe<Array<DocumentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_audit" */
export enum DocumentAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DocumentAuditPkey = 'document_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.document_audit" */
export type DocumentAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.document_audit" */
export type DocumentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentAuditMaxFields = {
  __typename?: 'document_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentAuditMinFields = {
  __typename?: 'document_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_audit" */
export type DocumentAuditMutationResponse = {
  __typename?: 'document_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentAudit>;
};

/** on_conflict condition type for table "risksmart.document_audit" */
export type DocumentAuditOnConflict = {
  constraint: DocumentAuditConstraint;
  update_columns?: Array<DocumentAuditUpdateColumn>;
  where?: InputMaybe<DocumentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_audit". */
export type DocumentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DocumentType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentDocument?: InputMaybe<OrderBy>;
  Purpose?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_audit */
export type DocumentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document_audit" */
export enum DocumentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DocumentType = 'DocumentType',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocument = 'ParentDocument',
  /** column name */
  Purpose = 'Purpose',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.document_audit" */
export type DocumentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type DocumentAuditStddevFields = {
  __typename?: 'document_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DocumentAuditStddevPopFields = {
  __typename?: 'document_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DocumentAuditStddevSampFields = {
  __typename?: 'document_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "document_audit" */
export type DocumentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type DocumentAuditSumFields = {
  __typename?: 'document_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.document_audit" */
export enum DocumentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DocumentType = 'DocumentType',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocument = 'ParentDocument',
  /** column name */
  Purpose = 'Purpose',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title'
}

export type DocumentAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentAuditBoolExp;
};

/** aggregate var_pop on columns */
export type DocumentAuditVarPopFields = {
  __typename?: 'document_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DocumentAuditVarSampFields = {
  __typename?: 'document_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DocumentAuditVarianceFields = {
  __typename?: 'document_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type DocumentAvgFields = {
  __typename?: 'document_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.document". All fields are combined with a logical 'AND'. */
export type DocumentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DocumentType?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentDocument?: InputMaybe<UuidComparisonExp>;
  Purpose?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestFrequency?: InputMaybe<TestFrequencyEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentBoolExp>>;
  _not?: InputMaybe<DocumentBoolExp>;
  _or?: InputMaybe<Array<DocumentBoolExp>>;
  actions?: InputMaybe<ActionParentBoolExp>;
  actions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  approvals?: InputMaybe<ApprovalBoolExp>;
  approvals_aggregate?: InputMaybe<ApprovalAggregateBoolExp>;
  assessmentResults?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  attestationConfig?: InputMaybe<AttestationConfigBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  documentFiles?: InputMaybe<DocumentFileBoolExp>;
  documentFiles_aggregate?: InputMaybe<DocumentFileAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  issues?: InputMaybe<IssueParentBoolExp>;
  issues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  linkedDocuments?: InputMaybe<DocumentLinkedDocumentBoolExp>;
  linkedDocuments_aggregate?: InputMaybe<DocumentLinkedDocumentAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parent?: InputMaybe<DocumentBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  testFrequency?: InputMaybe<TestFrequencyBoolExp>;
};

/** unique or primary key constraints on table "risksmart.document" */
export enum DocumentConstraint {
  /** unique or primary key constraint on columns "Id" */
  DocumentPkey = 'document_pkey',
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxDocumentOrgkeySequentialid = 'idx_document_orgkey_sequentialid'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.document_file" */
export type DocumentFile = {
  __typename?: 'document_file';
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  Link?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentDocumentId: Scalars['uuid']['output'];
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Status: VersionStatusEnum;
  Summary?: Maybe<Scalars['String']['output']>;
  Type: DocumentFileTypeEnum;
  Version: Scalars['String']['output'];
  /** An array relationship */
  attestations: Array<AttestationRecord>;
  /** An aggregate relationship */
  attestations_aggregate: AttestationRecordAggregate;
  /** An array relationship */
  changeRequests: Array<ChangeRequest>;
  /** An aggregate relationship */
  changeRequests_aggregate: ChangeRequestAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  file?: Maybe<File>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  parent?: Maybe<Document>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  reviewedBy?: Maybe<User>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFileCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFileMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFileAttestationsArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFileAttestationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFileChangeRequestsArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFileChangeRequestsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFilePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.document_file" */
export type DocumentFilePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.document_file" */
export type DocumentFileAggregate = {
  __typename?: 'document_file_aggregate';
  aggregate?: Maybe<DocumentFileAggregateFields>;
  nodes: Array<DocumentFile>;
};

export type DocumentFileAggregateBoolExp = {
  count?: InputMaybe<DocumentFileAggregateBoolExpCount>;
};

export type DocumentFileAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DocumentFileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DocumentFileBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.document_file" */
export type DocumentFileAggregateFields = {
  __typename?: 'document_file_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentFileMaxFields>;
  min?: Maybe<DocumentFileMinFields>;
};


/** aggregate fields of "risksmart.document_file" */
export type DocumentFileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentFileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.document_file" */
export type DocumentFileAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DocumentFileMaxOrderBy>;
  min?: InputMaybe<DocumentFileMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentFileAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.document_file" */
export type DocumentFileArrRelInsertInput = {
  data: Array<DocumentFileInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentFileOnConflict>;
};

/** columns and relationships of "risksmart.document_file_audit" */
export type DocumentFileAudit = {
  __typename?: 'document_file_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id: Scalars['uuid']['output'];
  Link?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentDocumentId: Scalars['uuid']['output'];
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Status: Scalars['String']['output'];
  Summary?: Maybe<Scalars['String']['output']>;
  Type: Scalars['String']['output'];
  Version: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.document_file_audit" */
export type DocumentFileAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.document_file_audit" */
export type DocumentFileAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.document_file_audit" */
export type DocumentFileAuditAggregate = {
  __typename?: 'document_file_audit_aggregate';
  aggregate?: Maybe<DocumentFileAuditAggregateFields>;
  nodes: Array<DocumentFileAudit>;
};

/** aggregate fields of "risksmart.document_file_audit" */
export type DocumentFileAuditAggregateFields = {
  __typename?: 'document_file_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentFileAuditMaxFields>;
  min?: Maybe<DocumentFileAuditMinFields>;
};


/** aggregate fields of "risksmart.document_file_audit" */
export type DocumentFileAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentFileAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type DocumentFileAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_file_audit". All fields are combined with a logical 'AND'. */
export type DocumentFileAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Content?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  FileId?: InputMaybe<UuidComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Link?: InputMaybe<StringComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextReviewDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentDocumentId?: InputMaybe<UuidComparisonExp>;
  PublishedDate?: InputMaybe<TimestamptzComparisonExp>;
  ReasonForReview?: InputMaybe<StringComparisonExp>;
  ReviewDate?: InputMaybe<TimestamptzComparisonExp>;
  ReviewedBy?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  Version?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentFileAuditBoolExp>>;
  _not?: InputMaybe<DocumentFileAuditBoolExp>;
  _or?: InputMaybe<Array<DocumentFileAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_file_audit" */
export enum DocumentFileAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  DocumentFileAuditPkey = 'document_file_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentFileAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentFileAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentFileAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.document_file_audit" */
export type DocumentFileAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentFileAuditMaxFields = {
  __typename?: 'document_file_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Link?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocumentId?: Maybe<Scalars['uuid']['output']>;
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentFileAuditMinFields = {
  __typename?: 'document_file_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Link?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocumentId?: Maybe<Scalars['uuid']['output']>;
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_file_audit" */
export type DocumentFileAuditMutationResponse = {
  __typename?: 'document_file_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentFileAudit>;
};

/** on_conflict condition type for table "risksmart.document_file_audit" */
export type DocumentFileAuditOnConflict = {
  constraint: DocumentFileAuditConstraint;
  update_columns?: Array<DocumentFileAuditUpdateColumn>;
  where?: InputMaybe<DocumentFileAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_file_audit". */
export type DocumentFileAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Content?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Link?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextReviewDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentDocumentId?: InputMaybe<OrderBy>;
  PublishedDate?: InputMaybe<OrderBy>;
  ReasonForReview?: InputMaybe<OrderBy>;
  ReviewDate?: InputMaybe<OrderBy>;
  ReviewedBy?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_file_audit */
export type DocumentFileAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentFileAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document_file_audit" */
export enum DocumentFileAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Id = 'Id',
  /** column name */
  Link = 'Link',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextReviewDate = 'NextReviewDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocumentId = 'ParentDocumentId',
  /** column name */
  PublishedDate = 'PublishedDate',
  /** column name */
  ReasonForReview = 'ReasonForReview',
  /** column name */
  ReviewDate = 'ReviewDate',
  /** column name */
  ReviewedBy = 'ReviewedBy',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Type = 'Type',
  /** column name */
  Version = 'Version'
}

/** input type for updating data in table "risksmart.document_file_audit" */
export type DocumentFileAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_file_audit" */
export type DocumentFileAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentFileAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentFileAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_file_audit" */
export enum DocumentFileAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Id = 'Id',
  /** column name */
  Link = 'Link',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextReviewDate = 'NextReviewDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocumentId = 'ParentDocumentId',
  /** column name */
  PublishedDate = 'PublishedDate',
  /** column name */
  ReasonForReview = 'ReasonForReview',
  /** column name */
  ReviewDate = 'ReviewDate',
  /** column name */
  ReviewedBy = 'ReviewedBy',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Type = 'Type',
  /** column name */
  Version = 'Version'
}

export type DocumentFileAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentFileAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentFileAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentFileAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentFileAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentFileAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentFileAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentFileAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.document_file". All fields are combined with a logical 'AND'. */
export type DocumentFileBoolExp = {
  Content?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  FileId?: InputMaybe<UuidComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Link?: InputMaybe<StringComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextReviewDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentDocumentId?: InputMaybe<UuidComparisonExp>;
  PublishedDate?: InputMaybe<TimestamptzComparisonExp>;
  ReasonForReview?: InputMaybe<StringComparisonExp>;
  ReviewDate?: InputMaybe<TimestamptzComparisonExp>;
  ReviewedBy?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<VersionStatusEnumComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<DocumentFileTypeEnumComparisonExp>;
  Version?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentFileBoolExp>>;
  _not?: InputMaybe<DocumentFileBoolExp>;
  _or?: InputMaybe<Array<DocumentFileBoolExp>>;
  attestations?: InputMaybe<AttestationRecordBoolExp>;
  attestations_aggregate?: InputMaybe<AttestationRecordAggregateBoolExp>;
  changeRequests?: InputMaybe<ChangeRequestBoolExp>;
  changeRequests_aggregate?: InputMaybe<ChangeRequestAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  file?: InputMaybe<FileBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  parent?: InputMaybe<DocumentBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  reviewedBy?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.document_file" */
export enum DocumentFileConstraint {
  /** unique or primary key constraint on columns "FileId" */
  DocumentFileFileId = 'document_file_fileId',
  /** unique or primary key constraint on columns "Id" */
  DocumentFilePkey = 'document_file_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type DocumentFileDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type DocumentFileDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type DocumentFileDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.document_file" */
export type DocumentFileInsertInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<VersionStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<DocumentFileTypeEnum>;
  Version?: InputMaybe<Scalars['String']['input']>;
  attestations?: InputMaybe<AttestationRecordArrRelInsertInput>;
  changeRequests?: InputMaybe<ChangeRequestArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  file?: InputMaybe<FileObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  parent?: InputMaybe<DocumentObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  reviewedBy?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type DocumentFileMaxFields = {
  __typename?: 'document_file_max_fields';
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Link?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocumentId?: Maybe<Scalars['uuid']['output']>;
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.document_file" */
export type DocumentFileMaxOrderBy = {
  Content?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Link?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextReviewDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentDocumentId?: InputMaybe<OrderBy>;
  PublishedDate?: InputMaybe<OrderBy>;
  ReasonForReview?: InputMaybe<OrderBy>;
  ReviewDate?: InputMaybe<OrderBy>;
  ReviewedBy?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DocumentFileMinFields = {
  __typename?: 'document_file_min_fields';
  Content?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Link?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocumentId?: Maybe<Scalars['uuid']['output']>;
  PublishedDate?: Maybe<Scalars['timestamptz']['output']>;
  ReasonForReview?: Maybe<Scalars['String']['output']>;
  ReviewDate?: Maybe<Scalars['timestamptz']['output']>;
  ReviewedBy?: Maybe<Scalars['String']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.document_file" */
export type DocumentFileMinOrderBy = {
  Content?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Link?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextReviewDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentDocumentId?: InputMaybe<OrderBy>;
  PublishedDate?: InputMaybe<OrderBy>;
  ReasonForReview?: InputMaybe<OrderBy>;
  ReviewDate?: InputMaybe<OrderBy>;
  ReviewedBy?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.document_file" */
export type DocumentFileMutationResponse = {
  __typename?: 'document_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentFile>;
};

/** input type for inserting object relation for remote table "risksmart.document_file" */
export type DocumentFileObjRelInsertInput = {
  data: DocumentFileInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentFileOnConflict>;
};

/** on_conflict condition type for table "risksmart.document_file" */
export type DocumentFileOnConflict = {
  constraint: DocumentFileConstraint;
  update_columns?: Array<DocumentFileUpdateColumn>;
  where?: InputMaybe<DocumentFileBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_file". */
export type DocumentFileOrderBy = {
  Content?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Link?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextReviewDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentDocumentId?: InputMaybe<OrderBy>;
  PublishedDate?: InputMaybe<OrderBy>;
  ReasonForReview?: InputMaybe<OrderBy>;
  ReviewDate?: InputMaybe<OrderBy>;
  ReviewedBy?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
  attestations_aggregate?: InputMaybe<AttestationRecordAggregateOrderBy>;
  changeRequests_aggregate?: InputMaybe<ChangeRequestAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  file?: InputMaybe<FileOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  parent?: InputMaybe<DocumentOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  reviewedBy?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.document_file */
export type DocumentFilePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentFilePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document_file" */
export enum DocumentFileSelectColumn {
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Id = 'Id',
  /** column name */
  Link = 'Link',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextReviewDate = 'NextReviewDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocumentId = 'ParentDocumentId',
  /** column name */
  PublishedDate = 'PublishedDate',
  /** column name */
  ReasonForReview = 'ReasonForReview',
  /** column name */
  ReviewDate = 'ReviewDate',
  /** column name */
  ReviewedBy = 'ReviewedBy',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Type = 'Type',
  /** column name */
  Version = 'Version'
}

/** input type for updating data in table "risksmart.document_file" */
export type DocumentFileSetInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<VersionStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<DocumentFileTypeEnum>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_file" */
export type DocumentFileStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentFileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentFileStreamCursorValueInput = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  PublishedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<VersionStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<DocumentFileTypeEnum>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.document_file_type" */
export type DocumentFileType = {
  __typename?: 'document_file_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.document_file_type" */
export type DocumentFileTypeAggregate = {
  __typename?: 'document_file_type_aggregate';
  aggregate?: Maybe<DocumentFileTypeAggregateFields>;
  nodes: Array<DocumentFileType>;
};

/** aggregate fields of "risksmart.document_file_type" */
export type DocumentFileTypeAggregateFields = {
  __typename?: 'document_file_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentFileTypeMaxFields>;
  min?: Maybe<DocumentFileTypeMinFields>;
};


/** aggregate fields of "risksmart.document_file_type" */
export type DocumentFileTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentFileTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_file_type". All fields are combined with a logical 'AND'. */
export type DocumentFileTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentFileTypeBoolExp>>;
  _not?: InputMaybe<DocumentFileTypeBoolExp>;
  _or?: InputMaybe<Array<DocumentFileTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_file_type" */
export enum DocumentFileTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  DocumentFileTypePkey = 'document_file_type_pkey'
}

export enum DocumentFileTypeEnum {
  /** File */
  File = 'file',
  /** Html */
  Html = 'html',
  /** Link */
  Link = 'link'
}

/** Boolean expression to compare columns of type "document_file_type_enum". All fields are combined with logical 'AND'. */
export type DocumentFileTypeEnumComparisonExp = {
  _eq?: InputMaybe<DocumentFileTypeEnum>;
  _in?: InputMaybe<Array<DocumentFileTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<DocumentFileTypeEnum>;
  _nin?: InputMaybe<Array<DocumentFileTypeEnum>>;
};

/** input type for inserting data into table "risksmart.document_file_type" */
export type DocumentFileTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentFileTypeMaxFields = {
  __typename?: 'document_file_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentFileTypeMinFields = {
  __typename?: 'document_file_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_file_type" */
export type DocumentFileTypeMutationResponse = {
  __typename?: 'document_file_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentFileType>;
};

/** on_conflict condition type for table "risksmart.document_file_type" */
export type DocumentFileTypeOnConflict = {
  constraint: DocumentFileTypeConstraint;
  update_columns?: Array<DocumentFileTypeUpdateColumn>;
  where?: InputMaybe<DocumentFileTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_file_type". */
export type DocumentFileTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_file_type */
export type DocumentFileTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.document_file_type" */
export enum DocumentFileTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.document_file_type" */
export type DocumentFileTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_file_type" */
export type DocumentFileTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentFileTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentFileTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_file_type" */
export enum DocumentFileTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type DocumentFileTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentFileTypeSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentFileTypeBoolExp;
};

/** update columns of table "risksmart.document_file" */
export enum DocumentFileUpdateColumn {
  /** column name */
  Content = 'Content',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Id = 'Id',
  /** column name */
  Link = 'Link',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextReviewDate = 'NextReviewDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocumentId = 'ParentDocumentId',
  /** column name */
  PublishedDate = 'PublishedDate',
  /** column name */
  ReasonForReview = 'ReasonForReview',
  /** column name */
  ReviewDate = 'ReviewDate',
  /** column name */
  ReviewedBy = 'ReviewedBy',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  Type = 'Type',
  /** column name */
  Version = 'Version'
}

export type DocumentFileUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentFileAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentFileDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentFileDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentFileDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentFilePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentFileSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentFileBoolExp;
};

/** input type for incrementing numeric columns in table "risksmart.document" */
export type DocumentIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.document" */
export type DocumentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actions?: InputMaybe<ActionParentArrRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  approvals?: InputMaybe<ApprovalArrRelInsertInput>;
  assessmentResults?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  attestationConfig?: InputMaybe<AttestationConfigObjRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  documentFiles?: InputMaybe<DocumentFileArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  issues?: InputMaybe<IssueParentArrRelInsertInput>;
  linkedDocuments?: InputMaybe<DocumentLinkedDocumentArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parent?: InputMaybe<DocumentObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  testFrequency?: InputMaybe<TestFrequencyObjRelInsertInput>;
};

/** columns and relationships of "risksmart.document_issue_audit" */
export type DocumentIssueAudit = {
  __typename?: 'document_issue_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DocumentId: Scalars['uuid']['output'];
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.document_issue_audit" */
export type DocumentIssueAuditAggregate = {
  __typename?: 'document_issue_audit_aggregate';
  aggregate?: Maybe<DocumentIssueAuditAggregateFields>;
  nodes: Array<DocumentIssueAudit>;
};

/** aggregate fields of "risksmart.document_issue_audit" */
export type DocumentIssueAuditAggregateFields = {
  __typename?: 'document_issue_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentIssueAuditMaxFields>;
  min?: Maybe<DocumentIssueAuditMinFields>;
};


/** aggregate fields of "risksmart.document_issue_audit" */
export type DocumentIssueAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentIssueAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_issue_audit". All fields are combined with a logical 'AND'. */
export type DocumentIssueAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DocumentId?: InputMaybe<UuidComparisonExp>;
  IssueId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentIssueAuditBoolExp>>;
  _not?: InputMaybe<DocumentIssueAuditBoolExp>;
  _or?: InputMaybe<Array<DocumentIssueAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_issue_audit" */
export enum DocumentIssueAuditConstraint {
  /** unique or primary key constraint on columns "DocumentId", "ModifiedAtTimestamp", "IssueId" */
  DocumentIssueAuditPkey = 'document_issue_audit_pkey'
}

/** input type for inserting data into table "risksmart.document_issue_audit" */
export type DocumentIssueAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentIssueAuditMaxFields = {
  __typename?: 'document_issue_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentIssueAuditMinFields = {
  __typename?: 'document_issue_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_issue_audit" */
export type DocumentIssueAuditMutationResponse = {
  __typename?: 'document_issue_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentIssueAudit>;
};

/** on_conflict condition type for table "risksmart.document_issue_audit" */
export type DocumentIssueAuditOnConflict = {
  constraint: DocumentIssueAuditConstraint;
  update_columns?: Array<DocumentIssueAuditUpdateColumn>;
  where?: InputMaybe<DocumentIssueAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_issue_audit". */
export type DocumentIssueAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DocumentId?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_issue_audit */
export type DocumentIssueAuditPkColumnsInput = {
  DocumentId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.document_issue_audit" */
export enum DocumentIssueAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.document_issue_audit" */
export type DocumentIssueAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_issue_audit" */
export type DocumentIssueAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentIssueAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentIssueAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_issue_audit" */
export enum DocumentIssueAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DocumentIssueAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentIssueAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentIssueAuditBoolExp;
};

/** columns and relationships of "risksmart.document_linked_document" */
export type DocumentLinkedDocument = {
  __typename?: 'document_linked_document';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DocumentId: Scalars['uuid']['output'];
  LinkedDocumentId: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An object relationship */
  child?: Maybe<Document>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Document>;
};


/** columns and relationships of "risksmart.document_linked_document" */
export type DocumentLinkedDocumentMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.document_linked_document" */
export type DocumentLinkedDocumentAggregate = {
  __typename?: 'document_linked_document_aggregate';
  aggregate?: Maybe<DocumentLinkedDocumentAggregateFields>;
  nodes: Array<DocumentLinkedDocument>;
};

export type DocumentLinkedDocumentAggregateBoolExp = {
  count?: InputMaybe<DocumentLinkedDocumentAggregateBoolExpCount>;
};

export type DocumentLinkedDocumentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<DocumentLinkedDocumentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.document_linked_document" */
export type DocumentLinkedDocumentAggregateFields = {
  __typename?: 'document_linked_document_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentLinkedDocumentMaxFields>;
  min?: Maybe<DocumentLinkedDocumentMinFields>;
};


/** aggregate fields of "risksmart.document_linked_document" */
export type DocumentLinkedDocumentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<DocumentLinkedDocumentMaxOrderBy>;
  min?: InputMaybe<DocumentLinkedDocumentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentArrRelInsertInput = {
  data: Array<DocumentLinkedDocumentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentLinkedDocumentOnConflict>;
};

/** columns and relationships of "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAudit = {
  __typename?: 'document_linked_document_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DocumentId: Scalars['uuid']['output'];
  LinkedDocumentId: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditAggregate = {
  __typename?: 'document_linked_document_audit_aggregate';
  aggregate?: Maybe<DocumentLinkedDocumentAuditAggregateFields>;
  nodes: Array<DocumentLinkedDocumentAudit>;
};

/** aggregate fields of "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditAggregateFields = {
  __typename?: 'document_linked_document_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<DocumentLinkedDocumentAuditMaxFields>;
  min?: Maybe<DocumentLinkedDocumentAuditMinFields>;
};


/** aggregate fields of "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<DocumentLinkedDocumentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.document_linked_document_audit". All fields are combined with a logical 'AND'. */
export type DocumentLinkedDocumentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DocumentId?: InputMaybe<UuidComparisonExp>;
  LinkedDocumentId?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentLinkedDocumentAuditBoolExp>>;
  _not?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
  _or?: InputMaybe<Array<DocumentLinkedDocumentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.document_linked_document_audit" */
export enum DocumentLinkedDocumentAuditConstraint {
  /** unique or primary key constraint on columns "DocumentId", "LinkedDocumentId", "ModifiedAtTimestamp" */
  DocumentLinkedDocumentAuditPkey = 'document_linked_document_audit_pkey'
}

/** input type for inserting data into table "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type DocumentLinkedDocumentAuditMaxFields = {
  __typename?: 'document_linked_document_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  LinkedDocumentId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentLinkedDocumentAuditMinFields = {
  __typename?: 'document_linked_document_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  LinkedDocumentId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditMutationResponse = {
  __typename?: 'document_linked_document_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentLinkedDocumentAudit>;
};

/** on_conflict condition type for table "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditOnConflict = {
  constraint: DocumentLinkedDocumentAuditConstraint;
  update_columns?: Array<DocumentLinkedDocumentAuditUpdateColumn>;
  where?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_linked_document_audit". */
export type DocumentLinkedDocumentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DocumentId?: InputMaybe<OrderBy>;
  LinkedDocumentId?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.document_linked_document_audit */
export type DocumentLinkedDocumentAuditPkColumnsInput = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.document_linked_document_audit" */
export enum DocumentLinkedDocumentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  LinkedDocumentId = 'LinkedDocumentId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.document_linked_document_audit" */
export type DocumentLinkedDocumentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_linked_document_audit" */
export type DocumentLinkedDocumentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentLinkedDocumentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentLinkedDocumentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_linked_document_audit" */
export enum DocumentLinkedDocumentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  LinkedDocumentId = 'LinkedDocumentId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DocumentLinkedDocumentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentLinkedDocumentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentLinkedDocumentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.document_linked_document". All fields are combined with a logical 'AND'. */
export type DocumentLinkedDocumentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DocumentId?: InputMaybe<UuidComparisonExp>;
  LinkedDocumentId?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<DocumentLinkedDocumentBoolExp>>;
  _not?: InputMaybe<DocumentLinkedDocumentBoolExp>;
  _or?: InputMaybe<Array<DocumentLinkedDocumentBoolExp>>;
  child?: InputMaybe<DocumentBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<DocumentBoolExp>;
};

/** unique or primary key constraints on table "risksmart.document_linked_document" */
export enum DocumentLinkedDocumentConstraint {
  /** unique or primary key constraint on columns "DocumentId", "LinkedDocumentId" */
  DocumentLinkedDocumentPkey = 'document_linked_document_pkey'
}

/** input type for inserting data into table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  child?: InputMaybe<DocumentObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<DocumentObjRelInsertInput>;
};

/** aggregate max on columns */
export type DocumentLinkedDocumentMaxFields = {
  __typename?: 'document_linked_document_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  LinkedDocumentId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DocumentId?: InputMaybe<OrderBy>;
  LinkedDocumentId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type DocumentLinkedDocumentMinFields = {
  __typename?: 'document_linked_document_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentId?: Maybe<Scalars['uuid']['output']>;
  LinkedDocumentId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DocumentId?: InputMaybe<OrderBy>;
  LinkedDocumentId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentMutationResponse = {
  __typename?: 'document_linked_document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<DocumentLinkedDocument>;
};

/** on_conflict condition type for table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentOnConflict = {
  constraint: DocumentLinkedDocumentConstraint;
  update_columns?: Array<DocumentLinkedDocumentUpdateColumn>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document_linked_document". */
export type DocumentLinkedDocumentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DocumentId?: InputMaybe<OrderBy>;
  LinkedDocumentId?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  child?: InputMaybe<DocumentOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<DocumentOrderBy>;
};

/** primary key columns input for table: risksmart.document_linked_document */
export type DocumentLinkedDocumentPkColumnsInput = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.document_linked_document" */
export enum DocumentLinkedDocumentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  LinkedDocumentId = 'LinkedDocumentId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.document_linked_document" */
export type DocumentLinkedDocumentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "document_linked_document" */
export type DocumentLinkedDocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentLinkedDocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentLinkedDocumentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DocumentId?: InputMaybe<Scalars['uuid']['input']>;
  LinkedDocumentId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.document_linked_document" */
export enum DocumentLinkedDocumentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DocumentId = 'DocumentId',
  /** column name */
  LinkedDocumentId = 'LinkedDocumentId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type DocumentLinkedDocumentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentLinkedDocumentSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentLinkedDocumentBoolExp;
};

/** aggregate max on columns */
export type DocumentMaxFields = {
  __typename?: 'document_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type DocumentMinFields = {
  __typename?: 'document_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DocumentType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentDocument?: Maybe<Scalars['uuid']['output']>;
  Purpose?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.document" */
export type DocumentMutationResponse = {
  __typename?: 'document_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Document>;
};

/** input type for inserting object relation for remote table "risksmart.document" */
export type DocumentObjRelInsertInput = {
  data: DocumentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<DocumentOnConflict>;
};

/** on_conflict condition type for table "risksmart.document" */
export type DocumentOnConflict = {
  constraint: DocumentConstraint;
  update_columns?: Array<DocumentUpdateColumn>;
  where?: InputMaybe<DocumentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.document". */
export type DocumentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DocumentType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentDocument?: InputMaybe<OrderBy>;
  Purpose?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  actions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  approvals_aggregate?: InputMaybe<ApprovalAggregateOrderBy>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  attestationConfig?: InputMaybe<AttestationConfigOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  documentFiles_aggregate?: InputMaybe<DocumentFileAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  issues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  linkedDocuments_aggregate?: InputMaybe<DocumentLinkedDocumentAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parent?: InputMaybe<DocumentOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  testFrequency?: InputMaybe<TestFrequencyOrderBy>;
};

/** primary key columns input for table: risksmart.document */
export type DocumentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type DocumentPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.document" */
export enum DocumentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DocumentType = 'DocumentType',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocument = 'ParentDocument',
  /** column name */
  Purpose = 'Purpose',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.document" */
export type DocumentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type DocumentStddevFields = {
  __typename?: 'document_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type DocumentStddevPopFields = {
  __typename?: 'document_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type DocumentStddevSampFields = {
  __typename?: 'document_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "document" */
export type DocumentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: DocumentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type DocumentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentDocument?: InputMaybe<Scalars['uuid']['input']>;
  Purpose?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type DocumentSumFields = {
  __typename?: 'document_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.document" */
export enum DocumentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DocumentType = 'DocumentType',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentDocument = 'ParentDocument',
  /** column name */
  Purpose = 'Purpose',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title'
}

export type DocumentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<DocumentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<DocumentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<DocumentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<DocumentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<DocumentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<DocumentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<DocumentSetInput>;
  /** filter the rows which have to be updated */
  where: DocumentBoolExp;
};

/** aggregate var_pop on columns */
export type DocumentVarPopFields = {
  __typename?: 'document_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type DocumentVarSampFields = {
  __typename?: 'document_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type DocumentVarianceFields = {
  __typename?: 'document_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.file" */
export type File = {
  __typename?: 'file';
  ContentType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName: Scalars['String']['output'];
  FileSize: Scalars['Int']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  documentFile?: Maybe<DocumentFile>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  relationFile: Array<RelationFile>;
  /** An aggregate relationship */
  relationFile_aggregate: RelationFileAggregate;
};


/** columns and relationships of "risksmart.file" */
export type FileMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.file" */
export type FileRelationFileArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.file" */
export type FileRelationFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};

/** aggregated selection of "risksmart.file" */
export type FileAggregate = {
  __typename?: 'file_aggregate';
  aggregate?: Maybe<FileAggregateFields>;
  nodes: Array<File>;
};

/** aggregate fields of "risksmart.file" */
export type FileAggregateFields = {
  __typename?: 'file_aggregate_fields';
  avg?: Maybe<FileAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FileMaxFields>;
  min?: Maybe<FileMinFields>;
  stddev?: Maybe<FileStddevFields>;
  stddev_pop?: Maybe<FileStddevPopFields>;
  stddev_samp?: Maybe<FileStddevSampFields>;
  sum?: Maybe<FileSumFields>;
  var_pop?: Maybe<FileVarPopFields>;
  var_samp?: Maybe<FileVarSampFields>;
  variance?: Maybe<FileVarianceFields>;
};


/** aggregate fields of "risksmart.file" */
export type FileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.file_audit" */
export type FileAudit = {
  __typename?: 'file_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ContentType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName: Scalars['String']['output'];
  FileSize: Scalars['Int']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.file_audit" */
export type FileAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.file_audit" */
export type FileAuditAggregate = {
  __typename?: 'file_audit_aggregate';
  aggregate?: Maybe<FileAuditAggregateFields>;
  nodes: Array<FileAudit>;
};

/** aggregate fields of "risksmart.file_audit" */
export type FileAuditAggregateFields = {
  __typename?: 'file_audit_aggregate_fields';
  avg?: Maybe<FileAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FileAuditMaxFields>;
  min?: Maybe<FileAuditMinFields>;
  stddev?: Maybe<FileAuditStddevFields>;
  stddev_pop?: Maybe<FileAuditStddevPopFields>;
  stddev_samp?: Maybe<FileAuditStddevSampFields>;
  sum?: Maybe<FileAuditSumFields>;
  var_pop?: Maybe<FileAuditVarPopFields>;
  var_samp?: Maybe<FileAuditVarSampFields>;
  variance?: Maybe<FileAuditVarianceFields>;
};


/** aggregate fields of "risksmart.file_audit" */
export type FileAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FileAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type FileAuditAvgFields = {
  __typename?: 'file_audit_avg_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.file_audit". All fields are combined with a logical 'AND'. */
export type FileAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ContentType?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FileName?: InputMaybe<StringComparisonExp>;
  FileSize?: InputMaybe<IntComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<FileAuditBoolExp>>;
  _not?: InputMaybe<FileAuditBoolExp>;
  _or?: InputMaybe<Array<FileAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.file_audit" */
export enum FileAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  FileAuditPkey = 'file_audit_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.file_audit" */
export type FileAuditIncInput = {
  FileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.file_audit" */
export type FileAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type FileAuditMaxFields = {
  __typename?: 'file_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ContentType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FileSize?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type FileAuditMinFields = {
  __typename?: 'file_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ContentType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FileSize?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.file_audit" */
export type FileAuditMutationResponse = {
  __typename?: 'file_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FileAudit>;
};

/** on_conflict condition type for table "risksmart.file_audit" */
export type FileAuditOnConflict = {
  constraint: FileAuditConstraint;
  update_columns?: Array<FileAuditUpdateColumn>;
  where?: InputMaybe<FileAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.file_audit". */
export type FileAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ContentType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileName?: InputMaybe<OrderBy>;
  FileSize?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.file_audit */
export type FileAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.file_audit" */
export enum FileAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ContentType = 'ContentType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileName = 'FileName',
  /** column name */
  FileSize = 'FileSize',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.file_audit" */
export type FileAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type FileAuditStddevFields = {
  __typename?: 'file_audit_stddev_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FileAuditStddevPopFields = {
  __typename?: 'file_audit_stddev_pop_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FileAuditStddevSampFields = {
  __typename?: 'file_audit_stddev_samp_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "file_audit" */
export type FileAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FileAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FileAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type FileAuditSumFields = {
  __typename?: 'file_audit_sum_fields';
  FileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.file_audit" */
export enum FileAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ContentType = 'ContentType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileName = 'FileName',
  /** column name */
  FileSize = 'FileSize',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type FileAuditUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FileAuditIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileAuditSetInput>;
  /** filter the rows which have to be updated */
  where: FileAuditBoolExp;
};

/** aggregate var_pop on columns */
export type FileAuditVarPopFields = {
  __typename?: 'file_audit_var_pop_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FileAuditVarSampFields = {
  __typename?: 'file_audit_var_samp_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FileAuditVarianceFields = {
  __typename?: 'file_audit_variance_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type FileAvgFields = {
  __typename?: 'file_avg_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.file". All fields are combined with a logical 'AND'. */
export type FileBoolExp = {
  ContentType?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FileName?: InputMaybe<StringComparisonExp>;
  FileSize?: InputMaybe<IntComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<FileBoolExp>>;
  _not?: InputMaybe<FileBoolExp>;
  _or?: InputMaybe<Array<FileBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  documentFile?: InputMaybe<DocumentFileBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  relationFile?: InputMaybe<RelationFileBoolExp>;
  relationFile_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.file" */
export enum FileConstraint {
  /** unique or primary key constraint on columns "Id" */
  FilePkey = 'file_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.file" */
export type FileIncInput = {
  FileSize?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.file" */
export type FileInsertInput = {
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  documentFile?: InputMaybe<DocumentFileObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  relationFile?: InputMaybe<RelationFileArrRelInsertInput>;
};

/** aggregate max on columns */
export type FileMaxFields = {
  __typename?: 'file_max_fields';
  ContentType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FileSize?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type FileMinFields = {
  __typename?: 'file_min_fields';
  ContentType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileName?: Maybe<Scalars['String']['output']>;
  FileSize?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.file" */
export type FileMutationResponse = {
  __typename?: 'file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "risksmart.file" */
export type FileObjRelInsertInput = {
  data: FileInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FileOnConflict>;
};

/** on_conflict condition type for table "risksmart.file" */
export type FileOnConflict = {
  constraint: FileConstraint;
  update_columns?: Array<FileUpdateColumn>;
  where?: InputMaybe<FileBoolExp>;
};

/** Ordering options when selecting data from "risksmart.file". */
export type FileOrderBy = {
  ContentType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileName?: InputMaybe<OrderBy>;
  FileSize?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  documentFile?: InputMaybe<DocumentFileOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  relationFile_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.file */
export type FilePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.file" */
export enum FileSelectColumn {
  /** column name */
  ContentType = 'ContentType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileName = 'FileName',
  /** column name */
  FileSize = 'FileSize',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.file" */
export type FileSetInput = {
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type FileStddevFields = {
  __typename?: 'file_stddev_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FileStddevPopFields = {
  __typename?: 'file_stddev_pop_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FileStddevSampFields = {
  __typename?: 'file_stddev_samp_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "file" */
export type FileStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FileStreamCursorValueInput = {
  ContentType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileName?: InputMaybe<Scalars['String']['input']>;
  FileSize?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type FileSumFields = {
  __typename?: 'file_sum_fields';
  FileSize?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.file" */
export enum FileUpdateColumn {
  /** column name */
  ContentType = 'ContentType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileName = 'FileName',
  /** column name */
  FileSize = 'FileSize',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type FileUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FileIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FileSetInput>;
  /** filter the rows which have to be updated */
  where: FileBoolExp;
};

/** aggregate var_pop on columns */
export type FileVarPopFields = {
  __typename?: 'file_var_pop_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FileVarSampFields = {
  __typename?: 'file_var_samp_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FileVarianceFields = {
  __typename?: 'file_variance_fields';
  FileSize?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8ComparisonExp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "risksmart.form_configuration" */
export type FormConfiguration = {
  __typename?: 'form_configuration';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentType: ParentTypeEnum;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  customAttributeSchema?: Maybe<CustomAttributeSchema>;
  /** An array relationship */
  fields_config: Array<FormFieldConfiguration>;
  /** An aggregate relationship */
  fields_config_aggregate: FormFieldConfigurationAggregate;
  /** An array relationship */
  fields_ordering: Array<FormFieldOrdering>;
  /** An aggregate relationship */
  fields_ordering_aggregate: FormFieldOrderingAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  parentType: ParentType;
};


/** columns and relationships of "risksmart.form_configuration" */
export type FormConfigurationFieldsConfigArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


/** columns and relationships of "risksmart.form_configuration" */
export type FormConfigurationFieldsConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


/** columns and relationships of "risksmart.form_configuration" */
export type FormConfigurationFieldsOrderingArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingOrderBy>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};


/** columns and relationships of "risksmart.form_configuration" */
export type FormConfigurationFieldsOrderingAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingOrderBy>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};

/** aggregated selection of "risksmart.form_configuration" */
export type FormConfigurationAggregate = {
  __typename?: 'form_configuration_aggregate';
  aggregate?: Maybe<FormConfigurationAggregateFields>;
  nodes: Array<FormConfiguration>;
};

/** aggregate fields of "risksmart.form_configuration" */
export type FormConfigurationAggregateFields = {
  __typename?: 'form_configuration_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<FormConfigurationMaxFields>;
  min?: Maybe<FormConfigurationMinFields>;
};


/** aggregate fields of "risksmart.form_configuration" */
export type FormConfigurationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormConfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.form_configuration_audit" */
export type FormConfigurationAudit = {
  __typename?: 'form_configuration_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentType: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.form_configuration_audit" */
export type FormConfigurationAuditAggregate = {
  __typename?: 'form_configuration_audit_aggregate';
  aggregate?: Maybe<FormConfigurationAuditAggregateFields>;
  nodes: Array<FormConfigurationAudit>;
};

/** aggregate fields of "risksmart.form_configuration_audit" */
export type FormConfigurationAuditAggregateFields = {
  __typename?: 'form_configuration_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<FormConfigurationAuditMaxFields>;
  min?: Maybe<FormConfigurationAuditMinFields>;
};


/** aggregate fields of "risksmart.form_configuration_audit" */
export type FormConfigurationAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormConfigurationAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.form_configuration_audit". All fields are combined with a logical 'AND'. */
export type FormConfigurationAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeSchemaId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentType?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<FormConfigurationAuditBoolExp>>;
  _not?: InputMaybe<FormConfigurationAuditBoolExp>;
  _or?: InputMaybe<Array<FormConfigurationAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.form_configuration_audit" */
export enum FormConfigurationAuditConstraint {
  /** unique or primary key constraint on columns "ModifiedAtTimestamp", "ParentType" */
  FormConfigurationAuditPkey = 'form_configuration_audit_pkey'
}

/** input type for inserting data into table "risksmart.form_configuration_audit" */
export type FormConfigurationAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type FormConfigurationAuditMaxFields = {
  __typename?: 'form_configuration_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type FormConfigurationAuditMinFields = {
  __typename?: 'form_configuration_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.form_configuration_audit" */
export type FormConfigurationAuditMutationResponse = {
  __typename?: 'form_configuration_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FormConfigurationAudit>;
};

/** on_conflict condition type for table "risksmart.form_configuration_audit" */
export type FormConfigurationAuditOnConflict = {
  constraint: FormConfigurationAuditConstraint;
  update_columns?: Array<FormConfigurationAuditUpdateColumn>;
  where?: InputMaybe<FormConfigurationAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.form_configuration_audit". */
export type FormConfigurationAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeSchemaId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.form_configuration_audit */
export type FormConfigurationAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentType: Scalars['String']['input'];
};

/** select columns of table "risksmart.form_configuration_audit" */
export enum FormConfigurationAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeSchemaId = 'CustomAttributeSchemaId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.form_configuration_audit" */
export type FormConfigurationAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "form_configuration_audit" */
export type FormConfigurationAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FormConfigurationAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormConfigurationAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.form_configuration_audit" */
export enum FormConfigurationAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeSchemaId = 'CustomAttributeSchemaId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

export type FormConfigurationAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormConfigurationAuditSetInput>;
  /** filter the rows which have to be updated */
  where: FormConfigurationAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.form_configuration". All fields are combined with a logical 'AND'. */
export type FormConfigurationBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeSchemaId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<FormConfigurationBoolExp>>;
  _not?: InputMaybe<FormConfigurationBoolExp>;
  _or?: InputMaybe<Array<FormConfigurationBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  customAttributeSchema?: InputMaybe<CustomAttributeSchemaBoolExp>;
  fields_config?: InputMaybe<FormFieldConfigurationBoolExp>;
  fields_config_aggregate?: InputMaybe<FormFieldConfigurationAggregateBoolExp>;
  fields_ordering?: InputMaybe<FormFieldOrderingBoolExp>;
  fields_ordering_aggregate?: InputMaybe<FormFieldOrderingAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  parentType?: InputMaybe<ParentTypeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.form_configuration" */
export enum FormConfigurationConstraint {
  /** unique or primary key constraint on columns "OrgKey", "ParentType" */
  FormConfigurationPkey = 'form_configuration_pkey'
}

/** input type for inserting data into table "risksmart.form_configuration" */
export type FormConfigurationInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  customAttributeSchema?: InputMaybe<CustomAttributeSchemaObjRelInsertInput>;
  fields_config?: InputMaybe<FormFieldConfigurationArrRelInsertInput>;
  fields_ordering?: InputMaybe<FormFieldOrderingArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  parentType?: InputMaybe<ParentTypeObjRelInsertInput>;
};

/** aggregate max on columns */
export type FormConfigurationMaxFields = {
  __typename?: 'form_configuration_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type FormConfigurationMinFields = {
  __typename?: 'form_configuration_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeSchemaId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.form_configuration" */
export type FormConfigurationMutationResponse = {
  __typename?: 'form_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FormConfiguration>;
};

/** input type for inserting object relation for remote table "risksmart.form_configuration" */
export type FormConfigurationObjRelInsertInput = {
  data: FormConfigurationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<FormConfigurationOnConflict>;
};

/** on_conflict condition type for table "risksmart.form_configuration" */
export type FormConfigurationOnConflict = {
  constraint: FormConfigurationConstraint;
  update_columns?: Array<FormConfigurationUpdateColumn>;
  where?: InputMaybe<FormConfigurationBoolExp>;
};

/** Ordering options when selecting data from "risksmart.form_configuration". */
export type FormConfigurationOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeSchemaId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  customAttributeSchema?: InputMaybe<CustomAttributeSchemaOrderBy>;
  fields_config_aggregate?: InputMaybe<FormFieldConfigurationAggregateOrderBy>;
  fields_ordering_aggregate?: InputMaybe<FormFieldOrderingAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  parentType?: InputMaybe<ParentTypeOrderBy>;
};

/** primary key columns input for table: risksmart.form_configuration */
export type FormConfigurationPkColumnsInput = {
  OrgKey: Scalars['String']['input'];
  ParentType: ParentTypeEnum;
};

/** select columns of table "risksmart.form_configuration" */
export enum FormConfigurationSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeSchemaId = 'CustomAttributeSchemaId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.form_configuration" */
export type FormConfigurationSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** Streaming cursor of the table "form_configuration" */
export type FormConfigurationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FormConfigurationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormConfigurationStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeSchemaId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** update columns of table "risksmart.form_configuration" */
export enum FormConfigurationUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeSchemaId = 'CustomAttributeSchemaId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentType = 'ParentType'
}

export type FormConfigurationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormConfigurationSetInput>;
  /** filter the rows which have to be updated */
  where: FormConfigurationBoolExp;
};

/** columns and relationships of "risksmart.form_field_configuration" */
export type FormFieldConfiguration = {
  __typename?: 'form_field_configuration';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: ParentTypeEnum;
  Hidden: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ReadOnly: Scalars['Boolean']['output'];
  Required: Scalars['Boolean']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  form?: Maybe<FormConfiguration>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};

/** aggregated selection of "risksmart.form_field_configuration" */
export type FormFieldConfigurationAggregate = {
  __typename?: 'form_field_configuration_aggregate';
  aggregate?: Maybe<FormFieldConfigurationAggregateFields>;
  nodes: Array<FormFieldConfiguration>;
};

export type FormFieldConfigurationAggregateBoolExp = {
  bool_and?: InputMaybe<FormFieldConfigurationAggregateBoolExpBoolAnd>;
  bool_or?: InputMaybe<FormFieldConfigurationAggregateBoolExpBoolOr>;
  count?: InputMaybe<FormFieldConfigurationAggregateBoolExpCount>;
};

export type FormFieldConfigurationAggregateBoolExpBoolAnd = {
  arguments: FormFieldConfigurationSelectColumnFormFieldConfigurationAggregateBoolExpBoolAndArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FormFieldConfigurationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FormFieldConfigurationAggregateBoolExpBoolOr = {
  arguments: FormFieldConfigurationSelectColumnFormFieldConfigurationAggregateBoolExpBoolOrArgumentsColumns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FormFieldConfigurationBoolExp>;
  predicate: BooleanComparisonExp;
};

export type FormFieldConfigurationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FormFieldConfigurationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.form_field_configuration" */
export type FormFieldConfigurationAggregateFields = {
  __typename?: 'form_field_configuration_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<FormFieldConfigurationMaxFields>;
  min?: Maybe<FormFieldConfigurationMinFields>;
};


/** aggregate fields of "risksmart.form_field_configuration" */
export type FormFieldConfigurationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.form_field_configuration" */
export type FormFieldConfigurationAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormFieldConfigurationMaxOrderBy>;
  min?: InputMaybe<FormFieldConfigurationMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.form_field_configuration" */
export type FormFieldConfigurationArrRelInsertInput = {
  data: Array<FormFieldConfigurationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FormFieldConfigurationOnConflict>;
};

/** columns and relationships of "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAudit = {
  __typename?: 'form_field_configuration_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: Scalars['String']['output'];
  Hidden: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ReadOnly: Scalars['Boolean']['output'];
  Required: Scalars['Boolean']['output'];
};

/** aggregated selection of "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditAggregate = {
  __typename?: 'form_field_configuration_audit_aggregate';
  aggregate?: Maybe<FormFieldConfigurationAuditAggregateFields>;
  nodes: Array<FormFieldConfigurationAudit>;
};

/** aggregate fields of "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditAggregateFields = {
  __typename?: 'form_field_configuration_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<FormFieldConfigurationAuditMaxFields>;
  min?: Maybe<FormFieldConfigurationAuditMinFields>;
};


/** aggregate fields of "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormFieldConfigurationAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.form_field_configuration_audit". All fields are combined with a logical 'AND'. */
export type FormFieldConfigurationAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DefaultValue?: InputMaybe<StringComparisonExp>;
  FieldId?: InputMaybe<StringComparisonExp>;
  FormConfigurationParentType?: InputMaybe<StringComparisonExp>;
  Hidden?: InputMaybe<BooleanComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ReadOnly?: InputMaybe<BooleanComparisonExp>;
  Required?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<FormFieldConfigurationAuditBoolExp>>;
  _not?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
  _or?: InputMaybe<Array<FormFieldConfigurationAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.form_field_configuration_audit" */
export enum FormFieldConfigurationAuditConstraint {
  /** unique or primary key constraint on columns "FormConfigurationParentType", "ModifiedAtTimestamp", "FieldId" */
  FormFieldConfigurationAuditPkey1 = 'form_field_configuration_audit_pkey1'
}

/** input type for inserting data into table "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type FormFieldConfigurationAuditMaxFields = {
  __typename?: 'form_field_configuration_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  FormConfigurationParentType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type FormFieldConfigurationAuditMinFields = {
  __typename?: 'form_field_configuration_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  FormConfigurationParentType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditMutationResponse = {
  __typename?: 'form_field_configuration_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FormFieldConfigurationAudit>;
};

/** on_conflict condition type for table "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditOnConflict = {
  constraint: FormFieldConfigurationAuditConstraint;
  update_columns?: Array<FormFieldConfigurationAuditUpdateColumn>;
  where?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.form_field_configuration_audit". */
export type FormFieldConfigurationAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DefaultValue?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  FormConfigurationParentType?: InputMaybe<OrderBy>;
  Hidden?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ReadOnly?: InputMaybe<OrderBy>;
  Required?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.form_field_configuration_audit */
export type FormFieldConfigurationAuditPkColumnsInput = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.form_field_configuration_audit" */
export enum FormFieldConfigurationAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DefaultValue = 'DefaultValue',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  Hidden = 'Hidden',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ReadOnly = 'ReadOnly',
  /** column name */
  Required = 'Required'
}

/** input type for updating data in table "risksmart.form_field_configuration_audit" */
export type FormFieldConfigurationAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "form_field_configuration_audit" */
export type FormFieldConfigurationAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FormFieldConfigurationAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormFieldConfigurationAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.form_field_configuration_audit" */
export enum FormFieldConfigurationAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DefaultValue = 'DefaultValue',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  Hidden = 'Hidden',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ReadOnly = 'ReadOnly',
  /** column name */
  Required = 'Required'
}

export type FormFieldConfigurationAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormFieldConfigurationAuditSetInput>;
  /** filter the rows which have to be updated */
  where: FormFieldConfigurationAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.form_field_configuration". All fields are combined with a logical 'AND'. */
export type FormFieldConfigurationBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  DefaultValue?: InputMaybe<StringComparisonExp>;
  FieldId?: InputMaybe<StringComparisonExp>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  Hidden?: InputMaybe<BooleanComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ReadOnly?: InputMaybe<BooleanComparisonExp>;
  Required?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<FormFieldConfigurationBoolExp>>;
  _not?: InputMaybe<FormFieldConfigurationBoolExp>;
  _or?: InputMaybe<Array<FormFieldConfigurationBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  form?: InputMaybe<FormConfigurationBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.form_field_configuration" */
export enum FormFieldConfigurationConstraint {
  /** unique or primary key constraint on columns "FormConfigurationParentType", "OrgKey", "FieldId" */
  FormFieldConfigurationPkey1 = 'form_field_configuration_pkey1'
}

/** input type for inserting data into table "risksmart.form_field_configuration" */
export type FormFieldConfigurationInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnum>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  form?: InputMaybe<FormConfigurationObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type FormFieldConfigurationMaxFields = {
  __typename?: 'form_field_configuration_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.form_field_configuration" */
export type FormFieldConfigurationMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DefaultValue?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormFieldConfigurationMinFields = {
  __typename?: 'form_field_configuration_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DefaultValue?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.form_field_configuration" */
export type FormFieldConfigurationMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DefaultValue?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.form_field_configuration" */
export type FormFieldConfigurationMutationResponse = {
  __typename?: 'form_field_configuration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FormFieldConfiguration>;
};

/** on_conflict condition type for table "risksmart.form_field_configuration" */
export type FormFieldConfigurationOnConflict = {
  constraint: FormFieldConfigurationConstraint;
  update_columns?: Array<FormFieldConfigurationUpdateColumn>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};

/** Ordering options when selecting data from "risksmart.form_field_configuration". */
export type FormFieldConfigurationOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  DefaultValue?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  FormConfigurationParentType?: InputMaybe<OrderBy>;
  Hidden?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ReadOnly?: InputMaybe<OrderBy>;
  Required?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  form?: InputMaybe<FormConfigurationOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.form_field_configuration */
export type FormFieldConfigurationPkColumnsInput = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.form_field_configuration" */
export enum FormFieldConfigurationSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DefaultValue = 'DefaultValue',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  Hidden = 'Hidden',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ReadOnly = 'ReadOnly',
  /** column name */
  Required = 'Required'
}

/** select "form_field_configuration_aggregate_bool_exp_bool_and_arguments_columns" columns of table "risksmart.form_field_configuration" */
export enum FormFieldConfigurationSelectColumnFormFieldConfigurationAggregateBoolExpBoolAndArgumentsColumns {
  /** column name */
  Hidden = 'Hidden',
  /** column name */
  ReadOnly = 'ReadOnly',
  /** column name */
  Required = 'Required'
}

/** select "form_field_configuration_aggregate_bool_exp_bool_or_arguments_columns" columns of table "risksmart.form_field_configuration" */
export enum FormFieldConfigurationSelectColumnFormFieldConfigurationAggregateBoolExpBoolOrArgumentsColumns {
  /** column name */
  Hidden = 'Hidden',
  /** column name */
  ReadOnly = 'ReadOnly',
  /** column name */
  Required = 'Required'
}

/** input type for updating data in table "risksmart.form_field_configuration" */
export type FormFieldConfigurationSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnum>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "form_field_configuration" */
export type FormFieldConfigurationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FormFieldConfigurationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormFieldConfigurationStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  DefaultValue?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnum>;
  Hidden?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ReadOnly?: InputMaybe<Scalars['Boolean']['input']>;
  Required?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.form_field_configuration" */
export enum FormFieldConfigurationUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  DefaultValue = 'DefaultValue',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  Hidden = 'Hidden',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ReadOnly = 'ReadOnly',
  /** column name */
  Required = 'Required'
}

export type FormFieldConfigurationUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormFieldConfigurationSetInput>;
  /** filter the rows which have to be updated */
  where: FormFieldConfigurationBoolExp;
};

/** columns and relationships of "risksmart.form_field_ordering" */
export type FormFieldOrdering = {
  __typename?: 'form_field_ordering';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: ParentTypeEnum;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Position: Scalars['Int']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  form?: Maybe<FormConfiguration>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
};

/** aggregated selection of "risksmart.form_field_ordering" */
export type FormFieldOrderingAggregate = {
  __typename?: 'form_field_ordering_aggregate';
  aggregate?: Maybe<FormFieldOrderingAggregateFields>;
  nodes: Array<FormFieldOrdering>;
};

export type FormFieldOrderingAggregateBoolExp = {
  count?: InputMaybe<FormFieldOrderingAggregateBoolExpCount>;
};

export type FormFieldOrderingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<FormFieldOrderingBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.form_field_ordering" */
export type FormFieldOrderingAggregateFields = {
  __typename?: 'form_field_ordering_aggregate_fields';
  avg?: Maybe<FormFieldOrderingAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FormFieldOrderingMaxFields>;
  min?: Maybe<FormFieldOrderingMinFields>;
  stddev?: Maybe<FormFieldOrderingStddevFields>;
  stddev_pop?: Maybe<FormFieldOrderingStddevPopFields>;
  stddev_samp?: Maybe<FormFieldOrderingStddevSampFields>;
  sum?: Maybe<FormFieldOrderingSumFields>;
  var_pop?: Maybe<FormFieldOrderingVarPopFields>;
  var_samp?: Maybe<FormFieldOrderingVarSampFields>;
  variance?: Maybe<FormFieldOrderingVarianceFields>;
};


/** aggregate fields of "risksmart.form_field_ordering" */
export type FormFieldOrderingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.form_field_ordering" */
export type FormFieldOrderingAggregateOrderBy = {
  avg?: InputMaybe<FormFieldOrderingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<FormFieldOrderingMaxOrderBy>;
  min?: InputMaybe<FormFieldOrderingMinOrderBy>;
  stddev?: InputMaybe<FormFieldOrderingStddevOrderBy>;
  stddev_pop?: InputMaybe<FormFieldOrderingStddevPopOrderBy>;
  stddev_samp?: InputMaybe<FormFieldOrderingStddevSampOrderBy>;
  sum?: InputMaybe<FormFieldOrderingSumOrderBy>;
  var_pop?: InputMaybe<FormFieldOrderingVarPopOrderBy>;
  var_samp?: InputMaybe<FormFieldOrderingVarSampOrderBy>;
  variance?: InputMaybe<FormFieldOrderingVarianceOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.form_field_ordering" */
export type FormFieldOrderingArrRelInsertInput = {
  data: Array<FormFieldOrderingInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<FormFieldOrderingOnConflict>;
};

/** columns and relationships of "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAudit = {
  __typename?: 'form_field_ordering_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FieldId: Scalars['String']['output'];
  FormConfigurationParentType: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Position: Scalars['Int']['output'];
};

/** aggregated selection of "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditAggregate = {
  __typename?: 'form_field_ordering_audit_aggregate';
  aggregate?: Maybe<FormFieldOrderingAuditAggregateFields>;
  nodes: Array<FormFieldOrderingAudit>;
};

/** aggregate fields of "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditAggregateFields = {
  __typename?: 'form_field_ordering_audit_aggregate_fields';
  avg?: Maybe<FormFieldOrderingAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<FormFieldOrderingAuditMaxFields>;
  min?: Maybe<FormFieldOrderingAuditMinFields>;
  stddev?: Maybe<FormFieldOrderingAuditStddevFields>;
  stddev_pop?: Maybe<FormFieldOrderingAuditStddevPopFields>;
  stddev_samp?: Maybe<FormFieldOrderingAuditStddevSampFields>;
  sum?: Maybe<FormFieldOrderingAuditSumFields>;
  var_pop?: Maybe<FormFieldOrderingAuditVarPopFields>;
  var_samp?: Maybe<FormFieldOrderingAuditVarSampFields>;
  variance?: Maybe<FormFieldOrderingAuditVarianceFields>;
};


/** aggregate fields of "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<FormFieldOrderingAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type FormFieldOrderingAuditAvgFields = {
  __typename?: 'form_field_ordering_audit_avg_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.form_field_ordering_audit". All fields are combined with a logical 'AND'. */
export type FormFieldOrderingAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FieldId?: InputMaybe<StringComparisonExp>;
  FormConfigurationParentType?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Position?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<FormFieldOrderingAuditBoolExp>>;
  _not?: InputMaybe<FormFieldOrderingAuditBoolExp>;
  _or?: InputMaybe<Array<FormFieldOrderingAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.form_field_ordering_audit" */
export enum FormFieldOrderingAuditConstraint {
  /** unique or primary key constraint on columns "FormConfigurationParentType", "ModifiedAtTimestamp", "FieldId" */
  FormFieldConfigurationAuditPkey = 'form_field_configuration_audit_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditIncInput = {
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type FormFieldOrderingAuditMaxFields = {
  __typename?: 'form_field_ordering_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  FormConfigurationParentType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type FormFieldOrderingAuditMinFields = {
  __typename?: 'form_field_ordering_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  FormConfigurationParentType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditMutationResponse = {
  __typename?: 'form_field_ordering_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FormFieldOrderingAudit>;
};

/** on_conflict condition type for table "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditOnConflict = {
  constraint: FormFieldOrderingAuditConstraint;
  update_columns?: Array<FormFieldOrderingAuditUpdateColumn>;
  where?: InputMaybe<FormFieldOrderingAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.form_field_ordering_audit". */
export type FormFieldOrderingAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  FormConfigurationParentType?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Position?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.form_field_ordering_audit */
export type FormFieldOrderingAuditPkColumnsInput = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.form_field_ordering_audit" */
export enum FormFieldOrderingAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Position = 'Position'
}

/** input type for updating data in table "risksmart.form_field_ordering_audit" */
export type FormFieldOrderingAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type FormFieldOrderingAuditStddevFields = {
  __typename?: 'form_field_ordering_audit_stddev_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type FormFieldOrderingAuditStddevPopFields = {
  __typename?: 'form_field_ordering_audit_stddev_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type FormFieldOrderingAuditStddevSampFields = {
  __typename?: 'form_field_ordering_audit_stddev_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "form_field_ordering_audit" */
export type FormFieldOrderingAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FormFieldOrderingAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormFieldOrderingAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type FormFieldOrderingAuditSumFields = {
  __typename?: 'form_field_ordering_audit_sum_fields';
  Position?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.form_field_ordering_audit" */
export enum FormFieldOrderingAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Position = 'Position'
}

export type FormFieldOrderingAuditUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormFieldOrderingAuditIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormFieldOrderingAuditSetInput>;
  /** filter the rows which have to be updated */
  where: FormFieldOrderingAuditBoolExp;
};

/** aggregate var_pop on columns */
export type FormFieldOrderingAuditVarPopFields = {
  __typename?: 'form_field_ordering_audit_var_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type FormFieldOrderingAuditVarSampFields = {
  __typename?: 'form_field_ordering_audit_var_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type FormFieldOrderingAuditVarianceFields = {
  __typename?: 'form_field_ordering_audit_variance_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type FormFieldOrderingAvgFields = {
  __typename?: 'form_field_ordering_avg_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingAvgOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.form_field_ordering". All fields are combined with a logical 'AND'. */
export type FormFieldOrderingBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FieldId?: InputMaybe<StringComparisonExp>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Position?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<FormFieldOrderingBoolExp>>;
  _not?: InputMaybe<FormFieldOrderingBoolExp>;
  _or?: InputMaybe<Array<FormFieldOrderingBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  form?: InputMaybe<FormConfigurationBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.form_field_ordering" */
export enum FormFieldOrderingConstraint {
  /** unique or primary key constraint on columns "FormConfigurationParentType", "OrgKey", "FieldId" */
  FormFieldConfigurationPkey = 'form_field_configuration_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.form_field_ordering" */
export type FormFieldOrderingIncInput = {
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.form_field_ordering" */
export type FormFieldOrderingInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  form?: InputMaybe<FormConfigurationObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type FormFieldOrderingMaxFields = {
  __typename?: 'form_field_ordering_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
};

/** order by max() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Position?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type FormFieldOrderingMinFields = {
  __typename?: 'form_field_ordering_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FieldId?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Position?: Maybe<Scalars['Int']['output']>;
};

/** order by min() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Position?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.form_field_ordering" */
export type FormFieldOrderingMutationResponse = {
  __typename?: 'form_field_ordering_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<FormFieldOrdering>;
};

/** on_conflict condition type for table "risksmart.form_field_ordering" */
export type FormFieldOrderingOnConflict = {
  constraint: FormFieldOrderingConstraint;
  update_columns?: Array<FormFieldOrderingUpdateColumn>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};

/** Ordering options when selecting data from "risksmart.form_field_ordering". */
export type FormFieldOrderingOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FieldId?: InputMaybe<OrderBy>;
  FormConfigurationParentType?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Position?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  form?: InputMaybe<FormConfigurationOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.form_field_ordering */
export type FormFieldOrderingPkColumnsInput = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.form_field_ordering" */
export enum FormFieldOrderingSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Position = 'Position'
}

/** input type for updating data in table "risksmart.form_field_ordering" */
export type FormFieldOrderingSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type FormFieldOrderingStddevFields = {
  __typename?: 'form_field_ordering_stddev_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingStddevOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type FormFieldOrderingStddevPopFields = {
  __typename?: 'form_field_ordering_stddev_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingStddevPopOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type FormFieldOrderingStddevSampFields = {
  __typename?: 'form_field_ordering_stddev_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingStddevSampOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "form_field_ordering" */
export type FormFieldOrderingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: FormFieldOrderingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type FormFieldOrderingStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FieldId?: InputMaybe<Scalars['String']['input']>;
  FormConfigurationParentType?: InputMaybe<ParentTypeEnum>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Position?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type FormFieldOrderingSumFields = {
  __typename?: 'form_field_ordering_sum_fields';
  Position?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingSumOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.form_field_ordering" */
export enum FormFieldOrderingUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FieldId = 'FieldId',
  /** column name */
  FormConfigurationParentType = 'FormConfigurationParentType',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Position = 'Position'
}

export type FormFieldOrderingUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<FormFieldOrderingIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<FormFieldOrderingSetInput>;
  /** filter the rows which have to be updated */
  where: FormFieldOrderingBoolExp;
};

/** aggregate var_pop on columns */
export type FormFieldOrderingVarPopFields = {
  __typename?: 'form_field_ordering_var_pop_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingVarPopOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type FormFieldOrderingVarSampFields = {
  __typename?: 'form_field_ordering_var_samp_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingVarSampOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type FormFieldOrderingVarianceFields = {
  __typename?: 'form_field_ordering_variance_fields';
  Position?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.form_field_ordering" */
export type FormFieldOrderingVarianceOrderBy = {
  Position?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.impact" */
export type Impact = {
  __typename?: 'impact';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId: Scalars['Int']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  appetites: Array<AppetiteParent>;
  /** An aggregate relationship */
  appetites_aggregate: AppetiteParentAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  parents: Array<ImpactParent>;
  /** An aggregate relationship */
  parents_aggregate: ImpactParentAggregate;
  /** An array relationship */
  ratings: Array<ImpactRating>;
  /** An aggregate relationship */
  ratings_aggregate: ImpactRatingAggregate;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAppetitesArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactAppetitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactParentsArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentOrderBy>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentOrderBy>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactRatingsArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


/** columns and relationships of "risksmart.impact" */
export type ImpactRatingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};

/** aggregated selection of "risksmart.impact" */
export type ImpactAggregate = {
  __typename?: 'impact_aggregate';
  aggregate?: Maybe<ImpactAggregateFields>;
  nodes: Array<Impact>;
};

/** aggregate fields of "risksmart.impact" */
export type ImpactAggregateFields = {
  __typename?: 'impact_aggregate_fields';
  avg?: Maybe<ImpactAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ImpactMaxFields>;
  min?: Maybe<ImpactMinFields>;
  stddev?: Maybe<ImpactStddevFields>;
  stddev_pop?: Maybe<ImpactStddevPopFields>;
  stddev_samp?: Maybe<ImpactStddevSampFields>;
  sum?: Maybe<ImpactSumFields>;
  var_pop?: Maybe<ImpactVarPopFields>;
  var_samp?: Maybe<ImpactVarSampFields>;
  variance?: Maybe<ImpactVarianceFields>;
};


/** aggregate fields of "risksmart.impact" */
export type ImpactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImpactAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.impact_audit" */
export type ImpactAudit = {
  __typename?: 'impact_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId: Scalars['Int']['output'];
};


/** columns and relationships of "risksmart.impact_audit" */
export type ImpactAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.impact_audit" */
export type ImpactAuditAggregate = {
  __typename?: 'impact_audit_aggregate';
  aggregate?: Maybe<ImpactAuditAggregateFields>;
  nodes: Array<ImpactAudit>;
};

/** aggregate fields of "risksmart.impact_audit" */
export type ImpactAuditAggregateFields = {
  __typename?: 'impact_audit_aggregate_fields';
  avg?: Maybe<ImpactAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ImpactAuditMaxFields>;
  min?: Maybe<ImpactAuditMinFields>;
  stddev?: Maybe<ImpactAuditStddevFields>;
  stddev_pop?: Maybe<ImpactAuditStddevPopFields>;
  stddev_samp?: Maybe<ImpactAuditStddevSampFields>;
  sum?: Maybe<ImpactAuditSumFields>;
  var_pop?: Maybe<ImpactAuditVarPopFields>;
  var_samp?: Maybe<ImpactAuditVarSampFields>;
  variance?: Maybe<ImpactAuditVarianceFields>;
};


/** aggregate fields of "risksmart.impact_audit" */
export type ImpactAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImpactAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ImpactAuditAvgFields = {
  __typename?: 'impact_audit_avg_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_audit". All fields are combined with a logical 'AND'. */
export type ImpactAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactAppetite?: InputMaybe<SmallintComparisonExp>;
  LikelihoodAppetite?: InputMaybe<SmallintComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RatingGuidance?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<ImpactAuditBoolExp>>;
  _not?: InputMaybe<ImpactAuditBoolExp>;
  _or?: InputMaybe<Array<ImpactAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.impact_audit" */
export enum ImpactAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ImpactAuditPkey = 'impact_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImpactAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImpactAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImpactAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.impact_audit" */
export type ImpactAuditIncInput = {
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.impact_audit" */
export type ImpactAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate max on columns */
export type ImpactAuditMaxFields = {
  __typename?: 'impact_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type ImpactAuditMinFields = {
  __typename?: 'impact_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.impact_audit" */
export type ImpactAuditMutationResponse = {
  __typename?: 'impact_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactAudit>;
};

/** on_conflict condition type for table "risksmart.impact_audit" */
export type ImpactAuditOnConflict = {
  constraint: ImpactAuditConstraint;
  update_columns?: Array<ImpactAuditUpdateColumn>;
  where?: InputMaybe<ImpactAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.impact_audit". */
export type ImpactAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactAppetite?: InputMaybe<OrderBy>;
  LikelihoodAppetite?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatingGuidance?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.impact_audit */
export type ImpactAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImpactAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.impact_audit" */
export enum ImpactAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatingGuidance = 'RatingGuidance',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  SequentialId = 'SequentialId'
}

/** input type for updating data in table "risksmart.impact_audit" */
export type ImpactAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ImpactAuditStddevFields = {
  __typename?: 'impact_audit_stddev_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ImpactAuditStddevPopFields = {
  __typename?: 'impact_audit_stddev_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ImpactAuditStddevSampFields = {
  __typename?: 'impact_audit_stddev_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "impact_audit" */
export type ImpactAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImpactAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ImpactAuditSumFields = {
  __typename?: 'impact_audit_sum_fields';
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.impact_audit" */
export enum ImpactAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatingGuidance = 'RatingGuidance',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  SequentialId = 'SequentialId'
}

export type ImpactAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImpactAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImpactAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImpactAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImpactAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImpactAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ImpactAuditVarPopFields = {
  __typename?: 'impact_audit_var_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ImpactAuditVarSampFields = {
  __typename?: 'impact_audit_var_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ImpactAuditVarianceFields = {
  __typename?: 'impact_audit_variance_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ImpactAvgFields = {
  __typename?: 'impact_avg_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.impact". All fields are combined with a logical 'AND'. */
export type ImpactBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactAppetite?: InputMaybe<SmallintComparisonExp>;
  LikelihoodAppetite?: InputMaybe<SmallintComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RatingGuidance?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<ImpactBoolExp>>;
  _not?: InputMaybe<ImpactBoolExp>;
  _or?: InputMaybe<Array<ImpactBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  appetites?: InputMaybe<AppetiteParentBoolExp>;
  appetites_aggregate?: InputMaybe<AppetiteParentAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parents?: InputMaybe<ImpactParentBoolExp>;
  parents_aggregate?: InputMaybe<ImpactParentAggregateBoolExp>;
  ratings?: InputMaybe<ImpactRatingBoolExp>;
  ratings_aggregate?: InputMaybe<ImpactRatingAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.impact" */
export enum ImpactConstraint {
  /** unique or primary key constraint on columns "Id" */
  ImpactPkey = 'impact_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImpactDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImpactDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImpactDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.impact" */
export type ImpactIncInput = {
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.impact" */
export type ImpactInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  appetites?: InputMaybe<AppetiteParentArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parents?: InputMaybe<ImpactParentArrRelInsertInput>;
  ratings?: InputMaybe<ImpactRatingArrRelInsertInput>;
};

/** aggregate max on columns */
export type ImpactMaxFields = {
  __typename?: 'impact_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type ImpactMinFields = {
  __typename?: 'impact_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatingGuidance?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.impact" */
export type ImpactMutationResponse = {
  __typename?: 'impact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Impact>;
};

/** input type for inserting object relation for remote table "risksmart.impact" */
export type ImpactObjRelInsertInput = {
  data: ImpactInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ImpactOnConflict>;
};

/** on_conflict condition type for table "risksmart.impact" */
export type ImpactOnConflict = {
  constraint: ImpactConstraint;
  update_columns?: Array<ImpactUpdateColumn>;
  where?: InputMaybe<ImpactBoolExp>;
};

/** Ordering options when selecting data from "risksmart.impact". */
export type ImpactOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactAppetite?: InputMaybe<OrderBy>;
  LikelihoodAppetite?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatingGuidance?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  appetites_aggregate?: InputMaybe<AppetiteParentAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parents_aggregate?: InputMaybe<ImpactParentAggregateOrderBy>;
  ratings_aggregate?: InputMaybe<ImpactRatingAggregateOrderBy>;
};

/** columns and relationships of "risksmart.impact_parent" */
export type ImpactParent = {
  __typename?: 'impact_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ImpactId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  impact?: Maybe<Impact>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
};

/** aggregated selection of "risksmart.impact_parent" */
export type ImpactParentAggregate = {
  __typename?: 'impact_parent_aggregate';
  aggregate?: Maybe<ImpactParentAggregateFields>;
  nodes: Array<ImpactParent>;
};

export type ImpactParentAggregateBoolExp = {
  count?: InputMaybe<ImpactParentAggregateBoolExpCount>;
};

export type ImpactParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImpactParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ImpactParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.impact_parent" */
export type ImpactParentAggregateFields = {
  __typename?: 'impact_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ImpactParentMaxFields>;
  min?: Maybe<ImpactParentMinFields>;
};


/** aggregate fields of "risksmart.impact_parent" */
export type ImpactParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.impact_parent" */
export type ImpactParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImpactParentMaxOrderBy>;
  min?: InputMaybe<ImpactParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.impact_parent" */
export type ImpactParentArrRelInsertInput = {
  data: Array<ImpactParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImpactParentOnConflict>;
};

/** columns and relationships of "risksmart.impact_parent_audit" */
export type ImpactParentAudit = {
  __typename?: 'impact_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ImpactId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.impact_parent_audit" */
export type ImpactParentAuditAggregate = {
  __typename?: 'impact_parent_audit_aggregate';
  aggregate?: Maybe<ImpactParentAuditAggregateFields>;
  nodes: Array<ImpactParentAudit>;
};

/** aggregate fields of "risksmart.impact_parent_audit" */
export type ImpactParentAuditAggregateFields = {
  __typename?: 'impact_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ImpactParentAuditMaxFields>;
  min?: Maybe<ImpactParentAuditMinFields>;
};


/** aggregate fields of "risksmart.impact_parent_audit" */
export type ImpactParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_parent_audit". All fields are combined with a logical 'AND'. */
export type ImpactParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ImpactId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ImpactParentAuditBoolExp>>;
  _not?: InputMaybe<ImpactParentAuditBoolExp>;
  _or?: InputMaybe<Array<ImpactParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.impact_parent_audit" */
export enum ImpactParentAuditConstraint {
  /** unique or primary key constraint on columns "ImpactId", "ParentId", "ModifiedAtTimestamp" */
  ImpactParentAuditPkey = 'impact_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.impact_parent_audit" */
export type ImpactParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ImpactParentAuditMaxFields = {
  __typename?: 'impact_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ImpactParentAuditMinFields = {
  __typename?: 'impact_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.impact_parent_audit" */
export type ImpactParentAuditMutationResponse = {
  __typename?: 'impact_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactParentAudit>;
};

/** on_conflict condition type for table "risksmart.impact_parent_audit" */
export type ImpactParentAuditOnConflict = {
  constraint: ImpactParentAuditConstraint;
  update_columns?: Array<ImpactParentAuditUpdateColumn>;
  where?: InputMaybe<ImpactParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.impact_parent_audit". */
export type ImpactParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.impact_parent_audit */
export type ImpactParentAuditPkColumnsInput = {
  ImpactId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.impact_parent_audit" */
export enum ImpactParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.impact_parent_audit" */
export type ImpactParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "impact_parent_audit" */
export type ImpactParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImpactParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.impact_parent_audit" */
export enum ImpactParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type ImpactParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.impact_parent". All fields are combined with a logical 'AND'. */
export type ImpactParentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ImpactId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ImpactParentBoolExp>>;
  _not?: InputMaybe<ImpactParentBoolExp>;
  _or?: InputMaybe<Array<ImpactParentBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  impact?: InputMaybe<ImpactBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.impact_parent" */
export enum ImpactParentConstraint {
  /** unique or primary key constraint on columns "ImpactId", "ParentId" */
  ImpactParentPkey = 'impact_parent_pkey'
}

/** input type for inserting data into table "risksmart.impact_parent" */
export type ImpactParentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  impact?: InputMaybe<ImpactObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
};

/** aggregate max on columns */
export type ImpactParentMaxFields = {
  __typename?: 'impact_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.impact_parent" */
export type ImpactParentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImpactParentMinFields = {
  __typename?: 'impact_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.impact_parent" */
export type ImpactParentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.impact_parent" */
export type ImpactParentMutationResponse = {
  __typename?: 'impact_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactParent>;
};

/** on_conflict condition type for table "risksmart.impact_parent" */
export type ImpactParentOnConflict = {
  constraint: ImpactParentConstraint;
  update_columns?: Array<ImpactParentUpdateColumn>;
  where?: InputMaybe<ImpactParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.impact_parent". */
export type ImpactParentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  impact?: InputMaybe<ImpactOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
};

/** primary key columns input for table: risksmart.impact_parent */
export type ImpactParentPkColumnsInput = {
  ImpactId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.impact_parent" */
export enum ImpactParentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.impact_parent" */
export type ImpactParentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "impact_parent" */
export type ImpactParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImpactParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactParentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.impact_parent" */
export enum ImpactParentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type ImpactParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactParentSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactParentBoolExp;
};

/** primary key columns input for table: risksmart.impact */
export type ImpactPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImpactPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.impact_rating" */
export type ImpactRating = {
  __typename?: 'impact_rating';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactId: Scalars['uuid']['output'];
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RatedItemId: Scalars['uuid']['output'];
  Rating: Scalars['smallint']['output'];
  RatingType: Scalars['String']['output'];
  SequentialId: Scalars['Int']['output'];
  TestDate: Scalars['timestamptz']['output'];
  /** An array relationship */
  assessmentParents: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentParents_aggregate: AssessmentResultParentAggregate;
  /** An object relationship */
  completedBy?: Maybe<User>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  impact: Impact;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  ratedItem: Node;
};


/** columns and relationships of "risksmart.impact_rating" */
export type ImpactRatingCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.impact_rating" */
export type ImpactRatingAssessmentParentsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.impact_rating" */
export type ImpactRatingAssessmentParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.impact_rating" */
export type ImpactRatingPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.impact_rating" */
export type ImpactRatingPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.impact_rating" */
export type ImpactRatingAggregate = {
  __typename?: 'impact_rating_aggregate';
  aggregate?: Maybe<ImpactRatingAggregateFields>;
  nodes: Array<ImpactRating>;
};

export type ImpactRatingAggregateBoolExp = {
  count?: InputMaybe<ImpactRatingAggregateBoolExpCount>;
};

export type ImpactRatingAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ImpactRatingBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.impact_rating" */
export type ImpactRatingAggregateFields = {
  __typename?: 'impact_rating_aggregate_fields';
  avg?: Maybe<ImpactRatingAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ImpactRatingMaxFields>;
  min?: Maybe<ImpactRatingMinFields>;
  stddev?: Maybe<ImpactRatingStddevFields>;
  stddev_pop?: Maybe<ImpactRatingStddevPopFields>;
  stddev_samp?: Maybe<ImpactRatingStddevSampFields>;
  sum?: Maybe<ImpactRatingSumFields>;
  var_pop?: Maybe<ImpactRatingVarPopFields>;
  var_samp?: Maybe<ImpactRatingVarSampFields>;
  variance?: Maybe<ImpactRatingVarianceFields>;
};


/** aggregate fields of "risksmart.impact_rating" */
export type ImpactRatingAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.impact_rating" */
export type ImpactRatingAggregateOrderBy = {
  avg?: InputMaybe<ImpactRatingAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImpactRatingMaxOrderBy>;
  min?: InputMaybe<ImpactRatingMinOrderBy>;
  stddev?: InputMaybe<ImpactRatingStddevOrderBy>;
  stddev_pop?: InputMaybe<ImpactRatingStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ImpactRatingStddevSampOrderBy>;
  sum?: InputMaybe<ImpactRatingSumOrderBy>;
  var_pop?: InputMaybe<ImpactRatingVarPopOrderBy>;
  var_samp?: InputMaybe<ImpactRatingVarSampOrderBy>;
  variance?: InputMaybe<ImpactRatingVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImpactRatingAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.impact_rating" */
export type ImpactRatingArrRelInsertInput = {
  data: Array<ImpactRatingInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImpactRatingOnConflict>;
};

/** columns and relationships of "risksmart.impact_rating_audit" */
export type ImpactRatingAudit = {
  __typename?: 'impact_rating_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ImpactId: Scalars['uuid']['output'];
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RatedItemId: Scalars['uuid']['output'];
  Rating: Scalars['smallint']['output'];
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId: Scalars['Int']['output'];
  TestDate: Scalars['timestamptz']['output'];
};


/** columns and relationships of "risksmart.impact_rating_audit" */
export type ImpactRatingAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.impact_rating_audit" */
export type ImpactRatingAuditAggregate = {
  __typename?: 'impact_rating_audit_aggregate';
  aggregate?: Maybe<ImpactRatingAuditAggregateFields>;
  nodes: Array<ImpactRatingAudit>;
};

export type ImpactRatingAuditAggregateBoolExp = {
  count?: InputMaybe<ImpactRatingAuditAggregateBoolExpCount>;
};

export type ImpactRatingAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ImpactRatingAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.impact_rating_audit" */
export type ImpactRatingAuditAggregateFields = {
  __typename?: 'impact_rating_audit_aggregate_fields';
  avg?: Maybe<ImpactRatingAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ImpactRatingAuditMaxFields>;
  min?: Maybe<ImpactRatingAuditMinFields>;
  stddev?: Maybe<ImpactRatingAuditStddevFields>;
  stddev_pop?: Maybe<ImpactRatingAuditStddevPopFields>;
  stddev_samp?: Maybe<ImpactRatingAuditStddevSampFields>;
  sum?: Maybe<ImpactRatingAuditSumFields>;
  var_pop?: Maybe<ImpactRatingAuditVarPopFields>;
  var_samp?: Maybe<ImpactRatingAuditVarSampFields>;
  variance?: Maybe<ImpactRatingAuditVarianceFields>;
};


/** aggregate fields of "risksmart.impact_rating_audit" */
export type ImpactRatingAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditAggregateOrderBy = {
  avg?: InputMaybe<ImpactRatingAuditAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ImpactRatingAuditMaxOrderBy>;
  min?: InputMaybe<ImpactRatingAuditMinOrderBy>;
  stddev?: InputMaybe<ImpactRatingAuditStddevOrderBy>;
  stddev_pop?: InputMaybe<ImpactRatingAuditStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ImpactRatingAuditStddevSampOrderBy>;
  sum?: InputMaybe<ImpactRatingAuditSumOrderBy>;
  var_pop?: InputMaybe<ImpactRatingAuditVarPopOrderBy>;
  var_samp?: InputMaybe<ImpactRatingAuditVarSampOrderBy>;
  variance?: InputMaybe<ImpactRatingAuditVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ImpactRatingAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditArrRelInsertInput = {
  data: Array<ImpactRatingAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ImpactRatingAuditOnConflict>;
};

/** aggregate avg on columns */
export type ImpactRatingAuditAvgFields = {
  __typename?: 'impact_rating_audit_avg_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditAvgOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_rating_audit". All fields are combined with a logical 'AND'. */
export type ImpactRatingAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CompletedBy?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactId?: InputMaybe<UuidComparisonExp>;
  Likelihood?: InputMaybe<IntComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RatedItemId?: InputMaybe<UuidComparisonExp>;
  Rating?: InputMaybe<SmallintComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<ImpactRatingAuditBoolExp>>;
  _not?: InputMaybe<ImpactRatingAuditBoolExp>;
  _or?: InputMaybe<Array<ImpactRatingAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.impact_rating_audit" */
export enum ImpactRatingAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ImpactRatingAuditPkey = 'impact_rating_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImpactRatingAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImpactRatingAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImpactRatingAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditIncInput = {
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type ImpactRatingAuditMaxFields = {
  __typename?: 'impact_rating_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatedItemId?: Maybe<Scalars['uuid']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CompletedBy?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatedItemId?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImpactRatingAuditMinFields = {
  __typename?: 'impact_rating_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatedItemId?: Maybe<Scalars['uuid']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CompletedBy?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatedItemId?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditMutationResponse = {
  __typename?: 'impact_rating_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactRatingAudit>;
};

/** on_conflict condition type for table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditOnConflict = {
  constraint: ImpactRatingAuditConstraint;
  update_columns?: Array<ImpactRatingAuditUpdateColumn>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.impact_rating_audit". */
export type ImpactRatingAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CompletedBy?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatedItemId?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.impact_rating_audit */
export type ImpactRatingAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImpactRatingAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.impact_rating_audit" */
export enum ImpactRatingAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CompletedBy = 'CompletedBy',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatedItemId = 'RatedItemId',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type ImpactRatingAuditStddevFields = {
  __typename?: 'impact_rating_audit_stddev_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditStddevOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ImpactRatingAuditStddevPopFields = {
  __typename?: 'impact_rating_audit_stddev_pop_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditStddevPopOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ImpactRatingAuditStddevSampFields = {
  __typename?: 'impact_rating_audit_stddev_samp_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditStddevSampOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "impact_rating_audit" */
export type ImpactRatingAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImpactRatingAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactRatingAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type ImpactRatingAuditSumFields = {
  __typename?: 'impact_rating_audit_sum_fields';
  Likelihood?: Maybe<Scalars['Int']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditSumOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.impact_rating_audit" */
export enum ImpactRatingAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CompletedBy = 'CompletedBy',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatedItemId = 'RatedItemId',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestDate = 'TestDate'
}

export type ImpactRatingAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImpactRatingAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImpactRatingAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImpactRatingAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImpactRatingAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactRatingAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImpactRatingAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactRatingAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactRatingAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ImpactRatingAuditVarPopFields = {
  __typename?: 'impact_rating_audit_var_pop_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditVarPopOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ImpactRatingAuditVarSampFields = {
  __typename?: 'impact_rating_audit_var_samp_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditVarSampOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImpactRatingAuditVarianceFields = {
  __typename?: 'impact_rating_audit_variance_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.impact_rating_audit" */
export type ImpactRatingAuditVarianceOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate avg on columns */
export type ImpactRatingAvgFields = {
  __typename?: 'impact_rating_avg_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.impact_rating" */
export type ImpactRatingAvgOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.impact_rating". All fields are combined with a logical 'AND'. */
export type ImpactRatingBoolExp = {
  CompletedBy?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactId?: InputMaybe<UuidComparisonExp>;
  Likelihood?: InputMaybe<IntComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RatedItemId?: InputMaybe<UuidComparisonExp>;
  Rating?: InputMaybe<SmallintComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<ImpactRatingBoolExp>>;
  _not?: InputMaybe<ImpactRatingBoolExp>;
  _or?: InputMaybe<Array<ImpactRatingBoolExp>>;
  assessmentParents?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentParents_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  completedBy?: InputMaybe<UserBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  impact?: InputMaybe<ImpactBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  ratedItem?: InputMaybe<NodeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.impact_rating" */
export enum ImpactRatingConstraint {
  /** unique or primary key constraint on columns "Id" */
  ImpactRatingPkey = 'impact_rating_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ImpactRatingDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ImpactRatingDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ImpactRatingDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.impact_rating" */
export type ImpactRatingIncInput = {
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.impact_rating" */
export type ImpactRatingInsertInput = {
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  assessmentParents?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  completedBy?: InputMaybe<UserObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  impact?: InputMaybe<ImpactObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  ratedItem?: InputMaybe<NodeObjRelInsertInput>;
};

/** aggregate max on columns */
export type ImpactRatingMaxFields = {
  __typename?: 'impact_rating_max_fields';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatedItemId?: Maybe<Scalars['uuid']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risksmart.impact_rating" */
export type ImpactRatingMaxOrderBy = {
  CompletedBy?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatedItemId?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ImpactRatingMinFields = {
  __typename?: 'impact_rating_min_fields';
  CompletedBy?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactId?: Maybe<Scalars['uuid']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RatedItemId?: Maybe<Scalars['uuid']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risksmart.impact_rating" */
export type ImpactRatingMinOrderBy = {
  CompletedBy?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatedItemId?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.impact_rating" */
export type ImpactRatingMutationResponse = {
  __typename?: 'impact_rating_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ImpactRating>;
};

/** input type for inserting object relation for remote table "risksmart.impact_rating" */
export type ImpactRatingObjRelInsertInput = {
  data: ImpactRatingInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ImpactRatingOnConflict>;
};

/** on_conflict condition type for table "risksmart.impact_rating" */
export type ImpactRatingOnConflict = {
  constraint: ImpactRatingConstraint;
  update_columns?: Array<ImpactRatingUpdateColumn>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};

/** Ordering options when selecting data from "risksmart.impact_rating". */
export type ImpactRatingOrderBy = {
  CompletedBy?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactId?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RatedItemId?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  assessmentParents_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  completedBy?: InputMaybe<UserOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  impact?: InputMaybe<ImpactOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  ratedItem?: InputMaybe<NodeOrderBy>;
};

/** primary key columns input for table: risksmart.impact_rating */
export type ImpactRatingPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ImpactRatingPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.impact_rating" */
export enum ImpactRatingSelectColumn {
  /** column name */
  CompletedBy = 'CompletedBy',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatedItemId = 'RatedItemId',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.impact_rating" */
export type ImpactRatingSetInput = {
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type ImpactRatingStddevFields = {
  __typename?: 'impact_rating_stddev_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.impact_rating" */
export type ImpactRatingStddevOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ImpactRatingStddevPopFields = {
  __typename?: 'impact_rating_stddev_pop_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.impact_rating" */
export type ImpactRatingStddevPopOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ImpactRatingStddevSampFields = {
  __typename?: 'impact_rating_stddev_samp_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.impact_rating" */
export type ImpactRatingStddevSampOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "impact_rating" */
export type ImpactRatingStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImpactRatingStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactRatingStreamCursorValueInput = {
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatedItemId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['smallint']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type ImpactRatingSumFields = {
  __typename?: 'impact_rating_sum_fields';
  Likelihood?: Maybe<Scalars['Int']['output']>;
  Rating?: Maybe<Scalars['smallint']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.impact_rating" */
export type ImpactRatingSumOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.impact_rating" */
export enum ImpactRatingUpdateColumn {
  /** column name */
  CompletedBy = 'CompletedBy',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactId = 'ImpactId',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatedItemId = 'RatedItemId',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestDate = 'TestDate'
}

export type ImpactRatingUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImpactRatingAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImpactRatingDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImpactRatingDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImpactRatingDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactRatingIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImpactRatingPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactRatingSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactRatingBoolExp;
};

/** aggregate var_pop on columns */
export type ImpactRatingVarPopFields = {
  __typename?: 'impact_rating_var_pop_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.impact_rating" */
export type ImpactRatingVarPopOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ImpactRatingVarSampFields = {
  __typename?: 'impact_rating_var_samp_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.impact_rating" */
export type ImpactRatingVarSampOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ImpactRatingVarianceFields = {
  __typename?: 'impact_rating_variance_fields';
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.impact_rating" */
export type ImpactRatingVarianceOrderBy = {
  Likelihood?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** select columns of table "risksmart.impact" */
export enum ImpactSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatingGuidance = 'RatingGuidance',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  SequentialId = 'SequentialId'
}

/** input type for updating data in table "risksmart.impact" */
export type ImpactSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type ImpactStddevFields = {
  __typename?: 'impact_stddev_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ImpactStddevPopFields = {
  __typename?: 'impact_stddev_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ImpactStddevSampFields = {
  __typename?: 'impact_stddev_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "impact" */
export type ImpactStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ImpactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ImpactStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['smallint']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type ImpactSumFields = {
  __typename?: 'impact_sum_fields';
  ImpactAppetite?: Maybe<Scalars['smallint']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['smallint']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.impact" */
export enum ImpactUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactAppetite = 'ImpactAppetite',
  /** column name */
  LikelihoodAppetite = 'LikelihoodAppetite',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RatingGuidance = 'RatingGuidance',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  SequentialId = 'SequentialId'
}

export type ImpactUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ImpactAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ImpactDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ImpactDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ImpactDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ImpactIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ImpactPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ImpactSetInput>;
  /** filter the rows which have to be updated */
  where: ImpactBoolExp;
};

/** aggregate var_pop on columns */
export type ImpactVarPopFields = {
  __typename?: 'impact_var_pop_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ImpactVarSampFields = {
  __typename?: 'impact_var_samp_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ImpactVarianceFields = {
  __typename?: 'impact_variance_fields';
  ImpactAppetite?: Maybe<Scalars['Float']['output']>;
  LikelihoodAppetite?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.indicator" */
export type Indicator = {
  __typename?: 'indicator';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  LatestResultDate?: Maybe<Scalars['timestamptz']['output']>;
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextResultDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  TestFrequency: TestFrequencyEnum;
  Title: Scalars['String']['output'];
  Type: IndicatorTypeEnum;
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  indicator_test_frequency: TestFrequency;
  /** An object relationship */
  indicator_type: IndicatorType;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  org?: Maybe<AuthOrganisation>;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  parents: Array<IndicatorParent>;
  /** An aggregate relationship */
  parents_aggregate: IndicatorParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  results: Array<IndicatorResult>;
  /** An aggregate relationship */
  results_aggregate: IndicatorResultAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorParentsArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorResultsArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultOrderBy>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultOrderBy>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.indicator" */
export type IndicatorTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** aggregated selection of "risksmart.indicator" */
export type IndicatorAggregate = {
  __typename?: 'indicator_aggregate';
  aggregate?: Maybe<IndicatorAggregateFields>;
  nodes: Array<Indicator>;
};

export type IndicatorAggregateBoolExp = {
  count?: InputMaybe<IndicatorAggregateBoolExpCount>;
};

export type IndicatorAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IndicatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IndicatorBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.indicator" */
export type IndicatorAggregateFields = {
  __typename?: 'indicator_aggregate_fields';
  avg?: Maybe<IndicatorAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IndicatorMaxFields>;
  min?: Maybe<IndicatorMinFields>;
  stddev?: Maybe<IndicatorStddevFields>;
  stddev_pop?: Maybe<IndicatorStddevPopFields>;
  stddev_samp?: Maybe<IndicatorStddevSampFields>;
  sum?: Maybe<IndicatorSumFields>;
  var_pop?: Maybe<IndicatorVarPopFields>;
  var_samp?: Maybe<IndicatorVarSampFields>;
  variance?: Maybe<IndicatorVarianceFields>;
};


/** aggregate fields of "risksmart.indicator" */
export type IndicatorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndicatorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.indicator" */
export type IndicatorAggregateOrderBy = {
  avg?: InputMaybe<IndicatorAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IndicatorMaxOrderBy>;
  min?: InputMaybe<IndicatorMinOrderBy>;
  stddev?: InputMaybe<IndicatorStddevOrderBy>;
  stddev_pop?: InputMaybe<IndicatorStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IndicatorStddevSampOrderBy>;
  sum?: InputMaybe<IndicatorSumOrderBy>;
  var_pop?: InputMaybe<IndicatorVarPopOrderBy>;
  var_samp?: InputMaybe<IndicatorVarSampOrderBy>;
  variance?: InputMaybe<IndicatorVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IndicatorAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.indicator" */
export type IndicatorArrRelInsertInput = {
  data: Array<IndicatorInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IndicatorOnConflict>;
};

/** columns and relationships of "risksmart.indicator_audit" */
export type IndicatorAudit = {
  __typename?: 'indicator_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  LatestResultDate?: Maybe<Scalars['timestamptz']['output']>;
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextResultDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};


/** columns and relationships of "risksmart.indicator_audit" */
export type IndicatorAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.indicator_audit" */
export type IndicatorAuditAggregate = {
  __typename?: 'indicator_audit_aggregate';
  aggregate?: Maybe<IndicatorAuditAggregateFields>;
  nodes: Array<IndicatorAudit>;
};

/** aggregate fields of "risksmart.indicator_audit" */
export type IndicatorAuditAggregateFields = {
  __typename?: 'indicator_audit_aggregate_fields';
  avg?: Maybe<IndicatorAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IndicatorAuditMaxFields>;
  min?: Maybe<IndicatorAuditMinFields>;
  stddev?: Maybe<IndicatorAuditStddevFields>;
  stddev_pop?: Maybe<IndicatorAuditStddevPopFields>;
  stddev_samp?: Maybe<IndicatorAuditStddevSampFields>;
  sum?: Maybe<IndicatorAuditSumFields>;
  var_pop?: Maybe<IndicatorAuditVarPopFields>;
  var_samp?: Maybe<IndicatorAuditVarSampFields>;
  variance?: Maybe<IndicatorAuditVarianceFields>;
};


/** aggregate fields of "risksmart.indicator_audit" */
export type IndicatorAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndicatorAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IndicatorAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type IndicatorAuditAvgFields = {
  __typename?: 'indicator_audit_avg_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_audit". All fields are combined with a logical 'AND'. */
export type IndicatorAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestResultDate?: InputMaybe<TimestamptzComparisonExp>;
  LowerAppetiteNum?: InputMaybe<NumericComparisonExp>;
  LowerToleranceNum?: InputMaybe<NumericComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextResultDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TargetValueTxt?: InputMaybe<StringComparisonExp>;
  TestFrequency?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  Unit?: InputMaybe<StringComparisonExp>;
  UpperAppetiteNum?: InputMaybe<NumericComparisonExp>;
  UpperToleranceNum?: InputMaybe<NumericComparisonExp>;
  _and?: InputMaybe<Array<IndicatorAuditBoolExp>>;
  _not?: InputMaybe<IndicatorAuditBoolExp>;
  _or?: InputMaybe<Array<IndicatorAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.indicator_audit" */
export enum IndicatorAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  IndicatorAuditPkey = 'indicator_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IndicatorAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IndicatorAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IndicatorAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.indicator_audit" */
export type IndicatorAuditIncInput = {
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "risksmart.indicator_audit" */
export type IndicatorAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate max on columns */
export type IndicatorAuditMaxFields = {
  __typename?: 'indicator_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestResultDate?: Maybe<Scalars['timestamptz']['output']>;
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextResultDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};

/** aggregate min on columns */
export type IndicatorAuditMinFields = {
  __typename?: 'indicator_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestResultDate?: Maybe<Scalars['timestamptz']['output']>;
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextResultDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};

/** response of any mutation on the table "risksmart.indicator_audit" */
export type IndicatorAuditMutationResponse = {
  __typename?: 'indicator_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IndicatorAudit>;
};

/** on_conflict condition type for table "risksmart.indicator_audit" */
export type IndicatorAuditOnConflict = {
  constraint: IndicatorAuditConstraint;
  update_columns?: Array<IndicatorAuditUpdateColumn>;
  where?: InputMaybe<IndicatorAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator_audit". */
export type IndicatorAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestResultDate?: InputMaybe<OrderBy>;
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextResultDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  Unit?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.indicator_audit */
export type IndicatorAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IndicatorAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.indicator_audit" */
export enum IndicatorAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestResultDate = 'LatestResultDate',
  /** column name */
  LowerAppetiteNum = 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum = 'LowerToleranceNum',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextResultDate = 'NextResultDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TargetValueTxt = 'TargetValueTxt',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type',
  /** column name */
  Unit = 'Unit',
  /** column name */
  UpperAppetiteNum = 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum = 'UpperToleranceNum'
}

/** input type for updating data in table "risksmart.indicator_audit" */
export type IndicatorAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type IndicatorAuditStddevFields = {
  __typename?: 'indicator_audit_stddev_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IndicatorAuditStddevPopFields = {
  __typename?: 'indicator_audit_stddev_pop_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IndicatorAuditStddevSampFields = {
  __typename?: 'indicator_audit_stddev_samp_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "indicator_audit" */
export type IndicatorAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IndicatorAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndicatorAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type IndicatorAuditSumFields = {
  __typename?: 'indicator_audit_sum_fields';
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "risksmart.indicator_audit" */
export enum IndicatorAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestResultDate = 'LatestResultDate',
  /** column name */
  LowerAppetiteNum = 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum = 'LowerToleranceNum',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextResultDate = 'NextResultDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TargetValueTxt = 'TargetValueTxt',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type',
  /** column name */
  Unit = 'Unit',
  /** column name */
  UpperAppetiteNum = 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum = 'UpperToleranceNum'
}

export type IndicatorAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IndicatorAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IndicatorAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IndicatorAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IndicatorAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IndicatorAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IndicatorAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndicatorAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IndicatorAuditBoolExp;
};

/** aggregate var_pop on columns */
export type IndicatorAuditVarPopFields = {
  __typename?: 'indicator_audit_var_pop_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IndicatorAuditVarSampFields = {
  __typename?: 'indicator_audit_var_samp_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IndicatorAuditVarianceFields = {
  __typename?: 'indicator_audit_variance_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type IndicatorAvgFields = {
  __typename?: 'indicator_avg_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.indicator" */
export type IndicatorAvgOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator". All fields are combined with a logical 'AND'. */
export type IndicatorBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestResultDate?: InputMaybe<TimestamptzComparisonExp>;
  LowerAppetiteNum?: InputMaybe<NumericComparisonExp>;
  LowerToleranceNum?: InputMaybe<NumericComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextResultDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TargetValueTxt?: InputMaybe<StringComparisonExp>;
  TestFrequency?: InputMaybe<TestFrequencyEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<IndicatorTypeEnumComparisonExp>;
  Unit?: InputMaybe<StringComparisonExp>;
  UpperAppetiteNum?: InputMaybe<NumericComparisonExp>;
  UpperToleranceNum?: InputMaybe<NumericComparisonExp>;
  _and?: InputMaybe<Array<IndicatorBoolExp>>;
  _not?: InputMaybe<IndicatorBoolExp>;
  _or?: InputMaybe<Array<IndicatorBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  indicator_test_frequency?: InputMaybe<TestFrequencyBoolExp>;
  indicator_type?: InputMaybe<IndicatorTypeBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  org?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parents?: InputMaybe<IndicatorParentBoolExp>;
  parents_aggregate?: InputMaybe<IndicatorParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  results?: InputMaybe<IndicatorResultBoolExp>;
  results_aggregate?: InputMaybe<IndicatorResultAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.indicator" */
export enum IndicatorConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxIndicatorOrgkeySequentialid = 'idx_indicator_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  IndicatorPkey = 'indicator_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IndicatorDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IndicatorDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IndicatorDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.indicator" */
export type IndicatorIncInput = {
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "risksmart.indicator" */
export type IndicatorInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<IndicatorTypeEnum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  indicator_test_frequency?: InputMaybe<TestFrequencyObjRelInsertInput>;
  indicator_type?: InputMaybe<IndicatorTypeObjRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  org?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parents?: InputMaybe<IndicatorParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  results?: InputMaybe<IndicatorResultArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
};

/** aggregate max on columns */
export type IndicatorMaxFields = {
  __typename?: 'indicator_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestResultDate?: Maybe<Scalars['timestamptz']['output']>;
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextResultDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};

/** order by max() on columns of table "risksmart.indicator" */
export type IndicatorMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestResultDate?: InputMaybe<OrderBy>;
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextResultDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Unit?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IndicatorMinFields = {
  __typename?: 'indicator_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestResultDate?: Maybe<Scalars['timestamptz']['output']>;
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextResultDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Unit?: Maybe<Scalars['String']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};

/** order by min() on columns of table "risksmart.indicator" */
export type IndicatorMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestResultDate?: InputMaybe<OrderBy>;
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextResultDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Unit?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.indicator" */
export type IndicatorMutationResponse = {
  __typename?: 'indicator_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Indicator>;
};

/** input type for inserting object relation for remote table "risksmart.indicator" */
export type IndicatorObjRelInsertInput = {
  data: IndicatorInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IndicatorOnConflict>;
};

/** on_conflict condition type for table "risksmart.indicator" */
export type IndicatorOnConflict = {
  constraint: IndicatorConstraint;
  update_columns?: Array<IndicatorUpdateColumn>;
  where?: InputMaybe<IndicatorBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator". */
export type IndicatorOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestResultDate?: InputMaybe<OrderBy>;
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextResultDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  Unit?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  indicator_test_frequency?: InputMaybe<TestFrequencyOrderBy>;
  indicator_type?: InputMaybe<IndicatorTypeOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  org?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parents_aggregate?: InputMaybe<IndicatorParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  results_aggregate?: InputMaybe<IndicatorResultAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
};

/** columns and relationships of "risksmart.indicator_parent" */
export type IndicatorParent = {
  __typename?: 'indicator_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  indicator?: Maybe<Indicator>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  risk?: Maybe<Risk>;
};

/** aggregated selection of "risksmart.indicator_parent" */
export type IndicatorParentAggregate = {
  __typename?: 'indicator_parent_aggregate';
  aggregate?: Maybe<IndicatorParentAggregateFields>;
  nodes: Array<IndicatorParent>;
};

export type IndicatorParentAggregateBoolExp = {
  count?: InputMaybe<IndicatorParentAggregateBoolExpCount>;
};

export type IndicatorParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IndicatorParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.indicator_parent" */
export type IndicatorParentAggregateFields = {
  __typename?: 'indicator_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IndicatorParentMaxFields>;
  min?: Maybe<IndicatorParentMinFields>;
};


/** aggregate fields of "risksmart.indicator_parent" */
export type IndicatorParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.indicator_parent" */
export type IndicatorParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IndicatorParentMaxOrderBy>;
  min?: InputMaybe<IndicatorParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.indicator_parent" */
export type IndicatorParentArrRelInsertInput = {
  data: Array<IndicatorParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IndicatorParentOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_parent". All fields are combined with a logical 'AND'. */
export type IndicatorParentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  IndicatorId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<IndicatorParentBoolExp>>;
  _not?: InputMaybe<IndicatorParentBoolExp>;
  _or?: InputMaybe<Array<IndicatorParentBoolExp>>;
  control?: InputMaybe<ControlBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  indicator?: InputMaybe<IndicatorBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.indicator_parent" */
export enum IndicatorParentConstraint {
  /** unique or primary key constraint on columns "ParentId", "IndicatorId" */
  IndicatorParentPkey = 'indicator_parent_pkey'
}

/** input type for inserting data into table "risksmart.indicator_parent" */
export type IndicatorParentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<ControlObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  indicator?: InputMaybe<IndicatorObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type IndicatorParentMaxFields = {
  __typename?: 'indicator_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.indicator_parent" */
export type IndicatorParentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IndicatorParentMinFields = {
  __typename?: 'indicator_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.indicator_parent" */
export type IndicatorParentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.indicator_parent" */
export type IndicatorParentMutationResponse = {
  __typename?: 'indicator_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IndicatorParent>;
};

/** on_conflict condition type for table "risksmart.indicator_parent" */
export type IndicatorParentOnConflict = {
  constraint: IndicatorParentConstraint;
  update_columns?: Array<IndicatorParentUpdateColumn>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator_parent". */
export type IndicatorParentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  control?: InputMaybe<ControlOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  indicator?: InputMaybe<IndicatorOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.indicator_parent */
export type IndicatorParentPkColumnsInput = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.indicator_parent" */
export enum IndicatorParentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.indicator_parent" */
export type IndicatorParentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "indicator_parent" */
export type IndicatorParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IndicatorParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndicatorParentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.indicator_parent" */
export enum IndicatorParentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type IndicatorParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndicatorParentSetInput>;
  /** filter the rows which have to be updated */
  where: IndicatorParentBoolExp;
};

/** primary key columns input for table: risksmart.indicator */
export type IndicatorPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IndicatorPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.indicator_result" */
export type IndicatorResult = {
  __typename?: 'indicator_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ResultDate: Scalars['timestamptz']['output'];
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Indicator>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.indicator_result" */
export type IndicatorResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type IndicatorResultFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type IndicatorResultFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type IndicatorResultPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.indicator_result" */
export type IndicatorResultPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.indicator_result" */
export type IndicatorResultAggregate = {
  __typename?: 'indicator_result_aggregate';
  aggregate?: Maybe<IndicatorResultAggregateFields>;
  nodes: Array<IndicatorResult>;
};

export type IndicatorResultAggregateBoolExp = {
  count?: InputMaybe<IndicatorResultAggregateBoolExpCount>;
};

export type IndicatorResultAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IndicatorResultBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.indicator_result" */
export type IndicatorResultAggregateFields = {
  __typename?: 'indicator_result_aggregate_fields';
  avg?: Maybe<IndicatorResultAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IndicatorResultMaxFields>;
  min?: Maybe<IndicatorResultMinFields>;
  stddev?: Maybe<IndicatorResultStddevFields>;
  stddev_pop?: Maybe<IndicatorResultStddevPopFields>;
  stddev_samp?: Maybe<IndicatorResultStddevSampFields>;
  sum?: Maybe<IndicatorResultSumFields>;
  var_pop?: Maybe<IndicatorResultVarPopFields>;
  var_samp?: Maybe<IndicatorResultVarSampFields>;
  variance?: Maybe<IndicatorResultVarianceFields>;
};


/** aggregate fields of "risksmart.indicator_result" */
export type IndicatorResultAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.indicator_result" */
export type IndicatorResultAggregateOrderBy = {
  avg?: InputMaybe<IndicatorResultAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IndicatorResultMaxOrderBy>;
  min?: InputMaybe<IndicatorResultMinOrderBy>;
  stddev?: InputMaybe<IndicatorResultStddevOrderBy>;
  stddev_pop?: InputMaybe<IndicatorResultStddevPopOrderBy>;
  stddev_samp?: InputMaybe<IndicatorResultStddevSampOrderBy>;
  sum?: InputMaybe<IndicatorResultSumOrderBy>;
  var_pop?: InputMaybe<IndicatorResultVarPopOrderBy>;
  var_samp?: InputMaybe<IndicatorResultVarSampOrderBy>;
  variance?: InputMaybe<IndicatorResultVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IndicatorResultAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.indicator_result" */
export type IndicatorResultArrRelInsertInput = {
  data: Array<IndicatorResultInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IndicatorResultOnConflict>;
};

/** columns and relationships of "risksmart.indicator_result_audit" */
export type IndicatorResultAudit = {
  __typename?: 'indicator_result_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ResultDate: Scalars['timestamptz']['output'];
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.indicator_result_audit" */
export type IndicatorResultAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.indicator_result_audit" */
export type IndicatorResultAuditAggregate = {
  __typename?: 'indicator_result_audit_aggregate';
  aggregate?: Maybe<IndicatorResultAuditAggregateFields>;
  nodes: Array<IndicatorResultAudit>;
};

/** aggregate fields of "risksmart.indicator_result_audit" */
export type IndicatorResultAuditAggregateFields = {
  __typename?: 'indicator_result_audit_aggregate_fields';
  avg?: Maybe<IndicatorResultAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IndicatorResultAuditMaxFields>;
  min?: Maybe<IndicatorResultAuditMinFields>;
  stddev?: Maybe<IndicatorResultAuditStddevFields>;
  stddev_pop?: Maybe<IndicatorResultAuditStddevPopFields>;
  stddev_samp?: Maybe<IndicatorResultAuditStddevSampFields>;
  sum?: Maybe<IndicatorResultAuditSumFields>;
  var_pop?: Maybe<IndicatorResultAuditVarPopFields>;
  var_samp?: Maybe<IndicatorResultAuditVarSampFields>;
  variance?: Maybe<IndicatorResultAuditVarianceFields>;
};


/** aggregate fields of "risksmart.indicator_result_audit" */
export type IndicatorResultAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndicatorResultAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IndicatorResultAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type IndicatorResultAuditAvgFields = {
  __typename?: 'indicator_result_audit_avg_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_result_audit". All fields are combined with a logical 'AND'. */
export type IndicatorResultAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  IndicatorId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ResultDate?: InputMaybe<TimestamptzComparisonExp>;
  TargetValueNum?: InputMaybe<NumericComparisonExp>;
  TargetValueTxt?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IndicatorResultAuditBoolExp>>;
  _not?: InputMaybe<IndicatorResultAuditBoolExp>;
  _or?: InputMaybe<Array<IndicatorResultAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.indicator_result_audit" */
export enum IndicatorResultAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  IndicatorResultAuditPkey = 'indicator_result_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IndicatorResultAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IndicatorResultAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IndicatorResultAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.indicator_result_audit" */
export type IndicatorResultAuditIncInput = {
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "risksmart.indicator_result_audit" */
export type IndicatorResultAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IndicatorResultAuditMaxFields = {
  __typename?: 'indicator_result_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResultDate?: Maybe<Scalars['timestamptz']['output']>;
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IndicatorResultAuditMinFields = {
  __typename?: 'indicator_result_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResultDate?: Maybe<Scalars['timestamptz']['output']>;
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.indicator_result_audit" */
export type IndicatorResultAuditMutationResponse = {
  __typename?: 'indicator_result_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IndicatorResultAudit>;
};

/** on_conflict condition type for table "risksmart.indicator_result_audit" */
export type IndicatorResultAuditOnConflict = {
  constraint: IndicatorResultAuditConstraint;
  update_columns?: Array<IndicatorResultAuditUpdateColumn>;
  where?: InputMaybe<IndicatorResultAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator_result_audit". */
export type IndicatorResultAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ResultDate?: InputMaybe<OrderBy>;
  TargetValueNum?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.indicator_result_audit */
export type IndicatorResultAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IndicatorResultAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.indicator_result_audit" */
export enum IndicatorResultAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ResultDate = 'ResultDate',
  /** column name */
  TargetValueNum = 'TargetValueNum',
  /** column name */
  TargetValueTxt = 'TargetValueTxt'
}

/** input type for updating data in table "risksmart.indicator_result_audit" */
export type IndicatorResultAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type IndicatorResultAuditStddevFields = {
  __typename?: 'indicator_result_audit_stddev_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IndicatorResultAuditStddevPopFields = {
  __typename?: 'indicator_result_audit_stddev_pop_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IndicatorResultAuditStddevSampFields = {
  __typename?: 'indicator_result_audit_stddev_samp_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "indicator_result_audit" */
export type IndicatorResultAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IndicatorResultAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndicatorResultAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IndicatorResultAuditSumFields = {
  __typename?: 'indicator_result_audit_sum_fields';
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
};

/** update columns of table "risksmart.indicator_result_audit" */
export enum IndicatorResultAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ResultDate = 'ResultDate',
  /** column name */
  TargetValueNum = 'TargetValueNum',
  /** column name */
  TargetValueTxt = 'TargetValueTxt'
}

export type IndicatorResultAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IndicatorResultAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IndicatorResultAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IndicatorResultAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IndicatorResultAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IndicatorResultAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IndicatorResultAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndicatorResultAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IndicatorResultAuditBoolExp;
};

/** aggregate var_pop on columns */
export type IndicatorResultAuditVarPopFields = {
  __typename?: 'indicator_result_audit_var_pop_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IndicatorResultAuditVarSampFields = {
  __typename?: 'indicator_result_audit_var_samp_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IndicatorResultAuditVarianceFields = {
  __typename?: 'indicator_result_audit_variance_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type IndicatorResultAvgFields = {
  __typename?: 'indicator_result_avg_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.indicator_result" */
export type IndicatorResultAvgOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_result". All fields are combined with a logical 'AND'. */
export type IndicatorResultBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  IndicatorId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ResultDate?: InputMaybe<TimestamptzComparisonExp>;
  TargetValueNum?: InputMaybe<NumericComparisonExp>;
  TargetValueTxt?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IndicatorResultBoolExp>>;
  _not?: InputMaybe<IndicatorResultBoolExp>;
  _or?: InputMaybe<Array<IndicatorResultBoolExp>>;
  createdBy?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<IndicatorBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.indicator_result" */
export enum IndicatorResultConstraint {
  /** unique or primary key constraint on columns "Id" */
  IndicatorResultPkey = 'indicator_result_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IndicatorResultDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IndicatorResultDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IndicatorResultDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.indicator_result" */
export type IndicatorResultIncInput = {
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "risksmart.indicator_result" */
export type IndicatorResultInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<IndicatorObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type IndicatorResultMaxFields = {
  __typename?: 'indicator_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResultDate?: Maybe<Scalars['timestamptz']['output']>;
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.indicator_result" */
export type IndicatorResultMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ResultDate?: InputMaybe<OrderBy>;
  TargetValueNum?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IndicatorResultMinFields = {
  __typename?: 'indicator_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResultDate?: Maybe<Scalars['timestamptz']['output']>;
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
  TargetValueTxt?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.indicator_result" */
export type IndicatorResultMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ResultDate?: InputMaybe<OrderBy>;
  TargetValueNum?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.indicator_result" */
export type IndicatorResultMutationResponse = {
  __typename?: 'indicator_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IndicatorResult>;
};

/** on_conflict condition type for table "risksmart.indicator_result" */
export type IndicatorResultOnConflict = {
  constraint: IndicatorResultConstraint;
  update_columns?: Array<IndicatorResultUpdateColumn>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator_result". */
export type IndicatorResultOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ResultDate?: InputMaybe<OrderBy>;
  TargetValueNum?: InputMaybe<OrderBy>;
  TargetValueTxt?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<IndicatorOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.indicator_result */
export type IndicatorResultPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IndicatorResultPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.indicator_result" */
export enum IndicatorResultSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ResultDate = 'ResultDate',
  /** column name */
  TargetValueNum = 'TargetValueNum',
  /** column name */
  TargetValueTxt = 'TargetValueTxt'
}

/** input type for updating data in table "risksmart.indicator_result" */
export type IndicatorResultSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type IndicatorResultStddevFields = {
  __typename?: 'indicator_result_stddev_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.indicator_result" */
export type IndicatorResultStddevOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type IndicatorResultStddevPopFields = {
  __typename?: 'indicator_result_stddev_pop_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.indicator_result" */
export type IndicatorResultStddevPopOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type IndicatorResultStddevSampFields = {
  __typename?: 'indicator_result_stddev_samp_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.indicator_result" */
export type IndicatorResultStddevSampOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "indicator_result" */
export type IndicatorResultStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IndicatorResultStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndicatorResultStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TargetValueNum?: InputMaybe<Scalars['numeric']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IndicatorResultSumFields = {
  __typename?: 'indicator_result_sum_fields';
  TargetValueNum?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "risksmart.indicator_result" */
export type IndicatorResultSumOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.indicator_result" */
export enum IndicatorResultUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ResultDate = 'ResultDate',
  /** column name */
  TargetValueNum = 'TargetValueNum',
  /** column name */
  TargetValueTxt = 'TargetValueTxt'
}

export type IndicatorResultUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IndicatorResultAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IndicatorResultDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IndicatorResultDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IndicatorResultDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IndicatorResultIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IndicatorResultPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndicatorResultSetInput>;
  /** filter the rows which have to be updated */
  where: IndicatorResultBoolExp;
};

/** aggregate var_pop on columns */
export type IndicatorResultVarPopFields = {
  __typename?: 'indicator_result_var_pop_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.indicator_result" */
export type IndicatorResultVarPopOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type IndicatorResultVarSampFields = {
  __typename?: 'indicator_result_var_samp_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.indicator_result" */
export type IndicatorResultVarSampOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type IndicatorResultVarianceFields = {
  __typename?: 'indicator_result_variance_fields';
  TargetValueNum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.indicator_result" */
export type IndicatorResultVarianceOrderBy = {
  TargetValueNum?: InputMaybe<OrderBy>;
};

/** select columns of table "risksmart.indicator" */
export enum IndicatorSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestResultDate = 'LatestResultDate',
  /** column name */
  LowerAppetiteNum = 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum = 'LowerToleranceNum',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextResultDate = 'NextResultDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TargetValueTxt = 'TargetValueTxt',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type',
  /** column name */
  Unit = 'Unit',
  /** column name */
  UpperAppetiteNum = 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum = 'UpperToleranceNum'
}

/** input type for updating data in table "risksmart.indicator" */
export type IndicatorSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<IndicatorTypeEnum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate stddev on columns */
export type IndicatorStddevFields = {
  __typename?: 'indicator_stddev_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.indicator" */
export type IndicatorStddevOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type IndicatorStddevPopFields = {
  __typename?: 'indicator_stddev_pop_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.indicator" */
export type IndicatorStddevPopOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type IndicatorStddevSampFields = {
  __typename?: 'indicator_stddev_samp_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.indicator" */
export type IndicatorStddevSampOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "indicator" */
export type IndicatorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IndicatorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndicatorStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextResultDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<IndicatorTypeEnum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};

/** aggregate sum on columns */
export type IndicatorSumFields = {
  __typename?: 'indicator_sum_fields';
  LowerAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  LowerToleranceNum?: Maybe<Scalars['numeric']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['numeric']['output']>;
  UpperToleranceNum?: Maybe<Scalars['numeric']['output']>;
};

/** order by sum() on columns of table "risksmart.indicator" */
export type IndicatorSumOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.indicator_type" */
export type IndicatorType = {
  __typename?: 'indicator_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  indicator_type: Array<Indicator>;
  /** An aggregate relationship */
  indicator_type_aggregate: IndicatorAggregate;
};


/** columns and relationships of "risksmart.indicator_type" */
export type IndicatorTypeIndicatorTypeArgs = {
  distinct_on?: InputMaybe<Array<IndicatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorOrderBy>>;
  where?: InputMaybe<IndicatorBoolExp>;
};


/** columns and relationships of "risksmart.indicator_type" */
export type IndicatorTypeIndicatorTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorOrderBy>>;
  where?: InputMaybe<IndicatorBoolExp>;
};

/** aggregated selection of "risksmart.indicator_type" */
export type IndicatorTypeAggregate = {
  __typename?: 'indicator_type_aggregate';
  aggregate?: Maybe<IndicatorTypeAggregateFields>;
  nodes: Array<IndicatorType>;
};

/** aggregate fields of "risksmart.indicator_type" */
export type IndicatorTypeAggregateFields = {
  __typename?: 'indicator_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IndicatorTypeMaxFields>;
  min?: Maybe<IndicatorTypeMinFields>;
};


/** aggregate fields of "risksmart.indicator_type" */
export type IndicatorTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IndicatorTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_type". All fields are combined with a logical 'AND'. */
export type IndicatorTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IndicatorTypeBoolExp>>;
  _not?: InputMaybe<IndicatorTypeBoolExp>;
  _or?: InputMaybe<Array<IndicatorTypeBoolExp>>;
  indicator_type?: InputMaybe<IndicatorBoolExp>;
  indicator_type_aggregate?: InputMaybe<IndicatorAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.indicator_type" */
export enum IndicatorTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  IndicatorTypePkey = 'indicator_type_pkey'
}

export enum IndicatorTypeEnum {
  /** True or false indicator type */
  Boolean = 'boolean',
  /**  Number indicator type */
  Number = 'number',
  /** Text indicator type */
  Text = 'text'
}

/** Boolean expression to compare columns of type "indicator_type_enum". All fields are combined with logical 'AND'. */
export type IndicatorTypeEnumComparisonExp = {
  _eq?: InputMaybe<IndicatorTypeEnum>;
  _in?: InputMaybe<Array<IndicatorTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IndicatorTypeEnum>;
  _nin?: InputMaybe<Array<IndicatorTypeEnum>>;
};

/** input type for inserting data into table "risksmart.indicator_type" */
export type IndicatorTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
  indicator_type?: InputMaybe<IndicatorArrRelInsertInput>;
};

/** aggregate max on columns */
export type IndicatorTypeMaxFields = {
  __typename?: 'indicator_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IndicatorTypeMinFields = {
  __typename?: 'indicator_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.indicator_type" */
export type IndicatorTypeMutationResponse = {
  __typename?: 'indicator_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IndicatorType>;
};

/** input type for inserting object relation for remote table "risksmart.indicator_type" */
export type IndicatorTypeObjRelInsertInput = {
  data: IndicatorTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IndicatorTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.indicator_type" */
export type IndicatorTypeOnConflict = {
  constraint: IndicatorTypeConstraint;
  update_columns?: Array<IndicatorTypeUpdateColumn>;
  where?: InputMaybe<IndicatorTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator_type". */
export type IndicatorTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
  indicator_type_aggregate?: InputMaybe<IndicatorAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.indicator_type */
export type IndicatorTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.indicator_type" */
export enum IndicatorTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.indicator_type" */
export type IndicatorTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "indicator_type" */
export type IndicatorTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IndicatorTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IndicatorTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.indicator_type" */
export enum IndicatorTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type IndicatorTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndicatorTypeSetInput>;
  /** filter the rows which have to be updated */
  where: IndicatorTypeBoolExp;
};

/** update columns of table "risksmart.indicator" */
export enum IndicatorUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestResultDate = 'LatestResultDate',
  /** column name */
  LowerAppetiteNum = 'LowerAppetiteNum',
  /** column name */
  LowerToleranceNum = 'LowerToleranceNum',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextResultDate = 'NextResultDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TargetValueTxt = 'TargetValueTxt',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type',
  /** column name */
  Unit = 'Unit',
  /** column name */
  UpperAppetiteNum = 'UpperAppetiteNum',
  /** column name */
  UpperToleranceNum = 'UpperToleranceNum'
}

export type IndicatorUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IndicatorAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IndicatorDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IndicatorDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IndicatorDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IndicatorIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IndicatorPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IndicatorSetInput>;
  /** filter the rows which have to be updated */
  where: IndicatorBoolExp;
};

/** aggregate var_pop on columns */
export type IndicatorVarPopFields = {
  __typename?: 'indicator_var_pop_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.indicator" */
export type IndicatorVarPopOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type IndicatorVarSampFields = {
  __typename?: 'indicator_var_samp_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.indicator" */
export type IndicatorVarSampOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type IndicatorVarianceFields = {
  __typename?: 'indicator_variance_fields';
  LowerAppetiteNum?: Maybe<Scalars['Float']['output']>;
  LowerToleranceNum?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
  UpperAppetiteNum?: Maybe<Scalars['Float']['output']>;
  UpperToleranceNum?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.indicator" */
export type IndicatorVarianceOrderBy = {
  LowerAppetiteNum?: InputMaybe<OrderBy>;
  LowerToleranceNum?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  UpperAppetiteNum?: InputMaybe<OrderBy>;
  UpperToleranceNum?: InputMaybe<OrderBy>;
};

export type InsertChildImpactRatingOutput = {
  __typename?: 'insertChildImpactRatingOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

export type InsertControlTestResultOutput = {
  __typename?: 'insertControlTestResultOutput';
  Ids: Array<Scalars['uuid']['output']>;
};

/** columns and relationships of "risksmart.insert_permission_view" */
export type InsertPermissions = {
  __typename?: 'insertPermissions';
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.insert_permission_view" */
export type InsertPermissionsAggregate = {
  __typename?: 'insertPermissions_aggregate';
  aggregate?: Maybe<InsertPermissionsAggregateFields>;
  nodes: Array<InsertPermissions>;
};

export type InsertPermissionsAggregateBoolExp = {
  count?: InputMaybe<InsertPermissionsAggregateBoolExpCount>;
};

export type InsertPermissionsAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InsertPermissionsBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.insert_permission_view" */
export type InsertPermissionsAggregateFields = {
  __typename?: 'insertPermissions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<InsertPermissionsMaxFields>;
  min?: Maybe<InsertPermissionsMinFields>;
};


/** aggregate fields of "risksmart.insert_permission_view" */
export type InsertPermissionsAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.insert_permission_view" */
export type InsertPermissionsAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InsertPermissionsMaxOrderBy>;
  min?: InputMaybe<InsertPermissionsMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.insert_permission_view" */
export type InsertPermissionsArrRelInsertInput = {
  data: Array<InsertPermissionsInsertInput>;
};

/** Boolean expression to filter rows from the table "risksmart.insert_permission_view". All fields are combined with a logical 'AND'. */
export type InsertPermissionsBoolExp = {
  Id?: InputMaybe<UuidComparisonExp>;
  ObjectType?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<InsertPermissionsBoolExp>>;
  _not?: InputMaybe<InsertPermissionsBoolExp>;
  _or?: InputMaybe<Array<InsertPermissionsBoolExp>>;
};

/** input type for inserting data into table "risksmart.insert_permission_view" */
export type InsertPermissionsInsertInput = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type InsertPermissionsMaxFields = {
  __typename?: 'insertPermissions_max_fields';
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.insert_permission_view" */
export type InsertPermissionsMaxOrderBy = {
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InsertPermissionsMinFields = {
  __typename?: 'insertPermissions_min_fields';
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.insert_permission_view" */
export type InsertPermissionsMinOrderBy = {
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "risksmart.insert_permission_view". */
export type InsertPermissionsOrderBy = {
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** select columns of table "risksmart.insert_permission_view" */
export enum InsertPermissionsSelectColumn {
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

/** Streaming cursor of the table "insertPermissions" */
export type InsertPermissionsStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InsertPermissionsStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InsertPermissionsStreamCursorValueInput = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntity = {
  __typename?: 'internal_audit_entity';
  BusinessAreaId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  actions: Array<ActionParent>;
  /** An aggregate relationship */
  actions_aggregate: ActionParentAggregate;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  businessArea?: Maybe<BusinessArea>;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An array relationship */
  internalAuditReports: Array<InternalAuditReport>;
  /** An aggregate relationship */
  internalAuditReports_aggregate: InternalAuditReportAggregate;
  /** An array relationship */
  issues: Array<IssueParent>;
  /** An aggregate relationship */
  issues_aggregate: IssueParentAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityInternalAuditReportsArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditReportOrderBy>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityInternalAuditReportsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditReportOrderBy>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_entity" */
export type InternalAuditEntityTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** aggregated selection of "risksmart.internal_audit_entity" */
export type InternalAuditEntityAggregate = {
  __typename?: 'internal_audit_entity_aggregate';
  aggregate?: Maybe<InternalAuditEntityAggregateFields>;
  nodes: Array<InternalAuditEntity>;
};

export type InternalAuditEntityAggregateBoolExp = {
  count?: InputMaybe<InternalAuditEntityAggregateBoolExpCount>;
};

export type InternalAuditEntityAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InternalAuditEntityBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.internal_audit_entity" */
export type InternalAuditEntityAggregateFields = {
  __typename?: 'internal_audit_entity_aggregate_fields';
  avg?: Maybe<InternalAuditEntityAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<InternalAuditEntityMaxFields>;
  min?: Maybe<InternalAuditEntityMinFields>;
  stddev?: Maybe<InternalAuditEntityStddevFields>;
  stddev_pop?: Maybe<InternalAuditEntityStddevPopFields>;
  stddev_samp?: Maybe<InternalAuditEntityStddevSampFields>;
  sum?: Maybe<InternalAuditEntitySumFields>;
  var_pop?: Maybe<InternalAuditEntityVarPopFields>;
  var_samp?: Maybe<InternalAuditEntityVarSampFields>;
  variance?: Maybe<InternalAuditEntityVarianceFields>;
};


/** aggregate fields of "risksmart.internal_audit_entity" */
export type InternalAuditEntityAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityAggregateOrderBy = {
  avg?: InputMaybe<InternalAuditEntityAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InternalAuditEntityMaxOrderBy>;
  min?: InputMaybe<InternalAuditEntityMinOrderBy>;
  stddev?: InputMaybe<InternalAuditEntityStddevOrderBy>;
  stddev_pop?: InputMaybe<InternalAuditEntityStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InternalAuditEntityStddevSampOrderBy>;
  sum?: InputMaybe<InternalAuditEntitySumOrderBy>;
  var_pop?: InputMaybe<InternalAuditEntityVarPopOrderBy>;
  var_samp?: InputMaybe<InternalAuditEntityVarSampOrderBy>;
  variance?: InputMaybe<InternalAuditEntityVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type InternalAuditEntityAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.internal_audit_entity" */
export type InternalAuditEntityArrRelInsertInput = {
  data: Array<InternalAuditEntityInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalAuditEntityOnConflict>;
};

/** aggregate avg on columns */
export type InternalAuditEntityAvgFields = {
  __typename?: 'internal_audit_entity_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityAvgOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.internal_audit_entity". All fields are combined with a logical 'AND'. */
export type InternalAuditEntityBoolExp = {
  BusinessAreaId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<InternalAuditEntityBoolExp>>;
  _not?: InputMaybe<InternalAuditEntityBoolExp>;
  _or?: InputMaybe<Array<InternalAuditEntityBoolExp>>;
  actions?: InputMaybe<ActionParentBoolExp>;
  actions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  businessArea?: InputMaybe<BusinessAreaBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  internalAuditReports?: InputMaybe<InternalAuditReportBoolExp>;
  internalAuditReports_aggregate?: InputMaybe<InternalAuditReportAggregateBoolExp>;
  issues?: InputMaybe<IssueParentBoolExp>;
  issues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.internal_audit_entity" */
export enum InternalAuditEntityConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxInternalAuditEntityOrgkeySequentialid = 'idx_internal_audit_entity_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  InternalAuditEntityPkey = 'internal_audit_entity_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type InternalAuditEntityDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type InternalAuditEntityDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type InternalAuditEntityDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.internal_audit_entity" */
export type InternalAuditEntityIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.internal_audit_entity" */
export type InternalAuditEntityInsertInput = {
  BusinessAreaId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actions?: InputMaybe<ActionParentArrRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  businessArea?: InputMaybe<BusinessAreaObjRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  internalAuditReports?: InputMaybe<InternalAuditReportArrRelInsertInput>;
  issues?: InputMaybe<IssueParentArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
};

/** aggregate max on columns */
export type InternalAuditEntityMaxFields = {
  __typename?: 'internal_audit_entity_max_fields';
  BusinessAreaId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityMaxOrderBy = {
  BusinessAreaId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InternalAuditEntityMinFields = {
  __typename?: 'internal_audit_entity_min_fields';
  BusinessAreaId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityMinOrderBy = {
  BusinessAreaId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.internal_audit_entity" */
export type InternalAuditEntityMutationResponse = {
  __typename?: 'internal_audit_entity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<InternalAuditEntity>;
};

/** input type for inserting object relation for remote table "risksmart.internal_audit_entity" */
export type InternalAuditEntityObjRelInsertInput = {
  data: InternalAuditEntityInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalAuditEntityOnConflict>;
};

/** on_conflict condition type for table "risksmart.internal_audit_entity" */
export type InternalAuditEntityOnConflict = {
  constraint: InternalAuditEntityConstraint;
  update_columns?: Array<InternalAuditEntityUpdateColumn>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};

/** Ordering options when selecting data from "risksmart.internal_audit_entity". */
export type InternalAuditEntityOrderBy = {
  BusinessAreaId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  actions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  businessArea?: InputMaybe<BusinessAreaOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  internalAuditReports_aggregate?: InputMaybe<InternalAuditReportAggregateOrderBy>;
  issues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.internal_audit_entity */
export type InternalAuditEntityPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type InternalAuditEntityPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.internal_audit_entity" */
export enum InternalAuditEntitySelectColumn {
  /** column name */
  BusinessAreaId = 'BusinessAreaId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.internal_audit_entity" */
export type InternalAuditEntitySetInput = {
  BusinessAreaId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type InternalAuditEntityStddevFields = {
  __typename?: 'internal_audit_entity_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityStddevOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type InternalAuditEntityStddevPopFields = {
  __typename?: 'internal_audit_entity_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityStddevPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type InternalAuditEntityStddevSampFields = {
  __typename?: 'internal_audit_entity_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityStddevSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "internal_audit_entity" */
export type InternalAuditEntityStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InternalAuditEntityStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InternalAuditEntityStreamCursorValueInput = {
  BusinessAreaId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type InternalAuditEntitySumFields = {
  __typename?: 'internal_audit_entity_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntitySumOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.internal_audit_entity" */
export enum InternalAuditEntityUpdateColumn {
  /** column name */
  BusinessAreaId = 'BusinessAreaId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

export type InternalAuditEntityUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<InternalAuditEntityAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<InternalAuditEntityDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<InternalAuditEntityDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<InternalAuditEntityDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InternalAuditEntityIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<InternalAuditEntityPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalAuditEntitySetInput>;
  /** filter the rows which have to be updated */
  where: InternalAuditEntityBoolExp;
};

/** aggregate var_pop on columns */
export type InternalAuditEntityVarPopFields = {
  __typename?: 'internal_audit_entity_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityVarPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type InternalAuditEntityVarSampFields = {
  __typename?: 'internal_audit_entity_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityVarSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InternalAuditEntityVarianceFields = {
  __typename?: 'internal_audit_entity_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.internal_audit_entity" */
export type InternalAuditEntityVarianceOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReport = {
  __typename?: 'internal_audit_report';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: AssessmentStatusEnum;
  Summary: Scalars['String']['output'];
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  assessmentActions: Array<ActionParent>;
  /** An aggregate relationship */
  assessmentActions_aggregate: ActionParentAggregate;
  /** An array relationship */
  assessmentActivities: Array<AssessmentActivity>;
  /** An aggregate relationship */
  assessmentActivities_aggregate: AssessmentActivityAggregate;
  /** An array relationship */
  assessmentIssues: Array<IssueParent>;
  /** An aggregate relationship */
  assessmentIssues_aggregate: IssueParentAggregate;
  /** An array relationship */
  assessmentResults: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: AssessmentResultParentAggregate;
  /** An object relationship */
  completedByUser?: Maybe<User>;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  originatingItem?: Maybe<Node>;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentActivitiesArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentActivitiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportAssessmentResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.internal_audit_report" */
export type InternalAuditReportTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** aggregated selection of "risksmart.internal_audit_report" */
export type InternalAuditReportAggregate = {
  __typename?: 'internal_audit_report_aggregate';
  aggregate?: Maybe<InternalAuditReportAggregateFields>;
  nodes: Array<InternalAuditReport>;
};

export type InternalAuditReportAggregateBoolExp = {
  count?: InputMaybe<InternalAuditReportAggregateBoolExpCount>;
};

export type InternalAuditReportAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<InternalAuditReportBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.internal_audit_report" */
export type InternalAuditReportAggregateFields = {
  __typename?: 'internal_audit_report_aggregate_fields';
  avg?: Maybe<InternalAuditReportAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<InternalAuditReportMaxFields>;
  min?: Maybe<InternalAuditReportMinFields>;
  stddev?: Maybe<InternalAuditReportStddevFields>;
  stddev_pop?: Maybe<InternalAuditReportStddevPopFields>;
  stddev_samp?: Maybe<InternalAuditReportStddevSampFields>;
  sum?: Maybe<InternalAuditReportSumFields>;
  var_pop?: Maybe<InternalAuditReportVarPopFields>;
  var_samp?: Maybe<InternalAuditReportVarSampFields>;
  variance?: Maybe<InternalAuditReportVarianceFields>;
};


/** aggregate fields of "risksmart.internal_audit_report" */
export type InternalAuditReportAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.internal_audit_report" */
export type InternalAuditReportAggregateOrderBy = {
  avg?: InputMaybe<InternalAuditReportAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<InternalAuditReportMaxOrderBy>;
  min?: InputMaybe<InternalAuditReportMinOrderBy>;
  stddev?: InputMaybe<InternalAuditReportStddevOrderBy>;
  stddev_pop?: InputMaybe<InternalAuditReportStddevPopOrderBy>;
  stddev_samp?: InputMaybe<InternalAuditReportStddevSampOrderBy>;
  sum?: InputMaybe<InternalAuditReportSumOrderBy>;
  var_pop?: InputMaybe<InternalAuditReportVarPopOrderBy>;
  var_samp?: InputMaybe<InternalAuditReportVarSampOrderBy>;
  variance?: InputMaybe<InternalAuditReportVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type InternalAuditReportAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.internal_audit_report" */
export type InternalAuditReportArrRelInsertInput = {
  data: Array<InternalAuditReportInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalAuditReportOnConflict>;
};

/** aggregate avg on columns */
export type InternalAuditReportAvgFields = {
  __typename?: 'internal_audit_report_avg_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportAvgOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.internal_audit_report". All fields are combined with a logical 'AND'. */
export type InternalAuditReportBoolExp = {
  ActualCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  CompletedByUser?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OriginatingItemId?: InputMaybe<UuidComparisonExp>;
  Outcome?: InputMaybe<IntComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  StartDate?: InputMaybe<TimestamptzComparisonExp>;
  Status?: InputMaybe<AssessmentStatusEnumComparisonExp>;
  Summary?: InputMaybe<StringComparisonExp>;
  TargetCompletionDate?: InputMaybe<TimestamptzComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<InternalAuditReportBoolExp>>;
  _not?: InputMaybe<InternalAuditReportBoolExp>;
  _or?: InputMaybe<Array<InternalAuditReportBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  assessmentActions?: InputMaybe<ActionParentBoolExp>;
  assessmentActions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  assessmentActivities?: InputMaybe<AssessmentActivityBoolExp>;
  assessmentActivities_aggregate?: InputMaybe<AssessmentActivityAggregateBoolExp>;
  assessmentIssues?: InputMaybe<IssueParentBoolExp>;
  assessmentIssues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  assessmentResults?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  completedByUser?: InputMaybe<UserBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  originatingItem?: InputMaybe<NodeBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.internal_audit_report" */
export enum InternalAuditReportConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxInternalAuditReportOrgkeySequentialid = 'idx_internal_audit_report_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  InternalAuditReportPkey = 'internal_audit_report_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type InternalAuditReportDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type InternalAuditReportDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type InternalAuditReportDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.internal_audit_report" */
export type InternalAuditReportIncInput = {
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.internal_audit_report" */
export type InternalAuditReportInsertInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  assessmentActions?: InputMaybe<ActionParentArrRelInsertInput>;
  assessmentActivities?: InputMaybe<AssessmentActivityArrRelInsertInput>;
  assessmentIssues?: InputMaybe<IssueParentArrRelInsertInput>;
  assessmentResults?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  completedByUser?: InputMaybe<UserObjRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  originatingItem?: InputMaybe<NodeObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
};

/** aggregate max on columns */
export type InternalAuditReportMaxFields = {
  __typename?: 'internal_audit_report_max_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportMaxOrderBy = {
  ActualCompletionDate?: InputMaybe<OrderBy>;
  CompletedByUser?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OriginatingItemId?: InputMaybe<OrderBy>;
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  TargetCompletionDate?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type InternalAuditReportMinFields = {
  __typename?: 'internal_audit_report_min_fields';
  ActualCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  CompletedByUser?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OriginatingItemId?: Maybe<Scalars['uuid']['output']>;
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Summary?: Maybe<Scalars['String']['output']>;
  TargetCompletionDate?: Maybe<Scalars['timestamptz']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportMinOrderBy = {
  ActualCompletionDate?: InputMaybe<OrderBy>;
  CompletedByUser?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OriginatingItemId?: InputMaybe<OrderBy>;
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  TargetCompletionDate?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.internal_audit_report" */
export type InternalAuditReportMutationResponse = {
  __typename?: 'internal_audit_report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<InternalAuditReport>;
};

/** input type for inserting object relation for remote table "risksmart.internal_audit_report" */
export type InternalAuditReportObjRelInsertInput = {
  data: InternalAuditReportInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<InternalAuditReportOnConflict>;
};

/** on_conflict condition type for table "risksmart.internal_audit_report" */
export type InternalAuditReportOnConflict = {
  constraint: InternalAuditReportConstraint;
  update_columns?: Array<InternalAuditReportUpdateColumn>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};

/** Ordering options when selecting data from "risksmart.internal_audit_report". */
export type InternalAuditReportOrderBy = {
  ActualCompletionDate?: InputMaybe<OrderBy>;
  CompletedByUser?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OriginatingItemId?: InputMaybe<OrderBy>;
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Summary?: InputMaybe<OrderBy>;
  TargetCompletionDate?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  assessmentActions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  assessmentActivities_aggregate?: InputMaybe<AssessmentActivityAggregateOrderBy>;
  assessmentIssues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  completedByUser?: InputMaybe<UserOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  originatingItem?: InputMaybe<NodeOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.internal_audit_report */
export type InternalAuditReportPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type InternalAuditReportPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.internal_audit_report" */
export enum InternalAuditReportSelectColumn {
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.internal_audit_report" */
export type InternalAuditReportSetInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type InternalAuditReportStddevFields = {
  __typename?: 'internal_audit_report_stddev_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportStddevOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type InternalAuditReportStddevPopFields = {
  __typename?: 'internal_audit_report_stddev_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportStddevPopOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type InternalAuditReportStddevSampFields = {
  __typename?: 'internal_audit_report_stddev_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportStddevSampOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "internal_audit_report" */
export type InternalAuditReportStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: InternalAuditReportStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type InternalAuditReportStreamCursorValueInput = {
  ActualCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CompletedByUser?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OriginatingItemId?: InputMaybe<Scalars['uuid']['input']>;
  Outcome?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<AssessmentStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  TargetCompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type InternalAuditReportSumFields = {
  __typename?: 'internal_audit_report_sum_fields';
  Outcome?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportSumOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.internal_audit_report" */
export enum InternalAuditReportUpdateColumn {
  /** column name */
  ActualCompletionDate = 'ActualCompletionDate',
  /** column name */
  CompletedByUser = 'CompletedByUser',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OriginatingItemId = 'OriginatingItemId',
  /** column name */
  Outcome = 'Outcome',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  Summary = 'Summary',
  /** column name */
  TargetCompletionDate = 'TargetCompletionDate',
  /** column name */
  Title = 'Title'
}

export type InternalAuditReportUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<InternalAuditReportAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<InternalAuditReportDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<InternalAuditReportDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<InternalAuditReportDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<InternalAuditReportIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<InternalAuditReportPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<InternalAuditReportSetInput>;
  /** filter the rows which have to be updated */
  where: InternalAuditReportBoolExp;
};

/** aggregate var_pop on columns */
export type InternalAuditReportVarPopFields = {
  __typename?: 'internal_audit_report_var_pop_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportVarPopOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type InternalAuditReportVarSampFields = {
  __typename?: 'internal_audit_report_var_samp_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportVarSampOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type InternalAuditReportVarianceFields = {
  __typename?: 'internal_audit_report_variance_fields';
  Outcome?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.internal_audit_report" */
export type InternalAuditReportVarianceOrderBy = {
  Outcome?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "interval". All fields are combined with logical 'AND'. */
export type IntervalComparisonExp = {
  _eq?: InputMaybe<Scalars['interval']['input']>;
  _gt?: InputMaybe<Scalars['interval']['input']>;
  _gte?: InputMaybe<Scalars['interval']['input']>;
  _in?: InputMaybe<Array<Scalars['interval']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['interval']['input']>;
  _lte?: InputMaybe<Scalars['interval']['input']>;
  _neq?: InputMaybe<Scalars['interval']['input']>;
  _nin?: InputMaybe<Array<Scalars['interval']['input']>>;
};

/** columns and relationships of "risksmart.issue" */
export type Issue = {
  __typename?: 'issue';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateIdentified: Scalars['timestamptz']['output'];
  DateOccurred: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactsCustomer?: Maybe<Scalars['Boolean']['output']>;
  IsExternalIssue?: Maybe<Scalars['Boolean']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RaisedAtTimestamp: Scalars['timestamptz']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
  /** An array relationship */
  actions: Array<ActionParent>;
  /** An aggregate relationship */
  actions_aggregate: ActionParentAggregate;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  assessment?: Maybe<IssueAssessment>;
  /** An array relationship */
  causes: Array<Cause>;
  /** An aggregate relationship */
  causes_aggregate: CauseAggregate;
  /** An array relationship */
  consequences: Array<Consequence>;
  /** An aggregate relationship */
  consequences_aggregate: ConsequenceAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  parents: Array<IssueParent>;
  /** An aggregate relationship */
  parents_aggregate: IssueParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An array relationship */
  updates: Array<IssueUpdate>;
  /** An aggregate relationship */
  updates_aggregate: IssueUpdateAggregate;
};


/** columns and relationships of "risksmart.issue" */
export type IssueCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueCausesArgs = {
  distinct_on?: InputMaybe<Array<CauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseOrderBy>>;
  where?: InputMaybe<CauseBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueCausesAggregateArgs = {
  distinct_on?: InputMaybe<Array<CauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseOrderBy>>;
  where?: InputMaybe<CauseBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueConsequencesArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceOrderBy>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueConsequencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceOrderBy>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueParentsArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssuePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssuePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueUpdatesArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateOrderBy>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};


/** columns and relationships of "risksmart.issue" */
export type IssueUpdatesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateOrderBy>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};

/** columns and relationships of "risksmart.issue_action_audit" */
export type IssueActionAudit = {
  __typename?: 'issue_action_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.issue_action_audit" */
export type IssueActionAuditAggregate = {
  __typename?: 'issue_action_audit_aggregate';
  aggregate?: Maybe<IssueActionAuditAggregateFields>;
  nodes: Array<IssueActionAudit>;
};

/** aggregate fields of "risksmart.issue_action_audit" */
export type IssueActionAuditAggregateFields = {
  __typename?: 'issue_action_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IssueActionAuditMaxFields>;
  min?: Maybe<IssueActionAuditMinFields>;
};


/** aggregate fields of "risksmart.issue_action_audit" */
export type IssueActionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueActionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_action_audit". All fields are combined with a logical 'AND'. */
export type IssueActionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActionId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  IssueId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueActionAuditBoolExp>>;
  _not?: InputMaybe<IssueActionAuditBoolExp>;
  _or?: InputMaybe<Array<IssueActionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.issue_action_audit" */
export enum IssueActionAuditConstraint {
  /** unique or primary key constraint on columns "ActionId", "ModifiedAtTimestamp", "IssueId" */
  IssueActionAuditPkey = 'issue_action_audit_pkey'
}

/** input type for inserting data into table "risksmart.issue_action_audit" */
export type IssueActionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IssueActionAuditMaxFields = {
  __typename?: 'issue_action_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueActionAuditMinFields = {
  __typename?: 'issue_action_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue_action_audit" */
export type IssueActionAuditMutationResponse = {
  __typename?: 'issue_action_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueActionAudit>;
};

/** on_conflict condition type for table "risksmart.issue_action_audit" */
export type IssueActionAuditOnConflict = {
  constraint: IssueActionAuditConstraint;
  update_columns?: Array<IssueActionAuditUpdateColumn>;
  where?: InputMaybe<IssueActionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_action_audit". */
export type IssueActionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.issue_action_audit */
export type IssueActionAuditPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.issue_action_audit" */
export enum IssueActionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.issue_action_audit" */
export type IssueActionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "issue_action_audit" */
export type IssueActionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueActionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueActionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.issue_action_audit" */
export enum IssueActionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type IssueActionAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueActionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IssueActionAuditBoolExp;
};

/** aggregated selection of "risksmart.issue" */
export type IssueAggregate = {
  __typename?: 'issue_aggregate';
  aggregate?: Maybe<IssueAggregateFields>;
  nodes: Array<Issue>;
};

/** aggregate fields of "risksmart.issue" */
export type IssueAggregateFields = {
  __typename?: 'issue_aggregate_fields';
  avg?: Maybe<IssueAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IssueMaxFields>;
  min?: Maybe<IssueMinFields>;
  stddev?: Maybe<IssueStddevFields>;
  stddev_pop?: Maybe<IssueStddevPopFields>;
  stddev_samp?: Maybe<IssueStddevSampFields>;
  sum?: Maybe<IssueSumFields>;
  var_pop?: Maybe<IssueVarPopFields>;
  var_samp?: Maybe<IssueVarSampFields>;
  variance?: Maybe<IssueVarianceFields>;
};


/** aggregate fields of "risksmart.issue" */
export type IssueAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IssueAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessment = {
  __typename?: 'issue_assessment';
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  IssueCausedBySystemIssue?: Maybe<Scalars['Boolean']['output']>;
  IssueCausedByThirdParty?: Maybe<Scalars['Boolean']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyBreach?: Maybe<Scalars['Boolean']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  RegulatoryBreach?: Maybe<Scalars['Boolean']['output']>;
  Reportable?: Maybe<Scalars['Boolean']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<IssueAssessmentStatusEnum>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  certifiedIndividual?: Maybe<User>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Issue>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  policyOwner?: Maybe<User>;
  /** An object relationship */
  status?: Maybe<IssueAssessmentStatus>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessmentCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessmentMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessmentDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessmentDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessmentPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.issue_assessment" */
export type IssueAssessmentPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.issue_assessment" */
export type IssueAssessmentAggregate = {
  __typename?: 'issue_assessment_aggregate';
  aggregate?: Maybe<IssueAssessmentAggregateFields>;
  nodes: Array<IssueAssessment>;
};

/** aggregate fields of "risksmart.issue_assessment" */
export type IssueAssessmentAggregateFields = {
  __typename?: 'issue_assessment_aggregate_fields';
  avg?: Maybe<IssueAssessmentAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IssueAssessmentMaxFields>;
  min?: Maybe<IssueAssessmentMinFields>;
  stddev?: Maybe<IssueAssessmentStddevFields>;
  stddev_pop?: Maybe<IssueAssessmentStddevPopFields>;
  stddev_samp?: Maybe<IssueAssessmentStddevSampFields>;
  sum?: Maybe<IssueAssessmentSumFields>;
  var_pop?: Maybe<IssueAssessmentVarPopFields>;
  var_samp?: Maybe<IssueAssessmentVarSampFields>;
  variance?: Maybe<IssueAssessmentVarianceFields>;
};


/** aggregate fields of "risksmart.issue_assessment" */
export type IssueAssessmentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueAssessmentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IssueAssessmentAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.issue_assessment_audit" */
export type IssueAssessmentAudit = {
  __typename?: 'issue_assessment_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  IssueCausedBySystemIssue?: Maybe<Scalars['Boolean']['output']>;
  IssueCausedByThirdParty?: Maybe<Scalars['Boolean']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyBreach?: Maybe<Scalars['Boolean']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  RegulatoryBreach?: Maybe<Scalars['Boolean']['output']>;
  Reportable?: Maybe<Scalars['Boolean']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditAggregate = {
  __typename?: 'issue_assessment_audit_aggregate';
  aggregate?: Maybe<IssueAssessmentAuditAggregateFields>;
  nodes: Array<IssueAssessmentAudit>;
};

/** aggregate fields of "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditAggregateFields = {
  __typename?: 'issue_assessment_audit_aggregate_fields';
  avg?: Maybe<IssueAssessmentAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IssueAssessmentAuditMaxFields>;
  min?: Maybe<IssueAssessmentAuditMinFields>;
  stddev?: Maybe<IssueAssessmentAuditStddevFields>;
  stddev_pop?: Maybe<IssueAssessmentAuditStddevPopFields>;
  stddev_samp?: Maybe<IssueAssessmentAuditStddevSampFields>;
  sum?: Maybe<IssueAssessmentAuditSumFields>;
  var_pop?: Maybe<IssueAssessmentAuditVarPopFields>;
  var_samp?: Maybe<IssueAssessmentAuditVarSampFields>;
  variance?: Maybe<IssueAssessmentAuditVarianceFields>;
};


/** aggregate fields of "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueAssessmentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IssueAssessmentAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type IssueAssessmentAuditAvgFields = {
  __typename?: 'issue_assessment_audit_avg_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_assessment_audit". All fields are combined with a logical 'AND'. */
export type IssueAssessmentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActualCloseDate?: InputMaybe<TimestamptzComparisonExp>;
  CertifiedIndividual?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  IssueCausedBySystemIssue?: InputMaybe<BooleanComparisonExp>;
  IssueCausedByThirdParty?: InputMaybe<BooleanComparisonExp>;
  IssueType?: InputMaybe<StringComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  PoliciesBreached?: InputMaybe<StringComparisonExp>;
  PolicyBreach?: InputMaybe<BooleanComparisonExp>;
  PolicyOwner?: InputMaybe<StringComparisonExp>;
  PolicyOwnerCommentary?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  RegulationsBreached?: InputMaybe<StringComparisonExp>;
  RegulatoryBreach?: InputMaybe<BooleanComparisonExp>;
  Reportable?: InputMaybe<BooleanComparisonExp>;
  Severity?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  SystemResponsible?: InputMaybe<StringComparisonExp>;
  TargetCloseDate?: InputMaybe<TimestamptzComparisonExp>;
  ThirdPartyResponsible?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueAssessmentAuditBoolExp>>;
  _not?: InputMaybe<IssueAssessmentAuditBoolExp>;
  _or?: InputMaybe<Array<IssueAssessmentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.issue_assessment_audit" */
export enum IssueAssessmentAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  IssueAssessmentAuditPkey = 'issue_assessment_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IssueAssessmentAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IssueAssessmentAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IssueAssessmentAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditIncInput = {
  Severity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IssueAssessmentAuditMaxFields = {
  __typename?: 'issue_assessment_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueAssessmentAuditMinFields = {
  __typename?: 'issue_assessment_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditMutationResponse = {
  __typename?: 'issue_assessment_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueAssessmentAudit>;
};

/** on_conflict condition type for table "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditOnConflict = {
  constraint: IssueAssessmentAuditConstraint;
  update_columns?: Array<IssueAssessmentAuditUpdateColumn>;
  where?: InputMaybe<IssueAssessmentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_assessment_audit". */
export type IssueAssessmentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActualCloseDate?: InputMaybe<OrderBy>;
  CertifiedIndividual?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IssueCausedBySystemIssue?: InputMaybe<OrderBy>;
  IssueCausedByThirdParty?: InputMaybe<OrderBy>;
  IssueType?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  PoliciesBreached?: InputMaybe<OrderBy>;
  PolicyBreach?: InputMaybe<OrderBy>;
  PolicyOwner?: InputMaybe<OrderBy>;
  PolicyOwnerCommentary?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  RegulationsBreached?: InputMaybe<OrderBy>;
  RegulatoryBreach?: InputMaybe<OrderBy>;
  Reportable?: InputMaybe<OrderBy>;
  Severity?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  SystemResponsible?: InputMaybe<OrderBy>;
  TargetCloseDate?: InputMaybe<OrderBy>;
  ThirdPartyResponsible?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.issue_assessment_audit */
export type IssueAssessmentAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IssueAssessmentAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue_assessment_audit" */
export enum IssueAssessmentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActualCloseDate = 'ActualCloseDate',
  /** column name */
  CertifiedIndividual = 'CertifiedIndividual',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  IssueCausedBySystemIssue = 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty = 'IssueCausedByThirdParty',
  /** column name */
  IssueType = 'IssueType',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  PoliciesBreached = 'PoliciesBreached',
  /** column name */
  PolicyBreach = 'PolicyBreach',
  /** column name */
  PolicyOwner = 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary = 'PolicyOwnerCommentary',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  RegulationsBreached = 'RegulationsBreached',
  /** column name */
  RegulatoryBreach = 'RegulatoryBreach',
  /** column name */
  Reportable = 'Reportable',
  /** column name */
  Severity = 'Severity',
  /** column name */
  Status = 'Status',
  /** column name */
  SystemResponsible = 'SystemResponsible',
  /** column name */
  TargetCloseDate = 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible = 'ThirdPartyResponsible'
}

/** input type for updating data in table "risksmart.issue_assessment_audit" */
export type IssueAssessmentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type IssueAssessmentAuditStddevFields = {
  __typename?: 'issue_assessment_audit_stddev_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IssueAssessmentAuditStddevPopFields = {
  __typename?: 'issue_assessment_audit_stddev_pop_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IssueAssessmentAuditStddevSampFields = {
  __typename?: 'issue_assessment_audit_stddev_samp_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "issue_assessment_audit" */
export type IssueAssessmentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueAssessmentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueAssessmentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IssueAssessmentAuditSumFields = {
  __typename?: 'issue_assessment_audit_sum_fields';
  Severity?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.issue_assessment_audit" */
export enum IssueAssessmentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActualCloseDate = 'ActualCloseDate',
  /** column name */
  CertifiedIndividual = 'CertifiedIndividual',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  IssueCausedBySystemIssue = 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty = 'IssueCausedByThirdParty',
  /** column name */
  IssueType = 'IssueType',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  PoliciesBreached = 'PoliciesBreached',
  /** column name */
  PolicyBreach = 'PolicyBreach',
  /** column name */
  PolicyOwner = 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary = 'PolicyOwnerCommentary',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  RegulationsBreached = 'RegulationsBreached',
  /** column name */
  RegulatoryBreach = 'RegulatoryBreach',
  /** column name */
  Reportable = 'Reportable',
  /** column name */
  Severity = 'Severity',
  /** column name */
  Status = 'Status',
  /** column name */
  SystemResponsible = 'SystemResponsible',
  /** column name */
  TargetCloseDate = 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible = 'ThirdPartyResponsible'
}

export type IssueAssessmentAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IssueAssessmentAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IssueAssessmentAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IssueAssessmentAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IssueAssessmentAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IssueAssessmentAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IssueAssessmentAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueAssessmentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IssueAssessmentAuditBoolExp;
};

/** aggregate var_pop on columns */
export type IssueAssessmentAuditVarPopFields = {
  __typename?: 'issue_assessment_audit_var_pop_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IssueAssessmentAuditVarSampFields = {
  __typename?: 'issue_assessment_audit_var_samp_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IssueAssessmentAuditVarianceFields = {
  __typename?: 'issue_assessment_audit_variance_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type IssueAssessmentAvgFields = {
  __typename?: 'issue_assessment_avg_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_assessment". All fields are combined with a logical 'AND'. */
export type IssueAssessmentBoolExp = {
  ActualCloseDate?: InputMaybe<TimestamptzComparisonExp>;
  CertifiedIndividual?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  IssueCausedBySystemIssue?: InputMaybe<BooleanComparisonExp>;
  IssueCausedByThirdParty?: InputMaybe<BooleanComparisonExp>;
  IssueType?: InputMaybe<StringComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  PoliciesBreached?: InputMaybe<StringComparisonExp>;
  PolicyBreach?: InputMaybe<BooleanComparisonExp>;
  PolicyOwner?: InputMaybe<StringComparisonExp>;
  PolicyOwnerCommentary?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  RegulationsBreached?: InputMaybe<StringComparisonExp>;
  RegulatoryBreach?: InputMaybe<BooleanComparisonExp>;
  Reportable?: InputMaybe<BooleanComparisonExp>;
  Severity?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<IssueAssessmentStatusEnumComparisonExp>;
  SystemResponsible?: InputMaybe<StringComparisonExp>;
  TargetCloseDate?: InputMaybe<TimestamptzComparisonExp>;
  ThirdPartyResponsible?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueAssessmentBoolExp>>;
  _not?: InputMaybe<IssueAssessmentBoolExp>;
  _or?: InputMaybe<Array<IssueAssessmentBoolExp>>;
  certifiedIndividual?: InputMaybe<UserBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<IssueBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  policyOwner?: InputMaybe<UserBoolExp>;
  status?: InputMaybe<IssueAssessmentStatusBoolExp>;
};

/** unique or primary key constraints on table "risksmart.issue_assessment" */
export enum IssueAssessmentConstraint {
  /** unique or primary key constraint on columns "ParentIssueId" */
  IdxIssueAssessmentParentIssueId = 'idx_issueAssessment_parentIssueId',
  /** unique or primary key constraint on columns "Id" */
  IssueAssessmentPkey = 'issue_assessment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IssueAssessmentDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IssueAssessmentDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IssueAssessmentDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.issue_assessment" */
export type IssueAssessmentIncInput = {
  Severity?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.issue_assessment" */
export type IssueAssessmentInsertInput = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<IssueAssessmentStatusEnum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
  certifiedIndividual?: InputMaybe<UserObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<IssueObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  policyOwner?: InputMaybe<UserObjRelInsertInput>;
  status?: InputMaybe<IssueAssessmentStatusObjRelInsertInput>;
};

/** aggregate max on columns */
export type IssueAssessmentMaxFields = {
  __typename?: 'issue_assessment_max_fields';
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueAssessmentMinFields = {
  __typename?: 'issue_assessment_min_fields';
  ActualCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  CertifiedIndividual?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  IssueType?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  PoliciesBreached?: Maybe<Scalars['String']['output']>;
  PolicyOwner?: Maybe<Scalars['String']['output']>;
  PolicyOwnerCommentary?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  RegulationsBreached?: Maybe<Scalars['String']['output']>;
  Severity?: Maybe<Scalars['Int']['output']>;
  SystemResponsible?: Maybe<Scalars['String']['output']>;
  TargetCloseDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyResponsible?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue_assessment" */
export type IssueAssessmentMutationResponse = {
  __typename?: 'issue_assessment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueAssessment>;
};

/** input type for inserting object relation for remote table "risksmart.issue_assessment" */
export type IssueAssessmentObjRelInsertInput = {
  data: IssueAssessmentInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueAssessmentOnConflict>;
};

/** on_conflict condition type for table "risksmart.issue_assessment" */
export type IssueAssessmentOnConflict = {
  constraint: IssueAssessmentConstraint;
  update_columns?: Array<IssueAssessmentUpdateColumn>;
  where?: InputMaybe<IssueAssessmentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_assessment". */
export type IssueAssessmentOrderBy = {
  ActualCloseDate?: InputMaybe<OrderBy>;
  CertifiedIndividual?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IssueCausedBySystemIssue?: InputMaybe<OrderBy>;
  IssueCausedByThirdParty?: InputMaybe<OrderBy>;
  IssueType?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  PoliciesBreached?: InputMaybe<OrderBy>;
  PolicyBreach?: InputMaybe<OrderBy>;
  PolicyOwner?: InputMaybe<OrderBy>;
  PolicyOwnerCommentary?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  RegulationsBreached?: InputMaybe<OrderBy>;
  RegulatoryBreach?: InputMaybe<OrderBy>;
  Reportable?: InputMaybe<OrderBy>;
  Severity?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  SystemResponsible?: InputMaybe<OrderBy>;
  TargetCloseDate?: InputMaybe<OrderBy>;
  ThirdPartyResponsible?: InputMaybe<OrderBy>;
  certifiedIndividual?: InputMaybe<UserOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<IssueOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  policyOwner?: InputMaybe<UserOrderBy>;
  status?: InputMaybe<IssueAssessmentStatusOrderBy>;
};

/** primary key columns input for table: risksmart.issue_assessment */
export type IssueAssessmentPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IssueAssessmentPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue_assessment" */
export enum IssueAssessmentSelectColumn {
  /** column name */
  ActualCloseDate = 'ActualCloseDate',
  /** column name */
  CertifiedIndividual = 'CertifiedIndividual',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  IssueCausedBySystemIssue = 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty = 'IssueCausedByThirdParty',
  /** column name */
  IssueType = 'IssueType',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  PoliciesBreached = 'PoliciesBreached',
  /** column name */
  PolicyBreach = 'PolicyBreach',
  /** column name */
  PolicyOwner = 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary = 'PolicyOwnerCommentary',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  RegulationsBreached = 'RegulationsBreached',
  /** column name */
  RegulatoryBreach = 'RegulatoryBreach',
  /** column name */
  Reportable = 'Reportable',
  /** column name */
  Severity = 'Severity',
  /** column name */
  Status = 'Status',
  /** column name */
  SystemResponsible = 'SystemResponsible',
  /** column name */
  TargetCloseDate = 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible = 'ThirdPartyResponsible'
}

/** input type for updating data in table "risksmart.issue_assessment" */
export type IssueAssessmentSetInput = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<IssueAssessmentStatusEnum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.issue_assessment_status" */
export type IssueAssessmentStatus = {
  __typename?: 'issue_assessment_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusAggregate = {
  __typename?: 'issue_assessment_status_aggregate';
  aggregate?: Maybe<IssueAssessmentStatusAggregateFields>;
  nodes: Array<IssueAssessmentStatus>;
};

/** aggregate fields of "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusAggregateFields = {
  __typename?: 'issue_assessment_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IssueAssessmentStatusMaxFields>;
  min?: Maybe<IssueAssessmentStatusMinFields>;
};


/** aggregate fields of "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueAssessmentStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_assessment_status". All fields are combined with a logical 'AND'. */
export type IssueAssessmentStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueAssessmentStatusBoolExp>>;
  _not?: InputMaybe<IssueAssessmentStatusBoolExp>;
  _or?: InputMaybe<Array<IssueAssessmentStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.issue_assessment_status" */
export enum IssueAssessmentStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  IssueAssessmentStatusPkey = 'issue_assessment_status_pkey'
}

export enum IssueAssessmentStatusEnum {
  /** awaiting closure */
  Awaitingclosure = 'awaitingclosure',
  /** Closed */
  Closed = 'closed',
  /** Declined */
  Declined = 'declined',
  /** 1st line approval */
  Firstlineapproval = 'firstlineapproval',
  /** Open */
  Open = 'open',
  /** Pending */
  Pending = 'pending'
}

/** Boolean expression to compare columns of type "issue_assessment_status_enum". All fields are combined with logical 'AND'. */
export type IssueAssessmentStatusEnumComparisonExp = {
  _eq?: InputMaybe<IssueAssessmentStatusEnum>;
  _in?: InputMaybe<Array<IssueAssessmentStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<IssueAssessmentStatusEnum>;
  _nin?: InputMaybe<Array<IssueAssessmentStatusEnum>>;
};

/** input type for inserting data into table "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IssueAssessmentStatusMaxFields = {
  __typename?: 'issue_assessment_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueAssessmentStatusMinFields = {
  __typename?: 'issue_assessment_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusMutationResponse = {
  __typename?: 'issue_assessment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueAssessmentStatus>;
};

/** input type for inserting object relation for remote table "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusObjRelInsertInput = {
  data: IssueAssessmentStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueAssessmentStatusOnConflict>;
};

/** on_conflict condition type for table "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusOnConflict = {
  constraint: IssueAssessmentStatusConstraint;
  update_columns?: Array<IssueAssessmentStatusUpdateColumn>;
  where?: InputMaybe<IssueAssessmentStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_assessment_status". */
export type IssueAssessmentStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.issue_assessment_status */
export type IssueAssessmentStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.issue_assessment_status" */
export enum IssueAssessmentStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.issue_assessment_status" */
export type IssueAssessmentStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "issue_assessment_status" */
export type IssueAssessmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueAssessmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueAssessmentStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.issue_assessment_status" */
export enum IssueAssessmentStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type IssueAssessmentStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueAssessmentStatusSetInput>;
  /** filter the rows which have to be updated */
  where: IssueAssessmentStatusBoolExp;
};

/** aggregate stddev on columns */
export type IssueAssessmentStddevFields = {
  __typename?: 'issue_assessment_stddev_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IssueAssessmentStddevPopFields = {
  __typename?: 'issue_assessment_stddev_pop_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IssueAssessmentStddevSampFields = {
  __typename?: 'issue_assessment_stddev_samp_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "issue_assessment" */
export type IssueAssessmentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueAssessmentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueAssessmentStreamCursorValueInput = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<IssueAssessmentStatusEnum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IssueAssessmentSumFields = {
  __typename?: 'issue_assessment_sum_fields';
  Severity?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.issue_assessment" */
export enum IssueAssessmentUpdateColumn {
  /** column name */
  ActualCloseDate = 'ActualCloseDate',
  /** column name */
  CertifiedIndividual = 'CertifiedIndividual',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  IssueCausedBySystemIssue = 'IssueCausedBySystemIssue',
  /** column name */
  IssueCausedByThirdParty = 'IssueCausedByThirdParty',
  /** column name */
  IssueType = 'IssueType',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  PoliciesBreached = 'PoliciesBreached',
  /** column name */
  PolicyBreach = 'PolicyBreach',
  /** column name */
  PolicyOwner = 'PolicyOwner',
  /** column name */
  PolicyOwnerCommentary = 'PolicyOwnerCommentary',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  RegulationsBreached = 'RegulationsBreached',
  /** column name */
  RegulatoryBreach = 'RegulatoryBreach',
  /** column name */
  Reportable = 'Reportable',
  /** column name */
  Severity = 'Severity',
  /** column name */
  Status = 'Status',
  /** column name */
  SystemResponsible = 'SystemResponsible',
  /** column name */
  TargetCloseDate = 'TargetCloseDate',
  /** column name */
  ThirdPartyResponsible = 'ThirdPartyResponsible'
}

export type IssueAssessmentUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IssueAssessmentAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IssueAssessmentDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IssueAssessmentDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IssueAssessmentDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IssueAssessmentIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IssueAssessmentPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueAssessmentSetInput>;
  /** filter the rows which have to be updated */
  where: IssueAssessmentBoolExp;
};

/** aggregate var_pop on columns */
export type IssueAssessmentVarPopFields = {
  __typename?: 'issue_assessment_var_pop_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IssueAssessmentVarSampFields = {
  __typename?: 'issue_assessment_var_samp_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IssueAssessmentVarianceFields = {
  __typename?: 'issue_assessment_variance_fields';
  Severity?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.issue_audit" */
export type IssueAudit = {
  __typename?: 'issue_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  DateIdentified: Scalars['timestamptz']['output'];
  DateOccurred: Scalars['timestamptz']['output'];
  Details: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactsCustomer?: Maybe<Scalars['Boolean']['output']>;
  IsExternalIssue?: Maybe<Scalars['Boolean']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.issue_audit" */
export type IssueAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_audit" */
export type IssueAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.issue_audit" */
export type IssueAuditAggregate = {
  __typename?: 'issue_audit_aggregate';
  aggregate?: Maybe<IssueAuditAggregateFields>;
  nodes: Array<IssueAudit>;
};

/** aggregate fields of "risksmart.issue_audit" */
export type IssueAuditAggregateFields = {
  __typename?: 'issue_audit_aggregate_fields';
  avg?: Maybe<IssueAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<IssueAuditMaxFields>;
  min?: Maybe<IssueAuditMinFields>;
  stddev?: Maybe<IssueAuditStddevFields>;
  stddev_pop?: Maybe<IssueAuditStddevPopFields>;
  stddev_samp?: Maybe<IssueAuditStddevSampFields>;
  sum?: Maybe<IssueAuditSumFields>;
  var_pop?: Maybe<IssueAuditVarPopFields>;
  var_samp?: Maybe<IssueAuditVarSampFields>;
  variance?: Maybe<IssueAuditVarianceFields>;
};


/** aggregate fields of "risksmart.issue_audit" */
export type IssueAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IssueAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type IssueAuditAvgFields = {
  __typename?: 'issue_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_audit". All fields are combined with a logical 'AND'. */
export type IssueAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DateIdentified?: InputMaybe<TimestamptzComparisonExp>;
  DateOccurred?: InputMaybe<TimestamptzComparisonExp>;
  Details?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactsCustomer?: InputMaybe<BooleanComparisonExp>;
  IsExternalIssue?: InputMaybe<BooleanComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RaisedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueAuditBoolExp>>;
  _not?: InputMaybe<IssueAuditBoolExp>;
  _or?: InputMaybe<Array<IssueAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.issue_audit" */
export enum IssueAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  IssueAuditPkey = 'issue_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IssueAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IssueAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IssueAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.issue_audit" */
export type IssueAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.issue_audit" */
export type IssueAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IssueAuditMaxFields = {
  __typename?: 'issue_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateIdentified?: Maybe<Scalars['timestamptz']['output']>;
  DateOccurred?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueAuditMinFields = {
  __typename?: 'issue_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateIdentified?: Maybe<Scalars['timestamptz']['output']>;
  DateOccurred?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue_audit" */
export type IssueAuditMutationResponse = {
  __typename?: 'issue_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueAudit>;
};

/** on_conflict condition type for table "risksmart.issue_audit" */
export type IssueAuditOnConflict = {
  constraint: IssueAuditConstraint;
  update_columns?: Array<IssueAuditUpdateColumn>;
  where?: InputMaybe<IssueAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_audit". */
export type IssueAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DateIdentified?: InputMaybe<OrderBy>;
  DateOccurred?: InputMaybe<OrderBy>;
  Details?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactsCustomer?: InputMaybe<OrderBy>;
  IsExternalIssue?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RaisedAtTimestamp?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.issue_audit */
export type IssueAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IssueAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue_audit" */
export enum IssueAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateIdentified = 'DateIdentified',
  /** column name */
  DateOccurred = 'DateOccurred',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactsCustomer = 'ImpactsCustomer',
  /** column name */
  IsExternalIssue = 'IsExternalIssue',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RaisedAtTimestamp = 'RaisedAtTimestamp',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.issue_audit" */
export type IssueAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type IssueAuditStddevFields = {
  __typename?: 'issue_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IssueAuditStddevPopFields = {
  __typename?: 'issue_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IssueAuditStddevSampFields = {
  __typename?: 'issue_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "issue_audit" */
export type IssueAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IssueAuditSumFields = {
  __typename?: 'issue_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.issue_audit" */
export enum IssueAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateIdentified = 'DateIdentified',
  /** column name */
  DateOccurred = 'DateOccurred',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactsCustomer = 'ImpactsCustomer',
  /** column name */
  IsExternalIssue = 'IsExternalIssue',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RaisedAtTimestamp = 'RaisedAtTimestamp',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

export type IssueAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IssueAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IssueAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IssueAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IssueAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IssueAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IssueAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IssueAuditBoolExp;
};

/** aggregate var_pop on columns */
export type IssueAuditVarPopFields = {
  __typename?: 'issue_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IssueAuditVarSampFields = {
  __typename?: 'issue_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IssueAuditVarianceFields = {
  __typename?: 'issue_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type IssueAvgFields = {
  __typename?: 'issue_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue". All fields are combined with a logical 'AND'. */
export type IssueBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  DateIdentified?: InputMaybe<TimestamptzComparisonExp>;
  DateOccurred?: InputMaybe<TimestamptzComparisonExp>;
  Details?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactsCustomer?: InputMaybe<BooleanComparisonExp>;
  IsExternalIssue?: InputMaybe<BooleanComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RaisedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueBoolExp>>;
  _not?: InputMaybe<IssueBoolExp>;
  _or?: InputMaybe<Array<IssueBoolExp>>;
  actions?: InputMaybe<ActionParentBoolExp>;
  actions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  assessment?: InputMaybe<IssueAssessmentBoolExp>;
  causes?: InputMaybe<CauseBoolExp>;
  causes_aggregate?: InputMaybe<CauseAggregateBoolExp>;
  consequences?: InputMaybe<ConsequenceBoolExp>;
  consequences_aggregate?: InputMaybe<ConsequenceAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parents?: InputMaybe<IssueParentBoolExp>;
  parents_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  updates?: InputMaybe<IssueUpdateBoolExp>;
  updates_aggregate?: InputMaybe<IssueUpdateAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.issue" */
export enum IssueConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxIssueOrgkeySequentialid = 'idx_issue_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  IssuePkey = 'issue_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IssueDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IssueDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IssueDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.issue" */
export type IssueIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.issue" */
export type IssueInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  actions?: InputMaybe<ActionParentArrRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  assessment?: InputMaybe<IssueAssessmentObjRelInsertInput>;
  causes?: InputMaybe<CauseArrRelInsertInput>;
  consequences?: InputMaybe<ConsequenceArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parents?: InputMaybe<IssueParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  updates?: InputMaybe<IssueUpdateArrRelInsertInput>;
};

/** aggregate max on columns */
export type IssueMaxFields = {
  __typename?: 'issue_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateIdentified?: Maybe<Scalars['timestamptz']['output']>;
  DateOccurred?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueMinFields = {
  __typename?: 'issue_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  DateIdentified?: Maybe<Scalars['timestamptz']['output']>;
  DateOccurred?: Maybe<Scalars['timestamptz']['output']>;
  Details?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RaisedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue" */
export type IssueMutationResponse = {
  __typename?: 'issue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Issue>;
};

/** input type for inserting object relation for remote table "risksmart.issue" */
export type IssueObjRelInsertInput = {
  data: IssueInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueOnConflict>;
};

/** on_conflict condition type for table "risksmart.issue" */
export type IssueOnConflict = {
  constraint: IssueConstraint;
  update_columns?: Array<IssueUpdateColumn>;
  where?: InputMaybe<IssueBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue". */
export type IssueOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  DateIdentified?: InputMaybe<OrderBy>;
  DateOccurred?: InputMaybe<OrderBy>;
  Details?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactsCustomer?: InputMaybe<OrderBy>;
  IsExternalIssue?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RaisedAtTimestamp?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  actions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  assessment?: InputMaybe<IssueAssessmentOrderBy>;
  causes_aggregate?: InputMaybe<CauseAggregateOrderBy>;
  consequences_aggregate?: InputMaybe<ConsequenceAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parents_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  updates_aggregate?: InputMaybe<IssueUpdateAggregateOrderBy>;
};

/** columns and relationships of "risksmart.issue_parent" */
export type IssueParent = {
  __typename?: 'issue_parent';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: ParentTypeEnum;
  /** An object relationship */
  assessment?: Maybe<Assessment>;
  /** An object relationship */
  complianceMonitoringAssessment?: Maybe<ComplianceMonitoringAssessment>;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  internalAuditEntity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  internalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
};

/** aggregated selection of "risksmart.issue_parent" */
export type IssueParentAggregate = {
  __typename?: 'issue_parent_aggregate';
  aggregate?: Maybe<IssueParentAggregateFields>;
  nodes: Array<IssueParent>;
};

export type IssueParentAggregateBoolExp = {
  count?: InputMaybe<IssueParentAggregateBoolExpCount>;
};

export type IssueParentAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IssueParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IssueParentBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.issue_parent" */
export type IssueParentAggregateFields = {
  __typename?: 'issue_parent_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IssueParentMaxFields>;
  min?: Maybe<IssueParentMinFields>;
};


/** aggregate fields of "risksmart.issue_parent" */
export type IssueParentAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueParentSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.issue_parent" */
export type IssueParentAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IssueParentMaxOrderBy>;
  min?: InputMaybe<IssueParentMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.issue_parent" */
export type IssueParentArrRelInsertInput = {
  data: Array<IssueParentInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueParentOnConflict>;
};

/** columns and relationships of "risksmart.issue_parent_audit" */
export type IssueParentAudit = {
  __typename?: 'issue_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.issue_parent_audit" */
export type IssueParentAuditAggregate = {
  __typename?: 'issue_parent_audit_aggregate';
  aggregate?: Maybe<IssueParentAuditAggregateFields>;
  nodes: Array<IssueParentAudit>;
};

export type IssueParentAuditAggregateBoolExp = {
  count?: InputMaybe<IssueParentAuditAggregateBoolExpCount>;
};

export type IssueParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IssueParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.issue_parent_audit" */
export type IssueParentAuditAggregateFields = {
  __typename?: 'issue_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IssueParentAuditMaxFields>;
  min?: Maybe<IssueParentAuditMinFields>;
};


/** aggregate fields of "risksmart.issue_parent_audit" */
export type IssueParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.issue_parent_audit" */
export type IssueParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IssueParentAuditMaxOrderBy>;
  min?: InputMaybe<IssueParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.issue_parent_audit" */
export type IssueParentAuditArrRelInsertInput = {
  data: Array<IssueParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_parent_audit". All fields are combined with a logical 'AND'. */
export type IssueParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  IssueId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueParentAuditBoolExp>>;
  _not?: InputMaybe<IssueParentAuditBoolExp>;
  _or?: InputMaybe<Array<IssueParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.issue_parent_audit" */
export enum IssueParentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "ModifiedAtTimestamp", "IssueId" */
  IssueParentAuditPkey = 'issue_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.issue_parent_audit" */
export type IssueParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IssueParentAuditMaxFields = {
  __typename?: 'issue_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.issue_parent_audit" */
export type IssueParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IssueParentAuditMinFields = {
  __typename?: 'issue_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.issue_parent_audit" */
export type IssueParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.issue_parent_audit" */
export type IssueParentAuditMutationResponse = {
  __typename?: 'issue_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueParentAudit>;
};

/** on_conflict condition type for table "risksmart.issue_parent_audit" */
export type IssueParentAuditOnConflict = {
  constraint: IssueParentAuditConstraint;
  update_columns?: Array<IssueParentAuditUpdateColumn>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_parent_audit". */
export type IssueParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.issue_parent_audit */
export type IssueParentAuditPkColumnsInput = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.issue_parent_audit" */
export enum IssueParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.issue_parent_audit" */
export type IssueParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "issue_parent_audit" */
export type IssueParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.issue_parent_audit" */
export enum IssueParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

export type IssueParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IssueParentAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.issue_parent". All fields are combined with a logical 'AND'. */
export type IssueParentBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  IssueId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<IssueParentBoolExp>>;
  _not?: InputMaybe<IssueParentBoolExp>;
  _or?: InputMaybe<Array<IssueParentBoolExp>>;
  assessment?: InputMaybe<AssessmentBoolExp>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
  control?: InputMaybe<ControlBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  document?: InputMaybe<DocumentBoolExp>;
  internalAuditEntity?: InputMaybe<InternalAuditEntityBoolExp>;
  internalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  obligation?: InputMaybe<ObligationBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.issue_parent" */
export enum IssueParentConstraint {
  /** unique or primary key constraint on columns "ParentId", "IssueId" */
  IssueParentPkey = 'issue_parent_pkey'
}

/** input type for inserting data into table "risksmart.issue_parent" */
export type IssueParentInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentObjRelInsertInput>;
  control?: InputMaybe<ControlObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  internalAuditEntity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  internalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  obligation?: InputMaybe<ObligationObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
};

/** aggregate max on columns */
export type IssueParentMaxFields = {
  __typename?: 'issue_parent_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.issue_parent" */
export type IssueParentMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IssueParentMinFields = {
  __typename?: 'issue_parent_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.issue_parent" */
export type IssueParentMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.issue_parent" */
export type IssueParentMutationResponse = {
  __typename?: 'issue_parent_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueParent>;
};

/** on_conflict condition type for table "risksmart.issue_parent" */
export type IssueParentOnConflict = {
  constraint: IssueParentConstraint;
  update_columns?: Array<IssueParentUpdateColumn>;
  where?: InputMaybe<IssueParentBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_parent". */
export type IssueParentOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  assessment?: InputMaybe<AssessmentOrderBy>;
  complianceMonitoringAssessment?: InputMaybe<ComplianceMonitoringAssessmentOrderBy>;
  control?: InputMaybe<ControlOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  internalAuditEntity?: InputMaybe<InternalAuditEntityOrderBy>;
  internalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  obligation?: InputMaybe<ObligationOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
};

/** primary key columns input for table: risksmart.issue_parent */
export type IssueParentPkColumnsInput = {
  IssueId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.issue_parent" */
export enum IssueParentSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.issue_parent" */
export type IssueParentSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** Streaming cursor of the table "issue_parent" */
export type IssueParentStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueParentStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueParentStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** update columns of table "risksmart.issue_parent" */
export enum IssueParentUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

export type IssueParentUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueParentSetInput>;
  /** filter the rows which have to be updated */
  where: IssueParentBoolExp;
};

/** primary key columns input for table: risksmart.issue */
export type IssuePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IssuePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue" */
export enum IssueSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateIdentified = 'DateIdentified',
  /** column name */
  DateOccurred = 'DateOccurred',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactsCustomer = 'ImpactsCustomer',
  /** column name */
  IsExternalIssue = 'IsExternalIssue',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RaisedAtTimestamp = 'RaisedAtTimestamp',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.issue" */
export type IssueSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type IssueStddevFields = {
  __typename?: 'issue_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type IssueStddevPopFields = {
  __typename?: 'issue_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type IssueStddevSampFields = {
  __typename?: 'issue_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "issue" */
export type IssueStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified?: InputMaybe<Scalars['timestamptz']['input']>;
  DateOccurred?: InputMaybe<Scalars['timestamptz']['input']>;
  Details?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RaisedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type IssueSumFields = {
  __typename?: 'issue_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdate = {
  __typename?: 'issue_update';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdateCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdateMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdateFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdateFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdatePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.issue_update" */
export type IssueUpdatePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.issue_update" */
export type IssueUpdateAggregate = {
  __typename?: 'issue_update_aggregate';
  aggregate?: Maybe<IssueUpdateAggregateFields>;
  nodes: Array<IssueUpdate>;
};

export type IssueUpdateAggregateBoolExp = {
  count?: InputMaybe<IssueUpdateAggregateBoolExpCount>;
};

export type IssueUpdateAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<IssueUpdateBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.issue_update" */
export type IssueUpdateAggregateFields = {
  __typename?: 'issue_update_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IssueUpdateMaxFields>;
  min?: Maybe<IssueUpdateMinFields>;
};


/** aggregate fields of "risksmart.issue_update" */
export type IssueUpdateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.issue_update" */
export type IssueUpdateAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<IssueUpdateMaxOrderBy>;
  min?: InputMaybe<IssueUpdateMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IssueUpdateAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.issue_update" */
export type IssueUpdateArrRelInsertInput = {
  data: Array<IssueUpdateInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueUpdateOnConflict>;
};

/** columns and relationships of "risksmart.issue_update_audit" */
export type IssueUpdateAudit = {
  __typename?: 'issue_update_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentIssueId: Scalars['uuid']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.issue_update_audit" */
export type IssueUpdateAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.issue_update_audit" */
export type IssueUpdateAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.issue_update_audit" */
export type IssueUpdateAuditAggregate = {
  __typename?: 'issue_update_audit_aggregate';
  aggregate?: Maybe<IssueUpdateAuditAggregateFields>;
  nodes: Array<IssueUpdateAudit>;
};

/** aggregate fields of "risksmart.issue_update_audit" */
export type IssueUpdateAuditAggregateFields = {
  __typename?: 'issue_update_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<IssueUpdateAuditMaxFields>;
  min?: Maybe<IssueUpdateAuditMinFields>;
};


/** aggregate fields of "risksmart.issue_update_audit" */
export type IssueUpdateAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<IssueUpdateAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type IssueUpdateAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.issue_update_audit". All fields are combined with a logical 'AND'. */
export type IssueUpdateAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueUpdateAuditBoolExp>>;
  _not?: InputMaybe<IssueUpdateAuditBoolExp>;
  _or?: InputMaybe<Array<IssueUpdateAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.issue_update_audit" */
export enum IssueUpdateAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  IssueUpdateAuditPkey = 'issue_update_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IssueUpdateAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IssueUpdateAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IssueUpdateAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.issue_update_audit" */
export type IssueUpdateAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type IssueUpdateAuditMaxFields = {
  __typename?: 'issue_update_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type IssueUpdateAuditMinFields = {
  __typename?: 'issue_update_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.issue_update_audit" */
export type IssueUpdateAuditMutationResponse = {
  __typename?: 'issue_update_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueUpdateAudit>;
};

/** on_conflict condition type for table "risksmart.issue_update_audit" */
export type IssueUpdateAuditOnConflict = {
  constraint: IssueUpdateAuditConstraint;
  update_columns?: Array<IssueUpdateAuditUpdateColumn>;
  where?: InputMaybe<IssueUpdateAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_update_audit". */
export type IssueUpdateAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.issue_update_audit */
export type IssueUpdateAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IssueUpdateAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue_update_audit" */
export enum IssueUpdateAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.issue_update_audit" */
export type IssueUpdateAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "issue_update_audit" */
export type IssueUpdateAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueUpdateAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueUpdateAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.issue_update_audit" */
export enum IssueUpdateAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title'
}

export type IssueUpdateAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IssueUpdateAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IssueUpdateAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IssueUpdateAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IssueUpdateAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IssueUpdateAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueUpdateAuditSetInput>;
  /** filter the rows which have to be updated */
  where: IssueUpdateAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.issue_update". All fields are combined with a logical 'AND'. */
export type IssueUpdateBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentIssueId?: InputMaybe<UuidComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<IssueUpdateBoolExp>>;
  _not?: InputMaybe<IssueUpdateBoolExp>;
  _or?: InputMaybe<Array<IssueUpdateBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** update columns of table "risksmart.issue" */
export enum IssueUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  DateIdentified = 'DateIdentified',
  /** column name */
  DateOccurred = 'DateOccurred',
  /** column name */
  Details = 'Details',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactsCustomer = 'ImpactsCustomer',
  /** column name */
  IsExternalIssue = 'IsExternalIssue',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RaisedAtTimestamp = 'RaisedAtTimestamp',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Title = 'Title'
}

/** unique or primary key constraints on table "risksmart.issue_update" */
export enum IssueUpdateConstraint {
  /** unique or primary key constraint on columns "Id" */
  IssueUpdatePkey = 'issue_update_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type IssueUpdateDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type IssueUpdateDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type IssueUpdateDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.issue_update" */
export type IssueUpdateInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type IssueUpdateMaxFields = {
  __typename?: 'issue_update_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.issue_update" */
export type IssueUpdateMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type IssueUpdateMinFields = {
  __typename?: 'issue_update_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentIssueId?: Maybe<Scalars['uuid']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.issue_update" */
export type IssueUpdateMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.issue_update" */
export type IssueUpdateMutationResponse = {
  __typename?: 'issue_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<IssueUpdate>;
};

/** input type for inserting object relation for remote table "risksmart.issue_update" */
export type IssueUpdateObjRelInsertInput = {
  data: IssueUpdateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<IssueUpdateOnConflict>;
};

/** on_conflict condition type for table "risksmart.issue_update" */
export type IssueUpdateOnConflict = {
  constraint: IssueUpdateConstraint;
  update_columns?: Array<IssueUpdateUpdateColumn>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};

/** Ordering options when selecting data from "risksmart.issue_update". */
export type IssueUpdateOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentIssueId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.issue_update */
export type IssueUpdatePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type IssueUpdatePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.issue_update" */
export enum IssueUpdateSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.issue_update" */
export type IssueUpdateSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "issue_update" */
export type IssueUpdateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: IssueUpdateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type IssueUpdateStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId?: InputMaybe<Scalars['uuid']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.issue_update" */
export enum IssueUpdateUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentIssueId = 'ParentIssueId',
  /** column name */
  Title = 'Title'
}

export type IssueUpdateUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IssueUpdateAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IssueUpdateDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IssueUpdateDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IssueUpdateDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IssueUpdatePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueUpdateSetInput>;
  /** filter the rows which have to be updated */
  where: IssueUpdateBoolExp;
};

export type IssueUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<IssueAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<IssueDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<IssueDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<IssueDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<IssueIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<IssuePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<IssueSetInput>;
  /** filter the rows which have to be updated */
  where: IssueBoolExp;
};

/** aggregate var_pop on columns */
export type IssueVarPopFields = {
  __typename?: 'issue_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type IssueVarSampFields = {
  __typename?: 'issue_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type IssueVarianceFields = {
  __typename?: 'issue_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type JsonComparisonExp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type JsonbCastExp = {
  String?: InputMaybe<StringComparisonExp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type JsonbComparisonExp = {
  _cast?: InputMaybe<JsonbCastExp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "risksmart.linked_item" */
export type LinkedItem = {
  __typename?: 'linked_item';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  OrgKey: Scalars['String']['output'];
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source: Scalars['uuid']['output'];
  Target: Scalars['uuid']['output'];
  /** An object relationship */
  source_acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  source_action?: Maybe<Action>;
  /** An object relationship */
  source_appetite?: Maybe<Appetite>;
  /** An object relationship */
  source_assessment?: Maybe<Assessment>;
  /** An object relationship */
  source_assessment_activity?: Maybe<AssessmentActivity>;
  /** An object relationship */
  source_cause?: Maybe<Cause>;
  /** An object relationship */
  source_consequence?: Maybe<Consequence>;
  /** An object relationship */
  source_control?: Maybe<Control>;
  /** An object relationship */
  source_control_group?: Maybe<ControlGroup>;
  /** An object relationship */
  source_document?: Maybe<Document>;
  /** An object relationship */
  source_impact?: Maybe<Impact>;
  /** An object relationship */
  source_indicator?: Maybe<Indicator>;
  /** An object relationship */
  source_internal_audit_entity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  source_internal_audit_report?: Maybe<InternalAuditReport>;
  /** An object relationship */
  source_issue?: Maybe<Issue>;
  /** An object relationship */
  source_obligation?: Maybe<Obligation>;
  /** An array relationship */
  source_permissions: Array<Permission>;
  /** An aggregate relationship */
  source_permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  source_risk?: Maybe<Risk>;
  /** An object relationship */
  source_third_party?: Maybe<ThirdParty>;
  /** An object relationship */
  target_acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  target_action?: Maybe<Action>;
  /** An object relationship */
  target_action_update?: Maybe<ActionUpdate>;
  /** An object relationship */
  target_appetite?: Maybe<Appetite>;
  /** An object relationship */
  target_assessment?: Maybe<Assessment>;
  /** An object relationship */
  target_assessment_activity?: Maybe<AssessmentActivity>;
  /** An object relationship */
  target_cause?: Maybe<Cause>;
  /** An object relationship */
  target_consequence?: Maybe<Consequence>;
  /** An object relationship */
  target_control?: Maybe<Control>;
  /** An object relationship */
  target_control_group?: Maybe<ControlGroup>;
  /** An object relationship */
  target_document?: Maybe<Document>;
  /** An object relationship */
  target_impact?: Maybe<Impact>;
  /** An object relationship */
  target_impact_rating?: Maybe<ImpactRating>;
  /** An object relationship */
  target_indicator?: Maybe<Indicator>;
  /** An object relationship */
  target_internal_audit_entity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  target_internal_audit_report?: Maybe<InternalAuditReport>;
  /** An object relationship */
  target_issue?: Maybe<Issue>;
  /** An object relationship */
  target_issue_update?: Maybe<IssueUpdate>;
  /** An object relationship */
  target_obligation?: Maybe<Obligation>;
  /** An object relationship */
  target_obligation_impact?: Maybe<ObligationImpact>;
  /** An array relationship */
  target_permissions: Array<Permission>;
  /** An aggregate relationship */
  target_permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  target_risk?: Maybe<Risk>;
  /** An object relationship */
  target_test_result?: Maybe<TestResult>;
  /** An object relationship */
  target_third_party?: Maybe<ThirdParty>;
};


/** columns and relationships of "risksmart.linked_item" */
export type LinkedItemSourcePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.linked_item" */
export type LinkedItemSourcePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.linked_item" */
export type LinkedItemTargetPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.linked_item" */
export type LinkedItemTargetPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.linked_item" */
export type LinkedItemAggregate = {
  __typename?: 'linked_item_aggregate';
  aggregate?: Maybe<LinkedItemAggregateFields>;
  nodes: Array<LinkedItem>;
};

/** aggregate fields of "risksmart.linked_item" */
export type LinkedItemAggregateFields = {
  __typename?: 'linked_item_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<LinkedItemMaxFields>;
  min?: Maybe<LinkedItemMinFields>;
};


/** aggregate fields of "risksmart.linked_item" */
export type LinkedItemAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<LinkedItemSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.linked_item". All fields are combined with a logical 'AND'. */
export type LinkedItemBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RelationshipType?: InputMaybe<StringComparisonExp>;
  Source?: InputMaybe<UuidComparisonExp>;
  Target?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<LinkedItemBoolExp>>;
  _not?: InputMaybe<LinkedItemBoolExp>;
  _or?: InputMaybe<Array<LinkedItemBoolExp>>;
  source_acceptance?: InputMaybe<AcceptanceBoolExp>;
  source_action?: InputMaybe<ActionBoolExp>;
  source_appetite?: InputMaybe<AppetiteBoolExp>;
  source_assessment?: InputMaybe<AssessmentBoolExp>;
  source_assessment_activity?: InputMaybe<AssessmentActivityBoolExp>;
  source_cause?: InputMaybe<CauseBoolExp>;
  source_consequence?: InputMaybe<ConsequenceBoolExp>;
  source_control?: InputMaybe<ControlBoolExp>;
  source_control_group?: InputMaybe<ControlGroupBoolExp>;
  source_document?: InputMaybe<DocumentBoolExp>;
  source_impact?: InputMaybe<ImpactBoolExp>;
  source_indicator?: InputMaybe<IndicatorBoolExp>;
  source_internal_audit_entity?: InputMaybe<InternalAuditEntityBoolExp>;
  source_internal_audit_report?: InputMaybe<InternalAuditReportBoolExp>;
  source_issue?: InputMaybe<IssueBoolExp>;
  source_obligation?: InputMaybe<ObligationBoolExp>;
  source_permissions?: InputMaybe<PermissionBoolExp>;
  source_permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  source_risk?: InputMaybe<RiskBoolExp>;
  source_third_party?: InputMaybe<ThirdPartyBoolExp>;
  target_acceptance?: InputMaybe<AcceptanceBoolExp>;
  target_action?: InputMaybe<ActionBoolExp>;
  target_action_update?: InputMaybe<ActionUpdateBoolExp>;
  target_appetite?: InputMaybe<AppetiteBoolExp>;
  target_assessment?: InputMaybe<AssessmentBoolExp>;
  target_assessment_activity?: InputMaybe<AssessmentActivityBoolExp>;
  target_cause?: InputMaybe<CauseBoolExp>;
  target_consequence?: InputMaybe<ConsequenceBoolExp>;
  target_control?: InputMaybe<ControlBoolExp>;
  target_control_group?: InputMaybe<ControlGroupBoolExp>;
  target_document?: InputMaybe<DocumentBoolExp>;
  target_impact?: InputMaybe<ImpactBoolExp>;
  target_impact_rating?: InputMaybe<ImpactRatingBoolExp>;
  target_indicator?: InputMaybe<IndicatorBoolExp>;
  target_internal_audit_entity?: InputMaybe<InternalAuditEntityBoolExp>;
  target_internal_audit_report?: InputMaybe<InternalAuditReportBoolExp>;
  target_issue?: InputMaybe<IssueBoolExp>;
  target_issue_update?: InputMaybe<IssueUpdateBoolExp>;
  target_obligation?: InputMaybe<ObligationBoolExp>;
  target_obligation_impact?: InputMaybe<ObligationImpactBoolExp>;
  target_permissions?: InputMaybe<PermissionBoolExp>;
  target_permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  target_risk?: InputMaybe<RiskBoolExp>;
  target_test_result?: InputMaybe<TestResultBoolExp>;
  target_third_party?: InputMaybe<ThirdPartyBoolExp>;
};

/** unique or primary key constraints on table "risksmart.linked_item" */
export enum LinkedItemConstraint {
  /** unique or primary key constraint on columns "Id" */
  LinkedItemIdKey = 'linked_item_Id_key',
  /** unique or primary key constraint on columns "Source", "Target" */
  LinkedItemPkey = 'linked_item_pkey',
  /** unique or primary key constraint on columns  */
  LinkedItems = 'linked_items'
}

/** input type for inserting data into table "risksmart.linked_item" */
export type LinkedItemInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
  source_acceptance?: InputMaybe<AcceptanceObjRelInsertInput>;
  source_action?: InputMaybe<ActionObjRelInsertInput>;
  source_appetite?: InputMaybe<AppetiteObjRelInsertInput>;
  source_assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  source_assessment_activity?: InputMaybe<AssessmentActivityObjRelInsertInput>;
  source_cause?: InputMaybe<CauseObjRelInsertInput>;
  source_consequence?: InputMaybe<ConsequenceObjRelInsertInput>;
  source_control?: InputMaybe<ControlObjRelInsertInput>;
  source_control_group?: InputMaybe<ControlGroupObjRelInsertInput>;
  source_document?: InputMaybe<DocumentObjRelInsertInput>;
  source_impact?: InputMaybe<ImpactObjRelInsertInput>;
  source_indicator?: InputMaybe<IndicatorObjRelInsertInput>;
  source_internal_audit_entity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  source_internal_audit_report?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  source_issue?: InputMaybe<IssueObjRelInsertInput>;
  source_obligation?: InputMaybe<ObligationObjRelInsertInput>;
  source_permissions?: InputMaybe<PermissionArrRelInsertInput>;
  source_risk?: InputMaybe<RiskObjRelInsertInput>;
  source_third_party?: InputMaybe<ThirdPartyObjRelInsertInput>;
  target_acceptance?: InputMaybe<AcceptanceObjRelInsertInput>;
  target_action?: InputMaybe<ActionObjRelInsertInput>;
  target_action_update?: InputMaybe<ActionUpdateObjRelInsertInput>;
  target_appetite?: InputMaybe<AppetiteObjRelInsertInput>;
  target_assessment?: InputMaybe<AssessmentObjRelInsertInput>;
  target_assessment_activity?: InputMaybe<AssessmentActivityObjRelInsertInput>;
  target_cause?: InputMaybe<CauseObjRelInsertInput>;
  target_consequence?: InputMaybe<ConsequenceObjRelInsertInput>;
  target_control?: InputMaybe<ControlObjRelInsertInput>;
  target_control_group?: InputMaybe<ControlGroupObjRelInsertInput>;
  target_document?: InputMaybe<DocumentObjRelInsertInput>;
  target_impact?: InputMaybe<ImpactObjRelInsertInput>;
  target_impact_rating?: InputMaybe<ImpactRatingObjRelInsertInput>;
  target_indicator?: InputMaybe<IndicatorObjRelInsertInput>;
  target_internal_audit_entity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  target_internal_audit_report?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  target_issue?: InputMaybe<IssueObjRelInsertInput>;
  target_issue_update?: InputMaybe<IssueUpdateObjRelInsertInput>;
  target_obligation?: InputMaybe<ObligationObjRelInsertInput>;
  target_obligation_impact?: InputMaybe<ObligationImpactObjRelInsertInput>;
  target_permissions?: InputMaybe<PermissionArrRelInsertInput>;
  target_risk?: InputMaybe<RiskObjRelInsertInput>;
  target_test_result?: InputMaybe<TestResultObjRelInsertInput>;
  target_third_party?: InputMaybe<ThirdPartyObjRelInsertInput>;
};

/** aggregate max on columns */
export type LinkedItemMaxFields = {
  __typename?: 'linked_item_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['uuid']['output']>;
  Target?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type LinkedItemMinFields = {
  __typename?: 'linked_item_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['uuid']['output']>;
  Target?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.linked_item" */
export type LinkedItemMutationResponse = {
  __typename?: 'linked_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<LinkedItem>;
};

/** on_conflict condition type for table "risksmart.linked_item" */
export type LinkedItemOnConflict = {
  constraint: LinkedItemConstraint;
  update_columns?: Array<LinkedItemUpdateColumn>;
  where?: InputMaybe<LinkedItemBoolExp>;
};

/** Ordering options when selecting data from "risksmart.linked_item". */
export type LinkedItemOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RelationshipType?: InputMaybe<OrderBy>;
  Source?: InputMaybe<OrderBy>;
  Target?: InputMaybe<OrderBy>;
  source_acceptance?: InputMaybe<AcceptanceOrderBy>;
  source_action?: InputMaybe<ActionOrderBy>;
  source_appetite?: InputMaybe<AppetiteOrderBy>;
  source_assessment?: InputMaybe<AssessmentOrderBy>;
  source_assessment_activity?: InputMaybe<AssessmentActivityOrderBy>;
  source_cause?: InputMaybe<CauseOrderBy>;
  source_consequence?: InputMaybe<ConsequenceOrderBy>;
  source_control?: InputMaybe<ControlOrderBy>;
  source_control_group?: InputMaybe<ControlGroupOrderBy>;
  source_document?: InputMaybe<DocumentOrderBy>;
  source_impact?: InputMaybe<ImpactOrderBy>;
  source_indicator?: InputMaybe<IndicatorOrderBy>;
  source_internal_audit_entity?: InputMaybe<InternalAuditEntityOrderBy>;
  source_internal_audit_report?: InputMaybe<InternalAuditReportOrderBy>;
  source_issue?: InputMaybe<IssueOrderBy>;
  source_obligation?: InputMaybe<ObligationOrderBy>;
  source_permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  source_risk?: InputMaybe<RiskOrderBy>;
  source_third_party?: InputMaybe<ThirdPartyOrderBy>;
  target_acceptance?: InputMaybe<AcceptanceOrderBy>;
  target_action?: InputMaybe<ActionOrderBy>;
  target_action_update?: InputMaybe<ActionUpdateOrderBy>;
  target_appetite?: InputMaybe<AppetiteOrderBy>;
  target_assessment?: InputMaybe<AssessmentOrderBy>;
  target_assessment_activity?: InputMaybe<AssessmentActivityOrderBy>;
  target_cause?: InputMaybe<CauseOrderBy>;
  target_consequence?: InputMaybe<ConsequenceOrderBy>;
  target_control?: InputMaybe<ControlOrderBy>;
  target_control_group?: InputMaybe<ControlGroupOrderBy>;
  target_document?: InputMaybe<DocumentOrderBy>;
  target_impact?: InputMaybe<ImpactOrderBy>;
  target_impact_rating?: InputMaybe<ImpactRatingOrderBy>;
  target_indicator?: InputMaybe<IndicatorOrderBy>;
  target_internal_audit_entity?: InputMaybe<InternalAuditEntityOrderBy>;
  target_internal_audit_report?: InputMaybe<InternalAuditReportOrderBy>;
  target_issue?: InputMaybe<IssueOrderBy>;
  target_issue_update?: InputMaybe<IssueUpdateOrderBy>;
  target_obligation?: InputMaybe<ObligationOrderBy>;
  target_obligation_impact?: InputMaybe<ObligationImpactOrderBy>;
  target_permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  target_risk?: InputMaybe<RiskOrderBy>;
  target_test_result?: InputMaybe<TestResultOrderBy>;
  target_third_party?: InputMaybe<ThirdPartyOrderBy>;
};

/** primary key columns input for table: risksmart.linked_item */
export type LinkedItemPkColumnsInput = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.linked_item" */
export enum LinkedItemSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RelationshipType = 'RelationshipType',
  /** column name */
  Source = 'Source',
  /** column name */
  Target = 'Target'
}

/** input type for updating data in table "risksmart.linked_item" */
export type LinkedItemSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "linked_item" */
export type LinkedItemStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: LinkedItemStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type LinkedItemStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.linked_item" */
export enum LinkedItemUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RelationshipType = 'RelationshipType',
  /** column name */
  Source = 'Source',
  /** column name */
  Target = 'Target'
}

export type LinkedItemUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<LinkedItemSetInput>;
  /** filter the rows which have to be updated */
  where: LinkedItemBoolExp;
};

/** mutation root */
export type MutationRoot = {
  __typename?: 'mutation_root';
  attestRecord: GenericMutationOutput;
  dataImportStartImport?: Maybe<DataImportStartImportOutput>;
  dataImportValidate?: Maybe<DataImportValidateOutput>;
  deleteAcceptancesById: GenericMutationOutput;
  deleteActionsById: GenericMutationOutput;
  deleteControlsById: GenericMutationOutput;
  deleteIssuesById: GenericMutationOutput;
  deleteRiskById: GenericMutationOutput;
  /** delete data from the table: "risksmart.acceptance" */
  delete_acceptance?: Maybe<AcceptanceMutationResponse>;
  /** delete data from the table: "risksmart.acceptance_audit" */
  delete_acceptance_audit?: Maybe<AcceptanceAuditMutationResponse>;
  /** delete single row from the table: "risksmart.acceptance_audit" */
  delete_acceptance_audit_by_pk?: Maybe<AcceptanceAudit>;
  /** delete single row from the table: "risksmart.acceptance" */
  delete_acceptance_by_pk?: Maybe<Acceptance>;
  /** delete data from the table: "risksmart.acceptance_parent" */
  delete_acceptance_parent?: Maybe<AcceptanceParentMutationResponse>;
  /** delete data from the table: "risksmart.acceptance_parent_audit" */
  delete_acceptance_parent_audit?: Maybe<AcceptanceParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.acceptance_parent_audit" */
  delete_acceptance_parent_audit_by_pk?: Maybe<AcceptanceParentAudit>;
  /** delete single row from the table: "risksmart.acceptance_parent" */
  delete_acceptance_parent_by_pk?: Maybe<AcceptanceParent>;
  /** delete data from the table: "risksmart.acceptance_status" */
  delete_acceptance_status?: Maybe<AcceptanceStatusMutationResponse>;
  /** delete single row from the table: "risksmart.acceptance_status" */
  delete_acceptance_status_by_pk?: Maybe<AcceptanceStatus>;
  /** delete data from the table: "risksmart.access_type" */
  delete_access_type?: Maybe<AccessTypeMutationResponse>;
  /** delete single row from the table: "risksmart.access_type" */
  delete_access_type_by_pk?: Maybe<AccessType>;
  /** delete data from the table: "risksmart.action" */
  delete_action?: Maybe<ActionMutationResponse>;
  /** delete data from the table: "risksmart.action_audit" */
  delete_action_audit?: Maybe<ActionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.action_audit" */
  delete_action_audit_by_pk?: Maybe<ActionAudit>;
  /** delete single row from the table: "risksmart.action" */
  delete_action_by_pk?: Maybe<Action>;
  /** delete data from the table: "risksmart.action_parent" */
  delete_action_parent?: Maybe<ActionParentMutationResponse>;
  /** delete data from the table: "risksmart.action_parent_audit" */
  delete_action_parent_audit?: Maybe<ActionParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.action_parent_audit" */
  delete_action_parent_audit_by_pk?: Maybe<ActionParentAudit>;
  /** delete single row from the table: "risksmart.action_parent" */
  delete_action_parent_by_pk?: Maybe<ActionParent>;
  /** delete data from the table: "risksmart.action_status" */
  delete_action_status?: Maybe<ActionStatusMutationResponse>;
  /** delete single row from the table: "risksmart.action_status" */
  delete_action_status_by_pk?: Maybe<ActionStatus>;
  /** delete data from the table: "risksmart.action_update" */
  delete_action_update?: Maybe<ActionUpdateMutationResponse>;
  /** delete data from the table: "risksmart.action_update_audit" */
  delete_action_update_audit?: Maybe<ActionUpdateAuditMutationResponse>;
  /** delete single row from the table: "risksmart.action_update_audit" */
  delete_action_update_audit_by_pk?: Maybe<ActionUpdateAudit>;
  /** delete single row from the table: "risksmart.action_update" */
  delete_action_update_by_pk?: Maybe<ActionUpdate>;
  /** delete data from the table: "risksmart.aggregation_org" */
  delete_aggregation_org?: Maybe<AggregationOrgMutationResponse>;
  /** delete single row from the table: "risksmart.aggregation_org" */
  delete_aggregation_org_by_pk?: Maybe<AggregationOrg>;
  /** delete data from the table: "risksmart.appetite" */
  delete_appetite?: Maybe<AppetiteMutationResponse>;
  /** delete data from the table: "risksmart.appetite_audit" */
  delete_appetite_audit?: Maybe<AppetiteAuditMutationResponse>;
  /** delete single row from the table: "risksmart.appetite_audit" */
  delete_appetite_audit_by_pk?: Maybe<AppetiteAudit>;
  /** delete single row from the table: "risksmart.appetite" */
  delete_appetite_by_pk?: Maybe<Appetite>;
  /** delete data from the table: "risksmart.appetite_model" */
  delete_appetite_model?: Maybe<AppetiteModelMutationResponse>;
  /** delete single row from the table: "risksmart.appetite_model" */
  delete_appetite_model_by_pk?: Maybe<AppetiteModel>;
  /** delete data from the table: "risksmart.appetite_parent" */
  delete_appetite_parent?: Maybe<AppetiteParentMutationResponse>;
  /** delete data from the table: "risksmart.appetite_parent_audit" */
  delete_appetite_parent_audit?: Maybe<AppetiteParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.appetite_parent_audit" */
  delete_appetite_parent_audit_by_pk?: Maybe<AppetiteParentAudit>;
  /** delete single row from the table: "risksmart.appetite_parent" */
  delete_appetite_parent_by_pk?: Maybe<AppetiteParent>;
  /** delete data from the table: "risksmart.appetite_type" */
  delete_appetite_type?: Maybe<AppetiteTypeMutationResponse>;
  /** delete single row from the table: "risksmart.appetite_type" */
  delete_appetite_type_by_pk?: Maybe<AppetiteType>;
  /** delete data from the table: "risksmart.approval" */
  delete_approval?: Maybe<ApprovalMutationResponse>;
  /** delete data from the table: "risksmart.approval_audit" */
  delete_approval_audit?: Maybe<ApprovalAuditMutationResponse>;
  /** delete single row from the table: "risksmart.approval_audit" */
  delete_approval_audit_by_pk?: Maybe<ApprovalAudit>;
  /** delete single row from the table: "risksmart.approval" */
  delete_approval_by_pk?: Maybe<Approval>;
  /** delete data from the table: "risksmart.approval_in_flight_edit_rule" */
  delete_approval_in_flight_edit_rule?: Maybe<ApprovalInFlightEditRuleMutationResponse>;
  /** delete single row from the table: "risksmart.approval_in_flight_edit_rule" */
  delete_approval_in_flight_edit_rule_by_pk?: Maybe<ApprovalInFlightEditRule>;
  /** delete data from the table: "risksmart.approval_level" */
  delete_approval_level?: Maybe<ApprovalLevelMutationResponse>;
  /** delete data from the table: "risksmart.approval_level_audit" */
  delete_approval_level_audit?: Maybe<ApprovalLevelAuditMutationResponse>;
  /** delete single row from the table: "risksmart.approval_level_audit" */
  delete_approval_level_audit_by_pk?: Maybe<ApprovalLevelAudit>;
  /** delete single row from the table: "risksmart.approval_level" */
  delete_approval_level_by_pk?: Maybe<ApprovalLevel>;
  /** delete data from the table: "risksmart.approval_rule_type" */
  delete_approval_rule_type?: Maybe<ApprovalRuleTypeMutationResponse>;
  /** delete single row from the table: "risksmart.approval_rule_type" */
  delete_approval_rule_type_by_pk?: Maybe<ApprovalRuleType>;
  /** delete data from the table: "risksmart.approval_status" */
  delete_approval_status?: Maybe<ApprovalStatusMutationResponse>;
  /** delete single row from the table: "risksmart.approval_status" */
  delete_approval_status_by_pk?: Maybe<ApprovalStatus>;
  /** delete data from the table: "risksmart.approver" */
  delete_approver?: Maybe<ApproverMutationResponse>;
  /** delete data from the table: "risksmart.approver_audit" */
  delete_approver_audit?: Maybe<ApproverAuditMutationResponse>;
  /** delete single row from the table: "risksmart.approver_audit" */
  delete_approver_audit_by_pk?: Maybe<ApproverAudit>;
  /** delete single row from the table: "risksmart.approver" */
  delete_approver_by_pk?: Maybe<Approver>;
  /** delete data from the table: "risksmart.approver_response" */
  delete_approver_response?: Maybe<ApproverResponseMutationResponse>;
  /** delete single row from the table: "risksmart.approver_response" */
  delete_approver_response_by_pk?: Maybe<ApproverResponse>;
  /** delete data from the table: "risksmart.assessment" */
  delete_assessment?: Maybe<AssessmentMutationResponse>;
  /** delete data from the table: "risksmart.assessment_activity" */
  delete_assessment_activity?: Maybe<AssessmentActivityMutationResponse>;
  /** delete data from the table: "risksmart.assessment_activity_audit" */
  delete_assessment_activity_audit?: Maybe<AssessmentActivityAuditMutationResponse>;
  /** delete single row from the table: "risksmart.assessment_activity_audit" */
  delete_assessment_activity_audit_by_pk?: Maybe<AssessmentActivityAudit>;
  /** delete single row from the table: "risksmart.assessment_activity" */
  delete_assessment_activity_by_pk?: Maybe<AssessmentActivity>;
  /** delete data from the table: "risksmart.assessment_activity_status" */
  delete_assessment_activity_status?: Maybe<AssessmentActivityStatusMutationResponse>;
  /** delete single row from the table: "risksmart.assessment_activity_status" */
  delete_assessment_activity_status_by_pk?: Maybe<AssessmentActivityStatus>;
  /** delete data from the table: "risksmart.assessment_activity_type" */
  delete_assessment_activity_type?: Maybe<AssessmentActivityTypeMutationResponse>;
  /** delete single row from the table: "risksmart.assessment_activity_type" */
  delete_assessment_activity_type_by_pk?: Maybe<AssessmentActivityType>;
  /** delete data from the table: "risksmart.assessment_audit" */
  delete_assessment_audit?: Maybe<AssessmentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.assessment_audit" */
  delete_assessment_audit_by_pk?: Maybe<AssessmentAudit>;
  /** delete single row from the table: "risksmart.assessment" */
  delete_assessment_by_pk?: Maybe<Assessment>;
  /** delete data from the table: "risksmart.assessment_result_parent" */
  delete_assessment_result_parent?: Maybe<AssessmentResultParentMutationResponse>;
  /** delete data from the table: "risksmart.assessment_result_parent_audit" */
  delete_assessment_result_parent_audit?: Maybe<AssessmentResultParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.assessment_result_parent_audit" */
  delete_assessment_result_parent_audit_by_pk?: Maybe<AssessmentResultParentAudit>;
  /** delete single row from the table: "risksmart.assessment_result_parent" */
  delete_assessment_result_parent_by_pk?: Maybe<AssessmentResultParent>;
  /** delete data from the table: "risksmart.assessment_status" */
  delete_assessment_status?: Maybe<AssessmentStatusMutationResponse>;
  /** delete single row from the table: "risksmart.assessment_status" */
  delete_assessment_status_by_pk?: Maybe<AssessmentStatus>;
  /** delete data from the table: "risksmart.attestation_config" */
  delete_attestation_config?: Maybe<AttestationConfigMutationResponse>;
  /** delete single row from the table: "risksmart.attestation_config" */
  delete_attestation_config_by_pk?: Maybe<AttestationConfig>;
  /** delete data from the table: "risksmart.attestation_group" */
  delete_attestation_group?: Maybe<AttestationGroupMutationResponse>;
  /** delete single row from the table: "risksmart.attestation_group" */
  delete_attestation_group_by_pk?: Maybe<AttestationGroup>;
  /** delete data from the table: "risksmart.attestation_record" */
  delete_attestation_record?: Maybe<AttestationRecordMutationResponse>;
  /** delete single row from the table: "risksmart.attestation_record" */
  delete_attestation_record_by_pk?: Maybe<AttestationRecord>;
  /** delete data from the table: "risksmart.attestation_record_status" */
  delete_attestation_record_status?: Maybe<AttestationRecordStatusMutationResponse>;
  /** delete single row from the table: "risksmart.attestation_record_status" */
  delete_attestation_record_status_by_pk?: Maybe<AttestationRecordStatus>;
  /** delete data from the table: "auth.organisation" */
  delete_auth_organisation?: Maybe<AuthOrganisationMutationResponse>;
  /** delete data from the table: "auth.organisation_audit" */
  delete_auth_organisation_audit?: Maybe<AuthOrganisationAuditMutationResponse>;
  /** delete single row from the table: "auth.organisation_audit" */
  delete_auth_organisation_audit_by_pk?: Maybe<AuthOrganisationAudit>;
  /** delete single row from the table: "auth.organisation" */
  delete_auth_organisation_by_pk?: Maybe<AuthOrganisation>;
  /** delete data from the table: "auth.organisationuser" */
  delete_auth_organisationuser?: Maybe<AuthOrganisationuserMutationResponse>;
  /** delete data from the table: "auth.organisationuser_audit" */
  delete_auth_organisationuser_audit?: Maybe<AuthOrganisationuserAuditMutationResponse>;
  /** delete single row from the table: "auth.organisationuser_audit" */
  delete_auth_organisationuser_audit_by_pk?: Maybe<AuthOrganisationuserAudit>;
  /** delete single row from the table: "auth.organisationuser" */
  delete_auth_organisationuser_by_pk?: Maybe<AuthOrganisationuser>;
  /** delete data from the table: "auth.user" */
  delete_auth_user?: Maybe<AuthUserMutationResponse>;
  /** delete data from the table: "auth.user_activity_audit" */
  delete_auth_user_activity_audit?: Maybe<AuthUserActivityAuditMutationResponse>;
  /** delete single row from the table: "auth.user_activity_audit" */
  delete_auth_user_activity_audit_by_pk?: Maybe<AuthUserActivityAudit>;
  /** delete data from the table: "auth.user_audit" */
  delete_auth_user_audit?: Maybe<AuthUserAuditMutationResponse>;
  /** delete single row from the table: "auth.user_audit" */
  delete_auth_user_audit_by_pk?: Maybe<AuthUserAudit>;
  /** delete single row from the table: "auth.user" */
  delete_auth_user_by_pk?: Maybe<AuthUser>;
  /** delete data from the table: "risksmart.business_area" */
  delete_business_area?: Maybe<BusinessAreaMutationResponse>;
  /** delete single row from the table: "risksmart.business_area" */
  delete_business_area_by_pk?: Maybe<BusinessArea>;
  /** delete data from the table: "risksmart.cause" */
  delete_cause?: Maybe<CauseMutationResponse>;
  /** delete data from the table: "risksmart.cause_audit" */
  delete_cause_audit?: Maybe<CauseAuditMutationResponse>;
  /** delete single row from the table: "risksmart.cause_audit" */
  delete_cause_audit_by_pk?: Maybe<CauseAudit>;
  /** delete single row from the table: "risksmart.cause" */
  delete_cause_by_pk?: Maybe<Cause>;
  /** delete data from the table: "risksmart.change_request" */
  delete_change_request?: Maybe<ChangeRequestMutationResponse>;
  /** delete data from the table: "risksmart.change_request_audit" */
  delete_change_request_audit?: Maybe<ChangeRequestAuditMutationResponse>;
  /** delete single row from the table: "risksmart.change_request_audit" */
  delete_change_request_audit_by_pk?: Maybe<ChangeRequestAudit>;
  /** delete single row from the table: "risksmart.change_request" */
  delete_change_request_by_pk?: Maybe<ChangeRequest>;
  /** delete data from the table: "risksmart.change_request_contributor" */
  delete_change_request_contributor?: Maybe<ChangeRequestContributorMutationResponse>;
  /** delete single row from the table: "risksmart.change_request_contributor" */
  delete_change_request_contributor_by_pk?: Maybe<ChangeRequestContributor>;
  /** delete data from the table: "risksmart.comment" */
  delete_comment?: Maybe<CommentMutationResponse>;
  /** delete data from the table: "risksmart.comment_audit" */
  delete_comment_audit?: Maybe<CommentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.comment_audit" */
  delete_comment_audit_by_pk?: Maybe<CommentAudit>;
  /** delete single row from the table: "risksmart.comment" */
  delete_comment_by_pk?: Maybe<Comment>;
  /** delete data from the table: "risksmart.compliance_monitoring_assessment" */
  delete_compliance_monitoring_assessment?: Maybe<ComplianceMonitoringAssessmentMutationResponse>;
  /** delete single row from the table: "risksmart.compliance_monitoring_assessment" */
  delete_compliance_monitoring_assessment_by_pk?: Maybe<ComplianceMonitoringAssessment>;
  /** delete data from the table: "config.env" */
  delete_config_env?: Maybe<ConfigEnvMutationResponse>;
  /** delete single row from the table: "config.env" */
  delete_config_env_by_pk?: Maybe<ConfigEnv>;
  /** delete data from the table: "risksmart.consequence" */
  delete_consequence?: Maybe<ConsequenceMutationResponse>;
  /** delete data from the table: "risksmart.consequence_audit" */
  delete_consequence_audit?: Maybe<ConsequenceAuditMutationResponse>;
  /** delete single row from the table: "risksmart.consequence_audit" */
  delete_consequence_audit_by_pk?: Maybe<ConsequenceAudit>;
  /** delete single row from the table: "risksmart.consequence" */
  delete_consequence_by_pk?: Maybe<Consequence>;
  /** delete data from the table: "risksmart.consequence_type" */
  delete_consequence_type?: Maybe<ConsequenceTypeMutationResponse>;
  /** delete single row from the table: "risksmart.consequence_type" */
  delete_consequence_type_by_pk?: Maybe<ConsequenceType>;
  /** delete data from the table: "risksmart.contributor" */
  delete_contributor?: Maybe<ContributorMutationResponse>;
  /** delete data from the table: "risksmart.contributor_audit" */
  delete_contributor_audit?: Maybe<ContributorAuditMutationResponse>;
  /** delete single row from the table: "risksmart.contributor_audit" */
  delete_contributor_audit_by_pk?: Maybe<ContributorAudit>;
  /** delete single row from the table: "risksmart.contributor" */
  delete_contributor_by_pk?: Maybe<Contributor>;
  /** delete data from the table: "risksmart.contributor_group" */
  delete_contributor_group?: Maybe<ContributorGroupMutationResponse>;
  /** delete data from the table: "risksmart.contributor_group_audit" */
  delete_contributor_group_audit?: Maybe<ContributorGroupAuditMutationResponse>;
  /** delete single row from the table: "risksmart.contributor_group_audit" */
  delete_contributor_group_audit_by_pk?: Maybe<ContributorGroupAudit>;
  /** delete single row from the table: "risksmart.contributor_group" */
  delete_contributor_group_by_pk?: Maybe<ContributorGroup>;
  /** delete data from the table: "risksmart.contributor_type" */
  delete_contributor_type?: Maybe<ContributorTypeMutationResponse>;
  /** delete single row from the table: "risksmart.contributor_type" */
  delete_contributor_type_by_pk?: Maybe<ContributorType>;
  /** delete data from the table: "risksmart.control" */
  delete_control?: Maybe<ControlMutationResponse>;
  /** delete data from the table: "risksmart.control_audit" */
  delete_control_audit?: Maybe<ControlAuditMutationResponse>;
  /** delete single row from the table: "risksmart.control_audit" */
  delete_control_audit_by_pk?: Maybe<ControlAudit>;
  /** delete single row from the table: "risksmart.control" */
  delete_control_by_pk?: Maybe<Control>;
  /** delete data from the table: "risksmart.control_group" */
  delete_control_group?: Maybe<ControlGroupMutationResponse>;
  /** delete data from the table: "risksmart.control_group_audit" */
  delete_control_group_audit?: Maybe<ControlGroupAuditMutationResponse>;
  /** delete single row from the table: "risksmart.control_group_audit" */
  delete_control_group_audit_by_pk?: Maybe<ControlGroupAudit>;
  /** delete single row from the table: "risksmart.control_group" */
  delete_control_group_by_pk?: Maybe<ControlGroup>;
  /** delete data from the table: "risksmart.control_parent" */
  delete_control_parent?: Maybe<ControlParentMutationResponse>;
  /** delete data from the table: "risksmart.control_parent_audit" */
  delete_control_parent_audit?: Maybe<ControlParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.control_parent_audit" */
  delete_control_parent_audit_by_pk?: Maybe<ControlParentAudit>;
  /** delete single row from the table: "risksmart.control_parent" */
  delete_control_parent_by_pk?: Maybe<ControlParent>;
  /** delete data from the table: "risksmart.control_test_frequency" */
  delete_control_test_frequency?: Maybe<ControlTestFrequencyMutationResponse>;
  /** delete single row from the table: "risksmart.control_test_frequency" */
  delete_control_test_frequency_by_pk?: Maybe<ControlTestFrequency>;
  /** delete data from the table: "risksmart.control_type" */
  delete_control_type?: Maybe<ControlTypeMutationResponse>;
  /** delete single row from the table: "risksmart.control_type" */
  delete_control_type_by_pk?: Maybe<ControlType>;
  /** delete data from the table: "risksmart.conversation" */
  delete_conversation?: Maybe<ConversationMutationResponse>;
  /** delete data from the table: "risksmart.conversation_audit" */
  delete_conversation_audit?: Maybe<ConversationAuditMutationResponse>;
  /** delete single row from the table: "risksmart.conversation_audit" */
  delete_conversation_audit_by_pk?: Maybe<ConversationAudit>;
  /** delete single row from the table: "risksmart.conversation" */
  delete_conversation_by_pk?: Maybe<Conversation>;
  /** delete data from the table: "risksmart.cost_type" */
  delete_cost_type?: Maybe<CostTypeMutationResponse>;
  /** delete single row from the table: "risksmart.cost_type" */
  delete_cost_type_by_pk?: Maybe<CostType>;
  /** delete data from the table: "risksmart.custom_attribute_schema" */
  delete_custom_attribute_schema?: Maybe<CustomAttributeSchemaMutationResponse>;
  /** delete data from the table: "risksmart.custom_attribute_schema_audit" */
  delete_custom_attribute_schema_audit?: Maybe<CustomAttributeSchemaAuditMutationResponse>;
  /** delete single row from the table: "risksmart.custom_attribute_schema_audit" */
  delete_custom_attribute_schema_audit_by_pk?: Maybe<CustomAttributeSchemaAudit>;
  /** delete single row from the table: "risksmart.custom_attribute_schema" */
  delete_custom_attribute_schema_by_pk?: Maybe<CustomAttributeSchema>;
  /** delete data from the table: "risksmart.custom_ribbon" */
  delete_custom_ribbon?: Maybe<CustomRibbonMutationResponse>;
  /** delete data from the table: "risksmart.custom_ribbon_audit" */
  delete_custom_ribbon_audit?: Maybe<CustomRibbonAuditMutationResponse>;
  /** delete single row from the table: "risksmart.custom_ribbon_audit" */
  delete_custom_ribbon_audit_by_pk?: Maybe<CustomRibbonAudit>;
  /** delete single row from the table: "risksmart.custom_ribbon" */
  delete_custom_ribbon_by_pk?: Maybe<CustomRibbon>;
  /** delete data from the table: "risksmart.dashboard" */
  delete_dashboard?: Maybe<DashboardMutationResponse>;
  /** delete data from the table: "risksmart.dashboard_audit" */
  delete_dashboard_audit?: Maybe<DashboardAuditMutationResponse>;
  /** delete single row from the table: "risksmart.dashboard_audit" */
  delete_dashboard_audit_by_pk?: Maybe<DashboardAudit>;
  /** delete single row from the table: "risksmart.dashboard" */
  delete_dashboard_by_pk?: Maybe<Dashboard>;
  /** delete data from the table: "risksmart.dashboard_sharing_type" */
  delete_dashboard_sharing_type?: Maybe<DashboardSharingTypeMutationResponse>;
  /** delete single row from the table: "risksmart.dashboard_sharing_type" */
  delete_dashboard_sharing_type_by_pk?: Maybe<DashboardSharingType>;
  /** delete data from the table: "risksmart.data_import" */
  delete_data_import?: Maybe<DataImportMutationResponse>;
  /** delete data from the table: "risksmart.data_import_audit" */
  delete_data_import_audit?: Maybe<DataImportAuditMutationResponse>;
  /** delete single row from the table: "risksmart.data_import_audit" */
  delete_data_import_audit_by_pk?: Maybe<DataImportAudit>;
  /** delete single row from the table: "risksmart.data_import" */
  delete_data_import_by_pk?: Maybe<DataImport>;
  /** delete data from the table: "risksmart.data_import_error" */
  delete_data_import_error?: Maybe<DataImportErrorMutationResponse>;
  /** delete data from the table: "risksmart.data_import_error_audit" */
  delete_data_import_error_audit?: Maybe<DataImportErrorAuditMutationResponse>;
  /** delete single row from the table: "risksmart.data_import_error_audit" */
  delete_data_import_error_audit_by_pk?: Maybe<DataImportErrorAudit>;
  /** delete single row from the table: "risksmart.data_import_error" */
  delete_data_import_error_by_pk?: Maybe<DataImportError>;
  /** delete data from the table: "risksmart.data_import_status" */
  delete_data_import_status?: Maybe<DataImportStatusMutationResponse>;
  /** delete single row from the table: "risksmart.data_import_status" */
  delete_data_import_status_by_pk?: Maybe<DataImportStatus>;
  /** delete data from the table: "risksmart.department" */
  delete_department?: Maybe<DepartmentMutationResponse>;
  /** delete data from the table: "risksmart.department_audit" */
  delete_department_audit?: Maybe<DepartmentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.department_audit" */
  delete_department_audit_by_pk?: Maybe<DepartmentAudit>;
  /** delete single row from the table: "risksmart.department" */
  delete_department_by_pk?: Maybe<Department>;
  /** delete data from the table: "risksmart.department_type" */
  delete_department_type?: Maybe<DepartmentTypeMutationResponse>;
  /** delete data from the table: "risksmart.department_type_audit" */
  delete_department_type_audit?: Maybe<DepartmentTypeAuditMutationResponse>;
  /** delete single row from the table: "risksmart.department_type_audit" */
  delete_department_type_audit_by_pk?: Maybe<DepartmentTypeAudit>;
  /** delete single row from the table: "risksmart.department_type" */
  delete_department_type_by_pk?: Maybe<DepartmentType>;
  /** delete data from the table: "risksmart.department_type_group" */
  delete_department_type_group?: Maybe<DepartmentTypeGroupMutationResponse>;
  /** delete data from the table: "risksmart.department_type_group_audit" */
  delete_department_type_group_audit?: Maybe<DepartmentTypeGroupAuditMutationResponse>;
  /** delete single row from the table: "risksmart.department_type_group_audit" */
  delete_department_type_group_audit_by_pk?: Maybe<DepartmentTypeGroupAudit>;
  /** delete single row from the table: "risksmart.department_type_group" */
  delete_department_type_group_by_pk?: Maybe<DepartmentTypeGroup>;
  /** delete data from the table: "risksmart.document" */
  delete_document?: Maybe<DocumentMutationResponse>;
  /** delete data from the table: "risksmart.document_action_audit" */
  delete_document_action_audit?: Maybe<DocumentActionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.document_action_audit" */
  delete_document_action_audit_by_pk?: Maybe<DocumentActionAudit>;
  /** delete data from the table: "risksmart.document_assessment_result" */
  delete_document_assessment_result?: Maybe<DocumentAssessmentResultMutationResponse>;
  /** delete data from the table: "risksmart.document_assessment_result_audit" */
  delete_document_assessment_result_audit?: Maybe<DocumentAssessmentResultAuditMutationResponse>;
  /** delete single row from the table: "risksmart.document_assessment_result_audit" */
  delete_document_assessment_result_audit_by_pk?: Maybe<DocumentAssessmentResultAudit>;
  /** delete single row from the table: "risksmart.document_assessment_result" */
  delete_document_assessment_result_by_pk?: Maybe<DocumentAssessmentResult>;
  /** delete data from the table: "risksmart.document_assessment_status" */
  delete_document_assessment_status?: Maybe<DocumentAssessmentStatusMutationResponse>;
  /** delete single row from the table: "risksmart.document_assessment_status" */
  delete_document_assessment_status_by_pk?: Maybe<DocumentAssessmentStatus>;
  /** delete data from the table: "risksmart.document_audit" */
  delete_document_audit?: Maybe<DocumentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.document_audit" */
  delete_document_audit_by_pk?: Maybe<DocumentAudit>;
  /** delete single row from the table: "risksmart.document" */
  delete_document_by_pk?: Maybe<Document>;
  /** delete data from the table: "risksmart.document_file" */
  delete_document_file?: Maybe<DocumentFileMutationResponse>;
  /** delete data from the table: "risksmart.document_file_audit" */
  delete_document_file_audit?: Maybe<DocumentFileAuditMutationResponse>;
  /** delete single row from the table: "risksmart.document_file_audit" */
  delete_document_file_audit_by_pk?: Maybe<DocumentFileAudit>;
  /** delete single row from the table: "risksmart.document_file" */
  delete_document_file_by_pk?: Maybe<DocumentFile>;
  /** delete data from the table: "risksmart.document_file_type" */
  delete_document_file_type?: Maybe<DocumentFileTypeMutationResponse>;
  /** delete single row from the table: "risksmart.document_file_type" */
  delete_document_file_type_by_pk?: Maybe<DocumentFileType>;
  /** delete data from the table: "risksmart.document_issue_audit" */
  delete_document_issue_audit?: Maybe<DocumentIssueAuditMutationResponse>;
  /** delete single row from the table: "risksmart.document_issue_audit" */
  delete_document_issue_audit_by_pk?: Maybe<DocumentIssueAudit>;
  /** delete data from the table: "risksmart.document_linked_document" */
  delete_document_linked_document?: Maybe<DocumentLinkedDocumentMutationResponse>;
  /** delete data from the table: "risksmart.document_linked_document_audit" */
  delete_document_linked_document_audit?: Maybe<DocumentLinkedDocumentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.document_linked_document_audit" */
  delete_document_linked_document_audit_by_pk?: Maybe<DocumentLinkedDocumentAudit>;
  /** delete single row from the table: "risksmart.document_linked_document" */
  delete_document_linked_document_by_pk?: Maybe<DocumentLinkedDocument>;
  /** delete data from the table: "risksmart.file" */
  delete_file?: Maybe<FileMutationResponse>;
  /** delete data from the table: "risksmart.file_audit" */
  delete_file_audit?: Maybe<FileAuditMutationResponse>;
  /** delete single row from the table: "risksmart.file_audit" */
  delete_file_audit_by_pk?: Maybe<FileAudit>;
  /** delete single row from the table: "risksmart.file" */
  delete_file_by_pk?: Maybe<File>;
  /** delete data from the table: "risksmart.form_configuration" */
  delete_form_configuration?: Maybe<FormConfigurationMutationResponse>;
  /** delete data from the table: "risksmart.form_configuration_audit" */
  delete_form_configuration_audit?: Maybe<FormConfigurationAuditMutationResponse>;
  /** delete single row from the table: "risksmart.form_configuration_audit" */
  delete_form_configuration_audit_by_pk?: Maybe<FormConfigurationAudit>;
  /** delete single row from the table: "risksmart.form_configuration" */
  delete_form_configuration_by_pk?: Maybe<FormConfiguration>;
  /** delete data from the table: "risksmart.form_field_configuration" */
  delete_form_field_configuration?: Maybe<FormFieldConfigurationMutationResponse>;
  /** delete data from the table: "risksmart.form_field_configuration_audit" */
  delete_form_field_configuration_audit?: Maybe<FormFieldConfigurationAuditMutationResponse>;
  /** delete single row from the table: "risksmart.form_field_configuration_audit" */
  delete_form_field_configuration_audit_by_pk?: Maybe<FormFieldConfigurationAudit>;
  /** delete single row from the table: "risksmart.form_field_configuration" */
  delete_form_field_configuration_by_pk?: Maybe<FormFieldConfiguration>;
  /** delete data from the table: "risksmart.form_field_ordering" */
  delete_form_field_ordering?: Maybe<FormFieldOrderingMutationResponse>;
  /** delete data from the table: "risksmart.form_field_ordering_audit" */
  delete_form_field_ordering_audit?: Maybe<FormFieldOrderingAuditMutationResponse>;
  /** delete single row from the table: "risksmart.form_field_ordering_audit" */
  delete_form_field_ordering_audit_by_pk?: Maybe<FormFieldOrderingAudit>;
  /** delete single row from the table: "risksmart.form_field_ordering" */
  delete_form_field_ordering_by_pk?: Maybe<FormFieldOrdering>;
  /** delete data from the table: "risksmart.impact" */
  delete_impact?: Maybe<ImpactMutationResponse>;
  /** delete data from the table: "risksmart.impact_audit" */
  delete_impact_audit?: Maybe<ImpactAuditMutationResponse>;
  /** delete single row from the table: "risksmart.impact_audit" */
  delete_impact_audit_by_pk?: Maybe<ImpactAudit>;
  /** delete single row from the table: "risksmart.impact" */
  delete_impact_by_pk?: Maybe<Impact>;
  /** delete data from the table: "risksmart.impact_parent" */
  delete_impact_parent?: Maybe<ImpactParentMutationResponse>;
  /** delete data from the table: "risksmart.impact_parent_audit" */
  delete_impact_parent_audit?: Maybe<ImpactParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.impact_parent_audit" */
  delete_impact_parent_audit_by_pk?: Maybe<ImpactParentAudit>;
  /** delete single row from the table: "risksmart.impact_parent" */
  delete_impact_parent_by_pk?: Maybe<ImpactParent>;
  /** delete data from the table: "risksmart.impact_rating" */
  delete_impact_rating?: Maybe<ImpactRatingMutationResponse>;
  /** delete data from the table: "risksmart.impact_rating_audit" */
  delete_impact_rating_audit?: Maybe<ImpactRatingAuditMutationResponse>;
  /** delete single row from the table: "risksmart.impact_rating_audit" */
  delete_impact_rating_audit_by_pk?: Maybe<ImpactRatingAudit>;
  /** delete single row from the table: "risksmart.impact_rating" */
  delete_impact_rating_by_pk?: Maybe<ImpactRating>;
  /** delete data from the table: "risksmart.indicator" */
  delete_indicator?: Maybe<IndicatorMutationResponse>;
  /** delete data from the table: "risksmart.indicator_audit" */
  delete_indicator_audit?: Maybe<IndicatorAuditMutationResponse>;
  /** delete single row from the table: "risksmart.indicator_audit" */
  delete_indicator_audit_by_pk?: Maybe<IndicatorAudit>;
  /** delete single row from the table: "risksmart.indicator" */
  delete_indicator_by_pk?: Maybe<Indicator>;
  /** delete data from the table: "risksmart.indicator_parent" */
  delete_indicator_parent?: Maybe<IndicatorParentMutationResponse>;
  /** delete single row from the table: "risksmart.indicator_parent" */
  delete_indicator_parent_by_pk?: Maybe<IndicatorParent>;
  /** delete data from the table: "risksmart.indicator_result" */
  delete_indicator_result?: Maybe<IndicatorResultMutationResponse>;
  /** delete data from the table: "risksmart.indicator_result_audit" */
  delete_indicator_result_audit?: Maybe<IndicatorResultAuditMutationResponse>;
  /** delete single row from the table: "risksmart.indicator_result_audit" */
  delete_indicator_result_audit_by_pk?: Maybe<IndicatorResultAudit>;
  /** delete single row from the table: "risksmart.indicator_result" */
  delete_indicator_result_by_pk?: Maybe<IndicatorResult>;
  /** delete data from the table: "risksmart.indicator_type" */
  delete_indicator_type?: Maybe<IndicatorTypeMutationResponse>;
  /** delete single row from the table: "risksmart.indicator_type" */
  delete_indicator_type_by_pk?: Maybe<IndicatorType>;
  /** delete data from the table: "risksmart.internal_audit_entity" */
  delete_internal_audit_entity?: Maybe<InternalAuditEntityMutationResponse>;
  /** delete single row from the table: "risksmart.internal_audit_entity" */
  delete_internal_audit_entity_by_pk?: Maybe<InternalAuditEntity>;
  /** delete data from the table: "risksmart.internal_audit_report" */
  delete_internal_audit_report?: Maybe<InternalAuditReportMutationResponse>;
  /** delete single row from the table: "risksmart.internal_audit_report" */
  delete_internal_audit_report_by_pk?: Maybe<InternalAuditReport>;
  /** delete data from the table: "risksmart.issue" */
  delete_issue?: Maybe<IssueMutationResponse>;
  /** delete data from the table: "risksmart.issue_action_audit" */
  delete_issue_action_audit?: Maybe<IssueActionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.issue_action_audit" */
  delete_issue_action_audit_by_pk?: Maybe<IssueActionAudit>;
  /** delete data from the table: "risksmart.issue_assessment" */
  delete_issue_assessment?: Maybe<IssueAssessmentMutationResponse>;
  /** delete data from the table: "risksmart.issue_assessment_audit" */
  delete_issue_assessment_audit?: Maybe<IssueAssessmentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.issue_assessment_audit" */
  delete_issue_assessment_audit_by_pk?: Maybe<IssueAssessmentAudit>;
  /** delete single row from the table: "risksmart.issue_assessment" */
  delete_issue_assessment_by_pk?: Maybe<IssueAssessment>;
  /** delete data from the table: "risksmart.issue_assessment_status" */
  delete_issue_assessment_status?: Maybe<IssueAssessmentStatusMutationResponse>;
  /** delete single row from the table: "risksmart.issue_assessment_status" */
  delete_issue_assessment_status_by_pk?: Maybe<IssueAssessmentStatus>;
  /** delete data from the table: "risksmart.issue_audit" */
  delete_issue_audit?: Maybe<IssueAuditMutationResponse>;
  /** delete single row from the table: "risksmart.issue_audit" */
  delete_issue_audit_by_pk?: Maybe<IssueAudit>;
  /** delete single row from the table: "risksmart.issue" */
  delete_issue_by_pk?: Maybe<Issue>;
  /** delete data from the table: "risksmart.issue_parent" */
  delete_issue_parent?: Maybe<IssueParentMutationResponse>;
  /** delete data from the table: "risksmart.issue_parent_audit" */
  delete_issue_parent_audit?: Maybe<IssueParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.issue_parent_audit" */
  delete_issue_parent_audit_by_pk?: Maybe<IssueParentAudit>;
  /** delete single row from the table: "risksmart.issue_parent" */
  delete_issue_parent_by_pk?: Maybe<IssueParent>;
  /** delete data from the table: "risksmart.issue_update" */
  delete_issue_update?: Maybe<IssueUpdateMutationResponse>;
  /** delete data from the table: "risksmart.issue_update_audit" */
  delete_issue_update_audit?: Maybe<IssueUpdateAuditMutationResponse>;
  /** delete single row from the table: "risksmart.issue_update_audit" */
  delete_issue_update_audit_by_pk?: Maybe<IssueUpdateAudit>;
  /** delete single row from the table: "risksmart.issue_update" */
  delete_issue_update_by_pk?: Maybe<IssueUpdate>;
  /** delete data from the table: "risksmart.linked_item" */
  delete_linked_item?: Maybe<LinkedItemMutationResponse>;
  /** delete single row from the table: "risksmart.linked_item" */
  delete_linked_item_by_pk?: Maybe<LinkedItem>;
  /** delete data from the table: "risksmart.node" */
  delete_node?: Maybe<NodeMutationResponse>;
  /** delete data from the table: "risksmart.node_ancestor" */
  delete_node_ancestor?: Maybe<NodeAncestorMutationResponse>;
  /** delete single row from the table: "risksmart.node_ancestor" */
  delete_node_ancestor_by_pk?: Maybe<NodeAncestor>;
  /** delete single row from the table: "risksmart.node" */
  delete_node_by_pk?: Maybe<Node>;
  /** delete data from the table: "risksmart.obligation" */
  delete_obligation?: Maybe<ObligationMutationResponse>;
  /** delete data from the table: "risksmart.obligation_action_audit" */
  delete_obligation_action_audit?: Maybe<ObligationActionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_action_audit" */
  delete_obligation_action_audit_by_pk?: Maybe<ObligationActionAudit>;
  /** delete data from the table: "risksmart.obligation_assessment_result" */
  delete_obligation_assessment_result?: Maybe<ObligationAssessmentResultMutationResponse>;
  /** delete data from the table: "risksmart.obligation_assessment_result_audit" */
  delete_obligation_assessment_result_audit?: Maybe<ObligationAssessmentResultAuditMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_assessment_result_audit" */
  delete_obligation_assessment_result_audit_by_pk?: Maybe<ObligationAssessmentResultAudit>;
  /** delete single row from the table: "risksmart.obligation_assessment_result" */
  delete_obligation_assessment_result_by_pk?: Maybe<ObligationAssessmentResult>;
  /** delete data from the table: "risksmart.obligation_assessment_status" */
  delete_obligation_assessment_status?: Maybe<ObligationAssessmentStatusMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_assessment_status" */
  delete_obligation_assessment_status_by_pk?: Maybe<ObligationAssessmentStatus>;
  /** delete data from the table: "risksmart.obligation_audit" */
  delete_obligation_audit?: Maybe<ObligationAuditMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_audit" */
  delete_obligation_audit_by_pk?: Maybe<ObligationAudit>;
  /** delete single row from the table: "risksmart.obligation" */
  delete_obligation_by_pk?: Maybe<Obligation>;
  /** delete data from the table: "risksmart.obligation_impact" */
  delete_obligation_impact?: Maybe<ObligationImpactMutationResponse>;
  /** delete data from the table: "risksmart.obligation_impact_audit" */
  delete_obligation_impact_audit?: Maybe<ObligationImpactAuditMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_impact_audit" */
  delete_obligation_impact_audit_by_pk?: Maybe<ObligationImpactAudit>;
  /** delete single row from the table: "risksmart.obligation_impact" */
  delete_obligation_impact_by_pk?: Maybe<ObligationImpact>;
  /** delete data from the table: "risksmart.obligation_type" */
  delete_obligation_type?: Maybe<ObligationTypeMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_type" */
  delete_obligation_type_by_pk?: Maybe<ObligationType>;
  /** delete data from the table: "risksmart.owner" */
  delete_owner?: Maybe<OwnerMutationResponse>;
  /** delete data from the table: "risksmart.owner_audit" */
  delete_owner_audit?: Maybe<OwnerAuditMutationResponse>;
  /** delete single row from the table: "risksmart.owner_audit" */
  delete_owner_audit_by_pk?: Maybe<OwnerAudit>;
  /** delete single row from the table: "risksmart.owner" */
  delete_owner_by_pk?: Maybe<Owner>;
  /** delete data from the table: "risksmart.owner_group" */
  delete_owner_group?: Maybe<OwnerGroupMutationResponse>;
  /** delete data from the table: "risksmart.owner_group_audit" */
  delete_owner_group_audit?: Maybe<OwnerGroupAuditMutationResponse>;
  /** delete single row from the table: "risksmart.owner_group_audit" */
  delete_owner_group_audit_by_pk?: Maybe<OwnerGroupAudit>;
  /** delete single row from the table: "risksmart.owner_group" */
  delete_owner_group_by_pk?: Maybe<OwnerGroup>;
  /** delete data from the table: "risksmart.parent_type" */
  delete_parent_type?: Maybe<ParentTypeMutationResponse>;
  /** delete single row from the table: "risksmart.parent_type" */
  delete_parent_type_by_pk?: Maybe<ParentType>;
  /** delete data from the table: "risksmart.questionnaire_invite" */
  delete_questionnaire_invite?: Maybe<QuestionnaireInviteMutationResponse>;
  /** delete single row from the table: "risksmart.questionnaire_invite" */
  delete_questionnaire_invite_by_pk?: Maybe<QuestionnaireInvite>;
  /** delete data from the table: "risksmart.questionnaire_template" */
  delete_questionnaire_template?: Maybe<QuestionnaireTemplateMutationResponse>;
  /** delete single row from the table: "risksmart.questionnaire_template" */
  delete_questionnaire_template_by_pk?: Maybe<QuestionnaireTemplate>;
  /** delete data from the table: "risksmart.questionnaire_template_version" */
  delete_questionnaire_template_version?: Maybe<QuestionnaireTemplateVersionMutationResponse>;
  /** delete single row from the table: "risksmart.questionnaire_template_version" */
  delete_questionnaire_template_version_by_pk?: Maybe<QuestionnaireTemplateVersion>;
  /** delete data from the table: "risksmart.questionnaire_template_version_status" */
  delete_questionnaire_template_version_status?: Maybe<QuestionnaireTemplateVersionStatusMutationResponse>;
  /** delete single row from the table: "risksmart.questionnaire_template_version_status" */
  delete_questionnaire_template_version_status_by_pk?: Maybe<QuestionnaireTemplateVersionStatus>;
  /** delete data from the table: "risksmart.relation_file" */
  delete_relation_file?: Maybe<RelationFileMutationResponse>;
  /** delete data from the table: "risksmart.relation_file_audit" */
  delete_relation_file_audit?: Maybe<RelationFileAuditMutationResponse>;
  /** delete single row from the table: "risksmart.relation_file_audit" */
  delete_relation_file_audit_by_pk?: Maybe<RelationFileAudit>;
  /** delete single row from the table: "risksmart.relation_file" */
  delete_relation_file_by_pk?: Maybe<RelationFile>;
  /** delete data from the table: "risksmart.risk" */
  delete_risk?: Maybe<RiskMutationResponse>;
  /** delete data from the table: "risksmart.risk_assessment_result" */
  delete_risk_assessment_result?: Maybe<RiskAssessmentResultMutationResponse>;
  /** delete data from the table: "risksmart.risk_assessment_result_audit" */
  delete_risk_assessment_result_audit?: Maybe<RiskAssessmentResultAuditMutationResponse>;
  /** delete single row from the table: "risksmart.risk_assessment_result_audit" */
  delete_risk_assessment_result_audit_by_pk?: Maybe<RiskAssessmentResultAudit>;
  /** delete single row from the table: "risksmart.risk_assessment_result" */
  delete_risk_assessment_result_by_pk?: Maybe<RiskAssessmentResult>;
  /** delete data from the table: "risksmart.risk_assessment_result_control_type" */
  delete_risk_assessment_result_control_type?: Maybe<RiskAssessmentResultControlTypeMutationResponse>;
  /** delete single row from the table: "risksmart.risk_assessment_result_control_type" */
  delete_risk_assessment_result_control_type_by_pk?: Maybe<RiskAssessmentResultControlType>;
  /** delete data from the table: "risksmart.risk_audit" */
  delete_risk_audit?: Maybe<RiskAuditMutationResponse>;
  /** delete single row from the table: "risksmart.risk_audit" */
  delete_risk_audit_by_pk?: Maybe<RiskAudit>;
  /** delete single row from the table: "risksmart.risk" */
  delete_risk_by_pk?: Maybe<Risk>;
  /** delete data from the table: "risksmart.risk_score" */
  delete_risk_score?: Maybe<RiskScoreMutationResponse>;
  /** delete single row from the table: "risksmart.risk_score" */
  delete_risk_score_by_pk?: Maybe<RiskScore>;
  /** delete data from the table: "risksmart.risk_scoring_model" */
  delete_risk_scoring_model?: Maybe<RiskScoringModelMutationResponse>;
  /** delete single row from the table: "risksmart.risk_scoring_model" */
  delete_risk_scoring_model_by_pk?: Maybe<RiskScoringModel>;
  /** delete data from the table: "risksmart.risk_status_type" */
  delete_risk_status_type?: Maybe<RiskStatusTypeMutationResponse>;
  /** delete single row from the table: "risksmart.risk_status_type" */
  delete_risk_status_type_by_pk?: Maybe<RiskStatusType>;
  /** delete data from the table: "risksmart.risk_treatment_type" */
  delete_risk_treatment_type?: Maybe<RiskTreatmentTypeMutationResponse>;
  /** delete single row from the table: "risksmart.risk_treatment_type" */
  delete_risk_treatment_type_by_pk?: Maybe<RiskTreatmentType>;
  /** delete data from the table: "risksmart.approver_response_audit" */
  delete_risksmart_approver_response_audit?: Maybe<RisksmartApproverResponseAuditMutationResponse>;
  /** delete single row from the table: "risksmart.approver_response_audit" */
  delete_risksmart_approver_response_audit_by_pk?: Maybe<RisksmartApproverResponseAudit>;
  /** delete data from the table: "risksmart.change_request_contributor_audit" */
  delete_risksmart_change_request_contributor_audit?: Maybe<RisksmartChangeRequestContributorAuditMutationResponse>;
  /** delete single row from the table: "risksmart.change_request_contributor_audit" */
  delete_risksmart_change_request_contributor_audit_by_pk?: Maybe<RisksmartChangeRequestContributorAudit>;
  /** delete data from the table: "risksmart.control_action_audit" */
  delete_risksmart_control_action_audit?: Maybe<RisksmartControlActionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.control_action_audit" */
  delete_risksmart_control_action_audit_by_pk?: Maybe<RisksmartControlActionAudit>;
  /** delete data from the table: "risksmart.counter" */
  delete_risksmart_counter?: Maybe<RisksmartCounterMutationResponse>;
  /** delete single row from the table: "risksmart.counter" */
  delete_risksmart_counter_by_pk?: Maybe<RisksmartCounter>;
  /** delete data from the table: "risksmart.indicator_parent_audit" */
  delete_risksmart_indicator_parent_audit?: Maybe<RisksmartIndicatorParentAuditMutationResponse>;
  /** delete single row from the table: "risksmart.indicator_parent_audit" */
  delete_risksmart_indicator_parent_audit_by_pk?: Maybe<RisksmartIndicatorParentAudit>;
  /** delete data from the table: "risksmart.linked_item_audit" */
  delete_risksmart_linked_item_audit?: Maybe<RisksmartLinkedItemAuditMutationResponse>;
  /** delete single row from the table: "risksmart.linked_item_audit" */
  delete_risksmart_linked_item_audit_by_pk?: Maybe<RisksmartLinkedItemAudit>;
  /** delete data from the table: "risksmart.obligation_issue_audit" */
  delete_risksmart_obligation_issue_audit?: Maybe<RisksmartObligationIssueAuditMutationResponse>;
  /** delete single row from the table: "risksmart.obligation_issue_audit" */
  delete_risksmart_obligation_issue_audit_by_pk?: Maybe<RisksmartObligationIssueAudit>;
  /** delete data from the table: "risksmart.questionnaire_template_audit" */
  delete_risksmart_questionnaire_template_audit?: Maybe<RisksmartQuestionnaireTemplateAuditMutationResponse>;
  /** delete single row from the table: "risksmart.questionnaire_template_audit" */
  delete_risksmart_questionnaire_template_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateAudit>;
  /** delete data from the table: "risksmart.questionnaire_template_version_audit" */
  delete_risksmart_questionnaire_template_version_audit?: Maybe<RisksmartQuestionnaireTemplateVersionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.questionnaire_template_version_audit" */
  delete_risksmart_questionnaire_template_version_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateVersionAudit>;
  /** delete data from the table: "risksmart.risk_action_audit" */
  delete_risksmart_risk_action_audit?: Maybe<RisksmartRiskActionAuditMutationResponse>;
  /** delete single row from the table: "risksmart.risk_action_audit" */
  delete_risksmart_risk_action_audit_by_pk?: Maybe<RisksmartRiskActionAudit>;
  /** delete data from the table: "risksmart.role_access" */
  delete_role_access?: Maybe<RoleAccessMutationResponse>;
  /** delete single row from the table: "risksmart.role_access" */
  delete_role_access_by_pk?: Maybe<RoleAccess>;
  /** delete data from the table: "risksmart.tag" */
  delete_tag?: Maybe<TagMutationResponse>;
  /** delete data from the table: "risksmart.tag_audit" */
  delete_tag_audit?: Maybe<TagAuditMutationResponse>;
  /** delete single row from the table: "risksmart.tag_audit" */
  delete_tag_audit_by_pk?: Maybe<TagAudit>;
  /** delete single row from the table: "risksmart.tag" */
  delete_tag_by_pk?: Maybe<Tag>;
  /** delete data from the table: "risksmart.tag_type" */
  delete_tag_type?: Maybe<TagTypeMutationResponse>;
  /** delete data from the table: "risksmart.tag_type_audit" */
  delete_tag_type_audit?: Maybe<TagTypeAuditMutationResponse>;
  /** delete single row from the table: "risksmart.tag_type_audit" */
  delete_tag_type_audit_by_pk?: Maybe<TagTypeAudit>;
  /** delete single row from the table: "risksmart.tag_type" */
  delete_tag_type_by_pk?: Maybe<TagType>;
  /** delete data from the table: "risksmart.tag_type_group" */
  delete_tag_type_group?: Maybe<TagTypeGroupMutationResponse>;
  /** delete data from the table: "risksmart.tag_type_group_audit" */
  delete_tag_type_group_audit?: Maybe<TagTypeGroupAuditMutationResponse>;
  /** delete single row from the table: "risksmart.tag_type_group_audit" */
  delete_tag_type_group_audit_by_pk?: Maybe<TagTypeGroupAudit>;
  /** delete single row from the table: "risksmart.tag_type_group" */
  delete_tag_type_group_by_pk?: Maybe<TagTypeGroup>;
  /** delete data from the table: "risksmart.taxonomy" */
  delete_taxonomy?: Maybe<TaxonomyMutationResponse>;
  /** delete data from the table: "risksmart.taxonomy_audit" */
  delete_taxonomy_audit?: Maybe<TaxonomyAuditMutationResponse>;
  /** delete single row from the table: "risksmart.taxonomy_audit" */
  delete_taxonomy_audit_by_pk?: Maybe<TaxonomyAudit>;
  /** delete single row from the table: "risksmart.taxonomy" */
  delete_taxonomy_by_pk?: Maybe<Taxonomy>;
  /** delete data from the table: "risksmart.taxonomy_org" */
  delete_taxonomy_org?: Maybe<TaxonomyOrgMutationResponse>;
  /** delete data from the table: "risksmart.taxonomy_org_audit" */
  delete_taxonomy_org_audit?: Maybe<TaxonomyOrgAuditMutationResponse>;
  /** delete single row from the table: "risksmart.taxonomy_org_audit" */
  delete_taxonomy_org_audit_by_pk?: Maybe<TaxonomyOrgAudit>;
  /** delete single row from the table: "risksmart.taxonomy_org" */
  delete_taxonomy_org_by_pk?: Maybe<TaxonomyOrg>;
  /** delete data from the table: "risksmart.test_frequency" */
  delete_test_frequency?: Maybe<TestFrequencyMutationResponse>;
  /** delete single row from the table: "risksmart.test_frequency" */
  delete_test_frequency_by_pk?: Maybe<TestFrequency>;
  /** delete data from the table: "risksmart.test_result" */
  delete_test_result?: Maybe<TestResultMutationResponse>;
  /** delete data from the table: "risksmart.test_result_audit" */
  delete_test_result_audit?: Maybe<TestResultAuditMutationResponse>;
  /** delete single row from the table: "risksmart.test_result_audit" */
  delete_test_result_audit_by_pk?: Maybe<TestResultAudit>;
  /** delete single row from the table: "risksmart.test_result" */
  delete_test_result_by_pk?: Maybe<TestResult>;
  /** delete data from the table: "risksmart.third_party" */
  delete_third_party?: Maybe<ThirdPartyMutationResponse>;
  /** delete data from the table: "risksmart.third_party_audit" */
  delete_third_party_audit?: Maybe<ThirdPartyAuditMutationResponse>;
  /** delete single row from the table: "risksmart.third_party_audit" */
  delete_third_party_audit_by_pk?: Maybe<ThirdPartyAudit>;
  /** delete single row from the table: "risksmart.third_party" */
  delete_third_party_by_pk?: Maybe<ThirdParty>;
  /** delete data from the table: "risksmart.third_party_response" */
  delete_third_party_response?: Maybe<ThirdPartyResponseMutationResponse>;
  /** delete single row from the table: "risksmart.third_party_response" */
  delete_third_party_response_by_pk?: Maybe<ThirdPartyResponse>;
  /** delete data from the table: "risksmart.third_party_response_status" */
  delete_third_party_response_status?: Maybe<ThirdPartyResponseStatusMutationResponse>;
  /** delete single row from the table: "risksmart.third_party_response_status" */
  delete_third_party_response_status_by_pk?: Maybe<ThirdPartyResponseStatus>;
  /** delete data from the table: "risksmart.third_party_status" */
  delete_third_party_status?: Maybe<ThirdPartyStatusMutationResponse>;
  /** delete single row from the table: "risksmart.third_party_status" */
  delete_third_party_status_by_pk?: Maybe<ThirdPartyStatus>;
  /** delete data from the table: "risksmart.third_party_type" */
  delete_third_party_type?: Maybe<ThirdPartyTypeMutationResponse>;
  /** delete single row from the table: "risksmart.third_party_type" */
  delete_third_party_type_by_pk?: Maybe<ThirdPartyType>;
  /** delete data from the table: "risksmart.user_group" */
  delete_user_group?: Maybe<UserGroupMutationResponse>;
  /** delete data from the table: "risksmart.user_group_audit" */
  delete_user_group_audit?: Maybe<UserGroupAuditMutationResponse>;
  /** delete single row from the table: "risksmart.user_group_audit" */
  delete_user_group_audit_by_pk?: Maybe<UserGroupAudit>;
  /** delete single row from the table: "risksmart.user_group" */
  delete_user_group_by_pk?: Maybe<UserGroup>;
  /** delete data from the table: "risksmart.user_group_user" */
  delete_user_group_user?: Maybe<UserGroupUserMutationResponse>;
  /** delete data from the table: "risksmart.user_group_user_audit" */
  delete_user_group_user_audit?: Maybe<UserGroupUserAuditMutationResponse>;
  /** delete single row from the table: "risksmart.user_group_user_audit" */
  delete_user_group_user_audit_by_pk?: Maybe<UserGroupUserAudit>;
  /** delete single row from the table: "risksmart.user_group_user" */
  delete_user_group_user_by_pk?: Maybe<UserGroupUser>;
  /** delete data from the table: "risksmart.user_search_preferences" */
  delete_user_search_preferences?: Maybe<UserSearchPreferencesMutationResponse>;
  /** delete data from the table: "risksmart.user_search_preferences_audit" */
  delete_user_search_preferences_audit?: Maybe<UserSearchPreferencesAuditMutationResponse>;
  /** delete single row from the table: "risksmart.user_search_preferences_audit" */
  delete_user_search_preferences_audit_by_pk?: Maybe<UserSearchPreferencesAudit>;
  /** delete single row from the table: "risksmart.user_search_preferences" */
  delete_user_search_preferences_by_pk?: Maybe<UserSearchPreferences>;
  /** delete data from the table: "auth.user_status" */
  delete_user_status?: Maybe<UserStatusMutationResponse>;
  /** delete single row from the table: "auth.user_status" */
  delete_user_status_by_pk?: Maybe<UserStatus>;
  /** delete data from the table: "risksmart.version_status" */
  delete_version_status?: Maybe<VersionStatusMutationResponse>;
  /** delete single row from the table: "risksmart.version_status" */
  delete_version_status_by_pk?: Maybe<VersionStatus>;
  disconnectSlack: DisconnectSlackOutput;
  insertAssessmentActivityWithLinkedItems?: Maybe<InsertAssessmentActivityOutput>;
  insertChildAcceptance?: Maybe<InsertChildAcceptanceOutput>;
  insertChildAction?: Maybe<InsertChildActionOutput>;
  insertChildAppetite?: Maybe<InsertChildAppetiteOutput>;
  insertChildControl?: Maybe<InsertChildControlOutput>;
  insertChildDashboard?: Maybe<InsertChildDashboardOutput>;
  insertChildDocumentAssessmentResult?: Maybe<InsertChildDocumentAssessmentResultOutput>;
  insertChildImpact?: Maybe<InsertChildImpactOutput>;
  insertChildImpactRating?: Maybe<InsertChildImpactRatingOutput>;
  insertChildIndicator?: Maybe<InsertChildIndicatorOutput>;
  insertChildIssue?: Maybe<InsertChildIssueOutput>;
  insertChildIssueAssessment?: Maybe<InsertIssueAssessmentOutput>;
  insertChildObligationAssessmentResult?: Maybe<InsertChildObligationAssessmentResultOutput>;
  /** insertChildRisk */
  insertChildRisk?: Maybe<InsertChildRiskOutput>;
  insertChildRiskAssessmentResult?: Maybe<InsertChildRiskAssessmentResultOutput>;
  insertControlTestResult?: Maybe<InsertControlTestResultOutput>;
  insertDocumentVersion?: Maybe<InsertDocumentVersionOutput>;
  insertInternalAudit?: Maybe<InsertInternalAuditOutput>;
  insertQuestionnaireInvites: GenericMutationOutput;
  insertReferenceUser?: Maybe<InsertReferenceUserOutput>;
  /** insert data into the table: "risksmart.acceptance" */
  insert_acceptance?: Maybe<AcceptanceMutationResponse>;
  /** insert data into the table: "risksmart.acceptance_audit" */
  insert_acceptance_audit?: Maybe<AcceptanceAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.acceptance_audit" */
  insert_acceptance_audit_one?: Maybe<AcceptanceAudit>;
  /** insert a single row into the table: "risksmart.acceptance" */
  insert_acceptance_one?: Maybe<Acceptance>;
  /** insert data into the table: "risksmart.acceptance_parent" */
  insert_acceptance_parent?: Maybe<AcceptanceParentMutationResponse>;
  /** insert data into the table: "risksmart.acceptance_parent_audit" */
  insert_acceptance_parent_audit?: Maybe<AcceptanceParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.acceptance_parent_audit" */
  insert_acceptance_parent_audit_one?: Maybe<AcceptanceParentAudit>;
  /** insert a single row into the table: "risksmart.acceptance_parent" */
  insert_acceptance_parent_one?: Maybe<AcceptanceParent>;
  /** insert data into the table: "risksmart.acceptance_status" */
  insert_acceptance_status?: Maybe<AcceptanceStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.acceptance_status" */
  insert_acceptance_status_one?: Maybe<AcceptanceStatus>;
  /** insert data into the table: "risksmart.access_type" */
  insert_access_type?: Maybe<AccessTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.access_type" */
  insert_access_type_one?: Maybe<AccessType>;
  /** insert data into the table: "risksmart.action" */
  insert_action?: Maybe<ActionMutationResponse>;
  /** insert data into the table: "risksmart.action_audit" */
  insert_action_audit?: Maybe<ActionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.action_audit" */
  insert_action_audit_one?: Maybe<ActionAudit>;
  /** insert a single row into the table: "risksmart.action" */
  insert_action_one?: Maybe<Action>;
  /** insert data into the table: "risksmart.action_parent" */
  insert_action_parent?: Maybe<ActionParentMutationResponse>;
  /** insert data into the table: "risksmart.action_parent_audit" */
  insert_action_parent_audit?: Maybe<ActionParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.action_parent_audit" */
  insert_action_parent_audit_one?: Maybe<ActionParentAudit>;
  /** insert a single row into the table: "risksmart.action_parent" */
  insert_action_parent_one?: Maybe<ActionParent>;
  /** insert data into the table: "risksmart.action_status" */
  insert_action_status?: Maybe<ActionStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.action_status" */
  insert_action_status_one?: Maybe<ActionStatus>;
  /** insert data into the table: "risksmart.action_update" */
  insert_action_update?: Maybe<ActionUpdateMutationResponse>;
  /** insert data into the table: "risksmart.action_update_audit" */
  insert_action_update_audit?: Maybe<ActionUpdateAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.action_update_audit" */
  insert_action_update_audit_one?: Maybe<ActionUpdateAudit>;
  /** insert a single row into the table: "risksmart.action_update" */
  insert_action_update_one?: Maybe<ActionUpdate>;
  /** insert data into the table: "risksmart.aggregation_org" */
  insert_aggregation_org?: Maybe<AggregationOrgMutationResponse>;
  /** insert a single row into the table: "risksmart.aggregation_org" */
  insert_aggregation_org_one?: Maybe<AggregationOrg>;
  /** insert data into the table: "risksmart.appetite" */
  insert_appetite?: Maybe<AppetiteMutationResponse>;
  /** insert data into the table: "risksmart.appetite_audit" */
  insert_appetite_audit?: Maybe<AppetiteAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.appetite_audit" */
  insert_appetite_audit_one?: Maybe<AppetiteAudit>;
  /** insert data into the table: "risksmart.appetite_model" */
  insert_appetite_model?: Maybe<AppetiteModelMutationResponse>;
  /** insert a single row into the table: "risksmart.appetite_model" */
  insert_appetite_model_one?: Maybe<AppetiteModel>;
  /** insert a single row into the table: "risksmart.appetite" */
  insert_appetite_one?: Maybe<Appetite>;
  /** insert data into the table: "risksmart.appetite_parent" */
  insert_appetite_parent?: Maybe<AppetiteParentMutationResponse>;
  /** insert data into the table: "risksmart.appetite_parent_audit" */
  insert_appetite_parent_audit?: Maybe<AppetiteParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.appetite_parent_audit" */
  insert_appetite_parent_audit_one?: Maybe<AppetiteParentAudit>;
  /** insert a single row into the table: "risksmart.appetite_parent" */
  insert_appetite_parent_one?: Maybe<AppetiteParent>;
  /** insert data into the table: "risksmart.appetite_type" */
  insert_appetite_type?: Maybe<AppetiteTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.appetite_type" */
  insert_appetite_type_one?: Maybe<AppetiteType>;
  /** insert data into the table: "risksmart.approval" */
  insert_approval?: Maybe<ApprovalMutationResponse>;
  /** insert data into the table: "risksmart.approval_audit" */
  insert_approval_audit?: Maybe<ApprovalAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.approval_audit" */
  insert_approval_audit_one?: Maybe<ApprovalAudit>;
  /** insert data into the table: "risksmart.approval_in_flight_edit_rule" */
  insert_approval_in_flight_edit_rule?: Maybe<ApprovalInFlightEditRuleMutationResponse>;
  /** insert a single row into the table: "risksmart.approval_in_flight_edit_rule" */
  insert_approval_in_flight_edit_rule_one?: Maybe<ApprovalInFlightEditRule>;
  /** insert data into the table: "risksmart.approval_level" */
  insert_approval_level?: Maybe<ApprovalLevelMutationResponse>;
  /** insert data into the table: "risksmart.approval_level_audit" */
  insert_approval_level_audit?: Maybe<ApprovalLevelAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.approval_level_audit" */
  insert_approval_level_audit_one?: Maybe<ApprovalLevelAudit>;
  /** insert a single row into the table: "risksmart.approval_level" */
  insert_approval_level_one?: Maybe<ApprovalLevel>;
  /** insert a single row into the table: "risksmart.approval" */
  insert_approval_one?: Maybe<Approval>;
  /** insert data into the table: "risksmart.approval_rule_type" */
  insert_approval_rule_type?: Maybe<ApprovalRuleTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.approval_rule_type" */
  insert_approval_rule_type_one?: Maybe<ApprovalRuleType>;
  /** insert data into the table: "risksmart.approval_status" */
  insert_approval_status?: Maybe<ApprovalStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.approval_status" */
  insert_approval_status_one?: Maybe<ApprovalStatus>;
  /** insert data into the table: "risksmart.approver" */
  insert_approver?: Maybe<ApproverMutationResponse>;
  /** insert data into the table: "risksmart.approver_audit" */
  insert_approver_audit?: Maybe<ApproverAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.approver_audit" */
  insert_approver_audit_one?: Maybe<ApproverAudit>;
  /** insert a single row into the table: "risksmart.approver" */
  insert_approver_one?: Maybe<Approver>;
  /** insert data into the table: "risksmart.approver_response" */
  insert_approver_response?: Maybe<ApproverResponseMutationResponse>;
  /** insert a single row into the table: "risksmart.approver_response" */
  insert_approver_response_one?: Maybe<ApproverResponse>;
  /** insert data into the table: "risksmart.assessment" */
  insert_assessment?: Maybe<AssessmentMutationResponse>;
  /** insert data into the table: "risksmart.assessment_activity" */
  insert_assessment_activity?: Maybe<AssessmentActivityMutationResponse>;
  /** insert data into the table: "risksmart.assessment_activity_audit" */
  insert_assessment_activity_audit?: Maybe<AssessmentActivityAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.assessment_activity_audit" */
  insert_assessment_activity_audit_one?: Maybe<AssessmentActivityAudit>;
  /** insert a single row into the table: "risksmart.assessment_activity" */
  insert_assessment_activity_one?: Maybe<AssessmentActivity>;
  /** insert data into the table: "risksmart.assessment_activity_status" */
  insert_assessment_activity_status?: Maybe<AssessmentActivityStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.assessment_activity_status" */
  insert_assessment_activity_status_one?: Maybe<AssessmentActivityStatus>;
  /** insert data into the table: "risksmart.assessment_activity_type" */
  insert_assessment_activity_type?: Maybe<AssessmentActivityTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.assessment_activity_type" */
  insert_assessment_activity_type_one?: Maybe<AssessmentActivityType>;
  /** insert data into the table: "risksmart.assessment_audit" */
  insert_assessment_audit?: Maybe<AssessmentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.assessment_audit" */
  insert_assessment_audit_one?: Maybe<AssessmentAudit>;
  /** insert a single row into the table: "risksmart.assessment" */
  insert_assessment_one?: Maybe<Assessment>;
  /** insert data into the table: "risksmart.assessment_result_parent" */
  insert_assessment_result_parent?: Maybe<AssessmentResultParentMutationResponse>;
  /** insert data into the table: "risksmart.assessment_result_parent_audit" */
  insert_assessment_result_parent_audit?: Maybe<AssessmentResultParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.assessment_result_parent_audit" */
  insert_assessment_result_parent_audit_one?: Maybe<AssessmentResultParentAudit>;
  /** insert a single row into the table: "risksmart.assessment_result_parent" */
  insert_assessment_result_parent_one?: Maybe<AssessmentResultParent>;
  /** insert data into the table: "risksmart.assessment_status" */
  insert_assessment_status?: Maybe<AssessmentStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.assessment_status" */
  insert_assessment_status_one?: Maybe<AssessmentStatus>;
  /** insert data into the table: "risksmart.attestation_config" */
  insert_attestation_config?: Maybe<AttestationConfigMutationResponse>;
  /** insert a single row into the table: "risksmart.attestation_config" */
  insert_attestation_config_one?: Maybe<AttestationConfig>;
  /** insert data into the table: "risksmart.attestation_group" */
  insert_attestation_group?: Maybe<AttestationGroupMutationResponse>;
  /** insert a single row into the table: "risksmart.attestation_group" */
  insert_attestation_group_one?: Maybe<AttestationGroup>;
  /** insert data into the table: "risksmart.attestation_record" */
  insert_attestation_record?: Maybe<AttestationRecordMutationResponse>;
  /** insert a single row into the table: "risksmart.attestation_record" */
  insert_attestation_record_one?: Maybe<AttestationRecord>;
  /** insert data into the table: "risksmart.attestation_record_status" */
  insert_attestation_record_status?: Maybe<AttestationRecordStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.attestation_record_status" */
  insert_attestation_record_status_one?: Maybe<AttestationRecordStatus>;
  /** insert data into the table: "auth.organisation" */
  insert_auth_organisation?: Maybe<AuthOrganisationMutationResponse>;
  /** insert data into the table: "auth.organisation_audit" */
  insert_auth_organisation_audit?: Maybe<AuthOrganisationAuditMutationResponse>;
  /** insert a single row into the table: "auth.organisation_audit" */
  insert_auth_organisation_audit_one?: Maybe<AuthOrganisationAudit>;
  /** insert a single row into the table: "auth.organisation" */
  insert_auth_organisation_one?: Maybe<AuthOrganisation>;
  /** insert data into the table: "auth.organisationuser" */
  insert_auth_organisationuser?: Maybe<AuthOrganisationuserMutationResponse>;
  /** insert data into the table: "auth.organisationuser_audit" */
  insert_auth_organisationuser_audit?: Maybe<AuthOrganisationuserAuditMutationResponse>;
  /** insert a single row into the table: "auth.organisationuser_audit" */
  insert_auth_organisationuser_audit_one?: Maybe<AuthOrganisationuserAudit>;
  /** insert a single row into the table: "auth.organisationuser" */
  insert_auth_organisationuser_one?: Maybe<AuthOrganisationuser>;
  /** insert data into the table: "auth.user" */
  insert_auth_user?: Maybe<AuthUserMutationResponse>;
  /** insert data into the table: "auth.user_activity_audit" */
  insert_auth_user_activity_audit?: Maybe<AuthUserActivityAuditMutationResponse>;
  /** insert a single row into the table: "auth.user_activity_audit" */
  insert_auth_user_activity_audit_one?: Maybe<AuthUserActivityAudit>;
  /** insert data into the table: "auth.user_audit" */
  insert_auth_user_audit?: Maybe<AuthUserAuditMutationResponse>;
  /** insert a single row into the table: "auth.user_audit" */
  insert_auth_user_audit_one?: Maybe<AuthUserAudit>;
  /** insert a single row into the table: "auth.user" */
  insert_auth_user_one?: Maybe<AuthUser>;
  /** insert data into the table: "risksmart.business_area" */
  insert_business_area?: Maybe<BusinessAreaMutationResponse>;
  /** insert a single row into the table: "risksmart.business_area" */
  insert_business_area_one?: Maybe<BusinessArea>;
  /** insert data into the table: "risksmart.cause" */
  insert_cause?: Maybe<CauseMutationResponse>;
  /** insert data into the table: "risksmart.cause_audit" */
  insert_cause_audit?: Maybe<CauseAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.cause_audit" */
  insert_cause_audit_one?: Maybe<CauseAudit>;
  /** insert a single row into the table: "risksmart.cause" */
  insert_cause_one?: Maybe<Cause>;
  /** insert data into the table: "risksmart.change_request" */
  insert_change_request?: Maybe<ChangeRequestMutationResponse>;
  /** insert data into the table: "risksmart.change_request_audit" */
  insert_change_request_audit?: Maybe<ChangeRequestAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.change_request_audit" */
  insert_change_request_audit_one?: Maybe<ChangeRequestAudit>;
  /** insert data into the table: "risksmart.change_request_contributor" */
  insert_change_request_contributor?: Maybe<ChangeRequestContributorMutationResponse>;
  /** insert a single row into the table: "risksmart.change_request_contributor" */
  insert_change_request_contributor_one?: Maybe<ChangeRequestContributor>;
  /** insert a single row into the table: "risksmart.change_request" */
  insert_change_request_one?: Maybe<ChangeRequest>;
  /** insert data into the table: "risksmart.comment" */
  insert_comment?: Maybe<CommentMutationResponse>;
  /** insert data into the table: "risksmart.comment_audit" */
  insert_comment_audit?: Maybe<CommentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.comment_audit" */
  insert_comment_audit_one?: Maybe<CommentAudit>;
  /** insert a single row into the table: "risksmart.comment" */
  insert_comment_one?: Maybe<Comment>;
  /** insert data into the table: "risksmart.compliance_monitoring_assessment" */
  insert_compliance_monitoring_assessment?: Maybe<ComplianceMonitoringAssessmentMutationResponse>;
  /** insert a single row into the table: "risksmart.compliance_monitoring_assessment" */
  insert_compliance_monitoring_assessment_one?: Maybe<ComplianceMonitoringAssessment>;
  /** insert data into the table: "config.env" */
  insert_config_env?: Maybe<ConfigEnvMutationResponse>;
  /** insert a single row into the table: "config.env" */
  insert_config_env_one?: Maybe<ConfigEnv>;
  /** insert data into the table: "risksmart.consequence" */
  insert_consequence?: Maybe<ConsequenceMutationResponse>;
  /** insert data into the table: "risksmart.consequence_audit" */
  insert_consequence_audit?: Maybe<ConsequenceAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.consequence_audit" */
  insert_consequence_audit_one?: Maybe<ConsequenceAudit>;
  /** insert a single row into the table: "risksmart.consequence" */
  insert_consequence_one?: Maybe<Consequence>;
  /** insert data into the table: "risksmart.consequence_type" */
  insert_consequence_type?: Maybe<ConsequenceTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.consequence_type" */
  insert_consequence_type_one?: Maybe<ConsequenceType>;
  /** insert data into the table: "risksmart.contributor" */
  insert_contributor?: Maybe<ContributorMutationResponse>;
  /** insert data into the table: "risksmart.contributor_audit" */
  insert_contributor_audit?: Maybe<ContributorAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.contributor_audit" */
  insert_contributor_audit_one?: Maybe<ContributorAudit>;
  /** insert data into the table: "risksmart.contributor_group" */
  insert_contributor_group?: Maybe<ContributorGroupMutationResponse>;
  /** insert data into the table: "risksmart.contributor_group_audit" */
  insert_contributor_group_audit?: Maybe<ContributorGroupAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.contributor_group_audit" */
  insert_contributor_group_audit_one?: Maybe<ContributorGroupAudit>;
  /** insert a single row into the table: "risksmart.contributor_group" */
  insert_contributor_group_one?: Maybe<ContributorGroup>;
  /** insert a single row into the table: "risksmart.contributor" */
  insert_contributor_one?: Maybe<Contributor>;
  /** insert data into the table: "risksmart.contributor_type" */
  insert_contributor_type?: Maybe<ContributorTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.contributor_type" */
  insert_contributor_type_one?: Maybe<ContributorType>;
  /** insert data into the table: "risksmart.control" */
  insert_control?: Maybe<ControlMutationResponse>;
  /** insert data into the table: "risksmart.control_audit" */
  insert_control_audit?: Maybe<ControlAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.control_audit" */
  insert_control_audit_one?: Maybe<ControlAudit>;
  /** insert data into the table: "risksmart.control_group" */
  insert_control_group?: Maybe<ControlGroupMutationResponse>;
  /** insert data into the table: "risksmart.control_group_audit" */
  insert_control_group_audit?: Maybe<ControlGroupAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.control_group_audit" */
  insert_control_group_audit_one?: Maybe<ControlGroupAudit>;
  /** insert a single row into the table: "risksmart.control_group" */
  insert_control_group_one?: Maybe<ControlGroup>;
  /** insert a single row into the table: "risksmart.control" */
  insert_control_one?: Maybe<Control>;
  /** insert data into the table: "risksmart.control_parent" */
  insert_control_parent?: Maybe<ControlParentMutationResponse>;
  /** insert data into the table: "risksmart.control_parent_audit" */
  insert_control_parent_audit?: Maybe<ControlParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.control_parent_audit" */
  insert_control_parent_audit_one?: Maybe<ControlParentAudit>;
  /** insert a single row into the table: "risksmart.control_parent" */
  insert_control_parent_one?: Maybe<ControlParent>;
  /** insert data into the table: "risksmart.control_test_frequency" */
  insert_control_test_frequency?: Maybe<ControlTestFrequencyMutationResponse>;
  /** insert a single row into the table: "risksmart.control_test_frequency" */
  insert_control_test_frequency_one?: Maybe<ControlTestFrequency>;
  /** insert data into the table: "risksmart.control_type" */
  insert_control_type?: Maybe<ControlTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.control_type" */
  insert_control_type_one?: Maybe<ControlType>;
  /** insert data into the table: "risksmart.conversation" */
  insert_conversation?: Maybe<ConversationMutationResponse>;
  /** insert data into the table: "risksmart.conversation_audit" */
  insert_conversation_audit?: Maybe<ConversationAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.conversation_audit" */
  insert_conversation_audit_one?: Maybe<ConversationAudit>;
  /** insert a single row into the table: "risksmart.conversation" */
  insert_conversation_one?: Maybe<Conversation>;
  /** insert data into the table: "risksmart.cost_type" */
  insert_cost_type?: Maybe<CostTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.cost_type" */
  insert_cost_type_one?: Maybe<CostType>;
  /** insert data into the table: "risksmart.custom_attribute_schema" */
  insert_custom_attribute_schema?: Maybe<CustomAttributeSchemaMutationResponse>;
  /** insert data into the table: "risksmart.custom_attribute_schema_audit" */
  insert_custom_attribute_schema_audit?: Maybe<CustomAttributeSchemaAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.custom_attribute_schema_audit" */
  insert_custom_attribute_schema_audit_one?: Maybe<CustomAttributeSchemaAudit>;
  /** insert a single row into the table: "risksmart.custom_attribute_schema" */
  insert_custom_attribute_schema_one?: Maybe<CustomAttributeSchema>;
  /** insert data into the table: "risksmart.custom_ribbon" */
  insert_custom_ribbon?: Maybe<CustomRibbonMutationResponse>;
  /** insert data into the table: "risksmart.custom_ribbon_audit" */
  insert_custom_ribbon_audit?: Maybe<CustomRibbonAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.custom_ribbon_audit" */
  insert_custom_ribbon_audit_one?: Maybe<CustomRibbonAudit>;
  /** insert a single row into the table: "risksmart.custom_ribbon" */
  insert_custom_ribbon_one?: Maybe<CustomRibbon>;
  /** insert data into the table: "risksmart.dashboard" */
  insert_dashboard?: Maybe<DashboardMutationResponse>;
  /** insert data into the table: "risksmart.dashboard_audit" */
  insert_dashboard_audit?: Maybe<DashboardAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.dashboard_audit" */
  insert_dashboard_audit_one?: Maybe<DashboardAudit>;
  /** insert a single row into the table: "risksmart.dashboard" */
  insert_dashboard_one?: Maybe<Dashboard>;
  /** insert data into the table: "risksmart.dashboard_sharing_type" */
  insert_dashboard_sharing_type?: Maybe<DashboardSharingTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.dashboard_sharing_type" */
  insert_dashboard_sharing_type_one?: Maybe<DashboardSharingType>;
  /** insert data into the table: "risksmart.data_import" */
  insert_data_import?: Maybe<DataImportMutationResponse>;
  /** insert data into the table: "risksmart.data_import_audit" */
  insert_data_import_audit?: Maybe<DataImportAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.data_import_audit" */
  insert_data_import_audit_one?: Maybe<DataImportAudit>;
  /** insert data into the table: "risksmart.data_import_error" */
  insert_data_import_error?: Maybe<DataImportErrorMutationResponse>;
  /** insert data into the table: "risksmart.data_import_error_audit" */
  insert_data_import_error_audit?: Maybe<DataImportErrorAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.data_import_error_audit" */
  insert_data_import_error_audit_one?: Maybe<DataImportErrorAudit>;
  /** insert a single row into the table: "risksmart.data_import_error" */
  insert_data_import_error_one?: Maybe<DataImportError>;
  /** insert a single row into the table: "risksmart.data_import" */
  insert_data_import_one?: Maybe<DataImport>;
  /** insert data into the table: "risksmart.data_import_status" */
  insert_data_import_status?: Maybe<DataImportStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.data_import_status" */
  insert_data_import_status_one?: Maybe<DataImportStatus>;
  /** insert data into the table: "risksmart.department" */
  insert_department?: Maybe<DepartmentMutationResponse>;
  /** insert data into the table: "risksmart.department_audit" */
  insert_department_audit?: Maybe<DepartmentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.department_audit" */
  insert_department_audit_one?: Maybe<DepartmentAudit>;
  /** insert a single row into the table: "risksmart.department" */
  insert_department_one?: Maybe<Department>;
  /** insert data into the table: "risksmart.department_type" */
  insert_department_type?: Maybe<DepartmentTypeMutationResponse>;
  /** insert data into the table: "risksmart.department_type_audit" */
  insert_department_type_audit?: Maybe<DepartmentTypeAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.department_type_audit" */
  insert_department_type_audit_one?: Maybe<DepartmentTypeAudit>;
  /** insert data into the table: "risksmart.department_type_group" */
  insert_department_type_group?: Maybe<DepartmentTypeGroupMutationResponse>;
  /** insert data into the table: "risksmart.department_type_group_audit" */
  insert_department_type_group_audit?: Maybe<DepartmentTypeGroupAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.department_type_group_audit" */
  insert_department_type_group_audit_one?: Maybe<DepartmentTypeGroupAudit>;
  /** insert a single row into the table: "risksmart.department_type_group" */
  insert_department_type_group_one?: Maybe<DepartmentTypeGroup>;
  /** insert a single row into the table: "risksmart.department_type" */
  insert_department_type_one?: Maybe<DepartmentType>;
  /** insert data into the table: "risksmart.document" */
  insert_document?: Maybe<DocumentMutationResponse>;
  /** insert data into the table: "risksmart.document_action_audit" */
  insert_document_action_audit?: Maybe<DocumentActionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.document_action_audit" */
  insert_document_action_audit_one?: Maybe<DocumentActionAudit>;
  /** insert data into the table: "risksmart.document_assessment_result" */
  insert_document_assessment_result?: Maybe<DocumentAssessmentResultMutationResponse>;
  /** insert data into the table: "risksmart.document_assessment_result_audit" */
  insert_document_assessment_result_audit?: Maybe<DocumentAssessmentResultAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.document_assessment_result_audit" */
  insert_document_assessment_result_audit_one?: Maybe<DocumentAssessmentResultAudit>;
  /** insert a single row into the table: "risksmart.document_assessment_result" */
  insert_document_assessment_result_one?: Maybe<DocumentAssessmentResult>;
  /** insert data into the table: "risksmart.document_assessment_status" */
  insert_document_assessment_status?: Maybe<DocumentAssessmentStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.document_assessment_status" */
  insert_document_assessment_status_one?: Maybe<DocumentAssessmentStatus>;
  /** insert data into the table: "risksmart.document_audit" */
  insert_document_audit?: Maybe<DocumentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.document_audit" */
  insert_document_audit_one?: Maybe<DocumentAudit>;
  /** insert data into the table: "risksmart.document_file" */
  insert_document_file?: Maybe<DocumentFileMutationResponse>;
  /** insert data into the table: "risksmart.document_file_audit" */
  insert_document_file_audit?: Maybe<DocumentFileAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.document_file_audit" */
  insert_document_file_audit_one?: Maybe<DocumentFileAudit>;
  /** insert a single row into the table: "risksmart.document_file" */
  insert_document_file_one?: Maybe<DocumentFile>;
  /** insert data into the table: "risksmart.document_file_type" */
  insert_document_file_type?: Maybe<DocumentFileTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.document_file_type" */
  insert_document_file_type_one?: Maybe<DocumentFileType>;
  /** insert data into the table: "risksmart.document_issue_audit" */
  insert_document_issue_audit?: Maybe<DocumentIssueAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.document_issue_audit" */
  insert_document_issue_audit_one?: Maybe<DocumentIssueAudit>;
  /** insert data into the table: "risksmart.document_linked_document" */
  insert_document_linked_document?: Maybe<DocumentLinkedDocumentMutationResponse>;
  /** insert data into the table: "risksmart.document_linked_document_audit" */
  insert_document_linked_document_audit?: Maybe<DocumentLinkedDocumentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.document_linked_document_audit" */
  insert_document_linked_document_audit_one?: Maybe<DocumentLinkedDocumentAudit>;
  /** insert a single row into the table: "risksmart.document_linked_document" */
  insert_document_linked_document_one?: Maybe<DocumentLinkedDocument>;
  /** insert a single row into the table: "risksmart.document" */
  insert_document_one?: Maybe<Document>;
  /** insert data into the table: "risksmart.file" */
  insert_file?: Maybe<FileMutationResponse>;
  /** insert data into the table: "risksmart.file_audit" */
  insert_file_audit?: Maybe<FileAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.file_audit" */
  insert_file_audit_one?: Maybe<FileAudit>;
  /** insert a single row into the table: "risksmart.file" */
  insert_file_one?: Maybe<File>;
  /** insert data into the table: "risksmart.form_configuration" */
  insert_form_configuration?: Maybe<FormConfigurationMutationResponse>;
  /** insert data into the table: "risksmart.form_configuration_audit" */
  insert_form_configuration_audit?: Maybe<FormConfigurationAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.form_configuration_audit" */
  insert_form_configuration_audit_one?: Maybe<FormConfigurationAudit>;
  /** insert a single row into the table: "risksmart.form_configuration" */
  insert_form_configuration_one?: Maybe<FormConfiguration>;
  /** insert data into the table: "risksmart.form_field_configuration" */
  insert_form_field_configuration?: Maybe<FormFieldConfigurationMutationResponse>;
  /** insert data into the table: "risksmart.form_field_configuration_audit" */
  insert_form_field_configuration_audit?: Maybe<FormFieldConfigurationAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.form_field_configuration_audit" */
  insert_form_field_configuration_audit_one?: Maybe<FormFieldConfigurationAudit>;
  /** insert a single row into the table: "risksmart.form_field_configuration" */
  insert_form_field_configuration_one?: Maybe<FormFieldConfiguration>;
  /** insert data into the table: "risksmart.form_field_ordering" */
  insert_form_field_ordering?: Maybe<FormFieldOrderingMutationResponse>;
  /** insert data into the table: "risksmart.form_field_ordering_audit" */
  insert_form_field_ordering_audit?: Maybe<FormFieldOrderingAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.form_field_ordering_audit" */
  insert_form_field_ordering_audit_one?: Maybe<FormFieldOrderingAudit>;
  /** insert a single row into the table: "risksmart.form_field_ordering" */
  insert_form_field_ordering_one?: Maybe<FormFieldOrdering>;
  /** insert data into the table: "risksmart.impact" */
  insert_impact?: Maybe<ImpactMutationResponse>;
  /** insert data into the table: "risksmart.impact_audit" */
  insert_impact_audit?: Maybe<ImpactAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.impact_audit" */
  insert_impact_audit_one?: Maybe<ImpactAudit>;
  /** insert a single row into the table: "risksmart.impact" */
  insert_impact_one?: Maybe<Impact>;
  /** insert data into the table: "risksmart.impact_parent" */
  insert_impact_parent?: Maybe<ImpactParentMutationResponse>;
  /** insert data into the table: "risksmart.impact_parent_audit" */
  insert_impact_parent_audit?: Maybe<ImpactParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.impact_parent_audit" */
  insert_impact_parent_audit_one?: Maybe<ImpactParentAudit>;
  /** insert a single row into the table: "risksmart.impact_parent" */
  insert_impact_parent_one?: Maybe<ImpactParent>;
  /** insert data into the table: "risksmart.impact_rating" */
  insert_impact_rating?: Maybe<ImpactRatingMutationResponse>;
  /** insert data into the table: "risksmart.impact_rating_audit" */
  insert_impact_rating_audit?: Maybe<ImpactRatingAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.impact_rating_audit" */
  insert_impact_rating_audit_one?: Maybe<ImpactRatingAudit>;
  /** insert a single row into the table: "risksmart.impact_rating" */
  insert_impact_rating_one?: Maybe<ImpactRating>;
  /** insert data into the table: "risksmart.indicator" */
  insert_indicator?: Maybe<IndicatorMutationResponse>;
  /** insert data into the table: "risksmart.indicator_audit" */
  insert_indicator_audit?: Maybe<IndicatorAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.indicator_audit" */
  insert_indicator_audit_one?: Maybe<IndicatorAudit>;
  /** insert a single row into the table: "risksmart.indicator" */
  insert_indicator_one?: Maybe<Indicator>;
  /** insert data into the table: "risksmart.indicator_parent" */
  insert_indicator_parent?: Maybe<IndicatorParentMutationResponse>;
  /** insert a single row into the table: "risksmart.indicator_parent" */
  insert_indicator_parent_one?: Maybe<IndicatorParent>;
  /** insert data into the table: "risksmart.indicator_result" */
  insert_indicator_result?: Maybe<IndicatorResultMutationResponse>;
  /** insert data into the table: "risksmart.indicator_result_audit" */
  insert_indicator_result_audit?: Maybe<IndicatorResultAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.indicator_result_audit" */
  insert_indicator_result_audit_one?: Maybe<IndicatorResultAudit>;
  /** insert a single row into the table: "risksmart.indicator_result" */
  insert_indicator_result_one?: Maybe<IndicatorResult>;
  /** insert data into the table: "risksmart.indicator_type" */
  insert_indicator_type?: Maybe<IndicatorTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.indicator_type" */
  insert_indicator_type_one?: Maybe<IndicatorType>;
  /** insert data into the table: "risksmart.internal_audit_entity" */
  insert_internal_audit_entity?: Maybe<InternalAuditEntityMutationResponse>;
  /** insert a single row into the table: "risksmart.internal_audit_entity" */
  insert_internal_audit_entity_one?: Maybe<InternalAuditEntity>;
  /** insert data into the table: "risksmart.internal_audit_report" */
  insert_internal_audit_report?: Maybe<InternalAuditReportMutationResponse>;
  /** insert a single row into the table: "risksmart.internal_audit_report" */
  insert_internal_audit_report_one?: Maybe<InternalAuditReport>;
  /** insert data into the table: "risksmart.issue" */
  insert_issue?: Maybe<IssueMutationResponse>;
  /** insert data into the table: "risksmart.issue_action_audit" */
  insert_issue_action_audit?: Maybe<IssueActionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.issue_action_audit" */
  insert_issue_action_audit_one?: Maybe<IssueActionAudit>;
  /** insert data into the table: "risksmart.issue_assessment" */
  insert_issue_assessment?: Maybe<IssueAssessmentMutationResponse>;
  /** insert data into the table: "risksmart.issue_assessment_audit" */
  insert_issue_assessment_audit?: Maybe<IssueAssessmentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.issue_assessment_audit" */
  insert_issue_assessment_audit_one?: Maybe<IssueAssessmentAudit>;
  /** insert a single row into the table: "risksmart.issue_assessment" */
  insert_issue_assessment_one?: Maybe<IssueAssessment>;
  /** insert data into the table: "risksmart.issue_assessment_status" */
  insert_issue_assessment_status?: Maybe<IssueAssessmentStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.issue_assessment_status" */
  insert_issue_assessment_status_one?: Maybe<IssueAssessmentStatus>;
  /** insert data into the table: "risksmart.issue_audit" */
  insert_issue_audit?: Maybe<IssueAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.issue_audit" */
  insert_issue_audit_one?: Maybe<IssueAudit>;
  /** insert a single row into the table: "risksmart.issue" */
  insert_issue_one?: Maybe<Issue>;
  /** insert data into the table: "risksmart.issue_parent" */
  insert_issue_parent?: Maybe<IssueParentMutationResponse>;
  /** insert data into the table: "risksmart.issue_parent_audit" */
  insert_issue_parent_audit?: Maybe<IssueParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.issue_parent_audit" */
  insert_issue_parent_audit_one?: Maybe<IssueParentAudit>;
  /** insert a single row into the table: "risksmart.issue_parent" */
  insert_issue_parent_one?: Maybe<IssueParent>;
  /** insert data into the table: "risksmart.issue_update" */
  insert_issue_update?: Maybe<IssueUpdateMutationResponse>;
  /** insert data into the table: "risksmart.issue_update_audit" */
  insert_issue_update_audit?: Maybe<IssueUpdateAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.issue_update_audit" */
  insert_issue_update_audit_one?: Maybe<IssueUpdateAudit>;
  /** insert a single row into the table: "risksmart.issue_update" */
  insert_issue_update_one?: Maybe<IssueUpdate>;
  /** insert data into the table: "risksmart.linked_item" */
  insert_linked_item?: Maybe<LinkedItemMutationResponse>;
  /** insert a single row into the table: "risksmart.linked_item" */
  insert_linked_item_one?: Maybe<LinkedItem>;
  /** insert data into the table: "risksmart.node" */
  insert_node?: Maybe<NodeMutationResponse>;
  /** insert data into the table: "risksmart.node_ancestor" */
  insert_node_ancestor?: Maybe<NodeAncestorMutationResponse>;
  /** insert a single row into the table: "risksmart.node_ancestor" */
  insert_node_ancestor_one?: Maybe<NodeAncestor>;
  /** insert a single row into the table: "risksmart.node" */
  insert_node_one?: Maybe<Node>;
  /** insert data into the table: "risksmart.obligation" */
  insert_obligation?: Maybe<ObligationMutationResponse>;
  /** insert data into the table: "risksmart.obligation_action_audit" */
  insert_obligation_action_audit?: Maybe<ObligationActionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_action_audit" */
  insert_obligation_action_audit_one?: Maybe<ObligationActionAudit>;
  /** insert data into the table: "risksmart.obligation_assessment_result" */
  insert_obligation_assessment_result?: Maybe<ObligationAssessmentResultMutationResponse>;
  /** insert data into the table: "risksmart.obligation_assessment_result_audit" */
  insert_obligation_assessment_result_audit?: Maybe<ObligationAssessmentResultAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_assessment_result_audit" */
  insert_obligation_assessment_result_audit_one?: Maybe<ObligationAssessmentResultAudit>;
  /** insert a single row into the table: "risksmart.obligation_assessment_result" */
  insert_obligation_assessment_result_one?: Maybe<ObligationAssessmentResult>;
  /** insert data into the table: "risksmart.obligation_assessment_status" */
  insert_obligation_assessment_status?: Maybe<ObligationAssessmentStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_assessment_status" */
  insert_obligation_assessment_status_one?: Maybe<ObligationAssessmentStatus>;
  /** insert data into the table: "risksmart.obligation_audit" */
  insert_obligation_audit?: Maybe<ObligationAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_audit" */
  insert_obligation_audit_one?: Maybe<ObligationAudit>;
  /** insert data into the table: "risksmart.obligation_impact" */
  insert_obligation_impact?: Maybe<ObligationImpactMutationResponse>;
  /** insert data into the table: "risksmart.obligation_impact_audit" */
  insert_obligation_impact_audit?: Maybe<ObligationImpactAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_impact_audit" */
  insert_obligation_impact_audit_one?: Maybe<ObligationImpactAudit>;
  /** insert a single row into the table: "risksmart.obligation_impact" */
  insert_obligation_impact_one?: Maybe<ObligationImpact>;
  /** insert a single row into the table: "risksmart.obligation" */
  insert_obligation_one?: Maybe<Obligation>;
  /** insert data into the table: "risksmart.obligation_type" */
  insert_obligation_type?: Maybe<ObligationTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_type" */
  insert_obligation_type_one?: Maybe<ObligationType>;
  /** insert data into the table: "risksmart.owner" */
  insert_owner?: Maybe<OwnerMutationResponse>;
  /** insert data into the table: "risksmart.owner_audit" */
  insert_owner_audit?: Maybe<OwnerAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.owner_audit" */
  insert_owner_audit_one?: Maybe<OwnerAudit>;
  /** insert data into the table: "risksmart.owner_group" */
  insert_owner_group?: Maybe<OwnerGroupMutationResponse>;
  /** insert data into the table: "risksmart.owner_group_audit" */
  insert_owner_group_audit?: Maybe<OwnerGroupAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.owner_group_audit" */
  insert_owner_group_audit_one?: Maybe<OwnerGroupAudit>;
  /** insert a single row into the table: "risksmart.owner_group" */
  insert_owner_group_one?: Maybe<OwnerGroup>;
  /** insert a single row into the table: "risksmart.owner" */
  insert_owner_one?: Maybe<Owner>;
  /** insert data into the table: "risksmart.parent_type" */
  insert_parent_type?: Maybe<ParentTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.parent_type" */
  insert_parent_type_one?: Maybe<ParentType>;
  /** insert data into the table: "risksmart.questionnaire_invite" */
  insert_questionnaire_invite?: Maybe<QuestionnaireInviteMutationResponse>;
  /** insert a single row into the table: "risksmart.questionnaire_invite" */
  insert_questionnaire_invite_one?: Maybe<QuestionnaireInvite>;
  /** insert data into the table: "risksmart.questionnaire_template" */
  insert_questionnaire_template?: Maybe<QuestionnaireTemplateMutationResponse>;
  /** insert a single row into the table: "risksmart.questionnaire_template" */
  insert_questionnaire_template_one?: Maybe<QuestionnaireTemplate>;
  /** insert data into the table: "risksmart.questionnaire_template_version" */
  insert_questionnaire_template_version?: Maybe<QuestionnaireTemplateVersionMutationResponse>;
  /** insert a single row into the table: "risksmart.questionnaire_template_version" */
  insert_questionnaire_template_version_one?: Maybe<QuestionnaireTemplateVersion>;
  /** insert data into the table: "risksmart.questionnaire_template_version_status" */
  insert_questionnaire_template_version_status?: Maybe<QuestionnaireTemplateVersionStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.questionnaire_template_version_status" */
  insert_questionnaire_template_version_status_one?: Maybe<QuestionnaireTemplateVersionStatus>;
  /** insert data into the table: "risksmart.relation_file" */
  insert_relation_file?: Maybe<RelationFileMutationResponse>;
  /** insert data into the table: "risksmart.relation_file_audit" */
  insert_relation_file_audit?: Maybe<RelationFileAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.relation_file_audit" */
  insert_relation_file_audit_one?: Maybe<RelationFileAudit>;
  /** insert a single row into the table: "risksmart.relation_file" */
  insert_relation_file_one?: Maybe<RelationFile>;
  /** insert data into the table: "risksmart.risk" */
  insert_risk?: Maybe<RiskMutationResponse>;
  /** insert data into the table: "risksmart.risk_assessment_result" */
  insert_risk_assessment_result?: Maybe<RiskAssessmentResultMutationResponse>;
  /** insert data into the table: "risksmart.risk_assessment_result_audit" */
  insert_risk_assessment_result_audit?: Maybe<RiskAssessmentResultAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_assessment_result_audit" */
  insert_risk_assessment_result_audit_one?: Maybe<RiskAssessmentResultAudit>;
  /** insert data into the table: "risksmart.risk_assessment_result_control_type" */
  insert_risk_assessment_result_control_type?: Maybe<RiskAssessmentResultControlTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_assessment_result_control_type" */
  insert_risk_assessment_result_control_type_one?: Maybe<RiskAssessmentResultControlType>;
  /** insert a single row into the table: "risksmart.risk_assessment_result" */
  insert_risk_assessment_result_one?: Maybe<RiskAssessmentResult>;
  /** insert data into the table: "risksmart.risk_audit" */
  insert_risk_audit?: Maybe<RiskAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_audit" */
  insert_risk_audit_one?: Maybe<RiskAudit>;
  /** insert a single row into the table: "risksmart.risk" */
  insert_risk_one?: Maybe<Risk>;
  /** insert data into the table: "risksmart.risk_score" */
  insert_risk_score?: Maybe<RiskScoreMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_score" */
  insert_risk_score_one?: Maybe<RiskScore>;
  /** insert data into the table: "risksmart.risk_scoring_model" */
  insert_risk_scoring_model?: Maybe<RiskScoringModelMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_scoring_model" */
  insert_risk_scoring_model_one?: Maybe<RiskScoringModel>;
  /** insert data into the table: "risksmart.risk_status_type" */
  insert_risk_status_type?: Maybe<RiskStatusTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_status_type" */
  insert_risk_status_type_one?: Maybe<RiskStatusType>;
  /** insert data into the table: "risksmart.risk_treatment_type" */
  insert_risk_treatment_type?: Maybe<RiskTreatmentTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_treatment_type" */
  insert_risk_treatment_type_one?: Maybe<RiskTreatmentType>;
  /** insert data into the table: "risksmart.approver_response_audit" */
  insert_risksmart_approver_response_audit?: Maybe<RisksmartApproverResponseAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.approver_response_audit" */
  insert_risksmart_approver_response_audit_one?: Maybe<RisksmartApproverResponseAudit>;
  /** insert data into the table: "risksmart.change_request_contributor_audit" */
  insert_risksmart_change_request_contributor_audit?: Maybe<RisksmartChangeRequestContributorAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.change_request_contributor_audit" */
  insert_risksmart_change_request_contributor_audit_one?: Maybe<RisksmartChangeRequestContributorAudit>;
  /** insert data into the table: "risksmart.control_action_audit" */
  insert_risksmart_control_action_audit?: Maybe<RisksmartControlActionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.control_action_audit" */
  insert_risksmart_control_action_audit_one?: Maybe<RisksmartControlActionAudit>;
  /** insert data into the table: "risksmart.counter" */
  insert_risksmart_counter?: Maybe<RisksmartCounterMutationResponse>;
  /** insert a single row into the table: "risksmart.counter" */
  insert_risksmart_counter_one?: Maybe<RisksmartCounter>;
  /** insert data into the table: "risksmart.indicator_parent_audit" */
  insert_risksmart_indicator_parent_audit?: Maybe<RisksmartIndicatorParentAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.indicator_parent_audit" */
  insert_risksmart_indicator_parent_audit_one?: Maybe<RisksmartIndicatorParentAudit>;
  /** insert data into the table: "risksmart.linked_item_audit" */
  insert_risksmart_linked_item_audit?: Maybe<RisksmartLinkedItemAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.linked_item_audit" */
  insert_risksmart_linked_item_audit_one?: Maybe<RisksmartLinkedItemAudit>;
  /** insert data into the table: "risksmart.obligation_issue_audit" */
  insert_risksmart_obligation_issue_audit?: Maybe<RisksmartObligationIssueAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.obligation_issue_audit" */
  insert_risksmart_obligation_issue_audit_one?: Maybe<RisksmartObligationIssueAudit>;
  /** insert data into the table: "risksmart.questionnaire_template_audit" */
  insert_risksmart_questionnaire_template_audit?: Maybe<RisksmartQuestionnaireTemplateAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.questionnaire_template_audit" */
  insert_risksmart_questionnaire_template_audit_one?: Maybe<RisksmartQuestionnaireTemplateAudit>;
  /** insert data into the table: "risksmart.questionnaire_template_version_audit" */
  insert_risksmart_questionnaire_template_version_audit?: Maybe<RisksmartQuestionnaireTemplateVersionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.questionnaire_template_version_audit" */
  insert_risksmart_questionnaire_template_version_audit_one?: Maybe<RisksmartQuestionnaireTemplateVersionAudit>;
  /** insert data into the table: "risksmart.risk_action_audit" */
  insert_risksmart_risk_action_audit?: Maybe<RisksmartRiskActionAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.risk_action_audit" */
  insert_risksmart_risk_action_audit_one?: Maybe<RisksmartRiskActionAudit>;
  /** insert data into the table: "risksmart.role_access" */
  insert_role_access?: Maybe<RoleAccessMutationResponse>;
  /** insert a single row into the table: "risksmart.role_access" */
  insert_role_access_one?: Maybe<RoleAccess>;
  /** insert data into the table: "risksmart.tag" */
  insert_tag?: Maybe<TagMutationResponse>;
  /** insert data into the table: "risksmart.tag_audit" */
  insert_tag_audit?: Maybe<TagAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.tag_audit" */
  insert_tag_audit_one?: Maybe<TagAudit>;
  /** insert a single row into the table: "risksmart.tag" */
  insert_tag_one?: Maybe<Tag>;
  /** insert data into the table: "risksmart.tag_type" */
  insert_tag_type?: Maybe<TagTypeMutationResponse>;
  /** insert data into the table: "risksmart.tag_type_audit" */
  insert_tag_type_audit?: Maybe<TagTypeAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.tag_type_audit" */
  insert_tag_type_audit_one?: Maybe<TagTypeAudit>;
  /** insert data into the table: "risksmart.tag_type_group" */
  insert_tag_type_group?: Maybe<TagTypeGroupMutationResponse>;
  /** insert data into the table: "risksmart.tag_type_group_audit" */
  insert_tag_type_group_audit?: Maybe<TagTypeGroupAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.tag_type_group_audit" */
  insert_tag_type_group_audit_one?: Maybe<TagTypeGroupAudit>;
  /** insert a single row into the table: "risksmart.tag_type_group" */
  insert_tag_type_group_one?: Maybe<TagTypeGroup>;
  /** insert a single row into the table: "risksmart.tag_type" */
  insert_tag_type_one?: Maybe<TagType>;
  /** insert data into the table: "risksmart.taxonomy" */
  insert_taxonomy?: Maybe<TaxonomyMutationResponse>;
  /** insert data into the table: "risksmart.taxonomy_audit" */
  insert_taxonomy_audit?: Maybe<TaxonomyAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.taxonomy_audit" */
  insert_taxonomy_audit_one?: Maybe<TaxonomyAudit>;
  /** insert a single row into the table: "risksmart.taxonomy" */
  insert_taxonomy_one?: Maybe<Taxonomy>;
  /** insert data into the table: "risksmart.taxonomy_org" */
  insert_taxonomy_org?: Maybe<TaxonomyOrgMutationResponse>;
  /** insert data into the table: "risksmart.taxonomy_org_audit" */
  insert_taxonomy_org_audit?: Maybe<TaxonomyOrgAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.taxonomy_org_audit" */
  insert_taxonomy_org_audit_one?: Maybe<TaxonomyOrgAudit>;
  /** insert a single row into the table: "risksmart.taxonomy_org" */
  insert_taxonomy_org_one?: Maybe<TaxonomyOrg>;
  /** insert data into the table: "risksmart.test_frequency" */
  insert_test_frequency?: Maybe<TestFrequencyMutationResponse>;
  /** insert a single row into the table: "risksmart.test_frequency" */
  insert_test_frequency_one?: Maybe<TestFrequency>;
  /** insert data into the table: "risksmart.test_result" */
  insert_test_result?: Maybe<TestResultMutationResponse>;
  /** insert data into the table: "risksmart.test_result_audit" */
  insert_test_result_audit?: Maybe<TestResultAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.test_result_audit" */
  insert_test_result_audit_one?: Maybe<TestResultAudit>;
  /** insert a single row into the table: "risksmart.test_result" */
  insert_test_result_one?: Maybe<TestResult>;
  /** insert data into the table: "risksmart.third_party" */
  insert_third_party?: Maybe<ThirdPartyMutationResponse>;
  /** insert data into the table: "risksmart.third_party_audit" */
  insert_third_party_audit?: Maybe<ThirdPartyAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.third_party_audit" */
  insert_third_party_audit_one?: Maybe<ThirdPartyAudit>;
  /** insert a single row into the table: "risksmart.third_party" */
  insert_third_party_one?: Maybe<ThirdParty>;
  /** insert data into the table: "risksmart.third_party_response" */
  insert_third_party_response?: Maybe<ThirdPartyResponseMutationResponse>;
  /** insert a single row into the table: "risksmart.third_party_response" */
  insert_third_party_response_one?: Maybe<ThirdPartyResponse>;
  /** insert data into the table: "risksmart.third_party_response_status" */
  insert_third_party_response_status?: Maybe<ThirdPartyResponseStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.third_party_response_status" */
  insert_third_party_response_status_one?: Maybe<ThirdPartyResponseStatus>;
  /** insert data into the table: "risksmart.third_party_status" */
  insert_third_party_status?: Maybe<ThirdPartyStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.third_party_status" */
  insert_third_party_status_one?: Maybe<ThirdPartyStatus>;
  /** insert data into the table: "risksmart.third_party_type" */
  insert_third_party_type?: Maybe<ThirdPartyTypeMutationResponse>;
  /** insert a single row into the table: "risksmart.third_party_type" */
  insert_third_party_type_one?: Maybe<ThirdPartyType>;
  /** insert data into the table: "risksmart.user_group" */
  insert_user_group?: Maybe<UserGroupMutationResponse>;
  /** insert data into the table: "risksmart.user_group_audit" */
  insert_user_group_audit?: Maybe<UserGroupAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.user_group_audit" */
  insert_user_group_audit_one?: Maybe<UserGroupAudit>;
  /** insert a single row into the table: "risksmart.user_group" */
  insert_user_group_one?: Maybe<UserGroup>;
  /** insert data into the table: "risksmart.user_group_user" */
  insert_user_group_user?: Maybe<UserGroupUserMutationResponse>;
  /** insert data into the table: "risksmart.user_group_user_audit" */
  insert_user_group_user_audit?: Maybe<UserGroupUserAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.user_group_user_audit" */
  insert_user_group_user_audit_one?: Maybe<UserGroupUserAudit>;
  /** insert a single row into the table: "risksmart.user_group_user" */
  insert_user_group_user_one?: Maybe<UserGroupUser>;
  /** insert data into the table: "risksmart.user_search_preferences" */
  insert_user_search_preferences?: Maybe<UserSearchPreferencesMutationResponse>;
  /** insert data into the table: "risksmart.user_search_preferences_audit" */
  insert_user_search_preferences_audit?: Maybe<UserSearchPreferencesAuditMutationResponse>;
  /** insert a single row into the table: "risksmart.user_search_preferences_audit" */
  insert_user_search_preferences_audit_one?: Maybe<UserSearchPreferencesAudit>;
  /** insert a single row into the table: "risksmart.user_search_preferences" */
  insert_user_search_preferences_one?: Maybe<UserSearchPreferences>;
  /** insert data into the table: "auth.user_status" */
  insert_user_status?: Maybe<UserStatusMutationResponse>;
  /** insert a single row into the table: "auth.user_status" */
  insert_user_status_one?: Maybe<UserStatus>;
  /** insert data into the table: "risksmart.version_status" */
  insert_version_status?: Maybe<VersionStatusMutationResponse>;
  /** insert a single row into the table: "risksmart.version_status" */
  insert_version_status_one?: Maybe<VersionStatus>;
  linkItems?: Maybe<LinkItemsOutput>;
  overrideChangeRequest: OverrideChangeRequestOutput;
  publishQuestionnaireTemplateVersion: PublishQuestionnaireTemplateVersionOutput;
  recalculateAppetites: RecalculateAppetitesOutput;
  recalculateRiskScores: RecalculateRiskScoresOutput;
  /** execute VOLATILE function "risksmart.delete_organisation_users" which returns "auth.user" */
  risksmart_delete_organisation_users: Array<AuthUser>;
  unlinkItems?: Maybe<UnlinkItemsOutput>;
  updateAssessmentActivityWithLinkedItems?: Maybe<InsertAssessmentActivityOutput>;
  updateChildAcceptance: UpdateAcceptanceOutput;
  updateChildAction?: Maybe<UpdateChildActionOutput>;
  updateChildControl?: Maybe<UpdateChildControlOutput>;
  updateChildDashboard?: Maybe<InsertChildDashboardOutput>;
  updateChildIssueAssessment?: Maybe<UpdateIssueAssessmentOutput>;
  updateChildRisk: UpdateChildRiskOutput;
  updateChildRiskAssessmentResult?: Maybe<UpdateChildRiskAssessmentResultOutput>;
  updateDocumentVersion?: Maybe<UpdateDocumentVersionOutput>;
  updateInternalAudit?: Maybe<UpdateInternalAuditOutput>;
  updateNotificationPreferences: UpdateNotificationPreferencesOutput;
  /** update data of the table: "risksmart.acceptance" */
  update_acceptance?: Maybe<AcceptanceMutationResponse>;
  /** update data of the table: "risksmart.acceptance_audit" */
  update_acceptance_audit?: Maybe<AcceptanceAuditMutationResponse>;
  /** update single row of the table: "risksmart.acceptance_audit" */
  update_acceptance_audit_by_pk?: Maybe<AcceptanceAudit>;
  /** update multiples rows of table: "risksmart.acceptance_audit" */
  update_acceptance_audit_many?: Maybe<Array<Maybe<AcceptanceAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.acceptance" */
  update_acceptance_by_pk?: Maybe<Acceptance>;
  /** update multiples rows of table: "risksmart.acceptance" */
  update_acceptance_many?: Maybe<Array<Maybe<AcceptanceMutationResponse>>>;
  /** update data of the table: "risksmart.acceptance_parent" */
  update_acceptance_parent?: Maybe<AcceptanceParentMutationResponse>;
  /** update data of the table: "risksmart.acceptance_parent_audit" */
  update_acceptance_parent_audit?: Maybe<AcceptanceParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.acceptance_parent_audit" */
  update_acceptance_parent_audit_by_pk?: Maybe<AcceptanceParentAudit>;
  /** update multiples rows of table: "risksmart.acceptance_parent_audit" */
  update_acceptance_parent_audit_many?: Maybe<Array<Maybe<AcceptanceParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.acceptance_parent" */
  update_acceptance_parent_by_pk?: Maybe<AcceptanceParent>;
  /** update multiples rows of table: "risksmart.acceptance_parent" */
  update_acceptance_parent_many?: Maybe<Array<Maybe<AcceptanceParentMutationResponse>>>;
  /** update data of the table: "risksmart.acceptance_status" */
  update_acceptance_status?: Maybe<AcceptanceStatusMutationResponse>;
  /** update single row of the table: "risksmart.acceptance_status" */
  update_acceptance_status_by_pk?: Maybe<AcceptanceStatus>;
  /** update multiples rows of table: "risksmart.acceptance_status" */
  update_acceptance_status_many?: Maybe<Array<Maybe<AcceptanceStatusMutationResponse>>>;
  /** update data of the table: "risksmart.access_type" */
  update_access_type?: Maybe<AccessTypeMutationResponse>;
  /** update single row of the table: "risksmart.access_type" */
  update_access_type_by_pk?: Maybe<AccessType>;
  /** update multiples rows of table: "risksmart.access_type" */
  update_access_type_many?: Maybe<Array<Maybe<AccessTypeMutationResponse>>>;
  /** update data of the table: "risksmart.action" */
  update_action?: Maybe<ActionMutationResponse>;
  /** update data of the table: "risksmart.action_audit" */
  update_action_audit?: Maybe<ActionAuditMutationResponse>;
  /** update single row of the table: "risksmart.action_audit" */
  update_action_audit_by_pk?: Maybe<ActionAudit>;
  /** update multiples rows of table: "risksmart.action_audit" */
  update_action_audit_many?: Maybe<Array<Maybe<ActionAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.action" */
  update_action_by_pk?: Maybe<Action>;
  /** update multiples rows of table: "risksmart.action" */
  update_action_many?: Maybe<Array<Maybe<ActionMutationResponse>>>;
  /** update data of the table: "risksmart.action_parent" */
  update_action_parent?: Maybe<ActionParentMutationResponse>;
  /** update data of the table: "risksmart.action_parent_audit" */
  update_action_parent_audit?: Maybe<ActionParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.action_parent_audit" */
  update_action_parent_audit_by_pk?: Maybe<ActionParentAudit>;
  /** update multiples rows of table: "risksmart.action_parent_audit" */
  update_action_parent_audit_many?: Maybe<Array<Maybe<ActionParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.action_parent" */
  update_action_parent_by_pk?: Maybe<ActionParent>;
  /** update multiples rows of table: "risksmart.action_parent" */
  update_action_parent_many?: Maybe<Array<Maybe<ActionParentMutationResponse>>>;
  /** update data of the table: "risksmart.action_status" */
  update_action_status?: Maybe<ActionStatusMutationResponse>;
  /** update single row of the table: "risksmart.action_status" */
  update_action_status_by_pk?: Maybe<ActionStatus>;
  /** update multiples rows of table: "risksmart.action_status" */
  update_action_status_many?: Maybe<Array<Maybe<ActionStatusMutationResponse>>>;
  /** update data of the table: "risksmart.action_update" */
  update_action_update?: Maybe<ActionUpdateMutationResponse>;
  /** update data of the table: "risksmart.action_update_audit" */
  update_action_update_audit?: Maybe<ActionUpdateAuditMutationResponse>;
  /** update single row of the table: "risksmart.action_update_audit" */
  update_action_update_audit_by_pk?: Maybe<ActionUpdateAudit>;
  /** update multiples rows of table: "risksmart.action_update_audit" */
  update_action_update_audit_many?: Maybe<Array<Maybe<ActionUpdateAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.action_update" */
  update_action_update_by_pk?: Maybe<ActionUpdate>;
  /** update multiples rows of table: "risksmart.action_update" */
  update_action_update_many?: Maybe<Array<Maybe<ActionUpdateMutationResponse>>>;
  /** update data of the table: "risksmart.aggregation_org" */
  update_aggregation_org?: Maybe<AggregationOrgMutationResponse>;
  /** update single row of the table: "risksmart.aggregation_org" */
  update_aggregation_org_by_pk?: Maybe<AggregationOrg>;
  /** update multiples rows of table: "risksmart.aggregation_org" */
  update_aggregation_org_many?: Maybe<Array<Maybe<AggregationOrgMutationResponse>>>;
  /** update data of the table: "risksmart.appetite" */
  update_appetite?: Maybe<AppetiteMutationResponse>;
  /** update data of the table: "risksmart.appetite_audit" */
  update_appetite_audit?: Maybe<AppetiteAuditMutationResponse>;
  /** update single row of the table: "risksmart.appetite_audit" */
  update_appetite_audit_by_pk?: Maybe<AppetiteAudit>;
  /** update multiples rows of table: "risksmart.appetite_audit" */
  update_appetite_audit_many?: Maybe<Array<Maybe<AppetiteAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.appetite" */
  update_appetite_by_pk?: Maybe<Appetite>;
  /** update multiples rows of table: "risksmart.appetite" */
  update_appetite_many?: Maybe<Array<Maybe<AppetiteMutationResponse>>>;
  /** update data of the table: "risksmart.appetite_model" */
  update_appetite_model?: Maybe<AppetiteModelMutationResponse>;
  /** update single row of the table: "risksmart.appetite_model" */
  update_appetite_model_by_pk?: Maybe<AppetiteModel>;
  /** update multiples rows of table: "risksmart.appetite_model" */
  update_appetite_model_many?: Maybe<Array<Maybe<AppetiteModelMutationResponse>>>;
  /** update data of the table: "risksmart.appetite_parent" */
  update_appetite_parent?: Maybe<AppetiteParentMutationResponse>;
  /** update data of the table: "risksmart.appetite_parent_audit" */
  update_appetite_parent_audit?: Maybe<AppetiteParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.appetite_parent_audit" */
  update_appetite_parent_audit_by_pk?: Maybe<AppetiteParentAudit>;
  /** update multiples rows of table: "risksmart.appetite_parent_audit" */
  update_appetite_parent_audit_many?: Maybe<Array<Maybe<AppetiteParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.appetite_parent" */
  update_appetite_parent_by_pk?: Maybe<AppetiteParent>;
  /** update multiples rows of table: "risksmart.appetite_parent" */
  update_appetite_parent_many?: Maybe<Array<Maybe<AppetiteParentMutationResponse>>>;
  /** update data of the table: "risksmart.appetite_type" */
  update_appetite_type?: Maybe<AppetiteTypeMutationResponse>;
  /** update single row of the table: "risksmart.appetite_type" */
  update_appetite_type_by_pk?: Maybe<AppetiteType>;
  /** update multiples rows of table: "risksmart.appetite_type" */
  update_appetite_type_many?: Maybe<Array<Maybe<AppetiteTypeMutationResponse>>>;
  /** update data of the table: "risksmart.approval" */
  update_approval?: Maybe<ApprovalMutationResponse>;
  /** update data of the table: "risksmart.approval_audit" */
  update_approval_audit?: Maybe<ApprovalAuditMutationResponse>;
  /** update single row of the table: "risksmart.approval_audit" */
  update_approval_audit_by_pk?: Maybe<ApprovalAudit>;
  /** update multiples rows of table: "risksmart.approval_audit" */
  update_approval_audit_many?: Maybe<Array<Maybe<ApprovalAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.approval" */
  update_approval_by_pk?: Maybe<Approval>;
  /** update data of the table: "risksmart.approval_in_flight_edit_rule" */
  update_approval_in_flight_edit_rule?: Maybe<ApprovalInFlightEditRuleMutationResponse>;
  /** update single row of the table: "risksmart.approval_in_flight_edit_rule" */
  update_approval_in_flight_edit_rule_by_pk?: Maybe<ApprovalInFlightEditRule>;
  /** update multiples rows of table: "risksmart.approval_in_flight_edit_rule" */
  update_approval_in_flight_edit_rule_many?: Maybe<Array<Maybe<ApprovalInFlightEditRuleMutationResponse>>>;
  /** update data of the table: "risksmart.approval_level" */
  update_approval_level?: Maybe<ApprovalLevelMutationResponse>;
  /** update data of the table: "risksmart.approval_level_audit" */
  update_approval_level_audit?: Maybe<ApprovalLevelAuditMutationResponse>;
  /** update single row of the table: "risksmart.approval_level_audit" */
  update_approval_level_audit_by_pk?: Maybe<ApprovalLevelAudit>;
  /** update multiples rows of table: "risksmart.approval_level_audit" */
  update_approval_level_audit_many?: Maybe<Array<Maybe<ApprovalLevelAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.approval_level" */
  update_approval_level_by_pk?: Maybe<ApprovalLevel>;
  /** update multiples rows of table: "risksmart.approval_level" */
  update_approval_level_many?: Maybe<Array<Maybe<ApprovalLevelMutationResponse>>>;
  /** update multiples rows of table: "risksmart.approval" */
  update_approval_many?: Maybe<Array<Maybe<ApprovalMutationResponse>>>;
  /** update data of the table: "risksmart.approval_rule_type" */
  update_approval_rule_type?: Maybe<ApprovalRuleTypeMutationResponse>;
  /** update single row of the table: "risksmart.approval_rule_type" */
  update_approval_rule_type_by_pk?: Maybe<ApprovalRuleType>;
  /** update multiples rows of table: "risksmart.approval_rule_type" */
  update_approval_rule_type_many?: Maybe<Array<Maybe<ApprovalRuleTypeMutationResponse>>>;
  /** update data of the table: "risksmart.approval_status" */
  update_approval_status?: Maybe<ApprovalStatusMutationResponse>;
  /** update single row of the table: "risksmart.approval_status" */
  update_approval_status_by_pk?: Maybe<ApprovalStatus>;
  /** update multiples rows of table: "risksmart.approval_status" */
  update_approval_status_many?: Maybe<Array<Maybe<ApprovalStatusMutationResponse>>>;
  /** update data of the table: "risksmart.approver" */
  update_approver?: Maybe<ApproverMutationResponse>;
  /** update data of the table: "risksmart.approver_audit" */
  update_approver_audit?: Maybe<ApproverAuditMutationResponse>;
  /** update single row of the table: "risksmart.approver_audit" */
  update_approver_audit_by_pk?: Maybe<ApproverAudit>;
  /** update multiples rows of table: "risksmart.approver_audit" */
  update_approver_audit_many?: Maybe<Array<Maybe<ApproverAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.approver" */
  update_approver_by_pk?: Maybe<Approver>;
  /** update multiples rows of table: "risksmart.approver" */
  update_approver_many?: Maybe<Array<Maybe<ApproverMutationResponse>>>;
  /** update data of the table: "risksmart.approver_response" */
  update_approver_response?: Maybe<ApproverResponseMutationResponse>;
  /** update single row of the table: "risksmart.approver_response" */
  update_approver_response_by_pk?: Maybe<ApproverResponse>;
  /** update multiples rows of table: "risksmart.approver_response" */
  update_approver_response_many?: Maybe<Array<Maybe<ApproverResponseMutationResponse>>>;
  /** update data of the table: "risksmart.assessment" */
  update_assessment?: Maybe<AssessmentMutationResponse>;
  /** update data of the table: "risksmart.assessment_activity" */
  update_assessment_activity?: Maybe<AssessmentActivityMutationResponse>;
  /** update data of the table: "risksmart.assessment_activity_audit" */
  update_assessment_activity_audit?: Maybe<AssessmentActivityAuditMutationResponse>;
  /** update single row of the table: "risksmart.assessment_activity_audit" */
  update_assessment_activity_audit_by_pk?: Maybe<AssessmentActivityAudit>;
  /** update multiples rows of table: "risksmart.assessment_activity_audit" */
  update_assessment_activity_audit_many?: Maybe<Array<Maybe<AssessmentActivityAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.assessment_activity" */
  update_assessment_activity_by_pk?: Maybe<AssessmentActivity>;
  /** update multiples rows of table: "risksmart.assessment_activity" */
  update_assessment_activity_many?: Maybe<Array<Maybe<AssessmentActivityMutationResponse>>>;
  /** update data of the table: "risksmart.assessment_activity_status" */
  update_assessment_activity_status?: Maybe<AssessmentActivityStatusMutationResponse>;
  /** update single row of the table: "risksmart.assessment_activity_status" */
  update_assessment_activity_status_by_pk?: Maybe<AssessmentActivityStatus>;
  /** update multiples rows of table: "risksmart.assessment_activity_status" */
  update_assessment_activity_status_many?: Maybe<Array<Maybe<AssessmentActivityStatusMutationResponse>>>;
  /** update data of the table: "risksmart.assessment_activity_type" */
  update_assessment_activity_type?: Maybe<AssessmentActivityTypeMutationResponse>;
  /** update single row of the table: "risksmart.assessment_activity_type" */
  update_assessment_activity_type_by_pk?: Maybe<AssessmentActivityType>;
  /** update multiples rows of table: "risksmart.assessment_activity_type" */
  update_assessment_activity_type_many?: Maybe<Array<Maybe<AssessmentActivityTypeMutationResponse>>>;
  /** update data of the table: "risksmart.assessment_audit" */
  update_assessment_audit?: Maybe<AssessmentAuditMutationResponse>;
  /** update single row of the table: "risksmart.assessment_audit" */
  update_assessment_audit_by_pk?: Maybe<AssessmentAudit>;
  /** update multiples rows of table: "risksmart.assessment_audit" */
  update_assessment_audit_many?: Maybe<Array<Maybe<AssessmentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.assessment" */
  update_assessment_by_pk?: Maybe<Assessment>;
  /** update multiples rows of table: "risksmart.assessment" */
  update_assessment_many?: Maybe<Array<Maybe<AssessmentMutationResponse>>>;
  /** update data of the table: "risksmart.assessment_result_parent" */
  update_assessment_result_parent?: Maybe<AssessmentResultParentMutationResponse>;
  /** update data of the table: "risksmart.assessment_result_parent_audit" */
  update_assessment_result_parent_audit?: Maybe<AssessmentResultParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.assessment_result_parent_audit" */
  update_assessment_result_parent_audit_by_pk?: Maybe<AssessmentResultParentAudit>;
  /** update multiples rows of table: "risksmart.assessment_result_parent_audit" */
  update_assessment_result_parent_audit_many?: Maybe<Array<Maybe<AssessmentResultParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.assessment_result_parent" */
  update_assessment_result_parent_by_pk?: Maybe<AssessmentResultParent>;
  /** update multiples rows of table: "risksmart.assessment_result_parent" */
  update_assessment_result_parent_many?: Maybe<Array<Maybe<AssessmentResultParentMutationResponse>>>;
  /** update data of the table: "risksmart.assessment_status" */
  update_assessment_status?: Maybe<AssessmentStatusMutationResponse>;
  /** update single row of the table: "risksmart.assessment_status" */
  update_assessment_status_by_pk?: Maybe<AssessmentStatus>;
  /** update multiples rows of table: "risksmart.assessment_status" */
  update_assessment_status_many?: Maybe<Array<Maybe<AssessmentStatusMutationResponse>>>;
  /** update data of the table: "risksmart.attestation_config" */
  update_attestation_config?: Maybe<AttestationConfigMutationResponse>;
  /** update single row of the table: "risksmart.attestation_config" */
  update_attestation_config_by_pk?: Maybe<AttestationConfig>;
  /** update multiples rows of table: "risksmart.attestation_config" */
  update_attestation_config_many?: Maybe<Array<Maybe<AttestationConfigMutationResponse>>>;
  /** update data of the table: "risksmart.attestation_group" */
  update_attestation_group?: Maybe<AttestationGroupMutationResponse>;
  /** update single row of the table: "risksmart.attestation_group" */
  update_attestation_group_by_pk?: Maybe<AttestationGroup>;
  /** update multiples rows of table: "risksmart.attestation_group" */
  update_attestation_group_many?: Maybe<Array<Maybe<AttestationGroupMutationResponse>>>;
  /** update data of the table: "risksmart.attestation_record" */
  update_attestation_record?: Maybe<AttestationRecordMutationResponse>;
  /** update single row of the table: "risksmart.attestation_record" */
  update_attestation_record_by_pk?: Maybe<AttestationRecord>;
  /** update multiples rows of table: "risksmart.attestation_record" */
  update_attestation_record_many?: Maybe<Array<Maybe<AttestationRecordMutationResponse>>>;
  /** update data of the table: "risksmart.attestation_record_status" */
  update_attestation_record_status?: Maybe<AttestationRecordStatusMutationResponse>;
  /** update single row of the table: "risksmart.attestation_record_status" */
  update_attestation_record_status_by_pk?: Maybe<AttestationRecordStatus>;
  /** update multiples rows of table: "risksmart.attestation_record_status" */
  update_attestation_record_status_many?: Maybe<Array<Maybe<AttestationRecordStatusMutationResponse>>>;
  /** update data of the table: "auth.organisation" */
  update_auth_organisation?: Maybe<AuthOrganisationMutationResponse>;
  /** update data of the table: "auth.organisation_audit" */
  update_auth_organisation_audit?: Maybe<AuthOrganisationAuditMutationResponse>;
  /** update single row of the table: "auth.organisation_audit" */
  update_auth_organisation_audit_by_pk?: Maybe<AuthOrganisationAudit>;
  /** update multiples rows of table: "auth.organisation_audit" */
  update_auth_organisation_audit_many?: Maybe<Array<Maybe<AuthOrganisationAuditMutationResponse>>>;
  /** update single row of the table: "auth.organisation" */
  update_auth_organisation_by_pk?: Maybe<AuthOrganisation>;
  /** update multiples rows of table: "auth.organisation" */
  update_auth_organisation_many?: Maybe<Array<Maybe<AuthOrganisationMutationResponse>>>;
  /** update data of the table: "auth.organisationuser" */
  update_auth_organisationuser?: Maybe<AuthOrganisationuserMutationResponse>;
  /** update data of the table: "auth.organisationuser_audit" */
  update_auth_organisationuser_audit?: Maybe<AuthOrganisationuserAuditMutationResponse>;
  /** update single row of the table: "auth.organisationuser_audit" */
  update_auth_organisationuser_audit_by_pk?: Maybe<AuthOrganisationuserAudit>;
  /** update multiples rows of table: "auth.organisationuser_audit" */
  update_auth_organisationuser_audit_many?: Maybe<Array<Maybe<AuthOrganisationuserAuditMutationResponse>>>;
  /** update single row of the table: "auth.organisationuser" */
  update_auth_organisationuser_by_pk?: Maybe<AuthOrganisationuser>;
  /** update multiples rows of table: "auth.organisationuser" */
  update_auth_organisationuser_many?: Maybe<Array<Maybe<AuthOrganisationuserMutationResponse>>>;
  /** update data of the table: "auth.user" */
  update_auth_user?: Maybe<AuthUserMutationResponse>;
  /** update data of the table: "auth.user_activity_audit" */
  update_auth_user_activity_audit?: Maybe<AuthUserActivityAuditMutationResponse>;
  /** update single row of the table: "auth.user_activity_audit" */
  update_auth_user_activity_audit_by_pk?: Maybe<AuthUserActivityAudit>;
  /** update multiples rows of table: "auth.user_activity_audit" */
  update_auth_user_activity_audit_many?: Maybe<Array<Maybe<AuthUserActivityAuditMutationResponse>>>;
  /** update data of the table: "auth.user_audit" */
  update_auth_user_audit?: Maybe<AuthUserAuditMutationResponse>;
  /** update single row of the table: "auth.user_audit" */
  update_auth_user_audit_by_pk?: Maybe<AuthUserAudit>;
  /** update multiples rows of table: "auth.user_audit" */
  update_auth_user_audit_many?: Maybe<Array<Maybe<AuthUserAuditMutationResponse>>>;
  /** update single row of the table: "auth.user" */
  update_auth_user_by_pk?: Maybe<AuthUser>;
  /** update multiples rows of table: "auth.user" */
  update_auth_user_many?: Maybe<Array<Maybe<AuthUserMutationResponse>>>;
  /** update data of the table: "risksmart.business_area" */
  update_business_area?: Maybe<BusinessAreaMutationResponse>;
  /** update single row of the table: "risksmart.business_area" */
  update_business_area_by_pk?: Maybe<BusinessArea>;
  /** update multiples rows of table: "risksmart.business_area" */
  update_business_area_many?: Maybe<Array<Maybe<BusinessAreaMutationResponse>>>;
  /** update data of the table: "risksmart.cause" */
  update_cause?: Maybe<CauseMutationResponse>;
  /** update data of the table: "risksmart.cause_audit" */
  update_cause_audit?: Maybe<CauseAuditMutationResponse>;
  /** update single row of the table: "risksmart.cause_audit" */
  update_cause_audit_by_pk?: Maybe<CauseAudit>;
  /** update multiples rows of table: "risksmart.cause_audit" */
  update_cause_audit_many?: Maybe<Array<Maybe<CauseAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.cause" */
  update_cause_by_pk?: Maybe<Cause>;
  /** update multiples rows of table: "risksmart.cause" */
  update_cause_many?: Maybe<Array<Maybe<CauseMutationResponse>>>;
  /** update data of the table: "risksmart.change_request" */
  update_change_request?: Maybe<ChangeRequestMutationResponse>;
  /** update data of the table: "risksmart.change_request_audit" */
  update_change_request_audit?: Maybe<ChangeRequestAuditMutationResponse>;
  /** update single row of the table: "risksmart.change_request_audit" */
  update_change_request_audit_by_pk?: Maybe<ChangeRequestAudit>;
  /** update multiples rows of table: "risksmart.change_request_audit" */
  update_change_request_audit_many?: Maybe<Array<Maybe<ChangeRequestAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.change_request" */
  update_change_request_by_pk?: Maybe<ChangeRequest>;
  /** update data of the table: "risksmart.change_request_contributor" */
  update_change_request_contributor?: Maybe<ChangeRequestContributorMutationResponse>;
  /** update single row of the table: "risksmart.change_request_contributor" */
  update_change_request_contributor_by_pk?: Maybe<ChangeRequestContributor>;
  /** update multiples rows of table: "risksmart.change_request_contributor" */
  update_change_request_contributor_many?: Maybe<Array<Maybe<ChangeRequestContributorMutationResponse>>>;
  /** update multiples rows of table: "risksmart.change_request" */
  update_change_request_many?: Maybe<Array<Maybe<ChangeRequestMutationResponse>>>;
  /** update data of the table: "risksmart.comment" */
  update_comment?: Maybe<CommentMutationResponse>;
  /** update data of the table: "risksmart.comment_audit" */
  update_comment_audit?: Maybe<CommentAuditMutationResponse>;
  /** update single row of the table: "risksmart.comment_audit" */
  update_comment_audit_by_pk?: Maybe<CommentAudit>;
  /** update multiples rows of table: "risksmart.comment_audit" */
  update_comment_audit_many?: Maybe<Array<Maybe<CommentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.comment" */
  update_comment_by_pk?: Maybe<Comment>;
  /** update multiples rows of table: "risksmart.comment" */
  update_comment_many?: Maybe<Array<Maybe<CommentMutationResponse>>>;
  /** update data of the table: "risksmart.compliance_monitoring_assessment" */
  update_compliance_monitoring_assessment?: Maybe<ComplianceMonitoringAssessmentMutationResponse>;
  /** update single row of the table: "risksmart.compliance_monitoring_assessment" */
  update_compliance_monitoring_assessment_by_pk?: Maybe<ComplianceMonitoringAssessment>;
  /** update multiples rows of table: "risksmart.compliance_monitoring_assessment" */
  update_compliance_monitoring_assessment_many?: Maybe<Array<Maybe<ComplianceMonitoringAssessmentMutationResponse>>>;
  /** update data of the table: "config.env" */
  update_config_env?: Maybe<ConfigEnvMutationResponse>;
  /** update single row of the table: "config.env" */
  update_config_env_by_pk?: Maybe<ConfigEnv>;
  /** update multiples rows of table: "config.env" */
  update_config_env_many?: Maybe<Array<Maybe<ConfigEnvMutationResponse>>>;
  /** update data of the table: "risksmart.consequence" */
  update_consequence?: Maybe<ConsequenceMutationResponse>;
  /** update data of the table: "risksmart.consequence_audit" */
  update_consequence_audit?: Maybe<ConsequenceAuditMutationResponse>;
  /** update single row of the table: "risksmart.consequence_audit" */
  update_consequence_audit_by_pk?: Maybe<ConsequenceAudit>;
  /** update multiples rows of table: "risksmart.consequence_audit" */
  update_consequence_audit_many?: Maybe<Array<Maybe<ConsequenceAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.consequence" */
  update_consequence_by_pk?: Maybe<Consequence>;
  /** update multiples rows of table: "risksmart.consequence" */
  update_consequence_many?: Maybe<Array<Maybe<ConsequenceMutationResponse>>>;
  /** update data of the table: "risksmart.consequence_type" */
  update_consequence_type?: Maybe<ConsequenceTypeMutationResponse>;
  /** update single row of the table: "risksmart.consequence_type" */
  update_consequence_type_by_pk?: Maybe<ConsequenceType>;
  /** update multiples rows of table: "risksmart.consequence_type" */
  update_consequence_type_many?: Maybe<Array<Maybe<ConsequenceTypeMutationResponse>>>;
  /** update data of the table: "risksmart.contributor" */
  update_contributor?: Maybe<ContributorMutationResponse>;
  /** update data of the table: "risksmart.contributor_audit" */
  update_contributor_audit?: Maybe<ContributorAuditMutationResponse>;
  /** update single row of the table: "risksmart.contributor_audit" */
  update_contributor_audit_by_pk?: Maybe<ContributorAudit>;
  /** update multiples rows of table: "risksmart.contributor_audit" */
  update_contributor_audit_many?: Maybe<Array<Maybe<ContributorAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.contributor" */
  update_contributor_by_pk?: Maybe<Contributor>;
  /** update data of the table: "risksmart.contributor_group" */
  update_contributor_group?: Maybe<ContributorGroupMutationResponse>;
  /** update data of the table: "risksmart.contributor_group_audit" */
  update_contributor_group_audit?: Maybe<ContributorGroupAuditMutationResponse>;
  /** update single row of the table: "risksmart.contributor_group_audit" */
  update_contributor_group_audit_by_pk?: Maybe<ContributorGroupAudit>;
  /** update multiples rows of table: "risksmart.contributor_group_audit" */
  update_contributor_group_audit_many?: Maybe<Array<Maybe<ContributorGroupAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.contributor_group" */
  update_contributor_group_by_pk?: Maybe<ContributorGroup>;
  /** update multiples rows of table: "risksmart.contributor_group" */
  update_contributor_group_many?: Maybe<Array<Maybe<ContributorGroupMutationResponse>>>;
  /** update multiples rows of table: "risksmart.contributor" */
  update_contributor_many?: Maybe<Array<Maybe<ContributorMutationResponse>>>;
  /** update data of the table: "risksmart.contributor_type" */
  update_contributor_type?: Maybe<ContributorTypeMutationResponse>;
  /** update single row of the table: "risksmart.contributor_type" */
  update_contributor_type_by_pk?: Maybe<ContributorType>;
  /** update multiples rows of table: "risksmart.contributor_type" */
  update_contributor_type_many?: Maybe<Array<Maybe<ContributorTypeMutationResponse>>>;
  /** update data of the table: "risksmart.control" */
  update_control?: Maybe<ControlMutationResponse>;
  /** update data of the table: "risksmart.control_audit" */
  update_control_audit?: Maybe<ControlAuditMutationResponse>;
  /** update single row of the table: "risksmart.control_audit" */
  update_control_audit_by_pk?: Maybe<ControlAudit>;
  /** update multiples rows of table: "risksmart.control_audit" */
  update_control_audit_many?: Maybe<Array<Maybe<ControlAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.control" */
  update_control_by_pk?: Maybe<Control>;
  /** update data of the table: "risksmart.control_group" */
  update_control_group?: Maybe<ControlGroupMutationResponse>;
  /** update data of the table: "risksmart.control_group_audit" */
  update_control_group_audit?: Maybe<ControlGroupAuditMutationResponse>;
  /** update single row of the table: "risksmart.control_group_audit" */
  update_control_group_audit_by_pk?: Maybe<ControlGroupAudit>;
  /** update multiples rows of table: "risksmart.control_group_audit" */
  update_control_group_audit_many?: Maybe<Array<Maybe<ControlGroupAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.control_group" */
  update_control_group_by_pk?: Maybe<ControlGroup>;
  /** update multiples rows of table: "risksmart.control_group" */
  update_control_group_many?: Maybe<Array<Maybe<ControlGroupMutationResponse>>>;
  /** update multiples rows of table: "risksmart.control" */
  update_control_many?: Maybe<Array<Maybe<ControlMutationResponse>>>;
  /** update data of the table: "risksmart.control_parent" */
  update_control_parent?: Maybe<ControlParentMutationResponse>;
  /** update data of the table: "risksmart.control_parent_audit" */
  update_control_parent_audit?: Maybe<ControlParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.control_parent_audit" */
  update_control_parent_audit_by_pk?: Maybe<ControlParentAudit>;
  /** update multiples rows of table: "risksmart.control_parent_audit" */
  update_control_parent_audit_many?: Maybe<Array<Maybe<ControlParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.control_parent" */
  update_control_parent_by_pk?: Maybe<ControlParent>;
  /** update multiples rows of table: "risksmart.control_parent" */
  update_control_parent_many?: Maybe<Array<Maybe<ControlParentMutationResponse>>>;
  /** update data of the table: "risksmart.control_test_frequency" */
  update_control_test_frequency?: Maybe<ControlTestFrequencyMutationResponse>;
  /** update single row of the table: "risksmart.control_test_frequency" */
  update_control_test_frequency_by_pk?: Maybe<ControlTestFrequency>;
  /** update multiples rows of table: "risksmart.control_test_frequency" */
  update_control_test_frequency_many?: Maybe<Array<Maybe<ControlTestFrequencyMutationResponse>>>;
  /** update data of the table: "risksmart.control_type" */
  update_control_type?: Maybe<ControlTypeMutationResponse>;
  /** update single row of the table: "risksmart.control_type" */
  update_control_type_by_pk?: Maybe<ControlType>;
  /** update multiples rows of table: "risksmart.control_type" */
  update_control_type_many?: Maybe<Array<Maybe<ControlTypeMutationResponse>>>;
  /** update data of the table: "risksmart.conversation" */
  update_conversation?: Maybe<ConversationMutationResponse>;
  /** update data of the table: "risksmart.conversation_audit" */
  update_conversation_audit?: Maybe<ConversationAuditMutationResponse>;
  /** update single row of the table: "risksmart.conversation_audit" */
  update_conversation_audit_by_pk?: Maybe<ConversationAudit>;
  /** update multiples rows of table: "risksmart.conversation_audit" */
  update_conversation_audit_many?: Maybe<Array<Maybe<ConversationAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.conversation" */
  update_conversation_by_pk?: Maybe<Conversation>;
  /** update multiples rows of table: "risksmart.conversation" */
  update_conversation_many?: Maybe<Array<Maybe<ConversationMutationResponse>>>;
  /** update data of the table: "risksmart.cost_type" */
  update_cost_type?: Maybe<CostTypeMutationResponse>;
  /** update single row of the table: "risksmart.cost_type" */
  update_cost_type_by_pk?: Maybe<CostType>;
  /** update multiples rows of table: "risksmart.cost_type" */
  update_cost_type_many?: Maybe<Array<Maybe<CostTypeMutationResponse>>>;
  /** update data of the table: "risksmart.custom_attribute_schema" */
  update_custom_attribute_schema?: Maybe<CustomAttributeSchemaMutationResponse>;
  /** update data of the table: "risksmart.custom_attribute_schema_audit" */
  update_custom_attribute_schema_audit?: Maybe<CustomAttributeSchemaAuditMutationResponse>;
  /** update single row of the table: "risksmart.custom_attribute_schema_audit" */
  update_custom_attribute_schema_audit_by_pk?: Maybe<CustomAttributeSchemaAudit>;
  /** update multiples rows of table: "risksmart.custom_attribute_schema_audit" */
  update_custom_attribute_schema_audit_many?: Maybe<Array<Maybe<CustomAttributeSchemaAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.custom_attribute_schema" */
  update_custom_attribute_schema_by_pk?: Maybe<CustomAttributeSchema>;
  /** update multiples rows of table: "risksmart.custom_attribute_schema" */
  update_custom_attribute_schema_many?: Maybe<Array<Maybe<CustomAttributeSchemaMutationResponse>>>;
  /** update data of the table: "risksmart.custom_ribbon" */
  update_custom_ribbon?: Maybe<CustomRibbonMutationResponse>;
  /** update data of the table: "risksmart.custom_ribbon_audit" */
  update_custom_ribbon_audit?: Maybe<CustomRibbonAuditMutationResponse>;
  /** update single row of the table: "risksmart.custom_ribbon_audit" */
  update_custom_ribbon_audit_by_pk?: Maybe<CustomRibbonAudit>;
  /** update multiples rows of table: "risksmart.custom_ribbon_audit" */
  update_custom_ribbon_audit_many?: Maybe<Array<Maybe<CustomRibbonAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.custom_ribbon" */
  update_custom_ribbon_by_pk?: Maybe<CustomRibbon>;
  /** update multiples rows of table: "risksmart.custom_ribbon" */
  update_custom_ribbon_many?: Maybe<Array<Maybe<CustomRibbonMutationResponse>>>;
  /** update data of the table: "risksmart.dashboard" */
  update_dashboard?: Maybe<DashboardMutationResponse>;
  /** update data of the table: "risksmart.dashboard_audit" */
  update_dashboard_audit?: Maybe<DashboardAuditMutationResponse>;
  /** update single row of the table: "risksmart.dashboard_audit" */
  update_dashboard_audit_by_pk?: Maybe<DashboardAudit>;
  /** update multiples rows of table: "risksmart.dashboard_audit" */
  update_dashboard_audit_many?: Maybe<Array<Maybe<DashboardAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.dashboard" */
  update_dashboard_by_pk?: Maybe<Dashboard>;
  /** update multiples rows of table: "risksmart.dashboard" */
  update_dashboard_many?: Maybe<Array<Maybe<DashboardMutationResponse>>>;
  /** update data of the table: "risksmart.dashboard_sharing_type" */
  update_dashboard_sharing_type?: Maybe<DashboardSharingTypeMutationResponse>;
  /** update single row of the table: "risksmart.dashboard_sharing_type" */
  update_dashboard_sharing_type_by_pk?: Maybe<DashboardSharingType>;
  /** update multiples rows of table: "risksmart.dashboard_sharing_type" */
  update_dashboard_sharing_type_many?: Maybe<Array<Maybe<DashboardSharingTypeMutationResponse>>>;
  /** update data of the table: "risksmart.data_import" */
  update_data_import?: Maybe<DataImportMutationResponse>;
  /** update data of the table: "risksmart.data_import_audit" */
  update_data_import_audit?: Maybe<DataImportAuditMutationResponse>;
  /** update single row of the table: "risksmart.data_import_audit" */
  update_data_import_audit_by_pk?: Maybe<DataImportAudit>;
  /** update multiples rows of table: "risksmart.data_import_audit" */
  update_data_import_audit_many?: Maybe<Array<Maybe<DataImportAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.data_import" */
  update_data_import_by_pk?: Maybe<DataImport>;
  /** update data of the table: "risksmart.data_import_error" */
  update_data_import_error?: Maybe<DataImportErrorMutationResponse>;
  /** update data of the table: "risksmart.data_import_error_audit" */
  update_data_import_error_audit?: Maybe<DataImportErrorAuditMutationResponse>;
  /** update single row of the table: "risksmart.data_import_error_audit" */
  update_data_import_error_audit_by_pk?: Maybe<DataImportErrorAudit>;
  /** update multiples rows of table: "risksmart.data_import_error_audit" */
  update_data_import_error_audit_many?: Maybe<Array<Maybe<DataImportErrorAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.data_import_error" */
  update_data_import_error_by_pk?: Maybe<DataImportError>;
  /** update multiples rows of table: "risksmart.data_import_error" */
  update_data_import_error_many?: Maybe<Array<Maybe<DataImportErrorMutationResponse>>>;
  /** update multiples rows of table: "risksmart.data_import" */
  update_data_import_many?: Maybe<Array<Maybe<DataImportMutationResponse>>>;
  /** update data of the table: "risksmart.data_import_status" */
  update_data_import_status?: Maybe<DataImportStatusMutationResponse>;
  /** update single row of the table: "risksmart.data_import_status" */
  update_data_import_status_by_pk?: Maybe<DataImportStatus>;
  /** update multiples rows of table: "risksmart.data_import_status" */
  update_data_import_status_many?: Maybe<Array<Maybe<DataImportStatusMutationResponse>>>;
  /** update data of the table: "risksmart.department" */
  update_department?: Maybe<DepartmentMutationResponse>;
  /** update data of the table: "risksmart.department_audit" */
  update_department_audit?: Maybe<DepartmentAuditMutationResponse>;
  /** update single row of the table: "risksmart.department_audit" */
  update_department_audit_by_pk?: Maybe<DepartmentAudit>;
  /** update multiples rows of table: "risksmart.department_audit" */
  update_department_audit_many?: Maybe<Array<Maybe<DepartmentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.department" */
  update_department_by_pk?: Maybe<Department>;
  /** update multiples rows of table: "risksmart.department" */
  update_department_many?: Maybe<Array<Maybe<DepartmentMutationResponse>>>;
  /** update data of the table: "risksmart.department_type" */
  update_department_type?: Maybe<DepartmentTypeMutationResponse>;
  /** update data of the table: "risksmart.department_type_audit" */
  update_department_type_audit?: Maybe<DepartmentTypeAuditMutationResponse>;
  /** update single row of the table: "risksmart.department_type_audit" */
  update_department_type_audit_by_pk?: Maybe<DepartmentTypeAudit>;
  /** update multiples rows of table: "risksmart.department_type_audit" */
  update_department_type_audit_many?: Maybe<Array<Maybe<DepartmentTypeAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.department_type" */
  update_department_type_by_pk?: Maybe<DepartmentType>;
  /** update data of the table: "risksmart.department_type_group" */
  update_department_type_group?: Maybe<DepartmentTypeGroupMutationResponse>;
  /** update data of the table: "risksmart.department_type_group_audit" */
  update_department_type_group_audit?: Maybe<DepartmentTypeGroupAuditMutationResponse>;
  /** update single row of the table: "risksmart.department_type_group_audit" */
  update_department_type_group_audit_by_pk?: Maybe<DepartmentTypeGroupAudit>;
  /** update multiples rows of table: "risksmart.department_type_group_audit" */
  update_department_type_group_audit_many?: Maybe<Array<Maybe<DepartmentTypeGroupAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.department_type_group" */
  update_department_type_group_by_pk?: Maybe<DepartmentTypeGroup>;
  /** update multiples rows of table: "risksmart.department_type_group" */
  update_department_type_group_many?: Maybe<Array<Maybe<DepartmentTypeGroupMutationResponse>>>;
  /** update multiples rows of table: "risksmart.department_type" */
  update_department_type_many?: Maybe<Array<Maybe<DepartmentTypeMutationResponse>>>;
  /** update data of the table: "risksmart.document" */
  update_document?: Maybe<DocumentMutationResponse>;
  /** update data of the table: "risksmart.document_action_audit" */
  update_document_action_audit?: Maybe<DocumentActionAuditMutationResponse>;
  /** update single row of the table: "risksmart.document_action_audit" */
  update_document_action_audit_by_pk?: Maybe<DocumentActionAudit>;
  /** update multiples rows of table: "risksmart.document_action_audit" */
  update_document_action_audit_many?: Maybe<Array<Maybe<DocumentActionAuditMutationResponse>>>;
  /** update data of the table: "risksmart.document_assessment_result" */
  update_document_assessment_result?: Maybe<DocumentAssessmentResultMutationResponse>;
  /** update data of the table: "risksmart.document_assessment_result_audit" */
  update_document_assessment_result_audit?: Maybe<DocumentAssessmentResultAuditMutationResponse>;
  /** update single row of the table: "risksmart.document_assessment_result_audit" */
  update_document_assessment_result_audit_by_pk?: Maybe<DocumentAssessmentResultAudit>;
  /** update multiples rows of table: "risksmart.document_assessment_result_audit" */
  update_document_assessment_result_audit_many?: Maybe<Array<Maybe<DocumentAssessmentResultAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.document_assessment_result" */
  update_document_assessment_result_by_pk?: Maybe<DocumentAssessmentResult>;
  /** update multiples rows of table: "risksmart.document_assessment_result" */
  update_document_assessment_result_many?: Maybe<Array<Maybe<DocumentAssessmentResultMutationResponse>>>;
  /** update data of the table: "risksmart.document_assessment_status" */
  update_document_assessment_status?: Maybe<DocumentAssessmentStatusMutationResponse>;
  /** update single row of the table: "risksmart.document_assessment_status" */
  update_document_assessment_status_by_pk?: Maybe<DocumentAssessmentStatus>;
  /** update multiples rows of table: "risksmart.document_assessment_status" */
  update_document_assessment_status_many?: Maybe<Array<Maybe<DocumentAssessmentStatusMutationResponse>>>;
  /** update data of the table: "risksmart.document_audit" */
  update_document_audit?: Maybe<DocumentAuditMutationResponse>;
  /** update single row of the table: "risksmart.document_audit" */
  update_document_audit_by_pk?: Maybe<DocumentAudit>;
  /** update multiples rows of table: "risksmart.document_audit" */
  update_document_audit_many?: Maybe<Array<Maybe<DocumentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.document" */
  update_document_by_pk?: Maybe<Document>;
  /** update data of the table: "risksmart.document_file" */
  update_document_file?: Maybe<DocumentFileMutationResponse>;
  /** update data of the table: "risksmart.document_file_audit" */
  update_document_file_audit?: Maybe<DocumentFileAuditMutationResponse>;
  /** update single row of the table: "risksmart.document_file_audit" */
  update_document_file_audit_by_pk?: Maybe<DocumentFileAudit>;
  /** update multiples rows of table: "risksmart.document_file_audit" */
  update_document_file_audit_many?: Maybe<Array<Maybe<DocumentFileAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.document_file" */
  update_document_file_by_pk?: Maybe<DocumentFile>;
  /** update multiples rows of table: "risksmart.document_file" */
  update_document_file_many?: Maybe<Array<Maybe<DocumentFileMutationResponse>>>;
  /** update data of the table: "risksmart.document_file_type" */
  update_document_file_type?: Maybe<DocumentFileTypeMutationResponse>;
  /** update single row of the table: "risksmart.document_file_type" */
  update_document_file_type_by_pk?: Maybe<DocumentFileType>;
  /** update multiples rows of table: "risksmart.document_file_type" */
  update_document_file_type_many?: Maybe<Array<Maybe<DocumentFileTypeMutationResponse>>>;
  /** update data of the table: "risksmart.document_issue_audit" */
  update_document_issue_audit?: Maybe<DocumentIssueAuditMutationResponse>;
  /** update single row of the table: "risksmart.document_issue_audit" */
  update_document_issue_audit_by_pk?: Maybe<DocumentIssueAudit>;
  /** update multiples rows of table: "risksmart.document_issue_audit" */
  update_document_issue_audit_many?: Maybe<Array<Maybe<DocumentIssueAuditMutationResponse>>>;
  /** update data of the table: "risksmart.document_linked_document" */
  update_document_linked_document?: Maybe<DocumentLinkedDocumentMutationResponse>;
  /** update data of the table: "risksmart.document_linked_document_audit" */
  update_document_linked_document_audit?: Maybe<DocumentLinkedDocumentAuditMutationResponse>;
  /** update single row of the table: "risksmart.document_linked_document_audit" */
  update_document_linked_document_audit_by_pk?: Maybe<DocumentLinkedDocumentAudit>;
  /** update multiples rows of table: "risksmart.document_linked_document_audit" */
  update_document_linked_document_audit_many?: Maybe<Array<Maybe<DocumentLinkedDocumentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.document_linked_document" */
  update_document_linked_document_by_pk?: Maybe<DocumentLinkedDocument>;
  /** update multiples rows of table: "risksmart.document_linked_document" */
  update_document_linked_document_many?: Maybe<Array<Maybe<DocumentLinkedDocumentMutationResponse>>>;
  /** update multiples rows of table: "risksmart.document" */
  update_document_many?: Maybe<Array<Maybe<DocumentMutationResponse>>>;
  /** update data of the table: "risksmart.file" */
  update_file?: Maybe<FileMutationResponse>;
  /** update data of the table: "risksmart.file_audit" */
  update_file_audit?: Maybe<FileAuditMutationResponse>;
  /** update single row of the table: "risksmart.file_audit" */
  update_file_audit_by_pk?: Maybe<FileAudit>;
  /** update multiples rows of table: "risksmart.file_audit" */
  update_file_audit_many?: Maybe<Array<Maybe<FileAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.file" */
  update_file_by_pk?: Maybe<File>;
  /** update multiples rows of table: "risksmart.file" */
  update_file_many?: Maybe<Array<Maybe<FileMutationResponse>>>;
  /** update data of the table: "risksmart.form_configuration" */
  update_form_configuration?: Maybe<FormConfigurationMutationResponse>;
  /** update data of the table: "risksmart.form_configuration_audit" */
  update_form_configuration_audit?: Maybe<FormConfigurationAuditMutationResponse>;
  /** update single row of the table: "risksmart.form_configuration_audit" */
  update_form_configuration_audit_by_pk?: Maybe<FormConfigurationAudit>;
  /** update multiples rows of table: "risksmart.form_configuration_audit" */
  update_form_configuration_audit_many?: Maybe<Array<Maybe<FormConfigurationAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.form_configuration" */
  update_form_configuration_by_pk?: Maybe<FormConfiguration>;
  /** update multiples rows of table: "risksmart.form_configuration" */
  update_form_configuration_many?: Maybe<Array<Maybe<FormConfigurationMutationResponse>>>;
  /** update data of the table: "risksmart.form_field_configuration" */
  update_form_field_configuration?: Maybe<FormFieldConfigurationMutationResponse>;
  /** update data of the table: "risksmart.form_field_configuration_audit" */
  update_form_field_configuration_audit?: Maybe<FormFieldConfigurationAuditMutationResponse>;
  /** update single row of the table: "risksmart.form_field_configuration_audit" */
  update_form_field_configuration_audit_by_pk?: Maybe<FormFieldConfigurationAudit>;
  /** update multiples rows of table: "risksmart.form_field_configuration_audit" */
  update_form_field_configuration_audit_many?: Maybe<Array<Maybe<FormFieldConfigurationAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.form_field_configuration" */
  update_form_field_configuration_by_pk?: Maybe<FormFieldConfiguration>;
  /** update multiples rows of table: "risksmart.form_field_configuration" */
  update_form_field_configuration_many?: Maybe<Array<Maybe<FormFieldConfigurationMutationResponse>>>;
  /** update data of the table: "risksmart.form_field_ordering" */
  update_form_field_ordering?: Maybe<FormFieldOrderingMutationResponse>;
  /** update data of the table: "risksmart.form_field_ordering_audit" */
  update_form_field_ordering_audit?: Maybe<FormFieldOrderingAuditMutationResponse>;
  /** update single row of the table: "risksmart.form_field_ordering_audit" */
  update_form_field_ordering_audit_by_pk?: Maybe<FormFieldOrderingAudit>;
  /** update multiples rows of table: "risksmart.form_field_ordering_audit" */
  update_form_field_ordering_audit_many?: Maybe<Array<Maybe<FormFieldOrderingAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.form_field_ordering" */
  update_form_field_ordering_by_pk?: Maybe<FormFieldOrdering>;
  /** update multiples rows of table: "risksmart.form_field_ordering" */
  update_form_field_ordering_many?: Maybe<Array<Maybe<FormFieldOrderingMutationResponse>>>;
  /** update data of the table: "risksmart.impact" */
  update_impact?: Maybe<ImpactMutationResponse>;
  /** update data of the table: "risksmart.impact_audit" */
  update_impact_audit?: Maybe<ImpactAuditMutationResponse>;
  /** update single row of the table: "risksmart.impact_audit" */
  update_impact_audit_by_pk?: Maybe<ImpactAudit>;
  /** update multiples rows of table: "risksmart.impact_audit" */
  update_impact_audit_many?: Maybe<Array<Maybe<ImpactAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.impact" */
  update_impact_by_pk?: Maybe<Impact>;
  /** update multiples rows of table: "risksmart.impact" */
  update_impact_many?: Maybe<Array<Maybe<ImpactMutationResponse>>>;
  /** update data of the table: "risksmart.impact_parent" */
  update_impact_parent?: Maybe<ImpactParentMutationResponse>;
  /** update data of the table: "risksmart.impact_parent_audit" */
  update_impact_parent_audit?: Maybe<ImpactParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.impact_parent_audit" */
  update_impact_parent_audit_by_pk?: Maybe<ImpactParentAudit>;
  /** update multiples rows of table: "risksmart.impact_parent_audit" */
  update_impact_parent_audit_many?: Maybe<Array<Maybe<ImpactParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.impact_parent" */
  update_impact_parent_by_pk?: Maybe<ImpactParent>;
  /** update multiples rows of table: "risksmart.impact_parent" */
  update_impact_parent_many?: Maybe<Array<Maybe<ImpactParentMutationResponse>>>;
  /** update data of the table: "risksmart.impact_rating" */
  update_impact_rating?: Maybe<ImpactRatingMutationResponse>;
  /** update data of the table: "risksmart.impact_rating_audit" */
  update_impact_rating_audit?: Maybe<ImpactRatingAuditMutationResponse>;
  /** update single row of the table: "risksmart.impact_rating_audit" */
  update_impact_rating_audit_by_pk?: Maybe<ImpactRatingAudit>;
  /** update multiples rows of table: "risksmart.impact_rating_audit" */
  update_impact_rating_audit_many?: Maybe<Array<Maybe<ImpactRatingAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.impact_rating" */
  update_impact_rating_by_pk?: Maybe<ImpactRating>;
  /** update multiples rows of table: "risksmart.impact_rating" */
  update_impact_rating_many?: Maybe<Array<Maybe<ImpactRatingMutationResponse>>>;
  /** update data of the table: "risksmart.indicator" */
  update_indicator?: Maybe<IndicatorMutationResponse>;
  /** update data of the table: "risksmart.indicator_audit" */
  update_indicator_audit?: Maybe<IndicatorAuditMutationResponse>;
  /** update single row of the table: "risksmart.indicator_audit" */
  update_indicator_audit_by_pk?: Maybe<IndicatorAudit>;
  /** update multiples rows of table: "risksmart.indicator_audit" */
  update_indicator_audit_many?: Maybe<Array<Maybe<IndicatorAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.indicator" */
  update_indicator_by_pk?: Maybe<Indicator>;
  /** update multiples rows of table: "risksmart.indicator" */
  update_indicator_many?: Maybe<Array<Maybe<IndicatorMutationResponse>>>;
  /** update data of the table: "risksmart.indicator_parent" */
  update_indicator_parent?: Maybe<IndicatorParentMutationResponse>;
  /** update single row of the table: "risksmart.indicator_parent" */
  update_indicator_parent_by_pk?: Maybe<IndicatorParent>;
  /** update multiples rows of table: "risksmart.indicator_parent" */
  update_indicator_parent_many?: Maybe<Array<Maybe<IndicatorParentMutationResponse>>>;
  /** update data of the table: "risksmart.indicator_result" */
  update_indicator_result?: Maybe<IndicatorResultMutationResponse>;
  /** update data of the table: "risksmart.indicator_result_audit" */
  update_indicator_result_audit?: Maybe<IndicatorResultAuditMutationResponse>;
  /** update single row of the table: "risksmart.indicator_result_audit" */
  update_indicator_result_audit_by_pk?: Maybe<IndicatorResultAudit>;
  /** update multiples rows of table: "risksmart.indicator_result_audit" */
  update_indicator_result_audit_many?: Maybe<Array<Maybe<IndicatorResultAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.indicator_result" */
  update_indicator_result_by_pk?: Maybe<IndicatorResult>;
  /** update multiples rows of table: "risksmart.indicator_result" */
  update_indicator_result_many?: Maybe<Array<Maybe<IndicatorResultMutationResponse>>>;
  /** update data of the table: "risksmart.indicator_type" */
  update_indicator_type?: Maybe<IndicatorTypeMutationResponse>;
  /** update single row of the table: "risksmart.indicator_type" */
  update_indicator_type_by_pk?: Maybe<IndicatorType>;
  /** update multiples rows of table: "risksmart.indicator_type" */
  update_indicator_type_many?: Maybe<Array<Maybe<IndicatorTypeMutationResponse>>>;
  /** update data of the table: "risksmart.internal_audit_entity" */
  update_internal_audit_entity?: Maybe<InternalAuditEntityMutationResponse>;
  /** update single row of the table: "risksmart.internal_audit_entity" */
  update_internal_audit_entity_by_pk?: Maybe<InternalAuditEntity>;
  /** update multiples rows of table: "risksmart.internal_audit_entity" */
  update_internal_audit_entity_many?: Maybe<Array<Maybe<InternalAuditEntityMutationResponse>>>;
  /** update data of the table: "risksmart.internal_audit_report" */
  update_internal_audit_report?: Maybe<InternalAuditReportMutationResponse>;
  /** update single row of the table: "risksmart.internal_audit_report" */
  update_internal_audit_report_by_pk?: Maybe<InternalAuditReport>;
  /** update multiples rows of table: "risksmart.internal_audit_report" */
  update_internal_audit_report_many?: Maybe<Array<Maybe<InternalAuditReportMutationResponse>>>;
  /** update data of the table: "risksmart.issue" */
  update_issue?: Maybe<IssueMutationResponse>;
  /** update data of the table: "risksmart.issue_action_audit" */
  update_issue_action_audit?: Maybe<IssueActionAuditMutationResponse>;
  /** update single row of the table: "risksmart.issue_action_audit" */
  update_issue_action_audit_by_pk?: Maybe<IssueActionAudit>;
  /** update multiples rows of table: "risksmart.issue_action_audit" */
  update_issue_action_audit_many?: Maybe<Array<Maybe<IssueActionAuditMutationResponse>>>;
  /** update data of the table: "risksmart.issue_assessment" */
  update_issue_assessment?: Maybe<IssueAssessmentMutationResponse>;
  /** update data of the table: "risksmart.issue_assessment_audit" */
  update_issue_assessment_audit?: Maybe<IssueAssessmentAuditMutationResponse>;
  /** update single row of the table: "risksmart.issue_assessment_audit" */
  update_issue_assessment_audit_by_pk?: Maybe<IssueAssessmentAudit>;
  /** update multiples rows of table: "risksmart.issue_assessment_audit" */
  update_issue_assessment_audit_many?: Maybe<Array<Maybe<IssueAssessmentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.issue_assessment" */
  update_issue_assessment_by_pk?: Maybe<IssueAssessment>;
  /** update multiples rows of table: "risksmart.issue_assessment" */
  update_issue_assessment_many?: Maybe<Array<Maybe<IssueAssessmentMutationResponse>>>;
  /** update data of the table: "risksmart.issue_assessment_status" */
  update_issue_assessment_status?: Maybe<IssueAssessmentStatusMutationResponse>;
  /** update single row of the table: "risksmart.issue_assessment_status" */
  update_issue_assessment_status_by_pk?: Maybe<IssueAssessmentStatus>;
  /** update multiples rows of table: "risksmart.issue_assessment_status" */
  update_issue_assessment_status_many?: Maybe<Array<Maybe<IssueAssessmentStatusMutationResponse>>>;
  /** update data of the table: "risksmart.issue_audit" */
  update_issue_audit?: Maybe<IssueAuditMutationResponse>;
  /** update single row of the table: "risksmart.issue_audit" */
  update_issue_audit_by_pk?: Maybe<IssueAudit>;
  /** update multiples rows of table: "risksmart.issue_audit" */
  update_issue_audit_many?: Maybe<Array<Maybe<IssueAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.issue" */
  update_issue_by_pk?: Maybe<Issue>;
  /** update multiples rows of table: "risksmart.issue" */
  update_issue_many?: Maybe<Array<Maybe<IssueMutationResponse>>>;
  /** update data of the table: "risksmart.issue_parent" */
  update_issue_parent?: Maybe<IssueParentMutationResponse>;
  /** update data of the table: "risksmart.issue_parent_audit" */
  update_issue_parent_audit?: Maybe<IssueParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.issue_parent_audit" */
  update_issue_parent_audit_by_pk?: Maybe<IssueParentAudit>;
  /** update multiples rows of table: "risksmart.issue_parent_audit" */
  update_issue_parent_audit_many?: Maybe<Array<Maybe<IssueParentAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.issue_parent" */
  update_issue_parent_by_pk?: Maybe<IssueParent>;
  /** update multiples rows of table: "risksmart.issue_parent" */
  update_issue_parent_many?: Maybe<Array<Maybe<IssueParentMutationResponse>>>;
  /** update data of the table: "risksmart.issue_update" */
  update_issue_update?: Maybe<IssueUpdateMutationResponse>;
  /** update data of the table: "risksmart.issue_update_audit" */
  update_issue_update_audit?: Maybe<IssueUpdateAuditMutationResponse>;
  /** update single row of the table: "risksmart.issue_update_audit" */
  update_issue_update_audit_by_pk?: Maybe<IssueUpdateAudit>;
  /** update multiples rows of table: "risksmart.issue_update_audit" */
  update_issue_update_audit_many?: Maybe<Array<Maybe<IssueUpdateAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.issue_update" */
  update_issue_update_by_pk?: Maybe<IssueUpdate>;
  /** update multiples rows of table: "risksmart.issue_update" */
  update_issue_update_many?: Maybe<Array<Maybe<IssueUpdateMutationResponse>>>;
  /** update data of the table: "risksmart.linked_item" */
  update_linked_item?: Maybe<LinkedItemMutationResponse>;
  /** update single row of the table: "risksmart.linked_item" */
  update_linked_item_by_pk?: Maybe<LinkedItem>;
  /** update multiples rows of table: "risksmart.linked_item" */
  update_linked_item_many?: Maybe<Array<Maybe<LinkedItemMutationResponse>>>;
  /** update data of the table: "risksmart.node" */
  update_node?: Maybe<NodeMutationResponse>;
  /** update data of the table: "risksmart.node_ancestor" */
  update_node_ancestor?: Maybe<NodeAncestorMutationResponse>;
  /** update single row of the table: "risksmart.node_ancestor" */
  update_node_ancestor_by_pk?: Maybe<NodeAncestor>;
  /** update multiples rows of table: "risksmart.node_ancestor" */
  update_node_ancestor_many?: Maybe<Array<Maybe<NodeAncestorMutationResponse>>>;
  /** update single row of the table: "risksmart.node" */
  update_node_by_pk?: Maybe<Node>;
  /** update multiples rows of table: "risksmart.node" */
  update_node_many?: Maybe<Array<Maybe<NodeMutationResponse>>>;
  /** update data of the table: "risksmart.obligation" */
  update_obligation?: Maybe<ObligationMutationResponse>;
  /** update data of the table: "risksmart.obligation_action_audit" */
  update_obligation_action_audit?: Maybe<ObligationActionAuditMutationResponse>;
  /** update single row of the table: "risksmart.obligation_action_audit" */
  update_obligation_action_audit_by_pk?: Maybe<ObligationActionAudit>;
  /** update multiples rows of table: "risksmart.obligation_action_audit" */
  update_obligation_action_audit_many?: Maybe<Array<Maybe<ObligationActionAuditMutationResponse>>>;
  /** update data of the table: "risksmart.obligation_assessment_result" */
  update_obligation_assessment_result?: Maybe<ObligationAssessmentResultMutationResponse>;
  /** update data of the table: "risksmart.obligation_assessment_result_audit" */
  update_obligation_assessment_result_audit?: Maybe<ObligationAssessmentResultAuditMutationResponse>;
  /** update single row of the table: "risksmart.obligation_assessment_result_audit" */
  update_obligation_assessment_result_audit_by_pk?: Maybe<ObligationAssessmentResultAudit>;
  /** update multiples rows of table: "risksmart.obligation_assessment_result_audit" */
  update_obligation_assessment_result_audit_many?: Maybe<Array<Maybe<ObligationAssessmentResultAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.obligation_assessment_result" */
  update_obligation_assessment_result_by_pk?: Maybe<ObligationAssessmentResult>;
  /** update multiples rows of table: "risksmart.obligation_assessment_result" */
  update_obligation_assessment_result_many?: Maybe<Array<Maybe<ObligationAssessmentResultMutationResponse>>>;
  /** update data of the table: "risksmart.obligation_assessment_status" */
  update_obligation_assessment_status?: Maybe<ObligationAssessmentStatusMutationResponse>;
  /** update single row of the table: "risksmart.obligation_assessment_status" */
  update_obligation_assessment_status_by_pk?: Maybe<ObligationAssessmentStatus>;
  /** update multiples rows of table: "risksmart.obligation_assessment_status" */
  update_obligation_assessment_status_many?: Maybe<Array<Maybe<ObligationAssessmentStatusMutationResponse>>>;
  /** update data of the table: "risksmart.obligation_audit" */
  update_obligation_audit?: Maybe<ObligationAuditMutationResponse>;
  /** update single row of the table: "risksmart.obligation_audit" */
  update_obligation_audit_by_pk?: Maybe<ObligationAudit>;
  /** update multiples rows of table: "risksmart.obligation_audit" */
  update_obligation_audit_many?: Maybe<Array<Maybe<ObligationAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.obligation" */
  update_obligation_by_pk?: Maybe<Obligation>;
  /** update data of the table: "risksmart.obligation_impact" */
  update_obligation_impact?: Maybe<ObligationImpactMutationResponse>;
  /** update data of the table: "risksmart.obligation_impact_audit" */
  update_obligation_impact_audit?: Maybe<ObligationImpactAuditMutationResponse>;
  /** update single row of the table: "risksmart.obligation_impact_audit" */
  update_obligation_impact_audit_by_pk?: Maybe<ObligationImpactAudit>;
  /** update multiples rows of table: "risksmart.obligation_impact_audit" */
  update_obligation_impact_audit_many?: Maybe<Array<Maybe<ObligationImpactAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.obligation_impact" */
  update_obligation_impact_by_pk?: Maybe<ObligationImpact>;
  /** update multiples rows of table: "risksmart.obligation_impact" */
  update_obligation_impact_many?: Maybe<Array<Maybe<ObligationImpactMutationResponse>>>;
  /** update multiples rows of table: "risksmart.obligation" */
  update_obligation_many?: Maybe<Array<Maybe<ObligationMutationResponse>>>;
  /** update data of the table: "risksmart.obligation_type" */
  update_obligation_type?: Maybe<ObligationTypeMutationResponse>;
  /** update single row of the table: "risksmart.obligation_type" */
  update_obligation_type_by_pk?: Maybe<ObligationType>;
  /** update multiples rows of table: "risksmart.obligation_type" */
  update_obligation_type_many?: Maybe<Array<Maybe<ObligationTypeMutationResponse>>>;
  /** update data of the table: "risksmart.owner" */
  update_owner?: Maybe<OwnerMutationResponse>;
  /** update data of the table: "risksmart.owner_audit" */
  update_owner_audit?: Maybe<OwnerAuditMutationResponse>;
  /** update single row of the table: "risksmart.owner_audit" */
  update_owner_audit_by_pk?: Maybe<OwnerAudit>;
  /** update multiples rows of table: "risksmart.owner_audit" */
  update_owner_audit_many?: Maybe<Array<Maybe<OwnerAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.owner" */
  update_owner_by_pk?: Maybe<Owner>;
  /** update data of the table: "risksmart.owner_group" */
  update_owner_group?: Maybe<OwnerGroupMutationResponse>;
  /** update data of the table: "risksmart.owner_group_audit" */
  update_owner_group_audit?: Maybe<OwnerGroupAuditMutationResponse>;
  /** update single row of the table: "risksmart.owner_group_audit" */
  update_owner_group_audit_by_pk?: Maybe<OwnerGroupAudit>;
  /** update multiples rows of table: "risksmart.owner_group_audit" */
  update_owner_group_audit_many?: Maybe<Array<Maybe<OwnerGroupAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.owner_group" */
  update_owner_group_by_pk?: Maybe<OwnerGroup>;
  /** update multiples rows of table: "risksmart.owner_group" */
  update_owner_group_many?: Maybe<Array<Maybe<OwnerGroupMutationResponse>>>;
  /** update multiples rows of table: "risksmart.owner" */
  update_owner_many?: Maybe<Array<Maybe<OwnerMutationResponse>>>;
  /** update data of the table: "risksmart.parent_type" */
  update_parent_type?: Maybe<ParentTypeMutationResponse>;
  /** update single row of the table: "risksmart.parent_type" */
  update_parent_type_by_pk?: Maybe<ParentType>;
  /** update multiples rows of table: "risksmart.parent_type" */
  update_parent_type_many?: Maybe<Array<Maybe<ParentTypeMutationResponse>>>;
  /** update data of the table: "risksmart.questionnaire_invite" */
  update_questionnaire_invite?: Maybe<QuestionnaireInviteMutationResponse>;
  /** update single row of the table: "risksmart.questionnaire_invite" */
  update_questionnaire_invite_by_pk?: Maybe<QuestionnaireInvite>;
  /** update multiples rows of table: "risksmart.questionnaire_invite" */
  update_questionnaire_invite_many?: Maybe<Array<Maybe<QuestionnaireInviteMutationResponse>>>;
  /** update data of the table: "risksmart.questionnaire_template" */
  update_questionnaire_template?: Maybe<QuestionnaireTemplateMutationResponse>;
  /** update single row of the table: "risksmart.questionnaire_template" */
  update_questionnaire_template_by_pk?: Maybe<QuestionnaireTemplate>;
  /** update multiples rows of table: "risksmart.questionnaire_template" */
  update_questionnaire_template_many?: Maybe<Array<Maybe<QuestionnaireTemplateMutationResponse>>>;
  /** update data of the table: "risksmart.questionnaire_template_version" */
  update_questionnaire_template_version?: Maybe<QuestionnaireTemplateVersionMutationResponse>;
  /** update single row of the table: "risksmart.questionnaire_template_version" */
  update_questionnaire_template_version_by_pk?: Maybe<QuestionnaireTemplateVersion>;
  /** update multiples rows of table: "risksmart.questionnaire_template_version" */
  update_questionnaire_template_version_many?: Maybe<Array<Maybe<QuestionnaireTemplateVersionMutationResponse>>>;
  /** update data of the table: "risksmart.questionnaire_template_version_status" */
  update_questionnaire_template_version_status?: Maybe<QuestionnaireTemplateVersionStatusMutationResponse>;
  /** update single row of the table: "risksmart.questionnaire_template_version_status" */
  update_questionnaire_template_version_status_by_pk?: Maybe<QuestionnaireTemplateVersionStatus>;
  /** update multiples rows of table: "risksmart.questionnaire_template_version_status" */
  update_questionnaire_template_version_status_many?: Maybe<Array<Maybe<QuestionnaireTemplateVersionStatusMutationResponse>>>;
  /** update data of the table: "risksmart.relation_file" */
  update_relation_file?: Maybe<RelationFileMutationResponse>;
  /** update data of the table: "risksmart.relation_file_audit" */
  update_relation_file_audit?: Maybe<RelationFileAuditMutationResponse>;
  /** update single row of the table: "risksmart.relation_file_audit" */
  update_relation_file_audit_by_pk?: Maybe<RelationFileAudit>;
  /** update multiples rows of table: "risksmart.relation_file_audit" */
  update_relation_file_audit_many?: Maybe<Array<Maybe<RelationFileAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.relation_file" */
  update_relation_file_by_pk?: Maybe<RelationFile>;
  /** update multiples rows of table: "risksmart.relation_file" */
  update_relation_file_many?: Maybe<Array<Maybe<RelationFileMutationResponse>>>;
  /** update data of the table: "risksmart.risk" */
  update_risk?: Maybe<RiskMutationResponse>;
  /** update data of the table: "risksmart.risk_assessment_result" */
  update_risk_assessment_result?: Maybe<RiskAssessmentResultMutationResponse>;
  /** update data of the table: "risksmart.risk_assessment_result_audit" */
  update_risk_assessment_result_audit?: Maybe<RiskAssessmentResultAuditMutationResponse>;
  /** update single row of the table: "risksmart.risk_assessment_result_audit" */
  update_risk_assessment_result_audit_by_pk?: Maybe<RiskAssessmentResultAudit>;
  /** update multiples rows of table: "risksmart.risk_assessment_result_audit" */
  update_risk_assessment_result_audit_many?: Maybe<Array<Maybe<RiskAssessmentResultAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.risk_assessment_result" */
  update_risk_assessment_result_by_pk?: Maybe<RiskAssessmentResult>;
  /** update data of the table: "risksmart.risk_assessment_result_control_type" */
  update_risk_assessment_result_control_type?: Maybe<RiskAssessmentResultControlTypeMutationResponse>;
  /** update single row of the table: "risksmart.risk_assessment_result_control_type" */
  update_risk_assessment_result_control_type_by_pk?: Maybe<RiskAssessmentResultControlType>;
  /** update multiples rows of table: "risksmart.risk_assessment_result_control_type" */
  update_risk_assessment_result_control_type_many?: Maybe<Array<Maybe<RiskAssessmentResultControlTypeMutationResponse>>>;
  /** update multiples rows of table: "risksmart.risk_assessment_result" */
  update_risk_assessment_result_many?: Maybe<Array<Maybe<RiskAssessmentResultMutationResponse>>>;
  /** update data of the table: "risksmart.risk_audit" */
  update_risk_audit?: Maybe<RiskAuditMutationResponse>;
  /** update single row of the table: "risksmart.risk_audit" */
  update_risk_audit_by_pk?: Maybe<RiskAudit>;
  /** update multiples rows of table: "risksmart.risk_audit" */
  update_risk_audit_many?: Maybe<Array<Maybe<RiskAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.risk" */
  update_risk_by_pk?: Maybe<Risk>;
  /** update multiples rows of table: "risksmart.risk" */
  update_risk_many?: Maybe<Array<Maybe<RiskMutationResponse>>>;
  /** update data of the table: "risksmart.risk_score" */
  update_risk_score?: Maybe<RiskScoreMutationResponse>;
  /** update single row of the table: "risksmart.risk_score" */
  update_risk_score_by_pk?: Maybe<RiskScore>;
  /** update multiples rows of table: "risksmart.risk_score" */
  update_risk_score_many?: Maybe<Array<Maybe<RiskScoreMutationResponse>>>;
  /** update data of the table: "risksmart.risk_scoring_model" */
  update_risk_scoring_model?: Maybe<RiskScoringModelMutationResponse>;
  /** update single row of the table: "risksmart.risk_scoring_model" */
  update_risk_scoring_model_by_pk?: Maybe<RiskScoringModel>;
  /** update multiples rows of table: "risksmart.risk_scoring_model" */
  update_risk_scoring_model_many?: Maybe<Array<Maybe<RiskScoringModelMutationResponse>>>;
  /** update data of the table: "risksmart.risk_status_type" */
  update_risk_status_type?: Maybe<RiskStatusTypeMutationResponse>;
  /** update single row of the table: "risksmart.risk_status_type" */
  update_risk_status_type_by_pk?: Maybe<RiskStatusType>;
  /** update multiples rows of table: "risksmart.risk_status_type" */
  update_risk_status_type_many?: Maybe<Array<Maybe<RiskStatusTypeMutationResponse>>>;
  /** update data of the table: "risksmart.risk_treatment_type" */
  update_risk_treatment_type?: Maybe<RiskTreatmentTypeMutationResponse>;
  /** update single row of the table: "risksmart.risk_treatment_type" */
  update_risk_treatment_type_by_pk?: Maybe<RiskTreatmentType>;
  /** update multiples rows of table: "risksmart.risk_treatment_type" */
  update_risk_treatment_type_many?: Maybe<Array<Maybe<RiskTreatmentTypeMutationResponse>>>;
  /** update data of the table: "risksmart.approver_response_audit" */
  update_risksmart_approver_response_audit?: Maybe<RisksmartApproverResponseAuditMutationResponse>;
  /** update single row of the table: "risksmart.approver_response_audit" */
  update_risksmart_approver_response_audit_by_pk?: Maybe<RisksmartApproverResponseAudit>;
  /** update multiples rows of table: "risksmart.approver_response_audit" */
  update_risksmart_approver_response_audit_many?: Maybe<Array<Maybe<RisksmartApproverResponseAuditMutationResponse>>>;
  /** update data of the table: "risksmart.change_request_contributor_audit" */
  update_risksmart_change_request_contributor_audit?: Maybe<RisksmartChangeRequestContributorAuditMutationResponse>;
  /** update single row of the table: "risksmart.change_request_contributor_audit" */
  update_risksmart_change_request_contributor_audit_by_pk?: Maybe<RisksmartChangeRequestContributorAudit>;
  /** update multiples rows of table: "risksmart.change_request_contributor_audit" */
  update_risksmart_change_request_contributor_audit_many?: Maybe<Array<Maybe<RisksmartChangeRequestContributorAuditMutationResponse>>>;
  /** update data of the table: "risksmart.control_action_audit" */
  update_risksmart_control_action_audit?: Maybe<RisksmartControlActionAuditMutationResponse>;
  /** update single row of the table: "risksmart.control_action_audit" */
  update_risksmart_control_action_audit_by_pk?: Maybe<RisksmartControlActionAudit>;
  /** update multiples rows of table: "risksmart.control_action_audit" */
  update_risksmart_control_action_audit_many?: Maybe<Array<Maybe<RisksmartControlActionAuditMutationResponse>>>;
  /** update data of the table: "risksmart.counter" */
  update_risksmart_counter?: Maybe<RisksmartCounterMutationResponse>;
  /** update single row of the table: "risksmart.counter" */
  update_risksmart_counter_by_pk?: Maybe<RisksmartCounter>;
  /** update multiples rows of table: "risksmart.counter" */
  update_risksmart_counter_many?: Maybe<Array<Maybe<RisksmartCounterMutationResponse>>>;
  /** update data of the table: "risksmart.indicator_parent_audit" */
  update_risksmart_indicator_parent_audit?: Maybe<RisksmartIndicatorParentAuditMutationResponse>;
  /** update single row of the table: "risksmart.indicator_parent_audit" */
  update_risksmart_indicator_parent_audit_by_pk?: Maybe<RisksmartIndicatorParentAudit>;
  /** update multiples rows of table: "risksmart.indicator_parent_audit" */
  update_risksmart_indicator_parent_audit_many?: Maybe<Array<Maybe<RisksmartIndicatorParentAuditMutationResponse>>>;
  /** update data of the table: "risksmart.linked_item_audit" */
  update_risksmart_linked_item_audit?: Maybe<RisksmartLinkedItemAuditMutationResponse>;
  /** update single row of the table: "risksmart.linked_item_audit" */
  update_risksmart_linked_item_audit_by_pk?: Maybe<RisksmartLinkedItemAudit>;
  /** update multiples rows of table: "risksmart.linked_item_audit" */
  update_risksmart_linked_item_audit_many?: Maybe<Array<Maybe<RisksmartLinkedItemAuditMutationResponse>>>;
  /** update data of the table: "risksmart.obligation_issue_audit" */
  update_risksmart_obligation_issue_audit?: Maybe<RisksmartObligationIssueAuditMutationResponse>;
  /** update single row of the table: "risksmart.obligation_issue_audit" */
  update_risksmart_obligation_issue_audit_by_pk?: Maybe<RisksmartObligationIssueAudit>;
  /** update multiples rows of table: "risksmart.obligation_issue_audit" */
  update_risksmart_obligation_issue_audit_many?: Maybe<Array<Maybe<RisksmartObligationIssueAuditMutationResponse>>>;
  /** update data of the table: "risksmart.questionnaire_template_audit" */
  update_risksmart_questionnaire_template_audit?: Maybe<RisksmartQuestionnaireTemplateAuditMutationResponse>;
  /** update single row of the table: "risksmart.questionnaire_template_audit" */
  update_risksmart_questionnaire_template_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateAudit>;
  /** update multiples rows of table: "risksmart.questionnaire_template_audit" */
  update_risksmart_questionnaire_template_audit_many?: Maybe<Array<Maybe<RisksmartQuestionnaireTemplateAuditMutationResponse>>>;
  /** update data of the table: "risksmart.questionnaire_template_version_audit" */
  update_risksmart_questionnaire_template_version_audit?: Maybe<RisksmartQuestionnaireTemplateVersionAuditMutationResponse>;
  /** update single row of the table: "risksmart.questionnaire_template_version_audit" */
  update_risksmart_questionnaire_template_version_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateVersionAudit>;
  /** update multiples rows of table: "risksmart.questionnaire_template_version_audit" */
  update_risksmart_questionnaire_template_version_audit_many?: Maybe<Array<Maybe<RisksmartQuestionnaireTemplateVersionAuditMutationResponse>>>;
  /** update data of the table: "risksmart.risk_action_audit" */
  update_risksmart_risk_action_audit?: Maybe<RisksmartRiskActionAuditMutationResponse>;
  /** update single row of the table: "risksmart.risk_action_audit" */
  update_risksmart_risk_action_audit_by_pk?: Maybe<RisksmartRiskActionAudit>;
  /** update multiples rows of table: "risksmart.risk_action_audit" */
  update_risksmart_risk_action_audit_many?: Maybe<Array<Maybe<RisksmartRiskActionAuditMutationResponse>>>;
  /** update data of the table: "risksmart.role_access" */
  update_role_access?: Maybe<RoleAccessMutationResponse>;
  /** update single row of the table: "risksmart.role_access" */
  update_role_access_by_pk?: Maybe<RoleAccess>;
  /** update multiples rows of table: "risksmart.role_access" */
  update_role_access_many?: Maybe<Array<Maybe<RoleAccessMutationResponse>>>;
  /** update data of the table: "risksmart.tag" */
  update_tag?: Maybe<TagMutationResponse>;
  /** update data of the table: "risksmart.tag_audit" */
  update_tag_audit?: Maybe<TagAuditMutationResponse>;
  /** update single row of the table: "risksmart.tag_audit" */
  update_tag_audit_by_pk?: Maybe<TagAudit>;
  /** update multiples rows of table: "risksmart.tag_audit" */
  update_tag_audit_many?: Maybe<Array<Maybe<TagAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.tag" */
  update_tag_by_pk?: Maybe<Tag>;
  /** update multiples rows of table: "risksmart.tag" */
  update_tag_many?: Maybe<Array<Maybe<TagMutationResponse>>>;
  /** update data of the table: "risksmart.tag_type" */
  update_tag_type?: Maybe<TagTypeMutationResponse>;
  /** update data of the table: "risksmart.tag_type_audit" */
  update_tag_type_audit?: Maybe<TagTypeAuditMutationResponse>;
  /** update single row of the table: "risksmart.tag_type_audit" */
  update_tag_type_audit_by_pk?: Maybe<TagTypeAudit>;
  /** update multiples rows of table: "risksmart.tag_type_audit" */
  update_tag_type_audit_many?: Maybe<Array<Maybe<TagTypeAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.tag_type" */
  update_tag_type_by_pk?: Maybe<TagType>;
  /** update data of the table: "risksmart.tag_type_group" */
  update_tag_type_group?: Maybe<TagTypeGroupMutationResponse>;
  /** update data of the table: "risksmart.tag_type_group_audit" */
  update_tag_type_group_audit?: Maybe<TagTypeGroupAuditMutationResponse>;
  /** update single row of the table: "risksmart.tag_type_group_audit" */
  update_tag_type_group_audit_by_pk?: Maybe<TagTypeGroupAudit>;
  /** update multiples rows of table: "risksmart.tag_type_group_audit" */
  update_tag_type_group_audit_many?: Maybe<Array<Maybe<TagTypeGroupAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.tag_type_group" */
  update_tag_type_group_by_pk?: Maybe<TagTypeGroup>;
  /** update multiples rows of table: "risksmart.tag_type_group" */
  update_tag_type_group_many?: Maybe<Array<Maybe<TagTypeGroupMutationResponse>>>;
  /** update multiples rows of table: "risksmart.tag_type" */
  update_tag_type_many?: Maybe<Array<Maybe<TagTypeMutationResponse>>>;
  /** update data of the table: "risksmart.taxonomy" */
  update_taxonomy?: Maybe<TaxonomyMutationResponse>;
  /** update data of the table: "risksmart.taxonomy_audit" */
  update_taxonomy_audit?: Maybe<TaxonomyAuditMutationResponse>;
  /** update single row of the table: "risksmart.taxonomy_audit" */
  update_taxonomy_audit_by_pk?: Maybe<TaxonomyAudit>;
  /** update multiples rows of table: "risksmart.taxonomy_audit" */
  update_taxonomy_audit_many?: Maybe<Array<Maybe<TaxonomyAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.taxonomy" */
  update_taxonomy_by_pk?: Maybe<Taxonomy>;
  /** update multiples rows of table: "risksmart.taxonomy" */
  update_taxonomy_many?: Maybe<Array<Maybe<TaxonomyMutationResponse>>>;
  /** update data of the table: "risksmart.taxonomy_org" */
  update_taxonomy_org?: Maybe<TaxonomyOrgMutationResponse>;
  /** update data of the table: "risksmart.taxonomy_org_audit" */
  update_taxonomy_org_audit?: Maybe<TaxonomyOrgAuditMutationResponse>;
  /** update single row of the table: "risksmart.taxonomy_org_audit" */
  update_taxonomy_org_audit_by_pk?: Maybe<TaxonomyOrgAudit>;
  /** update multiples rows of table: "risksmart.taxonomy_org_audit" */
  update_taxonomy_org_audit_many?: Maybe<Array<Maybe<TaxonomyOrgAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.taxonomy_org" */
  update_taxonomy_org_by_pk?: Maybe<TaxonomyOrg>;
  /** update multiples rows of table: "risksmart.taxonomy_org" */
  update_taxonomy_org_many?: Maybe<Array<Maybe<TaxonomyOrgMutationResponse>>>;
  /** update data of the table: "risksmart.test_frequency" */
  update_test_frequency?: Maybe<TestFrequencyMutationResponse>;
  /** update single row of the table: "risksmart.test_frequency" */
  update_test_frequency_by_pk?: Maybe<TestFrequency>;
  /** update multiples rows of table: "risksmart.test_frequency" */
  update_test_frequency_many?: Maybe<Array<Maybe<TestFrequencyMutationResponse>>>;
  /** update data of the table: "risksmart.test_result" */
  update_test_result?: Maybe<TestResultMutationResponse>;
  /** update data of the table: "risksmart.test_result_audit" */
  update_test_result_audit?: Maybe<TestResultAuditMutationResponse>;
  /** update single row of the table: "risksmart.test_result_audit" */
  update_test_result_audit_by_pk?: Maybe<TestResultAudit>;
  /** update multiples rows of table: "risksmart.test_result_audit" */
  update_test_result_audit_many?: Maybe<Array<Maybe<TestResultAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.test_result" */
  update_test_result_by_pk?: Maybe<TestResult>;
  /** update multiples rows of table: "risksmart.test_result" */
  update_test_result_many?: Maybe<Array<Maybe<TestResultMutationResponse>>>;
  /** update data of the table: "risksmart.third_party" */
  update_third_party?: Maybe<ThirdPartyMutationResponse>;
  /** update data of the table: "risksmart.third_party_audit" */
  update_third_party_audit?: Maybe<ThirdPartyAuditMutationResponse>;
  /** update single row of the table: "risksmart.third_party_audit" */
  update_third_party_audit_by_pk?: Maybe<ThirdPartyAudit>;
  /** update multiples rows of table: "risksmart.third_party_audit" */
  update_third_party_audit_many?: Maybe<Array<Maybe<ThirdPartyAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.third_party" */
  update_third_party_by_pk?: Maybe<ThirdParty>;
  /** update multiples rows of table: "risksmart.third_party" */
  update_third_party_many?: Maybe<Array<Maybe<ThirdPartyMutationResponse>>>;
  /** update data of the table: "risksmart.third_party_response" */
  update_third_party_response?: Maybe<ThirdPartyResponseMutationResponse>;
  /** update single row of the table: "risksmart.third_party_response" */
  update_third_party_response_by_pk?: Maybe<ThirdPartyResponse>;
  /** update multiples rows of table: "risksmart.third_party_response" */
  update_third_party_response_many?: Maybe<Array<Maybe<ThirdPartyResponseMutationResponse>>>;
  /** update data of the table: "risksmart.third_party_response_status" */
  update_third_party_response_status?: Maybe<ThirdPartyResponseStatusMutationResponse>;
  /** update single row of the table: "risksmart.third_party_response_status" */
  update_third_party_response_status_by_pk?: Maybe<ThirdPartyResponseStatus>;
  /** update multiples rows of table: "risksmart.third_party_response_status" */
  update_third_party_response_status_many?: Maybe<Array<Maybe<ThirdPartyResponseStatusMutationResponse>>>;
  /** update data of the table: "risksmart.third_party_status" */
  update_third_party_status?: Maybe<ThirdPartyStatusMutationResponse>;
  /** update single row of the table: "risksmart.third_party_status" */
  update_third_party_status_by_pk?: Maybe<ThirdPartyStatus>;
  /** update multiples rows of table: "risksmart.third_party_status" */
  update_third_party_status_many?: Maybe<Array<Maybe<ThirdPartyStatusMutationResponse>>>;
  /** update data of the table: "risksmart.third_party_type" */
  update_third_party_type?: Maybe<ThirdPartyTypeMutationResponse>;
  /** update single row of the table: "risksmart.third_party_type" */
  update_third_party_type_by_pk?: Maybe<ThirdPartyType>;
  /** update multiples rows of table: "risksmart.third_party_type" */
  update_third_party_type_many?: Maybe<Array<Maybe<ThirdPartyTypeMutationResponse>>>;
  /** update data of the table: "risksmart.user_group" */
  update_user_group?: Maybe<UserGroupMutationResponse>;
  /** update data of the table: "risksmart.user_group_audit" */
  update_user_group_audit?: Maybe<UserGroupAuditMutationResponse>;
  /** update single row of the table: "risksmart.user_group_audit" */
  update_user_group_audit_by_pk?: Maybe<UserGroupAudit>;
  /** update multiples rows of table: "risksmart.user_group_audit" */
  update_user_group_audit_many?: Maybe<Array<Maybe<UserGroupAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.user_group" */
  update_user_group_by_pk?: Maybe<UserGroup>;
  /** update multiples rows of table: "risksmart.user_group" */
  update_user_group_many?: Maybe<Array<Maybe<UserGroupMutationResponse>>>;
  /** update data of the table: "risksmart.user_group_user" */
  update_user_group_user?: Maybe<UserGroupUserMutationResponse>;
  /** update data of the table: "risksmart.user_group_user_audit" */
  update_user_group_user_audit?: Maybe<UserGroupUserAuditMutationResponse>;
  /** update single row of the table: "risksmart.user_group_user_audit" */
  update_user_group_user_audit_by_pk?: Maybe<UserGroupUserAudit>;
  /** update multiples rows of table: "risksmart.user_group_user_audit" */
  update_user_group_user_audit_many?: Maybe<Array<Maybe<UserGroupUserAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.user_group_user" */
  update_user_group_user_by_pk?: Maybe<UserGroupUser>;
  /** update multiples rows of table: "risksmart.user_group_user" */
  update_user_group_user_many?: Maybe<Array<Maybe<UserGroupUserMutationResponse>>>;
  update_user_roles: UpdateUserRolesMutationOutput;
  /** update data of the table: "risksmart.user_search_preferences" */
  update_user_search_preferences?: Maybe<UserSearchPreferencesMutationResponse>;
  /** update data of the table: "risksmart.user_search_preferences_audit" */
  update_user_search_preferences_audit?: Maybe<UserSearchPreferencesAuditMutationResponse>;
  /** update single row of the table: "risksmart.user_search_preferences_audit" */
  update_user_search_preferences_audit_by_pk?: Maybe<UserSearchPreferencesAudit>;
  /** update multiples rows of table: "risksmart.user_search_preferences_audit" */
  update_user_search_preferences_audit_many?: Maybe<Array<Maybe<UserSearchPreferencesAuditMutationResponse>>>;
  /** update single row of the table: "risksmart.user_search_preferences" */
  update_user_search_preferences_by_pk?: Maybe<UserSearchPreferences>;
  /** update multiples rows of table: "risksmart.user_search_preferences" */
  update_user_search_preferences_many?: Maybe<Array<Maybe<UserSearchPreferencesMutationResponse>>>;
  /** update data of the table: "auth.user_status" */
  update_user_status?: Maybe<UserStatusMutationResponse>;
  /** update single row of the table: "auth.user_status" */
  update_user_status_by_pk?: Maybe<UserStatus>;
  /** update multiples rows of table: "auth.user_status" */
  update_user_status_many?: Maybe<Array<Maybe<UserStatusMutationResponse>>>;
  /** update data of the table: "risksmart.version_status" */
  update_version_status?: Maybe<VersionStatusMutationResponse>;
  /** update single row of the table: "risksmart.version_status" */
  update_version_status_by_pk?: Maybe<VersionStatus>;
  /** update multiples rows of table: "risksmart.version_status" */
  update_version_status_many?: Maybe<Array<Maybe<VersionStatusMutationResponse>>>;
};


/** mutation root */
export type MutationRootAttestRecordArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDataImportStartImportArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDataImportValidateArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAcceptancesByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type MutationRootDeleteActionsByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type MutationRootDeleteControlsByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type MutationRootDeleteIssuesByIdArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type MutationRootDeleteRiskByIdArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAcceptanceArgs = {
  where: AcceptanceBoolExp;
};


/** mutation root */
export type MutationRootDeleteAcceptanceAuditArgs = {
  where: AcceptanceAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAcceptanceAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteAcceptanceByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAcceptanceParentArgs = {
  where: AcceptanceParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteAcceptanceParentAuditArgs = {
  where: AcceptanceParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAcceptanceParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAcceptanceParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAcceptanceStatusArgs = {
  where: AcceptanceStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteAcceptanceStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAccessTypeArgs = {
  where: AccessTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAccessTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteActionArgs = {
  where: ActionBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionAuditArgs = {
  where: ActionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteActionByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteActionParentArgs = {
  where: ActionParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionParentAuditArgs = {
  where: ActionParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionParentAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteActionParentByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteActionStatusArgs = {
  where: ActionStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteActionUpdateArgs = {
  where: ActionUpdateBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionUpdateAuditArgs = {
  where: ActionUpdateAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteActionUpdateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteActionUpdateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAggregationOrgArgs = {
  where: AggregationOrgBoolExp;
};


/** mutation root */
export type MutationRootDeleteAggregationOrgByPkArgs = {
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAppetiteArgs = {
  where: AppetiteBoolExp;
};


/** mutation root */
export type MutationRootDeleteAppetiteAuditArgs = {
  where: AppetiteAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAppetiteAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteAppetiteByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAppetiteModelArgs = {
  where: AppetiteModelBoolExp;
};


/** mutation root */
export type MutationRootDeleteAppetiteModelByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAppetiteParentArgs = {
  where: AppetiteParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteAppetiteParentAuditArgs = {
  where: AppetiteParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAppetiteParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAppetiteParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAppetiteTypeArgs = {
  where: AppetiteTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAppetiteTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalArgs = {
  where: ApprovalBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalAuditArgs = {
  where: ApprovalAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalInFlightEditRuleArgs = {
  where: ApprovalInFlightEditRuleBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalInFlightEditRuleByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalLevelArgs = {
  where: ApprovalLevelBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalLevelAuditArgs = {
  where: ApprovalLevelAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalLevelAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalLevelByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalRuleTypeArgs = {
  where: ApprovalRuleTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalRuleTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteApprovalStatusArgs = {
  where: ApprovalStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteApprovalStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteApproverArgs = {
  where: ApproverBoolExp;
};


/** mutation root */
export type MutationRootDeleteApproverAuditArgs = {
  where: ApproverAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteApproverAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteApproverByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteApproverResponseArgs = {
  where: ApproverResponseBoolExp;
};


/** mutation root */
export type MutationRootDeleteApproverResponseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentArgs = {
  where: AssessmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityArgs = {
  where: AssessmentActivityBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityAuditArgs = {
  where: AssessmentActivityAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityStatusArgs = {
  where: AssessmentActivityStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityTypeArgs = {
  where: AssessmentActivityTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentActivityTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentAuditArgs = {
  where: AssessmentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentResultParentArgs = {
  where: AssessmentResultParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentResultParentAuditArgs = {
  where: AssessmentResultParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentResultParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentResultParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAssessmentStatusArgs = {
  where: AssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAttestationConfigArgs = {
  where: AttestationConfigBoolExp;
};


/** mutation root */
export type MutationRootDeleteAttestationConfigByPkArgs = {
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAttestationGroupArgs = {
  where: AttestationGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteAttestationGroupByPkArgs = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAttestationRecordArgs = {
  where: AttestationRecordBoolExp;
};


/** mutation root */
export type MutationRootDeleteAttestationRecordByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteAttestationRecordStatusArgs = {
  where: AttestationRecordStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteAttestationRecordStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationArgs = {
  where: AuthOrganisationBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationAuditArgs = {
  where: AuthOrganisationAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationByPkArgs = {
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationuserArgs = {
  where: AuthOrganisationuserBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationuserAuditArgs = {
  where: AuthOrganisationuserAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationuserAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthOrganisationuserByPkArgs = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthUserArgs = {
  where: AuthUserBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthUserActivityAuditArgs = {
  where: AuthUserActivityAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthUserActivityAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ModifiedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthUserAuditArgs = {
  where: AuthUserAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteAuthUserAuditByPkArgs = {
  Id: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteAuthUserByPkArgs = {
  Id: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteBusinessAreaArgs = {
  where: BusinessAreaBoolExp;
};


/** mutation root */
export type MutationRootDeleteBusinessAreaByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteCauseArgs = {
  where: CauseBoolExp;
};


/** mutation root */
export type MutationRootDeleteCauseAuditArgs = {
  where: CauseAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteCauseAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteCauseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteChangeRequestArgs = {
  where: ChangeRequestBoolExp;
};


/** mutation root */
export type MutationRootDeleteChangeRequestAuditArgs = {
  where: ChangeRequestAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteChangeRequestAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteChangeRequestByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteChangeRequestContributorArgs = {
  where: ChangeRequestContributorBoolExp;
};


/** mutation root */
export type MutationRootDeleteChangeRequestContributorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteCommentArgs = {
  where: CommentBoolExp;
};


/** mutation root */
export type MutationRootDeleteCommentAuditArgs = {
  where: CommentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteCommentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteCommentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteComplianceMonitoringAssessmentArgs = {
  where: ComplianceMonitoringAssessmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteComplianceMonitoringAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteConfigEnvArgs = {
  where: ConfigEnvBoolExp;
};


/** mutation root */
export type MutationRootDeleteConfigEnvByPkArgs = {
  Name: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteConsequenceArgs = {
  where: ConsequenceBoolExp;
};


/** mutation root */
export type MutationRootDeleteConsequenceAuditArgs = {
  where: ConsequenceAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteConsequenceAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteConsequenceByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteConsequenceTypeArgs = {
  where: ConsequenceTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteConsequenceTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteContributorArgs = {
  where: ContributorBoolExp;
};


/** mutation root */
export type MutationRootDeleteContributorAuditArgs = {
  where: ContributorAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteContributorAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteContributorByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteContributorGroupArgs = {
  where: ContributorGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteContributorGroupAuditArgs = {
  where: ContributorGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteContributorGroupAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteContributorGroupByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteContributorTypeArgs = {
  where: ContributorTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteContributorTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteControlArgs = {
  where: ControlBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlAuditArgs = {
  where: ControlAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteControlByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteControlGroupArgs = {
  where: ControlGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlGroupAuditArgs = {
  where: ControlGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteControlGroupByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteControlParentArgs = {
  where: ControlParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlParentAuditArgs = {
  where: ControlParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlParentAuditByPkArgs = {
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteControlParentByPkArgs = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteControlTestFrequencyArgs = {
  where: ControlTestFrequencyBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlTestFrequencyByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteControlTypeArgs = {
  where: ControlTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteControlTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteConversationArgs = {
  where: ConversationBoolExp;
};


/** mutation root */
export type MutationRootDeleteConversationAuditArgs = {
  where: ConversationAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteConversationAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteConversationByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteCostTypeArgs = {
  where: CostTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteCostTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteCustomAttributeSchemaArgs = {
  where: CustomAttributeSchemaBoolExp;
};


/** mutation root */
export type MutationRootDeleteCustomAttributeSchemaAuditArgs = {
  where: CustomAttributeSchemaAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteCustomAttributeSchemaAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteCustomAttributeSchemaByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteCustomRibbonArgs = {
  where: CustomRibbonBoolExp;
};


/** mutation root */
export type MutationRootDeleteCustomRibbonAuditArgs = {
  where: CustomRibbonAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteCustomRibbonAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteCustomRibbonByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDashboardArgs = {
  where: DashboardBoolExp;
};


/** mutation root */
export type MutationRootDeleteDashboardAuditArgs = {
  where: DashboardAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDashboardAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDashboardByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDashboardSharingTypeArgs = {
  where: DashboardSharingTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDashboardSharingTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteDataImportArgs = {
  where: DataImportBoolExp;
};


/** mutation root */
export type MutationRootDeleteDataImportAuditArgs = {
  where: DataImportAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDataImportAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDataImportByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDataImportErrorArgs = {
  where: DataImportErrorBoolExp;
};


/** mutation root */
export type MutationRootDeleteDataImportErrorAuditArgs = {
  where: DataImportErrorAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDataImportErrorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDataImportErrorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDataImportStatusArgs = {
  where: DataImportStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteDataImportStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteDepartmentArgs = {
  where: DepartmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentAuditArgs = {
  where: DepartmentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentAuditByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDepartmentByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeArgs = {
  where: DepartmentTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeAuditArgs = {
  where: DepartmentTypeAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeAuditByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeGroupArgs = {
  where: DepartmentTypeGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeGroupAuditArgs = {
  where: DepartmentTypeGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDepartmentTypeGroupByPkArgs = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentArgs = {
  where: DocumentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentActionAuditArgs = {
  where: DocumentActionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  DocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentAssessmentResultArgs = {
  where: DocumentAssessmentResultBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentAssessmentResultAuditArgs = {
  where: DocumentAssessmentResultAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentAssessmentStatusArgs = {
  where: DocumentAssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentAuditArgs = {
  where: DocumentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentFileArgs = {
  where: DocumentFileBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentFileAuditArgs = {
  where: DocumentFileAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentFileAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentFileByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentFileTypeArgs = {
  where: DocumentFileTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentFileTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentIssueAuditArgs = {
  where: DocumentIssueAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentIssueAuditByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentLinkedDocumentArgs = {
  where: DocumentLinkedDocumentBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentLinkedDocumentAuditArgs = {
  where: DocumentLinkedDocumentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteDocumentLinkedDocumentAuditByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteDocumentLinkedDocumentByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFileArgs = {
  where: FileBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileAuditArgs = {
  where: FileAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteFileAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteFileByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteFormConfigurationArgs = {
  where: FormConfigurationBoolExp;
};


/** mutation root */
export type MutationRootDeleteFormConfigurationAuditArgs = {
  where: FormConfigurationAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteFormConfigurationAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentType: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteFormConfigurationByPkArgs = {
  OrgKey: Scalars['String']['input'];
  ParentType: ParentTypeEnum;
};


/** mutation root */
export type MutationRootDeleteFormFieldConfigurationArgs = {
  where: FormFieldConfigurationBoolExp;
};


/** mutation root */
export type MutationRootDeleteFormFieldConfigurationAuditArgs = {
  where: FormFieldConfigurationAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteFormFieldConfigurationAuditByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteFormFieldConfigurationByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteFormFieldOrderingArgs = {
  where: FormFieldOrderingBoolExp;
};


/** mutation root */
export type MutationRootDeleteFormFieldOrderingAuditArgs = {
  where: FormFieldOrderingAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteFormFieldOrderingAuditByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteFormFieldOrderingByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteImpactArgs = {
  where: ImpactBoolExp;
};


/** mutation root */
export type MutationRootDeleteImpactAuditArgs = {
  where: ImpactAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteImpactAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteImpactByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteImpactParentArgs = {
  where: ImpactParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteImpactParentAuditArgs = {
  where: ImpactParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteImpactParentAuditByPkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteImpactParentByPkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteImpactRatingArgs = {
  where: ImpactRatingBoolExp;
};


/** mutation root */
export type MutationRootDeleteImpactRatingAuditArgs = {
  where: ImpactRatingAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteImpactRatingAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteImpactRatingByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIndicatorArgs = {
  where: IndicatorBoolExp;
};


/** mutation root */
export type MutationRootDeleteIndicatorAuditArgs = {
  where: IndicatorAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIndicatorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteIndicatorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIndicatorParentArgs = {
  where: IndicatorParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteIndicatorParentByPkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIndicatorResultArgs = {
  where: IndicatorResultBoolExp;
};


/** mutation root */
export type MutationRootDeleteIndicatorResultAuditArgs = {
  where: IndicatorResultAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIndicatorResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteIndicatorResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIndicatorTypeArgs = {
  where: IndicatorTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteIndicatorTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteInternalAuditEntityArgs = {
  where: InternalAuditEntityBoolExp;
};


/** mutation root */
export type MutationRootDeleteInternalAuditEntityByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteInternalAuditReportArgs = {
  where: InternalAuditReportBoolExp;
};


/** mutation root */
export type MutationRootDeleteInternalAuditReportByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueArgs = {
  where: IssueBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueActionAuditArgs = {
  where: IssueActionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueAssessmentArgs = {
  where: IssueAssessmentBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueAssessmentAuditArgs = {
  where: IssueAssessmentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueAssessmentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueAssessmentStatusArgs = {
  where: IssueAssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueAuditArgs = {
  where: IssueAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueParentArgs = {
  where: IssueParentBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueParentAuditArgs = {
  where: IssueParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueParentAuditByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueParentByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueUpdateArgs = {
  where: IssueUpdateBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueUpdateAuditArgs = {
  where: IssueUpdateAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteIssueUpdateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteIssueUpdateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteLinkedItemArgs = {
  where: LinkedItemBoolExp;
};


/** mutation root */
export type MutationRootDeleteLinkedItemByPkArgs = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteNodeArgs = {
  where: NodeBoolExp;
};


/** mutation root */
export type MutationRootDeleteNodeAncestorArgs = {
  where: NodeAncestorBoolExp;
};


/** mutation root */
export type MutationRootDeleteNodeAncestorByPkArgs = {
  AncestorId: Scalars['uuid']['input'];
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteNodeByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationArgs = {
  where: ObligationBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationActionAuditArgs = {
  where: ObligationActionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationAssessmentResultArgs = {
  where: ObligationAssessmentResultBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationAssessmentResultAuditArgs = {
  where: ObligationAssessmentResultAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationAssessmentStatusArgs = {
  where: ObligationAssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationAuditArgs = {
  where: ObligationAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationImpactArgs = {
  where: ObligationImpactBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationImpactAuditArgs = {
  where: ObligationImpactAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationImpactAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationImpactByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteObligationTypeArgs = {
  where: ObligationTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteObligationTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteOwnerArgs = {
  where: OwnerBoolExp;
};


/** mutation root */
export type MutationRootDeleteOwnerAuditArgs = {
  where: OwnerAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteOwnerAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteOwnerByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteOwnerGroupArgs = {
  where: OwnerGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteOwnerGroupAuditArgs = {
  where: OwnerGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteOwnerGroupAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteOwnerGroupByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteParentTypeArgs = {
  where: ParentTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteParentTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteQuestionnaireInviteArgs = {
  where: QuestionnaireInviteBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireInviteByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteQuestionnaireTemplateArgs = {
  where: QuestionnaireTemplateBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireTemplateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteQuestionnaireTemplateVersionArgs = {
  where: QuestionnaireTemplateVersionBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireTemplateVersionByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteQuestionnaireTemplateVersionStatusArgs = {
  where: QuestionnaireTemplateVersionStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteQuestionnaireTemplateVersionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRelationFileArgs = {
  where: RelationFileBoolExp;
};


/** mutation root */
export type MutationRootDeleteRelationFileAuditArgs = {
  where: RelationFileAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRelationFileAuditByPkArgs = {
  FileId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRelationFileByPkArgs = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskArgs = {
  where: RiskBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskAssessmentResultArgs = {
  where: RiskAssessmentResultBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskAssessmentResultAuditArgs = {
  where: RiskAssessmentResultAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskAssessmentResultControlTypeArgs = {
  where: RiskAssessmentResultControlTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskAssessmentResultControlTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskAuditArgs = {
  where: RiskAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskScoreArgs = {
  where: RiskScoreBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskScoreByPkArgs = {
  RiskId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskScoringModelArgs = {
  where: RiskScoringModelBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskScoringModelByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskStatusTypeArgs = {
  where: RiskStatusTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskStatusTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRiskTreatmentTypeArgs = {
  where: RiskTreatmentTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteRiskTreatmentTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartApproverResponseAuditArgs = {
  where: RisksmartApproverResponseAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartApproverResponseAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartChangeRequestContributorAuditArgs = {
  where: RisksmartChangeRequestContributorAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartChangeRequestContributorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartControlActionAuditArgs = {
  where: RisksmartControlActionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartControlActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartCounterArgs = {
  where: RisksmartCounterBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartCounterByPkArgs = {
  Name: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartIndicatorParentAuditArgs = {
  where: RisksmartIndicatorParentAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartIndicatorParentAuditByPkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartLinkedItemAuditArgs = {
  where: RisksmartLinkedItemAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartLinkedItemAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartObligationIssueAuditArgs = {
  where: RisksmartObligationIssueAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartObligationIssueAuditByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartQuestionnaireTemplateAuditArgs = {
  where: RisksmartQuestionnaireTemplateAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartQuestionnaireTemplateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartQuestionnaireTemplateVersionAuditArgs = {
  where: RisksmartQuestionnaireTemplateVersionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartQuestionnaireTemplateVersionAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteRisksmartRiskActionAuditArgs = {
  where: RisksmartRiskActionAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteRisksmartRiskActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  RiskId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteRoleAccessArgs = {
  where: RoleAccessBoolExp;
};


/** mutation root */
export type MutationRootDeleteRoleAccessByPkArgs = {
  AccessType: AccessTypeEnum;
  ContributorType: ContributorTypeEnum;
  ObjectType: ParentTypeEnum;
  RoleKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteTagArgs = {
  where: TagBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagAuditArgs = {
  where: TagAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteTagByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteTagTypeArgs = {
  where: TagTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagTypeAuditArgs = {
  where: TagTypeAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagTypeAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteTagTypeByPkArgs = {
  TagTypeId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteTagTypeGroupArgs = {
  where: TagTypeGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagTypeGroupAuditArgs = {
  where: TagTypeGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteTagTypeGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteTagTypeGroupByPkArgs = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteTaxonomyArgs = {
  where: TaxonomyBoolExp;
};


/** mutation root */
export type MutationRootDeleteTaxonomyAuditArgs = {
  where: TaxonomyAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteTaxonomyAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteTaxonomyByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteTaxonomyOrgArgs = {
  where: TaxonomyOrgBoolExp;
};


/** mutation root */
export type MutationRootDeleteTaxonomyOrgAuditArgs = {
  where: TaxonomyOrgAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteTaxonomyOrgAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteTaxonomyOrgByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteTestFrequencyArgs = {
  where: TestFrequencyBoolExp;
};


/** mutation root */
export type MutationRootDeleteTestFrequencyByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteTestResultArgs = {
  where: TestResultBoolExp;
};


/** mutation root */
export type MutationRootDeleteTestResultAuditArgs = {
  where: TestResultAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteTestResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteTestResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteThirdPartyArgs = {
  where: ThirdPartyBoolExp;
};


/** mutation root */
export type MutationRootDeleteThirdPartyAuditArgs = {
  where: ThirdPartyAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteThirdPartyAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteThirdPartyByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteThirdPartyResponseArgs = {
  where: ThirdPartyResponseBoolExp;
};


/** mutation root */
export type MutationRootDeleteThirdPartyResponseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteThirdPartyResponseStatusArgs = {
  where: ThirdPartyResponseStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteThirdPartyResponseStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteThirdPartyStatusArgs = {
  where: ThirdPartyStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteThirdPartyStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteThirdPartyTypeArgs = {
  where: ThirdPartyTypeBoolExp;
};


/** mutation root */
export type MutationRootDeleteThirdPartyTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteUserGroupArgs = {
  where: UserGroupBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupAuditArgs = {
  where: UserGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootDeleteUserGroupByPkArgs = {
  Id: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootDeleteUserGroupUserArgs = {
  where: UserGroupUserBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupUserAuditArgs = {
  where: UserGroupUserAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserGroupUserAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteUserGroupUserByPkArgs = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteUserSearchPreferencesArgs = {
  where: UserSearchPreferencesBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserSearchPreferencesAuditArgs = {
  where: UserSearchPreferencesAuditBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserSearchPreferencesAuditByPkArgs = {
  CreatedByUser: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteUserSearchPreferencesByPkArgs = {
  CreatedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteUserStatusArgs = {
  where: UserStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteUserStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootDeleteVersionStatusArgs = {
  where: VersionStatusBoolExp;
};


/** mutation root */
export type MutationRootDeleteVersionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootInsertAssessmentActivityWithLinkedItemsArgs = {
  ActivityType: AssessmentActivityTypeEnum;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  LinkedItemIds: Array<Scalars['uuid']['input']>;
  ParentId: Scalars['uuid']['input'];
  Status: AssessmentActivityStatusEnum;
  Summary: Scalars['String']['input'];
  Title: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootInsertChildAcceptanceArgs = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom: Scalars['timestamptz']['input'];
  DateAcceptedTo: Scalars['timestamptz']['input'];
  Details?: InputMaybe<Scalars['String']['input']>;
  ParentId: Scalars['uuid']['input'];
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<AcceptanceStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertChildActionArgs = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue: Scalars['timestamptz']['input'];
  DateRaised: Scalars['timestamptz']['input'];
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  Status: ActionStatusEnum;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootInsertChildAppetiteArgs = {
  AppetiteType?: InputMaybe<AppetiteTypeEnum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  EffectiveDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ImpactAppetite?: InputMaybe<Scalars['Int']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['Int']['input']>;
  LowerAppetite?: InputMaybe<Scalars['Int']['input']>;
  ParentIds: Array<Scalars['uuid']['input']>;
  Statement?: InputMaybe<Scalars['String']['input']>;
  UpperAppetite?: InputMaybe<Scalars['Int']['input']>;
};


/** mutation root */
export type MutationRootInsertChildControlArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description: Scalars['String']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId: Scalars['uuid']['input'];
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TestFrequency?: InputMaybe<ControlTestFrequencyEnum>;
  Title: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootInsertChildDashboardArgs = {
  Content: Scalars['jsonb']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Name: Scalars['String']['input'];
  Sharing: DashboardSharingTypeEnumAction;
};


/** mutation root */
export type MutationRootInsertChildDocumentAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DocumentIds: Array<Scalars['uuid']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type MutationRootInsertChildImpactArgs = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  LikelihoodAppetite?: InputMaybe<Scalars['smallint']['input']>;
  Name: Scalars['String']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  RatingGuidance?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertChildImpactRatingArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CompletedBy?: InputMaybe<Scalars['String']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  RatedItemId: Scalars['uuid']['input'];
  Ratings: Array<InsertImpactRatingPairInput>;
  TestDate: Scalars['timestamptz']['input'];
};


/** mutation root */
export type MutationRootInsertChildIndicatorArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  LowerAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  LowerToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetValueTxt?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<IndicatorTypeEnum>;
  Unit?: InputMaybe<Scalars['String']['input']>;
  UpperAppetiteNum?: InputMaybe<Scalars['numeric']['input']>;
  UpperToleranceNum?: InputMaybe<Scalars['numeric']['input']>;
};


/** mutation root */
export type MutationRootInsertChildIssueArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateIdentified: Scalars['timestamptz']['input'];
  DateOccurred: Scalars['timestamptz']['input'];
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Details: Scalars['String']['input'];
  ImpactsCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  IsExternalIssue?: InputMaybe<Scalars['Boolean']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootInsertChildIssueAssessmentArgs = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  AssociatedControlIds: Array<Scalars['uuid']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  ParentIssueId: Scalars['uuid']['input'];
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PoliciesBreachedIds: Array<Scalars['uuid']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreachedIds: Array<Scalars['uuid']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<IssueAssessmentStatusEnum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertChildObligationAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  ObligationIds: Array<Scalars['uuid']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type MutationRootInsertChildRiskArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<RiskStatusTypeEnum>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Tier: Scalars['Int']['input'];
  Title: Scalars['String']['input'];
  Treatment?: InputMaybe<RiskTreatmentTypeEnum>;
};


/** mutation root */
export type MutationRootInsertChildRiskAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ControlType?: InputMaybe<RiskAssessmentResultControlTypeEnum>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RiskIds: Array<Scalars['uuid']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type MutationRootInsertControlTestResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ComplianceMonitoringAssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  ControlIds: Array<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  InternalAuditReportId?: InputMaybe<Scalars['uuid']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertDocumentVersionArgs = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Link?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ParentDocumentId: Scalars['uuid']['input'];
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<DocumentFileTypeEnum>;
  Version?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertInternalAuditArgs = {
  Input?: InputMaybe<InsertInternalAuditInput>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireInvitesArgs = {
  QuestionnaireTemplateVersionIds: Array<Scalars['uuid']['input']>;
  ThirdPartyId: Scalars['uuid']['input'];
  UserEmails: Array<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertReferenceUserArgs = {
  Email: Scalars['String']['input'];
  UserName?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootInsertAcceptanceArgs = {
  objects: Array<AcceptanceInsertInput>;
  on_conflict?: InputMaybe<AcceptanceOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceAuditArgs = {
  objects: Array<AcceptanceAuditInsertInput>;
  on_conflict?: InputMaybe<AcceptanceAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceAuditOneArgs = {
  object: AcceptanceAuditInsertInput;
  on_conflict?: InputMaybe<AcceptanceAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceOneArgs = {
  object: AcceptanceInsertInput;
  on_conflict?: InputMaybe<AcceptanceOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceParentArgs = {
  objects: Array<AcceptanceParentInsertInput>;
  on_conflict?: InputMaybe<AcceptanceParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceParentAuditArgs = {
  objects: Array<AcceptanceParentAuditInsertInput>;
  on_conflict?: InputMaybe<AcceptanceParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceParentAuditOneArgs = {
  object: AcceptanceParentAuditInsertInput;
  on_conflict?: InputMaybe<AcceptanceParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceParentOneArgs = {
  object: AcceptanceParentInsertInput;
  on_conflict?: InputMaybe<AcceptanceParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceStatusArgs = {
  objects: Array<AcceptanceStatusInsertInput>;
  on_conflict?: InputMaybe<AcceptanceStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAcceptanceStatusOneArgs = {
  object: AcceptanceStatusInsertInput;
  on_conflict?: InputMaybe<AcceptanceStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccessTypeArgs = {
  objects: Array<AccessTypeInsertInput>;
  on_conflict?: InputMaybe<AccessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAccessTypeOneArgs = {
  object: AccessTypeInsertInput;
  on_conflict?: InputMaybe<AccessTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionArgs = {
  objects: Array<ActionInsertInput>;
  on_conflict?: InputMaybe<ActionOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionAuditArgs = {
  objects: Array<ActionAuditInsertInput>;
  on_conflict?: InputMaybe<ActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionAuditOneArgs = {
  object: ActionAuditInsertInput;
  on_conflict?: InputMaybe<ActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionOneArgs = {
  object: ActionInsertInput;
  on_conflict?: InputMaybe<ActionOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionParentArgs = {
  objects: Array<ActionParentInsertInput>;
  on_conflict?: InputMaybe<ActionParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionParentAuditArgs = {
  objects: Array<ActionParentAuditInsertInput>;
  on_conflict?: InputMaybe<ActionParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionParentAuditOneArgs = {
  object: ActionParentAuditInsertInput;
  on_conflict?: InputMaybe<ActionParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionParentOneArgs = {
  object: ActionParentInsertInput;
  on_conflict?: InputMaybe<ActionParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionStatusArgs = {
  objects: Array<ActionStatusInsertInput>;
  on_conflict?: InputMaybe<ActionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionStatusOneArgs = {
  object: ActionStatusInsertInput;
  on_conflict?: InputMaybe<ActionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionUpdateArgs = {
  objects: Array<ActionUpdateInsertInput>;
  on_conflict?: InputMaybe<ActionUpdateOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionUpdateAuditArgs = {
  objects: Array<ActionUpdateAuditInsertInput>;
  on_conflict?: InputMaybe<ActionUpdateAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionUpdateAuditOneArgs = {
  object: ActionUpdateAuditInsertInput;
  on_conflict?: InputMaybe<ActionUpdateAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertActionUpdateOneArgs = {
  object: ActionUpdateInsertInput;
  on_conflict?: InputMaybe<ActionUpdateOnConflict>;
};


/** mutation root */
export type MutationRootInsertAggregationOrgArgs = {
  objects: Array<AggregationOrgInsertInput>;
  on_conflict?: InputMaybe<AggregationOrgOnConflict>;
};


/** mutation root */
export type MutationRootInsertAggregationOrgOneArgs = {
  object: AggregationOrgInsertInput;
  on_conflict?: InputMaybe<AggregationOrgOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteArgs = {
  objects: Array<AppetiteInsertInput>;
  on_conflict?: InputMaybe<AppetiteOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteAuditArgs = {
  objects: Array<AppetiteAuditInsertInput>;
  on_conflict?: InputMaybe<AppetiteAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteAuditOneArgs = {
  object: AppetiteAuditInsertInput;
  on_conflict?: InputMaybe<AppetiteAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteModelArgs = {
  objects: Array<AppetiteModelInsertInput>;
  on_conflict?: InputMaybe<AppetiteModelOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteModelOneArgs = {
  object: AppetiteModelInsertInput;
  on_conflict?: InputMaybe<AppetiteModelOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteOneArgs = {
  object: AppetiteInsertInput;
  on_conflict?: InputMaybe<AppetiteOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteParentArgs = {
  objects: Array<AppetiteParentInsertInput>;
  on_conflict?: InputMaybe<AppetiteParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteParentAuditArgs = {
  objects: Array<AppetiteParentAuditInsertInput>;
  on_conflict?: InputMaybe<AppetiteParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteParentAuditOneArgs = {
  object: AppetiteParentAuditInsertInput;
  on_conflict?: InputMaybe<AppetiteParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteParentOneArgs = {
  object: AppetiteParentInsertInput;
  on_conflict?: InputMaybe<AppetiteParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteTypeArgs = {
  objects: Array<AppetiteTypeInsertInput>;
  on_conflict?: InputMaybe<AppetiteTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAppetiteTypeOneArgs = {
  object: AppetiteTypeInsertInput;
  on_conflict?: InputMaybe<AppetiteTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalArgs = {
  objects: Array<ApprovalInsertInput>;
  on_conflict?: InputMaybe<ApprovalOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalAuditArgs = {
  objects: Array<ApprovalAuditInsertInput>;
  on_conflict?: InputMaybe<ApprovalAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalAuditOneArgs = {
  object: ApprovalAuditInsertInput;
  on_conflict?: InputMaybe<ApprovalAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalInFlightEditRuleArgs = {
  objects: Array<ApprovalInFlightEditRuleInsertInput>;
  on_conflict?: InputMaybe<ApprovalInFlightEditRuleOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalInFlightEditRuleOneArgs = {
  object: ApprovalInFlightEditRuleInsertInput;
  on_conflict?: InputMaybe<ApprovalInFlightEditRuleOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalLevelArgs = {
  objects: Array<ApprovalLevelInsertInput>;
  on_conflict?: InputMaybe<ApprovalLevelOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalLevelAuditArgs = {
  objects: Array<ApprovalLevelAuditInsertInput>;
  on_conflict?: InputMaybe<ApprovalLevelAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalLevelAuditOneArgs = {
  object: ApprovalLevelAuditInsertInput;
  on_conflict?: InputMaybe<ApprovalLevelAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalLevelOneArgs = {
  object: ApprovalLevelInsertInput;
  on_conflict?: InputMaybe<ApprovalLevelOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalOneArgs = {
  object: ApprovalInsertInput;
  on_conflict?: InputMaybe<ApprovalOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalRuleTypeArgs = {
  objects: Array<ApprovalRuleTypeInsertInput>;
  on_conflict?: InputMaybe<ApprovalRuleTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalRuleTypeOneArgs = {
  object: ApprovalRuleTypeInsertInput;
  on_conflict?: InputMaybe<ApprovalRuleTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalStatusArgs = {
  objects: Array<ApprovalStatusInsertInput>;
  on_conflict?: InputMaybe<ApprovalStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertApprovalStatusOneArgs = {
  object: ApprovalStatusInsertInput;
  on_conflict?: InputMaybe<ApprovalStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertApproverArgs = {
  objects: Array<ApproverInsertInput>;
  on_conflict?: InputMaybe<ApproverOnConflict>;
};


/** mutation root */
export type MutationRootInsertApproverAuditArgs = {
  objects: Array<ApproverAuditInsertInput>;
  on_conflict?: InputMaybe<ApproverAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertApproverAuditOneArgs = {
  object: ApproverAuditInsertInput;
  on_conflict?: InputMaybe<ApproverAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertApproverOneArgs = {
  object: ApproverInsertInput;
  on_conflict?: InputMaybe<ApproverOnConflict>;
};


/** mutation root */
export type MutationRootInsertApproverResponseArgs = {
  objects: Array<ApproverResponseInsertInput>;
  on_conflict?: InputMaybe<ApproverResponseOnConflict>;
};


/** mutation root */
export type MutationRootInsertApproverResponseOneArgs = {
  object: ApproverResponseInsertInput;
  on_conflict?: InputMaybe<ApproverResponseOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentArgs = {
  objects: Array<AssessmentInsertInput>;
  on_conflict?: InputMaybe<AssessmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityArgs = {
  objects: Array<AssessmentActivityInsertInput>;
  on_conflict?: InputMaybe<AssessmentActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityAuditArgs = {
  objects: Array<AssessmentActivityAuditInsertInput>;
  on_conflict?: InputMaybe<AssessmentActivityAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityAuditOneArgs = {
  object: AssessmentActivityAuditInsertInput;
  on_conflict?: InputMaybe<AssessmentActivityAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityOneArgs = {
  object: AssessmentActivityInsertInput;
  on_conflict?: InputMaybe<AssessmentActivityOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityStatusArgs = {
  objects: Array<AssessmentActivityStatusInsertInput>;
  on_conflict?: InputMaybe<AssessmentActivityStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityStatusOneArgs = {
  object: AssessmentActivityStatusInsertInput;
  on_conflict?: InputMaybe<AssessmentActivityStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityTypeArgs = {
  objects: Array<AssessmentActivityTypeInsertInput>;
  on_conflict?: InputMaybe<AssessmentActivityTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentActivityTypeOneArgs = {
  object: AssessmentActivityTypeInsertInput;
  on_conflict?: InputMaybe<AssessmentActivityTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentAuditArgs = {
  objects: Array<AssessmentAuditInsertInput>;
  on_conflict?: InputMaybe<AssessmentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentAuditOneArgs = {
  object: AssessmentAuditInsertInput;
  on_conflict?: InputMaybe<AssessmentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentOneArgs = {
  object: AssessmentInsertInput;
  on_conflict?: InputMaybe<AssessmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentResultParentArgs = {
  objects: Array<AssessmentResultParentInsertInput>;
  on_conflict?: InputMaybe<AssessmentResultParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentResultParentAuditArgs = {
  objects: Array<AssessmentResultParentAuditInsertInput>;
  on_conflict?: InputMaybe<AssessmentResultParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentResultParentAuditOneArgs = {
  object: AssessmentResultParentAuditInsertInput;
  on_conflict?: InputMaybe<AssessmentResultParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentResultParentOneArgs = {
  object: AssessmentResultParentInsertInput;
  on_conflict?: InputMaybe<AssessmentResultParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentStatusArgs = {
  objects: Array<AssessmentStatusInsertInput>;
  on_conflict?: InputMaybe<AssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAssessmentStatusOneArgs = {
  object: AssessmentStatusInsertInput;
  on_conflict?: InputMaybe<AssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationConfigArgs = {
  objects: Array<AttestationConfigInsertInput>;
  on_conflict?: InputMaybe<AttestationConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationConfigOneArgs = {
  object: AttestationConfigInsertInput;
  on_conflict?: InputMaybe<AttestationConfigOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationGroupArgs = {
  objects: Array<AttestationGroupInsertInput>;
  on_conflict?: InputMaybe<AttestationGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationGroupOneArgs = {
  object: AttestationGroupInsertInput;
  on_conflict?: InputMaybe<AttestationGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationRecordArgs = {
  objects: Array<AttestationRecordInsertInput>;
  on_conflict?: InputMaybe<AttestationRecordOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationRecordOneArgs = {
  object: AttestationRecordInsertInput;
  on_conflict?: InputMaybe<AttestationRecordOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationRecordStatusArgs = {
  objects: Array<AttestationRecordStatusInsertInput>;
  on_conflict?: InputMaybe<AttestationRecordStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAttestationRecordStatusOneArgs = {
  object: AttestationRecordStatusInsertInput;
  on_conflict?: InputMaybe<AttestationRecordStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationArgs = {
  objects: Array<AuthOrganisationInsertInput>;
  on_conflict?: InputMaybe<AuthOrganisationOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationAuditArgs = {
  objects: Array<AuthOrganisationAuditInsertInput>;
  on_conflict?: InputMaybe<AuthOrganisationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationAuditOneArgs = {
  object: AuthOrganisationAuditInsertInput;
  on_conflict?: InputMaybe<AuthOrganisationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationOneArgs = {
  object: AuthOrganisationInsertInput;
  on_conflict?: InputMaybe<AuthOrganisationOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationuserArgs = {
  objects: Array<AuthOrganisationuserInsertInput>;
  on_conflict?: InputMaybe<AuthOrganisationuserOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationuserAuditArgs = {
  objects: Array<AuthOrganisationuserAuditInsertInput>;
  on_conflict?: InputMaybe<AuthOrganisationuserAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationuserAuditOneArgs = {
  object: AuthOrganisationuserAuditInsertInput;
  on_conflict?: InputMaybe<AuthOrganisationuserAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthOrganisationuserOneArgs = {
  object: AuthOrganisationuserInsertInput;
  on_conflict?: InputMaybe<AuthOrganisationuserOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserArgs = {
  objects: Array<AuthUserInsertInput>;
  on_conflict?: InputMaybe<AuthUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserActivityAuditArgs = {
  objects: Array<AuthUserActivityAuditInsertInput>;
  on_conflict?: InputMaybe<AuthUserActivityAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserActivityAuditOneArgs = {
  object: AuthUserActivityAuditInsertInput;
  on_conflict?: InputMaybe<AuthUserActivityAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserAuditArgs = {
  objects: Array<AuthUserAuditInsertInput>;
  on_conflict?: InputMaybe<AuthUserAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserAuditOneArgs = {
  object: AuthUserAuditInsertInput;
  on_conflict?: InputMaybe<AuthUserAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertAuthUserOneArgs = {
  object: AuthUserInsertInput;
  on_conflict?: InputMaybe<AuthUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertBusinessAreaArgs = {
  objects: Array<BusinessAreaInsertInput>;
  on_conflict?: InputMaybe<BusinessAreaOnConflict>;
};


/** mutation root */
export type MutationRootInsertBusinessAreaOneArgs = {
  object: BusinessAreaInsertInput;
  on_conflict?: InputMaybe<BusinessAreaOnConflict>;
};


/** mutation root */
export type MutationRootInsertCauseArgs = {
  objects: Array<CauseInsertInput>;
  on_conflict?: InputMaybe<CauseOnConflict>;
};


/** mutation root */
export type MutationRootInsertCauseAuditArgs = {
  objects: Array<CauseAuditInsertInput>;
  on_conflict?: InputMaybe<CauseAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCauseAuditOneArgs = {
  object: CauseAuditInsertInput;
  on_conflict?: InputMaybe<CauseAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCauseOneArgs = {
  object: CauseInsertInput;
  on_conflict?: InputMaybe<CauseOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeRequestArgs = {
  objects: Array<ChangeRequestInsertInput>;
  on_conflict?: InputMaybe<ChangeRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeRequestAuditArgs = {
  objects: Array<ChangeRequestAuditInsertInput>;
  on_conflict?: InputMaybe<ChangeRequestAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeRequestAuditOneArgs = {
  object: ChangeRequestAuditInsertInput;
  on_conflict?: InputMaybe<ChangeRequestAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeRequestContributorArgs = {
  objects: Array<ChangeRequestContributorInsertInput>;
  on_conflict?: InputMaybe<ChangeRequestContributorOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeRequestContributorOneArgs = {
  object: ChangeRequestContributorInsertInput;
  on_conflict?: InputMaybe<ChangeRequestContributorOnConflict>;
};


/** mutation root */
export type MutationRootInsertChangeRequestOneArgs = {
  object: ChangeRequestInsertInput;
  on_conflict?: InputMaybe<ChangeRequestOnConflict>;
};


/** mutation root */
export type MutationRootInsertCommentArgs = {
  objects: Array<CommentInsertInput>;
  on_conflict?: InputMaybe<CommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertCommentAuditArgs = {
  objects: Array<CommentAuditInsertInput>;
  on_conflict?: InputMaybe<CommentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCommentAuditOneArgs = {
  object: CommentAuditInsertInput;
  on_conflict?: InputMaybe<CommentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCommentOneArgs = {
  object: CommentInsertInput;
  on_conflict?: InputMaybe<CommentOnConflict>;
};


/** mutation root */
export type MutationRootInsertComplianceMonitoringAssessmentArgs = {
  objects: Array<ComplianceMonitoringAssessmentInsertInput>;
  on_conflict?: InputMaybe<ComplianceMonitoringAssessmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertComplianceMonitoringAssessmentOneArgs = {
  object: ComplianceMonitoringAssessmentInsertInput;
  on_conflict?: InputMaybe<ComplianceMonitoringAssessmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertConfigEnvArgs = {
  objects: Array<ConfigEnvInsertInput>;
  on_conflict?: InputMaybe<ConfigEnvOnConflict>;
};


/** mutation root */
export type MutationRootInsertConfigEnvOneArgs = {
  object: ConfigEnvInsertInput;
  on_conflict?: InputMaybe<ConfigEnvOnConflict>;
};


/** mutation root */
export type MutationRootInsertConsequenceArgs = {
  objects: Array<ConsequenceInsertInput>;
  on_conflict?: InputMaybe<ConsequenceOnConflict>;
};


/** mutation root */
export type MutationRootInsertConsequenceAuditArgs = {
  objects: Array<ConsequenceAuditInsertInput>;
  on_conflict?: InputMaybe<ConsequenceAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertConsequenceAuditOneArgs = {
  object: ConsequenceAuditInsertInput;
  on_conflict?: InputMaybe<ConsequenceAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertConsequenceOneArgs = {
  object: ConsequenceInsertInput;
  on_conflict?: InputMaybe<ConsequenceOnConflict>;
};


/** mutation root */
export type MutationRootInsertConsequenceTypeArgs = {
  objects: Array<ConsequenceTypeInsertInput>;
  on_conflict?: InputMaybe<ConsequenceTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertConsequenceTypeOneArgs = {
  object: ConsequenceTypeInsertInput;
  on_conflict?: InputMaybe<ConsequenceTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorArgs = {
  objects: Array<ContributorInsertInput>;
  on_conflict?: InputMaybe<ContributorOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorAuditArgs = {
  objects: Array<ContributorAuditInsertInput>;
  on_conflict?: InputMaybe<ContributorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorAuditOneArgs = {
  object: ContributorAuditInsertInput;
  on_conflict?: InputMaybe<ContributorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorGroupArgs = {
  objects: Array<ContributorGroupInsertInput>;
  on_conflict?: InputMaybe<ContributorGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorGroupAuditArgs = {
  objects: Array<ContributorGroupAuditInsertInput>;
  on_conflict?: InputMaybe<ContributorGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorGroupAuditOneArgs = {
  object: ContributorGroupAuditInsertInput;
  on_conflict?: InputMaybe<ContributorGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorGroupOneArgs = {
  object: ContributorGroupInsertInput;
  on_conflict?: InputMaybe<ContributorGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorOneArgs = {
  object: ContributorInsertInput;
  on_conflict?: InputMaybe<ContributorOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorTypeArgs = {
  objects: Array<ContributorTypeInsertInput>;
  on_conflict?: InputMaybe<ContributorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertContributorTypeOneArgs = {
  object: ContributorTypeInsertInput;
  on_conflict?: InputMaybe<ContributorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlArgs = {
  objects: Array<ControlInsertInput>;
  on_conflict?: InputMaybe<ControlOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlAuditArgs = {
  objects: Array<ControlAuditInsertInput>;
  on_conflict?: InputMaybe<ControlAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlAuditOneArgs = {
  object: ControlAuditInsertInput;
  on_conflict?: InputMaybe<ControlAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlGroupArgs = {
  objects: Array<ControlGroupInsertInput>;
  on_conflict?: InputMaybe<ControlGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlGroupAuditArgs = {
  objects: Array<ControlGroupAuditInsertInput>;
  on_conflict?: InputMaybe<ControlGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlGroupAuditOneArgs = {
  object: ControlGroupAuditInsertInput;
  on_conflict?: InputMaybe<ControlGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlGroupOneArgs = {
  object: ControlGroupInsertInput;
  on_conflict?: InputMaybe<ControlGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlOneArgs = {
  object: ControlInsertInput;
  on_conflict?: InputMaybe<ControlOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlParentArgs = {
  objects: Array<ControlParentInsertInput>;
  on_conflict?: InputMaybe<ControlParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlParentAuditArgs = {
  objects: Array<ControlParentAuditInsertInput>;
  on_conflict?: InputMaybe<ControlParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlParentAuditOneArgs = {
  object: ControlParentAuditInsertInput;
  on_conflict?: InputMaybe<ControlParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlParentOneArgs = {
  object: ControlParentInsertInput;
  on_conflict?: InputMaybe<ControlParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlTestFrequencyArgs = {
  objects: Array<ControlTestFrequencyInsertInput>;
  on_conflict?: InputMaybe<ControlTestFrequencyOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlTestFrequencyOneArgs = {
  object: ControlTestFrequencyInsertInput;
  on_conflict?: InputMaybe<ControlTestFrequencyOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlTypeArgs = {
  objects: Array<ControlTypeInsertInput>;
  on_conflict?: InputMaybe<ControlTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertControlTypeOneArgs = {
  object: ControlTypeInsertInput;
  on_conflict?: InputMaybe<ControlTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertConversationArgs = {
  objects: Array<ConversationInsertInput>;
  on_conflict?: InputMaybe<ConversationOnConflict>;
};


/** mutation root */
export type MutationRootInsertConversationAuditArgs = {
  objects: Array<ConversationAuditInsertInput>;
  on_conflict?: InputMaybe<ConversationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertConversationAuditOneArgs = {
  object: ConversationAuditInsertInput;
  on_conflict?: InputMaybe<ConversationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertConversationOneArgs = {
  object: ConversationInsertInput;
  on_conflict?: InputMaybe<ConversationOnConflict>;
};


/** mutation root */
export type MutationRootInsertCostTypeArgs = {
  objects: Array<CostTypeInsertInput>;
  on_conflict?: InputMaybe<CostTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCostTypeOneArgs = {
  object: CostTypeInsertInput;
  on_conflict?: InputMaybe<CostTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomAttributeSchemaArgs = {
  objects: Array<CustomAttributeSchemaInsertInput>;
  on_conflict?: InputMaybe<CustomAttributeSchemaOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomAttributeSchemaAuditArgs = {
  objects: Array<CustomAttributeSchemaAuditInsertInput>;
  on_conflict?: InputMaybe<CustomAttributeSchemaAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomAttributeSchemaAuditOneArgs = {
  object: CustomAttributeSchemaAuditInsertInput;
  on_conflict?: InputMaybe<CustomAttributeSchemaAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomAttributeSchemaOneArgs = {
  object: CustomAttributeSchemaInsertInput;
  on_conflict?: InputMaybe<CustomAttributeSchemaOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomRibbonArgs = {
  objects: Array<CustomRibbonInsertInput>;
  on_conflict?: InputMaybe<CustomRibbonOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomRibbonAuditArgs = {
  objects: Array<CustomRibbonAuditInsertInput>;
  on_conflict?: InputMaybe<CustomRibbonAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomRibbonAuditOneArgs = {
  object: CustomRibbonAuditInsertInput;
  on_conflict?: InputMaybe<CustomRibbonAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertCustomRibbonOneArgs = {
  object: CustomRibbonInsertInput;
  on_conflict?: InputMaybe<CustomRibbonOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardArgs = {
  objects: Array<DashboardInsertInput>;
  on_conflict?: InputMaybe<DashboardOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardAuditArgs = {
  objects: Array<DashboardAuditInsertInput>;
  on_conflict?: InputMaybe<DashboardAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardAuditOneArgs = {
  object: DashboardAuditInsertInput;
  on_conflict?: InputMaybe<DashboardAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardOneArgs = {
  object: DashboardInsertInput;
  on_conflict?: InputMaybe<DashboardOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardSharingTypeArgs = {
  objects: Array<DashboardSharingTypeInsertInput>;
  on_conflict?: InputMaybe<DashboardSharingTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDashboardSharingTypeOneArgs = {
  object: DashboardSharingTypeInsertInput;
  on_conflict?: InputMaybe<DashboardSharingTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportArgs = {
  objects: Array<DataImportInsertInput>;
  on_conflict?: InputMaybe<DataImportOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportAuditArgs = {
  objects: Array<DataImportAuditInsertInput>;
  on_conflict?: InputMaybe<DataImportAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportAuditOneArgs = {
  object: DataImportAuditInsertInput;
  on_conflict?: InputMaybe<DataImportAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportErrorArgs = {
  objects: Array<DataImportErrorInsertInput>;
  on_conflict?: InputMaybe<DataImportErrorOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportErrorAuditArgs = {
  objects: Array<DataImportErrorAuditInsertInput>;
  on_conflict?: InputMaybe<DataImportErrorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportErrorAuditOneArgs = {
  object: DataImportErrorAuditInsertInput;
  on_conflict?: InputMaybe<DataImportErrorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportErrorOneArgs = {
  object: DataImportErrorInsertInput;
  on_conflict?: InputMaybe<DataImportErrorOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportOneArgs = {
  object: DataImportInsertInput;
  on_conflict?: InputMaybe<DataImportOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportStatusArgs = {
  objects: Array<DataImportStatusInsertInput>;
  on_conflict?: InputMaybe<DataImportStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDataImportStatusOneArgs = {
  object: DataImportStatusInsertInput;
  on_conflict?: InputMaybe<DataImportStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentArgs = {
  objects: Array<DepartmentInsertInput>;
  on_conflict?: InputMaybe<DepartmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentAuditArgs = {
  objects: Array<DepartmentAuditInsertInput>;
  on_conflict?: InputMaybe<DepartmentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentAuditOneArgs = {
  object: DepartmentAuditInsertInput;
  on_conflict?: InputMaybe<DepartmentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentOneArgs = {
  object: DepartmentInsertInput;
  on_conflict?: InputMaybe<DepartmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeArgs = {
  objects: Array<DepartmentTypeInsertInput>;
  on_conflict?: InputMaybe<DepartmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeAuditArgs = {
  objects: Array<DepartmentTypeAuditInsertInput>;
  on_conflict?: InputMaybe<DepartmentTypeAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeAuditOneArgs = {
  object: DepartmentTypeAuditInsertInput;
  on_conflict?: InputMaybe<DepartmentTypeAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeGroupArgs = {
  objects: Array<DepartmentTypeGroupInsertInput>;
  on_conflict?: InputMaybe<DepartmentTypeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeGroupAuditArgs = {
  objects: Array<DepartmentTypeGroupAuditInsertInput>;
  on_conflict?: InputMaybe<DepartmentTypeGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeGroupAuditOneArgs = {
  object: DepartmentTypeGroupAuditInsertInput;
  on_conflict?: InputMaybe<DepartmentTypeGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeGroupOneArgs = {
  object: DepartmentTypeGroupInsertInput;
  on_conflict?: InputMaybe<DepartmentTypeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertDepartmentTypeOneArgs = {
  object: DepartmentTypeInsertInput;
  on_conflict?: InputMaybe<DepartmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentArgs = {
  objects: Array<DocumentInsertInput>;
  on_conflict?: InputMaybe<DocumentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentActionAuditArgs = {
  objects: Array<DocumentActionAuditInsertInput>;
  on_conflict?: InputMaybe<DocumentActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentActionAuditOneArgs = {
  object: DocumentActionAuditInsertInput;
  on_conflict?: InputMaybe<DocumentActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAssessmentResultArgs = {
  objects: Array<DocumentAssessmentResultInsertInput>;
  on_conflict?: InputMaybe<DocumentAssessmentResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAssessmentResultAuditArgs = {
  objects: Array<DocumentAssessmentResultAuditInsertInput>;
  on_conflict?: InputMaybe<DocumentAssessmentResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAssessmentResultAuditOneArgs = {
  object: DocumentAssessmentResultAuditInsertInput;
  on_conflict?: InputMaybe<DocumentAssessmentResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAssessmentResultOneArgs = {
  object: DocumentAssessmentResultInsertInput;
  on_conflict?: InputMaybe<DocumentAssessmentResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAssessmentStatusArgs = {
  objects: Array<DocumentAssessmentStatusInsertInput>;
  on_conflict?: InputMaybe<DocumentAssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAssessmentStatusOneArgs = {
  object: DocumentAssessmentStatusInsertInput;
  on_conflict?: InputMaybe<DocumentAssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAuditArgs = {
  objects: Array<DocumentAuditInsertInput>;
  on_conflict?: InputMaybe<DocumentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentAuditOneArgs = {
  object: DocumentAuditInsertInput;
  on_conflict?: InputMaybe<DocumentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentFileArgs = {
  objects: Array<DocumentFileInsertInput>;
  on_conflict?: InputMaybe<DocumentFileOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentFileAuditArgs = {
  objects: Array<DocumentFileAuditInsertInput>;
  on_conflict?: InputMaybe<DocumentFileAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentFileAuditOneArgs = {
  object: DocumentFileAuditInsertInput;
  on_conflict?: InputMaybe<DocumentFileAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentFileOneArgs = {
  object: DocumentFileInsertInput;
  on_conflict?: InputMaybe<DocumentFileOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentFileTypeArgs = {
  objects: Array<DocumentFileTypeInsertInput>;
  on_conflict?: InputMaybe<DocumentFileTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentFileTypeOneArgs = {
  object: DocumentFileTypeInsertInput;
  on_conflict?: InputMaybe<DocumentFileTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentIssueAuditArgs = {
  objects: Array<DocumentIssueAuditInsertInput>;
  on_conflict?: InputMaybe<DocumentIssueAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentIssueAuditOneArgs = {
  object: DocumentIssueAuditInsertInput;
  on_conflict?: InputMaybe<DocumentIssueAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentLinkedDocumentArgs = {
  objects: Array<DocumentLinkedDocumentInsertInput>;
  on_conflict?: InputMaybe<DocumentLinkedDocumentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentLinkedDocumentAuditArgs = {
  objects: Array<DocumentLinkedDocumentAuditInsertInput>;
  on_conflict?: InputMaybe<DocumentLinkedDocumentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentLinkedDocumentAuditOneArgs = {
  object: DocumentLinkedDocumentAuditInsertInput;
  on_conflict?: InputMaybe<DocumentLinkedDocumentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentLinkedDocumentOneArgs = {
  object: DocumentLinkedDocumentInsertInput;
  on_conflict?: InputMaybe<DocumentLinkedDocumentOnConflict>;
};


/** mutation root */
export type MutationRootInsertDocumentOneArgs = {
  object: DocumentInsertInput;
  on_conflict?: InputMaybe<DocumentOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileArgs = {
  objects: Array<FileInsertInput>;
  on_conflict?: InputMaybe<FileOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileAuditArgs = {
  objects: Array<FileAuditInsertInput>;
  on_conflict?: InputMaybe<FileAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileAuditOneArgs = {
  object: FileAuditInsertInput;
  on_conflict?: InputMaybe<FileAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFileOneArgs = {
  object: FileInsertInput;
  on_conflict?: InputMaybe<FileOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormConfigurationArgs = {
  objects: Array<FormConfigurationInsertInput>;
  on_conflict?: InputMaybe<FormConfigurationOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormConfigurationAuditArgs = {
  objects: Array<FormConfigurationAuditInsertInput>;
  on_conflict?: InputMaybe<FormConfigurationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormConfigurationAuditOneArgs = {
  object: FormConfigurationAuditInsertInput;
  on_conflict?: InputMaybe<FormConfigurationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormConfigurationOneArgs = {
  object: FormConfigurationInsertInput;
  on_conflict?: InputMaybe<FormConfigurationOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldConfigurationArgs = {
  objects: Array<FormFieldConfigurationInsertInput>;
  on_conflict?: InputMaybe<FormFieldConfigurationOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldConfigurationAuditArgs = {
  objects: Array<FormFieldConfigurationAuditInsertInput>;
  on_conflict?: InputMaybe<FormFieldConfigurationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldConfigurationAuditOneArgs = {
  object: FormFieldConfigurationAuditInsertInput;
  on_conflict?: InputMaybe<FormFieldConfigurationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldConfigurationOneArgs = {
  object: FormFieldConfigurationInsertInput;
  on_conflict?: InputMaybe<FormFieldConfigurationOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldOrderingArgs = {
  objects: Array<FormFieldOrderingInsertInput>;
  on_conflict?: InputMaybe<FormFieldOrderingOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldOrderingAuditArgs = {
  objects: Array<FormFieldOrderingAuditInsertInput>;
  on_conflict?: InputMaybe<FormFieldOrderingAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldOrderingAuditOneArgs = {
  object: FormFieldOrderingAuditInsertInput;
  on_conflict?: InputMaybe<FormFieldOrderingAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertFormFieldOrderingOneArgs = {
  object: FormFieldOrderingInsertInput;
  on_conflict?: InputMaybe<FormFieldOrderingOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactArgs = {
  objects: Array<ImpactInsertInput>;
  on_conflict?: InputMaybe<ImpactOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactAuditArgs = {
  objects: Array<ImpactAuditInsertInput>;
  on_conflict?: InputMaybe<ImpactAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactAuditOneArgs = {
  object: ImpactAuditInsertInput;
  on_conflict?: InputMaybe<ImpactAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactOneArgs = {
  object: ImpactInsertInput;
  on_conflict?: InputMaybe<ImpactOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactParentArgs = {
  objects: Array<ImpactParentInsertInput>;
  on_conflict?: InputMaybe<ImpactParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactParentAuditArgs = {
  objects: Array<ImpactParentAuditInsertInput>;
  on_conflict?: InputMaybe<ImpactParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactParentAuditOneArgs = {
  object: ImpactParentAuditInsertInput;
  on_conflict?: InputMaybe<ImpactParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactParentOneArgs = {
  object: ImpactParentInsertInput;
  on_conflict?: InputMaybe<ImpactParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactRatingArgs = {
  objects: Array<ImpactRatingInsertInput>;
  on_conflict?: InputMaybe<ImpactRatingOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactRatingAuditArgs = {
  objects: Array<ImpactRatingAuditInsertInput>;
  on_conflict?: InputMaybe<ImpactRatingAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactRatingAuditOneArgs = {
  object: ImpactRatingAuditInsertInput;
  on_conflict?: InputMaybe<ImpactRatingAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertImpactRatingOneArgs = {
  object: ImpactRatingInsertInput;
  on_conflict?: InputMaybe<ImpactRatingOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorArgs = {
  objects: Array<IndicatorInsertInput>;
  on_conflict?: InputMaybe<IndicatorOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorAuditArgs = {
  objects: Array<IndicatorAuditInsertInput>;
  on_conflict?: InputMaybe<IndicatorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorAuditOneArgs = {
  object: IndicatorAuditInsertInput;
  on_conflict?: InputMaybe<IndicatorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorOneArgs = {
  object: IndicatorInsertInput;
  on_conflict?: InputMaybe<IndicatorOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorParentArgs = {
  objects: Array<IndicatorParentInsertInput>;
  on_conflict?: InputMaybe<IndicatorParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorParentOneArgs = {
  object: IndicatorParentInsertInput;
  on_conflict?: InputMaybe<IndicatorParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorResultArgs = {
  objects: Array<IndicatorResultInsertInput>;
  on_conflict?: InputMaybe<IndicatorResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorResultAuditArgs = {
  objects: Array<IndicatorResultAuditInsertInput>;
  on_conflict?: InputMaybe<IndicatorResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorResultAuditOneArgs = {
  object: IndicatorResultAuditInsertInput;
  on_conflict?: InputMaybe<IndicatorResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorResultOneArgs = {
  object: IndicatorResultInsertInput;
  on_conflict?: InputMaybe<IndicatorResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorTypeArgs = {
  objects: Array<IndicatorTypeInsertInput>;
  on_conflict?: InputMaybe<IndicatorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertIndicatorTypeOneArgs = {
  object: IndicatorTypeInsertInput;
  on_conflict?: InputMaybe<IndicatorTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertInternalAuditEntityArgs = {
  objects: Array<InternalAuditEntityInsertInput>;
  on_conflict?: InputMaybe<InternalAuditEntityOnConflict>;
};


/** mutation root */
export type MutationRootInsertInternalAuditEntityOneArgs = {
  object: InternalAuditEntityInsertInput;
  on_conflict?: InputMaybe<InternalAuditEntityOnConflict>;
};


/** mutation root */
export type MutationRootInsertInternalAuditReportArgs = {
  objects: Array<InternalAuditReportInsertInput>;
  on_conflict?: InputMaybe<InternalAuditReportOnConflict>;
};


/** mutation root */
export type MutationRootInsertInternalAuditReportOneArgs = {
  object: InternalAuditReportInsertInput;
  on_conflict?: InputMaybe<InternalAuditReportOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueArgs = {
  objects: Array<IssueInsertInput>;
  on_conflict?: InputMaybe<IssueOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueActionAuditArgs = {
  objects: Array<IssueActionAuditInsertInput>;
  on_conflict?: InputMaybe<IssueActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueActionAuditOneArgs = {
  object: IssueActionAuditInsertInput;
  on_conflict?: InputMaybe<IssueActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAssessmentArgs = {
  objects: Array<IssueAssessmentInsertInput>;
  on_conflict?: InputMaybe<IssueAssessmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAssessmentAuditArgs = {
  objects: Array<IssueAssessmentAuditInsertInput>;
  on_conflict?: InputMaybe<IssueAssessmentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAssessmentAuditOneArgs = {
  object: IssueAssessmentAuditInsertInput;
  on_conflict?: InputMaybe<IssueAssessmentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAssessmentOneArgs = {
  object: IssueAssessmentInsertInput;
  on_conflict?: InputMaybe<IssueAssessmentOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAssessmentStatusArgs = {
  objects: Array<IssueAssessmentStatusInsertInput>;
  on_conflict?: InputMaybe<IssueAssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAssessmentStatusOneArgs = {
  object: IssueAssessmentStatusInsertInput;
  on_conflict?: InputMaybe<IssueAssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAuditArgs = {
  objects: Array<IssueAuditInsertInput>;
  on_conflict?: InputMaybe<IssueAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueAuditOneArgs = {
  object: IssueAuditInsertInput;
  on_conflict?: InputMaybe<IssueAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueOneArgs = {
  object: IssueInsertInput;
  on_conflict?: InputMaybe<IssueOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueParentArgs = {
  objects: Array<IssueParentInsertInput>;
  on_conflict?: InputMaybe<IssueParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueParentAuditArgs = {
  objects: Array<IssueParentAuditInsertInput>;
  on_conflict?: InputMaybe<IssueParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueParentAuditOneArgs = {
  object: IssueParentAuditInsertInput;
  on_conflict?: InputMaybe<IssueParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueParentOneArgs = {
  object: IssueParentInsertInput;
  on_conflict?: InputMaybe<IssueParentOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueUpdateArgs = {
  objects: Array<IssueUpdateInsertInput>;
  on_conflict?: InputMaybe<IssueUpdateOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueUpdateAuditArgs = {
  objects: Array<IssueUpdateAuditInsertInput>;
  on_conflict?: InputMaybe<IssueUpdateAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueUpdateAuditOneArgs = {
  object: IssueUpdateAuditInsertInput;
  on_conflict?: InputMaybe<IssueUpdateAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertIssueUpdateOneArgs = {
  object: IssueUpdateInsertInput;
  on_conflict?: InputMaybe<IssueUpdateOnConflict>;
};


/** mutation root */
export type MutationRootInsertLinkedItemArgs = {
  objects: Array<LinkedItemInsertInput>;
  on_conflict?: InputMaybe<LinkedItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertLinkedItemOneArgs = {
  object: LinkedItemInsertInput;
  on_conflict?: InputMaybe<LinkedItemOnConflict>;
};


/** mutation root */
export type MutationRootInsertNodeArgs = {
  objects: Array<NodeInsertInput>;
  on_conflict?: InputMaybe<NodeOnConflict>;
};


/** mutation root */
export type MutationRootInsertNodeAncestorArgs = {
  objects: Array<NodeAncestorInsertInput>;
  on_conflict?: InputMaybe<NodeAncestorOnConflict>;
};


/** mutation root */
export type MutationRootInsertNodeAncestorOneArgs = {
  object: NodeAncestorInsertInput;
  on_conflict?: InputMaybe<NodeAncestorOnConflict>;
};


/** mutation root */
export type MutationRootInsertNodeOneArgs = {
  object: NodeInsertInput;
  on_conflict?: InputMaybe<NodeOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationArgs = {
  objects: Array<ObligationInsertInput>;
  on_conflict?: InputMaybe<ObligationOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationActionAuditArgs = {
  objects: Array<ObligationActionAuditInsertInput>;
  on_conflict?: InputMaybe<ObligationActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationActionAuditOneArgs = {
  object: ObligationActionAuditInsertInput;
  on_conflict?: InputMaybe<ObligationActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAssessmentResultArgs = {
  objects: Array<ObligationAssessmentResultInsertInput>;
  on_conflict?: InputMaybe<ObligationAssessmentResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAssessmentResultAuditArgs = {
  objects: Array<ObligationAssessmentResultAuditInsertInput>;
  on_conflict?: InputMaybe<ObligationAssessmentResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAssessmentResultAuditOneArgs = {
  object: ObligationAssessmentResultAuditInsertInput;
  on_conflict?: InputMaybe<ObligationAssessmentResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAssessmentResultOneArgs = {
  object: ObligationAssessmentResultInsertInput;
  on_conflict?: InputMaybe<ObligationAssessmentResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAssessmentStatusArgs = {
  objects: Array<ObligationAssessmentStatusInsertInput>;
  on_conflict?: InputMaybe<ObligationAssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAssessmentStatusOneArgs = {
  object: ObligationAssessmentStatusInsertInput;
  on_conflict?: InputMaybe<ObligationAssessmentStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAuditArgs = {
  objects: Array<ObligationAuditInsertInput>;
  on_conflict?: InputMaybe<ObligationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationAuditOneArgs = {
  object: ObligationAuditInsertInput;
  on_conflict?: InputMaybe<ObligationAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationImpactArgs = {
  objects: Array<ObligationImpactInsertInput>;
  on_conflict?: InputMaybe<ObligationImpactOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationImpactAuditArgs = {
  objects: Array<ObligationImpactAuditInsertInput>;
  on_conflict?: InputMaybe<ObligationImpactAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationImpactAuditOneArgs = {
  object: ObligationImpactAuditInsertInput;
  on_conflict?: InputMaybe<ObligationImpactAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationImpactOneArgs = {
  object: ObligationImpactInsertInput;
  on_conflict?: InputMaybe<ObligationImpactOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationOneArgs = {
  object: ObligationInsertInput;
  on_conflict?: InputMaybe<ObligationOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationTypeArgs = {
  objects: Array<ObligationTypeInsertInput>;
  on_conflict?: InputMaybe<ObligationTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertObligationTypeOneArgs = {
  object: ObligationTypeInsertInput;
  on_conflict?: InputMaybe<ObligationTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerArgs = {
  objects: Array<OwnerInsertInput>;
  on_conflict?: InputMaybe<OwnerOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerAuditArgs = {
  objects: Array<OwnerAuditInsertInput>;
  on_conflict?: InputMaybe<OwnerAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerAuditOneArgs = {
  object: OwnerAuditInsertInput;
  on_conflict?: InputMaybe<OwnerAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerGroupArgs = {
  objects: Array<OwnerGroupInsertInput>;
  on_conflict?: InputMaybe<OwnerGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerGroupAuditArgs = {
  objects: Array<OwnerGroupAuditInsertInput>;
  on_conflict?: InputMaybe<OwnerGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerGroupAuditOneArgs = {
  object: OwnerGroupAuditInsertInput;
  on_conflict?: InputMaybe<OwnerGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerGroupOneArgs = {
  object: OwnerGroupInsertInput;
  on_conflict?: InputMaybe<OwnerGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertOwnerOneArgs = {
  object: OwnerInsertInput;
  on_conflict?: InputMaybe<OwnerOnConflict>;
};


/** mutation root */
export type MutationRootInsertParentTypeArgs = {
  objects: Array<ParentTypeInsertInput>;
  on_conflict?: InputMaybe<ParentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertParentTypeOneArgs = {
  object: ParentTypeInsertInput;
  on_conflict?: InputMaybe<ParentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireInviteArgs = {
  objects: Array<QuestionnaireInviteInsertInput>;
  on_conflict?: InputMaybe<QuestionnaireInviteOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireInviteOneArgs = {
  object: QuestionnaireInviteInsertInput;
  on_conflict?: InputMaybe<QuestionnaireInviteOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireTemplateArgs = {
  objects: Array<QuestionnaireTemplateInsertInput>;
  on_conflict?: InputMaybe<QuestionnaireTemplateOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireTemplateOneArgs = {
  object: QuestionnaireTemplateInsertInput;
  on_conflict?: InputMaybe<QuestionnaireTemplateOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireTemplateVersionArgs = {
  objects: Array<QuestionnaireTemplateVersionInsertInput>;
  on_conflict?: InputMaybe<QuestionnaireTemplateVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireTemplateVersionOneArgs = {
  object: QuestionnaireTemplateVersionInsertInput;
  on_conflict?: InputMaybe<QuestionnaireTemplateVersionOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireTemplateVersionStatusArgs = {
  objects: Array<QuestionnaireTemplateVersionStatusInsertInput>;
  on_conflict?: InputMaybe<QuestionnaireTemplateVersionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertQuestionnaireTemplateVersionStatusOneArgs = {
  object: QuestionnaireTemplateVersionStatusInsertInput;
  on_conflict?: InputMaybe<QuestionnaireTemplateVersionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertRelationFileArgs = {
  objects: Array<RelationFileInsertInput>;
  on_conflict?: InputMaybe<RelationFileOnConflict>;
};


/** mutation root */
export type MutationRootInsertRelationFileAuditArgs = {
  objects: Array<RelationFileAuditInsertInput>;
  on_conflict?: InputMaybe<RelationFileAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRelationFileAuditOneArgs = {
  object: RelationFileAuditInsertInput;
  on_conflict?: InputMaybe<RelationFileAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRelationFileOneArgs = {
  object: RelationFileInsertInput;
  on_conflict?: InputMaybe<RelationFileOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskArgs = {
  objects: Array<RiskInsertInput>;
  on_conflict?: InputMaybe<RiskOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAssessmentResultArgs = {
  objects: Array<RiskAssessmentResultInsertInput>;
  on_conflict?: InputMaybe<RiskAssessmentResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAssessmentResultAuditArgs = {
  objects: Array<RiskAssessmentResultAuditInsertInput>;
  on_conflict?: InputMaybe<RiskAssessmentResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAssessmentResultAuditOneArgs = {
  object: RiskAssessmentResultAuditInsertInput;
  on_conflict?: InputMaybe<RiskAssessmentResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAssessmentResultControlTypeArgs = {
  objects: Array<RiskAssessmentResultControlTypeInsertInput>;
  on_conflict?: InputMaybe<RiskAssessmentResultControlTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAssessmentResultControlTypeOneArgs = {
  object: RiskAssessmentResultControlTypeInsertInput;
  on_conflict?: InputMaybe<RiskAssessmentResultControlTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAssessmentResultOneArgs = {
  object: RiskAssessmentResultInsertInput;
  on_conflict?: InputMaybe<RiskAssessmentResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAuditArgs = {
  objects: Array<RiskAuditInsertInput>;
  on_conflict?: InputMaybe<RiskAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskAuditOneArgs = {
  object: RiskAuditInsertInput;
  on_conflict?: InputMaybe<RiskAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskOneArgs = {
  object: RiskInsertInput;
  on_conflict?: InputMaybe<RiskOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskScoreArgs = {
  objects: Array<RiskScoreInsertInput>;
  on_conflict?: InputMaybe<RiskScoreOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskScoreOneArgs = {
  object: RiskScoreInsertInput;
  on_conflict?: InputMaybe<RiskScoreOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskScoringModelArgs = {
  objects: Array<RiskScoringModelInsertInput>;
  on_conflict?: InputMaybe<RiskScoringModelOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskScoringModelOneArgs = {
  object: RiskScoringModelInsertInput;
  on_conflict?: InputMaybe<RiskScoringModelOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskStatusTypeArgs = {
  objects: Array<RiskStatusTypeInsertInput>;
  on_conflict?: InputMaybe<RiskStatusTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskStatusTypeOneArgs = {
  object: RiskStatusTypeInsertInput;
  on_conflict?: InputMaybe<RiskStatusTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskTreatmentTypeArgs = {
  objects: Array<RiskTreatmentTypeInsertInput>;
  on_conflict?: InputMaybe<RiskTreatmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertRiskTreatmentTypeOneArgs = {
  object: RiskTreatmentTypeInsertInput;
  on_conflict?: InputMaybe<RiskTreatmentTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartApproverResponseAuditArgs = {
  objects: Array<RisksmartApproverResponseAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartApproverResponseAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartApproverResponseAuditOneArgs = {
  object: RisksmartApproverResponseAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartApproverResponseAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartChangeRequestContributorAuditArgs = {
  objects: Array<RisksmartChangeRequestContributorAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartChangeRequestContributorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartChangeRequestContributorAuditOneArgs = {
  object: RisksmartChangeRequestContributorAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartChangeRequestContributorAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartControlActionAuditArgs = {
  objects: Array<RisksmartControlActionAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartControlActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartControlActionAuditOneArgs = {
  object: RisksmartControlActionAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartControlActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartCounterArgs = {
  objects: Array<RisksmartCounterInsertInput>;
  on_conflict?: InputMaybe<RisksmartCounterOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartCounterOneArgs = {
  object: RisksmartCounterInsertInput;
  on_conflict?: InputMaybe<RisksmartCounterOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartIndicatorParentAuditArgs = {
  objects: Array<RisksmartIndicatorParentAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartIndicatorParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartIndicatorParentAuditOneArgs = {
  object: RisksmartIndicatorParentAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartIndicatorParentAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartLinkedItemAuditArgs = {
  objects: Array<RisksmartLinkedItemAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartLinkedItemAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartLinkedItemAuditOneArgs = {
  object: RisksmartLinkedItemAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartLinkedItemAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartObligationIssueAuditArgs = {
  objects: Array<RisksmartObligationIssueAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartObligationIssueAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartObligationIssueAuditOneArgs = {
  object: RisksmartObligationIssueAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartObligationIssueAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartQuestionnaireTemplateAuditArgs = {
  objects: Array<RisksmartQuestionnaireTemplateAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartQuestionnaireTemplateAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartQuestionnaireTemplateAuditOneArgs = {
  object: RisksmartQuestionnaireTemplateAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartQuestionnaireTemplateAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartQuestionnaireTemplateVersionAuditArgs = {
  objects: Array<RisksmartQuestionnaireTemplateVersionAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartQuestionnaireTemplateVersionAuditOneArgs = {
  object: RisksmartQuestionnaireTemplateVersionAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartRiskActionAuditArgs = {
  objects: Array<RisksmartRiskActionAuditInsertInput>;
  on_conflict?: InputMaybe<RisksmartRiskActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRisksmartRiskActionAuditOneArgs = {
  object: RisksmartRiskActionAuditInsertInput;
  on_conflict?: InputMaybe<RisksmartRiskActionAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleAccessArgs = {
  objects: Array<RoleAccessInsertInput>;
  on_conflict?: InputMaybe<RoleAccessOnConflict>;
};


/** mutation root */
export type MutationRootInsertRoleAccessOneArgs = {
  object: RoleAccessInsertInput;
  on_conflict?: InputMaybe<RoleAccessOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagArgs = {
  objects: Array<TagInsertInput>;
  on_conflict?: InputMaybe<TagOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagAuditArgs = {
  objects: Array<TagAuditInsertInput>;
  on_conflict?: InputMaybe<TagAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagAuditOneArgs = {
  object: TagAuditInsertInput;
  on_conflict?: InputMaybe<TagAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagOneArgs = {
  object: TagInsertInput;
  on_conflict?: InputMaybe<TagOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeArgs = {
  objects: Array<TagTypeInsertInput>;
  on_conflict?: InputMaybe<TagTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeAuditArgs = {
  objects: Array<TagTypeAuditInsertInput>;
  on_conflict?: InputMaybe<TagTypeAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeAuditOneArgs = {
  object: TagTypeAuditInsertInput;
  on_conflict?: InputMaybe<TagTypeAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeGroupArgs = {
  objects: Array<TagTypeGroupInsertInput>;
  on_conflict?: InputMaybe<TagTypeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeGroupAuditArgs = {
  objects: Array<TagTypeGroupAuditInsertInput>;
  on_conflict?: InputMaybe<TagTypeGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeGroupAuditOneArgs = {
  object: TagTypeGroupAuditInsertInput;
  on_conflict?: InputMaybe<TagTypeGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeGroupOneArgs = {
  object: TagTypeGroupInsertInput;
  on_conflict?: InputMaybe<TagTypeGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertTagTypeOneArgs = {
  object: TagTypeInsertInput;
  on_conflict?: InputMaybe<TagTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyArgs = {
  objects: Array<TaxonomyInsertInput>;
  on_conflict?: InputMaybe<TaxonomyOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyAuditArgs = {
  objects: Array<TaxonomyAuditInsertInput>;
  on_conflict?: InputMaybe<TaxonomyAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyAuditOneArgs = {
  object: TaxonomyAuditInsertInput;
  on_conflict?: InputMaybe<TaxonomyAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyOneArgs = {
  object: TaxonomyInsertInput;
  on_conflict?: InputMaybe<TaxonomyOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyOrgArgs = {
  objects: Array<TaxonomyOrgInsertInput>;
  on_conflict?: InputMaybe<TaxonomyOrgOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyOrgAuditArgs = {
  objects: Array<TaxonomyOrgAuditInsertInput>;
  on_conflict?: InputMaybe<TaxonomyOrgAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyOrgAuditOneArgs = {
  object: TaxonomyOrgAuditInsertInput;
  on_conflict?: InputMaybe<TaxonomyOrgAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTaxonomyOrgOneArgs = {
  object: TaxonomyOrgInsertInput;
  on_conflict?: InputMaybe<TaxonomyOrgOnConflict>;
};


/** mutation root */
export type MutationRootInsertTestFrequencyArgs = {
  objects: Array<TestFrequencyInsertInput>;
  on_conflict?: InputMaybe<TestFrequencyOnConflict>;
};


/** mutation root */
export type MutationRootInsertTestFrequencyOneArgs = {
  object: TestFrequencyInsertInput;
  on_conflict?: InputMaybe<TestFrequencyOnConflict>;
};


/** mutation root */
export type MutationRootInsertTestResultArgs = {
  objects: Array<TestResultInsertInput>;
  on_conflict?: InputMaybe<TestResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertTestResultAuditArgs = {
  objects: Array<TestResultAuditInsertInput>;
  on_conflict?: InputMaybe<TestResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTestResultAuditOneArgs = {
  object: TestResultAuditInsertInput;
  on_conflict?: InputMaybe<TestResultAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertTestResultOneArgs = {
  object: TestResultInsertInput;
  on_conflict?: InputMaybe<TestResultOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyArgs = {
  objects: Array<ThirdPartyInsertInput>;
  on_conflict?: InputMaybe<ThirdPartyOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyAuditArgs = {
  objects: Array<ThirdPartyAuditInsertInput>;
  on_conflict?: InputMaybe<ThirdPartyAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyAuditOneArgs = {
  object: ThirdPartyAuditInsertInput;
  on_conflict?: InputMaybe<ThirdPartyAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyOneArgs = {
  object: ThirdPartyInsertInput;
  on_conflict?: InputMaybe<ThirdPartyOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyResponseArgs = {
  objects: Array<ThirdPartyResponseInsertInput>;
  on_conflict?: InputMaybe<ThirdPartyResponseOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyResponseOneArgs = {
  object: ThirdPartyResponseInsertInput;
  on_conflict?: InputMaybe<ThirdPartyResponseOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyResponseStatusArgs = {
  objects: Array<ThirdPartyResponseStatusInsertInput>;
  on_conflict?: InputMaybe<ThirdPartyResponseStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyResponseStatusOneArgs = {
  object: ThirdPartyResponseStatusInsertInput;
  on_conflict?: InputMaybe<ThirdPartyResponseStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyStatusArgs = {
  objects: Array<ThirdPartyStatusInsertInput>;
  on_conflict?: InputMaybe<ThirdPartyStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyStatusOneArgs = {
  object: ThirdPartyStatusInsertInput;
  on_conflict?: InputMaybe<ThirdPartyStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyTypeArgs = {
  objects: Array<ThirdPartyTypeInsertInput>;
  on_conflict?: InputMaybe<ThirdPartyTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertThirdPartyTypeOneArgs = {
  object: ThirdPartyTypeInsertInput;
  on_conflict?: InputMaybe<ThirdPartyTypeOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupArgs = {
  objects: Array<UserGroupInsertInput>;
  on_conflict?: InputMaybe<UserGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupAuditArgs = {
  objects: Array<UserGroupAuditInsertInput>;
  on_conflict?: InputMaybe<UserGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupAuditOneArgs = {
  object: UserGroupAuditInsertInput;
  on_conflict?: InputMaybe<UserGroupAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupOneArgs = {
  object: UserGroupInsertInput;
  on_conflict?: InputMaybe<UserGroupOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupUserArgs = {
  objects: Array<UserGroupUserInsertInput>;
  on_conflict?: InputMaybe<UserGroupUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupUserAuditArgs = {
  objects: Array<UserGroupUserAuditInsertInput>;
  on_conflict?: InputMaybe<UserGroupUserAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupUserAuditOneArgs = {
  object: UserGroupUserAuditInsertInput;
  on_conflict?: InputMaybe<UserGroupUserAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserGroupUserOneArgs = {
  object: UserGroupUserInsertInput;
  on_conflict?: InputMaybe<UserGroupUserOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSearchPreferencesArgs = {
  objects: Array<UserSearchPreferencesInsertInput>;
  on_conflict?: InputMaybe<UserSearchPreferencesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSearchPreferencesAuditArgs = {
  objects: Array<UserSearchPreferencesAuditInsertInput>;
  on_conflict?: InputMaybe<UserSearchPreferencesAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSearchPreferencesAuditOneArgs = {
  object: UserSearchPreferencesAuditInsertInput;
  on_conflict?: InputMaybe<UserSearchPreferencesAuditOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserSearchPreferencesOneArgs = {
  object: UserSearchPreferencesInsertInput;
  on_conflict?: InputMaybe<UserSearchPreferencesOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserStatusArgs = {
  objects: Array<UserStatusInsertInput>;
  on_conflict?: InputMaybe<UserStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertUserStatusOneArgs = {
  object: UserStatusInsertInput;
  on_conflict?: InputMaybe<UserStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertVersionStatusArgs = {
  objects: Array<VersionStatusInsertInput>;
  on_conflict?: InputMaybe<VersionStatusOnConflict>;
};


/** mutation root */
export type MutationRootInsertVersionStatusOneArgs = {
  object: VersionStatusInsertInput;
  on_conflict?: InputMaybe<VersionStatusOnConflict>;
};


/** mutation root */
export type MutationRootLinkItemsArgs = {
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Targets: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type MutationRootOverrideChangeRequestArgs = {
  Approved: Scalars['Boolean']['input'];
  Id: Scalars['uuid']['input'];
  Rationale: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootPublishQuestionnaireTemplateVersionArgs = {
  QuestionnaireTemplateId: Scalars['uuid']['input'];
  QuestionnaireTemplateVersionId: Scalars['uuid']['input'];
};


/** mutation root */
export type MutationRootRecalculateRiskScoresArgs = {
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootRisksmartDeleteOrganisationUsersArgs = {
  args: RisksmartDeleteOrganisationUsersArgs;
  distinct_on?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


/** mutation root */
export type MutationRootUnlinkItemsArgs = {
  Ids: Array<Scalars['uuid']['input']>;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityWithLinkedItemsArgs = {
  ActivityType: AssessmentActivityTypeEnum;
  AssignedUser?: InputMaybe<Scalars['String']['input']>;
  CompletionDate?: InputMaybe<Scalars['timestamptz']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id: Scalars['uuid']['input'];
  LinkedItemIds: Array<Scalars['uuid']['input']>;
  OriginalTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  Status: AssessmentActivityStatusEnum;
  Summary: Scalars['String']['input'];
  Title: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootUpdateChildAcceptanceArgs = {
  ApprovedByUser?: InputMaybe<Scalars['String']['input']>;
  ApprovedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateAcceptedFrom: Scalars['timestamptz']['input'];
  DateAcceptedTo: Scalars['timestamptz']['input'];
  Details?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  LatestModifiedAtTimestamp: Scalars['timestamptz']['input'];
  RequestedByUser?: InputMaybe<Scalars['String']['input']>;
  RequestedByUserGroup?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<AcceptanceStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootUpdateChildActionArgs = {
  ClosedDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DateDue: Scalars['timestamptz']['input'];
  DateRaised: Scalars['timestamptz']['input'];
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  Priority?: InputMaybe<Scalars['Int']['input']>;
  Status: ActionStatusEnum;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  Title: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootUpdateChildControlArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OriginalTimestamp: Scalars['timestamptz']['input'];
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TestFrequency?: InputMaybe<ControlTestFrequencyEnum>;
  Title: Scalars['String']['input'];
  Type: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootUpdateChildDashboardArgs = {
  Content: Scalars['jsonb']['input'];
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id: Scalars['uuid']['input'];
  Name: Scalars['String']['input'];
  Sharing: DashboardSharingTypeEnumAction;
};


/** mutation root */
export type MutationRootUpdateChildIssueAssessmentArgs = {
  ActualCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  AssociatedControlIds: Array<Scalars['uuid']['input']>;
  CertifiedIndividual?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Id: Scalars['uuid']['input'];
  IssueCausedBySystemIssue?: InputMaybe<Scalars['Boolean']['input']>;
  IssueCausedByThirdParty?: InputMaybe<Scalars['Boolean']['input']>;
  IssueType?: InputMaybe<Scalars['String']['input']>;
  OriginalTimestamp: Scalars['timestamptz']['input'];
  PoliciesBreached?: InputMaybe<Scalars['String']['input']>;
  PoliciesBreachedIds: Array<Scalars['uuid']['input']>;
  PolicyBreach?: InputMaybe<Scalars['Boolean']['input']>;
  PolicyOwner?: InputMaybe<Scalars['String']['input']>;
  PolicyOwnerCommentary?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreached?: InputMaybe<Scalars['String']['input']>;
  RegulationsBreachedIds: Array<Scalars['uuid']['input']>;
  RegulatoryBreach?: InputMaybe<Scalars['Boolean']['input']>;
  Reportable?: InputMaybe<Scalars['Boolean']['input']>;
  Severity?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<IssueAssessmentStatusEnum>;
  SystemResponsible?: InputMaybe<Scalars['String']['input']>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TargetCloseDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ThirdPartyResponsible?: InputMaybe<Scalars['String']['input']>;
};


/** mutation root */
export type MutationRootUpdateChildRiskArgs = {
  ContributorGroupIds: Array<Scalars['uuid']['input']>;
  ContributorUserIds: Array<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  DepartmentTypeIds: Array<Scalars['uuid']['input']>;
  Description: Scalars['String']['input'];
  Id: Scalars['uuid']['input'];
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OwnerGroupIds: Array<Scalars['uuid']['input']>;
  OwnerUserIds: Array<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  Status?: InputMaybe<RiskStatusTypeEnum>;
  TagTypeIds: Array<Scalars['uuid']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Tier: Scalars['Int']['input'];
  Title: Scalars['String']['input'];
  Treatment?: InputMaybe<RiskTreatmentTypeEnum>;
};


/** mutation root */
export type MutationRootUpdateChildRiskAssessmentResultArgs = {
  AssessmentId?: InputMaybe<Scalars['uuid']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id: Scalars['uuid']['input'];
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};


/** mutation root */
export type MutationRootUpdateDocumentVersionArgs = {
  Content?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Id: Scalars['uuid']['input'];
  LatestModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Link?: InputMaybe<Scalars['String']['input']>;
  NextReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReasonForReview?: InputMaybe<Scalars['String']['input']>;
  ReviewDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ReviewedBy?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<VersionStatusEnum>;
  Summary?: InputMaybe<Scalars['String']['input']>;
  Type: DocumentFileTypeEnum;
  Version: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootUpdateInternalAuditArgs = {
  Input?: InputMaybe<UpdateInternalAuditInput>;
};


/** mutation root */
export type MutationRootUpdateNotificationPreferencesArgs = {
  preferenceSet: UpdateNotificationPreferencesInput;
};


/** mutation root */
export type MutationRootUpdateAcceptanceArgs = {
  _append?: InputMaybe<AcceptanceAppendInput>;
  _delete_at_path?: InputMaybe<AcceptanceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AcceptanceDeleteElemInput>;
  _delete_key?: InputMaybe<AcceptanceDeleteKeyInput>;
  _inc?: InputMaybe<AcceptanceIncInput>;
  _prepend?: InputMaybe<AcceptancePrependInput>;
  _set?: InputMaybe<AcceptanceSetInput>;
  where: AcceptanceBoolExp;
};


/** mutation root */
export type MutationRootUpdateAcceptanceAuditArgs = {
  _append?: InputMaybe<AcceptanceAuditAppendInput>;
  _delete_at_path?: InputMaybe<AcceptanceAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AcceptanceAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AcceptanceAuditDeleteKeyInput>;
  _inc?: InputMaybe<AcceptanceAuditIncInput>;
  _prepend?: InputMaybe<AcceptanceAuditPrependInput>;
  _set?: InputMaybe<AcceptanceAuditSetInput>;
  where: AcceptanceAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAcceptanceAuditByPkArgs = {
  _append?: InputMaybe<AcceptanceAuditAppendInput>;
  _delete_at_path?: InputMaybe<AcceptanceAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AcceptanceAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AcceptanceAuditDeleteKeyInput>;
  _inc?: InputMaybe<AcceptanceAuditIncInput>;
  _prepend?: InputMaybe<AcceptanceAuditPrependInput>;
  _set?: InputMaybe<AcceptanceAuditSetInput>;
  pk_columns: AcceptanceAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAcceptanceAuditManyArgs = {
  updates: Array<AcceptanceAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAcceptanceByPkArgs = {
  _append?: InputMaybe<AcceptanceAppendInput>;
  _delete_at_path?: InputMaybe<AcceptanceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AcceptanceDeleteElemInput>;
  _delete_key?: InputMaybe<AcceptanceDeleteKeyInput>;
  _inc?: InputMaybe<AcceptanceIncInput>;
  _prepend?: InputMaybe<AcceptancePrependInput>;
  _set?: InputMaybe<AcceptanceSetInput>;
  pk_columns: AcceptancePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAcceptanceManyArgs = {
  updates: Array<AcceptanceUpdates>;
};


/** mutation root */
export type MutationRootUpdateAcceptanceParentArgs = {
  _set?: InputMaybe<AcceptanceParentSetInput>;
  where: AcceptanceParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateAcceptanceParentAuditArgs = {
  _set?: InputMaybe<AcceptanceParentAuditSetInput>;
  where: AcceptanceParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAcceptanceParentAuditByPkArgs = {
  _set?: InputMaybe<AcceptanceParentAuditSetInput>;
  pk_columns: AcceptanceParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAcceptanceParentAuditManyArgs = {
  updates: Array<AcceptanceParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAcceptanceParentByPkArgs = {
  _set?: InputMaybe<AcceptanceParentSetInput>;
  pk_columns: AcceptanceParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAcceptanceParentManyArgs = {
  updates: Array<AcceptanceParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateAcceptanceStatusArgs = {
  _set?: InputMaybe<AcceptanceStatusSetInput>;
  where: AcceptanceStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateAcceptanceStatusByPkArgs = {
  _set?: InputMaybe<AcceptanceStatusSetInput>;
  pk_columns: AcceptanceStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAcceptanceStatusManyArgs = {
  updates: Array<AcceptanceStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateAccessTypeArgs = {
  _set?: InputMaybe<AccessTypeSetInput>;
  where: AccessTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAccessTypeByPkArgs = {
  _set?: InputMaybe<AccessTypeSetInput>;
  pk_columns: AccessTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAccessTypeManyArgs = {
  updates: Array<AccessTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionArgs = {
  _append?: InputMaybe<ActionAppendInput>;
  _delete_at_path?: InputMaybe<ActionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionDeleteElemInput>;
  _delete_key?: InputMaybe<ActionDeleteKeyInput>;
  _inc?: InputMaybe<ActionIncInput>;
  _prepend?: InputMaybe<ActionPrependInput>;
  _set?: InputMaybe<ActionSetInput>;
  where: ActionBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionAuditArgs = {
  _append?: InputMaybe<ActionAuditAppendInput>;
  _delete_at_path?: InputMaybe<ActionAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ActionAuditDeleteKeyInput>;
  _inc?: InputMaybe<ActionAuditIncInput>;
  _prepend?: InputMaybe<ActionAuditPrependInput>;
  _set?: InputMaybe<ActionAuditSetInput>;
  where: ActionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionAuditByPkArgs = {
  _append?: InputMaybe<ActionAuditAppendInput>;
  _delete_at_path?: InputMaybe<ActionAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ActionAuditDeleteKeyInput>;
  _inc?: InputMaybe<ActionAuditIncInput>;
  _prepend?: InputMaybe<ActionAuditPrependInput>;
  _set?: InputMaybe<ActionAuditSetInput>;
  pk_columns: ActionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionAuditManyArgs = {
  updates: Array<ActionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionByPkArgs = {
  _append?: InputMaybe<ActionAppendInput>;
  _delete_at_path?: InputMaybe<ActionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionDeleteElemInput>;
  _delete_key?: InputMaybe<ActionDeleteKeyInput>;
  _inc?: InputMaybe<ActionIncInput>;
  _prepend?: InputMaybe<ActionPrependInput>;
  _set?: InputMaybe<ActionSetInput>;
  pk_columns: ActionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionManyArgs = {
  updates: Array<ActionUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionParentArgs = {
  _set?: InputMaybe<ActionParentSetInput>;
  where: ActionParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionParentAuditArgs = {
  _set?: InputMaybe<ActionParentAuditSetInput>;
  where: ActionParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionParentAuditByPkArgs = {
  _set?: InputMaybe<ActionParentAuditSetInput>;
  pk_columns: ActionParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionParentAuditManyArgs = {
  updates: Array<ActionParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionParentByPkArgs = {
  _set?: InputMaybe<ActionParentSetInput>;
  pk_columns: ActionParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionParentManyArgs = {
  updates: Array<ActionParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionStatusArgs = {
  _set?: InputMaybe<ActionStatusSetInput>;
  where: ActionStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionStatusByPkArgs = {
  _set?: InputMaybe<ActionStatusSetInput>;
  pk_columns: ActionStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionStatusManyArgs = {
  updates: Array<ActionStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionUpdateArgs = {
  _append?: InputMaybe<ActionUpdateAppendInput>;
  _delete_at_path?: InputMaybe<ActionUpdateDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionUpdateDeleteElemInput>;
  _delete_key?: InputMaybe<ActionUpdateDeleteKeyInput>;
  _prepend?: InputMaybe<ActionUpdatePrependInput>;
  _set?: InputMaybe<ActionUpdateSetInput>;
  where: ActionUpdateBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionUpdateAuditArgs = {
  _append?: InputMaybe<ActionUpdateAuditAppendInput>;
  _delete_at_path?: InputMaybe<ActionUpdateAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionUpdateAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ActionUpdateAuditDeleteKeyInput>;
  _prepend?: InputMaybe<ActionUpdateAuditPrependInput>;
  _set?: InputMaybe<ActionUpdateAuditSetInput>;
  where: ActionUpdateAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateActionUpdateAuditByPkArgs = {
  _append?: InputMaybe<ActionUpdateAuditAppendInput>;
  _delete_at_path?: InputMaybe<ActionUpdateAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionUpdateAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ActionUpdateAuditDeleteKeyInput>;
  _prepend?: InputMaybe<ActionUpdateAuditPrependInput>;
  _set?: InputMaybe<ActionUpdateAuditSetInput>;
  pk_columns: ActionUpdateAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionUpdateAuditManyArgs = {
  updates: Array<ActionUpdateAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateActionUpdateByPkArgs = {
  _append?: InputMaybe<ActionUpdateAppendInput>;
  _delete_at_path?: InputMaybe<ActionUpdateDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ActionUpdateDeleteElemInput>;
  _delete_key?: InputMaybe<ActionUpdateDeleteKeyInput>;
  _prepend?: InputMaybe<ActionUpdatePrependInput>;
  _set?: InputMaybe<ActionUpdateSetInput>;
  pk_columns: ActionUpdatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateActionUpdateManyArgs = {
  updates: Array<ActionUpdateUpdates>;
};


/** mutation root */
export type MutationRootUpdateAggregationOrgArgs = {
  _append?: InputMaybe<AggregationOrgAppendInput>;
  _delete_at_path?: InputMaybe<AggregationOrgDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AggregationOrgDeleteElemInput>;
  _delete_key?: InputMaybe<AggregationOrgDeleteKeyInput>;
  _prepend?: InputMaybe<AggregationOrgPrependInput>;
  _set?: InputMaybe<AggregationOrgSetInput>;
  where: AggregationOrgBoolExp;
};


/** mutation root */
export type MutationRootUpdateAggregationOrgByPkArgs = {
  _append?: InputMaybe<AggregationOrgAppendInput>;
  _delete_at_path?: InputMaybe<AggregationOrgDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AggregationOrgDeleteElemInput>;
  _delete_key?: InputMaybe<AggregationOrgDeleteKeyInput>;
  _prepend?: InputMaybe<AggregationOrgPrependInput>;
  _set?: InputMaybe<AggregationOrgSetInput>;
  pk_columns: AggregationOrgPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAggregationOrgManyArgs = {
  updates: Array<AggregationOrgUpdates>;
};


/** mutation root */
export type MutationRootUpdateAppetiteArgs = {
  _append?: InputMaybe<AppetiteAppendInput>;
  _delete_at_path?: InputMaybe<AppetiteDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AppetiteDeleteElemInput>;
  _delete_key?: InputMaybe<AppetiteDeleteKeyInput>;
  _inc?: InputMaybe<AppetiteIncInput>;
  _prepend?: InputMaybe<AppetitePrependInput>;
  _set?: InputMaybe<AppetiteSetInput>;
  where: AppetiteBoolExp;
};


/** mutation root */
export type MutationRootUpdateAppetiteAuditArgs = {
  _append?: InputMaybe<AppetiteAuditAppendInput>;
  _delete_at_path?: InputMaybe<AppetiteAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AppetiteAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AppetiteAuditDeleteKeyInput>;
  _inc?: InputMaybe<AppetiteAuditIncInput>;
  _prepend?: InputMaybe<AppetiteAuditPrependInput>;
  _set?: InputMaybe<AppetiteAuditSetInput>;
  where: AppetiteAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAppetiteAuditByPkArgs = {
  _append?: InputMaybe<AppetiteAuditAppendInput>;
  _delete_at_path?: InputMaybe<AppetiteAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AppetiteAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AppetiteAuditDeleteKeyInput>;
  _inc?: InputMaybe<AppetiteAuditIncInput>;
  _prepend?: InputMaybe<AppetiteAuditPrependInput>;
  _set?: InputMaybe<AppetiteAuditSetInput>;
  pk_columns: AppetiteAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAppetiteAuditManyArgs = {
  updates: Array<AppetiteAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAppetiteByPkArgs = {
  _append?: InputMaybe<AppetiteAppendInput>;
  _delete_at_path?: InputMaybe<AppetiteDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AppetiteDeleteElemInput>;
  _delete_key?: InputMaybe<AppetiteDeleteKeyInput>;
  _inc?: InputMaybe<AppetiteIncInput>;
  _prepend?: InputMaybe<AppetitePrependInput>;
  _set?: InputMaybe<AppetiteSetInput>;
  pk_columns: AppetitePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAppetiteManyArgs = {
  updates: Array<AppetiteUpdates>;
};


/** mutation root */
export type MutationRootUpdateAppetiteModelArgs = {
  _set?: InputMaybe<AppetiteModelSetInput>;
  where: AppetiteModelBoolExp;
};


/** mutation root */
export type MutationRootUpdateAppetiteModelByPkArgs = {
  _set?: InputMaybe<AppetiteModelSetInput>;
  pk_columns: AppetiteModelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAppetiteModelManyArgs = {
  updates: Array<AppetiteModelUpdates>;
};


/** mutation root */
export type MutationRootUpdateAppetiteParentArgs = {
  _set?: InputMaybe<AppetiteParentSetInput>;
  where: AppetiteParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateAppetiteParentAuditArgs = {
  _set?: InputMaybe<AppetiteParentAuditSetInput>;
  where: AppetiteParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAppetiteParentAuditByPkArgs = {
  _set?: InputMaybe<AppetiteParentAuditSetInput>;
  pk_columns: AppetiteParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAppetiteParentAuditManyArgs = {
  updates: Array<AppetiteParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAppetiteParentByPkArgs = {
  _set?: InputMaybe<AppetiteParentSetInput>;
  pk_columns: AppetiteParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAppetiteParentManyArgs = {
  updates: Array<AppetiteParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateAppetiteTypeArgs = {
  _set?: InputMaybe<AppetiteTypeSetInput>;
  where: AppetiteTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAppetiteTypeByPkArgs = {
  _set?: InputMaybe<AppetiteTypeSetInput>;
  pk_columns: AppetiteTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAppetiteTypeManyArgs = {
  updates: Array<AppetiteTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalArgs = {
  _set?: InputMaybe<ApprovalSetInput>;
  where: ApprovalBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalAuditArgs = {
  _set?: InputMaybe<ApprovalAuditSetInput>;
  where: ApprovalAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalAuditByPkArgs = {
  _set?: InputMaybe<ApprovalAuditSetInput>;
  pk_columns: ApprovalAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalAuditManyArgs = {
  updates: Array<ApprovalAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalByPkArgs = {
  _set?: InputMaybe<ApprovalSetInput>;
  pk_columns: ApprovalPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalInFlightEditRuleArgs = {
  _set?: InputMaybe<ApprovalInFlightEditRuleSetInput>;
  where: ApprovalInFlightEditRuleBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalInFlightEditRuleByPkArgs = {
  _set?: InputMaybe<ApprovalInFlightEditRuleSetInput>;
  pk_columns: ApprovalInFlightEditRulePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalInFlightEditRuleManyArgs = {
  updates: Array<ApprovalInFlightEditRuleUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalLevelArgs = {
  _inc?: InputMaybe<ApprovalLevelIncInput>;
  _set?: InputMaybe<ApprovalLevelSetInput>;
  where: ApprovalLevelBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalLevelAuditArgs = {
  _inc?: InputMaybe<ApprovalLevelAuditIncInput>;
  _set?: InputMaybe<ApprovalLevelAuditSetInput>;
  where: ApprovalLevelAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalLevelAuditByPkArgs = {
  _inc?: InputMaybe<ApprovalLevelAuditIncInput>;
  _set?: InputMaybe<ApprovalLevelAuditSetInput>;
  pk_columns: ApprovalLevelAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalLevelAuditManyArgs = {
  updates: Array<ApprovalLevelAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalLevelByPkArgs = {
  _inc?: InputMaybe<ApprovalLevelIncInput>;
  _set?: InputMaybe<ApprovalLevelSetInput>;
  pk_columns: ApprovalLevelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalLevelManyArgs = {
  updates: Array<ApprovalLevelUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalManyArgs = {
  updates: Array<ApprovalUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalRuleTypeArgs = {
  _set?: InputMaybe<ApprovalRuleTypeSetInput>;
  where: ApprovalRuleTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalRuleTypeByPkArgs = {
  _set?: InputMaybe<ApprovalRuleTypeSetInput>;
  pk_columns: ApprovalRuleTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalRuleTypeManyArgs = {
  updates: Array<ApprovalRuleTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateApprovalStatusArgs = {
  _set?: InputMaybe<ApprovalStatusSetInput>;
  where: ApprovalStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateApprovalStatusByPkArgs = {
  _set?: InputMaybe<ApprovalStatusSetInput>;
  pk_columns: ApprovalStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApprovalStatusManyArgs = {
  updates: Array<ApprovalStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateApproverArgs = {
  _set?: InputMaybe<ApproverSetInput>;
  where: ApproverBoolExp;
};


/** mutation root */
export type MutationRootUpdateApproverAuditArgs = {
  _set?: InputMaybe<ApproverAuditSetInput>;
  where: ApproverAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateApproverAuditByPkArgs = {
  _set?: InputMaybe<ApproverAuditSetInput>;
  pk_columns: ApproverAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApproverAuditManyArgs = {
  updates: Array<ApproverAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateApproverByPkArgs = {
  _set?: InputMaybe<ApproverSetInput>;
  pk_columns: ApproverPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApproverManyArgs = {
  updates: Array<ApproverUpdates>;
};


/** mutation root */
export type MutationRootUpdateApproverResponseArgs = {
  _set?: InputMaybe<ApproverResponseSetInput>;
  where: ApproverResponseBoolExp;
};


/** mutation root */
export type MutationRootUpdateApproverResponseByPkArgs = {
  _set?: InputMaybe<ApproverResponseSetInput>;
  pk_columns: ApproverResponsePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateApproverResponseManyArgs = {
  updates: Array<ApproverResponseUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentArgs = {
  _append?: InputMaybe<AssessmentAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentDeleteKeyInput>;
  _inc?: InputMaybe<AssessmentIncInput>;
  _prepend?: InputMaybe<AssessmentPrependInput>;
  _set?: InputMaybe<AssessmentSetInput>;
  where: AssessmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityArgs = {
  _append?: InputMaybe<AssessmentActivityAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentActivityDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentActivityDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentActivityDeleteKeyInput>;
  _prepend?: InputMaybe<AssessmentActivityPrependInput>;
  _set?: InputMaybe<AssessmentActivitySetInput>;
  where: AssessmentActivityBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityAuditArgs = {
  _append?: InputMaybe<AssessmentActivityAuditAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentActivityAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentActivityAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentActivityAuditDeleteKeyInput>;
  _prepend?: InputMaybe<AssessmentActivityAuditPrependInput>;
  _set?: InputMaybe<AssessmentActivityAuditSetInput>;
  where: AssessmentActivityAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityAuditByPkArgs = {
  _append?: InputMaybe<AssessmentActivityAuditAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentActivityAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentActivityAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentActivityAuditDeleteKeyInput>;
  _prepend?: InputMaybe<AssessmentActivityAuditPrependInput>;
  _set?: InputMaybe<AssessmentActivityAuditSetInput>;
  pk_columns: AssessmentActivityAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityAuditManyArgs = {
  updates: Array<AssessmentActivityAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityByPkArgs = {
  _append?: InputMaybe<AssessmentActivityAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentActivityDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentActivityDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentActivityDeleteKeyInput>;
  _prepend?: InputMaybe<AssessmentActivityPrependInput>;
  _set?: InputMaybe<AssessmentActivitySetInput>;
  pk_columns: AssessmentActivityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityManyArgs = {
  updates: Array<AssessmentActivityUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityStatusArgs = {
  _set?: InputMaybe<AssessmentActivityStatusSetInput>;
  where: AssessmentActivityStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityStatusByPkArgs = {
  _set?: InputMaybe<AssessmentActivityStatusSetInput>;
  pk_columns: AssessmentActivityStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityStatusManyArgs = {
  updates: Array<AssessmentActivityStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityTypeArgs = {
  _set?: InputMaybe<AssessmentActivityTypeSetInput>;
  where: AssessmentActivityTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityTypeByPkArgs = {
  _set?: InputMaybe<AssessmentActivityTypeSetInput>;
  pk_columns: AssessmentActivityTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentActivityTypeManyArgs = {
  updates: Array<AssessmentActivityTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentAuditArgs = {
  _append?: InputMaybe<AssessmentAuditAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentAuditDeleteKeyInput>;
  _inc?: InputMaybe<AssessmentAuditIncInput>;
  _prepend?: InputMaybe<AssessmentAuditPrependInput>;
  _set?: InputMaybe<AssessmentAuditSetInput>;
  where: AssessmentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentAuditByPkArgs = {
  _append?: InputMaybe<AssessmentAuditAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentAuditDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentAuditDeleteKeyInput>;
  _inc?: InputMaybe<AssessmentAuditIncInput>;
  _prepend?: InputMaybe<AssessmentAuditPrependInput>;
  _set?: InputMaybe<AssessmentAuditSetInput>;
  pk_columns: AssessmentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentAuditManyArgs = {
  updates: Array<AssessmentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentByPkArgs = {
  _append?: InputMaybe<AssessmentAppendInput>;
  _delete_at_path?: InputMaybe<AssessmentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<AssessmentDeleteElemInput>;
  _delete_key?: InputMaybe<AssessmentDeleteKeyInput>;
  _inc?: InputMaybe<AssessmentIncInput>;
  _prepend?: InputMaybe<AssessmentPrependInput>;
  _set?: InputMaybe<AssessmentSetInput>;
  pk_columns: AssessmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentManyArgs = {
  updates: Array<AssessmentUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentResultParentArgs = {
  _set?: InputMaybe<AssessmentResultParentSetInput>;
  where: AssessmentResultParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentResultParentAuditArgs = {
  _set?: InputMaybe<AssessmentResultParentAuditSetInput>;
  where: AssessmentResultParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentResultParentAuditByPkArgs = {
  _set?: InputMaybe<AssessmentResultParentAuditSetInput>;
  pk_columns: AssessmentResultParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentResultParentAuditManyArgs = {
  updates: Array<AssessmentResultParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentResultParentByPkArgs = {
  _set?: InputMaybe<AssessmentResultParentSetInput>;
  pk_columns: AssessmentResultParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentResultParentManyArgs = {
  updates: Array<AssessmentResultParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateAssessmentStatusArgs = {
  _set?: InputMaybe<AssessmentStatusSetInput>;
  where: AssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateAssessmentStatusByPkArgs = {
  _set?: InputMaybe<AssessmentStatusSetInput>;
  pk_columns: AssessmentStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAssessmentStatusManyArgs = {
  updates: Array<AssessmentStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateAttestationConfigArgs = {
  _set?: InputMaybe<AttestationConfigSetInput>;
  where: AttestationConfigBoolExp;
};


/** mutation root */
export type MutationRootUpdateAttestationConfigByPkArgs = {
  _set?: InputMaybe<AttestationConfigSetInput>;
  pk_columns: AttestationConfigPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAttestationConfigManyArgs = {
  updates: Array<AttestationConfigUpdates>;
};


/** mutation root */
export type MutationRootUpdateAttestationGroupArgs = {
  _set?: InputMaybe<AttestationGroupSetInput>;
  where: AttestationGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateAttestationGroupByPkArgs = {
  _set?: InputMaybe<AttestationGroupSetInput>;
  pk_columns: AttestationGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAttestationGroupManyArgs = {
  updates: Array<AttestationGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateAttestationRecordArgs = {
  _set?: InputMaybe<AttestationRecordSetInput>;
  where: AttestationRecordBoolExp;
};


/** mutation root */
export type MutationRootUpdateAttestationRecordByPkArgs = {
  _set?: InputMaybe<AttestationRecordSetInput>;
  pk_columns: AttestationRecordPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAttestationRecordManyArgs = {
  updates: Array<AttestationRecordUpdates>;
};


/** mutation root */
export type MutationRootUpdateAttestationRecordStatusArgs = {
  _set?: InputMaybe<AttestationRecordStatusSetInput>;
  where: AttestationRecordStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateAttestationRecordStatusByPkArgs = {
  _set?: InputMaybe<AttestationRecordStatusSetInput>;
  pk_columns: AttestationRecordStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAttestationRecordStatusManyArgs = {
  updates: Array<AttestationRecordStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationArgs = {
  _set?: InputMaybe<AuthOrganisationSetInput>;
  where: AuthOrganisationBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationAuditArgs = {
  _set?: InputMaybe<AuthOrganisationAuditSetInput>;
  where: AuthOrganisationAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationAuditByPkArgs = {
  _set?: InputMaybe<AuthOrganisationAuditSetInput>;
  pk_columns: AuthOrganisationAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationAuditManyArgs = {
  updates: Array<AuthOrganisationAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationByPkArgs = {
  _set?: InputMaybe<AuthOrganisationSetInput>;
  pk_columns: AuthOrganisationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationManyArgs = {
  updates: Array<AuthOrganisationUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationuserArgs = {
  _set?: InputMaybe<AuthOrganisationuserSetInput>;
  where: AuthOrganisationuserBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationuserAuditArgs = {
  _set?: InputMaybe<AuthOrganisationuserAuditSetInput>;
  where: AuthOrganisationuserAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationuserAuditByPkArgs = {
  _set?: InputMaybe<AuthOrganisationuserAuditSetInput>;
  pk_columns: AuthOrganisationuserAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationuserAuditManyArgs = {
  updates: Array<AuthOrganisationuserAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationuserByPkArgs = {
  _set?: InputMaybe<AuthOrganisationuserSetInput>;
  pk_columns: AuthOrganisationuserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthOrganisationuserManyArgs = {
  updates: Array<AuthOrganisationuserUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthUserArgs = {
  _set?: InputMaybe<AuthUserSetInput>;
  where: AuthUserBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthUserActivityAuditArgs = {
  _set?: InputMaybe<AuthUserActivityAuditSetInput>;
  where: AuthUserActivityAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthUserActivityAuditByPkArgs = {
  _set?: InputMaybe<AuthUserActivityAuditSetInput>;
  pk_columns: AuthUserActivityAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthUserActivityAuditManyArgs = {
  updates: Array<AuthUserActivityAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthUserAuditArgs = {
  _set?: InputMaybe<AuthUserAuditSetInput>;
  where: AuthUserAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateAuthUserAuditByPkArgs = {
  _set?: InputMaybe<AuthUserAuditSetInput>;
  pk_columns: AuthUserAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthUserAuditManyArgs = {
  updates: Array<AuthUserAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateAuthUserByPkArgs = {
  _set?: InputMaybe<AuthUserSetInput>;
  pk_columns: AuthUserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateAuthUserManyArgs = {
  updates: Array<AuthUserUpdates>;
};


/** mutation root */
export type MutationRootUpdateBusinessAreaArgs = {
  _inc?: InputMaybe<BusinessAreaIncInput>;
  _set?: InputMaybe<BusinessAreaSetInput>;
  where: BusinessAreaBoolExp;
};


/** mutation root */
export type MutationRootUpdateBusinessAreaByPkArgs = {
  _inc?: InputMaybe<BusinessAreaIncInput>;
  _set?: InputMaybe<BusinessAreaSetInput>;
  pk_columns: BusinessAreaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateBusinessAreaManyArgs = {
  updates: Array<BusinessAreaUpdates>;
};


/** mutation root */
export type MutationRootUpdateCauseArgs = {
  _append?: InputMaybe<CauseAppendInput>;
  _delete_at_path?: InputMaybe<CauseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CauseDeleteElemInput>;
  _delete_key?: InputMaybe<CauseDeleteKeyInput>;
  _inc?: InputMaybe<CauseIncInput>;
  _prepend?: InputMaybe<CausePrependInput>;
  _set?: InputMaybe<CauseSetInput>;
  where: CauseBoolExp;
};


/** mutation root */
export type MutationRootUpdateCauseAuditArgs = {
  _append?: InputMaybe<CauseAuditAppendInput>;
  _delete_at_path?: InputMaybe<CauseAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CauseAuditDeleteElemInput>;
  _delete_key?: InputMaybe<CauseAuditDeleteKeyInput>;
  _inc?: InputMaybe<CauseAuditIncInput>;
  _prepend?: InputMaybe<CauseAuditPrependInput>;
  _set?: InputMaybe<CauseAuditSetInput>;
  where: CauseAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateCauseAuditByPkArgs = {
  _append?: InputMaybe<CauseAuditAppendInput>;
  _delete_at_path?: InputMaybe<CauseAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CauseAuditDeleteElemInput>;
  _delete_key?: InputMaybe<CauseAuditDeleteKeyInput>;
  _inc?: InputMaybe<CauseAuditIncInput>;
  _prepend?: InputMaybe<CauseAuditPrependInput>;
  _set?: InputMaybe<CauseAuditSetInput>;
  pk_columns: CauseAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCauseAuditManyArgs = {
  updates: Array<CauseAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateCauseByPkArgs = {
  _append?: InputMaybe<CauseAppendInput>;
  _delete_at_path?: InputMaybe<CauseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CauseDeleteElemInput>;
  _delete_key?: InputMaybe<CauseDeleteKeyInput>;
  _inc?: InputMaybe<CauseIncInput>;
  _prepend?: InputMaybe<CausePrependInput>;
  _set?: InputMaybe<CauseSetInput>;
  pk_columns: CausePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCauseManyArgs = {
  updates: Array<CauseUpdates>;
};


/** mutation root */
export type MutationRootUpdateChangeRequestArgs = {
  _append?: InputMaybe<ChangeRequestAppendInput>;
  _delete_at_path?: InputMaybe<ChangeRequestDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChangeRequestDeleteElemInput>;
  _delete_key?: InputMaybe<ChangeRequestDeleteKeyInput>;
  _inc?: InputMaybe<ChangeRequestIncInput>;
  _prepend?: InputMaybe<ChangeRequestPrependInput>;
  _set?: InputMaybe<ChangeRequestSetInput>;
  where: ChangeRequestBoolExp;
};


/** mutation root */
export type MutationRootUpdateChangeRequestAuditArgs = {
  _append?: InputMaybe<ChangeRequestAuditAppendInput>;
  _delete_at_path?: InputMaybe<ChangeRequestAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChangeRequestAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ChangeRequestAuditDeleteKeyInput>;
  _inc?: InputMaybe<ChangeRequestAuditIncInput>;
  _prepend?: InputMaybe<ChangeRequestAuditPrependInput>;
  _set?: InputMaybe<ChangeRequestAuditSetInput>;
  where: ChangeRequestAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateChangeRequestAuditByPkArgs = {
  _append?: InputMaybe<ChangeRequestAuditAppendInput>;
  _delete_at_path?: InputMaybe<ChangeRequestAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChangeRequestAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ChangeRequestAuditDeleteKeyInput>;
  _inc?: InputMaybe<ChangeRequestAuditIncInput>;
  _prepend?: InputMaybe<ChangeRequestAuditPrependInput>;
  _set?: InputMaybe<ChangeRequestAuditSetInput>;
  pk_columns: ChangeRequestAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChangeRequestAuditManyArgs = {
  updates: Array<ChangeRequestAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateChangeRequestByPkArgs = {
  _append?: InputMaybe<ChangeRequestAppendInput>;
  _delete_at_path?: InputMaybe<ChangeRequestDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ChangeRequestDeleteElemInput>;
  _delete_key?: InputMaybe<ChangeRequestDeleteKeyInput>;
  _inc?: InputMaybe<ChangeRequestIncInput>;
  _prepend?: InputMaybe<ChangeRequestPrependInput>;
  _set?: InputMaybe<ChangeRequestSetInput>;
  pk_columns: ChangeRequestPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChangeRequestContributorArgs = {
  _set?: InputMaybe<ChangeRequestContributorSetInput>;
  where: ChangeRequestContributorBoolExp;
};


/** mutation root */
export type MutationRootUpdateChangeRequestContributorByPkArgs = {
  _set?: InputMaybe<ChangeRequestContributorSetInput>;
  pk_columns: ChangeRequestContributorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateChangeRequestContributorManyArgs = {
  updates: Array<ChangeRequestContributorUpdates>;
};


/** mutation root */
export type MutationRootUpdateChangeRequestManyArgs = {
  updates: Array<ChangeRequestUpdates>;
};


/** mutation root */
export type MutationRootUpdateCommentArgs = {
  _set?: InputMaybe<CommentSetInput>;
  where: CommentBoolExp;
};


/** mutation root */
export type MutationRootUpdateCommentAuditArgs = {
  _set?: InputMaybe<CommentAuditSetInput>;
  where: CommentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateCommentAuditByPkArgs = {
  _set?: InputMaybe<CommentAuditSetInput>;
  pk_columns: CommentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCommentAuditManyArgs = {
  updates: Array<CommentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateCommentByPkArgs = {
  _set?: InputMaybe<CommentSetInput>;
  pk_columns: CommentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCommentManyArgs = {
  updates: Array<CommentUpdates>;
};


/** mutation root */
export type MutationRootUpdateComplianceMonitoringAssessmentArgs = {
  _append?: InputMaybe<ComplianceMonitoringAssessmentAppendInput>;
  _delete_at_path?: InputMaybe<ComplianceMonitoringAssessmentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ComplianceMonitoringAssessmentDeleteElemInput>;
  _delete_key?: InputMaybe<ComplianceMonitoringAssessmentDeleteKeyInput>;
  _inc?: InputMaybe<ComplianceMonitoringAssessmentIncInput>;
  _prepend?: InputMaybe<ComplianceMonitoringAssessmentPrependInput>;
  _set?: InputMaybe<ComplianceMonitoringAssessmentSetInput>;
  where: ComplianceMonitoringAssessmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateComplianceMonitoringAssessmentByPkArgs = {
  _append?: InputMaybe<ComplianceMonitoringAssessmentAppendInput>;
  _delete_at_path?: InputMaybe<ComplianceMonitoringAssessmentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ComplianceMonitoringAssessmentDeleteElemInput>;
  _delete_key?: InputMaybe<ComplianceMonitoringAssessmentDeleteKeyInput>;
  _inc?: InputMaybe<ComplianceMonitoringAssessmentIncInput>;
  _prepend?: InputMaybe<ComplianceMonitoringAssessmentPrependInput>;
  _set?: InputMaybe<ComplianceMonitoringAssessmentSetInput>;
  pk_columns: ComplianceMonitoringAssessmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateComplianceMonitoringAssessmentManyArgs = {
  updates: Array<ComplianceMonitoringAssessmentUpdates>;
};


/** mutation root */
export type MutationRootUpdateConfigEnvArgs = {
  _inc?: InputMaybe<ConfigEnvIncInput>;
  _set?: InputMaybe<ConfigEnvSetInput>;
  where: ConfigEnvBoolExp;
};


/** mutation root */
export type MutationRootUpdateConfigEnvByPkArgs = {
  _inc?: InputMaybe<ConfigEnvIncInput>;
  _set?: InputMaybe<ConfigEnvSetInput>;
  pk_columns: ConfigEnvPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConfigEnvManyArgs = {
  updates: Array<ConfigEnvUpdates>;
};


/** mutation root */
export type MutationRootUpdateConsequenceArgs = {
  _append?: InputMaybe<ConsequenceAppendInput>;
  _delete_at_path?: InputMaybe<ConsequenceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ConsequenceDeleteElemInput>;
  _delete_key?: InputMaybe<ConsequenceDeleteKeyInput>;
  _inc?: InputMaybe<ConsequenceIncInput>;
  _prepend?: InputMaybe<ConsequencePrependInput>;
  _set?: InputMaybe<ConsequenceSetInput>;
  where: ConsequenceBoolExp;
};


/** mutation root */
export type MutationRootUpdateConsequenceAuditArgs = {
  _append?: InputMaybe<ConsequenceAuditAppendInput>;
  _delete_at_path?: InputMaybe<ConsequenceAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ConsequenceAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ConsequenceAuditDeleteKeyInput>;
  _inc?: InputMaybe<ConsequenceAuditIncInput>;
  _prepend?: InputMaybe<ConsequenceAuditPrependInput>;
  _set?: InputMaybe<ConsequenceAuditSetInput>;
  where: ConsequenceAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateConsequenceAuditByPkArgs = {
  _append?: InputMaybe<ConsequenceAuditAppendInput>;
  _delete_at_path?: InputMaybe<ConsequenceAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ConsequenceAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ConsequenceAuditDeleteKeyInput>;
  _inc?: InputMaybe<ConsequenceAuditIncInput>;
  _prepend?: InputMaybe<ConsequenceAuditPrependInput>;
  _set?: InputMaybe<ConsequenceAuditSetInput>;
  pk_columns: ConsequenceAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConsequenceAuditManyArgs = {
  updates: Array<ConsequenceAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateConsequenceByPkArgs = {
  _append?: InputMaybe<ConsequenceAppendInput>;
  _delete_at_path?: InputMaybe<ConsequenceDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ConsequenceDeleteElemInput>;
  _delete_key?: InputMaybe<ConsequenceDeleteKeyInput>;
  _inc?: InputMaybe<ConsequenceIncInput>;
  _prepend?: InputMaybe<ConsequencePrependInput>;
  _set?: InputMaybe<ConsequenceSetInput>;
  pk_columns: ConsequencePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConsequenceManyArgs = {
  updates: Array<ConsequenceUpdates>;
};


/** mutation root */
export type MutationRootUpdateConsequenceTypeArgs = {
  _set?: InputMaybe<ConsequenceTypeSetInput>;
  where: ConsequenceTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateConsequenceTypeByPkArgs = {
  _set?: InputMaybe<ConsequenceTypeSetInput>;
  pk_columns: ConsequenceTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConsequenceTypeManyArgs = {
  updates: Array<ConsequenceTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateContributorArgs = {
  _set?: InputMaybe<ContributorSetInput>;
  where: ContributorBoolExp;
};


/** mutation root */
export type MutationRootUpdateContributorAuditArgs = {
  _set?: InputMaybe<ContributorAuditSetInput>;
  where: ContributorAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateContributorAuditByPkArgs = {
  _set?: InputMaybe<ContributorAuditSetInput>;
  pk_columns: ContributorAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContributorAuditManyArgs = {
  updates: Array<ContributorAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateContributorByPkArgs = {
  _set?: InputMaybe<ContributorSetInput>;
  pk_columns: ContributorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContributorGroupArgs = {
  _set?: InputMaybe<ContributorGroupSetInput>;
  where: ContributorGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateContributorGroupAuditArgs = {
  _set?: InputMaybe<ContributorGroupAuditSetInput>;
  where: ContributorGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateContributorGroupAuditByPkArgs = {
  _set?: InputMaybe<ContributorGroupAuditSetInput>;
  pk_columns: ContributorGroupAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContributorGroupAuditManyArgs = {
  updates: Array<ContributorGroupAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateContributorGroupByPkArgs = {
  _set?: InputMaybe<ContributorGroupSetInput>;
  pk_columns: ContributorGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContributorGroupManyArgs = {
  updates: Array<ContributorGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateContributorManyArgs = {
  updates: Array<ContributorUpdates>;
};


/** mutation root */
export type MutationRootUpdateContributorTypeArgs = {
  _set?: InputMaybe<ContributorTypeSetInput>;
  where: ContributorTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateContributorTypeByPkArgs = {
  _set?: InputMaybe<ContributorTypeSetInput>;
  pk_columns: ContributorTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateContributorTypeManyArgs = {
  updates: Array<ContributorTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlArgs = {
  _append?: InputMaybe<ControlAppendInput>;
  _delete_at_path?: InputMaybe<ControlDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlDeleteElemInput>;
  _delete_key?: InputMaybe<ControlDeleteKeyInput>;
  _inc?: InputMaybe<ControlIncInput>;
  _prepend?: InputMaybe<ControlPrependInput>;
  _set?: InputMaybe<ControlSetInput>;
  where: ControlBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlAuditArgs = {
  _append?: InputMaybe<ControlAuditAppendInput>;
  _delete_at_path?: InputMaybe<ControlAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ControlAuditDeleteKeyInput>;
  _inc?: InputMaybe<ControlAuditIncInput>;
  _prepend?: InputMaybe<ControlAuditPrependInput>;
  _set?: InputMaybe<ControlAuditSetInput>;
  where: ControlAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlAuditByPkArgs = {
  _append?: InputMaybe<ControlAuditAppendInput>;
  _delete_at_path?: InputMaybe<ControlAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ControlAuditDeleteKeyInput>;
  _inc?: InputMaybe<ControlAuditIncInput>;
  _prepend?: InputMaybe<ControlAuditPrependInput>;
  _set?: InputMaybe<ControlAuditSetInput>;
  pk_columns: ControlAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlAuditManyArgs = {
  updates: Array<ControlAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlByPkArgs = {
  _append?: InputMaybe<ControlAppendInput>;
  _delete_at_path?: InputMaybe<ControlDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlDeleteElemInput>;
  _delete_key?: InputMaybe<ControlDeleteKeyInput>;
  _inc?: InputMaybe<ControlIncInput>;
  _prepend?: InputMaybe<ControlPrependInput>;
  _set?: InputMaybe<ControlSetInput>;
  pk_columns: ControlPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlGroupArgs = {
  _append?: InputMaybe<ControlGroupAppendInput>;
  _delete_at_path?: InputMaybe<ControlGroupDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlGroupDeleteElemInput>;
  _delete_key?: InputMaybe<ControlGroupDeleteKeyInput>;
  _prepend?: InputMaybe<ControlGroupPrependInput>;
  _set?: InputMaybe<ControlGroupSetInput>;
  where: ControlGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlGroupAuditArgs = {
  _append?: InputMaybe<ControlGroupAuditAppendInput>;
  _delete_at_path?: InputMaybe<ControlGroupAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlGroupAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ControlGroupAuditDeleteKeyInput>;
  _prepend?: InputMaybe<ControlGroupAuditPrependInput>;
  _set?: InputMaybe<ControlGroupAuditSetInput>;
  where: ControlGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlGroupAuditByPkArgs = {
  _append?: InputMaybe<ControlGroupAuditAppendInput>;
  _delete_at_path?: InputMaybe<ControlGroupAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlGroupAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ControlGroupAuditDeleteKeyInput>;
  _prepend?: InputMaybe<ControlGroupAuditPrependInput>;
  _set?: InputMaybe<ControlGroupAuditSetInput>;
  pk_columns: ControlGroupAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlGroupAuditManyArgs = {
  updates: Array<ControlGroupAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlGroupByPkArgs = {
  _append?: InputMaybe<ControlGroupAppendInput>;
  _delete_at_path?: InputMaybe<ControlGroupDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ControlGroupDeleteElemInput>;
  _delete_key?: InputMaybe<ControlGroupDeleteKeyInput>;
  _prepend?: InputMaybe<ControlGroupPrependInput>;
  _set?: InputMaybe<ControlGroupSetInput>;
  pk_columns: ControlGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlGroupManyArgs = {
  updates: Array<ControlGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlManyArgs = {
  updates: Array<ControlUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlParentArgs = {
  _set?: InputMaybe<ControlParentSetInput>;
  where: ControlParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlParentAuditArgs = {
  _set?: InputMaybe<ControlParentAuditSetInput>;
  where: ControlParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlParentAuditByPkArgs = {
  _set?: InputMaybe<ControlParentAuditSetInput>;
  pk_columns: ControlParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlParentAuditManyArgs = {
  updates: Array<ControlParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlParentByPkArgs = {
  _set?: InputMaybe<ControlParentSetInput>;
  pk_columns: ControlParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlParentManyArgs = {
  updates: Array<ControlParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlTestFrequencyArgs = {
  _set?: InputMaybe<ControlTestFrequencySetInput>;
  where: ControlTestFrequencyBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlTestFrequencyByPkArgs = {
  _set?: InputMaybe<ControlTestFrequencySetInput>;
  pk_columns: ControlTestFrequencyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlTestFrequencyManyArgs = {
  updates: Array<ControlTestFrequencyUpdates>;
};


/** mutation root */
export type MutationRootUpdateControlTypeArgs = {
  _set?: InputMaybe<ControlTypeSetInput>;
  where: ControlTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateControlTypeByPkArgs = {
  _set?: InputMaybe<ControlTypeSetInput>;
  pk_columns: ControlTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateControlTypeManyArgs = {
  updates: Array<ControlTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateConversationArgs = {
  _set?: InputMaybe<ConversationSetInput>;
  where: ConversationBoolExp;
};


/** mutation root */
export type MutationRootUpdateConversationAuditArgs = {
  _set?: InputMaybe<ConversationAuditSetInput>;
  where: ConversationAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateConversationAuditByPkArgs = {
  _set?: InputMaybe<ConversationAuditSetInput>;
  pk_columns: ConversationAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConversationAuditManyArgs = {
  updates: Array<ConversationAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateConversationByPkArgs = {
  _set?: InputMaybe<ConversationSetInput>;
  pk_columns: ConversationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateConversationManyArgs = {
  updates: Array<ConversationUpdates>;
};


/** mutation root */
export type MutationRootUpdateCostTypeArgs = {
  _set?: InputMaybe<CostTypeSetInput>;
  where: CostTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateCostTypeByPkArgs = {
  _set?: InputMaybe<CostTypeSetInput>;
  pk_columns: CostTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCostTypeManyArgs = {
  updates: Array<CostTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateCustomAttributeSchemaArgs = {
  _append?: InputMaybe<CustomAttributeSchemaAppendInput>;
  _delete_at_path?: InputMaybe<CustomAttributeSchemaDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomAttributeSchemaDeleteElemInput>;
  _delete_key?: InputMaybe<CustomAttributeSchemaDeleteKeyInput>;
  _prepend?: InputMaybe<CustomAttributeSchemaPrependInput>;
  _set?: InputMaybe<CustomAttributeSchemaSetInput>;
  where: CustomAttributeSchemaBoolExp;
};


/** mutation root */
export type MutationRootUpdateCustomAttributeSchemaAuditArgs = {
  _append?: InputMaybe<CustomAttributeSchemaAuditAppendInput>;
  _delete_at_path?: InputMaybe<CustomAttributeSchemaAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomAttributeSchemaAuditDeleteElemInput>;
  _delete_key?: InputMaybe<CustomAttributeSchemaAuditDeleteKeyInput>;
  _prepend?: InputMaybe<CustomAttributeSchemaAuditPrependInput>;
  _set?: InputMaybe<CustomAttributeSchemaAuditSetInput>;
  where: CustomAttributeSchemaAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateCustomAttributeSchemaAuditByPkArgs = {
  _append?: InputMaybe<CustomAttributeSchemaAuditAppendInput>;
  _delete_at_path?: InputMaybe<CustomAttributeSchemaAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomAttributeSchemaAuditDeleteElemInput>;
  _delete_key?: InputMaybe<CustomAttributeSchemaAuditDeleteKeyInput>;
  _prepend?: InputMaybe<CustomAttributeSchemaAuditPrependInput>;
  _set?: InputMaybe<CustomAttributeSchemaAuditSetInput>;
  pk_columns: CustomAttributeSchemaAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCustomAttributeSchemaAuditManyArgs = {
  updates: Array<CustomAttributeSchemaAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateCustomAttributeSchemaByPkArgs = {
  _append?: InputMaybe<CustomAttributeSchemaAppendInput>;
  _delete_at_path?: InputMaybe<CustomAttributeSchemaDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomAttributeSchemaDeleteElemInput>;
  _delete_key?: InputMaybe<CustomAttributeSchemaDeleteKeyInput>;
  _prepend?: InputMaybe<CustomAttributeSchemaPrependInput>;
  _set?: InputMaybe<CustomAttributeSchemaSetInput>;
  pk_columns: CustomAttributeSchemaPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCustomAttributeSchemaManyArgs = {
  updates: Array<CustomAttributeSchemaUpdates>;
};


/** mutation root */
export type MutationRootUpdateCustomRibbonArgs = {
  _append?: InputMaybe<CustomRibbonAppendInput>;
  _delete_at_path?: InputMaybe<CustomRibbonDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomRibbonDeleteElemInput>;
  _delete_key?: InputMaybe<CustomRibbonDeleteKeyInput>;
  _prepend?: InputMaybe<CustomRibbonPrependInput>;
  _set?: InputMaybe<CustomRibbonSetInput>;
  where: CustomRibbonBoolExp;
};


/** mutation root */
export type MutationRootUpdateCustomRibbonAuditArgs = {
  _append?: InputMaybe<CustomRibbonAuditAppendInput>;
  _delete_at_path?: InputMaybe<CustomRibbonAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomRibbonAuditDeleteElemInput>;
  _delete_key?: InputMaybe<CustomRibbonAuditDeleteKeyInput>;
  _prepend?: InputMaybe<CustomRibbonAuditPrependInput>;
  _set?: InputMaybe<CustomRibbonAuditSetInput>;
  where: CustomRibbonAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateCustomRibbonAuditByPkArgs = {
  _append?: InputMaybe<CustomRibbonAuditAppendInput>;
  _delete_at_path?: InputMaybe<CustomRibbonAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomRibbonAuditDeleteElemInput>;
  _delete_key?: InputMaybe<CustomRibbonAuditDeleteKeyInput>;
  _prepend?: InputMaybe<CustomRibbonAuditPrependInput>;
  _set?: InputMaybe<CustomRibbonAuditSetInput>;
  pk_columns: CustomRibbonAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCustomRibbonAuditManyArgs = {
  updates: Array<CustomRibbonAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateCustomRibbonByPkArgs = {
  _append?: InputMaybe<CustomRibbonAppendInput>;
  _delete_at_path?: InputMaybe<CustomRibbonDeleteAtPathInput>;
  _delete_elem?: InputMaybe<CustomRibbonDeleteElemInput>;
  _delete_key?: InputMaybe<CustomRibbonDeleteKeyInput>;
  _prepend?: InputMaybe<CustomRibbonPrependInput>;
  _set?: InputMaybe<CustomRibbonSetInput>;
  pk_columns: CustomRibbonPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateCustomRibbonManyArgs = {
  updates: Array<CustomRibbonUpdates>;
};


/** mutation root */
export type MutationRootUpdateDashboardArgs = {
  _append?: InputMaybe<DashboardAppendInput>;
  _delete_at_path?: InputMaybe<DashboardDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DashboardDeleteElemInput>;
  _delete_key?: InputMaybe<DashboardDeleteKeyInput>;
  _prepend?: InputMaybe<DashboardPrependInput>;
  _set?: InputMaybe<DashboardSetInput>;
  where: DashboardBoolExp;
};


/** mutation root */
export type MutationRootUpdateDashboardAuditArgs = {
  _append?: InputMaybe<DashboardAuditAppendInput>;
  _delete_at_path?: InputMaybe<DashboardAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DashboardAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DashboardAuditDeleteKeyInput>;
  _prepend?: InputMaybe<DashboardAuditPrependInput>;
  _set?: InputMaybe<DashboardAuditSetInput>;
  where: DashboardAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDashboardAuditByPkArgs = {
  _append?: InputMaybe<DashboardAuditAppendInput>;
  _delete_at_path?: InputMaybe<DashboardAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DashboardAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DashboardAuditDeleteKeyInput>;
  _prepend?: InputMaybe<DashboardAuditPrependInput>;
  _set?: InputMaybe<DashboardAuditSetInput>;
  pk_columns: DashboardAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDashboardAuditManyArgs = {
  updates: Array<DashboardAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDashboardByPkArgs = {
  _append?: InputMaybe<DashboardAppendInput>;
  _delete_at_path?: InputMaybe<DashboardDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DashboardDeleteElemInput>;
  _delete_key?: InputMaybe<DashboardDeleteKeyInput>;
  _prepend?: InputMaybe<DashboardPrependInput>;
  _set?: InputMaybe<DashboardSetInput>;
  pk_columns: DashboardPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDashboardManyArgs = {
  updates: Array<DashboardUpdates>;
};


/** mutation root */
export type MutationRootUpdateDashboardSharingTypeArgs = {
  _set?: InputMaybe<DashboardSharingTypeSetInput>;
  where: DashboardSharingTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDashboardSharingTypeByPkArgs = {
  _set?: InputMaybe<DashboardSharingTypeSetInput>;
  pk_columns: DashboardSharingTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDashboardSharingTypeManyArgs = {
  updates: Array<DashboardSharingTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateDataImportArgs = {
  _set?: InputMaybe<DataImportSetInput>;
  where: DataImportBoolExp;
};


/** mutation root */
export type MutationRootUpdateDataImportAuditArgs = {
  _set?: InputMaybe<DataImportAuditSetInput>;
  where: DataImportAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDataImportAuditByPkArgs = {
  _set?: InputMaybe<DataImportAuditSetInput>;
  pk_columns: DataImportAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDataImportAuditManyArgs = {
  updates: Array<DataImportAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDataImportByPkArgs = {
  _set?: InputMaybe<DataImportSetInput>;
  pk_columns: DataImportPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDataImportErrorArgs = {
  _inc?: InputMaybe<DataImportErrorIncInput>;
  _set?: InputMaybe<DataImportErrorSetInput>;
  where: DataImportErrorBoolExp;
};


/** mutation root */
export type MutationRootUpdateDataImportErrorAuditArgs = {
  _inc?: InputMaybe<DataImportErrorAuditIncInput>;
  _set?: InputMaybe<DataImportErrorAuditSetInput>;
  where: DataImportErrorAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDataImportErrorAuditByPkArgs = {
  _inc?: InputMaybe<DataImportErrorAuditIncInput>;
  _set?: InputMaybe<DataImportErrorAuditSetInput>;
  pk_columns: DataImportErrorAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDataImportErrorAuditManyArgs = {
  updates: Array<DataImportErrorAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDataImportErrorByPkArgs = {
  _inc?: InputMaybe<DataImportErrorIncInput>;
  _set?: InputMaybe<DataImportErrorSetInput>;
  pk_columns: DataImportErrorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDataImportErrorManyArgs = {
  updates: Array<DataImportErrorUpdates>;
};


/** mutation root */
export type MutationRootUpdateDataImportManyArgs = {
  updates: Array<DataImportUpdates>;
};


/** mutation root */
export type MutationRootUpdateDataImportStatusArgs = {
  _set?: InputMaybe<DataImportStatusSetInput>;
  where: DataImportStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateDataImportStatusByPkArgs = {
  _set?: InputMaybe<DataImportStatusSetInput>;
  pk_columns: DataImportStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDataImportStatusManyArgs = {
  updates: Array<DataImportStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateDepartmentArgs = {
  _set?: InputMaybe<DepartmentSetInput>;
  where: DepartmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentAuditArgs = {
  _set?: InputMaybe<DepartmentAuditSetInput>;
  where: DepartmentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentAuditByPkArgs = {
  _set?: InputMaybe<DepartmentAuditSetInput>;
  pk_columns: DepartmentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDepartmentAuditManyArgs = {
  updates: Array<DepartmentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDepartmentByPkArgs = {
  _set?: InputMaybe<DepartmentSetInput>;
  pk_columns: DepartmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDepartmentManyArgs = {
  updates: Array<DepartmentUpdates>;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeArgs = {
  _set?: InputMaybe<DepartmentTypeSetInput>;
  where: DepartmentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeAuditArgs = {
  _set?: InputMaybe<DepartmentTypeAuditSetInput>;
  where: DepartmentTypeAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeAuditByPkArgs = {
  _set?: InputMaybe<DepartmentTypeAuditSetInput>;
  pk_columns: DepartmentTypeAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeAuditManyArgs = {
  updates: Array<DepartmentTypeAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeByPkArgs = {
  _set?: InputMaybe<DepartmentTypeSetInput>;
  pk_columns: DepartmentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeGroupArgs = {
  _set?: InputMaybe<DepartmentTypeGroupSetInput>;
  where: DepartmentTypeGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeGroupAuditArgs = {
  _set?: InputMaybe<DepartmentTypeGroupAuditSetInput>;
  where: DepartmentTypeGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeGroupAuditByPkArgs = {
  _set?: InputMaybe<DepartmentTypeGroupAuditSetInput>;
  pk_columns: DepartmentTypeGroupAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeGroupAuditManyArgs = {
  updates: Array<DepartmentTypeGroupAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeGroupByPkArgs = {
  _set?: InputMaybe<DepartmentTypeGroupSetInput>;
  pk_columns: DepartmentTypeGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeGroupManyArgs = {
  updates: Array<DepartmentTypeGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateDepartmentTypeManyArgs = {
  updates: Array<DepartmentTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentArgs = {
  _append?: InputMaybe<DocumentAppendInput>;
  _delete_at_path?: InputMaybe<DocumentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentDeleteKeyInput>;
  _inc?: InputMaybe<DocumentIncInput>;
  _prepend?: InputMaybe<DocumentPrependInput>;
  _set?: InputMaybe<DocumentSetInput>;
  where: DocumentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentActionAuditArgs = {
  _set?: InputMaybe<DocumentActionAuditSetInput>;
  where: DocumentActionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentActionAuditByPkArgs = {
  _set?: InputMaybe<DocumentActionAuditSetInput>;
  pk_columns: DocumentActionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentActionAuditManyArgs = {
  updates: Array<DocumentActionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentResultArgs = {
  _append?: InputMaybe<DocumentAssessmentResultAppendInput>;
  _delete_at_path?: InputMaybe<DocumentAssessmentResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentAssessmentResultDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentAssessmentResultDeleteKeyInput>;
  _inc?: InputMaybe<DocumentAssessmentResultIncInput>;
  _prepend?: InputMaybe<DocumentAssessmentResultPrependInput>;
  _set?: InputMaybe<DocumentAssessmentResultSetInput>;
  where: DocumentAssessmentResultBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentResultAuditArgs = {
  _append?: InputMaybe<DocumentAssessmentResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<DocumentAssessmentResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentAssessmentResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentAssessmentResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<DocumentAssessmentResultAuditIncInput>;
  _prepend?: InputMaybe<DocumentAssessmentResultAuditPrependInput>;
  _set?: InputMaybe<DocumentAssessmentResultAuditSetInput>;
  where: DocumentAssessmentResultAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentResultAuditByPkArgs = {
  _append?: InputMaybe<DocumentAssessmentResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<DocumentAssessmentResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentAssessmentResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentAssessmentResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<DocumentAssessmentResultAuditIncInput>;
  _prepend?: InputMaybe<DocumentAssessmentResultAuditPrependInput>;
  _set?: InputMaybe<DocumentAssessmentResultAuditSetInput>;
  pk_columns: DocumentAssessmentResultAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentResultAuditManyArgs = {
  updates: Array<DocumentAssessmentResultAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentResultByPkArgs = {
  _append?: InputMaybe<DocumentAssessmentResultAppendInput>;
  _delete_at_path?: InputMaybe<DocumentAssessmentResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentAssessmentResultDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentAssessmentResultDeleteKeyInput>;
  _inc?: InputMaybe<DocumentAssessmentResultIncInput>;
  _prepend?: InputMaybe<DocumentAssessmentResultPrependInput>;
  _set?: InputMaybe<DocumentAssessmentResultSetInput>;
  pk_columns: DocumentAssessmentResultPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentResultManyArgs = {
  updates: Array<DocumentAssessmentResultUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentStatusArgs = {
  _set?: InputMaybe<DocumentAssessmentStatusSetInput>;
  where: DocumentAssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentStatusByPkArgs = {
  _set?: InputMaybe<DocumentAssessmentStatusSetInput>;
  pk_columns: DocumentAssessmentStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentAssessmentStatusManyArgs = {
  updates: Array<DocumentAssessmentStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentAuditArgs = {
  _append?: InputMaybe<DocumentAuditAppendInput>;
  _delete_at_path?: InputMaybe<DocumentAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentAuditDeleteKeyInput>;
  _inc?: InputMaybe<DocumentAuditIncInput>;
  _prepend?: InputMaybe<DocumentAuditPrependInput>;
  _set?: InputMaybe<DocumentAuditSetInput>;
  where: DocumentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentAuditByPkArgs = {
  _append?: InputMaybe<DocumentAuditAppendInput>;
  _delete_at_path?: InputMaybe<DocumentAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentAuditDeleteKeyInput>;
  _inc?: InputMaybe<DocumentAuditIncInput>;
  _prepend?: InputMaybe<DocumentAuditPrependInput>;
  _set?: InputMaybe<DocumentAuditSetInput>;
  pk_columns: DocumentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentAuditManyArgs = {
  updates: Array<DocumentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentByPkArgs = {
  _append?: InputMaybe<DocumentAppendInput>;
  _delete_at_path?: InputMaybe<DocumentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentDeleteKeyInput>;
  _inc?: InputMaybe<DocumentIncInput>;
  _prepend?: InputMaybe<DocumentPrependInput>;
  _set?: InputMaybe<DocumentSetInput>;
  pk_columns: DocumentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentFileArgs = {
  _append?: InputMaybe<DocumentFileAppendInput>;
  _delete_at_path?: InputMaybe<DocumentFileDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentFileDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentFileDeleteKeyInput>;
  _prepend?: InputMaybe<DocumentFilePrependInput>;
  _set?: InputMaybe<DocumentFileSetInput>;
  where: DocumentFileBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentFileAuditArgs = {
  _append?: InputMaybe<DocumentFileAuditAppendInput>;
  _delete_at_path?: InputMaybe<DocumentFileAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentFileAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentFileAuditDeleteKeyInput>;
  _prepend?: InputMaybe<DocumentFileAuditPrependInput>;
  _set?: InputMaybe<DocumentFileAuditSetInput>;
  where: DocumentFileAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentFileAuditByPkArgs = {
  _append?: InputMaybe<DocumentFileAuditAppendInput>;
  _delete_at_path?: InputMaybe<DocumentFileAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentFileAuditDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentFileAuditDeleteKeyInput>;
  _prepend?: InputMaybe<DocumentFileAuditPrependInput>;
  _set?: InputMaybe<DocumentFileAuditSetInput>;
  pk_columns: DocumentFileAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentFileAuditManyArgs = {
  updates: Array<DocumentFileAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentFileByPkArgs = {
  _append?: InputMaybe<DocumentFileAppendInput>;
  _delete_at_path?: InputMaybe<DocumentFileDeleteAtPathInput>;
  _delete_elem?: InputMaybe<DocumentFileDeleteElemInput>;
  _delete_key?: InputMaybe<DocumentFileDeleteKeyInput>;
  _prepend?: InputMaybe<DocumentFilePrependInput>;
  _set?: InputMaybe<DocumentFileSetInput>;
  pk_columns: DocumentFilePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentFileManyArgs = {
  updates: Array<DocumentFileUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentFileTypeArgs = {
  _set?: InputMaybe<DocumentFileTypeSetInput>;
  where: DocumentFileTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentFileTypeByPkArgs = {
  _set?: InputMaybe<DocumentFileTypeSetInput>;
  pk_columns: DocumentFileTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentFileTypeManyArgs = {
  updates: Array<DocumentFileTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentIssueAuditArgs = {
  _set?: InputMaybe<DocumentIssueAuditSetInput>;
  where: DocumentIssueAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentIssueAuditByPkArgs = {
  _set?: InputMaybe<DocumentIssueAuditSetInput>;
  pk_columns: DocumentIssueAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentIssueAuditManyArgs = {
  updates: Array<DocumentIssueAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentLinkedDocumentArgs = {
  _set?: InputMaybe<DocumentLinkedDocumentSetInput>;
  where: DocumentLinkedDocumentBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentLinkedDocumentAuditArgs = {
  _set?: InputMaybe<DocumentLinkedDocumentAuditSetInput>;
  where: DocumentLinkedDocumentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateDocumentLinkedDocumentAuditByPkArgs = {
  _set?: InputMaybe<DocumentLinkedDocumentAuditSetInput>;
  pk_columns: DocumentLinkedDocumentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentLinkedDocumentAuditManyArgs = {
  updates: Array<DocumentLinkedDocumentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentLinkedDocumentByPkArgs = {
  _set?: InputMaybe<DocumentLinkedDocumentSetInput>;
  pk_columns: DocumentLinkedDocumentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateDocumentLinkedDocumentManyArgs = {
  updates: Array<DocumentLinkedDocumentUpdates>;
};


/** mutation root */
export type MutationRootUpdateDocumentManyArgs = {
  updates: Array<DocumentUpdates>;
};


/** mutation root */
export type MutationRootUpdateFileArgs = {
  _inc?: InputMaybe<FileIncInput>;
  _set?: InputMaybe<FileSetInput>;
  where: FileBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileAuditArgs = {
  _inc?: InputMaybe<FileAuditIncInput>;
  _set?: InputMaybe<FileAuditSetInput>;
  where: FileAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateFileAuditByPkArgs = {
  _inc?: InputMaybe<FileAuditIncInput>;
  _set?: InputMaybe<FileAuditSetInput>;
  pk_columns: FileAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFileAuditManyArgs = {
  updates: Array<FileAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateFileByPkArgs = {
  _inc?: InputMaybe<FileIncInput>;
  _set?: InputMaybe<FileSetInput>;
  pk_columns: FilePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFileManyArgs = {
  updates: Array<FileUpdates>;
};


/** mutation root */
export type MutationRootUpdateFormConfigurationArgs = {
  _set?: InputMaybe<FormConfigurationSetInput>;
  where: FormConfigurationBoolExp;
};


/** mutation root */
export type MutationRootUpdateFormConfigurationAuditArgs = {
  _set?: InputMaybe<FormConfigurationAuditSetInput>;
  where: FormConfigurationAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateFormConfigurationAuditByPkArgs = {
  _set?: InputMaybe<FormConfigurationAuditSetInput>;
  pk_columns: FormConfigurationAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFormConfigurationAuditManyArgs = {
  updates: Array<FormConfigurationAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateFormConfigurationByPkArgs = {
  _set?: InputMaybe<FormConfigurationSetInput>;
  pk_columns: FormConfigurationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFormConfigurationManyArgs = {
  updates: Array<FormConfigurationUpdates>;
};


/** mutation root */
export type MutationRootUpdateFormFieldConfigurationArgs = {
  _set?: InputMaybe<FormFieldConfigurationSetInput>;
  where: FormFieldConfigurationBoolExp;
};


/** mutation root */
export type MutationRootUpdateFormFieldConfigurationAuditArgs = {
  _set?: InputMaybe<FormFieldConfigurationAuditSetInput>;
  where: FormFieldConfigurationAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateFormFieldConfigurationAuditByPkArgs = {
  _set?: InputMaybe<FormFieldConfigurationAuditSetInput>;
  pk_columns: FormFieldConfigurationAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFormFieldConfigurationAuditManyArgs = {
  updates: Array<FormFieldConfigurationAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateFormFieldConfigurationByPkArgs = {
  _set?: InputMaybe<FormFieldConfigurationSetInput>;
  pk_columns: FormFieldConfigurationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFormFieldConfigurationManyArgs = {
  updates: Array<FormFieldConfigurationUpdates>;
};


/** mutation root */
export type MutationRootUpdateFormFieldOrderingArgs = {
  _inc?: InputMaybe<FormFieldOrderingIncInput>;
  _set?: InputMaybe<FormFieldOrderingSetInput>;
  where: FormFieldOrderingBoolExp;
};


/** mutation root */
export type MutationRootUpdateFormFieldOrderingAuditArgs = {
  _inc?: InputMaybe<FormFieldOrderingAuditIncInput>;
  _set?: InputMaybe<FormFieldOrderingAuditSetInput>;
  where: FormFieldOrderingAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateFormFieldOrderingAuditByPkArgs = {
  _inc?: InputMaybe<FormFieldOrderingAuditIncInput>;
  _set?: InputMaybe<FormFieldOrderingAuditSetInput>;
  pk_columns: FormFieldOrderingAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFormFieldOrderingAuditManyArgs = {
  updates: Array<FormFieldOrderingAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateFormFieldOrderingByPkArgs = {
  _inc?: InputMaybe<FormFieldOrderingIncInput>;
  _set?: InputMaybe<FormFieldOrderingSetInput>;
  pk_columns: FormFieldOrderingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateFormFieldOrderingManyArgs = {
  updates: Array<FormFieldOrderingUpdates>;
};


/** mutation root */
export type MutationRootUpdateImpactArgs = {
  _append?: InputMaybe<ImpactAppendInput>;
  _delete_at_path?: InputMaybe<ImpactDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactDeleteKeyInput>;
  _inc?: InputMaybe<ImpactIncInput>;
  _prepend?: InputMaybe<ImpactPrependInput>;
  _set?: InputMaybe<ImpactSetInput>;
  where: ImpactBoolExp;
};


/** mutation root */
export type MutationRootUpdateImpactAuditArgs = {
  _append?: InputMaybe<ImpactAuditAppendInput>;
  _delete_at_path?: InputMaybe<ImpactAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactAuditDeleteKeyInput>;
  _inc?: InputMaybe<ImpactAuditIncInput>;
  _prepend?: InputMaybe<ImpactAuditPrependInput>;
  _set?: InputMaybe<ImpactAuditSetInput>;
  where: ImpactAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateImpactAuditByPkArgs = {
  _append?: InputMaybe<ImpactAuditAppendInput>;
  _delete_at_path?: InputMaybe<ImpactAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactAuditDeleteKeyInput>;
  _inc?: InputMaybe<ImpactAuditIncInput>;
  _prepend?: InputMaybe<ImpactAuditPrependInput>;
  _set?: InputMaybe<ImpactAuditSetInput>;
  pk_columns: ImpactAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImpactAuditManyArgs = {
  updates: Array<ImpactAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateImpactByPkArgs = {
  _append?: InputMaybe<ImpactAppendInput>;
  _delete_at_path?: InputMaybe<ImpactDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactDeleteKeyInput>;
  _inc?: InputMaybe<ImpactIncInput>;
  _prepend?: InputMaybe<ImpactPrependInput>;
  _set?: InputMaybe<ImpactSetInput>;
  pk_columns: ImpactPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImpactManyArgs = {
  updates: Array<ImpactUpdates>;
};


/** mutation root */
export type MutationRootUpdateImpactParentArgs = {
  _set?: InputMaybe<ImpactParentSetInput>;
  where: ImpactParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateImpactParentAuditArgs = {
  _set?: InputMaybe<ImpactParentAuditSetInput>;
  where: ImpactParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateImpactParentAuditByPkArgs = {
  _set?: InputMaybe<ImpactParentAuditSetInput>;
  pk_columns: ImpactParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImpactParentAuditManyArgs = {
  updates: Array<ImpactParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateImpactParentByPkArgs = {
  _set?: InputMaybe<ImpactParentSetInput>;
  pk_columns: ImpactParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImpactParentManyArgs = {
  updates: Array<ImpactParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateImpactRatingArgs = {
  _append?: InputMaybe<ImpactRatingAppendInput>;
  _delete_at_path?: InputMaybe<ImpactRatingDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactRatingDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactRatingDeleteKeyInput>;
  _inc?: InputMaybe<ImpactRatingIncInput>;
  _prepend?: InputMaybe<ImpactRatingPrependInput>;
  _set?: InputMaybe<ImpactRatingSetInput>;
  where: ImpactRatingBoolExp;
};


/** mutation root */
export type MutationRootUpdateImpactRatingAuditArgs = {
  _append?: InputMaybe<ImpactRatingAuditAppendInput>;
  _delete_at_path?: InputMaybe<ImpactRatingAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactRatingAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactRatingAuditDeleteKeyInput>;
  _inc?: InputMaybe<ImpactRatingAuditIncInput>;
  _prepend?: InputMaybe<ImpactRatingAuditPrependInput>;
  _set?: InputMaybe<ImpactRatingAuditSetInput>;
  where: ImpactRatingAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateImpactRatingAuditByPkArgs = {
  _append?: InputMaybe<ImpactRatingAuditAppendInput>;
  _delete_at_path?: InputMaybe<ImpactRatingAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactRatingAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactRatingAuditDeleteKeyInput>;
  _inc?: InputMaybe<ImpactRatingAuditIncInput>;
  _prepend?: InputMaybe<ImpactRatingAuditPrependInput>;
  _set?: InputMaybe<ImpactRatingAuditSetInput>;
  pk_columns: ImpactRatingAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImpactRatingAuditManyArgs = {
  updates: Array<ImpactRatingAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateImpactRatingByPkArgs = {
  _append?: InputMaybe<ImpactRatingAppendInput>;
  _delete_at_path?: InputMaybe<ImpactRatingDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ImpactRatingDeleteElemInput>;
  _delete_key?: InputMaybe<ImpactRatingDeleteKeyInput>;
  _inc?: InputMaybe<ImpactRatingIncInput>;
  _prepend?: InputMaybe<ImpactRatingPrependInput>;
  _set?: InputMaybe<ImpactRatingSetInput>;
  pk_columns: ImpactRatingPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateImpactRatingManyArgs = {
  updates: Array<ImpactRatingUpdates>;
};


/** mutation root */
export type MutationRootUpdateIndicatorArgs = {
  _append?: InputMaybe<IndicatorAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorIncInput>;
  _prepend?: InputMaybe<IndicatorPrependInput>;
  _set?: InputMaybe<IndicatorSetInput>;
  where: IndicatorBoolExp;
};


/** mutation root */
export type MutationRootUpdateIndicatorAuditArgs = {
  _append?: InputMaybe<IndicatorAuditAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorAuditDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorAuditIncInput>;
  _prepend?: InputMaybe<IndicatorAuditPrependInput>;
  _set?: InputMaybe<IndicatorAuditSetInput>;
  where: IndicatorAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIndicatorAuditByPkArgs = {
  _append?: InputMaybe<IndicatorAuditAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorAuditDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorAuditIncInput>;
  _prepend?: InputMaybe<IndicatorAuditPrependInput>;
  _set?: InputMaybe<IndicatorAuditSetInput>;
  pk_columns: IndicatorAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIndicatorAuditManyArgs = {
  updates: Array<IndicatorAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIndicatorByPkArgs = {
  _append?: InputMaybe<IndicatorAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorIncInput>;
  _prepend?: InputMaybe<IndicatorPrependInput>;
  _set?: InputMaybe<IndicatorSetInput>;
  pk_columns: IndicatorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIndicatorManyArgs = {
  updates: Array<IndicatorUpdates>;
};


/** mutation root */
export type MutationRootUpdateIndicatorParentArgs = {
  _set?: InputMaybe<IndicatorParentSetInput>;
  where: IndicatorParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateIndicatorParentByPkArgs = {
  _set?: InputMaybe<IndicatorParentSetInput>;
  pk_columns: IndicatorParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIndicatorParentManyArgs = {
  updates: Array<IndicatorParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateIndicatorResultArgs = {
  _append?: InputMaybe<IndicatorResultAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorResultDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorResultDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorResultIncInput>;
  _prepend?: InputMaybe<IndicatorResultPrependInput>;
  _set?: InputMaybe<IndicatorResultSetInput>;
  where: IndicatorResultBoolExp;
};


/** mutation root */
export type MutationRootUpdateIndicatorResultAuditArgs = {
  _append?: InputMaybe<IndicatorResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorResultAuditIncInput>;
  _prepend?: InputMaybe<IndicatorResultAuditPrependInput>;
  _set?: InputMaybe<IndicatorResultAuditSetInput>;
  where: IndicatorResultAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIndicatorResultAuditByPkArgs = {
  _append?: InputMaybe<IndicatorResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorResultAuditIncInput>;
  _prepend?: InputMaybe<IndicatorResultAuditPrependInput>;
  _set?: InputMaybe<IndicatorResultAuditSetInput>;
  pk_columns: IndicatorResultAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIndicatorResultAuditManyArgs = {
  updates: Array<IndicatorResultAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIndicatorResultByPkArgs = {
  _append?: InputMaybe<IndicatorResultAppendInput>;
  _delete_at_path?: InputMaybe<IndicatorResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IndicatorResultDeleteElemInput>;
  _delete_key?: InputMaybe<IndicatorResultDeleteKeyInput>;
  _inc?: InputMaybe<IndicatorResultIncInput>;
  _prepend?: InputMaybe<IndicatorResultPrependInput>;
  _set?: InputMaybe<IndicatorResultSetInput>;
  pk_columns: IndicatorResultPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIndicatorResultManyArgs = {
  updates: Array<IndicatorResultUpdates>;
};


/** mutation root */
export type MutationRootUpdateIndicatorTypeArgs = {
  _set?: InputMaybe<IndicatorTypeSetInput>;
  where: IndicatorTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateIndicatorTypeByPkArgs = {
  _set?: InputMaybe<IndicatorTypeSetInput>;
  pk_columns: IndicatorTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIndicatorTypeManyArgs = {
  updates: Array<IndicatorTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateInternalAuditEntityArgs = {
  _append?: InputMaybe<InternalAuditEntityAppendInput>;
  _delete_at_path?: InputMaybe<InternalAuditEntityDeleteAtPathInput>;
  _delete_elem?: InputMaybe<InternalAuditEntityDeleteElemInput>;
  _delete_key?: InputMaybe<InternalAuditEntityDeleteKeyInput>;
  _inc?: InputMaybe<InternalAuditEntityIncInput>;
  _prepend?: InputMaybe<InternalAuditEntityPrependInput>;
  _set?: InputMaybe<InternalAuditEntitySetInput>;
  where: InternalAuditEntityBoolExp;
};


/** mutation root */
export type MutationRootUpdateInternalAuditEntityByPkArgs = {
  _append?: InputMaybe<InternalAuditEntityAppendInput>;
  _delete_at_path?: InputMaybe<InternalAuditEntityDeleteAtPathInput>;
  _delete_elem?: InputMaybe<InternalAuditEntityDeleteElemInput>;
  _delete_key?: InputMaybe<InternalAuditEntityDeleteKeyInput>;
  _inc?: InputMaybe<InternalAuditEntityIncInput>;
  _prepend?: InputMaybe<InternalAuditEntityPrependInput>;
  _set?: InputMaybe<InternalAuditEntitySetInput>;
  pk_columns: InternalAuditEntityPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateInternalAuditEntityManyArgs = {
  updates: Array<InternalAuditEntityUpdates>;
};


/** mutation root */
export type MutationRootUpdateInternalAuditReportArgs = {
  _append?: InputMaybe<InternalAuditReportAppendInput>;
  _delete_at_path?: InputMaybe<InternalAuditReportDeleteAtPathInput>;
  _delete_elem?: InputMaybe<InternalAuditReportDeleteElemInput>;
  _delete_key?: InputMaybe<InternalAuditReportDeleteKeyInput>;
  _inc?: InputMaybe<InternalAuditReportIncInput>;
  _prepend?: InputMaybe<InternalAuditReportPrependInput>;
  _set?: InputMaybe<InternalAuditReportSetInput>;
  where: InternalAuditReportBoolExp;
};


/** mutation root */
export type MutationRootUpdateInternalAuditReportByPkArgs = {
  _append?: InputMaybe<InternalAuditReportAppendInput>;
  _delete_at_path?: InputMaybe<InternalAuditReportDeleteAtPathInput>;
  _delete_elem?: InputMaybe<InternalAuditReportDeleteElemInput>;
  _delete_key?: InputMaybe<InternalAuditReportDeleteKeyInput>;
  _inc?: InputMaybe<InternalAuditReportIncInput>;
  _prepend?: InputMaybe<InternalAuditReportPrependInput>;
  _set?: InputMaybe<InternalAuditReportSetInput>;
  pk_columns: InternalAuditReportPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateInternalAuditReportManyArgs = {
  updates: Array<InternalAuditReportUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueArgs = {
  _append?: InputMaybe<IssueAppendInput>;
  _delete_at_path?: InputMaybe<IssueDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueDeleteElemInput>;
  _delete_key?: InputMaybe<IssueDeleteKeyInput>;
  _inc?: InputMaybe<IssueIncInput>;
  _prepend?: InputMaybe<IssuePrependInput>;
  _set?: InputMaybe<IssueSetInput>;
  where: IssueBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueActionAuditArgs = {
  _set?: InputMaybe<IssueActionAuditSetInput>;
  where: IssueActionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueActionAuditByPkArgs = {
  _set?: InputMaybe<IssueActionAuditSetInput>;
  pk_columns: IssueActionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueActionAuditManyArgs = {
  updates: Array<IssueActionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentArgs = {
  _append?: InputMaybe<IssueAssessmentAppendInput>;
  _delete_at_path?: InputMaybe<IssueAssessmentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueAssessmentDeleteElemInput>;
  _delete_key?: InputMaybe<IssueAssessmentDeleteKeyInput>;
  _inc?: InputMaybe<IssueAssessmentIncInput>;
  _prepend?: InputMaybe<IssueAssessmentPrependInput>;
  _set?: InputMaybe<IssueAssessmentSetInput>;
  where: IssueAssessmentBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentAuditArgs = {
  _append?: InputMaybe<IssueAssessmentAuditAppendInput>;
  _delete_at_path?: InputMaybe<IssueAssessmentAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueAssessmentAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IssueAssessmentAuditDeleteKeyInput>;
  _inc?: InputMaybe<IssueAssessmentAuditIncInput>;
  _prepend?: InputMaybe<IssueAssessmentAuditPrependInput>;
  _set?: InputMaybe<IssueAssessmentAuditSetInput>;
  where: IssueAssessmentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentAuditByPkArgs = {
  _append?: InputMaybe<IssueAssessmentAuditAppendInput>;
  _delete_at_path?: InputMaybe<IssueAssessmentAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueAssessmentAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IssueAssessmentAuditDeleteKeyInput>;
  _inc?: InputMaybe<IssueAssessmentAuditIncInput>;
  _prepend?: InputMaybe<IssueAssessmentAuditPrependInput>;
  _set?: InputMaybe<IssueAssessmentAuditSetInput>;
  pk_columns: IssueAssessmentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentAuditManyArgs = {
  updates: Array<IssueAssessmentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentByPkArgs = {
  _append?: InputMaybe<IssueAssessmentAppendInput>;
  _delete_at_path?: InputMaybe<IssueAssessmentDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueAssessmentDeleteElemInput>;
  _delete_key?: InputMaybe<IssueAssessmentDeleteKeyInput>;
  _inc?: InputMaybe<IssueAssessmentIncInput>;
  _prepend?: InputMaybe<IssueAssessmentPrependInput>;
  _set?: InputMaybe<IssueAssessmentSetInput>;
  pk_columns: IssueAssessmentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentManyArgs = {
  updates: Array<IssueAssessmentUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentStatusArgs = {
  _set?: InputMaybe<IssueAssessmentStatusSetInput>;
  where: IssueAssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentStatusByPkArgs = {
  _set?: InputMaybe<IssueAssessmentStatusSetInput>;
  pk_columns: IssueAssessmentStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueAssessmentStatusManyArgs = {
  updates: Array<IssueAssessmentStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueAuditArgs = {
  _append?: InputMaybe<IssueAuditAppendInput>;
  _delete_at_path?: InputMaybe<IssueAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IssueAuditDeleteKeyInput>;
  _inc?: InputMaybe<IssueAuditIncInput>;
  _prepend?: InputMaybe<IssueAuditPrependInput>;
  _set?: InputMaybe<IssueAuditSetInput>;
  where: IssueAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueAuditByPkArgs = {
  _append?: InputMaybe<IssueAuditAppendInput>;
  _delete_at_path?: InputMaybe<IssueAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IssueAuditDeleteKeyInput>;
  _inc?: InputMaybe<IssueAuditIncInput>;
  _prepend?: InputMaybe<IssueAuditPrependInput>;
  _set?: InputMaybe<IssueAuditSetInput>;
  pk_columns: IssueAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueAuditManyArgs = {
  updates: Array<IssueAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueByPkArgs = {
  _append?: InputMaybe<IssueAppendInput>;
  _delete_at_path?: InputMaybe<IssueDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueDeleteElemInput>;
  _delete_key?: InputMaybe<IssueDeleteKeyInput>;
  _inc?: InputMaybe<IssueIncInput>;
  _prepend?: InputMaybe<IssuePrependInput>;
  _set?: InputMaybe<IssueSetInput>;
  pk_columns: IssuePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueManyArgs = {
  updates: Array<IssueUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueParentArgs = {
  _set?: InputMaybe<IssueParentSetInput>;
  where: IssueParentBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueParentAuditArgs = {
  _set?: InputMaybe<IssueParentAuditSetInput>;
  where: IssueParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueParentAuditByPkArgs = {
  _set?: InputMaybe<IssueParentAuditSetInput>;
  pk_columns: IssueParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueParentAuditManyArgs = {
  updates: Array<IssueParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueParentByPkArgs = {
  _set?: InputMaybe<IssueParentSetInput>;
  pk_columns: IssueParentPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueParentManyArgs = {
  updates: Array<IssueParentUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueUpdateArgs = {
  _append?: InputMaybe<IssueUpdateAppendInput>;
  _delete_at_path?: InputMaybe<IssueUpdateDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueUpdateDeleteElemInput>;
  _delete_key?: InputMaybe<IssueUpdateDeleteKeyInput>;
  _prepend?: InputMaybe<IssueUpdatePrependInput>;
  _set?: InputMaybe<IssueUpdateSetInput>;
  where: IssueUpdateBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueUpdateAuditArgs = {
  _append?: InputMaybe<IssueUpdateAuditAppendInput>;
  _delete_at_path?: InputMaybe<IssueUpdateAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueUpdateAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IssueUpdateAuditDeleteKeyInput>;
  _prepend?: InputMaybe<IssueUpdateAuditPrependInput>;
  _set?: InputMaybe<IssueUpdateAuditSetInput>;
  where: IssueUpdateAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateIssueUpdateAuditByPkArgs = {
  _append?: InputMaybe<IssueUpdateAuditAppendInput>;
  _delete_at_path?: InputMaybe<IssueUpdateAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueUpdateAuditDeleteElemInput>;
  _delete_key?: InputMaybe<IssueUpdateAuditDeleteKeyInput>;
  _prepend?: InputMaybe<IssueUpdateAuditPrependInput>;
  _set?: InputMaybe<IssueUpdateAuditSetInput>;
  pk_columns: IssueUpdateAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueUpdateAuditManyArgs = {
  updates: Array<IssueUpdateAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateIssueUpdateByPkArgs = {
  _append?: InputMaybe<IssueUpdateAppendInput>;
  _delete_at_path?: InputMaybe<IssueUpdateDeleteAtPathInput>;
  _delete_elem?: InputMaybe<IssueUpdateDeleteElemInput>;
  _delete_key?: InputMaybe<IssueUpdateDeleteKeyInput>;
  _prepend?: InputMaybe<IssueUpdatePrependInput>;
  _set?: InputMaybe<IssueUpdateSetInput>;
  pk_columns: IssueUpdatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateIssueUpdateManyArgs = {
  updates: Array<IssueUpdateUpdates>;
};


/** mutation root */
export type MutationRootUpdateLinkedItemArgs = {
  _set?: InputMaybe<LinkedItemSetInput>;
  where: LinkedItemBoolExp;
};


/** mutation root */
export type MutationRootUpdateLinkedItemByPkArgs = {
  _set?: InputMaybe<LinkedItemSetInput>;
  pk_columns: LinkedItemPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateLinkedItemManyArgs = {
  updates: Array<LinkedItemUpdates>;
};


/** mutation root */
export type MutationRootUpdateNodeArgs = {
  _inc?: InputMaybe<NodeIncInput>;
  _set?: InputMaybe<NodeSetInput>;
  where: NodeBoolExp;
};


/** mutation root */
export type MutationRootUpdateNodeAncestorArgs = {
  _set?: InputMaybe<NodeAncestorSetInput>;
  where: NodeAncestorBoolExp;
};


/** mutation root */
export type MutationRootUpdateNodeAncestorByPkArgs = {
  _set?: InputMaybe<NodeAncestorSetInput>;
  pk_columns: NodeAncestorPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateNodeAncestorManyArgs = {
  updates: Array<NodeAncestorUpdates>;
};


/** mutation root */
export type MutationRootUpdateNodeByPkArgs = {
  _inc?: InputMaybe<NodeIncInput>;
  _set?: InputMaybe<NodeSetInput>;
  pk_columns: NodePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateNodeManyArgs = {
  updates: Array<NodeUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationArgs = {
  _append?: InputMaybe<ObligationAppendInput>;
  _delete_at_path?: InputMaybe<ObligationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationDeleteKeyInput>;
  _inc?: InputMaybe<ObligationIncInput>;
  _prepend?: InputMaybe<ObligationPrependInput>;
  _set?: InputMaybe<ObligationSetInput>;
  where: ObligationBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationActionAuditArgs = {
  _set?: InputMaybe<ObligationActionAuditSetInput>;
  where: ObligationActionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationActionAuditByPkArgs = {
  _set?: InputMaybe<ObligationActionAuditSetInput>;
  pk_columns: ObligationActionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationActionAuditManyArgs = {
  updates: Array<ObligationActionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentResultArgs = {
  _append?: InputMaybe<ObligationAssessmentResultAppendInput>;
  _delete_at_path?: InputMaybe<ObligationAssessmentResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationAssessmentResultDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationAssessmentResultDeleteKeyInput>;
  _inc?: InputMaybe<ObligationAssessmentResultIncInput>;
  _prepend?: InputMaybe<ObligationAssessmentResultPrependInput>;
  _set?: InputMaybe<ObligationAssessmentResultSetInput>;
  where: ObligationAssessmentResultBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentResultAuditArgs = {
  _append?: InputMaybe<ObligationAssessmentResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<ObligationAssessmentResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationAssessmentResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationAssessmentResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<ObligationAssessmentResultAuditIncInput>;
  _prepend?: InputMaybe<ObligationAssessmentResultAuditPrependInput>;
  _set?: InputMaybe<ObligationAssessmentResultAuditSetInput>;
  where: ObligationAssessmentResultAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentResultAuditByPkArgs = {
  _append?: InputMaybe<ObligationAssessmentResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<ObligationAssessmentResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationAssessmentResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationAssessmentResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<ObligationAssessmentResultAuditIncInput>;
  _prepend?: InputMaybe<ObligationAssessmentResultAuditPrependInput>;
  _set?: InputMaybe<ObligationAssessmentResultAuditSetInput>;
  pk_columns: ObligationAssessmentResultAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentResultAuditManyArgs = {
  updates: Array<ObligationAssessmentResultAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentResultByPkArgs = {
  _append?: InputMaybe<ObligationAssessmentResultAppendInput>;
  _delete_at_path?: InputMaybe<ObligationAssessmentResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationAssessmentResultDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationAssessmentResultDeleteKeyInput>;
  _inc?: InputMaybe<ObligationAssessmentResultIncInput>;
  _prepend?: InputMaybe<ObligationAssessmentResultPrependInput>;
  _set?: InputMaybe<ObligationAssessmentResultSetInput>;
  pk_columns: ObligationAssessmentResultPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentResultManyArgs = {
  updates: Array<ObligationAssessmentResultUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentStatusArgs = {
  _set?: InputMaybe<ObligationAssessmentStatusSetInput>;
  where: ObligationAssessmentStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentStatusByPkArgs = {
  _set?: InputMaybe<ObligationAssessmentStatusSetInput>;
  pk_columns: ObligationAssessmentStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationAssessmentStatusManyArgs = {
  updates: Array<ObligationAssessmentStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationAuditArgs = {
  _append?: InputMaybe<ObligationAuditAppendInput>;
  _delete_at_path?: InputMaybe<ObligationAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationAuditDeleteKeyInput>;
  _inc?: InputMaybe<ObligationAuditIncInput>;
  _prepend?: InputMaybe<ObligationAuditPrependInput>;
  _set?: InputMaybe<ObligationAuditSetInput>;
  where: ObligationAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationAuditByPkArgs = {
  _append?: InputMaybe<ObligationAuditAppendInput>;
  _delete_at_path?: InputMaybe<ObligationAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationAuditDeleteKeyInput>;
  _inc?: InputMaybe<ObligationAuditIncInput>;
  _prepend?: InputMaybe<ObligationAuditPrependInput>;
  _set?: InputMaybe<ObligationAuditSetInput>;
  pk_columns: ObligationAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationAuditManyArgs = {
  updates: Array<ObligationAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationByPkArgs = {
  _append?: InputMaybe<ObligationAppendInput>;
  _delete_at_path?: InputMaybe<ObligationDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationDeleteKeyInput>;
  _inc?: InputMaybe<ObligationIncInput>;
  _prepend?: InputMaybe<ObligationPrependInput>;
  _set?: InputMaybe<ObligationSetInput>;
  pk_columns: ObligationPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationImpactArgs = {
  _append?: InputMaybe<ObligationImpactAppendInput>;
  _delete_at_path?: InputMaybe<ObligationImpactDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationImpactDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationImpactDeleteKeyInput>;
  _inc?: InputMaybe<ObligationImpactIncInput>;
  _prepend?: InputMaybe<ObligationImpactPrependInput>;
  _set?: InputMaybe<ObligationImpactSetInput>;
  where: ObligationImpactBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationImpactAuditArgs = {
  _append?: InputMaybe<ObligationImpactAuditAppendInput>;
  _delete_at_path?: InputMaybe<ObligationImpactAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationImpactAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationImpactAuditDeleteKeyInput>;
  _inc?: InputMaybe<ObligationImpactAuditIncInput>;
  _prepend?: InputMaybe<ObligationImpactAuditPrependInput>;
  _set?: InputMaybe<ObligationImpactAuditSetInput>;
  where: ObligationImpactAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationImpactAuditByPkArgs = {
  _append?: InputMaybe<ObligationImpactAuditAppendInput>;
  _delete_at_path?: InputMaybe<ObligationImpactAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationImpactAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationImpactAuditDeleteKeyInput>;
  _inc?: InputMaybe<ObligationImpactAuditIncInput>;
  _prepend?: InputMaybe<ObligationImpactAuditPrependInput>;
  _set?: InputMaybe<ObligationImpactAuditSetInput>;
  pk_columns: ObligationImpactAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationImpactAuditManyArgs = {
  updates: Array<ObligationImpactAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationImpactByPkArgs = {
  _append?: InputMaybe<ObligationImpactAppendInput>;
  _delete_at_path?: InputMaybe<ObligationImpactDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ObligationImpactDeleteElemInput>;
  _delete_key?: InputMaybe<ObligationImpactDeleteKeyInput>;
  _inc?: InputMaybe<ObligationImpactIncInput>;
  _prepend?: InputMaybe<ObligationImpactPrependInput>;
  _set?: InputMaybe<ObligationImpactSetInput>;
  pk_columns: ObligationImpactPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationImpactManyArgs = {
  updates: Array<ObligationImpactUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationManyArgs = {
  updates: Array<ObligationUpdates>;
};


/** mutation root */
export type MutationRootUpdateObligationTypeArgs = {
  _set?: InputMaybe<ObligationTypeSetInput>;
  where: ObligationTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateObligationTypeByPkArgs = {
  _set?: InputMaybe<ObligationTypeSetInput>;
  pk_columns: ObligationTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateObligationTypeManyArgs = {
  updates: Array<ObligationTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateOwnerArgs = {
  _set?: InputMaybe<OwnerSetInput>;
  where: OwnerBoolExp;
};


/** mutation root */
export type MutationRootUpdateOwnerAuditArgs = {
  _set?: InputMaybe<OwnerAuditSetInput>;
  where: OwnerAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateOwnerAuditByPkArgs = {
  _set?: InputMaybe<OwnerAuditSetInput>;
  pk_columns: OwnerAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOwnerAuditManyArgs = {
  updates: Array<OwnerAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateOwnerByPkArgs = {
  _set?: InputMaybe<OwnerSetInput>;
  pk_columns: OwnerPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOwnerGroupArgs = {
  _set?: InputMaybe<OwnerGroupSetInput>;
  where: OwnerGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateOwnerGroupAuditArgs = {
  _set?: InputMaybe<OwnerGroupAuditSetInput>;
  where: OwnerGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateOwnerGroupAuditByPkArgs = {
  _set?: InputMaybe<OwnerGroupAuditSetInput>;
  pk_columns: OwnerGroupAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOwnerGroupAuditManyArgs = {
  updates: Array<OwnerGroupAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateOwnerGroupByPkArgs = {
  _set?: InputMaybe<OwnerGroupSetInput>;
  pk_columns: OwnerGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateOwnerGroupManyArgs = {
  updates: Array<OwnerGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateOwnerManyArgs = {
  updates: Array<OwnerUpdates>;
};


/** mutation root */
export type MutationRootUpdateParentTypeArgs = {
  _set?: InputMaybe<ParentTypeSetInput>;
  where: ParentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateParentTypeByPkArgs = {
  _set?: InputMaybe<ParentTypeSetInput>;
  pk_columns: ParentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateParentTypeManyArgs = {
  updates: Array<ParentTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireInviteArgs = {
  _set?: InputMaybe<QuestionnaireInviteSetInput>;
  where: QuestionnaireInviteBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireInviteByPkArgs = {
  _set?: InputMaybe<QuestionnaireInviteSetInput>;
  pk_columns: QuestionnaireInvitePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireInviteManyArgs = {
  updates: Array<QuestionnaireInviteUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateArgs = {
  _append?: InputMaybe<QuestionnaireTemplateAppendInput>;
  _delete_at_path?: InputMaybe<QuestionnaireTemplateDeleteAtPathInput>;
  _delete_elem?: InputMaybe<QuestionnaireTemplateDeleteElemInput>;
  _delete_key?: InputMaybe<QuestionnaireTemplateDeleteKeyInput>;
  _prepend?: InputMaybe<QuestionnaireTemplatePrependInput>;
  _set?: InputMaybe<QuestionnaireTemplateSetInput>;
  where: QuestionnaireTemplateBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateByPkArgs = {
  _append?: InputMaybe<QuestionnaireTemplateAppendInput>;
  _delete_at_path?: InputMaybe<QuestionnaireTemplateDeleteAtPathInput>;
  _delete_elem?: InputMaybe<QuestionnaireTemplateDeleteElemInput>;
  _delete_key?: InputMaybe<QuestionnaireTemplateDeleteKeyInput>;
  _prepend?: InputMaybe<QuestionnaireTemplatePrependInput>;
  _set?: InputMaybe<QuestionnaireTemplateSetInput>;
  pk_columns: QuestionnaireTemplatePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateManyArgs = {
  updates: Array<QuestionnaireTemplateUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateVersionArgs = {
  _append?: InputMaybe<QuestionnaireTemplateVersionAppendInput>;
  _delete_at_path?: InputMaybe<QuestionnaireTemplateVersionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<QuestionnaireTemplateVersionDeleteElemInput>;
  _delete_key?: InputMaybe<QuestionnaireTemplateVersionDeleteKeyInput>;
  _prepend?: InputMaybe<QuestionnaireTemplateVersionPrependInput>;
  _set?: InputMaybe<QuestionnaireTemplateVersionSetInput>;
  where: QuestionnaireTemplateVersionBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateVersionByPkArgs = {
  _append?: InputMaybe<QuestionnaireTemplateVersionAppendInput>;
  _delete_at_path?: InputMaybe<QuestionnaireTemplateVersionDeleteAtPathInput>;
  _delete_elem?: InputMaybe<QuestionnaireTemplateVersionDeleteElemInput>;
  _delete_key?: InputMaybe<QuestionnaireTemplateVersionDeleteKeyInput>;
  _prepend?: InputMaybe<QuestionnaireTemplateVersionPrependInput>;
  _set?: InputMaybe<QuestionnaireTemplateVersionSetInput>;
  pk_columns: QuestionnaireTemplateVersionPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateVersionManyArgs = {
  updates: Array<QuestionnaireTemplateVersionUpdates>;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateVersionStatusArgs = {
  _set?: InputMaybe<QuestionnaireTemplateVersionStatusSetInput>;
  where: QuestionnaireTemplateVersionStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateVersionStatusByPkArgs = {
  _set?: InputMaybe<QuestionnaireTemplateVersionStatusSetInput>;
  pk_columns: QuestionnaireTemplateVersionStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateQuestionnaireTemplateVersionStatusManyArgs = {
  updates: Array<QuestionnaireTemplateVersionStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateRelationFileArgs = {
  _set?: InputMaybe<RelationFileSetInput>;
  where: RelationFileBoolExp;
};


/** mutation root */
export type MutationRootUpdateRelationFileAuditArgs = {
  _set?: InputMaybe<RelationFileAuditSetInput>;
  where: RelationFileAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRelationFileAuditByPkArgs = {
  _set?: InputMaybe<RelationFileAuditSetInput>;
  pk_columns: RelationFileAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRelationFileAuditManyArgs = {
  updates: Array<RelationFileAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRelationFileByPkArgs = {
  _set?: InputMaybe<RelationFileSetInput>;
  pk_columns: RelationFilePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRelationFileManyArgs = {
  updates: Array<RelationFileUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskArgs = {
  _append?: InputMaybe<RiskAppendInput>;
  _delete_at_path?: InputMaybe<RiskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskDeleteElemInput>;
  _delete_key?: InputMaybe<RiskDeleteKeyInput>;
  _inc?: InputMaybe<RiskIncInput>;
  _prepend?: InputMaybe<RiskPrependInput>;
  _set?: InputMaybe<RiskSetInput>;
  where: RiskBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultArgs = {
  _append?: InputMaybe<RiskAssessmentResultAppendInput>;
  _delete_at_path?: InputMaybe<RiskAssessmentResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskAssessmentResultDeleteElemInput>;
  _delete_key?: InputMaybe<RiskAssessmentResultDeleteKeyInput>;
  _inc?: InputMaybe<RiskAssessmentResultIncInput>;
  _prepend?: InputMaybe<RiskAssessmentResultPrependInput>;
  _set?: InputMaybe<RiskAssessmentResultSetInput>;
  where: RiskAssessmentResultBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultAuditArgs = {
  _append?: InputMaybe<RiskAssessmentResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<RiskAssessmentResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskAssessmentResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RiskAssessmentResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<RiskAssessmentResultAuditIncInput>;
  _prepend?: InputMaybe<RiskAssessmentResultAuditPrependInput>;
  _set?: InputMaybe<RiskAssessmentResultAuditSetInput>;
  where: RiskAssessmentResultAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultAuditByPkArgs = {
  _append?: InputMaybe<RiskAssessmentResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<RiskAssessmentResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskAssessmentResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RiskAssessmentResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<RiskAssessmentResultAuditIncInput>;
  _prepend?: InputMaybe<RiskAssessmentResultAuditPrependInput>;
  _set?: InputMaybe<RiskAssessmentResultAuditSetInput>;
  pk_columns: RiskAssessmentResultAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultAuditManyArgs = {
  updates: Array<RiskAssessmentResultAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultByPkArgs = {
  _append?: InputMaybe<RiskAssessmentResultAppendInput>;
  _delete_at_path?: InputMaybe<RiskAssessmentResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskAssessmentResultDeleteElemInput>;
  _delete_key?: InputMaybe<RiskAssessmentResultDeleteKeyInput>;
  _inc?: InputMaybe<RiskAssessmentResultIncInput>;
  _prepend?: InputMaybe<RiskAssessmentResultPrependInput>;
  _set?: InputMaybe<RiskAssessmentResultSetInput>;
  pk_columns: RiskAssessmentResultPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultControlTypeArgs = {
  _set?: InputMaybe<RiskAssessmentResultControlTypeSetInput>;
  where: RiskAssessmentResultControlTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultControlTypeByPkArgs = {
  _set?: InputMaybe<RiskAssessmentResultControlTypeSetInput>;
  pk_columns: RiskAssessmentResultControlTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultControlTypeManyArgs = {
  updates: Array<RiskAssessmentResultControlTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskAssessmentResultManyArgs = {
  updates: Array<RiskAssessmentResultUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskAuditArgs = {
  _append?: InputMaybe<RiskAuditAppendInput>;
  _delete_at_path?: InputMaybe<RiskAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RiskAuditDeleteKeyInput>;
  _inc?: InputMaybe<RiskAuditIncInput>;
  _prepend?: InputMaybe<RiskAuditPrependInput>;
  _set?: InputMaybe<RiskAuditSetInput>;
  where: RiskAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskAuditByPkArgs = {
  _append?: InputMaybe<RiskAuditAppendInput>;
  _delete_at_path?: InputMaybe<RiskAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RiskAuditDeleteKeyInput>;
  _inc?: InputMaybe<RiskAuditIncInput>;
  _prepend?: InputMaybe<RiskAuditPrependInput>;
  _set?: InputMaybe<RiskAuditSetInput>;
  pk_columns: RiskAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskAuditManyArgs = {
  updates: Array<RiskAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskByPkArgs = {
  _append?: InputMaybe<RiskAppendInput>;
  _delete_at_path?: InputMaybe<RiskDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RiskDeleteElemInput>;
  _delete_key?: InputMaybe<RiskDeleteKeyInput>;
  _inc?: InputMaybe<RiskIncInput>;
  _prepend?: InputMaybe<RiskPrependInput>;
  _set?: InputMaybe<RiskSetInput>;
  pk_columns: RiskPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskManyArgs = {
  updates: Array<RiskUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskScoreArgs = {
  _inc?: InputMaybe<RiskScoreIncInput>;
  _set?: InputMaybe<RiskScoreSetInput>;
  where: RiskScoreBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskScoreByPkArgs = {
  _inc?: InputMaybe<RiskScoreIncInput>;
  _set?: InputMaybe<RiskScoreSetInput>;
  pk_columns: RiskScorePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskScoreManyArgs = {
  updates: Array<RiskScoreUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskScoringModelArgs = {
  _set?: InputMaybe<RiskScoringModelSetInput>;
  where: RiskScoringModelBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskScoringModelByPkArgs = {
  _set?: InputMaybe<RiskScoringModelSetInput>;
  pk_columns: RiskScoringModelPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskScoringModelManyArgs = {
  updates: Array<RiskScoringModelUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskStatusTypeArgs = {
  _set?: InputMaybe<RiskStatusTypeSetInput>;
  where: RiskStatusTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskStatusTypeByPkArgs = {
  _set?: InputMaybe<RiskStatusTypeSetInput>;
  pk_columns: RiskStatusTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskStatusTypeManyArgs = {
  updates: Array<RiskStatusTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateRiskTreatmentTypeArgs = {
  _set?: InputMaybe<RiskTreatmentTypeSetInput>;
  where: RiskTreatmentTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateRiskTreatmentTypeByPkArgs = {
  _set?: InputMaybe<RiskTreatmentTypeSetInput>;
  pk_columns: RiskTreatmentTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRiskTreatmentTypeManyArgs = {
  updates: Array<RiskTreatmentTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartApproverResponseAuditArgs = {
  _set?: InputMaybe<RisksmartApproverResponseAuditSetInput>;
  where: RisksmartApproverResponseAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartApproverResponseAuditByPkArgs = {
  _set?: InputMaybe<RisksmartApproverResponseAuditSetInput>;
  pk_columns: RisksmartApproverResponseAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartApproverResponseAuditManyArgs = {
  updates: Array<RisksmartApproverResponseAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartChangeRequestContributorAuditArgs = {
  _set?: InputMaybe<RisksmartChangeRequestContributorAuditSetInput>;
  where: RisksmartChangeRequestContributorAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartChangeRequestContributorAuditByPkArgs = {
  _set?: InputMaybe<RisksmartChangeRequestContributorAuditSetInput>;
  pk_columns: RisksmartChangeRequestContributorAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartChangeRequestContributorAuditManyArgs = {
  updates: Array<RisksmartChangeRequestContributorAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartControlActionAuditArgs = {
  _set?: InputMaybe<RisksmartControlActionAuditSetInput>;
  where: RisksmartControlActionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartControlActionAuditByPkArgs = {
  _set?: InputMaybe<RisksmartControlActionAuditSetInput>;
  pk_columns: RisksmartControlActionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartControlActionAuditManyArgs = {
  updates: Array<RisksmartControlActionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartCounterArgs = {
  _inc?: InputMaybe<RisksmartCounterIncInput>;
  _set?: InputMaybe<RisksmartCounterSetInput>;
  where: RisksmartCounterBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartCounterByPkArgs = {
  _inc?: InputMaybe<RisksmartCounterIncInput>;
  _set?: InputMaybe<RisksmartCounterSetInput>;
  pk_columns: RisksmartCounterPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartCounterManyArgs = {
  updates: Array<RisksmartCounterUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartIndicatorParentAuditArgs = {
  _set?: InputMaybe<RisksmartIndicatorParentAuditSetInput>;
  where: RisksmartIndicatorParentAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartIndicatorParentAuditByPkArgs = {
  _set?: InputMaybe<RisksmartIndicatorParentAuditSetInput>;
  pk_columns: RisksmartIndicatorParentAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartIndicatorParentAuditManyArgs = {
  updates: Array<RisksmartIndicatorParentAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartLinkedItemAuditArgs = {
  _set?: InputMaybe<RisksmartLinkedItemAuditSetInput>;
  where: RisksmartLinkedItemAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartLinkedItemAuditByPkArgs = {
  _set?: InputMaybe<RisksmartLinkedItemAuditSetInput>;
  pk_columns: RisksmartLinkedItemAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartLinkedItemAuditManyArgs = {
  updates: Array<RisksmartLinkedItemAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartObligationIssueAuditArgs = {
  _set?: InputMaybe<RisksmartObligationIssueAuditSetInput>;
  where: RisksmartObligationIssueAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartObligationIssueAuditByPkArgs = {
  _set?: InputMaybe<RisksmartObligationIssueAuditSetInput>;
  pk_columns: RisksmartObligationIssueAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartObligationIssueAuditManyArgs = {
  updates: Array<RisksmartObligationIssueAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartQuestionnaireTemplateAuditArgs = {
  _append?: InputMaybe<RisksmartQuestionnaireTemplateAuditAppendInput>;
  _delete_at_path?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteKeyInput>;
  _prepend?: InputMaybe<RisksmartQuestionnaireTemplateAuditPrependInput>;
  _set?: InputMaybe<RisksmartQuestionnaireTemplateAuditSetInput>;
  where: RisksmartQuestionnaireTemplateAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartQuestionnaireTemplateAuditByPkArgs = {
  _append?: InputMaybe<RisksmartQuestionnaireTemplateAuditAppendInput>;
  _delete_at_path?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteKeyInput>;
  _prepend?: InputMaybe<RisksmartQuestionnaireTemplateAuditPrependInput>;
  _set?: InputMaybe<RisksmartQuestionnaireTemplateAuditSetInput>;
  pk_columns: RisksmartQuestionnaireTemplateAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartQuestionnaireTemplateAuditManyArgs = {
  updates: Array<RisksmartQuestionnaireTemplateAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartQuestionnaireTemplateVersionAuditArgs = {
  _append?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditAppendInput>;
  _delete_at_path?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteKeyInput>;
  _prepend?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditPrependInput>;
  _set?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditSetInput>;
  where: RisksmartQuestionnaireTemplateVersionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartQuestionnaireTemplateVersionAuditByPkArgs = {
  _append?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditAppendInput>;
  _delete_at_path?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteElemInput>;
  _delete_key?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteKeyInput>;
  _prepend?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditPrependInput>;
  _set?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditSetInput>;
  pk_columns: RisksmartQuestionnaireTemplateVersionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartQuestionnaireTemplateVersionAuditManyArgs = {
  updates: Array<RisksmartQuestionnaireTemplateVersionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRisksmartRiskActionAuditArgs = {
  _set?: InputMaybe<RisksmartRiskActionAuditSetInput>;
  where: RisksmartRiskActionAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateRisksmartRiskActionAuditByPkArgs = {
  _set?: InputMaybe<RisksmartRiskActionAuditSetInput>;
  pk_columns: RisksmartRiskActionAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRisksmartRiskActionAuditManyArgs = {
  updates: Array<RisksmartRiskActionAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateRoleAccessArgs = {
  _set?: InputMaybe<RoleAccessSetInput>;
  where: RoleAccessBoolExp;
};


/** mutation root */
export type MutationRootUpdateRoleAccessByPkArgs = {
  _set?: InputMaybe<RoleAccessSetInput>;
  pk_columns: RoleAccessPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateRoleAccessManyArgs = {
  updates: Array<RoleAccessUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagArgs = {
  _set?: InputMaybe<TagSetInput>;
  where: TagBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagAuditArgs = {
  _set?: InputMaybe<TagAuditSetInput>;
  where: TagAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagAuditByPkArgs = {
  _set?: InputMaybe<TagAuditSetInput>;
  pk_columns: TagAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagAuditManyArgs = {
  updates: Array<TagAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagByPkArgs = {
  _set?: InputMaybe<TagSetInput>;
  pk_columns: TagPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagManyArgs = {
  updates: Array<TagUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagTypeArgs = {
  _set?: InputMaybe<TagTypeSetInput>;
  where: TagTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagTypeAuditArgs = {
  _set?: InputMaybe<TagTypeAuditSetInput>;
  where: TagTypeAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagTypeAuditByPkArgs = {
  _set?: InputMaybe<TagTypeAuditSetInput>;
  pk_columns: TagTypeAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagTypeAuditManyArgs = {
  updates: Array<TagTypeAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagTypeByPkArgs = {
  _set?: InputMaybe<TagTypeSetInput>;
  pk_columns: TagTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagTypeGroupArgs = {
  _set?: InputMaybe<TagTypeGroupSetInput>;
  where: TagTypeGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagTypeGroupAuditArgs = {
  _set?: InputMaybe<TagTypeGroupAuditSetInput>;
  where: TagTypeGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateTagTypeGroupAuditByPkArgs = {
  _set?: InputMaybe<TagTypeGroupAuditSetInput>;
  pk_columns: TagTypeGroupAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagTypeGroupAuditManyArgs = {
  updates: Array<TagTypeGroupAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagTypeGroupByPkArgs = {
  _set?: InputMaybe<TagTypeGroupSetInput>;
  pk_columns: TagTypeGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTagTypeGroupManyArgs = {
  updates: Array<TagTypeGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateTagTypeManyArgs = {
  updates: Array<TagTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateTaxonomyArgs = {
  _append?: InputMaybe<TaxonomyAppendInput>;
  _delete_at_path?: InputMaybe<TaxonomyDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaxonomyDeleteElemInput>;
  _delete_key?: InputMaybe<TaxonomyDeleteKeyInput>;
  _prepend?: InputMaybe<TaxonomyPrependInput>;
  _set?: InputMaybe<TaxonomySetInput>;
  where: TaxonomyBoolExp;
};


/** mutation root */
export type MutationRootUpdateTaxonomyAuditArgs = {
  _append?: InputMaybe<TaxonomyAuditAppendInput>;
  _delete_at_path?: InputMaybe<TaxonomyAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaxonomyAuditDeleteElemInput>;
  _delete_key?: InputMaybe<TaxonomyAuditDeleteKeyInput>;
  _prepend?: InputMaybe<TaxonomyAuditPrependInput>;
  _set?: InputMaybe<TaxonomyAuditSetInput>;
  where: TaxonomyAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateTaxonomyAuditByPkArgs = {
  _append?: InputMaybe<TaxonomyAuditAppendInput>;
  _delete_at_path?: InputMaybe<TaxonomyAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaxonomyAuditDeleteElemInput>;
  _delete_key?: InputMaybe<TaxonomyAuditDeleteKeyInput>;
  _prepend?: InputMaybe<TaxonomyAuditPrependInput>;
  _set?: InputMaybe<TaxonomyAuditSetInput>;
  pk_columns: TaxonomyAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTaxonomyAuditManyArgs = {
  updates: Array<TaxonomyAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateTaxonomyByPkArgs = {
  _append?: InputMaybe<TaxonomyAppendInput>;
  _delete_at_path?: InputMaybe<TaxonomyDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TaxonomyDeleteElemInput>;
  _delete_key?: InputMaybe<TaxonomyDeleteKeyInput>;
  _prepend?: InputMaybe<TaxonomyPrependInput>;
  _set?: InputMaybe<TaxonomySetInput>;
  pk_columns: TaxonomyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTaxonomyManyArgs = {
  updates: Array<TaxonomyUpdates>;
};


/** mutation root */
export type MutationRootUpdateTaxonomyOrgArgs = {
  _set?: InputMaybe<TaxonomyOrgSetInput>;
  where: TaxonomyOrgBoolExp;
};


/** mutation root */
export type MutationRootUpdateTaxonomyOrgAuditArgs = {
  _set?: InputMaybe<TaxonomyOrgAuditSetInput>;
  where: TaxonomyOrgAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateTaxonomyOrgAuditByPkArgs = {
  _set?: InputMaybe<TaxonomyOrgAuditSetInput>;
  pk_columns: TaxonomyOrgAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTaxonomyOrgAuditManyArgs = {
  updates: Array<TaxonomyOrgAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateTaxonomyOrgByPkArgs = {
  _set?: InputMaybe<TaxonomyOrgSetInput>;
  pk_columns: TaxonomyOrgPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTaxonomyOrgManyArgs = {
  updates: Array<TaxonomyOrgUpdates>;
};


/** mutation root */
export type MutationRootUpdateTestFrequencyArgs = {
  _set?: InputMaybe<TestFrequencySetInput>;
  where: TestFrequencyBoolExp;
};


/** mutation root */
export type MutationRootUpdateTestFrequencyByPkArgs = {
  _set?: InputMaybe<TestFrequencySetInput>;
  pk_columns: TestFrequencyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTestFrequencyManyArgs = {
  updates: Array<TestFrequencyUpdates>;
};


/** mutation root */
export type MutationRootUpdateTestResultArgs = {
  _append?: InputMaybe<TestResultAppendInput>;
  _delete_at_path?: InputMaybe<TestResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TestResultDeleteElemInput>;
  _delete_key?: InputMaybe<TestResultDeleteKeyInput>;
  _inc?: InputMaybe<TestResultIncInput>;
  _prepend?: InputMaybe<TestResultPrependInput>;
  _set?: InputMaybe<TestResultSetInput>;
  where: TestResultBoolExp;
};


/** mutation root */
export type MutationRootUpdateTestResultAuditArgs = {
  _append?: InputMaybe<TestResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<TestResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TestResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<TestResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<TestResultAuditIncInput>;
  _prepend?: InputMaybe<TestResultAuditPrependInput>;
  _set?: InputMaybe<TestResultAuditSetInput>;
  where: TestResultAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateTestResultAuditByPkArgs = {
  _append?: InputMaybe<TestResultAuditAppendInput>;
  _delete_at_path?: InputMaybe<TestResultAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TestResultAuditDeleteElemInput>;
  _delete_key?: InputMaybe<TestResultAuditDeleteKeyInput>;
  _inc?: InputMaybe<TestResultAuditIncInput>;
  _prepend?: InputMaybe<TestResultAuditPrependInput>;
  _set?: InputMaybe<TestResultAuditSetInput>;
  pk_columns: TestResultAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTestResultAuditManyArgs = {
  updates: Array<TestResultAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateTestResultByPkArgs = {
  _append?: InputMaybe<TestResultAppendInput>;
  _delete_at_path?: InputMaybe<TestResultDeleteAtPathInput>;
  _delete_elem?: InputMaybe<TestResultDeleteElemInput>;
  _delete_key?: InputMaybe<TestResultDeleteKeyInput>;
  _inc?: InputMaybe<TestResultIncInput>;
  _prepend?: InputMaybe<TestResultPrependInput>;
  _set?: InputMaybe<TestResultSetInput>;
  pk_columns: TestResultPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateTestResultManyArgs = {
  updates: Array<TestResultUpdates>;
};


/** mutation root */
export type MutationRootUpdateThirdPartyArgs = {
  _append?: InputMaybe<ThirdPartyAppendInput>;
  _delete_at_path?: InputMaybe<ThirdPartyDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ThirdPartyDeleteElemInput>;
  _delete_key?: InputMaybe<ThirdPartyDeleteKeyInput>;
  _inc?: InputMaybe<ThirdPartyIncInput>;
  _prepend?: InputMaybe<ThirdPartyPrependInput>;
  _set?: InputMaybe<ThirdPartySetInput>;
  where: ThirdPartyBoolExp;
};


/** mutation root */
export type MutationRootUpdateThirdPartyAuditArgs = {
  _append?: InputMaybe<ThirdPartyAuditAppendInput>;
  _delete_at_path?: InputMaybe<ThirdPartyAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ThirdPartyAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ThirdPartyAuditDeleteKeyInput>;
  _inc?: InputMaybe<ThirdPartyAuditIncInput>;
  _prepend?: InputMaybe<ThirdPartyAuditPrependInput>;
  _set?: InputMaybe<ThirdPartyAuditSetInput>;
  where: ThirdPartyAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateThirdPartyAuditByPkArgs = {
  _append?: InputMaybe<ThirdPartyAuditAppendInput>;
  _delete_at_path?: InputMaybe<ThirdPartyAuditDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ThirdPartyAuditDeleteElemInput>;
  _delete_key?: InputMaybe<ThirdPartyAuditDeleteKeyInput>;
  _inc?: InputMaybe<ThirdPartyAuditIncInput>;
  _prepend?: InputMaybe<ThirdPartyAuditPrependInput>;
  _set?: InputMaybe<ThirdPartyAuditSetInput>;
  pk_columns: ThirdPartyAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateThirdPartyAuditManyArgs = {
  updates: Array<ThirdPartyAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateThirdPartyByPkArgs = {
  _append?: InputMaybe<ThirdPartyAppendInput>;
  _delete_at_path?: InputMaybe<ThirdPartyDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ThirdPartyDeleteElemInput>;
  _delete_key?: InputMaybe<ThirdPartyDeleteKeyInput>;
  _inc?: InputMaybe<ThirdPartyIncInput>;
  _prepend?: InputMaybe<ThirdPartyPrependInput>;
  _set?: InputMaybe<ThirdPartySetInput>;
  pk_columns: ThirdPartyPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateThirdPartyManyArgs = {
  updates: Array<ThirdPartyUpdates>;
};


/** mutation root */
export type MutationRootUpdateThirdPartyResponseArgs = {
  _append?: InputMaybe<ThirdPartyResponseAppendInput>;
  _delete_at_path?: InputMaybe<ThirdPartyResponseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ThirdPartyResponseDeleteElemInput>;
  _delete_key?: InputMaybe<ThirdPartyResponseDeleteKeyInput>;
  _prepend?: InputMaybe<ThirdPartyResponsePrependInput>;
  _set?: InputMaybe<ThirdPartyResponseSetInput>;
  where: ThirdPartyResponseBoolExp;
};


/** mutation root */
export type MutationRootUpdateThirdPartyResponseByPkArgs = {
  _append?: InputMaybe<ThirdPartyResponseAppendInput>;
  _delete_at_path?: InputMaybe<ThirdPartyResponseDeleteAtPathInput>;
  _delete_elem?: InputMaybe<ThirdPartyResponseDeleteElemInput>;
  _delete_key?: InputMaybe<ThirdPartyResponseDeleteKeyInput>;
  _prepend?: InputMaybe<ThirdPartyResponsePrependInput>;
  _set?: InputMaybe<ThirdPartyResponseSetInput>;
  pk_columns: ThirdPartyResponsePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateThirdPartyResponseManyArgs = {
  updates: Array<ThirdPartyResponseUpdates>;
};


/** mutation root */
export type MutationRootUpdateThirdPartyResponseStatusArgs = {
  _set?: InputMaybe<ThirdPartyResponseStatusSetInput>;
  where: ThirdPartyResponseStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateThirdPartyResponseStatusByPkArgs = {
  _set?: InputMaybe<ThirdPartyResponseStatusSetInput>;
  pk_columns: ThirdPartyResponseStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateThirdPartyResponseStatusManyArgs = {
  updates: Array<ThirdPartyResponseStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateThirdPartyStatusArgs = {
  _set?: InputMaybe<ThirdPartyStatusSetInput>;
  where: ThirdPartyStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateThirdPartyStatusByPkArgs = {
  _set?: InputMaybe<ThirdPartyStatusSetInput>;
  pk_columns: ThirdPartyStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateThirdPartyStatusManyArgs = {
  updates: Array<ThirdPartyStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateThirdPartyTypeArgs = {
  _set?: InputMaybe<ThirdPartyTypeSetInput>;
  where: ThirdPartyTypeBoolExp;
};


/** mutation root */
export type MutationRootUpdateThirdPartyTypeByPkArgs = {
  _set?: InputMaybe<ThirdPartyTypeSetInput>;
  pk_columns: ThirdPartyTypePkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateThirdPartyTypeManyArgs = {
  updates: Array<ThirdPartyTypeUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupArgs = {
  _set?: InputMaybe<UserGroupSetInput>;
  where: UserGroupBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupAuditArgs = {
  _set?: InputMaybe<UserGroupAuditSetInput>;
  where: UserGroupAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupAuditByPkArgs = {
  _set?: InputMaybe<UserGroupAuditSetInput>;
  pk_columns: UserGroupAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupAuditManyArgs = {
  updates: Array<UserGroupAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupByPkArgs = {
  _set?: InputMaybe<UserGroupSetInput>;
  pk_columns: UserGroupPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupManyArgs = {
  updates: Array<UserGroupUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserArgs = {
  _set?: InputMaybe<UserGroupUserSetInput>;
  where: UserGroupUserBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserAuditArgs = {
  _set?: InputMaybe<UserGroupUserAuditSetInput>;
  where: UserGroupUserAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserAuditByPkArgs = {
  _set?: InputMaybe<UserGroupUserAuditSetInput>;
  pk_columns: UserGroupUserAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserAuditManyArgs = {
  updates: Array<UserGroupUserAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserByPkArgs = {
  _set?: InputMaybe<UserGroupUserSetInput>;
  pk_columns: UserGroupUserPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserGroupUserManyArgs = {
  updates: Array<UserGroupUserUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserRolesArgs = {
  roleIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


/** mutation root */
export type MutationRootUpdateUserSearchPreferencesArgs = {
  _set?: InputMaybe<UserSearchPreferencesSetInput>;
  where: UserSearchPreferencesBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserSearchPreferencesAuditArgs = {
  _set?: InputMaybe<UserSearchPreferencesAuditSetInput>;
  where: UserSearchPreferencesAuditBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserSearchPreferencesAuditByPkArgs = {
  _set?: InputMaybe<UserSearchPreferencesAuditSetInput>;
  pk_columns: UserSearchPreferencesAuditPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserSearchPreferencesAuditManyArgs = {
  updates: Array<UserSearchPreferencesAuditUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserSearchPreferencesByPkArgs = {
  _set?: InputMaybe<UserSearchPreferencesSetInput>;
  pk_columns: UserSearchPreferencesPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserSearchPreferencesManyArgs = {
  updates: Array<UserSearchPreferencesUpdates>;
};


/** mutation root */
export type MutationRootUpdateUserStatusArgs = {
  _set?: InputMaybe<UserStatusSetInput>;
  where: UserStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateUserStatusByPkArgs = {
  _set?: InputMaybe<UserStatusSetInput>;
  pk_columns: UserStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateUserStatusManyArgs = {
  updates: Array<UserStatusUpdates>;
};


/** mutation root */
export type MutationRootUpdateVersionStatusArgs = {
  _set?: InputMaybe<VersionStatusSetInput>;
  where: VersionStatusBoolExp;
};


/** mutation root */
export type MutationRootUpdateVersionStatusByPkArgs = {
  _set?: InputMaybe<VersionStatusSetInput>;
  pk_columns: VersionStatusPkColumnsInput;
};


/** mutation root */
export type MutationRootUpdateVersionStatusManyArgs = {
  updates: Array<VersionStatusUpdates>;
};

/** columns and relationships of "risksmart.node" */
export type Node = {
  __typename?: 'node';
  Id: Scalars['uuid']['output'];
  ObjectType: ParentTypeEnum;
  OrgKey: Scalars['String']['output'];
  SequentialId?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  anyContributorAccess: Array<UserRoleAccess>;
  /** An aggregate relationship */
  anyContributorAccess_aggregate: UserRoleAccessAggregate;
  /** An object relationship */
  control?: Maybe<Control>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  documentFile?: Maybe<DocumentFile>;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  issue_assessment?: Maybe<IssueAssessment>;
  /** An object relationship */
  obligation?: Maybe<Obligation>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  risk?: Maybe<Risk>;
};


/** columns and relationships of "risksmart.node" */
export type NodeAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodeAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodeAnyContributorAccessArgs = {
  distinct_on?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleAccessOrderBy>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodeAnyContributorAccessAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleAccessOrderBy>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodeInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodeInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.node" */
export type NodePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.node" */
export type NodeAggregate = {
  __typename?: 'node_aggregate';
  aggregate?: Maybe<NodeAggregateFields>;
  nodes: Array<Node>;
};

/** aggregate fields of "risksmart.node" */
export type NodeAggregateFields = {
  __typename?: 'node_aggregate_fields';
  avg?: Maybe<NodeAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<NodeMaxFields>;
  min?: Maybe<NodeMinFields>;
  stddev?: Maybe<NodeStddevFields>;
  stddev_pop?: Maybe<NodeStddevPopFields>;
  stddev_samp?: Maybe<NodeStddevSampFields>;
  sum?: Maybe<NodeSumFields>;
  var_pop?: Maybe<NodeVarPopFields>;
  var_samp?: Maybe<NodeVarSampFields>;
  variance?: Maybe<NodeVarianceFields>;
};


/** aggregate fields of "risksmart.node" */
export type NodeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NodeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.node_ancestor" */
export type NodeAncestor = {
  __typename?: 'node_ancestor';
  AncestorId: Scalars['uuid']['output'];
  AncestorObjectType?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ObjectType: Scalars['String']['output'];
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.node_ancestor" */
export type NodeAncestorAggregate = {
  __typename?: 'node_ancestor_aggregate';
  aggregate?: Maybe<NodeAncestorAggregateFields>;
  nodes: Array<NodeAncestor>;
};

/** aggregate fields of "risksmart.node_ancestor" */
export type NodeAncestorAggregateFields = {
  __typename?: 'node_ancestor_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<NodeAncestorMaxFields>;
  min?: Maybe<NodeAncestorMinFields>;
};


/** aggregate fields of "risksmart.node_ancestor" */
export type NodeAncestorAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<NodeAncestorSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.node_ancestor". All fields are combined with a logical 'AND'. */
export type NodeAncestorBoolExp = {
  AncestorId?: InputMaybe<UuidComparisonExp>;
  AncestorObjectType?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ObjectType?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<NodeAncestorBoolExp>>;
  _not?: InputMaybe<NodeAncestorBoolExp>;
  _or?: InputMaybe<Array<NodeAncestorBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.node_ancestor" */
export enum NodeAncestorConstraint {
  /** unique or primary key constraint on columns "Id", "AncestorId" */
  NodeAncestorPkey = 'node_ancestor_pkey'
}

/** input type for inserting data into table "risksmart.node_ancestor" */
export type NodeAncestorInsertInput = {
  AncestorId?: InputMaybe<Scalars['uuid']['input']>;
  AncestorObjectType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type NodeAncestorMaxFields = {
  __typename?: 'node_ancestor_max_fields';
  AncestorId?: Maybe<Scalars['uuid']['output']>;
  AncestorObjectType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type NodeAncestorMinFields = {
  __typename?: 'node_ancestor_min_fields';
  AncestorId?: Maybe<Scalars['uuid']['output']>;
  AncestorObjectType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.node_ancestor" */
export type NodeAncestorMutationResponse = {
  __typename?: 'node_ancestor_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<NodeAncestor>;
};

/** on_conflict condition type for table "risksmart.node_ancestor" */
export type NodeAncestorOnConflict = {
  constraint: NodeAncestorConstraint;
  update_columns?: Array<NodeAncestorUpdateColumn>;
  where?: InputMaybe<NodeAncestorBoolExp>;
};

/** Ordering options when selecting data from "risksmart.node_ancestor". */
export type NodeAncestorOrderBy = {
  AncestorId?: InputMaybe<OrderBy>;
  AncestorObjectType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.node_ancestor */
export type NodeAncestorPkColumnsInput = {
  AncestorId: Scalars['uuid']['input'];
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.node_ancestor" */
export enum NodeAncestorSelectColumn {
  /** column name */
  AncestorId = 'AncestorId',
  /** column name */
  AncestorObjectType = 'AncestorObjectType',
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.node_ancestor" */
export type NodeAncestorSetInput = {
  AncestorId?: InputMaybe<Scalars['uuid']['input']>;
  AncestorObjectType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "node_ancestor" */
export type NodeAncestorStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: NodeAncestorStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NodeAncestorStreamCursorValueInput = {
  AncestorId?: InputMaybe<Scalars['uuid']['input']>;
  AncestorObjectType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.node_ancestor" */
export enum NodeAncestorUpdateColumn {
  /** column name */
  AncestorId = 'AncestorId',
  /** column name */
  AncestorObjectType = 'AncestorObjectType',
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey'
}

export type NodeAncestorUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NodeAncestorSetInput>;
  /** filter the rows which have to be updated */
  where: NodeAncestorBoolExp;
};

/** aggregate avg on columns */
export type NodeAvgFields = {
  __typename?: 'node_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.node". All fields are combined with a logical 'AND'. */
export type NodeBoolExp = {
  Id?: InputMaybe<UuidComparisonExp>;
  ObjectType?: InputMaybe<ParentTypeEnumComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  _and?: InputMaybe<Array<NodeBoolExp>>;
  _not?: InputMaybe<NodeBoolExp>;
  _or?: InputMaybe<Array<NodeBoolExp>>;
  acceptance?: InputMaybe<AcceptanceBoolExp>;
  action?: InputMaybe<ActionBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  anyContributorAccess?: InputMaybe<UserRoleAccessBoolExp>;
  anyContributorAccess_aggregate?: InputMaybe<UserRoleAccessAggregateBoolExp>;
  control?: InputMaybe<ControlBoolExp>;
  document?: InputMaybe<DocumentBoolExp>;
  documentFile?: InputMaybe<DocumentFileBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  issue_assessment?: InputMaybe<IssueAssessmentBoolExp>;
  obligation?: InputMaybe<ObligationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.node" */
export enum NodeConstraint {
  /** unique or primary key constraint on columns "Id" */
  NodePkey = 'node_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.node" */
export type NodeIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.node" */
export type NodeInsertInput = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<ParentTypeEnum>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  acceptance?: InputMaybe<AcceptanceObjRelInsertInput>;
  action?: InputMaybe<ActionObjRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  anyContributorAccess?: InputMaybe<UserRoleAccessArrRelInsertInput>;
  control?: InputMaybe<ControlObjRelInsertInput>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  documentFile?: InputMaybe<DocumentFileObjRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  issue_assessment?: InputMaybe<IssueAssessmentObjRelInsertInput>;
  obligation?: InputMaybe<ObligationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type NodeMaxFields = {
  __typename?: 'node_max_fields';
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** aggregate min on columns */
export type NodeMinFields = {
  __typename?: 'node_min_fields';
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** response of any mutation on the table "risksmart.node" */
export type NodeMutationResponse = {
  __typename?: 'node_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Node>;
};

/** input type for inserting object relation for remote table "risksmart.node" */
export type NodeObjRelInsertInput = {
  data: NodeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<NodeOnConflict>;
};

/** on_conflict condition type for table "risksmart.node" */
export type NodeOnConflict = {
  constraint: NodeConstraint;
  update_columns?: Array<NodeUpdateColumn>;
  where?: InputMaybe<NodeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.node". */
export type NodeOrderBy = {
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  acceptance?: InputMaybe<AcceptanceOrderBy>;
  action?: InputMaybe<ActionOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  anyContributorAccess_aggregate?: InputMaybe<UserRoleAccessAggregateOrderBy>;
  control?: InputMaybe<ControlOrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  documentFile?: InputMaybe<DocumentFileOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  issue_assessment?: InputMaybe<IssueAssessmentOrderBy>;
  obligation?: InputMaybe<ObligationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.node */
export type NodePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.node" */
export enum NodeSelectColumn {
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId'
}

/** input type for updating data in table "risksmart.node" */
export type NodeSetInput = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<ParentTypeEnum>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate stddev on columns */
export type NodeStddevFields = {
  __typename?: 'node_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type NodeStddevPopFields = {
  __typename?: 'node_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type NodeStddevSampFields = {
  __typename?: 'node_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "node" */
export type NodeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: NodeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type NodeStreamCursorValueInput = {
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<ParentTypeEnum>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** aggregate sum on columns */
export type NodeSumFields = {
  __typename?: 'node_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.node" */
export enum NodeUpdateColumn {
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  SequentialId = 'SequentialId'
}

export type NodeUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<NodeIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<NodeSetInput>;
  /** filter the rows which have to be updated */
  where: NodeBoolExp;
};

/** aggregate var_pop on columns */
export type NodeVarPopFields = {
  __typename?: 'node_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type NodeVarSampFields = {
  __typename?: 'node_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type NodeVarianceFields = {
  __typename?: 'node_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type NumericComparisonExp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** columns and relationships of "risksmart.obligation" */
export type Obligation = {
  __typename?: 'obligation';
  Adherence: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Interpretation?: Maybe<Scalars['String']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<TestFrequencyEnum>;
  Title: Scalars['String']['output'];
  Type: ObligationTypeEnum;
  /** An array relationship */
  actions: Array<ActionParent>;
  /** An aggregate relationship */
  actions_aggregate: ActionParentAggregate;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  assessmentResults: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: AssessmentResultParentAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An array relationship */
  controls: Array<ControlParent>;
  /** An aggregate relationship */
  controls_aggregate: ControlParentAggregate;
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  impacts: Array<ObligationImpact>;
  /** An aggregate relationship */
  impacts_aggregate: ObligationImpactAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An array relationship */
  issues: Array<IssueParent>;
  /** An aggregate relationship */
  issues_aggregate: IssueParentAggregate;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  obligation_type: ObligationType;
  /** An object relationship */
  org?: Maybe<AuthOrganisation>;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An object relationship */
  parent?: Maybe<Obligation>;
  /** An object relationship */
  parentNode?: Maybe<Node>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An object relationship */
  testFrequency?: Maybe<TestFrequency>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationAssessmentResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationControlsArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationControlsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationImpactsArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactOrderBy>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationImpactsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactOrderBy>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationIssuesArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationIssuesAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.obligation" */
export type ObligationTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** columns and relationships of "risksmart.obligation_action_audit" */
export type ObligationActionAudit = {
  __typename?: 'obligation_action_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ObligationId: Scalars['uuid']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.obligation_action_audit" */
export type ObligationActionAuditAggregate = {
  __typename?: 'obligation_action_audit_aggregate';
  aggregate?: Maybe<ObligationActionAuditAggregateFields>;
  nodes: Array<ObligationActionAudit>;
};

/** aggregate fields of "risksmart.obligation_action_audit" */
export type ObligationActionAuditAggregateFields = {
  __typename?: 'obligation_action_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationActionAuditMaxFields>;
  min?: Maybe<ObligationActionAuditMinFields>;
};


/** aggregate fields of "risksmart.obligation_action_audit" */
export type ObligationActionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationActionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_action_audit". All fields are combined with a logical 'AND'. */
export type ObligationActionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActionId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  ObligationId?: InputMaybe<UuidComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ObligationActionAuditBoolExp>>;
  _not?: InputMaybe<ObligationActionAuditBoolExp>;
  _or?: InputMaybe<Array<ObligationActionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.obligation_action_audit" */
export enum ObligationActionAuditConstraint {
  /** unique or primary key constraint on columns "ActionId", "ObligationId", "ModifiedAtTimestamp" */
  ObligationActionAuditPkey = 'obligation_action_audit_pkey'
}

/** input type for inserting data into table "risksmart.obligation_action_audit" */
export type ObligationActionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObligationId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ObligationActionAuditMaxFields = {
  __typename?: 'obligation_action_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObligationId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ObligationActionAuditMinFields = {
  __typename?: 'obligation_action_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObligationId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_action_audit" */
export type ObligationActionAuditMutationResponse = {
  __typename?: 'obligation_action_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationActionAudit>;
};

/** on_conflict condition type for table "risksmart.obligation_action_audit" */
export type ObligationActionAuditOnConflict = {
  constraint: ObligationActionAuditConstraint;
  update_columns?: Array<ObligationActionAuditUpdateColumn>;
  where?: InputMaybe<ObligationActionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_action_audit". */
export type ObligationActionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  ObligationId?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.obligation_action_audit */
export type ObligationActionAuditPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.obligation_action_audit" */
export enum ObligationActionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  ObligationId = 'ObligationId',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.obligation_action_audit" */
export type ObligationActionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObligationId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "obligation_action_audit" */
export type ObligationActionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationActionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationActionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObligationId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.obligation_action_audit" */
export enum ObligationActionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  ObligationId = 'ObligationId',
  /** column name */
  OrgKey = 'OrgKey'
}

export type ObligationActionAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationActionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationActionAuditBoolExp;
};

/** aggregated selection of "risksmart.obligation" */
export type ObligationAggregate = {
  __typename?: 'obligation_aggregate';
  aggregate?: Maybe<ObligationAggregateFields>;
  nodes: Array<Obligation>;
};

export type ObligationAggregateBoolExp = {
  count?: InputMaybe<ObligationAggregateBoolExpCount>;
};

export type ObligationAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ObligationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ObligationBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.obligation" */
export type ObligationAggregateFields = {
  __typename?: 'obligation_aggregate_fields';
  avg?: Maybe<ObligationAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationMaxFields>;
  min?: Maybe<ObligationMinFields>;
  stddev?: Maybe<ObligationStddevFields>;
  stddev_pop?: Maybe<ObligationStddevPopFields>;
  stddev_samp?: Maybe<ObligationStddevSampFields>;
  sum?: Maybe<ObligationSumFields>;
  var_pop?: Maybe<ObligationVarPopFields>;
  var_samp?: Maybe<ObligationVarSampFields>;
  variance?: Maybe<ObligationVarianceFields>;
};


/** aggregate fields of "risksmart.obligation" */
export type ObligationAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.obligation" */
export type ObligationAggregateOrderBy = {
  avg?: InputMaybe<ObligationAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ObligationMaxOrderBy>;
  min?: InputMaybe<ObligationMinOrderBy>;
  stddev?: InputMaybe<ObligationStddevOrderBy>;
  stddev_pop?: InputMaybe<ObligationStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ObligationStddevSampOrderBy>;
  sum?: InputMaybe<ObligationSumOrderBy>;
  var_pop?: InputMaybe<ObligationVarPopOrderBy>;
  var_samp?: InputMaybe<ObligationVarSampOrderBy>;
  variance?: InputMaybe<ObligationVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObligationAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.obligation" */
export type ObligationArrRelInsertInput = {
  data: Array<ObligationInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ObligationOnConflict>;
};

/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResult = {
  __typename?: 'obligation_assessment_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  parents: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  parents_aggregate: AssessmentResultParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultParentsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultAggregate = {
  __typename?: 'obligation_assessment_result_aggregate';
  aggregate?: Maybe<ObligationAssessmentResultAggregateFields>;
  nodes: Array<ObligationAssessmentResult>;
};

/** aggregate fields of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultAggregateFields = {
  __typename?: 'obligation_assessment_result_aggregate_fields';
  avg?: Maybe<ObligationAssessmentResultAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationAssessmentResultMaxFields>;
  min?: Maybe<ObligationAssessmentResultMinFields>;
  stddev?: Maybe<ObligationAssessmentResultStddevFields>;
  stddev_pop?: Maybe<ObligationAssessmentResultStddevPopFields>;
  stddev_samp?: Maybe<ObligationAssessmentResultStddevSampFields>;
  sum?: Maybe<ObligationAssessmentResultSumFields>;
  var_pop?: Maybe<ObligationAssessmentResultVarPopFields>;
  var_samp?: Maybe<ObligationAssessmentResultVarSampFields>;
  variance?: Maybe<ObligationAssessmentResultVarianceFields>;
};


/** aggregate fields of "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationAssessmentResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObligationAssessmentResultAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAudit = {
  __typename?: 'obligation_assessment_result_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditAggregate = {
  __typename?: 'obligation_assessment_result_audit_aggregate';
  aggregate?: Maybe<ObligationAssessmentResultAuditAggregateFields>;
  nodes: Array<ObligationAssessmentResultAudit>;
};

/** aggregate fields of "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditAggregateFields = {
  __typename?: 'obligation_assessment_result_audit_aggregate_fields';
  avg?: Maybe<ObligationAssessmentResultAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationAssessmentResultAuditMaxFields>;
  min?: Maybe<ObligationAssessmentResultAuditMinFields>;
  stddev?: Maybe<ObligationAssessmentResultAuditStddevFields>;
  stddev_pop?: Maybe<ObligationAssessmentResultAuditStddevPopFields>;
  stddev_samp?: Maybe<ObligationAssessmentResultAuditStddevSampFields>;
  sum?: Maybe<ObligationAssessmentResultAuditSumFields>;
  var_pop?: Maybe<ObligationAssessmentResultAuditVarPopFields>;
  var_samp?: Maybe<ObligationAssessmentResultAuditVarSampFields>;
  variance?: Maybe<ObligationAssessmentResultAuditVarianceFields>;
};


/** aggregate fields of "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationAssessmentResultAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObligationAssessmentResultAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ObligationAssessmentResultAuditAvgFields = {
  __typename?: 'obligation_assessment_result_audit_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_assessment_result_audit". All fields are combined with a logical 'AND'. */
export type ObligationAssessmentResultAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<ObligationAssessmentResultAuditBoolExp>>;
  _not?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
  _or?: InputMaybe<Array<ObligationAssessmentResultAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.obligation_assessment_result_audit" */
export enum ObligationAssessmentResultAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ObligationAssessmentResultAuditPkey = 'obligation_assessment_result_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObligationAssessmentResultAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObligationAssessmentResultAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObligationAssessmentResultAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditIncInput = {
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type ObligationAssessmentResultAuditMaxFields = {
  __typename?: 'obligation_assessment_result_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type ObligationAssessmentResultAuditMinFields = {
  __typename?: 'obligation_assessment_result_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditMutationResponse = {
  __typename?: 'obligation_assessment_result_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationAssessmentResultAudit>;
};

/** on_conflict condition type for table "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditOnConflict = {
  constraint: ObligationAssessmentResultAuditConstraint;
  update_columns?: Array<ObligationAssessmentResultAuditUpdateColumn>;
  where?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_assessment_result_audit". */
export type ObligationAssessmentResultAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.obligation_assessment_result_audit */
export type ObligationAssessmentResultAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObligationAssessmentResultAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_assessment_result_audit" */
export enum ObligationAssessmentResultAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type ObligationAssessmentResultAuditStddevFields = {
  __typename?: 'obligation_assessment_result_audit_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ObligationAssessmentResultAuditStddevPopFields = {
  __typename?: 'obligation_assessment_result_audit_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ObligationAssessmentResultAuditStddevSampFields = {
  __typename?: 'obligation_assessment_result_audit_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "obligation_assessment_result_audit" */
export type ObligationAssessmentResultAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationAssessmentResultAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationAssessmentResultAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type ObligationAssessmentResultAuditSumFields = {
  __typename?: 'obligation_assessment_result_audit_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.obligation_assessment_result_audit" */
export enum ObligationAssessmentResultAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

export type ObligationAssessmentResultAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObligationAssessmentResultAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObligationAssessmentResultAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObligationAssessmentResultAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObligationAssessmentResultAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObligationAssessmentResultAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObligationAssessmentResultAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationAssessmentResultAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationAssessmentResultAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ObligationAssessmentResultAuditVarPopFields = {
  __typename?: 'obligation_assessment_result_audit_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ObligationAssessmentResultAuditVarSampFields = {
  __typename?: 'obligation_assessment_result_audit_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ObligationAssessmentResultAuditVarianceFields = {
  __typename?: 'obligation_assessment_result_audit_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ObligationAssessmentResultAvgFields = {
  __typename?: 'obligation_assessment_result_avg_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_assessment_result". All fields are combined with a logical 'AND'. */
export type ObligationAssessmentResultBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<ObligationAssessmentResultBoolExp>>;
  _not?: InputMaybe<ObligationAssessmentResultBoolExp>;
  _or?: InputMaybe<Array<ObligationAssessmentResultBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parents?: InputMaybe<AssessmentResultParentBoolExp>;
  parents_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.obligation_assessment_result" */
export enum ObligationAssessmentResultConstraint {
  /** unique or primary key constraint on columns "Id" */
  ObligationAssessmentResultPkey = 'obligation_assessment_result_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObligationAssessmentResultDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObligationAssessmentResultDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObligationAssessmentResultDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultIncInput = {
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parents?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ObligationAssessmentResultMaxFields = {
  __typename?: 'obligation_assessment_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type ObligationAssessmentResultMinFields = {
  __typename?: 'obligation_assessment_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultMutationResponse = {
  __typename?: 'obligation_assessment_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationAssessmentResult>;
};

/** input type for inserting object relation for remote table "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultObjRelInsertInput = {
  data: ObligationAssessmentResultInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ObligationAssessmentResultOnConflict>;
};

/** on_conflict condition type for table "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultOnConflict = {
  constraint: ObligationAssessmentResultConstraint;
  update_columns?: Array<ObligationAssessmentResultUpdateColumn>;
  where?: InputMaybe<ObligationAssessmentResultBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_assessment_result". */
export type ObligationAssessmentResultOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parents_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.obligation_assessment_result */
export type ObligationAssessmentResultPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObligationAssessmentResultPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_assessment_result" */
export enum ObligationAssessmentResultSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.obligation_assessment_result" */
export type ObligationAssessmentResultSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type ObligationAssessmentResultStddevFields = {
  __typename?: 'obligation_assessment_result_stddev_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ObligationAssessmentResultStddevPopFields = {
  __typename?: 'obligation_assessment_result_stddev_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ObligationAssessmentResultStddevSampFields = {
  __typename?: 'obligation_assessment_result_stddev_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "obligation_assessment_result" */
export type ObligationAssessmentResultStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationAssessmentResultStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationAssessmentResultStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type ObligationAssessmentResultSumFields = {
  __typename?: 'obligation_assessment_result_sum_fields';
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.obligation_assessment_result" */
export enum ObligationAssessmentResultUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

export type ObligationAssessmentResultUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObligationAssessmentResultAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObligationAssessmentResultDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObligationAssessmentResultDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObligationAssessmentResultDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObligationAssessmentResultIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObligationAssessmentResultPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationAssessmentResultSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationAssessmentResultBoolExp;
};

/** aggregate var_pop on columns */
export type ObligationAssessmentResultVarPopFields = {
  __typename?: 'obligation_assessment_result_var_pop_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ObligationAssessmentResultVarSampFields = {
  __typename?: 'obligation_assessment_result_var_samp_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ObligationAssessmentResultVarianceFields = {
  __typename?: 'obligation_assessment_result_variance_fields';
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatus = {
  __typename?: 'obligation_assessment_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusAggregate = {
  __typename?: 'obligation_assessment_status_aggregate';
  aggregate?: Maybe<ObligationAssessmentStatusAggregateFields>;
  nodes: Array<ObligationAssessmentStatus>;
};

/** aggregate fields of "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusAggregateFields = {
  __typename?: 'obligation_assessment_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationAssessmentStatusMaxFields>;
  min?: Maybe<ObligationAssessmentStatusMinFields>;
};


/** aggregate fields of "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationAssessmentStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_assessment_status". All fields are combined with a logical 'AND'. */
export type ObligationAssessmentStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ObligationAssessmentStatusBoolExp>>;
  _not?: InputMaybe<ObligationAssessmentStatusBoolExp>;
  _or?: InputMaybe<Array<ObligationAssessmentStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.obligation_assessment_status" */
export enum ObligationAssessmentStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  ObligationAssessmentStatusPkey = 'obligation_assessment_status_pkey'
}

/** input type for inserting data into table "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ObligationAssessmentStatusMaxFields = {
  __typename?: 'obligation_assessment_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ObligationAssessmentStatusMinFields = {
  __typename?: 'obligation_assessment_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusMutationResponse = {
  __typename?: 'obligation_assessment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationAssessmentStatus>;
};

/** on_conflict condition type for table "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusOnConflict = {
  constraint: ObligationAssessmentStatusConstraint;
  update_columns?: Array<ObligationAssessmentStatusUpdateColumn>;
  where?: InputMaybe<ObligationAssessmentStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_assessment_status". */
export type ObligationAssessmentStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.obligation_assessment_status */
export type ObligationAssessmentStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.obligation_assessment_status" */
export enum ObligationAssessmentStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.obligation_assessment_status" */
export type ObligationAssessmentStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "obligation_assessment_status" */
export type ObligationAssessmentStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationAssessmentStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationAssessmentStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.obligation_assessment_status" */
export enum ObligationAssessmentStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ObligationAssessmentStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationAssessmentStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationAssessmentStatusBoolExp;
};

/** columns and relationships of "risksmart.obligation_audit" */
export type ObligationAudit = {
  __typename?: 'obligation_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  Interpretation?: Maybe<Scalars['String']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.obligation_audit" */
export type ObligationAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.obligation_audit" */
export type ObligationAuditAggregate = {
  __typename?: 'obligation_audit_aggregate';
  aggregate?: Maybe<ObligationAuditAggregateFields>;
  nodes: Array<ObligationAudit>;
};

/** aggregate fields of "risksmart.obligation_audit" */
export type ObligationAuditAggregateFields = {
  __typename?: 'obligation_audit_aggregate_fields';
  avg?: Maybe<ObligationAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationAuditMaxFields>;
  min?: Maybe<ObligationAuditMinFields>;
  stddev?: Maybe<ObligationAuditStddevFields>;
  stddev_pop?: Maybe<ObligationAuditStddevPopFields>;
  stddev_samp?: Maybe<ObligationAuditStddevSampFields>;
  sum?: Maybe<ObligationAuditSumFields>;
  var_pop?: Maybe<ObligationAuditVarPopFields>;
  var_samp?: Maybe<ObligationAuditVarSampFields>;
  variance?: Maybe<ObligationAuditVarianceFields>;
};


/** aggregate fields of "risksmart.obligation_audit" */
export type ObligationAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObligationAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ObligationAuditAvgFields = {
  __typename?: 'obligation_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_audit". All fields are combined with a logical 'AND'. */
export type ObligationAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Adherence?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Interpretation?: InputMaybe<StringComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestFrequency?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ObligationAuditBoolExp>>;
  _not?: InputMaybe<ObligationAuditBoolExp>;
  _or?: InputMaybe<Array<ObligationAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.obligation_audit" */
export enum ObligationAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ObligationAuditPkey = 'obligation_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObligationAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObligationAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObligationAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_audit" */
export type ObligationAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_audit" */
export type ObligationAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ObligationAuditMaxFields = {
  __typename?: 'obligation_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ObligationAuditMinFields = {
  __typename?: 'obligation_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_audit" */
export type ObligationAuditMutationResponse = {
  __typename?: 'obligation_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationAudit>;
};

/** on_conflict condition type for table "risksmart.obligation_audit" */
export type ObligationAuditOnConflict = {
  constraint: ObligationAuditConstraint;
  update_columns?: Array<ObligationAuditUpdateColumn>;
  where?: InputMaybe<ObligationAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_audit". */
export type ObligationAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Adherence?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Interpretation?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.obligation_audit */
export type ObligationAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObligationAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_audit" */
export enum ObligationAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Adherence = 'Adherence',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Interpretation = 'Interpretation',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.obligation_audit" */
export type ObligationAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ObligationAuditStddevFields = {
  __typename?: 'obligation_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ObligationAuditStddevPopFields = {
  __typename?: 'obligation_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ObligationAuditStddevSampFields = {
  __typename?: 'obligation_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "obligation_audit" */
export type ObligationAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ObligationAuditSumFields = {
  __typename?: 'obligation_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.obligation_audit" */
export enum ObligationAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Adherence = 'Adherence',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Interpretation = 'Interpretation',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ObligationAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObligationAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObligationAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObligationAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObligationAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObligationAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObligationAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ObligationAuditVarPopFields = {
  __typename?: 'obligation_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ObligationAuditVarSampFields = {
  __typename?: 'obligation_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ObligationAuditVarianceFields = {
  __typename?: 'obligation_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ObligationAvgFields = {
  __typename?: 'obligation_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.obligation" */
export type ObligationAvgOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation". All fields are combined with a logical 'AND'. */
export type ObligationBoolExp = {
  Adherence?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Interpretation?: InputMaybe<StringComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  TestFrequency?: InputMaybe<TestFrequencyEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<ObligationTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<ObligationBoolExp>>;
  _not?: InputMaybe<ObligationBoolExp>;
  _or?: InputMaybe<Array<ObligationBoolExp>>;
  actions?: InputMaybe<ActionParentBoolExp>;
  actions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  assessmentResults?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  controls?: InputMaybe<ControlParentBoolExp>;
  controls_aggregate?: InputMaybe<ControlParentAggregateBoolExp>;
  createdBy?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  impacts?: InputMaybe<ObligationImpactBoolExp>;
  impacts_aggregate?: InputMaybe<ObligationImpactAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  issues?: InputMaybe<IssueParentBoolExp>;
  issues_aggregate?: InputMaybe<IssueParentAggregateBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  obligation_type?: InputMaybe<ObligationTypeBoolExp>;
  org?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parent?: InputMaybe<ObligationBoolExp>;
  parentNode?: InputMaybe<NodeBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  testFrequency?: InputMaybe<TestFrequencyBoolExp>;
};

/** unique or primary key constraints on table "risksmart.obligation" */
export enum ObligationConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxObligationOrgkeySequentialid = 'idx_obligation_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  ObligationPkey = 'obligation_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObligationDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObligationDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObligationDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.obligation_impact" */
export type ObligationImpact = {
  __typename?: 'obligation_impact';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactRating: Scalars['smallint']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentObligationId: Scalars['uuid']['output'];
  /** An object relationship */
  createdBy?: Maybe<User>;
  /** An object relationship */
  modifiedBy?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Obligation>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.obligation_impact" */
export type ObligationImpactCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.obligation_impact" */
export type ObligationImpactPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.obligation_impact" */
export type ObligationImpactPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.obligation_impact" */
export type ObligationImpactAggregate = {
  __typename?: 'obligation_impact_aggregate';
  aggregate?: Maybe<ObligationImpactAggregateFields>;
  nodes: Array<ObligationImpact>;
};

export type ObligationImpactAggregateBoolExp = {
  count?: InputMaybe<ObligationImpactAggregateBoolExpCount>;
};

export type ObligationImpactAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ObligationImpactBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.obligation_impact" */
export type ObligationImpactAggregateFields = {
  __typename?: 'obligation_impact_aggregate_fields';
  avg?: Maybe<ObligationImpactAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationImpactMaxFields>;
  min?: Maybe<ObligationImpactMinFields>;
  stddev?: Maybe<ObligationImpactStddevFields>;
  stddev_pop?: Maybe<ObligationImpactStddevPopFields>;
  stddev_samp?: Maybe<ObligationImpactStddevSampFields>;
  sum?: Maybe<ObligationImpactSumFields>;
  var_pop?: Maybe<ObligationImpactVarPopFields>;
  var_samp?: Maybe<ObligationImpactVarSampFields>;
  variance?: Maybe<ObligationImpactVarianceFields>;
};


/** aggregate fields of "risksmart.obligation_impact" */
export type ObligationImpactAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.obligation_impact" */
export type ObligationImpactAggregateOrderBy = {
  avg?: InputMaybe<ObligationImpactAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ObligationImpactMaxOrderBy>;
  min?: InputMaybe<ObligationImpactMinOrderBy>;
  stddev?: InputMaybe<ObligationImpactStddevOrderBy>;
  stddev_pop?: InputMaybe<ObligationImpactStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ObligationImpactStddevSampOrderBy>;
  sum?: InputMaybe<ObligationImpactSumOrderBy>;
  var_pop?: InputMaybe<ObligationImpactVarPopOrderBy>;
  var_samp?: InputMaybe<ObligationImpactVarSampOrderBy>;
  variance?: InputMaybe<ObligationImpactVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObligationImpactAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.obligation_impact" */
export type ObligationImpactArrRelInsertInput = {
  data: Array<ObligationImpactInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ObligationImpactOnConflict>;
};

/** columns and relationships of "risksmart.obligation_impact_audit" */
export type ObligationImpactAudit = {
  __typename?: 'obligation_impact_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ImpactRating: Scalars['smallint']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentObligationId: Scalars['uuid']['output'];
};


/** columns and relationships of "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditAggregate = {
  __typename?: 'obligation_impact_audit_aggregate';
  aggregate?: Maybe<ObligationImpactAuditAggregateFields>;
  nodes: Array<ObligationImpactAudit>;
};

/** aggregate fields of "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditAggregateFields = {
  __typename?: 'obligation_impact_audit_aggregate_fields';
  avg?: Maybe<ObligationImpactAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationImpactAuditMaxFields>;
  min?: Maybe<ObligationImpactAuditMinFields>;
  stddev?: Maybe<ObligationImpactAuditStddevFields>;
  stddev_pop?: Maybe<ObligationImpactAuditStddevPopFields>;
  stddev_samp?: Maybe<ObligationImpactAuditStddevSampFields>;
  sum?: Maybe<ObligationImpactAuditSumFields>;
  var_pop?: Maybe<ObligationImpactAuditVarPopFields>;
  var_samp?: Maybe<ObligationImpactAuditVarSampFields>;
  variance?: Maybe<ObligationImpactAuditVarianceFields>;
};


/** aggregate fields of "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationImpactAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ObligationImpactAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ObligationImpactAuditAvgFields = {
  __typename?: 'obligation_impact_audit_avg_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_impact_audit". All fields are combined with a logical 'AND'. */
export type ObligationImpactAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactRating?: InputMaybe<SmallintComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentObligationId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ObligationImpactAuditBoolExp>>;
  _not?: InputMaybe<ObligationImpactAuditBoolExp>;
  _or?: InputMaybe<Array<ObligationImpactAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.obligation_impact_audit" */
export enum ObligationImpactAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ObligationImpactAuditPkey = 'obligation_impact_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObligationImpactAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObligationImpactAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObligationImpactAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditIncInput = {
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type ObligationImpactAuditMaxFields = {
  __typename?: 'obligation_impact_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactRating?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ObligationImpactAuditMinFields = {
  __typename?: 'obligation_impact_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactRating?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditMutationResponse = {
  __typename?: 'obligation_impact_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationImpactAudit>;
};

/** on_conflict condition type for table "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditOnConflict = {
  constraint: ObligationImpactAuditConstraint;
  update_columns?: Array<ObligationImpactAuditUpdateColumn>;
  where?: InputMaybe<ObligationImpactAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_impact_audit". */
export type ObligationImpactAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactRating?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentObligationId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.obligation_impact_audit */
export type ObligationImpactAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObligationImpactAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_impact_audit" */
export enum ObligationImpactAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactRating = 'ImpactRating',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentObligationId = 'ParentObligationId'
}

/** input type for updating data in table "risksmart.obligation_impact_audit" */
export type ObligationImpactAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type ObligationImpactAuditStddevFields = {
  __typename?: 'obligation_impact_audit_stddev_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ObligationImpactAuditStddevPopFields = {
  __typename?: 'obligation_impact_audit_stddev_pop_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ObligationImpactAuditStddevSampFields = {
  __typename?: 'obligation_impact_audit_stddev_samp_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "obligation_impact_audit" */
export type ObligationImpactAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationImpactAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationImpactAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type ObligationImpactAuditSumFields = {
  __typename?: 'obligation_impact_audit_sum_fields';
  ImpactRating?: Maybe<Scalars['smallint']['output']>;
};

/** update columns of table "risksmart.obligation_impact_audit" */
export enum ObligationImpactAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactRating = 'ImpactRating',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentObligationId = 'ParentObligationId'
}

export type ObligationImpactAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObligationImpactAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObligationImpactAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObligationImpactAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObligationImpactAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObligationImpactAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObligationImpactAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationImpactAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationImpactAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ObligationImpactAuditVarPopFields = {
  __typename?: 'obligation_impact_audit_var_pop_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ObligationImpactAuditVarSampFields = {
  __typename?: 'obligation_impact_audit_var_samp_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ObligationImpactAuditVarianceFields = {
  __typename?: 'obligation_impact_audit_variance_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ObligationImpactAvgFields = {
  __typename?: 'obligation_impact_avg_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactAvgOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_impact". All fields are combined with a logical 'AND'. */
export type ObligationImpactBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ImpactRating?: InputMaybe<SmallintComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentObligationId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ObligationImpactBoolExp>>;
  _not?: InputMaybe<ObligationImpactBoolExp>;
  _or?: InputMaybe<Array<ObligationImpactBoolExp>>;
  createdBy?: InputMaybe<UserBoolExp>;
  modifiedBy?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<ObligationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.obligation_impact" */
export enum ObligationImpactConstraint {
  /** unique or primary key constraint on columns "Id" */
  ObligationImpactPkey = 'obligation_impact_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ObligationImpactDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ObligationImpactDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ObligationImpactDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation_impact" */
export type ObligationImpactIncInput = {
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
};

/** input type for inserting data into table "risksmart.obligation_impact" */
export type ObligationImpactInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<ObligationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type ObligationImpactMaxFields = {
  __typename?: 'obligation_impact_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactRating?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactRating?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentObligationId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ObligationImpactMinFields = {
  __typename?: 'obligation_impact_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ImpactRating?: Maybe<Scalars['smallint']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentObligationId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactRating?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentObligationId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.obligation_impact" */
export type ObligationImpactMutationResponse = {
  __typename?: 'obligation_impact_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationImpact>;
};

/** input type for inserting object relation for remote table "risksmart.obligation_impact" */
export type ObligationImpactObjRelInsertInput = {
  data: ObligationImpactInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ObligationImpactOnConflict>;
};

/** on_conflict condition type for table "risksmart.obligation_impact" */
export type ObligationImpactOnConflict = {
  constraint: ObligationImpactConstraint;
  update_columns?: Array<ObligationImpactUpdateColumn>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_impact". */
export type ObligationImpactOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ImpactRating?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentObligationId?: InputMaybe<OrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<ObligationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.obligation_impact */
export type ObligationImpactPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObligationImpactPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation_impact" */
export enum ObligationImpactSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactRating = 'ImpactRating',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentObligationId = 'ParentObligationId'
}

/** input type for updating data in table "risksmart.obligation_impact" */
export type ObligationImpactSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type ObligationImpactStddevFields = {
  __typename?: 'obligation_impact_stddev_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactStddevOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ObligationImpactStddevPopFields = {
  __typename?: 'obligation_impact_stddev_pop_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactStddevPopOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ObligationImpactStddevSampFields = {
  __typename?: 'obligation_impact_stddev_samp_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactStddevSampOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "obligation_impact" */
export type ObligationImpactStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationImpactStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationImpactStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ImpactRating?: InputMaybe<Scalars['smallint']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentObligationId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type ObligationImpactSumFields = {
  __typename?: 'obligation_impact_sum_fields';
  ImpactRating?: Maybe<Scalars['smallint']['output']>;
};

/** order by sum() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactSumOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.obligation_impact" */
export enum ObligationImpactUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ImpactRating = 'ImpactRating',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentObligationId = 'ParentObligationId'
}

export type ObligationImpactUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObligationImpactAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObligationImpactDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObligationImpactDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObligationImpactDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObligationImpactIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObligationImpactPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationImpactSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationImpactBoolExp;
};

/** aggregate var_pop on columns */
export type ObligationImpactVarPopFields = {
  __typename?: 'obligation_impact_var_pop_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactVarPopOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ObligationImpactVarSampFields = {
  __typename?: 'obligation_impact_var_samp_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactVarSampOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ObligationImpactVarianceFields = {
  __typename?: 'obligation_impact_variance_fields';
  ImpactRating?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.obligation_impact" */
export type ObligationImpactVarianceOrderBy = {
  ImpactRating?: InputMaybe<OrderBy>;
};

/** input type for incrementing numeric columns in table "risksmart.obligation" */
export type ObligationIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.obligation" */
export type ObligationInsertInput = {
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ObligationTypeEnum>;
  actions?: InputMaybe<ActionParentArrRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  assessmentResults?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  controls?: InputMaybe<ControlParentArrRelInsertInput>;
  createdBy?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  impacts?: InputMaybe<ObligationImpactArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  issues?: InputMaybe<IssueParentArrRelInsertInput>;
  modifiedBy?: InputMaybe<UserObjRelInsertInput>;
  obligation_type?: InputMaybe<ObligationTypeObjRelInsertInput>;
  org?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parent?: InputMaybe<ObligationObjRelInsertInput>;
  parentNode?: InputMaybe<NodeObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  testFrequency?: InputMaybe<TestFrequencyObjRelInsertInput>;
};

/** aggregate max on columns */
export type ObligationMaxFields = {
  __typename?: 'obligation_max_fields';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.obligation" */
export type ObligationMaxOrderBy = {
  Adherence?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Interpretation?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ObligationMinFields = {
  __typename?: 'obligation_min_fields';
  Adherence?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Interpretation?: Maybe<Scalars['String']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.obligation" */
export type ObligationMinOrderBy = {
  Adherence?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Interpretation?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.obligation" */
export type ObligationMutationResponse = {
  __typename?: 'obligation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Obligation>;
};

/** input type for inserting object relation for remote table "risksmart.obligation" */
export type ObligationObjRelInsertInput = {
  data: ObligationInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ObligationOnConflict>;
};

/** on_conflict condition type for table "risksmart.obligation" */
export type ObligationOnConflict = {
  constraint: ObligationConstraint;
  update_columns?: Array<ObligationUpdateColumn>;
  where?: InputMaybe<ObligationBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation". */
export type ObligationOrderBy = {
  Adherence?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Interpretation?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  actions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  controls_aggregate?: InputMaybe<ControlParentAggregateOrderBy>;
  createdBy?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  impacts_aggregate?: InputMaybe<ObligationImpactAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  issues_aggregate?: InputMaybe<IssueParentAggregateOrderBy>;
  modifiedBy?: InputMaybe<UserOrderBy>;
  obligation_type?: InputMaybe<ObligationTypeOrderBy>;
  org?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parent?: InputMaybe<ObligationOrderBy>;
  parentNode?: InputMaybe<NodeOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  testFrequency?: InputMaybe<TestFrequencyOrderBy>;
};

/** primary key columns input for table: risksmart.obligation */
export type ObligationPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ObligationPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.obligation" */
export enum ObligationSelectColumn {
  /** column name */
  Adherence = 'Adherence',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Interpretation = 'Interpretation',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.obligation" */
export type ObligationSetInput = {
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ObligationTypeEnum>;
};

/** aggregate stddev on columns */
export type ObligationStddevFields = {
  __typename?: 'obligation_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.obligation" */
export type ObligationStddevOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ObligationStddevPopFields = {
  __typename?: 'obligation_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.obligation" */
export type ObligationStddevPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ObligationStddevSampFields = {
  __typename?: 'obligation_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.obligation" */
export type ObligationStddevSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "obligation" */
export type ObligationStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationStreamCursorValueInput = {
  Adherence?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Interpretation?: InputMaybe<Scalars['String']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ObligationTypeEnum>;
};

/** aggregate sum on columns */
export type ObligationSumFields = {
  __typename?: 'obligation_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.obligation" */
export type ObligationSumOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.obligation_type" */
export type ObligationType = {
  __typename?: 'obligation_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  obligations: Array<Obligation>;
  /** An aggregate relationship */
  obligations_aggregate: ObligationAggregate;
};


/** columns and relationships of "risksmart.obligation_type" */
export type ObligationTypeObligationsArgs = {
  distinct_on?: InputMaybe<Array<ObligationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationOrderBy>>;
  where?: InputMaybe<ObligationBoolExp>;
};


/** columns and relationships of "risksmart.obligation_type" */
export type ObligationTypeObligationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationOrderBy>>;
  where?: InputMaybe<ObligationBoolExp>;
};

/** aggregated selection of "risksmart.obligation_type" */
export type ObligationTypeAggregate = {
  __typename?: 'obligation_type_aggregate';
  aggregate?: Maybe<ObligationTypeAggregateFields>;
  nodes: Array<ObligationType>;
};

/** aggregate fields of "risksmart.obligation_type" */
export type ObligationTypeAggregateFields = {
  __typename?: 'obligation_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ObligationTypeMaxFields>;
  min?: Maybe<ObligationTypeMinFields>;
};


/** aggregate fields of "risksmart.obligation_type" */
export type ObligationTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ObligationTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_type". All fields are combined with a logical 'AND'. */
export type ObligationTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ObligationTypeBoolExp>>;
  _not?: InputMaybe<ObligationTypeBoolExp>;
  _or?: InputMaybe<Array<ObligationTypeBoolExp>>;
  obligations?: InputMaybe<ObligationBoolExp>;
  obligations_aggregate?: InputMaybe<ObligationAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.obligation_type" */
export enum ObligationTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ObligationTypePkey = 'obligation_type_pkey'
}

export enum ObligationTypeEnum {
  /** Chapter */
  Chapter = 'chapter',
  /** Rule */
  Rule = 'rule',
  /** High-level Standard */
  Standard = 'standard'
}

/** Boolean expression to compare columns of type "obligation_type_enum". All fields are combined with logical 'AND'. */
export type ObligationTypeEnumComparisonExp = {
  _eq?: InputMaybe<ObligationTypeEnum>;
  _in?: InputMaybe<Array<ObligationTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ObligationTypeEnum>;
  _nin?: InputMaybe<Array<ObligationTypeEnum>>;
};

/** input type for inserting data into table "risksmart.obligation_type" */
export type ObligationTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
  obligations?: InputMaybe<ObligationArrRelInsertInput>;
};

/** aggregate max on columns */
export type ObligationTypeMaxFields = {
  __typename?: 'obligation_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ObligationTypeMinFields = {
  __typename?: 'obligation_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_type" */
export type ObligationTypeMutationResponse = {
  __typename?: 'obligation_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ObligationType>;
};

/** input type for inserting object relation for remote table "risksmart.obligation_type" */
export type ObligationTypeObjRelInsertInput = {
  data: ObligationTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ObligationTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.obligation_type" */
export type ObligationTypeOnConflict = {
  constraint: ObligationTypeConstraint;
  update_columns?: Array<ObligationTypeUpdateColumn>;
  where?: InputMaybe<ObligationTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_type". */
export type ObligationTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
  obligations_aggregate?: InputMaybe<ObligationAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.obligation_type */
export type ObligationTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.obligation_type" */
export enum ObligationTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.obligation_type" */
export type ObligationTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "obligation_type" */
export type ObligationTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ObligationTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ObligationTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.obligation_type" */
export enum ObligationTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ObligationTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationTypeBoolExp;
};

/** update columns of table "risksmart.obligation" */
export enum ObligationUpdateColumn {
  /** column name */
  Adherence = 'Adherence',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Interpretation = 'Interpretation',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ObligationUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ObligationAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ObligationDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ObligationDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ObligationDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ObligationIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ObligationPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ObligationSetInput>;
  /** filter the rows which have to be updated */
  where: ObligationBoolExp;
};

/** aggregate var_pop on columns */
export type ObligationVarPopFields = {
  __typename?: 'obligation_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.obligation" */
export type ObligationVarPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ObligationVarSampFields = {
  __typename?: 'obligation_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.obligation" */
export type ObligationVarSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ObligationVarianceFields = {
  __typename?: 'obligation_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.obligation" */
export type ObligationVarianceOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
};

/** column ordering options */
export enum OrderBy {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "risksmart.owner" */
export type Owner = {
  __typename?: 'owner';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentDashboard?: Maybe<Dashboard>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An object relationship */
  parentRisk?: Maybe<Risk>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  user?: Maybe<User>;
};


/** columns and relationships of "risksmart.owner" */
export type OwnerPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.owner" */
export type OwnerPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.owner" */
export type OwnerAggregate = {
  __typename?: 'owner_aggregate';
  aggregate?: Maybe<OwnerAggregateFields>;
  nodes: Array<Owner>;
};

export type OwnerAggregateBoolExp = {
  count?: InputMaybe<OwnerAggregateBoolExpCount>;
};

export type OwnerAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OwnerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OwnerBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.owner" */
export type OwnerAggregateFields = {
  __typename?: 'owner_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<OwnerMaxFields>;
  min?: Maybe<OwnerMinFields>;
};


/** aggregate fields of "risksmart.owner" */
export type OwnerAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OwnerSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.owner" */
export type OwnerAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OwnerMaxOrderBy>;
  min?: InputMaybe<OwnerMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.owner" */
export type OwnerArrRelInsertInput = {
  data: Array<OwnerInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OwnerOnConflict>;
};

/** columns and relationships of "risksmart.owner_audit" */
export type OwnerAudit = {
  __typename?: 'owner_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.owner_audit" */
export type OwnerAuditAggregate = {
  __typename?: 'owner_audit_aggregate';
  aggregate?: Maybe<OwnerAuditAggregateFields>;
  nodes: Array<OwnerAudit>;
};

export type OwnerAuditAggregateBoolExp = {
  count?: InputMaybe<OwnerAuditAggregateBoolExpCount>;
};

export type OwnerAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OwnerAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.owner_audit" */
export type OwnerAuditAggregateFields = {
  __typename?: 'owner_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<OwnerAuditMaxFields>;
  min?: Maybe<OwnerAuditMinFields>;
};


/** aggregate fields of "risksmart.owner_audit" */
export type OwnerAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.owner_audit" */
export type OwnerAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OwnerAuditMaxOrderBy>;
  min?: InputMaybe<OwnerAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.owner_audit" */
export type OwnerAuditArrRelInsertInput = {
  data: Array<OwnerAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OwnerAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.owner_audit". All fields are combined with a logical 'AND'. */
export type OwnerAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<OwnerAuditBoolExp>>;
  _not?: InputMaybe<OwnerAuditBoolExp>;
  _or?: InputMaybe<Array<OwnerAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.owner_audit" */
export enum OwnerAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "ModifiedAtTimestamp", "UserId" */
  OwnerAuditPkey = 'owner_audit_pkey'
}

/** input type for inserting data into table "risksmart.owner_audit" */
export type OwnerAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type OwnerAuditMaxFields = {
  __typename?: 'owner_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.owner_audit" */
export type OwnerAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OwnerAuditMinFields = {
  __typename?: 'owner_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.owner_audit" */
export type OwnerAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.owner_audit" */
export type OwnerAuditMutationResponse = {
  __typename?: 'owner_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OwnerAudit>;
};

/** on_conflict condition type for table "risksmart.owner_audit" */
export type OwnerAuditOnConflict = {
  constraint: OwnerAuditConstraint;
  update_columns?: Array<OwnerAuditUpdateColumn>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.owner_audit". */
export type OwnerAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.owner_audit */
export type OwnerAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};

/** select columns of table "risksmart.owner_audit" */
export enum OwnerAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.owner_audit" */
export type OwnerAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "owner_audit" */
export type OwnerAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OwnerAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OwnerAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.owner_audit" */
export enum OwnerAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

export type OwnerAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OwnerAuditSetInput>;
  /** filter the rows which have to be updated */
  where: OwnerAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.owner". All fields are combined with a logical 'AND'. */
export type OwnerBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<OwnerBoolExp>>;
  _not?: InputMaybe<OwnerBoolExp>;
  _or?: InputMaybe<Array<OwnerBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parentAction?: InputMaybe<ActionBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentDashboard?: InputMaybe<DashboardBoolExp>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityBoolExp>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  parentIssue?: InputMaybe<IssueBoolExp>;
  parentRisk?: InputMaybe<RiskBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  user?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.owner" */
export enum OwnerConstraint {
  /** unique or primary key constraint on columns "ParentId", "UserId" */
  OwnerPkey = 'owner_pkey'
}

/** columns and relationships of "risksmart.owner_group" */
export type OwnerGroup = {
  __typename?: 'owner_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  group?: Maybe<UserGroup>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentInternalAuditEntity?: Maybe<InternalAuditEntity>;
  /** An object relationship */
  parentInternalAuditReport?: Maybe<InternalAuditReport>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.owner_group" */
export type OwnerGroupPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.owner_group" */
export type OwnerGroupPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.owner_group" */
export type OwnerGroupAggregate = {
  __typename?: 'owner_group_aggregate';
  aggregate?: Maybe<OwnerGroupAggregateFields>;
  nodes: Array<OwnerGroup>;
};

export type OwnerGroupAggregateBoolExp = {
  count?: InputMaybe<OwnerGroupAggregateBoolExpCount>;
};

export type OwnerGroupAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OwnerGroupBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.owner_group" */
export type OwnerGroupAggregateFields = {
  __typename?: 'owner_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<OwnerGroupMaxFields>;
  min?: Maybe<OwnerGroupMinFields>;
};


/** aggregate fields of "risksmart.owner_group" */
export type OwnerGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.owner_group" */
export type OwnerGroupAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OwnerGroupMaxOrderBy>;
  min?: InputMaybe<OwnerGroupMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.owner_group" */
export type OwnerGroupArrRelInsertInput = {
  data: Array<OwnerGroupInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OwnerGroupOnConflict>;
};

/** columns and relationships of "risksmart.owner_group_audit" */
export type OwnerGroupAudit = {
  __typename?: 'owner_group_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  UserGroupId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.owner_group_audit" */
export type OwnerGroupAuditAggregate = {
  __typename?: 'owner_group_audit_aggregate';
  aggregate?: Maybe<OwnerGroupAuditAggregateFields>;
  nodes: Array<OwnerGroupAudit>;
};

export type OwnerGroupAuditAggregateBoolExp = {
  count?: InputMaybe<OwnerGroupAuditAggregateBoolExpCount>;
};

export type OwnerGroupAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<OwnerGroupAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.owner_group_audit" */
export type OwnerGroupAuditAggregateFields = {
  __typename?: 'owner_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<OwnerGroupAuditMaxFields>;
  min?: Maybe<OwnerGroupAuditMinFields>;
};


/** aggregate fields of "risksmart.owner_group_audit" */
export type OwnerGroupAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.owner_group_audit" */
export type OwnerGroupAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<OwnerGroupAuditMaxOrderBy>;
  min?: InputMaybe<OwnerGroupAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.owner_group_audit" */
export type OwnerGroupAuditArrRelInsertInput = {
  data: Array<OwnerGroupAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<OwnerGroupAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.owner_group_audit". All fields are combined with a logical 'AND'. */
export type OwnerGroupAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<OwnerGroupAuditBoolExp>>;
  _not?: InputMaybe<OwnerGroupAuditBoolExp>;
  _or?: InputMaybe<Array<OwnerGroupAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.owner_group_audit" */
export enum OwnerGroupAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "UserGroupId", "ModifiedAtTimestamp" */
  OwnerGroupAuditPkey = 'owner_group_audit_pkey'
}

/** input type for inserting data into table "risksmart.owner_group_audit" */
export type OwnerGroupAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type OwnerGroupAuditMaxFields = {
  __typename?: 'owner_group_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.owner_group_audit" */
export type OwnerGroupAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OwnerGroupAuditMinFields = {
  __typename?: 'owner_group_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.owner_group_audit" */
export type OwnerGroupAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.owner_group_audit" */
export type OwnerGroupAuditMutationResponse = {
  __typename?: 'owner_group_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OwnerGroupAudit>;
};

/** on_conflict condition type for table "risksmart.owner_group_audit" */
export type OwnerGroupAuditOnConflict = {
  constraint: OwnerGroupAuditConstraint;
  update_columns?: Array<OwnerGroupAuditUpdateColumn>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.owner_group_audit". */
export type OwnerGroupAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.owner_group_audit */
export type OwnerGroupAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.owner_group_audit" */
export enum OwnerGroupAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

/** input type for updating data in table "risksmart.owner_group_audit" */
export type OwnerGroupAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "owner_group_audit" */
export type OwnerGroupAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OwnerGroupAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OwnerGroupAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.owner_group_audit" */
export enum OwnerGroupAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

export type OwnerGroupAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OwnerGroupAuditSetInput>;
  /** filter the rows which have to be updated */
  where: OwnerGroupAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.owner_group". All fields are combined with a logical 'AND'. */
export type OwnerGroupBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<OwnerGroupBoolExp>>;
  _not?: InputMaybe<OwnerGroupBoolExp>;
  _or?: InputMaybe<Array<OwnerGroupBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  group?: InputMaybe<UserGroupBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parentAction?: InputMaybe<ActionBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityBoolExp>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportBoolExp>;
  parentIssue?: InputMaybe<IssueBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.owner_group" */
export enum OwnerGroupConstraint {
  /** unique or primary key constraint on columns "ParentId", "UserGroupId" */
  OwnerGroupPkey = 'owner_group_pkey'
}

/** input type for inserting data into table "risksmart.owner_group" */
export type OwnerGroupInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  group?: InputMaybe<UserGroupObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parentAction?: InputMaybe<ActionObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  parentIssue?: InputMaybe<IssueObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type OwnerGroupMaxFields = {
  __typename?: 'owner_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.owner_group" */
export type OwnerGroupMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OwnerGroupMinFields = {
  __typename?: 'owner_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.owner_group" */
export type OwnerGroupMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.owner_group" */
export type OwnerGroupMutationResponse = {
  __typename?: 'owner_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<OwnerGroup>;
};

/** on_conflict condition type for table "risksmart.owner_group" */
export type OwnerGroupOnConflict = {
  constraint: OwnerGroupConstraint;
  update_columns?: Array<OwnerGroupUpdateColumn>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.owner_group". */
export type OwnerGroupOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  group?: InputMaybe<UserGroupOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parentAction?: InputMaybe<ActionOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityOrderBy>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  parentIssue?: InputMaybe<IssueOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.owner_group */
export type OwnerGroupPkColumnsInput = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.owner_group" */
export enum OwnerGroupSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

/** input type for updating data in table "risksmart.owner_group" */
export type OwnerGroupSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "owner_group" */
export type OwnerGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OwnerGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OwnerGroupStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.owner_group" */
export enum OwnerGroupUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserGroupId = 'UserGroupId'
}

export type OwnerGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OwnerGroupSetInput>;
  /** filter the rows which have to be updated */
  where: OwnerGroupBoolExp;
};

/** input type for inserting data into table "risksmart.owner" */
export type OwnerInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parentAction?: InputMaybe<ActionObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentDashboard?: InputMaybe<DashboardObjRelInsertInput>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityObjRelInsertInput>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportObjRelInsertInput>;
  parentIssue?: InputMaybe<IssueObjRelInsertInput>;
  parentRisk?: InputMaybe<RiskObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  user?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type OwnerMaxFields = {
  __typename?: 'owner_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.owner" */
export type OwnerMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type OwnerMinFields = {
  __typename?: 'owner_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.owner" */
export type OwnerMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.owner" */
export type OwnerMutationResponse = {
  __typename?: 'owner_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Owner>;
};

/** on_conflict condition type for table "risksmart.owner" */
export type OwnerOnConflict = {
  constraint: OwnerConstraint;
  update_columns?: Array<OwnerUpdateColumn>;
  where?: InputMaybe<OwnerBoolExp>;
};

/** Ordering options when selecting data from "risksmart.owner". */
export type OwnerOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parentAction?: InputMaybe<ActionOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentDashboard?: InputMaybe<DashboardOrderBy>;
  parentInternalAuditEntity?: InputMaybe<InternalAuditEntityOrderBy>;
  parentInternalAuditReport?: InputMaybe<InternalAuditReportOrderBy>;
  parentIssue?: InputMaybe<IssueOrderBy>;
  parentRisk?: InputMaybe<RiskOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  user?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.owner */
export type OwnerPkColumnsInput = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};

/** select columns of table "risksmart.owner" */
export enum OwnerSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.owner" */
export type OwnerSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "owner" */
export type OwnerStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: OwnerStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type OwnerStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.owner" */
export enum OwnerUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  UserId = 'UserId'
}

export type OwnerUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<OwnerSetInput>;
  /** filter the rows which have to be updated */
  where: OwnerBoolExp;
};

/** columns and relationships of "risksmart.parent_type" */
export type ParentType = {
  __typename?: 'parent_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.parent_type" */
export type ParentTypeAggregate = {
  __typename?: 'parent_type_aggregate';
  aggregate?: Maybe<ParentTypeAggregateFields>;
  nodes: Array<ParentType>;
};

/** aggregate fields of "risksmart.parent_type" */
export type ParentTypeAggregateFields = {
  __typename?: 'parent_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ParentTypeMaxFields>;
  min?: Maybe<ParentTypeMinFields>;
};


/** aggregate fields of "risksmart.parent_type" */
export type ParentTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ParentTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.parent_type". All fields are combined with a logical 'AND'. */
export type ParentTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ParentTypeBoolExp>>;
  _not?: InputMaybe<ParentTypeBoolExp>;
  _or?: InputMaybe<Array<ParentTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.parent_type" */
export enum ParentTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ParentTypePkey = 'parent_type_pkey'
}

export enum ParentTypeEnum {
  /** Acceptance */
  Acceptance = 'acceptance',
  /** Action */
  Action = 'action',
  /** Action Update */
  ActionUpdate = 'action_update',
  /** Appetite */
  Appetite = 'appetite',
  /** Approval result */
  ApprovalResult = 'approval_result',
  /** Assessment */
  Assessment = 'assessment',
  /** Assessment Activity */
  AssessmentActivity = 'assessment_activity',
  /** Attestation Record */
  AttestationRecord = 'attestation_record',
  /** Audit entities */
  Audit = 'audit',
  /** Business Area */
  BusinessArea = 'business_area',
  /** Cause */
  Cause = 'cause',
  /** Change Request */
  ChangeRequest = 'change_request',
  /** Compliance Monitoring Assessment */
  ComplianceMonitoringAssessment = 'compliance_monitoring_assessment',
  /** Consequence */
  Consequence = 'consequence',
  /** Control */
  Control = 'control',
  /** Control Group */
  ControlGroup = 'control_group',
  /** Controlled Risk Assessment Result */
  ControlledRiskAssessmentResult = 'controlled_risk_assessment_result',
  /** Conversation */
  Conversation = 'conversation',
  /** Custom attribute schema */
  CustomAttributeSchema = 'custom_attribute_schema',
  /** Custom Ribbon Item */
  CustomRibbon = 'custom_ribbon',
  /** Dashboard */
  Dashboard = 'dashboard',
  /** Data Import */
  DataImport = 'data_import',
  /** Document */
  Document = 'document',
  /** Document Assessment */
  DocumentAssessment = 'document_assessment',
  /** Document Assessment Result */
  DocumentAssessmentResult = 'document_assessment_result',
  /** Document version */
  DocumentFile = 'document_file',
  /** Impact */
  Impact = 'impact',
  /** Impact Parent */
  ImpactParent = 'impact_parent',
  /** Impact rating */
  ImpactRating = 'impact_rating',
  /** Indicator */
  Indicator = 'indicator',
  /** Indicator Result */
  IndicatorResult = 'indicator_result',
  /** Internal Audit */
  InternalAuditEntity = 'internal_audit_entity',
  /** Internal Audit Report */
  InternalAuditReport = 'internal_audit_report',
  /** Internal Audit Controlled Risk Assessment Result */
  InternalAuditReportControlledRiskAssessmentResult = 'internal_audit_report_controlled_risk_assessment_result',
  /** Internal Audit Document Assessment Result */
  InternalAuditReportDocumentAssessmentResult = 'internal_audit_report_document_assessment_result',
  /** Internal Audit Obligation Assessment Result */
  InternalAuditReportObligationAssessmentResult = 'internal_audit_report_obligation_assessment_result',
  /** Internal Audit Test Result */
  InternalAuditReportTestResult = 'internal_audit_report_test_result',
  /** Internal Audit Uncontrolled Risk Assessment Result */
  InternalAuditReportUncontrolledRiskAssessmentResult = 'internal_audit_report_uncontrolled_risk_assessment_result',
  /** Issue */
  Issue = 'issue',
  /** Issue Assessment */
  IssueAssessment = 'issue_assessment',
  /** Issue assessment audit */
  IssueAssessmentAudit = 'issue_assessment_audit',
  /** Issue Update */
  IssueUpdate = 'issue_update',
  /** Linked item */
  LinkedItem = 'linked_item',
  /** My items */
  MyItems = 'my_items',
  /** Notification */
  Notification = 'notification',
  /** Obligation */
  Obligation = 'obligation',
  /** Obligation Assessment */
  ObligationAssessment = 'obligation_assessment',
  /** Obligation Assessment Result */
  ObligationAssessmentResult = 'obligation_assessment_result',
  /** Obligation Impact */
  ObligationImpact = 'obligation_impact',
  /** Organisation shared dashboards */
  OrganisationDashboard = 'organisation_dashboard',
  /** Public issue form */
  PublicIssueForm = 'public_issue_form',
  /** Public policies */
  PublicPolicies = 'public_policies',
  /** Questionnaire Template */
  QuestionnaireTemplate = 'questionnaire_template',
  /** Questionnaire Template Version */
  QuestionnaireTemplateVersion = 'questionnaire_template_version',
  /** Reports */
  Report = 'report',
  /** Risk */
  Risk = 'risk',
  /** Risk assessment */
  RiskAssessment = 'risk_assessment',
  /** Risk Assessment Result */
  RiskAssessmentResult = 'risk_assessment_result',
  /** Risk Controlled Assessment */
  RiskControlledAssessment = 'risk_controlled_assessment',
  /** Risk Uncontrolled Assessment */
  RiskUncontrolledAssessment = 'risk_uncontrolled_assessment',
  /** Second Line Controlled Risk Assessment Result */
  SecondLineAssessmentControlledRiskAssessmentResult = 'second_line_assessment_controlled_risk_assessment_result',
  /** Second Line Document Assessment Result */
  SecondLineAssessmentDocumentAssessmentResult = 'second_line_assessment_document_assessment_result',
  /** Second Line Obligation Assessment Result */
  SecondLineAssessmentObligationAssessmentResult = 'second_line_assessment_obligation_assessment_result',
  /** Second Line Test Result */
  SecondLineAssessmentTestResult = 'second_line_assessment_test_result',
  /** Second Line Uncontrolled Risk Assessment Result */
  SecondLineAssessmentUncontrolledRiskAssessmentResult = 'second_line_assessment_uncontrolled_risk_assessment_result',
  /** Settings */
  Settings = 'settings',
  /** Taxonomy */
  Taxonomy = 'taxonomy',
  /** Test Result */
  TestResult = 'test_result',
  /** Third Party */
  ThirdParty = 'third_party',
  /** Third Party Response */
  ThirdPartyResponse = 'third_party_response',
  /** Uncontrolled Risk Assessment Result */
  UncontrolledRiskAssessmentResult = 'uncontrolled_risk_assessment_result'
}

/** Boolean expression to compare columns of type "parent_type_enum". All fields are combined with logical 'AND'. */
export type ParentTypeEnumComparisonExp = {
  _eq?: InputMaybe<ParentTypeEnum>;
  _in?: InputMaybe<Array<ParentTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ParentTypeEnum>;
  _nin?: InputMaybe<Array<ParentTypeEnum>>;
};

/** input type for inserting data into table "risksmart.parent_type" */
export type ParentTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ParentTypeMaxFields = {
  __typename?: 'parent_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ParentTypeMinFields = {
  __typename?: 'parent_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.parent_type" */
export type ParentTypeMutationResponse = {
  __typename?: 'parent_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ParentType>;
};

/** input type for inserting object relation for remote table "risksmart.parent_type" */
export type ParentTypeObjRelInsertInput = {
  data: ParentTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ParentTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.parent_type" */
export type ParentTypeOnConflict = {
  constraint: ParentTypeConstraint;
  update_columns?: Array<ParentTypeUpdateColumn>;
  where?: InputMaybe<ParentTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.parent_type". */
export type ParentTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.parent_type */
export type ParentTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.parent_type" */
export enum ParentTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.parent_type" */
export type ParentTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "parent_type" */
export type ParentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ParentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ParentTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.parent_type" */
export enum ParentTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ParentTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ParentTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ParentTypeBoolExp;
};

/** columns and relationships of "risksmart.permission_view" */
export type Permission = {
  __typename?: 'permission';
  AccessType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.permission_view" */
export type PermissionAggregate = {
  __typename?: 'permission_aggregate';
  aggregate?: Maybe<PermissionAggregateFields>;
  nodes: Array<Permission>;
};

export type PermissionAggregateBoolExp = {
  count?: InputMaybe<PermissionAggregateBoolExpCount>;
};

export type PermissionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<PermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<PermissionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.permission_view" */
export type PermissionAggregateFields = {
  __typename?: 'permission_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<PermissionMaxFields>;
  min?: Maybe<PermissionMinFields>;
};


/** aggregate fields of "risksmart.permission_view" */
export type PermissionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<PermissionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.permission_view" */
export type PermissionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<PermissionMaxOrderBy>;
  min?: InputMaybe<PermissionMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.permission_view" */
export type PermissionArrRelInsertInput = {
  data: Array<PermissionInsertInput>;
};

/** Boolean expression to filter rows from the table "risksmart.permission_view". All fields are combined with a logical 'AND'. */
export type PermissionBoolExp = {
  AccessType?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ObjectType?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RoleKey?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<PermissionBoolExp>>;
  _not?: InputMaybe<PermissionBoolExp>;
  _or?: InputMaybe<Array<PermissionBoolExp>>;
};

/** input type for inserting data into table "risksmart.permission_view" */
export type PermissionInsertInput = {
  AccessType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type PermissionMaxFields = {
  __typename?: 'permission_max_fields';
  AccessType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.permission_view" */
export type PermissionMaxOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type PermissionMinFields = {
  __typename?: 'permission_min_fields';
  AccessType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.permission_view" */
export type PermissionMinOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "risksmart.permission_view". */
export type PermissionOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** select columns of table "risksmart.permission_view" */
export enum PermissionSelectColumn {
  /** column name */
  AccessType = 'AccessType',
  /** column name */
  Id = 'Id',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  UserId = 'UserId'
}

/** Streaming cursor of the table "permission" */
export type PermissionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: PermissionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type PermissionStreamCursorValueInput = {
  AccessType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryRoot = {
  __typename?: 'query_root';
  /** fetch data from the table: "risksmart.acceptance" */
  acceptance: Array<Acceptance>;
  /** fetch aggregated fields from the table: "risksmart.acceptance" */
  acceptance_aggregate: AcceptanceAggregate;
  /** fetch data from the table: "risksmart.acceptance_audit" */
  acceptance_audit: Array<AcceptanceAudit>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_audit" */
  acceptance_audit_aggregate: AcceptanceAuditAggregate;
  /** fetch data from the table: "risksmart.acceptance_audit" using primary key columns */
  acceptance_audit_by_pk?: Maybe<AcceptanceAudit>;
  /** fetch data from the table: "risksmart.acceptance" using primary key columns */
  acceptance_by_pk?: Maybe<Acceptance>;
  /** fetch data from the table: "risksmart.acceptance_parent" */
  acceptance_parent: Array<AcceptanceParent>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_parent" */
  acceptance_parent_aggregate: AcceptanceParentAggregate;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit: Array<AcceptanceParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit_aggregate: AcceptanceParentAuditAggregate;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" using primary key columns */
  acceptance_parent_audit_by_pk?: Maybe<AcceptanceParentAudit>;
  /** fetch data from the table: "risksmart.acceptance_parent" using primary key columns */
  acceptance_parent_by_pk?: Maybe<AcceptanceParent>;
  /** fetch data from the table: "risksmart.acceptance_status" */
  acceptance_status: Array<AcceptanceStatus>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_status" */
  acceptance_status_aggregate: AcceptanceStatusAggregate;
  /** fetch data from the table: "risksmart.acceptance_status" using primary key columns */
  acceptance_status_by_pk?: Maybe<AcceptanceStatus>;
  /** fetch data from the table: "risksmart.access_type" */
  access_type: Array<AccessType>;
  /** fetch aggregated fields from the table: "risksmart.access_type" */
  access_type_aggregate: AccessTypeAggregate;
  /** fetch data from the table: "risksmart.access_type" using primary key columns */
  access_type_by_pk?: Maybe<AccessType>;
  /** fetch data from the table: "risksmart.action" */
  action: Array<Action>;
  /** fetch aggregated fields from the table: "risksmart.action" */
  action_aggregate: ActionAggregate;
  /** fetch data from the table: "risksmart.action_audit" */
  action_audit: Array<ActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.action_audit" */
  action_audit_aggregate: ActionAuditAggregate;
  /** fetch data from the table: "risksmart.action_audit" using primary key columns */
  action_audit_by_pk?: Maybe<ActionAudit>;
  /** fetch data from the table: "risksmart.action" using primary key columns */
  action_by_pk?: Maybe<Action>;
  /** fetch data from the table: "risksmart.action_parent" */
  action_parent: Array<ActionParent>;
  /** fetch aggregated fields from the table: "risksmart.action_parent" */
  action_parent_aggregate: ActionParentAggregate;
  /** fetch data from the table: "risksmart.action_parent_audit" */
  action_parent_audit: Array<ActionParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.action_parent_audit" */
  action_parent_audit_aggregate: ActionParentAuditAggregate;
  /** fetch data from the table: "risksmart.action_parent_audit" using primary key columns */
  action_parent_audit_by_pk?: Maybe<ActionParentAudit>;
  /** fetch data from the table: "risksmart.action_parent" using primary key columns */
  action_parent_by_pk?: Maybe<ActionParent>;
  /** fetch data from the table: "risksmart.action_status" */
  action_status: Array<ActionStatus>;
  /** fetch aggregated fields from the table: "risksmart.action_status" */
  action_status_aggregate: ActionStatusAggregate;
  /** fetch data from the table: "risksmart.action_status" using primary key columns */
  action_status_by_pk?: Maybe<ActionStatus>;
  /** fetch data from the table: "risksmart.action_update" */
  action_update: Array<ActionUpdate>;
  /** fetch aggregated fields from the table: "risksmart.action_update" */
  action_update_aggregate: ActionUpdateAggregate;
  /** fetch data from the table: "risksmart.action_update_audit" */
  action_update_audit: Array<ActionUpdateAudit>;
  /** fetch aggregated fields from the table: "risksmart.action_update_audit" */
  action_update_audit_aggregate: ActionUpdateAuditAggregate;
  /** fetch data from the table: "risksmart.action_update_audit" using primary key columns */
  action_update_audit_by_pk?: Maybe<ActionUpdateAudit>;
  /** fetch data from the table: "risksmart.action_update" using primary key columns */
  action_update_by_pk?: Maybe<ActionUpdate>;
  /** fetch data from the table: "risksmart.action_update_summary_view" */
  action_update_summary: Array<ActionUpdateSummary>;
  /** fetch aggregated fields from the table: "risksmart.action_update_summary_view" */
  action_update_summary_aggregate: ActionUpdateSummaryAggregate;
  /** fetch data from the table: "risksmart.aggregation_org" */
  aggregation_org: Array<AggregationOrg>;
  /** fetch aggregated fields from the table: "risksmart.aggregation_org" */
  aggregation_org_aggregate: AggregationOrgAggregate;
  /** fetch data from the table: "risksmart.aggregation_org" using primary key columns */
  aggregation_org_by_pk?: Maybe<AggregationOrg>;
  /** fetch data from the table: "risksmart.ancestor_contributor_view" */
  ancestor_contributor: Array<AncestorContributor>;
  /** fetch aggregated fields from the table: "risksmart.ancestor_contributor_view" */
  ancestor_contributor_aggregate: AncestorContributorAggregate;
  /** fetch data from the table: "risksmart.appetite" */
  appetite: Array<Appetite>;
  /** fetch aggregated fields from the table: "risksmart.appetite" */
  appetite_aggregate: AppetiteAggregate;
  /** fetch data from the table: "risksmart.appetite_audit" */
  appetite_audit: Array<AppetiteAudit>;
  /** fetch aggregated fields from the table: "risksmart.appetite_audit" */
  appetite_audit_aggregate: AppetiteAuditAggregate;
  /** fetch data from the table: "risksmart.appetite_audit" using primary key columns */
  appetite_audit_by_pk?: Maybe<AppetiteAudit>;
  /** fetch data from the table: "risksmart.appetite" using primary key columns */
  appetite_by_pk?: Maybe<Appetite>;
  /** fetch data from the table: "risksmart.appetite_model" */
  appetite_model: Array<AppetiteModel>;
  /** fetch aggregated fields from the table: "risksmart.appetite_model" */
  appetite_model_aggregate: AppetiteModelAggregate;
  /** fetch data from the table: "risksmart.appetite_model" using primary key columns */
  appetite_model_by_pk?: Maybe<AppetiteModel>;
  /** fetch data from the table: "risksmart.appetite_parent" */
  appetite_parent: Array<AppetiteParent>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent" */
  appetite_parent_aggregate: AppetiteParentAggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit: Array<AppetiteParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit_aggregate: AppetiteParentAuditAggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" using primary key columns */
  appetite_parent_audit_by_pk?: Maybe<AppetiteParentAudit>;
  /** fetch data from the table: "risksmart.appetite_parent" using primary key columns */
  appetite_parent_by_pk?: Maybe<AppetiteParent>;
  /** fetch data from the table: "risksmart.appetite_type" */
  appetite_type: Array<AppetiteType>;
  /** fetch aggregated fields from the table: "risksmart.appetite_type" */
  appetite_type_aggregate: AppetiteTypeAggregate;
  /** fetch data from the table: "risksmart.appetite_type" using primary key columns */
  appetite_type_by_pk?: Maybe<AppetiteType>;
  /** fetch data from the table: "risksmart.approval" */
  approval: Array<Approval>;
  /** fetch aggregated fields from the table: "risksmart.approval" */
  approval_aggregate: ApprovalAggregate;
  /** fetch data from the table: "risksmart.approval_audit" */
  approval_audit: Array<ApprovalAudit>;
  /** fetch aggregated fields from the table: "risksmart.approval_audit" */
  approval_audit_aggregate: ApprovalAuditAggregate;
  /** fetch data from the table: "risksmart.approval_audit" using primary key columns */
  approval_audit_by_pk?: Maybe<ApprovalAudit>;
  /** fetch data from the table: "risksmart.approval" using primary key columns */
  approval_by_pk?: Maybe<Approval>;
  /** fetch data from the table: "risksmart.approval_in_flight_edit_rule" */
  approval_in_flight_edit_rule: Array<ApprovalInFlightEditRule>;
  /** fetch aggregated fields from the table: "risksmart.approval_in_flight_edit_rule" */
  approval_in_flight_edit_rule_aggregate: ApprovalInFlightEditRuleAggregate;
  /** fetch data from the table: "risksmart.approval_in_flight_edit_rule" using primary key columns */
  approval_in_flight_edit_rule_by_pk?: Maybe<ApprovalInFlightEditRule>;
  /** fetch data from the table: "risksmart.approval_level" */
  approval_level: Array<ApprovalLevel>;
  /** fetch aggregated fields from the table: "risksmart.approval_level" */
  approval_level_aggregate: ApprovalLevelAggregate;
  /** fetch data from the table: "risksmart.approval_level_audit" */
  approval_level_audit: Array<ApprovalLevelAudit>;
  /** fetch aggregated fields from the table: "risksmart.approval_level_audit" */
  approval_level_audit_aggregate: ApprovalLevelAuditAggregate;
  /** fetch data from the table: "risksmart.approval_level_audit" using primary key columns */
  approval_level_audit_by_pk?: Maybe<ApprovalLevelAudit>;
  /** fetch data from the table: "risksmart.approval_level" using primary key columns */
  approval_level_by_pk?: Maybe<ApprovalLevel>;
  /** fetch data from the table: "risksmart.approval_rule_type" */
  approval_rule_type: Array<ApprovalRuleType>;
  /** fetch aggregated fields from the table: "risksmart.approval_rule_type" */
  approval_rule_type_aggregate: ApprovalRuleTypeAggregate;
  /** fetch data from the table: "risksmart.approval_rule_type" using primary key columns */
  approval_rule_type_by_pk?: Maybe<ApprovalRuleType>;
  /** fetch data from the table: "risksmart.approval_status" */
  approval_status: Array<ApprovalStatus>;
  /** fetch aggregated fields from the table: "risksmart.approval_status" */
  approval_status_aggregate: ApprovalStatusAggregate;
  /** fetch data from the table: "risksmart.approval_status" using primary key columns */
  approval_status_by_pk?: Maybe<ApprovalStatus>;
  /** fetch data from the table: "risksmart.approver" */
  approver: Array<Approver>;
  /** fetch aggregated fields from the table: "risksmart.approver" */
  approver_aggregate: ApproverAggregate;
  /** fetch data from the table: "risksmart.approver_audit" */
  approver_audit: Array<ApproverAudit>;
  /** fetch aggregated fields from the table: "risksmart.approver_audit" */
  approver_audit_aggregate: ApproverAuditAggregate;
  /** fetch data from the table: "risksmart.approver_audit" using primary key columns */
  approver_audit_by_pk?: Maybe<ApproverAudit>;
  /** fetch data from the table: "risksmart.approver" using primary key columns */
  approver_by_pk?: Maybe<Approver>;
  /** fetch data from the table: "risksmart.approver_response" */
  approver_response: Array<ApproverResponse>;
  /** fetch aggregated fields from the table: "risksmart.approver_response" */
  approver_response_aggregate: ApproverResponseAggregate;
  /** fetch data from the table: "risksmart.approver_response" using primary key columns */
  approver_response_by_pk?: Maybe<ApproverResponse>;
  /** fetch data from the table: "risksmart.assessment" */
  assessment: Array<Assessment>;
  /** fetch data from the table: "risksmart.assessment_activity" */
  assessment_activity: Array<AssessmentActivity>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity" */
  assessment_activity_aggregate: AssessmentActivityAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_audit" */
  assessment_activity_audit: Array<AssessmentActivityAudit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity_audit" */
  assessment_activity_audit_aggregate: AssessmentActivityAuditAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_audit" using primary key columns */
  assessment_activity_audit_by_pk?: Maybe<AssessmentActivityAudit>;
  /** fetch data from the table: "risksmart.assessment_activity" using primary key columns */
  assessment_activity_by_pk?: Maybe<AssessmentActivity>;
  /** fetch data from the table: "risksmart.assessment_activity_status" */
  assessment_activity_status: Array<AssessmentActivityStatus>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity_status" */
  assessment_activity_status_aggregate: AssessmentActivityStatusAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_status" using primary key columns */
  assessment_activity_status_by_pk?: Maybe<AssessmentActivityStatus>;
  /** fetch data from the table: "risksmart.assessment_activity_type" */
  assessment_activity_type: Array<AssessmentActivityType>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity_type" */
  assessment_activity_type_aggregate: AssessmentActivityTypeAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_type" using primary key columns */
  assessment_activity_type_by_pk?: Maybe<AssessmentActivityType>;
  /** fetch aggregated fields from the table: "risksmart.assessment" */
  assessment_aggregate: AssessmentAggregate;
  /** fetch data from the table: "risksmart.assessment_audit" */
  assessment_audit: Array<AssessmentAudit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_audit" */
  assessment_audit_aggregate: AssessmentAuditAggregate;
  /** fetch data from the table: "risksmart.assessment_audit" using primary key columns */
  assessment_audit_by_pk?: Maybe<AssessmentAudit>;
  /** fetch data from the table: "risksmart.assessment" using primary key columns */
  assessment_by_pk?: Maybe<Assessment>;
  /** fetch data from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent: Array<AssessmentResultParent>;
  /** fetch aggregated fields from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent_aggregate: AssessmentResultParentAggregate;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit: Array<AssessmentResultParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit_aggregate: AssessmentResultParentAuditAggregate;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" using primary key columns */
  assessment_result_parent_audit_by_pk?: Maybe<AssessmentResultParentAudit>;
  /** fetch data from the table: "risksmart.assessment_result_parent" using primary key columns */
  assessment_result_parent_by_pk?: Maybe<AssessmentResultParent>;
  /** fetch data from the table: "risksmart.assessment_status" */
  assessment_status: Array<AssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.assessment_status" */
  assessment_status_aggregate: AssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.assessment_status" using primary key columns */
  assessment_status_by_pk?: Maybe<AssessmentStatus>;
  /** fetch data from the table: "risksmart.attestation_config" */
  attestation_config: Array<AttestationConfig>;
  /** fetch aggregated fields from the table: "risksmart.attestation_config" */
  attestation_config_aggregate: AttestationConfigAggregate;
  /** fetch data from the table: "risksmart.attestation_config" using primary key columns */
  attestation_config_by_pk?: Maybe<AttestationConfig>;
  /** fetch data from the table: "risksmart.attestation_group" */
  attestation_group: Array<AttestationGroup>;
  /** fetch aggregated fields from the table: "risksmart.attestation_group" */
  attestation_group_aggregate: AttestationGroupAggregate;
  /** fetch data from the table: "risksmart.attestation_group" using primary key columns */
  attestation_group_by_pk?: Maybe<AttestationGroup>;
  /** fetch data from the table: "risksmart.attestation_record" */
  attestation_record: Array<AttestationRecord>;
  /** fetch aggregated fields from the table: "risksmart.attestation_record" */
  attestation_record_aggregate: AttestationRecordAggregate;
  /** fetch data from the table: "risksmart.attestation_record" using primary key columns */
  attestation_record_by_pk?: Maybe<AttestationRecord>;
  /** fetch data from the table: "risksmart.attestation_record_status" */
  attestation_record_status: Array<AttestationRecordStatus>;
  /** fetch aggregated fields from the table: "risksmart.attestation_record_status" */
  attestation_record_status_aggregate: AttestationRecordStatusAggregate;
  /** fetch data from the table: "risksmart.attestation_record_status" using primary key columns */
  attestation_record_status_by_pk?: Maybe<AttestationRecordStatus>;
  /** fetch data from the table: "risksmart.audit_log_view" */
  audit_log_view: Array<AuditLogView>;
  /** fetch aggregated fields from the table: "risksmart.audit_log_view" */
  audit_log_view_aggregate: AuditLogViewAggregate;
  /** fetch data from the table: "auth.organisation" */
  auth_organisation: Array<AuthOrganisation>;
  /** fetch aggregated fields from the table: "auth.organisation" */
  auth_organisation_aggregate: AuthOrganisationAggregate;
  /** fetch data from the table: "auth.organisation_audit" */
  auth_organisation_audit: Array<AuthOrganisationAudit>;
  /** fetch aggregated fields from the table: "auth.organisation_audit" */
  auth_organisation_audit_aggregate: AuthOrganisationAuditAggregate;
  /** fetch data from the table: "auth.organisation_audit" using primary key columns */
  auth_organisation_audit_by_pk?: Maybe<AuthOrganisationAudit>;
  /** fetch data from the table: "auth.organisation" using primary key columns */
  auth_organisation_by_pk?: Maybe<AuthOrganisation>;
  /** fetch data from the table: "auth.organisationuser" */
  auth_organisationuser: Array<AuthOrganisationuser>;
  /** fetch aggregated fields from the table: "auth.organisationuser" */
  auth_organisationuser_aggregate: AuthOrganisationuserAggregate;
  /** fetch data from the table: "auth.organisationuser_audit" */
  auth_organisationuser_audit: Array<AuthOrganisationuserAudit>;
  /** fetch aggregated fields from the table: "auth.organisationuser_audit" */
  auth_organisationuser_audit_aggregate: AuthOrganisationuserAuditAggregate;
  /** fetch data from the table: "auth.organisationuser_audit" using primary key columns */
  auth_organisationuser_audit_by_pk?: Maybe<AuthOrganisationuserAudit>;
  /** fetch data from the table: "auth.organisationuser" using primary key columns */
  auth_organisationuser_by_pk?: Maybe<AuthOrganisationuser>;
  /** fetch data from the table: "auth.user" */
  auth_user: Array<AuthUser>;
  /** fetch data from the table: "auth.user_activity_audit" */
  auth_user_activity_audit: Array<AuthUserActivityAudit>;
  /** fetch aggregated fields from the table: "auth.user_activity_audit" */
  auth_user_activity_audit_aggregate: AuthUserActivityAuditAggregate;
  /** fetch data from the table: "auth.user_activity_audit" using primary key columns */
  auth_user_activity_audit_by_pk?: Maybe<AuthUserActivityAudit>;
  /** fetch aggregated fields from the table: "auth.user" */
  auth_user_aggregate: AuthUserAggregate;
  /** fetch data from the table: "auth.user_audit" */
  auth_user_audit: Array<AuthUserAudit>;
  /** fetch aggregated fields from the table: "auth.user_audit" */
  auth_user_audit_aggregate: AuthUserAuditAggregate;
  /** fetch data from the table: "auth.user_audit" using primary key columns */
  auth_user_audit_by_pk?: Maybe<AuthUserAudit>;
  /** fetch data from the table: "auth.user" using primary key columns */
  auth_user_by_pk?: Maybe<AuthUser>;
  available_roles: Array<RoleOutput>;
  /** fetch data from the table: "risksmart.business_area" */
  business_area: Array<BusinessArea>;
  /** fetch aggregated fields from the table: "risksmart.business_area" */
  business_area_aggregate: BusinessAreaAggregate;
  /** fetch data from the table: "risksmart.business_area" using primary key columns */
  business_area_by_pk?: Maybe<BusinessArea>;
  /** fetch data from the table: "risksmart.cause" */
  cause: Array<Cause>;
  /** fetch aggregated fields from the table: "risksmart.cause" */
  cause_aggregate: CauseAggregate;
  /** fetch data from the table: "risksmart.cause_audit" */
  cause_audit: Array<CauseAudit>;
  /** fetch aggregated fields from the table: "risksmart.cause_audit" */
  cause_audit_aggregate: CauseAuditAggregate;
  /** fetch data from the table: "risksmart.cause_audit" using primary key columns */
  cause_audit_by_pk?: Maybe<CauseAudit>;
  /** fetch data from the table: "risksmart.cause" using primary key columns */
  cause_by_pk?: Maybe<Cause>;
  /** fetch data from the table: "risksmart.change_request" */
  change_request: Array<ChangeRequest>;
  /** fetch aggregated fields from the table: "risksmart.change_request" */
  change_request_aggregate: ChangeRequestAggregate;
  /** fetch data from the table: "risksmart.change_request_audit" */
  change_request_audit: Array<ChangeRequestAudit>;
  /** fetch aggregated fields from the table: "risksmart.change_request_audit" */
  change_request_audit_aggregate: ChangeRequestAuditAggregate;
  /** fetch data from the table: "risksmart.change_request_audit" using primary key columns */
  change_request_audit_by_pk?: Maybe<ChangeRequestAudit>;
  /** fetch data from the table: "risksmart.change_request" using primary key columns */
  change_request_by_pk?: Maybe<ChangeRequest>;
  /** fetch data from the table: "risksmart.change_request_contributor" */
  change_request_contributor: Array<ChangeRequestContributor>;
  /** fetch aggregated fields from the table: "risksmart.change_request_contributor" */
  change_request_contributor_aggregate: ChangeRequestContributorAggregate;
  /** fetch data from the table: "risksmart.change_request_contributor" using primary key columns */
  change_request_contributor_by_pk?: Maybe<ChangeRequestContributor>;
  /** fetch data from the table: "risksmart.comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "risksmart.comment" */
  comment_aggregate: CommentAggregate;
  /** fetch data from the table: "risksmart.comment_audit" */
  comment_audit: Array<CommentAudit>;
  /** fetch aggregated fields from the table: "risksmart.comment_audit" */
  comment_audit_aggregate: CommentAuditAggregate;
  /** fetch data from the table: "risksmart.comment_audit" using primary key columns */
  comment_audit_by_pk?: Maybe<CommentAudit>;
  /** fetch data from the table: "risksmart.comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment: Array<ComplianceMonitoringAssessment>;
  /** fetch aggregated fields from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment_aggregate: ComplianceMonitoringAssessmentAggregate;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" using primary key columns */
  compliance_monitoring_assessment_by_pk?: Maybe<ComplianceMonitoringAssessment>;
  /** fetch data from the table: "config.env" */
  config_env: Array<ConfigEnv>;
  /** fetch aggregated fields from the table: "config.env" */
  config_env_aggregate: ConfigEnvAggregate;
  /** fetch data from the table: "config.env" using primary key columns */
  config_env_by_pk?: Maybe<ConfigEnv>;
  /** fetch data from the table: "risksmart.consequence" */
  consequence: Array<Consequence>;
  /** fetch aggregated fields from the table: "risksmart.consequence" */
  consequence_aggregate: ConsequenceAggregate;
  /** fetch data from the table: "risksmart.consequence_audit" */
  consequence_audit: Array<ConsequenceAudit>;
  /** fetch aggregated fields from the table: "risksmart.consequence_audit" */
  consequence_audit_aggregate: ConsequenceAuditAggregate;
  /** fetch data from the table: "risksmart.consequence_audit" using primary key columns */
  consequence_audit_by_pk?: Maybe<ConsequenceAudit>;
  /** fetch data from the table: "risksmart.consequence" using primary key columns */
  consequence_by_pk?: Maybe<Consequence>;
  /** fetch data from the table: "risksmart.consequence_type" */
  consequence_type: Array<ConsequenceType>;
  /** fetch aggregated fields from the table: "risksmart.consequence_type" */
  consequence_type_aggregate: ConsequenceTypeAggregate;
  /** fetch data from the table: "risksmart.consequence_type" using primary key columns */
  consequence_type_by_pk?: Maybe<ConsequenceType>;
  /** fetch data from the table: "risksmart.contributor" */
  contributor: Array<Contributor>;
  /** fetch aggregated fields from the table: "risksmart.contributor" */
  contributor_aggregate: ContributorAggregate;
  /** fetch data from the table: "risksmart.contributor_audit" */
  contributor_audit: Array<ContributorAudit>;
  /** fetch aggregated fields from the table: "risksmart.contributor_audit" */
  contributor_audit_aggregate: ContributorAuditAggregate;
  /** fetch data from the table: "risksmart.contributor_audit" using primary key columns */
  contributor_audit_by_pk?: Maybe<ContributorAudit>;
  /** fetch data from the table: "risksmart.contributor" using primary key columns */
  contributor_by_pk?: Maybe<Contributor>;
  /** fetch data from the table: "risksmart.contributor_group" */
  contributor_group: Array<ContributorGroup>;
  /** fetch aggregated fields from the table: "risksmart.contributor_group" */
  contributor_group_aggregate: ContributorGroupAggregate;
  /** fetch data from the table: "risksmart.contributor_group_audit" */
  contributor_group_audit: Array<ContributorGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.contributor_group_audit" */
  contributor_group_audit_aggregate: ContributorGroupAuditAggregate;
  /** fetch data from the table: "risksmart.contributor_group_audit" using primary key columns */
  contributor_group_audit_by_pk?: Maybe<ContributorGroupAudit>;
  /** fetch data from the table: "risksmart.contributor_group" using primary key columns */
  contributor_group_by_pk?: Maybe<ContributorGroup>;
  /** fetch data from the table: "risksmart.contributor_type" */
  contributor_type: Array<ContributorType>;
  /** fetch aggregated fields from the table: "risksmart.contributor_type" */
  contributor_type_aggregate: ContributorTypeAggregate;
  /** fetch data from the table: "risksmart.contributor_type" using primary key columns */
  contributor_type_by_pk?: Maybe<ContributorType>;
  /** fetch data from the table: "risksmart.control" */
  control: Array<Control>;
  /** fetch aggregated fields from the table: "risksmart.control" */
  control_aggregate: ControlAggregate;
  /** fetch data from the table: "risksmart.control_audit" */
  control_audit: Array<ControlAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_audit" */
  control_audit_aggregate: ControlAuditAggregate;
  /** fetch data from the table: "risksmart.control_audit" using primary key columns */
  control_audit_by_pk?: Maybe<ControlAudit>;
  /** fetch data from the table: "risksmart.control" using primary key columns */
  control_by_pk?: Maybe<Control>;
  /** fetch data from the table: "risksmart.control_group" */
  control_group: Array<ControlGroup>;
  /** fetch aggregated fields from the table: "risksmart.control_group" */
  control_group_aggregate: ControlGroupAggregate;
  /** fetch data from the table: "risksmart.control_group_audit" */
  control_group_audit: Array<ControlGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_group_audit" */
  control_group_audit_aggregate: ControlGroupAuditAggregate;
  /** fetch data from the table: "risksmart.control_group_audit" using primary key columns */
  control_group_audit_by_pk?: Maybe<ControlGroupAudit>;
  /** fetch data from the table: "risksmart.control_group" using primary key columns */
  control_group_by_pk?: Maybe<ControlGroup>;
  /** fetch data from the table: "risksmart.control_parent" */
  control_parent: Array<ControlParent>;
  /** fetch aggregated fields from the table: "risksmart.control_parent" */
  control_parent_aggregate: ControlParentAggregate;
  /** fetch data from the table: "risksmart.control_parent_audit" */
  control_parent_audit: Array<ControlParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_parent_audit" */
  control_parent_audit_aggregate: ControlParentAuditAggregate;
  /** fetch data from the table: "risksmart.control_parent_audit" using primary key columns */
  control_parent_audit_by_pk?: Maybe<ControlParentAudit>;
  /** fetch data from the table: "risksmart.control_parent" using primary key columns */
  control_parent_by_pk?: Maybe<ControlParent>;
  /** fetch data from the table: "risksmart.control_test_frequency" */
  control_test_frequency: Array<ControlTestFrequency>;
  /** fetch aggregated fields from the table: "risksmart.control_test_frequency" */
  control_test_frequency_aggregate: ControlTestFrequencyAggregate;
  /** fetch data from the table: "risksmart.control_test_frequency" using primary key columns */
  control_test_frequency_by_pk?: Maybe<ControlTestFrequency>;
  /** fetch data from the table: "risksmart.control_type" */
  control_type: Array<ControlType>;
  /** fetch aggregated fields from the table: "risksmart.control_type" */
  control_type_aggregate: ControlTypeAggregate;
  /** fetch data from the table: "risksmart.control_type" using primary key columns */
  control_type_by_pk?: Maybe<ControlType>;
  /** fetch data from the table: "risksmart.conversation" */
  conversation: Array<Conversation>;
  /** fetch aggregated fields from the table: "risksmart.conversation" */
  conversation_aggregate: ConversationAggregate;
  /** fetch data from the table: "risksmart.conversation_audit" */
  conversation_audit: Array<ConversationAudit>;
  /** fetch aggregated fields from the table: "risksmart.conversation_audit" */
  conversation_audit_aggregate: ConversationAuditAggregate;
  /** fetch data from the table: "risksmart.conversation_audit" using primary key columns */
  conversation_audit_by_pk?: Maybe<ConversationAudit>;
  /** fetch data from the table: "risksmart.conversation" using primary key columns */
  conversation_by_pk?: Maybe<Conversation>;
  /** fetch data from the table: "risksmart.cost_type" */
  cost_type: Array<CostType>;
  /** fetch aggregated fields from the table: "risksmart.cost_type" */
  cost_type_aggregate: CostTypeAggregate;
  /** fetch data from the table: "risksmart.cost_type" using primary key columns */
  cost_type_by_pk?: Maybe<CostType>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" */
  custom_attribute_schema: Array<CustomAttributeSchema>;
  /** fetch aggregated fields from the table: "risksmart.custom_attribute_schema" */
  custom_attribute_schema_aggregate: CustomAttributeSchemaAggregate;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit: Array<CustomAttributeSchemaAudit>;
  /** fetch aggregated fields from the table: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit_aggregate: CustomAttributeSchemaAuditAggregate;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" using primary key columns */
  custom_attribute_schema_audit_by_pk?: Maybe<CustomAttributeSchemaAudit>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" using primary key columns */
  custom_attribute_schema_by_pk?: Maybe<CustomAttributeSchema>;
  /** fetch data from the table: "risksmart.custom_ribbon" */
  custom_ribbon: Array<CustomRibbon>;
  /** fetch aggregated fields from the table: "risksmart.custom_ribbon" */
  custom_ribbon_aggregate: CustomRibbonAggregate;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit: Array<CustomRibbonAudit>;
  /** fetch aggregated fields from the table: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit_aggregate: CustomRibbonAuditAggregate;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" using primary key columns */
  custom_ribbon_audit_by_pk?: Maybe<CustomRibbonAudit>;
  /** fetch data from the table: "risksmart.custom_ribbon" using primary key columns */
  custom_ribbon_by_pk?: Maybe<CustomRibbon>;
  /** fetch data from the table: "risksmart.dashboard" */
  dashboard: Array<Dashboard>;
  /** fetch aggregated fields from the table: "risksmart.dashboard" */
  dashboard_aggregate: DashboardAggregate;
  /** fetch data from the table: "risksmart.dashboard_audit" */
  dashboard_audit: Array<DashboardAudit>;
  /** fetch aggregated fields from the table: "risksmart.dashboard_audit" */
  dashboard_audit_aggregate: DashboardAuditAggregate;
  /** fetch data from the table: "risksmart.dashboard_audit" using primary key columns */
  dashboard_audit_by_pk?: Maybe<DashboardAudit>;
  /** fetch data from the table: "risksmart.dashboard" using primary key columns */
  dashboard_by_pk?: Maybe<Dashboard>;
  /** fetch data from the table: "risksmart.dashboard_sharing_type" */
  dashboard_sharing_type: Array<DashboardSharingType>;
  /** fetch aggregated fields from the table: "risksmart.dashboard_sharing_type" */
  dashboard_sharing_type_aggregate: DashboardSharingTypeAggregate;
  /** fetch data from the table: "risksmart.dashboard_sharing_type" using primary key columns */
  dashboard_sharing_type_by_pk?: Maybe<DashboardSharingType>;
  /** fetch data from the table: "risksmart.data_import" */
  data_import: Array<DataImport>;
  /** fetch aggregated fields from the table: "risksmart.data_import" */
  data_import_aggregate: DataImportAggregate;
  /** fetch data from the table: "risksmart.data_import_audit" */
  data_import_audit: Array<DataImportAudit>;
  /** fetch aggregated fields from the table: "risksmart.data_import_audit" */
  data_import_audit_aggregate: DataImportAuditAggregate;
  /** fetch data from the table: "risksmart.data_import_audit" using primary key columns */
  data_import_audit_by_pk?: Maybe<DataImportAudit>;
  /** fetch data from the table: "risksmart.data_import" using primary key columns */
  data_import_by_pk?: Maybe<DataImport>;
  /** fetch data from the table: "risksmart.data_import_error" */
  data_import_error: Array<DataImportError>;
  /** fetch aggregated fields from the table: "risksmart.data_import_error" */
  data_import_error_aggregate: DataImportErrorAggregate;
  /** fetch data from the table: "risksmart.data_import_error_audit" */
  data_import_error_audit: Array<DataImportErrorAudit>;
  /** fetch aggregated fields from the table: "risksmart.data_import_error_audit" */
  data_import_error_audit_aggregate: DataImportErrorAuditAggregate;
  /** fetch data from the table: "risksmart.data_import_error_audit" using primary key columns */
  data_import_error_audit_by_pk?: Maybe<DataImportErrorAudit>;
  /** fetch data from the table: "risksmart.data_import_error" using primary key columns */
  data_import_error_by_pk?: Maybe<DataImportError>;
  /** fetch data from the table: "risksmart.data_import_status" */
  data_import_status: Array<DataImportStatus>;
  /** fetch aggregated fields from the table: "risksmart.data_import_status" */
  data_import_status_aggregate: DataImportStatusAggregate;
  /** fetch data from the table: "risksmart.data_import_status" using primary key columns */
  data_import_status_by_pk?: Maybe<DataImportStatus>;
  /** fetch data from the table: "risksmart.department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "risksmart.department" */
  department_aggregate: DepartmentAggregate;
  /** fetch data from the table: "risksmart.department_audit" */
  department_audit: Array<DepartmentAudit>;
  /** fetch aggregated fields from the table: "risksmart.department_audit" */
  department_audit_aggregate: DepartmentAuditAggregate;
  /** fetch data from the table: "risksmart.department_audit" using primary key columns */
  department_audit_by_pk?: Maybe<DepartmentAudit>;
  /** fetch data from the table: "risksmart.department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table: "risksmart.department_type" */
  department_type: Array<DepartmentType>;
  /** fetch aggregated fields from the table: "risksmart.department_type" */
  department_type_aggregate: DepartmentTypeAggregate;
  /** fetch data from the table: "risksmart.department_type_audit" */
  department_type_audit: Array<DepartmentTypeAudit>;
  /** fetch aggregated fields from the table: "risksmart.department_type_audit" */
  department_type_audit_aggregate: DepartmentTypeAuditAggregate;
  /** fetch data from the table: "risksmart.department_type_audit" using primary key columns */
  department_type_audit_by_pk?: Maybe<DepartmentTypeAudit>;
  /** fetch data from the table: "risksmart.department_type" using primary key columns */
  department_type_by_pk?: Maybe<DepartmentType>;
  /** fetch data from the table: "risksmart.department_type_group" */
  department_type_group: Array<DepartmentTypeGroup>;
  /** fetch aggregated fields from the table: "risksmart.department_type_group" */
  department_type_group_aggregate: DepartmentTypeGroupAggregate;
  /** fetch data from the table: "risksmart.department_type_group_audit" */
  department_type_group_audit: Array<DepartmentTypeGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.department_type_group_audit" */
  department_type_group_audit_aggregate: DepartmentTypeGroupAuditAggregate;
  /** fetch data from the table: "risksmart.department_type_group_audit" using primary key columns */
  department_type_group_audit_by_pk?: Maybe<DepartmentTypeGroupAudit>;
  /** fetch data from the table: "risksmart.department_type_group" using primary key columns */
  department_type_group_by_pk?: Maybe<DepartmentTypeGroup>;
  /** fetch data from the table: "risksmart.document" */
  document: Array<Document>;
  /** fetch data from the table: "risksmart.document_action_audit" */
  document_action_audit: Array<DocumentActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_action_audit" */
  document_action_audit_aggregate: DocumentActionAuditAggregate;
  /** fetch data from the table: "risksmart.document_action_audit" using primary key columns */
  document_action_audit_by_pk?: Maybe<DocumentActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.document" */
  document_aggregate: DocumentAggregate;
  /** fetch data from the table: "risksmart.document_assessment_result" */
  document_assessment_result: Array<DocumentAssessmentResult>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result" */
  document_assessment_result_aggregate: DocumentAssessmentResultAggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit: Array<DocumentAssessmentResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit_aggregate: DocumentAssessmentResultAuditAggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" using primary key columns */
  document_assessment_result_audit_by_pk?: Maybe<DocumentAssessmentResultAudit>;
  /** fetch data from the table: "risksmart.document_assessment_result" using primary key columns */
  document_assessment_result_by_pk?: Maybe<DocumentAssessmentResult>;
  /** fetch data from the table: "risksmart.document_assessment_status" */
  document_assessment_status: Array<DocumentAssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_status" */
  document_assessment_status_aggregate: DocumentAssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.document_assessment_status" using primary key columns */
  document_assessment_status_by_pk?: Maybe<DocumentAssessmentStatus>;
  /** fetch data from the table: "risksmart.document_audit" */
  document_audit: Array<DocumentAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_audit" */
  document_audit_aggregate: DocumentAuditAggregate;
  /** fetch data from the table: "risksmart.document_audit" using primary key columns */
  document_audit_by_pk?: Maybe<DocumentAudit>;
  /** fetch data from the table: "risksmart.document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "risksmart.document_file" */
  document_file: Array<DocumentFile>;
  /** fetch aggregated fields from the table: "risksmart.document_file" */
  document_file_aggregate: DocumentFileAggregate;
  /** fetch data from the table: "risksmart.document_file_audit" */
  document_file_audit: Array<DocumentFileAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_file_audit" */
  document_file_audit_aggregate: DocumentFileAuditAggregate;
  /** fetch data from the table: "risksmart.document_file_audit" using primary key columns */
  document_file_audit_by_pk?: Maybe<DocumentFileAudit>;
  /** fetch data from the table: "risksmart.document_file" using primary key columns */
  document_file_by_pk?: Maybe<DocumentFile>;
  /** fetch data from the table: "risksmart.document_file_type" */
  document_file_type: Array<DocumentFileType>;
  /** fetch aggregated fields from the table: "risksmart.document_file_type" */
  document_file_type_aggregate: DocumentFileTypeAggregate;
  /** fetch data from the table: "risksmart.document_file_type" using primary key columns */
  document_file_type_by_pk?: Maybe<DocumentFileType>;
  /** fetch data from the table: "risksmart.document_issue_audit" */
  document_issue_audit: Array<DocumentIssueAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_issue_audit" */
  document_issue_audit_aggregate: DocumentIssueAuditAggregate;
  /** fetch data from the table: "risksmart.document_issue_audit" using primary key columns */
  document_issue_audit_by_pk?: Maybe<DocumentIssueAudit>;
  /** fetch data from the table: "risksmart.document_linked_document" */
  document_linked_document: Array<DocumentLinkedDocument>;
  /** fetch aggregated fields from the table: "risksmart.document_linked_document" */
  document_linked_document_aggregate: DocumentLinkedDocumentAggregate;
  /** fetch data from the table: "risksmart.document_linked_document_audit" */
  document_linked_document_audit: Array<DocumentLinkedDocumentAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_linked_document_audit" */
  document_linked_document_audit_aggregate: DocumentLinkedDocumentAuditAggregate;
  /** fetch data from the table: "risksmart.document_linked_document_audit" using primary key columns */
  document_linked_document_audit_by_pk?: Maybe<DocumentLinkedDocumentAudit>;
  /** fetch data from the table: "risksmart.document_linked_document" using primary key columns */
  document_linked_document_by_pk?: Maybe<DocumentLinkedDocument>;
  /** fetch data from the table: "risksmart.file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "risksmart.file" */
  file_aggregate: FileAggregate;
  /** fetch data from the table: "risksmart.file_audit" */
  file_audit: Array<FileAudit>;
  /** fetch aggregated fields from the table: "risksmart.file_audit" */
  file_audit_aggregate: FileAuditAggregate;
  /** fetch data from the table: "risksmart.file_audit" using primary key columns */
  file_audit_by_pk?: Maybe<FileAudit>;
  /** fetch data from the table: "risksmart.file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table: "risksmart.form_configuration" */
  form_configuration: Array<FormConfiguration>;
  /** fetch aggregated fields from the table: "risksmart.form_configuration" */
  form_configuration_aggregate: FormConfigurationAggregate;
  /** fetch data from the table: "risksmart.form_configuration_audit" */
  form_configuration_audit: Array<FormConfigurationAudit>;
  /** fetch aggregated fields from the table: "risksmart.form_configuration_audit" */
  form_configuration_audit_aggregate: FormConfigurationAuditAggregate;
  /** fetch data from the table: "risksmart.form_configuration_audit" using primary key columns */
  form_configuration_audit_by_pk?: Maybe<FormConfigurationAudit>;
  /** fetch data from the table: "risksmart.form_configuration" using primary key columns */
  form_configuration_by_pk?: Maybe<FormConfiguration>;
  /** fetch data from the table: "risksmart.form_field_configuration" */
  form_field_configuration: Array<FormFieldConfiguration>;
  /** fetch aggregated fields from the table: "risksmart.form_field_configuration" */
  form_field_configuration_aggregate: FormFieldConfigurationAggregate;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit: Array<FormFieldConfigurationAudit>;
  /** fetch aggregated fields from the table: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit_aggregate: FormFieldConfigurationAuditAggregate;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" using primary key columns */
  form_field_configuration_audit_by_pk?: Maybe<FormFieldConfigurationAudit>;
  /** fetch data from the table: "risksmart.form_field_configuration" using primary key columns */
  form_field_configuration_by_pk?: Maybe<FormFieldConfiguration>;
  /** fetch data from the table: "risksmart.form_field_ordering" */
  form_field_ordering: Array<FormFieldOrdering>;
  /** fetch aggregated fields from the table: "risksmart.form_field_ordering" */
  form_field_ordering_aggregate: FormFieldOrderingAggregate;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit: Array<FormFieldOrderingAudit>;
  /** fetch aggregated fields from the table: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit_aggregate: FormFieldOrderingAuditAggregate;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" using primary key columns */
  form_field_ordering_audit_by_pk?: Maybe<FormFieldOrderingAudit>;
  /** fetch data from the table: "risksmart.form_field_ordering" using primary key columns */
  form_field_ordering_by_pk?: Maybe<FormFieldOrdering>;
  /** fetch data from the table: "risksmart.impact" */
  impact: Array<Impact>;
  /** fetch aggregated fields from the table: "risksmart.impact" */
  impact_aggregate: ImpactAggregate;
  /** fetch data from the table: "risksmart.impact_audit" */
  impact_audit: Array<ImpactAudit>;
  /** fetch aggregated fields from the table: "risksmart.impact_audit" */
  impact_audit_aggregate: ImpactAuditAggregate;
  /** fetch data from the table: "risksmart.impact_audit" using primary key columns */
  impact_audit_by_pk?: Maybe<ImpactAudit>;
  /** fetch data from the table: "risksmart.impact" using primary key columns */
  impact_by_pk?: Maybe<Impact>;
  /** fetch data from the table: "risksmart.impact_parent" */
  impact_parent: Array<ImpactParent>;
  /** fetch aggregated fields from the table: "risksmart.impact_parent" */
  impact_parent_aggregate: ImpactParentAggregate;
  /** fetch data from the table: "risksmart.impact_parent_audit" */
  impact_parent_audit: Array<ImpactParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.impact_parent_audit" */
  impact_parent_audit_aggregate: ImpactParentAuditAggregate;
  /** fetch data from the table: "risksmart.impact_parent_audit" using primary key columns */
  impact_parent_audit_by_pk?: Maybe<ImpactParentAudit>;
  /** fetch data from the table: "risksmart.impact_parent" using primary key columns */
  impact_parent_by_pk?: Maybe<ImpactParent>;
  /** fetch data from the table: "risksmart.impact_rating" */
  impact_rating: Array<ImpactRating>;
  /** fetch aggregated fields from the table: "risksmart.impact_rating" */
  impact_rating_aggregate: ImpactRatingAggregate;
  /** fetch data from the table: "risksmart.impact_rating_audit" */
  impact_rating_audit: Array<ImpactRatingAudit>;
  /** fetch aggregated fields from the table: "risksmart.impact_rating_audit" */
  impact_rating_audit_aggregate: ImpactRatingAuditAggregate;
  /** fetch data from the table: "risksmart.impact_rating_audit" using primary key columns */
  impact_rating_audit_by_pk?: Maybe<ImpactRatingAudit>;
  /** fetch data from the table: "risksmart.impact_rating" using primary key columns */
  impact_rating_by_pk?: Maybe<ImpactRating>;
  /** fetch data from the table: "risksmart.indicator" */
  indicator: Array<Indicator>;
  /** fetch aggregated fields from the table: "risksmart.indicator" */
  indicator_aggregate: IndicatorAggregate;
  /** fetch data from the table: "risksmart.indicator_audit" */
  indicator_audit: Array<IndicatorAudit>;
  /** fetch aggregated fields from the table: "risksmart.indicator_audit" */
  indicator_audit_aggregate: IndicatorAuditAggregate;
  /** fetch data from the table: "risksmart.indicator_audit" using primary key columns */
  indicator_audit_by_pk?: Maybe<IndicatorAudit>;
  /** fetch data from the table: "risksmart.indicator" using primary key columns */
  indicator_by_pk?: Maybe<Indicator>;
  /** fetch data from the table: "risksmart.indicator_parent" */
  indicator_parent: Array<IndicatorParent>;
  /** fetch aggregated fields from the table: "risksmart.indicator_parent" */
  indicator_parent_aggregate: IndicatorParentAggregate;
  /** fetch data from the table: "risksmart.indicator_parent" using primary key columns */
  indicator_parent_by_pk?: Maybe<IndicatorParent>;
  /** fetch data from the table: "risksmart.indicator_result" */
  indicator_result: Array<IndicatorResult>;
  /** fetch aggregated fields from the table: "risksmart.indicator_result" */
  indicator_result_aggregate: IndicatorResultAggregate;
  /** fetch data from the table: "risksmart.indicator_result_audit" */
  indicator_result_audit: Array<IndicatorResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.indicator_result_audit" */
  indicator_result_audit_aggregate: IndicatorResultAuditAggregate;
  /** fetch data from the table: "risksmart.indicator_result_audit" using primary key columns */
  indicator_result_audit_by_pk?: Maybe<IndicatorResultAudit>;
  /** fetch data from the table: "risksmart.indicator_result" using primary key columns */
  indicator_result_by_pk?: Maybe<IndicatorResult>;
  /** fetch data from the table: "risksmart.indicator_type" */
  indicator_type: Array<IndicatorType>;
  /** fetch aggregated fields from the table: "risksmart.indicator_type" */
  indicator_type_aggregate: IndicatorTypeAggregate;
  /** fetch data from the table: "risksmart.indicator_type" using primary key columns */
  indicator_type_by_pk?: Maybe<IndicatorType>;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** fetch data from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity: Array<InternalAuditEntity>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity_aggregate: InternalAuditEntityAggregate;
  /** fetch data from the table: "risksmart.internal_audit_entity" using primary key columns */
  internal_audit_entity_by_pk?: Maybe<InternalAuditEntity>;
  /** fetch data from the table: "risksmart.internal_audit_report" */
  internal_audit_report: Array<InternalAuditReport>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_report" */
  internal_audit_report_aggregate: InternalAuditReportAggregate;
  /** fetch data from the table: "risksmart.internal_audit_report" using primary key columns */
  internal_audit_report_by_pk?: Maybe<InternalAuditReport>;
  /** fetch data from the table: "risksmart.issue" */
  issue: Array<Issue>;
  /** fetch data from the table: "risksmart.issue_action_audit" */
  issue_action_audit: Array<IssueActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_action_audit" */
  issue_action_audit_aggregate: IssueActionAuditAggregate;
  /** fetch data from the table: "risksmart.issue_action_audit" using primary key columns */
  issue_action_audit_by_pk?: Maybe<IssueActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue" */
  issue_aggregate: IssueAggregate;
  /** fetch data from the table: "risksmart.issue_assessment" */
  issue_assessment: Array<IssueAssessment>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment" */
  issue_assessment_aggregate: IssueAssessmentAggregate;
  /** fetch data from the table: "risksmart.issue_assessment_audit" */
  issue_assessment_audit: Array<IssueAssessmentAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment_audit" */
  issue_assessment_audit_aggregate: IssueAssessmentAuditAggregate;
  /** fetch data from the table: "risksmart.issue_assessment_audit" using primary key columns */
  issue_assessment_audit_by_pk?: Maybe<IssueAssessmentAudit>;
  /** fetch data from the table: "risksmart.issue_assessment" using primary key columns */
  issue_assessment_by_pk?: Maybe<IssueAssessment>;
  /** fetch data from the table: "risksmart.issue_assessment_status" */
  issue_assessment_status: Array<IssueAssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment_status" */
  issue_assessment_status_aggregate: IssueAssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.issue_assessment_status" using primary key columns */
  issue_assessment_status_by_pk?: Maybe<IssueAssessmentStatus>;
  /** fetch data from the table: "risksmart.issue_audit" */
  issue_audit: Array<IssueAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_audit" */
  issue_audit_aggregate: IssueAuditAggregate;
  /** fetch data from the table: "risksmart.issue_audit" using primary key columns */
  issue_audit_by_pk?: Maybe<IssueAudit>;
  /** fetch data from the table: "risksmart.issue" using primary key columns */
  issue_by_pk?: Maybe<Issue>;
  /** fetch data from the table: "risksmart.issue_parent" */
  issue_parent: Array<IssueParent>;
  /** fetch aggregated fields from the table: "risksmart.issue_parent" */
  issue_parent_aggregate: IssueParentAggregate;
  /** fetch data from the table: "risksmart.issue_parent_audit" */
  issue_parent_audit: Array<IssueParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_parent_audit" */
  issue_parent_audit_aggregate: IssueParentAuditAggregate;
  /** fetch data from the table: "risksmart.issue_parent_audit" using primary key columns */
  issue_parent_audit_by_pk?: Maybe<IssueParentAudit>;
  /** fetch data from the table: "risksmart.issue_parent" using primary key columns */
  issue_parent_by_pk?: Maybe<IssueParent>;
  /** fetch data from the table: "risksmart.issue_update" */
  issue_update: Array<IssueUpdate>;
  /** fetch aggregated fields from the table: "risksmart.issue_update" */
  issue_update_aggregate: IssueUpdateAggregate;
  /** fetch data from the table: "risksmart.issue_update_audit" */
  issue_update_audit: Array<IssueUpdateAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_update_audit" */
  issue_update_audit_aggregate: IssueUpdateAuditAggregate;
  /** fetch data from the table: "risksmart.issue_update_audit" using primary key columns */
  issue_update_audit_by_pk?: Maybe<IssueUpdateAudit>;
  /** fetch data from the table: "risksmart.issue_update" using primary key columns */
  issue_update_by_pk?: Maybe<IssueUpdate>;
  /** fetch data from the table: "risksmart.linked_item" */
  linked_item: Array<LinkedItem>;
  /** fetch aggregated fields from the table: "risksmart.linked_item" */
  linked_item_aggregate: LinkedItemAggregate;
  /** fetch data from the table: "risksmart.linked_item" using primary key columns */
  linked_item_by_pk?: Maybe<LinkedItem>;
  /** fetch data from the table: "risksmart.node" */
  node: Array<Node>;
  /** fetch aggregated fields from the table: "risksmart.node" */
  node_aggregate: NodeAggregate;
  /** fetch data from the table: "risksmart.node_ancestor" */
  node_ancestor: Array<NodeAncestor>;
  /** fetch aggregated fields from the table: "risksmart.node_ancestor" */
  node_ancestor_aggregate: NodeAncestorAggregate;
  /** fetch data from the table: "risksmart.node_ancestor" using primary key columns */
  node_ancestor_by_pk?: Maybe<NodeAncestor>;
  /** fetch data from the table: "risksmart.node" using primary key columns */
  node_by_pk?: Maybe<Node>;
  notificationPreferences: NotificationPreferencesOutput;
  /** fetch data from the table: "risksmart.obligation" */
  obligation: Array<Obligation>;
  /** fetch data from the table: "risksmart.obligation_action_audit" */
  obligation_action_audit: Array<ObligationActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_action_audit" */
  obligation_action_audit_aggregate: ObligationActionAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_action_audit" using primary key columns */
  obligation_action_audit_by_pk?: Maybe<ObligationActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation" */
  obligation_aggregate: ObligationAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result: Array<ObligationAssessmentResult>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result_aggregate: ObligationAssessmentResultAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit: Array<ObligationAssessmentResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit_aggregate: ObligationAssessmentResultAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" using primary key columns */
  obligation_assessment_result_audit_by_pk?: Maybe<ObligationAssessmentResultAudit>;
  /** fetch data from the table: "risksmart.obligation_assessment_result" using primary key columns */
  obligation_assessment_result_by_pk?: Maybe<ObligationAssessmentResult>;
  /** fetch data from the table: "risksmart.obligation_assessment_status" */
  obligation_assessment_status: Array<ObligationAssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_status" */
  obligation_assessment_status_aggregate: ObligationAssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_status" using primary key columns */
  obligation_assessment_status_by_pk?: Maybe<ObligationAssessmentStatus>;
  /** fetch data from the table: "risksmart.obligation_audit" */
  obligation_audit: Array<ObligationAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_audit" */
  obligation_audit_aggregate: ObligationAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_audit" using primary key columns */
  obligation_audit_by_pk?: Maybe<ObligationAudit>;
  /** fetch data from the table: "risksmart.obligation" using primary key columns */
  obligation_by_pk?: Maybe<Obligation>;
  /** fetch data from the table: "risksmart.obligation_impact" */
  obligation_impact: Array<ObligationImpact>;
  /** fetch aggregated fields from the table: "risksmart.obligation_impact" */
  obligation_impact_aggregate: ObligationImpactAggregate;
  /** fetch data from the table: "risksmart.obligation_impact_audit" */
  obligation_impact_audit: Array<ObligationImpactAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_impact_audit" */
  obligation_impact_audit_aggregate: ObligationImpactAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_impact_audit" using primary key columns */
  obligation_impact_audit_by_pk?: Maybe<ObligationImpactAudit>;
  /** fetch data from the table: "risksmart.obligation_impact" using primary key columns */
  obligation_impact_by_pk?: Maybe<ObligationImpact>;
  /** fetch data from the table: "risksmart.obligation_type" */
  obligation_type: Array<ObligationType>;
  /** fetch aggregated fields from the table: "risksmart.obligation_type" */
  obligation_type_aggregate: ObligationTypeAggregate;
  /** fetch data from the table: "risksmart.obligation_type" using primary key columns */
  obligation_type_by_pk?: Maybe<ObligationType>;
  /** fetch data from the table: "risksmart.owner" */
  owner: Array<Owner>;
  /** fetch aggregated fields from the table: "risksmart.owner" */
  owner_aggregate: OwnerAggregate;
  /** fetch data from the table: "risksmart.owner_audit" */
  owner_audit: Array<OwnerAudit>;
  /** fetch aggregated fields from the table: "risksmart.owner_audit" */
  owner_audit_aggregate: OwnerAuditAggregate;
  /** fetch data from the table: "risksmart.owner_audit" using primary key columns */
  owner_audit_by_pk?: Maybe<OwnerAudit>;
  /** fetch data from the table: "risksmart.owner" using primary key columns */
  owner_by_pk?: Maybe<Owner>;
  /** fetch data from the table: "risksmart.owner_group" */
  owner_group: Array<OwnerGroup>;
  /** fetch aggregated fields from the table: "risksmart.owner_group" */
  owner_group_aggregate: OwnerGroupAggregate;
  /** fetch data from the table: "risksmart.owner_group_audit" */
  owner_group_audit: Array<OwnerGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.owner_group_audit" */
  owner_group_audit_aggregate: OwnerGroupAuditAggregate;
  /** fetch data from the table: "risksmart.owner_group_audit" using primary key columns */
  owner_group_audit_by_pk?: Maybe<OwnerGroupAudit>;
  /** fetch data from the table: "risksmart.owner_group" using primary key columns */
  owner_group_by_pk?: Maybe<OwnerGroup>;
  /** fetch data from the table: "risksmart.parent_type" */
  parent_type: Array<ParentType>;
  /** fetch aggregated fields from the table: "risksmart.parent_type" */
  parent_type_aggregate: ParentTypeAggregate;
  /** fetch data from the table: "risksmart.parent_type" using primary key columns */
  parent_type_by_pk?: Maybe<ParentType>;
  /** fetch data from the table: "risksmart.permission_view" */
  permission: Array<Permission>;
  /** fetch aggregated fields from the table: "risksmart.permission_view" */
  permission_aggregate: PermissionAggregate;
  /** fetch data from the table: "risksmart.questionnaire_invite" */
  questionnaire_invite: Array<QuestionnaireInvite>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_invite" */
  questionnaire_invite_aggregate: QuestionnaireInviteAggregate;
  /** fetch data from the table: "risksmart.questionnaire_invite" using primary key columns */
  questionnaire_invite_by_pk?: Maybe<QuestionnaireInvite>;
  /** fetch data from the table: "risksmart.questionnaire_template" */
  questionnaire_template: Array<QuestionnaireTemplate>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template" */
  questionnaire_template_aggregate: QuestionnaireTemplateAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template" using primary key columns */
  questionnaire_template_by_pk?: Maybe<QuestionnaireTemplate>;
  /** fetch data from the table: "risksmart.questionnaire_template_version" */
  questionnaire_template_version: Array<QuestionnaireTemplateVersion>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_version" */
  questionnaire_template_version_aggregate: QuestionnaireTemplateVersionAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_version" using primary key columns */
  questionnaire_template_version_by_pk?: Maybe<QuestionnaireTemplateVersion>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status: Array<QuestionnaireTemplateVersionStatus>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status_aggregate: QuestionnaireTemplateVersionStatusAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" using primary key columns */
  questionnaire_template_version_status_by_pk?: Maybe<QuestionnaireTemplateVersionStatus>;
  /** fetch data from the table: "risksmart.relation_file" */
  relation_file: Array<RelationFile>;
  /** fetch aggregated fields from the table: "risksmart.relation_file" */
  relation_file_aggregate: RelationFileAggregate;
  /** fetch data from the table: "risksmart.relation_file_audit" */
  relation_file_audit: Array<RelationFileAudit>;
  /** fetch aggregated fields from the table: "risksmart.relation_file_audit" */
  relation_file_audit_aggregate: RelationFileAuditAggregate;
  /** fetch data from the table: "risksmart.relation_file_audit" using primary key columns */
  relation_file_audit_by_pk?: Maybe<RelationFileAudit>;
  /** fetch data from the table: "risksmart.relation_file" using primary key columns */
  relation_file_by_pk?: Maybe<RelationFile>;
  /** fetch data from the table: "risksmart.risk" */
  risk: Array<Risk>;
  /** fetch aggregated fields from the table: "risksmart.risk" */
  risk_aggregate: RiskAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result: Array<RiskAssessmentResult>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result_aggregate: RiskAssessmentResultAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit: Array<RiskAssessmentResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit_aggregate: RiskAssessmentResultAuditAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" using primary key columns */
  risk_assessment_result_audit_by_pk?: Maybe<RiskAssessmentResultAudit>;
  /** fetch data from the table: "risksmart.risk_assessment_result" using primary key columns */
  risk_assessment_result_by_pk?: Maybe<RiskAssessmentResult>;
  /** fetch data from the table: "risksmart.risk_assessment_result_control_type" */
  risk_assessment_result_control_type: Array<RiskAssessmentResultControlType>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result_control_type" */
  risk_assessment_result_control_type_aggregate: RiskAssessmentResultControlTypeAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_control_type" using primary key columns */
  risk_assessment_result_control_type_by_pk?: Maybe<RiskAssessmentResultControlType>;
  /** fetch data from the table: "risksmart.risk_audit" */
  risk_audit: Array<RiskAudit>;
  /** fetch aggregated fields from the table: "risksmart.risk_audit" */
  risk_audit_aggregate: RiskAuditAggregate;
  /** fetch data from the table: "risksmart.risk_audit" using primary key columns */
  risk_audit_by_pk?: Maybe<RiskAudit>;
  /** fetch data from the table: "risksmart.risk" using primary key columns */
  risk_by_pk?: Maybe<Risk>;
  /** fetch data from the table: "risksmart.risk_score" */
  risk_score: Array<RiskScore>;
  /** fetch aggregated fields from the table: "risksmart.risk_score" */
  risk_score_aggregate: RiskScoreAggregate;
  /** fetch data from the table: "risksmart.risk_score" using primary key columns */
  risk_score_by_pk?: Maybe<RiskScore>;
  /** fetch data from the table: "risksmart.risk_scoring_model" */
  risk_scoring_model: Array<RiskScoringModel>;
  /** fetch aggregated fields from the table: "risksmart.risk_scoring_model" */
  risk_scoring_model_aggregate: RiskScoringModelAggregate;
  /** fetch data from the table: "risksmart.risk_scoring_model" using primary key columns */
  risk_scoring_model_by_pk?: Maybe<RiskScoringModel>;
  /** fetch data from the table: "risksmart.risk_status_type" */
  risk_status_type: Array<RiskStatusType>;
  /** fetch aggregated fields from the table: "risksmart.risk_status_type" */
  risk_status_type_aggregate: RiskStatusTypeAggregate;
  /** fetch data from the table: "risksmart.risk_status_type" using primary key columns */
  risk_status_type_by_pk?: Maybe<RiskStatusType>;
  /** fetch data from the table: "risksmart.risk_treatment_type" */
  risk_treatment_type: Array<RiskTreatmentType>;
  /** fetch aggregated fields from the table: "risksmart.risk_treatment_type" */
  risk_treatment_type_aggregate: RiskTreatmentTypeAggregate;
  /** fetch data from the table: "risksmart.risk_treatment_type" using primary key columns */
  risk_treatment_type_by_pk?: Maybe<RiskTreatmentType>;
  /** fetch data from the table: "risksmart.approver_response_audit" */
  risksmart_approver_response_audit: Array<RisksmartApproverResponseAudit>;
  /** fetch aggregated fields from the table: "risksmart.approver_response_audit" */
  risksmart_approver_response_audit_aggregate: RisksmartApproverResponseAuditAggregate;
  /** fetch data from the table: "risksmart.approver_response_audit" using primary key columns */
  risksmart_approver_response_audit_by_pk?: Maybe<RisksmartApproverResponseAudit>;
  /** fetch data from the table: "risksmart.change_request_contributor_audit" */
  risksmart_change_request_contributor_audit: Array<RisksmartChangeRequestContributorAudit>;
  /** fetch aggregated fields from the table: "risksmart.change_request_contributor_audit" */
  risksmart_change_request_contributor_audit_aggregate: RisksmartChangeRequestContributorAuditAggregate;
  /** fetch data from the table: "risksmart.change_request_contributor_audit" using primary key columns */
  risksmart_change_request_contributor_audit_by_pk?: Maybe<RisksmartChangeRequestContributorAudit>;
  /** fetch data from the table: "risksmart.contributor_view" */
  risksmart_contributor_view: Array<RisksmartContributorView>;
  /** fetch aggregated fields from the table: "risksmart.contributor_view" */
  risksmart_contributor_view_aggregate: RisksmartContributorViewAggregate;
  /** fetch data from the table: "risksmart.control_action_audit" */
  risksmart_control_action_audit: Array<RisksmartControlActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_action_audit" */
  risksmart_control_action_audit_aggregate: RisksmartControlActionAuditAggregate;
  /** fetch data from the table: "risksmart.control_action_audit" using primary key columns */
  risksmart_control_action_audit_by_pk?: Maybe<RisksmartControlActionAudit>;
  /** fetch data from the table: "risksmart.counter" */
  risksmart_counter: Array<RisksmartCounter>;
  /** fetch aggregated fields from the table: "risksmart.counter" */
  risksmart_counter_aggregate: RisksmartCounterAggregate;
  /** fetch data from the table: "risksmart.counter" using primary key columns */
  risksmart_counter_by_pk?: Maybe<RisksmartCounter>;
  /** fetch data from the table: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit: Array<RisksmartIndicatorParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit_aggregate: RisksmartIndicatorParentAuditAggregate;
  /** fetch data from the table: "risksmart.indicator_parent_audit" using primary key columns */
  risksmart_indicator_parent_audit_by_pk?: Maybe<RisksmartIndicatorParentAudit>;
  /** fetch data from the table: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit: Array<RisksmartLinkedItemAudit>;
  /** fetch aggregated fields from the table: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit_aggregate: RisksmartLinkedItemAuditAggregate;
  /** fetch data from the table: "risksmart.linked_item_audit" using primary key columns */
  risksmart_linked_item_audit_by_pk?: Maybe<RisksmartLinkedItemAudit>;
  /** fetch data from the table: "risksmart.obligation_issue_audit" */
  risksmart_obligation_issue_audit: Array<RisksmartObligationIssueAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_issue_audit" */
  risksmart_obligation_issue_audit_aggregate: RisksmartObligationIssueAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_issue_audit" using primary key columns */
  risksmart_obligation_issue_audit_by_pk?: Maybe<RisksmartObligationIssueAudit>;
  /** fetch data from the table: "risksmart.questionnaire_template_audit" */
  risksmart_questionnaire_template_audit: Array<RisksmartQuestionnaireTemplateAudit>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_audit" */
  risksmart_questionnaire_template_audit_aggregate: RisksmartQuestionnaireTemplateAuditAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_audit" using primary key columns */
  risksmart_questionnaire_template_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateAudit>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_audit" */
  risksmart_questionnaire_template_version_audit: Array<RisksmartQuestionnaireTemplateVersionAudit>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_version_audit" */
  risksmart_questionnaire_template_version_audit_aggregate: RisksmartQuestionnaireTemplateVersionAuditAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_version_audit" using primary key columns */
  risksmart_questionnaire_template_version_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateVersionAudit>;
  /** fetch data from the table: "risksmart.risk_action_audit" */
  risksmart_risk_action_audit: Array<RisksmartRiskActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.risk_action_audit" */
  risksmart_risk_action_audit_aggregate: RisksmartRiskActionAuditAggregate;
  /** fetch data from the table: "risksmart.risk_action_audit" using primary key columns */
  risksmart_risk_action_audit_by_pk?: Maybe<RisksmartRiskActionAudit>;
  /** fetch data from the table: "risksmart.role_access" */
  role_access: Array<RoleAccess>;
  /** fetch aggregated fields from the table: "risksmart.role_access" */
  role_access_aggregate: RoleAccessAggregate;
  /** fetch data from the table: "risksmart.role_access" using primary key columns */
  role_access_by_pk?: Maybe<RoleAccess>;
  slackNotificationConnection: SlackNotificationConnectionOutput;
  /** fetch data from the table: "risksmart.tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "risksmart.tag" */
  tag_aggregate: TagAggregate;
  /** fetch data from the table: "risksmart.tag_audit" */
  tag_audit: Array<TagAudit>;
  /** fetch aggregated fields from the table: "risksmart.tag_audit" */
  tag_audit_aggregate: TagAuditAggregate;
  /** fetch data from the table: "risksmart.tag_audit" using primary key columns */
  tag_audit_by_pk?: Maybe<TagAudit>;
  /** fetch data from the table: "risksmart.tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table: "risksmart.tag_type" */
  tag_type: Array<TagType>;
  /** fetch aggregated fields from the table: "risksmart.tag_type" */
  tag_type_aggregate: TagTypeAggregate;
  /** fetch data from the table: "risksmart.tag_type_audit" */
  tag_type_audit: Array<TagTypeAudit>;
  /** fetch aggregated fields from the table: "risksmart.tag_type_audit" */
  tag_type_audit_aggregate: TagTypeAuditAggregate;
  /** fetch data from the table: "risksmart.tag_type_audit" using primary key columns */
  tag_type_audit_by_pk?: Maybe<TagTypeAudit>;
  /** fetch data from the table: "risksmart.tag_type" using primary key columns */
  tag_type_by_pk?: Maybe<TagType>;
  /** fetch data from the table: "risksmart.tag_type_group" */
  tag_type_group: Array<TagTypeGroup>;
  /** fetch aggregated fields from the table: "risksmart.tag_type_group" */
  tag_type_group_aggregate: TagTypeGroupAggregate;
  /** fetch data from the table: "risksmart.tag_type_group_audit" */
  tag_type_group_audit: Array<TagTypeGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.tag_type_group_audit" */
  tag_type_group_audit_aggregate: TagTypeGroupAuditAggregate;
  /** fetch data from the table: "risksmart.tag_type_group_audit" using primary key columns */
  tag_type_group_audit_by_pk?: Maybe<TagTypeGroupAudit>;
  /** fetch data from the table: "risksmart.tag_type_group" using primary key columns */
  tag_type_group_by_pk?: Maybe<TagTypeGroup>;
  /** fetch data from the table: "risksmart.taxonomy" */
  taxonomy: Array<Taxonomy>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy" */
  taxonomy_aggregate: TaxonomyAggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit: Array<TaxonomyAudit>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit_aggregate: TaxonomyAuditAggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" using primary key columns */
  taxonomy_audit_by_pk?: Maybe<TaxonomyAudit>;
  /** fetch data from the table: "risksmart.taxonomy" using primary key columns */
  taxonomy_by_pk?: Maybe<Taxonomy>;
  /** fetch data from the table: "risksmart.taxonomy_org" */
  taxonomy_org: Array<TaxonomyOrg>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_org" */
  taxonomy_org_aggregate: TaxonomyOrgAggregate;
  /** fetch data from the table: "risksmart.taxonomy_org_audit" */
  taxonomy_org_audit: Array<TaxonomyOrgAudit>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_org_audit" */
  taxonomy_org_audit_aggregate: TaxonomyOrgAuditAggregate;
  /** fetch data from the table: "risksmart.taxonomy_org_audit" using primary key columns */
  taxonomy_org_audit_by_pk?: Maybe<TaxonomyOrgAudit>;
  /** fetch data from the table: "risksmart.taxonomy_org" using primary key columns */
  taxonomy_org_by_pk?: Maybe<TaxonomyOrg>;
  /** fetch data from the table: "risksmart.test_frequency" */
  test_frequency: Array<TestFrequency>;
  /** fetch aggregated fields from the table: "risksmart.test_frequency" */
  test_frequency_aggregate: TestFrequencyAggregate;
  /** fetch data from the table: "risksmart.test_frequency" using primary key columns */
  test_frequency_by_pk?: Maybe<TestFrequency>;
  /** fetch data from the table: "risksmart.test_result" */
  test_result: Array<TestResult>;
  /** fetch aggregated fields from the table: "risksmart.test_result" */
  test_result_aggregate: TestResultAggregate;
  /** fetch data from the table: "risksmart.test_result_audit" */
  test_result_audit: Array<TestResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.test_result_audit" */
  test_result_audit_aggregate: TestResultAuditAggregate;
  /** fetch data from the table: "risksmart.test_result_audit" using primary key columns */
  test_result_audit_by_pk?: Maybe<TestResultAudit>;
  /** fetch data from the table: "risksmart.test_result" using primary key columns */
  test_result_by_pk?: Maybe<TestResult>;
  /** fetch data from the table: "risksmart.third_party" */
  third_party: Array<ThirdParty>;
  /** fetch aggregated fields from the table: "risksmart.third_party" */
  third_party_aggregate: ThirdPartyAggregate;
  /** fetch data from the table: "risksmart.third_party_audit" */
  third_party_audit: Array<ThirdPartyAudit>;
  /** fetch aggregated fields from the table: "risksmart.third_party_audit" */
  third_party_audit_aggregate: ThirdPartyAuditAggregate;
  /** fetch data from the table: "risksmart.third_party_audit" using primary key columns */
  third_party_audit_by_pk?: Maybe<ThirdPartyAudit>;
  /** fetch data from the table: "risksmart.third_party" using primary key columns */
  third_party_by_pk?: Maybe<ThirdParty>;
  /** fetch data from the table: "risksmart.third_party_response" */
  third_party_response: Array<ThirdPartyResponse>;
  /** fetch aggregated fields from the table: "risksmart.third_party_response" */
  third_party_response_aggregate: ThirdPartyResponseAggregate;
  /** fetch data from the table: "risksmart.third_party_response" using primary key columns */
  third_party_response_by_pk?: Maybe<ThirdPartyResponse>;
  /** fetch data from the table: "risksmart.third_party_response_status" */
  third_party_response_status: Array<ThirdPartyResponseStatus>;
  /** fetch aggregated fields from the table: "risksmart.third_party_response_status" */
  third_party_response_status_aggregate: ThirdPartyResponseStatusAggregate;
  /** fetch data from the table: "risksmart.third_party_response_status" using primary key columns */
  third_party_response_status_by_pk?: Maybe<ThirdPartyResponseStatus>;
  /** fetch data from the table: "risksmart.third_party_status" */
  third_party_status: Array<ThirdPartyStatus>;
  /** fetch aggregated fields from the table: "risksmart.third_party_status" */
  third_party_status_aggregate: ThirdPartyStatusAggregate;
  /** fetch data from the table: "risksmart.third_party_status" using primary key columns */
  third_party_status_by_pk?: Maybe<ThirdPartyStatus>;
  /** fetch data from the table: "risksmart.third_party_type" */
  third_party_type: Array<ThirdPartyType>;
  /** fetch aggregated fields from the table: "risksmart.third_party_type" */
  third_party_type_aggregate: ThirdPartyTypeAggregate;
  /** fetch data from the table: "risksmart.third_party_type" using primary key columns */
  third_party_type_by_pk?: Maybe<ThirdPartyType>;
  /** fetch data from the table: "risksmart.user_view_active" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "risksmart.user_view_active" */
  user_aggregate: UserAggregate;
  /** fetch data from the table: "risksmart.user_group" */
  user_group: Array<UserGroup>;
  /** fetch aggregated fields from the table: "risksmart.user_group" */
  user_group_aggregate: UserGroupAggregate;
  /** fetch data from the table: "risksmart.user_group_audit" */
  user_group_audit: Array<UserGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.user_group_audit" */
  user_group_audit_aggregate: UserGroupAuditAggregate;
  /** fetch data from the table: "risksmart.user_group_audit" using primary key columns */
  user_group_audit_by_pk?: Maybe<UserGroupAudit>;
  /** fetch data from the table: "risksmart.user_group" using primary key columns */
  user_group_by_pk?: Maybe<UserGroup>;
  /** fetch data from the table: "risksmart.user_group_user" */
  user_group_user: Array<UserGroupUser>;
  /** fetch aggregated fields from the table: "risksmart.user_group_user" */
  user_group_user_aggregate: UserGroupUserAggregate;
  /** fetch data from the table: "risksmart.user_group_user_audit" */
  user_group_user_audit: Array<UserGroupUserAudit>;
  /** fetch aggregated fields from the table: "risksmart.user_group_user_audit" */
  user_group_user_audit_aggregate: UserGroupUserAuditAggregate;
  /** fetch data from the table: "risksmart.user_group_user_audit" using primary key columns */
  user_group_user_audit_by_pk?: Maybe<UserGroupUserAudit>;
  /** fetch data from the table: "risksmart.user_group_user" using primary key columns */
  user_group_user_by_pk?: Maybe<UserGroupUser>;
  /** fetch data from the table: "risksmart.user_role_access" */
  user_role_access: Array<UserRoleAccess>;
  /** fetch aggregated fields from the table: "risksmart.user_role_access" */
  user_role_access_aggregate: UserRoleAccessAggregate;
  /** fetch data from the table: "risksmart.user_search_preferences" */
  user_search_preferences: Array<UserSearchPreferences>;
  /** fetch aggregated fields from the table: "risksmart.user_search_preferences" */
  user_search_preferences_aggregate: UserSearchPreferencesAggregate;
  /** fetch data from the table: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit: Array<UserSearchPreferencesAudit>;
  /** fetch aggregated fields from the table: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit_aggregate: UserSearchPreferencesAuditAggregate;
  /** fetch data from the table: "risksmart.user_search_preferences_audit" using primary key columns */
  user_search_preferences_audit_by_pk?: Maybe<UserSearchPreferencesAudit>;
  /** fetch data from the table: "risksmart.user_search_preferences" using primary key columns */
  user_search_preferences_by_pk?: Maybe<UserSearchPreferences>;
  /** fetch data from the table: "auth.user_status" */
  user_status: Array<UserStatus>;
  /** fetch aggregated fields from the table: "auth.user_status" */
  user_status_aggregate: UserStatusAggregate;
  /** fetch data from the table: "auth.user_status" using primary key columns */
  user_status_by_pk?: Maybe<UserStatus>;
  /** fetch data from the table: "risksmart.version_status" */
  version_status: Array<VersionStatus>;
  /** fetch aggregated fields from the table: "risksmart.version_status" */
  version_status_aggregate: VersionStatusAggregate;
  /** fetch data from the table: "risksmart.version_status" using primary key columns */
  version_status_by_pk?: Maybe<VersionStatus>;
};


export type QueryRootAcceptanceArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceOrderBy>>;
  where?: InputMaybe<AcceptanceBoolExp>;
};


export type QueryRootAcceptanceAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceOrderBy>>;
  where?: InputMaybe<AcceptanceBoolExp>;
};


export type QueryRootAcceptanceAuditArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceAuditOrderBy>>;
  where?: InputMaybe<AcceptanceAuditBoolExp>;
};


export type QueryRootAcceptanceAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceAuditOrderBy>>;
  where?: InputMaybe<AcceptanceAuditBoolExp>;
};


export type QueryRootAcceptanceAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootAcceptanceByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootAcceptanceParentArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


export type QueryRootAcceptanceParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


export type QueryRootAcceptanceParentAuditArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentAuditOrderBy>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


export type QueryRootAcceptanceParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentAuditOrderBy>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


export type QueryRootAcceptanceParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAcceptanceParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAcceptanceStatusArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceStatusOrderBy>>;
  where?: InputMaybe<AcceptanceStatusBoolExp>;
};


export type QueryRootAcceptanceStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceStatusOrderBy>>;
  where?: InputMaybe<AcceptanceStatusBoolExp>;
};


export type QueryRootAcceptanceStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootAccessTypeArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AccessTypeOrderBy>>;
  where?: InputMaybe<AccessTypeBoolExp>;
};


export type QueryRootAccessTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AccessTypeOrderBy>>;
  where?: InputMaybe<AccessTypeBoolExp>;
};


export type QueryRootAccessTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootActionArgs = {
  distinct_on?: InputMaybe<Array<ActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionOrderBy>>;
  where?: InputMaybe<ActionBoolExp>;
};


export type QueryRootActionAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionOrderBy>>;
  where?: InputMaybe<ActionBoolExp>;
};


export type QueryRootActionAuditArgs = {
  distinct_on?: InputMaybe<Array<ActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionAuditOrderBy>>;
  where?: InputMaybe<ActionAuditBoolExp>;
};


export type QueryRootActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionAuditOrderBy>>;
  where?: InputMaybe<ActionAuditBoolExp>;
};


export type QueryRootActionAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootActionByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootActionParentArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


export type QueryRootActionParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


export type QueryRootActionParentAuditArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


export type QueryRootActionParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


export type QueryRootActionParentAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootActionParentByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootActionStatusArgs = {
  distinct_on?: InputMaybe<Array<ActionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionStatusOrderBy>>;
  where?: InputMaybe<ActionStatusBoolExp>;
};


export type QueryRootActionStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionStatusOrderBy>>;
  where?: InputMaybe<ActionStatusBoolExp>;
};


export type QueryRootActionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootActionUpdateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateOrderBy>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};


export type QueryRootActionUpdateAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateOrderBy>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};


export type QueryRootActionUpdateAuditArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateAuditOrderBy>>;
  where?: InputMaybe<ActionUpdateAuditBoolExp>;
};


export type QueryRootActionUpdateAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateAuditOrderBy>>;
  where?: InputMaybe<ActionUpdateAuditBoolExp>;
};


export type QueryRootActionUpdateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootActionUpdateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootActionUpdateSummaryArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSummarySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateSummaryOrderBy>>;
  where?: InputMaybe<ActionUpdateSummaryBoolExp>;
};


export type QueryRootActionUpdateSummaryAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSummarySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateSummaryOrderBy>>;
  where?: InputMaybe<ActionUpdateSummaryBoolExp>;
};


export type QueryRootAggregationOrgArgs = {
  distinct_on?: InputMaybe<Array<AggregationOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregationOrgOrderBy>>;
  where?: InputMaybe<AggregationOrgBoolExp>;
};


export type QueryRootAggregationOrgAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregationOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregationOrgOrderBy>>;
  where?: InputMaybe<AggregationOrgBoolExp>;
};


export type QueryRootAggregationOrgByPkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type QueryRootAncestorContributorArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


export type QueryRootAncestorContributorAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


export type QueryRootAppetiteArgs = {
  distinct_on?: InputMaybe<Array<AppetiteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteOrderBy>>;
  where?: InputMaybe<AppetiteBoolExp>;
};


export type QueryRootAppetiteAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteOrderBy>>;
  where?: InputMaybe<AppetiteBoolExp>;
};


export type QueryRootAppetiteAuditArgs = {
  distinct_on?: InputMaybe<Array<AppetiteAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteAuditOrderBy>>;
  where?: InputMaybe<AppetiteAuditBoolExp>;
};


export type QueryRootAppetiteAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteAuditOrderBy>>;
  where?: InputMaybe<AppetiteAuditBoolExp>;
};


export type QueryRootAppetiteAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootAppetiteByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootAppetiteModelArgs = {
  distinct_on?: InputMaybe<Array<AppetiteModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteModelOrderBy>>;
  where?: InputMaybe<AppetiteModelBoolExp>;
};


export type QueryRootAppetiteModelAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteModelOrderBy>>;
  where?: InputMaybe<AppetiteModelBoolExp>;
};


export type QueryRootAppetiteModelByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootAppetiteParentArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


export type QueryRootAppetiteParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


export type QueryRootAppetiteParentAuditArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentAuditOrderBy>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


export type QueryRootAppetiteParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentAuditOrderBy>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


export type QueryRootAppetiteParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAppetiteParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAppetiteTypeArgs = {
  distinct_on?: InputMaybe<Array<AppetiteTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteTypeOrderBy>>;
  where?: InputMaybe<AppetiteTypeBoolExp>;
};


export type QueryRootAppetiteTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteTypeOrderBy>>;
  where?: InputMaybe<AppetiteTypeBoolExp>;
};


export type QueryRootAppetiteTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootApprovalArgs = {
  distinct_on?: InputMaybe<Array<ApprovalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalOrderBy>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


export type QueryRootApprovalAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalOrderBy>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


export type QueryRootApprovalAuditArgs = {
  distinct_on?: InputMaybe<Array<ApprovalAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalAuditOrderBy>>;
  where?: InputMaybe<ApprovalAuditBoolExp>;
};


export type QueryRootApprovalAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalAuditOrderBy>>;
  where?: InputMaybe<ApprovalAuditBoolExp>;
};


export type QueryRootApprovalAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootApprovalByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootApprovalInFlightEditRuleArgs = {
  distinct_on?: InputMaybe<Array<ApprovalInFlightEditRuleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalInFlightEditRuleOrderBy>>;
  where?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
};


export type QueryRootApprovalInFlightEditRuleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalInFlightEditRuleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalInFlightEditRuleOrderBy>>;
  where?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
};


export type QueryRootApprovalInFlightEditRuleByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootApprovalLevelArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelOrderBy>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};


export type QueryRootApprovalLevelAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelOrderBy>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};


export type QueryRootApprovalLevelAuditArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelAuditOrderBy>>;
  where?: InputMaybe<ApprovalLevelAuditBoolExp>;
};


export type QueryRootApprovalLevelAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelAuditOrderBy>>;
  where?: InputMaybe<ApprovalLevelAuditBoolExp>;
};


export type QueryRootApprovalLevelAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootApprovalLevelByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootApprovalRuleTypeArgs = {
  distinct_on?: InputMaybe<Array<ApprovalRuleTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalRuleTypeOrderBy>>;
  where?: InputMaybe<ApprovalRuleTypeBoolExp>;
};


export type QueryRootApprovalRuleTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalRuleTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalRuleTypeOrderBy>>;
  where?: InputMaybe<ApprovalRuleTypeBoolExp>;
};


export type QueryRootApprovalRuleTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootApprovalStatusArgs = {
  distinct_on?: InputMaybe<Array<ApprovalStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalStatusOrderBy>>;
  where?: InputMaybe<ApprovalStatusBoolExp>;
};


export type QueryRootApprovalStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalStatusOrderBy>>;
  where?: InputMaybe<ApprovalStatusBoolExp>;
};


export type QueryRootApprovalStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootApproverArgs = {
  distinct_on?: InputMaybe<Array<ApproverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverOrderBy>>;
  where?: InputMaybe<ApproverBoolExp>;
};


export type QueryRootApproverAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverOrderBy>>;
  where?: InputMaybe<ApproverBoolExp>;
};


export type QueryRootApproverAuditArgs = {
  distinct_on?: InputMaybe<Array<ApproverAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverAuditOrderBy>>;
  where?: InputMaybe<ApproverAuditBoolExp>;
};


export type QueryRootApproverAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverAuditOrderBy>>;
  where?: InputMaybe<ApproverAuditBoolExp>;
};


export type QueryRootApproverAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootApproverByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootApproverResponseArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


export type QueryRootApproverResponseAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


export type QueryRootApproverResponseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootAssessmentArgs = {
  distinct_on?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type QueryRootAssessmentActivityArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


export type QueryRootAssessmentActivityAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


export type QueryRootAssessmentActivityAuditArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityAuditOrderBy>>;
  where?: InputMaybe<AssessmentActivityAuditBoolExp>;
};


export type QueryRootAssessmentActivityAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityAuditOrderBy>>;
  where?: InputMaybe<AssessmentActivityAuditBoolExp>;
};


export type QueryRootAssessmentActivityAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootAssessmentActivityByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootAssessmentActivityStatusArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityStatusOrderBy>>;
  where?: InputMaybe<AssessmentActivityStatusBoolExp>;
};


export type QueryRootAssessmentActivityStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityStatusOrderBy>>;
  where?: InputMaybe<AssessmentActivityStatusBoolExp>;
};


export type QueryRootAssessmentActivityStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootAssessmentActivityTypeArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityTypeOrderBy>>;
  where?: InputMaybe<AssessmentActivityTypeBoolExp>;
};


export type QueryRootAssessmentActivityTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityTypeOrderBy>>;
  where?: InputMaybe<AssessmentActivityTypeBoolExp>;
};


export type QueryRootAssessmentActivityTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootAssessmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type QueryRootAssessmentAuditArgs = {
  distinct_on?: InputMaybe<Array<AssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentAuditOrderBy>>;
  where?: InputMaybe<AssessmentAuditBoolExp>;
};


export type QueryRootAssessmentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentAuditOrderBy>>;
  where?: InputMaybe<AssessmentAuditBoolExp>;
};


export type QueryRootAssessmentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootAssessmentResultParentArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


export type QueryRootAssessmentResultParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


export type QueryRootAssessmentResultParentAuditArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentAuditOrderBy>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


export type QueryRootAssessmentResultParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentAuditOrderBy>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


export type QueryRootAssessmentResultParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAssessmentResultParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<AssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentStatusOrderBy>>;
  where?: InputMaybe<AssessmentStatusBoolExp>;
};


export type QueryRootAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentStatusOrderBy>>;
  where?: InputMaybe<AssessmentStatusBoolExp>;
};


export type QueryRootAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootAttestationConfigArgs = {
  distinct_on?: InputMaybe<Array<AttestationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationConfigOrderBy>>;
  where?: InputMaybe<AttestationConfigBoolExp>;
};


export type QueryRootAttestationConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationConfigOrderBy>>;
  where?: InputMaybe<AttestationConfigBoolExp>;
};


export type QueryRootAttestationConfigByPkArgs = {
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootAttestationGroupArgs = {
  distinct_on?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationGroupOrderBy>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


export type QueryRootAttestationGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationGroupOrderBy>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


export type QueryRootAttestationGroupByPkArgs = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};


export type QueryRootAttestationRecordArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


export type QueryRootAttestationRecordAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


export type QueryRootAttestationRecordByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootAttestationRecordStatusArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordStatusOrderBy>>;
  where?: InputMaybe<AttestationRecordStatusBoolExp>;
};


export type QueryRootAttestationRecordStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordStatusOrderBy>>;
  where?: InputMaybe<AttestationRecordStatusBoolExp>;
};


export type QueryRootAttestationRecordStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootAuditLogViewArgs = {
  distinct_on?: InputMaybe<Array<AuditLogViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuditLogViewOrderBy>>;
  where?: InputMaybe<AuditLogViewBoolExp>;
};


export type QueryRootAuditLogViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditLogViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuditLogViewOrderBy>>;
  where?: InputMaybe<AuditLogViewBoolExp>;
};


export type QueryRootAuthOrganisationArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationOrderBy>>;
  where?: InputMaybe<AuthOrganisationBoolExp>;
};


export type QueryRootAuthOrganisationAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationOrderBy>>;
  where?: InputMaybe<AuthOrganisationBoolExp>;
};


export type QueryRootAuthOrganisationAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationAuditBoolExp>;
};


export type QueryRootAuthOrganisationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationAuditBoolExp>;
};


export type QueryRootAuthOrganisationAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};


export type QueryRootAuthOrganisationByPkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type QueryRootAuthOrganisationuserArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


export type QueryRootAuthOrganisationuserAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


export type QueryRootAuthOrganisationuserAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
};


export type QueryRootAuthOrganisationuserAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
};


export type QueryRootAuthOrganisationuserAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


export type QueryRootAuthOrganisationuserByPkArgs = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


export type QueryRootAuthUserArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type QueryRootAuthUserActivityAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthUserActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserActivityAuditOrderBy>>;
  where?: InputMaybe<AuthUserActivityAuditBoolExp>;
};


export type QueryRootAuthUserActivityAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserActivityAuditOrderBy>>;
  where?: InputMaybe<AuthUserActivityAuditBoolExp>;
};


export type QueryRootAuthUserActivityAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ModifiedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type QueryRootAuthUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type QueryRootAuthUserAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserAuditOrderBy>>;
  where?: InputMaybe<AuthUserAuditBoolExp>;
};


export type QueryRootAuthUserAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserAuditOrderBy>>;
  where?: InputMaybe<AuthUserAuditBoolExp>;
};


export type QueryRootAuthUserAuditByPkArgs = {
  Id: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootAuthUserByPkArgs = {
  Id: Scalars['String']['input'];
};


export type QueryRootBusinessAreaArgs = {
  distinct_on?: InputMaybe<Array<BusinessAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BusinessAreaOrderBy>>;
  where?: InputMaybe<BusinessAreaBoolExp>;
};


export type QueryRootBusinessAreaAggregateArgs = {
  distinct_on?: InputMaybe<Array<BusinessAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BusinessAreaOrderBy>>;
  where?: InputMaybe<BusinessAreaBoolExp>;
};


export type QueryRootBusinessAreaByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootCauseArgs = {
  distinct_on?: InputMaybe<Array<CauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseOrderBy>>;
  where?: InputMaybe<CauseBoolExp>;
};


export type QueryRootCauseAggregateArgs = {
  distinct_on?: InputMaybe<Array<CauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseOrderBy>>;
  where?: InputMaybe<CauseBoolExp>;
};


export type QueryRootCauseAuditArgs = {
  distinct_on?: InputMaybe<Array<CauseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseAuditOrderBy>>;
  where?: InputMaybe<CauseAuditBoolExp>;
};


export type QueryRootCauseAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CauseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseAuditOrderBy>>;
  where?: InputMaybe<CauseAuditBoolExp>;
};


export type QueryRootCauseAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootCauseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootChangeRequestArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


export type QueryRootChangeRequestAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


export type QueryRootChangeRequestAuditArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestAuditOrderBy>>;
  where?: InputMaybe<ChangeRequestAuditBoolExp>;
};


export type QueryRootChangeRequestAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestAuditOrderBy>>;
  where?: InputMaybe<ChangeRequestAuditBoolExp>;
};


export type QueryRootChangeRequestAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootChangeRequestByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootChangeRequestContributorArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestContributorOrderBy>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


export type QueryRootChangeRequestContributorAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestContributorOrderBy>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


export type QueryRootChangeRequestContributorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootCommentArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type QueryRootCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type QueryRootCommentAuditArgs = {
  distinct_on?: InputMaybe<Array<CommentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentAuditOrderBy>>;
  where?: InputMaybe<CommentAuditBoolExp>;
};


export type QueryRootCommentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentAuditOrderBy>>;
  where?: InputMaybe<CommentAuditBoolExp>;
};


export type QueryRootCommentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootCommentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootComplianceMonitoringAssessmentArgs = {
  distinct_on?: InputMaybe<Array<ComplianceMonitoringAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComplianceMonitoringAssessmentOrderBy>>;
  where?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
};


export type QueryRootComplianceMonitoringAssessmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComplianceMonitoringAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComplianceMonitoringAssessmentOrderBy>>;
  where?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
};


export type QueryRootComplianceMonitoringAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootConfigEnvArgs = {
  distinct_on?: InputMaybe<Array<ConfigEnvSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigEnvOrderBy>>;
  where?: InputMaybe<ConfigEnvBoolExp>;
};


export type QueryRootConfigEnvAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConfigEnvSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigEnvOrderBy>>;
  where?: InputMaybe<ConfigEnvBoolExp>;
};


export type QueryRootConfigEnvByPkArgs = {
  Name: Scalars['String']['input'];
};


export type QueryRootConsequenceArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceOrderBy>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


export type QueryRootConsequenceAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceOrderBy>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


export type QueryRootConsequenceAuditArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceAuditOrderBy>>;
  where?: InputMaybe<ConsequenceAuditBoolExp>;
};


export type QueryRootConsequenceAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceAuditOrderBy>>;
  where?: InputMaybe<ConsequenceAuditBoolExp>;
};


export type QueryRootConsequenceAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootConsequenceByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootConsequenceTypeArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceTypeOrderBy>>;
  where?: InputMaybe<ConsequenceTypeBoolExp>;
};


export type QueryRootConsequenceTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceTypeOrderBy>>;
  where?: InputMaybe<ConsequenceTypeBoolExp>;
};


export type QueryRootConsequenceTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootContributorArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


export type QueryRootContributorAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


export type QueryRootContributorAuditArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


export type QueryRootContributorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


export type QueryRootContributorAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type QueryRootContributorByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type QueryRootContributorGroupArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


export type QueryRootContributorGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


export type QueryRootContributorGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


export type QueryRootContributorGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


export type QueryRootContributorGroupAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type QueryRootContributorGroupByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type QueryRootContributorTypeArgs = {
  distinct_on?: InputMaybe<Array<ContributorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorTypeOrderBy>>;
  where?: InputMaybe<ContributorTypeBoolExp>;
};


export type QueryRootContributorTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorTypeOrderBy>>;
  where?: InputMaybe<ContributorTypeBoolExp>;
};


export type QueryRootContributorTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootControlArgs = {
  distinct_on?: InputMaybe<Array<ControlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlOrderBy>>;
  where?: InputMaybe<ControlBoolExp>;
};


export type QueryRootControlAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlOrderBy>>;
  where?: InputMaybe<ControlBoolExp>;
};


export type QueryRootControlAuditArgs = {
  distinct_on?: InputMaybe<Array<ControlAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlAuditOrderBy>>;
  where?: InputMaybe<ControlAuditBoolExp>;
};


export type QueryRootControlAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlAuditOrderBy>>;
  where?: InputMaybe<ControlAuditBoolExp>;
};


export type QueryRootControlAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootControlByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootControlGroupArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupOrderBy>>;
  where?: InputMaybe<ControlGroupBoolExp>;
};


export type QueryRootControlGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupOrderBy>>;
  where?: InputMaybe<ControlGroupBoolExp>;
};


export type QueryRootControlGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupAuditOrderBy>>;
  where?: InputMaybe<ControlGroupAuditBoolExp>;
};


export type QueryRootControlGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupAuditOrderBy>>;
  where?: InputMaybe<ControlGroupAuditBoolExp>;
};


export type QueryRootControlGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootControlGroupByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootControlParentArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


export type QueryRootControlParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


export type QueryRootControlParentAuditArgs = {
  distinct_on?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentAuditOrderBy>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


export type QueryRootControlParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentAuditOrderBy>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


export type QueryRootControlParentAuditByPkArgs = {
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootControlParentByPkArgs = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootControlTestFrequencyArgs = {
  distinct_on?: InputMaybe<Array<ControlTestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTestFrequencyOrderBy>>;
  where?: InputMaybe<ControlTestFrequencyBoolExp>;
};


export type QueryRootControlTestFrequencyAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlTestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTestFrequencyOrderBy>>;
  where?: InputMaybe<ControlTestFrequencyBoolExp>;
};


export type QueryRootControlTestFrequencyByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootControlTypeArgs = {
  distinct_on?: InputMaybe<Array<ControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTypeOrderBy>>;
  where?: InputMaybe<ControlTypeBoolExp>;
};


export type QueryRootControlTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTypeOrderBy>>;
  where?: InputMaybe<ControlTypeBoolExp>;
};


export type QueryRootControlTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootConversationArgs = {
  distinct_on?: InputMaybe<Array<ConversationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationOrderBy>>;
  where?: InputMaybe<ConversationBoolExp>;
};


export type QueryRootConversationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationOrderBy>>;
  where?: InputMaybe<ConversationBoolExp>;
};


export type QueryRootConversationAuditArgs = {
  distinct_on?: InputMaybe<Array<ConversationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationAuditOrderBy>>;
  where?: InputMaybe<ConversationAuditBoolExp>;
};


export type QueryRootConversationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationAuditOrderBy>>;
  where?: InputMaybe<ConversationAuditBoolExp>;
};


export type QueryRootConversationAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootConversationByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootCostTypeArgs = {
  distinct_on?: InputMaybe<Array<CostTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CostTypeOrderBy>>;
  where?: InputMaybe<CostTypeBoolExp>;
};


export type QueryRootCostTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<CostTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CostTypeOrderBy>>;
  where?: InputMaybe<CostTypeBoolExp>;
};


export type QueryRootCostTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootCustomAttributeSchemaArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaBoolExp>;
};


export type QueryRootCustomAttributeSchemaAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaBoolExp>;
};


export type QueryRootCustomAttributeSchemaAuditArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaAuditOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
};


export type QueryRootCustomAttributeSchemaAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaAuditOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
};


export type QueryRootCustomAttributeSchemaAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootCustomAttributeSchemaByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootCustomRibbonArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonOrderBy>>;
  where?: InputMaybe<CustomRibbonBoolExp>;
};


export type QueryRootCustomRibbonAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonOrderBy>>;
  where?: InputMaybe<CustomRibbonBoolExp>;
};


export type QueryRootCustomRibbonAuditArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonAuditOrderBy>>;
  where?: InputMaybe<CustomRibbonAuditBoolExp>;
};


export type QueryRootCustomRibbonAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonAuditOrderBy>>;
  where?: InputMaybe<CustomRibbonAuditBoolExp>;
};


export type QueryRootCustomRibbonAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootCustomRibbonByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDashboardArgs = {
  distinct_on?: InputMaybe<Array<DashboardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardOrderBy>>;
  where?: InputMaybe<DashboardBoolExp>;
};


export type QueryRootDashboardAggregateArgs = {
  distinct_on?: InputMaybe<Array<DashboardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardOrderBy>>;
  where?: InputMaybe<DashboardBoolExp>;
};


export type QueryRootDashboardAuditArgs = {
  distinct_on?: InputMaybe<Array<DashboardAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardAuditOrderBy>>;
  where?: InputMaybe<DashboardAuditBoolExp>;
};


export type QueryRootDashboardAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DashboardAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardAuditOrderBy>>;
  where?: InputMaybe<DashboardAuditBoolExp>;
};


export type QueryRootDashboardAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDashboardByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDashboardSharingTypeArgs = {
  distinct_on?: InputMaybe<Array<DashboardSharingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardSharingTypeOrderBy>>;
  where?: InputMaybe<DashboardSharingTypeBoolExp>;
};


export type QueryRootDashboardSharingTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<DashboardSharingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardSharingTypeOrderBy>>;
  where?: InputMaybe<DashboardSharingTypeBoolExp>;
};


export type QueryRootDashboardSharingTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootDataImportArgs = {
  distinct_on?: InputMaybe<Array<DataImportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportOrderBy>>;
  where?: InputMaybe<DataImportBoolExp>;
};


export type QueryRootDataImportAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportOrderBy>>;
  where?: InputMaybe<DataImportBoolExp>;
};


export type QueryRootDataImportAuditArgs = {
  distinct_on?: InputMaybe<Array<DataImportAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportAuditOrderBy>>;
  where?: InputMaybe<DataImportAuditBoolExp>;
};


export type QueryRootDataImportAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportAuditOrderBy>>;
  where?: InputMaybe<DataImportAuditBoolExp>;
};


export type QueryRootDataImportAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDataImportByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDataImportErrorArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorOrderBy>>;
  where?: InputMaybe<DataImportErrorBoolExp>;
};


export type QueryRootDataImportErrorAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorOrderBy>>;
  where?: InputMaybe<DataImportErrorBoolExp>;
};


export type QueryRootDataImportErrorAuditArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorAuditOrderBy>>;
  where?: InputMaybe<DataImportErrorAuditBoolExp>;
};


export type QueryRootDataImportErrorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorAuditOrderBy>>;
  where?: InputMaybe<DataImportErrorAuditBoolExp>;
};


export type QueryRootDataImportErrorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDataImportErrorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDataImportStatusArgs = {
  distinct_on?: InputMaybe<Array<DataImportStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportStatusOrderBy>>;
  where?: InputMaybe<DataImportStatusBoolExp>;
};


export type QueryRootDataImportStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportStatusOrderBy>>;
  where?: InputMaybe<DataImportStatusBoolExp>;
};


export type QueryRootDataImportStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootDepartmentArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


export type QueryRootDepartmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


export type QueryRootDepartmentAuditArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


export type QueryRootDepartmentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


export type QueryRootDepartmentAuditByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootDepartmentByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootDepartmentTypeArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeOrderBy>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};


export type QueryRootDepartmentTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeOrderBy>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};


export type QueryRootDepartmentTypeAuditArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeAuditBoolExp>;
};


export type QueryRootDepartmentTypeAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeAuditBoolExp>;
};


export type QueryRootDepartmentTypeAuditByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDepartmentTypeByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
};


export type QueryRootDepartmentTypeGroupArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupBoolExp>;
};


export type QueryRootDepartmentTypeGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupBoolExp>;
};


export type QueryRootDepartmentTypeGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
};


export type QueryRootDepartmentTypeGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
};


export type QueryRootDepartmentTypeGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDepartmentTypeGroupByPkArgs = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type QueryRootDocumentArgs = {
  distinct_on?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type QueryRootDocumentActionAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentActionAuditOrderBy>>;
  where?: InputMaybe<DocumentActionAuditBoolExp>;
};


export type QueryRootDocumentActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentActionAuditOrderBy>>;
  where?: InputMaybe<DocumentActionAuditBoolExp>;
};


export type QueryRootDocumentActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  DocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDocumentAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type QueryRootDocumentAssessmentResultArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultBoolExp>;
};


export type QueryRootDocumentAssessmentResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultBoolExp>;
};


export type QueryRootDocumentAssessmentResultAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
};


export type QueryRootDocumentAssessmentResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
};


export type QueryRootDocumentAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDocumentAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDocumentAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentStatusOrderBy>>;
  where?: InputMaybe<DocumentAssessmentStatusBoolExp>;
};


export type QueryRootDocumentAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentStatusOrderBy>>;
  where?: InputMaybe<DocumentAssessmentStatusBoolExp>;
};


export type QueryRootDocumentAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootDocumentAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentAuditBoolExp>;
};


export type QueryRootDocumentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentAuditBoolExp>;
};


export type QueryRootDocumentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDocumentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDocumentFileArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileOrderBy>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


export type QueryRootDocumentFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileOrderBy>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


export type QueryRootDocumentFileAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileAuditOrderBy>>;
  where?: InputMaybe<DocumentFileAuditBoolExp>;
};


export type QueryRootDocumentFileAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileAuditOrderBy>>;
  where?: InputMaybe<DocumentFileAuditBoolExp>;
};


export type QueryRootDocumentFileAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDocumentFileByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootDocumentFileTypeArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileTypeOrderBy>>;
  where?: InputMaybe<DocumentFileTypeBoolExp>;
};


export type QueryRootDocumentFileTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileTypeOrderBy>>;
  where?: InputMaybe<DocumentFileTypeBoolExp>;
};


export type QueryRootDocumentFileTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootDocumentIssueAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentIssueAuditOrderBy>>;
  where?: InputMaybe<DocumentIssueAuditBoolExp>;
};


export type QueryRootDocumentIssueAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentIssueAuditOrderBy>>;
  where?: InputMaybe<DocumentIssueAuditBoolExp>;
};


export type QueryRootDocumentIssueAuditByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDocumentLinkedDocumentArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


export type QueryRootDocumentLinkedDocumentAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


export type QueryRootDocumentLinkedDocumentAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
};


export type QueryRootDocumentLinkedDocumentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
};


export type QueryRootDocumentLinkedDocumentAuditByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootDocumentLinkedDocumentByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};


export type QueryRootFileArgs = {
  distinct_on?: InputMaybe<Array<FileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileOrderBy>>;
  where?: InputMaybe<FileBoolExp>;
};


export type QueryRootFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileOrderBy>>;
  where?: InputMaybe<FileBoolExp>;
};


export type QueryRootFileAuditArgs = {
  distinct_on?: InputMaybe<Array<FileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileAuditOrderBy>>;
  where?: InputMaybe<FileAuditBoolExp>;
};


export type QueryRootFileAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileAuditOrderBy>>;
  where?: InputMaybe<FileAuditBoolExp>;
};


export type QueryRootFileAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootFileByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootFormConfigurationArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationOrderBy>>;
  where?: InputMaybe<FormConfigurationBoolExp>;
};


export type QueryRootFormConfigurationAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationOrderBy>>;
  where?: InputMaybe<FormConfigurationBoolExp>;
};


export type QueryRootFormConfigurationAuditArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormConfigurationAuditBoolExp>;
};


export type QueryRootFormConfigurationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormConfigurationAuditBoolExp>;
};


export type QueryRootFormConfigurationAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentType: Scalars['String']['input'];
};


export type QueryRootFormConfigurationByPkArgs = {
  OrgKey: Scalars['String']['input'];
  ParentType: ParentTypeEnum;
};


export type QueryRootFormFieldConfigurationArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


export type QueryRootFormFieldConfigurationAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


export type QueryRootFormFieldConfigurationAuditArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
};


export type QueryRootFormFieldConfigurationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
};


export type QueryRootFormFieldConfigurationAuditByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootFormFieldConfigurationByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


export type QueryRootFormFieldOrderingArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingOrderBy>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};


export type QueryRootFormFieldOrderingAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingOrderBy>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};


export type QueryRootFormFieldOrderingAuditArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingAuditOrderBy>>;
  where?: InputMaybe<FormFieldOrderingAuditBoolExp>;
};


export type QueryRootFormFieldOrderingAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingAuditOrderBy>>;
  where?: InputMaybe<FormFieldOrderingAuditBoolExp>;
};


export type QueryRootFormFieldOrderingAuditByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootFormFieldOrderingByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


export type QueryRootImpactArgs = {
  distinct_on?: InputMaybe<Array<ImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactOrderBy>>;
  where?: InputMaybe<ImpactBoolExp>;
};


export type QueryRootImpactAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactOrderBy>>;
  where?: InputMaybe<ImpactBoolExp>;
};


export type QueryRootImpactAuditArgs = {
  distinct_on?: InputMaybe<Array<ImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactAuditOrderBy>>;
  where?: InputMaybe<ImpactAuditBoolExp>;
};


export type QueryRootImpactAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactAuditOrderBy>>;
  where?: InputMaybe<ImpactAuditBoolExp>;
};


export type QueryRootImpactAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootImpactByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootImpactParentArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentOrderBy>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


export type QueryRootImpactParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentOrderBy>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


export type QueryRootImpactParentAuditArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentAuditOrderBy>>;
  where?: InputMaybe<ImpactParentAuditBoolExp>;
};


export type QueryRootImpactParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentAuditOrderBy>>;
  where?: InputMaybe<ImpactParentAuditBoolExp>;
};


export type QueryRootImpactParentAuditByPkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootImpactParentByPkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootImpactRatingArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


export type QueryRootImpactRatingAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


export type QueryRootImpactRatingAuditArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingAuditOrderBy>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


export type QueryRootImpactRatingAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingAuditOrderBy>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


export type QueryRootImpactRatingAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootImpactRatingByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootIndicatorArgs = {
  distinct_on?: InputMaybe<Array<IndicatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorOrderBy>>;
  where?: InputMaybe<IndicatorBoolExp>;
};


export type QueryRootIndicatorAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorOrderBy>>;
  where?: InputMaybe<IndicatorBoolExp>;
};


export type QueryRootIndicatorAuditArgs = {
  distinct_on?: InputMaybe<Array<IndicatorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorAuditOrderBy>>;
  where?: InputMaybe<IndicatorAuditBoolExp>;
};


export type QueryRootIndicatorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorAuditOrderBy>>;
  where?: InputMaybe<IndicatorAuditBoolExp>;
};


export type QueryRootIndicatorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootIndicatorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootIndicatorParentArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


export type QueryRootIndicatorParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


export type QueryRootIndicatorParentByPkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootIndicatorResultArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultOrderBy>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


export type QueryRootIndicatorResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultOrderBy>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


export type QueryRootIndicatorResultAuditArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultAuditOrderBy>>;
  where?: InputMaybe<IndicatorResultAuditBoolExp>;
};


export type QueryRootIndicatorResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultAuditOrderBy>>;
  where?: InputMaybe<IndicatorResultAuditBoolExp>;
};


export type QueryRootIndicatorResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootIndicatorResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootIndicatorTypeArgs = {
  distinct_on?: InputMaybe<Array<IndicatorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorTypeOrderBy>>;
  where?: InputMaybe<IndicatorTypeBoolExp>;
};


export type QueryRootIndicatorTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorTypeOrderBy>>;
  where?: InputMaybe<IndicatorTypeBoolExp>;
};


export type QueryRootIndicatorTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


export type QueryRootInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


export type QueryRootInternalAuditEntityArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditEntityOrderBy>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


export type QueryRootInternalAuditEntityAggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditEntityOrderBy>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


export type QueryRootInternalAuditEntityByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootInternalAuditReportArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditReportOrderBy>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


export type QueryRootInternalAuditReportAggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditReportOrderBy>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


export type QueryRootInternalAuditReportByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootIssueArgs = {
  distinct_on?: InputMaybe<Array<IssueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueOrderBy>>;
  where?: InputMaybe<IssueBoolExp>;
};


export type QueryRootIssueActionAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueActionAuditOrderBy>>;
  where?: InputMaybe<IssueActionAuditBoolExp>;
};


export type QueryRootIssueActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueActionAuditOrderBy>>;
  where?: InputMaybe<IssueActionAuditBoolExp>;
};


export type QueryRootIssueActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootIssueAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueOrderBy>>;
  where?: InputMaybe<IssueBoolExp>;
};


export type QueryRootIssueAssessmentArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentOrderBy>>;
  where?: InputMaybe<IssueAssessmentBoolExp>;
};


export type QueryRootIssueAssessmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentOrderBy>>;
  where?: InputMaybe<IssueAssessmentBoolExp>;
};


export type QueryRootIssueAssessmentAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentAuditOrderBy>>;
  where?: InputMaybe<IssueAssessmentAuditBoolExp>;
};


export type QueryRootIssueAssessmentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentAuditOrderBy>>;
  where?: InputMaybe<IssueAssessmentAuditBoolExp>;
};


export type QueryRootIssueAssessmentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootIssueAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootIssueAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentStatusOrderBy>>;
  where?: InputMaybe<IssueAssessmentStatusBoolExp>;
};


export type QueryRootIssueAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentStatusOrderBy>>;
  where?: InputMaybe<IssueAssessmentStatusBoolExp>;
};


export type QueryRootIssueAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootIssueAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAuditOrderBy>>;
  where?: InputMaybe<IssueAuditBoolExp>;
};


export type QueryRootIssueAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAuditOrderBy>>;
  where?: InputMaybe<IssueAuditBoolExp>;
};


export type QueryRootIssueAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootIssueByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootIssueParentArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


export type QueryRootIssueParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


export type QueryRootIssueParentAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentAuditOrderBy>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


export type QueryRootIssueParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentAuditOrderBy>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


export type QueryRootIssueParentAuditByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootIssueParentByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootIssueUpdateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateOrderBy>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};


export type QueryRootIssueUpdateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateOrderBy>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};


export type QueryRootIssueUpdateAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateAuditOrderBy>>;
  where?: InputMaybe<IssueUpdateAuditBoolExp>;
};


export type QueryRootIssueUpdateAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateAuditOrderBy>>;
  where?: InputMaybe<IssueUpdateAuditBoolExp>;
};


export type QueryRootIssueUpdateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootIssueUpdateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootLinkedItemArgs = {
  distinct_on?: InputMaybe<Array<LinkedItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LinkedItemOrderBy>>;
  where?: InputMaybe<LinkedItemBoolExp>;
};


export type QueryRootLinkedItemAggregateArgs = {
  distinct_on?: InputMaybe<Array<LinkedItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LinkedItemOrderBy>>;
  where?: InputMaybe<LinkedItemBoolExp>;
};


export type QueryRootLinkedItemByPkArgs = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type QueryRootNodeArgs = {
  distinct_on?: InputMaybe<Array<NodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeOrderBy>>;
  where?: InputMaybe<NodeBoolExp>;
};


export type QueryRootNodeAggregateArgs = {
  distinct_on?: InputMaybe<Array<NodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeOrderBy>>;
  where?: InputMaybe<NodeBoolExp>;
};


export type QueryRootNodeAncestorArgs = {
  distinct_on?: InputMaybe<Array<NodeAncestorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeAncestorOrderBy>>;
  where?: InputMaybe<NodeAncestorBoolExp>;
};


export type QueryRootNodeAncestorAggregateArgs = {
  distinct_on?: InputMaybe<Array<NodeAncestorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeAncestorOrderBy>>;
  where?: InputMaybe<NodeAncestorBoolExp>;
};


export type QueryRootNodeAncestorByPkArgs = {
  AncestorId: Scalars['uuid']['input'];
  Id: Scalars['uuid']['input'];
};


export type QueryRootNodeByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootObligationArgs = {
  distinct_on?: InputMaybe<Array<ObligationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationOrderBy>>;
  where?: InputMaybe<ObligationBoolExp>;
};


export type QueryRootObligationActionAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationActionAuditOrderBy>>;
  where?: InputMaybe<ObligationActionAuditBoolExp>;
};


export type QueryRootObligationActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationActionAuditOrderBy>>;
  where?: InputMaybe<ObligationActionAuditBoolExp>;
};


export type QueryRootObligationActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};


export type QueryRootObligationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationOrderBy>>;
  where?: InputMaybe<ObligationBoolExp>;
};


export type QueryRootObligationAssessmentResultArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultBoolExp>;
};


export type QueryRootObligationAssessmentResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultBoolExp>;
};


export type QueryRootObligationAssessmentResultAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
};


export type QueryRootObligationAssessmentResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
};


export type QueryRootObligationAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootObligationAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootObligationAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentStatusOrderBy>>;
  where?: InputMaybe<ObligationAssessmentStatusBoolExp>;
};


export type QueryRootObligationAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentStatusOrderBy>>;
  where?: InputMaybe<ObligationAssessmentStatusBoolExp>;
};


export type QueryRootObligationAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootObligationAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAuditOrderBy>>;
  where?: InputMaybe<ObligationAuditBoolExp>;
};


export type QueryRootObligationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAuditOrderBy>>;
  where?: InputMaybe<ObligationAuditBoolExp>;
};


export type QueryRootObligationAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootObligationByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootObligationImpactArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactOrderBy>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


export type QueryRootObligationImpactAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactOrderBy>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


export type QueryRootObligationImpactAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactAuditOrderBy>>;
  where?: InputMaybe<ObligationImpactAuditBoolExp>;
};


export type QueryRootObligationImpactAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactAuditOrderBy>>;
  where?: InputMaybe<ObligationImpactAuditBoolExp>;
};


export type QueryRootObligationImpactAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootObligationImpactByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootObligationTypeArgs = {
  distinct_on?: InputMaybe<Array<ObligationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationTypeOrderBy>>;
  where?: InputMaybe<ObligationTypeBoolExp>;
};


export type QueryRootObligationTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationTypeOrderBy>>;
  where?: InputMaybe<ObligationTypeBoolExp>;
};


export type QueryRootObligationTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootOwnerArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


export type QueryRootOwnerAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


export type QueryRootOwnerAuditArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


export type QueryRootOwnerAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


export type QueryRootOwnerAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type QueryRootOwnerByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type QueryRootOwnerGroupArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


export type QueryRootOwnerGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


export type QueryRootOwnerGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


export type QueryRootOwnerGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


export type QueryRootOwnerGroupAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type QueryRootOwnerGroupByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type QueryRootParentTypeArgs = {
  distinct_on?: InputMaybe<Array<ParentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParentTypeOrderBy>>;
  where?: InputMaybe<ParentTypeBoolExp>;
};


export type QueryRootParentTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ParentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParentTypeOrderBy>>;
  where?: InputMaybe<ParentTypeBoolExp>;
};


export type QueryRootParentTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootPermissionArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


export type QueryRootPermissionAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


export type QueryRootQuestionnaireInviteArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


export type QueryRootQuestionnaireInviteAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


export type QueryRootQuestionnaireInviteByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootQuestionnaireTemplateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateBoolExp>;
};


export type QueryRootQuestionnaireTemplateAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateBoolExp>;
};


export type QueryRootQuestionnaireTemplateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootQuestionnaireTemplateVersionArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


export type QueryRootQuestionnaireTemplateVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


export type QueryRootQuestionnaireTemplateVersionByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootQuestionnaireTemplateVersionStatusArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionStatusOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
};


export type QueryRootQuestionnaireTemplateVersionStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionStatusOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
};


export type QueryRootQuestionnaireTemplateVersionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootRelationFileArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


export type QueryRootRelationFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


export type QueryRootRelationFileAuditArgs = {
  distinct_on?: InputMaybe<Array<RelationFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileAuditOrderBy>>;
  where?: InputMaybe<RelationFileAuditBoolExp>;
};


export type QueryRootRelationFileAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileAuditOrderBy>>;
  where?: InputMaybe<RelationFileAuditBoolExp>;
};


export type QueryRootRelationFileAuditByPkArgs = {
  FileId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootRelationFileByPkArgs = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootRiskArgs = {
  distinct_on?: InputMaybe<Array<RiskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskOrderBy>>;
  where?: InputMaybe<RiskBoolExp>;
};


export type QueryRootRiskAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskOrderBy>>;
  where?: InputMaybe<RiskBoolExp>;
};


export type QueryRootRiskAssessmentResultArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultBoolExp>;
};


export type QueryRootRiskAssessmentResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultBoolExp>;
};


export type QueryRootRiskAssessmentResultAuditArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
};


export type QueryRootRiskAssessmentResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
};


export type QueryRootRiskAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRiskAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootRiskAssessmentResultControlTypeArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultControlTypeOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
};


export type QueryRootRiskAssessmentResultControlTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultControlTypeOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
};


export type QueryRootRiskAssessmentResultControlTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootRiskAuditArgs = {
  distinct_on?: InputMaybe<Array<RiskAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAuditOrderBy>>;
  where?: InputMaybe<RiskAuditBoolExp>;
};


export type QueryRootRiskAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAuditOrderBy>>;
  where?: InputMaybe<RiskAuditBoolExp>;
};


export type QueryRootRiskAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRiskByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootRiskScoreArgs = {
  distinct_on?: InputMaybe<Array<RiskScoreSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoreOrderBy>>;
  where?: InputMaybe<RiskScoreBoolExp>;
};


export type QueryRootRiskScoreAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskScoreSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoreOrderBy>>;
  where?: InputMaybe<RiskScoreBoolExp>;
};


export type QueryRootRiskScoreByPkArgs = {
  RiskId: Scalars['uuid']['input'];
};


export type QueryRootRiskScoringModelArgs = {
  distinct_on?: InputMaybe<Array<RiskScoringModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoringModelOrderBy>>;
  where?: InputMaybe<RiskScoringModelBoolExp>;
};


export type QueryRootRiskScoringModelAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskScoringModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoringModelOrderBy>>;
  where?: InputMaybe<RiskScoringModelBoolExp>;
};


export type QueryRootRiskScoringModelByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootRiskStatusTypeArgs = {
  distinct_on?: InputMaybe<Array<RiskStatusTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskStatusTypeOrderBy>>;
  where?: InputMaybe<RiskStatusTypeBoolExp>;
};


export type QueryRootRiskStatusTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskStatusTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskStatusTypeOrderBy>>;
  where?: InputMaybe<RiskStatusTypeBoolExp>;
};


export type QueryRootRiskStatusTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootRiskTreatmentTypeArgs = {
  distinct_on?: InputMaybe<Array<RiskTreatmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskTreatmentTypeOrderBy>>;
  where?: InputMaybe<RiskTreatmentTypeBoolExp>;
};


export type QueryRootRiskTreatmentTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskTreatmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskTreatmentTypeOrderBy>>;
  where?: InputMaybe<RiskTreatmentTypeBoolExp>;
};


export type QueryRootRiskTreatmentTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootRisksmartApproverResponseAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartApproverResponseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartApproverResponseAuditOrderBy>>;
  where?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
};


export type QueryRootRisksmartApproverResponseAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartApproverResponseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartApproverResponseAuditOrderBy>>;
  where?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
};


export type QueryRootRisksmartApproverResponseAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRisksmartChangeRequestContributorAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartChangeRequestContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartChangeRequestContributorAuditOrderBy>>;
  where?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
};


export type QueryRootRisksmartChangeRequestContributorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartChangeRequestContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartChangeRequestContributorAuditOrderBy>>;
  where?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
};


export type QueryRootRisksmartChangeRequestContributorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRisksmartContributorViewArgs = {
  distinct_on?: InputMaybe<Array<RisksmartContributorViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartContributorViewOrderBy>>;
  where?: InputMaybe<RisksmartContributorViewBoolExp>;
};


export type QueryRootRisksmartContributorViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartContributorViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartContributorViewOrderBy>>;
  where?: InputMaybe<RisksmartContributorViewBoolExp>;
};


export type QueryRootRisksmartControlActionAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartControlActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartControlActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartControlActionAuditBoolExp>;
};


export type QueryRootRisksmartControlActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartControlActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartControlActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartControlActionAuditBoolExp>;
};


export type QueryRootRisksmartControlActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRisksmartCounterArgs = {
  distinct_on?: InputMaybe<Array<RisksmartCounterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartCounterOrderBy>>;
  where?: InputMaybe<RisksmartCounterBoolExp>;
};


export type QueryRootRisksmartCounterAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartCounterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartCounterOrderBy>>;
  where?: InputMaybe<RisksmartCounterBoolExp>;
};


export type QueryRootRisksmartCounterByPkArgs = {
  Name: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


export type QueryRootRisksmartIndicatorParentAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


export type QueryRootRisksmartIndicatorParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


export type QueryRootRisksmartIndicatorParentAuditByPkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type QueryRootRisksmartLinkedItemAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartLinkedItemAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartLinkedItemAuditOrderBy>>;
  where?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
};


export type QueryRootRisksmartLinkedItemAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartLinkedItemAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartLinkedItemAuditOrderBy>>;
  where?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
};


export type QueryRootRisksmartLinkedItemAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type QueryRootRisksmartObligationIssueAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartObligationIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartObligationIssueAuditOrderBy>>;
  where?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
};


export type QueryRootRisksmartObligationIssueAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartObligationIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartObligationIssueAuditOrderBy>>;
  where?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
};


export type QueryRootRisksmartObligationIssueAuditByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};


export type QueryRootRisksmartQuestionnaireTemplateAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
};


export type QueryRootRisksmartQuestionnaireTemplateAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
};


export type QueryRootRisksmartQuestionnaireTemplateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRisksmartQuestionnaireTemplateVersionAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
};


export type QueryRootRisksmartQuestionnaireTemplateVersionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
};


export type QueryRootRisksmartQuestionnaireTemplateVersionAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootRisksmartRiskActionAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartRiskActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartRiskActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
};


export type QueryRootRisksmartRiskActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartRiskActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartRiskActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
};


export type QueryRootRisksmartRiskActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  RiskId: Scalars['uuid']['input'];
};


export type QueryRootRoleAccessArgs = {
  distinct_on?: InputMaybe<Array<RoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoleAccessOrderBy>>;
  where?: InputMaybe<RoleAccessBoolExp>;
};


export type QueryRootRoleAccessAggregateArgs = {
  distinct_on?: InputMaybe<Array<RoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoleAccessOrderBy>>;
  where?: InputMaybe<RoleAccessBoolExp>;
};


export type QueryRootRoleAccessByPkArgs = {
  AccessType: AccessTypeEnum;
  ContributorType: ContributorTypeEnum;
  ObjectType: ParentTypeEnum;
  RoleKey: Scalars['String']['input'];
};


export type QueryRootTagArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


export type QueryRootTagAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


export type QueryRootTagAuditArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


export type QueryRootTagAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


export type QueryRootTagAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type QueryRootTagByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type QueryRootTagTypeArgs = {
  distinct_on?: InputMaybe<Array<TagTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeOrderBy>>;
  where?: InputMaybe<TagTypeBoolExp>;
};


export type QueryRootTagTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeOrderBy>>;
  where?: InputMaybe<TagTypeBoolExp>;
};


export type QueryRootTagTypeAuditArgs = {
  distinct_on?: InputMaybe<Array<TagTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeAuditOrderBy>>;
  where?: InputMaybe<TagTypeAuditBoolExp>;
};


export type QueryRootTagTypeAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeAuditOrderBy>>;
  where?: InputMaybe<TagTypeAuditBoolExp>;
};


export type QueryRootTagTypeAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type QueryRootTagTypeByPkArgs = {
  TagTypeId: Scalars['uuid']['input'];
};


export type QueryRootTagTypeGroupArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupOrderBy>>;
  where?: InputMaybe<TagTypeGroupBoolExp>;
};


export type QueryRootTagTypeGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupOrderBy>>;
  where?: InputMaybe<TagTypeGroupBoolExp>;
};


export type QueryRootTagTypeGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupAuditOrderBy>>;
  where?: InputMaybe<TagTypeGroupAuditBoolExp>;
};


export type QueryRootTagTypeGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupAuditOrderBy>>;
  where?: InputMaybe<TagTypeGroupAuditBoolExp>;
};


export type QueryRootTagTypeGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootTagTypeGroupByPkArgs = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type QueryRootTaxonomyArgs = {
  distinct_on?: InputMaybe<Array<TaxonomySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrderBy>>;
  where?: InputMaybe<TaxonomyBoolExp>;
};


export type QueryRootTaxonomyAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrderBy>>;
  where?: InputMaybe<TaxonomyBoolExp>;
};


export type QueryRootTaxonomyAuditArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyAuditOrderBy>>;
  where?: InputMaybe<TaxonomyAuditBoolExp>;
};


export type QueryRootTaxonomyAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyAuditOrderBy>>;
  where?: InputMaybe<TaxonomyAuditBoolExp>;
};


export type QueryRootTaxonomyAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootTaxonomyByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootTaxonomyOrgArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


export type QueryRootTaxonomyOrgAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


export type QueryRootTaxonomyOrgAuditArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgAuditOrderBy>>;
  where?: InputMaybe<TaxonomyOrgAuditBoolExp>;
};


export type QueryRootTaxonomyOrgAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgAuditOrderBy>>;
  where?: InputMaybe<TaxonomyOrgAuditBoolExp>;
};


export type QueryRootTaxonomyOrgAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootTaxonomyOrgByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootTestFrequencyArgs = {
  distinct_on?: InputMaybe<Array<TestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestFrequencyOrderBy>>;
  where?: InputMaybe<TestFrequencyBoolExp>;
};


export type QueryRootTestFrequencyAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestFrequencyOrderBy>>;
  where?: InputMaybe<TestFrequencyBoolExp>;
};


export type QueryRootTestFrequencyByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootTestResultArgs = {
  distinct_on?: InputMaybe<Array<TestResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultOrderBy>>;
  where?: InputMaybe<TestResultBoolExp>;
};


export type QueryRootTestResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultOrderBy>>;
  where?: InputMaybe<TestResultBoolExp>;
};


export type QueryRootTestResultAuditArgs = {
  distinct_on?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultAuditOrderBy>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};


export type QueryRootTestResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultAuditOrderBy>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};


export type QueryRootTestResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootTestResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootThirdPartyArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


export type QueryRootThirdPartyAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


export type QueryRootThirdPartyAuditArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyAuditOrderBy>>;
  where?: InputMaybe<ThirdPartyAuditBoolExp>;
};


export type QueryRootThirdPartyAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyAuditOrderBy>>;
  where?: InputMaybe<ThirdPartyAuditBoolExp>;
};


export type QueryRootThirdPartyAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootThirdPartyByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootThirdPartyResponseArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseBoolExp>;
};


export type QueryRootThirdPartyResponseAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseBoolExp>;
};


export type QueryRootThirdPartyResponseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootThirdPartyResponseStatusArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
};


export type QueryRootThirdPartyResponseStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
};


export type QueryRootThirdPartyResponseStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootThirdPartyStatusArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyStatusBoolExp>;
};


export type QueryRootThirdPartyStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyStatusBoolExp>;
};


export type QueryRootThirdPartyStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootThirdPartyTypeArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyTypeOrderBy>>;
  where?: InputMaybe<ThirdPartyTypeBoolExp>;
};


export type QueryRootThirdPartyTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyTypeOrderBy>>;
  where?: InputMaybe<ThirdPartyTypeBoolExp>;
};


export type QueryRootThirdPartyTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootUserArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type QueryRootUserGroupArgs = {
  distinct_on?: InputMaybe<Array<UserGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupOrderBy>>;
  where?: InputMaybe<UserGroupBoolExp>;
};


export type QueryRootUserGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupOrderBy>>;
  where?: InputMaybe<UserGroupBoolExp>;
};


export type QueryRootUserGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<UserGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupAuditOrderBy>>;
  where?: InputMaybe<UserGroupAuditBoolExp>;
};


export type QueryRootUserGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupAuditOrderBy>>;
  where?: InputMaybe<UserGroupAuditBoolExp>;
};


export type QueryRootUserGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type QueryRootUserGroupByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type QueryRootUserGroupUserArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


export type QueryRootUserGroupUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


export type QueryRootUserGroupUserAuditArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserAuditOrderBy>>;
  where?: InputMaybe<UserGroupUserAuditBoolExp>;
};


export type QueryRootUserGroupUserAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserAuditOrderBy>>;
  where?: InputMaybe<UserGroupUserAuditBoolExp>;
};


export type QueryRootUserGroupUserAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type QueryRootUserGroupUserByPkArgs = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type QueryRootUserRoleAccessArgs = {
  distinct_on?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleAccessOrderBy>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


export type QueryRootUserRoleAccessAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleAccessOrderBy>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


export type QueryRootUserSearchPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesBoolExp>;
};


export type QueryRootUserSearchPreferencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesBoolExp>;
};


export type QueryRootUserSearchPreferencesAuditArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesAuditOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
};


export type QueryRootUserSearchPreferencesAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesAuditOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
};


export type QueryRootUserSearchPreferencesAuditByPkArgs = {
  CreatedByUser: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};


export type QueryRootUserSearchPreferencesByPkArgs = {
  CreatedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type QueryRootUserStatusArgs = {
  distinct_on?: InputMaybe<Array<UserStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserStatusOrderBy>>;
  where?: InputMaybe<UserStatusBoolExp>;
};


export type QueryRootUserStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserStatusOrderBy>>;
  where?: InputMaybe<UserStatusBoolExp>;
};


export type QueryRootUserStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type QueryRootVersionStatusArgs = {
  distinct_on?: InputMaybe<Array<VersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionStatusOrderBy>>;
  where?: InputMaybe<VersionStatusBoolExp>;
};


export type QueryRootVersionStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<VersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionStatusOrderBy>>;
  where?: InputMaybe<VersionStatusBoolExp>;
};


export type QueryRootVersionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};

/** columns and relationships of "risksmart.questionnaire_invite" */
export type QuestionnaireInvite = {
  __typename?: 'questionnaire_invite';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  QuestionnaireTemplateVersionId: Scalars['uuid']['output'];
  ThirdPartyId: Scalars['uuid']['output'];
  UserEmail: Scalars['String']['output'];
  UserId?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent: ThirdPartyResponse;
  /** An object relationship */
  questionnaireTemplateVersion: QuestionnaireTemplateVersion;
  /** An object relationship */
  thirdParty: ThirdParty;
  /** An object relationship */
  user?: Maybe<AuthUser>;
};

/** aggregated selection of "risksmart.questionnaire_invite" */
export type QuestionnaireInviteAggregate = {
  __typename?: 'questionnaire_invite_aggregate';
  aggregate?: Maybe<QuestionnaireInviteAggregateFields>;
  nodes: Array<QuestionnaireInvite>;
};

export type QuestionnaireInviteAggregateBoolExp = {
  count?: InputMaybe<QuestionnaireInviteAggregateBoolExpCount>;
};

export type QuestionnaireInviteAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionnaireInviteBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.questionnaire_invite" */
export type QuestionnaireInviteAggregateFields = {
  __typename?: 'questionnaire_invite_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionnaireInviteMaxFields>;
  min?: Maybe<QuestionnaireInviteMinFields>;
};


/** aggregate fields of "risksmart.questionnaire_invite" */
export type QuestionnaireInviteAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionnaireInviteMaxOrderBy>;
  min?: InputMaybe<QuestionnaireInviteMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteArrRelInsertInput = {
  data: Array<QuestionnaireInviteInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireInviteOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_invite". All fields are combined with a logical 'AND'. */
export type QuestionnaireInviteBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  QuestionnaireTemplateVersionId?: InputMaybe<UuidComparisonExp>;
  ThirdPartyId?: InputMaybe<UuidComparisonExp>;
  UserEmail?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<QuestionnaireInviteBoolExp>>;
  _not?: InputMaybe<QuestionnaireInviteBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireInviteBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<ThirdPartyResponseBoolExp>;
  questionnaireTemplateVersion?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
  thirdParty?: InputMaybe<ThirdPartyBoolExp>;
  user?: InputMaybe<AuthUserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_invite" */
export enum QuestionnaireInviteConstraint {
  /** unique or primary key constraint on columns "Id" */
  QuestionnaireInvitePkey = 'questionnaire_invite_pkey'
}

/** input type for inserting data into table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
  UserEmail?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<ThirdPartyResponseObjRelInsertInput>;
  questionnaireTemplateVersion?: InputMaybe<QuestionnaireTemplateVersionObjRelInsertInput>;
  thirdParty?: InputMaybe<ThirdPartyObjRelInsertInput>;
  user?: InputMaybe<AuthUserObjRelInsertInput>;
};

/** aggregate max on columns */
export type QuestionnaireInviteMaxFields = {
  __typename?: 'questionnaire_invite_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  QuestionnaireTemplateVersionId?: Maybe<Scalars['uuid']['output']>;
  ThirdPartyId?: Maybe<Scalars['uuid']['output']>;
  UserEmail?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  QuestionnaireTemplateVersionId?: InputMaybe<OrderBy>;
  ThirdPartyId?: InputMaybe<OrderBy>;
  UserEmail?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type QuestionnaireInviteMinFields = {
  __typename?: 'questionnaire_invite_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  QuestionnaireTemplateVersionId?: Maybe<Scalars['uuid']['output']>;
  ThirdPartyId?: Maybe<Scalars['uuid']['output']>;
  UserEmail?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  QuestionnaireTemplateVersionId?: InputMaybe<OrderBy>;
  ThirdPartyId?: InputMaybe<OrderBy>;
  UserEmail?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteMutationResponse = {
  __typename?: 'questionnaire_invite_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireInvite>;
};

/** on_conflict condition type for table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteOnConflict = {
  constraint: QuestionnaireInviteConstraint;
  update_columns?: Array<QuestionnaireInviteUpdateColumn>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_invite". */
export type QuestionnaireInviteOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  QuestionnaireTemplateVersionId?: InputMaybe<OrderBy>;
  ThirdPartyId?: InputMaybe<OrderBy>;
  UserEmail?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<ThirdPartyResponseOrderBy>;
  questionnaireTemplateVersion?: InputMaybe<QuestionnaireTemplateVersionOrderBy>;
  thirdParty?: InputMaybe<ThirdPartyOrderBy>;
  user?: InputMaybe<AuthUserOrderBy>;
};

/** primary key columns input for table: risksmart.questionnaire_invite */
export type QuestionnaireInvitePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.questionnaire_invite" */
export enum QuestionnaireInviteSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  QuestionnaireTemplateVersionId = 'QuestionnaireTemplateVersionId',
  /** column name */
  ThirdPartyId = 'ThirdPartyId',
  /** column name */
  UserEmail = 'UserEmail',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.questionnaire_invite" */
export type QuestionnaireInviteSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
  UserEmail?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "questionnaire_invite" */
export type QuestionnaireInviteStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireInviteStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireInviteStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
  UserEmail?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_invite" */
export enum QuestionnaireInviteUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  QuestionnaireTemplateVersionId = 'QuestionnaireTemplateVersionId',
  /** column name */
  ThirdPartyId = 'ThirdPartyId',
  /** column name */
  UserEmail = 'UserEmail',
  /** column name */
  UserId = 'UserId'
}

export type QuestionnaireInviteUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireInviteSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionnaireInviteBoolExp;
};

/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplate = {
  __typename?: 'questionnaire_template';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Title: Scalars['String']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An array relationship */
  versions: Array<QuestionnaireTemplateVersion>;
  /** An aggregate relationship */
  versions_aggregate: QuestionnaireTemplateVersionAggregate;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplatePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplatePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateVersionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateVersionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};

/** aggregated selection of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateAggregate = {
  __typename?: 'questionnaire_template_aggregate';
  aggregate?: Maybe<QuestionnaireTemplateAggregateFields>;
  nodes: Array<QuestionnaireTemplate>;
};

/** aggregate fields of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateAggregateFields = {
  __typename?: 'questionnaire_template_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionnaireTemplateMaxFields>;
  min?: Maybe<QuestionnaireTemplateMinFields>;
};


/** aggregate fields of "risksmart.questionnaire_template" */
export type QuestionnaireTemplateAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireTemplateSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireTemplateAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template". All fields are combined with a logical 'AND'. */
export type QuestionnaireTemplateBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<QuestionnaireTemplateBoolExp>>;
  _not?: InputMaybe<QuestionnaireTemplateBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireTemplateBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  versions?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
  versions_aggregate?: InputMaybe<QuestionnaireTemplateVersionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template" */
export enum QuestionnaireTemplateConstraint {
  /** unique or primary key constraint on columns "Id" */
  QuestionnaireTemplatePkey = 'questionnaire_template_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type QuestionnaireTemplateDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type QuestionnaireTemplateDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type QuestionnaireTemplateDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.questionnaire_template" */
export type QuestionnaireTemplateInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  versions?: InputMaybe<QuestionnaireTemplateVersionArrRelInsertInput>;
};

/** aggregate max on columns */
export type QuestionnaireTemplateMaxFields = {
  __typename?: 'questionnaire_template_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type QuestionnaireTemplateMinFields = {
  __typename?: 'questionnaire_template_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.questionnaire_template" */
export type QuestionnaireTemplateMutationResponse = {
  __typename?: 'questionnaire_template_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireTemplate>;
};

/** input type for inserting object relation for remote table "risksmart.questionnaire_template" */
export type QuestionnaireTemplateObjRelInsertInput = {
  data: QuestionnaireTemplateInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireTemplateOnConflict>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template" */
export type QuestionnaireTemplateOnConflict = {
  constraint: QuestionnaireTemplateConstraint;
  update_columns?: Array<QuestionnaireTemplateUpdateColumn>;
  where?: InputMaybe<QuestionnaireTemplateBoolExp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template". */
export type QuestionnaireTemplateOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  versions_aggregate?: InputMaybe<QuestionnaireTemplateVersionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.questionnaire_template */
export type QuestionnaireTemplatePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireTemplatePrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.questionnaire_template" */
export enum QuestionnaireTemplateSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.questionnaire_template" */
export type QuestionnaireTemplateSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "questionnaire_template" */
export type QuestionnaireTemplateStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireTemplateStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireTemplateStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template" */
export enum QuestionnaireTemplateUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Title = 'Title'
}

export type QuestionnaireTemplateUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<QuestionnaireTemplateAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<QuestionnaireTemplateDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<QuestionnaireTemplateDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<QuestionnaireTemplateDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<QuestionnaireTemplatePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireTemplateSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionnaireTemplateBoolExp;
};

/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersion = {
  __typename?: 'questionnaire_template_version';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Schema: Scalars['jsonb']['output'];
  Status: QuestionnaireTemplateVersionStatusEnum;
  UISchema: Scalars['jsonb']['output'];
  Version: Scalars['String']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  invitations: Array<QuestionnaireInvite>;
  /** An aggregate relationship */
  invitations_aggregate: QuestionnaireInviteAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  parent?: Maybe<QuestionnaireTemplate>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionInvitationsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionInvitationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionAggregate = {
  __typename?: 'questionnaire_template_version_aggregate';
  aggregate?: Maybe<QuestionnaireTemplateVersionAggregateFields>;
  nodes: Array<QuestionnaireTemplateVersion>;
};

export type QuestionnaireTemplateVersionAggregateBoolExp = {
  count?: InputMaybe<QuestionnaireTemplateVersionAggregateBoolExpCount>;
};

export type QuestionnaireTemplateVersionAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionAggregateFields = {
  __typename?: 'questionnaire_template_version_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionnaireTemplateVersionMaxFields>;
  min?: Maybe<QuestionnaireTemplateVersionMinFields>;
};


/** aggregate fields of "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<QuestionnaireTemplateVersionMaxOrderBy>;
  min?: InputMaybe<QuestionnaireTemplateVersionMinOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireTemplateVersionAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionArrRelInsertInput = {
  data: Array<QuestionnaireTemplateVersionInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireTemplateVersionOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template_version". All fields are combined with a logical 'AND'. */
export type QuestionnaireTemplateVersionBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  Schema?: InputMaybe<JsonbComparisonExp>;
  Status?: InputMaybe<QuestionnaireTemplateVersionStatusEnumComparisonExp>;
  UISchema?: InputMaybe<JsonbComparisonExp>;
  Version?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<QuestionnaireTemplateVersionBoolExp>>;
  _not?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireTemplateVersionBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  invitations?: InputMaybe<QuestionnaireInviteBoolExp>;
  invitations_aggregate?: InputMaybe<QuestionnaireInviteAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  parent?: InputMaybe<QuestionnaireTemplateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template_version" */
export enum QuestionnaireTemplateVersionConstraint {
  /** unique or primary key constraint on columns "Id" */
  QuestionnaireTemplateVersionPkey = 'questionnaire_template_version_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type QuestionnaireTemplateVersionDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
  Schema?: InputMaybe<Array<Scalars['String']['input']>>;
  UISchema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type QuestionnaireTemplateVersionDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
  Schema?: InputMaybe<Scalars['Int']['input']>;
  UISchema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type QuestionnaireTemplateVersionDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['String']['input']>;
  UISchema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<QuestionnaireTemplateVersionStatusEnum>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  invitations?: InputMaybe<QuestionnaireInviteArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  parent?: InputMaybe<QuestionnaireTemplateObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type QuestionnaireTemplateVersionMaxFields = {
  __typename?: 'questionnaire_template_version_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type QuestionnaireTemplateVersionMinFields = {
  __typename?: 'questionnaire_template_version_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionMutationResponse = {
  __typename?: 'questionnaire_template_version_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireTemplateVersion>;
};

/** input type for inserting object relation for remote table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionObjRelInsertInput = {
  data: QuestionnaireTemplateVersionInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<QuestionnaireTemplateVersionOnConflict>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionOnConflict = {
  constraint: QuestionnaireTemplateVersionConstraint;
  update_columns?: Array<QuestionnaireTemplateVersionUpdateColumn>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template_version". */
export type QuestionnaireTemplateVersionOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Schema?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  UISchema?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  invitations_aggregate?: InputMaybe<QuestionnaireInviteAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  parent?: InputMaybe<QuestionnaireTemplateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.questionnaire_template_version */
export type QuestionnaireTemplateVersionPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type QuestionnaireTemplateVersionPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.questionnaire_template_version" */
export enum QuestionnaireTemplateVersionSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Status = 'Status',
  /** column name */
  UiSchema = 'UISchema',
  /** column name */
  Version = 'Version'
}

/** input type for updating data in table "risksmart.questionnaire_template_version" */
export type QuestionnaireTemplateVersionSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<QuestionnaireTemplateVersionStatusEnum>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatus = {
  __typename?: 'questionnaire_template_version_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  questionnaire_template_versions: Array<QuestionnaireTemplateVersion>;
  /** An aggregate relationship */
  questionnaire_template_versions_aggregate: QuestionnaireTemplateVersionAggregate;
};


/** columns and relationships of "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusQuestionnaireTemplateVersionsArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


/** columns and relationships of "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusQuestionnaireTemplateVersionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};

/** aggregated selection of "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusAggregate = {
  __typename?: 'questionnaire_template_version_status_aggregate';
  aggregate?: Maybe<QuestionnaireTemplateVersionStatusAggregateFields>;
  nodes: Array<QuestionnaireTemplateVersionStatus>;
};

/** aggregate fields of "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusAggregateFields = {
  __typename?: 'questionnaire_template_version_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<QuestionnaireTemplateVersionStatusMaxFields>;
  min?: Maybe<QuestionnaireTemplateVersionStatusMinFields>;
};


/** aggregate fields of "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<QuestionnaireTemplateVersionStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template_version_status". All fields are combined with a logical 'AND'. */
export type QuestionnaireTemplateVersionStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<QuestionnaireTemplateVersionStatusBoolExp>>;
  _not?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
  _or?: InputMaybe<Array<QuestionnaireTemplateVersionStatusBoolExp>>;
  questionnaire_template_versions?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
  questionnaire_template_versions_aggregate?: InputMaybe<QuestionnaireTemplateVersionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template_version_status" */
export enum QuestionnaireTemplateVersionStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  QuestionnaireTemplateVersionStatusPkey = 'questionnaire_template_version_status_pkey'
}

export enum QuestionnaireTemplateVersionStatusEnum {
  /** Archived */
  Archived = 'archived',
  /** Draft */
  Draft = 'draft',
  /** Published */
  Published = 'published'
}

/** Boolean expression to compare columns of type "questionnaire_template_version_status_enum". All fields are combined with logical 'AND'. */
export type QuestionnaireTemplateVersionStatusEnumComparisonExp = {
  _eq?: InputMaybe<QuestionnaireTemplateVersionStatusEnum>;
  _in?: InputMaybe<Array<QuestionnaireTemplateVersionStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<QuestionnaireTemplateVersionStatusEnum>;
  _nin?: InputMaybe<Array<QuestionnaireTemplateVersionStatusEnum>>;
};

/** input type for inserting data into table "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
  questionnaire_template_versions?: InputMaybe<QuestionnaireTemplateVersionArrRelInsertInput>;
};

/** aggregate max on columns */
export type QuestionnaireTemplateVersionStatusMaxFields = {
  __typename?: 'questionnaire_template_version_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type QuestionnaireTemplateVersionStatusMinFields = {
  __typename?: 'questionnaire_template_version_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusMutationResponse = {
  __typename?: 'questionnaire_template_version_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<QuestionnaireTemplateVersionStatus>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusOnConflict = {
  constraint: QuestionnaireTemplateVersionStatusConstraint;
  update_columns?: Array<QuestionnaireTemplateVersionStatusUpdateColumn>;
  where?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template_version_status". */
export type QuestionnaireTemplateVersionStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
  questionnaire_template_versions_aggregate?: InputMaybe<QuestionnaireTemplateVersionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.questionnaire_template_version_status */
export type QuestionnaireTemplateVersionStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.questionnaire_template_version_status" */
export enum QuestionnaireTemplateVersionStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "questionnaire_template_version_status" */
export type QuestionnaireTemplateVersionStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireTemplateVersionStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireTemplateVersionStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template_version_status" */
export enum QuestionnaireTemplateVersionStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type QuestionnaireTemplateVersionStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireTemplateVersionStatusSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionnaireTemplateVersionStatusBoolExp;
};

/** Streaming cursor of the table "questionnaire_template_version" */
export type QuestionnaireTemplateVersionStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: QuestionnaireTemplateVersionStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type QuestionnaireTemplateVersionStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<QuestionnaireTemplateVersionStatusEnum>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template_version" */
export enum QuestionnaireTemplateVersionUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Status = 'Status',
  /** column name */
  UiSchema = 'UISchema',
  /** column name */
  Version = 'Version'
}

export type QuestionnaireTemplateVersionUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<QuestionnaireTemplateVersionAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<QuestionnaireTemplateVersionDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<QuestionnaireTemplateVersionDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<QuestionnaireTemplateVersionDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<QuestionnaireTemplateVersionPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<QuestionnaireTemplateVersionSetInput>;
  /** filter the rows which have to be updated */
  where: QuestionnaireTemplateVersionBoolExp;
};

/** columns and relationships of "risksmart.relation_file" */
export type RelationFile = {
  __typename?: 'relation_file';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: ParentTypeEnum;
  /** An object relationship */
  acceptance?: Maybe<Acceptance>;
  /** An object relationship */
  action?: Maybe<Action>;
  /** An object relationship */
  appetite?: Maybe<Appetite>;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  document?: Maybe<Document>;
  /** An object relationship */
  file?: Maybe<File>;
  /** An object relationship */
  issue?: Maybe<Issue>;
  /** An object relationship */
  issueUpdate?: Maybe<IssueUpdate>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parentType: ParentType;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  riskAssessmentResult?: Maybe<RiskAssessmentResult>;
  /** An object relationship */
  testResult?: Maybe<TestResult>;
};


/** columns and relationships of "risksmart.relation_file" */
export type RelationFileMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.relation_file" */
export type RelationFilePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.relation_file" */
export type RelationFilePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.relation_file" */
export type RelationFileAggregate = {
  __typename?: 'relation_file_aggregate';
  aggregate?: Maybe<RelationFileAggregateFields>;
  nodes: Array<RelationFile>;
};

export type RelationFileAggregateBoolExp = {
  count?: InputMaybe<RelationFileAggregateBoolExpCount>;
};

export type RelationFileAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RelationFileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<RelationFileBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.relation_file" */
export type RelationFileAggregateFields = {
  __typename?: 'relation_file_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RelationFileMaxFields>;
  min?: Maybe<RelationFileMinFields>;
};


/** aggregate fields of "risksmart.relation_file" */
export type RelationFileAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RelationFileSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.relation_file" */
export type RelationFileAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RelationFileMaxOrderBy>;
  min?: InputMaybe<RelationFileMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.relation_file" */
export type RelationFileArrRelInsertInput = {
  data: Array<RelationFileInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RelationFileOnConflict>;
};

/** columns and relationships of "risksmart.relation_file_audit" */
export type RelationFileAudit = {
  __typename?: 'relation_file_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  ParentType: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.relation_file_audit" */
export type RelationFileAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.relation_file_audit" */
export type RelationFileAuditAggregate = {
  __typename?: 'relation_file_audit_aggregate';
  aggregate?: Maybe<RelationFileAuditAggregateFields>;
  nodes: Array<RelationFileAudit>;
};

/** aggregate fields of "risksmart.relation_file_audit" */
export type RelationFileAuditAggregateFields = {
  __typename?: 'relation_file_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RelationFileAuditMaxFields>;
  min?: Maybe<RelationFileAuditMinFields>;
};


/** aggregate fields of "risksmart.relation_file_audit" */
export type RelationFileAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RelationFileAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.relation_file_audit". All fields are combined with a logical 'AND'. */
export type RelationFileAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FileId?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RelationFileAuditBoolExp>>;
  _not?: InputMaybe<RelationFileAuditBoolExp>;
  _or?: InputMaybe<Array<RelationFileAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.relation_file_audit" */
export enum RelationFileAuditConstraint {
  /** unique or primary key constraint on columns "FileId", "ParentId", "ModifiedAtTimestamp" */
  RelationFileAuditPkey = 'relation_file_audit_pkey'
}

/** input type for inserting data into table "risksmart.relation_file_audit" */
export type RelationFileAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RelationFileAuditMaxFields = {
  __typename?: 'relation_file_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RelationFileAuditMinFields = {
  __typename?: 'relation_file_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  ParentType?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.relation_file_audit" */
export type RelationFileAuditMutationResponse = {
  __typename?: 'relation_file_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RelationFileAudit>;
};

/** on_conflict condition type for table "risksmart.relation_file_audit" */
export type RelationFileAuditOnConflict = {
  constraint: RelationFileAuditConstraint;
  update_columns?: Array<RelationFileAuditUpdateColumn>;
  where?: InputMaybe<RelationFileAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.relation_file_audit". */
export type RelationFileAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.relation_file_audit */
export type RelationFileAuditPkColumnsInput = {
  FileId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.relation_file_audit" */
export enum RelationFileAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.relation_file_audit" */
export type RelationFileAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "relation_file_audit" */
export type RelationFileAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RelationFileAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RelationFileAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.relation_file_audit" */
export enum RelationFileAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

export type RelationFileAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RelationFileAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RelationFileAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.relation_file". All fields are combined with a logical 'AND'. */
export type RelationFileBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FileId?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  ParentType?: InputMaybe<ParentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<RelationFileBoolExp>>;
  _not?: InputMaybe<RelationFileBoolExp>;
  _or?: InputMaybe<Array<RelationFileBoolExp>>;
  acceptance?: InputMaybe<AcceptanceBoolExp>;
  action?: InputMaybe<ActionBoolExp>;
  appetite?: InputMaybe<AppetiteBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  document?: InputMaybe<DocumentBoolExp>;
  file?: InputMaybe<FileBoolExp>;
  issue?: InputMaybe<IssueBoolExp>;
  issueUpdate?: InputMaybe<IssueUpdateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parentType?: InputMaybe<ParentTypeBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  riskAssessmentResult?: InputMaybe<RiskAssessmentResultBoolExp>;
  testResult?: InputMaybe<TestResultBoolExp>;
};

/** unique or primary key constraints on table "risksmart.relation_file" */
export enum RelationFileConstraint {
  /** unique or primary key constraint on columns "FileId", "ParentId" */
  RelationFilePkey = 'relation_file_pkey'
}

/** input type for inserting data into table "risksmart.relation_file" */
export type RelationFileInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
  acceptance?: InputMaybe<AcceptanceObjRelInsertInput>;
  action?: InputMaybe<ActionObjRelInsertInput>;
  appetite?: InputMaybe<AppetiteObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  document?: InputMaybe<DocumentObjRelInsertInput>;
  file?: InputMaybe<FileObjRelInsertInput>;
  issue?: InputMaybe<IssueObjRelInsertInput>;
  issueUpdate?: InputMaybe<IssueUpdateObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parentType?: InputMaybe<ParentTypeObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  riskAssessmentResult?: InputMaybe<RiskAssessmentResultObjRelInsertInput>;
  testResult?: InputMaybe<TestResultObjRelInsertInput>;
};

/** aggregate max on columns */
export type RelationFileMaxFields = {
  __typename?: 'relation_file_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.relation_file" */
export type RelationFileMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RelationFileMinFields = {
  __typename?: 'relation_file_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  FileId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.relation_file" */
export type RelationFileMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.relation_file" */
export type RelationFileMutationResponse = {
  __typename?: 'relation_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RelationFile>;
};

/** on_conflict condition type for table "risksmart.relation_file" */
export type RelationFileOnConflict = {
  constraint: RelationFileConstraint;
  update_columns?: Array<RelationFileUpdateColumn>;
  where?: InputMaybe<RelationFileBoolExp>;
};

/** Ordering options when selecting data from "risksmart.relation_file". */
export type RelationFileOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FileId?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  ParentType?: InputMaybe<OrderBy>;
  acceptance?: InputMaybe<AcceptanceOrderBy>;
  action?: InputMaybe<ActionOrderBy>;
  appetite?: InputMaybe<AppetiteOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  document?: InputMaybe<DocumentOrderBy>;
  file?: InputMaybe<FileOrderBy>;
  issue?: InputMaybe<IssueOrderBy>;
  issueUpdate?: InputMaybe<IssueUpdateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parentType?: InputMaybe<ParentTypeOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  riskAssessmentResult?: InputMaybe<RiskAssessmentResultOrderBy>;
  testResult?: InputMaybe<TestResultOrderBy>;
};

/** primary key columns input for table: risksmart.relation_file */
export type RelationFilePkColumnsInput = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.relation_file" */
export enum RelationFileSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

/** input type for updating data in table "risksmart.relation_file" */
export type RelationFileSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** Streaming cursor of the table "relation_file" */
export type RelationFileStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RelationFileStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RelationFileStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FileId?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  ParentType?: InputMaybe<ParentTypeEnum>;
};

/** update columns of table "risksmart.relation_file" */
export enum RelationFileUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FileId = 'FileId',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  ParentType = 'ParentType'
}

export type RelationFileUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RelationFileSetInput>;
  /** filter the rows which have to be updated */
  where: RelationFileBoolExp;
};

/** columns and relationships of "risksmart.risk" */
export type Risk = {
  __typename?: 'risk';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<RiskStatusTypeEnum>;
  TestFrequency?: Maybe<TestFrequencyEnum>;
  Tier: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  Treatment?: Maybe<RiskTreatmentTypeEnum>;
  /** An array relationship */
  acceptances: Array<AcceptanceParent>;
  /** An aggregate relationship */
  acceptances_aggregate: AcceptanceParentAggregate;
  /** An array relationship */
  actions: Array<ActionParent>;
  /** An aggregate relationship */
  actions_aggregate: ActionParentAggregate;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  appetites: Array<AppetiteParent>;
  /** An aggregate relationship */
  appetites_aggregate: AppetiteParentAggregate;
  /** An array relationship */
  assessmentResults: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentResults_aggregate: AssessmentResultParentAggregate;
  /** An array relationship */
  childRisks: Array<Risk>;
  /** An aggregate relationship */
  childRisks_aggregate: RiskAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An array relationship */
  controls: Array<ControlParent>;
  /** An aggregate relationship */
  controls_aggregate: ControlParentAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An array relationship */
  impactRatings: Array<ImpactRating>;
  /** An aggregate relationship */
  impactRatings_aggregate: ImpactRatingAggregate;
  /** An array relationship */
  indicators: Array<IndicatorParent>;
  /** An aggregate relationship */
  indicators_aggregate: IndicatorParentAggregate;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An object relationship */
  parent?: Maybe<Risk>;
  /** An object relationship */
  parentNode?: Maybe<Node>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  riskScore?: Maybe<RiskScore>;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
  /** An object relationship */
  testFrequency?: Maybe<TestFrequency>;
  /** An object relationship */
  treatment?: Maybe<RiskTreatmentType>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAcceptancesArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAcceptancesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskActionsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskActionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAppetitesArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAppetitesAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAssessmentResultsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskAssessmentResultsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskChildRisksArgs = {
  distinct_on?: InputMaybe<Array<RiskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskOrderBy>>;
  where?: InputMaybe<RiskBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskChildRisksAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskOrderBy>>;
  where?: InputMaybe<RiskBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskControlsArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskControlsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskImpactRatingsArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskImpactRatingsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskIndicatorsArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskIndicatorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.risk" */
export type RiskTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** aggregated selection of "risksmart.risk" */
export type RiskAggregate = {
  __typename?: 'risk_aggregate';
  aggregate?: Maybe<RiskAggregateFields>;
  nodes: Array<Risk>;
};

export type RiskAggregateBoolExp = {
  count?: InputMaybe<RiskAggregateBoolExpCount>;
};

export type RiskAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RiskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<RiskBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.risk" */
export type RiskAggregateFields = {
  __typename?: 'risk_aggregate_fields';
  avg?: Maybe<RiskAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RiskMaxFields>;
  min?: Maybe<RiskMinFields>;
  stddev?: Maybe<RiskStddevFields>;
  stddev_pop?: Maybe<RiskStddevPopFields>;
  stddev_samp?: Maybe<RiskStddevSampFields>;
  sum?: Maybe<RiskSumFields>;
  var_pop?: Maybe<RiskVarPopFields>;
  var_samp?: Maybe<RiskVarSampFields>;
  variance?: Maybe<RiskVarianceFields>;
};


/** aggregate fields of "risksmart.risk" */
export type RiskAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.risk" */
export type RiskAggregateOrderBy = {
  avg?: InputMaybe<RiskAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RiskMaxOrderBy>;
  min?: InputMaybe<RiskMinOrderBy>;
  stddev?: InputMaybe<RiskStddevOrderBy>;
  stddev_pop?: InputMaybe<RiskStddevPopOrderBy>;
  stddev_samp?: InputMaybe<RiskStddevSampOrderBy>;
  sum?: InputMaybe<RiskSumOrderBy>;
  var_pop?: InputMaybe<RiskVarPopOrderBy>;
  var_samp?: InputMaybe<RiskVarSampOrderBy>;
  variance?: InputMaybe<RiskVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RiskAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.risk" */
export type RiskArrRelInsertInput = {
  data: Array<RiskInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RiskOnConflict>;
};

/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResult = {
  __typename?: 'risk_assessment_result';
  ControlType: RiskAssessmentResultControlTypeEnum;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  parents: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  parents_aggregate: AssessmentResultParentAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultParentsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultAggregate = {
  __typename?: 'risk_assessment_result_aggregate';
  aggregate?: Maybe<RiskAssessmentResultAggregateFields>;
  nodes: Array<RiskAssessmentResult>;
};

/** aggregate fields of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultAggregateFields = {
  __typename?: 'risk_assessment_result_aggregate_fields';
  avg?: Maybe<RiskAssessmentResultAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RiskAssessmentResultMaxFields>;
  min?: Maybe<RiskAssessmentResultMinFields>;
  stddev?: Maybe<RiskAssessmentResultStddevFields>;
  stddev_pop?: Maybe<RiskAssessmentResultStddevPopFields>;
  stddev_samp?: Maybe<RiskAssessmentResultStddevSampFields>;
  sum?: Maybe<RiskAssessmentResultSumFields>;
  var_pop?: Maybe<RiskAssessmentResultVarPopFields>;
  var_samp?: Maybe<RiskAssessmentResultVarSampFields>;
  variance?: Maybe<RiskAssessmentResultVarianceFields>;
};


/** aggregate fields of "risksmart.risk_assessment_result" */
export type RiskAssessmentResultAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskAssessmentResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RiskAssessmentResultAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAudit = {
  __typename?: 'risk_assessment_result_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ControlType: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditAggregate = {
  __typename?: 'risk_assessment_result_audit_aggregate';
  aggregate?: Maybe<RiskAssessmentResultAuditAggregateFields>;
  nodes: Array<RiskAssessmentResultAudit>;
};

/** aggregate fields of "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditAggregateFields = {
  __typename?: 'risk_assessment_result_audit_aggregate_fields';
  avg?: Maybe<RiskAssessmentResultAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RiskAssessmentResultAuditMaxFields>;
  min?: Maybe<RiskAssessmentResultAuditMinFields>;
  stddev?: Maybe<RiskAssessmentResultAuditStddevFields>;
  stddev_pop?: Maybe<RiskAssessmentResultAuditStddevPopFields>;
  stddev_samp?: Maybe<RiskAssessmentResultAuditStddevSampFields>;
  sum?: Maybe<RiskAssessmentResultAuditSumFields>;
  var_pop?: Maybe<RiskAssessmentResultAuditVarPopFields>;
  var_samp?: Maybe<RiskAssessmentResultAuditVarSampFields>;
  variance?: Maybe<RiskAssessmentResultAuditVarianceFields>;
};


/** aggregate fields of "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskAssessmentResultAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RiskAssessmentResultAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type RiskAssessmentResultAuditAvgFields = {
  __typename?: 'risk_assessment_result_audit_avg_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_assessment_result_audit". All fields are combined with a logical 'AND'. */
export type RiskAssessmentResultAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ControlType?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Impact?: InputMaybe<IntComparisonExp>;
  Likelihood?: InputMaybe<IntComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<RiskAssessmentResultAuditBoolExp>>;
  _not?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
  _or?: InputMaybe<Array<RiskAssessmentResultAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.risk_assessment_result_audit" */
export enum RiskAssessmentResultAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  RiskAssessmentResultAuditPkey = 'risk_assessment_result_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RiskAssessmentResultAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RiskAssessmentResultAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RiskAssessmentResultAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditIncInput = {
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ControlType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type RiskAssessmentResultAuditMaxFields = {
  __typename?: 'risk_assessment_result_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ControlType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type RiskAssessmentResultAuditMinFields = {
  __typename?: 'risk_assessment_result_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ControlType?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditMutationResponse = {
  __typename?: 'risk_assessment_result_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskAssessmentResultAudit>;
};

/** on_conflict condition type for table "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditOnConflict = {
  constraint: RiskAssessmentResultAuditConstraint;
  update_columns?: Array<RiskAssessmentResultAuditUpdateColumn>;
  where?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_assessment_result_audit". */
export type RiskAssessmentResultAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ControlType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Impact?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.risk_assessment_result_audit */
export type RiskAssessmentResultAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RiskAssessmentResultAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.risk_assessment_result_audit" */
export enum RiskAssessmentResultAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ControlType = 'ControlType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  Impact = 'Impact',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.risk_assessment_result_audit" */
export type RiskAssessmentResultAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ControlType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type RiskAssessmentResultAuditStddevFields = {
  __typename?: 'risk_assessment_result_audit_stddev_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RiskAssessmentResultAuditStddevPopFields = {
  __typename?: 'risk_assessment_result_audit_stddev_pop_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RiskAssessmentResultAuditStddevSampFields = {
  __typename?: 'risk_assessment_result_audit_stddev_samp_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_assessment_result_audit" */
export type RiskAssessmentResultAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskAssessmentResultAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskAssessmentResultAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ControlType?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type RiskAssessmentResultAuditSumFields = {
  __typename?: 'risk_assessment_result_audit_sum_fields';
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.risk_assessment_result_audit" */
export enum RiskAssessmentResultAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ControlType = 'ControlType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  Impact = 'Impact',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

export type RiskAssessmentResultAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RiskAssessmentResultAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RiskAssessmentResultAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RiskAssessmentResultAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RiskAssessmentResultAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RiskAssessmentResultAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RiskAssessmentResultAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskAssessmentResultAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RiskAssessmentResultAuditBoolExp;
};

/** aggregate var_pop on columns */
export type RiskAssessmentResultAuditVarPopFields = {
  __typename?: 'risk_assessment_result_audit_var_pop_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RiskAssessmentResultAuditVarSampFields = {
  __typename?: 'risk_assessment_result_audit_var_samp_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RiskAssessmentResultAuditVarianceFields = {
  __typename?: 'risk_assessment_result_audit_variance_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type RiskAssessmentResultAvgFields = {
  __typename?: 'risk_assessment_result_avg_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_assessment_result". All fields are combined with a logical 'AND'. */
export type RiskAssessmentResultBoolExp = {
  ControlType?: InputMaybe<RiskAssessmentResultControlTypeEnumComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Impact?: InputMaybe<IntComparisonExp>;
  Likelihood?: InputMaybe<IntComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  Rationale?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  _and?: InputMaybe<Array<RiskAssessmentResultBoolExp>>;
  _not?: InputMaybe<RiskAssessmentResultBoolExp>;
  _or?: InputMaybe<Array<RiskAssessmentResultBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parents?: InputMaybe<AssessmentResultParentBoolExp>;
  parents_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.risk_assessment_result" */
export enum RiskAssessmentResultConstraint {
  /** unique or primary key constraint on columns "Id" */
  RiskAssessmentResultPkey = 'risk_assessment_result_pkey'
}

/** columns and relationships of "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlType = {
  __typename?: 'risk_assessment_result_control_type';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeAggregate = {
  __typename?: 'risk_assessment_result_control_type_aggregate';
  aggregate?: Maybe<RiskAssessmentResultControlTypeAggregateFields>;
  nodes: Array<RiskAssessmentResultControlType>;
};

/** aggregate fields of "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeAggregateFields = {
  __typename?: 'risk_assessment_result_control_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RiskAssessmentResultControlTypeMaxFields>;
  min?: Maybe<RiskAssessmentResultControlTypeMinFields>;
};


/** aggregate fields of "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskAssessmentResultControlTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_assessment_result_control_type". All fields are combined with a logical 'AND'. */
export type RiskAssessmentResultControlTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RiskAssessmentResultControlTypeBoolExp>>;
  _not?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
  _or?: InputMaybe<Array<RiskAssessmentResultControlTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.risk_assessment_result_control_type" */
export enum RiskAssessmentResultControlTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  RiskAssessmentResultControlTypePkey = 'risk_assessment_result_control_type_pkey'
}

export enum RiskAssessmentResultControlTypeEnum {
  /** Controlled */
  Controlled = 'Controlled',
  /** Uncontrolled */
  Uncontrolled = 'Uncontrolled'
}

/** Boolean expression to compare columns of type "risk_assessment_result_control_type_enum". All fields are combined with logical 'AND'. */
export type RiskAssessmentResultControlTypeEnumComparisonExp = {
  _eq?: InputMaybe<RiskAssessmentResultControlTypeEnum>;
  _in?: InputMaybe<Array<RiskAssessmentResultControlTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<RiskAssessmentResultControlTypeEnum>;
  _nin?: InputMaybe<Array<RiskAssessmentResultControlTypeEnum>>;
};

/** input type for inserting data into table "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RiskAssessmentResultControlTypeMaxFields = {
  __typename?: 'risk_assessment_result_control_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RiskAssessmentResultControlTypeMinFields = {
  __typename?: 'risk_assessment_result_control_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeMutationResponse = {
  __typename?: 'risk_assessment_result_control_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskAssessmentResultControlType>;
};

/** on_conflict condition type for table "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeOnConflict = {
  constraint: RiskAssessmentResultControlTypeConstraint;
  update_columns?: Array<RiskAssessmentResultControlTypeUpdateColumn>;
  where?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_assessment_result_control_type". */
export type RiskAssessmentResultControlTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.risk_assessment_result_control_type */
export type RiskAssessmentResultControlTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.risk_assessment_result_control_type" */
export enum RiskAssessmentResultControlTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_assessment_result_control_type" */
export type RiskAssessmentResultControlTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskAssessmentResultControlTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskAssessmentResultControlTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.risk_assessment_result_control_type" */
export enum RiskAssessmentResultControlTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type RiskAssessmentResultControlTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskAssessmentResultControlTypeSetInput>;
  /** filter the rows which have to be updated */
  where: RiskAssessmentResultControlTypeBoolExp;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RiskAssessmentResultDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RiskAssessmentResultDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RiskAssessmentResultDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.risk_assessment_result" */
export type RiskAssessmentResultIncInput = {
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.risk_assessment_result" */
export type RiskAssessmentResultInsertInput = {
  ControlType?: InputMaybe<RiskAssessmentResultControlTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parents?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
};

/** aggregate max on columns */
export type RiskAssessmentResultMaxFields = {
  __typename?: 'risk_assessment_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type RiskAssessmentResultMinFields = {
  __typename?: 'risk_assessment_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  Rationale?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "risksmart.risk_assessment_result" */
export type RiskAssessmentResultMutationResponse = {
  __typename?: 'risk_assessment_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskAssessmentResult>;
};

/** input type for inserting object relation for remote table "risksmart.risk_assessment_result" */
export type RiskAssessmentResultObjRelInsertInput = {
  data: RiskAssessmentResultInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RiskAssessmentResultOnConflict>;
};

/** on_conflict condition type for table "risksmart.risk_assessment_result" */
export type RiskAssessmentResultOnConflict = {
  constraint: RiskAssessmentResultConstraint;
  update_columns?: Array<RiskAssessmentResultUpdateColumn>;
  where?: InputMaybe<RiskAssessmentResultBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_assessment_result". */
export type RiskAssessmentResultOrderBy = {
  ControlType?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Impact?: InputMaybe<OrderBy>;
  Likelihood?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  Rationale?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parents_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.risk_assessment_result */
export type RiskAssessmentResultPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RiskAssessmentResultPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.risk_assessment_result" */
export enum RiskAssessmentResultSelectColumn {
  /** column name */
  ControlType = 'ControlType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  Impact = 'Impact',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

/** input type for updating data in table "risksmart.risk_assessment_result" */
export type RiskAssessmentResultSetInput = {
  ControlType?: InputMaybe<RiskAssessmentResultControlTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type RiskAssessmentResultStddevFields = {
  __typename?: 'risk_assessment_result_stddev_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RiskAssessmentResultStddevPopFields = {
  __typename?: 'risk_assessment_result_stddev_pop_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RiskAssessmentResultStddevSampFields = {
  __typename?: 'risk_assessment_result_stddev_samp_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_assessment_result" */
export type RiskAssessmentResultStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskAssessmentResultStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskAssessmentResultStreamCursorValueInput = {
  ControlType?: InputMaybe<RiskAssessmentResultControlTypeEnum>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Impact?: InputMaybe<Scalars['Int']['input']>;
  Likelihood?: InputMaybe<Scalars['Int']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  Rationale?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type RiskAssessmentResultSumFields = {
  __typename?: 'risk_assessment_result_sum_fields';
  Impact?: Maybe<Scalars['Int']['output']>;
  Likelihood?: Maybe<Scalars['Int']['output']>;
  Rating?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.risk_assessment_result" */
export enum RiskAssessmentResultUpdateColumn {
  /** column name */
  ControlType = 'ControlType',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  Impact = 'Impact',
  /** column name */
  Likelihood = 'Likelihood',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Rating = 'Rating',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  Rationale = 'Rationale',
  /** column name */
  TestDate = 'TestDate'
}

export type RiskAssessmentResultUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RiskAssessmentResultAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RiskAssessmentResultDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RiskAssessmentResultDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RiskAssessmentResultDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RiskAssessmentResultIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RiskAssessmentResultPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskAssessmentResultSetInput>;
  /** filter the rows which have to be updated */
  where: RiskAssessmentResultBoolExp;
};

/** aggregate var_pop on columns */
export type RiskAssessmentResultVarPopFields = {
  __typename?: 'risk_assessment_result_var_pop_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RiskAssessmentResultVarSampFields = {
  __typename?: 'risk_assessment_result_var_samp_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RiskAssessmentResultVarianceFields = {
  __typename?: 'risk_assessment_result_variance_fields';
  Impact?: Maybe<Scalars['Float']['output']>;
  Likelihood?: Maybe<Scalars['Float']['output']>;
  Rating?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.risk_audit" */
export type RiskAudit = {
  __typename?: 'risk_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Tier: Scalars['Int']['output'];
  Title: Scalars['String']['output'];
  Treatment?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  acceptanceAudits: Array<AcceptanceParentAudit>;
  /** An aggregate relationship */
  acceptanceAudits_aggregate: AcceptanceParentAuditAggregate;
  /** An array relationship */
  actionAudits: Array<ActionParentAudit>;
  /** An aggregate relationship */
  actionAudits_aggregate: ActionParentAuditAggregate;
  /** An array relationship */
  appetiteAudits: Array<AppetiteParentAudit>;
  /** An aggregate relationship */
  appetiteAudits_aggregate: AppetiteParentAuditAggregate;
  /** An array relationship */
  assessmentResultAudits: Array<AssessmentResultParentAudit>;
  /** An aggregate relationship */
  assessmentResultAudits_aggregate: AssessmentResultParentAuditAggregate;
  /** An array relationship */
  contributorAudits: Array<ContributorAudit>;
  /** An aggregate relationship */
  contributorAudits_aggregate: ContributorAuditAggregate;
  /** An array relationship */
  contributorGroupAudits: Array<ContributorGroupAudit>;
  /** An aggregate relationship */
  contributorGroupAudits_aggregate: ContributorGroupAuditAggregate;
  /** An array relationship */
  controlAudits: Array<ControlParentAudit>;
  /** An aggregate relationship */
  controlAudits_aggregate: ControlParentAuditAggregate;
  /** An array relationship */
  departmentAudits: Array<DepartmentAudit>;
  /** An aggregate relationship */
  departmentAudits_aggregate: DepartmentAuditAggregate;
  /** An array relationship */
  impactRatingAudits: Array<ImpactRatingAudit>;
  /** An aggregate relationship */
  impactRatingAudits_aggregate: ImpactRatingAuditAggregate;
  /** An array relationship */
  indicatorAudits: Array<RisksmartIndicatorParentAudit>;
  /** An aggregate relationship */
  indicatorAudits_aggregate: RisksmartIndicatorParentAuditAggregate;
  /** An array relationship */
  ownerAudits: Array<OwnerAudit>;
  /** An aggregate relationship */
  ownerAudits_aggregate: OwnerAuditAggregate;
  /** An array relationship */
  ownerGroupAudits: Array<OwnerGroupAudit>;
  /** An aggregate relationship */
  ownerGroupAudits_aggregate: OwnerGroupAuditAggregate;
  /** An array relationship */
  tagAudits: Array<TagAudit>;
  /** An aggregate relationship */
  tagAudits_aggregate: TagAuditAggregate;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditAcceptanceAuditsArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentAuditOrderBy>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditAcceptanceAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentAuditOrderBy>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditActionAuditsArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditActionAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditAppetiteAuditsArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentAuditOrderBy>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditAppetiteAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentAuditOrderBy>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditAssessmentResultAuditsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentAuditOrderBy>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditAssessmentResultAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentAuditOrderBy>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditContributorAuditsArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditContributorAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditContributorGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditContributorGroupAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditControlAuditsArgs = {
  distinct_on?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentAuditOrderBy>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditControlAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentAuditOrderBy>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditDepartmentAuditsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditDepartmentAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditImpactRatingAuditsArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingAuditOrderBy>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditImpactRatingAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingAuditOrderBy>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditIndicatorAuditsArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditIndicatorAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditOwnerAuditsArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditOwnerAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditOwnerGroupAuditsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditOwnerGroupAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditTagAuditsArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


/** columns and relationships of "risksmart.risk_audit" */
export type RiskAuditTagAuditsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};

/** aggregated selection of "risksmart.risk_audit" */
export type RiskAuditAggregate = {
  __typename?: 'risk_audit_aggregate';
  aggregate?: Maybe<RiskAuditAggregateFields>;
  nodes: Array<RiskAudit>;
};

/** aggregate fields of "risksmart.risk_audit" */
export type RiskAuditAggregateFields = {
  __typename?: 'risk_audit_aggregate_fields';
  avg?: Maybe<RiskAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RiskAuditMaxFields>;
  min?: Maybe<RiskAuditMinFields>;
  stddev?: Maybe<RiskAuditStddevFields>;
  stddev_pop?: Maybe<RiskAuditStddevPopFields>;
  stddev_samp?: Maybe<RiskAuditStddevSampFields>;
  sum?: Maybe<RiskAuditSumFields>;
  var_pop?: Maybe<RiskAuditVarPopFields>;
  var_samp?: Maybe<RiskAuditVarSampFields>;
  variance?: Maybe<RiskAuditVarianceFields>;
};


/** aggregate fields of "risksmart.risk_audit" */
export type RiskAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RiskAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type RiskAuditAvgFields = {
  __typename?: 'risk_audit_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_audit". All fields are combined with a logical 'AND'. */
export type RiskAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentRiskId?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  TestFrequency?: InputMaybe<StringComparisonExp>;
  Tier?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Treatment?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RiskAuditBoolExp>>;
  _not?: InputMaybe<RiskAuditBoolExp>;
  _or?: InputMaybe<Array<RiskAuditBoolExp>>;
  acceptanceAudits?: InputMaybe<AcceptanceParentAuditBoolExp>;
  acceptanceAudits_aggregate?: InputMaybe<AcceptanceParentAuditAggregateBoolExp>;
  actionAudits?: InputMaybe<ActionParentAuditBoolExp>;
  actionAudits_aggregate?: InputMaybe<ActionParentAuditAggregateBoolExp>;
  appetiteAudits?: InputMaybe<AppetiteParentAuditBoolExp>;
  appetiteAudits_aggregate?: InputMaybe<AppetiteParentAuditAggregateBoolExp>;
  assessmentResultAudits?: InputMaybe<AssessmentResultParentAuditBoolExp>;
  assessmentResultAudits_aggregate?: InputMaybe<AssessmentResultParentAuditAggregateBoolExp>;
  contributorAudits?: InputMaybe<ContributorAuditBoolExp>;
  contributorAudits_aggregate?: InputMaybe<ContributorAuditAggregateBoolExp>;
  contributorGroupAudits?: InputMaybe<ContributorGroupAuditBoolExp>;
  contributorGroupAudits_aggregate?: InputMaybe<ContributorGroupAuditAggregateBoolExp>;
  controlAudits?: InputMaybe<ControlParentAuditBoolExp>;
  controlAudits_aggregate?: InputMaybe<ControlParentAuditAggregateBoolExp>;
  departmentAudits?: InputMaybe<DepartmentAuditBoolExp>;
  departmentAudits_aggregate?: InputMaybe<DepartmentAuditAggregateBoolExp>;
  impactRatingAudits?: InputMaybe<ImpactRatingAuditBoolExp>;
  impactRatingAudits_aggregate?: InputMaybe<ImpactRatingAuditAggregateBoolExp>;
  indicatorAudits?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
  indicatorAudits_aggregate?: InputMaybe<RisksmartIndicatorParentAuditAggregateBoolExp>;
  ownerAudits?: InputMaybe<OwnerAuditBoolExp>;
  ownerAudits_aggregate?: InputMaybe<OwnerAuditAggregateBoolExp>;
  ownerGroupAudits?: InputMaybe<OwnerGroupAuditBoolExp>;
  ownerGroupAudits_aggregate?: InputMaybe<OwnerGroupAuditAggregateBoolExp>;
  tagAudits?: InputMaybe<TagAuditBoolExp>;
  tagAudits_aggregate?: InputMaybe<TagAuditAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.risk_audit" */
export enum RiskAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  RiskAuditPkey = 'risk_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RiskAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RiskAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RiskAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.risk_audit" */
export type RiskAuditIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.risk_audit" */
export type RiskAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<Scalars['String']['input']>;
  acceptanceAudits?: InputMaybe<AcceptanceParentAuditArrRelInsertInput>;
  actionAudits?: InputMaybe<ActionParentAuditArrRelInsertInput>;
  appetiteAudits?: InputMaybe<AppetiteParentAuditArrRelInsertInput>;
  assessmentResultAudits?: InputMaybe<AssessmentResultParentAuditArrRelInsertInput>;
  contributorAudits?: InputMaybe<ContributorAuditArrRelInsertInput>;
  contributorGroupAudits?: InputMaybe<ContributorGroupAuditArrRelInsertInput>;
  controlAudits?: InputMaybe<ControlParentAuditArrRelInsertInput>;
  departmentAudits?: InputMaybe<DepartmentAuditArrRelInsertInput>;
  impactRatingAudits?: InputMaybe<ImpactRatingAuditArrRelInsertInput>;
  indicatorAudits?: InputMaybe<RisksmartIndicatorParentAuditArrRelInsertInput>;
  ownerAudits?: InputMaybe<OwnerAuditArrRelInsertInput>;
  ownerGroupAudits?: InputMaybe<OwnerGroupAuditArrRelInsertInput>;
  tagAudits?: InputMaybe<TagAuditArrRelInsertInput>;
};

/** aggregate max on columns */
export type RiskAuditMaxFields = {
  __typename?: 'risk_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Treatment?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RiskAuditMinFields = {
  __typename?: 'risk_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  TestFrequency?: Maybe<Scalars['String']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Treatment?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.risk_audit" */
export type RiskAuditMutationResponse = {
  __typename?: 'risk_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskAudit>;
};

/** on_conflict condition type for table "risksmart.risk_audit" */
export type RiskAuditOnConflict = {
  constraint: RiskAuditConstraint;
  update_columns?: Array<RiskAuditUpdateColumn>;
  where?: InputMaybe<RiskAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_audit". */
export type RiskAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentRiskId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Treatment?: InputMaybe<OrderBy>;
  acceptanceAudits_aggregate?: InputMaybe<AcceptanceParentAuditAggregateOrderBy>;
  actionAudits_aggregate?: InputMaybe<ActionParentAuditAggregateOrderBy>;
  appetiteAudits_aggregate?: InputMaybe<AppetiteParentAuditAggregateOrderBy>;
  assessmentResultAudits_aggregate?: InputMaybe<AssessmentResultParentAuditAggregateOrderBy>;
  contributorAudits_aggregate?: InputMaybe<ContributorAuditAggregateOrderBy>;
  contributorGroupAudits_aggregate?: InputMaybe<ContributorGroupAuditAggregateOrderBy>;
  controlAudits_aggregate?: InputMaybe<ControlParentAuditAggregateOrderBy>;
  departmentAudits_aggregate?: InputMaybe<DepartmentAuditAggregateOrderBy>;
  impactRatingAudits_aggregate?: InputMaybe<ImpactRatingAuditAggregateOrderBy>;
  indicatorAudits_aggregate?: InputMaybe<RisksmartIndicatorParentAuditAggregateOrderBy>;
  ownerAudits_aggregate?: InputMaybe<OwnerAuditAggregateOrderBy>;
  ownerGroupAudits_aggregate?: InputMaybe<OwnerGroupAuditAggregateOrderBy>;
  tagAudits_aggregate?: InputMaybe<TagAuditAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.risk_audit */
export type RiskAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RiskAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.risk_audit" */
export enum RiskAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentRiskId = 'ParentRiskId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Tier = 'Tier',
  /** column name */
  Title = 'Title',
  /** column name */
  Treatment = 'Treatment'
}

/** input type for updating data in table "risksmart.risk_audit" */
export type RiskAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type RiskAuditStddevFields = {
  __typename?: 'risk_audit_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RiskAuditStddevPopFields = {
  __typename?: 'risk_audit_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RiskAuditStddevSampFields = {
  __typename?: 'risk_audit_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_audit" */
export type RiskAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  TestFrequency?: InputMaybe<Scalars['String']['input']>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RiskAuditSumFields = {
  __typename?: 'risk_audit_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.risk_audit" */
export enum RiskAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentRiskId = 'ParentRiskId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Tier = 'Tier',
  /** column name */
  Title = 'Title',
  /** column name */
  Treatment = 'Treatment'
}

export type RiskAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RiskAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RiskAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RiskAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RiskAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RiskAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RiskAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RiskAuditBoolExp;
};

/** aggregate var_pop on columns */
export type RiskAuditVarPopFields = {
  __typename?: 'risk_audit_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RiskAuditVarSampFields = {
  __typename?: 'risk_audit_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RiskAuditVarianceFields = {
  __typename?: 'risk_audit_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type RiskAvgFields = {
  __typename?: 'risk_avg_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.risk" */
export type RiskAvgOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.risk". All fields are combined with a logical 'AND'. */
export type RiskBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  LatestRatingDate?: InputMaybe<TimestamptzComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentRiskId?: InputMaybe<UuidComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<RiskStatusTypeEnumComparisonExp>;
  TestFrequency?: InputMaybe<TestFrequencyEnumComparisonExp>;
  Tier?: InputMaybe<IntComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Treatment?: InputMaybe<RiskTreatmentTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<RiskBoolExp>>;
  _not?: InputMaybe<RiskBoolExp>;
  _or?: InputMaybe<Array<RiskBoolExp>>;
  acceptances?: InputMaybe<AcceptanceParentBoolExp>;
  acceptances_aggregate?: InputMaybe<AcceptanceParentAggregateBoolExp>;
  actions?: InputMaybe<ActionParentBoolExp>;
  actions_aggregate?: InputMaybe<ActionParentAggregateBoolExp>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  appetites?: InputMaybe<AppetiteParentBoolExp>;
  appetites_aggregate?: InputMaybe<AppetiteParentAggregateBoolExp>;
  assessmentResults?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  childRisks?: InputMaybe<RiskBoolExp>;
  childRisks_aggregate?: InputMaybe<RiskAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  controls?: InputMaybe<ControlParentBoolExp>;
  controls_aggregate?: InputMaybe<ControlParentAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  impactRatings?: InputMaybe<ImpactRatingBoolExp>;
  impactRatings_aggregate?: InputMaybe<ImpactRatingAggregateBoolExp>;
  indicators?: InputMaybe<IndicatorParentBoolExp>;
  indicators_aggregate?: InputMaybe<IndicatorParentAggregateBoolExp>;
  insertPermissions?: InputMaybe<InsertPermissionsBoolExp>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  parent?: InputMaybe<RiskBoolExp>;
  parentNode?: InputMaybe<NodeBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  riskScore?: InputMaybe<RiskScoreBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
  testFrequency?: InputMaybe<TestFrequencyBoolExp>;
  treatment?: InputMaybe<RiskTreatmentTypeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.risk" */
export enum RiskConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxRiskOrgkeySequentialid = 'idx_risk_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  RiskPkey = 'risk_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RiskDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RiskDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RiskDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.risk" */
export type RiskIncInput = {
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.risk" */
export type RiskInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<RiskStatusTypeEnum>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<RiskTreatmentTypeEnum>;
  acceptances?: InputMaybe<AcceptanceParentArrRelInsertInput>;
  actions?: InputMaybe<ActionParentArrRelInsertInput>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  appetites?: InputMaybe<AppetiteParentArrRelInsertInput>;
  assessmentResults?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  childRisks?: InputMaybe<RiskArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  controls?: InputMaybe<ControlParentArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  impactRatings?: InputMaybe<ImpactRatingArrRelInsertInput>;
  indicators?: InputMaybe<IndicatorParentArrRelInsertInput>;
  insertPermissions?: InputMaybe<InsertPermissionsArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  parent?: InputMaybe<RiskObjRelInsertInput>;
  parentNode?: InputMaybe<NodeObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  riskScore?: InputMaybe<RiskScoreObjRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
  testFrequency?: InputMaybe<TestFrequencyObjRelInsertInput>;
  treatment?: InputMaybe<RiskTreatmentTypeObjRelInsertInput>;
};

/** aggregate max on columns */
export type RiskMaxFields = {
  __typename?: 'risk_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.risk" */
export type RiskMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentRiskId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RiskMinFields = {
  __typename?: 'risk_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  LatestRatingDate?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentRiskId?: Maybe<Scalars['uuid']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.risk" */
export type RiskMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentRiskId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.risk" */
export type RiskMutationResponse = {
  __typename?: 'risk_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk>;
};

/** input type for inserting object relation for remote table "risksmart.risk" */
export type RiskObjRelInsertInput = {
  data: RiskInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RiskOnConflict>;
};

/** on_conflict condition type for table "risksmart.risk" */
export type RiskOnConflict = {
  constraint: RiskConstraint;
  update_columns?: Array<RiskUpdateColumn>;
  where?: InputMaybe<RiskBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk". */
export type RiskOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  LatestRatingDate?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentRiskId?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  TestFrequency?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Treatment?: InputMaybe<OrderBy>;
  acceptances_aggregate?: InputMaybe<AcceptanceParentAggregateOrderBy>;
  actions_aggregate?: InputMaybe<ActionParentAggregateOrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  appetites_aggregate?: InputMaybe<AppetiteParentAggregateOrderBy>;
  assessmentResults_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  childRisks_aggregate?: InputMaybe<RiskAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  controls_aggregate?: InputMaybe<ControlParentAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  impactRatings_aggregate?: InputMaybe<ImpactRatingAggregateOrderBy>;
  indicators_aggregate?: InputMaybe<IndicatorParentAggregateOrderBy>;
  insertPermissions_aggregate?: InputMaybe<InsertPermissionsAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  parent?: InputMaybe<RiskOrderBy>;
  parentNode?: InputMaybe<NodeOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  riskScore?: InputMaybe<RiskScoreOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
  testFrequency?: InputMaybe<TestFrequencyOrderBy>;
  treatment?: InputMaybe<RiskTreatmentTypeOrderBy>;
};

/** primary key columns input for table: risksmart.risk */
export type RiskPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RiskPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.risk_score" */
export type RiskScore = {
  __typename?: 'risk_score';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  OrgKey: Scalars['String']['output'];
  ResidualScore?: Maybe<Scalars['float8']['output']>;
  RiskId: Scalars['uuid']['output'];
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  risk?: Maybe<Risk>;
};


/** columns and relationships of "risksmart.risk_score" */
export type RiskScorePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.risk_score" */
export type RiskScorePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.risk_score" */
export type RiskScoreAggregate = {
  __typename?: 'risk_score_aggregate';
  aggregate?: Maybe<RiskScoreAggregateFields>;
  nodes: Array<RiskScore>;
};

/** aggregate fields of "risksmart.risk_score" */
export type RiskScoreAggregateFields = {
  __typename?: 'risk_score_aggregate_fields';
  avg?: Maybe<RiskScoreAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RiskScoreMaxFields>;
  min?: Maybe<RiskScoreMinFields>;
  stddev?: Maybe<RiskScoreStddevFields>;
  stddev_pop?: Maybe<RiskScoreStddevPopFields>;
  stddev_samp?: Maybe<RiskScoreStddevSampFields>;
  sum?: Maybe<RiskScoreSumFields>;
  var_pop?: Maybe<RiskScoreVarPopFields>;
  var_samp?: Maybe<RiskScoreVarSampFields>;
  variance?: Maybe<RiskScoreVarianceFields>;
};


/** aggregate fields of "risksmart.risk_score" */
export type RiskScoreAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskScoreSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RiskScoreAvgFields = {
  __typename?: 'risk_score_avg_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_score". All fields are combined with a logical 'AND'. */
export type RiskScoreBoolExp = {
  InherentScore?: InputMaybe<Float8ComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ResidualScore?: InputMaybe<Float8ComparisonExp>;
  RiskId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<RiskScoreBoolExp>>;
  _not?: InputMaybe<RiskScoreBoolExp>;
  _or?: InputMaybe<Array<RiskScoreBoolExp>>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  risk?: InputMaybe<RiskBoolExp>;
};

/** unique or primary key constraints on table "risksmart.risk_score" */
export enum RiskScoreConstraint {
  /** unique or primary key constraint on columns "RiskId" */
  RiskScorePkey = 'risk_score_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.risk_score" */
export type RiskScoreIncInput = {
  InherentScore?: InputMaybe<Scalars['float8']['input']>;
  ResidualScore?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "risksmart.risk_score" */
export type RiskScoreInsertInput = {
  InherentScore?: InputMaybe<Scalars['float8']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResidualScore?: InputMaybe<Scalars['float8']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  risk?: InputMaybe<RiskObjRelInsertInput>;
};

/** aggregate max on columns */
export type RiskScoreMaxFields = {
  __typename?: 'risk_score_max_fields';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResidualScore?: Maybe<Scalars['float8']['output']>;
  RiskId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type RiskScoreMinFields = {
  __typename?: 'risk_score_min_fields';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ResidualScore?: Maybe<Scalars['float8']['output']>;
  RiskId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.risk_score" */
export type RiskScoreMutationResponse = {
  __typename?: 'risk_score_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskScore>;
};

/** input type for inserting object relation for remote table "risksmart.risk_score" */
export type RiskScoreObjRelInsertInput = {
  data: RiskScoreInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RiskScoreOnConflict>;
};

/** on_conflict condition type for table "risksmart.risk_score" */
export type RiskScoreOnConflict = {
  constraint: RiskScoreConstraint;
  update_columns?: Array<RiskScoreUpdateColumn>;
  where?: InputMaybe<RiskScoreBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_score". */
export type RiskScoreOrderBy = {
  InherentScore?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ResidualScore?: InputMaybe<OrderBy>;
  RiskId?: InputMaybe<OrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  risk?: InputMaybe<RiskOrderBy>;
};

/** primary key columns input for table: risksmart.risk_score */
export type RiskScorePkColumnsInput = {
  RiskId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.risk_score" */
export enum RiskScoreSelectColumn {
  /** column name */
  InherentScore = 'InherentScore',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ResidualScore = 'ResidualScore',
  /** column name */
  RiskId = 'RiskId'
}

/** input type for updating data in table "risksmart.risk_score" */
export type RiskScoreSetInput = {
  InherentScore?: InputMaybe<Scalars['float8']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResidualScore?: InputMaybe<Scalars['float8']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type RiskScoreStddevFields = {
  __typename?: 'risk_score_stddev_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RiskScoreStddevPopFields = {
  __typename?: 'risk_score_stddev_pop_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RiskScoreStddevSampFields = {
  __typename?: 'risk_score_stddev_samp_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risk_score" */
export type RiskScoreStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskScoreStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskScoreStreamCursorValueInput = {
  InherentScore?: InputMaybe<Scalars['float8']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ResidualScore?: InputMaybe<Scalars['float8']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate sum on columns */
export type RiskScoreSumFields = {
  __typename?: 'risk_score_sum_fields';
  InherentScore?: Maybe<Scalars['float8']['output']>;
  ResidualScore?: Maybe<Scalars['float8']['output']>;
};

/** update columns of table "risksmart.risk_score" */
export enum RiskScoreUpdateColumn {
  /** column name */
  InherentScore = 'InherentScore',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ResidualScore = 'ResidualScore',
  /** column name */
  RiskId = 'RiskId'
}

export type RiskScoreUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RiskScoreIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskScoreSetInput>;
  /** filter the rows which have to be updated */
  where: RiskScoreBoolExp;
};

/** aggregate var_pop on columns */
export type RiskScoreVarPopFields = {
  __typename?: 'risk_score_var_pop_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RiskScoreVarSampFields = {
  __typename?: 'risk_score_var_samp_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RiskScoreVarianceFields = {
  __typename?: 'risk_score_variance_fields';
  InherentScore?: Maybe<Scalars['Float']['output']>;
  ResidualScore?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "risksmart.risk_scoring_model" */
export type RiskScoringModel = {
  __typename?: 'risk_scoring_model';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.risk_scoring_model" */
export type RiskScoringModelAggregate = {
  __typename?: 'risk_scoring_model_aggregate';
  aggregate?: Maybe<RiskScoringModelAggregateFields>;
  nodes: Array<RiskScoringModel>;
};

/** aggregate fields of "risksmart.risk_scoring_model" */
export type RiskScoringModelAggregateFields = {
  __typename?: 'risk_scoring_model_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RiskScoringModelMaxFields>;
  min?: Maybe<RiskScoringModelMinFields>;
};


/** aggregate fields of "risksmart.risk_scoring_model" */
export type RiskScoringModelAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskScoringModelSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_scoring_model". All fields are combined with a logical 'AND'. */
export type RiskScoringModelBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RiskScoringModelBoolExp>>;
  _not?: InputMaybe<RiskScoringModelBoolExp>;
  _or?: InputMaybe<Array<RiskScoringModelBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.risk_scoring_model" */
export enum RiskScoringModelConstraint {
  /** unique or primary key constraint on columns "Value" */
  RiskScoringModelPkey = 'risk_scoring_model_pkey'
}

export enum RiskScoringModelEnum {
  /** Control effectiveness averages */
  ControlEffectivenessAverages = 'control_effectiveness_averages',
  /** Default model */
  Default = 'default',
  /** Number of controls with gaps */
  NumberOfControlsWithGaps = 'number_of_controls_with_gaps'
}

/** Boolean expression to compare columns of type "risk_scoring_model_enum". All fields are combined with logical 'AND'. */
export type RiskScoringModelEnumComparisonExp = {
  _eq?: InputMaybe<RiskScoringModelEnum>;
  _in?: InputMaybe<Array<RiskScoringModelEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<RiskScoringModelEnum>;
  _nin?: InputMaybe<Array<RiskScoringModelEnum>>;
};

/** input type for inserting data into table "risksmart.risk_scoring_model" */
export type RiskScoringModelInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RiskScoringModelMaxFields = {
  __typename?: 'risk_scoring_model_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RiskScoringModelMinFields = {
  __typename?: 'risk_scoring_model_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.risk_scoring_model" */
export type RiskScoringModelMutationResponse = {
  __typename?: 'risk_scoring_model_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskScoringModel>;
};

/** on_conflict condition type for table "risksmart.risk_scoring_model" */
export type RiskScoringModelOnConflict = {
  constraint: RiskScoringModelConstraint;
  update_columns?: Array<RiskScoringModelUpdateColumn>;
  where?: InputMaybe<RiskScoringModelBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_scoring_model". */
export type RiskScoringModelOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.risk_scoring_model */
export type RiskScoringModelPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.risk_scoring_model" */
export enum RiskScoringModelSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.risk_scoring_model" */
export type RiskScoringModelSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_scoring_model" */
export type RiskScoringModelStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskScoringModelStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskScoringModelStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.risk_scoring_model" */
export enum RiskScoringModelUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type RiskScoringModelUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskScoringModelSetInput>;
  /** filter the rows which have to be updated */
  where: RiskScoringModelBoolExp;
};

/** select columns of table "risksmart.risk" */
export enum RiskSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentRiskId = 'ParentRiskId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Tier = 'Tier',
  /** column name */
  Title = 'Title',
  /** column name */
  Treatment = 'Treatment'
}

/** input type for updating data in table "risksmart.risk" */
export type RiskSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<RiskStatusTypeEnum>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<RiskTreatmentTypeEnum>;
};

/** columns and relationships of "risksmart.risk_status_type" */
export type RiskStatusType = {
  __typename?: 'risk_status_type';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.risk_status_type" */
export type RiskStatusTypeAggregate = {
  __typename?: 'risk_status_type_aggregate';
  aggregate?: Maybe<RiskStatusTypeAggregateFields>;
  nodes: Array<RiskStatusType>;
};

/** aggregate fields of "risksmart.risk_status_type" */
export type RiskStatusTypeAggregateFields = {
  __typename?: 'risk_status_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RiskStatusTypeMaxFields>;
  min?: Maybe<RiskStatusTypeMinFields>;
};


/** aggregate fields of "risksmart.risk_status_type" */
export type RiskStatusTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskStatusTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_status_type". All fields are combined with a logical 'AND'. */
export type RiskStatusTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RiskStatusTypeBoolExp>>;
  _not?: InputMaybe<RiskStatusTypeBoolExp>;
  _or?: InputMaybe<Array<RiskStatusTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.risk_status_type" */
export enum RiskStatusTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  RiskStatusTypePkey = 'risk_status_type_pkey'
}

export enum RiskStatusTypeEnum {
  /** Active */
  Active = 'active',
  /** Emerging */
  Emerging = 'emerging',
  /** Monitored */
  Monitored = 'monitored',
  /** Retired */
  Retired = 'retired'
}

/** Boolean expression to compare columns of type "risk_status_type_enum". All fields are combined with logical 'AND'. */
export type RiskStatusTypeEnumComparisonExp = {
  _eq?: InputMaybe<RiskStatusTypeEnum>;
  _in?: InputMaybe<Array<RiskStatusTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<RiskStatusTypeEnum>;
  _nin?: InputMaybe<Array<RiskStatusTypeEnum>>;
};

/** input type for inserting data into table "risksmart.risk_status_type" */
export type RiskStatusTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RiskStatusTypeMaxFields = {
  __typename?: 'risk_status_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RiskStatusTypeMinFields = {
  __typename?: 'risk_status_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.risk_status_type" */
export type RiskStatusTypeMutationResponse = {
  __typename?: 'risk_status_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskStatusType>;
};

/** on_conflict condition type for table "risksmart.risk_status_type" */
export type RiskStatusTypeOnConflict = {
  constraint: RiskStatusTypeConstraint;
  update_columns?: Array<RiskStatusTypeUpdateColumn>;
  where?: InputMaybe<RiskStatusTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_status_type". */
export type RiskStatusTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.risk_status_type */
export type RiskStatusTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.risk_status_type" */
export enum RiskStatusTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.risk_status_type" */
export type RiskStatusTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_status_type" */
export type RiskStatusTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskStatusTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskStatusTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.risk_status_type" */
export enum RiskStatusTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type RiskStatusTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskStatusTypeSetInput>;
  /** filter the rows which have to be updated */
  where: RiskStatusTypeBoolExp;
};

/** aggregate stddev on columns */
export type RiskStddevFields = {
  __typename?: 'risk_stddev_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.risk" */
export type RiskStddevOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type RiskStddevPopFields = {
  __typename?: 'risk_stddev_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.risk" */
export type RiskStddevPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type RiskStddevSampFields = {
  __typename?: 'risk_stddev_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.risk" */
export type RiskStddevSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "risk" */
export type RiskStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  LatestRatingDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentRiskId?: InputMaybe<Scalars['uuid']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<RiskStatusTypeEnum>;
  TestFrequency?: InputMaybe<TestFrequencyEnum>;
  Tier?: InputMaybe<Scalars['Int']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Treatment?: InputMaybe<RiskTreatmentTypeEnum>;
};

/** aggregate sum on columns */
export type RiskSumFields = {
  __typename?: 'risk_sum_fields';
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Tier?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.risk" */
export type RiskSumOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.risk_treatment_type" */
export type RiskTreatmentType = {
  __typename?: 'risk_treatment_type';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeAggregate = {
  __typename?: 'risk_treatment_type_aggregate';
  aggregate?: Maybe<RiskTreatmentTypeAggregateFields>;
  nodes: Array<RiskTreatmentType>;
};

/** aggregate fields of "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeAggregateFields = {
  __typename?: 'risk_treatment_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RiskTreatmentTypeMaxFields>;
  min?: Maybe<RiskTreatmentTypeMinFields>;
};


/** aggregate fields of "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RiskTreatmentTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_treatment_type". All fields are combined with a logical 'AND'. */
export type RiskTreatmentTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RiskTreatmentTypeBoolExp>>;
  _not?: InputMaybe<RiskTreatmentTypeBoolExp>;
  _or?: InputMaybe<Array<RiskTreatmentTypeBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.risk_treatment_type" */
export enum RiskTreatmentTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  RiskTreatmentTypePkey = 'risk_treatment_type_pkey'
}

export enum RiskTreatmentTypeEnum {
  /** Terminate */
  Terminate = 'terminate',
  /** Tolerate */
  Tolerate = 'tolerate',
  /** Transfer */
  Transfer = 'transfer',
  /** Treat */
  Treat = 'treat'
}

/** Boolean expression to compare columns of type "risk_treatment_type_enum". All fields are combined with logical 'AND'. */
export type RiskTreatmentTypeEnumComparisonExp = {
  _eq?: InputMaybe<RiskTreatmentTypeEnum>;
  _in?: InputMaybe<Array<RiskTreatmentTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<RiskTreatmentTypeEnum>;
  _nin?: InputMaybe<Array<RiskTreatmentTypeEnum>>;
};

/** input type for inserting data into table "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RiskTreatmentTypeMaxFields = {
  __typename?: 'risk_treatment_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RiskTreatmentTypeMinFields = {
  __typename?: 'risk_treatment_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeMutationResponse = {
  __typename?: 'risk_treatment_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RiskTreatmentType>;
};

/** input type for inserting object relation for remote table "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeObjRelInsertInput = {
  data: RiskTreatmentTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<RiskTreatmentTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeOnConflict = {
  constraint: RiskTreatmentTypeConstraint;
  update_columns?: Array<RiskTreatmentTypeUpdateColumn>;
  where?: InputMaybe<RiskTreatmentTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_treatment_type". */
export type RiskTreatmentTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.risk_treatment_type */
export type RiskTreatmentTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.risk_treatment_type" */
export enum RiskTreatmentTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.risk_treatment_type" */
export type RiskTreatmentTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_treatment_type" */
export type RiskTreatmentTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RiskTreatmentTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RiskTreatmentTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.risk_treatment_type" */
export enum RiskTreatmentTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type RiskTreatmentTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskTreatmentTypeSetInput>;
  /** filter the rows which have to be updated */
  where: RiskTreatmentTypeBoolExp;
};

/** update columns of table "risksmart.risk" */
export enum RiskUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  LatestRatingDate = 'LatestRatingDate',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentRiskId = 'ParentRiskId',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  TestFrequency = 'TestFrequency',
  /** column name */
  Tier = 'Tier',
  /** column name */
  Title = 'Title',
  /** column name */
  Treatment = 'Treatment'
}

export type RiskUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RiskAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RiskDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RiskDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RiskDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RiskIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RiskPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RiskSetInput>;
  /** filter the rows which have to be updated */
  where: RiskBoolExp;
};

/** aggregate var_pop on columns */
export type RiskVarPopFields = {
  __typename?: 'risk_var_pop_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.risk" */
export type RiskVarPopOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type RiskVarSampFields = {
  __typename?: 'risk_var_samp_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.risk" */
export type RiskVarSampOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type RiskVarianceFields = {
  __typename?: 'risk_variance_fields';
  SequentialId?: Maybe<Scalars['Float']['output']>;
  Tier?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.risk" */
export type RiskVarianceOrderBy = {
  SequentialId?: InputMaybe<OrderBy>;
  Tier?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAudit = {
  __typename?: 'risksmart_approver_response_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Approved?: Maybe<Scalars['Boolean']['output']>;
  ApproverId: Scalars['uuid']['output'];
  ChangeRequestId: Scalars['uuid']['output'];
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditAggregate = {
  __typename?: 'risksmart_approver_response_audit_aggregate';
  aggregate?: Maybe<RisksmartApproverResponseAuditAggregateFields>;
  nodes: Array<RisksmartApproverResponseAudit>;
};

/** aggregate fields of "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditAggregateFields = {
  __typename?: 'risksmart_approver_response_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartApproverResponseAuditMaxFields>;
  min?: Maybe<RisksmartApproverResponseAuditMinFields>;
};


/** aggregate fields of "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartApproverResponseAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.approver_response_audit". All fields are combined with a logical 'AND'. */
export type RisksmartApproverResponseAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Approved?: InputMaybe<BooleanComparisonExp>;
  ApproverId?: InputMaybe<UuidComparisonExp>;
  ChangeRequestId?: InputMaybe<UuidComparisonExp>;
  Comment?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OverriddenAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OverriddenByUser?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartApproverResponseAuditBoolExp>>;
  _not?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartApproverResponseAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.approver_response_audit" */
export enum RisksmartApproverResponseAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ApproverResponseAuditPkey = 'approver_response_audit_pkey'
}

/** input type for inserting data into table "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartApproverResponseAuditMaxFields = {
  __typename?: 'risksmart_approver_response_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ApproverId?: Maybe<Scalars['uuid']['output']>;
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartApproverResponseAuditMinFields = {
  __typename?: 'risksmart_approver_response_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ApproverId?: Maybe<Scalars['uuid']['output']>;
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  Comment?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OverriddenAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OverriddenByUser?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditMutationResponse = {
  __typename?: 'risksmart_approver_response_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartApproverResponseAudit>;
};

/** on_conflict condition type for table "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditOnConflict = {
  constraint: RisksmartApproverResponseAuditConstraint;
  update_columns?: Array<RisksmartApproverResponseAuditUpdateColumn>;
  where?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.approver_response_audit". */
export type RisksmartApproverResponseAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Approved?: InputMaybe<OrderBy>;
  ApproverId?: InputMaybe<OrderBy>;
  ChangeRequestId?: InputMaybe<OrderBy>;
  Comment?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OverriddenAtTimestamp?: InputMaybe<OrderBy>;
  OverriddenByUser?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.approver_response_audit */
export type RisksmartApproverResponseAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.approver_response_audit" */
export enum RisksmartApproverResponseAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Approved = 'Approved',
  /** column name */
  ApproverId = 'ApproverId',
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser'
}

/** input type for updating data in table "risksmart.approver_response_audit" */
export type RisksmartApproverResponseAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risksmart_approver_response_audit" */
export type RisksmartApproverResponseAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartApproverResponseAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartApproverResponseAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Approved?: InputMaybe<Scalars['Boolean']['input']>;
  ApproverId?: InputMaybe<Scalars['uuid']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  Comment?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OverriddenAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OverriddenByUser?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.approver_response_audit" */
export enum RisksmartApproverResponseAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Approved = 'Approved',
  /** column name */
  ApproverId = 'ApproverId',
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  Comment = 'Comment',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OverriddenAtTimestamp = 'OverriddenAtTimestamp',
  /** column name */
  OverriddenByUser = 'OverriddenByUser'
}

export type RisksmartApproverResponseAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartApproverResponseAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartApproverResponseAuditBoolExp;
};

/** columns and relationships of "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAudit = {
  __typename?: 'risksmart_change_request_contributor_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ChangeRequestId: Scalars['uuid']['output'];
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  OrgKey: Scalars['String']['output'];
  UserId: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditAggregate = {
  __typename?: 'risksmart_change_request_contributor_audit_aggregate';
  aggregate?: Maybe<RisksmartChangeRequestContributorAuditAggregateFields>;
  nodes: Array<RisksmartChangeRequestContributorAudit>;
};

/** aggregate fields of "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditAggregateFields = {
  __typename?: 'risksmart_change_request_contributor_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartChangeRequestContributorAuditMaxFields>;
  min?: Maybe<RisksmartChangeRequestContributorAuditMinFields>;
};


/** aggregate fields of "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartChangeRequestContributorAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.change_request_contributor_audit". All fields are combined with a logical 'AND'. */
export type RisksmartChangeRequestContributorAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ChangeRequestId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartChangeRequestContributorAuditBoolExp>>;
  _not?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartChangeRequestContributorAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.change_request_contributor_audit" */
export enum RisksmartChangeRequestContributorAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ChangeRequestContributorAuditPkey = 'change_request_contributor_audit_pkey'
}

/** input type for inserting data into table "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartChangeRequestContributorAuditMaxFields = {
  __typename?: 'risksmart_change_request_contributor_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartChangeRequestContributorAuditMinFields = {
  __typename?: 'risksmart_change_request_contributor_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ChangeRequestId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditMutationResponse = {
  __typename?: 'risksmart_change_request_contributor_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartChangeRequestContributorAudit>;
};

/** on_conflict condition type for table "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditOnConflict = {
  constraint: RisksmartChangeRequestContributorAuditConstraint;
  update_columns?: Array<RisksmartChangeRequestContributorAuditUpdateColumn>;
  where?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.change_request_contributor_audit". */
export type RisksmartChangeRequestContributorAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ChangeRequestId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.change_request_contributor_audit */
export type RisksmartChangeRequestContributorAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.change_request_contributor_audit" */
export enum RisksmartChangeRequestContributorAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risksmart_change_request_contributor_audit" */
export type RisksmartChangeRequestContributorAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartChangeRequestContributorAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartChangeRequestContributorAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ChangeRequestId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.change_request_contributor_audit" */
export enum RisksmartChangeRequestContributorAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ChangeRequestId = 'ChangeRequestId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserId = 'UserId'
}

export type RisksmartChangeRequestContributorAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartChangeRequestContributorAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartChangeRequestContributorAuditBoolExp;
};

/** columns and relationships of "risksmart.contributor_view" */
export type RisksmartContributorView = {
  __typename?: 'risksmart_contributor_view';
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.contributor_view" */
export type RisksmartContributorViewAggregate = {
  __typename?: 'risksmart_contributor_view_aggregate';
  aggregate?: Maybe<RisksmartContributorViewAggregateFields>;
  nodes: Array<RisksmartContributorView>;
};

/** aggregate fields of "risksmart.contributor_view" */
export type RisksmartContributorViewAggregateFields = {
  __typename?: 'risksmart_contributor_view_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartContributorViewMaxFields>;
  min?: Maybe<RisksmartContributorViewMinFields>;
};


/** aggregate fields of "risksmart.contributor_view" */
export type RisksmartContributorViewAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartContributorViewSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.contributor_view". All fields are combined with a logical 'AND'. */
export type RisksmartContributorViewBoolExp = {
  ContributorType?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartContributorViewBoolExp>>;
  _not?: InputMaybe<RisksmartContributorViewBoolExp>;
  _or?: InputMaybe<Array<RisksmartContributorViewBoolExp>>;
};

/** aggregate max on columns */
export type RisksmartContributorViewMaxFields = {
  __typename?: 'risksmart_contributor_view_max_fields';
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartContributorViewMinFields = {
  __typename?: 'risksmart_contributor_view_min_fields';
  ContributorType?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "risksmart.contributor_view". */
export type RisksmartContributorViewOrderBy = {
  ContributorType?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** select columns of table "risksmart.contributor_view" */
export enum RisksmartContributorViewSelectColumn {
  /** column name */
  ContributorType = 'ContributorType',
  /** column name */
  Id = 'Id',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

/** Streaming cursor of the table "risksmart_contributor_view" */
export type RisksmartContributorViewStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartContributorViewStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartContributorViewStreamCursorValueInput = {
  ContributorType?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.control_action_audit" */
export type RisksmartControlActionAudit = {
  __typename?: 'risksmart_control_action_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId: Scalars['uuid']['output'];
  ControlId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.control_action_audit" */
export type RisksmartControlActionAuditAggregate = {
  __typename?: 'risksmart_control_action_audit_aggregate';
  aggregate?: Maybe<RisksmartControlActionAuditAggregateFields>;
  nodes: Array<RisksmartControlActionAudit>;
};

/** aggregate fields of "risksmart.control_action_audit" */
export type RisksmartControlActionAuditAggregateFields = {
  __typename?: 'risksmart_control_action_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartControlActionAuditMaxFields>;
  min?: Maybe<RisksmartControlActionAuditMinFields>;
};


/** aggregate fields of "risksmart.control_action_audit" */
export type RisksmartControlActionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartControlActionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.control_action_audit". All fields are combined with a logical 'AND'. */
export type RisksmartControlActionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActionId?: InputMaybe<UuidComparisonExp>;
  ControlId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartControlActionAuditBoolExp>>;
  _not?: InputMaybe<RisksmartControlActionAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartControlActionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.control_action_audit" */
export enum RisksmartControlActionAuditConstraint {
  /** unique or primary key constraint on columns "ActionId", "ControlId", "ModifiedAtTimestamp" */
  ControlActionAuditPkey = 'control_action_audit_pkey'
}

/** input type for inserting data into table "risksmart.control_action_audit" */
export type RisksmartControlActionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartControlActionAuditMaxFields = {
  __typename?: 'risksmart_control_action_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartControlActionAuditMinFields = {
  __typename?: 'risksmart_control_action_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  ControlId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.control_action_audit" */
export type RisksmartControlActionAuditMutationResponse = {
  __typename?: 'risksmart_control_action_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartControlActionAudit>;
};

/** on_conflict condition type for table "risksmart.control_action_audit" */
export type RisksmartControlActionAuditOnConflict = {
  constraint: RisksmartControlActionAuditConstraint;
  update_columns?: Array<RisksmartControlActionAuditUpdateColumn>;
  where?: InputMaybe<RisksmartControlActionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.control_action_audit". */
export type RisksmartControlActionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  ControlId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.control_action_audit */
export type RisksmartControlActionAuditPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.control_action_audit" */
export enum RisksmartControlActionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  ControlId = 'ControlId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.control_action_audit" */
export type RisksmartControlActionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risksmart_control_action_audit" */
export type RisksmartControlActionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartControlActionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartControlActionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  ControlId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.control_action_audit" */
export enum RisksmartControlActionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  ControlId = 'ControlId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey'
}

export type RisksmartControlActionAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartControlActionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartControlActionAuditBoolExp;
};

/** columns and relationships of "risksmart.counter" */
export type RisksmartCounter = {
  __typename?: 'risksmart_counter';
  LastValue: Scalars['Int']['output'];
  Name: ParentTypeEnum;
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.counter" */
export type RisksmartCounterAggregate = {
  __typename?: 'risksmart_counter_aggregate';
  aggregate?: Maybe<RisksmartCounterAggregateFields>;
  nodes: Array<RisksmartCounter>;
};

/** aggregate fields of "risksmart.counter" */
export type RisksmartCounterAggregateFields = {
  __typename?: 'risksmart_counter_aggregate_fields';
  avg?: Maybe<RisksmartCounterAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartCounterMaxFields>;
  min?: Maybe<RisksmartCounterMinFields>;
  stddev?: Maybe<RisksmartCounterStddevFields>;
  stddev_pop?: Maybe<RisksmartCounterStddevPopFields>;
  stddev_samp?: Maybe<RisksmartCounterStddevSampFields>;
  sum?: Maybe<RisksmartCounterSumFields>;
  var_pop?: Maybe<RisksmartCounterVarPopFields>;
  var_samp?: Maybe<RisksmartCounterVarSampFields>;
  variance?: Maybe<RisksmartCounterVarianceFields>;
};


/** aggregate fields of "risksmart.counter" */
export type RisksmartCounterAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartCounterSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type RisksmartCounterAvgFields = {
  __typename?: 'risksmart_counter_avg_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.counter". All fields are combined with a logical 'AND'. */
export type RisksmartCounterBoolExp = {
  LastValue?: InputMaybe<IntComparisonExp>;
  Name?: InputMaybe<ParentTypeEnumComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartCounterBoolExp>>;
  _not?: InputMaybe<RisksmartCounterBoolExp>;
  _or?: InputMaybe<Array<RisksmartCounterBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.counter" */
export enum RisksmartCounterConstraint {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  CounterPkey = 'counter_pkey'
}

/** input type for incrementing numeric columns in table "risksmart.counter" */
export type RisksmartCounterIncInput = {
  LastValue?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.counter" */
export type RisksmartCounterInsertInput = {
  LastValue?: InputMaybe<Scalars['Int']['input']>;
  Name?: InputMaybe<ParentTypeEnum>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartCounterMaxFields = {
  __typename?: 'risksmart_counter_max_fields';
  LastValue?: Maybe<Scalars['Int']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartCounterMinFields = {
  __typename?: 'risksmart_counter_min_fields';
  LastValue?: Maybe<Scalars['Int']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.counter" */
export type RisksmartCounterMutationResponse = {
  __typename?: 'risksmart_counter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartCounter>;
};

/** on_conflict condition type for table "risksmart.counter" */
export type RisksmartCounterOnConflict = {
  constraint: RisksmartCounterConstraint;
  update_columns?: Array<RisksmartCounterUpdateColumn>;
  where?: InputMaybe<RisksmartCounterBoolExp>;
};

/** Ordering options when selecting data from "risksmart.counter". */
export type RisksmartCounterOrderBy = {
  LastValue?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.counter */
export type RisksmartCounterPkColumnsInput = {
  Name: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.counter" */
export enum RisksmartCounterSelectColumn {
  /** column name */
  LastValue = 'LastValue',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.counter" */
export type RisksmartCounterSetInput = {
  LastValue?: InputMaybe<Scalars['Int']['input']>;
  Name?: InputMaybe<ParentTypeEnum>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type RisksmartCounterStddevFields = {
  __typename?: 'risksmart_counter_stddev_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type RisksmartCounterStddevPopFields = {
  __typename?: 'risksmart_counter_stddev_pop_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type RisksmartCounterStddevSampFields = {
  __typename?: 'risksmart_counter_stddev_samp_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "risksmart_counter" */
export type RisksmartCounterStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartCounterStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartCounterStreamCursorValueInput = {
  LastValue?: InputMaybe<Scalars['Int']['input']>;
  Name?: InputMaybe<ParentTypeEnum>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type RisksmartCounterSumFields = {
  __typename?: 'risksmart_counter_sum_fields';
  LastValue?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.counter" */
export enum RisksmartCounterUpdateColumn {
  /** column name */
  LastValue = 'LastValue',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type RisksmartCounterUpdates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<RisksmartCounterIncInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartCounterSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartCounterBoolExp;
};

/** aggregate var_pop on columns */
export type RisksmartCounterVarPopFields = {
  __typename?: 'risksmart_counter_var_pop_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type RisksmartCounterVarSampFields = {
  __typename?: 'risksmart_counter_var_samp_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type RisksmartCounterVarianceFields = {
  __typename?: 'risksmart_counter_variance_fields';
  LastValue?: Maybe<Scalars['Float']['output']>;
};

export type RisksmartDeleteOrganisationUsersArgs = {
  orgkey?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAudit = {
  __typename?: 'risksmart_indicator_parent_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IndicatorId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditAggregate = {
  __typename?: 'risksmart_indicator_parent_audit_aggregate';
  aggregate?: Maybe<RisksmartIndicatorParentAuditAggregateFields>;
  nodes: Array<RisksmartIndicatorParentAudit>;
};

export type RisksmartIndicatorParentAuditAggregateBoolExp = {
  count?: InputMaybe<RisksmartIndicatorParentAuditAggregateBoolExpCount>;
};

export type RisksmartIndicatorParentAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditAggregateFields = {
  __typename?: 'risksmart_indicator_parent_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartIndicatorParentAuditMaxFields>;
  min?: Maybe<RisksmartIndicatorParentAuditMinFields>;
};


/** aggregate fields of "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<RisksmartIndicatorParentAuditMaxOrderBy>;
  min?: InputMaybe<RisksmartIndicatorParentAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditArrRelInsertInput = {
  data: Array<RisksmartIndicatorParentAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<RisksmartIndicatorParentAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.indicator_parent_audit". All fields are combined with a logical 'AND'. */
export type RisksmartIndicatorParentAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  IndicatorId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<RisksmartIndicatorParentAuditBoolExp>>;
  _not?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartIndicatorParentAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.indicator_parent_audit" */
export enum RisksmartIndicatorParentAuditConstraint {
  /** unique or primary key constraint on columns "ParentId", "ModifiedAtTimestamp", "IndicatorId" */
  IndicatorParentAuditPkey = 'indicator_parent_audit_pkey'
}

/** input type for inserting data into table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type RisksmartIndicatorParentAuditMaxFields = {
  __typename?: 'risksmart_indicator_parent_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type RisksmartIndicatorParentAuditMinFields = {
  __typename?: 'risksmart_indicator_parent_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IndicatorId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditMutationResponse = {
  __typename?: 'risksmart_indicator_parent_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartIndicatorParentAudit>;
};

/** on_conflict condition type for table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditOnConflict = {
  constraint: RisksmartIndicatorParentAuditConstraint;
  update_columns?: Array<RisksmartIndicatorParentAuditUpdateColumn>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.indicator_parent_audit". */
export type RisksmartIndicatorParentAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IndicatorId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.indicator_parent_audit */
export type RisksmartIndicatorParentAuditPkColumnsInput = {
  IndicatorId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.indicator_parent_audit" */
export enum RisksmartIndicatorParentAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

/** input type for updating data in table "risksmart.indicator_parent_audit" */
export type RisksmartIndicatorParentAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risksmart_indicator_parent_audit" */
export type RisksmartIndicatorParentAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartIndicatorParentAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartIndicatorParentAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IndicatorId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.indicator_parent_audit" */
export enum RisksmartIndicatorParentAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IndicatorId = 'IndicatorId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId'
}

export type RisksmartIndicatorParentAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartIndicatorParentAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartIndicatorParentAuditBoolExp;
};

/** columns and relationships of "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAudit = {
  __typename?: 'risksmart_linked_item_audit';
  Action: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source: Scalars['uuid']['output'];
  Target: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditAggregate = {
  __typename?: 'risksmart_linked_item_audit_aggregate';
  aggregate?: Maybe<RisksmartLinkedItemAuditAggregateFields>;
  nodes: Array<RisksmartLinkedItemAudit>;
};

/** aggregate fields of "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditAggregateFields = {
  __typename?: 'risksmart_linked_item_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartLinkedItemAuditMaxFields>;
  min?: Maybe<RisksmartLinkedItemAuditMinFields>;
};


/** aggregate fields of "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartLinkedItemAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.linked_item_audit". All fields are combined with a logical 'AND'. */
export type RisksmartLinkedItemAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RelationshipType?: InputMaybe<StringComparisonExp>;
  Source?: InputMaybe<UuidComparisonExp>;
  Target?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<RisksmartLinkedItemAuditBoolExp>>;
  _not?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartLinkedItemAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.linked_item_audit" */
export enum RisksmartLinkedItemAuditConstraint {
  /** unique or primary key constraint on columns "Source", "ModifiedAtTimestamp", "Target" */
  LinkedItemAuditPkey = 'linked_item_audit_pkey'
}

/** input type for inserting data into table "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type RisksmartLinkedItemAuditMaxFields = {
  __typename?: 'risksmart_linked_item_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['uuid']['output']>;
  Target?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type RisksmartLinkedItemAuditMinFields = {
  __typename?: 'risksmart_linked_item_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RelationshipType?: Maybe<Scalars['String']['output']>;
  Source?: Maybe<Scalars['uuid']['output']>;
  Target?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditMutationResponse = {
  __typename?: 'risksmart_linked_item_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartLinkedItemAudit>;
};

/** on_conflict condition type for table "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditOnConflict = {
  constraint: RisksmartLinkedItemAuditConstraint;
  update_columns?: Array<RisksmartLinkedItemAuditUpdateColumn>;
  where?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.linked_item_audit". */
export type RisksmartLinkedItemAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RelationshipType?: InputMaybe<OrderBy>;
  Source?: InputMaybe<OrderBy>;
  Target?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.linked_item_audit */
export type RisksmartLinkedItemAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.linked_item_audit" */
export enum RisksmartLinkedItemAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RelationshipType = 'RelationshipType',
  /** column name */
  Source = 'Source',
  /** column name */
  Target = 'Target'
}

/** input type for updating data in table "risksmart.linked_item_audit" */
export type RisksmartLinkedItemAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risksmart_linked_item_audit" */
export type RisksmartLinkedItemAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartLinkedItemAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartLinkedItemAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RelationshipType?: InputMaybe<Scalars['String']['input']>;
  Source?: InputMaybe<Scalars['uuid']['input']>;
  Target?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.linked_item_audit" */
export enum RisksmartLinkedItemAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RelationshipType = 'RelationshipType',
  /** column name */
  Source = 'Source',
  /** column name */
  Target = 'Target'
}

export type RisksmartLinkedItemAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartLinkedItemAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartLinkedItemAuditBoolExp;
};

/** columns and relationships of "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAudit = {
  __typename?: 'risksmart_obligation_issue_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  IssueId: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  ObligationId: Scalars['uuid']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditAggregate = {
  __typename?: 'risksmart_obligation_issue_audit_aggregate';
  aggregate?: Maybe<RisksmartObligationIssueAuditAggregateFields>;
  nodes: Array<RisksmartObligationIssueAudit>;
};

/** aggregate fields of "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditAggregateFields = {
  __typename?: 'risksmart_obligation_issue_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartObligationIssueAuditMaxFields>;
  min?: Maybe<RisksmartObligationIssueAuditMinFields>;
};


/** aggregate fields of "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartObligationIssueAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.obligation_issue_audit". All fields are combined with a logical 'AND'. */
export type RisksmartObligationIssueAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  IssueId?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  ObligationId?: InputMaybe<UuidComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartObligationIssueAuditBoolExp>>;
  _not?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartObligationIssueAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.obligation_issue_audit" */
export enum RisksmartObligationIssueAuditConstraint {
  /** unique or primary key constraint on columns "ObligationId", "ModifiedAtTimestamp", "IssueId" */
  ObligationIssueAuditPkey = 'obligation_issue_audit_pkey'
}

/** input type for inserting data into table "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObligationId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartObligationIssueAuditMaxFields = {
  __typename?: 'risksmart_obligation_issue_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObligationId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartObligationIssueAuditMinFields = {
  __typename?: 'risksmart_obligation_issue_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  IssueId?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  ObligationId?: Maybe<Scalars['uuid']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditMutationResponse = {
  __typename?: 'risksmart_obligation_issue_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartObligationIssueAudit>;
};

/** on_conflict condition type for table "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditOnConflict = {
  constraint: RisksmartObligationIssueAuditConstraint;
  update_columns?: Array<RisksmartObligationIssueAuditUpdateColumn>;
  where?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.obligation_issue_audit". */
export type RisksmartObligationIssueAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  IssueId?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  ObligationId?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.obligation_issue_audit */
export type RisksmartObligationIssueAuditPkColumnsInput = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.obligation_issue_audit" */
export enum RisksmartObligationIssueAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  ObligationId = 'ObligationId',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.obligation_issue_audit" */
export type RisksmartObligationIssueAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObligationId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risksmart_obligation_issue_audit" */
export type RisksmartObligationIssueAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartObligationIssueAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartObligationIssueAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  IssueId?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  ObligationId?: InputMaybe<Scalars['uuid']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.obligation_issue_audit" */
export enum RisksmartObligationIssueAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  IssueId = 'IssueId',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  ObligationId = 'ObligationId',
  /** column name */
  OrgKey = 'OrgKey'
}

export type RisksmartObligationIssueAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartObligationIssueAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartObligationIssueAuditBoolExp;
};

/** columns and relationships of "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAudit = {
  __typename?: 'risksmart_questionnaire_template_audit';
  Action: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Title: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditAggregate = {
  __typename?: 'risksmart_questionnaire_template_audit_aggregate';
  aggregate?: Maybe<RisksmartQuestionnaireTemplateAuditAggregateFields>;
  nodes: Array<RisksmartQuestionnaireTemplateAudit>;
};

/** aggregate fields of "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditAggregateFields = {
  __typename?: 'risksmart_questionnaire_template_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartQuestionnaireTemplateAuditMaxFields>;
  min?: Maybe<RisksmartQuestionnaireTemplateAuditMinFields>;
};


/** aggregate fields of "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RisksmartQuestionnaireTemplateAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template_audit". All fields are combined with a logical 'AND'. */
export type RisksmartQuestionnaireTemplateAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditBoolExp>>;
  _not?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template_audit" */
export enum RisksmartQuestionnaireTemplateAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  QuestionnaireTemplateAuditPkey = 'questionnaire_template_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RisksmartQuestionnaireTemplateAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RisksmartQuestionnaireTemplateAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RisksmartQuestionnaireTemplateAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartQuestionnaireTemplateAuditMaxFields = {
  __typename?: 'risksmart_questionnaire_template_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartQuestionnaireTemplateAuditMinFields = {
  __typename?: 'risksmart_questionnaire_template_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditMutationResponse = {
  __typename?: 'risksmart_questionnaire_template_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartQuestionnaireTemplateAudit>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditOnConflict = {
  constraint: RisksmartQuestionnaireTemplateAuditConstraint;
  update_columns?: Array<RisksmartQuestionnaireTemplateAuditUpdateColumn>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template_audit". */
export type RisksmartQuestionnaireTemplateAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.questionnaire_template_audit */
export type RisksmartQuestionnaireTemplateAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RisksmartQuestionnaireTemplateAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.questionnaire_template_audit" */
export enum RisksmartQuestionnaireTemplateAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risksmart_questionnaire_template_audit" */
export type RisksmartQuestionnaireTemplateAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartQuestionnaireTemplateAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartQuestionnaireTemplateAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template_audit" */
export enum RisksmartQuestionnaireTemplateAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Title = 'Title'
}

export type RisksmartQuestionnaireTemplateAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RisksmartQuestionnaireTemplateAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RisksmartQuestionnaireTemplateAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RisksmartQuestionnaireTemplateAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartQuestionnaireTemplateAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartQuestionnaireTemplateAuditBoolExp;
};

/** columns and relationships of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAudit = {
  __typename?: 'risksmart_questionnaire_template_version_audit';
  Action: Scalars['String']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  Schema: Scalars['jsonb']['output'];
  Status: Scalars['String']['output'];
  UISchema: Scalars['jsonb']['output'];
  Version: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditUiSchemaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditAggregate = {
  __typename?: 'risksmart_questionnaire_template_version_audit_aggregate';
  aggregate?: Maybe<RisksmartQuestionnaireTemplateVersionAuditAggregateFields>;
  nodes: Array<RisksmartQuestionnaireTemplateVersionAudit>;
};

/** aggregate fields of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditAggregateFields = {
  __typename?: 'risksmart_questionnaire_template_version_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartQuestionnaireTemplateVersionAuditMaxFields>;
  min?: Maybe<RisksmartQuestionnaireTemplateVersionAuditMinFields>;
};


/** aggregate fields of "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type RisksmartQuestionnaireTemplateVersionAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.questionnaire_template_version_audit". All fields are combined with a logical 'AND'. */
export type RisksmartQuestionnaireTemplateVersionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  Schema?: InputMaybe<JsonbComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  UISchema?: InputMaybe<JsonbComparisonExp>;
  Version?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditBoolExp>>;
  _not?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.questionnaire_template_version_audit" */
export enum RisksmartQuestionnaireTemplateVersionAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  QuestionnaireTemplateVersionAuditPkey = 'questionnaire_template_version_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type RisksmartQuestionnaireTemplateVersionAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
  Schema?: InputMaybe<Array<Scalars['String']['input']>>;
  UISchema?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type RisksmartQuestionnaireTemplateVersionAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
  Schema?: InputMaybe<Scalars['Int']['input']>;
  UISchema?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type RisksmartQuestionnaireTemplateVersionAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
  Schema?: InputMaybe<Scalars['String']['input']>;
  UISchema?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RisksmartQuestionnaireTemplateVersionAuditMaxFields = {
  __typename?: 'risksmart_questionnaire_template_version_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RisksmartQuestionnaireTemplateVersionAuditMinFields = {
  __typename?: 'risksmart_questionnaire_template_version_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Version?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditMutationResponse = {
  __typename?: 'risksmart_questionnaire_template_version_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartQuestionnaireTemplateVersionAudit>;
};

/** on_conflict condition type for table "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditOnConflict = {
  constraint: RisksmartQuestionnaireTemplateVersionAuditConstraint;
  update_columns?: Array<RisksmartQuestionnaireTemplateVersionAuditUpdateColumn>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.questionnaire_template_version_audit". */
export type RisksmartQuestionnaireTemplateVersionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  Schema?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  UISchema?: InputMaybe<OrderBy>;
  Version?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.questionnaire_template_version_audit */
export type RisksmartQuestionnaireTemplateVersionAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type RisksmartQuestionnaireTemplateVersionAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.questionnaire_template_version_audit" */
export enum RisksmartQuestionnaireTemplateVersionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Status = 'Status',
  /** column name */
  UiSchema = 'UISchema',
  /** column name */
  Version = 'Version'
}

/** input type for updating data in table "risksmart.questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risksmart_questionnaire_template_version_audit" */
export type RisksmartQuestionnaireTemplateVersionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartQuestionnaireTemplateVersionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartQuestionnaireTemplateVersionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  Schema?: InputMaybe<Scalars['jsonb']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UISchema?: InputMaybe<Scalars['jsonb']['input']>;
  Version?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.questionnaire_template_version_audit" */
export enum RisksmartQuestionnaireTemplateVersionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  Schema = 'Schema',
  /** column name */
  Status = 'Status',
  /** column name */
  UiSchema = 'UISchema',
  /** column name */
  Version = 'Version'
}

export type RisksmartQuestionnaireTemplateVersionAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartQuestionnaireTemplateVersionAuditBoolExp;
};

/** columns and relationships of "risksmart.risk_action_audit" */
export type RisksmartRiskActionAudit = {
  __typename?: 'risksmart_risk_action_audit';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId: Scalars['uuid']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  RiskId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditAggregate = {
  __typename?: 'risksmart_risk_action_audit_aggregate';
  aggregate?: Maybe<RisksmartRiskActionAuditAggregateFields>;
  nodes: Array<RisksmartRiskActionAudit>;
};

/** aggregate fields of "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditAggregateFields = {
  __typename?: 'risksmart_risk_action_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RisksmartRiskActionAuditMaxFields>;
  min?: Maybe<RisksmartRiskActionAuditMinFields>;
};


/** aggregate fields of "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RisksmartRiskActionAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.risk_action_audit". All fields are combined with a logical 'AND'. */
export type RisksmartRiskActionAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  ActionId?: InputMaybe<UuidComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RiskId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<RisksmartRiskActionAuditBoolExp>>;
  _not?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
  _or?: InputMaybe<Array<RisksmartRiskActionAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.risk_action_audit" */
export enum RisksmartRiskActionAuditConstraint {
  /** unique or primary key constraint on columns "ActionId", "ModifiedAtTimestamp", "RiskId" */
  RiskActionAuditPkey = 'risk_action_audit_pkey'
}

/** input type for inserting data into table "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type RisksmartRiskActionAuditMaxFields = {
  __typename?: 'risksmart_risk_action_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RiskId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type RisksmartRiskActionAuditMinFields = {
  __typename?: 'risksmart_risk_action_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  ActionId?: Maybe<Scalars['uuid']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RiskId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditMutationResponse = {
  __typename?: 'risksmart_risk_action_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RisksmartRiskActionAudit>;
};

/** on_conflict condition type for table "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditOnConflict = {
  constraint: RisksmartRiskActionAuditConstraint;
  update_columns?: Array<RisksmartRiskActionAuditUpdateColumn>;
  where?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.risk_action_audit". */
export type RisksmartRiskActionAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  ActionId?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RiskId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.risk_action_audit */
export type RisksmartRiskActionAuditPkColumnsInput = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  RiskId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.risk_action_audit" */
export enum RisksmartRiskActionAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RiskId = 'RiskId'
}

/** input type for updating data in table "risksmart.risk_action_audit" */
export type RisksmartRiskActionAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "risksmart_risk_action_audit" */
export type RisksmartRiskActionAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RisksmartRiskActionAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RisksmartRiskActionAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  ActionId?: InputMaybe<Scalars['uuid']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RiskId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.risk_action_audit" */
export enum RisksmartRiskActionAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  ActionId = 'ActionId',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RiskId = 'RiskId'
}

export type RisksmartRiskActionAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RisksmartRiskActionAuditSetInput>;
  /** filter the rows which have to be updated */
  where: RisksmartRiskActionAuditBoolExp;
};

/** columns and relationships of "risksmart.role_access" */
export type RoleAccess = {
  __typename?: 'role_access';
  AccessType: AccessTypeEnum;
  ContributorType: ContributorTypeEnum;
  ObjectType: ParentTypeEnum;
  RoleKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.role_access" */
export type RoleAccessAggregate = {
  __typename?: 'role_access_aggregate';
  aggregate?: Maybe<RoleAccessAggregateFields>;
  nodes: Array<RoleAccess>;
};

/** aggregate fields of "risksmart.role_access" */
export type RoleAccessAggregateFields = {
  __typename?: 'role_access_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<RoleAccessMaxFields>;
  min?: Maybe<RoleAccessMinFields>;
};


/** aggregate fields of "risksmart.role_access" */
export type RoleAccessAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<RoleAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.role_access". All fields are combined with a logical 'AND'. */
export type RoleAccessBoolExp = {
  AccessType?: InputMaybe<AccessTypeEnumComparisonExp>;
  ContributorType?: InputMaybe<ContributorTypeEnumComparisonExp>;
  ObjectType?: InputMaybe<ParentTypeEnumComparisonExp>;
  RoleKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<RoleAccessBoolExp>>;
  _not?: InputMaybe<RoleAccessBoolExp>;
  _or?: InputMaybe<Array<RoleAccessBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.role_access" */
export enum RoleAccessConstraint {
  /** unique or primary key constraint on columns "ContributorType", "ObjectType", "RoleKey", "AccessType" */
  RoleAccessPkey = 'role_access_pkey'
}

/** input type for inserting data into table "risksmart.role_access" */
export type RoleAccessInsertInput = {
  AccessType?: InputMaybe<AccessTypeEnum>;
  ContributorType?: InputMaybe<ContributorTypeEnum>;
  ObjectType?: InputMaybe<ParentTypeEnum>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type RoleAccessMaxFields = {
  __typename?: 'role_access_max_fields';
  RoleKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type RoleAccessMinFields = {
  __typename?: 'role_access_min_fields';
  RoleKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.role_access" */
export type RoleAccessMutationResponse = {
  __typename?: 'role_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<RoleAccess>;
};

/** on_conflict condition type for table "risksmart.role_access" */
export type RoleAccessOnConflict = {
  constraint: RoleAccessConstraint;
  update_columns?: Array<RoleAccessUpdateColumn>;
  where?: InputMaybe<RoleAccessBoolExp>;
};

/** Ordering options when selecting data from "risksmart.role_access". */
export type RoleAccessOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  ContributorType?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.role_access */
export type RoleAccessPkColumnsInput = {
  AccessType: AccessTypeEnum;
  ContributorType: ContributorTypeEnum;
  ObjectType: ParentTypeEnum;
  RoleKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.role_access" */
export enum RoleAccessSelectColumn {
  /** column name */
  AccessType = 'AccessType',
  /** column name */
  ContributorType = 'ContributorType',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  RoleKey = 'RoleKey'
}

/** input type for updating data in table "risksmart.role_access" */
export type RoleAccessSetInput = {
  AccessType?: InputMaybe<AccessTypeEnum>;
  ContributorType?: InputMaybe<ContributorTypeEnum>;
  ObjectType?: InputMaybe<ParentTypeEnum>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "role_access" */
export type RoleAccessStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: RoleAccessStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type RoleAccessStreamCursorValueInput = {
  AccessType?: InputMaybe<AccessTypeEnum>;
  ContributorType?: InputMaybe<ContributorTypeEnum>;
  ObjectType?: InputMaybe<ParentTypeEnum>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.role_access" */
export enum RoleAccessUpdateColumn {
  /** column name */
  AccessType = 'AccessType',
  /** column name */
  ContributorType = 'ContributorType',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  RoleKey = 'RoleKey'
}

export type RoleAccessUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<RoleAccessSetInput>;
  /** filter the rows which have to be updated */
  where: RoleAccessBoolExp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type SmallintComparisonExp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

export type SubscriptionRoot = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "risksmart.acceptance" */
  acceptance: Array<Acceptance>;
  /** fetch aggregated fields from the table: "risksmart.acceptance" */
  acceptance_aggregate: AcceptanceAggregate;
  /** fetch data from the table: "risksmart.acceptance_audit" */
  acceptance_audit: Array<AcceptanceAudit>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_audit" */
  acceptance_audit_aggregate: AcceptanceAuditAggregate;
  /** fetch data from the table: "risksmart.acceptance_audit" using primary key columns */
  acceptance_audit_by_pk?: Maybe<AcceptanceAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_audit" */
  acceptance_audit_stream: Array<AcceptanceAudit>;
  /** fetch data from the table: "risksmart.acceptance" using primary key columns */
  acceptance_by_pk?: Maybe<Acceptance>;
  /** fetch data from the table: "risksmart.acceptance_parent" */
  acceptance_parent: Array<AcceptanceParent>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_parent" */
  acceptance_parent_aggregate: AcceptanceParentAggregate;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit: Array<AcceptanceParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit_aggregate: AcceptanceParentAuditAggregate;
  /** fetch data from the table: "risksmart.acceptance_parent_audit" using primary key columns */
  acceptance_parent_audit_by_pk?: Maybe<AcceptanceParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_parent_audit" */
  acceptance_parent_audit_stream: Array<AcceptanceParentAudit>;
  /** fetch data from the table: "risksmart.acceptance_parent" using primary key columns */
  acceptance_parent_by_pk?: Maybe<AcceptanceParent>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_parent" */
  acceptance_parent_stream: Array<AcceptanceParent>;
  /** fetch data from the table: "risksmart.acceptance_status" */
  acceptance_status: Array<AcceptanceStatus>;
  /** fetch aggregated fields from the table: "risksmart.acceptance_status" */
  acceptance_status_aggregate: AcceptanceStatusAggregate;
  /** fetch data from the table: "risksmart.acceptance_status" using primary key columns */
  acceptance_status_by_pk?: Maybe<AcceptanceStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance_status" */
  acceptance_status_stream: Array<AcceptanceStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.acceptance" */
  acceptance_stream: Array<Acceptance>;
  /** fetch data from the table: "risksmart.access_type" */
  access_type: Array<AccessType>;
  /** fetch aggregated fields from the table: "risksmart.access_type" */
  access_type_aggregate: AccessTypeAggregate;
  /** fetch data from the table: "risksmart.access_type" using primary key columns */
  access_type_by_pk?: Maybe<AccessType>;
  /** fetch data from the table in a streaming manner: "risksmart.access_type" */
  access_type_stream: Array<AccessType>;
  /** fetch data from the table: "risksmart.action" */
  action: Array<Action>;
  /** fetch aggregated fields from the table: "risksmart.action" */
  action_aggregate: ActionAggregate;
  /** fetch data from the table: "risksmart.action_audit" */
  action_audit: Array<ActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.action_audit" */
  action_audit_aggregate: ActionAuditAggregate;
  /** fetch data from the table: "risksmart.action_audit" using primary key columns */
  action_audit_by_pk?: Maybe<ActionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.action_audit" */
  action_audit_stream: Array<ActionAudit>;
  /** fetch data from the table: "risksmart.action" using primary key columns */
  action_by_pk?: Maybe<Action>;
  /** fetch data from the table: "risksmart.action_parent" */
  action_parent: Array<ActionParent>;
  /** fetch aggregated fields from the table: "risksmart.action_parent" */
  action_parent_aggregate: ActionParentAggregate;
  /** fetch data from the table: "risksmart.action_parent_audit" */
  action_parent_audit: Array<ActionParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.action_parent_audit" */
  action_parent_audit_aggregate: ActionParentAuditAggregate;
  /** fetch data from the table: "risksmart.action_parent_audit" using primary key columns */
  action_parent_audit_by_pk?: Maybe<ActionParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.action_parent_audit" */
  action_parent_audit_stream: Array<ActionParentAudit>;
  /** fetch data from the table: "risksmart.action_parent" using primary key columns */
  action_parent_by_pk?: Maybe<ActionParent>;
  /** fetch data from the table in a streaming manner: "risksmart.action_parent" */
  action_parent_stream: Array<ActionParent>;
  /** fetch data from the table: "risksmart.action_status" */
  action_status: Array<ActionStatus>;
  /** fetch aggregated fields from the table: "risksmart.action_status" */
  action_status_aggregate: ActionStatusAggregate;
  /** fetch data from the table: "risksmart.action_status" using primary key columns */
  action_status_by_pk?: Maybe<ActionStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.action_status" */
  action_status_stream: Array<ActionStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.action" */
  action_stream: Array<Action>;
  /** fetch data from the table: "risksmart.action_update" */
  action_update: Array<ActionUpdate>;
  /** fetch aggregated fields from the table: "risksmart.action_update" */
  action_update_aggregate: ActionUpdateAggregate;
  /** fetch data from the table: "risksmart.action_update_audit" */
  action_update_audit: Array<ActionUpdateAudit>;
  /** fetch aggregated fields from the table: "risksmart.action_update_audit" */
  action_update_audit_aggregate: ActionUpdateAuditAggregate;
  /** fetch data from the table: "risksmart.action_update_audit" using primary key columns */
  action_update_audit_by_pk?: Maybe<ActionUpdateAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.action_update_audit" */
  action_update_audit_stream: Array<ActionUpdateAudit>;
  /** fetch data from the table: "risksmart.action_update" using primary key columns */
  action_update_by_pk?: Maybe<ActionUpdate>;
  /** fetch data from the table in a streaming manner: "risksmart.action_update" */
  action_update_stream: Array<ActionUpdate>;
  /** fetch data from the table: "risksmart.action_update_summary_view" */
  action_update_summary: Array<ActionUpdateSummary>;
  /** fetch aggregated fields from the table: "risksmart.action_update_summary_view" */
  action_update_summary_aggregate: ActionUpdateSummaryAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.action_update_summary_view" */
  action_update_summary_stream: Array<ActionUpdateSummary>;
  /** fetch data from the table: "risksmart.aggregation_org" */
  aggregation_org: Array<AggregationOrg>;
  /** fetch aggregated fields from the table: "risksmart.aggregation_org" */
  aggregation_org_aggregate: AggregationOrgAggregate;
  /** fetch data from the table: "risksmart.aggregation_org" using primary key columns */
  aggregation_org_by_pk?: Maybe<AggregationOrg>;
  /** fetch data from the table in a streaming manner: "risksmart.aggregation_org" */
  aggregation_org_stream: Array<AggregationOrg>;
  /** fetch data from the table: "risksmart.ancestor_contributor_view" */
  ancestor_contributor: Array<AncestorContributor>;
  /** fetch aggregated fields from the table: "risksmart.ancestor_contributor_view" */
  ancestor_contributor_aggregate: AncestorContributorAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.ancestor_contributor_view" */
  ancestor_contributor_stream: Array<AncestorContributor>;
  /** fetch data from the table: "risksmart.appetite" */
  appetite: Array<Appetite>;
  /** fetch aggregated fields from the table: "risksmart.appetite" */
  appetite_aggregate: AppetiteAggregate;
  /** fetch data from the table: "risksmart.appetite_audit" */
  appetite_audit: Array<AppetiteAudit>;
  /** fetch aggregated fields from the table: "risksmart.appetite_audit" */
  appetite_audit_aggregate: AppetiteAuditAggregate;
  /** fetch data from the table: "risksmart.appetite_audit" using primary key columns */
  appetite_audit_by_pk?: Maybe<AppetiteAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_audit" */
  appetite_audit_stream: Array<AppetiteAudit>;
  /** fetch data from the table: "risksmart.appetite" using primary key columns */
  appetite_by_pk?: Maybe<Appetite>;
  /** fetch data from the table: "risksmart.appetite_model" */
  appetite_model: Array<AppetiteModel>;
  /** fetch aggregated fields from the table: "risksmart.appetite_model" */
  appetite_model_aggregate: AppetiteModelAggregate;
  /** fetch data from the table: "risksmart.appetite_model" using primary key columns */
  appetite_model_by_pk?: Maybe<AppetiteModel>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_model" */
  appetite_model_stream: Array<AppetiteModel>;
  /** fetch data from the table: "risksmart.appetite_parent" */
  appetite_parent: Array<AppetiteParent>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent" */
  appetite_parent_aggregate: AppetiteParentAggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit: Array<AppetiteParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.appetite_parent_audit" */
  appetite_parent_audit_aggregate: AppetiteParentAuditAggregate;
  /** fetch data from the table: "risksmart.appetite_parent_audit" using primary key columns */
  appetite_parent_audit_by_pk?: Maybe<AppetiteParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_parent_audit" */
  appetite_parent_audit_stream: Array<AppetiteParentAudit>;
  /** fetch data from the table: "risksmart.appetite_parent" using primary key columns */
  appetite_parent_by_pk?: Maybe<AppetiteParent>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_parent" */
  appetite_parent_stream: Array<AppetiteParent>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite" */
  appetite_stream: Array<Appetite>;
  /** fetch data from the table: "risksmart.appetite_type" */
  appetite_type: Array<AppetiteType>;
  /** fetch aggregated fields from the table: "risksmart.appetite_type" */
  appetite_type_aggregate: AppetiteTypeAggregate;
  /** fetch data from the table: "risksmart.appetite_type" using primary key columns */
  appetite_type_by_pk?: Maybe<AppetiteType>;
  /** fetch data from the table in a streaming manner: "risksmart.appetite_type" */
  appetite_type_stream: Array<AppetiteType>;
  /** fetch data from the table: "risksmart.approval" */
  approval: Array<Approval>;
  /** fetch aggregated fields from the table: "risksmart.approval" */
  approval_aggregate: ApprovalAggregate;
  /** fetch data from the table: "risksmart.approval_audit" */
  approval_audit: Array<ApprovalAudit>;
  /** fetch aggregated fields from the table: "risksmart.approval_audit" */
  approval_audit_aggregate: ApprovalAuditAggregate;
  /** fetch data from the table: "risksmart.approval_audit" using primary key columns */
  approval_audit_by_pk?: Maybe<ApprovalAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_audit" */
  approval_audit_stream: Array<ApprovalAudit>;
  /** fetch data from the table: "risksmart.approval" using primary key columns */
  approval_by_pk?: Maybe<Approval>;
  /** fetch data from the table: "risksmart.approval_in_flight_edit_rule" */
  approval_in_flight_edit_rule: Array<ApprovalInFlightEditRule>;
  /** fetch aggregated fields from the table: "risksmart.approval_in_flight_edit_rule" */
  approval_in_flight_edit_rule_aggregate: ApprovalInFlightEditRuleAggregate;
  /** fetch data from the table: "risksmart.approval_in_flight_edit_rule" using primary key columns */
  approval_in_flight_edit_rule_by_pk?: Maybe<ApprovalInFlightEditRule>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_in_flight_edit_rule" */
  approval_in_flight_edit_rule_stream: Array<ApprovalInFlightEditRule>;
  /** fetch data from the table: "risksmart.approval_level" */
  approval_level: Array<ApprovalLevel>;
  /** fetch aggregated fields from the table: "risksmart.approval_level" */
  approval_level_aggregate: ApprovalLevelAggregate;
  /** fetch data from the table: "risksmart.approval_level_audit" */
  approval_level_audit: Array<ApprovalLevelAudit>;
  /** fetch aggregated fields from the table: "risksmart.approval_level_audit" */
  approval_level_audit_aggregate: ApprovalLevelAuditAggregate;
  /** fetch data from the table: "risksmart.approval_level_audit" using primary key columns */
  approval_level_audit_by_pk?: Maybe<ApprovalLevelAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_level_audit" */
  approval_level_audit_stream: Array<ApprovalLevelAudit>;
  /** fetch data from the table: "risksmart.approval_level" using primary key columns */
  approval_level_by_pk?: Maybe<ApprovalLevel>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_level" */
  approval_level_stream: Array<ApprovalLevel>;
  /** fetch data from the table: "risksmart.approval_rule_type" */
  approval_rule_type: Array<ApprovalRuleType>;
  /** fetch aggregated fields from the table: "risksmart.approval_rule_type" */
  approval_rule_type_aggregate: ApprovalRuleTypeAggregate;
  /** fetch data from the table: "risksmart.approval_rule_type" using primary key columns */
  approval_rule_type_by_pk?: Maybe<ApprovalRuleType>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_rule_type" */
  approval_rule_type_stream: Array<ApprovalRuleType>;
  /** fetch data from the table: "risksmart.approval_status" */
  approval_status: Array<ApprovalStatus>;
  /** fetch aggregated fields from the table: "risksmart.approval_status" */
  approval_status_aggregate: ApprovalStatusAggregate;
  /** fetch data from the table: "risksmart.approval_status" using primary key columns */
  approval_status_by_pk?: Maybe<ApprovalStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.approval_status" */
  approval_status_stream: Array<ApprovalStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.approval" */
  approval_stream: Array<Approval>;
  /** fetch data from the table: "risksmart.approver" */
  approver: Array<Approver>;
  /** fetch aggregated fields from the table: "risksmart.approver" */
  approver_aggregate: ApproverAggregate;
  /** fetch data from the table: "risksmart.approver_audit" */
  approver_audit: Array<ApproverAudit>;
  /** fetch aggregated fields from the table: "risksmart.approver_audit" */
  approver_audit_aggregate: ApproverAuditAggregate;
  /** fetch data from the table: "risksmart.approver_audit" using primary key columns */
  approver_audit_by_pk?: Maybe<ApproverAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.approver_audit" */
  approver_audit_stream: Array<ApproverAudit>;
  /** fetch data from the table: "risksmart.approver" using primary key columns */
  approver_by_pk?: Maybe<Approver>;
  /** fetch data from the table: "risksmart.approver_response" */
  approver_response: Array<ApproverResponse>;
  /** fetch aggregated fields from the table: "risksmart.approver_response" */
  approver_response_aggregate: ApproverResponseAggregate;
  /** fetch data from the table: "risksmart.approver_response" using primary key columns */
  approver_response_by_pk?: Maybe<ApproverResponse>;
  /** fetch data from the table in a streaming manner: "risksmart.approver_response" */
  approver_response_stream: Array<ApproverResponse>;
  /** fetch data from the table in a streaming manner: "risksmart.approver" */
  approver_stream: Array<Approver>;
  /** fetch data from the table: "risksmart.assessment" */
  assessment: Array<Assessment>;
  /** fetch data from the table: "risksmart.assessment_activity" */
  assessment_activity: Array<AssessmentActivity>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity" */
  assessment_activity_aggregate: AssessmentActivityAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_audit" */
  assessment_activity_audit: Array<AssessmentActivityAudit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity_audit" */
  assessment_activity_audit_aggregate: AssessmentActivityAuditAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_audit" using primary key columns */
  assessment_activity_audit_by_pk?: Maybe<AssessmentActivityAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_activity_audit" */
  assessment_activity_audit_stream: Array<AssessmentActivityAudit>;
  /** fetch data from the table: "risksmart.assessment_activity" using primary key columns */
  assessment_activity_by_pk?: Maybe<AssessmentActivity>;
  /** fetch data from the table: "risksmart.assessment_activity_status" */
  assessment_activity_status: Array<AssessmentActivityStatus>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity_status" */
  assessment_activity_status_aggregate: AssessmentActivityStatusAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_status" using primary key columns */
  assessment_activity_status_by_pk?: Maybe<AssessmentActivityStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_activity_status" */
  assessment_activity_status_stream: Array<AssessmentActivityStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_activity" */
  assessment_activity_stream: Array<AssessmentActivity>;
  /** fetch data from the table: "risksmart.assessment_activity_type" */
  assessment_activity_type: Array<AssessmentActivityType>;
  /** fetch aggregated fields from the table: "risksmart.assessment_activity_type" */
  assessment_activity_type_aggregate: AssessmentActivityTypeAggregate;
  /** fetch data from the table: "risksmart.assessment_activity_type" using primary key columns */
  assessment_activity_type_by_pk?: Maybe<AssessmentActivityType>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_activity_type" */
  assessment_activity_type_stream: Array<AssessmentActivityType>;
  /** fetch aggregated fields from the table: "risksmart.assessment" */
  assessment_aggregate: AssessmentAggregate;
  /** fetch data from the table: "risksmart.assessment_audit" */
  assessment_audit: Array<AssessmentAudit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_audit" */
  assessment_audit_aggregate: AssessmentAuditAggregate;
  /** fetch data from the table: "risksmart.assessment_audit" using primary key columns */
  assessment_audit_by_pk?: Maybe<AssessmentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_audit" */
  assessment_audit_stream: Array<AssessmentAudit>;
  /** fetch data from the table: "risksmart.assessment" using primary key columns */
  assessment_by_pk?: Maybe<Assessment>;
  /** fetch data from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent: Array<AssessmentResultParent>;
  /** fetch aggregated fields from the table: "risksmart.assessment_result_parent" */
  assessment_result_parent_aggregate: AssessmentResultParentAggregate;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit: Array<AssessmentResultParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit_aggregate: AssessmentResultParentAuditAggregate;
  /** fetch data from the table: "risksmart.assessment_result_parent_audit" using primary key columns */
  assessment_result_parent_audit_by_pk?: Maybe<AssessmentResultParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_result_parent_audit" */
  assessment_result_parent_audit_stream: Array<AssessmentResultParentAudit>;
  /** fetch data from the table: "risksmart.assessment_result_parent" using primary key columns */
  assessment_result_parent_by_pk?: Maybe<AssessmentResultParent>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_result_parent" */
  assessment_result_parent_stream: Array<AssessmentResultParent>;
  /** fetch data from the table: "risksmart.assessment_status" */
  assessment_status: Array<AssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.assessment_status" */
  assessment_status_aggregate: AssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.assessment_status" using primary key columns */
  assessment_status_by_pk?: Maybe<AssessmentStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment_status" */
  assessment_status_stream: Array<AssessmentStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.assessment" */
  assessment_stream: Array<Assessment>;
  /** fetch data from the table: "risksmart.attestation_config" */
  attestation_config: Array<AttestationConfig>;
  /** fetch aggregated fields from the table: "risksmart.attestation_config" */
  attestation_config_aggregate: AttestationConfigAggregate;
  /** fetch data from the table: "risksmart.attestation_config" using primary key columns */
  attestation_config_by_pk?: Maybe<AttestationConfig>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_config" */
  attestation_config_stream: Array<AttestationConfig>;
  /** fetch data from the table: "risksmart.attestation_group" */
  attestation_group: Array<AttestationGroup>;
  /** fetch aggregated fields from the table: "risksmart.attestation_group" */
  attestation_group_aggregate: AttestationGroupAggregate;
  /** fetch data from the table: "risksmart.attestation_group" using primary key columns */
  attestation_group_by_pk?: Maybe<AttestationGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_group" */
  attestation_group_stream: Array<AttestationGroup>;
  /** fetch data from the table: "risksmart.attestation_record" */
  attestation_record: Array<AttestationRecord>;
  /** fetch aggregated fields from the table: "risksmart.attestation_record" */
  attestation_record_aggregate: AttestationRecordAggregate;
  /** fetch data from the table: "risksmart.attestation_record" using primary key columns */
  attestation_record_by_pk?: Maybe<AttestationRecord>;
  /** fetch data from the table: "risksmart.attestation_record_status" */
  attestation_record_status: Array<AttestationRecordStatus>;
  /** fetch aggregated fields from the table: "risksmart.attestation_record_status" */
  attestation_record_status_aggregate: AttestationRecordStatusAggregate;
  /** fetch data from the table: "risksmart.attestation_record_status" using primary key columns */
  attestation_record_status_by_pk?: Maybe<AttestationRecordStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_record_status" */
  attestation_record_status_stream: Array<AttestationRecordStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.attestation_record" */
  attestation_record_stream: Array<AttestationRecord>;
  /** fetch data from the table: "risksmart.audit_log_view" */
  audit_log_view: Array<AuditLogView>;
  /** fetch aggregated fields from the table: "risksmart.audit_log_view" */
  audit_log_view_aggregate: AuditLogViewAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.audit_log_view" */
  audit_log_view_stream: Array<AuditLogView>;
  /** fetch data from the table: "auth.organisation" */
  auth_organisation: Array<AuthOrganisation>;
  /** fetch aggregated fields from the table: "auth.organisation" */
  auth_organisation_aggregate: AuthOrganisationAggregate;
  /** fetch data from the table: "auth.organisation_audit" */
  auth_organisation_audit: Array<AuthOrganisationAudit>;
  /** fetch aggregated fields from the table: "auth.organisation_audit" */
  auth_organisation_audit_aggregate: AuthOrganisationAuditAggregate;
  /** fetch data from the table: "auth.organisation_audit" using primary key columns */
  auth_organisation_audit_by_pk?: Maybe<AuthOrganisationAudit>;
  /** fetch data from the table in a streaming manner: "auth.organisation_audit" */
  auth_organisation_audit_stream: Array<AuthOrganisationAudit>;
  /** fetch data from the table: "auth.organisation" using primary key columns */
  auth_organisation_by_pk?: Maybe<AuthOrganisation>;
  /** fetch data from the table in a streaming manner: "auth.organisation" */
  auth_organisation_stream: Array<AuthOrganisation>;
  /** fetch data from the table: "auth.organisationuser" */
  auth_organisationuser: Array<AuthOrganisationuser>;
  /** fetch aggregated fields from the table: "auth.organisationuser" */
  auth_organisationuser_aggregate: AuthOrganisationuserAggregate;
  /** fetch data from the table: "auth.organisationuser_audit" */
  auth_organisationuser_audit: Array<AuthOrganisationuserAudit>;
  /** fetch aggregated fields from the table: "auth.organisationuser_audit" */
  auth_organisationuser_audit_aggregate: AuthOrganisationuserAuditAggregate;
  /** fetch data from the table: "auth.organisationuser_audit" using primary key columns */
  auth_organisationuser_audit_by_pk?: Maybe<AuthOrganisationuserAudit>;
  /** fetch data from the table in a streaming manner: "auth.organisationuser_audit" */
  auth_organisationuser_audit_stream: Array<AuthOrganisationuserAudit>;
  /** fetch data from the table: "auth.organisationuser" using primary key columns */
  auth_organisationuser_by_pk?: Maybe<AuthOrganisationuser>;
  /** fetch data from the table in a streaming manner: "auth.organisationuser" */
  auth_organisationuser_stream: Array<AuthOrganisationuser>;
  /** fetch data from the table: "auth.user" */
  auth_user: Array<AuthUser>;
  /** fetch data from the table: "auth.user_activity_audit" */
  auth_user_activity_audit: Array<AuthUserActivityAudit>;
  /** fetch aggregated fields from the table: "auth.user_activity_audit" */
  auth_user_activity_audit_aggregate: AuthUserActivityAuditAggregate;
  /** fetch data from the table: "auth.user_activity_audit" using primary key columns */
  auth_user_activity_audit_by_pk?: Maybe<AuthUserActivityAudit>;
  /** fetch data from the table in a streaming manner: "auth.user_activity_audit" */
  auth_user_activity_audit_stream: Array<AuthUserActivityAudit>;
  /** fetch aggregated fields from the table: "auth.user" */
  auth_user_aggregate: AuthUserAggregate;
  /** fetch data from the table: "auth.user_audit" */
  auth_user_audit: Array<AuthUserAudit>;
  /** fetch aggregated fields from the table: "auth.user_audit" */
  auth_user_audit_aggregate: AuthUserAuditAggregate;
  /** fetch data from the table: "auth.user_audit" using primary key columns */
  auth_user_audit_by_pk?: Maybe<AuthUserAudit>;
  /** fetch data from the table in a streaming manner: "auth.user_audit" */
  auth_user_audit_stream: Array<AuthUserAudit>;
  /** fetch data from the table: "auth.user" using primary key columns */
  auth_user_by_pk?: Maybe<AuthUser>;
  /** fetch data from the table in a streaming manner: "auth.user" */
  auth_user_stream: Array<AuthUser>;
  /** fetch data from the table: "risksmart.business_area" */
  business_area: Array<BusinessArea>;
  /** fetch aggregated fields from the table: "risksmart.business_area" */
  business_area_aggregate: BusinessAreaAggregate;
  /** fetch data from the table: "risksmart.business_area" using primary key columns */
  business_area_by_pk?: Maybe<BusinessArea>;
  /** fetch data from the table in a streaming manner: "risksmart.business_area" */
  business_area_stream: Array<BusinessArea>;
  /** fetch data from the table: "risksmart.cause" */
  cause: Array<Cause>;
  /** fetch aggregated fields from the table: "risksmart.cause" */
  cause_aggregate: CauseAggregate;
  /** fetch data from the table: "risksmart.cause_audit" */
  cause_audit: Array<CauseAudit>;
  /** fetch aggregated fields from the table: "risksmart.cause_audit" */
  cause_audit_aggregate: CauseAuditAggregate;
  /** fetch data from the table: "risksmart.cause_audit" using primary key columns */
  cause_audit_by_pk?: Maybe<CauseAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.cause_audit" */
  cause_audit_stream: Array<CauseAudit>;
  /** fetch data from the table: "risksmart.cause" using primary key columns */
  cause_by_pk?: Maybe<Cause>;
  /** fetch data from the table in a streaming manner: "risksmart.cause" */
  cause_stream: Array<Cause>;
  /** fetch data from the table: "risksmart.change_request" */
  change_request: Array<ChangeRequest>;
  /** fetch aggregated fields from the table: "risksmart.change_request" */
  change_request_aggregate: ChangeRequestAggregate;
  /** fetch data from the table: "risksmart.change_request_audit" */
  change_request_audit: Array<ChangeRequestAudit>;
  /** fetch aggregated fields from the table: "risksmart.change_request_audit" */
  change_request_audit_aggregate: ChangeRequestAuditAggregate;
  /** fetch data from the table: "risksmart.change_request_audit" using primary key columns */
  change_request_audit_by_pk?: Maybe<ChangeRequestAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request_audit" */
  change_request_audit_stream: Array<ChangeRequestAudit>;
  /** fetch data from the table: "risksmart.change_request" using primary key columns */
  change_request_by_pk?: Maybe<ChangeRequest>;
  /** fetch data from the table: "risksmart.change_request_contributor" */
  change_request_contributor: Array<ChangeRequestContributor>;
  /** fetch aggregated fields from the table: "risksmart.change_request_contributor" */
  change_request_contributor_aggregate: ChangeRequestContributorAggregate;
  /** fetch data from the table: "risksmart.change_request_contributor" using primary key columns */
  change_request_contributor_by_pk?: Maybe<ChangeRequestContributor>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request_contributor" */
  change_request_contributor_stream: Array<ChangeRequestContributor>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request" */
  change_request_stream: Array<ChangeRequest>;
  /** fetch data from the table: "risksmart.comment" */
  comment: Array<Comment>;
  /** fetch aggregated fields from the table: "risksmart.comment" */
  comment_aggregate: CommentAggregate;
  /** fetch data from the table: "risksmart.comment_audit" */
  comment_audit: Array<CommentAudit>;
  /** fetch aggregated fields from the table: "risksmart.comment_audit" */
  comment_audit_aggregate: CommentAuditAggregate;
  /** fetch data from the table: "risksmart.comment_audit" using primary key columns */
  comment_audit_by_pk?: Maybe<CommentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.comment_audit" */
  comment_audit_stream: Array<CommentAudit>;
  /** fetch data from the table: "risksmart.comment" using primary key columns */
  comment_by_pk?: Maybe<Comment>;
  /** fetch data from the table in a streaming manner: "risksmart.comment" */
  comment_stream: Array<Comment>;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment: Array<ComplianceMonitoringAssessment>;
  /** fetch aggregated fields from the table: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment_aggregate: ComplianceMonitoringAssessmentAggregate;
  /** fetch data from the table: "risksmart.compliance_monitoring_assessment" using primary key columns */
  compliance_monitoring_assessment_by_pk?: Maybe<ComplianceMonitoringAssessment>;
  /** fetch data from the table in a streaming manner: "risksmart.compliance_monitoring_assessment" */
  compliance_monitoring_assessment_stream: Array<ComplianceMonitoringAssessment>;
  /** fetch data from the table: "config.env" */
  config_env: Array<ConfigEnv>;
  /** fetch aggregated fields from the table: "config.env" */
  config_env_aggregate: ConfigEnvAggregate;
  /** fetch data from the table: "config.env" using primary key columns */
  config_env_by_pk?: Maybe<ConfigEnv>;
  /** fetch data from the table in a streaming manner: "config.env" */
  config_env_stream: Array<ConfigEnv>;
  /** fetch data from the table: "risksmart.consequence" */
  consequence: Array<Consequence>;
  /** fetch aggregated fields from the table: "risksmart.consequence" */
  consequence_aggregate: ConsequenceAggregate;
  /** fetch data from the table: "risksmart.consequence_audit" */
  consequence_audit: Array<ConsequenceAudit>;
  /** fetch aggregated fields from the table: "risksmart.consequence_audit" */
  consequence_audit_aggregate: ConsequenceAuditAggregate;
  /** fetch data from the table: "risksmart.consequence_audit" using primary key columns */
  consequence_audit_by_pk?: Maybe<ConsequenceAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.consequence_audit" */
  consequence_audit_stream: Array<ConsequenceAudit>;
  /** fetch data from the table: "risksmart.consequence" using primary key columns */
  consequence_by_pk?: Maybe<Consequence>;
  /** fetch data from the table in a streaming manner: "risksmart.consequence" */
  consequence_stream: Array<Consequence>;
  /** fetch data from the table: "risksmart.consequence_type" */
  consequence_type: Array<ConsequenceType>;
  /** fetch aggregated fields from the table: "risksmart.consequence_type" */
  consequence_type_aggregate: ConsequenceTypeAggregate;
  /** fetch data from the table: "risksmart.consequence_type" using primary key columns */
  consequence_type_by_pk?: Maybe<ConsequenceType>;
  /** fetch data from the table in a streaming manner: "risksmart.consequence_type" */
  consequence_type_stream: Array<ConsequenceType>;
  /** fetch data from the table: "risksmart.contributor" */
  contributor: Array<Contributor>;
  /** fetch aggregated fields from the table: "risksmart.contributor" */
  contributor_aggregate: ContributorAggregate;
  /** fetch data from the table: "risksmart.contributor_audit" */
  contributor_audit: Array<ContributorAudit>;
  /** fetch aggregated fields from the table: "risksmart.contributor_audit" */
  contributor_audit_aggregate: ContributorAuditAggregate;
  /** fetch data from the table: "risksmart.contributor_audit" using primary key columns */
  contributor_audit_by_pk?: Maybe<ContributorAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.contributor_audit" */
  contributor_audit_stream: Array<ContributorAudit>;
  /** fetch data from the table: "risksmart.contributor" using primary key columns */
  contributor_by_pk?: Maybe<Contributor>;
  /** fetch data from the table: "risksmart.contributor_group" */
  contributor_group: Array<ContributorGroup>;
  /** fetch aggregated fields from the table: "risksmart.contributor_group" */
  contributor_group_aggregate: ContributorGroupAggregate;
  /** fetch data from the table: "risksmart.contributor_group_audit" */
  contributor_group_audit: Array<ContributorGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.contributor_group_audit" */
  contributor_group_audit_aggregate: ContributorGroupAuditAggregate;
  /** fetch data from the table: "risksmart.contributor_group_audit" using primary key columns */
  contributor_group_audit_by_pk?: Maybe<ContributorGroupAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.contributor_group_audit" */
  contributor_group_audit_stream: Array<ContributorGroupAudit>;
  /** fetch data from the table: "risksmart.contributor_group" using primary key columns */
  contributor_group_by_pk?: Maybe<ContributorGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.contributor_group" */
  contributor_group_stream: Array<ContributorGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.contributor" */
  contributor_stream: Array<Contributor>;
  /** fetch data from the table: "risksmart.contributor_type" */
  contributor_type: Array<ContributorType>;
  /** fetch aggregated fields from the table: "risksmart.contributor_type" */
  contributor_type_aggregate: ContributorTypeAggregate;
  /** fetch data from the table: "risksmart.contributor_type" using primary key columns */
  contributor_type_by_pk?: Maybe<ContributorType>;
  /** fetch data from the table in a streaming manner: "risksmart.contributor_type" */
  contributor_type_stream: Array<ContributorType>;
  /** fetch data from the table: "risksmart.control" */
  control: Array<Control>;
  /** fetch aggregated fields from the table: "risksmart.control" */
  control_aggregate: ControlAggregate;
  /** fetch data from the table: "risksmart.control_audit" */
  control_audit: Array<ControlAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_audit" */
  control_audit_aggregate: ControlAuditAggregate;
  /** fetch data from the table: "risksmart.control_audit" using primary key columns */
  control_audit_by_pk?: Maybe<ControlAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_audit" */
  control_audit_stream: Array<ControlAudit>;
  /** fetch data from the table: "risksmart.control" using primary key columns */
  control_by_pk?: Maybe<Control>;
  /** fetch data from the table: "risksmart.control_group" */
  control_group: Array<ControlGroup>;
  /** fetch aggregated fields from the table: "risksmart.control_group" */
  control_group_aggregate: ControlGroupAggregate;
  /** fetch data from the table: "risksmart.control_group_audit" */
  control_group_audit: Array<ControlGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_group_audit" */
  control_group_audit_aggregate: ControlGroupAuditAggregate;
  /** fetch data from the table: "risksmart.control_group_audit" using primary key columns */
  control_group_audit_by_pk?: Maybe<ControlGroupAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_group_audit" */
  control_group_audit_stream: Array<ControlGroupAudit>;
  /** fetch data from the table: "risksmart.control_group" using primary key columns */
  control_group_by_pk?: Maybe<ControlGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.control_group" */
  control_group_stream: Array<ControlGroup>;
  /** fetch data from the table: "risksmart.control_parent" */
  control_parent: Array<ControlParent>;
  /** fetch aggregated fields from the table: "risksmart.control_parent" */
  control_parent_aggregate: ControlParentAggregate;
  /** fetch data from the table: "risksmart.control_parent_audit" */
  control_parent_audit: Array<ControlParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_parent_audit" */
  control_parent_audit_aggregate: ControlParentAuditAggregate;
  /** fetch data from the table: "risksmart.control_parent_audit" using primary key columns */
  control_parent_audit_by_pk?: Maybe<ControlParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_parent_audit" */
  control_parent_audit_stream: Array<ControlParentAudit>;
  /** fetch data from the table: "risksmart.control_parent" using primary key columns */
  control_parent_by_pk?: Maybe<ControlParent>;
  /** fetch data from the table in a streaming manner: "risksmart.control_parent" */
  control_parent_stream: Array<ControlParent>;
  /** fetch data from the table in a streaming manner: "risksmart.control" */
  control_stream: Array<Control>;
  /** fetch data from the table: "risksmart.control_test_frequency" */
  control_test_frequency: Array<ControlTestFrequency>;
  /** fetch aggregated fields from the table: "risksmart.control_test_frequency" */
  control_test_frequency_aggregate: ControlTestFrequencyAggregate;
  /** fetch data from the table: "risksmart.control_test_frequency" using primary key columns */
  control_test_frequency_by_pk?: Maybe<ControlTestFrequency>;
  /** fetch data from the table in a streaming manner: "risksmart.control_test_frequency" */
  control_test_frequency_stream: Array<ControlTestFrequency>;
  /** fetch data from the table: "risksmart.control_type" */
  control_type: Array<ControlType>;
  /** fetch aggregated fields from the table: "risksmart.control_type" */
  control_type_aggregate: ControlTypeAggregate;
  /** fetch data from the table: "risksmart.control_type" using primary key columns */
  control_type_by_pk?: Maybe<ControlType>;
  /** fetch data from the table in a streaming manner: "risksmart.control_type" */
  control_type_stream: Array<ControlType>;
  /** fetch data from the table: "risksmart.conversation" */
  conversation: Array<Conversation>;
  /** fetch aggregated fields from the table: "risksmart.conversation" */
  conversation_aggregate: ConversationAggregate;
  /** fetch data from the table: "risksmart.conversation_audit" */
  conversation_audit: Array<ConversationAudit>;
  /** fetch aggregated fields from the table: "risksmart.conversation_audit" */
  conversation_audit_aggregate: ConversationAuditAggregate;
  /** fetch data from the table: "risksmart.conversation_audit" using primary key columns */
  conversation_audit_by_pk?: Maybe<ConversationAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.conversation_audit" */
  conversation_audit_stream: Array<ConversationAudit>;
  /** fetch data from the table: "risksmart.conversation" using primary key columns */
  conversation_by_pk?: Maybe<Conversation>;
  /** fetch data from the table in a streaming manner: "risksmart.conversation" */
  conversation_stream: Array<Conversation>;
  /** fetch data from the table: "risksmart.cost_type" */
  cost_type: Array<CostType>;
  /** fetch aggregated fields from the table: "risksmart.cost_type" */
  cost_type_aggregate: CostTypeAggregate;
  /** fetch data from the table: "risksmart.cost_type" using primary key columns */
  cost_type_by_pk?: Maybe<CostType>;
  /** fetch data from the table in a streaming manner: "risksmart.cost_type" */
  cost_type_stream: Array<CostType>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" */
  custom_attribute_schema: Array<CustomAttributeSchema>;
  /** fetch aggregated fields from the table: "risksmart.custom_attribute_schema" */
  custom_attribute_schema_aggregate: CustomAttributeSchemaAggregate;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit: Array<CustomAttributeSchemaAudit>;
  /** fetch aggregated fields from the table: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit_aggregate: CustomAttributeSchemaAuditAggregate;
  /** fetch data from the table: "risksmart.custom_attribute_schema_audit" using primary key columns */
  custom_attribute_schema_audit_by_pk?: Maybe<CustomAttributeSchemaAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_attribute_schema_audit" */
  custom_attribute_schema_audit_stream: Array<CustomAttributeSchemaAudit>;
  /** fetch data from the table: "risksmart.custom_attribute_schema" using primary key columns */
  custom_attribute_schema_by_pk?: Maybe<CustomAttributeSchema>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_attribute_schema" */
  custom_attribute_schema_stream: Array<CustomAttributeSchema>;
  /** fetch data from the table: "risksmart.custom_ribbon" */
  custom_ribbon: Array<CustomRibbon>;
  /** fetch aggregated fields from the table: "risksmart.custom_ribbon" */
  custom_ribbon_aggregate: CustomRibbonAggregate;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit: Array<CustomRibbonAudit>;
  /** fetch aggregated fields from the table: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit_aggregate: CustomRibbonAuditAggregate;
  /** fetch data from the table: "risksmart.custom_ribbon_audit" using primary key columns */
  custom_ribbon_audit_by_pk?: Maybe<CustomRibbonAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_ribbon_audit" */
  custom_ribbon_audit_stream: Array<CustomRibbonAudit>;
  /** fetch data from the table: "risksmart.custom_ribbon" using primary key columns */
  custom_ribbon_by_pk?: Maybe<CustomRibbon>;
  /** fetch data from the table in a streaming manner: "risksmart.custom_ribbon" */
  custom_ribbon_stream: Array<CustomRibbon>;
  /** fetch data from the table: "risksmart.dashboard" */
  dashboard: Array<Dashboard>;
  /** fetch aggregated fields from the table: "risksmart.dashboard" */
  dashboard_aggregate: DashboardAggregate;
  /** fetch data from the table: "risksmart.dashboard_audit" */
  dashboard_audit: Array<DashboardAudit>;
  /** fetch aggregated fields from the table: "risksmart.dashboard_audit" */
  dashboard_audit_aggregate: DashboardAuditAggregate;
  /** fetch data from the table: "risksmart.dashboard_audit" using primary key columns */
  dashboard_audit_by_pk?: Maybe<DashboardAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.dashboard_audit" */
  dashboard_audit_stream: Array<DashboardAudit>;
  /** fetch data from the table: "risksmart.dashboard" using primary key columns */
  dashboard_by_pk?: Maybe<Dashboard>;
  /** fetch data from the table: "risksmart.dashboard_sharing_type" */
  dashboard_sharing_type: Array<DashboardSharingType>;
  /** fetch aggregated fields from the table: "risksmart.dashboard_sharing_type" */
  dashboard_sharing_type_aggregate: DashboardSharingTypeAggregate;
  /** fetch data from the table: "risksmart.dashboard_sharing_type" using primary key columns */
  dashboard_sharing_type_by_pk?: Maybe<DashboardSharingType>;
  /** fetch data from the table in a streaming manner: "risksmart.dashboard_sharing_type" */
  dashboard_sharing_type_stream: Array<DashboardSharingType>;
  /** fetch data from the table in a streaming manner: "risksmart.dashboard" */
  dashboard_stream: Array<Dashboard>;
  /** fetch data from the table: "risksmart.data_import" */
  data_import: Array<DataImport>;
  /** fetch aggregated fields from the table: "risksmart.data_import" */
  data_import_aggregate: DataImportAggregate;
  /** fetch data from the table: "risksmart.data_import_audit" */
  data_import_audit: Array<DataImportAudit>;
  /** fetch aggregated fields from the table: "risksmart.data_import_audit" */
  data_import_audit_aggregate: DataImportAuditAggregate;
  /** fetch data from the table: "risksmart.data_import_audit" using primary key columns */
  data_import_audit_by_pk?: Maybe<DataImportAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import_audit" */
  data_import_audit_stream: Array<DataImportAudit>;
  /** fetch data from the table: "risksmart.data_import" using primary key columns */
  data_import_by_pk?: Maybe<DataImport>;
  /** fetch data from the table: "risksmart.data_import_error" */
  data_import_error: Array<DataImportError>;
  /** fetch aggregated fields from the table: "risksmart.data_import_error" */
  data_import_error_aggregate: DataImportErrorAggregate;
  /** fetch data from the table: "risksmart.data_import_error_audit" */
  data_import_error_audit: Array<DataImportErrorAudit>;
  /** fetch aggregated fields from the table: "risksmart.data_import_error_audit" */
  data_import_error_audit_aggregate: DataImportErrorAuditAggregate;
  /** fetch data from the table: "risksmart.data_import_error_audit" using primary key columns */
  data_import_error_audit_by_pk?: Maybe<DataImportErrorAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import_error_audit" */
  data_import_error_audit_stream: Array<DataImportErrorAudit>;
  /** fetch data from the table: "risksmart.data_import_error" using primary key columns */
  data_import_error_by_pk?: Maybe<DataImportError>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import_error" */
  data_import_error_stream: Array<DataImportError>;
  /** fetch data from the table: "risksmart.data_import_status" */
  data_import_status: Array<DataImportStatus>;
  /** fetch aggregated fields from the table: "risksmart.data_import_status" */
  data_import_status_aggregate: DataImportStatusAggregate;
  /** fetch data from the table: "risksmart.data_import_status" using primary key columns */
  data_import_status_by_pk?: Maybe<DataImportStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import_status" */
  data_import_status_stream: Array<DataImportStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.data_import" */
  data_import_stream: Array<DataImport>;
  /** fetch data from the table: "risksmart.department" */
  department: Array<Department>;
  /** fetch aggregated fields from the table: "risksmart.department" */
  department_aggregate: DepartmentAggregate;
  /** fetch data from the table: "risksmart.department_audit" */
  department_audit: Array<DepartmentAudit>;
  /** fetch aggregated fields from the table: "risksmart.department_audit" */
  department_audit_aggregate: DepartmentAuditAggregate;
  /** fetch data from the table: "risksmart.department_audit" using primary key columns */
  department_audit_by_pk?: Maybe<DepartmentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_audit" */
  department_audit_stream: Array<DepartmentAudit>;
  /** fetch data from the table: "risksmart.department" using primary key columns */
  department_by_pk?: Maybe<Department>;
  /** fetch data from the table in a streaming manner: "risksmart.department" */
  department_stream: Array<Department>;
  /** fetch data from the table: "risksmart.department_type" */
  department_type: Array<DepartmentType>;
  /** fetch aggregated fields from the table: "risksmart.department_type" */
  department_type_aggregate: DepartmentTypeAggregate;
  /** fetch data from the table: "risksmart.department_type_audit" */
  department_type_audit: Array<DepartmentTypeAudit>;
  /** fetch aggregated fields from the table: "risksmart.department_type_audit" */
  department_type_audit_aggregate: DepartmentTypeAuditAggregate;
  /** fetch data from the table: "risksmart.department_type_audit" using primary key columns */
  department_type_audit_by_pk?: Maybe<DepartmentTypeAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type_audit" */
  department_type_audit_stream: Array<DepartmentTypeAudit>;
  /** fetch data from the table: "risksmart.department_type" using primary key columns */
  department_type_by_pk?: Maybe<DepartmentType>;
  /** fetch data from the table: "risksmart.department_type_group" */
  department_type_group: Array<DepartmentTypeGroup>;
  /** fetch aggregated fields from the table: "risksmart.department_type_group" */
  department_type_group_aggregate: DepartmentTypeGroupAggregate;
  /** fetch data from the table: "risksmart.department_type_group_audit" */
  department_type_group_audit: Array<DepartmentTypeGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.department_type_group_audit" */
  department_type_group_audit_aggregate: DepartmentTypeGroupAuditAggregate;
  /** fetch data from the table: "risksmart.department_type_group_audit" using primary key columns */
  department_type_group_audit_by_pk?: Maybe<DepartmentTypeGroupAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type_group_audit" */
  department_type_group_audit_stream: Array<DepartmentTypeGroupAudit>;
  /** fetch data from the table: "risksmart.department_type_group" using primary key columns */
  department_type_group_by_pk?: Maybe<DepartmentTypeGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type_group" */
  department_type_group_stream: Array<DepartmentTypeGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.department_type" */
  department_type_stream: Array<DepartmentType>;
  /** fetch data from the table: "risksmart.document" */
  document: Array<Document>;
  /** fetch data from the table: "risksmart.document_action_audit" */
  document_action_audit: Array<DocumentActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_action_audit" */
  document_action_audit_aggregate: DocumentActionAuditAggregate;
  /** fetch data from the table: "risksmart.document_action_audit" using primary key columns */
  document_action_audit_by_pk?: Maybe<DocumentActionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_action_audit" */
  document_action_audit_stream: Array<DocumentActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.document" */
  document_aggregate: DocumentAggregate;
  /** fetch data from the table: "risksmart.document_assessment_result" */
  document_assessment_result: Array<DocumentAssessmentResult>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result" */
  document_assessment_result_aggregate: DocumentAssessmentResultAggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit: Array<DocumentAssessmentResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit_aggregate: DocumentAssessmentResultAuditAggregate;
  /** fetch data from the table: "risksmart.document_assessment_result_audit" using primary key columns */
  document_assessment_result_audit_by_pk?: Maybe<DocumentAssessmentResultAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_assessment_result_audit" */
  document_assessment_result_audit_stream: Array<DocumentAssessmentResultAudit>;
  /** fetch data from the table: "risksmart.document_assessment_result" using primary key columns */
  document_assessment_result_by_pk?: Maybe<DocumentAssessmentResult>;
  /** fetch data from the table in a streaming manner: "risksmart.document_assessment_result" */
  document_assessment_result_stream: Array<DocumentAssessmentResult>;
  /** fetch data from the table: "risksmart.document_assessment_status" */
  document_assessment_status: Array<DocumentAssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.document_assessment_status" */
  document_assessment_status_aggregate: DocumentAssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.document_assessment_status" using primary key columns */
  document_assessment_status_by_pk?: Maybe<DocumentAssessmentStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.document_assessment_status" */
  document_assessment_status_stream: Array<DocumentAssessmentStatus>;
  /** fetch data from the table: "risksmart.document_audit" */
  document_audit: Array<DocumentAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_audit" */
  document_audit_aggregate: DocumentAuditAggregate;
  /** fetch data from the table: "risksmart.document_audit" using primary key columns */
  document_audit_by_pk?: Maybe<DocumentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_audit" */
  document_audit_stream: Array<DocumentAudit>;
  /** fetch data from the table: "risksmart.document" using primary key columns */
  document_by_pk?: Maybe<Document>;
  /** fetch data from the table: "risksmart.document_file" */
  document_file: Array<DocumentFile>;
  /** fetch aggregated fields from the table: "risksmart.document_file" */
  document_file_aggregate: DocumentFileAggregate;
  /** fetch data from the table: "risksmart.document_file_audit" */
  document_file_audit: Array<DocumentFileAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_file_audit" */
  document_file_audit_aggregate: DocumentFileAuditAggregate;
  /** fetch data from the table: "risksmart.document_file_audit" using primary key columns */
  document_file_audit_by_pk?: Maybe<DocumentFileAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_file_audit" */
  document_file_audit_stream: Array<DocumentFileAudit>;
  /** fetch data from the table: "risksmart.document_file" using primary key columns */
  document_file_by_pk?: Maybe<DocumentFile>;
  /** fetch data from the table in a streaming manner: "risksmart.document_file" */
  document_file_stream: Array<DocumentFile>;
  /** fetch data from the table: "risksmart.document_file_type" */
  document_file_type: Array<DocumentFileType>;
  /** fetch aggregated fields from the table: "risksmart.document_file_type" */
  document_file_type_aggregate: DocumentFileTypeAggregate;
  /** fetch data from the table: "risksmart.document_file_type" using primary key columns */
  document_file_type_by_pk?: Maybe<DocumentFileType>;
  /** fetch data from the table in a streaming manner: "risksmart.document_file_type" */
  document_file_type_stream: Array<DocumentFileType>;
  /** fetch data from the table: "risksmart.document_issue_audit" */
  document_issue_audit: Array<DocumentIssueAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_issue_audit" */
  document_issue_audit_aggregate: DocumentIssueAuditAggregate;
  /** fetch data from the table: "risksmart.document_issue_audit" using primary key columns */
  document_issue_audit_by_pk?: Maybe<DocumentIssueAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_issue_audit" */
  document_issue_audit_stream: Array<DocumentIssueAudit>;
  /** fetch data from the table: "risksmart.document_linked_document" */
  document_linked_document: Array<DocumentLinkedDocument>;
  /** fetch aggregated fields from the table: "risksmart.document_linked_document" */
  document_linked_document_aggregate: DocumentLinkedDocumentAggregate;
  /** fetch data from the table: "risksmart.document_linked_document_audit" */
  document_linked_document_audit: Array<DocumentLinkedDocumentAudit>;
  /** fetch aggregated fields from the table: "risksmart.document_linked_document_audit" */
  document_linked_document_audit_aggregate: DocumentLinkedDocumentAuditAggregate;
  /** fetch data from the table: "risksmart.document_linked_document_audit" using primary key columns */
  document_linked_document_audit_by_pk?: Maybe<DocumentLinkedDocumentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.document_linked_document_audit" */
  document_linked_document_audit_stream: Array<DocumentLinkedDocumentAudit>;
  /** fetch data from the table: "risksmart.document_linked_document" using primary key columns */
  document_linked_document_by_pk?: Maybe<DocumentLinkedDocument>;
  /** fetch data from the table in a streaming manner: "risksmart.document_linked_document" */
  document_linked_document_stream: Array<DocumentLinkedDocument>;
  /** fetch data from the table in a streaming manner: "risksmart.document" */
  document_stream: Array<Document>;
  /** fetch data from the table: "risksmart.file" */
  file: Array<File>;
  /** fetch aggregated fields from the table: "risksmart.file" */
  file_aggregate: FileAggregate;
  /** fetch data from the table: "risksmart.file_audit" */
  file_audit: Array<FileAudit>;
  /** fetch aggregated fields from the table: "risksmart.file_audit" */
  file_audit_aggregate: FileAuditAggregate;
  /** fetch data from the table: "risksmart.file_audit" using primary key columns */
  file_audit_by_pk?: Maybe<FileAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.file_audit" */
  file_audit_stream: Array<FileAudit>;
  /** fetch data from the table: "risksmart.file" using primary key columns */
  file_by_pk?: Maybe<File>;
  /** fetch data from the table in a streaming manner: "risksmart.file" */
  file_stream: Array<File>;
  /** fetch data from the table: "risksmart.form_configuration" */
  form_configuration: Array<FormConfiguration>;
  /** fetch aggregated fields from the table: "risksmart.form_configuration" */
  form_configuration_aggregate: FormConfigurationAggregate;
  /** fetch data from the table: "risksmart.form_configuration_audit" */
  form_configuration_audit: Array<FormConfigurationAudit>;
  /** fetch aggregated fields from the table: "risksmart.form_configuration_audit" */
  form_configuration_audit_aggregate: FormConfigurationAuditAggregate;
  /** fetch data from the table: "risksmart.form_configuration_audit" using primary key columns */
  form_configuration_audit_by_pk?: Maybe<FormConfigurationAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_configuration_audit" */
  form_configuration_audit_stream: Array<FormConfigurationAudit>;
  /** fetch data from the table: "risksmart.form_configuration" using primary key columns */
  form_configuration_by_pk?: Maybe<FormConfiguration>;
  /** fetch data from the table in a streaming manner: "risksmart.form_configuration" */
  form_configuration_stream: Array<FormConfiguration>;
  /** fetch data from the table: "risksmart.form_field_configuration" */
  form_field_configuration: Array<FormFieldConfiguration>;
  /** fetch aggregated fields from the table: "risksmart.form_field_configuration" */
  form_field_configuration_aggregate: FormFieldConfigurationAggregate;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit: Array<FormFieldConfigurationAudit>;
  /** fetch aggregated fields from the table: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit_aggregate: FormFieldConfigurationAuditAggregate;
  /** fetch data from the table: "risksmart.form_field_configuration_audit" using primary key columns */
  form_field_configuration_audit_by_pk?: Maybe<FormFieldConfigurationAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_configuration_audit" */
  form_field_configuration_audit_stream: Array<FormFieldConfigurationAudit>;
  /** fetch data from the table: "risksmart.form_field_configuration" using primary key columns */
  form_field_configuration_by_pk?: Maybe<FormFieldConfiguration>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_configuration" */
  form_field_configuration_stream: Array<FormFieldConfiguration>;
  /** fetch data from the table: "risksmart.form_field_ordering" */
  form_field_ordering: Array<FormFieldOrdering>;
  /** fetch aggregated fields from the table: "risksmart.form_field_ordering" */
  form_field_ordering_aggregate: FormFieldOrderingAggregate;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit: Array<FormFieldOrderingAudit>;
  /** fetch aggregated fields from the table: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit_aggregate: FormFieldOrderingAuditAggregate;
  /** fetch data from the table: "risksmart.form_field_ordering_audit" using primary key columns */
  form_field_ordering_audit_by_pk?: Maybe<FormFieldOrderingAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_ordering_audit" */
  form_field_ordering_audit_stream: Array<FormFieldOrderingAudit>;
  /** fetch data from the table: "risksmart.form_field_ordering" using primary key columns */
  form_field_ordering_by_pk?: Maybe<FormFieldOrdering>;
  /** fetch data from the table in a streaming manner: "risksmart.form_field_ordering" */
  form_field_ordering_stream: Array<FormFieldOrdering>;
  /** fetch data from the table: "risksmart.impact" */
  impact: Array<Impact>;
  /** fetch aggregated fields from the table: "risksmart.impact" */
  impact_aggregate: ImpactAggregate;
  /** fetch data from the table: "risksmart.impact_audit" */
  impact_audit: Array<ImpactAudit>;
  /** fetch aggregated fields from the table: "risksmart.impact_audit" */
  impact_audit_aggregate: ImpactAuditAggregate;
  /** fetch data from the table: "risksmart.impact_audit" using primary key columns */
  impact_audit_by_pk?: Maybe<ImpactAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_audit" */
  impact_audit_stream: Array<ImpactAudit>;
  /** fetch data from the table: "risksmart.impact" using primary key columns */
  impact_by_pk?: Maybe<Impact>;
  /** fetch data from the table: "risksmart.impact_parent" */
  impact_parent: Array<ImpactParent>;
  /** fetch aggregated fields from the table: "risksmart.impact_parent" */
  impact_parent_aggregate: ImpactParentAggregate;
  /** fetch data from the table: "risksmart.impact_parent_audit" */
  impact_parent_audit: Array<ImpactParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.impact_parent_audit" */
  impact_parent_audit_aggregate: ImpactParentAuditAggregate;
  /** fetch data from the table: "risksmart.impact_parent_audit" using primary key columns */
  impact_parent_audit_by_pk?: Maybe<ImpactParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_parent_audit" */
  impact_parent_audit_stream: Array<ImpactParentAudit>;
  /** fetch data from the table: "risksmart.impact_parent" using primary key columns */
  impact_parent_by_pk?: Maybe<ImpactParent>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_parent" */
  impact_parent_stream: Array<ImpactParent>;
  /** fetch data from the table: "risksmart.impact_rating" */
  impact_rating: Array<ImpactRating>;
  /** fetch aggregated fields from the table: "risksmart.impact_rating" */
  impact_rating_aggregate: ImpactRatingAggregate;
  /** fetch data from the table: "risksmart.impact_rating_audit" */
  impact_rating_audit: Array<ImpactRatingAudit>;
  /** fetch aggregated fields from the table: "risksmart.impact_rating_audit" */
  impact_rating_audit_aggregate: ImpactRatingAuditAggregate;
  /** fetch data from the table: "risksmart.impact_rating_audit" using primary key columns */
  impact_rating_audit_by_pk?: Maybe<ImpactRatingAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_rating_audit" */
  impact_rating_audit_stream: Array<ImpactRatingAudit>;
  /** fetch data from the table: "risksmart.impact_rating" using primary key columns */
  impact_rating_by_pk?: Maybe<ImpactRating>;
  /** fetch data from the table in a streaming manner: "risksmart.impact_rating" */
  impact_rating_stream: Array<ImpactRating>;
  /** fetch data from the table in a streaming manner: "risksmart.impact" */
  impact_stream: Array<Impact>;
  /** fetch data from the table: "risksmart.indicator" */
  indicator: Array<Indicator>;
  /** fetch aggregated fields from the table: "risksmart.indicator" */
  indicator_aggregate: IndicatorAggregate;
  /** fetch data from the table: "risksmart.indicator_audit" */
  indicator_audit: Array<IndicatorAudit>;
  /** fetch aggregated fields from the table: "risksmart.indicator_audit" */
  indicator_audit_aggregate: IndicatorAuditAggregate;
  /** fetch data from the table: "risksmart.indicator_audit" using primary key columns */
  indicator_audit_by_pk?: Maybe<IndicatorAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_audit" */
  indicator_audit_stream: Array<IndicatorAudit>;
  /** fetch data from the table: "risksmart.indicator" using primary key columns */
  indicator_by_pk?: Maybe<Indicator>;
  /** fetch data from the table: "risksmart.indicator_parent" */
  indicator_parent: Array<IndicatorParent>;
  /** fetch aggregated fields from the table: "risksmart.indicator_parent" */
  indicator_parent_aggregate: IndicatorParentAggregate;
  /** fetch data from the table: "risksmart.indicator_parent" using primary key columns */
  indicator_parent_by_pk?: Maybe<IndicatorParent>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_parent" */
  indicator_parent_stream: Array<IndicatorParent>;
  /** fetch data from the table: "risksmart.indicator_result" */
  indicator_result: Array<IndicatorResult>;
  /** fetch aggregated fields from the table: "risksmart.indicator_result" */
  indicator_result_aggregate: IndicatorResultAggregate;
  /** fetch data from the table: "risksmart.indicator_result_audit" */
  indicator_result_audit: Array<IndicatorResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.indicator_result_audit" */
  indicator_result_audit_aggregate: IndicatorResultAuditAggregate;
  /** fetch data from the table: "risksmart.indicator_result_audit" using primary key columns */
  indicator_result_audit_by_pk?: Maybe<IndicatorResultAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_result_audit" */
  indicator_result_audit_stream: Array<IndicatorResultAudit>;
  /** fetch data from the table: "risksmart.indicator_result" using primary key columns */
  indicator_result_by_pk?: Maybe<IndicatorResult>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_result" */
  indicator_result_stream: Array<IndicatorResult>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator" */
  indicator_stream: Array<Indicator>;
  /** fetch data from the table: "risksmart.indicator_type" */
  indicator_type: Array<IndicatorType>;
  /** fetch aggregated fields from the table: "risksmart.indicator_type" */
  indicator_type_aggregate: IndicatorTypeAggregate;
  /** fetch data from the table: "risksmart.indicator_type" using primary key columns */
  indicator_type_by_pk?: Maybe<IndicatorType>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_type" */
  indicator_type_stream: Array<IndicatorType>;
  /** An array relationship */
  insertPermissions: Array<InsertPermissions>;
  /** An aggregate relationship */
  insertPermissions_aggregate: InsertPermissionsAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.insert_permission_view" */
  insertPermissions_stream: Array<InsertPermissions>;
  /** fetch data from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity: Array<InternalAuditEntity>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_entity" */
  internal_audit_entity_aggregate: InternalAuditEntityAggregate;
  /** fetch data from the table: "risksmart.internal_audit_entity" using primary key columns */
  internal_audit_entity_by_pk?: Maybe<InternalAuditEntity>;
  /** fetch data from the table in a streaming manner: "risksmart.internal_audit_entity" */
  internal_audit_entity_stream: Array<InternalAuditEntity>;
  /** fetch data from the table: "risksmart.internal_audit_report" */
  internal_audit_report: Array<InternalAuditReport>;
  /** fetch aggregated fields from the table: "risksmart.internal_audit_report" */
  internal_audit_report_aggregate: InternalAuditReportAggregate;
  /** fetch data from the table: "risksmart.internal_audit_report" using primary key columns */
  internal_audit_report_by_pk?: Maybe<InternalAuditReport>;
  /** fetch data from the table in a streaming manner: "risksmart.internal_audit_report" */
  internal_audit_report_stream: Array<InternalAuditReport>;
  /** fetch data from the table: "risksmart.issue" */
  issue: Array<Issue>;
  /** fetch data from the table: "risksmart.issue_action_audit" */
  issue_action_audit: Array<IssueActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_action_audit" */
  issue_action_audit_aggregate: IssueActionAuditAggregate;
  /** fetch data from the table: "risksmart.issue_action_audit" using primary key columns */
  issue_action_audit_by_pk?: Maybe<IssueActionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_action_audit" */
  issue_action_audit_stream: Array<IssueActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue" */
  issue_aggregate: IssueAggregate;
  /** fetch data from the table: "risksmart.issue_assessment" */
  issue_assessment: Array<IssueAssessment>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment" */
  issue_assessment_aggregate: IssueAssessmentAggregate;
  /** fetch data from the table: "risksmart.issue_assessment_audit" */
  issue_assessment_audit: Array<IssueAssessmentAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment_audit" */
  issue_assessment_audit_aggregate: IssueAssessmentAuditAggregate;
  /** fetch data from the table: "risksmart.issue_assessment_audit" using primary key columns */
  issue_assessment_audit_by_pk?: Maybe<IssueAssessmentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_assessment_audit" */
  issue_assessment_audit_stream: Array<IssueAssessmentAudit>;
  /** fetch data from the table: "risksmart.issue_assessment" using primary key columns */
  issue_assessment_by_pk?: Maybe<IssueAssessment>;
  /** fetch data from the table: "risksmart.issue_assessment_status" */
  issue_assessment_status: Array<IssueAssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.issue_assessment_status" */
  issue_assessment_status_aggregate: IssueAssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.issue_assessment_status" using primary key columns */
  issue_assessment_status_by_pk?: Maybe<IssueAssessmentStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_assessment_status" */
  issue_assessment_status_stream: Array<IssueAssessmentStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_assessment" */
  issue_assessment_stream: Array<IssueAssessment>;
  /** fetch data from the table: "risksmart.issue_audit" */
  issue_audit: Array<IssueAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_audit" */
  issue_audit_aggregate: IssueAuditAggregate;
  /** fetch data from the table: "risksmart.issue_audit" using primary key columns */
  issue_audit_by_pk?: Maybe<IssueAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_audit" */
  issue_audit_stream: Array<IssueAudit>;
  /** fetch data from the table: "risksmart.issue" using primary key columns */
  issue_by_pk?: Maybe<Issue>;
  /** fetch data from the table: "risksmart.issue_parent" */
  issue_parent: Array<IssueParent>;
  /** fetch aggregated fields from the table: "risksmart.issue_parent" */
  issue_parent_aggregate: IssueParentAggregate;
  /** fetch data from the table: "risksmart.issue_parent_audit" */
  issue_parent_audit: Array<IssueParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_parent_audit" */
  issue_parent_audit_aggregate: IssueParentAuditAggregate;
  /** fetch data from the table: "risksmart.issue_parent_audit" using primary key columns */
  issue_parent_audit_by_pk?: Maybe<IssueParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_parent_audit" */
  issue_parent_audit_stream: Array<IssueParentAudit>;
  /** fetch data from the table: "risksmart.issue_parent" using primary key columns */
  issue_parent_by_pk?: Maybe<IssueParent>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_parent" */
  issue_parent_stream: Array<IssueParent>;
  /** fetch data from the table in a streaming manner: "risksmart.issue" */
  issue_stream: Array<Issue>;
  /** fetch data from the table: "risksmart.issue_update" */
  issue_update: Array<IssueUpdate>;
  /** fetch aggregated fields from the table: "risksmart.issue_update" */
  issue_update_aggregate: IssueUpdateAggregate;
  /** fetch data from the table: "risksmart.issue_update_audit" */
  issue_update_audit: Array<IssueUpdateAudit>;
  /** fetch aggregated fields from the table: "risksmart.issue_update_audit" */
  issue_update_audit_aggregate: IssueUpdateAuditAggregate;
  /** fetch data from the table: "risksmart.issue_update_audit" using primary key columns */
  issue_update_audit_by_pk?: Maybe<IssueUpdateAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_update_audit" */
  issue_update_audit_stream: Array<IssueUpdateAudit>;
  /** fetch data from the table: "risksmart.issue_update" using primary key columns */
  issue_update_by_pk?: Maybe<IssueUpdate>;
  /** fetch data from the table in a streaming manner: "risksmart.issue_update" */
  issue_update_stream: Array<IssueUpdate>;
  /** fetch data from the table: "risksmart.linked_item" */
  linked_item: Array<LinkedItem>;
  /** fetch aggregated fields from the table: "risksmart.linked_item" */
  linked_item_aggregate: LinkedItemAggregate;
  /** fetch data from the table: "risksmart.linked_item" using primary key columns */
  linked_item_by_pk?: Maybe<LinkedItem>;
  /** fetch data from the table in a streaming manner: "risksmart.linked_item" */
  linked_item_stream: Array<LinkedItem>;
  /** fetch data from the table: "risksmart.node" */
  node: Array<Node>;
  /** fetch aggregated fields from the table: "risksmart.node" */
  node_aggregate: NodeAggregate;
  /** fetch data from the table: "risksmart.node_ancestor" */
  node_ancestor: Array<NodeAncestor>;
  /** fetch aggregated fields from the table: "risksmart.node_ancestor" */
  node_ancestor_aggregate: NodeAncestorAggregate;
  /** fetch data from the table: "risksmart.node_ancestor" using primary key columns */
  node_ancestor_by_pk?: Maybe<NodeAncestor>;
  /** fetch data from the table in a streaming manner: "risksmart.node_ancestor" */
  node_ancestor_stream: Array<NodeAncestor>;
  /** fetch data from the table: "risksmart.node" using primary key columns */
  node_by_pk?: Maybe<Node>;
  /** fetch data from the table in a streaming manner: "risksmart.node" */
  node_stream: Array<Node>;
  /** fetch data from the table: "risksmart.obligation" */
  obligation: Array<Obligation>;
  /** fetch data from the table: "risksmart.obligation_action_audit" */
  obligation_action_audit: Array<ObligationActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_action_audit" */
  obligation_action_audit_aggregate: ObligationActionAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_action_audit" using primary key columns */
  obligation_action_audit_by_pk?: Maybe<ObligationActionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_action_audit" */
  obligation_action_audit_stream: Array<ObligationActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation" */
  obligation_aggregate: ObligationAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result: Array<ObligationAssessmentResult>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_result" */
  obligation_assessment_result_aggregate: ObligationAssessmentResultAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit: Array<ObligationAssessmentResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit_aggregate: ObligationAssessmentResultAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_result_audit" using primary key columns */
  obligation_assessment_result_audit_by_pk?: Maybe<ObligationAssessmentResultAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_assessment_result_audit" */
  obligation_assessment_result_audit_stream: Array<ObligationAssessmentResultAudit>;
  /** fetch data from the table: "risksmart.obligation_assessment_result" using primary key columns */
  obligation_assessment_result_by_pk?: Maybe<ObligationAssessmentResult>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_assessment_result" */
  obligation_assessment_result_stream: Array<ObligationAssessmentResult>;
  /** fetch data from the table: "risksmart.obligation_assessment_status" */
  obligation_assessment_status: Array<ObligationAssessmentStatus>;
  /** fetch aggregated fields from the table: "risksmart.obligation_assessment_status" */
  obligation_assessment_status_aggregate: ObligationAssessmentStatusAggregate;
  /** fetch data from the table: "risksmart.obligation_assessment_status" using primary key columns */
  obligation_assessment_status_by_pk?: Maybe<ObligationAssessmentStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_assessment_status" */
  obligation_assessment_status_stream: Array<ObligationAssessmentStatus>;
  /** fetch data from the table: "risksmart.obligation_audit" */
  obligation_audit: Array<ObligationAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_audit" */
  obligation_audit_aggregate: ObligationAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_audit" using primary key columns */
  obligation_audit_by_pk?: Maybe<ObligationAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_audit" */
  obligation_audit_stream: Array<ObligationAudit>;
  /** fetch data from the table: "risksmart.obligation" using primary key columns */
  obligation_by_pk?: Maybe<Obligation>;
  /** fetch data from the table: "risksmart.obligation_impact" */
  obligation_impact: Array<ObligationImpact>;
  /** fetch aggregated fields from the table: "risksmart.obligation_impact" */
  obligation_impact_aggregate: ObligationImpactAggregate;
  /** fetch data from the table: "risksmart.obligation_impact_audit" */
  obligation_impact_audit: Array<ObligationImpactAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_impact_audit" */
  obligation_impact_audit_aggregate: ObligationImpactAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_impact_audit" using primary key columns */
  obligation_impact_audit_by_pk?: Maybe<ObligationImpactAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_impact_audit" */
  obligation_impact_audit_stream: Array<ObligationImpactAudit>;
  /** fetch data from the table: "risksmart.obligation_impact" using primary key columns */
  obligation_impact_by_pk?: Maybe<ObligationImpact>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_impact" */
  obligation_impact_stream: Array<ObligationImpact>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation" */
  obligation_stream: Array<Obligation>;
  /** fetch data from the table: "risksmart.obligation_type" */
  obligation_type: Array<ObligationType>;
  /** fetch aggregated fields from the table: "risksmart.obligation_type" */
  obligation_type_aggregate: ObligationTypeAggregate;
  /** fetch data from the table: "risksmart.obligation_type" using primary key columns */
  obligation_type_by_pk?: Maybe<ObligationType>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_type" */
  obligation_type_stream: Array<ObligationType>;
  /** fetch data from the table: "risksmart.owner" */
  owner: Array<Owner>;
  /** fetch aggregated fields from the table: "risksmart.owner" */
  owner_aggregate: OwnerAggregate;
  /** fetch data from the table: "risksmart.owner_audit" */
  owner_audit: Array<OwnerAudit>;
  /** fetch aggregated fields from the table: "risksmart.owner_audit" */
  owner_audit_aggregate: OwnerAuditAggregate;
  /** fetch data from the table: "risksmart.owner_audit" using primary key columns */
  owner_audit_by_pk?: Maybe<OwnerAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.owner_audit" */
  owner_audit_stream: Array<OwnerAudit>;
  /** fetch data from the table: "risksmart.owner" using primary key columns */
  owner_by_pk?: Maybe<Owner>;
  /** fetch data from the table: "risksmart.owner_group" */
  owner_group: Array<OwnerGroup>;
  /** fetch aggregated fields from the table: "risksmart.owner_group" */
  owner_group_aggregate: OwnerGroupAggregate;
  /** fetch data from the table: "risksmart.owner_group_audit" */
  owner_group_audit: Array<OwnerGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.owner_group_audit" */
  owner_group_audit_aggregate: OwnerGroupAuditAggregate;
  /** fetch data from the table: "risksmart.owner_group_audit" using primary key columns */
  owner_group_audit_by_pk?: Maybe<OwnerGroupAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.owner_group_audit" */
  owner_group_audit_stream: Array<OwnerGroupAudit>;
  /** fetch data from the table: "risksmart.owner_group" using primary key columns */
  owner_group_by_pk?: Maybe<OwnerGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.owner_group" */
  owner_group_stream: Array<OwnerGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.owner" */
  owner_stream: Array<Owner>;
  /** fetch data from the table: "risksmart.parent_type" */
  parent_type: Array<ParentType>;
  /** fetch aggregated fields from the table: "risksmart.parent_type" */
  parent_type_aggregate: ParentTypeAggregate;
  /** fetch data from the table: "risksmart.parent_type" using primary key columns */
  parent_type_by_pk?: Maybe<ParentType>;
  /** fetch data from the table in a streaming manner: "risksmart.parent_type" */
  parent_type_stream: Array<ParentType>;
  /** fetch data from the table: "risksmart.permission_view" */
  permission: Array<Permission>;
  /** fetch aggregated fields from the table: "risksmart.permission_view" */
  permission_aggregate: PermissionAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.permission_view" */
  permission_stream: Array<Permission>;
  /** fetch data from the table: "risksmart.questionnaire_invite" */
  questionnaire_invite: Array<QuestionnaireInvite>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_invite" */
  questionnaire_invite_aggregate: QuestionnaireInviteAggregate;
  /** fetch data from the table: "risksmart.questionnaire_invite" using primary key columns */
  questionnaire_invite_by_pk?: Maybe<QuestionnaireInvite>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_invite" */
  questionnaire_invite_stream: Array<QuestionnaireInvite>;
  /** fetch data from the table: "risksmart.questionnaire_template" */
  questionnaire_template: Array<QuestionnaireTemplate>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template" */
  questionnaire_template_aggregate: QuestionnaireTemplateAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template" using primary key columns */
  questionnaire_template_by_pk?: Maybe<QuestionnaireTemplate>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template" */
  questionnaire_template_stream: Array<QuestionnaireTemplate>;
  /** fetch data from the table: "risksmart.questionnaire_template_version" */
  questionnaire_template_version: Array<QuestionnaireTemplateVersion>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_version" */
  questionnaire_template_version_aggregate: QuestionnaireTemplateVersionAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_version" using primary key columns */
  questionnaire_template_version_by_pk?: Maybe<QuestionnaireTemplateVersion>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status: Array<QuestionnaireTemplateVersionStatus>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status_aggregate: QuestionnaireTemplateVersionStatusAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_version_status" using primary key columns */
  questionnaire_template_version_status_by_pk?: Maybe<QuestionnaireTemplateVersionStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template_version_status" */
  questionnaire_template_version_status_stream: Array<QuestionnaireTemplateVersionStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template_version" */
  questionnaire_template_version_stream: Array<QuestionnaireTemplateVersion>;
  /** fetch data from the table: "risksmart.relation_file" */
  relation_file: Array<RelationFile>;
  /** fetch aggregated fields from the table: "risksmart.relation_file" */
  relation_file_aggregate: RelationFileAggregate;
  /** fetch data from the table: "risksmart.relation_file_audit" */
  relation_file_audit: Array<RelationFileAudit>;
  /** fetch aggregated fields from the table: "risksmart.relation_file_audit" */
  relation_file_audit_aggregate: RelationFileAuditAggregate;
  /** fetch data from the table: "risksmart.relation_file_audit" using primary key columns */
  relation_file_audit_by_pk?: Maybe<RelationFileAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.relation_file_audit" */
  relation_file_audit_stream: Array<RelationFileAudit>;
  /** fetch data from the table: "risksmart.relation_file" using primary key columns */
  relation_file_by_pk?: Maybe<RelationFile>;
  /** fetch data from the table in a streaming manner: "risksmart.relation_file" */
  relation_file_stream: Array<RelationFile>;
  /** fetch data from the table: "risksmart.risk" */
  risk: Array<Risk>;
  /** fetch aggregated fields from the table: "risksmart.risk" */
  risk_aggregate: RiskAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result: Array<RiskAssessmentResult>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result" */
  risk_assessment_result_aggregate: RiskAssessmentResultAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit: Array<RiskAssessmentResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit_aggregate: RiskAssessmentResultAuditAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_audit" using primary key columns */
  risk_assessment_result_audit_by_pk?: Maybe<RiskAssessmentResultAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_assessment_result_audit" */
  risk_assessment_result_audit_stream: Array<RiskAssessmentResultAudit>;
  /** fetch data from the table: "risksmart.risk_assessment_result" using primary key columns */
  risk_assessment_result_by_pk?: Maybe<RiskAssessmentResult>;
  /** fetch data from the table: "risksmart.risk_assessment_result_control_type" */
  risk_assessment_result_control_type: Array<RiskAssessmentResultControlType>;
  /** fetch aggregated fields from the table: "risksmart.risk_assessment_result_control_type" */
  risk_assessment_result_control_type_aggregate: RiskAssessmentResultControlTypeAggregate;
  /** fetch data from the table: "risksmart.risk_assessment_result_control_type" using primary key columns */
  risk_assessment_result_control_type_by_pk?: Maybe<RiskAssessmentResultControlType>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_assessment_result_control_type" */
  risk_assessment_result_control_type_stream: Array<RiskAssessmentResultControlType>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_assessment_result" */
  risk_assessment_result_stream: Array<RiskAssessmentResult>;
  /** fetch data from the table: "risksmart.risk_audit" */
  risk_audit: Array<RiskAudit>;
  /** fetch aggregated fields from the table: "risksmart.risk_audit" */
  risk_audit_aggregate: RiskAuditAggregate;
  /** fetch data from the table: "risksmart.risk_audit" using primary key columns */
  risk_audit_by_pk?: Maybe<RiskAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_audit" */
  risk_audit_stream: Array<RiskAudit>;
  /** fetch data from the table: "risksmart.risk" using primary key columns */
  risk_by_pk?: Maybe<Risk>;
  /** fetch data from the table: "risksmart.risk_score" */
  risk_score: Array<RiskScore>;
  /** fetch aggregated fields from the table: "risksmart.risk_score" */
  risk_score_aggregate: RiskScoreAggregate;
  /** fetch data from the table: "risksmart.risk_score" using primary key columns */
  risk_score_by_pk?: Maybe<RiskScore>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_score" */
  risk_score_stream: Array<RiskScore>;
  /** fetch data from the table: "risksmart.risk_scoring_model" */
  risk_scoring_model: Array<RiskScoringModel>;
  /** fetch aggregated fields from the table: "risksmart.risk_scoring_model" */
  risk_scoring_model_aggregate: RiskScoringModelAggregate;
  /** fetch data from the table: "risksmart.risk_scoring_model" using primary key columns */
  risk_scoring_model_by_pk?: Maybe<RiskScoringModel>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_scoring_model" */
  risk_scoring_model_stream: Array<RiskScoringModel>;
  /** fetch data from the table: "risksmart.risk_status_type" */
  risk_status_type: Array<RiskStatusType>;
  /** fetch aggregated fields from the table: "risksmart.risk_status_type" */
  risk_status_type_aggregate: RiskStatusTypeAggregate;
  /** fetch data from the table: "risksmart.risk_status_type" using primary key columns */
  risk_status_type_by_pk?: Maybe<RiskStatusType>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_status_type" */
  risk_status_type_stream: Array<RiskStatusType>;
  /** fetch data from the table in a streaming manner: "risksmart.risk" */
  risk_stream: Array<Risk>;
  /** fetch data from the table: "risksmart.risk_treatment_type" */
  risk_treatment_type: Array<RiskTreatmentType>;
  /** fetch aggregated fields from the table: "risksmart.risk_treatment_type" */
  risk_treatment_type_aggregate: RiskTreatmentTypeAggregate;
  /** fetch data from the table: "risksmart.risk_treatment_type" using primary key columns */
  risk_treatment_type_by_pk?: Maybe<RiskTreatmentType>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_treatment_type" */
  risk_treatment_type_stream: Array<RiskTreatmentType>;
  /** fetch data from the table: "risksmart.approver_response_audit" */
  risksmart_approver_response_audit: Array<RisksmartApproverResponseAudit>;
  /** fetch aggregated fields from the table: "risksmart.approver_response_audit" */
  risksmart_approver_response_audit_aggregate: RisksmartApproverResponseAuditAggregate;
  /** fetch data from the table: "risksmart.approver_response_audit" using primary key columns */
  risksmart_approver_response_audit_by_pk?: Maybe<RisksmartApproverResponseAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.approver_response_audit" */
  risksmart_approver_response_audit_stream: Array<RisksmartApproverResponseAudit>;
  /** fetch data from the table: "risksmart.change_request_contributor_audit" */
  risksmart_change_request_contributor_audit: Array<RisksmartChangeRequestContributorAudit>;
  /** fetch aggregated fields from the table: "risksmart.change_request_contributor_audit" */
  risksmart_change_request_contributor_audit_aggregate: RisksmartChangeRequestContributorAuditAggregate;
  /** fetch data from the table: "risksmart.change_request_contributor_audit" using primary key columns */
  risksmart_change_request_contributor_audit_by_pk?: Maybe<RisksmartChangeRequestContributorAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.change_request_contributor_audit" */
  risksmart_change_request_contributor_audit_stream: Array<RisksmartChangeRequestContributorAudit>;
  /** fetch data from the table: "risksmart.contributor_view" */
  risksmart_contributor_view: Array<RisksmartContributorView>;
  /** fetch aggregated fields from the table: "risksmart.contributor_view" */
  risksmart_contributor_view_aggregate: RisksmartContributorViewAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.contributor_view" */
  risksmart_contributor_view_stream: Array<RisksmartContributorView>;
  /** fetch data from the table: "risksmart.control_action_audit" */
  risksmart_control_action_audit: Array<RisksmartControlActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.control_action_audit" */
  risksmart_control_action_audit_aggregate: RisksmartControlActionAuditAggregate;
  /** fetch data from the table: "risksmart.control_action_audit" using primary key columns */
  risksmart_control_action_audit_by_pk?: Maybe<RisksmartControlActionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.control_action_audit" */
  risksmart_control_action_audit_stream: Array<RisksmartControlActionAudit>;
  /** fetch data from the table: "risksmart.counter" */
  risksmart_counter: Array<RisksmartCounter>;
  /** fetch aggregated fields from the table: "risksmart.counter" */
  risksmart_counter_aggregate: RisksmartCounterAggregate;
  /** fetch data from the table: "risksmart.counter" using primary key columns */
  risksmart_counter_by_pk?: Maybe<RisksmartCounter>;
  /** fetch data from the table in a streaming manner: "risksmart.counter" */
  risksmart_counter_stream: Array<RisksmartCounter>;
  /** fetch data from the table: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit: Array<RisksmartIndicatorParentAudit>;
  /** fetch aggregated fields from the table: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit_aggregate: RisksmartIndicatorParentAuditAggregate;
  /** fetch data from the table: "risksmart.indicator_parent_audit" using primary key columns */
  risksmart_indicator_parent_audit_by_pk?: Maybe<RisksmartIndicatorParentAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.indicator_parent_audit" */
  risksmart_indicator_parent_audit_stream: Array<RisksmartIndicatorParentAudit>;
  /** fetch data from the table: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit: Array<RisksmartLinkedItemAudit>;
  /** fetch aggregated fields from the table: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit_aggregate: RisksmartLinkedItemAuditAggregate;
  /** fetch data from the table: "risksmart.linked_item_audit" using primary key columns */
  risksmart_linked_item_audit_by_pk?: Maybe<RisksmartLinkedItemAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.linked_item_audit" */
  risksmart_linked_item_audit_stream: Array<RisksmartLinkedItemAudit>;
  /** fetch data from the table: "risksmart.obligation_issue_audit" */
  risksmart_obligation_issue_audit: Array<RisksmartObligationIssueAudit>;
  /** fetch aggregated fields from the table: "risksmart.obligation_issue_audit" */
  risksmart_obligation_issue_audit_aggregate: RisksmartObligationIssueAuditAggregate;
  /** fetch data from the table: "risksmart.obligation_issue_audit" using primary key columns */
  risksmart_obligation_issue_audit_by_pk?: Maybe<RisksmartObligationIssueAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.obligation_issue_audit" */
  risksmart_obligation_issue_audit_stream: Array<RisksmartObligationIssueAudit>;
  /** fetch data from the table: "risksmart.questionnaire_template_audit" */
  risksmart_questionnaire_template_audit: Array<RisksmartQuestionnaireTemplateAudit>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_audit" */
  risksmart_questionnaire_template_audit_aggregate: RisksmartQuestionnaireTemplateAuditAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_audit" using primary key columns */
  risksmart_questionnaire_template_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template_audit" */
  risksmart_questionnaire_template_audit_stream: Array<RisksmartQuestionnaireTemplateAudit>;
  /** fetch data from the table: "risksmart.questionnaire_template_version_audit" */
  risksmart_questionnaire_template_version_audit: Array<RisksmartQuestionnaireTemplateVersionAudit>;
  /** fetch aggregated fields from the table: "risksmart.questionnaire_template_version_audit" */
  risksmart_questionnaire_template_version_audit_aggregate: RisksmartQuestionnaireTemplateVersionAuditAggregate;
  /** fetch data from the table: "risksmart.questionnaire_template_version_audit" using primary key columns */
  risksmart_questionnaire_template_version_audit_by_pk?: Maybe<RisksmartQuestionnaireTemplateVersionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.questionnaire_template_version_audit" */
  risksmart_questionnaire_template_version_audit_stream: Array<RisksmartQuestionnaireTemplateVersionAudit>;
  /** fetch data from the table: "risksmart.risk_action_audit" */
  risksmart_risk_action_audit: Array<RisksmartRiskActionAudit>;
  /** fetch aggregated fields from the table: "risksmart.risk_action_audit" */
  risksmart_risk_action_audit_aggregate: RisksmartRiskActionAuditAggregate;
  /** fetch data from the table: "risksmart.risk_action_audit" using primary key columns */
  risksmart_risk_action_audit_by_pk?: Maybe<RisksmartRiskActionAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.risk_action_audit" */
  risksmart_risk_action_audit_stream: Array<RisksmartRiskActionAudit>;
  /** fetch data from the table: "risksmart.role_access" */
  role_access: Array<RoleAccess>;
  /** fetch aggregated fields from the table: "risksmart.role_access" */
  role_access_aggregate: RoleAccessAggregate;
  /** fetch data from the table: "risksmart.role_access" using primary key columns */
  role_access_by_pk?: Maybe<RoleAccess>;
  /** fetch data from the table in a streaming manner: "risksmart.role_access" */
  role_access_stream: Array<RoleAccess>;
  /** fetch data from the table: "risksmart.tag" */
  tag: Array<Tag>;
  /** fetch aggregated fields from the table: "risksmart.tag" */
  tag_aggregate: TagAggregate;
  /** fetch data from the table: "risksmart.tag_audit" */
  tag_audit: Array<TagAudit>;
  /** fetch aggregated fields from the table: "risksmart.tag_audit" */
  tag_audit_aggregate: TagAuditAggregate;
  /** fetch data from the table: "risksmart.tag_audit" using primary key columns */
  tag_audit_by_pk?: Maybe<TagAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_audit" */
  tag_audit_stream: Array<TagAudit>;
  /** fetch data from the table: "risksmart.tag" using primary key columns */
  tag_by_pk?: Maybe<Tag>;
  /** fetch data from the table in a streaming manner: "risksmart.tag" */
  tag_stream: Array<Tag>;
  /** fetch data from the table: "risksmart.tag_type" */
  tag_type: Array<TagType>;
  /** fetch aggregated fields from the table: "risksmart.tag_type" */
  tag_type_aggregate: TagTypeAggregate;
  /** fetch data from the table: "risksmart.tag_type_audit" */
  tag_type_audit: Array<TagTypeAudit>;
  /** fetch aggregated fields from the table: "risksmart.tag_type_audit" */
  tag_type_audit_aggregate: TagTypeAuditAggregate;
  /** fetch data from the table: "risksmart.tag_type_audit" using primary key columns */
  tag_type_audit_by_pk?: Maybe<TagTypeAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type_audit" */
  tag_type_audit_stream: Array<TagTypeAudit>;
  /** fetch data from the table: "risksmart.tag_type" using primary key columns */
  tag_type_by_pk?: Maybe<TagType>;
  /** fetch data from the table: "risksmart.tag_type_group" */
  tag_type_group: Array<TagTypeGroup>;
  /** fetch aggregated fields from the table: "risksmart.tag_type_group" */
  tag_type_group_aggregate: TagTypeGroupAggregate;
  /** fetch data from the table: "risksmart.tag_type_group_audit" */
  tag_type_group_audit: Array<TagTypeGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.tag_type_group_audit" */
  tag_type_group_audit_aggregate: TagTypeGroupAuditAggregate;
  /** fetch data from the table: "risksmart.tag_type_group_audit" using primary key columns */
  tag_type_group_audit_by_pk?: Maybe<TagTypeGroupAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type_group_audit" */
  tag_type_group_audit_stream: Array<TagTypeGroupAudit>;
  /** fetch data from the table: "risksmart.tag_type_group" using primary key columns */
  tag_type_group_by_pk?: Maybe<TagTypeGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type_group" */
  tag_type_group_stream: Array<TagTypeGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.tag_type" */
  tag_type_stream: Array<TagType>;
  /** fetch data from the table: "risksmart.taxonomy" */
  taxonomy: Array<Taxonomy>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy" */
  taxonomy_aggregate: TaxonomyAggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit: Array<TaxonomyAudit>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_audit" */
  taxonomy_audit_aggregate: TaxonomyAuditAggregate;
  /** fetch data from the table: "risksmart.taxonomy_audit" using primary key columns */
  taxonomy_audit_by_pk?: Maybe<TaxonomyAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy_audit" */
  taxonomy_audit_stream: Array<TaxonomyAudit>;
  /** fetch data from the table: "risksmart.taxonomy" using primary key columns */
  taxonomy_by_pk?: Maybe<Taxonomy>;
  /** fetch data from the table: "risksmart.taxonomy_org" */
  taxonomy_org: Array<TaxonomyOrg>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_org" */
  taxonomy_org_aggregate: TaxonomyOrgAggregate;
  /** fetch data from the table: "risksmart.taxonomy_org_audit" */
  taxonomy_org_audit: Array<TaxonomyOrgAudit>;
  /** fetch aggregated fields from the table: "risksmart.taxonomy_org_audit" */
  taxonomy_org_audit_aggregate: TaxonomyOrgAuditAggregate;
  /** fetch data from the table: "risksmart.taxonomy_org_audit" using primary key columns */
  taxonomy_org_audit_by_pk?: Maybe<TaxonomyOrgAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy_org_audit" */
  taxonomy_org_audit_stream: Array<TaxonomyOrgAudit>;
  /** fetch data from the table: "risksmart.taxonomy_org" using primary key columns */
  taxonomy_org_by_pk?: Maybe<TaxonomyOrg>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy_org" */
  taxonomy_org_stream: Array<TaxonomyOrg>;
  /** fetch data from the table in a streaming manner: "risksmart.taxonomy" */
  taxonomy_stream: Array<Taxonomy>;
  /** fetch data from the table: "risksmart.test_frequency" */
  test_frequency: Array<TestFrequency>;
  /** fetch aggregated fields from the table: "risksmart.test_frequency" */
  test_frequency_aggregate: TestFrequencyAggregate;
  /** fetch data from the table: "risksmart.test_frequency" using primary key columns */
  test_frequency_by_pk?: Maybe<TestFrequency>;
  /** fetch data from the table in a streaming manner: "risksmart.test_frequency" */
  test_frequency_stream: Array<TestFrequency>;
  /** fetch data from the table: "risksmart.test_result" */
  test_result: Array<TestResult>;
  /** fetch aggregated fields from the table: "risksmart.test_result" */
  test_result_aggregate: TestResultAggregate;
  /** fetch data from the table: "risksmart.test_result_audit" */
  test_result_audit: Array<TestResultAudit>;
  /** fetch aggregated fields from the table: "risksmart.test_result_audit" */
  test_result_audit_aggregate: TestResultAuditAggregate;
  /** fetch data from the table: "risksmart.test_result_audit" using primary key columns */
  test_result_audit_by_pk?: Maybe<TestResultAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.test_result_audit" */
  test_result_audit_stream: Array<TestResultAudit>;
  /** fetch data from the table: "risksmart.test_result" using primary key columns */
  test_result_by_pk?: Maybe<TestResult>;
  /** fetch data from the table in a streaming manner: "risksmart.test_result" */
  test_result_stream: Array<TestResult>;
  /** fetch data from the table: "risksmart.third_party" */
  third_party: Array<ThirdParty>;
  /** fetch aggregated fields from the table: "risksmart.third_party" */
  third_party_aggregate: ThirdPartyAggregate;
  /** fetch data from the table: "risksmart.third_party_audit" */
  third_party_audit: Array<ThirdPartyAudit>;
  /** fetch aggregated fields from the table: "risksmart.third_party_audit" */
  third_party_audit_aggregate: ThirdPartyAuditAggregate;
  /** fetch data from the table: "risksmart.third_party_audit" using primary key columns */
  third_party_audit_by_pk?: Maybe<ThirdPartyAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_audit" */
  third_party_audit_stream: Array<ThirdPartyAudit>;
  /** fetch data from the table: "risksmart.third_party" using primary key columns */
  third_party_by_pk?: Maybe<ThirdParty>;
  /** fetch data from the table: "risksmart.third_party_response" */
  third_party_response: Array<ThirdPartyResponse>;
  /** fetch aggregated fields from the table: "risksmart.third_party_response" */
  third_party_response_aggregate: ThirdPartyResponseAggregate;
  /** fetch data from the table: "risksmart.third_party_response" using primary key columns */
  third_party_response_by_pk?: Maybe<ThirdPartyResponse>;
  /** fetch data from the table: "risksmart.third_party_response_status" */
  third_party_response_status: Array<ThirdPartyResponseStatus>;
  /** fetch aggregated fields from the table: "risksmart.third_party_response_status" */
  third_party_response_status_aggregate: ThirdPartyResponseStatusAggregate;
  /** fetch data from the table: "risksmart.third_party_response_status" using primary key columns */
  third_party_response_status_by_pk?: Maybe<ThirdPartyResponseStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_response_status" */
  third_party_response_status_stream: Array<ThirdPartyResponseStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_response" */
  third_party_response_stream: Array<ThirdPartyResponse>;
  /** fetch data from the table: "risksmart.third_party_status" */
  third_party_status: Array<ThirdPartyStatus>;
  /** fetch aggregated fields from the table: "risksmart.third_party_status" */
  third_party_status_aggregate: ThirdPartyStatusAggregate;
  /** fetch data from the table: "risksmart.third_party_status" using primary key columns */
  third_party_status_by_pk?: Maybe<ThirdPartyStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_status" */
  third_party_status_stream: Array<ThirdPartyStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party" */
  third_party_stream: Array<ThirdParty>;
  /** fetch data from the table: "risksmart.third_party_type" */
  third_party_type: Array<ThirdPartyType>;
  /** fetch aggregated fields from the table: "risksmart.third_party_type" */
  third_party_type_aggregate: ThirdPartyTypeAggregate;
  /** fetch data from the table: "risksmart.third_party_type" using primary key columns */
  third_party_type_by_pk?: Maybe<ThirdPartyType>;
  /** fetch data from the table in a streaming manner: "risksmart.third_party_type" */
  third_party_type_stream: Array<ThirdPartyType>;
  /** fetch data from the table: "risksmart.user_view_active" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "risksmart.user_view_active" */
  user_aggregate: UserAggregate;
  /** fetch data from the table: "risksmart.user_group" */
  user_group: Array<UserGroup>;
  /** fetch aggregated fields from the table: "risksmart.user_group" */
  user_group_aggregate: UserGroupAggregate;
  /** fetch data from the table: "risksmart.user_group_audit" */
  user_group_audit: Array<UserGroupAudit>;
  /** fetch aggregated fields from the table: "risksmart.user_group_audit" */
  user_group_audit_aggregate: UserGroupAuditAggregate;
  /** fetch data from the table: "risksmart.user_group_audit" using primary key columns */
  user_group_audit_by_pk?: Maybe<UserGroupAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group_audit" */
  user_group_audit_stream: Array<UserGroupAudit>;
  /** fetch data from the table: "risksmart.user_group" using primary key columns */
  user_group_by_pk?: Maybe<UserGroup>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group" */
  user_group_stream: Array<UserGroup>;
  /** fetch data from the table: "risksmart.user_group_user" */
  user_group_user: Array<UserGroupUser>;
  /** fetch aggregated fields from the table: "risksmart.user_group_user" */
  user_group_user_aggregate: UserGroupUserAggregate;
  /** fetch data from the table: "risksmart.user_group_user_audit" */
  user_group_user_audit: Array<UserGroupUserAudit>;
  /** fetch aggregated fields from the table: "risksmart.user_group_user_audit" */
  user_group_user_audit_aggregate: UserGroupUserAuditAggregate;
  /** fetch data from the table: "risksmart.user_group_user_audit" using primary key columns */
  user_group_user_audit_by_pk?: Maybe<UserGroupUserAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group_user_audit" */
  user_group_user_audit_stream: Array<UserGroupUserAudit>;
  /** fetch data from the table: "risksmart.user_group_user" using primary key columns */
  user_group_user_by_pk?: Maybe<UserGroupUser>;
  /** fetch data from the table in a streaming manner: "risksmart.user_group_user" */
  user_group_user_stream: Array<UserGroupUser>;
  /** fetch data from the table: "risksmart.user_role_access" */
  user_role_access: Array<UserRoleAccess>;
  /** fetch aggregated fields from the table: "risksmart.user_role_access" */
  user_role_access_aggregate: UserRoleAccessAggregate;
  /** fetch data from the table in a streaming manner: "risksmart.user_role_access" */
  user_role_access_stream: Array<UserRoleAccess>;
  /** fetch data from the table: "risksmart.user_search_preferences" */
  user_search_preferences: Array<UserSearchPreferences>;
  /** fetch aggregated fields from the table: "risksmart.user_search_preferences" */
  user_search_preferences_aggregate: UserSearchPreferencesAggregate;
  /** fetch data from the table: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit: Array<UserSearchPreferencesAudit>;
  /** fetch aggregated fields from the table: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit_aggregate: UserSearchPreferencesAuditAggregate;
  /** fetch data from the table: "risksmart.user_search_preferences_audit" using primary key columns */
  user_search_preferences_audit_by_pk?: Maybe<UserSearchPreferencesAudit>;
  /** fetch data from the table in a streaming manner: "risksmart.user_search_preferences_audit" */
  user_search_preferences_audit_stream: Array<UserSearchPreferencesAudit>;
  /** fetch data from the table: "risksmart.user_search_preferences" using primary key columns */
  user_search_preferences_by_pk?: Maybe<UserSearchPreferences>;
  /** fetch data from the table in a streaming manner: "risksmart.user_search_preferences" */
  user_search_preferences_stream: Array<UserSearchPreferences>;
  /** fetch data from the table: "auth.user_status" */
  user_status: Array<UserStatus>;
  /** fetch aggregated fields from the table: "auth.user_status" */
  user_status_aggregate: UserStatusAggregate;
  /** fetch data from the table: "auth.user_status" using primary key columns */
  user_status_by_pk?: Maybe<UserStatus>;
  /** fetch data from the table in a streaming manner: "auth.user_status" */
  user_status_stream: Array<UserStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.user_view_active" */
  user_stream: Array<User>;
  /** fetch data from the table: "risksmart.version_status" */
  version_status: Array<VersionStatus>;
  /** fetch aggregated fields from the table: "risksmart.version_status" */
  version_status_aggregate: VersionStatusAggregate;
  /** fetch data from the table: "risksmart.version_status" using primary key columns */
  version_status_by_pk?: Maybe<VersionStatus>;
  /** fetch data from the table in a streaming manner: "risksmart.version_status" */
  version_status_stream: Array<VersionStatus>;
};


export type SubscriptionRootAcceptanceArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceOrderBy>>;
  where?: InputMaybe<AcceptanceBoolExp>;
};


export type SubscriptionRootAcceptanceAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceOrderBy>>;
  where?: InputMaybe<AcceptanceBoolExp>;
};


export type SubscriptionRootAcceptanceAuditArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceAuditOrderBy>>;
  where?: InputMaybe<AcceptanceAuditBoolExp>;
};


export type SubscriptionRootAcceptanceAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceAuditOrderBy>>;
  where?: InputMaybe<AcceptanceAuditBoolExp>;
};


export type SubscriptionRootAcceptanceAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootAcceptanceAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AcceptanceAuditStreamCursorInput>>;
  where?: InputMaybe<AcceptanceAuditBoolExp>;
};


export type SubscriptionRootAcceptanceByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootAcceptanceParentArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


export type SubscriptionRootAcceptanceParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentOrderBy>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


export type SubscriptionRootAcceptanceParentAuditArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentAuditOrderBy>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


export type SubscriptionRootAcceptanceParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceParentAuditOrderBy>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


export type SubscriptionRootAcceptanceParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAcceptanceParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AcceptanceParentAuditStreamCursorInput>>;
  where?: InputMaybe<AcceptanceParentAuditBoolExp>;
};


export type SubscriptionRootAcceptanceParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAcceptanceParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AcceptanceParentStreamCursorInput>>;
  where?: InputMaybe<AcceptanceParentBoolExp>;
};


export type SubscriptionRootAcceptanceStatusArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceStatusOrderBy>>;
  where?: InputMaybe<AcceptanceStatusBoolExp>;
};


export type SubscriptionRootAcceptanceStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AcceptanceStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AcceptanceStatusOrderBy>>;
  where?: InputMaybe<AcceptanceStatusBoolExp>;
};


export type SubscriptionRootAcceptanceStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAcceptanceStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AcceptanceStatusStreamCursorInput>>;
  where?: InputMaybe<AcceptanceStatusBoolExp>;
};


export type SubscriptionRootAcceptanceStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AcceptanceStreamCursorInput>>;
  where?: InputMaybe<AcceptanceBoolExp>;
};


export type SubscriptionRootAccessTypeArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AccessTypeOrderBy>>;
  where?: InputMaybe<AccessTypeBoolExp>;
};


export type SubscriptionRootAccessTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<AccessTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AccessTypeOrderBy>>;
  where?: InputMaybe<AccessTypeBoolExp>;
};


export type SubscriptionRootAccessTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAccessTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AccessTypeStreamCursorInput>>;
  where?: InputMaybe<AccessTypeBoolExp>;
};


export type SubscriptionRootActionArgs = {
  distinct_on?: InputMaybe<Array<ActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionOrderBy>>;
  where?: InputMaybe<ActionBoolExp>;
};


export type SubscriptionRootActionAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionOrderBy>>;
  where?: InputMaybe<ActionBoolExp>;
};


export type SubscriptionRootActionAuditArgs = {
  distinct_on?: InputMaybe<Array<ActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionAuditOrderBy>>;
  where?: InputMaybe<ActionAuditBoolExp>;
};


export type SubscriptionRootActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionAuditOrderBy>>;
  where?: InputMaybe<ActionAuditBoolExp>;
};


export type SubscriptionRootActionAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootActionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionAuditStreamCursorInput>>;
  where?: InputMaybe<ActionAuditBoolExp>;
};


export type SubscriptionRootActionByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootActionParentArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


export type SubscriptionRootActionParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentOrderBy>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


export type SubscriptionRootActionParentAuditArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


export type SubscriptionRootActionParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionParentAuditOrderBy>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


export type SubscriptionRootActionParentAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootActionParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionParentAuditStreamCursorInput>>;
  where?: InputMaybe<ActionParentAuditBoolExp>;
};


export type SubscriptionRootActionParentByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootActionParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionParentStreamCursorInput>>;
  where?: InputMaybe<ActionParentBoolExp>;
};


export type SubscriptionRootActionStatusArgs = {
  distinct_on?: InputMaybe<Array<ActionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionStatusOrderBy>>;
  where?: InputMaybe<ActionStatusBoolExp>;
};


export type SubscriptionRootActionStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionStatusOrderBy>>;
  where?: InputMaybe<ActionStatusBoolExp>;
};


export type SubscriptionRootActionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootActionStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionStatusStreamCursorInput>>;
  where?: InputMaybe<ActionStatusBoolExp>;
};


export type SubscriptionRootActionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionStreamCursorInput>>;
  where?: InputMaybe<ActionBoolExp>;
};


export type SubscriptionRootActionUpdateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateOrderBy>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};


export type SubscriptionRootActionUpdateAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateOrderBy>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};


export type SubscriptionRootActionUpdateAuditArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateAuditOrderBy>>;
  where?: InputMaybe<ActionUpdateAuditBoolExp>;
};


export type SubscriptionRootActionUpdateAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateAuditOrderBy>>;
  where?: InputMaybe<ActionUpdateAuditBoolExp>;
};


export type SubscriptionRootActionUpdateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootActionUpdateAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionUpdateAuditStreamCursorInput>>;
  where?: InputMaybe<ActionUpdateAuditBoolExp>;
};


export type SubscriptionRootActionUpdateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootActionUpdateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionUpdateStreamCursorInput>>;
  where?: InputMaybe<ActionUpdateBoolExp>;
};


export type SubscriptionRootActionUpdateSummaryArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSummarySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateSummaryOrderBy>>;
  where?: InputMaybe<ActionUpdateSummaryBoolExp>;
};


export type SubscriptionRootActionUpdateSummaryAggregateArgs = {
  distinct_on?: InputMaybe<Array<ActionUpdateSummarySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ActionUpdateSummaryOrderBy>>;
  where?: InputMaybe<ActionUpdateSummaryBoolExp>;
};


export type SubscriptionRootActionUpdateSummaryStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ActionUpdateSummaryStreamCursorInput>>;
  where?: InputMaybe<ActionUpdateSummaryBoolExp>;
};


export type SubscriptionRootAggregationOrgArgs = {
  distinct_on?: InputMaybe<Array<AggregationOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregationOrgOrderBy>>;
  where?: InputMaybe<AggregationOrgBoolExp>;
};


export type SubscriptionRootAggregationOrgAggregateArgs = {
  distinct_on?: InputMaybe<Array<AggregationOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AggregationOrgOrderBy>>;
  where?: InputMaybe<AggregationOrgBoolExp>;
};


export type SubscriptionRootAggregationOrgByPkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootAggregationOrgStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AggregationOrgStreamCursorInput>>;
  where?: InputMaybe<AggregationOrgBoolExp>;
};


export type SubscriptionRootAncestorContributorArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


export type SubscriptionRootAncestorContributorAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


export type SubscriptionRootAncestorContributorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AncestorContributorStreamCursorInput>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


export type SubscriptionRootAppetiteArgs = {
  distinct_on?: InputMaybe<Array<AppetiteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteOrderBy>>;
  where?: InputMaybe<AppetiteBoolExp>;
};


export type SubscriptionRootAppetiteAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteOrderBy>>;
  where?: InputMaybe<AppetiteBoolExp>;
};


export type SubscriptionRootAppetiteAuditArgs = {
  distinct_on?: InputMaybe<Array<AppetiteAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteAuditOrderBy>>;
  where?: InputMaybe<AppetiteAuditBoolExp>;
};


export type SubscriptionRootAppetiteAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteAuditOrderBy>>;
  where?: InputMaybe<AppetiteAuditBoolExp>;
};


export type SubscriptionRootAppetiteAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootAppetiteAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AppetiteAuditStreamCursorInput>>;
  where?: InputMaybe<AppetiteAuditBoolExp>;
};


export type SubscriptionRootAppetiteByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootAppetiteModelArgs = {
  distinct_on?: InputMaybe<Array<AppetiteModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteModelOrderBy>>;
  where?: InputMaybe<AppetiteModelBoolExp>;
};


export type SubscriptionRootAppetiteModelAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteModelOrderBy>>;
  where?: InputMaybe<AppetiteModelBoolExp>;
};


export type SubscriptionRootAppetiteModelByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAppetiteModelStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AppetiteModelStreamCursorInput>>;
  where?: InputMaybe<AppetiteModelBoolExp>;
};


export type SubscriptionRootAppetiteParentArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


export type SubscriptionRootAppetiteParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentOrderBy>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


export type SubscriptionRootAppetiteParentAuditArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentAuditOrderBy>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


export type SubscriptionRootAppetiteParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteParentAuditOrderBy>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


export type SubscriptionRootAppetiteParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAppetiteParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AppetiteParentAuditStreamCursorInput>>;
  where?: InputMaybe<AppetiteParentAuditBoolExp>;
};


export type SubscriptionRootAppetiteParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAppetiteParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AppetiteParentStreamCursorInput>>;
  where?: InputMaybe<AppetiteParentBoolExp>;
};


export type SubscriptionRootAppetiteStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AppetiteStreamCursorInput>>;
  where?: InputMaybe<AppetiteBoolExp>;
};


export type SubscriptionRootAppetiteTypeArgs = {
  distinct_on?: InputMaybe<Array<AppetiteTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteTypeOrderBy>>;
  where?: InputMaybe<AppetiteTypeBoolExp>;
};


export type SubscriptionRootAppetiteTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<AppetiteTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AppetiteTypeOrderBy>>;
  where?: InputMaybe<AppetiteTypeBoolExp>;
};


export type SubscriptionRootAppetiteTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAppetiteTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AppetiteTypeStreamCursorInput>>;
  where?: InputMaybe<AppetiteTypeBoolExp>;
};


export type SubscriptionRootApprovalArgs = {
  distinct_on?: InputMaybe<Array<ApprovalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalOrderBy>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


export type SubscriptionRootApprovalAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalOrderBy>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


export type SubscriptionRootApprovalAuditArgs = {
  distinct_on?: InputMaybe<Array<ApprovalAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalAuditOrderBy>>;
  where?: InputMaybe<ApprovalAuditBoolExp>;
};


export type SubscriptionRootApprovalAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalAuditOrderBy>>;
  where?: InputMaybe<ApprovalAuditBoolExp>;
};


export type SubscriptionRootApprovalAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootApprovalAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalAuditStreamCursorInput>>;
  where?: InputMaybe<ApprovalAuditBoolExp>;
};


export type SubscriptionRootApprovalByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootApprovalInFlightEditRuleArgs = {
  distinct_on?: InputMaybe<Array<ApprovalInFlightEditRuleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalInFlightEditRuleOrderBy>>;
  where?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
};


export type SubscriptionRootApprovalInFlightEditRuleAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalInFlightEditRuleSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalInFlightEditRuleOrderBy>>;
  where?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
};


export type SubscriptionRootApprovalInFlightEditRuleByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootApprovalInFlightEditRuleStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalInFlightEditRuleStreamCursorInput>>;
  where?: InputMaybe<ApprovalInFlightEditRuleBoolExp>;
};


export type SubscriptionRootApprovalLevelArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelOrderBy>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};


export type SubscriptionRootApprovalLevelAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelOrderBy>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};


export type SubscriptionRootApprovalLevelAuditArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelAuditOrderBy>>;
  where?: InputMaybe<ApprovalLevelAuditBoolExp>;
};


export type SubscriptionRootApprovalLevelAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalLevelAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalLevelAuditOrderBy>>;
  where?: InputMaybe<ApprovalLevelAuditBoolExp>;
};


export type SubscriptionRootApprovalLevelAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootApprovalLevelAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalLevelAuditStreamCursorInput>>;
  where?: InputMaybe<ApprovalLevelAuditBoolExp>;
};


export type SubscriptionRootApprovalLevelByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootApprovalLevelStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalLevelStreamCursorInput>>;
  where?: InputMaybe<ApprovalLevelBoolExp>;
};


export type SubscriptionRootApprovalRuleTypeArgs = {
  distinct_on?: InputMaybe<Array<ApprovalRuleTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalRuleTypeOrderBy>>;
  where?: InputMaybe<ApprovalRuleTypeBoolExp>;
};


export type SubscriptionRootApprovalRuleTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalRuleTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalRuleTypeOrderBy>>;
  where?: InputMaybe<ApprovalRuleTypeBoolExp>;
};


export type SubscriptionRootApprovalRuleTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootApprovalRuleTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalRuleTypeStreamCursorInput>>;
  where?: InputMaybe<ApprovalRuleTypeBoolExp>;
};


export type SubscriptionRootApprovalStatusArgs = {
  distinct_on?: InputMaybe<Array<ApprovalStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalStatusOrderBy>>;
  where?: InputMaybe<ApprovalStatusBoolExp>;
};


export type SubscriptionRootApprovalStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApprovalStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApprovalStatusOrderBy>>;
  where?: InputMaybe<ApprovalStatusBoolExp>;
};


export type SubscriptionRootApprovalStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootApprovalStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalStatusStreamCursorInput>>;
  where?: InputMaybe<ApprovalStatusBoolExp>;
};


export type SubscriptionRootApprovalStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApprovalStreamCursorInput>>;
  where?: InputMaybe<ApprovalBoolExp>;
};


export type SubscriptionRootApproverArgs = {
  distinct_on?: InputMaybe<Array<ApproverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverOrderBy>>;
  where?: InputMaybe<ApproverBoolExp>;
};


export type SubscriptionRootApproverAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverOrderBy>>;
  where?: InputMaybe<ApproverBoolExp>;
};


export type SubscriptionRootApproverAuditArgs = {
  distinct_on?: InputMaybe<Array<ApproverAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverAuditOrderBy>>;
  where?: InputMaybe<ApproverAuditBoolExp>;
};


export type SubscriptionRootApproverAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverAuditOrderBy>>;
  where?: InputMaybe<ApproverAuditBoolExp>;
};


export type SubscriptionRootApproverAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootApproverAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApproverAuditStreamCursorInput>>;
  where?: InputMaybe<ApproverAuditBoolExp>;
};


export type SubscriptionRootApproverByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootApproverResponseArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


export type SubscriptionRootApproverResponseAggregateArgs = {
  distinct_on?: InputMaybe<Array<ApproverResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ApproverResponseOrderBy>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


export type SubscriptionRootApproverResponseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootApproverResponseStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApproverResponseStreamCursorInput>>;
  where?: InputMaybe<ApproverResponseBoolExp>;
};


export type SubscriptionRootApproverStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ApproverStreamCursorInput>>;
  where?: InputMaybe<ApproverBoolExp>;
};


export type SubscriptionRootAssessmentArgs = {
  distinct_on?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type SubscriptionRootAssessmentActivityArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


export type SubscriptionRootAssessmentActivityAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityOrderBy>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


export type SubscriptionRootAssessmentActivityAuditArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityAuditOrderBy>>;
  where?: InputMaybe<AssessmentActivityAuditBoolExp>;
};


export type SubscriptionRootAssessmentActivityAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityAuditOrderBy>>;
  where?: InputMaybe<AssessmentActivityAuditBoolExp>;
};


export type SubscriptionRootAssessmentActivityAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootAssessmentActivityAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentActivityAuditStreamCursorInput>>;
  where?: InputMaybe<AssessmentActivityAuditBoolExp>;
};


export type SubscriptionRootAssessmentActivityByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootAssessmentActivityStatusArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityStatusOrderBy>>;
  where?: InputMaybe<AssessmentActivityStatusBoolExp>;
};


export type SubscriptionRootAssessmentActivityStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityStatusOrderBy>>;
  where?: InputMaybe<AssessmentActivityStatusBoolExp>;
};


export type SubscriptionRootAssessmentActivityStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAssessmentActivityStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentActivityStatusStreamCursorInput>>;
  where?: InputMaybe<AssessmentActivityStatusBoolExp>;
};


export type SubscriptionRootAssessmentActivityStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentActivityStreamCursorInput>>;
  where?: InputMaybe<AssessmentActivityBoolExp>;
};


export type SubscriptionRootAssessmentActivityTypeArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityTypeOrderBy>>;
  where?: InputMaybe<AssessmentActivityTypeBoolExp>;
};


export type SubscriptionRootAssessmentActivityTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentActivityTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentActivityTypeOrderBy>>;
  where?: InputMaybe<AssessmentActivityTypeBoolExp>;
};


export type SubscriptionRootAssessmentActivityTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAssessmentActivityTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentActivityTypeStreamCursorInput>>;
  where?: InputMaybe<AssessmentActivityTypeBoolExp>;
};


export type SubscriptionRootAssessmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentOrderBy>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type SubscriptionRootAssessmentAuditArgs = {
  distinct_on?: InputMaybe<Array<AssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentAuditOrderBy>>;
  where?: InputMaybe<AssessmentAuditBoolExp>;
};


export type SubscriptionRootAssessmentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentAuditOrderBy>>;
  where?: InputMaybe<AssessmentAuditBoolExp>;
};


export type SubscriptionRootAssessmentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootAssessmentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentAuditStreamCursorInput>>;
  where?: InputMaybe<AssessmentAuditBoolExp>;
};


export type SubscriptionRootAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootAssessmentResultParentArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


export type SubscriptionRootAssessmentResultParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


export type SubscriptionRootAssessmentResultParentAuditArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentAuditOrderBy>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


export type SubscriptionRootAssessmentResultParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentAuditOrderBy>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


export type SubscriptionRootAssessmentResultParentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAssessmentResultParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentResultParentAuditStreamCursorInput>>;
  where?: InputMaybe<AssessmentResultParentAuditBoolExp>;
};


export type SubscriptionRootAssessmentResultParentByPkArgs = {
  Id: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAssessmentResultParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentResultParentStreamCursorInput>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


export type SubscriptionRootAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<AssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentStatusOrderBy>>;
  where?: InputMaybe<AssessmentStatusBoolExp>;
};


export type SubscriptionRootAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentStatusOrderBy>>;
  where?: InputMaybe<AssessmentStatusBoolExp>;
};


export type SubscriptionRootAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAssessmentStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentStatusStreamCursorInput>>;
  where?: InputMaybe<AssessmentStatusBoolExp>;
};


export type SubscriptionRootAssessmentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AssessmentStreamCursorInput>>;
  where?: InputMaybe<AssessmentBoolExp>;
};


export type SubscriptionRootAttestationConfigArgs = {
  distinct_on?: InputMaybe<Array<AttestationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationConfigOrderBy>>;
  where?: InputMaybe<AttestationConfigBoolExp>;
};


export type SubscriptionRootAttestationConfigAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationConfigSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationConfigOrderBy>>;
  where?: InputMaybe<AttestationConfigBoolExp>;
};


export type SubscriptionRootAttestationConfigByPkArgs = {
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootAttestationConfigStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AttestationConfigStreamCursorInput>>;
  where?: InputMaybe<AttestationConfigBoolExp>;
};


export type SubscriptionRootAttestationGroupArgs = {
  distinct_on?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationGroupOrderBy>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


export type SubscriptionRootAttestationGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationGroupOrderBy>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


export type SubscriptionRootAttestationGroupByPkArgs = {
  ConfigId: Scalars['uuid']['input'];
  GroupId: Scalars['uuid']['input'];
};


export type SubscriptionRootAttestationGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AttestationGroupStreamCursorInput>>;
  where?: InputMaybe<AttestationGroupBoolExp>;
};


export type SubscriptionRootAttestationRecordArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


export type SubscriptionRootAttestationRecordAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordOrderBy>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


export type SubscriptionRootAttestationRecordByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootAttestationRecordStatusArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordStatusOrderBy>>;
  where?: InputMaybe<AttestationRecordStatusBoolExp>;
};


export type SubscriptionRootAttestationRecordStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<AttestationRecordStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AttestationRecordStatusOrderBy>>;
  where?: InputMaybe<AttestationRecordStatusBoolExp>;
};


export type SubscriptionRootAttestationRecordStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootAttestationRecordStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AttestationRecordStatusStreamCursorInput>>;
  where?: InputMaybe<AttestationRecordStatusBoolExp>;
};


export type SubscriptionRootAttestationRecordStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AttestationRecordStreamCursorInput>>;
  where?: InputMaybe<AttestationRecordBoolExp>;
};


export type SubscriptionRootAuditLogViewArgs = {
  distinct_on?: InputMaybe<Array<AuditLogViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuditLogViewOrderBy>>;
  where?: InputMaybe<AuditLogViewBoolExp>;
};


export type SubscriptionRootAuditLogViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuditLogViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuditLogViewOrderBy>>;
  where?: InputMaybe<AuditLogViewBoolExp>;
};


export type SubscriptionRootAuditLogViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuditLogViewStreamCursorInput>>;
  where?: InputMaybe<AuditLogViewBoolExp>;
};


export type SubscriptionRootAuthOrganisationArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationOrderBy>>;
  where?: InputMaybe<AuthOrganisationBoolExp>;
};


export type SubscriptionRootAuthOrganisationAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationOrderBy>>;
  where?: InputMaybe<AuthOrganisationBoolExp>;
};


export type SubscriptionRootAuthOrganisationAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationAuditBoolExp>;
};


export type SubscriptionRootAuthOrganisationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationAuditBoolExp>;
};


export type SubscriptionRootAuthOrganisationAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootAuthOrganisationAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthOrganisationAuditStreamCursorInput>>;
  where?: InputMaybe<AuthOrganisationAuditBoolExp>;
};


export type SubscriptionRootAuthOrganisationByPkArgs = {
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootAuthOrganisationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthOrganisationStreamCursorInput>>;
  where?: InputMaybe<AuthOrganisationBoolExp>;
};


export type SubscriptionRootAuthOrganisationuserArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


export type SubscriptionRootAuthOrganisationuserAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


export type SubscriptionRootAuthOrganisationuserAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
};


export type SubscriptionRootAuthOrganisationuserAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthOrganisationuserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthOrganisationuserAuditOrderBy>>;
  where?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
};


export type SubscriptionRootAuthOrganisationuserAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


export type SubscriptionRootAuthOrganisationuserAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthOrganisationuserAuditStreamCursorInput>>;
  where?: InputMaybe<AuthOrganisationuserAuditBoolExp>;
};


export type SubscriptionRootAuthOrganisationuserByPkArgs = {
  OrgKey: Scalars['String']['input'];
  User_Id: Scalars['String']['input'];
};


export type SubscriptionRootAuthOrganisationuserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthOrganisationuserStreamCursorInput>>;
  where?: InputMaybe<AuthOrganisationuserBoolExp>;
};


export type SubscriptionRootAuthUserArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type SubscriptionRootAuthUserActivityAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthUserActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserActivityAuditOrderBy>>;
  where?: InputMaybe<AuthUserActivityAuditBoolExp>;
};


export type SubscriptionRootAuthUserActivityAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserActivityAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserActivityAuditOrderBy>>;
  where?: InputMaybe<AuthUserActivityAuditBoolExp>;
};


export type SubscriptionRootAuthUserActivityAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ModifiedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootAuthUserActivityAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserActivityAuditStreamCursorInput>>;
  where?: InputMaybe<AuthUserActivityAuditBoolExp>;
};


export type SubscriptionRootAuthUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserOrderBy>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type SubscriptionRootAuthUserAuditArgs = {
  distinct_on?: InputMaybe<Array<AuthUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserAuditOrderBy>>;
  where?: InputMaybe<AuthUserAuditBoolExp>;
};


export type SubscriptionRootAuthUserAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<AuthUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthUserAuditOrderBy>>;
  where?: InputMaybe<AuthUserAuditBoolExp>;
};


export type SubscriptionRootAuthUserAuditByPkArgs = {
  Id: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootAuthUserAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserAuditStreamCursorInput>>;
  where?: InputMaybe<AuthUserAuditBoolExp>;
};


export type SubscriptionRootAuthUserByPkArgs = {
  Id: Scalars['String']['input'];
};


export type SubscriptionRootAuthUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthUserStreamCursorInput>>;
  where?: InputMaybe<AuthUserBoolExp>;
};


export type SubscriptionRootBusinessAreaArgs = {
  distinct_on?: InputMaybe<Array<BusinessAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BusinessAreaOrderBy>>;
  where?: InputMaybe<BusinessAreaBoolExp>;
};


export type SubscriptionRootBusinessAreaAggregateArgs = {
  distinct_on?: InputMaybe<Array<BusinessAreaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<BusinessAreaOrderBy>>;
  where?: InputMaybe<BusinessAreaBoolExp>;
};


export type SubscriptionRootBusinessAreaByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootBusinessAreaStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<BusinessAreaStreamCursorInput>>;
  where?: InputMaybe<BusinessAreaBoolExp>;
};


export type SubscriptionRootCauseArgs = {
  distinct_on?: InputMaybe<Array<CauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseOrderBy>>;
  where?: InputMaybe<CauseBoolExp>;
};


export type SubscriptionRootCauseAggregateArgs = {
  distinct_on?: InputMaybe<Array<CauseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseOrderBy>>;
  where?: InputMaybe<CauseBoolExp>;
};


export type SubscriptionRootCauseAuditArgs = {
  distinct_on?: InputMaybe<Array<CauseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseAuditOrderBy>>;
  where?: InputMaybe<CauseAuditBoolExp>;
};


export type SubscriptionRootCauseAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CauseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CauseAuditOrderBy>>;
  where?: InputMaybe<CauseAuditBoolExp>;
};


export type SubscriptionRootCauseAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootCauseAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CauseAuditStreamCursorInput>>;
  where?: InputMaybe<CauseAuditBoolExp>;
};


export type SubscriptionRootCauseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootCauseStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CauseStreamCursorInput>>;
  where?: InputMaybe<CauseBoolExp>;
};


export type SubscriptionRootChangeRequestArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


export type SubscriptionRootChangeRequestAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestOrderBy>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


export type SubscriptionRootChangeRequestAuditArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestAuditOrderBy>>;
  where?: InputMaybe<ChangeRequestAuditBoolExp>;
};


export type SubscriptionRootChangeRequestAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestAuditOrderBy>>;
  where?: InputMaybe<ChangeRequestAuditBoolExp>;
};


export type SubscriptionRootChangeRequestAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootChangeRequestAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChangeRequestAuditStreamCursorInput>>;
  where?: InputMaybe<ChangeRequestAuditBoolExp>;
};


export type SubscriptionRootChangeRequestByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootChangeRequestContributorArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestContributorOrderBy>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


export type SubscriptionRootChangeRequestContributorAggregateArgs = {
  distinct_on?: InputMaybe<Array<ChangeRequestContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ChangeRequestContributorOrderBy>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


export type SubscriptionRootChangeRequestContributorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootChangeRequestContributorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChangeRequestContributorStreamCursorInput>>;
  where?: InputMaybe<ChangeRequestContributorBoolExp>;
};


export type SubscriptionRootChangeRequestStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ChangeRequestStreamCursorInput>>;
  where?: InputMaybe<ChangeRequestBoolExp>;
};


export type SubscriptionRootCommentArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type SubscriptionRootCommentAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentOrderBy>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type SubscriptionRootCommentAuditArgs = {
  distinct_on?: InputMaybe<Array<CommentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentAuditOrderBy>>;
  where?: InputMaybe<CommentAuditBoolExp>;
};


export type SubscriptionRootCommentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CommentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CommentAuditOrderBy>>;
  where?: InputMaybe<CommentAuditBoolExp>;
};


export type SubscriptionRootCommentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootCommentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CommentAuditStreamCursorInput>>;
  where?: InputMaybe<CommentAuditBoolExp>;
};


export type SubscriptionRootCommentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootCommentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CommentStreamCursorInput>>;
  where?: InputMaybe<CommentBoolExp>;
};


export type SubscriptionRootComplianceMonitoringAssessmentArgs = {
  distinct_on?: InputMaybe<Array<ComplianceMonitoringAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComplianceMonitoringAssessmentOrderBy>>;
  where?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
};


export type SubscriptionRootComplianceMonitoringAssessmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ComplianceMonitoringAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ComplianceMonitoringAssessmentOrderBy>>;
  where?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
};


export type SubscriptionRootComplianceMonitoringAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootComplianceMonitoringAssessmentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ComplianceMonitoringAssessmentStreamCursorInput>>;
  where?: InputMaybe<ComplianceMonitoringAssessmentBoolExp>;
};


export type SubscriptionRootConfigEnvArgs = {
  distinct_on?: InputMaybe<Array<ConfigEnvSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigEnvOrderBy>>;
  where?: InputMaybe<ConfigEnvBoolExp>;
};


export type SubscriptionRootConfigEnvAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConfigEnvSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConfigEnvOrderBy>>;
  where?: InputMaybe<ConfigEnvBoolExp>;
};


export type SubscriptionRootConfigEnvByPkArgs = {
  Name: Scalars['String']['input'];
};


export type SubscriptionRootConfigEnvStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConfigEnvStreamCursorInput>>;
  where?: InputMaybe<ConfigEnvBoolExp>;
};


export type SubscriptionRootConsequenceArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceOrderBy>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


export type SubscriptionRootConsequenceAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceOrderBy>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


export type SubscriptionRootConsequenceAuditArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceAuditOrderBy>>;
  where?: InputMaybe<ConsequenceAuditBoolExp>;
};


export type SubscriptionRootConsequenceAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceAuditOrderBy>>;
  where?: InputMaybe<ConsequenceAuditBoolExp>;
};


export type SubscriptionRootConsequenceAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootConsequenceAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConsequenceAuditStreamCursorInput>>;
  where?: InputMaybe<ConsequenceAuditBoolExp>;
};


export type SubscriptionRootConsequenceByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootConsequenceStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConsequenceStreamCursorInput>>;
  where?: InputMaybe<ConsequenceBoolExp>;
};


export type SubscriptionRootConsequenceTypeArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceTypeOrderBy>>;
  where?: InputMaybe<ConsequenceTypeBoolExp>;
};


export type SubscriptionRootConsequenceTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConsequenceTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConsequenceTypeOrderBy>>;
  where?: InputMaybe<ConsequenceTypeBoolExp>;
};


export type SubscriptionRootConsequenceTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootConsequenceTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConsequenceTypeStreamCursorInput>>;
  where?: InputMaybe<ConsequenceTypeBoolExp>;
};


export type SubscriptionRootContributorArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


export type SubscriptionRootContributorAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


export type SubscriptionRootContributorAuditArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


export type SubscriptionRootContributorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorAuditOrderBy>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


export type SubscriptionRootContributorAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type SubscriptionRootContributorAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContributorAuditStreamCursorInput>>;
  where?: InputMaybe<ContributorAuditBoolExp>;
};


export type SubscriptionRootContributorByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type SubscriptionRootContributorGroupArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


export type SubscriptionRootContributorGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


export type SubscriptionRootContributorGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


export type SubscriptionRootContributorGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupAuditOrderBy>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


export type SubscriptionRootContributorGroupAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type SubscriptionRootContributorGroupAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContributorGroupAuditStreamCursorInput>>;
  where?: InputMaybe<ContributorGroupAuditBoolExp>;
};


export type SubscriptionRootContributorGroupByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type SubscriptionRootContributorGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContributorGroupStreamCursorInput>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


export type SubscriptionRootContributorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContributorStreamCursorInput>>;
  where?: InputMaybe<ContributorBoolExp>;
};


export type SubscriptionRootContributorTypeArgs = {
  distinct_on?: InputMaybe<Array<ContributorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorTypeOrderBy>>;
  where?: InputMaybe<ContributorTypeBoolExp>;
};


export type SubscriptionRootContributorTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorTypeOrderBy>>;
  where?: InputMaybe<ContributorTypeBoolExp>;
};


export type SubscriptionRootContributorTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootContributorTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ContributorTypeStreamCursorInput>>;
  where?: InputMaybe<ContributorTypeBoolExp>;
};


export type SubscriptionRootControlArgs = {
  distinct_on?: InputMaybe<Array<ControlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlOrderBy>>;
  where?: InputMaybe<ControlBoolExp>;
};


export type SubscriptionRootControlAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlOrderBy>>;
  where?: InputMaybe<ControlBoolExp>;
};


export type SubscriptionRootControlAuditArgs = {
  distinct_on?: InputMaybe<Array<ControlAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlAuditOrderBy>>;
  where?: InputMaybe<ControlAuditBoolExp>;
};


export type SubscriptionRootControlAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlAuditOrderBy>>;
  where?: InputMaybe<ControlAuditBoolExp>;
};


export type SubscriptionRootControlAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootControlAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlAuditStreamCursorInput>>;
  where?: InputMaybe<ControlAuditBoolExp>;
};


export type SubscriptionRootControlByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootControlGroupArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupOrderBy>>;
  where?: InputMaybe<ControlGroupBoolExp>;
};


export type SubscriptionRootControlGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupOrderBy>>;
  where?: InputMaybe<ControlGroupBoolExp>;
};


export type SubscriptionRootControlGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupAuditOrderBy>>;
  where?: InputMaybe<ControlGroupAuditBoolExp>;
};


export type SubscriptionRootControlGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlGroupAuditOrderBy>>;
  where?: InputMaybe<ControlGroupAuditBoolExp>;
};


export type SubscriptionRootControlGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootControlGroupAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlGroupAuditStreamCursorInput>>;
  where?: InputMaybe<ControlGroupAuditBoolExp>;
};


export type SubscriptionRootControlGroupByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootControlGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlGroupStreamCursorInput>>;
  where?: InputMaybe<ControlGroupBoolExp>;
};


export type SubscriptionRootControlParentArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


export type SubscriptionRootControlParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentOrderBy>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


export type SubscriptionRootControlParentAuditArgs = {
  distinct_on?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentAuditOrderBy>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


export type SubscriptionRootControlParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlParentAuditOrderBy>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


export type SubscriptionRootControlParentAuditByPkArgs = {
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootControlParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlParentAuditStreamCursorInput>>;
  where?: InputMaybe<ControlParentAuditBoolExp>;
};


export type SubscriptionRootControlParentByPkArgs = {
  ControlId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootControlParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlParentStreamCursorInput>>;
  where?: InputMaybe<ControlParentBoolExp>;
};


export type SubscriptionRootControlStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlStreamCursorInput>>;
  where?: InputMaybe<ControlBoolExp>;
};


export type SubscriptionRootControlTestFrequencyArgs = {
  distinct_on?: InputMaybe<Array<ControlTestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTestFrequencyOrderBy>>;
  where?: InputMaybe<ControlTestFrequencyBoolExp>;
};


export type SubscriptionRootControlTestFrequencyAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlTestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTestFrequencyOrderBy>>;
  where?: InputMaybe<ControlTestFrequencyBoolExp>;
};


export type SubscriptionRootControlTestFrequencyByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootControlTestFrequencyStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlTestFrequencyStreamCursorInput>>;
  where?: InputMaybe<ControlTestFrequencyBoolExp>;
};


export type SubscriptionRootControlTypeArgs = {
  distinct_on?: InputMaybe<Array<ControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTypeOrderBy>>;
  where?: InputMaybe<ControlTypeBoolExp>;
};


export type SubscriptionRootControlTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ControlTypeOrderBy>>;
  where?: InputMaybe<ControlTypeBoolExp>;
};


export type SubscriptionRootControlTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootControlTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ControlTypeStreamCursorInput>>;
  where?: InputMaybe<ControlTypeBoolExp>;
};


export type SubscriptionRootConversationArgs = {
  distinct_on?: InputMaybe<Array<ConversationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationOrderBy>>;
  where?: InputMaybe<ConversationBoolExp>;
};


export type SubscriptionRootConversationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationOrderBy>>;
  where?: InputMaybe<ConversationBoolExp>;
};


export type SubscriptionRootConversationAuditArgs = {
  distinct_on?: InputMaybe<Array<ConversationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationAuditOrderBy>>;
  where?: InputMaybe<ConversationAuditBoolExp>;
};


export type SubscriptionRootConversationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ConversationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ConversationAuditOrderBy>>;
  where?: InputMaybe<ConversationAuditBoolExp>;
};


export type SubscriptionRootConversationAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootConversationAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConversationAuditStreamCursorInput>>;
  where?: InputMaybe<ConversationAuditBoolExp>;
};


export type SubscriptionRootConversationByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootConversationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ConversationStreamCursorInput>>;
  where?: InputMaybe<ConversationBoolExp>;
};


export type SubscriptionRootCostTypeArgs = {
  distinct_on?: InputMaybe<Array<CostTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CostTypeOrderBy>>;
  where?: InputMaybe<CostTypeBoolExp>;
};


export type SubscriptionRootCostTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<CostTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CostTypeOrderBy>>;
  where?: InputMaybe<CostTypeBoolExp>;
};


export type SubscriptionRootCostTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootCostTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CostTypeStreamCursorInput>>;
  where?: InputMaybe<CostTypeBoolExp>;
};


export type SubscriptionRootCustomAttributeSchemaArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaBoolExp>;
};


export type SubscriptionRootCustomAttributeSchemaAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaBoolExp>;
};


export type SubscriptionRootCustomAttributeSchemaAuditArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaAuditOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
};


export type SubscriptionRootCustomAttributeSchemaAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomAttributeSchemaAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomAttributeSchemaAuditOrderBy>>;
  where?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
};


export type SubscriptionRootCustomAttributeSchemaAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootCustomAttributeSchemaAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CustomAttributeSchemaAuditStreamCursorInput>>;
  where?: InputMaybe<CustomAttributeSchemaAuditBoolExp>;
};


export type SubscriptionRootCustomAttributeSchemaByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootCustomAttributeSchemaStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CustomAttributeSchemaStreamCursorInput>>;
  where?: InputMaybe<CustomAttributeSchemaBoolExp>;
};


export type SubscriptionRootCustomRibbonArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonOrderBy>>;
  where?: InputMaybe<CustomRibbonBoolExp>;
};


export type SubscriptionRootCustomRibbonAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonOrderBy>>;
  where?: InputMaybe<CustomRibbonBoolExp>;
};


export type SubscriptionRootCustomRibbonAuditArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonAuditOrderBy>>;
  where?: InputMaybe<CustomRibbonAuditBoolExp>;
};


export type SubscriptionRootCustomRibbonAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<CustomRibbonAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<CustomRibbonAuditOrderBy>>;
  where?: InputMaybe<CustomRibbonAuditBoolExp>;
};


export type SubscriptionRootCustomRibbonAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootCustomRibbonAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CustomRibbonAuditStreamCursorInput>>;
  where?: InputMaybe<CustomRibbonAuditBoolExp>;
};


export type SubscriptionRootCustomRibbonByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootCustomRibbonStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<CustomRibbonStreamCursorInput>>;
  where?: InputMaybe<CustomRibbonBoolExp>;
};


export type SubscriptionRootDashboardArgs = {
  distinct_on?: InputMaybe<Array<DashboardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardOrderBy>>;
  where?: InputMaybe<DashboardBoolExp>;
};


export type SubscriptionRootDashboardAggregateArgs = {
  distinct_on?: InputMaybe<Array<DashboardSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardOrderBy>>;
  where?: InputMaybe<DashboardBoolExp>;
};


export type SubscriptionRootDashboardAuditArgs = {
  distinct_on?: InputMaybe<Array<DashboardAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardAuditOrderBy>>;
  where?: InputMaybe<DashboardAuditBoolExp>;
};


export type SubscriptionRootDashboardAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DashboardAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardAuditOrderBy>>;
  where?: InputMaybe<DashboardAuditBoolExp>;
};


export type SubscriptionRootDashboardAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDashboardAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DashboardAuditStreamCursorInput>>;
  where?: InputMaybe<DashboardAuditBoolExp>;
};


export type SubscriptionRootDashboardByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootDashboardSharingTypeArgs = {
  distinct_on?: InputMaybe<Array<DashboardSharingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardSharingTypeOrderBy>>;
  where?: InputMaybe<DashboardSharingTypeBoolExp>;
};


export type SubscriptionRootDashboardSharingTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<DashboardSharingTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DashboardSharingTypeOrderBy>>;
  where?: InputMaybe<DashboardSharingTypeBoolExp>;
};


export type SubscriptionRootDashboardSharingTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootDashboardSharingTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DashboardSharingTypeStreamCursorInput>>;
  where?: InputMaybe<DashboardSharingTypeBoolExp>;
};


export type SubscriptionRootDashboardStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DashboardStreamCursorInput>>;
  where?: InputMaybe<DashboardBoolExp>;
};


export type SubscriptionRootDataImportArgs = {
  distinct_on?: InputMaybe<Array<DataImportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportOrderBy>>;
  where?: InputMaybe<DataImportBoolExp>;
};


export type SubscriptionRootDataImportAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportOrderBy>>;
  where?: InputMaybe<DataImportBoolExp>;
};


export type SubscriptionRootDataImportAuditArgs = {
  distinct_on?: InputMaybe<Array<DataImportAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportAuditOrderBy>>;
  where?: InputMaybe<DataImportAuditBoolExp>;
};


export type SubscriptionRootDataImportAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportAuditOrderBy>>;
  where?: InputMaybe<DataImportAuditBoolExp>;
};


export type SubscriptionRootDataImportAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDataImportAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DataImportAuditStreamCursorInput>>;
  where?: InputMaybe<DataImportAuditBoolExp>;
};


export type SubscriptionRootDataImportByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootDataImportErrorArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorOrderBy>>;
  where?: InputMaybe<DataImportErrorBoolExp>;
};


export type SubscriptionRootDataImportErrorAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorOrderBy>>;
  where?: InputMaybe<DataImportErrorBoolExp>;
};


export type SubscriptionRootDataImportErrorAuditArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorAuditOrderBy>>;
  where?: InputMaybe<DataImportErrorAuditBoolExp>;
};


export type SubscriptionRootDataImportErrorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportErrorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportErrorAuditOrderBy>>;
  where?: InputMaybe<DataImportErrorAuditBoolExp>;
};


export type SubscriptionRootDataImportErrorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDataImportErrorAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DataImportErrorAuditStreamCursorInput>>;
  where?: InputMaybe<DataImportErrorAuditBoolExp>;
};


export type SubscriptionRootDataImportErrorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootDataImportErrorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DataImportErrorStreamCursorInput>>;
  where?: InputMaybe<DataImportErrorBoolExp>;
};


export type SubscriptionRootDataImportStatusArgs = {
  distinct_on?: InputMaybe<Array<DataImportStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportStatusOrderBy>>;
  where?: InputMaybe<DataImportStatusBoolExp>;
};


export type SubscriptionRootDataImportStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<DataImportStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DataImportStatusOrderBy>>;
  where?: InputMaybe<DataImportStatusBoolExp>;
};


export type SubscriptionRootDataImportStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootDataImportStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DataImportStatusStreamCursorInput>>;
  where?: InputMaybe<DataImportStatusBoolExp>;
};


export type SubscriptionRootDataImportStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DataImportStreamCursorInput>>;
  where?: InputMaybe<DataImportBoolExp>;
};


export type SubscriptionRootDepartmentArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


export type SubscriptionRootDepartmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


export type SubscriptionRootDepartmentAuditArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


export type SubscriptionRootDepartmentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentAuditOrderBy>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


export type SubscriptionRootDepartmentAuditByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootDepartmentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentAuditStreamCursorInput>>;
  where?: InputMaybe<DepartmentAuditBoolExp>;
};


export type SubscriptionRootDepartmentByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootDepartmentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentStreamCursorInput>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


export type SubscriptionRootDepartmentTypeArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeOrderBy>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};


export type SubscriptionRootDepartmentTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeOrderBy>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};


export type SubscriptionRootDepartmentTypeAuditArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeAuditBoolExp>;
};


export type SubscriptionRootDepartmentTypeAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeAuditBoolExp>;
};


export type SubscriptionRootDepartmentTypeAuditByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDepartmentTypeAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentTypeAuditStreamCursorInput>>;
  where?: InputMaybe<DepartmentTypeAuditBoolExp>;
};


export type SubscriptionRootDepartmentTypeByPkArgs = {
  DepartmentTypeId: Scalars['uuid']['input'];
};


export type SubscriptionRootDepartmentTypeGroupArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupBoolExp>;
};


export type SubscriptionRootDepartmentTypeGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupBoolExp>;
};


export type SubscriptionRootDepartmentTypeGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
};


export type SubscriptionRootDepartmentTypeGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentTypeGroupAuditOrderBy>>;
  where?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
};


export type SubscriptionRootDepartmentTypeGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDepartmentTypeGroupAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentTypeGroupAuditStreamCursorInput>>;
  where?: InputMaybe<DepartmentTypeGroupAuditBoolExp>;
};


export type SubscriptionRootDepartmentTypeGroupByPkArgs = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootDepartmentTypeGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentTypeGroupStreamCursorInput>>;
  where?: InputMaybe<DepartmentTypeGroupBoolExp>;
};


export type SubscriptionRootDepartmentTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DepartmentTypeStreamCursorInput>>;
  where?: InputMaybe<DepartmentTypeBoolExp>;
};


export type SubscriptionRootDocumentArgs = {
  distinct_on?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type SubscriptionRootDocumentActionAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentActionAuditOrderBy>>;
  where?: InputMaybe<DocumentActionAuditBoolExp>;
};


export type SubscriptionRootDocumentActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentActionAuditOrderBy>>;
  where?: InputMaybe<DocumentActionAuditBoolExp>;
};


export type SubscriptionRootDocumentActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  DocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDocumentActionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentActionAuditStreamCursorInput>>;
  where?: InputMaybe<DocumentActionAuditBoolExp>;
};


export type SubscriptionRootDocumentAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentOrderBy>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type SubscriptionRootDocumentAssessmentResultArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultBoolExp>;
};


export type SubscriptionRootDocumentAssessmentResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultBoolExp>;
};


export type SubscriptionRootDocumentAssessmentResultAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootDocumentAssessmentResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootDocumentAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDocumentAssessmentResultAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentAssessmentResultAuditStreamCursorInput>>;
  where?: InputMaybe<DocumentAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootDocumentAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootDocumentAssessmentResultStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentAssessmentResultStreamCursorInput>>;
  where?: InputMaybe<DocumentAssessmentResultBoolExp>;
};


export type SubscriptionRootDocumentAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentStatusOrderBy>>;
  where?: InputMaybe<DocumentAssessmentStatusBoolExp>;
};


export type SubscriptionRootDocumentAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAssessmentStatusOrderBy>>;
  where?: InputMaybe<DocumentAssessmentStatusBoolExp>;
};


export type SubscriptionRootDocumentAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootDocumentAssessmentStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentAssessmentStatusStreamCursorInput>>;
  where?: InputMaybe<DocumentAssessmentStatusBoolExp>;
};


export type SubscriptionRootDocumentAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentAuditBoolExp>;
};


export type SubscriptionRootDocumentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentAuditBoolExp>;
};


export type SubscriptionRootDocumentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDocumentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentAuditStreamCursorInput>>;
  where?: InputMaybe<DocumentAuditBoolExp>;
};


export type SubscriptionRootDocumentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootDocumentFileArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileOrderBy>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


export type SubscriptionRootDocumentFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileOrderBy>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


export type SubscriptionRootDocumentFileAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileAuditOrderBy>>;
  where?: InputMaybe<DocumentFileAuditBoolExp>;
};


export type SubscriptionRootDocumentFileAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileAuditOrderBy>>;
  where?: InputMaybe<DocumentFileAuditBoolExp>;
};


export type SubscriptionRootDocumentFileAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDocumentFileAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentFileAuditStreamCursorInput>>;
  where?: InputMaybe<DocumentFileAuditBoolExp>;
};


export type SubscriptionRootDocumentFileByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootDocumentFileStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentFileStreamCursorInput>>;
  where?: InputMaybe<DocumentFileBoolExp>;
};


export type SubscriptionRootDocumentFileTypeArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileTypeOrderBy>>;
  where?: InputMaybe<DocumentFileTypeBoolExp>;
};


export type SubscriptionRootDocumentFileTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentFileTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentFileTypeOrderBy>>;
  where?: InputMaybe<DocumentFileTypeBoolExp>;
};


export type SubscriptionRootDocumentFileTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootDocumentFileTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentFileTypeStreamCursorInput>>;
  where?: InputMaybe<DocumentFileTypeBoolExp>;
};


export type SubscriptionRootDocumentIssueAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentIssueAuditOrderBy>>;
  where?: InputMaybe<DocumentIssueAuditBoolExp>;
};


export type SubscriptionRootDocumentIssueAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentIssueAuditOrderBy>>;
  where?: InputMaybe<DocumentIssueAuditBoolExp>;
};


export type SubscriptionRootDocumentIssueAuditByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDocumentIssueAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentIssueAuditStreamCursorInput>>;
  where?: InputMaybe<DocumentIssueAuditBoolExp>;
};


export type SubscriptionRootDocumentLinkedDocumentArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


export type SubscriptionRootDocumentLinkedDocumentAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


export type SubscriptionRootDocumentLinkedDocumentAuditArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
};


export type SubscriptionRootDocumentLinkedDocumentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<DocumentLinkedDocumentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DocumentLinkedDocumentAuditOrderBy>>;
  where?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
};


export type SubscriptionRootDocumentLinkedDocumentAuditByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootDocumentLinkedDocumentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentLinkedDocumentAuditStreamCursorInput>>;
  where?: InputMaybe<DocumentLinkedDocumentAuditBoolExp>;
};


export type SubscriptionRootDocumentLinkedDocumentByPkArgs = {
  DocumentId: Scalars['uuid']['input'];
  LinkedDocumentId: Scalars['uuid']['input'];
};


export type SubscriptionRootDocumentLinkedDocumentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentLinkedDocumentStreamCursorInput>>;
  where?: InputMaybe<DocumentLinkedDocumentBoolExp>;
};


export type SubscriptionRootDocumentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<DocumentStreamCursorInput>>;
  where?: InputMaybe<DocumentBoolExp>;
};


export type SubscriptionRootFileArgs = {
  distinct_on?: InputMaybe<Array<FileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileOrderBy>>;
  where?: InputMaybe<FileBoolExp>;
};


export type SubscriptionRootFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileOrderBy>>;
  where?: InputMaybe<FileBoolExp>;
};


export type SubscriptionRootFileAuditArgs = {
  distinct_on?: InputMaybe<Array<FileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileAuditOrderBy>>;
  where?: InputMaybe<FileAuditBoolExp>;
};


export type SubscriptionRootFileAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FileAuditOrderBy>>;
  where?: InputMaybe<FileAuditBoolExp>;
};


export type SubscriptionRootFileAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootFileAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FileAuditStreamCursorInput>>;
  where?: InputMaybe<FileAuditBoolExp>;
};


export type SubscriptionRootFileByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootFileStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FileStreamCursorInput>>;
  where?: InputMaybe<FileBoolExp>;
};


export type SubscriptionRootFormConfigurationArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationOrderBy>>;
  where?: InputMaybe<FormConfigurationBoolExp>;
};


export type SubscriptionRootFormConfigurationAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationOrderBy>>;
  where?: InputMaybe<FormConfigurationBoolExp>;
};


export type SubscriptionRootFormConfigurationAuditArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormConfigurationAuditBoolExp>;
};


export type SubscriptionRootFormConfigurationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormConfigurationAuditBoolExp>;
};


export type SubscriptionRootFormConfigurationAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentType: Scalars['String']['input'];
};


export type SubscriptionRootFormConfigurationAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FormConfigurationAuditStreamCursorInput>>;
  where?: InputMaybe<FormConfigurationAuditBoolExp>;
};


export type SubscriptionRootFormConfigurationByPkArgs = {
  OrgKey: Scalars['String']['input'];
  ParentType: ParentTypeEnum;
};


export type SubscriptionRootFormConfigurationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FormConfigurationStreamCursorInput>>;
  where?: InputMaybe<FormConfigurationBoolExp>;
};


export type SubscriptionRootFormFieldConfigurationArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


export type SubscriptionRootFormFieldConfigurationAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


export type SubscriptionRootFormFieldConfigurationAuditArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
};


export type SubscriptionRootFormFieldConfigurationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldConfigurationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldConfigurationAuditOrderBy>>;
  where?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
};


export type SubscriptionRootFormFieldConfigurationAuditByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootFormFieldConfigurationAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FormFieldConfigurationAuditStreamCursorInput>>;
  where?: InputMaybe<FormFieldConfigurationAuditBoolExp>;
};


export type SubscriptionRootFormFieldConfigurationByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootFormFieldConfigurationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FormFieldConfigurationStreamCursorInput>>;
  where?: InputMaybe<FormFieldConfigurationBoolExp>;
};


export type SubscriptionRootFormFieldOrderingArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingOrderBy>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};


export type SubscriptionRootFormFieldOrderingAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingOrderBy>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};


export type SubscriptionRootFormFieldOrderingAuditArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingAuditOrderBy>>;
  where?: InputMaybe<FormFieldOrderingAuditBoolExp>;
};


export type SubscriptionRootFormFieldOrderingAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<FormFieldOrderingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<FormFieldOrderingAuditOrderBy>>;
  where?: InputMaybe<FormFieldOrderingAuditBoolExp>;
};


export type SubscriptionRootFormFieldOrderingAuditByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootFormFieldOrderingAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FormFieldOrderingAuditStreamCursorInput>>;
  where?: InputMaybe<FormFieldOrderingAuditBoolExp>;
};


export type SubscriptionRootFormFieldOrderingByPkArgs = {
  FieldId: Scalars['String']['input'];
  FormConfigurationParentType: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootFormFieldOrderingStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<FormFieldOrderingStreamCursorInput>>;
  where?: InputMaybe<FormFieldOrderingBoolExp>;
};


export type SubscriptionRootImpactArgs = {
  distinct_on?: InputMaybe<Array<ImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactOrderBy>>;
  where?: InputMaybe<ImpactBoolExp>;
};


export type SubscriptionRootImpactAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactOrderBy>>;
  where?: InputMaybe<ImpactBoolExp>;
};


export type SubscriptionRootImpactAuditArgs = {
  distinct_on?: InputMaybe<Array<ImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactAuditOrderBy>>;
  where?: InputMaybe<ImpactAuditBoolExp>;
};


export type SubscriptionRootImpactAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactAuditOrderBy>>;
  where?: InputMaybe<ImpactAuditBoolExp>;
};


export type SubscriptionRootImpactAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootImpactAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImpactAuditStreamCursorInput>>;
  where?: InputMaybe<ImpactAuditBoolExp>;
};


export type SubscriptionRootImpactByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootImpactParentArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentOrderBy>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


export type SubscriptionRootImpactParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentOrderBy>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


export type SubscriptionRootImpactParentAuditArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentAuditOrderBy>>;
  where?: InputMaybe<ImpactParentAuditBoolExp>;
};


export type SubscriptionRootImpactParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactParentAuditOrderBy>>;
  where?: InputMaybe<ImpactParentAuditBoolExp>;
};


export type SubscriptionRootImpactParentAuditByPkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootImpactParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImpactParentAuditStreamCursorInput>>;
  where?: InputMaybe<ImpactParentAuditBoolExp>;
};


export type SubscriptionRootImpactParentByPkArgs = {
  ImpactId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootImpactParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImpactParentStreamCursorInput>>;
  where?: InputMaybe<ImpactParentBoolExp>;
};


export type SubscriptionRootImpactRatingArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


export type SubscriptionRootImpactRatingAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingOrderBy>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


export type SubscriptionRootImpactRatingAuditArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingAuditOrderBy>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


export type SubscriptionRootImpactRatingAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ImpactRatingAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ImpactRatingAuditOrderBy>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


export type SubscriptionRootImpactRatingAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootImpactRatingAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImpactRatingAuditStreamCursorInput>>;
  where?: InputMaybe<ImpactRatingAuditBoolExp>;
};


export type SubscriptionRootImpactRatingByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootImpactRatingStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImpactRatingStreamCursorInput>>;
  where?: InputMaybe<ImpactRatingBoolExp>;
};


export type SubscriptionRootImpactStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ImpactStreamCursorInput>>;
  where?: InputMaybe<ImpactBoolExp>;
};


export type SubscriptionRootIndicatorArgs = {
  distinct_on?: InputMaybe<Array<IndicatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorOrderBy>>;
  where?: InputMaybe<IndicatorBoolExp>;
};


export type SubscriptionRootIndicatorAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorOrderBy>>;
  where?: InputMaybe<IndicatorBoolExp>;
};


export type SubscriptionRootIndicatorAuditArgs = {
  distinct_on?: InputMaybe<Array<IndicatorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorAuditOrderBy>>;
  where?: InputMaybe<IndicatorAuditBoolExp>;
};


export type SubscriptionRootIndicatorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorAuditOrderBy>>;
  where?: InputMaybe<IndicatorAuditBoolExp>;
};


export type SubscriptionRootIndicatorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootIndicatorAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IndicatorAuditStreamCursorInput>>;
  where?: InputMaybe<IndicatorAuditBoolExp>;
};


export type SubscriptionRootIndicatorByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootIndicatorParentArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


export type SubscriptionRootIndicatorParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorParentOrderBy>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


export type SubscriptionRootIndicatorParentByPkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootIndicatorParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IndicatorParentStreamCursorInput>>;
  where?: InputMaybe<IndicatorParentBoolExp>;
};


export type SubscriptionRootIndicatorResultArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultOrderBy>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


export type SubscriptionRootIndicatorResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultOrderBy>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


export type SubscriptionRootIndicatorResultAuditArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultAuditOrderBy>>;
  where?: InputMaybe<IndicatorResultAuditBoolExp>;
};


export type SubscriptionRootIndicatorResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorResultAuditOrderBy>>;
  where?: InputMaybe<IndicatorResultAuditBoolExp>;
};


export type SubscriptionRootIndicatorResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootIndicatorResultAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IndicatorResultAuditStreamCursorInput>>;
  where?: InputMaybe<IndicatorResultAuditBoolExp>;
};


export type SubscriptionRootIndicatorResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootIndicatorResultStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IndicatorResultStreamCursorInput>>;
  where?: InputMaybe<IndicatorResultBoolExp>;
};


export type SubscriptionRootIndicatorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IndicatorStreamCursorInput>>;
  where?: InputMaybe<IndicatorBoolExp>;
};


export type SubscriptionRootIndicatorTypeArgs = {
  distinct_on?: InputMaybe<Array<IndicatorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorTypeOrderBy>>;
  where?: InputMaybe<IndicatorTypeBoolExp>;
};


export type SubscriptionRootIndicatorTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<IndicatorTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IndicatorTypeOrderBy>>;
  where?: InputMaybe<IndicatorTypeBoolExp>;
};


export type SubscriptionRootIndicatorTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootIndicatorTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IndicatorTypeStreamCursorInput>>;
  where?: InputMaybe<IndicatorTypeBoolExp>;
};


export type SubscriptionRootInsertPermissionsArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


export type SubscriptionRootInsertPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<InsertPermissionsSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InsertPermissionsOrderBy>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


export type SubscriptionRootInsertPermissionsStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<InsertPermissionsStreamCursorInput>>;
  where?: InputMaybe<InsertPermissionsBoolExp>;
};


export type SubscriptionRootInternalAuditEntityArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditEntityOrderBy>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


export type SubscriptionRootInternalAuditEntityAggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditEntitySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditEntityOrderBy>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


export type SubscriptionRootInternalAuditEntityByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootInternalAuditEntityStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<InternalAuditEntityStreamCursorInput>>;
  where?: InputMaybe<InternalAuditEntityBoolExp>;
};


export type SubscriptionRootInternalAuditReportArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditReportOrderBy>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


export type SubscriptionRootInternalAuditReportAggregateArgs = {
  distinct_on?: InputMaybe<Array<InternalAuditReportSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<InternalAuditReportOrderBy>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


export type SubscriptionRootInternalAuditReportByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootInternalAuditReportStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<InternalAuditReportStreamCursorInput>>;
  where?: InputMaybe<InternalAuditReportBoolExp>;
};


export type SubscriptionRootIssueArgs = {
  distinct_on?: InputMaybe<Array<IssueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueOrderBy>>;
  where?: InputMaybe<IssueBoolExp>;
};


export type SubscriptionRootIssueActionAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueActionAuditOrderBy>>;
  where?: InputMaybe<IssueActionAuditBoolExp>;
};


export type SubscriptionRootIssueActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueActionAuditOrderBy>>;
  where?: InputMaybe<IssueActionAuditBoolExp>;
};


export type SubscriptionRootIssueActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootIssueActionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueActionAuditStreamCursorInput>>;
  where?: InputMaybe<IssueActionAuditBoolExp>;
};


export type SubscriptionRootIssueAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueOrderBy>>;
  where?: InputMaybe<IssueBoolExp>;
};


export type SubscriptionRootIssueAssessmentArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentOrderBy>>;
  where?: InputMaybe<IssueAssessmentBoolExp>;
};


export type SubscriptionRootIssueAssessmentAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentOrderBy>>;
  where?: InputMaybe<IssueAssessmentBoolExp>;
};


export type SubscriptionRootIssueAssessmentAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentAuditOrderBy>>;
  where?: InputMaybe<IssueAssessmentAuditBoolExp>;
};


export type SubscriptionRootIssueAssessmentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentAuditOrderBy>>;
  where?: InputMaybe<IssueAssessmentAuditBoolExp>;
};


export type SubscriptionRootIssueAssessmentAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootIssueAssessmentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueAssessmentAuditStreamCursorInput>>;
  where?: InputMaybe<IssueAssessmentAuditBoolExp>;
};


export type SubscriptionRootIssueAssessmentByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootIssueAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentStatusOrderBy>>;
  where?: InputMaybe<IssueAssessmentStatusBoolExp>;
};


export type SubscriptionRootIssueAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAssessmentStatusOrderBy>>;
  where?: InputMaybe<IssueAssessmentStatusBoolExp>;
};


export type SubscriptionRootIssueAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootIssueAssessmentStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueAssessmentStatusStreamCursorInput>>;
  where?: InputMaybe<IssueAssessmentStatusBoolExp>;
};


export type SubscriptionRootIssueAssessmentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueAssessmentStreamCursorInput>>;
  where?: InputMaybe<IssueAssessmentBoolExp>;
};


export type SubscriptionRootIssueAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAuditOrderBy>>;
  where?: InputMaybe<IssueAuditBoolExp>;
};


export type SubscriptionRootIssueAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueAuditOrderBy>>;
  where?: InputMaybe<IssueAuditBoolExp>;
};


export type SubscriptionRootIssueAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootIssueAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueAuditStreamCursorInput>>;
  where?: InputMaybe<IssueAuditBoolExp>;
};


export type SubscriptionRootIssueByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootIssueParentArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


export type SubscriptionRootIssueParentAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentOrderBy>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


export type SubscriptionRootIssueParentAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentAuditOrderBy>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


export type SubscriptionRootIssueParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueParentAuditOrderBy>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


export type SubscriptionRootIssueParentAuditByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootIssueParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueParentAuditStreamCursorInput>>;
  where?: InputMaybe<IssueParentAuditBoolExp>;
};


export type SubscriptionRootIssueParentByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootIssueParentStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueParentStreamCursorInput>>;
  where?: InputMaybe<IssueParentBoolExp>;
};


export type SubscriptionRootIssueStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueStreamCursorInput>>;
  where?: InputMaybe<IssueBoolExp>;
};


export type SubscriptionRootIssueUpdateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateOrderBy>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};


export type SubscriptionRootIssueUpdateAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateOrderBy>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};


export type SubscriptionRootIssueUpdateAuditArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateAuditOrderBy>>;
  where?: InputMaybe<IssueUpdateAuditBoolExp>;
};


export type SubscriptionRootIssueUpdateAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<IssueUpdateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<IssueUpdateAuditOrderBy>>;
  where?: InputMaybe<IssueUpdateAuditBoolExp>;
};


export type SubscriptionRootIssueUpdateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootIssueUpdateAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueUpdateAuditStreamCursorInput>>;
  where?: InputMaybe<IssueUpdateAuditBoolExp>;
};


export type SubscriptionRootIssueUpdateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootIssueUpdateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<IssueUpdateStreamCursorInput>>;
  where?: InputMaybe<IssueUpdateBoolExp>;
};


export type SubscriptionRootLinkedItemArgs = {
  distinct_on?: InputMaybe<Array<LinkedItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LinkedItemOrderBy>>;
  where?: InputMaybe<LinkedItemBoolExp>;
};


export type SubscriptionRootLinkedItemAggregateArgs = {
  distinct_on?: InputMaybe<Array<LinkedItemSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<LinkedItemOrderBy>>;
  where?: InputMaybe<LinkedItemBoolExp>;
};


export type SubscriptionRootLinkedItemByPkArgs = {
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type SubscriptionRootLinkedItemStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<LinkedItemStreamCursorInput>>;
  where?: InputMaybe<LinkedItemBoolExp>;
};


export type SubscriptionRootNodeArgs = {
  distinct_on?: InputMaybe<Array<NodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeOrderBy>>;
  where?: InputMaybe<NodeBoolExp>;
};


export type SubscriptionRootNodeAggregateArgs = {
  distinct_on?: InputMaybe<Array<NodeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeOrderBy>>;
  where?: InputMaybe<NodeBoolExp>;
};


export type SubscriptionRootNodeAncestorArgs = {
  distinct_on?: InputMaybe<Array<NodeAncestorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeAncestorOrderBy>>;
  where?: InputMaybe<NodeAncestorBoolExp>;
};


export type SubscriptionRootNodeAncestorAggregateArgs = {
  distinct_on?: InputMaybe<Array<NodeAncestorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<NodeAncestorOrderBy>>;
  where?: InputMaybe<NodeAncestorBoolExp>;
};


export type SubscriptionRootNodeAncestorByPkArgs = {
  AncestorId: Scalars['uuid']['input'];
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootNodeAncestorStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NodeAncestorStreamCursorInput>>;
  where?: InputMaybe<NodeAncestorBoolExp>;
};


export type SubscriptionRootNodeByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootNodeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<NodeStreamCursorInput>>;
  where?: InputMaybe<NodeBoolExp>;
};


export type SubscriptionRootObligationArgs = {
  distinct_on?: InputMaybe<Array<ObligationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationOrderBy>>;
  where?: InputMaybe<ObligationBoolExp>;
};


export type SubscriptionRootObligationActionAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationActionAuditOrderBy>>;
  where?: InputMaybe<ObligationActionAuditBoolExp>;
};


export type SubscriptionRootObligationActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationActionAuditOrderBy>>;
  where?: InputMaybe<ObligationActionAuditBoolExp>;
};


export type SubscriptionRootObligationActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};


export type SubscriptionRootObligationActionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationActionAuditStreamCursorInput>>;
  where?: InputMaybe<ObligationActionAuditBoolExp>;
};


export type SubscriptionRootObligationAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationOrderBy>>;
  where?: InputMaybe<ObligationBoolExp>;
};


export type SubscriptionRootObligationAssessmentResultArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultBoolExp>;
};


export type SubscriptionRootObligationAssessmentResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultBoolExp>;
};


export type SubscriptionRootObligationAssessmentResultAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootObligationAssessmentResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootObligationAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootObligationAssessmentResultAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationAssessmentResultAuditStreamCursorInput>>;
  where?: InputMaybe<ObligationAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootObligationAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootObligationAssessmentResultStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationAssessmentResultStreamCursorInput>>;
  where?: InputMaybe<ObligationAssessmentResultBoolExp>;
};


export type SubscriptionRootObligationAssessmentStatusArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentStatusOrderBy>>;
  where?: InputMaybe<ObligationAssessmentStatusBoolExp>;
};


export type SubscriptionRootObligationAssessmentStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAssessmentStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAssessmentStatusOrderBy>>;
  where?: InputMaybe<ObligationAssessmentStatusBoolExp>;
};


export type SubscriptionRootObligationAssessmentStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootObligationAssessmentStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationAssessmentStatusStreamCursorInput>>;
  where?: InputMaybe<ObligationAssessmentStatusBoolExp>;
};


export type SubscriptionRootObligationAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAuditOrderBy>>;
  where?: InputMaybe<ObligationAuditBoolExp>;
};


export type SubscriptionRootObligationAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationAuditOrderBy>>;
  where?: InputMaybe<ObligationAuditBoolExp>;
};


export type SubscriptionRootObligationAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootObligationAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationAuditStreamCursorInput>>;
  where?: InputMaybe<ObligationAuditBoolExp>;
};


export type SubscriptionRootObligationByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootObligationImpactArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactOrderBy>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


export type SubscriptionRootObligationImpactAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactOrderBy>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


export type SubscriptionRootObligationImpactAuditArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactAuditOrderBy>>;
  where?: InputMaybe<ObligationImpactAuditBoolExp>;
};


export type SubscriptionRootObligationImpactAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationImpactAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationImpactAuditOrderBy>>;
  where?: InputMaybe<ObligationImpactAuditBoolExp>;
};


export type SubscriptionRootObligationImpactAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootObligationImpactAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationImpactAuditStreamCursorInput>>;
  where?: InputMaybe<ObligationImpactAuditBoolExp>;
};


export type SubscriptionRootObligationImpactByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootObligationImpactStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationImpactStreamCursorInput>>;
  where?: InputMaybe<ObligationImpactBoolExp>;
};


export type SubscriptionRootObligationStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationStreamCursorInput>>;
  where?: InputMaybe<ObligationBoolExp>;
};


export type SubscriptionRootObligationTypeArgs = {
  distinct_on?: InputMaybe<Array<ObligationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationTypeOrderBy>>;
  where?: InputMaybe<ObligationTypeBoolExp>;
};


export type SubscriptionRootObligationTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ObligationTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ObligationTypeOrderBy>>;
  where?: InputMaybe<ObligationTypeBoolExp>;
};


export type SubscriptionRootObligationTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootObligationTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ObligationTypeStreamCursorInput>>;
  where?: InputMaybe<ObligationTypeBoolExp>;
};


export type SubscriptionRootOwnerArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


export type SubscriptionRootOwnerAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


export type SubscriptionRootOwnerAuditArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


export type SubscriptionRootOwnerAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerAuditOrderBy>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


export type SubscriptionRootOwnerAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type SubscriptionRootOwnerAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OwnerAuditStreamCursorInput>>;
  where?: InputMaybe<OwnerAuditBoolExp>;
};


export type SubscriptionRootOwnerByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type SubscriptionRootOwnerGroupArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


export type SubscriptionRootOwnerGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


export type SubscriptionRootOwnerGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


export type SubscriptionRootOwnerGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupAuditOrderBy>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


export type SubscriptionRootOwnerGroupAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type SubscriptionRootOwnerGroupAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OwnerGroupAuditStreamCursorInput>>;
  where?: InputMaybe<OwnerGroupAuditBoolExp>;
};


export type SubscriptionRootOwnerGroupByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  UserGroupId: Scalars['uuid']['input'];
};


export type SubscriptionRootOwnerGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OwnerGroupStreamCursorInput>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


export type SubscriptionRootOwnerStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<OwnerStreamCursorInput>>;
  where?: InputMaybe<OwnerBoolExp>;
};


export type SubscriptionRootParentTypeArgs = {
  distinct_on?: InputMaybe<Array<ParentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParentTypeOrderBy>>;
  where?: InputMaybe<ParentTypeBoolExp>;
};


export type SubscriptionRootParentTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ParentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ParentTypeOrderBy>>;
  where?: InputMaybe<ParentTypeBoolExp>;
};


export type SubscriptionRootParentTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootParentTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ParentTypeStreamCursorInput>>;
  where?: InputMaybe<ParentTypeBoolExp>;
};


export type SubscriptionRootPermissionArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


export type SubscriptionRootPermissionAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


export type SubscriptionRootPermissionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<PermissionStreamCursorInput>>;
  where?: InputMaybe<PermissionBoolExp>;
};


export type SubscriptionRootQuestionnaireInviteArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


export type SubscriptionRootQuestionnaireInviteAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


export type SubscriptionRootQuestionnaireInviteByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootQuestionnaireInviteStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionnaireInviteStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootQuestionnaireTemplateStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionnaireTemplateStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireTemplateBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateVersionArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateVersionAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateVersionByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootQuestionnaireTemplateVersionStatusArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionStatusOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateVersionStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireTemplateVersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireTemplateVersionStatusOrderBy>>;
  where?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateVersionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootQuestionnaireTemplateVersionStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionnaireTemplateVersionStatusStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireTemplateVersionStatusBoolExp>;
};


export type SubscriptionRootQuestionnaireTemplateVersionStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<QuestionnaireTemplateVersionStreamCursorInput>>;
  where?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
};


export type SubscriptionRootRelationFileArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


export type SubscriptionRootRelationFileAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


export type SubscriptionRootRelationFileAuditArgs = {
  distinct_on?: InputMaybe<Array<RelationFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileAuditOrderBy>>;
  where?: InputMaybe<RelationFileAuditBoolExp>;
};


export type SubscriptionRootRelationFileAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileAuditOrderBy>>;
  where?: InputMaybe<RelationFileAuditBoolExp>;
};


export type SubscriptionRootRelationFileAuditByPkArgs = {
  FileId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootRelationFileAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RelationFileAuditStreamCursorInput>>;
  where?: InputMaybe<RelationFileAuditBoolExp>;
};


export type SubscriptionRootRelationFileByPkArgs = {
  FileId: Scalars['uuid']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootRelationFileStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RelationFileStreamCursorInput>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


export type SubscriptionRootRiskArgs = {
  distinct_on?: InputMaybe<Array<RiskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskOrderBy>>;
  where?: InputMaybe<RiskBoolExp>;
};


export type SubscriptionRootRiskAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskOrderBy>>;
  where?: InputMaybe<RiskBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultAuditArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultAuditOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRiskAssessmentResultAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskAssessmentResultAuditStreamCursorInput>>;
  where?: InputMaybe<RiskAssessmentResultAuditBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootRiskAssessmentResultControlTypeArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultControlTypeOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultControlTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAssessmentResultControlTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAssessmentResultControlTypeOrderBy>>;
  where?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultControlTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootRiskAssessmentResultControlTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskAssessmentResultControlTypeStreamCursorInput>>;
  where?: InputMaybe<RiskAssessmentResultControlTypeBoolExp>;
};


export type SubscriptionRootRiskAssessmentResultStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskAssessmentResultStreamCursorInput>>;
  where?: InputMaybe<RiskAssessmentResultBoolExp>;
};


export type SubscriptionRootRiskAuditArgs = {
  distinct_on?: InputMaybe<Array<RiskAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAuditOrderBy>>;
  where?: InputMaybe<RiskAuditBoolExp>;
};


export type SubscriptionRootRiskAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskAuditOrderBy>>;
  where?: InputMaybe<RiskAuditBoolExp>;
};


export type SubscriptionRootRiskAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRiskAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskAuditStreamCursorInput>>;
  where?: InputMaybe<RiskAuditBoolExp>;
};


export type SubscriptionRootRiskByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootRiskScoreArgs = {
  distinct_on?: InputMaybe<Array<RiskScoreSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoreOrderBy>>;
  where?: InputMaybe<RiskScoreBoolExp>;
};


export type SubscriptionRootRiskScoreAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskScoreSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoreOrderBy>>;
  where?: InputMaybe<RiskScoreBoolExp>;
};


export type SubscriptionRootRiskScoreByPkArgs = {
  RiskId: Scalars['uuid']['input'];
};


export type SubscriptionRootRiskScoreStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskScoreStreamCursorInput>>;
  where?: InputMaybe<RiskScoreBoolExp>;
};


export type SubscriptionRootRiskScoringModelArgs = {
  distinct_on?: InputMaybe<Array<RiskScoringModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoringModelOrderBy>>;
  where?: InputMaybe<RiskScoringModelBoolExp>;
};


export type SubscriptionRootRiskScoringModelAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskScoringModelSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskScoringModelOrderBy>>;
  where?: InputMaybe<RiskScoringModelBoolExp>;
};


export type SubscriptionRootRiskScoringModelByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootRiskScoringModelStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskScoringModelStreamCursorInput>>;
  where?: InputMaybe<RiskScoringModelBoolExp>;
};


export type SubscriptionRootRiskStatusTypeArgs = {
  distinct_on?: InputMaybe<Array<RiskStatusTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskStatusTypeOrderBy>>;
  where?: InputMaybe<RiskStatusTypeBoolExp>;
};


export type SubscriptionRootRiskStatusTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskStatusTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskStatusTypeOrderBy>>;
  where?: InputMaybe<RiskStatusTypeBoolExp>;
};


export type SubscriptionRootRiskStatusTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootRiskStatusTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskStatusTypeStreamCursorInput>>;
  where?: InputMaybe<RiskStatusTypeBoolExp>;
};


export type SubscriptionRootRiskStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskStreamCursorInput>>;
  where?: InputMaybe<RiskBoolExp>;
};


export type SubscriptionRootRiskTreatmentTypeArgs = {
  distinct_on?: InputMaybe<Array<RiskTreatmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskTreatmentTypeOrderBy>>;
  where?: InputMaybe<RiskTreatmentTypeBoolExp>;
};


export type SubscriptionRootRiskTreatmentTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<RiskTreatmentTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RiskTreatmentTypeOrderBy>>;
  where?: InputMaybe<RiskTreatmentTypeBoolExp>;
};


export type SubscriptionRootRiskTreatmentTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootRiskTreatmentTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RiskTreatmentTypeStreamCursorInput>>;
  where?: InputMaybe<RiskTreatmentTypeBoolExp>;
};


export type SubscriptionRootRisksmartApproverResponseAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartApproverResponseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartApproverResponseAuditOrderBy>>;
  where?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
};


export type SubscriptionRootRisksmartApproverResponseAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartApproverResponseAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartApproverResponseAuditOrderBy>>;
  where?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
};


export type SubscriptionRootRisksmartApproverResponseAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRisksmartApproverResponseAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartApproverResponseAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartApproverResponseAuditBoolExp>;
};


export type SubscriptionRootRisksmartChangeRequestContributorAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartChangeRequestContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartChangeRequestContributorAuditOrderBy>>;
  where?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
};


export type SubscriptionRootRisksmartChangeRequestContributorAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartChangeRequestContributorAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartChangeRequestContributorAuditOrderBy>>;
  where?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
};


export type SubscriptionRootRisksmartChangeRequestContributorAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRisksmartChangeRequestContributorAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartChangeRequestContributorAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartChangeRequestContributorAuditBoolExp>;
};


export type SubscriptionRootRisksmartContributorViewArgs = {
  distinct_on?: InputMaybe<Array<RisksmartContributorViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartContributorViewOrderBy>>;
  where?: InputMaybe<RisksmartContributorViewBoolExp>;
};


export type SubscriptionRootRisksmartContributorViewAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartContributorViewSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartContributorViewOrderBy>>;
  where?: InputMaybe<RisksmartContributorViewBoolExp>;
};


export type SubscriptionRootRisksmartContributorViewStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartContributorViewStreamCursorInput>>;
  where?: InputMaybe<RisksmartContributorViewBoolExp>;
};


export type SubscriptionRootRisksmartControlActionAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartControlActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartControlActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartControlActionAuditBoolExp>;
};


export type SubscriptionRootRisksmartControlActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartControlActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartControlActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartControlActionAuditBoolExp>;
};


export type SubscriptionRootRisksmartControlActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ControlId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRisksmartControlActionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartControlActionAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartControlActionAuditBoolExp>;
};


export type SubscriptionRootRisksmartCounterArgs = {
  distinct_on?: InputMaybe<Array<RisksmartCounterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartCounterOrderBy>>;
  where?: InputMaybe<RisksmartCounterBoolExp>;
};


export type SubscriptionRootRisksmartCounterAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartCounterSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartCounterOrderBy>>;
  where?: InputMaybe<RisksmartCounterBoolExp>;
};


export type SubscriptionRootRisksmartCounterByPkArgs = {
  Name: ParentTypeEnum;
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootRisksmartCounterStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartCounterStreamCursorInput>>;
  where?: InputMaybe<RisksmartCounterBoolExp>;
};


export type SubscriptionRootRisksmartIndicatorParentAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


export type SubscriptionRootRisksmartIndicatorParentAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartIndicatorParentAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartIndicatorParentAuditOrderBy>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


export type SubscriptionRootRisksmartIndicatorParentAuditByPkArgs = {
  IndicatorId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
};


export type SubscriptionRootRisksmartIndicatorParentAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartIndicatorParentAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartIndicatorParentAuditBoolExp>;
};


export type SubscriptionRootRisksmartLinkedItemAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartLinkedItemAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartLinkedItemAuditOrderBy>>;
  where?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
};


export type SubscriptionRootRisksmartLinkedItemAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartLinkedItemAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartLinkedItemAuditOrderBy>>;
  where?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
};


export type SubscriptionRootRisksmartLinkedItemAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  Source: Scalars['uuid']['input'];
  Target: Scalars['uuid']['input'];
};


export type SubscriptionRootRisksmartLinkedItemAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartLinkedItemAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartLinkedItemAuditBoolExp>;
};


export type SubscriptionRootRisksmartObligationIssueAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartObligationIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartObligationIssueAuditOrderBy>>;
  where?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
};


export type SubscriptionRootRisksmartObligationIssueAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartObligationIssueAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartObligationIssueAuditOrderBy>>;
  where?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
};


export type SubscriptionRootRisksmartObligationIssueAuditByPkArgs = {
  IssueId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ObligationId: Scalars['uuid']['input'];
};


export type SubscriptionRootRisksmartObligationIssueAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartObligationIssueAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartObligationIssueAuditBoolExp>;
};


export type SubscriptionRootRisksmartQuestionnaireTemplateAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
};


export type SubscriptionRootRisksmartQuestionnaireTemplateAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
};


export type SubscriptionRootRisksmartQuestionnaireTemplateAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRisksmartQuestionnaireTemplateAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartQuestionnaireTemplateAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateAuditBoolExp>;
};


export type SubscriptionRootRisksmartQuestionnaireTemplateVersionAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
};


export type SubscriptionRootRisksmartQuestionnaireTemplateVersionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartQuestionnaireTemplateVersionAuditOrderBy>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
};


export type SubscriptionRootRisksmartQuestionnaireTemplateVersionAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootRisksmartQuestionnaireTemplateVersionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartQuestionnaireTemplateVersionAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartQuestionnaireTemplateVersionAuditBoolExp>;
};


export type SubscriptionRootRisksmartRiskActionAuditArgs = {
  distinct_on?: InputMaybe<Array<RisksmartRiskActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartRiskActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
};


export type SubscriptionRootRisksmartRiskActionAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<RisksmartRiskActionAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RisksmartRiskActionAuditOrderBy>>;
  where?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
};


export type SubscriptionRootRisksmartRiskActionAuditByPkArgs = {
  ActionId: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  RiskId: Scalars['uuid']['input'];
};


export type SubscriptionRootRisksmartRiskActionAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RisksmartRiskActionAuditStreamCursorInput>>;
  where?: InputMaybe<RisksmartRiskActionAuditBoolExp>;
};


export type SubscriptionRootRoleAccessArgs = {
  distinct_on?: InputMaybe<Array<RoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoleAccessOrderBy>>;
  where?: InputMaybe<RoleAccessBoolExp>;
};


export type SubscriptionRootRoleAccessAggregateArgs = {
  distinct_on?: InputMaybe<Array<RoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RoleAccessOrderBy>>;
  where?: InputMaybe<RoleAccessBoolExp>;
};


export type SubscriptionRootRoleAccessByPkArgs = {
  AccessType: AccessTypeEnum;
  ContributorType: ContributorTypeEnum;
  ObjectType: ParentTypeEnum;
  RoleKey: Scalars['String']['input'];
};


export type SubscriptionRootRoleAccessStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<RoleAccessStreamCursorInput>>;
  where?: InputMaybe<RoleAccessBoolExp>;
};


export type SubscriptionRootTagArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


export type SubscriptionRootTagAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


export type SubscriptionRootTagAuditArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


export type SubscriptionRootTagAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagAuditOrderBy>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


export type SubscriptionRootTagAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type SubscriptionRootTagAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TagAuditStreamCursorInput>>;
  where?: InputMaybe<TagAuditBoolExp>;
};


export type SubscriptionRootTagByPkArgs = {
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type SubscriptionRootTagStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TagStreamCursorInput>>;
  where?: InputMaybe<TagBoolExp>;
};


export type SubscriptionRootTagTypeArgs = {
  distinct_on?: InputMaybe<Array<TagTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeOrderBy>>;
  where?: InputMaybe<TagTypeBoolExp>;
};


export type SubscriptionRootTagTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeOrderBy>>;
  where?: InputMaybe<TagTypeBoolExp>;
};


export type SubscriptionRootTagTypeAuditArgs = {
  distinct_on?: InputMaybe<Array<TagTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeAuditOrderBy>>;
  where?: InputMaybe<TagTypeAuditBoolExp>;
};


export type SubscriptionRootTagTypeAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeAuditOrderBy>>;
  where?: InputMaybe<TagTypeAuditBoolExp>;
};


export type SubscriptionRootTagTypeAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  TagTypeId: Scalars['uuid']['input'];
};


export type SubscriptionRootTagTypeAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TagTypeAuditStreamCursorInput>>;
  where?: InputMaybe<TagTypeAuditBoolExp>;
};


export type SubscriptionRootTagTypeByPkArgs = {
  TagTypeId: Scalars['uuid']['input'];
};


export type SubscriptionRootTagTypeGroupArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupOrderBy>>;
  where?: InputMaybe<TagTypeGroupBoolExp>;
};


export type SubscriptionRootTagTypeGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupOrderBy>>;
  where?: InputMaybe<TagTypeGroupBoolExp>;
};


export type SubscriptionRootTagTypeGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupAuditOrderBy>>;
  where?: InputMaybe<TagTypeGroupAuditBoolExp>;
};


export type SubscriptionRootTagTypeGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeGroupAuditOrderBy>>;
  where?: InputMaybe<TagTypeGroupAuditBoolExp>;
};


export type SubscriptionRootTagTypeGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootTagTypeGroupAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TagTypeGroupAuditStreamCursorInput>>;
  where?: InputMaybe<TagTypeGroupAuditBoolExp>;
};


export type SubscriptionRootTagTypeGroupByPkArgs = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootTagTypeGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TagTypeGroupStreamCursorInput>>;
  where?: InputMaybe<TagTypeGroupBoolExp>;
};


export type SubscriptionRootTagTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TagTypeStreamCursorInput>>;
  where?: InputMaybe<TagTypeBoolExp>;
};


export type SubscriptionRootTaxonomyArgs = {
  distinct_on?: InputMaybe<Array<TaxonomySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrderBy>>;
  where?: InputMaybe<TaxonomyBoolExp>;
};


export type SubscriptionRootTaxonomyAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrderBy>>;
  where?: InputMaybe<TaxonomyBoolExp>;
};


export type SubscriptionRootTaxonomyAuditArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyAuditOrderBy>>;
  where?: InputMaybe<TaxonomyAuditBoolExp>;
};


export type SubscriptionRootTaxonomyAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyAuditOrderBy>>;
  where?: InputMaybe<TaxonomyAuditBoolExp>;
};


export type SubscriptionRootTaxonomyAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootTaxonomyAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaxonomyAuditStreamCursorInput>>;
  where?: InputMaybe<TaxonomyAuditBoolExp>;
};


export type SubscriptionRootTaxonomyByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootTaxonomyOrgArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


export type SubscriptionRootTaxonomyOrgAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


export type SubscriptionRootTaxonomyOrgAuditArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgAuditOrderBy>>;
  where?: InputMaybe<TaxonomyOrgAuditBoolExp>;
};


export type SubscriptionRootTaxonomyOrgAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgAuditOrderBy>>;
  where?: InputMaybe<TaxonomyOrgAuditBoolExp>;
};


export type SubscriptionRootTaxonomyOrgAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootTaxonomyOrgAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaxonomyOrgAuditStreamCursorInput>>;
  where?: InputMaybe<TaxonomyOrgAuditBoolExp>;
};


export type SubscriptionRootTaxonomyOrgByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootTaxonomyOrgStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaxonomyOrgStreamCursorInput>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


export type SubscriptionRootTaxonomyStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TaxonomyStreamCursorInput>>;
  where?: InputMaybe<TaxonomyBoolExp>;
};


export type SubscriptionRootTestFrequencyArgs = {
  distinct_on?: InputMaybe<Array<TestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestFrequencyOrderBy>>;
  where?: InputMaybe<TestFrequencyBoolExp>;
};


export type SubscriptionRootTestFrequencyAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestFrequencySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestFrequencyOrderBy>>;
  where?: InputMaybe<TestFrequencyBoolExp>;
};


export type SubscriptionRootTestFrequencyByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootTestFrequencyStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TestFrequencyStreamCursorInput>>;
  where?: InputMaybe<TestFrequencyBoolExp>;
};


export type SubscriptionRootTestResultArgs = {
  distinct_on?: InputMaybe<Array<TestResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultOrderBy>>;
  where?: InputMaybe<TestResultBoolExp>;
};


export type SubscriptionRootTestResultAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestResultSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultOrderBy>>;
  where?: InputMaybe<TestResultBoolExp>;
};


export type SubscriptionRootTestResultAuditArgs = {
  distinct_on?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultAuditOrderBy>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};


export type SubscriptionRootTestResultAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TestResultAuditOrderBy>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};


export type SubscriptionRootTestResultAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootTestResultAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TestResultAuditStreamCursorInput>>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};


export type SubscriptionRootTestResultByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootTestResultStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<TestResultStreamCursorInput>>;
  where?: InputMaybe<TestResultBoolExp>;
};


export type SubscriptionRootThirdPartyArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


export type SubscriptionRootThirdPartyAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


export type SubscriptionRootThirdPartyAuditArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyAuditOrderBy>>;
  where?: InputMaybe<ThirdPartyAuditBoolExp>;
};


export type SubscriptionRootThirdPartyAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyAuditOrderBy>>;
  where?: InputMaybe<ThirdPartyAuditBoolExp>;
};


export type SubscriptionRootThirdPartyAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootThirdPartyAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ThirdPartyAuditStreamCursorInput>>;
  where?: InputMaybe<ThirdPartyAuditBoolExp>;
};


export type SubscriptionRootThirdPartyByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootThirdPartyResponseArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseBoolExp>;
};


export type SubscriptionRootThirdPartyResponseAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseBoolExp>;
};


export type SubscriptionRootThirdPartyResponseByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootThirdPartyResponseStatusArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
};


export type SubscriptionRootThirdPartyResponseStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyResponseStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyResponseStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
};


export type SubscriptionRootThirdPartyResponseStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootThirdPartyResponseStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ThirdPartyResponseStatusStreamCursorInput>>;
  where?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
};


export type SubscriptionRootThirdPartyResponseStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ThirdPartyResponseStreamCursorInput>>;
  where?: InputMaybe<ThirdPartyResponseBoolExp>;
};


export type SubscriptionRootThirdPartyStatusArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyStatusBoolExp>;
};


export type SubscriptionRootThirdPartyStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyStatusOrderBy>>;
  where?: InputMaybe<ThirdPartyStatusBoolExp>;
};


export type SubscriptionRootThirdPartyStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootThirdPartyStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ThirdPartyStatusStreamCursorInput>>;
  where?: InputMaybe<ThirdPartyStatusBoolExp>;
};


export type SubscriptionRootThirdPartyStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ThirdPartyStreamCursorInput>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


export type SubscriptionRootThirdPartyTypeArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyTypeOrderBy>>;
  where?: InputMaybe<ThirdPartyTypeBoolExp>;
};


export type SubscriptionRootThirdPartyTypeAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartyTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyTypeOrderBy>>;
  where?: InputMaybe<ThirdPartyTypeBoolExp>;
};


export type SubscriptionRootThirdPartyTypeByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootThirdPartyTypeStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<ThirdPartyTypeStreamCursorInput>>;
  where?: InputMaybe<ThirdPartyTypeBoolExp>;
};


export type SubscriptionRootUserArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserOrderBy>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootUserGroupArgs = {
  distinct_on?: InputMaybe<Array<UserGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupOrderBy>>;
  where?: InputMaybe<UserGroupBoolExp>;
};


export type SubscriptionRootUserGroupAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupOrderBy>>;
  where?: InputMaybe<UserGroupBoolExp>;
};


export type SubscriptionRootUserGroupAuditArgs = {
  distinct_on?: InputMaybe<Array<UserGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupAuditOrderBy>>;
  where?: InputMaybe<UserGroupAuditBoolExp>;
};


export type SubscriptionRootUserGroupAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupAuditOrderBy>>;
  where?: InputMaybe<UserGroupAuditBoolExp>;
};


export type SubscriptionRootUserGroupAuditByPkArgs = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};


export type SubscriptionRootUserGroupAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserGroupAuditStreamCursorInput>>;
  where?: InputMaybe<UserGroupAuditBoolExp>;
};


export type SubscriptionRootUserGroupByPkArgs = {
  Id: Scalars['uuid']['input'];
};


export type SubscriptionRootUserGroupStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserGroupStreamCursorInput>>;
  where?: InputMaybe<UserGroupBoolExp>;
};


export type SubscriptionRootUserGroupUserArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


export type SubscriptionRootUserGroupUserAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


export type SubscriptionRootUserGroupUserAuditArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserAuditOrderBy>>;
  where?: InputMaybe<UserGroupUserAuditBoolExp>;
};


export type SubscriptionRootUserGroupUserAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserAuditOrderBy>>;
  where?: InputMaybe<UserGroupUserAuditBoolExp>;
};


export type SubscriptionRootUserGroupUserAuditByPkArgs = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type SubscriptionRootUserGroupUserAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserGroupUserAuditStreamCursorInput>>;
  where?: InputMaybe<UserGroupUserAuditBoolExp>;
};


export type SubscriptionRootUserGroupUserByPkArgs = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};


export type SubscriptionRootUserGroupUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserGroupUserStreamCursorInput>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


export type SubscriptionRootUserRoleAccessArgs = {
  distinct_on?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleAccessOrderBy>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


export type SubscriptionRootUserRoleAccessAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserRoleAccessOrderBy>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


export type SubscriptionRootUserRoleAccessStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserRoleAccessStreamCursorInput>>;
  where?: InputMaybe<UserRoleAccessBoolExp>;
};


export type SubscriptionRootUserSearchPreferencesArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesBoolExp>;
};


export type SubscriptionRootUserSearchPreferencesAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesBoolExp>;
};


export type SubscriptionRootUserSearchPreferencesAuditArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesAuditOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
};


export type SubscriptionRootUserSearchPreferencesAuditAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserSearchPreferencesAuditSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserSearchPreferencesAuditOrderBy>>;
  where?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
};


export type SubscriptionRootUserSearchPreferencesAuditByPkArgs = {
  CreatedByUser: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootUserSearchPreferencesAuditStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserSearchPreferencesAuditStreamCursorInput>>;
  where?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
};


export type SubscriptionRootUserSearchPreferencesByPkArgs = {
  CreatedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};


export type SubscriptionRootUserSearchPreferencesStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserSearchPreferencesStreamCursorInput>>;
  where?: InputMaybe<UserSearchPreferencesBoolExp>;
};


export type SubscriptionRootUserStatusArgs = {
  distinct_on?: InputMaybe<Array<UserStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserStatusOrderBy>>;
  where?: InputMaybe<UserStatusBoolExp>;
};


export type SubscriptionRootUserStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserStatusOrderBy>>;
  where?: InputMaybe<UserStatusBoolExp>;
};


export type SubscriptionRootUserStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootUserStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserStatusStreamCursorInput>>;
  where?: InputMaybe<UserStatusBoolExp>;
};


export type SubscriptionRootUserStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<UserStreamCursorInput>>;
  where?: InputMaybe<UserBoolExp>;
};


export type SubscriptionRootVersionStatusArgs = {
  distinct_on?: InputMaybe<Array<VersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionStatusOrderBy>>;
  where?: InputMaybe<VersionStatusBoolExp>;
};


export type SubscriptionRootVersionStatusAggregateArgs = {
  distinct_on?: InputMaybe<Array<VersionStatusSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<VersionStatusOrderBy>>;
  where?: InputMaybe<VersionStatusBoolExp>;
};


export type SubscriptionRootVersionStatusByPkArgs = {
  Value: Scalars['String']['input'];
};


export type SubscriptionRootVersionStatusStreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<VersionStatusStreamCursorInput>>;
  where?: InputMaybe<VersionStatusBoolExp>;
};

/** columns and relationships of "risksmart.tag" */
export type Tag = {
  __typename?: 'tag';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  TagTypeId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Node>;
  /** An object relationship */
  parentAction?: Maybe<Action>;
  /** An object relationship */
  parentAssessment?: Maybe<Assessment>;
  /** An object relationship */
  parentIssue?: Maybe<Issue>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  type?: Maybe<TagType>;
};


/** columns and relationships of "risksmart.tag" */
export type TagPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.tag" */
export type TagPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.tag" */
export type TagAggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<TagAggregateFields>;
  nodes: Array<Tag>;
};

export type TagAggregateBoolExp = {
  count?: InputMaybe<TagAggregateBoolExpCount>;
};

export type TagAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TagBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.tag" */
export type TagAggregateFields = {
  __typename?: 'tag_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TagMaxFields>;
  min?: Maybe<TagMinFields>;
};


/** aggregate fields of "risksmart.tag" */
export type TagAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.tag" */
export type TagAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TagMaxOrderBy>;
  min?: InputMaybe<TagMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.tag" */
export type TagArrRelInsertInput = {
  data: Array<TagInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TagOnConflict>;
};

/** columns and relationships of "risksmart.tag_audit" */
export type TagAudit = {
  __typename?: 'tag_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  ParentId: Scalars['uuid']['output'];
  TagTypeId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.tag_audit" */
export type TagAuditAggregate = {
  __typename?: 'tag_audit_aggregate';
  aggregate?: Maybe<TagAuditAggregateFields>;
  nodes: Array<TagAudit>;
};

export type TagAuditAggregateBoolExp = {
  count?: InputMaybe<TagAuditAggregateBoolExpCount>;
};

export type TagAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TagAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TagAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.tag_audit" */
export type TagAuditAggregateFields = {
  __typename?: 'tag_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TagAuditMaxFields>;
  min?: Maybe<TagAuditMinFields>;
};


/** aggregate fields of "risksmart.tag_audit" */
export type TagAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.tag_audit" */
export type TagAuditAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TagAuditMaxOrderBy>;
  min?: InputMaybe<TagAuditMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.tag_audit" */
export type TagAuditArrRelInsertInput = {
  data: Array<TagAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TagAuditOnConflict>;
};

/** Boolean expression to filter rows from the table "risksmart.tag_audit". All fields are combined with a logical 'AND'. */
export type TagAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  TagTypeId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<TagAuditBoolExp>>;
  _not?: InputMaybe<TagAuditBoolExp>;
  _or?: InputMaybe<Array<TagAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.tag_audit" */
export enum TagAuditConstraint {
  /** unique or primary key constraint on columns "TagTypeId", "ParentId", "ModifiedAtTimestamp" */
  TagAuditPkey = 'tag_audit_pkey'
}

/** input type for inserting data into table "risksmart.tag_audit" */
export type TagAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TagAuditMaxFields = {
  __typename?: 'tag_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.tag_audit" */
export type TagAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TagAuditMinFields = {
  __typename?: 'tag_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.tag_audit" */
export type TagAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.tag_audit" */
export type TagAuditMutationResponse = {
  __typename?: 'tag_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TagAudit>;
};

/** on_conflict condition type for table "risksmart.tag_audit" */
export type TagAuditOnConflict = {
  constraint: TagAuditConstraint;
  update_columns?: Array<TagAuditUpdateColumn>;
  where?: InputMaybe<TagAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.tag_audit". */
export type TagAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.tag_audit */
export type TagAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.tag_audit" */
export enum TagAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

/** input type for updating data in table "risksmart.tag_audit" */
export type TagAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tag_audit" */
export type TagAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.tag_audit" */
export enum TagAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

export type TagAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagAuditSetInput>;
  /** filter the rows which have to be updated */
  where: TagAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.tag". All fields are combined with a logical 'AND'. */
export type TagBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  ParentId?: InputMaybe<UuidComparisonExp>;
  TagTypeId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<TagBoolExp>>;
  _not?: InputMaybe<TagBoolExp>;
  _or?: InputMaybe<Array<TagBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<NodeBoolExp>;
  parentAction?: InputMaybe<ActionBoolExp>;
  parentAssessment?: InputMaybe<AssessmentBoolExp>;
  parentIssue?: InputMaybe<IssueBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  type?: InputMaybe<TagTypeBoolExp>;
};

/** unique or primary key constraints on table "risksmart.tag" */
export enum TagConstraint {
  /** unique or primary key constraint on columns "TagTypeId", "ParentId" */
  TagPkey = 'tag_pkey'
}

/** input type for inserting data into table "risksmart.tag" */
export type TagInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<NodeObjRelInsertInput>;
  parentAction?: InputMaybe<ActionObjRelInsertInput>;
  parentAssessment?: InputMaybe<AssessmentObjRelInsertInput>;
  parentIssue?: InputMaybe<IssueObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  type?: InputMaybe<TagTypeObjRelInsertInput>;
};

/** aggregate max on columns */
export type TagMaxFields = {
  __typename?: 'tag_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.tag" */
export type TagMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TagMinFields = {
  __typename?: 'tag_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  ParentId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.tag" */
export type TagMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.tag" */
export type TagMutationResponse = {
  __typename?: 'tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tag>;
};

/** on_conflict condition type for table "risksmart.tag" */
export type TagOnConflict = {
  constraint: TagConstraint;
  update_columns?: Array<TagUpdateColumn>;
  where?: InputMaybe<TagBoolExp>;
};

/** Ordering options when selecting data from "risksmart.tag". */
export type TagOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  ParentId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<NodeOrderBy>;
  parentAction?: InputMaybe<ActionOrderBy>;
  parentAssessment?: InputMaybe<AssessmentOrderBy>;
  parentIssue?: InputMaybe<IssueOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  type?: InputMaybe<TagTypeOrderBy>;
};

/** primary key columns input for table: risksmart.tag */
export type TagPkColumnsInput = {
  ParentId: Scalars['uuid']['input'];
  TagTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.tag" */
export enum TagSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

/** input type for updating data in table "risksmart.tag" */
export type TagSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tag" */
export type TagStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  ParentId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "risksmart.tag_type" */
export type TagType = {
  __typename?: 'tag_type';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId: Scalars['uuid']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  tag_type_group?: Maybe<TagTypeGroup>;
};

/** aggregated selection of "risksmart.tag_type" */
export type TagTypeAggregate = {
  __typename?: 'tag_type_aggregate';
  aggregate?: Maybe<TagTypeAggregateFields>;
  nodes: Array<TagType>;
};

export type TagTypeAggregateBoolExp = {
  count?: InputMaybe<TagTypeAggregateBoolExpCount>;
};

export type TagTypeAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TagTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TagTypeBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.tag_type" */
export type TagTypeAggregateFields = {
  __typename?: 'tag_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TagTypeMaxFields>;
  min?: Maybe<TagTypeMinFields>;
};


/** aggregate fields of "risksmart.tag_type" */
export type TagTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.tag_type" */
export type TagTypeAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TagTypeMaxOrderBy>;
  min?: InputMaybe<TagTypeMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.tag_type" */
export type TagTypeArrRelInsertInput = {
  data: Array<TagTypeInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TagTypeOnConflict>;
};

/** columns and relationships of "risksmart.tag_type_audit" */
export type TagTypeAudit = {
  __typename?: 'tag_type_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.tag_type_audit" */
export type TagTypeAuditAggregate = {
  __typename?: 'tag_type_audit_aggregate';
  aggregate?: Maybe<TagTypeAuditAggregateFields>;
  nodes: Array<TagTypeAudit>;
};

/** aggregate fields of "risksmart.tag_type_audit" */
export type TagTypeAuditAggregateFields = {
  __typename?: 'tag_type_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TagTypeAuditMaxFields>;
  min?: Maybe<TagTypeAuditMinFields>;
};


/** aggregate fields of "risksmart.tag_type_audit" */
export type TagTypeAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagTypeAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.tag_type_audit". All fields are combined with a logical 'AND'. */
export type TagTypeAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  TagTypeGroupId?: InputMaybe<UuidComparisonExp>;
  TagTypeId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<TagTypeAuditBoolExp>>;
  _not?: InputMaybe<TagTypeAuditBoolExp>;
  _or?: InputMaybe<Array<TagTypeAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.tag_type_audit" */
export enum TagTypeAuditConstraint {
  /** unique or primary key constraint on columns "TagTypeId", "ModifiedAtTimestamp" */
  TagTypeAuditPkey = 'tag_type_audit_pkey'
}

/** input type for inserting data into table "risksmart.tag_type_audit" */
export type TagTypeAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TagTypeAuditMaxFields = {
  __typename?: 'tag_type_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type TagTypeAuditMinFields = {
  __typename?: 'tag_type_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.tag_type_audit" */
export type TagTypeAuditMutationResponse = {
  __typename?: 'tag_type_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TagTypeAudit>;
};

/** on_conflict condition type for table "risksmart.tag_type_audit" */
export type TagTypeAuditOnConflict = {
  constraint: TagTypeAuditConstraint;
  update_columns?: Array<TagTypeAuditUpdateColumn>;
  where?: InputMaybe<TagTypeAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.tag_type_audit". */
export type TagTypeAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  TagTypeGroupId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.tag_type_audit */
export type TagTypeAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  TagTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.tag_type_audit" */
export enum TagTypeAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  TagTypeGroupId = 'TagTypeGroupId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

/** input type for updating data in table "risksmart.tag_type_audit" */
export type TagTypeAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tag_type_audit" */
export type TagTypeAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagTypeAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagTypeAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.tag_type_audit" */
export enum TagTypeAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  TagTypeGroupId = 'TagTypeGroupId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

export type TagTypeAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagTypeAuditSetInput>;
  /** filter the rows which have to be updated */
  where: TagTypeAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.tag_type". All fields are combined with a logical 'AND'. */
export type TagTypeBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  TagTypeGroupId?: InputMaybe<UuidComparisonExp>;
  TagTypeId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<TagTypeBoolExp>>;
  _not?: InputMaybe<TagTypeBoolExp>;
  _or?: InputMaybe<Array<TagTypeBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  tag_type_group?: InputMaybe<TagTypeGroupBoolExp>;
};

/** unique or primary key constraints on table "risksmart.tag_type" */
export enum TagTypeConstraint {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  IxTagTypeOrgkeyTitle = 'ix_tag_type_orgkey_title',
  /** unique or primary key constraint on columns "TagTypeId" */
  TagTypePkey = 'tag_type_pkey'
}

/** columns and relationships of "risksmart.tag_type_group" */
export type TagTypeGroup = {
  __typename?: 'tag_type_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  /** An array relationship */
  tag_types: Array<TagType>;
  /** An aggregate relationship */
  tag_types_aggregate: TagTypeAggregate;
};


/** columns and relationships of "risksmart.tag_type_group" */
export type TagTypeGroupTagTypesArgs = {
  distinct_on?: InputMaybe<Array<TagTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeOrderBy>>;
  where?: InputMaybe<TagTypeBoolExp>;
};


/** columns and relationships of "risksmart.tag_type_group" */
export type TagTypeGroupTagTypesAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagTypeSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagTypeOrderBy>>;
  where?: InputMaybe<TagTypeBoolExp>;
};

/** aggregated selection of "risksmart.tag_type_group" */
export type TagTypeGroupAggregate = {
  __typename?: 'tag_type_group_aggregate';
  aggregate?: Maybe<TagTypeGroupAggregateFields>;
  nodes: Array<TagTypeGroup>;
};

/** aggregate fields of "risksmart.tag_type_group" */
export type TagTypeGroupAggregateFields = {
  __typename?: 'tag_type_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TagTypeGroupMaxFields>;
  min?: Maybe<TagTypeGroupMinFields>;
};


/** aggregate fields of "risksmart.tag_type_group" */
export type TagTypeGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagTypeGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.tag_type_group_audit" */
export type TagTypeGroupAudit = {
  __typename?: 'tag_type_group_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditAggregate = {
  __typename?: 'tag_type_group_audit_aggregate';
  aggregate?: Maybe<TagTypeGroupAuditAggregateFields>;
  nodes: Array<TagTypeGroupAudit>;
};

/** aggregate fields of "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditAggregateFields = {
  __typename?: 'tag_type_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TagTypeGroupAuditMaxFields>;
  min?: Maybe<TagTypeGroupAuditMinFields>;
};


/** aggregate fields of "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TagTypeGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.tag_type_group_audit". All fields are combined with a logical 'AND'. */
export type TagTypeGroupAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<TagTypeGroupAuditBoolExp>>;
  _not?: InputMaybe<TagTypeGroupAuditBoolExp>;
  _or?: InputMaybe<Array<TagTypeGroupAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.tag_type_group_audit" */
export enum TagTypeGroupAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  TagTypeGroupAuditPkey = 'tag_type_group_audit_pkey'
}

/** input type for inserting data into table "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TagTypeGroupAuditMaxFields = {
  __typename?: 'tag_type_group_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TagTypeGroupAuditMinFields = {
  __typename?: 'tag_type_group_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditMutationResponse = {
  __typename?: 'tag_type_group_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TagTypeGroupAudit>;
};

/** on_conflict condition type for table "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditOnConflict = {
  constraint: TagTypeGroupAuditConstraint;
  update_columns?: Array<TagTypeGroupAuditUpdateColumn>;
  where?: InputMaybe<TagTypeGroupAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.tag_type_group_audit". */
export type TagTypeGroupAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.tag_type_group_audit */
export type TagTypeGroupAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.tag_type_group_audit" */
export enum TagTypeGroupAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.tag_type_group_audit" */
export type TagTypeGroupAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tag_type_group_audit" */
export type TagTypeGroupAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagTypeGroupAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagTypeGroupAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.tag_type_group_audit" */
export enum TagTypeGroupAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type TagTypeGroupAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagTypeGroupAuditSetInput>;
  /** filter the rows which have to be updated */
  where: TagTypeGroupAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.tag_type_group". All fields are combined with a logical 'AND'. */
export type TagTypeGroupBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<TagTypeGroupBoolExp>>;
  _not?: InputMaybe<TagTypeGroupBoolExp>;
  _or?: InputMaybe<Array<TagTypeGroupBoolExp>>;
  tag_types?: InputMaybe<TagTypeBoolExp>;
  tag_types_aggregate?: InputMaybe<TagTypeAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.tag_type_group" */
export enum TagTypeGroupConstraint {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  TagTypeGroupPkey = 'TagTypeGroup_pkey',
  /** unique or primary key constraint on columns "Id" */
  TagTypeGroupIdKey = 'tag_type_group_Id_key'
}

/** input type for inserting data into table "risksmart.tag_type_group" */
export type TagTypeGroupInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  tag_types?: InputMaybe<TagTypeArrRelInsertInput>;
};

/** aggregate max on columns */
export type TagTypeGroupMaxFields = {
  __typename?: 'tag_type_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TagTypeGroupMinFields = {
  __typename?: 'tag_type_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.tag_type_group" */
export type TagTypeGroupMutationResponse = {
  __typename?: 'tag_type_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TagTypeGroup>;
};

/** input type for inserting object relation for remote table "risksmart.tag_type_group" */
export type TagTypeGroupObjRelInsertInput = {
  data: TagTypeGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TagTypeGroupOnConflict>;
};

/** on_conflict condition type for table "risksmart.tag_type_group" */
export type TagTypeGroupOnConflict = {
  constraint: TagTypeGroupConstraint;
  update_columns?: Array<TagTypeGroupUpdateColumn>;
  where?: InputMaybe<TagTypeGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.tag_type_group". */
export type TagTypeGroupOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  tag_types_aggregate?: InputMaybe<TagTypeAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.tag_type_group */
export type TagTypeGroupPkColumnsInput = {
  Name: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.tag_type_group" */
export enum TagTypeGroupSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

/** input type for updating data in table "risksmart.tag_type_group" */
export type TagTypeGroupSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tag_type_group" */
export type TagTypeGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagTypeGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagTypeGroupStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.tag_type_group" */
export enum TagTypeGroupUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey'
}

export type TagTypeGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagTypeGroupSetInput>;
  /** filter the rows which have to be updated */
  where: TagTypeGroupBoolExp;
};

/** input type for inserting data into table "risksmart.tag_type" */
export type TagTypeInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  tag_type_group?: InputMaybe<TagTypeGroupObjRelInsertInput>;
};

/** aggregate max on columns */
export type TagTypeMaxFields = {
  __typename?: 'tag_type_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.tag_type" */
export type TagTypeMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  TagTypeGroupId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TagTypeMinFields = {
  __typename?: 'tag_type_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  TagTypeGroupId?: Maybe<Scalars['uuid']['output']>;
  TagTypeId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.tag_type" */
export type TagTypeMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  TagTypeGroupId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.tag_type" */
export type TagTypeMutationResponse = {
  __typename?: 'tag_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TagType>;
};

/** input type for inserting object relation for remote table "risksmart.tag_type" */
export type TagTypeObjRelInsertInput = {
  data: TagTypeInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TagTypeOnConflict>;
};

/** on_conflict condition type for table "risksmart.tag_type" */
export type TagTypeOnConflict = {
  constraint: TagTypeConstraint;
  update_columns?: Array<TagTypeUpdateColumn>;
  where?: InputMaybe<TagTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.tag_type". */
export type TagTypeOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  TagTypeGroupId?: InputMaybe<OrderBy>;
  TagTypeId?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  tag_type_group?: InputMaybe<TagTypeGroupOrderBy>;
};

/** primary key columns input for table: risksmart.tag_type */
export type TagTypePkColumnsInput = {
  TagTypeId: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.tag_type" */
export enum TagTypeSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  TagTypeGroupId = 'TagTypeGroupId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

/** input type for updating data in table "risksmart.tag_type" */
export type TagTypeSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "tag_type" */
export type TagTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TagTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TagTypeStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  TagTypeGroupId?: InputMaybe<Scalars['uuid']['input']>;
  TagTypeId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.tag_type" */
export enum TagTypeUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  TagTypeGroupId = 'TagTypeGroupId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

export type TagTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagTypeSetInput>;
  /** filter the rows which have to be updated */
  where: TagTypeBoolExp;
};

/** update columns of table "risksmart.tag" */
export enum TagUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  ParentId = 'ParentId',
  /** column name */
  TagTypeId = 'TagTypeId'
}

export type TagUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TagSetInput>;
  /** filter the rows which have to be updated */
  where: TagBoolExp;
};

/** columns and relationships of "risksmart.taxonomy" */
export type Taxonomy = {
  __typename?: 'taxonomy';
  Common: Scalars['jsonb']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Library: Scalars['jsonb']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Rating: Scalars['jsonb']['output'];
  Taxonomy: Scalars['jsonb']['output'];
  /** An array relationship */
  organisations: Array<TaxonomyOrg>;
  /** An aggregate relationship */
  organisations_aggregate: TaxonomyOrgAggregate;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyCommonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyLibraryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyRatingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyTaxonomyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


/** columns and relationships of "risksmart.taxonomy" */
export type TaxonomyOrganisationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};

/** aggregated selection of "risksmart.taxonomy" */
export type TaxonomyAggregate = {
  __typename?: 'taxonomy_aggregate';
  aggregate?: Maybe<TaxonomyAggregateFields>;
  nodes: Array<Taxonomy>;
};

/** aggregate fields of "risksmart.taxonomy" */
export type TaxonomyAggregateFields = {
  __typename?: 'taxonomy_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaxonomyMaxFields>;
  min?: Maybe<TaxonomyMinFields>;
};


/** aggregate fields of "risksmart.taxonomy" */
export type TaxonomyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaxonomySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TaxonomyAppendInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAudit = {
  __typename?: 'taxonomy_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Common: Scalars['jsonb']['output'];
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Library: Scalars['jsonb']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Rating: Scalars['jsonb']['output'];
  Taxonomy: Scalars['jsonb']['output'];
  /** An array relationship */
  organisations: Array<TaxonomyOrg>;
  /** An aggregate relationship */
  organisations_aggregate: TaxonomyOrgAggregate;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAuditCommonArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAuditLibraryArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAuditRatingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAuditTaxonomyArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAuditOrganisationsArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};


/** columns and relationships of "risksmart.taxonomy_audit" */
export type TaxonomyAuditOrganisationsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TaxonomyOrgOrderBy>>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};

/** aggregated selection of "risksmart.taxonomy_audit" */
export type TaxonomyAuditAggregate = {
  __typename?: 'taxonomy_audit_aggregate';
  aggregate?: Maybe<TaxonomyAuditAggregateFields>;
  nodes: Array<TaxonomyAudit>;
};

/** aggregate fields of "risksmart.taxonomy_audit" */
export type TaxonomyAuditAggregateFields = {
  __typename?: 'taxonomy_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaxonomyAuditMaxFields>;
  min?: Maybe<TaxonomyAuditMinFields>;
};


/** aggregate fields of "risksmart.taxonomy_audit" */
export type TaxonomyAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaxonomyAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TaxonomyAuditAppendInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy_audit". All fields are combined with a logical 'AND'. */
export type TaxonomyAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Common?: InputMaybe<JsonbComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Library?: InputMaybe<JsonbComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<JsonbComparisonExp>;
  Taxonomy?: InputMaybe<JsonbComparisonExp>;
  _and?: InputMaybe<Array<TaxonomyAuditBoolExp>>;
  _not?: InputMaybe<TaxonomyAuditBoolExp>;
  _or?: InputMaybe<Array<TaxonomyAuditBoolExp>>;
  organisations?: InputMaybe<TaxonomyOrgBoolExp>;
  organisations_aggregate?: InputMaybe<TaxonomyOrgAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.taxonomy_audit" */
export enum TaxonomyAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  TaxonomyAuditPkey = 'taxonomy_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TaxonomyAuditDeleteAtPathInput = {
  Common?: InputMaybe<Array<Scalars['String']['input']>>;
  Library?: InputMaybe<Array<Scalars['String']['input']>>;
  Rating?: InputMaybe<Array<Scalars['String']['input']>>;
  Taxonomy?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TaxonomyAuditDeleteElemInput = {
  Common?: InputMaybe<Scalars['Int']['input']>;
  Library?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Taxonomy?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TaxonomyAuditDeleteKeyInput = {
  Common?: InputMaybe<Scalars['String']['input']>;
  Library?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['String']['input']>;
  Taxonomy?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.taxonomy_audit" */
export type TaxonomyAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
  organisations?: InputMaybe<TaxonomyOrgArrRelInsertInput>;
};

/** aggregate max on columns */
export type TaxonomyAuditMaxFields = {
  __typename?: 'taxonomy_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TaxonomyAuditMinFields = {
  __typename?: 'taxonomy_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.taxonomy_audit" */
export type TaxonomyAuditMutationResponse = {
  __typename?: 'taxonomy_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaxonomyAudit>;
};

/** on_conflict condition type for table "risksmart.taxonomy_audit" */
export type TaxonomyAuditOnConflict = {
  constraint: TaxonomyAuditConstraint;
  update_columns?: Array<TaxonomyAuditUpdateColumn>;
  where?: InputMaybe<TaxonomyAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.taxonomy_audit". */
export type TaxonomyAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Common?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Library?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  Taxonomy?: InputMaybe<OrderBy>;
  organisations_aggregate?: InputMaybe<TaxonomyOrgAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.taxonomy_audit */
export type TaxonomyAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TaxonomyAuditPrependInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.taxonomy_audit" */
export enum TaxonomyAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Common = 'Common',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Library = 'Library',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Rating = 'Rating',
  /** column name */
  Taxonomy = 'Taxonomy'
}

/** input type for updating data in table "risksmart.taxonomy_audit" */
export type TaxonomyAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "taxonomy_audit" */
export type TaxonomyAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TaxonomyAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TaxonomyAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "risksmart.taxonomy_audit" */
export enum TaxonomyAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Common = 'Common',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Library = 'Library',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Rating = 'Rating',
  /** column name */
  Taxonomy = 'Taxonomy'
}

export type TaxonomyAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TaxonomyAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TaxonomyAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TaxonomyAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TaxonomyAuditDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TaxonomyAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaxonomyAuditSetInput>;
  /** filter the rows which have to be updated */
  where: TaxonomyAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy". All fields are combined with a logical 'AND'. */
export type TaxonomyBoolExp = {
  Common?: InputMaybe<JsonbComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Library?: InputMaybe<JsonbComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Rating?: InputMaybe<JsonbComparisonExp>;
  Taxonomy?: InputMaybe<JsonbComparisonExp>;
  _and?: InputMaybe<Array<TaxonomyBoolExp>>;
  _not?: InputMaybe<TaxonomyBoolExp>;
  _or?: InputMaybe<Array<TaxonomyBoolExp>>;
  organisations?: InputMaybe<TaxonomyOrgBoolExp>;
  organisations_aggregate?: InputMaybe<TaxonomyOrgAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.taxonomy" */
export enum TaxonomyConstraint {
  /** unique or primary key constraint on columns "Id" */
  TaxonomyPkey = 'taxonomy_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TaxonomyDeleteAtPathInput = {
  Common?: InputMaybe<Array<Scalars['String']['input']>>;
  Library?: InputMaybe<Array<Scalars['String']['input']>>;
  Rating?: InputMaybe<Array<Scalars['String']['input']>>;
  Taxonomy?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TaxonomyDeleteElemInput = {
  Common?: InputMaybe<Scalars['Int']['input']>;
  Library?: InputMaybe<Scalars['Int']['input']>;
  Rating?: InputMaybe<Scalars['Int']['input']>;
  Taxonomy?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TaxonomyDeleteKeyInput = {
  Common?: InputMaybe<Scalars['String']['input']>;
  Library?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['String']['input']>;
  Taxonomy?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.taxonomy" */
export type TaxonomyInsertInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
  organisations?: InputMaybe<TaxonomyOrgArrRelInsertInput>;
};

/** aggregate max on columns */
export type TaxonomyMaxFields = {
  __typename?: 'taxonomy_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TaxonomyMinFields = {
  __typename?: 'taxonomy_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.taxonomy" */
export type TaxonomyMutationResponse = {
  __typename?: 'taxonomy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy>;
};

/** input type for inserting object relation for remote table "risksmart.taxonomy" */
export type TaxonomyObjRelInsertInput = {
  data: TaxonomyInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TaxonomyOnConflict>;
};

/** on_conflict condition type for table "risksmart.taxonomy" */
export type TaxonomyOnConflict = {
  constraint: TaxonomyConstraint;
  update_columns?: Array<TaxonomyUpdateColumn>;
  where?: InputMaybe<TaxonomyBoolExp>;
};

/** Ordering options when selecting data from "risksmart.taxonomy". */
export type TaxonomyOrderBy = {
  Common?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Library?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Rating?: InputMaybe<OrderBy>;
  Taxonomy?: InputMaybe<OrderBy>;
  organisations_aggregate?: InputMaybe<TaxonomyOrgAggregateOrderBy>;
};

/** columns and relationships of "risksmart.taxonomy_org" */
export type TaxonomyOrg = {
  __typename?: 'taxonomy_org';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Locale: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OrgName: Scalars['String']['output'];
  TaxonomyId: Scalars['uuid']['output'];
  /** An object relationship */
  taxonomy: Taxonomy;
};

/** aggregated selection of "risksmart.taxonomy_org" */
export type TaxonomyOrgAggregate = {
  __typename?: 'taxonomy_org_aggregate';
  aggregate?: Maybe<TaxonomyOrgAggregateFields>;
  nodes: Array<TaxonomyOrg>;
};

export type TaxonomyOrgAggregateBoolExp = {
  count?: InputMaybe<TaxonomyOrgAggregateBoolExpCount>;
};

export type TaxonomyOrgAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TaxonomyOrgBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.taxonomy_org" */
export type TaxonomyOrgAggregateFields = {
  __typename?: 'taxonomy_org_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaxonomyOrgMaxFields>;
  min?: Maybe<TaxonomyOrgMinFields>;
};


/** aggregate fields of "risksmart.taxonomy_org" */
export type TaxonomyOrgAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaxonomyOrgSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.taxonomy_org" */
export type TaxonomyOrgAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TaxonomyOrgMaxOrderBy>;
  min?: InputMaybe<TaxonomyOrgMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.taxonomy_org" */
export type TaxonomyOrgArrRelInsertInput = {
  data: Array<TaxonomyOrgInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TaxonomyOrgOnConflict>;
};

/** columns and relationships of "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAudit = {
  __typename?: 'taxonomy_org_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Id: Scalars['uuid']['output'];
  Locale: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName: Scalars['String']['output'];
  TaxonomyId: Scalars['uuid']['output'];
};

/** aggregated selection of "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditAggregate = {
  __typename?: 'taxonomy_org_audit_aggregate';
  aggregate?: Maybe<TaxonomyOrgAuditAggregateFields>;
  nodes: Array<TaxonomyOrgAudit>;
};

/** aggregate fields of "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditAggregateFields = {
  __typename?: 'taxonomy_org_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TaxonomyOrgAuditMaxFields>;
  min?: Maybe<TaxonomyOrgAuditMinFields>;
};


/** aggregate fields of "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TaxonomyOrgAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy_org_audit". All fields are combined with a logical 'AND'. */
export type TaxonomyOrgAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Locale?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OrgName?: InputMaybe<StringComparisonExp>;
  TaxonomyId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<TaxonomyOrgAuditBoolExp>>;
  _not?: InputMaybe<TaxonomyOrgAuditBoolExp>;
  _or?: InputMaybe<Array<TaxonomyOrgAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.taxonomy_org_audit" */
export enum TaxonomyOrgAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  TaxonomyOrgAuditPkey = 'taxonomy_org_audit_pkey'
}

/** input type for inserting data into table "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type TaxonomyOrgAuditMaxFields = {
  __typename?: 'taxonomy_org_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Locale?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  TaxonomyId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type TaxonomyOrgAuditMinFields = {
  __typename?: 'taxonomy_org_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Locale?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  TaxonomyId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditMutationResponse = {
  __typename?: 'taxonomy_org_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaxonomyOrgAudit>;
};

/** on_conflict condition type for table "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditOnConflict = {
  constraint: TaxonomyOrgAuditConstraint;
  update_columns?: Array<TaxonomyOrgAuditUpdateColumn>;
  where?: InputMaybe<TaxonomyOrgAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.taxonomy_org_audit". */
export type TaxonomyOrgAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Locale?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OrgName?: InputMaybe<OrderBy>;
  TaxonomyId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.taxonomy_org_audit */
export type TaxonomyOrgAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.taxonomy_org_audit" */
export enum TaxonomyOrgAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  Locale = 'Locale',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OrgName = 'OrgName',
  /** column name */
  TaxonomyId = 'TaxonomyId'
}

/** input type for updating data in table "risksmart.taxonomy_org_audit" */
export type TaxonomyOrgAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "taxonomy_org_audit" */
export type TaxonomyOrgAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TaxonomyOrgAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TaxonomyOrgAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.taxonomy_org_audit" */
export enum TaxonomyOrgAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  Locale = 'Locale',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OrgName = 'OrgName',
  /** column name */
  TaxonomyId = 'TaxonomyId'
}

export type TaxonomyOrgAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaxonomyOrgAuditSetInput>;
  /** filter the rows which have to be updated */
  where: TaxonomyOrgAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.taxonomy_org". All fields are combined with a logical 'AND'. */
export type TaxonomyOrgBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Locale?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OrgName?: InputMaybe<StringComparisonExp>;
  TaxonomyId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<TaxonomyOrgBoolExp>>;
  _not?: InputMaybe<TaxonomyOrgBoolExp>;
  _or?: InputMaybe<Array<TaxonomyOrgBoolExp>>;
  taxonomy?: InputMaybe<TaxonomyBoolExp>;
};

/** unique or primary key constraints on table "risksmart.taxonomy_org" */
export enum TaxonomyOrgConstraint {
  /** unique or primary key constraint on columns "Id" */
  TaxonomyOrgPkey = 'taxonomy_org_pkey'
}

/** input type for inserting data into table "risksmart.taxonomy_org" */
export type TaxonomyOrgInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
  taxonomy?: InputMaybe<TaxonomyObjRelInsertInput>;
};

/** aggregate max on columns */
export type TaxonomyOrgMaxFields = {
  __typename?: 'taxonomy_org_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Locale?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  TaxonomyId?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "risksmart.taxonomy_org" */
export type TaxonomyOrgMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Locale?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OrgName?: InputMaybe<OrderBy>;
  TaxonomyId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TaxonomyOrgMinFields = {
  __typename?: 'taxonomy_org_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  Locale?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OrgName?: Maybe<Scalars['String']['output']>;
  TaxonomyId?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "risksmart.taxonomy_org" */
export type TaxonomyOrgMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Locale?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OrgName?: InputMaybe<OrderBy>;
  TaxonomyId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.taxonomy_org" */
export type TaxonomyOrgMutationResponse = {
  __typename?: 'taxonomy_org_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TaxonomyOrg>;
};

/** on_conflict condition type for table "risksmart.taxonomy_org" */
export type TaxonomyOrgOnConflict = {
  constraint: TaxonomyOrgConstraint;
  update_columns?: Array<TaxonomyOrgUpdateColumn>;
  where?: InputMaybe<TaxonomyOrgBoolExp>;
};

/** Ordering options when selecting data from "risksmart.taxonomy_org". */
export type TaxonomyOrgOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Locale?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OrgName?: InputMaybe<OrderBy>;
  TaxonomyId?: InputMaybe<OrderBy>;
  taxonomy?: InputMaybe<TaxonomyOrderBy>;
};

/** primary key columns input for table: risksmart.taxonomy_org */
export type TaxonomyOrgPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.taxonomy_org" */
export enum TaxonomyOrgSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  Locale = 'Locale',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OrgName = 'OrgName',
  /** column name */
  TaxonomyId = 'TaxonomyId'
}

/** input type for updating data in table "risksmart.taxonomy_org" */
export type TaxonomyOrgSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "taxonomy_org" */
export type TaxonomyOrgStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TaxonomyOrgStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TaxonomyOrgStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Locale?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OrgName?: InputMaybe<Scalars['String']['input']>;
  TaxonomyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.taxonomy_org" */
export enum TaxonomyOrgUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Id = 'Id',
  /** column name */
  Locale = 'Locale',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OrgName = 'OrgName',
  /** column name */
  TaxonomyId = 'TaxonomyId'
}

export type TaxonomyOrgUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaxonomyOrgSetInput>;
  /** filter the rows which have to be updated */
  where: TaxonomyOrgBoolExp;
};

/** primary key columns input for table: risksmart.taxonomy */
export type TaxonomyPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TaxonomyPrependInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.taxonomy" */
export enum TaxonomySelectColumn {
  /** column name */
  Common = 'Common',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Library = 'Library',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Rating = 'Rating',
  /** column name */
  Taxonomy = 'Taxonomy'
}

/** input type for updating data in table "risksmart.taxonomy" */
export type TaxonomySetInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "taxonomy" */
export type TaxonomyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TaxonomyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TaxonomyStreamCursorValueInput = {
  Common?: InputMaybe<Scalars['jsonb']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Library?: InputMaybe<Scalars['jsonb']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Rating?: InputMaybe<Scalars['jsonb']['input']>;
  Taxonomy?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "risksmart.taxonomy" */
export enum TaxonomyUpdateColumn {
  /** column name */
  Common = 'Common',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  Library = 'Library',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Rating = 'Rating',
  /** column name */
  Taxonomy = 'Taxonomy'
}

export type TaxonomyUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TaxonomyAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TaxonomyDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TaxonomyDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TaxonomyDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TaxonomyPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TaxonomySetInput>;
  /** filter the rows which have to be updated */
  where: TaxonomyBoolExp;
};

/** columns and relationships of "risksmart.test_frequency" */
export type TestFrequency = {
  __typename?: 'test_frequency';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.test_frequency" */
export type TestFrequencyAggregate = {
  __typename?: 'test_frequency_aggregate';
  aggregate?: Maybe<TestFrequencyAggregateFields>;
  nodes: Array<TestFrequency>;
};

/** aggregate fields of "risksmart.test_frequency" */
export type TestFrequencyAggregateFields = {
  __typename?: 'test_frequency_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<TestFrequencyMaxFields>;
  min?: Maybe<TestFrequencyMinFields>;
};


/** aggregate fields of "risksmart.test_frequency" */
export type TestFrequencyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TestFrequencySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.test_frequency". All fields are combined with a logical 'AND'. */
export type TestFrequencyBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<TestFrequencyBoolExp>>;
  _not?: InputMaybe<TestFrequencyBoolExp>;
  _or?: InputMaybe<Array<TestFrequencyBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.test_frequency" */
export enum TestFrequencyConstraint {
  /** unique or primary key constraint on columns "Value" */
  TestFrequencyPkey = 'test_frequency_pkey'
}

export enum TestFrequencyEnum {
  /** Ad Hoc */
  Adhoc = 'adhoc',
  /** Annually */
  Annually = 'annually',
  /** Bi-Annually */
  Biannually = 'biannually',
  /**  Daily */
  Daily = 'daily',
  /** Fortnightly */
  Fortnightly = 'fortnightly',
  /** Four Weekly */
  Fourweekly = 'fourweekly',
  /** Monthly */
  Monthly = 'monthly',
  /** Quarterly */
  Quarterly = 'quarterly',
  /** Weekly */
  Weekly = 'weekly'
}

/** Boolean expression to compare columns of type "test_frequency_enum". All fields are combined with logical 'AND'. */
export type TestFrequencyEnumComparisonExp = {
  _eq?: InputMaybe<TestFrequencyEnum>;
  _in?: InputMaybe<Array<TestFrequencyEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<TestFrequencyEnum>;
  _nin?: InputMaybe<Array<TestFrequencyEnum>>;
};

/** input type for inserting data into table "risksmart.test_frequency" */
export type TestFrequencyInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TestFrequencyMaxFields = {
  __typename?: 'test_frequency_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type TestFrequencyMinFields = {
  __typename?: 'test_frequency_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.test_frequency" */
export type TestFrequencyMutationResponse = {
  __typename?: 'test_frequency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TestFrequency>;
};

/** input type for inserting object relation for remote table "risksmart.test_frequency" */
export type TestFrequencyObjRelInsertInput = {
  data: TestFrequencyInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TestFrequencyOnConflict>;
};

/** on_conflict condition type for table "risksmart.test_frequency" */
export type TestFrequencyOnConflict = {
  constraint: TestFrequencyConstraint;
  update_columns?: Array<TestFrequencyUpdateColumn>;
  where?: InputMaybe<TestFrequencyBoolExp>;
};

/** Ordering options when selecting data from "risksmart.test_frequency". */
export type TestFrequencyOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.test_frequency */
export type TestFrequencyPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.test_frequency" */
export enum TestFrequencySelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.test_frequency" */
export type TestFrequencySetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "test_frequency" */
export type TestFrequencyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TestFrequencyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TestFrequencyStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.test_frequency" */
export enum TestFrequencyUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type TestFrequencyUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TestFrequencySetInput>;
  /** filter the rows which have to be updated */
  where: TestFrequencyBoolExp;
};

/** columns and relationships of "risksmart.test_result" */
export type TestResult = {
  __typename?: 'test_result';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description: Scalars['String']['output'];
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId: Scalars['uuid']['output'];
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType: Scalars['String']['output'];
  SequentialId: Scalars['Int']['output'];
  Submitter: Scalars['String']['output'];
  TestDate: Scalars['timestamptz']['output'];
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  assessmentParents: Array<AssessmentResultParent>;
  /** An aggregate relationship */
  assessmentParents_aggregate: AssessmentResultParentAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  files: Array<RelationFile>;
  /** An aggregate relationship */
  files_aggregate: RelationFileAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An object relationship */
  parent?: Maybe<Control>;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  submitter?: Maybe<User>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultAssessmentParentsArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultAssessmentParentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AssessmentResultParentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AssessmentResultParentOrderBy>>;
  where?: InputMaybe<AssessmentResultParentBoolExp>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultFilesArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultFilesAggregateArgs = {
  distinct_on?: InputMaybe<Array<RelationFileSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<RelationFileOrderBy>>;
  where?: InputMaybe<RelationFileBoolExp>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.test_result" */
export type TestResultPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.test_result" */
export type TestResultAggregate = {
  __typename?: 'test_result_aggregate';
  aggregate?: Maybe<TestResultAggregateFields>;
  nodes: Array<TestResult>;
};

export type TestResultAggregateBoolExp = {
  count?: InputMaybe<TestResultAggregateBoolExpCount>;
};

export type TestResultAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TestResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TestResultBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.test_result" */
export type TestResultAggregateFields = {
  __typename?: 'test_result_aggregate_fields';
  avg?: Maybe<TestResultAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TestResultMaxFields>;
  min?: Maybe<TestResultMinFields>;
  stddev?: Maybe<TestResultStddevFields>;
  stddev_pop?: Maybe<TestResultStddevPopFields>;
  stddev_samp?: Maybe<TestResultStddevSampFields>;
  sum?: Maybe<TestResultSumFields>;
  var_pop?: Maybe<TestResultVarPopFields>;
  var_samp?: Maybe<TestResultVarSampFields>;
  variance?: Maybe<TestResultVarianceFields>;
};


/** aggregate fields of "risksmart.test_result" */
export type TestResultAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TestResultSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.test_result" */
export type TestResultAggregateOrderBy = {
  avg?: InputMaybe<TestResultAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TestResultMaxOrderBy>;
  min?: InputMaybe<TestResultMinOrderBy>;
  stddev?: InputMaybe<TestResultStddevOrderBy>;
  stddev_pop?: InputMaybe<TestResultStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TestResultStddevSampOrderBy>;
  sum?: InputMaybe<TestResultSumOrderBy>;
  var_pop?: InputMaybe<TestResultVarPopOrderBy>;
  var_samp?: InputMaybe<TestResultVarSampOrderBy>;
  variance?: InputMaybe<TestResultVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TestResultAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.test_result" */
export type TestResultArrRelInsertInput = {
  data: Array<TestResultInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TestResultOnConflict>;
};

/** columns and relationships of "risksmart.test_result_audit" */
export type TestResultAudit = {
  __typename?: 'test_result_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id: Scalars['uuid']['output'];
  Meta?: Maybe<Scalars['json']['output']>;
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey: Scalars['String']['output'];
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId: Scalars['uuid']['output'];
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter: Scalars['String']['output'];
  TestDate: Scalars['timestamptz']['output'];
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "risksmart.test_result_audit" */
export type TestResultAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.test_result_audit" */
export type TestResultAuditMetaArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.test_result_audit" */
export type TestResultAuditAggregate = {
  __typename?: 'test_result_audit_aggregate';
  aggregate?: Maybe<TestResultAuditAggregateFields>;
  nodes: Array<TestResultAudit>;
};

export type TestResultAuditAggregateBoolExp = {
  count?: InputMaybe<TestResultAuditAggregateBoolExpCount>;
};

export type TestResultAuditAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<TestResultAuditBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.test_result_audit" */
export type TestResultAuditAggregateFields = {
  __typename?: 'test_result_audit_aggregate_fields';
  avg?: Maybe<TestResultAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<TestResultAuditMaxFields>;
  min?: Maybe<TestResultAuditMinFields>;
  stddev?: Maybe<TestResultAuditStddevFields>;
  stddev_pop?: Maybe<TestResultAuditStddevPopFields>;
  stddev_samp?: Maybe<TestResultAuditStddevSampFields>;
  sum?: Maybe<TestResultAuditSumFields>;
  var_pop?: Maybe<TestResultAuditVarPopFields>;
  var_samp?: Maybe<TestResultAuditVarSampFields>;
  variance?: Maybe<TestResultAuditVarianceFields>;
};


/** aggregate fields of "risksmart.test_result_audit" */
export type TestResultAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<TestResultAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.test_result_audit" */
export type TestResultAuditAggregateOrderBy = {
  avg?: InputMaybe<TestResultAuditAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<TestResultAuditMaxOrderBy>;
  min?: InputMaybe<TestResultAuditMinOrderBy>;
  stddev?: InputMaybe<TestResultAuditStddevOrderBy>;
  stddev_pop?: InputMaybe<TestResultAuditStddevPopOrderBy>;
  stddev_samp?: InputMaybe<TestResultAuditStddevSampOrderBy>;
  sum?: InputMaybe<TestResultAuditSumOrderBy>;
  var_pop?: InputMaybe<TestResultAuditVarPopOrderBy>;
  var_samp?: InputMaybe<TestResultAuditVarSampOrderBy>;
  variance?: InputMaybe<TestResultAuditVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type TestResultAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.test_result_audit" */
export type TestResultAuditArrRelInsertInput = {
  data: Array<TestResultAuditInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<TestResultAuditOnConflict>;
};

/** aggregate avg on columns */
export type TestResultAuditAvgFields = {
  __typename?: 'test_result_audit_avg_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditAvgOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.test_result_audit". All fields are combined with a logical 'AND'. */
export type TestResultAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  DesignEffectiveness?: InputMaybe<IntComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OverallEffectiveness?: InputMaybe<IntComparisonExp>;
  ParentControlId?: InputMaybe<UuidComparisonExp>;
  PerformanceEffectiveness?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Submitter?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  TestType?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<TestResultAuditBoolExp>>;
  _not?: InputMaybe<TestResultAuditBoolExp>;
  _or?: InputMaybe<Array<TestResultAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.test_result_audit" */
export enum TestResultAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  TestResultAuditPkey = 'test_result_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TestResultAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TestResultAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TestResultAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.test_result_audit" */
export type TestResultAuditIncInput = {
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.test_result_audit" */
export type TestResultAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type TestResultAuditMaxFields = {
  __typename?: 'test_result_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId?: Maybe<Scalars['uuid']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditMaxOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  DesignEffectiveness?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  ParentControlId?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Submitter?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  TestType?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TestResultAuditMinFields = {
  __typename?: 'test_result_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId?: Maybe<Scalars['uuid']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditMinOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  DesignEffectiveness?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  ParentControlId?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Submitter?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  TestType?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.test_result_audit" */
export type TestResultAuditMutationResponse = {
  __typename?: 'test_result_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TestResultAudit>;
};

/** on_conflict condition type for table "risksmart.test_result_audit" */
export type TestResultAuditOnConflict = {
  constraint: TestResultAuditConstraint;
  update_columns?: Array<TestResultAuditUpdateColumn>;
  where?: InputMaybe<TestResultAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.test_result_audit". */
export type TestResultAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  DesignEffectiveness?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  ParentControlId?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Submitter?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  TestType?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.test_result_audit */
export type TestResultAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TestResultAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.test_result_audit" */
export enum TestResultAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  DesignEffectiveness = 'DesignEffectiveness',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverallEffectiveness = 'OverallEffectiveness',
  /** column name */
  ParentControlId = 'ParentControlId',
  /** column name */
  PerformanceEffectiveness = 'PerformanceEffectiveness',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Submitter = 'Submitter',
  /** column name */
  TestDate = 'TestDate',
  /** column name */
  TestType = 'TestType',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.test_result_audit" */
export type TestResultAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type TestResultAuditStddevFields = {
  __typename?: 'test_result_audit_stddev_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditStddevOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TestResultAuditStddevPopFields = {
  __typename?: 'test_result_audit_stddev_pop_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditStddevPopOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TestResultAuditStddevSampFields = {
  __typename?: 'test_result_audit_stddev_samp_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditStddevSampOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "test_result_audit" */
export type TestResultAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TestResultAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TestResultAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type TestResultAuditSumFields = {
  __typename?: 'test_result_audit_sum_fields';
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditSumOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.test_result_audit" */
export enum TestResultAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  DesignEffectiveness = 'DesignEffectiveness',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverallEffectiveness = 'OverallEffectiveness',
  /** column name */
  ParentControlId = 'ParentControlId',
  /** column name */
  PerformanceEffectiveness = 'PerformanceEffectiveness',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Submitter = 'Submitter',
  /** column name */
  TestDate = 'TestDate',
  /** column name */
  TestType = 'TestType',
  /** column name */
  Title = 'Title'
}

export type TestResultAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TestResultAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TestResultAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TestResultAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TestResultAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TestResultAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TestResultAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TestResultAuditSetInput>;
  /** filter the rows which have to be updated */
  where: TestResultAuditBoolExp;
};

/** aggregate var_pop on columns */
export type TestResultAuditVarPopFields = {
  __typename?: 'test_result_audit_var_pop_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditVarPopOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TestResultAuditVarSampFields = {
  __typename?: 'test_result_audit_var_samp_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditVarSampOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TestResultAuditVarianceFields = {
  __typename?: 'test_result_audit_variance_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.test_result_audit" */
export type TestResultAuditVarianceOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate avg on columns */
export type TestResultAvgFields = {
  __typename?: 'test_result_avg_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.test_result" */
export type TestResultAvgOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.test_result". All fields are combined with a logical 'AND'. */
export type TestResultBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  DesignEffectiveness?: InputMaybe<IntComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  Meta?: InputMaybe<JsonComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  NextTestDate?: InputMaybe<TimestamptzComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OverallEffectiveness?: InputMaybe<IntComparisonExp>;
  ParentControlId?: InputMaybe<UuidComparisonExp>;
  PerformanceEffectiveness?: InputMaybe<IntComparisonExp>;
  RatingType?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Submitter?: InputMaybe<StringComparisonExp>;
  TestDate?: InputMaybe<TimestamptzComparisonExp>;
  TestType?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<TestResultBoolExp>>;
  _not?: InputMaybe<TestResultBoolExp>;
  _or?: InputMaybe<Array<TestResultBoolExp>>;
  assessmentParents?: InputMaybe<AssessmentResultParentBoolExp>;
  assessmentParents_aggregate?: InputMaybe<AssessmentResultParentAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  files?: InputMaybe<RelationFileBoolExp>;
  files_aggregate?: InputMaybe<RelationFileAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  parent?: InputMaybe<ControlBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  submitter?: InputMaybe<UserBoolExp>;
};

/** unique or primary key constraints on table "risksmart.test_result" */
export enum TestResultConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxTestResultOrgkeySequentialid = 'idx_test_result_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  TestResultPkey = 'test_result_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type TestResultDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type TestResultDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type TestResultDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.test_result" */
export type TestResultIncInput = {
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.test_result" */
export type TestResultInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  assessmentParents?: InputMaybe<AssessmentResultParentArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  files?: InputMaybe<RelationFileArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  parent?: InputMaybe<ControlObjRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  submitter?: InputMaybe<UserObjRelInsertInput>;
};

/** aggregate max on columns */
export type TestResultMaxFields = {
  __typename?: 'test_result_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId?: Maybe<Scalars['uuid']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.test_result" */
export type TestResultMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  DesignEffectiveness?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  ParentControlId?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Submitter?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  TestType?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type TestResultMinFields = {
  __typename?: 'test_result_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  NextTestDate?: Maybe<Scalars['timestamptz']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  ParentControlId?: Maybe<Scalars['uuid']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  RatingType?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Submitter?: Maybe<Scalars['String']['output']>;
  TestDate?: Maybe<Scalars['timestamptz']['output']>;
  TestType?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.test_result" */
export type TestResultMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  DesignEffectiveness?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  ParentControlId?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Submitter?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  TestType?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.test_result" */
export type TestResultMutationResponse = {
  __typename?: 'test_result_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<TestResult>;
};

/** input type for inserting object relation for remote table "risksmart.test_result" */
export type TestResultObjRelInsertInput = {
  data: TestResultInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<TestResultOnConflict>;
};

/** on_conflict condition type for table "risksmart.test_result" */
export type TestResultOnConflict = {
  constraint: TestResultConstraint;
  update_columns?: Array<TestResultUpdateColumn>;
  where?: InputMaybe<TestResultBoolExp>;
};

/** Ordering options when selecting data from "risksmart.test_result". */
export type TestResultOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  DesignEffectiveness?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  Meta?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  NextTestDate?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  ParentControlId?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  RatingType?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Submitter?: InputMaybe<OrderBy>;
  TestDate?: InputMaybe<OrderBy>;
  TestType?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  assessmentParents_aggregate?: InputMaybe<AssessmentResultParentAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  files_aggregate?: InputMaybe<RelationFileAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  parent?: InputMaybe<ControlOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  submitter?: InputMaybe<UserOrderBy>;
};

/** primary key columns input for table: risksmart.test_result */
export type TestResultPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type TestResultPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.test_result" */
export enum TestResultSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  DesignEffectiveness = 'DesignEffectiveness',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverallEffectiveness = 'OverallEffectiveness',
  /** column name */
  ParentControlId = 'ParentControlId',
  /** column name */
  PerformanceEffectiveness = 'PerformanceEffectiveness',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Submitter = 'Submitter',
  /** column name */
  TestDate = 'TestDate',
  /** column name */
  TestType = 'TestType',
  /** column name */
  Title = 'Title'
}

/** input type for updating data in table "risksmart.test_result" */
export type TestResultSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type TestResultStddevFields = {
  __typename?: 'test_result_stddev_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.test_result" */
export type TestResultStddevOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type TestResultStddevPopFields = {
  __typename?: 'test_result_stddev_pop_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.test_result" */
export type TestResultStddevPopOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type TestResultStddevSampFields = {
  __typename?: 'test_result_stddev_samp_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.test_result" */
export type TestResultStddevSampOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "test_result" */
export type TestResultStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: TestResultStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type TestResultStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  DesignEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  Meta?: InputMaybe<Scalars['json']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  NextTestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OverallEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  ParentControlId?: InputMaybe<Scalars['uuid']['input']>;
  PerformanceEffectiveness?: InputMaybe<Scalars['Int']['input']>;
  RatingType?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Submitter?: InputMaybe<Scalars['String']['input']>;
  TestDate?: InputMaybe<Scalars['timestamptz']['input']>;
  TestType?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type TestResultSumFields = {
  __typename?: 'test_result_sum_fields';
  DesignEffectiveness?: Maybe<Scalars['Int']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Int']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.test_result" */
export type TestResultSumOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** update columns of table "risksmart.test_result" */
export enum TestResultUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  DesignEffectiveness = 'DesignEffectiveness',
  /** column name */
  Id = 'Id',
  /** column name */
  Meta = 'Meta',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  NextTestDate = 'NextTestDate',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OverallEffectiveness = 'OverallEffectiveness',
  /** column name */
  ParentControlId = 'ParentControlId',
  /** column name */
  PerformanceEffectiveness = 'PerformanceEffectiveness',
  /** column name */
  RatingType = 'RatingType',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Submitter = 'Submitter',
  /** column name */
  TestDate = 'TestDate',
  /** column name */
  TestType = 'TestType',
  /** column name */
  Title = 'Title'
}

export type TestResultUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<TestResultAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<TestResultDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<TestResultDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<TestResultDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<TestResultIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<TestResultPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<TestResultSetInput>;
  /** filter the rows which have to be updated */
  where: TestResultBoolExp;
};

/** aggregate var_pop on columns */
export type TestResultVarPopFields = {
  __typename?: 'test_result_var_pop_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.test_result" */
export type TestResultVarPopOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type TestResultVarSampFields = {
  __typename?: 'test_result_var_samp_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.test_result" */
export type TestResultVarSampOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type TestResultVarianceFields = {
  __typename?: 'test_result_variance_fields';
  DesignEffectiveness?: Maybe<Scalars['Float']['output']>;
  OverallEffectiveness?: Maybe<Scalars['Float']['output']>;
  PerformanceEffectiveness?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.test_result" */
export type TestResultVarianceOrderBy = {
  DesignEffectiveness?: InputMaybe<OrderBy>;
  OverallEffectiveness?: InputMaybe<OrderBy>;
  PerformanceEffectiveness?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.third_party" */
export type ThirdParty = {
  __typename?: 'third_party';
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName: Scalars['String']['output'];
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Criticality: Scalars['Int']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: ThirdPartyStatusEnum;
  Title: Scalars['String']['output'];
  Type: ThirdPartyTypeEnum;
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  departments: Array<Department>;
  /** An aggregate relationship */
  departments_aggregate: DepartmentAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An array relationship */
  tags: Array<Tag>;
  /** An aggregate relationship */
  tags_aggregate: TagAggregate;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyDepartmentsArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyDepartmentsAggregateArgs = {
  distinct_on?: InputMaybe<Array<DepartmentSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<DepartmentOrderBy>>;
  where?: InputMaybe<DepartmentBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyPermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyPermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyTagsArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};


/** columns and relationships of "risksmart.third_party" */
export type ThirdPartyTagsAggregateArgs = {
  distinct_on?: InputMaybe<Array<TagSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<TagOrderBy>>;
  where?: InputMaybe<TagBoolExp>;
};

/** aggregated selection of "risksmart.third_party" */
export type ThirdPartyAggregate = {
  __typename?: 'third_party_aggregate';
  aggregate?: Maybe<ThirdPartyAggregateFields>;
  nodes: Array<ThirdParty>;
};

export type ThirdPartyAggregateBoolExp = {
  count?: InputMaybe<ThirdPartyAggregateBoolExpCount>;
};

export type ThirdPartyAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<ThirdPartySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<ThirdPartyBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.third_party" */
export type ThirdPartyAggregateFields = {
  __typename?: 'third_party_aggregate_fields';
  avg?: Maybe<ThirdPartyAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ThirdPartyMaxFields>;
  min?: Maybe<ThirdPartyMinFields>;
  stddev?: Maybe<ThirdPartyStddevFields>;
  stddev_pop?: Maybe<ThirdPartyStddevPopFields>;
  stddev_samp?: Maybe<ThirdPartyStddevSampFields>;
  sum?: Maybe<ThirdPartySumFields>;
  var_pop?: Maybe<ThirdPartyVarPopFields>;
  var_samp?: Maybe<ThirdPartyVarSampFields>;
  variance?: Maybe<ThirdPartyVarianceFields>;
};


/** aggregate fields of "risksmart.third_party" */
export type ThirdPartyAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThirdPartySelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.third_party" */
export type ThirdPartyAggregateOrderBy = {
  avg?: InputMaybe<ThirdPartyAvgOrderBy>;
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<ThirdPartyMaxOrderBy>;
  min?: InputMaybe<ThirdPartyMinOrderBy>;
  stddev?: InputMaybe<ThirdPartyStddevOrderBy>;
  stddev_pop?: InputMaybe<ThirdPartyStddevPopOrderBy>;
  stddev_samp?: InputMaybe<ThirdPartyStddevSampOrderBy>;
  sum?: InputMaybe<ThirdPartySumOrderBy>;
  var_pop?: InputMaybe<ThirdPartyVarPopOrderBy>;
  var_samp?: InputMaybe<ThirdPartyVarSampOrderBy>;
  variance?: InputMaybe<ThirdPartyVarianceOrderBy>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ThirdPartyAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "risksmart.third_party" */
export type ThirdPartyArrRelInsertInput = {
  data: Array<ThirdPartyInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<ThirdPartyOnConflict>;
};

/** columns and relationships of "risksmart.third_party_audit" */
export type ThirdPartyAudit = {
  __typename?: 'third_party_audit';
  Action?: Maybe<Scalars['String']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName: Scalars['String']['output'];
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Criticality: Scalars['Int']['output'];
  CustomAttributeData?: Maybe<Scalars['jsonb']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status: Scalars['String']['output'];
  Title: Scalars['String']['output'];
  Type: Scalars['String']['output'];
};


/** columns and relationships of "risksmart.third_party_audit" */
export type ThirdPartyAuditCustomAttributeDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "risksmart.third_party_audit" */
export type ThirdPartyAuditAggregate = {
  __typename?: 'third_party_audit_aggregate';
  aggregate?: Maybe<ThirdPartyAuditAggregateFields>;
  nodes: Array<ThirdPartyAudit>;
};

/** aggregate fields of "risksmart.third_party_audit" */
export type ThirdPartyAuditAggregateFields = {
  __typename?: 'third_party_audit_aggregate_fields';
  avg?: Maybe<ThirdPartyAuditAvgFields>;
  count: Scalars['Int']['output'];
  max?: Maybe<ThirdPartyAuditMaxFields>;
  min?: Maybe<ThirdPartyAuditMinFields>;
  stddev?: Maybe<ThirdPartyAuditStddevFields>;
  stddev_pop?: Maybe<ThirdPartyAuditStddevPopFields>;
  stddev_samp?: Maybe<ThirdPartyAuditStddevSampFields>;
  sum?: Maybe<ThirdPartyAuditSumFields>;
  var_pop?: Maybe<ThirdPartyAuditVarPopFields>;
  var_samp?: Maybe<ThirdPartyAuditVarSampFields>;
  variance?: Maybe<ThirdPartyAuditVarianceFields>;
};


/** aggregate fields of "risksmart.third_party_audit" */
export type ThirdPartyAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThirdPartyAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ThirdPartyAuditAppendInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type ThirdPartyAuditAvgFields = {
  __typename?: 'third_party_audit_avg_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party_audit". All fields are combined with a logical 'AND'. */
export type ThirdPartyAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  Address?: InputMaybe<StringComparisonExp>;
  CityTown?: InputMaybe<StringComparisonExp>;
  CompaniesHouseNumber?: InputMaybe<StringComparisonExp>;
  CompanyDomain?: InputMaybe<StringComparisonExp>;
  CompanyName?: InputMaybe<StringComparisonExp>;
  ContactEmail?: InputMaybe<StringComparisonExp>;
  ContactName?: InputMaybe<StringComparisonExp>;
  Country?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Criticality?: InputMaybe<IntComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Postcode?: InputMaybe<StringComparisonExp>;
  PrimaryContactName?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ThirdPartyAuditBoolExp>>;
  _not?: InputMaybe<ThirdPartyAuditBoolExp>;
  _or?: InputMaybe<Array<ThirdPartyAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.third_party_audit" */
export enum ThirdPartyAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  ThirdPartyAuditPkey = 'third_party_audit_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ThirdPartyAuditDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ThirdPartyAuditDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ThirdPartyAuditDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.third_party_audit" */
export type ThirdPartyAuditIncInput = {
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.third_party_audit" */
export type ThirdPartyAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ThirdPartyAuditMaxFields = {
  __typename?: 'third_party_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName?: Maybe<Scalars['String']['output']>;
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ThirdPartyAuditMinFields = {
  __typename?: 'third_party_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName?: Maybe<Scalars['String']['output']>;
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
  Type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.third_party_audit" */
export type ThirdPartyAuditMutationResponse = {
  __typename?: 'third_party_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ThirdPartyAudit>;
};

/** on_conflict condition type for table "risksmart.third_party_audit" */
export type ThirdPartyAuditOnConflict = {
  constraint: ThirdPartyAuditConstraint;
  update_columns?: Array<ThirdPartyAuditUpdateColumn>;
  where?: InputMaybe<ThirdPartyAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.third_party_audit". */
export type ThirdPartyAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  Address?: InputMaybe<OrderBy>;
  CityTown?: InputMaybe<OrderBy>;
  CompaniesHouseNumber?: InputMaybe<OrderBy>;
  CompanyDomain?: InputMaybe<OrderBy>;
  CompanyName?: InputMaybe<OrderBy>;
  ContactEmail?: InputMaybe<OrderBy>;
  ContactName?: InputMaybe<OrderBy>;
  Country?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Postcode?: InputMaybe<OrderBy>;
  PrimaryContactName?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.third_party_audit */
export type ThirdPartyAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ThirdPartyAuditPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.third_party_audit" */
export enum ThirdPartyAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Address = 'Address',
  /** column name */
  CityTown = 'CityTown',
  /** column name */
  CompaniesHouseNumber = 'CompaniesHouseNumber',
  /** column name */
  CompanyDomain = 'CompanyDomain',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  ContactEmail = 'ContactEmail',
  /** column name */
  ContactName = 'ContactName',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Postcode = 'Postcode',
  /** column name */
  PrimaryContactName = 'PrimaryContactName',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.third_party_audit" */
export type ThirdPartyAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type ThirdPartyAuditStddevFields = {
  __typename?: 'third_party_audit_stddev_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type ThirdPartyAuditStddevPopFields = {
  __typename?: 'third_party_audit_stddev_pop_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type ThirdPartyAuditStddevSampFields = {
  __typename?: 'third_party_audit_stddev_samp_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "third_party_audit" */
export type ThirdPartyAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThirdPartyAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThirdPartyAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type ThirdPartyAuditSumFields = {
  __typename?: 'third_party_audit_sum_fields';
  Criticality?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "risksmart.third_party_audit" */
export enum ThirdPartyAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  Address = 'Address',
  /** column name */
  CityTown = 'CityTown',
  /** column name */
  CompaniesHouseNumber = 'CompaniesHouseNumber',
  /** column name */
  CompanyDomain = 'CompanyDomain',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  ContactEmail = 'ContactEmail',
  /** column name */
  ContactName = 'ContactName',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Postcode = 'Postcode',
  /** column name */
  PrimaryContactName = 'PrimaryContactName',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ThirdPartyAuditUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ThirdPartyAuditAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ThirdPartyAuditDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ThirdPartyAuditDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ThirdPartyAuditDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ThirdPartyAuditIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ThirdPartyAuditPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThirdPartyAuditSetInput>;
  /** filter the rows which have to be updated */
  where: ThirdPartyAuditBoolExp;
};

/** aggregate var_pop on columns */
export type ThirdPartyAuditVarPopFields = {
  __typename?: 'third_party_audit_var_pop_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type ThirdPartyAuditVarSampFields = {
  __typename?: 'third_party_audit_var_samp_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type ThirdPartyAuditVarianceFields = {
  __typename?: 'third_party_audit_variance_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** aggregate avg on columns */
export type ThirdPartyAvgFields = {
  __typename?: 'third_party_avg_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risksmart.third_party" */
export type ThirdPartyAvgOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party". All fields are combined with a logical 'AND'. */
export type ThirdPartyBoolExp = {
  Address?: InputMaybe<StringComparisonExp>;
  CityTown?: InputMaybe<StringComparisonExp>;
  CompaniesHouseNumber?: InputMaybe<StringComparisonExp>;
  CompanyDomain?: InputMaybe<StringComparisonExp>;
  CompanyName?: InputMaybe<StringComparisonExp>;
  ContactEmail?: InputMaybe<StringComparisonExp>;
  ContactName?: InputMaybe<StringComparisonExp>;
  Country?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Criticality?: InputMaybe<IntComparisonExp>;
  CustomAttributeData?: InputMaybe<JsonbComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  Postcode?: InputMaybe<StringComparisonExp>;
  PrimaryContactName?: InputMaybe<StringComparisonExp>;
  SequentialId?: InputMaybe<IntComparisonExp>;
  Status?: InputMaybe<ThirdPartyStatusEnumComparisonExp>;
  Title?: InputMaybe<StringComparisonExp>;
  Type?: InputMaybe<ThirdPartyTypeEnumComparisonExp>;
  _and?: InputMaybe<Array<ThirdPartyBoolExp>>;
  _not?: InputMaybe<ThirdPartyBoolExp>;
  _or?: InputMaybe<Array<ThirdPartyBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  departments?: InputMaybe<DepartmentBoolExp>;
  departments_aggregate?: InputMaybe<DepartmentAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  tags?: InputMaybe<TagBoolExp>;
  tags_aggregate?: InputMaybe<TagAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.third_party" */
export enum ThirdPartyConstraint {
  /** unique or primary key constraint on columns "SequentialId", "OrgKey" */
  IdxThirdPartyOrgkeySequentialid = 'idx_third_party_orgkey_sequentialid',
  /** unique or primary key constraint on columns "Id" */
  ThirdPartyPkey = 'third_party_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ThirdPartyDeleteAtPathInput = {
  CustomAttributeData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ThirdPartyDeleteElemInput = {
  CustomAttributeData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ThirdPartyDeleteKeyInput = {
  CustomAttributeData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "risksmart.third_party" */
export type ThirdPartyIncInput = {
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "risksmart.third_party" */
export type ThirdPartyInsertInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<ThirdPartyStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ThirdPartyTypeEnum>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  departments?: InputMaybe<DepartmentArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  tags?: InputMaybe<TagArrRelInsertInput>;
};

/** aggregate max on columns */
export type ThirdPartyMaxFields = {
  __typename?: 'third_party_max_fields';
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName?: Maybe<Scalars['String']['output']>;
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.third_party" */
export type ThirdPartyMaxOrderBy = {
  Address?: InputMaybe<OrderBy>;
  CityTown?: InputMaybe<OrderBy>;
  CompaniesHouseNumber?: InputMaybe<OrderBy>;
  CompanyDomain?: InputMaybe<OrderBy>;
  CompanyName?: InputMaybe<OrderBy>;
  ContactEmail?: InputMaybe<OrderBy>;
  ContactName?: InputMaybe<OrderBy>;
  Country?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Postcode?: InputMaybe<OrderBy>;
  PrimaryContactName?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type ThirdPartyMinFields = {
  __typename?: 'third_party_min_fields';
  Address?: Maybe<Scalars['String']['output']>;
  CityTown?: Maybe<Scalars['String']['output']>;
  CompaniesHouseNumber?: Maybe<Scalars['String']['output']>;
  CompanyDomain?: Maybe<Scalars['String']['output']>;
  CompanyName?: Maybe<Scalars['String']['output']>;
  ContactEmail?: Maybe<Scalars['String']['output']>;
  ContactName?: Maybe<Scalars['String']['output']>;
  Country?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Criticality?: Maybe<Scalars['Int']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  Postcode?: Maybe<Scalars['String']['output']>;
  PrimaryContactName?: Maybe<Scalars['String']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
  Title?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.third_party" */
export type ThirdPartyMinOrderBy = {
  Address?: InputMaybe<OrderBy>;
  CityTown?: InputMaybe<OrderBy>;
  CompaniesHouseNumber?: InputMaybe<OrderBy>;
  CompanyDomain?: InputMaybe<OrderBy>;
  CompanyName?: InputMaybe<OrderBy>;
  ContactEmail?: InputMaybe<OrderBy>;
  ContactName?: InputMaybe<OrderBy>;
  Country?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Postcode?: InputMaybe<OrderBy>;
  PrimaryContactName?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.third_party" */
export type ThirdPartyMutationResponse = {
  __typename?: 'third_party_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ThirdParty>;
};

/** input type for inserting object relation for remote table "risksmart.third_party" */
export type ThirdPartyObjRelInsertInput = {
  data: ThirdPartyInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ThirdPartyOnConflict>;
};

/** on_conflict condition type for table "risksmart.third_party" */
export type ThirdPartyOnConflict = {
  constraint: ThirdPartyConstraint;
  update_columns?: Array<ThirdPartyUpdateColumn>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};

/** Ordering options when selecting data from "risksmart.third_party". */
export type ThirdPartyOrderBy = {
  Address?: InputMaybe<OrderBy>;
  CityTown?: InputMaybe<OrderBy>;
  CompaniesHouseNumber?: InputMaybe<OrderBy>;
  CompanyDomain?: InputMaybe<OrderBy>;
  CompanyName?: InputMaybe<OrderBy>;
  ContactEmail?: InputMaybe<OrderBy>;
  ContactName?: InputMaybe<OrderBy>;
  Country?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Criticality?: InputMaybe<OrderBy>;
  CustomAttributeData?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  Postcode?: InputMaybe<OrderBy>;
  PrimaryContactName?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  Title?: InputMaybe<OrderBy>;
  Type?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  departments_aggregate?: InputMaybe<DepartmentAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  tags_aggregate?: InputMaybe<TagAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.third_party */
export type ThirdPartyPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ThirdPartyPrependInput = {
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponse = {
  __typename?: 'third_party_response';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  QuestionnaireTemplateVersionId: Scalars['uuid']['output'];
  ResponseData: Scalars['jsonb']['output'];
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  Status: ThirdPartyResponseStatusEnum;
  ThirdPartyId: Scalars['uuid']['output'];
  /** An array relationship */
  ancestorContributors: Array<AncestorContributor>;
  /** An aggregate relationship */
  ancestorContributors_aggregate: AncestorContributorAggregate;
  /** An array relationship */
  contributorGroups: Array<ContributorGroup>;
  /** An aggregate relationship */
  contributorGroups_aggregate: ContributorGroupAggregate;
  /** An array relationship */
  contributors: Array<Contributor>;
  /** An aggregate relationship */
  contributors_aggregate: ContributorAggregate;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An array relationship */
  invitees: Array<QuestionnaireInvite>;
  /** An aggregate relationship */
  invitees_aggregate: QuestionnaireInviteAggregate;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  ownerGroups: Array<OwnerGroup>;
  /** An aggregate relationship */
  ownerGroups_aggregate: OwnerGroupAggregate;
  /** An array relationship */
  owners: Array<Owner>;
  /** An aggregate relationship */
  owners_aggregate: OwnerAggregate;
  /** An array relationship */
  permissions: Array<Permission>;
  /** An aggregate relationship */
  permissions_aggregate: PermissionAggregate;
  /** An object relationship */
  questionnaireTemplateVersion?: Maybe<QuestionnaireTemplateVersion>;
  /** An object relationship */
  thirdParty?: Maybe<ThirdParty>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseResponseDataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseAncestorContributorsArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseAncestorContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<AncestorContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AncestorContributorOrderBy>>;
  where?: InputMaybe<AncestorContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseContributorGroupsArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseContributorGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorGroupOrderBy>>;
  where?: InputMaybe<ContributorGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseContributorsArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseContributorsAggregateArgs = {
  distinct_on?: InputMaybe<Array<ContributorSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ContributorOrderBy>>;
  where?: InputMaybe<ContributorBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseInviteesArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseInviteesAggregateArgs = {
  distinct_on?: InputMaybe<Array<QuestionnaireInviteSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<QuestionnaireInviteOrderBy>>;
  where?: InputMaybe<QuestionnaireInviteBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseOwnerGroupsArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseOwnerGroupsAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerGroupSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerGroupOrderBy>>;
  where?: InputMaybe<OwnerGroupBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseOwnersArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponseOwnersAggregateArgs = {
  distinct_on?: InputMaybe<Array<OwnerSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<OwnerOrderBy>>;
  where?: InputMaybe<OwnerBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponsePermissionsArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};


/** columns and relationships of "risksmart.third_party_response" */
export type ThirdPartyResponsePermissionsAggregateArgs = {
  distinct_on?: InputMaybe<Array<PermissionSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<PermissionOrderBy>>;
  where?: InputMaybe<PermissionBoolExp>;
};

/** aggregated selection of "risksmart.third_party_response" */
export type ThirdPartyResponseAggregate = {
  __typename?: 'third_party_response_aggregate';
  aggregate?: Maybe<ThirdPartyResponseAggregateFields>;
  nodes: Array<ThirdPartyResponse>;
};

/** aggregate fields of "risksmart.third_party_response" */
export type ThirdPartyResponseAggregateFields = {
  __typename?: 'third_party_response_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ThirdPartyResponseMaxFields>;
  min?: Maybe<ThirdPartyResponseMinFields>;
};


/** aggregate fields of "risksmart.third_party_response" */
export type ThirdPartyResponseAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThirdPartyResponseSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type ThirdPartyResponseAppendInput = {
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party_response". All fields are combined with a logical 'AND'. */
export type ThirdPartyResponseBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ExpiresAt?: InputMaybe<TimestamptzComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  QuestionnaireTemplateVersionId?: InputMaybe<UuidComparisonExp>;
  ResponseData?: InputMaybe<JsonbComparisonExp>;
  StartDate?: InputMaybe<TimestamptzComparisonExp>;
  Status?: InputMaybe<ThirdPartyResponseStatusEnumComparisonExp>;
  ThirdPartyId?: InputMaybe<UuidComparisonExp>;
  _and?: InputMaybe<Array<ThirdPartyResponseBoolExp>>;
  _not?: InputMaybe<ThirdPartyResponseBoolExp>;
  _or?: InputMaybe<Array<ThirdPartyResponseBoolExp>>;
  ancestorContributors?: InputMaybe<AncestorContributorBoolExp>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateBoolExp>;
  contributorGroups?: InputMaybe<ContributorGroupBoolExp>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateBoolExp>;
  contributors?: InputMaybe<ContributorBoolExp>;
  contributors_aggregate?: InputMaybe<ContributorAggregateBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  invitees?: InputMaybe<QuestionnaireInviteBoolExp>;
  invitees_aggregate?: InputMaybe<QuestionnaireInviteAggregateBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  ownerGroups?: InputMaybe<OwnerGroupBoolExp>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateBoolExp>;
  owners?: InputMaybe<OwnerBoolExp>;
  owners_aggregate?: InputMaybe<OwnerAggregateBoolExp>;
  permissions?: InputMaybe<PermissionBoolExp>;
  permissions_aggregate?: InputMaybe<PermissionAggregateBoolExp>;
  questionnaireTemplateVersion?: InputMaybe<QuestionnaireTemplateVersionBoolExp>;
  thirdParty?: InputMaybe<ThirdPartyBoolExp>;
};

/** unique or primary key constraints on table "risksmart.third_party_response" */
export enum ThirdPartyResponseConstraint {
  /** unique or primary key constraint on columns "Id" */
  ThirdPartyResponsePkey = 'third_party_response_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type ThirdPartyResponseDeleteAtPathInput = {
  ResponseData?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type ThirdPartyResponseDeleteElemInput = {
  ResponseData?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type ThirdPartyResponseDeleteKeyInput = {
  ResponseData?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "risksmart.third_party_response" */
export type ThirdPartyResponseInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<ThirdPartyResponseStatusEnum>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
  ancestorContributors?: InputMaybe<AncestorContributorArrRelInsertInput>;
  contributorGroups?: InputMaybe<ContributorGroupArrRelInsertInput>;
  contributors?: InputMaybe<ContributorArrRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  invitees?: InputMaybe<QuestionnaireInviteArrRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  ownerGroups?: InputMaybe<OwnerGroupArrRelInsertInput>;
  owners?: InputMaybe<OwnerArrRelInsertInput>;
  permissions?: InputMaybe<PermissionArrRelInsertInput>;
  questionnaireTemplateVersion?: InputMaybe<QuestionnaireTemplateVersionObjRelInsertInput>;
  thirdParty?: InputMaybe<ThirdPartyObjRelInsertInput>;
};

/** aggregate max on columns */
export type ThirdPartyResponseMaxFields = {
  __typename?: 'third_party_response_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  QuestionnaireTemplateVersionId?: Maybe<Scalars['uuid']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyId?: Maybe<Scalars['uuid']['output']>;
};

/** aggregate min on columns */
export type ThirdPartyResponseMinFields = {
  __typename?: 'third_party_response_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ExpiresAt?: Maybe<Scalars['timestamptz']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  QuestionnaireTemplateVersionId?: Maybe<Scalars['uuid']['output']>;
  StartDate?: Maybe<Scalars['timestamptz']['output']>;
  ThirdPartyId?: Maybe<Scalars['uuid']['output']>;
};

/** response of any mutation on the table "risksmart.third_party_response" */
export type ThirdPartyResponseMutationResponse = {
  __typename?: 'third_party_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ThirdPartyResponse>;
};

/** input type for inserting object relation for remote table "risksmart.third_party_response" */
export type ThirdPartyResponseObjRelInsertInput = {
  data: ThirdPartyResponseInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<ThirdPartyResponseOnConflict>;
};

/** on_conflict condition type for table "risksmart.third_party_response" */
export type ThirdPartyResponseOnConflict = {
  constraint: ThirdPartyResponseConstraint;
  update_columns?: Array<ThirdPartyResponseUpdateColumn>;
  where?: InputMaybe<ThirdPartyResponseBoolExp>;
};

/** Ordering options when selecting data from "risksmart.third_party_response". */
export type ThirdPartyResponseOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ExpiresAt?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  QuestionnaireTemplateVersionId?: InputMaybe<OrderBy>;
  ResponseData?: InputMaybe<OrderBy>;
  StartDate?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  ThirdPartyId?: InputMaybe<OrderBy>;
  ancestorContributors_aggregate?: InputMaybe<AncestorContributorAggregateOrderBy>;
  contributorGroups_aggregate?: InputMaybe<ContributorGroupAggregateOrderBy>;
  contributors_aggregate?: InputMaybe<ContributorAggregateOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  invitees_aggregate?: InputMaybe<QuestionnaireInviteAggregateOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  ownerGroups_aggregate?: InputMaybe<OwnerGroupAggregateOrderBy>;
  owners_aggregate?: InputMaybe<OwnerAggregateOrderBy>;
  permissions_aggregate?: InputMaybe<PermissionAggregateOrderBy>;
  questionnaireTemplateVersion?: InputMaybe<QuestionnaireTemplateVersionOrderBy>;
  thirdParty?: InputMaybe<ThirdPartyOrderBy>;
};

/** primary key columns input for table: risksmart.third_party_response */
export type ThirdPartyResponsePkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type ThirdPartyResponsePrependInput = {
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "risksmart.third_party_response" */
export enum ThirdPartyResponseSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ExpiresAt = 'ExpiresAt',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  QuestionnaireTemplateVersionId = 'QuestionnaireTemplateVersionId',
  /** column name */
  ResponseData = 'ResponseData',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  ThirdPartyId = 'ThirdPartyId'
}

/** input type for updating data in table "risksmart.third_party_response" */
export type ThirdPartyResponseSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<ThirdPartyResponseStatusEnum>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatus = {
  __typename?: 'third_party_response_status';
  Comment: Scalars['String']['output'];
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusAggregate = {
  __typename?: 'third_party_response_status_aggregate';
  aggregate?: Maybe<ThirdPartyResponseStatusAggregateFields>;
  nodes: Array<ThirdPartyResponseStatus>;
};

/** aggregate fields of "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusAggregateFields = {
  __typename?: 'third_party_response_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ThirdPartyResponseStatusMaxFields>;
  min?: Maybe<ThirdPartyResponseStatusMinFields>;
};


/** aggregate fields of "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThirdPartyResponseStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party_response_status". All fields are combined with a logical 'AND'. */
export type ThirdPartyResponseStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ThirdPartyResponseStatusBoolExp>>;
  _not?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
  _or?: InputMaybe<Array<ThirdPartyResponseStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.third_party_response_status" */
export enum ThirdPartyResponseStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  ThirdPartyResponseStatusPkey = 'third_party_response_status_pkey'
}

export enum ThirdPartyResponseStatusEnum {
  /** Awaiting review */
  AwaitingReview = 'awaiting_review',
  /** Completed */
  Completed = 'completed',
  /** Expired */
  Expired = 'expired',
  /** In progress */
  InProgress = 'in_progress',
  /** Not started */
  NotStarted = 'not_started',
  /** Rejected */
  Rejected = 'rejected'
}

/** Boolean expression to compare columns of type "third_party_response_status_enum". All fields are combined with logical 'AND'. */
export type ThirdPartyResponseStatusEnumComparisonExp = {
  _eq?: InputMaybe<ThirdPartyResponseStatusEnum>;
  _in?: InputMaybe<Array<ThirdPartyResponseStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ThirdPartyResponseStatusEnum>;
  _nin?: InputMaybe<Array<ThirdPartyResponseStatusEnum>>;
};

/** input type for inserting data into table "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type ThirdPartyResponseStatusMaxFields = {
  __typename?: 'third_party_response_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ThirdPartyResponseStatusMinFields = {
  __typename?: 'third_party_response_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusMutationResponse = {
  __typename?: 'third_party_response_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ThirdPartyResponseStatus>;
};

/** on_conflict condition type for table "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusOnConflict = {
  constraint: ThirdPartyResponseStatusConstraint;
  update_columns?: Array<ThirdPartyResponseStatusUpdateColumn>;
  where?: InputMaybe<ThirdPartyResponseStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.third_party_response_status". */
export type ThirdPartyResponseStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.third_party_response_status */
export type ThirdPartyResponseStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.third_party_response_status" */
export enum ThirdPartyResponseStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.third_party_response_status" */
export type ThirdPartyResponseStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "third_party_response_status" */
export type ThirdPartyResponseStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThirdPartyResponseStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThirdPartyResponseStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.third_party_response_status" */
export enum ThirdPartyResponseStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ThirdPartyResponseStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThirdPartyResponseStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ThirdPartyResponseStatusBoolExp;
};

/** Streaming cursor of the table "third_party_response" */
export type ThirdPartyResponseStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThirdPartyResponseStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThirdPartyResponseStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ExpiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  QuestionnaireTemplateVersionId?: InputMaybe<Scalars['uuid']['input']>;
  ResponseData?: InputMaybe<Scalars['jsonb']['input']>;
  StartDate?: InputMaybe<Scalars['timestamptz']['input']>;
  Status?: InputMaybe<ThirdPartyResponseStatusEnum>;
  ThirdPartyId?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "risksmart.third_party_response" */
export enum ThirdPartyResponseUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ExpiresAt = 'ExpiresAt',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  QuestionnaireTemplateVersionId = 'QuestionnaireTemplateVersionId',
  /** column name */
  ResponseData = 'ResponseData',
  /** column name */
  StartDate = 'StartDate',
  /** column name */
  Status = 'Status',
  /** column name */
  ThirdPartyId = 'ThirdPartyId'
}

export type ThirdPartyResponseUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ThirdPartyResponseAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ThirdPartyResponseDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ThirdPartyResponseDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ThirdPartyResponseDeleteKeyInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ThirdPartyResponsePrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThirdPartyResponseSetInput>;
  /** filter the rows which have to be updated */
  where: ThirdPartyResponseBoolExp;
};

/** select columns of table "risksmart.third_party" */
export enum ThirdPartySelectColumn {
  /** column name */
  Address = 'Address',
  /** column name */
  CityTown = 'CityTown',
  /** column name */
  CompaniesHouseNumber = 'CompaniesHouseNumber',
  /** column name */
  CompanyDomain = 'CompanyDomain',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  ContactEmail = 'ContactEmail',
  /** column name */
  ContactName = 'ContactName',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Postcode = 'Postcode',
  /** column name */
  PrimaryContactName = 'PrimaryContactName',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

/** input type for updating data in table "risksmart.third_party" */
export type ThirdPartySetInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<ThirdPartyStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ThirdPartyTypeEnum>;
};

/** columns and relationships of "risksmart.third_party_status" */
export type ThirdPartyStatus = {
  __typename?: 'third_party_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  third_parties: Array<ThirdParty>;
  /** An aggregate relationship */
  third_parties_aggregate: ThirdPartyAggregate;
};


/** columns and relationships of "risksmart.third_party_status" */
export type ThirdPartyStatusThirdPartiesArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


/** columns and relationships of "risksmart.third_party_status" */
export type ThirdPartyStatusThirdPartiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};

/** aggregated selection of "risksmart.third_party_status" */
export type ThirdPartyStatusAggregate = {
  __typename?: 'third_party_status_aggregate';
  aggregate?: Maybe<ThirdPartyStatusAggregateFields>;
  nodes: Array<ThirdPartyStatus>;
};

/** aggregate fields of "risksmart.third_party_status" */
export type ThirdPartyStatusAggregateFields = {
  __typename?: 'third_party_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ThirdPartyStatusMaxFields>;
  min?: Maybe<ThirdPartyStatusMinFields>;
};


/** aggregate fields of "risksmart.third_party_status" */
export type ThirdPartyStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThirdPartyStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party_status". All fields are combined with a logical 'AND'. */
export type ThirdPartyStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ThirdPartyStatusBoolExp>>;
  _not?: InputMaybe<ThirdPartyStatusBoolExp>;
  _or?: InputMaybe<Array<ThirdPartyStatusBoolExp>>;
  third_parties?: InputMaybe<ThirdPartyBoolExp>;
  third_parties_aggregate?: InputMaybe<ThirdPartyAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.third_party_status" */
export enum ThirdPartyStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  ThirdPartyStatusPkey = 'third_party_status_pkey'
}

export enum ThirdPartyStatusEnum {
  /** Active */
  Active = 'active',
  /** Archived */
  Archived = 'archived',
  /** Pre-Contract */
  PreContract = 'pre_contract',
  /** Review Due */
  ReviewDue = 'review_due'
}

/** Boolean expression to compare columns of type "third_party_status_enum". All fields are combined with logical 'AND'. */
export type ThirdPartyStatusEnumComparisonExp = {
  _eq?: InputMaybe<ThirdPartyStatusEnum>;
  _in?: InputMaybe<Array<ThirdPartyStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ThirdPartyStatusEnum>;
  _nin?: InputMaybe<Array<ThirdPartyStatusEnum>>;
};

/** input type for inserting data into table "risksmart.third_party_status" */
export type ThirdPartyStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
  third_parties?: InputMaybe<ThirdPartyArrRelInsertInput>;
};

/** aggregate max on columns */
export type ThirdPartyStatusMaxFields = {
  __typename?: 'third_party_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ThirdPartyStatusMinFields = {
  __typename?: 'third_party_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.third_party_status" */
export type ThirdPartyStatusMutationResponse = {
  __typename?: 'third_party_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ThirdPartyStatus>;
};

/** on_conflict condition type for table "risksmart.third_party_status" */
export type ThirdPartyStatusOnConflict = {
  constraint: ThirdPartyStatusConstraint;
  update_columns?: Array<ThirdPartyStatusUpdateColumn>;
  where?: InputMaybe<ThirdPartyStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.third_party_status". */
export type ThirdPartyStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
  third_parties_aggregate?: InputMaybe<ThirdPartyAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.third_party_status */
export type ThirdPartyStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.third_party_status" */
export enum ThirdPartyStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.third_party_status" */
export type ThirdPartyStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "third_party_status" */
export type ThirdPartyStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThirdPartyStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThirdPartyStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.third_party_status" */
export enum ThirdPartyStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ThirdPartyStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThirdPartyStatusSetInput>;
  /** filter the rows which have to be updated */
  where: ThirdPartyStatusBoolExp;
};

/** aggregate stddev on columns */
export type ThirdPartyStddevFields = {
  __typename?: 'third_party_stddev_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risksmart.third_party" */
export type ThirdPartyStddevOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_pop on columns */
export type ThirdPartyStddevPopFields = {
  __typename?: 'third_party_stddev_pop_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risksmart.third_party" */
export type ThirdPartyStddevPopOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate stddev_samp on columns */
export type ThirdPartyStddevSampFields = {
  __typename?: 'third_party_stddev_samp_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risksmart.third_party" */
export type ThirdPartyStddevSampOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Streaming cursor of the table "third_party" */
export type ThirdPartyStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThirdPartyStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThirdPartyStreamCursorValueInput = {
  Address?: InputMaybe<Scalars['String']['input']>;
  CityTown?: InputMaybe<Scalars['String']['input']>;
  CompaniesHouseNumber?: InputMaybe<Scalars['String']['input']>;
  CompanyDomain?: InputMaybe<Scalars['String']['input']>;
  CompanyName?: InputMaybe<Scalars['String']['input']>;
  ContactEmail?: InputMaybe<Scalars['String']['input']>;
  ContactName?: InputMaybe<Scalars['String']['input']>;
  Country?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Criticality?: InputMaybe<Scalars['Int']['input']>;
  CustomAttributeData?: InputMaybe<Scalars['jsonb']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  Postcode?: InputMaybe<Scalars['String']['input']>;
  PrimaryContactName?: InputMaybe<Scalars['String']['input']>;
  SequentialId?: InputMaybe<Scalars['Int']['input']>;
  Status?: InputMaybe<ThirdPartyStatusEnum>;
  Title?: InputMaybe<Scalars['String']['input']>;
  Type?: InputMaybe<ThirdPartyTypeEnum>;
};

/** aggregate sum on columns */
export type ThirdPartySumFields = {
  __typename?: 'third_party_sum_fields';
  Criticality?: Maybe<Scalars['Int']['output']>;
  SequentialId?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "risksmart.third_party" */
export type ThirdPartySumOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** columns and relationships of "risksmart.third_party_type" */
export type ThirdPartyType = {
  __typename?: 'third_party_type';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
  /** An array relationship */
  third_parties: Array<ThirdParty>;
  /** An aggregate relationship */
  third_parties_aggregate: ThirdPartyAggregate;
};


/** columns and relationships of "risksmart.third_party_type" */
export type ThirdPartyTypeThirdPartiesArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};


/** columns and relationships of "risksmart.third_party_type" */
export type ThirdPartyTypeThirdPartiesAggregateArgs = {
  distinct_on?: InputMaybe<Array<ThirdPartySelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<ThirdPartyOrderBy>>;
  where?: InputMaybe<ThirdPartyBoolExp>;
};

/** aggregated selection of "risksmart.third_party_type" */
export type ThirdPartyTypeAggregate = {
  __typename?: 'third_party_type_aggregate';
  aggregate?: Maybe<ThirdPartyTypeAggregateFields>;
  nodes: Array<ThirdPartyType>;
};

/** aggregate fields of "risksmart.third_party_type" */
export type ThirdPartyTypeAggregateFields = {
  __typename?: 'third_party_type_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<ThirdPartyTypeMaxFields>;
  min?: Maybe<ThirdPartyTypeMinFields>;
};


/** aggregate fields of "risksmart.third_party_type" */
export type ThirdPartyTypeAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<ThirdPartyTypeSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.third_party_type". All fields are combined with a logical 'AND'. */
export type ThirdPartyTypeBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<ThirdPartyTypeBoolExp>>;
  _not?: InputMaybe<ThirdPartyTypeBoolExp>;
  _or?: InputMaybe<Array<ThirdPartyTypeBoolExp>>;
  third_parties?: InputMaybe<ThirdPartyBoolExp>;
  third_parties_aggregate?: InputMaybe<ThirdPartyAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.third_party_type" */
export enum ThirdPartyTypeConstraint {
  /** unique or primary key constraint on columns "Value" */
  ThirdPartyTypePkey = 'third_party_type_pkey'
}

export enum ThirdPartyTypeEnum {
  /** Consultant */
  Consultant = 'consultant',
  /** Managed Service */
  ManagedService = 'managed_service',
  /** Outsource */
  Outsource = 'outsource',
  /** Partner */
  Partner = 'partner',
  /** Supplier */
  Supplier = 'supplier'
}

/** Boolean expression to compare columns of type "third_party_type_enum". All fields are combined with logical 'AND'. */
export type ThirdPartyTypeEnumComparisonExp = {
  _eq?: InputMaybe<ThirdPartyTypeEnum>;
  _in?: InputMaybe<Array<ThirdPartyTypeEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<ThirdPartyTypeEnum>;
  _nin?: InputMaybe<Array<ThirdPartyTypeEnum>>;
};

/** input type for inserting data into table "risksmart.third_party_type" */
export type ThirdPartyTypeInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
  third_parties?: InputMaybe<ThirdPartyArrRelInsertInput>;
};

/** aggregate max on columns */
export type ThirdPartyTypeMaxFields = {
  __typename?: 'third_party_type_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type ThirdPartyTypeMinFields = {
  __typename?: 'third_party_type_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.third_party_type" */
export type ThirdPartyTypeMutationResponse = {
  __typename?: 'third_party_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<ThirdPartyType>;
};

/** on_conflict condition type for table "risksmart.third_party_type" */
export type ThirdPartyTypeOnConflict = {
  constraint: ThirdPartyTypeConstraint;
  update_columns?: Array<ThirdPartyTypeUpdateColumn>;
  where?: InputMaybe<ThirdPartyTypeBoolExp>;
};

/** Ordering options when selecting data from "risksmart.third_party_type". */
export type ThirdPartyTypeOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
  third_parties_aggregate?: InputMaybe<ThirdPartyAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.third_party_type */
export type ThirdPartyTypePkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.third_party_type" */
export enum ThirdPartyTypeSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.third_party_type" */
export type ThirdPartyTypeSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "third_party_type" */
export type ThirdPartyTypeStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: ThirdPartyTypeStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type ThirdPartyTypeStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.third_party_type" */
export enum ThirdPartyTypeUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type ThirdPartyTypeUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThirdPartyTypeSetInput>;
  /** filter the rows which have to be updated */
  where: ThirdPartyTypeBoolExp;
};

/** update columns of table "risksmart.third_party" */
export enum ThirdPartyUpdateColumn {
  /** column name */
  Address = 'Address',
  /** column name */
  CityTown = 'CityTown',
  /** column name */
  CompaniesHouseNumber = 'CompaniesHouseNumber',
  /** column name */
  CompanyDomain = 'CompanyDomain',
  /** column name */
  CompanyName = 'CompanyName',
  /** column name */
  ContactEmail = 'ContactEmail',
  /** column name */
  ContactName = 'ContactName',
  /** column name */
  Country = 'Country',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Criticality = 'Criticality',
  /** column name */
  CustomAttributeData = 'CustomAttributeData',
  /** column name */
  Description = 'Description',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  Postcode = 'Postcode',
  /** column name */
  PrimaryContactName = 'PrimaryContactName',
  /** column name */
  SequentialId = 'SequentialId',
  /** column name */
  Status = 'Status',
  /** column name */
  Title = 'Title',
  /** column name */
  Type = 'Type'
}

export type ThirdPartyUpdates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<ThirdPartyAppendInput>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<ThirdPartyDeleteAtPathInput>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<ThirdPartyDeleteElemInput>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<ThirdPartyDeleteKeyInput>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<ThirdPartyIncInput>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<ThirdPartyPrependInput>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<ThirdPartySetInput>;
  /** filter the rows which have to be updated */
  where: ThirdPartyBoolExp;
};

/** aggregate var_pop on columns */
export type ThirdPartyVarPopFields = {
  __typename?: 'third_party_var_pop_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risksmart.third_party" */
export type ThirdPartyVarPopOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate var_samp on columns */
export type ThirdPartyVarSampFields = {
  __typename?: 'third_party_var_samp_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risksmart.third_party" */
export type ThirdPartyVarSampOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** aggregate variance on columns */
export type ThirdPartyVarianceFields = {
  __typename?: 'third_party_variance_fields';
  Criticality?: Maybe<Scalars['Float']['output']>;
  SequentialId?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risksmart.third_party" */
export type ThirdPartyVarianceOrderBy = {
  Criticality?: InputMaybe<OrderBy>;
  SequentialId?: InputMaybe<OrderBy>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type TimestamptzComparisonExp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "risksmart.user_view_active" */
export type User = {
  __typename?: 'user';
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  IsCustomerSupport?: Maybe<Scalars['Boolean']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  group_memberships: Array<UserGroupUser>;
  /** An aggregate relationship */
  group_memberships_aggregate: UserGroupUserAggregate;
  /** An object relationship */
  status?: Maybe<UserStatus>;
};


/** columns and relationships of "risksmart.user_view_active" */
export type UserGroupMembershipsArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


/** columns and relationships of "risksmart.user_view_active" */
export type UserGroupMembershipsAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};

/** aggregated selection of "risksmart.user_view_active" */
export type UserAggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<UserAggregateFields>;
  nodes: Array<User>;
};

/** aggregate fields of "risksmart.user_view_active" */
export type UserAggregateFields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserMaxFields>;
  min?: Maybe<UserMinFields>;
};


/** aggregate fields of "risksmart.user_view_active" */
export type UserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_view_active". All fields are combined with a logical 'AND'. */
export type UserBoolExp = {
  BusinessUnit_Id?: InputMaybe<UuidComparisonExp>;
  Department?: InputMaybe<StringComparisonExp>;
  Email?: InputMaybe<StringComparisonExp>;
  FirstName?: InputMaybe<StringComparisonExp>;
  FriendlyName?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<StringComparisonExp>;
  IsCustomerSupport?: InputMaybe<BooleanComparisonExp>;
  JobTitle?: InputMaybe<StringComparisonExp>;
  LastName?: InputMaybe<StringComparisonExp>;
  LastSeen?: InputMaybe<TimestamptzComparisonExp>;
  OfficeLocation?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RoleKey?: InputMaybe<StringComparisonExp>;
  Status?: InputMaybe<StringComparisonExp>;
  UserName?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<UserBoolExp>>;
  _not?: InputMaybe<UserBoolExp>;
  _or?: InputMaybe<Array<UserBoolExp>>;
  group_memberships?: InputMaybe<UserGroupUserBoolExp>;
  group_memberships_aggregate?: InputMaybe<UserGroupUserAggregateBoolExp>;
  status?: InputMaybe<UserStatusBoolExp>;
};

/** columns and relationships of "risksmart.user_group" */
export type UserGroup = {
  __typename?: 'user_group';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OwnerContributor: Scalars['Boolean']['output'];
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  modifiedByUser?: Maybe<User>;
  /** An object relationship */
  organisation: AuthOrganisation;
  /** An array relationship */
  users: Array<UserGroupUser>;
  /** An aggregate relationship */
  users_aggregate: UserGroupUserAggregate;
};


/** columns and relationships of "risksmart.user_group" */
export type UserGroupUsersArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};


/** columns and relationships of "risksmart.user_group" */
export type UserGroupUsersAggregateArgs = {
  distinct_on?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<UserGroupUserOrderBy>>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};

/** aggregated selection of "risksmart.user_group" */
export type UserGroupAggregate = {
  __typename?: 'user_group_aggregate';
  aggregate?: Maybe<UserGroupAggregateFields>;
  nodes: Array<UserGroup>;
};

/** aggregate fields of "risksmart.user_group" */
export type UserGroupAggregateFields = {
  __typename?: 'user_group_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserGroupMaxFields>;
  min?: Maybe<UserGroupMinFields>;
};


/** aggregate fields of "risksmart.user_group" */
export type UserGroupAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.user_group_audit" */
export type UserGroupAudit = {
  __typename?: 'user_group_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id: Scalars['uuid']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  OwnerContributor?: Maybe<Scalars['Boolean']['output']>;
};

/** aggregated selection of "risksmart.user_group_audit" */
export type UserGroupAuditAggregate = {
  __typename?: 'user_group_audit_aggregate';
  aggregate?: Maybe<UserGroupAuditAggregateFields>;
  nodes: Array<UserGroupAudit>;
};

/** aggregate fields of "risksmart.user_group_audit" */
export type UserGroupAuditAggregateFields = {
  __typename?: 'user_group_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserGroupAuditMaxFields>;
  min?: Maybe<UserGroupAuditMinFields>;
};


/** aggregate fields of "risksmart.user_group_audit" */
export type UserGroupAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_group_audit". All fields are combined with a logical 'AND'. */
export type UserGroupAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Email?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OwnerContributor?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<UserGroupAuditBoolExp>>;
  _not?: InputMaybe<UserGroupAuditBoolExp>;
  _or?: InputMaybe<Array<UserGroupAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.user_group_audit" */
export enum UserGroupAuditConstraint {
  /** unique or primary key constraint on columns "Id", "ModifiedAtTimestamp" */
  UserGroupAuditPkey = 'user_group_audit_pkey'
}

/** input type for inserting data into table "risksmart.user_group_audit" */
export type UserGroupAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type UserGroupAuditMaxFields = {
  __typename?: 'user_group_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserGroupAuditMinFields = {
  __typename?: 'user_group_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.user_group_audit" */
export type UserGroupAuditMutationResponse = {
  __typename?: 'user_group_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroupAudit>;
};

/** on_conflict condition type for table "risksmart.user_group_audit" */
export type UserGroupAuditOnConflict = {
  constraint: UserGroupAuditConstraint;
  update_columns?: Array<UserGroupAuditUpdateColumn>;
  where?: InputMaybe<UserGroupAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.user_group_audit". */
export type UserGroupAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Email?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OwnerContributor?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.user_group_audit */
export type UserGroupAuditPkColumnsInput = {
  Id: Scalars['uuid']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
};

/** select columns of table "risksmart.user_group_audit" */
export enum UserGroupAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Email = 'Email',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerContributor = 'OwnerContributor'
}

/** input type for updating data in table "risksmart.user_group_audit" */
export type UserGroupAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "user_group_audit" */
export type UserGroupAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.user_group_audit" */
export enum UserGroupAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Email = 'Email',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerContributor = 'OwnerContributor'
}

export type UserGroupAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupAuditSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.user_group". All fields are combined with a logical 'AND'. */
export type UserGroupBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  Description?: InputMaybe<StringComparisonExp>;
  Email?: InputMaybe<StringComparisonExp>;
  Id?: InputMaybe<UuidComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  Name?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  OwnerContributor?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<UserGroupBoolExp>>;
  _not?: InputMaybe<UserGroupBoolExp>;
  _or?: InputMaybe<Array<UserGroupBoolExp>>;
  createdByUser?: InputMaybe<UserBoolExp>;
  modifiedByUser?: InputMaybe<UserBoolExp>;
  organisation?: InputMaybe<AuthOrganisationBoolExp>;
  users?: InputMaybe<UserGroupUserBoolExp>;
  users_aggregate?: InputMaybe<UserGroupUserAggregateBoolExp>;
};

/** unique or primary key constraints on table "risksmart.user_group" */
export enum UserGroupConstraint {
  /** unique or primary key constraint on columns "Name", "OrgKey" */
  UniqueOrgkeyNameConstraint = 'unique_orgkey_name_constraint',
  /** unique or primary key constraint on columns "Id" */
  UserGroupPkey = 'user_group_pkey'
}

/** input type for inserting data into table "risksmart.user_group" */
export type UserGroupInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  modifiedByUser?: InputMaybe<UserObjRelInsertInput>;
  organisation?: InputMaybe<AuthOrganisationObjRelInsertInput>;
  users?: InputMaybe<UserGroupUserArrRelInsertInput>;
};

/** aggregate max on columns */
export type UserGroupMaxFields = {
  __typename?: 'user_group_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserGroupMinFields = {
  __typename?: 'user_group_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  Description?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['uuid']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  Name?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.user_group" */
export type UserGroupMutationResponse = {
  __typename?: 'user_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroup>;
};

/** input type for inserting object relation for remote table "risksmart.user_group" */
export type UserGroupObjRelInsertInput = {
  data: UserGroupInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupOnConflict>;
};

/** on_conflict condition type for table "risksmart.user_group" */
export type UserGroupOnConflict = {
  constraint: UserGroupConstraint;
  update_columns?: Array<UserGroupUpdateColumn>;
  where?: InputMaybe<UserGroupBoolExp>;
};

/** Ordering options when selecting data from "risksmart.user_group". */
export type UserGroupOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  Description?: InputMaybe<OrderBy>;
  Email?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  Name?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  OwnerContributor?: InputMaybe<OrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  modifiedByUser?: InputMaybe<UserOrderBy>;
  organisation?: InputMaybe<AuthOrganisationOrderBy>;
  users_aggregate?: InputMaybe<UserGroupUserAggregateOrderBy>;
};

/** primary key columns input for table: risksmart.user_group */
export type UserGroupPkColumnsInput = {
  Id: Scalars['uuid']['input'];
};

/** select columns of table "risksmart.user_group" */
export enum UserGroupSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Email = 'Email',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerContributor = 'OwnerContributor'
}

/** input type for updating data in table "risksmart.user_group" */
export type UserGroupSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "user_group" */
export type UserGroupStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  Description?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['uuid']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  Name?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  OwnerContributor?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.user_group" */
export enum UserGroupUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  Description = 'Description',
  /** column name */
  Email = 'Email',
  /** column name */
  Id = 'Id',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  Name = 'Name',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  OwnerContributor = 'OwnerContributor'
}

export type UserGroupUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupBoolExp;
};

/** columns and relationships of "risksmart.user_group_user" */
export type UserGroupUser = {
  __typename?: 'user_group_user';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  UserGroupId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
  /** An object relationship */
  authUsers: AuthUser;
  /** An object relationship */
  createdByUser?: Maybe<User>;
  /** An object relationship */
  userGroups: UserGroup;
};

/** aggregated selection of "risksmart.user_group_user" */
export type UserGroupUserAggregate = {
  __typename?: 'user_group_user_aggregate';
  aggregate?: Maybe<UserGroupUserAggregateFields>;
  nodes: Array<UserGroupUser>;
};

export type UserGroupUserAggregateBoolExp = {
  count?: InputMaybe<UserGroupUserAggregateBoolExpCount>;
};

export type UserGroupUserAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserGroupUserBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.user_group_user" */
export type UserGroupUserAggregateFields = {
  __typename?: 'user_group_user_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserGroupUserMaxFields>;
  min?: Maybe<UserGroupUserMinFields>;
};


/** aggregate fields of "risksmart.user_group_user" */
export type UserGroupUserAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupUserSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.user_group_user" */
export type UserGroupUserAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserGroupUserMaxOrderBy>;
  min?: InputMaybe<UserGroupUserMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.user_group_user" */
export type UserGroupUserArrRelInsertInput = {
  data: Array<UserGroupUserInsertInput>;
  /** upsert condition */
  on_conflict?: InputMaybe<UserGroupUserOnConflict>;
};

/** columns and relationships of "risksmart.user_group_user_audit" */
export type UserGroupUserAudit = {
  __typename?: 'user_group_user_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey: Scalars['String']['output'];
  UserGroupId: Scalars['uuid']['output'];
  UserId: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.user_group_user_audit" */
export type UserGroupUserAuditAggregate = {
  __typename?: 'user_group_user_audit_aggregate';
  aggregate?: Maybe<UserGroupUserAuditAggregateFields>;
  nodes: Array<UserGroupUserAudit>;
};

/** aggregate fields of "risksmart.user_group_user_audit" */
export type UserGroupUserAuditAggregateFields = {
  __typename?: 'user_group_user_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserGroupUserAuditMaxFields>;
  min?: Maybe<UserGroupUserAuditMinFields>;
};


/** aggregate fields of "risksmart.user_group_user_audit" */
export type UserGroupUserAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserGroupUserAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_group_user_audit". All fields are combined with a logical 'AND'. */
export type UserGroupUserAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<UserGroupUserAuditBoolExp>>;
  _not?: InputMaybe<UserGroupUserAuditBoolExp>;
  _or?: InputMaybe<Array<UserGroupUserAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.user_group_user_audit" */
export enum UserGroupUserAuditConstraint {
  /** unique or primary key constraint on columns "UserGroupId", "ModifiedAtTimestamp", "UserId" */
  UserGroupUserAuditPkey = 'user_group_user_audit_pkey'
}

/** input type for inserting data into table "risksmart.user_group_user_audit" */
export type UserGroupUserAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserGroupUserAuditMaxFields = {
  __typename?: 'user_group_user_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserGroupUserAuditMinFields = {
  __typename?: 'user_group_user_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.user_group_user_audit" */
export type UserGroupUserAuditMutationResponse = {
  __typename?: 'user_group_user_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroupUserAudit>;
};

/** on_conflict condition type for table "risksmart.user_group_user_audit" */
export type UserGroupUserAuditOnConflict = {
  constraint: UserGroupUserAuditConstraint;
  update_columns?: Array<UserGroupUserAuditUpdateColumn>;
  where?: InputMaybe<UserGroupUserAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.user_group_user_audit". */
export type UserGroupUserAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.user_group_user_audit */
export type UserGroupUserAuditPkColumnsInput = {
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};

/** select columns of table "risksmart.user_group_user_audit" */
export enum UserGroupUserAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.user_group_user_audit" */
export type UserGroupUserAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_group_user_audit" */
export type UserGroupUserAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupUserAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupUserAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.user_group_user_audit" */
export enum UserGroupUserAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

export type UserGroupUserAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupUserAuditSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupUserAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.user_group_user". All fields are combined with a logical 'AND'. */
export type UserGroupUserBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  UserGroupId?: InputMaybe<UuidComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<UserGroupUserBoolExp>>;
  _not?: InputMaybe<UserGroupUserBoolExp>;
  _or?: InputMaybe<Array<UserGroupUserBoolExp>>;
  authUsers?: InputMaybe<AuthUserBoolExp>;
  createdByUser?: InputMaybe<UserBoolExp>;
  userGroups?: InputMaybe<UserGroupBoolExp>;
};

/** unique or primary key constraints on table "risksmart.user_group_user" */
export enum UserGroupUserConstraint {
  /** unique or primary key constraint on columns "UserGroupId", "UserId" */
  UserGroupUserPkey = 'user_group_user_pkey'
}

/** input type for inserting data into table "risksmart.user_group_user" */
export type UserGroupUserInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
  authUsers?: InputMaybe<AuthUserObjRelInsertInput>;
  createdByUser?: InputMaybe<UserObjRelInsertInput>;
  userGroups?: InputMaybe<UserGroupObjRelInsertInput>;
};

/** aggregate max on columns */
export type UserGroupUserMaxFields = {
  __typename?: 'user_group_user_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.user_group_user" */
export type UserGroupUserMaxOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserGroupUserMinFields = {
  __typename?: 'user_group_user_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  UserGroupId?: Maybe<Scalars['uuid']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.user_group_user" */
export type UserGroupUserMinOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** response of any mutation on the table "risksmart.user_group_user" */
export type UserGroupUserMutationResponse = {
  __typename?: 'user_group_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserGroupUser>;
};

/** on_conflict condition type for table "risksmart.user_group_user" */
export type UserGroupUserOnConflict = {
  constraint: UserGroupUserConstraint;
  update_columns?: Array<UserGroupUserUpdateColumn>;
  where?: InputMaybe<UserGroupUserBoolExp>;
};

/** Ordering options when selecting data from "risksmart.user_group_user". */
export type UserGroupUserOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  UserGroupId?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
  authUsers?: InputMaybe<AuthUserOrderBy>;
  createdByUser?: InputMaybe<UserOrderBy>;
  userGroups?: InputMaybe<UserGroupOrderBy>;
};

/** primary key columns input for table: risksmart.user_group_user */
export type UserGroupUserPkColumnsInput = {
  UserGroupId: Scalars['uuid']['input'];
  UserId: Scalars['String']['input'];
};

/** select columns of table "risksmart.user_group_user" */
export enum UserGroupUserSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

/** input type for updating data in table "risksmart.user_group_user" */
export type UserGroupUserSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_group_user" */
export type UserGroupUserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserGroupUserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserGroupUserStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  UserGroupId?: InputMaybe<Scalars['uuid']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.user_group_user" */
export enum UserGroupUserUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  UserGroupId = 'UserGroupId',
  /** column name */
  UserId = 'UserId'
}

export type UserGroupUserUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserGroupUserSetInput>;
  /** filter the rows which have to be updated */
  where: UserGroupUserBoolExp;
};

/** input type for inserting data into table "risksmart.user_view_active" */
export type UserInsertInput = {
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FriendlyName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  IsCustomerSupport?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
  group_memberships?: InputMaybe<UserGroupUserArrRelInsertInput>;
  status?: InputMaybe<UserStatusObjRelInsertInput>;
};

/** aggregate max on columns */
export type UserMaxFields = {
  __typename?: 'user_max_fields';
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserMinFields = {
  __typename?: 'user_min_fields';
  BusinessUnit_Id?: Maybe<Scalars['uuid']['output']>;
  Department?: Maybe<Scalars['String']['output']>;
  Email?: Maybe<Scalars['String']['output']>;
  FirstName?: Maybe<Scalars['String']['output']>;
  FriendlyName?: Maybe<Scalars['String']['output']>;
  Id?: Maybe<Scalars['String']['output']>;
  JobTitle?: Maybe<Scalars['String']['output']>;
  LastName?: Maybe<Scalars['String']['output']>;
  LastSeen?: Maybe<Scalars['timestamptz']['output']>;
  OfficeLocation?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RoleKey?: Maybe<Scalars['String']['output']>;
  Status?: Maybe<Scalars['String']['output']>;
  UserName?: Maybe<Scalars['String']['output']>;
};

/** input type for inserting object relation for remote table "risksmart.user_view_active" */
export type UserObjRelInsertInput = {
  data: UserInsertInput;
};

/** Ordering options when selecting data from "risksmart.user_view_active". */
export type UserOrderBy = {
  BusinessUnit_Id?: InputMaybe<OrderBy>;
  Department?: InputMaybe<OrderBy>;
  Email?: InputMaybe<OrderBy>;
  FirstName?: InputMaybe<OrderBy>;
  FriendlyName?: InputMaybe<OrderBy>;
  Id?: InputMaybe<OrderBy>;
  IsCustomerSupport?: InputMaybe<OrderBy>;
  JobTitle?: InputMaybe<OrderBy>;
  LastName?: InputMaybe<OrderBy>;
  LastSeen?: InputMaybe<OrderBy>;
  OfficeLocation?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RoleKey?: InputMaybe<OrderBy>;
  Status?: InputMaybe<OrderBy>;
  UserName?: InputMaybe<OrderBy>;
  group_memberships_aggregate?: InputMaybe<UserGroupUserAggregateOrderBy>;
  status?: InputMaybe<UserStatusOrderBy>;
};

/** columns and relationships of "risksmart.user_role_access" */
export type UserRoleAccess = {
  __typename?: 'user_role_access';
  AccessType?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "risksmart.user_role_access" */
export type UserRoleAccessAggregate = {
  __typename?: 'user_role_access_aggregate';
  aggregate?: Maybe<UserRoleAccessAggregateFields>;
  nodes: Array<UserRoleAccess>;
};

export type UserRoleAccessAggregateBoolExp = {
  count?: InputMaybe<UserRoleAccessAggregateBoolExpCount>;
};

export type UserRoleAccessAggregateBoolExpCount = {
  arguments?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<UserRoleAccessBoolExp>;
  predicate: IntComparisonExp;
};

/** aggregate fields of "risksmart.user_role_access" */
export type UserRoleAccessAggregateFields = {
  __typename?: 'user_role_access_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserRoleAccessMaxFields>;
  min?: Maybe<UserRoleAccessMinFields>;
};


/** aggregate fields of "risksmart.user_role_access" */
export type UserRoleAccessAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserRoleAccessSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risksmart.user_role_access" */
export type UserRoleAccessAggregateOrderBy = {
  count?: InputMaybe<OrderBy>;
  max?: InputMaybe<UserRoleAccessMaxOrderBy>;
  min?: InputMaybe<UserRoleAccessMinOrderBy>;
};

/** input type for inserting array relation for remote table "risksmart.user_role_access" */
export type UserRoleAccessArrRelInsertInput = {
  data: Array<UserRoleAccessInsertInput>;
};

/** Boolean expression to filter rows from the table "risksmart.user_role_access". All fields are combined with a logical 'AND'. */
export type UserRoleAccessBoolExp = {
  AccessType?: InputMaybe<StringComparisonExp>;
  ObjectType?: InputMaybe<StringComparisonExp>;
  UserId?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<UserRoleAccessBoolExp>>;
  _not?: InputMaybe<UserRoleAccessBoolExp>;
  _or?: InputMaybe<Array<UserRoleAccessBoolExp>>;
};

/** input type for inserting data into table "risksmart.user_role_access" */
export type UserRoleAccessInsertInput = {
  AccessType?: InputMaybe<Scalars['String']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserRoleAccessMaxFields = {
  __typename?: 'user_role_access_max_fields';
  AccessType?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "risksmart.user_role_access" */
export type UserRoleAccessMaxOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** aggregate min on columns */
export type UserRoleAccessMinFields = {
  __typename?: 'user_role_access_min_fields';
  AccessType?: Maybe<Scalars['String']['output']>;
  ObjectType?: Maybe<Scalars['String']['output']>;
  UserId?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "risksmart.user_role_access" */
export type UserRoleAccessMinOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** Ordering options when selecting data from "risksmart.user_role_access". */
export type UserRoleAccessOrderBy = {
  AccessType?: InputMaybe<OrderBy>;
  ObjectType?: InputMaybe<OrderBy>;
  UserId?: InputMaybe<OrderBy>;
};

/** select columns of table "risksmart.user_role_access" */
export enum UserRoleAccessSelectColumn {
  /** column name */
  AccessType = 'AccessType',
  /** column name */
  ObjectType = 'ObjectType',
  /** column name */
  UserId = 'UserId'
}

/** Streaming cursor of the table "user_role_access" */
export type UserRoleAccessStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserRoleAccessStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserRoleAccessStreamCursorValueInput = {
  AccessType?: InputMaybe<Scalars['String']['input']>;
  ObjectType?: InputMaybe<Scalars['String']['input']>;
  UserId?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "risksmart.user_search_preferences" */
export type UserSearchPreferences = {
  __typename?: 'user_search_preferences';
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FilterByActivePlatformUsers: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RecentUserIds: Array<Scalars['String']['output']>;
  ShowArchivedUsers: Scalars['Boolean']['output'];
  ShowDirectoryDepartment: Scalars['Boolean']['output'];
  ShowGroups: Scalars['Boolean']['output'];
  ShowUserEmail: Scalars['Boolean']['output'];
  ShowUserJobTitle: Scalars['Boolean']['output'];
  ShowUserLocation: Scalars['Boolean']['output'];
  ShowUserPlatformRole: Scalars['Boolean']['output'];
};

/** aggregated selection of "risksmart.user_search_preferences" */
export type UserSearchPreferencesAggregate = {
  __typename?: 'user_search_preferences_aggregate';
  aggregate?: Maybe<UserSearchPreferencesAggregateFields>;
  nodes: Array<UserSearchPreferences>;
};

/** aggregate fields of "risksmart.user_search_preferences" */
export type UserSearchPreferencesAggregateFields = {
  __typename?: 'user_search_preferences_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserSearchPreferencesMaxFields>;
  min?: Maybe<UserSearchPreferencesMinFields>;
};


/** aggregate fields of "risksmart.user_search_preferences" */
export type UserSearchPreferencesAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSearchPreferencesSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** columns and relationships of "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAudit = {
  __typename?: 'user_search_preferences_audit';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp: Scalars['timestamptz']['output'];
  CreatedByUser: Scalars['String']['output'];
  FilterByActivePlatformUsers: Scalars['Boolean']['output'];
  ModifiedAtTimestamp: Scalars['timestamptz']['output'];
  ModifiedByUser: Scalars['String']['output'];
  OrgKey: Scalars['String']['output'];
  RecentUserIds: Array<Scalars['String']['output']>;
  ShowArchivedUsers: Scalars['Boolean']['output'];
  ShowDirectoryDepartment: Scalars['Boolean']['output'];
  ShowGroups: Scalars['Boolean']['output'];
  ShowUserEmail: Scalars['Boolean']['output'];
  ShowUserJobTitle: Scalars['Boolean']['output'];
  ShowUserLocation: Scalars['Boolean']['output'];
  ShowUserPlatformRole: Scalars['Boolean']['output'];
};

/** aggregated selection of "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditAggregate = {
  __typename?: 'user_search_preferences_audit_aggregate';
  aggregate?: Maybe<UserSearchPreferencesAuditAggregateFields>;
  nodes: Array<UserSearchPreferencesAudit>;
};

/** aggregate fields of "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditAggregateFields = {
  __typename?: 'user_search_preferences_audit_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserSearchPreferencesAuditMaxFields>;
  min?: Maybe<UserSearchPreferencesAuditMinFields>;
};


/** aggregate fields of "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserSearchPreferencesAuditSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.user_search_preferences_audit". All fields are combined with a logical 'AND'. */
export type UserSearchPreferencesAuditBoolExp = {
  Action?: InputMaybe<StringComparisonExp>;
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FilterByActivePlatformUsers?: InputMaybe<BooleanComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RecentUserIds?: InputMaybe<StringArrayComparisonExp>;
  ShowArchivedUsers?: InputMaybe<BooleanComparisonExp>;
  ShowDirectoryDepartment?: InputMaybe<BooleanComparisonExp>;
  ShowGroups?: InputMaybe<BooleanComparisonExp>;
  ShowUserEmail?: InputMaybe<BooleanComparisonExp>;
  ShowUserJobTitle?: InputMaybe<BooleanComparisonExp>;
  ShowUserLocation?: InputMaybe<BooleanComparisonExp>;
  ShowUserPlatformRole?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<UserSearchPreferencesAuditBoolExp>>;
  _not?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
  _or?: InputMaybe<Array<UserSearchPreferencesAuditBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.user_search_preferences_audit" */
export enum UserSearchPreferencesAuditConstraint {
  /** unique or primary key constraint on columns "CreatedByUser", "OrgKey", "ModifiedAtTimestamp" */
  RecentUsersAuditPkey = 'recent_users_audit_pkey'
}

/** input type for inserting data into table "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditInsertInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type UserSearchPreferencesAuditMaxFields = {
  __typename?: 'user_search_preferences_audit_max_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RecentUserIds?: Maybe<Array<Scalars['String']['output']>>;
};

/** aggregate min on columns */
export type UserSearchPreferencesAuditMinFields = {
  __typename?: 'user_search_preferences_audit_min_fields';
  Action?: Maybe<Scalars['String']['output']>;
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RecentUserIds?: Maybe<Array<Scalars['String']['output']>>;
};

/** response of any mutation on the table "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditMutationResponse = {
  __typename?: 'user_search_preferences_audit_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSearchPreferencesAudit>;
};

/** on_conflict condition type for table "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditOnConflict = {
  constraint: UserSearchPreferencesAuditConstraint;
  update_columns?: Array<UserSearchPreferencesAuditUpdateColumn>;
  where?: InputMaybe<UserSearchPreferencesAuditBoolExp>;
};

/** Ordering options when selecting data from "risksmart.user_search_preferences_audit". */
export type UserSearchPreferencesAuditOrderBy = {
  Action?: InputMaybe<OrderBy>;
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FilterByActivePlatformUsers?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RecentUserIds?: InputMaybe<OrderBy>;
  ShowArchivedUsers?: InputMaybe<OrderBy>;
  ShowDirectoryDepartment?: InputMaybe<OrderBy>;
  ShowGroups?: InputMaybe<OrderBy>;
  ShowUserEmail?: InputMaybe<OrderBy>;
  ShowUserJobTitle?: InputMaybe<OrderBy>;
  ShowUserLocation?: InputMaybe<OrderBy>;
  ShowUserPlatformRole?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.user_search_preferences_audit */
export type UserSearchPreferencesAuditPkColumnsInput = {
  CreatedByUser: Scalars['String']['input'];
  ModifiedAtTimestamp: Scalars['timestamptz']['input'];
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.user_search_preferences_audit" */
export enum UserSearchPreferencesAuditSelectColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FilterByActivePlatformUsers = 'FilterByActivePlatformUsers',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RecentUserIds = 'RecentUserIds',
  /** column name */
  ShowArchivedUsers = 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment = 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups = 'ShowGroups',
  /** column name */
  ShowUserEmail = 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle = 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation = 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole = 'ShowUserPlatformRole'
}

/** input type for updating data in table "risksmart.user_search_preferences_audit" */
export type UserSearchPreferencesAuditSetInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "user_search_preferences_audit" */
export type UserSearchPreferencesAuditStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserSearchPreferencesAuditStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserSearchPreferencesAuditStreamCursorValueInput = {
  Action?: InputMaybe<Scalars['String']['input']>;
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.user_search_preferences_audit" */
export enum UserSearchPreferencesAuditUpdateColumn {
  /** column name */
  Action = 'Action',
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FilterByActivePlatformUsers = 'FilterByActivePlatformUsers',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RecentUserIds = 'RecentUserIds',
  /** column name */
  ShowArchivedUsers = 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment = 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups = 'ShowGroups',
  /** column name */
  ShowUserEmail = 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle = 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation = 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole = 'ShowUserPlatformRole'
}

export type UserSearchPreferencesAuditUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSearchPreferencesAuditSetInput>;
  /** filter the rows which have to be updated */
  where: UserSearchPreferencesAuditBoolExp;
};

/** Boolean expression to filter rows from the table "risksmart.user_search_preferences". All fields are combined with a logical 'AND'. */
export type UserSearchPreferencesBoolExp = {
  CreatedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  CreatedByUser?: InputMaybe<StringComparisonExp>;
  FilterByActivePlatformUsers?: InputMaybe<BooleanComparisonExp>;
  ModifiedAtTimestamp?: InputMaybe<TimestamptzComparisonExp>;
  ModifiedByUser?: InputMaybe<StringComparisonExp>;
  OrgKey?: InputMaybe<StringComparisonExp>;
  RecentUserIds?: InputMaybe<StringArrayComparisonExp>;
  ShowArchivedUsers?: InputMaybe<BooleanComparisonExp>;
  ShowDirectoryDepartment?: InputMaybe<BooleanComparisonExp>;
  ShowGroups?: InputMaybe<BooleanComparisonExp>;
  ShowUserEmail?: InputMaybe<BooleanComparisonExp>;
  ShowUserJobTitle?: InputMaybe<BooleanComparisonExp>;
  ShowUserLocation?: InputMaybe<BooleanComparisonExp>;
  ShowUserPlatformRole?: InputMaybe<BooleanComparisonExp>;
  _and?: InputMaybe<Array<UserSearchPreferencesBoolExp>>;
  _not?: InputMaybe<UserSearchPreferencesBoolExp>;
  _or?: InputMaybe<Array<UserSearchPreferencesBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.user_search_preferences" */
export enum UserSearchPreferencesConstraint {
  /** unique or primary key constraint on columns "CreatedByUser", "OrgKey" */
  RecentUsersPkey = 'recent_users_pkey'
}

/** input type for inserting data into table "risksmart.user_search_preferences" */
export type UserSearchPreferencesInsertInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate max on columns */
export type UserSearchPreferencesMaxFields = {
  __typename?: 'user_search_preferences_max_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RecentUserIds?: Maybe<Array<Scalars['String']['output']>>;
};

/** aggregate min on columns */
export type UserSearchPreferencesMinFields = {
  __typename?: 'user_search_preferences_min_fields';
  CreatedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  CreatedByUser?: Maybe<Scalars['String']['output']>;
  ModifiedAtTimestamp?: Maybe<Scalars['timestamptz']['output']>;
  ModifiedByUser?: Maybe<Scalars['String']['output']>;
  OrgKey?: Maybe<Scalars['String']['output']>;
  RecentUserIds?: Maybe<Array<Scalars['String']['output']>>;
};

/** response of any mutation on the table "risksmart.user_search_preferences" */
export type UserSearchPreferencesMutationResponse = {
  __typename?: 'user_search_preferences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserSearchPreferences>;
};

/** on_conflict condition type for table "risksmart.user_search_preferences" */
export type UserSearchPreferencesOnConflict = {
  constraint: UserSearchPreferencesConstraint;
  update_columns?: Array<UserSearchPreferencesUpdateColumn>;
  where?: InputMaybe<UserSearchPreferencesBoolExp>;
};

/** Ordering options when selecting data from "risksmart.user_search_preferences". */
export type UserSearchPreferencesOrderBy = {
  CreatedAtTimestamp?: InputMaybe<OrderBy>;
  CreatedByUser?: InputMaybe<OrderBy>;
  FilterByActivePlatformUsers?: InputMaybe<OrderBy>;
  ModifiedAtTimestamp?: InputMaybe<OrderBy>;
  ModifiedByUser?: InputMaybe<OrderBy>;
  OrgKey?: InputMaybe<OrderBy>;
  RecentUserIds?: InputMaybe<OrderBy>;
  ShowArchivedUsers?: InputMaybe<OrderBy>;
  ShowDirectoryDepartment?: InputMaybe<OrderBy>;
  ShowGroups?: InputMaybe<OrderBy>;
  ShowUserEmail?: InputMaybe<OrderBy>;
  ShowUserJobTitle?: InputMaybe<OrderBy>;
  ShowUserLocation?: InputMaybe<OrderBy>;
  ShowUserPlatformRole?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.user_search_preferences */
export type UserSearchPreferencesPkColumnsInput = {
  CreatedByUser: Scalars['String']['input'];
  OrgKey: Scalars['String']['input'];
};

/** select columns of table "risksmart.user_search_preferences" */
export enum UserSearchPreferencesSelectColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FilterByActivePlatformUsers = 'FilterByActivePlatformUsers',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RecentUserIds = 'RecentUserIds',
  /** column name */
  ShowArchivedUsers = 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment = 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups = 'ShowGroups',
  /** column name */
  ShowUserEmail = 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle = 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation = 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole = 'ShowUserPlatformRole'
}

/** input type for updating data in table "risksmart.user_search_preferences" */
export type UserSearchPreferencesSetInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Streaming cursor of the table "user_search_preferences" */
export type UserSearchPreferencesStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserSearchPreferencesStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserSearchPreferencesStreamCursorValueInput = {
  CreatedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  CreatedByUser?: InputMaybe<Scalars['String']['input']>;
  FilterByActivePlatformUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ModifiedAtTimestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  ModifiedByUser?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RecentUserIds?: InputMaybe<Array<Scalars['String']['input']>>;
  ShowArchivedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  ShowDirectoryDepartment?: InputMaybe<Scalars['Boolean']['input']>;
  ShowGroups?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserJobTitle?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserLocation?: InputMaybe<Scalars['Boolean']['input']>;
  ShowUserPlatformRole?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "risksmart.user_search_preferences" */
export enum UserSearchPreferencesUpdateColumn {
  /** column name */
  CreatedAtTimestamp = 'CreatedAtTimestamp',
  /** column name */
  CreatedByUser = 'CreatedByUser',
  /** column name */
  FilterByActivePlatformUsers = 'FilterByActivePlatformUsers',
  /** column name */
  ModifiedAtTimestamp = 'ModifiedAtTimestamp',
  /** column name */
  ModifiedByUser = 'ModifiedByUser',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RecentUserIds = 'RecentUserIds',
  /** column name */
  ShowArchivedUsers = 'ShowArchivedUsers',
  /** column name */
  ShowDirectoryDepartment = 'ShowDirectoryDepartment',
  /** column name */
  ShowGroups = 'ShowGroups',
  /** column name */
  ShowUserEmail = 'ShowUserEmail',
  /** column name */
  ShowUserJobTitle = 'ShowUserJobTitle',
  /** column name */
  ShowUserLocation = 'ShowUserLocation',
  /** column name */
  ShowUserPlatformRole = 'ShowUserPlatformRole'
}

export type UserSearchPreferencesUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserSearchPreferencesSetInput>;
  /** filter the rows which have to be updated */
  where: UserSearchPreferencesBoolExp;
};

/** select columns of table "risksmart.user_view_active" */
export enum UserSelectColumn {
  /** column name */
  BusinessUnitId = 'BusinessUnit_Id',
  /** column name */
  Department = 'Department',
  /** column name */
  Email = 'Email',
  /** column name */
  FirstName = 'FirstName',
  /** column name */
  FriendlyName = 'FriendlyName',
  /** column name */
  Id = 'Id',
  /** column name */
  IsCustomerSupport = 'IsCustomerSupport',
  /** column name */
  JobTitle = 'JobTitle',
  /** column name */
  LastName = 'LastName',
  /** column name */
  LastSeen = 'LastSeen',
  /** column name */
  OfficeLocation = 'OfficeLocation',
  /** column name */
  OrgKey = 'OrgKey',
  /** column name */
  RoleKey = 'RoleKey',
  /** column name */
  Status = 'Status',
  /** column name */
  UserName = 'UserName'
}

/** columns and relationships of "auth.user_status" */
export type UserStatus = {
  __typename?: 'user_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "auth.user_status" */
export type UserStatusAggregate = {
  __typename?: 'user_status_aggregate';
  aggregate?: Maybe<UserStatusAggregateFields>;
  nodes: Array<UserStatus>;
};

/** aggregate fields of "auth.user_status" */
export type UserStatusAggregateFields = {
  __typename?: 'user_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<UserStatusMaxFields>;
  min?: Maybe<UserStatusMinFields>;
};


/** aggregate fields of "auth.user_status" */
export type UserStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<UserStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "auth.user_status". All fields are combined with a logical 'AND'. */
export type UserStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<UserStatusBoolExp>>;
  _not?: InputMaybe<UserStatusBoolExp>;
  _or?: InputMaybe<Array<UserStatusBoolExp>>;
};

/** unique or primary key constraints on table "auth.user_status" */
export enum UserStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  UserStatusPkey = 'user_status_pkey'
}

export enum UserStatusEnum {
  /** Active */
  Active = 'active',
  /** Archived */
  Archived = 'archived'
}

/** Boolean expression to compare columns of type "user_status_enum". All fields are combined with logical 'AND'. */
export type UserStatusEnumComparisonExp = {
  _eq?: InputMaybe<UserStatusEnum>;
  _in?: InputMaybe<Array<UserStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<UserStatusEnum>;
  _nin?: InputMaybe<Array<UserStatusEnum>>;
};

/** input type for inserting data into table "auth.user_status" */
export type UserStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type UserStatusMaxFields = {
  __typename?: 'user_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type UserStatusMinFields = {
  __typename?: 'user_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "auth.user_status" */
export type UserStatusMutationResponse = {
  __typename?: 'user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<UserStatus>;
};

/** input type for inserting object relation for remote table "auth.user_status" */
export type UserStatusObjRelInsertInput = {
  data: UserStatusInsertInput;
  /** upsert condition */
  on_conflict?: InputMaybe<UserStatusOnConflict>;
};

/** on_conflict condition type for table "auth.user_status" */
export type UserStatusOnConflict = {
  constraint: UserStatusConstraint;
  update_columns?: Array<UserStatusUpdateColumn>;
  where?: InputMaybe<UserStatusBoolExp>;
};

/** Ordering options when selecting data from "auth.user_status". */
export type UserStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: auth.user_status */
export type UserStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "auth.user_status" */
export enum UserStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "auth.user_status" */
export type UserStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "user_status" */
export type UserStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "auth.user_status" */
export enum UserStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type UserStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<UserStatusSetInput>;
  /** filter the rows which have to be updated */
  where: UserStatusBoolExp;
};

/** Streaming cursor of the table "user" */
export type UserStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: UserStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type UserStreamCursorValueInput = {
  BusinessUnit_Id?: InputMaybe<Scalars['uuid']['input']>;
  Department?: InputMaybe<Scalars['String']['input']>;
  Email?: InputMaybe<Scalars['String']['input']>;
  FirstName?: InputMaybe<Scalars['String']['input']>;
  FriendlyName?: InputMaybe<Scalars['String']['input']>;
  Id?: InputMaybe<Scalars['String']['input']>;
  IsCustomerSupport?: InputMaybe<Scalars['Boolean']['input']>;
  JobTitle?: InputMaybe<Scalars['String']['input']>;
  LastName?: InputMaybe<Scalars['String']['input']>;
  LastSeen?: InputMaybe<Scalars['timestamptz']['input']>;
  OfficeLocation?: InputMaybe<Scalars['String']['input']>;
  OrgKey?: InputMaybe<Scalars['String']['input']>;
  RoleKey?: InputMaybe<Scalars['String']['input']>;
  Status?: InputMaybe<Scalars['String']['input']>;
  UserName?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type UuidComparisonExp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "risksmart.version_status" */
export type VersionStatus = {
  __typename?: 'version_status';
  Comment?: Maybe<Scalars['String']['output']>;
  Value: Scalars['String']['output'];
};

/** aggregated selection of "risksmart.version_status" */
export type VersionStatusAggregate = {
  __typename?: 'version_status_aggregate';
  aggregate?: Maybe<VersionStatusAggregateFields>;
  nodes: Array<VersionStatus>;
};

/** aggregate fields of "risksmart.version_status" */
export type VersionStatusAggregateFields = {
  __typename?: 'version_status_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<VersionStatusMaxFields>;
  min?: Maybe<VersionStatusMinFields>;
};


/** aggregate fields of "risksmart.version_status" */
export type VersionStatusAggregateFieldsCountArgs = {
  columns?: InputMaybe<Array<VersionStatusSelectColumn>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "risksmart.version_status". All fields are combined with a logical 'AND'. */
export type VersionStatusBoolExp = {
  Comment?: InputMaybe<StringComparisonExp>;
  Value?: InputMaybe<StringComparisonExp>;
  _and?: InputMaybe<Array<VersionStatusBoolExp>>;
  _not?: InputMaybe<VersionStatusBoolExp>;
  _or?: InputMaybe<Array<VersionStatusBoolExp>>;
};

/** unique or primary key constraints on table "risksmart.version_status" */
export enum VersionStatusConstraint {
  /** unique or primary key constraint on columns "Value" */
  VersionStatusPkey = 'version_status_pkey'
}

export enum VersionStatusEnum {
  /** Archived */
  Archived = 'archived',
  /** Draft */
  Draft = 'draft',
  /** Pending Approval */
  PendingApproval = 'pending_approval',
  /** Published */
  Published = 'published'
}

/** Boolean expression to compare columns of type "version_status_enum". All fields are combined with logical 'AND'. */
export type VersionStatusEnumComparisonExp = {
  _eq?: InputMaybe<VersionStatusEnum>;
  _in?: InputMaybe<Array<VersionStatusEnum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<VersionStatusEnum>;
  _nin?: InputMaybe<Array<VersionStatusEnum>>;
};

/** input type for inserting data into table "risksmart.version_status" */
export type VersionStatusInsertInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type VersionStatusMaxFields = {
  __typename?: 'version_status_max_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type VersionStatusMinFields = {
  __typename?: 'version_status_min_fields';
  Comment?: Maybe<Scalars['String']['output']>;
  Value?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "risksmart.version_status" */
export type VersionStatusMutationResponse = {
  __typename?: 'version_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<VersionStatus>;
};

/** on_conflict condition type for table "risksmart.version_status" */
export type VersionStatusOnConflict = {
  constraint: VersionStatusConstraint;
  update_columns?: Array<VersionStatusUpdateColumn>;
  where?: InputMaybe<VersionStatusBoolExp>;
};

/** Ordering options when selecting data from "risksmart.version_status". */
export type VersionStatusOrderBy = {
  Comment?: InputMaybe<OrderBy>;
  Value?: InputMaybe<OrderBy>;
};

/** primary key columns input for table: risksmart.version_status */
export type VersionStatusPkColumnsInput = {
  Value: Scalars['String']['input'];
};

/** select columns of table "risksmart.version_status" */
export enum VersionStatusSelectColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

/** input type for updating data in table "risksmart.version_status" */
export type VersionStatusSetInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "version_status" */
export type VersionStatusStreamCursorInput = {
  /** Stream column input with initial value */
  initial_value: VersionStatusStreamCursorValueInput;
  /** cursor ordering */
  ordering?: InputMaybe<CursorOrdering>;
};

/** Initial value of the column from where the streaming should start */
export type VersionStatusStreamCursorValueInput = {
  Comment?: InputMaybe<Scalars['String']['input']>;
  Value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "risksmart.version_status" */
export enum VersionStatusUpdateColumn {
  /** column name */
  Comment = 'Comment',
  /** column name */
  Value = 'Value'
}

export type VersionStatusUpdates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<VersionStatusSetInput>;
  /** filter the rows which have to be updated */
  where: VersionStatusBoolExp;
};
