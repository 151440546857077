import { useGetLinkedItemAuditQuery } from '@/generated/graphql';

import { getAuditItems } from './auditEntityFinder';
import AuditViewComponent from './AuditViewComponent';
import { AuditEntityRetrieverInput } from './types';

const LinkedItemAudit = (input: AuditEntityRetrieverInput) => {
  const { data: result } = useGetLinkedItemAuditQuery({
    variables: {
      Id: input.id,
    },
  });
  const { current, previous } = getAuditItems(
    result?.risksmart_linked_item_audit,
    input.operationDate
  );

  return (
    <AuditViewComponent
      operation={input.operation}
      current={JSON.stringify(current, null, 2)}
      previous={JSON.stringify(previous, null, 2)}
    />
  );
};

export default LinkedItemAudit;
