import { createContext, useContext } from 'react';

type WidgetContextState = {
  widgetId: string;
};

const WidgetContext = createContext<WidgetContextState | null>(null);

export const WidgetContextProvider = WidgetContext.Provider;

export const useWidgetContext = () => {
  return useContext(WidgetContext);
};
