export const getAuditItems = <T extends { ModifiedAtTimestamp: string }>(
  items: T[] | undefined,
  operationDate: string
) => {
  const current = items?.filter(
    (c) => c?.ModifiedAtTimestamp === operationDate
  )[0];
  const previous = items?.filter(
    (c) => c?.ModifiedAtTimestamp < operationDate
  )[0];

  return {
    current,
    previous,
  };
};
