import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { thirdPartyDetailsUrl } from '@/utils/urls';

import {
  ThirdPartyResponseFields,
  ThirdPartyResponseRegisterFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ThirdPartyResponseRegisterFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party_responses.columns',
  });

  const getStatus = useRating('third_party_response_status');

  return {
    ThirdPartyName: {
      header: st('thirdPartyTitle'),
      cell: (item) => (
        <Link
          variant="secondary"
          href={thirdPartyDetailsUrl(item.thirdParty!.Id)}
        >
          {item.ThirdPartyName}
        </Link>
      ),
    },
    QuestionnaireTitle: {
      header: st('questionnaireTitle'),
    },
    QuestionnaireVersion: {
      header: st('questionnaireVersion'),
    },
    StatusLabelled: {
      header: t('status'),
      cell: (item) => (
        <SimpleRatingBadge rating={getStatus.getByValue(item.Status)} />
      ),
    },
    Respondents: {
      header: st('respondent'),
      cell: (item) => item.Respondents,
    },
    CreatedByUser: {
      header: t('created_by_id'),
    },
    ModifiedByUser: {
      header: t('updated_by_id'),
    },
    ModifiedAtTimestamp: {
      header: t('updated_on'),
    },
  };
};

export const useGetThirdPartyTableProps = (
  records: ThirdPartyResponseFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ThirdPartyResponseRegisterFields> => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'third_party_responses',
  });
  const data = useLabelledFields(records);
  const fields = useGetFieldConfig();

  return useMemo(() => {
    return {
      data,
      fields,
      customAttributeSchema,
      entityLabel: st('entity_name'),
      enableFiltering: true,
      initialColumns: [
        'ThirdPartyName',
        'QuestionnaireTitle',
        'QuestionnaireVersion',
        'StatusLabelled',
        'Respondents',
      ],
      storageKey: 'ThirdPartyResponseTable-Preferences',
    };
  }, [st, data, fields, customAttributeSchema]);
};

export const useGetCollectionTableProps = (
  records: ThirdPartyResponseFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ThirdPartyResponseRegisterFields> => {
  const props = useGetThirdPartyTableProps(records, customAttributeSchema);

  return useGetTableProps(props);
};
