import { useApolloClient } from '@apollo/client';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import ActionModal from 'src/pages/actions/ActionModal';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { ownerAndContributorIds } from '@/components/Form';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  Parent_Type_Enum,
  useDeleteActionsMutation,
  useGetActionsByParentIdQuery,
  useInsertChildActionMutation,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { useActionsColumnDefinitions } from './actionTabConfig';
import { ActionFields } from './types';
import { ActionFormFieldData } from './update/forms/actionsSchema';

interface Props {
  parent: ObjectWithContributors;
}

const ActionsTab: FC<Props> = ({ parent }) => {
  useI18NSummaryHelpContent('actions.tabHelp');
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'actions',
  });
  const apolloClient = useApolloClient();
  const { updateFiles } = useFileUpdate();
  const canDeleteAction = useHasPermission('delete:action', parent);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedActions, setSelectedActions] = useState<ActionFields[]>([]);
  const { data, loading } = useGetActionsByParentIdQuery({
    variables: {
      ParentId: parent.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const [mutate] = useInsertChildActionMutation();

  const saveAction = async (data: ActionFormFieldData) => {
    const { files, newFiles } = data;
    const result = await mutate({
      variables: {
        ...data,
        ParentId: parent.Id,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
      },
    });
    const actionId = result.data?.insertChildAction?.Id;
    if (!actionId) {
      throw new Error('Missing actionId');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Action,
      parentId: actionId,
      newFiles,
      originalFiles: data?.files,
      selectedFiles: files,
    });
    evictField(apolloClient.cache, 'action');
    evictField(apolloClient.cache, 'action_aggregate');
    evictField(apolloClient.cache, 'internal_audit_entity');
  };

  const [deleteActions, deleteResult] = useDeleteActionsMutation({
    update: (cache) => {
      evictField(cache, 'action');
      evictField(cache, 'action_aggregate');
    },
  });

  const handleActionOpen = () => {
    setIsEditOpen(true);
  };

  const handleActionCreateClose = () => {
    setIsEditOpen(false);
  };

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteActions({
        variables: {
          Ids: selectedActions.map((s) => s.Id),
        },
      });
      setSelectedActions([]);
      setIsDeleteModalVisible(false);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  const columnDefinitions = useActionsColumnDefinitions();

  const { items, collectionProps } = useCollection(data?.action ?? [], {
    propertyFiltering: {
      filteringProperties: [],
      empty: (
        <EmptyEntityCollection
          entityLabel={st('entity_name')}
          action={
            <Permission permission="insert:action" parentObject={parent}>
              <Button formAction="none" onClick={handleActionOpen}>
                {st('add_button')}
              </Button>
            </Permission>
          }
        />
      ),
    },
    sorting: {
      defaultState: {
        sortingColumn: columnDefinitions[2],
        isDescending: true,
      },
    },
  });

  return (
    <>
      <Table
        {...collectionProps}
        selectionType={canDeleteAction ? 'multi' : undefined}
        selectedItems={selectedActions}
        trackBy="Id"
        onSelectionChange={({ detail }) => {
          setSelectedActions(detail.selectedItems);
        }}
        resizableColumns={true}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission permission="delete:action" parentObject={parent}>
                    <Button
                      formAction="none"
                      variant="normal"
                      disabled={!selectedActions.length}
                      onClick={() => setIsDeleteModalVisible(true)}
                    >
                      {t('delete')}
                    </Button>
                  </Permission>
                  <Permission permission="insert:action" parentObject={parent}>
                    <Button
                      variant="primary"
                      formAction="none"
                      onClick={handleActionOpen}
                    >
                      {st('add_button')}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {st('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
        columnDefinitions={columnDefinitions}
        items={items}
        loadingText={st('loading_message')}
        sortingDisabled={false}
      />
      {isEditOpen && (
        <ActionModal
          onSaving={saveAction}
          onDismiss={handleActionCreateClose}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default ActionsTab;
