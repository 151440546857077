import _ from 'lodash';
import { useMemo } from 'react';

import { useRating } from '@/hooks/use-rating';

import { getAllOwnersCellValue } from '../../rbac/contributorHelper';
import { UNRATED } from '../controls/lookupData';
import { CauseFlatField, CauseRegisterFields } from './types';

export const useLabelledFields = (
  records: CauseFlatField[] | undefined
): CauseRegisterFields[] | undefined => {
  const { getByValue } = useRating('significance');
  const status = useRating('issue_assessment_status');
  const severity = useRating('severity');

  return useMemo<CauseRegisterFields[] | undefined>(() => {
    return records?.map((d) => {
      return {
        ...d,
        ModifiedByUserName: d.modifiedByUser?.FriendlyName || '-',
        CreatedByUserName: d.createdByUser?.FriendlyName || '-',
        IssueTitle: d.issue?.Title ?? '-',
        IssueSequentialId: d.issue?.SequentialId ?? null,
        IssueStatus: d.issue?.assessment?.Status ?? null,
        IssueStatusLabelled: status.getLabel(
          d.issue?.assessment?.Status ?? null
        ),
        IssueRaisedDate: d.issue?.CreatedAtTimestamp ?? null,
        IssueClosedDate: d.issue?.assessment?.ActualCloseDate ?? null,
        IssueSeverity: d.issue?.assessment?.Severity ?? null,
        IssueSeverityLabelled:
          severity.getLabel(d.issue?.assessment?.Severity ?? null) ||
          UNRATED.label,
        SignificanceLabelled: getByValue(d.Significance)?.label ?? '-',
        allOwners: d.issue ? getAllOwnersCellValue(d.issue) : [],
      };
    });
  }, [getByValue, records, status, severity]);
};
