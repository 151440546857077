import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { handleError } from '@/utils/errorUtils';

export type StorageKeys =
  | 'AttestationRegisterTable-PreferencesV1'
  | 'ControlRegisterTable-PreferencesV2'
  | 'RiskRegisterTable-PreferencesV2'
  | 'ThirdPartyTable-PreferencesV1'
  | 'QuestionnaireTemplatesTable-PreferencesV1'
  | 'AppetiteRegisterTable-PreferencesV2'
  | 'AcceptanceRegisterTable-PreferencesV1'
  | 'ActionsRegisterTable-PreferencesV1'
  | 'IssuesRegisterTable-PreferencesV1'
  | 'Temp-PreferencesV1'
  | 'ControlGroupRegisterTable-PreferencesV1'
  | 'ObligationRegisterTable-PreferencesV1'
  | 'ControlTestRegisterTable-PreferencesV1'
  | 'Dashboard-Preferences'
  | 'Dashboard-PreferencesV3'
  | 'NavMenu-Preferences'
  | 'PolicyRegisterTable-Preferences'
  | 'ObligationAssessmentsRegisterTable-PreferencesV1'
  | 'DocumentAssessmentsRegisterTable-PreferencesV1'
  | 'IndicatorsRegisterTable-PreferencesV1'
  | 'MyItems-PreferencesV1'
  | 'AuditLogRegisterTable-PreferencesV2'
  | 'MyPolicies-Preferences'
  | 'AssessmentRegister-Preferences'
  | 'InternalAuditRegister-Preferences'
  | 'InternalAuditReportRegister-Preferences'
  | 'ComplianceMonitoringAssessmentRegister-Preferences'
  | 'AssessmentResults-Preferences'
  | 'AssessmentResultRegister-Preferences'
  | 'ComplianceMonitoringAssessmentResultRegister-Preferences'
  | 'InternalAuditReportResultRegister-Preferences'
  | 'ImpactRegisterTable-Preferences'
  | 'ImpactRatingRegisterTable-Preferences'
  | 'TagsSettingsTable-Preferences'
  | 'DepartmentsSettingsTable-Preferences'
  | 'GroupsSettingsTable-Preferences'
  | 'GroupMembersSettingsTable-Preferences'
  | 'AuthUsersRegisterTable-Preferences'
  | 'ConsequencesRegisterTable-PreferencesV1'
  | 'CausesRegisterTable-PreferencesV1'
  | 'GlobalApprovalRegisterTable-PreferencesV1'
  | 'RequestsRegisterTable-PreferencesV1'
  | 'LinkedItemsTable-PreferencesV1'
  | 'AssessmentActivitiesRegister-Preferences'
  | 'ImpactRatingsTable-Preferences'
  | 'ComplianceRiskRatingRegisterTable-Preferences'
  | 'InternalAuditRiskRatingRegisterTable-Preferences'
  | 'RiskRatingRegisterTable-Preferences'
  | 'ControlPerformanceRegisterTable-Preferences'
  | 'ControlComplianceMonitoringPerformanceRegisterTable-Preferences'
  | 'ControlInternalAuditPerformanceRegisterTable-Preferences'
  | 'DocumentRatingRegisterTable-Preferences'
  | 'ComplianceMonitoringDocumentRatingRegisterTable-Preferences'
  | 'InternalAuditDocumentRatingRegisterTable-Preferences'
  | 'ObligationRatingRegisterTable-Preferences'
  | 'ComplianceMonitoringObligationRatingRegisterTable-Preferences'
  | 'InternalAuditObligationRatingRegisterTable-Preferences'
  | 'Impact-PreferencesV1'
  | 'DataImportsRegisterTable-PreferencesV1'
  | 'DataImportErrorTable-PreferencesV1'
  | 'QuestionnaireInvitesTable-PreferencesV1'
  | 'ThirdPartyResponseTable-Preferences';

export function useStorage<T>(
  initialValue: T,
  { localStorageKey }: { localStorageKey?: StorageKeys }
): [T, Dispatch<SetStateAction<T>>] {
  const { user } = useRisksmartUser();

  // Create a key that is scoped to the organization
  // (non-scoped storageKey is still used as a fallback).
  const orgScopedStorageKey = useMemo(() => {
    if (user?.orgKey) {
      return `${localStorageKey}-${user.orgKey}`;
    }

    return null;
  }, [user, localStorageKey]);

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === 'undefined' || !localStorageKey) {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item =
        window.localStorage.getItem(orgScopedStorageKey ?? localStorageKey) ??
        window.localStorage.getItem(localStorageKey);

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      handleError(error);

      // If error also return initialValue
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((arg0: T) => T)): void => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValue) : value;
        // Save state
        setStoredValue(valueToStore);
        // Save to local storage
        if (typeof window !== 'undefined' && localStorageKey) {
          window.localStorage.setItem(
            orgScopedStorageKey ?? localStorageKey,
            JSON.stringify(valueToStore)
          );
        }
      } catch (error) {
        handleError(error);
        // A more advanced implementation would handle the error case
      }
    },
    [orgScopedStorageKey, localStorageKey, storedValue]
  );

  return [storedValue, setValue];
}
