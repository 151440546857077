import { PropsWithChildren, useState } from 'react';

import { EditFieldModal } from '../../EditFieldModal/EditFieldModal';
import { EditFieldModalProps } from '../../EditFieldModal/EditFieldModalProps';
import { EditableFormContext } from './EditableFormContext';

export const EditableFormProvider = ({ children }: PropsWithChildren) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [editFieldModalProps, setEditFieldModalProps] =
    useState<Omit<EditFieldModalProps, 'onDismiss'>>();

  const showEditModalWithProps = (
    props: Omit<EditFieldModalProps, 'onDismiss'>
  ) => {
    setEditFieldModalProps(props);
    setShowEditModal(true);
  };

  return (
    <EditableFormContext.Provider
      value={{
        showEditModal: showEditModalWithProps,
      }}
    >
      {children}
      {editFieldModalProps && showEditModal && (
        <EditFieldModal
          {...editFieldModalProps}
          onDismiss={() => setShowEditModal(false)}
        />
      )}
    </EditableFormContext.Provider>
  );
};
