import { BreadcrumbGroupProps } from '@cloudscape-design/components';
import { useMemo } from 'react';
import { Location, Params, useLocation, useMatches } from 'react-router-dom';

export type HandleOptions = {
  match: Match;
  location: Location;
};

export type Match = {
  /**
   * Set to true, if this page doesn't follow a hierarchy of objects pattern
   * e.g    list-of-items-page/item-page/list-of-sub-items-page  (don't need isParent=false)
   *        list-of-items-page/add-item-page/item-page/list-of-sub-items-page (add-item-page should have isParent=false)
   */
  isNotParent?: boolean;
  id: string;
  pathname: string;
  params: Params<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handle?: {
    title?: (options: HandleOptions) => string;
    breadcrumbUrl?: (options: HandleOptions) => string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    breadcrumb?: any;
  };
};

function useBreadcrumbs() {
  const matches = useMatches();
  const location = useLocation();

  const breadcrumbs = useMemo<BreadcrumbGroupProps.Item[]>(() => {
    return (
      matches
        ?.map((match) => updateReferrer(location, match as Match))
        ?.map((match) => {
          const Breadcrumb = match.handle?.breadcrumb;
          if (Breadcrumb) {
            const item: BreadcrumbGroupProps.Item = {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              text: (<Breadcrumb {...match.params} />) as any,
              href: match.pathname,
            };

            return item;
          }
          const title =
            typeof match.handle?.title === 'function'
              ? match.handle?.title({
                  match,
                  location,
                })
              : match.handle?.title;

          return {
            text: title ?? '',
            href: match.pathname,
          };
        })
        .filter((route) => route.text) || []
    );
  }, [location, matches]);

  return {
    breadcrumbs,
    showBreadcrumbs: breadcrumbs.length >= 2,
  };
}

function updateReferrer(location: Location, match: Match) {
  let pathname = match.pathname;
  if (match.handle?.breadcrumbUrl) {
    pathname = match.handle.breadcrumbUrl({
      match,
      location,
    });
    match = {
      ...match,
      pathname,
    };
  }

  return match;
}

export default useBreadcrumbs;
