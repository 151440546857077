import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import { ImpactRatingStatus } from './ratingStatus';
import {
  ImpactAppetites,
  ImpactRating,
  ImpactRatingTableFields,
} from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (
  onEdit: (item: ImpactRatingTableFields) => void
): TableFields<ImpactRatingTableFields> => {
  const { getByValue: getImpactRatingByValue } = useRating('impact');
  const { getByValue: getImpactPerformanceByValue } =
    useRating('impact_performance');
  const { t: stf } = useTranslation(['common'], {
    keyPrefix: 'impactRatings.footerLabels',
  });
  const { t: stc } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'impactRatings.columns',
  });
  const { getByValue: getImpactRatingStatus } = useRating(
    'impact_rating_status'
  );
  const { getByValue: getLikelihood } = useRating('likelihood');

  return {
    SequentialIdLabel: { header: stc('id'), sortingField: 'SequentialId' },
    Name: {
      header: st('Name'),
      cell: (item) => (
        <Link variant="secondary" onFollow={() => onEdit(item)}>
          {item.impact.Name}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    RatedItem: {
      header: st('RatedItem'),
    },
    TestDate: dateColumn(st('TestDate'), 'TestDate'),
    Status: {
      header: st('Status'),
      cell: (item) => (
        <SimpleRatingBadge rating={getImpactRatingStatus(item.Status)} />
      ),
      sortingField: 'Status',
      maxWidth: MAX_COL_WIDTH,
    },
    RatingScore: {
      header: st('RatingScore'),
      cell: (item) => {
        const rating = getImpactRatingByValue(item.Rating);

        return (
          <SimpleRatingBadge
            rating={{
              ...rating,
              label: item.Rating.toString(),
              tooltip: rating?.label,
            }}
          />
        );
      },
      footerLabel: stf('RatingScore'),
      footerVal: (records) =>
        records
          .filter((r) => r.Status === ImpactRatingStatus.Active)
          .reduce(
            (previous, currentValue) => previous + currentValue.RatingScore,
            0
          ),
      exportVal: (item) => item.Rating,
    },
    PerformanceScore: {
      header: st('PerformanceScore'),
      cell: (item) => {
        const rating = getImpactPerformanceByValue(item.Performance);

        return (
          <SimpleRatingBadge
            rating={{
              ...rating,
              label: item.PerformanceScore?.toString() ?? '',
              tooltip: rating?.label,
            }}
          />
        );
      },
      footerLabel: stf('PerformanceScore'),
      footerVal: (records) =>
        records
          .filter((r) => r.Status === ImpactRatingStatus.Active)
          .reduce(
            (previous, currentValue) =>
              previous + (currentValue.PerformanceScore ?? 0),
            0
          ),
    },
    CreatedAtTimestamp: dateColumn(stc('created_on'), 'CreatedAtTimestamp'),
    Id: {
      header: stc('guid'),
    },
    ModifiedAtTimestamp: dateColumn(stc('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByUser: {
      header: stc('created_by_id'),
    },
    CreatedByUserName: {
      header: stc('created_by_username'),
    },
    CompletedByUserName: {
      header: st('CompletedBy'),
    },
    LikelihoodLabel: {
      header: st('Likelihood'),
      cell: (item) => (
        <SimpleRatingBadge rating={getLikelihood(item.Likelihood)} />
      ),
    },
  };
};

export const useGetCollectionTableProps = (
  records: ImpactRating[] | undefined,
  impactAppetites: ImpactAppetites | undefined,
  customAttributeSchema: CustomAttributeFields | null,
  onEdit: (item: ImpactRatingTableFields) => void
): TablePropsWithActions<ImpactRatingTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'impactRatings' });
  const fields = useGetFieldConfig(onEdit);
  const labelledFields = useLabelledFields(records, impactAppetites);

  return useGetTableProps({
    data: labelledFields,
    customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'ImpactRatingRegisterTable-Preferences',
    enableFiltering: true,
    initialColumns: [
      'Name',
      'RatedItem',
      'CompletedByUserName',
      'TestDate',
      'Status',
      'PerformanceScore',
      'RatingScore',
    ],
    fields,
  });
};
