import {
  PropertyFilterOperatorExtended,
  PropertyFilterOption,
} from '@cloudscape-design/collection-hooks';

export const translatedExactFilterOperators = (
  filteringOptions: Omit<PropertyFilterOption, 'propertyKey'>[]
): PropertyFilterOperatorExtended<string>[] =>
  (['=', '!='] as const).map((operator) => ({
    operator,
    format: (value: string) =>
      filteringOptions.find((option) => option.value === value)?.label ?? value,
  }));
