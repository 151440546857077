import { FC, useState } from 'react';

import { initialSectionData, SectionData } from './form-configs/section';
import { FormSectionModal } from './FormSectionModal';
import {
  CustomSchema,
  CustomUISchema,
  CustomUISchemaElement,
} from './useFormBuilderContext';

type FormBuilderAddSectionProps = {
  schema: CustomSchema;
  uiSchema: CustomUISchema | CustomUISchemaElement;
  setUISchema: (uiSchema: CustomUISchema) => void;
};

export const FormBuilderAddSection: FC<FormBuilderAddSectionProps> = ({
  uiSchema,
}) => {
  const [isAddingSection, setIsAddingSection] = useState<boolean>(false);
  const [sectionData, setSectionData] =
    useState<SectionData>(initialSectionData);

  return (
    <>
      <div className={'flex w-full'}>
        <div className={'flex w-full justify-start pr-5'}>
          <div
            className={
              'flex flex-col basis-2/3 rounded-md ' +
              'border-2 border-dashed border-[#B9B9C6] ' +
              'text-slate-700 items-center gap-y-4 py-[48px] ' +
              'transition hover:cursor-pointer hover:bg-grey150'
            }
            onClick={() => setIsAddingSection(true)}
          >
            <h1 className={'text-[#B9B9C6] m-0 font-normal'}>+</h1>
            <h1 className={'text-[#B9B9C6] m-0 font-semibold'}>Add Section</h1>
          </div>
        </div>
      </div>

      <FormSectionModal
        action={'add'}
        sectionData={sectionData}
        setSectionData={setSectionData}
        isEditingSection={isAddingSection}
        setIsEditingSection={setIsAddingSection}
        initialSectionData={initialSectionData}
        uiSchema={uiSchema}
      />
    </>
  );
};
