import {
  JsonSchema7,
  Labelable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';
import { createContext, useContext } from 'react';

export interface CustomSchemaProperties extends JsonSchema7 {
  parentId?: string;
}

export type CustomSchema = JsonSchema7 & {
  properties?: CustomSchemaProperties;
  isCustomisable?: boolean;
};

export type CustomUISchemaElement = UISchemaElement &
  Labelable & {
    id: string;
    parentId?: string;
    elements?: CustomUISchemaElement[];
  };

export type CustomUISchema = VerticalLayout & {
  id?: string;
  elements: CustomUISchemaElement[];
};

type FormBuilderContextState = {
  formData: unknown;
  schema: CustomSchema;
  uiSchema: CustomUISchema;
  isEditingForm: boolean;
  isFormEditable: boolean;
  isFormCustomisable: boolean;
  isCustomising: boolean;
  setFormData: (formData: unknown) => void;
  setSchema: (schema: CustomSchema) => void;
  setUISchema: (uiSchema: CustomUISchema) => void;
  setIsFormEditable: (isFormEditable: boolean) => void;
  setIsEditingForm: (isEditingForm: boolean) => void;
  setIsFormCustomisable: (isFormCustomisable: boolean) => void;
  setIsCustomising: (isCustomising: boolean) => void;
};

export const FormBuilderContext = createContext<FormBuilderContextState | null>(
  null
);

export const useFormBuilderContext = () => {
  const context = useContext(FormBuilderContext);

  if (!context) {
    throw new Error(
      'useSetFormBuilderContext must be used within a FormBuilderContextProvider'
    );
  }

  const {
    formData,
    schema,
    uiSchema,
    isFormEditable,
    isEditingForm,
    isFormCustomisable,
    isCustomising,
    setFormData,
    setSchema,
    setUISchema,
    setIsFormEditable,
    setIsEditingForm,
    setIsFormCustomisable,
    setIsCustomising,
  } = context;

  return {
    formData,
    schema,
    uiSchema,
    isFormEditable,
    isEditingForm,
    isFormCustomisable,
    isCustomising,
    setFormData,
    setSchema,
    setUISchema,
    setIsFormEditable,
    setIsEditingForm,
    setIsFormCustomisable,
    setIsCustomising,
  };
};
