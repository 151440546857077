import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';

import { FormContext } from '@/components/Form/Form/InnerFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useDataImportValidateMutation,
  useInsertDataImportMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';
import { dataImportResultsUrl } from '@/utils/urls';

import { DataImport } from '../../types';
import { supportedCsvFiles } from '../csvFiles';
import DataImportFormFields from './DataImportFormFields';
import {
  DataImportDataFields,
  DataImportSchema,
  defaultValues,
} from './dataImportSchema';
import TemplateLink from './TemplateLink';

export const DataImportForm: FC<{
  dataImport: DataImport | undefined;
}> = ({ dataImport }) => {
  const { updateFiles } = useFileUpdate();

  const [insertDataImport] = useInsertDataImportMutation({
    update: (cache) => {
      evictField(cache, 'data_import');
    },
  });
  const [validate] = useDataImportValidateMutation({
    update: (cache) => {
      evictField(cache, 'data_import');
    },
  });

  const { t: st } = useTranslation(['common'], { keyPrefix: 'dataImport' });
  const navigate = useNavigate();

  return (
    <FormContext<DataImportDataFields>
      formId={''}
      header={st('tabTitle')}
      values={dataImport ? { ...dataImport, newFiles: [] } : undefined}
      onDismiss={(saved) => {
        if (!saved) {
          navigate('..');
        }
      }}
      defaultValues={defaultValues}
      onSave={async (data) => {
        let dataImportId = dataImport?.Id;
        if (!dataImport) {
          const { data: insertDataImportResult } = await insertDataImport();
          dataImportId = insertDataImportResult?.insert_data_import_one?.Id;
        }
        if (!dataImportId) {
          throw new Error('Missing data import id');
        }

        await updateFiles({
          parentType: Parent_Type_Enum.DataImport,
          parentId: dataImportId,
          newFiles: data.newFiles,
          originalFiles: dataImport?.files,
          selectedFiles: data.files,
        });

        await validate({ variables: { Id: dataImportId } });
        navigate(dataImportResultsUrl(dataImportId));
      }}
      schema={DataImportSchema}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      i18n={{
        entity_name: st('entity_name'),
      }}
    >
      {supportedCsvFiles.map((csvFile, i) => (
        <TemplateLink csvFile={csvFile} key={i} />
      ))}

      <DataImportFormFields />
    </FormContext>
  );
};
