import { Box } from '@cloudscape-design/components';
import { TypedPropertyFilterQuery } from '@risksmart-app/components/Table/tableUtils';
import _, { Dictionary } from 'lodash';
import { forwardRef, Ref, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RiskRegisterFields } from 'src/pages/risks/types';
import { useAggregation } from 'src/providers/AggregationProvider';
import { useComputedRating } from 'src/ratings/useComputedRating';
import { merge } from 'ts-deepmerge';

import {
  Risk_Assessment_Result_Control_Type_Enum,
  Risk_Scoring_Model_Enum,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { handleError } from '@/utils/errorUtils';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';
import { emptyFilterQuery } from '@/utils/table/types';

import { shouldDisableClickThrough } from '../../../../context/shouldDisableClickThrough';
import { useDashboardFilter } from '../../../../context/useDashboardFilter';
import { useDashboardWidgetSettings } from '../../../../context/useDashboardWidgetSettings';
import { useGetWidgetData } from '../../Gigawidget/hooks/useGetWidgetData';
import { WidgetRef } from '../../types';
import { dataSources } from '../../UniversalWidget/dataSources';
import {
  dashboardFilterToQuery,
  FilterSettings,
} from '../../UniversalWidget/util';
import HeatmapWidget, {
  initializeHeatmapCells,
} from '../HeatmapWidget/HeatmapWidget';
import { HeatmapCellData } from '../HeatmapWidget/types';
import { WidgetSettingsModal } from './WidgetSettingsModal';

type Props = {
  controlType: Risk_Assessment_Result_Control_Type_Enum;
};

export const RiskHeatmap = forwardRef((props: Props, ref: Ref<WidgetRef>) => {
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useDashboardWidgetSettings<FilterSettings>();
  const navigate = useNavigate();
  const { riskModel } = useAggregation();
  const hasImpacts = useIsFeatureVisibleToOrg('impacts');
  const nonDefaultAggregation = riskModel !== Risk_Scoring_Model_Enum.Default;
  const { getLabel: getImpactLabel } = useRating('impact');
  const { getLabel: getLikelihoodLabel } = useRating('likelihood');
  const { t } = useTranslation(['common'], {
    keyPrefix:
      props.controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
        ? 'dashboard.widgets.controlledRiskHeatMap'
        : 'dashboard.widgets.uncontrolledRiskHeatMap',
  });
  useImperativeHandle(ref, () => ({
    openSettings: () => setShowModal(true),
  }));

  const { filters } = useDashboardFilter();
  const dataSource = dataSources.risk;
  const {
    tableProps: { allItems },
    loading,
  } = useGetWidgetData({
    dataSource,
    propertyFilterQuery: settings?.filtering,
  });
  const handleSave = async (data: FilterSettings) => {
    setSettings(data);
  };

  const data = useGetRiskAssessmentRatingsData(props.controlType, allItems);

  if (nonDefaultAggregation || hasImpacts) {
    return (
      <div className="flex justify-center items-center h-full">
        {t('disabledMessage')}
      </div>
    );
  }

  return (
    <>
      {showModal && (
        <WidgetSettingsModal
          onDismiss={() => setShowModal(false)}
          onSave={handleSave}
        />
      )}
      <HeatmapWidget
        onCellClick={(cell) => {
          if (shouldDisableClickThrough(filters)) {
            return;
          }
          const localPropertyFilter: TypedPropertyFilterQuery<RiskRegisterFields> =
            settings?.filtering ?? emptyFilterQuery;
          const cellPropertyFilter: TypedPropertyFilterQuery<RiskRegisterFields> =
            {
              operation: 'and',
              tokens: [
                {
                  propertyKey:
                    props.controlType ===
                    Risk_Assessment_Result_Control_Type_Enum.Controlled
                      ? 'ControlledImpact'
                      : 'UncontrolledImpact',
                  value: getImpactLabel(cell.x + 1),
                  operator: '=',
                },
                {
                  propertyKey:
                    props.controlType ===
                    Risk_Assessment_Result_Control_Type_Enum.Controlled
                      ? 'ControlledLikelihood'
                      : 'UncontrolledLikelihood',
                  value: getLikelihoodLabel(cell.y + 1),
                  operator: '=',
                },
              ],
            };
          const url = dataSource.clickThroughUrl?.(
            merge(
              localPropertyFilter,
              dashboardFilterToQuery(filters, 'day', undefined, {
                departments:
                  !!dataSource.dashboardFilterConfig.departmentsFilter,
                tags: !!dataSource.dashboardFilterConfig.tagsFilter,
              }),
              cellPropertyFilter
            )
          );
          if (url) {
            navigate(url);
          }
        }}
        data={data}
        loading={loading}
        xAxisTitle={t('xAxisTitle')}
        yAxisTitle={t('yAxisTitle')}
        renderPopoverContent={(data) => (
          <div>
            <Box variant="strong">{t('popover.likelihood')}:</Box>
            <Box>{getLikelihoodLabel(data.y + 1)}</Box>
            <Box variant="strong">{t('popover.impact')}:</Box>
            <Box>{getImpactLabel(data.x + 1)}</Box>
            <Box variant="strong">{t('popover.recordCount')}:</Box>
            <Box>{data.data.value}</Box>
            {data.data.label && (
              <>
                <Box variant="strong">{t('popover.label')}:</Box>
                <Box>{data.data.label}</Box>
              </>
            )}
          </div>
        )}
      />
    </>
  );
});

RiskHeatmap.displayName = 'RiskHeatMap';

export const useGetRiskAssessmentRatingsData = (
  controlType: Risk_Assessment_Result_Control_Type_Enum,
  risks: readonly RiskRegisterFields[] | undefined
): HeatmapCellData[][] => {
  const cellData = useCreateCellDataWithBackgroundColor(controlType);

  const rawData = risks
    ?.map((a) => {
      return {
        Impact:
          controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
            ? a?.ControlledImpactValue
            : a?.UncontrolledImpactValue,
        Likelihood:
          controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
            ? a?.ControlledLikelihoodValue
            : a?.UncontrolledLikelihoodValue,
      };
    })
    .filter(hasImpactAndLikelihood);

  populateCellDataValues(cellData, rawData);

  return cellData;
};

const hasImpactAndLikelihood = (
  rawData: OptionalImpactLikelihood
): rawData is ImpactLikelihood => !!rawData.Impact && !!rawData.Likelihood;

type ImpactLikelihood = { Impact: number; Likelihood: number };
type OptionalImpactLikelihood = {
  Impact: number | null | undefined;
  Likelihood: number | null | undefined;
};

const populateCellDataValues = (
  cellData: HeatmapCellData[][],
  rawData: ImpactLikelihood[] | undefined
) => {
  const counts: Dictionary<number> = _.countBy(
    rawData,
    (item) => `${item.Impact},${item.Likelihood}`
  );
  Object.keys(counts).forEach((k) => {
    const fields = k.split(',');
    const impact = Number(fields[0]);
    const likelihood = Number(fields[1]);
    if (likelihood > cellData.length) {
      handleError(
        `Cannot render likelihood value ${likelihood} on grid of size ${cellData.length}`
      );

      return;
    }
    if (impact > cellData[likelihood - 1].length) {
      handleError(
        `Cannot render impact value ${impact} on grid of size ${
          cellData[likelihood - 1].length
        }`
      );

      return;
    }
    cellData[likelihood - 1][impact - 1].value = counts[k];
  });
};

const useCreateCellDataWithBackgroundColor = (
  controlType: Risk_Assessment_Result_Control_Type_Enum
) => {
  const { options: impactOptions } = useRating('impact');
  const { options: likelihoodOptions } = useRating('likelihood');
  const getComputedRating = useComputedRating(controlType);

  return initializeHeatmapCells(
    likelihoodOptions.length,
    impactOptions.length,
    (r, c) => {
      const rating = getComputedRating({ likelihood: r + 1, impact: c + 1 });

      return { label: rating.label, background: rating.color! };
    }
  );
};

export default RiskHeatmap;
