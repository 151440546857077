import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTablePropsWithoutUrlHash } from '@/utils/table/hooks/useGetTablePropsWithoutUrlHash';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';
import { internalAuditReportDetailsUrl } from '@/utils/urls';

import { UNRATED } from '../../../../../controls/lookupData';
import {
  InternalAuditObligationAssessmentResultFlatFields,
  InternalAuditObligationAssessmentResultRegisterFields,
} from './types';
import { useInternalAuditObligationRatingLabelledFields } from './useInternalAuditObligationRatingLabelledFields';

const useGetFieldConfig = (
  onOpenResult: (id: string) => void
): TableFields<InternalAuditObligationAssessmentResultRegisterFields> => {
  const { t: at } = useTranslation(['common'], {
    keyPrefix: 'internalAuditReports',
  });
  const { t: rt } = useTranslation(['common'], {
    keyPrefix: 'ratings.columns',
  });
  const { getByValue: getResultByValue } = useRating('performance_result');
  const { getByValue: statusGetByValue } = useRating('assessment_status');

  return useMemo(
    () => ({
      TestDate: dateColumn(rt('TestDate'), 'TestDate', (item) =>
        onOpenResult(item.Id)
      ),
      Title: {
        header: rt('InternalAuditReportTitle'),
        cell: (item) =>
          item.parents.find((p) => p.internalAuditReport)
            ?.internalAuditReport ? (
            <Link
              variant="secondary"
              href={internalAuditReportDetailsUrl(
                item.parents.find((p) => p.internalAuditReport)!
                  .internalAuditReport!.Id
              )}
            >
              {
                item.parents.find((p) => p.internalAuditReport)
                  ?.internalAuditReport?.Title
              }
            </Link>
          ) : (
            '-'
          ),
        sortingField: 'Title',
      },
      Result: {
        header: rt('Rating'),
        cell: (item) => {
          const result = getResultByValue(item.Rating);

          return <SimpleRatingBadge rating={result ?? UNRATED} />;
        },
        sortingField: 'ResultValue',
      },
      Status: {
        header: at('columns.Status'),
        cell: (item) => {
          return <SimpleRatingBadge rating={statusGetByValue(item.Status)} />;
        },
        sortingField: 'Status',
      },
      CompletionDate: dateColumn(rt('CompletionDate'), 'CompletionDate'),
      NextTestDate: dateColumn(at('columns.NextTestDate'), 'NextTestDate'),
    }),
    [at, getResultByValue, onOpenResult, rt, statusGetByValue]
  );
};

const useGetObligationRatingTableProps = (
  records: InternalAuditObligationAssessmentResultFlatFields[] | undefined,
  onOpenResult: (id: string) => void,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<InternalAuditObligationAssessmentResultRegisterFields> => {
  const labelledFields =
    useInternalAuditObligationRatingLabelledFields(records);
  const fields = useGetFieldConfig(onOpenResult);

  return {
    customAttributeSchema: customAttributeSchema,
    data: labelledFields,
    enableFiltering: true,
    entityLabel: 'rating',
    defaultSortingState: {
      sortingColumn: 'TestDate',
      sortingDirection: 'desc',
    },
    initialColumns: [
      'TestDate',
      'Title',
      'Result',
      'Status',
      'CompletionDate',
      'NextTestDate',
    ],
    storageKey: 'InternalAuditObligationRatingRegisterTable-Preferences',
    fields,
  };
};

export const useGetCollectionTableProps = (
  records: InternalAuditObligationAssessmentResultFlatFields[] | undefined,
  onOpenResult: (id: string) => void,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<InternalAuditObligationAssessmentResultRegisterFields> => {
  const props = useGetObligationRatingTableProps(
    records,
    onOpenResult,
    customAttributeSchema
  );

  return useGetTablePropsWithoutUrlHash(props);
};
