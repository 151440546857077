import 'pdfmake/build/vfs_fonts';

import { useTranslation } from 'react-i18next';
import useActionExportTable from 'src/pages/actions/update/useActionExportTable';
import useAssessmentActivityExportTable from 'src/pages/assessments/update/useAssessmentActivityExportTable';
import useIssuesExportTable from 'src/pages/issues/update/useIssuesExportTable';

import {
  Parent_Type_Enum,
  useGetComplianceMonitoringAssessmentByIdLazyQuery,
  useGetFormFieldOptionsByParentTypeLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createConfigurableField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import useAssessmentResultExportTable from './useAssessmentResultExportTable';

const useExporter = (
  assessmentId: string
): [() => void, { loading: boolean }] => {
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.Assessment);
  const [createAssessmentResultsTable, assessmentResultsLoading] =
    useAssessmentResultExportTable(assessmentId);
  const [createAssessmentActivityTable, assessmentActivitiesLoading] =
    useAssessmentActivityExportTable(assessmentId);
  const [createAssessmentActionTable, assessmentActionsLoading] =
    useActionExportTable(assessmentId);
  const [createAssessmentIssueTable, assessmentIssuesLoading] =
    useIssuesExportTable(assessmentId);

  const { t } = useTranslation(['common']);
  const { t: assessmentFields } = useTranslation(['common'], {
    keyPrefix: `complianceMonitoringAssessment.fields`,
  });

  const { getLabel: getStatusLabel } = useRating('assessment_status');
  const { getLabel: getOutcomeLabel } = useRating(
    'compliance_monitoring_assessment_outcome'
  );

  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });

  const [
    getComplianceMonitoringAssessment,
    getComplianceMonitoringAssessmentResult,
  ] = useGetComplianceMonitoringAssessmentByIdLazyQuery({
    variables: {
      Id: assessmentId,
    },
  });
  const [
    getComplianceMonitoringAssessmentFormFieldConfigData,
    assessmentFormFieldConfigResult,
  ] = useGetFormFieldOptionsByParentTypeLazyQuery({
    variables: { parentType: 'compliance_monitoring_assessment' },
  });

  const loading =
    getComplianceMonitoringAssessmentResult.loading ||
    assessmentResultsLoading ||
    customAttributesLoading ||
    assessmentActionsLoading ||
    assessmentIssuesLoading ||
    assessmentActivitiesLoading ||
    assessmentFormFieldConfigResult.loading;

  const exportFunc = async () => {
    const { data: assessmentData } = await getComplianceMonitoringAssessment();
    const { data: assessmentFormFieldConfigData } =
      await getComplianceMonitoringAssessmentFormFieldConfigData();
    const fieldConfig =
      assessmentFormFieldConfigData?.form_field_configuration ?? [];
    const createIndicatorTablePromise = createAssessmentResultsTable();
    const createActionTablePromise = createAssessmentActionTable();
    const createIssueTablePromise = createAssessmentIssueTable();
    const createAssessmentActivityTablePromise =
      createAssessmentActivityTable();

    const assessment = assessmentData?.compliance_monitoring_assessment?.[0];
    const indicatorTable = await createIndicatorTablePromise;
    const actionTable = await createActionTablePromise;
    const issueTable = await createIssueTablePromise;
    const assessmentActivityTable = await createAssessmentActivityTablePromise;

    if (!assessment) {
      return;
    }

    const title = `${assessment.Title} (${getFriendlyId(
      Parent_Type_Enum.Risk,
      assessment.SequentialId
    )})`;

    const detailFields = [
      createConfigurableField(
        fields('Title'),
        'Title',
        assessment.Title,
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('Summary'),
        'Summary',
        assessment.Summary,
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('CompletedBy'),
        'CompletedByUser',
        assessment.completedByUser?.FriendlyName,
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('StartDate'),
        'StartDate',
        toLocalDate(assessment.StartDate) ?? '-',
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('TargetCompletionDate'),
        'TargetCompletionDate',
        toLocalDate(assessment.TargetCompletionDate) ?? '-',
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('ActualCompletionDate'),
        'ActualCompletionDate',
        toLocalDate(assessment.ActualCompletionDate) ?? '-',
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('NextTestDate'),
        'NextTestDate',
        toLocalDate(assessment.NextTestDate) ?? '-',
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('Status'),
        'Status',
        assessment.Status ? getStatusLabel(assessment.Status) : '-',
        fieldConfig
      ),
      createConfigurableField(
        assessmentFields('Outcome'),
        'Outcome',
        assessment.Outcome ? getOutcomeLabel(assessment.Outcome) : '-',
        fieldConfig
      ),
      createConfigurableField(
        fields('Owner'),
        'Owners',
        getOwnerValue(assessment),
        fieldConfig
      ),
      createConfigurableField(
        fields('Contributor'),
        'Contributors',
        getContributorValue(assessment),
        fieldConfig
      ),
      createConfigurableField(
        fields('Tags'),
        'tags',
        getTagsValue(assessment),
        fieldConfig
      ),
      createConfigurableField(
        fields('Departments'),
        'departments',
        getDepartmentsValue(assessment),
        fieldConfig
      ),
      ...(await getCustomAttribute(assessment)),
    ].filter((c) => !(Array.isArray(c) && c.length === 0));
    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),

      optionalTableSection(
        t('assessmentActivities.tab_title'),
        assessmentActivityTable
      ),
      optionalTableSection(t('assessmentResults.tab_title'), indicatorTable),
      optionalTableSection(t('assessmentResults.issues'), issueTable),
      optionalTableSection(t('assessmentResults.action'), actionTable),
    ]);
    download(docDefinition);
  };

  return [exportFunc, { loading }];
};

export default useExporter;
