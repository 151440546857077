import { AnimatePresence, Reorder } from 'framer-motion';
import { motion } from 'framer-motion';
import React, { FC, useCallback, useEffect, useMemo } from 'react';

import Loading from '@/components/Loading';
import {
  useGetCustomAttributeSchemasByParentTypeQuery,
  useGetFormFieldPositionsByParentTypeQuery,
  useInsertFormFieldPositionsMutation,
  useUpdateFormFieldPositionsMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import useControlledCustomAttributes from '../../CustomAttributes/useControlledCustomAttributes';
import DraggableField from './DraggableField';
import { useElementsOrder } from './hooks/useElementsOrder';
import { useTransformSchemaToRequiredFields } from './hooks/useTransformSchemaToRequiredFields';
import { useRiskSmartForm } from './RiskSmartFormContext';
import style from './style.module.scss';

type Props = {
  children: React.ReactNode | React.ReactNode[];
  excludeCustomAttributes?: boolean;
  readOnly?: boolean;
};

const CustomisableForm: FC<Props> = ({
  children: childrenProp,
  excludeCustomAttributes,
  readOnly,
}) => {
  const { parentType, editMode } = useRiskSmartForm();

  if (!parentType) {
    throw new Error(
      'CustomisableForm must have a parentType on the RiskSmartFormProvider'
    );
  }

  const [updateFormFieldPositions] = useUpdateFormFieldPositionsMutation({
    update: (cache) => {
      evictField(cache, 'form_configuration');
    },
  });
  const [insertFormFieldPositions] = useInsertFormFieldPositionsMutation({
    update: (cache) => {
      evictField(cache, 'form_configuration');
    },
  });
  const customAttributeElements = useControlledCustomAttributes({
    parentType,
    readOnly,
  });

  const { data } = useGetCustomAttributeSchemasByParentTypeQuery({
    variables: { parentType },
  });
  const { data: fieldOrdering } = useGetFormFieldPositionsByParentTypeQuery({
    variables: { parentType },
    fetchPolicy: 'no-cache',
  });

  const updating = useMemo(() => {
    return (data?.form_configuration?.length ?? 0) > 0;
  }, [data]);

  const childrenArray = Array.isArray(childrenProp)
    ? childrenProp
    : [childrenProp];
  const children = excludeCustomAttributes
    ? childrenArray
    : [...childrenArray, ...customAttributeElements];

  const elementsByKey = (
    children.filter((c) => React.isValidElement(c)) as React.ReactElement[]
  ).map((element) => {
    if (!element.key) {
      throw new Error(
        `All children of a CustomisableForm must have a unique 'key' prop, ${
          element.props.name ?? 'a child'
        } does not have one.`
      );
    }

    return {
      key: String(element.key),
      value: element,
    };
  });

  if (elementsByKey.some((e, i) => elementsByKey.indexOf(e) !== i)) {
    throw new Error("CustomisableForm's children must have unique 'key' props");
  }
  useTransformSchemaToRequiredFields(parentType);

  const { elementsOrder, visibleElements, setElementsOrder } = useElementsOrder(
    elementsByKey,
    fieldOrdering?.form_field_ordering,
    !!fieldOrdering
  );

  const saveFields = useCallback(async () => {
    if (!parentType || !elementsOrder) {
      return;
    }

    const mutateFn = updating
      ? updateFormFieldPositions
      : insertFormFieldPositions;

    await mutateFn({
      variables: {
        fieldConfig: elementsOrder.map((key, i) => ({
          FormConfigurationParentType: updating ? parentType : undefined,
          FieldId: key,
          Position: i,
          form: null,
        })),
        fieldIds: elementsOrder.map((key) => key),
        parentType,
      },
    });
  }, [
    elementsOrder,
    parentType,
    updateFormFieldPositions,
    insertFormFieldPositions,
    updating,
  ]);

  useEffect(() => {
    if (!editMode) {
      saveFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  const { setOnSave } = useRiskSmartForm();

  useEffect(() => {
    if (editMode) {
      setOnSave(() => saveFields);
    }

    return () => setOnSave(undefined);
  }, [saveFields, setOnSave, editMode]);

  return (
    <AnimatePresence mode="wait">
      {!elementsOrder ? (
        <Loading key="loading" />
      ) : (
        <motion.div
          key="fields"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.05 }}
        >
          <Reorder.Group
            data-testid="customisable-form-content"
            className={style.reorderGroup}
            axis="y"
            values={elementsOrder}
            onReorder={setElementsOrder}
          >
            <div>
              {visibleElements
                ?.filter(
                  (key) =>
                    elementsByKey.find((e) => e.key === key)?.value !== null
                )
                .map((key) => (
                  <DraggableField key={key} value={key} draggable={editMode}>
                    {elementsByKey.find((e) => e.key === key)?.value}
                  </DraggableField>
                ))}
            </div>
          </Reorder.Group>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CustomisableForm;
