import {
  PropertyFilterOperator,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { useTranslation } from 'react-i18next';

import CommaSeparatedCell from '@/components/CommaSeparatedCell';
import { useGetUsersQuery } from '@/generated/graphql';

import { LabelledIdArray } from '../../../rbac/types';
import { FieldConfig } from '../types';

const createRequestersFieldPropertyFilter = (
  options: LabelledIdArray
): Partial<PropertyFilterProperty> => ({
  operators: (['=', ':'] as PropertyFilterOperator[]).map((operator) => ({
    operator,
    format: (id): string => {
      return options.find((t) => t.id === id)?.label || '-';
    },
    match: (users: unknown, id: string) => {
      return !!((users || []) as { label: string; id: string }[]).find(
        (t) => t.id === id
      );
    },
  })),
});

export function useGetRequestersFieldConfig<
  T extends { allRequesters: LabelledIdArray },
>(): FieldConfig<T> {
  const { data: users } = useGetUsersQuery();
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const userOptions =
    users?.user.map((u) => ({ label: u.FriendlyName ?? '', id: u.Id ?? '' })) ??
    [];

  return {
    header: t('requesters'),
    cell: (item) => <CommaSeparatedCell items={item.allRequesters} />,
    filterOptions: {
      filteringProperties: createRequestersFieldPropertyFilter([
        ...userOptions,
      ]),
    },
    sortingComparator: (a, b) => {
      return (
        a.allRequesters?.[0]?.label.localeCompare(
          b.allRequesters?.[0]?.label ?? ''
        ) || 0
      );
    },
    exportVal: (item) => item.allRequesters.map((a) => a.label).join(', '),
  };
}
