import {
  PropertyFilterProperty,
  PropertyFilterQuery,
  PropertyFilterToken,
} from '@cloudscape-design/collection-hooks';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { Action_Status_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';
import { tablePropertyFilterDateFormat } from '@/utils/dateUtils';

import { ActionTableFields } from './types';

type ActionsDashboardProps = {
  items: readonly ActionTableFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const ActionsDashboard: FC<ActionsDashboardProps> = ({
  items,
  propertyFilterQuery,
  onFilterQueryChanged,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'actions.dashboard',
  });
  const today = dayjs().startOf('day');
  const tomorrow = today.add(1, 'day');
  const eightDaysTime = today.add(8, 'day');
  const thirtyDaysTime = today.add(30, 'day');

  const { getLabel } = useRating('action_status');

  const notClosedToken: PropertyFilterToken = {
    value: getLabel(Action_Status_Enum.Closed),
    propertyKey: 'StatusLabelled',
    operator: '!=',
  };

  const dueDayOrLayer: PropertyFilterToken = {
    value: today.format(tablePropertyFilterDateFormat),
    propertyKey: 'DateDue',
    operator: '>=',
  };

  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={{ tokens: [notClosedToken], operation: 'and' }}
        title={t('open_actions')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            {
              value: today.format(tablePropertyFilterDateFormat),
              propertyKey: 'DateDue',
              operator: '<',
            },
            notClosedToken,
          ],
          operation: 'and',
        }}
        title={t('overdue')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            dueDayOrLayer,
            {
              value: tomorrow.format(tablePropertyFilterDateFormat),
              propertyKey: 'DateDue',
              operator: '<',
            },
            notClosedToken,
          ],
          operation: 'and',
        }}
        title={t('due_today')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            dueDayOrLayer,
            {
              value: eightDaysTime.format(tablePropertyFilterDateFormat),
              propertyKey: 'DateDue',
              operator: '<',
            },
            notClosedToken,
          ],
          operation: 'and',
        }}
        title={t('due_this_week')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            dueDayOrLayer,
            {
              value: thirtyDaysTime.format(tablePropertyFilterDateFormat),
              propertyKey: 'DateDue',
              operator: '<',
            },
            notClosedToken,
          ],
          operation: 'and',
        }}
        title={t('due_this_month')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        tableFilterQuery={propertyFilterQuery}
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
