import { RiskRegisterFields } from '../risks/types';

type CardData = RiskRegisterFields;

type UnlinkedType = Pick<CardData, 'Id' | 'Title' | 'Tier' | 'SequentialId'> & {
  unlinked: true;
};

export type CardType = (CardData & { unlinked?: false }) | UnlinkedType;

export enum RiskAttribute {
  ControlledRating = 'controlledRating',
  UncontrolledRating = 'uncontrolledRating',
  AppetitePerformance = 'appetitePerformance',
  ImpactPerformance = 'impactPerformance',
  RiskStatus = 'riskStatus',
}

export type DashboardState = Map<number, string | undefined>;
