import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { ChangeRequestRegisterFields } from './types';

type Props = {
  items: readonly ChangeRequestRegisterFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const RequestsSummary: FC<Props> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'approvals.requestsRegister.summary',
  });
  const ratings = useRating('approval_status');
  const { user } = useRisksmartUser();

  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'RequiresAction',
              value: 'true',
              operator: '=',
            },
          ],
          operation: 'and',
        }}
        tableFilterQuery={propertyFilterQuery}
        title={t('requiresAction')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'allRequesters',
              value: user?.userId,
              operator: ':',
            },
          ],
          operation: 'and',
        }}
        tableFilterQuery={propertyFilterQuery}
        title={t('myRequests')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      {ratings.options.map((rating) => (
        <FilterPropertyDashboardItem
          key={rating.value}
          onClick={onFilterQueryChanged}
          itemFilterQuery={{
            tokens: [
              {
                propertyKey: 'StatusLabelled',
                value: rating.label,
                operator: '=',
              },
            ],
            operation: 'and',
          }}
          tableFilterQuery={propertyFilterQuery}
          title={rating.label}
          items={items ?? []}
          filteringProperties={filteringProperties}
        />
      ))}
    </RegisterDashboard>
  );
};
