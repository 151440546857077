import { CategoryType, GigawidgetCommonProps, WidgetDataSource } from './types';
import {
  GigaBarWidget,
  GigaBarWidgetProps,
} from './widgetTypes/bar/GigaBarWidget';
import {
  GigaNumberWidget,
  GigaNumberWidgetProps,
} from './widgetTypes/number/GigaNumberWidget';
import {
  GigaPieWidget,
  GigaPieWidgetProps,
} from './widgetTypes/pie/GigaPieWidget';
import { PlacematWidget } from './widgetTypes/placemat/PlacematWidget';
import {
  GigaTableWidget,
  GigaTableWidgetProps,
} from './widgetTypes/table/GigaTableWidget';

type Props<
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
  TSubCategory extends CategoryType | never,
> = GigawidgetCommonProps<TDataSource> &
  (
    | ({
        type: 'bar' | 'stacked-bar';
      } & Omit<
        GigaBarWidgetProps<TDataSource, TCategory, TSubCategory>,
        'stackedBars'
      >)
    | ({ type: 'pie' | 'donut' } & Omit<
        GigaPieWidgetProps<TDataSource, TCategory>,
        'donut'
      >)
    | ({
        type: 'kpi';
      } & GigaNumberWidgetProps<TDataSource>)
    | ({
        type: 'table' | 'placemat';
      } & GigaTableWidgetProps<TDataSource>)
  );

export const Gigawidget = <
  TDataSource extends WidgetDataSource,
  TCategory extends CategoryType,
  TSubCategory extends CategoryType | never,
>(
  props: Props<TDataSource, TCategory, TSubCategory>
) => {
  switch (props.type) {
    case 'bar':
    case 'stacked-bar':
      return (
        <GigaBarWidget {...props} stackedBars={props.type === 'stacked-bar'} />
      );
    case 'pie':
    case 'donut':
      return <GigaPieWidget {...props} donut={props.type === 'donut'} />;
    case 'kpi':
      return <GigaNumberWidget {...props} />;
    case 'table':
      return <GigaTableWidget {...props} />;
    case 'placemat':
      return <PlacematWidget {...props} />;
    default:
      return null;
  }
};
