
    import './styles.scoped.css';
    export default {
  "universal-toolbar": "awsui_universal-toolbar_1kzri_5ose6_149",
  "disable-body-scroll": "awsui_disable-body-scroll_1kzri_5ose6_170",
  "toolbar-hidden": "awsui_toolbar-hidden_1kzri_5ose6_173",
  "toolbar-container": "awsui_toolbar-container_1kzri_5ose6_176",
  "universal-toolbar-nav": "awsui_universal-toolbar-nav_1kzri_5ose6_185",
  "universal-toolbar-breadcrumbs": "awsui_universal-toolbar-breadcrumbs_1kzri_5ose6_189",
  "universal-toolbar-drawers": "awsui_universal-toolbar-drawers_1kzri_5ose6_194",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_1kzri_5ose6_202",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_1kzri_5ose6_203",
  "drawers-trigger-content": "awsui_drawers-trigger-content_1kzri_5ose6_249",
  "group-divider": "awsui_group-divider_1kzri_5ose6_259",
  "drawers-trigger": "awsui_drawers-trigger_1kzri_5ose6_249",
  "block-body-scroll": "awsui_block-body-scroll_1kzri_5ose6_272"
};
  