import { MultiselectProps } from '@cloudscape-design/components';
import { UserUp01 } from '@untitled-ui/icons-react';
import _ from 'lodash';
import { FC } from 'react';

import Tokens from '@/components/Tokens';
import { Token } from '@/components/Tokens/Tokens';
import { GetUserGroupsQuery, GetUsersQuery } from '@/generated/graphql';

type Props = {
  tokenOptions: MultiselectProps.Option[];
  inheritedContributorLookup: Set<string>;
  users: GetUsersQuery | undefined;
  userGroups: GetUserGroupsQuery | undefined;
  disabled: boolean;
  onRemoveToken: (value: string) => void;
};

export const ContributorTokens: FC<Props> = ({
  tokenOptions,
  inheritedContributorLookup,
  users,
  userGroups,
  disabled,
  onRemoveToken,
}) => {
  const directTokens: Token[] = _.sortBy(
    tokenOptions
      // Removed inherited contributors as rendered below
      .map((o) => ({
        value: o.value!,
        label: o.label!,
        disabled: false,
      })),
    'label'
  );
  const directLookup = new Set<string>(directTokens.map((d) => d.value));

  const inheritedTokens: Token[] = _.sortBy(
    Array.from(inheritedContributorLookup)
      .filter((value) => !directLookup.has(value))
      .map((value) => ({
        value,
        label:
          users?.user.find((o) => o.Id === value)?.FriendlyName ??
          userGroups?.user_group.find((o) => o.Id === value)?.Name ??
          value,
        disabled: true,
        icon: <UserUp01 viewBox="0 0 24 24" className="w-6 h-6" />,
      })),
    'label'
  );

  const currentAndInheritedContributors = directTokens.concat(inheritedTokens);

  return (
    <Tokens
      limit={4}
      disabled={disabled}
      onRemove={onRemoveToken}
      tokens={currentAndInheritedContributors}
    />
  );
};
