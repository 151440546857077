import { ReactNode, useState } from 'react';

import {
  CustomSchema,
  CustomUISchema,
  FormBuilderContext,
} from './useFormBuilderContext';

interface FormBuilderContextProviderProps {
  children: ReactNode;
}

const initialFormData = {} as unknown;

const initialFormSchema: CustomSchema = {
  type: 'object',
  properties: {},
  required: [],
};

const initialFormUISchema: CustomUISchema = {
  type: 'VerticalLayout',
  elements: [],
};

export const FormBuilderContextProvider = ({
  children,
}: FormBuilderContextProviderProps) => {
  const [formData, setFormData] = useState(initialFormData);
  const [schema, setSchema] = useState(initialFormSchema);
  const [uiSchema, setUISchema] = useState(initialFormUISchema);

  /* EDITING vs CUSTOMISING
  To avoid confusion between respondents filling out forms (EDITING) and admins building forms (CUSTOMISING)
  the following states have been defined as follows:
  - isFormEditable: whether the form is editable (i.e. whether a form can be filled out)
  - isEditingForm: whether the form is being edited (i.e. filling out the form)
  - isFormCustomisable: whether the form is customisable (i.e. whether a form config can be customised)
  - isCustomising: whether the form is being customised (i.e. customising a form config)

  RULES OF THUMB:
  - A respondent SHOULD be able to fill out a form (isFormEditable)
  - A respondent SHOULD NOT be able to customise a form config (isFormCustomisable)

  - An admin SHOULD be able to customise a form config (isFormCustomisable)
  - An admin SHOULD NOT be able to fill out a form (isFormEditable) while customising a form config (isCustomising)
   */
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [isEditingForm, setIsEditingForm] = useState(false);
  const [isFormCustomisable, setIsFormCustomisable] = useState(true);
  const [isCustomising, setIsCustomising] = useState(false);

  return (
    <FormBuilderContext.Provider
      value={{
        formData,
        schema,
        uiSchema,
        isFormEditable,
        isEditingForm,
        isFormCustomisable,
        isCustomising,
        setFormData,
        setSchema,
        setUISchema,
        setIsFormEditable,
        setIsEditingForm,
        setIsFormCustomisable,
        setIsCustomising,
      }}
    >
      {children}
    </FormBuilderContext.Provider>
  );
};
