import { FormBuilderContextProvider } from '@risksmart-app/components/FormBuilder/FormBuilderContextProvider';
import {
  CustomUISchema,
  useFormBuilderContext,
} from '@risksmart-app/components/FormBuilder/useFormBuilderContext';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { FormContextProps } from '@/components/Form/Form/types';
import {
  Parent_Type_Enum,
  Questionnaire_Template_Version_Status_Enum,
  Version_Status_Enum,
} from '@/generated/graphql';

import QuestionnaireTemplateVersionFormFields from './QuestionnaireTemplateVersionFormFields';
import {
  QuestionnaireTemplateVersionFormFieldData,
  QuestionnaireTemplateVersionFormSchema,
} from './questionnaireTemplateVersionSchema';

export type Props = Omit<
  FormContextProps<QuestionnaireTemplateVersionFormFieldData>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'i18n'
  | 'renderTemplate'
  | 'submitActions'
> & {
  readonly?: boolean;
  isCreatingNewEntity: boolean;
  savedStatus: Questionnaire_Template_Version_Status_Enum;
  parentId: string;
  disableStatus: boolean;
  onPublish?: () => Promise<void>;
};

const QuestionnaireTemplateVersionForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const { t: qt } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions',
  });

  const { values } = props;
  const { schema, uiSchema, setSchema, setUISchema } = useFormBuilderContext();

  useEffect(() => {
    if (values?.Schema) {
      setSchema(values.Schema);
    }

    if (values?.UISchema) {
      setUISchema(values.UISchema as CustomUISchema);
    }

    /* Disabling the eslint rule here because updating this effect will cause the
    uischema to appear as if it hasn't been updated correctly on save */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDraft =
    props.savedStatus === Questionnaire_Template_Version_Status_Enum.Draft;
  const isPublished =
    props.savedStatus === Questionnaire_Template_Version_Status_Enum.Published;
  const allowStatusChange = !props.readOnly && props.isCreatingNewEntity;

  const onSave = (data: QuestionnaireTemplateVersionFormFieldData) => {
    return props.onSave({
      ...data,
      Schema: schema,
      UISchema: uiSchema,
    });
  };

  return (
    <CustomisableFormContext
      {...props}
      parentType={Parent_Type_Enum.QuestionnaireTemplateVersion}
      header={qt('details')}
      i18n={t('questionnaire_template_versions')}
      schema={QuestionnaireTemplateVersionFormSchema}
      formId={'questionnaire-template-version-form'}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      submitActions={[
        {
          label: qt('save_draft'),
          action: onSave,
        },
        ...(allowStatusChange && isDraft
          ? [
              {
                label: qt('status.publish_present'),
                action: () => {
                  if (!props.onPublish) {
                    return Promise.resolve();
                  }

                  return props.onPublish();
                },
              },
            ]
          : []),
        ...(allowStatusChange && isPublished
          ? [
              {
                label: qt('status.archive_present'),
                action: (data: QuestionnaireTemplateVersionFormFieldData) =>
                  props.onSave({
                    ...data,
                    Status: Version_Status_Enum.Archived,
                  }),
              },
            ]
          : []),
      ]}
    >
      <QuestionnaireTemplateVersionFormFields
        disableStatus={props.disableStatus}
        isCreatingNewEntity={props.isCreatingNewEntity}
        savedStatus={props.savedStatus}
        parentId={props.parentId}
      />
    </CustomisableFormContext>
  );
};

const withFormBuilderContextProvider = (Component: FC<Props>) => {
  return function ProviderWrapper({ children, ...props }: Props) {
    return (
      <FormBuilderContextProvider>
        <Component {...props}>{children}</Component>
      </FormBuilderContextProvider>
    );
  };
};

export default withFormBuilderContextProvider(QuestionnaireTemplateVersionForm);
