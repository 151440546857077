import { FormField } from '@cloudscape-design/components';
import { capitalize } from 'lodash';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '@/components/Form/Select';
import { Parent_Type_Enum } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import {
  ObjectWithContributors,
  useHasPermission,
} from '../../../rbac/Permission';
import { ResultType } from '../modals/types';

interface Props {
  readOnly: boolean;
  onChange?: (val: ResultType) => void;
  parent?: ObjectWithContributors;
  value: ResultType;
  testId?: string;
}

const TypeSelector: FC<Props> = ({
  readOnly,
  onChange,
  parent,
  value,
  testId,
}) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'assessmentResults.fields',
  });
  const { t: tt } = useTranslation('taxonomy');

  const policyModuleEnabled = useIsFeatureVisibleToOrg('policy');
  const complianceModuleEnabled = useIsFeatureVisibleToOrg('compliance');

  const canCreateDocumentAssessmentResult = useHasPermission(
    'insert:document_assessment_result',
    parent
  );
  const canCreateObligationAssessmentResult = useHasPermission(
    'insert:obligation_assessment_result',
    parent
  );
  const canCreateRiskAssessmentResult = useHasPermission(
    'insert:risk_assessment_result',
    parent
  );

  const options = [];

  if (canCreateDocumentAssessmentResult && policyModuleEnabled) {
    options.push({
      value: Parent_Type_Enum.DocumentAssessmentResult,
      label: capitalize(tt('document_one')),
    });
  }

  if (canCreateRiskAssessmentResult) {
    options.push({
      value: Parent_Type_Enum.RiskAssessmentResult,
      label: capitalize(tt('risk_one')),
    });
  }

  if (canCreateObligationAssessmentResult && complianceModuleEnabled) {
    options.push({
      value: Parent_Type_Enum.ObligationAssessmentResult,
      label: capitalize(tt('obligation_one')),
    });
  }

  return (
    <FormField label={t('Type')} data-testid={`form-field-${testId}`}>
      <Select
        disabled={readOnly || options.length === 1}
        onChange={(e) =>
          onChange?.(e.detail.selectedOption.value as ResultType)
        }
        options={options}
        selectedOption={options.find((o) => o.value === value) || null}
        placeholder={t('Type_placeholder')}
      />
    </FormField>
  );
};

export default TypeSelector;
