import './Navigation.css';

import { Auth0ContextInterface, useAuth0 } from '@auth0/auth0-react';
import {
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { ChevronSelectorVertical } from '@untitled-ui/icons-react';
import { Context, FC, useState } from 'react';

import Loading from '../Loading';
import { hasuraClaimsNamespace, hasuraDefaultRole, logo } from '../rbac/jwt';
import OrganisationLogo from './OrganisationLogo';
import UserMenuPopup from './UserMenuPopup';

interface Props {
  collapsed?: boolean;
  logoutUrl: string;
  customLogoUrl?: () => Promise<string>;
  authContext?: Context<Auth0ContextInterface>;
}

const UserMenu: FC<Props> = ({
  collapsed,
  logoutUrl,
  customLogoUrl,
  authContext,
}) => {
  const { isLoading, user, loginWithRedirect } = useAuth0(authContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { refs, floatingStyles, context } = useFloating({
    placement: 'right-end',
    strategy: 'fixed',
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
  });
  const dismiss = useDismiss(context, { ancestorScroll: true });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);

  return isLoading ? (
    <Loading />
  ) : (
    <div
      ref={refs.setReference}
      {...getReferenceProps()}
      className="rs-user-menu sticky grow-0 bg-navy_mid z-20 "
      style={{
        bottom: 0,
      }}
    >
      {isMenuOpen && (
        <FloatingPortal>
          <div
            style={floatingStyles}
            {...getFloatingProps()}
            className="pb-3 ml-3"
            ref={refs.setFloating}
          >
            <UserMenuPopup
              logoutUrl={logoutUrl}
              logoKey={user?.[hasuraClaimsNamespace][logo]}
              customLogoUrl={customLogoUrl}
              username={user?.claims_username}
              organisation={user?.claims_organization_name}
              role={user?.[hasuraClaimsNamespace][hasuraDefaultRole]}
              handleOrgSwitch={() => loginWithRedirect()}
            />
          </div>
        </FloatingPortal>
      )}
      <div
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="border-0 border-t border-solid flex-none border-navy_light"
      >
        {collapsed ? (
          <div className="p-3 text-center cursor-pointer" role="button">
            <OrganisationLogo
              logoKey={user?.[hasuraClaimsNamespace][logo]}
              customLogoUrl={customLogoUrl}
            />
          </div>
        ) : (
          <div
            className="transition duration-200 flex justify-between items-center p-4 px-6 cursor-pointer hover:bg-navy_light"
            role="button"
          >
            <OrganisationLogo
              logoKey={user?.[hasuraClaimsNamespace][logo]}
              customLogoUrl={customLogoUrl}
            />

            <div className="grow">
              <p className="my-1 text-sm font-bold">{user?.claims_username}</p>
              <p className="my-1 text-sm text-grey line-clamp-1 text-ellipsis">
                {user?.claims_organization_name}
              </p>
            </div>
            <div className="grow-0 flex content-center">
              <ChevronSelectorVertical />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserMenu;
