import { Feature } from '@risksmart-app/shared/organisation/Feature';
import { useContext } from 'react';

import { FeaturesContext } from './FeatureProvider';

export function useFeatures(): Feature[] {
  const context = useContext(FeaturesContext)!;
  if (context === null) {
    throw new Error('useFeatures must be used within FeatureProvider');
  }

  return context;
}
