import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { getReviewDueDateCutoff } from 'src/pages/policy/helpers';

import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import {
  Approval_Status_Enum,
  ChangeRequestPartsFragment,
  Document_File,
  Version_Status_Enum,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

type Props = {
  item: Pick<Document_File, 'Status' | 'NextReviewDate'>;
  changeRequests?: Pick<ChangeRequestPartsFragment, 'ChangeRequestStatus'>[];
};

const PolicyDocumentStatusBadge = ({ item, changeRequests }: Props) => {
  const { getByValue: getDocumentFileStatus } = useRating(
    'document_file_status'
  );

  const reviewDueSoon = useMemo(() => {
    const reviewDate = dayjs(item.NextReviewDate);
    const reviewDueDateCutoff = getReviewDueDateCutoff();

    return reviewDate.isBefore(reviewDueDateCutoff);
  }, [item.NextReviewDate]);

  const status = useMemo(() => {
    if (reviewDueSoon && item.Status === Version_Status_Enum.Published) {
      return 'review_due';
    }
    if (
      changeRequests?.some(
        (cr) => cr.ChangeRequestStatus === Approval_Status_Enum.Pending
      )
    ) {
      return 'pending_approval';
    }

    return item.Status;
  }, [reviewDueSoon, item.Status, changeRequests]);

  const rating = useMemo(() => {
    return getDocumentFileStatus(status);
  }, [status, getDocumentFileStatus]);

  return <SimpleRatingBadge rating={rating} />;
};

export default PolicyDocumentStatusBadge;
