import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import {
  Questionnaire_Template_Version_Status_Enum,
  useInsertQuestionnaireTemplateVersionMutation,
  Version_Status_Enum,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import QuestionnaireVersionTemplateForm from '../../../forms/QuestionnaireTemplateVersionForm';
import { defaultValues } from '../../../forms/questionnaireTemplateVersionSchema';
import { QuestionnaireTemplateVersionFormFieldData } from '../../../forms/questionnaireTemplateVersionSchema';
import { useNextTemplateVersion } from '../../../useNextTemplateVersion';

type TabProps = {
  parentId: string;
};

const Tab = ({ parentId }: TabProps) => {
  const { t: qt } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_template_versions.confirm_close_modal',
  });

  const [confirmCloseVisible, setConfirmCloseVisible] = useState(false);
  const [insert] = useInsertQuestionnaireTemplateVersionMutation({
    update: (cache) => {
      evictField(cache, 'document');
    },
  });

  const [{ nextVersion }, { loading: loadingNextTemplateVersion }] =
    useNextTemplateVersion(parentId);

  const navigate = useNavigate();

  const onSave = async (data: QuestionnaireTemplateVersionFormFieldData) => {
    await insert({
      variables: {
        object: {
          ...data,
          ParentId: parentId,
          Status: Questionnaire_Template_Version_Status_Enum.Draft,
        },
      },
    });
  };

  const defaultValuesWithVersion: QuestionnaireTemplateVersionFormFieldData = {
    ...defaultValues,
    Version: nextVersion,
  };

  if (loadingNextTemplateVersion) {
    return null;
  }

  const onDismiss = () => {
    navigate(`/third-party/questionnaire-templates/${parentId}/versions`);
  };

  return (
    <>
      <QuestionnaireVersionTemplateForm
        disableStatus={true}
        onSave={onSave}
        onDismiss={(saved) => {
          if (saved) {
            onDismiss();
          }

          setConfirmCloseVisible(true);
        }}
        parentId={parentId}
        savedStatus={Version_Status_Enum.Draft}
        isCreatingNewEntity={false}
        defaultValues={defaultValuesWithVersion}
      />

      <ConfirmModal
        isVisible={confirmCloseVisible}
        onConfirm={onDismiss}
        onDismiss={() => setConfirmCloseVisible(false)}
        header={qt('title')}
      >
        {qt('message')}
      </ConfirmModal>
    </>
  );
};

export default Tab;
