import { PreferenceCategory } from '@risksmart-app/shared/knock/schemas';
import { Feature } from '@risksmart-app/shared/organisation/Feature';

type LabelledKey = {
  label: string;
  key: string;
};

export type WorkflowTemplate = LabelledKey & {
  category?: PreferenceCategory;
  featureFlagKey?: Feature;
};

export const workflows: WorkflowTemplate[] = [
  // Actions
  { label: 'Action new', key: 'action-insert', category: 'actions' },
  { label: 'Action updated', key: 'action-update', category: 'actions' },
  { label: 'Action deleted', key: 'action-delete', category: 'actions' },
  { label: 'Action due', key: 'action-due', category: 'actions' },
  { label: 'Action overdue', key: 'action-overdue', category: 'actions' },

  // Controls
  { label: 'Control new', key: 'control-insert', category: 'controls' },
  { label: 'Control updated', key: 'control-update', category: 'controls' },
  { label: 'Control deleted', key: 'control-delete', category: 'controls' },
  {
    label: 'Control test overdue',
    key: 'control-test-overdue',
    category: 'controls',
  },
  {
    label: 'Control test due',
    key: 'control-test-due',
    category: 'controls',
  },

  // Issues
  { label: 'Issue new', key: 'issue-insert', category: 'issues' },
  { label: 'Issue updated', key: 'issue-update', category: 'issues' },
  { label: 'Issue deleted', key: 'issue-delete', category: 'issues' },
  { label: 'Issue due', key: 'issue-due', category: 'issues' },
  { label: 'Issue overdue', key: 'issue-overdue', category: 'issues' },
  // Policy
  {
    label: 'Document review due',
    key: 'policy-document-version-review-due',
    category: 'policy',
  },
  {
    label: 'Document review overdue',
    key: 'policy-document-version-review-upcoming',
    category: 'policy',
  },
  // Documents
  { label: 'Document new', key: 'document-insert', category: 'policy' },
  { label: 'Document updated', key: 'document-update', category: 'policy' },
  { label: 'Document deleted', key: 'document-delete', category: 'policy' },
  // Risks
  { label: 'Risk new', key: 'risk-insert', category: 'risks' },
  { label: 'Risk updated', key: 'risk-update', category: 'risks' },
  { label: 'Risk deleted', key: 'risk-delete', category: 'risks' },
  {
    label: 'Risk assessment overdue',
    key: 'risk-assessment-overdue',
    category: 'risks',
  },
  {
    label: 'Risk assessment due',
    key: 'risk-assessment-due',
    category: 'risks',
  },
  // Requests
  { label: 'Change request new', key: 'request-insert', category: 'requests' },
  // Attestation
  {
    label: 'Attestation required',
    key: 'attestation-record-insert',
    category: 'attestations',
    featureFlagKey: 'attestations',
  },
  {
    label: 'Policy attestation reminder',
    key: 'policy-attestation-reminder',
    category: 'attestations',
    featureFlagKey: 'attestations',
  },
  // Indicators
  { label: 'Indicator due', key: 'indicator-due', category: 'indicators' },
];
