import { Spinner } from '@cloudscape-design/components';
import { FC } from 'react';

import styles from './style.module.scss';

const Loading: FC = () => (
  <div data-testid="loading" className={styles.loadingContainer}>
    <Spinner size="large" />
  </div>
);

export default Loading;
