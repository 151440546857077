import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { publicPolicyFileUrl } from '@/utils/urls';

const Page = () => {
  const fileId = useGetGuidParam('fileId');
  const documentId = useGetGuidParam('documentId');

  return <Navigate to={publicPolicyFileUrl(documentId, fileId)} replace />;
};

export default Page;
