import { Reorder } from 'framer-motion';
import { motion } from 'framer-motion';
import React, { PropsWithChildren, useState } from 'react';

import { useRiskSmartForm } from './RiskSmartFormContext';
import style from './style.module.scss';

type Props = {
  value: unknown;
  draggable: boolean;
  onDrop?: () => void;
};

function ReorderItem({
  value,
  draggable,
  children,
  onDrop,
}: PropsWithChildren<Props>) {
  const [dragging, setDragging] = useState(false);
  const { editMode } = useRiskSmartForm();
  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <motion.div
      ref={ref}
      className={`${
        dragging ? 'bg-off_white' : ''
      } transition-colors rounded-md`}
    >
      <Reorder.Item
        drag={draggable}
        transition={
          editMode
            ? undefined
            : {
                duration: 0,
              }
        }
        className={`relative bg-white rounded-md flex items-center transition-shadow ${
          dragging && draggable
            ? 'z-50 border-2 border-teal cursor-grabbing !shadow-xl'
            : 'z-0'
        } ${
          draggable
            ? `shadow-sm mb-3 border border-solid border-grey200 p-5 ${
                dragging ? style.grabbing : style.grabbable
              }`
            : ''
        }`}
        value={value}
        onDragStart={() => setDragging(true)}
        onDragEnd={() => {
          setDragging(false);
          onDrop?.();
        }}
      >
        <div className={`flex-1 ${style.children}`}>{children}</div>
      </Reorder.Item>
    </motion.div>
  );
}

export default ReorderItem;
