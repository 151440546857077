import { FC } from 'react';

import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';
import { useGetImpactRatingByIdQuery } from '@/generated/graphql';

import ImpactRatingForm from './forms/ImpactRatingForm';
import { ImpactRatingFormFieldData } from './forms/ImpactRatingForm/impactRatingFormSchema';

type Props = {
  onDismiss: () => void;
  onSaving: (action: ImpactRatingFormFieldData) => Promise<void>;
  impactRatingId?: string;
  ratedItemId?: string;
  impactId?: string;
};

const ImpactRatingModal: FC<Props> = ({
  onDismiss,
  onSaving,
  impactRatingId,
  impactId,
  ratedItemId,
}) => {
  const { data } = useGetImpactRatingByIdQuery({
    variables: { id: impactRatingId! },
    skip: !impactRatingId,
  });
  const impactRating = data?.impact_rating?.[0];

  return (
    <ImpactRatingForm
      ratedItemId={ratedItemId}
      impactId={impactId}
      values={
        impactRating
          ? {
              ...impactRating,
              CompletedBy: impactRating.CompletedBy
                ? { value: impactRating.CompletedBy, type: 'user' }
                : null,
            }
          : undefined
      }
      readOnly={!!impactRatingId}
      onSave={onSaving}
      onDismiss={onDismiss}
      renderTemplate={(renderProps) => (
        <ModalWrapper {...renderProps} visible={true} />
      )}
    />
  );
};

export default ImpactRatingModal;
