
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_14yj0_kgglm_141",
  "no-gutters": "awsui_no-gutters_14yj0_kgglm_180",
  "grid-column": "awsui_grid-column_14yj0_kgglm_185",
  "colspan-1": "awsui_colspan-1_14yj0_kgglm_196",
  "push-1": "awsui_push-1_14yj0_kgglm_200",
  "pull-1": "awsui_pull-1_14yj0_kgglm_203",
  "colspan-2": "awsui_colspan-2_14yj0_kgglm_206",
  "push-2": "awsui_push-2_14yj0_kgglm_210",
  "pull-2": "awsui_pull-2_14yj0_kgglm_213",
  "colspan-3": "awsui_colspan-3_14yj0_kgglm_216",
  "push-3": "awsui_push-3_14yj0_kgglm_220",
  "pull-3": "awsui_pull-3_14yj0_kgglm_223",
  "colspan-4": "awsui_colspan-4_14yj0_kgglm_226",
  "push-4": "awsui_push-4_14yj0_kgglm_230",
  "pull-4": "awsui_pull-4_14yj0_kgglm_233",
  "colspan-5": "awsui_colspan-5_14yj0_kgglm_236",
  "push-5": "awsui_push-5_14yj0_kgglm_240",
  "pull-5": "awsui_pull-5_14yj0_kgglm_243",
  "colspan-6": "awsui_colspan-6_14yj0_kgglm_246",
  "push-6": "awsui_push-6_14yj0_kgglm_250",
  "pull-6": "awsui_pull-6_14yj0_kgglm_253",
  "colspan-7": "awsui_colspan-7_14yj0_kgglm_256",
  "push-7": "awsui_push-7_14yj0_kgglm_260",
  "pull-7": "awsui_pull-7_14yj0_kgglm_263",
  "colspan-8": "awsui_colspan-8_14yj0_kgglm_266",
  "push-8": "awsui_push-8_14yj0_kgglm_270",
  "pull-8": "awsui_pull-8_14yj0_kgglm_273",
  "colspan-9": "awsui_colspan-9_14yj0_kgglm_276",
  "push-9": "awsui_push-9_14yj0_kgglm_280",
  "pull-9": "awsui_pull-9_14yj0_kgglm_283",
  "colspan-10": "awsui_colspan-10_14yj0_kgglm_286",
  "push-10": "awsui_push-10_14yj0_kgglm_290",
  "pull-10": "awsui_pull-10_14yj0_kgglm_293",
  "colspan-11": "awsui_colspan-11_14yj0_kgglm_296",
  "push-11": "awsui_push-11_14yj0_kgglm_300",
  "pull-11": "awsui_pull-11_14yj0_kgglm_303",
  "colspan-12": "awsui_colspan-12_14yj0_kgglm_306",
  "push-12": "awsui_push-12_14yj0_kgglm_310",
  "pull-12": "awsui_pull-12_14yj0_kgglm_313",
  "push-0": "awsui_push-0_14yj0_kgglm_316",
  "pull-0": "awsui_pull-0_14yj0_kgglm_319",
  "offset-1": "awsui_offset-1_14yj0_kgglm_322",
  "offset-2": "awsui_offset-2_14yj0_kgglm_325",
  "offset-3": "awsui_offset-3_14yj0_kgglm_328",
  "offset-4": "awsui_offset-4_14yj0_kgglm_331",
  "offset-5": "awsui_offset-5_14yj0_kgglm_334",
  "offset-6": "awsui_offset-6_14yj0_kgglm_337",
  "offset-7": "awsui_offset-7_14yj0_kgglm_340",
  "offset-8": "awsui_offset-8_14yj0_kgglm_343",
  "offset-9": "awsui_offset-9_14yj0_kgglm_346",
  "offset-10": "awsui_offset-10_14yj0_kgglm_349",
  "offset-11": "awsui_offset-11_14yj0_kgglm_352",
  "restore-pointer-events": "awsui_restore-pointer-events_14yj0_kgglm_356"
};
  