import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import ImpactRatingFormFields from './ImpactRatingFormFields';
import {
  defaultValues,
  ImpactRatingFormFieldData,
  ImpactRatingFormSchema,
} from './impactRatingFormSchema';

export type Props = Omit<
  FormContextProps<ImpactRatingFormFieldData>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
> & {
  ratedItemId?: string;
  impactId?: string;
  beforeFieldsSlot?: ReactNode;
};

const ImpactRatingForm: FC<Props> = (props) => {
  const { t } = useTranslation();

  return (
    <CustomisableFormContext
      {...props}
      schema={ImpactRatingFormSchema}
      defaultValues={{
        ...defaultValues,
        ImpactId: props.impactId as unknown as string,
        RatedItemId: props.ratedItemId as unknown as string,
      }}
      i18n={t('impactRatings')}
      formId={'impact-rating-form'}
      parentType={Parent_Type_Enum.ImpactRating}
    >
      {props.beforeFieldsSlot}
      <ImpactRatingFormFields
        readOnly={props.readOnly}
        hideImpact={!!props.impactId}
        hideRatedItem={!!props.ratedItemId}
      />
    </CustomisableFormContext>
  );
};

export default ImpactRatingForm;
