import { z } from 'zod';

import { Acceptance_Status_Enum } from '@/generated/graphql';

import {
  CustomAttributeDataSchema,
  FilesSchema,
  StringDateSchema,
  UserOrGroupSchema,
} from '../../../../schemas/global';

export const AcceptanceSchema = z
  .object({
    Id: z.string().uuid().optional(),
    Status: z.nativeEnum(Acceptance_Status_Enum, {
      errorMap: () => ({ message: 'Required' }),
    }),
    DateAcceptedFrom: StringDateSchema,
    DateAcceptedTo: StringDateSchema,
    Details: z.string(),
    Title: z.string().min(1, { message: 'Required' }),
    approvedBy: UserOrGroupSchema.nullish(),
    requestedBy: UserOrGroupSchema.nullish(),
  })
  .and(FilesSchema)
  .and(CustomAttributeDataSchema);

export type AcceptanceFormDataFields = z.infer<typeof AcceptanceSchema>;

export const defaultValues: AcceptanceFormDataFields = {
  Details: '',
  DateAcceptedTo: '',
  DateAcceptedFrom: '',
  Status: Acceptance_Status_Enum.Pending,
  Title: '',
  newFiles: [],
  files: [],
  approvedBy: null,
  requestedBy: null,
  CustomAttributeData: null,
};
