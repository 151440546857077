import { FC } from 'react';
import { IndicatorFormDataFields } from 'src/pages/indicators/forms/indicatorSchema';

import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';

import IndicatorsDetailsForm from '../forms/IndicatorDetailsForm';

type Props = {
  onDismiss: () => void;
  onSave: (control: IndicatorFormDataFields) => Promise<void>;
};

const CreateIndicatorModal: FC<Props> = ({ onDismiss, onSave }) => {
  return (
    <IndicatorsDetailsForm
      onSave={onSave}
      onDismiss={onDismiss}
      renderTemplate={(renderProps) => (
        <ModalWrapper {...renderProps} visible={true} />
      )}
    />
  );
};

export default CreateIndicatorModal;
