import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';

import { useDashboardWidgetSettings } from '../../../../context/useDashboardWidgetSettings';
import { FilterSettings } from '../../UniversalWidget/util';
import { WidgetSettingsForm } from './WidgetSettingsForm';

type Props = {
  onDismiss: () => void;
  onSave: (data: FilterSettings) => Promise<void>;
};

export const WidgetSettingsModal = ({ onDismiss, onSave }: Props) => {
  const [settings] = useDashboardWidgetSettings<FilterSettings>();

  return (
    <WidgetSettingsForm
      renderTemplate={(renderProps) => (
        <ModalWrapper {...renderProps} visible={true} />
      )}
      settings={settings}
      onSave={onSave}
      onDismiss={onDismiss}
    />
  );
};
