import { Risk_Assessment_Result_Control_Type_Enum } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { handleError } from '@/utils/errorUtils';
import { hasColor, hasLikelihoodImpact, hasRange, range } from '@/utils/utils';

export const useComputedRating = (
  controlType: Risk_Assessment_Result_Control_Type_Enum
) => {
  const { options } = useRating(
    controlType === Risk_Assessment_Result_Control_Type_Enum.Controlled
      ? 'risk_controlled'
      : 'risk_uncontrolled'
  );

  return ({
    likelihood,
    impact,
  }: {
    likelihood: number;
    impact: number;
  }): {
    value: number;
    label: string;
    color?: string;
  } => {
    const combinedValue = Math.max(likelihood, 1) * Math.max(impact, 1); // Don't times by 0
    const rating = options.find((option) => {
      if (hasLikelihoodImpact(option)) {
        return option.likelihoodImpact.find(
          (li) => li.impact === impact && li.likelihood === likelihood
        );
      }

      return (
        hasRange(option) &&
        range(option.range[0], option.range[1]).includes(combinedValue)
      );
    });
    if (!rating) {
      handleError(
        `Cannot find rating value for likelihood ${likelihood} and impact ${impact}`
      );
    }

    return {
      label: rating?.label || 'Unknown',
      value: Number(rating?.value) || 0,
      color: hasColor(rating) ? rating.color : undefined,
    };
  };
};
