import _ from 'lodash';
import { FieldValues } from 'react-hook-form';

import { useGetFormFieldOptionsByParentTypeQuery } from '@/generated/graphql';

import { EditableFormProvider } from './CustomisableForm/EditableFormProvider';
import { FormContextInner } from './InnerFormContext';
import { FormContextProps } from './types';

export const CustomisableFormContext = <TFieldValues extends FieldValues>(
  props: FormContextProps<TFieldValues>
) => {
  const { data, loading } = useGetFormFieldOptionsByParentTypeQuery({
    variables: { parentType: props.parentType! },
    skip: !props.parentType,
  });
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <FormContextInner
      {...props}
      formFieldOptions={data?.form_field_configuration}
      renderTemplate={(innerProps) => (
        <EditableFormProvider>
          {props.renderTemplate(innerProps)}
        </EditableFormProvider>
      )}
    />
  );
};
