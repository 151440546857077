import {
  CustomAttributeDataSchema,
  InheritedContributorSchema,
  NullableStringDateSchema,
  TagsAndDepartmentsSchema,
  UserOptionSchema,
  UserOrGroupsSchema,
} from 'src/schemas/global';
import { z } from 'zod';

import { Assessment_Status_Enum } from '@/generated/graphql';

const BaseAssessmentSchema = z.object({
  Title: z.string().min(1, { message: 'Required' }),
  Summary: z.string(),
  ActualCompletionDate: NullableStringDateSchema,
  NextTestDate: NullableStringDateSchema,
  StartDate: NullableStringDateSchema,
  TargetCompletionDate: NullableStringDateSchema,
  CompletedByUser: UserOptionSchema.nullable(),
  Contributors: UserOrGroupsSchema,
  Status: z.nativeEnum(Assessment_Status_Enum),
  Outcome: z.number().nullable(),
  Owners: UserOrGroupsSchema.min(1, { message: 'Required' }),
  ancestorContributors: InheritedContributorSchema,
});

export const AssessmentFormSchema = BaseAssessmentSchema.and(
  TagsAndDepartmentsSchema
).and(CustomAttributeDataSchema);

export type AssessmentFormDataFields = z.infer<typeof AssessmentFormSchema>;

export const defaultValues: AssessmentFormDataFields = {
  Title: '',
  Summary: '',
  ActualCompletionDate: null,
  NextTestDate: null,
  StartDate: null,
  TargetCompletionDate: null,
  CompletedByUser: null,
  Status: Assessment_Status_Enum.Notstarted,
  Outcome: null,
  tags: [],
  TagTypeIds: [],
  departments: [],
  DepartmentTypeIds: [],
  CustomAttributeData: null,
  Contributors: [],
  Owners: [],
  ancestorContributors: [],
};
