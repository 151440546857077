import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ownerAndContributorInsertFields } from 'src/components/Form';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import { PageLayout } from 'src/layouts';
import { IssueFormDataFields } from 'src/pages/issues/update/forms/issueSchema';
import { Permission } from 'src/rbac/Permission';

import CustomisableRibbon from '@/components/CustomisableRibbon/CustomisableRibbon';
import {
  departmentInsertInputBuilder,
  tagInsertInputBuilder,
} from '@/components/Form';
import {
  namedOperations,
  Parent_Type_Enum,
  useGetIssuesQuery,
  useInsertIssueMutation,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import { useGetRegisterTableProps } from './config';
import { defaultRibbonFilters } from './defaultRibbonFilters';
import IssueModal from './IssueModal';

const Page: FC = () => {
  const { t } = useTranslation(['common'], {});
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'issues',
  });
  const { addNotification } = useNotifications();
  const { updateFiles } = useFileUpdate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data, loading } = useGetIssuesQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const customAttributeSchemas = data?.form_configuration.length
    ? data?.form_configuration.map(
        (schemaData) => schemaData.customAttributeSchema!
      )
    : [];

  const tableProps = useGetRegisterTableProps(
    data?.issue,
    customAttributeSchemas,
    loading
  );

  const title = st('register_title');
  const counter = getCounter(tableProps.totalItemsCount, loading);
  const [insertIssue] = useInsertIssueMutation({
    update: (cache) => {
      evictField(cache, 'issue');
      evictField(cache, 'issue_aggregate');
    },
    refetchQueries: [namedOperations.Query.getIssues],
  });

  const onSave = async (data: IssueFormDataFields) => {
    const { newFiles, files, ...issueData } = data;
    const issueId = crypto.randomUUID();
    await insertIssue({
      variables: {
        ...issueData,
        Id: issueId,
        CustomAttributeData: data.CustomAttributeData || undefined,
        Tags: tagInsertInputBuilder(data.TagTypeIds),
        Departments: departmentInsertInputBuilder(data.DepartmentTypeIds),
        ...ownerAndContributorInsertFields(data),
      },
    });

    await updateFiles({
      parentType: Parent_Type_Enum.Issue,
      parentId: issueId,
      newFiles,
      originalFiles: [], // This is always an insert so no original files
      selectedFiles: files,
    });
  };

  return (
    <PageLayout
      helpTranslationKey="issues.registerHelp"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission="insert:issue">
            <Button variant="primary" onClick={() => setIsModalVisible(true)}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <CustomisableRibbon
        items={tableProps.allItems}
        propertyFilterQuery={tableProps.propertyFilterQuery}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        filteringOptions={tableProps.propertyFilterProps.filteringOptions}
        parentType={Parent_Type_Enum.Issue}
        defaultFilters={defaultRibbonFilters}
      />
      <Table {...tableProps} loading={loading} />
      {isModalVisible && (
        <IssueModal
          onDismiss={() => setIsModalVisible(false)}
          onSaving={onSave}
        />
      )}
    </PageLayout>
  );
};

export default Page;
