import { FC } from 'react';

import { GetControlByIdQuery } from '@/generated/graphql';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import { useHasPermission } from '../../../../../rbac/Permission';
import ComplianceMonitoringPerformanceRatingRegister from './ComplianceMonitoringPerformanceRatingRegister';
import InternalAuditPerformanceRatingRegister from './InternalAuditPerformanceRatingRegister';
import PerformanceRatingRegister from './PerformanceRatingRegister';

type Props = {
  control: GetControlByIdQuery['control'][number];
};

const Tab: FC<Props> = ({ control }) => {
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    control
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    control
  );

  return (
    <>
      <PerformanceRatingRegister control={control} />
      {complianceMonitoringEnabled && canViewCompliance && (
        <ComplianceMonitoringPerformanceRatingRegister control={control} />
      )}
      {internalAuditEnabled && canViewInternalAudit && (
        <InternalAuditPerformanceRatingRegister control={control} />
      )}
    </>
  );
};

export default Tab;
