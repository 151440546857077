
    import './styles.scoped.css';
    export default {
  "breadcrumb-group": "awsui_breadcrumb-group_d19fg_4hi1l_141",
  "breadcrumb-group-list": "awsui_breadcrumb-group-list_d19fg_4hi1l_179",
  "ghost": "awsui_ghost_d19fg_4hi1l_190",
  "item": "awsui_item_d19fg_4hi1l_195",
  "ghost-item": "awsui_ghost-item_d19fg_4hi1l_196",
  "ellipsis": "awsui_ellipsis_d19fg_4hi1l_197",
  "hide": "awsui_hide_d19fg_4hi1l_204",
  "visible": "awsui_visible_d19fg_4hi1l_210",
  "icon": "awsui_icon_d19fg_4hi1l_214",
  "breadcrumbs-skeleton": "awsui_breadcrumbs-skeleton_d19fg_4hi1l_220"
};
  