import { KnockFeedProvider, KnockProvider } from '@knocklabs/react';
import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { getEnv } from '@risksmart-app/components/utils/environment';
import { FC, ReactElement } from 'react';

export const MessagesProvider: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { user } = useRisksmartUser();

  return (
    <KnockProvider
      apiKey={getEnv('REACT_APP_KNOCK_PUBLIC_API_KEY')}
      userId={user!.userId}
    >
      <KnockFeedProvider
        feedId={getEnv('REACT_APP_KNOCK_FEED_CHANNEL_ID')}
        defaultFeedOptions={{ tenant: user?.orgKey }}
      >
        {children}
      </KnockFeedProvider>
    </KnockProvider>
  );
};
