import { FC } from 'react';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import ComplianceMonitoringDocumentRatingRegister from './ComplianceMonitoringDocumentRatingRegister';
import DocumentRatingRegister from './DocumentRatingRegister';
import InternalAuditDocumentRatingRegister from './InternalAuditDocumentRatingRegister';

interface Props {
  parent: ObjectWithContributors;
}

const Tab: FC<Props> = ({ parent }) => {
  const complianceMonitoringEnabled = useIsFeatureVisibleToOrg(
    'compliance_monitoring'
  );
  const internalAuditEnabled = useIsFeatureVisibleToOrg('internal_audit');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment',
    parent
  );
  const canViewInternalAudit = useHasPermission(
    'read:internal_audit_report',
    parent
  );

  return (
    <>
      <DocumentRatingRegister parent={parent} />
      {complianceMonitoringEnabled && canViewCompliance && (
        <ComplianceMonitoringDocumentRatingRegister parent={parent} />
      )}
      {internalAuditEnabled && canViewInternalAudit && (
        <InternalAuditDocumentRatingRegister parent={parent} />
      )}
    </>
  );
};

export default Tab;
