import { PropertyFilterOption } from '@cloudscape-design/collection-hooks';

import {
  useGetControlGroupsQuery,
  useGetDepartmentsQuery,
  useGetTagsQuery,
  useGetUserGroupsQuery,
  useGetUsersQuery,
} from '@/generated/graphql';

/*
NOTE: useCollection doesn't support nested objects, so items like tags get turning into a string [object, object] in the filtering options
 https://github.com/cloudscape-design/collection-hooks/issues/30
The current recommendation is to flatten the data.

This gets around the issue by adding all tags as drop down options on the filter input
*/
export const useFixFilterOptions = (
  filteringOptions: readonly PropertyFilterOption[]
): PropertyFilterOption[] => {
  const userProperties = ['owners'];
  const tagProperties = ['tags'];
  const departmentProperties = ['AssessmentDepartments', 'departments'];
  const userAndGroupProperties = [
    'allOwners',
    'allContributors',
    'allApprovers',
    'allRequesters',
    'currentApprovers',
    'nextApprovers',
  ];
  const controlGroupProperties = ['ControlGroups'];
  const hasControlGroups = filteringOptions.some((c) =>
    controlGroupProperties.includes(c.propertyKey)
  );
  const { data: users } = useGetUsersQuery();
  const { data: groups } = useGetUserGroupsQuery();
  const { data: tags } = useGetTagsQuery();
  const { data: departments } = useGetDepartmentsQuery();
  const { data: controlGroups } = useGetControlGroupsQuery({
    skip: !hasControlGroups,
  });

  // Remove [object, object] on tags.
  const newFilterOptions = filteringOptions.filter(
    (f) =>
      !tagProperties.includes(f.propertyKey) &&
      !departmentProperties.includes(f.propertyKey) &&
      !userProperties.includes(f.propertyKey) &&
      !userAndGroupProperties.includes(f.propertyKey) &&
      !controlGroupProperties.includes(f.propertyKey)
  );

  for (const filterProperty of tagProperties) {
    const tagFilterOptions =
      tags?.tag_type.map((t) => ({
        propertyKey: filterProperty,
        label: t.Name || '',
        value: t.TagTypeId || '',
      })) || [];

    newFilterOptions.push(...tagFilterOptions);
  }

  for (const departmentProperty of departmentProperties) {
    const departmentsFilterOptions =
      departments?.department_type.map((t) => ({
        propertyKey: departmentProperty,
        label: t.Name || '',
        value: t.DepartmentTypeId || '',
      })) || [];

    newFilterOptions.push(...departmentsFilterOptions);
  }

  for (const userProperty of userProperties) {
    const usersFilterOptions =
      users?.user.map((t) => ({
        propertyKey: userProperty,
        label: t.FriendlyName || '',
        value: t.Id || '',
      })) || [];

    newFilterOptions.push(...usersFilterOptions);
  }

  for (const userAndGroupProperty of userAndGroupProperties) {
    const usersFilterOptions =
      users?.user.map((t) => ({
        propertyKey: userAndGroupProperty,
        label: t.FriendlyName || '',
        value: t.Id || '',
      })) || [];
    newFilterOptions.push(...usersFilterOptions);

    const groupFilterOptions =
      groups?.user_group.map((t) => ({
        propertyKey: userAndGroupProperty,
        label: t.Name || '',
        value: t.Id || '',
      })) || [];
    newFilterOptions.push(...groupFilterOptions);
  }

  for (const controlGroupProperty of controlGroupProperties) {
    const controlGroupsFilterOptions =
      controlGroups?.control_group.map((t) => ({
        propertyKey: controlGroupProperty,
        label: t.Title || '',
        value: t.Id || '',
      })) || [];

    newFilterOptions.push(...controlGroupsFilterOptions);
  }

  return newFilterOptions;
};
