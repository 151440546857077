import { FC } from 'react';

interface Props {
  items: { label: string }[];
}

const CommaSeparatedCell: FC<Props> = ({ items }) => {
  return <span>{toSingleCell(items)}</span>;
};

export const toSingleCell = (items: { label: string }[]) => {
  return items
    .map((u) => u.label)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
};

export default CommaSeparatedCell;
