
    import './styles.scoped.css';
    export default {
  "segment__path": "awsui_segment__path_1edmh_1pipm_157",
  "segment": "awsui_segment_1edmh_1pipm_157",
  "segment__hover": "awsui_segment__hover_1edmh_1pipm_184",
  "label": "awsui_label_1edmh_1pipm_198",
  "root": "awsui_root_1edmh_1pipm_212",
  "content": "awsui_content_1edmh_1pipm_216",
  "content--small": "awsui_content--small_1edmh_1pipm_216",
  "content--fit-height": "awsui_content--fit-height_1edmh_1pipm_219",
  "content--without-labels": "awsui_content--without-labels_1edmh_1pipm_222",
  "content--medium": "awsui_content--medium_1edmh_1pipm_228",
  "content--large": "awsui_content--large_1edmh_1pipm_240",
  "status-container": "awsui_status-container_1edmh_1pipm_257",
  "chart-container": "awsui_chart-container_1edmh_1pipm_261",
  "chart-container--fit-height": "awsui_chart-container--fit-height_1edmh_1pipm_265",
  "chart-container-chart-plot": "awsui_chart-container-chart-plot_1edmh_1pipm_270",
  "chart-container-chart-plot--fit-height": "awsui_chart-container-chart-plot--fit-height_1edmh_1pipm_273",
  "inner-content": "awsui_inner-content_1edmh_1pipm_279",
  "segment__highlight": "awsui_segment__highlight_1edmh_1pipm_290",
  "segment--dimmed": "awsui_segment--dimmed_1edmh_1pipm_305",
  "segment--highlighted": "awsui_segment--highlighted_1edmh_1pipm_308",
  "label--dimmed": "awsui_label--dimmed_1edmh_1pipm_325",
  "label--align-right": "awsui_label--align-right_1edmh_1pipm_328",
  "label-text": "awsui_label-text_1edmh_1pipm_336",
  "label__description": "awsui_label__description_1edmh_1pipm_344",
  "label-line": "awsui_label-line_1edmh_1pipm_351",
  "label--highlighted": "awsui_label--highlighted_1edmh_1pipm_355",
  "popover-header": "awsui_popover-header_1edmh_1pipm_359"
};
  