import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControlFormFieldData,
  ControlFormSchema,
  defaultValues,
} from 'src/pages/controls/update/forms/controlSchema';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import { Parent_Type_Enum } from '@/generated/graphql';

import ControlFormFields from '../update/forms/ControlFormFields';

type Props = {
  onDismiss: () => void;
  onSave: (control: ControlFormFieldData) => Promise<void>;
};

const ControlCreateModal: FC<Props> = ({ onDismiss, onSave }) => {
  const { t } = useTranslation();

  return (
    <ModalForm
      i18n={t('controls')}
      onSave={onSave}
      schema={ControlFormSchema}
      defaultValues={{ ...defaultValues }}
      onDismiss={onDismiss}
      formId={'control-form'}
      visible={true}
      parentType={Parent_Type_Enum.Control}
    >
      <ControlFormFields />
    </ModalForm>
  );
};

export default ControlCreateModal;
