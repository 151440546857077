import { FC } from 'react';

import ButtonDropdown from '@/components/ButtonDropdown';

export type ActionItem = {
  text: string;
  id: string;

  onItemClick: () => void | Promise<void>;
};

export type Props = {
  buttonText: string;
  items: ActionItem[];
  variant?: 'primary' | 'normal';
  testId?: string;
};

const ActionsButton: FC<Props> = ({ buttonText, items, variant, testId }) => {
  return (
    <ButtonDropdown
      data-testid={testId}
      items={items}
      variant={variant || 'normal'}
      onItemClick={(event) => {
        items.find((option) => option.id === event.detail.id)?.onItemClick();
      }}
    >
      {buttonText}
    </ButtonDropdown>
  );
};

export default ActionsButton;
