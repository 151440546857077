
    import './styles.scoped.css';
    export default {
  "dropdown-content-wrapper": "awsui_dropdown-content-wrapper_qwoo0_eylyk_149",
  "awsui-motion-fade-in-dropdown": "awsui_awsui-motion-fade-in-dropdown_qwoo0_eylyk_1",
  "refresh": "awsui_refresh_qwoo0_eylyk_170",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_qwoo0_eylyk_1",
  "root": "awsui_root_qwoo0_eylyk_195",
  "interior": "awsui_interior_qwoo0_eylyk_231",
  "dropdown": "awsui_dropdown_qwoo0_eylyk_149",
  "use-portal": "awsui_use-portal_qwoo0_eylyk_242",
  "is-empty": "awsui_is-empty_qwoo0_eylyk_288",
  "dropdown-drop-up": "awsui_dropdown-drop-up_qwoo0_eylyk_295",
  "with-limited-width": "awsui_with-limited-width_qwoo0_eylyk_299",
  "dropdown-drop-left": "awsui_dropdown-drop-left_qwoo0_eylyk_302",
  "dropdown-drop-right": "awsui_dropdown-drop-right_qwoo0_eylyk_305",
  "occupy-entire-width": "awsui_occupy-entire-width_qwoo0_eylyk_308",
  "stretch-beyond-trigger-width": "awsui_stretch-beyond-trigger-width_qwoo0_eylyk_311",
  "hide-block-border": "awsui_hide-block-border_qwoo0_eylyk_318",
  "open": "awsui_open_qwoo0_eylyk_327",
  "nowrap": "awsui_nowrap_qwoo0_eylyk_336",
  "dropdown-content": "awsui_dropdown-content_qwoo0_eylyk_149",
  "stretch-trigger-height": "awsui_stretch-trigger-height_qwoo0_eylyk_347"
};
  