import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControlGroupFormFieldData,
  defaultValues,
  useControlGroupSchema,
} from 'src/pages/control-groups/update/forms/controlGroupSchema';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  namedOperations,
  Parent_Type_Enum,
  useInsertControlGroupMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ControlGroup from './update/forms/ControlGroupFormFields';

type Props = {
  onDismiss: (updated?: boolean) => void;
};

const ControlGroupModal: FC<Props> = ({ onDismiss }) => {
  const { t } = useTranslation('common');
  const ControlGroupSchema = useControlGroupSchema();
  const [mutate] = useInsertControlGroupMutation({
    update: (cache) => {
      evictField(cache, 'control_group');
    },
    refetchQueries: [
      namedOperations.Query.getControlGroupById,
      namedOperations.Query.getControlGroupsFlat,
    ],
  });

  const onSave = async (data: ControlGroupFormFieldData) => {
    await mutate({
      variables: { ...data, Owner: data.Owner.value },
    });
  };

  return (
    <ModalForm
      onSave={onSave}
      defaultValues={defaultValues}
      i18n={t('controlGroups')}
      onDismiss={onDismiss}
      schema={ControlGroupSchema}
      formId={'control-group-form'}
      visible={true}
      parentType={Parent_Type_Enum.ControlGroup}
    >
      <ControlGroup />
    </ModalForm>
  );
};

export default ControlGroupModal;
