import {
  Badge,
  Icon,
  Popover,
  SpaceBetween,
} from '@cloudscape-design/components';
import { FC } from 'react';

import { TagPartsFragment } from '@/generated/graphql';

import styles from './style.module.scss';
interface Props {
  id: string;
  tags?: TagPartsFragment[] | null;
}

const TagsPopover: FC<Props> = ({ id, tags }) => {
  return (
    <Popover
      dismissButton={true}
      position="left"
      size="small"
      // TODO: Translations
      header="Tags"
      content={
        <SpaceBetween direction="horizontal" size="xs">
          {tags?.map((tag) => (
            <Badge key={id + tag.type?.Name}>{tag.type?.Name}</Badge>
          ))}
        </SpaceBetween>
      }
    >
      <div>
        {tags?.length || '0'}&nbsp;
        <Icon
          variant="subtle"
          size="small"
          {...{ className: styles.icon }}
          svg={
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8714 6.15091L5.89298 0.172461C5.80726 0.0867467 5.70012 0.0438895 5.59298 0.0438895L0.428571 0.0224609C0.192857 0.0224609 0 0.215318 0 0.451032L0.0214286 5.61544C0.0214286 5.72258 0.0642857 5.82973 0.15 5.91544L6.12845 11.8939C6.21416 11.9796 6.32131 12.0225 6.42845 12.0225C6.53559 12.0225 6.66416 11.9796 6.72845 11.8939L11.8713 6.75103C11.957 6.66532 11.9999 6.55818 11.9999 6.45103C11.9999 6.34389 11.9571 6.2152 11.8714 6.15091ZM3.42857 4.30805C2.95714 4.30805 2.57143 3.92234 2.57143 3.45091C2.57143 2.97948 2.95714 2.59377 3.42857 2.59377C3.9 2.59377 4.28571 2.97948 4.28571 3.45091C4.28571 3.92234 3.9 4.30805 3.42857 4.30805Z"
                fill="#A2A2B3"
              />
              <circle cx="3.82247" cy="3.86196" r="1.46554" fill="white" />
            </svg>
          }
        />
      </div>
    </Popover>
  );
};

export default TagsPopover;
