import 'pdfmake/build/vfs_fonts';

import { useTranslation } from 'react-i18next';

import {
  Parent_Type_Enum,
  useGetActionByIdLazyQuery,
  useGetActionUpdatesByParentActionIdLazyQuery,
} from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { getFriendlyId } from '@/utils/friendlyId';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable, tableHeaders } from '@/utils/pdf/table';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

const useExporter = (actionId: string): [() => void, { loading: boolean }] => {
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.Action);
  const { t: actionFields } = useTranslation(['common'], {
    keyPrefix: 'actions.fields',
  });
  const { t } = useTranslation(['common']);
  const { t: actionUpdateColumns } = useTranslation(['common'], {
    keyPrefix: 'actionUpdates.columns',
  });
  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });
  const { getLabel: getPriorityLabel } = useRating('priority');
  const { getLabel: getStatusLabel } = useRating('action_status');
  const [getAction, getActionResult] = useGetActionByIdLazyQuery({
    variables: {
      _eq: actionId,
    },
  });
  const [getActionUpdates, getActionUpdatesResult] =
    useGetActionUpdatesByParentActionIdLazyQuery({
      variables: {
        _eq: actionId,
      },
    });

  const loading =
    getActionResult.loading ||
    getActionUpdatesResult.loading ||
    customAttributesLoading;
  const exportFunc = async () => {
    const { data: actionData } = await getAction();
    const { data: actionUpdatesData } = await getActionUpdates();
    const action = actionData?.action?.[0];

    const actionUpdates = actionUpdatesData?.action_update;

    if (!action || !actionUpdates) {
      return;
    }

    const actionUpdateTableData = actionUpdates.map((au) => [
      au.Title,
      au.Description,
      au.createdByUser?.FriendlyName ?? '',
      toLocalDate(au.CreatedAtTimestamp),
    ]);
    const title = `${action.Title} (${getFriendlyId(
      Parent_Type_Enum.Action,
      action.SequentialId
    )})`;

    const detailFields = [
      createField(fields('Title'), action.Title),
      createField(fields('Description'), action.Description),
      createField(fields('Owner'), getOwnerValue(action)),
      createField(fields('Contributor'), getContributorValue(action)),
      createField(fields('Status'), getStatusLabel(action.Status)),
      createField(actionFields('DateRaised'), toLocalDate(action.DateRaised)),
      createField(
        actionFields('TargetCloseDate'),
        toLocalDate(action.ClosedDate)
      ),
      createField(actionFields('Priority'), getPriorityLabel(action.Priority)),
      createField(fields('Tags'), getTagsValue(action)),
      createField(fields('Departments'), getDepartmentsValue(action)),
      ...(await getCustomAttribute(action)),
    ];

    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),
      optionalTableSection(
        t('actionUpdates.tab_title'),
        createTable({
          widths: ['*', '*', 70, 50],
          body: [
            tableHeaders([
              actionUpdateColumns('title'),
              actionUpdateColumns('description'),
              columns('created_by_username'),
              columns('date'),
            ]),
            ...actionUpdateTableData,
          ],
        })
      ),
    ]);
    download(docDefinition);
  };

  return [exportFunc, { loading }];
};

export default useExporter;
