import { FC, useState } from 'react';

import { Header } from '../../theme/risksmart-themed/components';
import { QuestionData } from './form-configs/question';
import { FormQuestionModal } from './FormQuestionModal';
import { useFormBuilderContext } from './useFormBuilderContext';

type FormBuilderAddSectionProps = {
  parentId: string;
};

export const FormBuilderAddQuestion: FC<FormBuilderAddSectionProps> = ({
  parentId,
}) => {
  const [isAddingQuestion, setIsAddingQuestion] = useState<boolean>(false);
  const { uiSchema } = useFormBuilderContext();

  const initialQuestionData: QuestionData = {
    questionTitle: '',
    placeholder: '',
  };

  const [questionData, setQuestionData] =
    useState<QuestionData>(initialQuestionData);

  return (
    <>
      <div
        className={
          'flex w-max p-3 transition hover:cursor-pointer hover:bg-grey150 rounded-md'
        }
        onClick={() => setIsAddingQuestion(true)}
      >
        <Header variant={'h3'}>+ Add question</Header>
      </div>

      <FormQuestionModal
        action={'add'}
        parentId={parentId}
        questionData={questionData}
        setQuestionData={setQuestionData}
        isEditingQuestion={isAddingQuestion}
        setIsEditingQuestion={setIsAddingQuestion}
        initialQuestionData={initialQuestionData}
        uiSchema={uiSchema}
      />
    </>
  );
};
