import i18n from '@risksmart-app/components/providers/i18n';
import { RouteObject } from 'react-router-dom';

import { ActionsUpdateBreadcrumb, ActionsUpdatePage } from '../pages';

export const actionRoute: RouteObject = {
  path: ':actionId',
  handle: {
    breadcrumb: ActionsUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <ActionsUpdatePage activeTabId="details" showDeleteButton={true} />
      ),
    },
    {
      path: 'updates',
      handle: {
        title: () => i18n.format(i18n.t('update_other'), 'capitalize'),
      },
      element: <ActionsUpdatePage activeTabId="updates" />,
    },
    {
      path: 'linked-items',
      handle: {
        title: () => i18n.t('linkedItems.tab_title'),
      },
      element: <ActionsUpdatePage activeTabId="linkedItems" />,
    },
  ],
};
