
    import './styles.scoped.css';
    export default {
  "content-enter": "awsui_content-enter_gwq0h_4ay5v_157",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_gwq0h_4ay5v_1",
  "trigger-expanded": "awsui_trigger-expanded_gwq0h_4ay5v_179",
  "icon": "awsui_icon_gwq0h_4ay5v_193",
  "root": "awsui_root_gwq0h_4ay5v_207",
  "expand-button": "awsui_expand-button_gwq0h_4ay5v_245",
  "expanded": "awsui_expanded_gwq0h_4ay5v_253",
  "icon-container": "awsui_icon-container_gwq0h_4ay5v_263",
  "icon-container-container": "awsui_icon-container-container_gwq0h_4ay5v_267",
  "wrapper": "awsui_wrapper_gwq0h_4ay5v_271",
  "wrapper-default": "awsui_wrapper-default_gwq0h_4ay5v_279",
  "wrapper-inline": "awsui_wrapper-inline_gwq0h_4ay5v_279",
  "wrapper-footer": "awsui_wrapper-footer_gwq0h_4ay5v_279",
  "wrapper-navigation": "awsui_wrapper-navigation_gwq0h_4ay5v_283",
  "wrapper-container": "awsui_wrapper-container_gwq0h_4ay5v_286",
  "wrapper-compact": "awsui_wrapper-compact_gwq0h_4ay5v_290",
  "header-deprecated": "awsui_header-deprecated_gwq0h_4ay5v_303",
  "wrapper-expanded": "awsui_wrapper-expanded_gwq0h_4ay5v_309",
  "header": "awsui_header_gwq0h_4ay5v_303",
  "header-wrapper": "awsui_header-wrapper_gwq0h_4ay5v_346",
  "header-actions-wrapper": "awsui_header-actions-wrapper_gwq0h_4ay5v_358",
  "header-button": "awsui_header-button_gwq0h_4ay5v_364",
  "header-container-button": "awsui_header-container-button_gwq0h_4ay5v_364",
  "header-container": "awsui_header-container_gwq0h_4ay5v_364",
  "header-navigation": "awsui_header-navigation_gwq0h_4ay5v_399",
  "header-text": "awsui_header-text_gwq0h_4ay5v_436",
  "content": "awsui_content_gwq0h_4ay5v_157",
  "content-default": "awsui_content-default_gwq0h_4ay5v_449",
  "content-inline": "awsui_content-inline_gwq0h_4ay5v_449",
  "content-footer": "awsui_content-footer_gwq0h_4ay5v_453",
  "content-expanded": "awsui_content-expanded_gwq0h_4ay5v_457",
  "content-compact": "awsui_content-compact_gwq0h_4ay5v_460",
  "focusable": "awsui_focusable_gwq0h_4ay5v_464",
  "click-target": "awsui_click-target_gwq0h_4ay5v_479"
};
  