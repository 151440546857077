
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_wih1l_nj52p_149",
  "tools": "awsui_tools_wih1l_nj52p_160",
  "tools-filtering": "awsui_tools-filtering_wih1l_nj52p_168",
  "tools-align-right": "awsui_tools-align-right_wih1l_nj52p_182",
  "tools-pagination": "awsui_tools-pagination_wih1l_nj52p_186",
  "tools-preferences": "awsui_tools-preferences_wih1l_nj52p_186",
  "tools-small": "awsui_tools-small_wih1l_nj52p_192",
  "table": "awsui_table_wih1l_nj52p_198",
  "table-layout-fixed": "awsui_table-layout-fixed_wih1l_nj52p_204",
  "wrapper": "awsui_wrapper_wih1l_nj52p_208",
  "variant-stacked": "awsui_variant-stacked_wih1l_nj52p_215",
  "wrapper-content-measure": "awsui_wrapper-content-measure_wih1l_nj52p_215",
  "variant-container": "awsui_variant-container_wih1l_nj52p_215",
  "has-footer": "awsui_has-footer_wih1l_nj52p_218",
  "has-header": "awsui_has-header_wih1l_nj52p_221",
  "cell-merged": "awsui_cell-merged_wih1l_nj52p_238",
  "cell-merged-content": "awsui_cell-merged-content_wih1l_nj52p_250",
  "empty": "awsui_empty_wih1l_nj52p_266",
  "loading": "awsui_loading_wih1l_nj52p_270",
  "selection-control": "awsui_selection-control_wih1l_nj52p_279",
  "selection-control-header": "awsui_selection-control-header_wih1l_nj52p_286",
  "header-secondary": "awsui_header-secondary_wih1l_nj52p_292",
  "variant-full-page": "awsui_variant-full-page_wih1l_nj52p_304",
  "table-has-header": "awsui_table-has-header_wih1l_nj52p_310",
  "header-controls": "awsui_header-controls_wih1l_nj52p_321",
  "variant-embedded": "awsui_variant-embedded_wih1l_nj52p_330",
  "variant-borderless": "awsui_variant-borderless_wih1l_nj52p_330",
  "footer-wrapper": "awsui_footer-wrapper_wih1l_nj52p_335",
  "footer": "awsui_footer_wih1l_nj52p_335",
  "footer-with-pagination": "awsui_footer-with-pagination_wih1l_nj52p_343",
  "footer-pagination": "awsui_footer-pagination_wih1l_nj52p_351",
  "thead-active": "awsui_thead-active_wih1l_nj52p_355",
  "row": "awsui_row_wih1l_nj52p_356",
  "row-selected": "awsui_row-selected_wih1l_nj52p_357"
};
  