import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import AssessmentFindingForm from 'src/pages/assessments/forms/AssessmentFindingForm';
import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from 'src/pages/assessments/useAssessmentTypeConfig';
import { ObjectWithContributors } from 'src/rbac/Permission';

interface Props {
  assessment?: ObjectWithContributors;
  assessmentMode: AssessmentTypeEnum;
}

const AssessmentResultCreateTab: FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation(['common']);
  const assessmentId = useGetGuidParam('assessmentId');
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(props.assessmentMode);
  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(resultsRegisterUrl(assessmentId));
    }
  };

  return (
    <AssessmentFindingForm
      readonly={!props.assessment}
      parentAssessment={props.assessment}
      onDismiss={onDismiss}
      assessmentId={assessmentId}
      formType={location.state?.type}
      ratingResultType={location.state?.ratingType}
      preselectedAssessedItemIds={location.state?.ids}
      assessmentMode={props.assessmentMode}
      navigateToResults={true}
      showAssessmentSelector={true}
      header={t('details')}
    />
  );
};

export default AssessmentResultCreateTab;
