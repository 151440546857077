import { uuid4 } from '@sentry/utils';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GetAllComplianceMonitoringAssessmentsDocument,
  GetAssessmentByIdDocument,
  GetAssessmentsDocument,
  GetComplianceMonitoringAssessmentByIdDocument,
  GetInternalAuditReportByIdDocument,
  InsertAssessmentMutation,
  InsertComplianceMonitoringAssessmentMutation,
  InsertInternalAuditReportMutation,
  useInsertAssessmentMutation,
  useInsertComplianceMonitoringAssessmentMutation,
  useInsertInternalAuditReportMutation,
} from 'src/generated/graphql';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { evictField } from '@/utils/graphqlUtils';

import AssessmentForm from '../../forms/AssessmentForm';
import { AssessmentFormDataFields } from '../../forms/AssessmentForm/assessmentSchema';
import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from '../../useAssessmentTypeConfig';

interface Props {
  assessmentMode: AssessmentTypeEnum;
}

const getMutation = (assessmentMode: AssessmentTypeEnum) => {
  switch (assessmentMode) {
    case 'rating':
      return useInsertAssessmentMutation;
    case 'compliance_monitoring_assessment':
      return useInsertComplianceMonitoringAssessmentMutation;
    case 'internal_audit_report':
      return useInsertInternalAuditReportMutation;
  }
};

const mapData = (
  data:
    | InsertAssessmentMutation
    | InsertComplianceMonitoringAssessmentMutation
    | InsertInternalAuditReportMutation
    | null
    | undefined
) => {
  if ((data as InsertAssessmentMutation)?.insert_assessment_one) {
    return (data as InsertAssessmentMutation).insert_assessment_one;
  }
  if (
    (data as InsertComplianceMonitoringAssessmentMutation)
      ?.insert_compliance_monitoring_assessment_one
  ) {
    return (data as InsertComplianceMonitoringAssessmentMutation)
      .insert_compliance_monitoring_assessment_one;
  }
  if (
    (data as InsertInternalAuditReportMutation)
      ?.insert_internal_audit_report_one
  ) {
    return (data as InsertInternalAuditReportMutation)
      .insert_internal_audit_report_one;
  }
};

const AssessmentCreateTab: FC<Props> = ({ assessmentMode }) => {
  const navigate = useNavigate();
  const {
    routing: { detailsUrl },
  } = useAssessmentTypeConfig(assessmentMode);
  const mutation = getMutation(assessmentMode);
  const [mutate] = mutation({
    update: (cache) => {
      evictField(cache, 'assessment');
      evictField(cache, 'assessment_aggregate');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'internal_audit_report_aggregate');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'compliance_monitoring_assessment_aggregate');
    },
    refetchQueries: [
      GetAssessmentByIdDocument,
      GetAssessmentsDocument,
      GetInternalAuditReportByIdDocument,
      GetInternalAuditReportByIdDocument,
      GetComplianceMonitoringAssessmentByIdDocument,
      GetAllComplianceMonitoringAssessmentsDocument,
    ],
  });

  const onSave = async (variables: AssessmentFormDataFields) => {
    const Id = uuid4();
    const { data } = await mutate({
      variables: {
        ...variables,
        Id,
        CustomAttributeData: variables.CustomAttributeData || undefined,
        ...ownerAndContributorInsertFields(variables, Id),
        Tags: tagInsertInputBuilder(variables.TagTypeIds, Id),
        Departments: departmentInsertInputBuilder(
          variables.DepartmentTypeIds,
          Id
        ),
        CompletedByUser: variables.CompletedByUser?.value,
      },
    });
    const result = mapData(data);
    if (result?.Id) {
      navigate(detailsUrl(result.Id), { replace: true });
    }
  };

  const onDismiss = (saved?: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return (
    <AssessmentForm
      onSave={onSave}
      onDismiss={onDismiss}
      assessmentMode={assessmentMode}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};

export default AssessmentCreateTab;
