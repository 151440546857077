import { useMemo } from 'react';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';

import { TableFields, TableRecord } from '../types';
import { convertSchemasToFieldConfigs } from '../utils/customAttributes';
import { TableFieldsWithCustomAttributes } from './useAddCustomAttributeFieldData';

type Options<T extends TableRecord> = {
  customAttributeSchema: CustomAttributeFields[] | CustomAttributeFields | null;
  fields: TableFields<T>;
  useRelativeDates: boolean;
};

/**
 * Add custom attributes field definitions
 *
 * @param options
 * @returns
 */
export const useAddCustomAttributeFieldDefinitions = <T extends TableRecord>({
  customAttributeSchema,
  fields,
  useRelativeDates,
}: Options<T>): TableFieldsWithCustomAttributes<T> => {
  return useMemo(() => {
    const customFields = convertSchemasToFieldConfigs({
      customAttributeSchemas: customAttributeSchema
        ? Array.isArray(customAttributeSchema)
          ? customAttributeSchema
          : [customAttributeSchema]
        : [],
      useRelativeDates,
    });

    return {
      ...fields,
      ...customFields,
    };
  }, [customAttributeSchema, useRelativeDates, fields]);
};
