import {
  HorizontalLayout,
  JsonFormsCellRendererRegistryEntry,
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  RankedTester,
  rankWith,
  uiTypeIs,
  VerticalLayout,
} from '@jsonforms/core';
import { FC, useState } from 'react';

import {
  Header,
  SpaceBetween,
} from '../../../theme/risksmart-themed/components';
import { SectionData } from '../../FormBuilder/form-configs/section';
import { FormBuilderAddQuestion } from '../../FormBuilder/FormBuilderAddQuestion';
import { FormSectionModal } from '../../FormBuilder/FormSectionModal';
import { CustomUISchemaElement } from '../../FormBuilder/useFormBuilderContext';
import FormEditButton from '../../FormEditButton/FormEditButton';
import { renderChildren } from './utils';

export const groupLayoutTester: RankedTester = rankWith(3, uiTypeIs('Group'));

export type GroupLayoutRendererProps = {
  layout: VerticalLayout | HorizontalLayout;
  schema: JsonSchema;
  uischema: CustomUISchemaElement;
  path: string;
  renderers: JsonFormsRendererRegistryEntry[];
  cells: JsonFormsCellRendererRegistryEntry[] | undefined;
  enabled: boolean;
};

export const GroupLayoutRenderer: FC<GroupLayoutRendererProps> = ({
  schema,
  uischema, // uischema is only a partial ui schema for this specific group
  path,
  renderers,
  cells,
  enabled,
}) => {
  const label = uischema?.label ? uischema.label : '';
  const initialSectionData = {
    sectionTitle: label,
  };

  const [isEditingSection, setIsEditingSection] = useState(false);
  const [sectionData, setSectionData] =
    useState<SectionData>(initialSectionData);

  return (
    <div className={'flex w-full mb-5'}>
      <div className={'flex w-full justify-start pr-[52px]'}>
        <div
          className={
            'flex flex-col basis-2/3 rounded-md border border-solid border-[#B9B9C6] px-4 py-3 gap-y-3'
          }
        >
          <div className={'flex items-start'}>
            <SpaceBetween direction={'horizontal'} size={'xs'}>
              <Header variant={'h2'}>{label}</Header>
              <FormEditButton onClick={() => setIsEditingSection(true)} />
            </SpaceBetween>
          </div>

          {renderChildren({
            layout: uischema,
            schema,
            path,
            renderers,
            cells,
            enabled,
          })}

          <FormBuilderAddQuestion parentId={uischema.id || ''} />

          <FormSectionModal
            action={'edit'}
            sectionData={sectionData}
            setSectionData={setSectionData}
            isEditingSection={isEditingSection}
            setIsEditingSection={setIsEditingSection}
            initialSectionData={initialSectionData}
            uiSchema={uischema}
          />
        </div>
      </div>
    </div>
  );
};
