import { useMemo } from 'react';

import { JSONObject } from '@/types/types';

import { Dataset, FieldConfig, TableFields, TableRecord } from '../types';
import { getCustomAttributeDataForRecord } from '../utils/customAttributes';

export type TableFieldsWithCustomAttributes<T extends TableRecord> =
  TableFields<T> & {
    [key: string]: FieldConfig<{
      CustomAttributeData: JSONObject;
    }>;
  };

type Options<T extends TableRecord> = {
  data?: Dataset<T> | undefined;
  tableFields: TableFieldsWithCustomAttributes<T>;
};

/**
 * Spreads custom attribute fields onto the dataset so they can be used as
 * columns in the datagrid
 *
 * @param
 * @returns
 */
export const useAddCustomAttributeFieldData = <T extends TableRecord>({
  data,
  tableFields,
}: Options<T>) => {
  // Get all fields and convert into table columns.
  return useMemo(() => {
    return (data ?? []).map((record) => ({
      ...record,
      ...getCustomAttributeDataForRecord(tableFields, record),
    }));
  }, [tableFields, data]);
};
