
    import './styles.scoped.css';
    export default {
  "calendar": "awsui_calendar_mgja0_1i1e2_149",
  "awsui-motion-fade-in-0": "awsui_awsui-motion-fade-in-0_mgja0_1i1e2_1",
  "root": "awsui_root_mgja0_1i1e2_172",
  "wide": "awsui_wide_mgja0_1i1e2_206",
  "focus-lock": "awsui_focus-lock_mgja0_1i1e2_213",
  "trigger-flexbox": "awsui_trigger-flexbox_mgja0_1i1e2_217",
  "calendar-container": "awsui_calendar-container_mgja0_1i1e2_221",
  "one-grid": "awsui_one-grid_mgja0_1i1e2_224",
  "calendar-header": "awsui_calendar-header_mgja0_1i1e2_228",
  "calendar-header-months-wrapper": "awsui_calendar-header-months-wrapper_mgja0_1i1e2_234",
  "calendar-header-month": "awsui_calendar-header-month_mgja0_1i1e2_234",
  "calendar-next-month-btn": "awsui_calendar-next-month-btn_mgja0_1i1e2_253",
  "calendar-prev-month-btn": "awsui_calendar-prev-month-btn_mgja0_1i1e2_256",
  "first-grid": "awsui_first-grid_mgja0_1i1e2_260",
  "second-grid": "awsui_second-grid_mgja0_1i1e2_261",
  "date-and-time-container": "awsui_date-and-time-container_mgja0_1i1e2_265",
  "date-and-time-wrapper": "awsui_date-and-time-wrapper_mgja0_1i1e2_271",
  "footer": "awsui_footer_mgja0_1i1e2_278",
  "has-clear-button": "awsui_has-clear-button_mgja0_1i1e2_287",
  "footer-button-wrapper": "awsui_footer-button-wrapper_mgja0_1i1e2_296",
  "icon-wrapper": "awsui_icon-wrapper_mgja0_1i1e2_303",
  "label": "awsui_label_mgja0_1i1e2_308",
  "label-enabled": "awsui_label-enabled_mgja0_1i1e2_313",
  "label-text": "awsui_label-text_mgja0_1i1e2_317",
  "label-token-nowrap": "awsui_label-token-nowrap_mgja0_1i1e2_322",
  "mode-switch": "awsui_mode-switch_mgja0_1i1e2_326",
  "dropdown": "awsui_dropdown_mgja0_1i1e2_330",
  "dropdown-content": "awsui_dropdown-content_mgja0_1i1e2_352",
  "start-date-input": "awsui_start-date-input_mgja0_1i1e2_361",
  "start-time-input": "awsui_start-time-input_mgja0_1i1e2_362",
  "end-date-input": "awsui_end-date-input_mgja0_1i1e2_363",
  "end-time-input": "awsui_end-time-input_mgja0_1i1e2_364",
  "validation-section": "awsui_validation-section_mgja0_1i1e2_368",
  "validation-error": "awsui_validation-error_mgja0_1i1e2_372",
  "clear-button": "awsui_clear-button_mgja0_1i1e2_376",
  "apply-button": "awsui_apply-button_mgja0_1i1e2_380",
  "cancel-button": "awsui_cancel-button_mgja0_1i1e2_384",
  "calendar-aria-live": "awsui_calendar-aria-live_mgja0_1i1e2_388"
};
  