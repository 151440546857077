import { RelationFilesSchema } from 'src/schemas/global';
import { z } from 'zod';

import { supportedCsvFiles } from '../csvFiles';

export const DataImportSchema = z
  .object({
    newFiles: z.array(z.instanceof(File)),
    files: RelationFilesSchema,
  })
  .superRefine((data, ctx) => {
    for (const file of data.newFiles) {
      if (!supportedCsvFiles.includes(file.name)) {
        ctx.addIssue({
          message: `The file "${file.name}" is not supported. Must be one of ${supportedCsvFiles.join(', ')}`,
          path: ['global'],
          code: 'custom',
        });
      }
    }
    if (data.newFiles.length + (data.files?.length ?? 0) == 0) {
      ctx.addIssue({
        message: 'At least one csv file is required',
        path: ['newFiles'],
        code: 'custom',
      });
    }
  });
export type DataImportDataFields = z.infer<typeof DataImportSchema>;

export const defaultValues: DataImportDataFields = {
  files: [],
  newFiles: [],
};
