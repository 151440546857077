import { useTranslation } from 'react-i18next';
import { MAX_COL_WIDTH } from 'src/App.config';

import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import { ControlGroupFlatFields, ControlGroupTableFields } from './types';
import { useLabelledFields } from './useLabelledFields';

const useGetFieldConfig = (): TableFields<ControlGroupTableFields> => {
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controlGroups.columns',
  });

  return {
    Title: {
      header: st('title'),
      cell: (item) => (
        <Link variant="secondary" href={`/control-groups/${item.Id}`}>
          {item.Title}
        </Link>
      ),
      maxWidth: MAX_COL_WIDTH,
      isRowHeader: true,
    },
    OwnerName: {
      header: st('owner_username'),
      cell: (item) => item.OwnerName || '-',
      maxWidth: MAX_COL_WIDTH,
    },
    Description: {
      header: st('description'),
      cell: (item) => item.Description || '-',
      maxWidth: MAX_COL_WIDTH,
    },

    LinkedControlCount: {
      header: st('linked_controls'),
      cell: (item) => item.LinkedControlCount ?? '-',
      filterOptions: {
        filteringProperties: {
          operators: ['!=', '>', '<', '>=', '<='],
        },
      },
    },
    //------------------
    Id: {
      header: t('id'),
    },
    Owner: {
      header: st('owner_id'),
    },
    ModifiedAtTimestamp: dateColumn(t('updated_on'), 'ModifiedAtTimestamp'),
    CreatedByUser: {
      header: t('created_by_id'),
    },
    CreatedByUserName: {
      header: t('created_by_username'),
    },
  };
};

export const useGetCollectionTableProps = (
  records: ControlGroupFlatFields[] | undefined,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ControlGroupTableFields> => {
  const { t: st } = useTranslation(['common'], { keyPrefix: 'controlGroups' });
  const fields = useGetFieldConfig();
  const labelledFields = useLabelledFields(records);

  return useGetTableProps({
    data: labelledFields,
    customAttributeSchema: customAttributeSchema,
    entityLabel: st('entity_name'),
    emptyCollectionAction: <></>,
    storageKey: 'ControlGroupRegisterTable-PreferencesV1',
    enableFiltering: true,
    initialColumns: ['Title', 'OwnerName', 'Description', 'LinkedControlCount'],
    fields,
  });
};
