import { TabsProps } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';

import { GetInternalAuditByIdQuery } from '@/generated/graphql';
import { filterEmptyTabs } from '@/utils/tabUtils';

import ActionsTab from '../../actions/ActionsTab';
import IssuesTab from '../../issues/IssueTab';
import InternalAuditCreateTab from '../create/tabs/InternalAuditCreateTab';
import DetailsTab from './tabs/details/Tab';
import ReportsTab from './tabs/reports/Tab';
import RisksTab from './tabs/risks/Tab';

export const useTabs = (
  internalAudit:
    | GetInternalAuditByIdQuery['internal_audit_entity'][number]
    | undefined = undefined
) => {
  const detailsOnly = !internalAudit;
  const { t, i18n } = useTranslation(['common']);
  const tabs: TabsProps.Tab[] = [
    {
      label: i18n.format(t('details'), 'capitalize'),
      id: 'details',
      content: detailsOnly ? <InternalAuditCreateTab /> : <DetailsTab />,
      href: detailsOnly ? undefined : `/internal-audits/${internalAudit.Id}`,
    },
    {
      label: i18n.format(t('internal_audit_report_other'), 'capitalize'),
      id: 'reports',
      content: internalAudit && <ReportsTab internalAudit={internalAudit} />,
      href: `/internal-audits/${internalAudit?.Id}/reports`,
      disabled: detailsOnly,
    },
    {
      label: i18n.format(t('issue_other'), 'capitalize'),
      id: 'issues',
      content: internalAudit && <IssuesTab parent={internalAudit} />,
      href: `/internal-audits/${internalAudit?.Id}/issues`,
      disabled: detailsOnly,
    },
    {
      label: i18n.format(t('action_other'), 'capitalize'),
      id: 'actions',
      content: internalAudit && <ActionsTab parent={internalAudit} />,
      href: `/internal-audits/${internalAudit?.Id}/actions`,
      disabled: detailsOnly,
    },
    {
      label: i18n.format(t('risk_other'), 'capitalize'),
      id: 'risks',
      content: internalAudit && <RisksTab parent={internalAudit} />,
      href: `/internal-audits/${internalAudit?.Id}/risks`,
      disabled: detailsOnly,
    },
  ];

  return filterEmptyTabs(tabs);
};
