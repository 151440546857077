import {
  PropertyFilterOperator,
  PropertyFilterOperatorExtended,
} from '@cloudscape-design/collection-hooks';
import { DateRangePickerProps } from '@cloudscape-design/components';
import dayjs from 'dayjs';

import { DateTimeForm } from '@/components/DateTimeFilter/DateTimeForm';
import { toLocalDate, useGetDateRangeFormat } from '@/utils/dateUtils';

import { convertDateRangeValues } from '../../pages/dashboards/widgets/filterHelpers';
import { RelativeDateTimeForm } from './RelativeDateTimeForm';

const dateFilterOperator = (
  operator: PropertyFilterOperator
): PropertyFilterOperatorExtended<string | null> => ({
  operator,
  form: DateTimeForm,
  format: toLocalDate,
  match: (itemValue, tokenValue) => {
    const item = itemValue as string;
    const token = tokenValue as string;

    const itemDate = dayjs(item);

    switch (operator) {
      case '<':
        return itemDate.isBefore(token, 'day');
      case '<=':
        return itemDate.isBefore(token, 'day') || itemDate.isSame(token, 'day');
      case '>':
        return itemDate.isAfter(token, 'day');
      case '>=':
        return itemDate.isAfter(token, 'day') || itemDate.isSame(token, 'day');
      case ':':
      case '=':
        return itemDate.isSame(token, 'day');
      case '!:':
      case '!=':
        return !itemDate.isSame(token, 'day');
      default:
        return false;
    }
  },
});

const dateFilterOperators = (
  operators: PropertyFilterOperator[]
): PropertyFilterOperatorExtended<string | null>[] =>
  operators.map(dateFilterOperator);

export const defaultDateFilterOperators = dateFilterOperators([
  '<=',
  '>=',
  '>',
  '<',
  '=',
]);

export const dateRangeFilterOperators: PropertyFilterOperatorExtended<
  string | null
>[] = [
  {
    operator: '=',
    form: RelativeDateTimeForm,
    format: useGetDateRangeFormat,
    match: (itemValue, tokenValue: string | null) => {
      const tItemValue = itemValue as string;
      const tTokenValue =
        (tokenValue as unknown as DateRangePickerProps.Value) || null;

      const token = convertDateRangeValues(tTokenValue);
      const itemDate = dayjs(tItemValue);

      const isSameDay =
        itemDate.isSame(token.startDate, 'day') ||
        itemDate.isSame(token.endDate, 'day');

      if (isSameDay) {
        return true;
      }

      const isAfterStartDate = itemDate.isAfter(token.startDate, 'day');
      const isBeforeEndDate = itemDate.isBefore(token.endDate, 'day');

      return isAfterStartDate && isBeforeEndDate;
    },
  },
];
