import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useRating } from '@/hooks/use-rating';
import { isPastDate } from '@/utils/dateUtils';

import { ControlTestFlatFields, ControlTestTableFields } from './types';

export const useLabelledFields = (
  records: ControlTestFlatFields[] | undefined
) => {
  const { t } = useTranslation(['common']);
  const { getLabel } = useRating('effectiveness');

  const testTypeLookup = useMemo(() => t('testTypes'), [t]);

  return useMemo<ControlTestTableFields[] | undefined>(() => {
    return records?.map((d) => {
      return {
        ...d,
        ParentTitle: d.parent?.Title ?? null,
        CreatedByUserName: d.createdByUser?.FriendlyName ?? null,
        SubmitterNameLabelled: d.submitter?.FriendlyName ?? null,
        DesignEffectivenessLabelled: getLabel(d.DesignEffectiveness),
        PerformanceEffectivenessLabelled: getLabel(d.PerformanceEffectiveness),
        OverallEffectivenessLabelled: getLabel(d.OverallEffectiveness),
        TestType: testTypeLookup[d.TestType as keyof typeof testTypeLookup],
        ControlSequentialId: d.parent?.SequentialId ?? null,
        Overdue: isPastDate(d.parent?.NextTestDate ?? null),
        tags: d.parent?.tags ?? [],
        departments: d.parent?.departments ?? [],
        NextTestDate: d.parent?.NextTestDate ?? null,
        FileCount: d.files_aggregate.aggregate?.count ?? 0,
      };
    });
  }, [records, getLabel, testTypeLookup]);
};
