import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { getEnv } from '@risksmart-app/components/utils/environment';
import axios, { AxiosInstance } from 'axios';
import { createContext, FC, ReactNode, useContext, useEffect } from 'react';

import { handleError } from '@/utils/errorUtils';

const instance = axios.create({
  baseURL: getEnv('REACT_APP_REST_API_URL'),
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    handleError(error);

    return Promise.reject(error);
  }
);

const AxiosContext = createContext<AxiosInstance>(axios);

type Props = {
  children: ReactNode;
};

export const AxiosProvider: FC<Props> = ({ children }) => {
  const { getAccessTokenSilently } = useRisksmartUser();
  useEffect(() => {
    instance.interceptors.request.use(async function (config) {
      const token = await getAccessTokenSilently();
      config.headers['Authorization'] = `Bearer ${token}`;

      return config;
    });
  }, [getAccessTokenSilently]);

  return (
    <AxiosContext.Provider value={instance}>{children}</AxiosContext.Provider>
  );
};

export const useAxios = () => useContext(AxiosContext);
