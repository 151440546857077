import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import DocumentAssessmentResultFormFields from './DocumentAssessmentResultFormFields';
import {
  DocumentAssessmentResultFormDataFields,
  DocumentAssessmentResultSchema,
} from './documentAssessmentResultSchema';

// TODO: possible change for Pick or just create new props
type Props = Omit<
  FormContextProps<DocumentAssessmentResultFormDataFields>,
  'schema' | 'formId' | 'parentType' | 'i18n'
> & {
  beforeFieldsSlot?: ReactNode;
  showSelector?: AssessmentTypeEnum;
  assessmentMode: AssessmentTypeEnum;
  disableDocumentSelector: boolean;
};

const getParentType = (assessmentMode?: AssessmentTypeEnum) => {
  switch (assessmentMode) {
    case 'rating':
      return Parent_Type_Enum.DocumentAssessmentResult;
    case 'internal_audit_report':
      return Parent_Type_Enum.InternalAuditReportDocumentAssessmentResult;
    case 'compliance_monitoring_assessment':
      return Parent_Type_Enum.SecondLineAssessmentDocumentAssessmentResult;
  }

  return Parent_Type_Enum.DocumentAssessmentResult;
};

const DocumentAssessmentResultForm: FC<Props> = (props) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={DocumentAssessmentResultSchema}
      i18n={t('assessmentResults')}
      formId={'assessment-result-form'}
      renderTemplate={props.renderTemplate}
      parentType={getParentType(props.assessmentMode)}
    >
      {props.beforeFieldsSlot}
      <DocumentAssessmentResultFormFields
        readOnly={props.readOnly}
        disableDocumentSelector={props.disableDocumentSelector}
        showSelector={props.showSelector}
      />
    </CustomisableFormContext>
  );
};

export default DocumentAssessmentResultForm;
