import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';

import ControlledTabs from '@/components/ControlledTabs/ControlledTabs';
import Loading from '@/components/Loading';
import { useGetQuestionnaireTemplateVersionByIdQuery } from '@/generated/graphql';

import { PageLayout } from '../../../../../../layouts';
import { useTabs } from './useTabs';

interface Props {
  activeTabId: 'details';
}

const QuestionnaireTemplateVersionUpdatePage: FC<Props> = ({ activeTabId }) => {
  const tabs = useTabs();
  const questionnaireTemplateVersionId = useGetGuidParam(
    'questionnaireTemplateVersionId'
  );

  const { data: questionnaireTemplateVersionData, loading } =
    useGetQuestionnaireTemplateVersionByIdQuery({
      fetchPolicy: 'no-cache',
      variables: { Id: questionnaireTemplateVersionId! },
      skip: !questionnaireTemplateVersionId,
    });

  if (loading) {
    return (
      <PageLayout>
        <Loading />
      </PageLayout>
    );
  }

  if (
    questionnaireTemplateVersionData?.questionnaire_template_version?.Id !==
    questionnaireTemplateVersionId
  ) {
    throw new PageNotFound(
      `Version with id ${questionnaireTemplateVersionId} not found`
    );
  }

  const entity =
    questionnaireTemplateVersionData?.questionnaire_template_version;
  const title = entity?.parent?.Title ? `${entity.parent.Title}` : 'Version';
  const counter = entity?.Version ? `(${entity.Version})` : '';

  return (
    <PageLayout title={title} counter={counter}>
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
    </PageLayout>
  );
};

export default QuestionnaireTemplateVersionUpdatePage;
