import { FieldValues } from 'react-hook-form';

import { CustomisableFormContext } from './CustomisableFormContext';
import { PageWrapper } from './PageWrapper';
import { CommonProps } from './types';

export const PageForm = <TFieldValues extends FieldValues>(
  props: CommonProps<TFieldValues>
) => {
  return (
    <CustomisableFormContext
      {...props}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    />
  );
};
