export enum PromptId {
  UseSimplerLanguage = 'UseSimplerLanguage',
  FixSpellingAndGrammar = 'FixSpellingAndGrammar',
  ImproveWriting = 'ImproveWriting',
  GenerateARiskDescription = 'GenerateARiskDescription',
  GenerateAControlDescription = 'GenerateAControlDescription',
  MakeMoreConcise = 'MakeMoreConcise',
  MakeLonger = 'MakeLonger',
  TranslateToEnglish = 'TranslateToEnglish',
  TranslateToFrench = 'TranslateToFrench',
  TranslateToGerman = 'TranslateToGerman',
  TranslateToSpanish = 'TranslateToSpanish',
  TranslateToItalian = 'TranslateToItalian',
  TranslateToPortuguese = 'TranslateToPortuguese',
  TranslateToBrazilianPortuguese = 'TranslateToBrazilianPortuguese',
}
