import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { t } from 'i18next';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ObjectWithContributors,
  Permission,
  useHasPermission,
} from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  Parent_Type_Enum,
  useDeleteLinkedItemsMutation,
  useGetLinkedItemsQuery,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import { useGetLinkedItemsTableProps } from './config';
import LinkItemModal from './modal/LinkItemModal';
import { LinkedItemsTableFields } from './types';

interface Props {
  parent: ObjectWithContributors;
  parentType?: Parent_Type_Enum;
  includeAssessments?: boolean;
}

const LinkedItemsTab: FC<Props> = ({
  parent,
  parentType,
  includeAssessments,
}) => {
  useI18NSummaryHelpContent('linkedItems.tabHelp');
  const { t } = useTranslation('common', { keyPrefix: 'linkedItems' });
  const { addNotification } = useNotifications();

  const [selectedItems, setSelectedItems] = useState<LinkedItemsTableFields[]>(
    []
  );
  const canViewInternalAudit = useHasPermission('read:internal_audit_entity');
  const canViewCompliance = useHasPermission(
    'read:compliance_monitoring_assessment'
  );

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);

  const { data, loading } = useGetLinkedItemsQuery({
    variables: {
      Id: parent.Id,
      IncludeInternalAudit: canViewInternalAudit,
      IncludeCompliance: canViewCompliance,
    },
  });

  const [unlink, { loading: deleteLinkedItemsLoading }] =
    useDeleteLinkedItemsMutation({
      update: (cache) => {
        evictField(cache, 'linked_item');
        evictField(cache, 'control');
        evictField(cache, 'control_group');
        evictField(cache, 'action');
        evictField(cache, 'appetite');
        evictField(cache, 'issue');
        evictField(cache, 'document');
        evictField(cache, 'obligation');
        evictField(cache, 'risk');
        evictField(cache, 'acceptance');
        evictField(cache, 'indicator');
        evictField(cache, 'risk_score');
      },
      onError: (error) => {
        const friendlyErrorMessage = getUnlinkFriendlyErrorMessage(
          error.message
        );
        addNotification({
          type: 'error',
          content: <>{friendlyErrorMessage}</>,
        });
      },
    });

  const tableProps = useGetLinkedItemsTableProps(data, parentType, parent);

  const excludeIds = useMemo(() => {
    return [
      parent.Id,
      ...(data?.as_source.map((li) => li.Target) ?? []),
      ...(data?.as_target.map((li) => li.Source) ?? []),
    ];
  }, [data?.as_source, data?.as_target, parent.Id]);

  const onLinkItemClicked = () => {
    setShowLinkModal(true);
  };

  const onLinkItemModalDismiss = () => {
    setShowLinkModal(false);
  };

  const onUnlinkItemsClicked = () => {
    setShowUnlinkModal(true);
  };

  const onUnlinkItemsModalDismiss = () => {
    setSelectedItems([]);
    setShowUnlinkModal(false);
  };

  const unlinkItems = async () => {
    await unlink({
      variables: {
        Ids: selectedItems.map((si) => si.Id),
      },
    });
    setShowUnlinkModal(false);
  };

  return (
    <>
      <Table
        {...tableProps}
        key="Id"
        trackBy={'Id'}
        selectionType="multi"
        selectedItems={selectedItems}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        header={
          <SpaceBetween size="m">
            <TabHeader
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  <Permission
                    permission="delete:linked_item"
                    parentObject={parent}
                  >
                    <Button
                      onClick={onUnlinkItemsClicked}
                      disabled={selectedItems.length < 1}
                    >
                      Unlink{/* TODO: Translation */}
                    </Button>
                  </Permission>
                  <Permission
                    permission="insert:linked_item"
                    parentObject={parent}
                  >
                    <Button
                      iconName="add-plus"
                      onClick={onLinkItemClicked}
                      variant="primary"
                    >
                      Link items{/* TODO: Translation */}
                    </Button>
                  </Permission>
                </SpaceBetween>
              }
            >
              {t('tab_title')}
            </TabHeader>
          </SpaceBetween>
        }
        variant="embedded"
        loading={loading}
      />
      {showLinkModal && (
        <LinkItemModal
          onDismiss={onLinkItemModalDismiss}
          sourceId={parent.Id}
          excludeIds={excludeIds}
          includeAssessments={includeAssessments}
        />
      )}
      {showUnlinkModal && (
        <DeleteModal
          deleteButtonLabel="Yes, remove"
          onDismiss={onUnlinkItemsModalDismiss}
          isVisible={true}
          onDelete={unlinkItems}
          loading={deleteLinkedItemsLoading}
          header="Remove link?"
        >
          <p>
            Are you sure you want to remove the link between these items?
            {/* TODO: Translation */}
          </p>
        </DeleteModal>
      )}
    </>
  );
};

export default LinkedItemsTab;

export const getUnlinkFriendlyErrorMessage = (errorMessage: string): string => {
  // @TODO: this is temporary, remove once we've implemented supported for all object types
  const types = errorMessage.match(/(?<=\{).*?(?=\})/g);
  if (types && types?.length >= 2) {
    const sourceType = t(
      // @ts-ignore
      `${types[0]}_other`
    );
    const targetType = t(
      // @ts-ignore
      `${types[1]}_other`
    );

    // TODO: Translation
    return `Removing links between ${sourceType} and ${targetType} is not supported.`;
  } else {
    // TODO: Translation
    return 'Failed to remove links';
  }
};
