import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useGetDetailParentPath } from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal';
import {
  Parent_Type_Enum,
  useDeleteImpactMutation,
  useGetImpactByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getFriendlyId } from '@/utils/friendlyId';
import { evictField } from '@/utils/graphqlUtils';

import { useTabs } from './useTabs';

type Props = {
  activeTabId: 'details' | 'ratings';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const impactId = useGetGuidParam('impactId');
  const navigate = useNavigate();
  const { t: st } = useTranslation(['common'], { keyPrefix: 'impacts' });
  const defaultTitle = st('fallback_title');
  const [deleteImpact, deleteResult] = useDeleteImpactMutation({
    update: (cache) => {
      evictField(cache, 'impact');
      evictField(cache, 'impact_rating');
      evictField(cache, 'impact_aggregate');
      evictField(cache, 'impact_rating_aggregate');
    },
  });
  const { data, error } = useGetImpactByIdQuery({
    variables: {
      id: impactId,
    },
  });
  if (error) {
    throw error;
  }
  const impact = data?.impact?.[0];
  const tabs = useTabs(impact);
  const parentPath = useGetDetailParentPath(impactId);
  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    asyncAction: async () => {
      await deleteImpact({
        variables: {
          Id: impactId,
        },
      });
      setIsDeleteModalVisible(false);
      navigate(parentPath);

      return true;
    },
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
  });

  if (data?.impact.length === 0) {
    throw new PageNotFound(`Impact with id ${impactId} not found`);
  }
  const counter =
    impact &&
    `(${getFriendlyId(Parent_Type_Enum.Impact, impact.SequentialId)})`;

  return (
    <PageLayout
      title={impact?.Name ?? defaultTitle}
      meta={{
        title: defaultTitle,
      }}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Permission permission="delete:impact" parentObject={impact}>
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={st('delete_modal_title')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
