import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetIssueUpdatesByParentIssueIdLazyQuery } from '@/generated/graphql';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useUpdatesExportTable = (
  issueId: string
): [() => Promise<ContentTable>, boolean] => {
  const [getIssueUpdates, getIssueUpdatesResult] =
    useGetIssueUpdatesByParentIssueIdLazyQuery({
      variables: {
        _eq: issueId,
      },
    });

  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: updatesColumns } = useTranslation(['common'], {
    keyPrefix: 'actionUpdates.columns',
  });
  const createExportTable = async () => {
    const { data: issueUpdatesData } = await getIssueUpdates();
    const issueUpdatesTableData = (issueUpdatesData?.issue_update ?? []).map(
      (i) => [
        i.Title,
        i.Description,
        i.createdByUser?.FriendlyName ?? '-',
        toLocalDate(i.ModifiedAtTimestamp),
      ]
    );

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          updatesColumns('title'),
          updatesColumns('description'),
          columns('created_by_username'),
          updatesColumns('date'),
        ]),
        ...issueUpdatesTableData,
      ],
    });
  };

  return [createExportTable, getIssueUpdatesResult.loading];
};

export default useUpdatesExportTable;
