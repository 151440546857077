import { FC } from 'react';

import { TextareaInput } from '@/components/Form/ControlledTextarea/ControlledTextarea';
import HelpLink from '@/components/HelpPanel/HelpLink';

import { CustomAttributeProps } from './CustomAttributeProps';

export const CustomAttributeTextarea: FC<CustomAttributeProps> = ({
  label,
  onChange,
  value,
  error,
  disabled,
  description,
}) => (
  <TextareaInput
    info={
      description && <HelpLink id={label} title={label} content={description} />
    }
    label={label}
    onChange={onChange}
    value={value}
    errorMessage={error}
    disabled={disabled}
  />
);
