import { DatePicker, SpaceBetween } from '@cloudscape-design/components';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { FieldValues, Noop, RefCallBack } from 'react-hook-form';

import { FormField } from '@/components/Form/Form/FormField';
import HelpLink from '@/components/HelpPanel/HelpLink';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import SimpleDateInput from '../SimpleDateInput';
import { ControlledBaseProps } from '../types';
import styles from './style.module.scss';

interface DatePickerInputProps {
  label: string;
  onChange?: (value: string) => void;
  value?: string;
  onBlur?: Noop;
  locale?: string;
  errorMessage?: string;
  disabled?: boolean;
  innerRef?: RefCallBack;
  placeholder?: string;
  testId?: string;
  info?: ReactNode;
}

export const DatePickerInput = ({
  label,
  value = '',
  onChange,
  errorMessage,
  disabled,
  onBlur,
  innerRef,
  placeholder,
  testId,
  info,
  ...rest
}: DatePickerInputProps) => {
  const valueToIso = (val: string) => (val ? new Date(val).toISOString() : '');

  return (
    <FormField
      info={info}
      label={label}
      errorText={errorMessage}
      stretch
      testId={testId}
    >
      <SpaceBetween direction="horizontal" size="xs" alignItems="center">
        <SimpleDateInput
          value={value}
          onChange={(val) => {
            onChange?.(valueToIso(val));
          }}
          onBlur={onBlur}
          disabled={disabled}
        />
        <DatePicker
          {...{ className: styles.hideDateInput }}
          ref={innerRef}
          onBlur={onBlur}
          onChange={(e) => {
            onChange?.(valueToIso(e.detail.value));
          }}
          value={dayjs(value || Date.now()).format('YYYY-MM-DD')}
          nextMonthAriaLabel="Next month"
          placeholder={placeholder || 'DD/MM/YYYY'}
          previousMonthAriaLabel="Previous month"
          todayAriaLabel="Today"
          disabled={disabled}
          {...rest}
        />
      </SpaceBetween>
    </FormField>
  );
};

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  locale?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  testId?: string;
}

export const ControlledDatePicker = <T extends FieldValues>({
  control,
  name,
  label,
  placeholder,
  disabled,
  onChange,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
  testId,
  description,
  ...rest
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  return (
    <Controller
      forceRequired={forceRequired}
      defaultRequired={defaultRequired}
      allowDefaultValue={allowDefaultValue}
      name={name}
      control={control}
      render={({ field: { ref, onChange: formOnChange, onBlur, value } }) => (
        <DatePickerInput
          info={
            description && (
              <HelpLink title={label} content={description} id={label} />
            )
          }
          label={label}
          onChange={(val: string) => {
            formOnChange(val);
            onChange?.(val);
          }}
          testId={testId}
          onBlur={onBlur}
          disabled={disabled || readOnly}
          value={value}
          innerRef={ref}
          placeholder={placeholder}
          errorMessage={error?.message}
          {...rest}
        />
      )}
    />
  );
};
