import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import _ from 'lodash';
import { FieldValues, useFormContext } from 'react-hook-form';

import {
  GetRiskListQuery,
  Parent_Type_Enum,
  useGetRiskListQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';

import ControlledSelect from '../ControlledSelect';
import { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  single?: boolean;
  testId?: string;
}

export const ControlledRiskSelect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();
  const { watch } = useFormContext();
  const value = watch(props.name);
  const { data, loading } = useGetRiskListQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  return (
    <ControlledSelect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      options={getOptions(data, value)}
      filteringType="auto"
    />
  );
};

export const getOptions = (
  data: GetRiskListQuery | undefined,
  selectedRiskId: string | undefined
) => {
  const risksById = _.keyBy(data?.risk, 'Id');
  let options: {
    Id: string;
    Title?: string;
    SequentialId?: number | null | undefined;
  }[] = data?.risk ?? [];

  options = options.concat(
    (data?.node ?? []).filter(
      (n) => !risksById[n.Id] && n.Id === selectedRiskId
    )
  );

  return (
    options.map((r) => ({
      value: r.Id,
      label: r.Title ?? getFriendlyId(Parent_Type_Enum.Risk, r.SequentialId),
    })) ?? []
  );
};
