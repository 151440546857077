import { FC, PropsWithChildren, ReactNode, useState } from 'react';

import { FormStateContext } from './useFormState';

interface FormStateContextProviderProps {
  children: ReactNode;
}

export const FormStateProvider: FC<FormStateContextProviderProps> = ({
  children,
}) => {
  const [isFormDirty, setIsFormDirty] = useState(false);

  return (
    <FormStateContext.Provider value={{ isFormDirty, setIsFormDirty }}>
      {children}
    </FormStateContext.Provider>
  );
};

export const withFormStateProvider = <P extends object>(
  Component: FC<PropsWithChildren<P>>
) => {
  return function ProviderWrapper(props: PropsWithChildren<P>) {
    return (
      <FormStateProvider>
        <Component {...props} />
      </FormStateProvider>
    );
  };
};
