import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Parent_Type_Enum,
  useGetAssessmentResultByIdQuery,
} from 'src/generated/graphql';
import AssessmentFindingForm from 'src/pages/assessments/forms/AssessmentFindingForm';
import { FormType, RatingResultType } from 'src/pages/assessments/forms/types';
import { AssessmentTypeEnum } from 'src/pages/assessments/useAssessmentTypeConfig';
import { ObjectWithContributors } from 'src/rbac/Permission';

interface Props {
  assessment: ObjectWithContributors;
  assessmentMode: AssessmentTypeEnum;
  showAssessmentSelector: boolean;
  navigateToResults: boolean;
}

const AssessmentResultUpdateTab: FC<Props> = ({
  assessment,
  assessmentMode,
  showAssessmentSelector,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common'], { keyPrefix: 'assessmentResults' });
  const findingId = useGetGuidParam('findingId');
  const { data } = useGetAssessmentResultByIdQuery({
    variables: {
      Id: findingId,
    },
  });

  const assessmentResult = data?.assessment_result_parent?.[0];

  const onDismiss = () => {
    navigate('..');
  };
  let readonly = false;
  let formType: FormType | undefined = undefined;
  let ratingFormType: RatingResultType | undefined = undefined;

  switch (assessmentResult?.ResultType) {
    case Parent_Type_Enum.RiskAssessmentResult:
    case Parent_Type_Enum.DocumentAssessmentResult:
    case Parent_Type_Enum.ObligationAssessmentResult:
    case Parent_Type_Enum.ImpactRating: {
      readonly = true;
      formType = 'rating';
      ratingFormType = assessmentResult?.ResultType;
      break;
    }
    case Parent_Type_Enum.TestResult: {
      formType = 'rating';
      ratingFormType = assessmentResult?.ResultType;
      break;
    }
    case Parent_Type_Enum.Action: {
      formType = 'action';
      break;
    }
    case Parent_Type_Enum.Issue: {
      formType = 'issue';
      break;
    }
  }

  return (
    <>
      {formType && assessment && (
        <AssessmentFindingForm
          readonly={!assessment || readonly}
          parentAssessment={assessment}
          onDismiss={onDismiss}
          assessmentId={assessment.Id}
          assessmentResultId={findingId}
          formType={formType}
          ratingResultType={ratingFormType}
          assessmentMode={assessmentMode}
          showAssessmentSelector={showAssessmentSelector}
          navigateToResults={false}
          header={t('edit_modal_title')}
        />
      )}
    </>
  );
};

export default AssessmentResultUpdateTab;
