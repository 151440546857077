import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import IssueFormFields from './IssueFormFields';
import {
  defaultValues,
  IssueFormDataFields,
  IssueFormSchema,
} from './issueSchema';

type Props = Omit<
  FormContextProps<IssueFormDataFields>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
> & { beforeFieldsSlot?: ReactNode };

const IssueForm: FC<Props> = ({ beforeFieldsSlot, ...props }) => {
  const { t } = useTranslation('common');

  return (
    <CustomisableFormContext
      {...props}
      schema={IssueFormSchema}
      defaultValues={defaultValues}
      i18n={t('issues')}
      formId={'issue-form'}
      parentType={Parent_Type_Enum.Issue}
      approvalConfig={{
        object: props.values?.Id ? { Id: props.values.Id } : undefined,
      }}
    >
      {beforeFieldsSlot}
      <IssueFormFields readOnly={props.readOnly} />
    </CustomisableFormContext>
  );
};

export default IssueForm;
