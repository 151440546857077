
    import './styles.scoped.css';
    export default {
  "tabs-header": "awsui_tabs-header_14rmt_1vz8f_282",
  "tabs-header-list": "awsui_tabs-header-list_14rmt_1vz8f_290",
  "pagination-button": "awsui_pagination-button_14rmt_1vz8f_307",
  "pagination-button-left": "awsui_pagination-button-left_14rmt_1vz8f_314",
  "pagination-button-left-scrollable": "awsui_pagination-button-left-scrollable_14rmt_1vz8f_317",
  "pagination-button-right": "awsui_pagination-button-right_14rmt_1vz8f_321",
  "pagination-button-right-scrollable": "awsui_pagination-button-right-scrollable_14rmt_1vz8f_324",
  "tabs-tab": "awsui_tabs-tab_14rmt_1vz8f_329",
  "tabs-tab-label": "awsui_tabs-tab-label_14rmt_1vz8f_338",
  "tabs-tab-header-container": "awsui_tabs-tab-header-container_14rmt_1vz8f_349",
  "tabs-tab-dismiss": "awsui_tabs-tab-dismiss_14rmt_1vz8f_360",
  "tabs-tab-action": "awsui_tabs-tab-action_14rmt_1vz8f_360",
  "refresh": "awsui_refresh_14rmt_1vz8f_364",
  "tabs-tab-disabled": "awsui_tabs-tab-disabled_14rmt_1vz8f_368",
  "tabs-tab-link": "awsui_tabs-tab-link_14rmt_1vz8f_410",
  "tabs-tab-active": "awsui_tabs-tab-active_14rmt_1vz8f_486",
  "tabs-header-with-divider": "awsui_tabs-header-with-divider_14rmt_1vz8f_493",
  "tabs-tab-focusable": "awsui_tabs-tab-focusable_14rmt_1vz8f_497",
  "root": "awsui_root_14rmt_1vz8f_501",
  "tabs": "awsui_tabs_14rmt_1vz8f_282",
  "tabs-content": "awsui_tabs-content_14rmt_1vz8f_542",
  "fit-height": "awsui_fit-height_14rmt_1vz8f_546",
  "tabs-content-active": "awsui_tabs-content-active_14rmt_1vz8f_552",
  "tabs-content-wrapper": "awsui_tabs-content-wrapper_14rmt_1vz8f_566",
  "with-paddings": "awsui_with-paddings_14rmt_1vz8f_566",
  "tabs-container-content-wrapper": "awsui_tabs-container-content-wrapper_14rmt_1vz8f_577",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_14rmt_1vz8f_588",
  "tabs-tab-focused": "awsui_tabs-tab-focused_14rmt_1vz8f_592"
};
  