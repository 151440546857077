
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_gfwv3_198wh_189",
  "horizontal": "awsui_horizontal_gfwv3_198wh_192",
  "vertical": "awsui_vertical_gfwv3_198wh_198",
  "list": "awsui_list_gfwv3_198wh_203",
  "grid": "awsui_grid_gfwv3_198wh_222",
  "list-item": "awsui_list-item_gfwv3_198wh_234",
  "toggle-container-inline": "awsui_toggle-container-inline_gfwv3_198wh_241",
  "toggle": "awsui_toggle_gfwv3_198wh_241",
  "description": "awsui_description_gfwv3_198wh_336",
  "separator": "awsui_separator_gfwv3_198wh_340"
};
  