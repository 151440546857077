import { ButtonProps } from '@cloudscape-design/components';
import { FC } from 'react';

import Button from '../Button';
import styles from './style.module.scss';

const DeleteButton: FC<Omit<ButtonProps, 'variant' | 'formAction'>> = (
  props
) => {
  return (
    <Button
      variant="primary"
      formAction="none"
      {...props}
      {...{ className: styles.button }}
    />
  );
};

export default DeleteButton;
