import { useApolloClient } from '@apollo/client';
import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';

import { ownerAndContributorIds } from '@/components/Form';
import {
  Parent_Type_Enum,
  useGetActionsQuery,
  useInsertChildActionMutation,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import ActionModal from './ActionModal';
import { ActionsDashboard } from './ActionsDashboard';
import { useGetCollectionTableProps } from './config';
import { ActionFormFieldData } from './update/forms/actionsSchema';

const Page: FC = () => {
  const [isAddActionOpen, setIsAddActionOpen] = useState(false);
  const { updateFiles } = useFileUpdate();
  const handleActionCreateClose = () => {
    setIsAddActionOpen(false);
  };
  const apolloClient = useApolloClient();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'actions' });
  const title = st('register_title');
  const { addNotification } = useNotifications();
  const { data, loading, refetch } = useGetActionsQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const [mutate] = useInsertChildActionMutation();

  const saveAction = async (data: ActionFormFieldData) => {
    const { files, newFiles } = data;
    const result = await mutate({
      variables: {
        ...data,
        CustomAttributeData: data.CustomAttributeData || undefined,
        ...ownerAndContributorIds(data),
      },
    });
    const actionId = result.data?.insertChildAction?.Id;
    if (!actionId) {
      throw new Error('Missing actionId');
    }
    await updateFiles({
      parentType: Parent_Type_Enum.Action,
      parentId: actionId,
      newFiles,
      originalFiles: data?.files,
      selectedFiles: files,
    });
    evictField(apolloClient.cache, 'action');
    evictField(apolloClient.cache, 'action_aggregate');
    evictField(apolloClient.cache, 'internal_audit_entity');
    refetch();
  };

  const tableProps = useGetCollectionTableProps(
    data?.action,
    data?.form_configuration?.[0]?.customAttributeSchema
  );
  const counter = getCounter(tableProps.totalItemsCount, loading);

  return (
    <PageLayout
      helpTranslationKey="actions.registerHelp"
      title={title}
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission
            permission="insert:action"
            canHaveAccessAsContributor={true}
          >
            <Button variant="primary" onClick={() => setIsAddActionOpen(true)}>
              {st('createNewButton')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <ActionsDashboard
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
      />
      <Table {...tableProps} loading={loading} />
      {isAddActionOpen && (
        <ActionModal
          onSaving={saveAction}
          onDismiss={handleActionCreateClose}
        />
      )}
    </PageLayout>
  );
};

export default Page;
