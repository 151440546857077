
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_19bso_v8vof_149",
  "inner-root": "awsui_inner-root_19bso_v8vof_150",
  "has-groups": "awsui_has-groups_19bso_v8vof_186",
  "compact-mode": "awsui_compact-mode_19bso_v8vof_190",
  "token": "awsui_token_19bso_v8vof_199",
  "inner-token": "awsui_inner-token_19bso_v8vof_200",
  "grouped": "awsui_grouped_19bso_v8vof_213",
  "list": "awsui_list_19bso_v8vof_225",
  "show-operation": "awsui_show-operation_19bso_v8vof_236",
  "select": "awsui_select_19bso_v8vof_242",
  "inner-select": "awsui_inner-select_19bso_v8vof_243",
  "token-content": "awsui_token-content_19bso_v8vof_247",
  "token-content-grouped": "awsui_token-content-grouped_19bso_v8vof_253",
  "inner-token-content": "awsui_inner-token-content_19bso_v8vof_258",
  "edit-button": "awsui_edit-button_19bso_v8vof_263",
  "dismiss-button": "awsui_dismiss-button_19bso_v8vof_264",
  "inner-dismiss-button": "awsui_inner-dismiss-button_19bso_v8vof_265",
  "token-disabled": "awsui_token-disabled_19bso_v8vof_323"
};
  