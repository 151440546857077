import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { PageNotFound } from '@risksmart-app/components/errors/errors';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from 'src/layouts';
import { Permission } from 'src/rbac/Permission';
import { useGetDetailPath } from 'src/routes/useGetDetailParentPath';

import ControlledTabs from '@/components/ControlledTabs';
import DeleteModal from '@/components/DeleteModal/DeleteModal';
import {
  namedOperations,
  useDeleteControlGroupMutation,
  useGetControlGroupByIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { evictField } from '@/utils/graphqlUtils';

import { useTabs } from './useTabs';

type Props = {
  activeTabId: 'details' | 'controls' | 'linkedItems';
};

const Page: FC<Props> = ({ activeTabId }) => {
  const controlGroupId = useGetGuidParam('controlGroupId');
  const navigate = useNavigate();
  const detailsPath = useGetDetailPath(controlGroupId);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'controlGroups',
  });
  const defaultTitle = st('fallback_title', 'Control Group');

  const { data, error } = useGetControlGroupByIdQuery({
    variables: {
      _eq: controlGroupId,
    },
  });
  if (error) {
    throw error;
  }
  const controlGroup = data?.control_group?.[0];

  const [mutate, deleteResult] = useDeleteControlGroupMutation({
    update: (cache) => {
      evictField(cache, 'control_group');
    },
    refetchQueries: [namedOperations.Query.getControlById],
  });

  const onDelete = useDeleteResultNotification({
    asyncAction: async () => {
      if (!controlGroup) {
        throw new Error('Missing control group');
      }
      await mutate({
        variables: {
          id: controlGroup.Id,
          original_timestamp: controlGroup.ModifiedAtTimestamp,
        },
      });
      navigate(`/control-groups`);

      return true;
    },
    entityName: st('entity_name', 'control group'),
  });

  if (data?.control_group.length === 0) {
    throw new PageNotFound(`Control group with id ${controlGroupId} not found`);
  }
  const tabs = useTabs(detailsPath, controlGroup);

  return (
    <PageLayout
      title={controlGroup?.Title}
      meta={{
        title: defaultTitle,
      }}
      actions={
        <Permission permission="delete:control_group">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="normal"
              formAction="none"
              onClick={() => {
                setIsDeleteModalVisible(true);
              }}
            >
              {st('delete_button', 'Delete')}
            </Button>
          </SpaceBetween>
        </Permission>
      }
    >
      <ControlledTabs
        activeTabId={activeTabId}
        tabs={tabs}
        variant="container"
      />
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => setIsDeleteModalVisible(false)}
      >
        {st('confirm_delete_message', 'Are you sure you want to delete?')}
      </DeleteModal>
    </PageLayout>
  );
};

export default Page;
