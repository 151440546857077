import { getEnv } from '@risksmart-app/components/utils/environment';
import { Feature } from '@risksmart-app/shared/organisation/Feature';
import { useFeatures } from 'src/rbac/useFeatures';

export type IsFeatureVisibleToOrg = (feature: Feature) => boolean | null;

export const useIsFeatureVisibleToOrgLazy = (): ((
  feature: Feature
) => boolean) => {
  const features = useFeatures();

  return (feature: Feature) =>
    features.includes(feature) ||
    isFeatureEnabled(`REACT_APP_FEATURE_${feature}`);
};

export const useIsFeatureVisibleToOrg = (feature: Feature): boolean => {
  const isFeatureVisibleToOrg = useIsFeatureVisibleToOrgLazy();

  return (
    isFeatureVisibleToOrg(feature) ||
    isFeatureEnabled(`REACT_APP_FEATURE_${feature}`)
  );
};

export type FeatureFlagKey = `REACT_APP_FEATURE_${Feature}`;

export const isFeatureEnabled = (flag: FeatureFlagKey) => {
  return getEnv(flag, true) === 'true';
};
