import {
  PropertyFilterOperation,
  PropertyFilterOperator,
  PropertyFilterQuery,
  PropertyFilterToken,
} from '@cloudscape-design/collection-hooks';
import { SortingState } from '@cloudscape-design/collection-hooks/cjs/interfaces';

export type TableOptions<T extends object> = {
  filtering?: TypedPropertyFilterQuery<T>;
  sorting?: SortingState<T>;
};

export const tableOptionsToQueryString = <T extends object>(
  options: TableOptions<T>
) => {
  const params = new URLSearchParams();
  if (options.filtering) {
    params.set('fo', options.filtering.operation);

    options.filtering.tokens.forEach((token, i) => {
      if (token.propertyKey) {
        params.set(`k${i}`, token.propertyKey);
      }
      params.set(`o${i}`, token.operator);

      const tokenValueString =
        typeof token.value === 'string'
          ? token.value
          : JSON.stringify(token.value);

      params.set(`v${i}`, tokenValueString);
    });
  }
  if (options?.sorting?.sortingColumn.sortingField) {
    params.set('sb', options.sorting.sortingColumn.sortingField);
    params.set('so', options.sorting.isDescending ? 'DESCENDING' : 'ASCENDING');
  }

  return params.toString();
};

const queryStringToSortingState = <T>(
  queryString: string
): SortingState<T> | undefined => {
  const params = new URLSearchParams(queryString);
  const sb = params.get('sb');
  const so = params.get('so');
  if (!sb || !so || !['DESCENDING', 'ASCENDING'].includes(so)) {
    return undefined;
  }

  return {
    sortingColumn: {
      sortingField: sb,
    },
    isDescending: so === 'DESCENDING',
  };
};

const queryStringToPropertyFilterQuery = (
  queryString: string
): PropertyFilterQuery | undefined => {
  const params = new URLSearchParams(queryString);
  const fo = params.get('fo');
  if (fo === null || !['and', 'or'].includes(fo)) {
    return undefined;
  }

  let continueLoop = true;
  const tokens: PropertyFilterToken[] = [];
  let i = 0;
  do {
    const v = params.get(`v${i}`);
    const k = params.get(`k${i}`);
    const o = params.get(`o${i}`);
    if (!o || !v || !['<', '<=', '>', '>=', ':', '!:', '=', '!='].includes(o)) {
      continueLoop = false;
    } else {
      tokens.push({
        value: v,
        propertyKey: k || undefined,
        operator: o as PropertyFilterOperator,
      });
    }
    i++;
  } while (continueLoop);

  return {
    tokens,
    operation: fo as PropertyFilterOperation,
  };
};

export const queryStringToTableOptions = (
  queryString: string
): {
  filtering?: PropertyFilterQuery;
  sorting?: SortingState<unknown>;
} => {
  return {
    filtering: queryStringToPropertyFilterQuery(queryString),
    sorting: queryStringToSortingState(queryString),
  };
};

export type TypedPropertyFilterQuery<TableFields extends object> = {
  tokens: readonly TypedPropertyFilterToken<TableFields>[];
  operation: PropertyFilterOperation;
};
export interface TypedPropertyFilterToken<TableFields> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  propertyKey?: Extract<keyof TableFields, string>;
  operator: PropertyFilterOperator;
}
