import { createContext, FC, PropsWithChildren } from 'react';

import { FieldType } from '../EditFields/NewFieldSchema';

type CurrentField = {
  Label: string;
  Type: FieldType;
  Options?: string[];
  Required: boolean;
  ReadOnly: boolean;
  Hidden: boolean;
  Description?: string;
};

type CustomFieldState = {
  currentField: CurrentField;
  fieldPath: string;
};

export const CustomFieldContext = createContext<CustomFieldState | null>(null);

export const CustomFieldProvider: FC<PropsWithChildren<CustomFieldState>> = ({
  children,
  currentField,
  fieldPath,
}) => {
  return (
    <CustomFieldContext.Provider value={{ currentField, fieldPath }}>
      {children}
    </CustomFieldContext.Provider>
  );
};
