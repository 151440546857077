
    import './styles.scoped.css';
    export default {
  "alert": "awsui_alert_mx3cw_1m973_189",
  "awsui-motion-fade-in": "awsui_awsui-motion-fade-in_mx3cw_1m973_1",
  "root": "awsui_root_mx3cw_1m973_211",
  "hidden": "awsui_hidden_mx3cw_1m973_249",
  "with-dismiss": "awsui_with-dismiss_mx3cw_1m973_299",
  "with-action": "awsui_with-action_mx3cw_1m973_299",
  "breakpoint-default": "awsui_breakpoint-default_mx3cw_1m973_305",
  "initial-hidden": "awsui_initial-hidden_mx3cw_1m973_316",
  "header": "awsui_header_mx3cw_1m973_321",
  "header-replacement": "awsui_header-replacement_mx3cw_1m973_322",
  "action": "awsui_action_mx3cw_1m973_326",
  "action-slot": "awsui_action-slot_mx3cw_1m973_331",
  "action-button": "awsui_action-button_mx3cw_1m973_332",
  "alert-focus-wrapper": "awsui_alert-focus-wrapper_mx3cw_1m973_336",
  "text": "awsui_text_mx3cw_1m973_365",
  "icon": "awsui_icon_mx3cw_1m973_372",
  "message": "awsui_message_mx3cw_1m973_375",
  "icon-size-medium": "awsui_icon-size-medium_mx3cw_1m973_383",
  "icon-size-big": "awsui_icon-size-big_mx3cw_1m973_386",
  "icon-size-normal": "awsui_icon-size-normal_mx3cw_1m973_389",
  "content": "awsui_content_mx3cw_1m973_393",
  "content-replacement": "awsui_content-replacement_mx3cw_1m973_394",
  "dismiss": "awsui_dismiss_mx3cw_1m973_398",
  "dismiss-button": "awsui_dismiss-button_mx3cw_1m973_403",
  "type-error": "awsui_type-error_mx3cw_1m973_407",
  "type-warning": "awsui_type-warning_mx3cw_1m973_415",
  "type-success": "awsui_type-success_mx3cw_1m973_423",
  "type-info": "awsui_type-info_mx3cw_1m973_431"
};
  