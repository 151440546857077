import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHasPermission } from 'src/rbac/Permission';
import {
  defaultValues,
  ImpactFormFields,
  ImpactSchema,
} from 'src/schemas/impacts';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  Parent_Type_Enum,
  useGetObligationByIdQuery,
  useGetObligationImpactByIdQuery,
  useInsertObligationImpactMutation,
  useUpdateObligationImpactMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ImpactDetailsForm from '../forms/ImpactDetailsForm';

type Props = {
  onDismiss: () => void;
  parentObligationId: string;
  Id?: string;
};

const ImpactModel: FC<Props> = ({ onDismiss, parentObligationId, Id }) => {
  const { t } = useTranslation('common');
  const { data: obligationData } = useGetObligationByIdQuery({
    variables: { _eq: parentObligationId },

    fetchPolicy: 'no-cache',
  });
  const obligation = obligationData?.obligation[0];

  const [insert] = useInsertObligationImpactMutation({
    update: (cache) => {
      evictField(cache, 'obligation_impact');
      evictField(cache, 'obligation');
    },
  });
  const [update] = useUpdateObligationImpactMutation({
    update: (cache) => {
      evictField(cache, 'obligation_impact');
      evictField(cache, 'obligation');
    },
  });
  const { data, loading, error } = useGetObligationImpactByIdQuery({
    variables: { id: Id! },
    skip: !Id,
    fetchPolicy: 'no-cache',
  });
  if (error) {
    throw error;
  }
  const userCanEdit = useHasPermission('update:obligation_impact', obligation);
  const userCanCreate = useHasPermission(
    'insert:obligation_impact',
    obligation
  );
  const impact = data?.obligation_impact[0];
  const userCanModify = impact ? userCanEdit : userCanCreate;

  const onSave = async (data: ImpactFormFields) => {
    if (impact) {
      await update({
        variables: {
          ...data,
          CustomAttributeData: data.CustomAttributeData ?? null,
          id: impact.Id,
        },
      });
    } else {
      await insert({
        variables: {
          ...data,
          CustomAttributeData: data.CustomAttributeData ?? null,
          ParentObligationId: parentObligationId,
        },
      });
    }
  };

  if (loading) {
    return null;
  }
  const formId = 'obligation-impact-form';

  return (
    <ModalForm
      i18n={t('impacts')}
      values={impact}
      defaultValues={defaultValues}
      schema={ImpactSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      formId={formId}
      visible={true}
      readOnly={!userCanModify}
      parentType={Parent_Type_Enum.ObligationImpact}
    >
      <ImpactDetailsForm readOnly={!userCanModify} />
    </ModalForm>
  );
};

export default ImpactModel;
