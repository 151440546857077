import { SpaceBetween } from '@cloudscape-design/components';
import ExpandableSection from '@cloudscape-design/components/expandable-section';
import Button from '@risksmart-app/components/Button';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ObjectWithContributors, useHasPermission } from 'src/rbac/Permission';

import TabHeader from '@/components/TabHeader';
import { useGetAssessmentResultsByParentIdQuery } from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';
import { useIsFeatureVisibleToOrg } from '@/utils/featureFlags';

import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from '../../../useAssessmentTypeConfig';
import AssessmentActionRegister from './AssessmentActionRegister';
import AssessmentImpactRatingRegister from './AssessmentImpactRatingRegister';
import AssessmentIssueRegister from './AssessmentIssueRegister';
import AssessmentRatingRegister from './AssessmentRatingRegister';

interface Props {
  parent: ObjectWithContributors;
  assessmentMode: AssessmentTypeEnum;
}

const Tab: FC<Props> = ({ parent, assessmentMode }) => {
  const navigate = useNavigate();
  const assessmentId = useGetGuidParam('assessmentId');
  const { t } = useTranslation(['common'], { keyPrefix: 'assessmentResults' });

  const { data, loading } = useGetAssessmentResultsByParentIdQuery({
    variables: { ParentId: assessmentId },
  });
  const {
    routing: { resultsAddUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const handleAssessmentResultCreateOpen = () => {
    navigate(resultsAddUrl(assessmentId));
  };

  const canCreateDocumentAssessmentResult = useHasPermission(
    'insert:document_assessment_result',
    parent
  );
  const canCreateObligationAssessmentResult = useHasPermission(
    'insert:obligation_assessment_result',
    parent
  );
  const canCreateRiskAssessmentResult = useHasPermission(
    'insert:risk_assessment_result',
    parent
  );
  const impactsEnabled = useIsFeatureVisibleToOrg('impacts');

  const canCreateAssessmentResult =
    canCreateDocumentAssessmentResult ||
    canCreateObligationAssessmentResult ||
    canCreateRiskAssessmentResult;

  return (
    <>
      <SpaceBetween size="m">
        <TabHeader
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              {canCreateAssessmentResult && (
                <Button
                  variant="primary"
                  formAction="none"
                  onClick={handleAssessmentResultCreateOpen}
                >
                  {t('add_button')}
                </Button>
              )}
            </SpaceBetween>
          }
        >
          {t('tab_title')}
        </TabHeader>
      </SpaceBetween>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{t('ratings')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(
                (data?.document_assessment_result?.length ?? 0) +
                  (data?.risk_assessment_result?.length ?? 0) +
                  (data?.obligation_assessment_result?.length ?? 0) +
                  (data?.test_result?.length ?? 0),
                loading
              )}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <AssessmentRatingRegister
          loading={loading}
          records={data}
          assessmentId={assessmentId}
          parent={parent}
          assessmentMode={assessmentMode}
        />
      </ExpandableSection>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{t('issues')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(data?.issue?.length ?? 0, loading)}
            </span>
          </div>
        }
      >
        <AssessmentIssueRegister
          loading={loading}
          records={data?.issue}
          customAttributeSchema={
            data?.form_configuration.length
              ? data?.form_configuration
                  .filter((c) => c.ParentType === 'issue')
                  .map((schemaData) => schemaData.customAttributeSchema!)
              : []
          }
        />
      </ExpandableSection>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{t('action')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(data?.action?.length ?? 0, loading)}
            </span>
          </div>
        }
      >
        <AssessmentActionRegister
          loading={loading}
          records={data?.action}
          customAttributeSchema={
            data?.form_configuration?.filter(
              (c) => c.ParentType === 'action'
            )[0]?.customAttributeSchema
          }
        />
      </ExpandableSection>
      {impactsEnabled && (
        <>
          <ExpandableSection
            header={
              <div className={'flex space-x-2'}>
                <span>{t('impact_rating')}</span>
                <span className={'text-grey font-normal'}>
                  {getCounter(data?.impact_rating?.length ?? 0, loading)}
                </span>
              </div>
            }
          >
            <AssessmentImpactRatingRegister
              loading={loading}
              assessmentId={assessmentId}
              records={data?.impact_rating}
              impactAppetites={data?.impact}
              customAttributeSchema={
                data?.form_configuration?.filter(
                  (c) => c.ParentType === 'impact_rating'
                )[0]?.customAttributeSchema
              }
            />
          </ExpandableSection>
        </>
      )}
    </>
  );
};

export default Tab;
