
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_dm8gx_1qisp_157",
  "has-items": "awsui_has-items_dm8gx_1qisp_191",
  "no-padding": "awsui_no-padding_dm8gx_1qisp_191",
  "dismiss-button": "awsui_dismiss-button_dm8gx_1qisp_195",
  "token": "awsui_token_dm8gx_1qisp_237",
  "token-box": "awsui_token-box_dm8gx_1qisp_244",
  "token-box-readonly": "awsui_token-box-readonly_dm8gx_1qisp_264",
  "token-box-disabled": "awsui_token-box-disabled_dm8gx_1qisp_278"
};
  