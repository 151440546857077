
    import './styles.scoped.css';
    export default {
  "link": "awsui_link_1kosq_bh5ps_141",
  "breadcrumb": "awsui_breadcrumb_1kosq_bh5ps_145",
  "ghost-breadcrumb": "awsui_ghost-breadcrumb_1kosq_bh5ps_146",
  "icon": "awsui_icon_1kosq_bh5ps_149",
  "anchor": "awsui_anchor_1kosq_bh5ps_155",
  "text": "awsui_text_1kosq_bh5ps_202",
  "last": "awsui_last_1kosq_bh5ps_221"
};
  