import { FC } from 'react';

interface Props {
  size?: 's' | 'm' | 'l' | 'xl';
  children: React.ReactNode | React.ReactNode[];
}

const FormRow: FC<Props> = ({ children }) => {
  return <div>{children}</div>;
};

export default FormRow;
