import Table from '@risksmart-app/components/Table';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormField } from '@/components/Form/Form/FormField';
import {
  Questionnaire_Template_Version_Status_Enum,
  useGetQuestionnaireTemplatesQuery,
} from '@/generated/graphql';

import { ControlledMultiAutosuggest } from '../../../../../components/Form/ControlledMultiAutosuggest/ControlledMultiAutosuggest';
import { useGetCollectionTableProps } from '../../../../questionnaire-templates/config';
import { QuestionnaireTemplateFields } from '../../../../questionnaire-templates/types';
import { InvitationFields, invitationSchema } from './invitationSchema';

export const InvitationFormFields = () => {
  const { control, setValue, watch } = useFormContext<InvitationFields>();
  const [userValue, setUserValue] = useState('');

  const { data, loading } = useGetQuestionnaireTemplatesQuery({
    variables: {
      where: {
        versions: {
          Status: { _eq: Questionnaire_Template_Version_Status_Enum.Published },
        },
      },
    },
  });

  const versionBidirectionalMap = useMemo(
    () =>
      data?.questionnaire_template.reduce<Record<string, string>>(
        (acc, curr) => {
          acc[curr.Id] = curr.publishedVersion[0]?.Id;

          return acc;
        },
        {}
      ) ?? {},
    [data]
  );

  const tableProps = useGetCollectionTableProps(data?.questionnaire_template);

  const users = watch('users');
  const { t } = useTranslation(['common'], {
    keyPrefix: 'plan_questionnaire.fields',
  });
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState<
    QuestionnaireTemplateFields[]
  >([]);

  const suggestedEmails = useMemo(() => {
    const emailDomains = ['@gmail.com', '@yahoo.com', '@outlook.com'];
    const emailSplit = userValue.split('@');
    const partialUserAddress = emailSplit[0];
    const partialDomain = emailSplit[1];
    if (partialDomain === undefined) {
      return [];
    }

    const suggestedDomains = emailDomains.filter((domain) =>
      domain.toLowerCase().startsWith(`@${partialDomain.toLowerCase()}`)
    );

    return suggestedDomains
      .map((domain) => `${partialUserAddress}${domain}`)
      .map((email) => ({
        value: email,
        label: email,
      }));
  }, [userValue]);

  useEffect(() => {
    setValue(
      'questionnaires',
      selectedQuestionnaires.map((q) => versionBidirectionalMap[q.Id])
    );
  }, [selectedQuestionnaires, setValue, versionBidirectionalMap]);

  return (
    <div>
      <ControlledMultiAutosuggest
        onCurrentValueChange={setUserValue}
        options={[...suggestedEmails, ...users]}
        check={(val) =>
          invitationSchema.shape.users.element.shape.value.safeParse(val)
            .success
        }
        placeholder={t('users_placeholder')}
        name={'users'}
        label={t('users')}
        control={control}
      />
      <FormField
        label={t('questionnaires', {
          count: selectedQuestionnaires.length,
          total: data?.questionnaire_template.length ?? 0,
        })}
      >
        <Table
          {...tableProps}
          loading={loading}
          variant={'embedded'}
          selectedItems={selectedQuestionnaires}
          trackBy="Id"
          onSelectionChange={({ detail }) => {
            setSelectedQuestionnaires(detail.selectedItems);
          }}
          selectionType={'multi'}
        />
      </FormField>
    </div>
  );
};
