import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';
import { UNRATED } from 'src/pages/controls/lookupData';

import { useGetObligationAssessmentResultsByObligationIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const useAssessmentsExportTable = (
  obligationId: string
): [() => Promise<ContentTable>, boolean] => {
  const { getLabel: getStatusLabel } = useRating('assessment_status');
  const { getLabel: getResultLabel } = useRating('performance_result');
  const [getAssessments, getAssessmentsResult] =
    useGetObligationAssessmentResultsByObligationIdLazyQuery({
      variables: {
        ObligationId: obligationId,
      },
    });

  const { t: assessmentsColumns } = useTranslation(['common'], {
    keyPrefix: 'obligationsAssessments.columns',
  });
  const createExportTable = async () => {
    const { data: assessmentsData } = await getAssessments();
    const assessmentsTableData = (
      assessmentsData?.obligation_assessment_result ?? []
    ).map((au) => {
      let title = '-';
      let statusLabel = '-';
      let startDate = '-';
      let completionDate: string;
      let completedBy = '-';
      const hasAssessment = au.parents.length > 0 && au.parents[0].assessment;
      if (hasAssessment) {
        title = au.parents[0].assessment?.Title ?? '-';
        statusLabel = getStatusLabel(au.parents[0].assessment?.Status ?? '-');
        startDate = toLocalDate(au.parents[0].assessment?.StartDate);
        completionDate = toLocalDate(
          au.parents[0].assessment?.ActualCompletionDate
        );
        completedBy =
          au.parents[0].assessment?.completedByUser?.FriendlyName ?? '-';
      } else {
        completionDate = toLocalDate(au.TestDate);
      }

      return [
        title,
        statusLabel,
        getResultLabel(au.Rating ?? UNRATED.value),
        startDate,
        completionDate,
        completedBy,
      ];
    });

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          assessmentsColumns('Title'),
          assessmentsColumns('Status'),
          assessmentsColumns('Result'),
          assessmentsColumns('StartDate'),
          assessmentsColumns('CompletionDate'),
          assessmentsColumns('CompletionBy'),
        ]),
        ...assessmentsTableData,
      ],
    });
  };

  return [createExportTable, getAssessmentsResult.loading];
};

export default useAssessmentsExportTable;
