import { FC } from 'react';

import { useGetControlGroupByIdQuery } from '@/generated/graphql';

type Props = {
  controlGroupId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ controlGroupId }) => {
  const { data } = useGetControlGroupByIdQuery({
    variables: {
      _eq: controlGroupId!,
    },
    skip: !controlGroupId,
  });

  return <>{data?.control_group?.[0]?.Title}</>;
};

export default Breadcrumb;
