import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetTestResultsByControlIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { toLocalDate } from '@/utils/dateUtils';
import { createTable, tableHeaders } from '@/utils/pdf/table';

const usePerformanceTableExporter = (
  controlId: string
): [() => Promise<ContentTable>, boolean] => {
  const { t } = useTranslation(['common']);
  const { t: testResultColumns } = useTranslation(['common'], {
    keyPrefix: 'testResults.columns',
  });
  const [getTestResults, getTestResultsResult] =
    useGetTestResultsByControlIdLazyQuery({
      variables: {
        controlId: controlId,
      },
    });
  const { getLabel: getEffectivenessLabel } = useRating('effectiveness');

  const createExportTable = async () => {
    const { data: testResultData } = await getTestResults();

    const testTypeLookup = t('testTypes');

    const testResultsTableData = (testResultData?.test_result ?? []).map(
      (au) => [
        au.Title ?? '-',
        toLocalDate(au.TestDate),
        au.TestType
          ? testTypeLookup[au.TestType as keyof typeof testTypeLookup]
          : '-',
        getEffectivenessLabel(au.OverallEffectiveness),
        au.submitter?.FriendlyName ?? '',
        toLocalDate(
          au.assessmentParents
            .filter((c) => c.assessment)
            .map((c) => c.assessment)[0]?.NextTestDate
        ) ?? '',
      ]
    );

    return createTable({
      widths: ['*', 50, 70, 90, 70, 50],
      body: [
        tableHeaders([
          testResultColumns('title'),
          testResultColumns('date'),
          testResultColumns('test_type'),
          testResultColumns('overall_effectiveness'),
          testResultColumns('submitter'),
          testResultColumns('next_test_date'),
        ]),
        ...testResultsTableData,
      ],
    });
  };

  return [createExportTable, getTestResultsResult.loading];
};

export default usePerformanceTableExporter;
