import { BreadcrumbGroup } from '@cloudscape-design/components';
import { FC } from 'react';

import useBreadcrumbs from '../hooks/use-breadcrumbs';
import useLink from '../hooks/use-link';
import styles from './style.module.scss';

interface Props {}

const Breadcrumbs: FC<Props> = () => {
  const { breadcrumbs, showBreadcrumbs } = useBreadcrumbs();
  const { handleFollow } = useLink();
  if (!showBreadcrumbs) {
    return <></>;
  }

  return (
    <div className={styles.breadcrumb}>
      <BreadcrumbGroup
        items={breadcrumbs}
        ariaLabel="Breadcrumbs"
        onFollow={handleFollow}
      />
    </div>
  );
};
export default Breadcrumbs;
