import Modal from '@risksmart-app/components/Modal';
import { FC } from 'react';

import { ObjectWithContributors } from '../../../rbac/Permission';
import AssessmentResultForm from '../forms/AssessmentResultForm';
import { AssessmentTypeEnum } from '../useAssessmentTypeConfig';
import { ResultType } from './types';

interface Props {
  id?: string;
  resultType?: ResultType;
  onDismiss: (saved?: boolean) => void;
  assessmentMode: AssessmentTypeEnum;
  parentAssessment?: ObjectWithContributors;
  assessedItem?: ObjectWithContributors;
  hideTypeSelector?: boolean;
  i18n: {
    edit_modal_title?: string;
    create_modal_title?: string;
  };
}

const AssessmentResultModal: FC<Props> = ({
  id,
  resultType,
  onDismiss,
  parentAssessment,
  assessedItem,
  hideTypeSelector,
  i18n,
  assessmentMode,
}) => {
  return (
    <Modal
      disableContentPaddings={true}
      visible={true}
      onDismiss={(event) => {
        // don't close modal on overlay click
        if (event.detail.reason === 'overlay') {
          return;
        }
        onDismiss(false);
      }}
      header={id ? i18n.edit_modal_title : i18n.create_modal_title}
    >
      <AssessmentResultForm
        readonly={!!id}
        parentAssessment={parentAssessment}
        assessedItem={assessedItem}
        resultType={resultType}
        onDismiss={onDismiss}
        id={id}
        hideTypeSelector={hideTypeSelector}
        navigateToResults={false}
        assessmentMode={assessmentMode}
      />
    </Modal>
  );
};

export default AssessmentResultModal;
