import { TabsProps } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';

import { GetImpactByIdQuery } from '@/generated/graphql';
import { filterEmptyTabs } from '@/utils/tabUtils';
import { impactDetailRatingUrl, impactDetailsUrl } from '@/utils/urls';

import DetailsTab from './tabs/details/Tab';
import RatingsTab from './tabs/ratings/Tab';

export const useTabs = (
  impact: GetImpactByIdQuery['impact'][number] | undefined
) => {
  const { t } = useTranslation(['common']);

  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: impact && <DetailsTab impact={impact} />,
      href: impact && impactDetailsUrl(impact.Id),
    },
    {
      label: 'Ratings', // TODO: translation
      id: 'ratings',
      content: impact && <RatingsTab />,
      href: impact && impactDetailRatingUrl(impact.Id),
    },
  ];

  return filterEmptyTabs(tabs);
};
