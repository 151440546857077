import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { FormContextProps } from '@/components/Form/Form/types';
import { Parent_Type_Enum } from '@/generated/graphql';

import ObligationDetailsFormFields from './ObligationDetailsFormFields';
import { ObligationFormFieldData, ObligationSchema } from './obligationSchema';

export type Props = Omit<
  FormContextProps<ObligationFormFieldData>,
  'schema' | 'formId' | 'parentType' | 'renderTemplate' | 'i18n' | 'header'
> & {
  obligationId?: string;
  parentObligationNode?: {
    Id: string;
    SequentialId?: number | null | undefined;
    ObjectType: Parent_Type_Enum;
  } | null;
};

const ObligationDetailsForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);

  return (
    <CustomisableFormContext
      {...props}
      formId={'obligation-form'}
      i18n={t('obligations')}
      header={t('details')}
      schema={ObligationSchema}
      parentType={Parent_Type_Enum.Obligation}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
    >
      <ObligationDetailsFormFields
        readOnly={props.readOnly}
        obligationId={props.obligationId}
        parentObligationNode={props.parentObligationNode}
      />
    </CustomisableFormContext>
  );
};

export default ObligationDetailsForm;
