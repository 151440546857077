import { FC } from 'react';

import { useGetControlByIdQuery } from '@/generated/graphql';

type Props = {
  controlId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ controlId }) => {
  if (!controlId) {
    throw new Error('controlId missing');
  }
  const { data } = useGetControlByIdQuery({
    variables: {
      _eq: controlId,
    },
  });

  return <>{data?.control?.[0]?.Title}</>;
};

export default Breadcrumb;
