import { Container } from '@cloudscape-design/components';
import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  useGetThirdPartyByIdQuery,
  useInsertQuestionnaireInvitesMutation,
} from '@/generated/graphql';

import { PageLayout } from '../../../../layouts';
import { useHasPermission } from '../../../../rbac/Permission';
import { useGetDetailPath } from '../../../../routes/useGetDetailParentPath';
import { InvitationForm } from './forms/InvitationForm';
import { defaultValues, InvitationFields } from './forms/invitationSchema';

const ThirdPartyInvitePage = () => {
  const thirdPartyId = useGetGuidParam('id');
  const navigate = useNavigate();
  const parentPath = useGetDetailPath(thirdPartyId);
  const [createInvites] = useInsertQuestionnaireInvitesMutation();
  const { t } = useTranslation(['common'], { keyPrefix: 'plan_questionnaire' });

  const { data } = useGetThirdPartyByIdQuery({
    variables: { Id: thirdPartyId },
  });

  const thirdParty = data?.third_party;
  const canEdit = useHasPermission('update:third_party', thirdParty);

  const onDismiss = () => {
    navigate(parentPath);
  };

  const onSave = async (data: InvitationFields) => {
    await createInvites({
      variables: {
        thirdPartyId,
        users: data.users.map((u) => u.value),
        questionnaires: data.questionnaires,
      },
    });
  };

  return (
    <PageLayout title={t('page_title')}>
      <Container>
        <InvitationForm
          readOnly={!canEdit}
          values={{
            ...defaultValues,
          }}
          onSave={onSave}
          onDismiss={onDismiss}
        />
      </Container>
    </PageLayout>
  );
};

export default ThirdPartyInvitePage;
