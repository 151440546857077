import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHasPermission } from 'src/rbac/Permission';

import {
  departmentInsertInputBuilder,
  ownerAndContributorInsertFields,
  tagInsertInputBuilder,
} from '@/components/Form';
import { useInsertDocumentMutation } from '@/generated/graphql';
import { policyDetailsUrl } from '@/utils/urls';

import DocumentForm from '../../forms/DocumentForm';
import { DocumentFormFieldData } from '../../forms/documentSchema';

const Tab: FC = () => {
  const canCreateDocument = useHasPermission('insert:document');
  const navigate = useNavigate();
  const [mutate] = useInsertDocumentMutation({});

  const onSave = async (data: DocumentFormFieldData) => {
    const result = await mutate({
      variables: {
        ...data,
        Tags: tagInsertInputBuilder(data.TagTypeIds),
        Departments: departmentInsertInputBuilder(data.DepartmentTypeIds),
        ...ownerAndContributorInsertFields(data),
        linkedDocuments: data.linkedDocuments.map((linkedDocument) => ({
          LinkedDocumentId: linkedDocument.value,
        })),
        CustomAttributeData: data.CustomAttributeData || undefined,
        RequireGlobalAttestation:
          data.requireAttestationFromEveryone === 'true',
        AttestationTimeLimit: data.attestationTimeLimit,
        AttestationPromptText: data.attestationPromptText,
        AttestationGroups: {
          data: data.attestationGroups.map((group) => ({
            GroupId: group.value,
          })),
        },
      },
    });
    if (result.data?.insert_document_one?.Id) {
      navigate(policyDetailsUrl(result.data?.insert_document_one?.Id), {
        replace: true,
      });
    }
  };

  const onDismiss = (saved: boolean) => {
    if (!saved) {
      navigate(-1);
    }
  };

  return (
    <DocumentForm
      onDismiss={onDismiss}
      onSave={onSave}
      readOnly={!canCreateDocument}
    />
  );
};

export default Tab;
