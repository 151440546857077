import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalForm } from '@/components/Form/Form/ModalForm';
import {
  useGetAuthUserByIdQuery,
  useGetAvailableRolesQuery,
  useUpdateUserRolesMutation,
} from '@/generated/graphql';

import UserDetailsForm from './forms/UserDetailsForm';
import {
  UserDetailsFormFields,
  UserDetailsSchema,
} from './forms/UserDetailsSchema';

type Props = {
  onDismiss: () => void;
  id: string;
};

const UserDetailsModal: FC<Props> = ({ onDismiss, id }) => {
  const { addNotification } = useNotifications();
  const { t } = useTranslation();
  const [update] = useUpdateUserRolesMutation();

  const { data: getUserResponse, loading: loadingUser } =
    useGetAuthUserByIdQuery({
      variables: {
        Id: id,
      },
      skip: !id,
      fetchPolicy: 'no-cache',
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });
  const user = getUserResponse?.auth_user_by_pk;
  const userValues: UserDetailsFormFields = {
    UserId: user?.Id ?? '',
    Role: user?.organisationusers[0].RoleKey ?? user?.RoleKey ?? '',
  };

  const { data: getRolesResponse, loading: loadingRoles } =
    useGetAvailableRolesQuery({
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });
  const availableRoles = getRolesResponse?.available_roles ?? [];
  const roleOptions = availableRoles.map((role) => ({
    id: role.id,
    value: role.name,
    description: role.description,
  }));

  const onSave = async (values: UserDetailsFormFields) => {
    const { UserId, Role } = values;
    const roleIds = availableRoles
      .filter((role) => role.name === Role)
      .map((role) => role.id);

    await update({
      variables: {
        userId: UserId,
        roleIds,
      },
    });
  };

  if (loadingUser || loadingRoles) {
    return null;
  }

  const formId = 'user-form';

  return (
    <ModalForm
      i18n={t('userSettings')}
      values={userValues}
      defaultValues={{ Role: '' }}
      schema={UserDetailsSchema}
      onSave={onSave}
      onDismiss={onDismiss}
      formId={formId}
      visible={true}
      readOnly={!user?.External_Id}
    >
      <UserDetailsForm
        roleOptions={roleOptions}
        readOnly={!user?.External_Id}
      />
    </ModalForm>
  );
};

export default UserDetailsModal;
