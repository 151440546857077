import { SpaceBetween } from '@cloudscape-design/components';
import ExpandableSection from '@cloudscape-design/components/expandable-section';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission, useHasPermission } from 'src/rbac/Permission';

import DeleteModal from '@/components/DeleteModal/DeleteModal';
import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import TabHeader from '@/components/TabHeader';
import {
  GetControlByIdQuery,
  useDeleteTestResultsMutation,
  useGetTestResultsByControlIdQuery,
} from '@/generated/graphql';
import { useDeleteResultNotification } from '@/hooks/useMutationResultNotification';
import { getCounter } from '@/utils/collectionUtils';
import { evictField } from '@/utils/graphqlUtils';

import TestResultModal from '../TestResultModal';
import { useGetCollectionTableProps } from './performanceRatingConfig';
import { PerformanceRegisterFields } from './types';

type Props = {
  control: GetControlByIdQuery['control'][number];
};

const PerformanceRatingRegister: FC<Props> = ({ control }) => {
  useI18NSummaryHelpContent('testResults.tabHelp');
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'testResults',
  });
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addNotification } = useNotifications();

  const [selectedTestResults, setSelectedTestResults] = useState<
    PerformanceRegisterFields[]
  >([]);
  const canDeleteTestResults = useHasPermission('delete:test_result', control);
  const [openTestResultId, setOpenTestResultId] = useState<
    string | undefined
  >();

  const { data, loading, refetch } = useGetTestResultsByControlIdQuery({
    variables: {
      controlId: control.Id,
    },
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  const handleTestResultModalOpen = () => {
    setIsEditOpen(true);
  };

  const handleTestResultModalClose = () => {
    setOpenTestResultId(undefined);
    setIsEditOpen(false);
  };

  const [deleteTestResults, deleteResult] = useDeleteTestResultsMutation({
    update: (cache) => {
      evictField(cache, 'test_result');
      evictField(cache, 'test_result_aggregate');
      evictField(cache, 'risk_score');
    },
  });

  const onDelete = useDeleteResultNotification({
    entityName: st('entity_name'),
    failureAction: () => {
      setIsDeleteModalVisible(false);
    },
    asyncAction: async () => {
      await deleteTestResults({
        variables: {
          Ids: selectedTestResults.map((s) => s.Id),
        },
      });
      setSelectedTestResults([]);
      setIsDeleteModalVisible(false);

      return true;
    },
  });

  const tableProps = useGetCollectionTableProps(
    control,
    (testResult) => {
      setOpenTestResultId(testResult.Id);
      setIsEditOpen(true);
    },
    () => {
      setIsEditOpen(true);
    },
    data?.test_result,
    data?.form_configuration?.[0]?.customAttributeSchema
  );

  return (
    <>
      <SpaceBetween size="m">
        <TabHeader
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Permission
                permission="delete:test_result"
                parentObject={control}
              >
                <Button
                  formAction="none"
                  variant="normal"
                  disabled={!selectedTestResults.length}
                  onClick={() => setIsDeleteModalVisible(true)}
                >
                  {t('delete')}
                </Button>
              </Permission>
              <Permission
                permission="insert:test_result"
                parentObject={control}
              >
                <Button
                  variant="primary"
                  formAction="none"
                  onClick={handleTestResultModalOpen}
                >
                  {st('add_button')}
                </Button>
              </Permission>
            </SpaceBetween>
          }
        >
          {st('tab_title')}
        </TabHeader>
      </SpaceBetween>

      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{st('performanceRatingSubheading')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={true}
      >
        <Table
          {...tableProps}
          selectionType={canDeleteTestResults ? 'multi' : undefined}
          selectedItems={selectedTestResults}
          trackBy="Id"
          onSelectionChange={({ detail }) => {
            setSelectedTestResults(detail.selectedItems);
          }}
          resizableColumns={true}
          variant="embedded"
          loading={loading}
          loadingText={t('loadingTestResults') ?? ''}
          sortingDisabled={false}
        />
      </ExpandableSection>
      {isEditOpen && control.Id && (
        <TestResultModal
          parentControlId={control.Id}
          Id={openTestResultId}
          onDismiss={handleTestResultModalClose}
          assessmentMode={'rating'}
        />
      )}
      <DeleteModal
        loading={deleteResult.loading}
        isVisible={isDeleteModalVisible}
        header={t('delete')}
        onDelete={onDelete}
        onDismiss={() => {
          refetch();
          setOpenTestResultId(undefined);
          setSelectedTestResults([]);
          setIsDeleteModalVisible(false);
        }}
      >
        {st('confirm_delete_message')}
      </DeleteModal>
    </>
  );
};

export default PerformanceRatingRegister;
