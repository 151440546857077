import dayjs from 'dayjs';
import _ from 'lodash';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetImpactRatingsWithAppetitesQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';

import { useLabelledFields } from '../../../../impacts/ratings/useLabelledFields';
import { dataSources } from '../../../UniversalWidget/dataSources';
import { HeatmapCell } from '../../../widgets/HeatmapWidget/HeatmapCell';
import { useGetWidgetData } from '../../hooks/useGetWidgetData';
import { WidgetDataSource } from '../../types';
import { GigaTableWidgetProps } from '../table/GigaTableWidget';
import { useCalculatePlacematRatings } from './useCalculatePlacematRatings';

export const PlacematWidget = <TDataSource extends WidgetDataSource>({
  dataSource,
  variables,
  dateFilterOptions,
  propertyFilterQuery,
}: GigaTableWidgetProps<TDataSource>) => {
  const { getColorClass: getPlacematColor } = useRating('impact_placemat');
  const { tableProps } = useGetWidgetData<(typeof dataSources)['risk']>({
    dataSource,
    variables,
    dateFilterOptions,
    propertyFilterQuery,
  });
  const { t } = useTranslation(['common'], {
    keyPrefix: 'dashboard.widgets.placemat',
  });

  const { data } = useGetImpactRatingsWithAppetitesQuery({
    fetchPolicy: 'network-only',
    variables: { today: dayjs().endOf('day').toISOString() },
  });
  const impactRatings = useLabelledFields(data?.impact_rating);
  const impacts = Array.from(
    new Set(impactRatings?.map((rating) => rating.ImpactId) ?? [])
  );
  const placematRatings = useCalculatePlacematRatings(
    tableProps.allItems ?? [],
    impactRatings ?? [],
    data
  );

  const getRiskName = (riskId: string) => {
    const risk = tableProps.allItems?.find((risk) => risk.Id === riskId);

    return risk?.Title ?? riskId;
  };

  const getImpactName = (impactId: string) => {
    const impact = impactRatings?.find(
      (rating) => rating.ImpactId === impactId
    );

    return impact?.impact?.Name ?? impactId;
  };

  const likelihoodCellData = (riskId: string, value: number) => {
    return {
      value,
      background: getPlacematColor(value) ?? '',
      label: riskId,
    };
  };

  const impactCellData = (riskId: string, value: number) => {
    return {
      value,
      background: getPlacematColor(value) ?? '',
      label: riskId,
    };
  };

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: `max-content repeat(${
      Object.keys(placematRatings).length
    }, 1fr) 10px repeat(3, min-content)`,
    gridTemplateRows: `min-content 1fr 10px min-content repeat(${impacts.length}, 1fr) 10px repeat(3, 30px)`,
    gap: '5px',
    height: '100%',
  };

  return (
    <div style={gridStyle}>
      <div></div>
      {Object.keys(placematRatings).map((riskId) => (
        <div
          className={'flex items-end justify-center text-center font-bold'}
          key={riskId}
        >
          {getRiskName(riskId)}
        </div>
      ))}

      <div></div>
      <div
        className={'flex items-end justify-center text-center font-bold px-3'}
      >
        {t('moreThan')}
      </div>
      <div
        className={'flex items-end justify-center text-center font-bold px-3'}
      >
        {t('equalTo')}
      </div>
      <div
        className={'flex items-end justify-center text-center font-bold px-3'}
      >
        {t('lessThan')}
      </div>

      {/* LIKELIHOOD ROW */}
      <div
        className={'flex items-center justify-end text-right mr-3 font-bold'}
      >
        {t('likelihood')}
      </div>
      {Object.entries(placematRatings).map(([riskId, risk]) => (
        <HeatmapCell
          data={likelihoodCellData(riskId, risk.liklihood)}
          className={'flex justify-center items-center'}
          onMouseOver={() => null}
          key={riskId}
        />
      ))}
      <div></div>
      <div className={'flex justify-center items-center bg-grey150'}>0</div>
      <div className={'flex justify-center items-center bg-grey150'}>0</div>
      <div className={'flex justify-center items-center bg-grey150'}>0</div>

      <div
        style={{
          gridColumn: `span ${Object.keys(placematRatings).length + 5}`,
        }}
      ></div>
      <div className={'font-bold'}>{t('impactsAssessment')}</div>
      <div
        style={{
          gridColumn: `span ${Object.keys(placematRatings).length + 4}`,
        }}
      ></div>

      {/* IMPACT ROWS */}
      {impacts.map((impactId, i) => {
        const ratings = Object.values(placematRatings).map(
          (rating) => rating.ratings
        );
        const moreThan = _.sumBy(ratings, (r) => Number(r[impactId] > 0));
        const equalTo = _.sumBy(ratings, (r) => Number(r[impactId] === 0));
        const lessThan = _.sumBy(ratings, (r) => Number(r[impactId] < 0));

        return (
          <Fragment key={i}>
            <div className={'flex items-center justify-end text-right mr-3'}>
              {getImpactName(impactId)}
            </div>
            {Object.entries(placematRatings).map(([riskId, risk]) => (
              <HeatmapCell
                data={impactCellData(riskId, risk.ratings[impactId])}
                className={'flex justify-center items-center'}
                onMouseOver={() => null}
                key={riskId}
              />
            ))}
            <div></div>
            <div className={'flex justify-center items-center bg-grey150'}>
              {moreThan}
            </div>
            <div className={'flex justify-center items-center bg-grey150'}>
              {equalTo}
            </div>
            <div className={'flex justify-center items-center bg-grey150'}>
              {lessThan}
            </div>
          </Fragment>
        );
      })}

      <div
        style={{
          gridColumn: `span ${Object.keys(placematRatings).length + 5}`,
        }}
      ></div>
      {/*  SUMMARY*/}
      <div
        className={'flex items-center justify-end text-right mr-3 font-bold'}
      >
        {t('moreThan')}
      </div>
      {Object.values(placematRatings).map((rating, i) => {
        const moreThan = _.sumBy(Object.values(rating.ratings), (r) =>
          Number(r > 0)
        );

        return (
          <div
            className={'flex justify-center items-center bg-grey150'}
            key={i}
          >
            {moreThan}
          </div>
        );
      })}
      <div style={{ gridColumn: 'span 4' }}></div>
      <div
        className={'flex items-center justify-end text-right mr-3 font-bold'}
      >
        {t('equalTo')}
      </div>
      {Object.values(placematRatings).map((rating, i) => {
        const equalTo = _.sumBy(Object.values(rating.ratings), (r) =>
          Number(r === 0)
        );

        return (
          <div
            className={'flex justify-center items-center bg-grey150'}
            key={i}
          >
            {equalTo}
          </div>
        );
      })}
      <div style={{ gridColumn: 'span 4' }}></div>
      <div
        className={'flex items-center justify-end text-right mr-3 font-bold'}
      >
        {t('lessThan')}
      </div>
      {Object.values(placematRatings).map((rating, i) => {
        const lessThan = _.sumBy(Object.values(rating.ratings), (r) =>
          Number(r < 0)
        );

        return (
          <div
            className={'flex justify-center items-center bg-grey150'}
            key={i}
          >
            {lessThan}
          </div>
        );
      })}
      <div style={{ gridColumn: 'span 4' }}></div>
    </div>
  );
};
