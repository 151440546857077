import {
  JsonSchema,
  Labelable,
  UISchemaElement,
  VerticalLayout,
} from '@jsonforms/core';

export interface QuestionData {
  questionTitle: string;
  placeholder?: string;
}

export const initialQuestionData: QuestionData = {
  questionTitle: '',
  placeholder: '',
};

export const questionSchema: JsonSchema = {
  type: 'object',
  properties: {
    questionTitle: { type: 'string', minLength: 1 },
    placeholder: { type: 'string' },
  },
  required: ['questionTitle'],
};

interface VerticalLayoutWithLabelables extends VerticalLayout {
  elements: UISchemaElement[] & Labelable[];
}

export const questionUISchema: VerticalLayoutWithLabelables = {
  type: 'VerticalLayout',
  elements: [
    {
      type: 'Control',
      label: 'Question Title',
      scope: '#/properties/questionTitle',
      options: {
        placeholder: 'Enter question title here...',
      },
    },
    {
      type: 'Control',
      label: 'Placeholder Text',
      scope: '#/properties/placeholder',
      options: {
        placeholder: 'Enter placeholder text here...',
      },
    },
  ],
};
