import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

export type HelpContent = FieldContent[];

export type Content = string | { title: string; content: string }[];

export type FieldContent = {
  title: string;
  content: Content;
};

type FormFieldHelpContent = {
  [fieldId: string]: FieldContent;
};

type HelpContext = {
  addFieldHelp: (fieldId: string, helpContent: FieldContent) => void;
  removeFieldHelp: (fieldId: string) => void;
  formFieldHelpContent: FormFieldHelpContent;
  summaryHelpContent: HelpContent | null;
  setSummaryHelpContent: (content: HelpContent | null) => void;
  contentId: string | null;
  setContentId: (contentId: string | null) => void;
  hasHelpContent: boolean;
  translationKey: string | null;
  setTranslationKey: (translationKey: string) => void;
  showFieldHelpInPopover: boolean;
  setShowFieldHelpInPopover: (showFieldHelpInPopover: boolean) => void;
};

const HelpContext = createContext<HelpContext | null>(null);

export const HelpProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [formFieldHelpContent, setFieldHelpContent] =
    useState<FormFieldHelpContent>({});
  const [showFieldHelpInPopover, setShowFieldHelpInPopover] =
    useState<boolean>(false);
  const [translationKey, setTranslationKey] = useState<string>('');
  const [contentId, setContentId] = useState<string | null>(null);
  const [summaryHelpContent, setSummaryHelpContent] =
    useState<HelpContent | null>(null);
  const addFieldHelp = useCallback(
    (fieldId: string, helpContent: FieldContent) =>
      setFieldHelpContent((fields) => {
        if (fields[fieldId] != helpContent) {
          return { ...fields, [fieldId]: helpContent };
        }

        return fields;
      }),
    []
  );
  const removeFieldHelp = useCallback((fieldId: string) => {
    setFieldHelpContent((fields) => {
      if (fields[fieldId]) {
        delete fields[fieldId];

        return { ...fields };
      }

      return fields;
    });
  }, []);
  const hasHelpContent =
    (summaryHelpContent && !!summaryHelpContent.length) ||
    !!Object.keys(formFieldHelpContent).length;

  return (
    <HelpContext.Provider
      value={{
        addFieldHelp,
        removeFieldHelp,
        formFieldHelpContent,
        summaryHelpContent,
        setSummaryHelpContent,
        setContentId,
        contentId,
        hasHelpContent,
        translationKey,
        setTranslationKey,
        showFieldHelpInPopover,
        setShowFieldHelpInPopover,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};

export const useHelpContent = () => {
  const context = useContext(HelpContext);
  if (context === null) {
    throw new Error('useHelpContent must be used within a HelpProvider');
  }

  return context;
};
