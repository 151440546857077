import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import _ from 'lodash';
import { FieldValues } from 'react-hook-form';

import Tokens from '@/components/Tokens';
import {
  GetObligationListQuery,
  Parent_Type_Enum,
  useGetObligationListQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { obligationDetailsUrl } from '@/utils/urls';

import ControlledMultiselect from '../ControlledMultiselect';
import { ControlledBaseProps } from '../types';

interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  addEmptyOption?: boolean;
  disabled?: boolean;
  excludedIds?: string[];
}

export const getOptions = (
  allObligations: GetObligationListQuery | undefined,
  defaultValues: { value: string }[],
  excludedIds?: string[]
): { value: string; label: string }[] => {
  const ids = defaultValues.map((d) => d.value);
  const obligationsById = _.keyBy(allObligations?.obligation, 'Id');

  let options: {
    Id: string;
    Title?: string;
    SequentialId?: number | null | undefined;
  }[] = allObligations?.obligation ?? [];

  options = options.concat(
    (allObligations?.node ?? []).filter(
      (n) => ids.includes(n.Id) && !obligationsById[n.Id]
    )
  );

  return (
    options
      ?.filter((o) => !excludedIds?.includes(o.Id))
      .map((o) => ({
        value: o.Id,
        label:
          o?.Title ??
          getFriendlyId(Parent_Type_Enum.Obligation, o.SequentialId),
      })) ?? []
  );
};

export const ControlledObligationMultiselect = <T extends FieldValues>({
  ...props
}: Props<T>) => {
  const { addNotification } = useNotifications();

  const defaultValues: { value: string }[] =
    props.control._defaultValues[props.name] ?? [];
  const { data: obligations, loading } = useGetObligationListQuery({
    fetchPolicy: 'no-cache',
    onError: (error) => {
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });

  return (
    <ControlledMultiselect
      statusType={loading ? 'loading' : 'finished'}
      {...props}
      hideTokens={true}
      options={getOptions(obligations, defaultValues, props.excludedIds)}
      renderTokens={true}
      customTokenRender={(options, actions) => (
        <Tokens
          onRemove={actions.removeToken}
          tokens={options.map((o) => ({
            value: o.value!,
            url: obligationDetailsUrl(o.value!),
            label: o.label!,
          }))}
        />
      )}
    />
  );
};
