import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Editor as TinyEditor } from 'tinymce';

import ConfirmModal from '@/components/ConfirmModal/ConfirmModal';
import {
  Document_File_Type_Enum,
  namedOperations,
  Parent_Type_Enum,
  useInsertDocumentVersionMutation,
  Version_Status_Enum,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import { useFileUpload } from '../../../../../../../../data/rest/useFileUpload';
import {
  defaultValues,
  DocumentVersionFormFieldData,
} from '../../../forms/documentFileSchema';
import DocumentVersionForm from '../../../forms/DocumentVersionForm';
import { useNextFileVersion } from '../../../useNextFileVersion';

type TabProps = {
  parentDocumentId: string;
};

const Tab = ({ parentDocumentId }: TabProps) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'documentFiles.confirm_close_modal',
  });

  const editorRef = useRef<TinyEditor | null>(null);
  const uploadFile = useFileUpload();
  const [confirmCloseVisible, setConfirmCloseVisible] = useState(false);
  const [insert] = useInsertDocumentVersionMutation({
    update: (cache) => {
      evictField(cache, 'document');
    },
  });

  const [
    { nextVersion, content, type, link, customAttributeData },
    { loading: loadingNextFileVersion },
  ] = useNextFileVersion(parentDocumentId);
  const navigate = useNavigate();
  const onSave = async (data: DocumentVersionFormFieldData) => {
    let content: string | null = null;
    let link: string | null = null;
    let fileId: string | null = null;
    switch (data.Type) {
      case Document_File_Type_Enum.Link:
        link = data.Link;
        break;
      case Document_File_Type_Enum.Html:
        content = editorRef.current?.getContent() ?? null;
        break;
      case Document_File_Type_Enum.File:
        if (data.newFiles.length === 1) {
          const uploadResponse = await uploadFile(
            Parent_Type_Enum.Document,
            [parentDocumentId],
            data.newFiles
          );
          fileId = uploadResponse.fileIds[0];
        }
        break;
    }
    const { Status: _, ...dataToInsert } = data;
    await insert({
      variables: {
        ...dataToInsert,
        FileId: fileId,
        ParentDocumentId: parentDocumentId,
        Content: content,
        Link: link,
        ReviewedBy: dataToInsert.ReviewedBy?.value,
      },
      refetchQueries: [namedOperations.Query.getDocumentFileById],
    });
  };

  const defaultValuesWithVersion: DocumentVersionFormFieldData = {
    ...defaultValues,
    Version: nextVersion,
    Content: content,
    Type: type,
    Link: link,
    CustomAttributeData: customAttributeData,
  } as DocumentVersionFormFieldData;

  if (loadingNextFileVersion) {
    return null;
  }

  const onDismiss = () => {
    navigate(`/policy/${parentDocumentId}/files`);
  };

  return (
    <>
      <DocumentVersionForm
        disableStatus={true}
        defaultValues={defaultValuesWithVersion}
        onSave={onSave}
        onDismiss={(saved) => {
          if (saved) {
            onDismiss();
          }

          setConfirmCloseVisible(true);
        }}
        isCreatingNewEntity={false}
        editorRef={editorRef}
        hasPendingChangeRequests={false}
        savedStatus={Version_Status_Enum.Draft}
        parentId={parentDocumentId}
      />

      <ConfirmModal
        isVisible={confirmCloseVisible}
        onConfirm={onDismiss}
        onDismiss={() => setConfirmCloseVisible(false)}
        header={t('title')}
      >
        {t('message')}
      </ConfirmModal>
    </>
  );
};

export default Tab;
