
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1fn7j_1jm8s_141",
  "styled-box": "awsui_styled-box_1fn7j_1jm8s_148",
  "styled-box-checked": "awsui_styled-box-checked_1fn7j_1jm8s_164",
  "styled-box-indeterminate": "awsui_styled-box-indeterminate_1fn7j_1jm8s_164",
  "styled-box-disabled": "awsui_styled-box-disabled_1fn7j_1jm8s_168",
  "styled-box-readonly": "awsui_styled-box-readonly_1fn7j_1jm8s_168",
  "styled-line": "awsui_styled-line_1fn7j_1jm8s_172",
  "styled-line-disabled": "awsui_styled-line-disabled_1fn7j_1jm8s_177",
  "styled-line-readonly": "awsui_styled-line-readonly_1fn7j_1jm8s_180"
};
  