import i18n from '@risksmart-app/components/providers/i18n';

import { Parent_Type_Enum } from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { auditItemSearch } from '@/utils/urls';

import { GetItem } from './types';

export const getItem: GetItem = (item, lookupData) => {
  const riskId = item.data?.objectId;
  const risk = lookupData.risks?.[riskId];

  return {
    message: i18n.t('notifications.messages.riskDelete', {
      title:
        risk?.SequentialId && risk?.Title
          ? `${risk?.Title}`
          : i18n.t('notifications.unknown'),
    }),
    url: risk ? auditItemSearch(risk.Id) : null,
    id: `${getFriendlyId(Parent_Type_Enum.Risk, risk?.SequentialId)}`,
  };
};
