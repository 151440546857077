import { SpaceBetween } from '@cloudscape-design/components';
import Button from '@risksmart-app/components/Button';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';
import { AssessmentSummary } from 'src/pages/assessments/AssessmentSummary';
import { useGetCollectionTableProps } from 'src/pages/assessments/config';
import { Permission } from 'src/rbac/Permission';

import { useGetInternalAuditReportsQuery } from '@/generated/graphql';
import { handleError } from '@/utils/errorUtils';
import { internalAuditReportAddUrl } from '@/utils/urls';

const InternalAuditReportPage: FC = () => {
  const { t } = useTranslation('common');
  const { addNotification } = useNotifications();
  const { t: st } = useTranslation('common', {
    keyPrefix: 'internalAuditReports',
  });
  const { data, loading } = useGetInternalAuditReportsQuery({
    onError: (error) => {
      handleError(error);
      addNotification({
        type: 'error',
        content: <>{error.message}</>,
      });
    },
  });
  const tableProps = useGetCollectionTableProps(
    'internal_audit_report',
    data?.internal_audit_report,
    data?.form_configuration?.[0]?.customAttributeSchema
  );
  const assessmentCount = useMemo(() => {
    if (loading) {
      return '';
    }

    return `(${data?.internal_audit_report?.length})`;
  }, [data, loading]);

  return (
    <PageLayout
      helpTranslationKey={'internalAuditReports.registerHelp'}
      title={st('register_title')}
      counter={assessmentCount}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Button iconName="download" onClick={tableProps.exportToCsv}>
            {t('export')}
          </Button>
          <Permission permission={'insert:internal_audit_report'}>
            <Button variant="primary" href={internalAuditReportAddUrl()}>
              {st('create_new_button')}
            </Button>
          </Permission>
        </SpaceBetween>
      }
    >
      <AssessmentSummary
        propertyFilterQuery={tableProps.propertyFilterQuery}
        items={tableProps?.allItems}
        onFilterQueryChanged={tableProps.actions.setPropertyFiltering}
        filteringProperties={tableProps.filteringProperties}
        assessmentMode={'internal_audit_report'}
      />
      <Table {...tableProps} loading={loading} />
    </PageLayout>
  );
};

export default InternalAuditReportPage;
