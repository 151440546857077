import { FC, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor as TinyEditor } from 'tinymce';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import { FormContextProps } from '@/components/Form/Form/types';
import {
  Parent_Type_Enum,
  useGetGlobalApprovalsQuery,
  Version_Status_Enum,
} from '@/generated/graphql';

import {
  DocumentFileFormSchema,
  DocumentVersionFormFieldData,
} from './documentFileSchema';
import DocumentVersionFormFields from './DocumentVersionFormFields';

export type Props = Omit<
  FormContextProps<DocumentVersionFormFieldData>,
  | 'schema'
  | 'formId'
  | 'parentType'
  | 'i18n'
  | 'renderTemplate'
  | 'submitActions'
> & {
  readonly?: boolean;
  isCreatingNewEntity: boolean;
  savedStatus: Version_Status_Enum;
  editorRef: MutableRefObject<TinyEditor | null>;
  parentId: string;
  hasPendingChangeRequests: boolean;
  disableStatus: boolean;
};

const DocumentVersionForm: FC<Props> = ({
  savedStatus,
  disableStatus,
  hasPendingChangeRequests,
  readOnly,
  isCreatingNewEntity,
  parentId,
  onSave,
  editorRef,
  ...props
}) => {
  const { t: st } = useTranslation(['common']);
  const isDocumentFileDraft = savedStatus === Version_Status_Enum.Draft;
  const isPublished = savedStatus === Version_Status_Enum.Published;
  const allowStatusChange =
    !hasPendingChangeRequests && !readOnly && isCreatingNewEntity;
  const { data } = useGetGlobalApprovalsQuery({
    variables: { parentId },
  });

  const requiresApproval = data?.approval.some(
    (a) => a.Workflow === 'publish-document-version'
  );

  return (
    <CustomisableFormContext
      {...props}
      onSave={onSave}
      parentType={Parent_Type_Enum.DocumentFile}
      header={st('details')}
      i18n={st('documentFiles')}
      schema={DocumentFileFormSchema}
      formId={'document-file-form'}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      submitActions={[
        {
          label: st('save'),
          action: onSave,
        },
        ...(allowStatusChange && isDocumentFileDraft
          ? [
              {
                label: requiresApproval
                  ? st('submit_for_approval')
                  : st('publish'),
                action: (data: DocumentVersionFormFieldData) =>
                  onSave({
                    ...data,
                    Status: Version_Status_Enum.Published,
                  }),
              },
            ]
          : []),
        ...(allowStatusChange && isPublished
          ? [
              {
                label: st('archive'),
                action: (data: DocumentVersionFormFieldData) =>
                  onSave({
                    ...data,
                    Status: Version_Status_Enum.Archived,
                  }),
              },
            ]
          : []),
      ]}
    >
      <DocumentVersionFormFields
        disableStatus={disableStatus}
        isCreatingNewEntity={isCreatingNewEntity}
        editorRef={editorRef}
        savedStatus={savedStatus}
        parentId={parentId}
      />
    </CustomisableFormContext>
  );
};

export default DocumentVersionForm;
