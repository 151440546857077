import { SelectProps } from '@cloudscape-design/components';
import { useMemo } from 'react';
import { FieldValues } from 'react-hook-form';
import { RatingKeys, RatingOption } from 'src/ratings/ratings';

import { FormField } from '@/components/Form/Form/FormField';
import HelpLink from '@/components/HelpPanel/HelpLink';
import RatingSwatch from '@/components/RatingSwatch';
import { useRating } from '@/hooks/use-rating';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import { getSelectedOptionFromFormValue, OptionWithColor } from '../form-utils';
import Select from '../Select';
import { ControlledBaseProps } from '../types';
type SelectOption = SelectProps.Option;
interface Props<T extends FieldValues> extends ControlledBaseProps<T> {
  type: RatingKeys;
  filteringType?: SelectProps.FilteringType;
  addEmptyOption?: boolean;
  disabled?: boolean;
  onChange?: (value: number | null) => void;
  testId?: string;
  showValue?: boolean;
}

export const ControlledRating = <T extends FieldValues>({
  name,
  control,
  type,
  label,
  addEmptyOption,
  onChange,
  forceRequired,
  defaultRequired,
  allowDefaultValue,
  testId,
  showValue,
  description,
  ...props
}: Props<T>) => {
  const { error } = control.getFieldState(name);
  const readOnly = useIsFieldReadOnly(name);

  const emptyOption: OptionWithColor = {
    value: '',
    label: '-',
    color: 'light-grey',
  };

  const ratings = useGetOptions(type);
  const options = addEmptyOption ? [emptyOption, ...ratings] : ratings;

  if (options?.length === 0 || !options) {
    throw new Error('Rating options missing');
  }

  return (
    <Controller
      defaultRequired={defaultRequired}
      forceRequired={forceRequired}
      allowDefaultValue={allowDefaultValue}
      defaultValueOptions={options}
      name={name}
      control={control}
      render={({ field: { ref, onChange: onChangeForm, onBlur, value } }) => {
        const selectedOption = getSelectedOptionFromFormValue(
          String(value),
          options
        );

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const rating = useMemo(() => {
          if (!selectedOption) {
            return null;
          }

          return convertSelectOptionToRatingOption(selectedOption);
        }, [selectedOption]);

        return (
          <FormField
            label={label}
            errorText={error?.message}
            stretch
            testId={testId}
            info={
              description && (
                <HelpLink title={label} content={description} id={label} />
              )
            }
          >
            <div>
              <div className="flex">
                <div className="flex-1">
                  <Select
                    ref={ref}
                    selectedOption={selectedOption}
                    onBlur={onBlur}
                    onChange={(e) => {
                      const value = e.detail.selectedOption.value;
                      const nullableValue = value == '' ? null : Number(value);
                      onChangeForm(nullableValue);
                      onChange?.(nullableValue);
                    }}
                    options={options}
                    // TODO: translation
                    placeholder="Select"
                    // TODO: translation
                    empty="No matches found"
                    {...props}
                    disabled={readOnly || props.disabled}
                  />
                </div>
                <div className="grow-0 pl-4">
                  <RatingSwatch rating={rating} showValue={showValue} />
                </div>
              </div>
            </div>
          </FormField>
        );
      }}
    />
  );
};

function convertRatingOptionToSelectOption(option: RatingOption): SelectOption {
  return {
    ...option,
    value: String(option.value),
  };
}

function convertSelectOptionToRatingOption(
  option: SelectOption & { color?: string }
): RatingOption {
  if (option.label === undefined) {
    throw new Error('Option must have a label');
  }
  if (option.value === undefined) {
    throw new Error('Option must have a value');
  }

  return {
    label: option.label,
    value: Number(option.value),
    color: option.color,
  };
}

function useGetOptions(type: RatingKeys): SelectOption[] {
  const convert = (options: RatingOption[]) =>
    options.map((option) => convertRatingOptionToSelectOption(option));
  const ratings = useRating(type);

  return convert(ratings.options);
}
