import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from 'src/layouts';

import ControlledTabs from '@/components/ControlledTabs';

import { useTabs } from '../update/useTabs';

interface Props {}

const Page: FC<Props> = () => {
  const { t } = useTranslation(['common']);
  const title = t('internalAudits.create_title');
  const tabs = useTabs();

  return (
    <PageLayout title={title}>
      <ControlledTabs tabs={tabs} variant="container" />
    </PageLayout>
  );
};

export default Page;
