import {
  GetFormFieldOptionsByParentTypeQueryResult,
  useGetFormFieldOptionsByParentTypeQuery,
} from '@/generated/graphql';

import { useRiskSmartForm } from '../RiskSmartFormContext';

type FieldConfig = NonNullable<
  GetFormFieldOptionsByParentTypeQueryResult['data']
>['form_field_configuration'][number];

const DEFAULT_FIELD_CONFIG: FieldConfig = {
  Required: false,
  ReadOnly: false,
  Hidden: false,
  FieldId: '',
};

type UseFieldConfigOptions = {
  defaultRequired?: boolean;
};

export const useFieldConfig = (
  fieldId?: string,
  options?: UseFieldConfigOptions
) => {
  const { parentType } = useRiskSmartForm();

  const { data } = useGetFormFieldOptionsByParentTypeQuery({
    variables: { parentType: parentType! },
    skip: !parentType,
  });

  if (!parentType) {
    return null;
  }

  const fieldOptionsArray = data?.form_field_configuration;
  const fieldOptionsData = fieldOptionsArray?.find(
    (field) => field.FieldId === fieldId
  );

  return (
    fieldOptionsData ?? {
      ...DEFAULT_FIELD_CONFIG,
      Required: options?.defaultRequired ?? false,
    }
  );
};
