import { FC } from 'react';

import { useGetUserGroupByIdQuery } from '@/generated/graphql';

type Props = {
  groupId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ groupId }) => {
  if (!groupId) {
    throw new Error('Missing groupId param');
  }
  const { data } = useGetUserGroupByIdQuery({
    variables: {
      Id: groupId,
    },
  });

  return <>{data?.user_group?.[0]?.Name || ''}</>;
};

export default Breadcrumb;
