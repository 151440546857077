import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFileUpdate } from 'src/data/rest/useFileUpdate';
import {
  ActionFormFieldData,
  defaultValues,
} from 'src/pages/actions/update/forms/actionsSchema';
import { getContributors, getOwners } from 'src/rbac/contributorHelper';
import { ObjectWithContributors } from 'src/rbac/Permission';

import { ownerAndContributorIds } from '@/components/Form';
import { PageWrapper } from '@/components/Form/Form/PageWrapper';
import {
  Parent_Type_Enum,
  useGetActionByIdQuery,
  useInsertChildActionMutation,
} from '@/generated/graphql';
import { evictField } from '@/utils/graphqlUtils';

import ActionForm from '../../actions/update/forms/ActionForm';
import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from '../useAssessmentTypeConfig';

type Props = {
  assessmentMode: AssessmentTypeEnum;
  readonly: boolean;
  assessmentId: string;
  assessedItem?: ObjectWithContributors;
  id?: string;
  onDismiss?: (saved: boolean) => void;
  beforeFieldsSlot?: ReactNode;
  showAssessmentSelector?: boolean;
  header?: string;
};

const ConnectedActionForm: FC<Props> = ({
  assessmentMode,
  readonly,
  assessmentId,
  id,
  onDismiss,
  beforeFieldsSlot,
  header,
}) => {
  const navigate = useNavigate();
  const { data } = useGetActionByIdQuery({
    variables: {
      _eq: id!,
    },
    skip: !id,
  });
  const action = data?.action?.[0];
  const {
    routing: { resultsRegisterUrl },
  } = useAssessmentTypeConfig(assessmentMode);

  const { updateFiles } = useFileUpdate();
  const [insertChildActionMutation] = useInsertChildActionMutation({
    update: (cache) => {
      evictField(cache, 'assessment');
      evictField(cache, 'internal_audit_report');
      evictField(cache, 'compliance_monitoring_assessment');
      evictField(cache, 'action');
      evictField(cache, 'assessment_by_pk');
    },
  });

  const onSave = async (values: ActionFormFieldData) => {
    const { files, newFiles } = values;
    const result = await insertChildActionMutation({
      variables: {
        ...values,
        ParentId: assessmentId!,
        CustomAttributeData: values?.CustomAttributeData || undefined,
        ...ownerAndContributorIds(values),
      },
    });

    if (!result.data?.insertChildAction?.Id) {
      throw new Error('Action id is missing');
    }

    await updateFiles({
      parentId: result.data?.insertChildAction?.Id,
      parentType: Parent_Type_Enum.Action,
      newFiles,
      selectedFiles: files,
      originalFiles: action?.files ?? [],
    });
    navigate(resultsRegisterUrl(assessmentId));
  };

  return (
    <ActionForm
      header={header}
      onDismiss={onDismiss}
      values={{
        ...defaultValues,
        ...action,
        Owners: getOwners(action),
        Contributors: getContributors(action),
        ancestorContributors: action?.ancestorContributors ?? [],
      }}
      onSave={onSave}
      readOnly={readonly}
      renderTemplate={(renderProps) => <PageWrapper {...renderProps} />}
      beforeFieldsSlot={beforeFieldsSlot}
    />
  );
};

export default ConnectedActionForm;
