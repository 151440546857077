import { Toggle, ToggleProps } from '@cloudscape-design/components';
import React, { forwardRef } from 'react';
import { FieldValues, useFormContext } from 'react-hook-form';

import { Controller } from '../FieldController/Controller';
import { useIsFieldReadOnly } from '../Form/CustomisableForm/hooks/useIsFieldReadOnly';
import { ControlledBaseProps } from '../types';

type SwitchProps = ToggleProps & { label?: string; testId?: string };

export const Switch = forwardRef<ToggleProps.Ref, SwitchProps>(
  ({ label = '', testId, ...switchProps }, ref) => {
    return (
      <Toggle data-testid={testId} ref={ref} {...switchProps}>
        {label}
      </Toggle>
    );
  }
);

Switch.displayName = 'Switch';

type ControlledSwitchProps<T extends FieldValues> = ControlledBaseProps<T> &
  Omit<SwitchProps, 'checked'>;

export function ControlledSwitch<T extends FieldValues>(
  props: ControlledSwitchProps<T>
) {
  const { control } = useFormContext<FieldValues>();
  const readOnly = useIsFieldReadOnly(props.name);

  return (
    <Controller
      defaultRequired={props.defaultRequired}
      forceRequired={props.forceRequired}
      allowDefaultValue={props.allowDefaultValue}
      name={props.name}
      control={control}
      render={({ field: { ref, onChange, onBlur, value } }) => (
        <Switch
          {...props}
          ref={ref}
          onChange={(e) => onChange(e.detail.checked)}
          onBlur={onBlur}
          checked={value}
          disabled={readOnly || props.disabled}
        />
      )}
    />
  );
}
