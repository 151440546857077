import _ from 'lodash';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalWrapper } from '@/components/Form/Form/ModalWrapper';
import { useGetImpactListQuery } from '@/generated/graphql';

import MultipleImpactRatingsForm from './forms/MultipleImpactRatingsForm/MultipleImpactRatingsForm';
import {
  ImpactRatingsFormFieldData,
  useDefaultValues,
} from './forms/MultipleImpactRatingsForm/MultipleImpactRatingsFormSchema';

type Props = {
  onDismiss: () => void;
  onSaving: (action: ImpactRatingsFormFieldData) => Promise<void>;
  impactRatingId?: string;
  impactId?: string;
};

const InsertMultipleImpactRatingsModal: FC<Props> = ({
  onDismiss,
  onSaving,
}) => {
  const { data: impacts, loading } = useGetImpactListQuery();
  const { t } = useTranslation();
  const defaultValues = useDefaultValues();
  const sortedImpacts = useMemo(
    () => _.sortBy(impacts?.impact ?? [], 'SequentialId'),
    [impacts?.impact]
  );
  if (loading) {
    return;
  }

  return (
    <MultipleImpactRatingsForm
      defaultValues={{
        ...defaultValues,
        Ratings:
          sortedImpacts.map((impact) => {
            return {
              ImpactId: impact.Id,
              Rating: -1,
            };
          }) ?? [],
      }}
      onSave={onSaving}
      onDismiss={onDismiss}
      renderTemplate={(renderProps) => (
        <ModalWrapper
          {...renderProps}
          visible={true}
          size={'max'}
          i18n={t('impactRatingsMultiple')}
        />
      )}
    />
  );
};

export default InsertMultipleImpactRatingsModal;
