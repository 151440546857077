import Button from '@risksmart-app/components/Button';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import Link from '@/components/Link';
import SimpleRatingBadge from '@/components/SimpleRatingBadge';
import { useRating } from '@/hooks/use-rating';
import { UseGetTablePropsOptions } from '@/utils/table/hooks/useGetStatelessTableProps';
import { useGetTableProps } from '@/utils/table/hooks/useGetTableProps';
import { TableFields, TablePropsWithActions } from '@/utils/table/types';
import { dateColumn } from '@/utils/table/utils/dateColumn';

import {
  ObjectWithContributors,
  Permission,
} from '../../../../../../rbac/Permission';
import { UNRATED } from '../../../../../controls/lookupData';
import {
  ObligationAssessmentResultFlatFields,
  ObligationAssessmentResultRegisterFields,
} from './types';
import { useObligationRatingLabelledFields } from './useObligationRatingLabelledFields';

const useGetFieldConfig = (
  onOpenResult: (id: string) => void
): TableFields<ObligationAssessmentResultRegisterFields> => {
  const { t: at } = useTranslation(['common'], {
    keyPrefix: 'assessments',
  });
  const { t: rt } = useTranslation(['common'], {
    keyPrefix: 'ratings.columns',
  });
  const { getByValue: getResultByValue } = useRating('performance_result');
  const { getByValue: statusGetByValue } = useRating('assessment_status');

  return useMemo(
    () => ({
      TestDate: dateColumn(rt('TestDate'), 'TestDate', (item) =>
        onOpenResult(item.Id)
      ),
      Title: {
        header: rt('AssessmentTitle'),
        cell: (item) => (
          <Link variant="secondary" href={item.LinkedAssessmentId}>
            {item.Title}
          </Link>
        ),
        sortingField: 'Title',
        isRowHeader: true,
      },
      Result: {
        header: rt('Rating'),
        cell: (item) => {
          const result = getResultByValue(item.Rating);

          return <SimpleRatingBadge rating={result ?? UNRATED} />;
        },
        sortingField: 'ResultValue',
      },
      Status: {
        header: at('columns.Status'),
        cell: (item) => {
          return <SimpleRatingBadge rating={statusGetByValue(item.Status)} />;
        },
        sortingField: 'Status',
      },
      CompletionDate: dateColumn(rt('CompletionDate'), 'CompletionDate'),
      NextTestDate: dateColumn(at('columns.NextTestDate'), 'NextTestDate'),
    }),
    [at, getResultByValue, onOpenResult, rt, statusGetByValue]
  );
};

const useGetObligationRatingTableProps = (
  records: ObligationAssessmentResultFlatFields[] | undefined,
  onOpenResult: (id: string) => void,
  customAttributeSchema?: CustomAttributeFields | null
): UseGetTablePropsOptions<ObligationAssessmentResultRegisterFields> => {
  const labelledFields = useObligationRatingLabelledFields(records);
  const fields = useGetFieldConfig(onOpenResult);

  return {
    customAttributeSchema: customAttributeSchema,
    data: labelledFields,
    enableFiltering: true,
    entityLabel: 'rating',
    defaultSortingState: {
      sortingColumn: 'TestDate',
      sortingDirection: 'desc',
    },
    initialColumns: [
      'TestDate',
      'Title',
      'Result',
      'Status',
      'CompletionDate',
      'NextTestDate',
    ],
    storageKey: 'ObligationRatingRegisterTable-Preferences',
    fields,
  };
};

export const useGetCollectionTableProps = (
  obligation: ObjectWithContributors,
  records: ObligationAssessmentResultFlatFields[] | undefined,
  onOpenResult: (id: string) => void,
  onAddRating: () => void,
  customAttributeSchema?: CustomAttributeFields | null
): TablePropsWithActions<ObligationAssessmentResultRegisterFields> => {
  const { t: st } = useTranslation(['common']);
  const props = useGetObligationRatingTableProps(
    records,
    onOpenResult,
    customAttributeSchema
  );

  return {
    ...useGetTableProps(props),
    empty: (
      <EmptyEntityCollection
        entityLabel={st('obligationsAssessments.entity_name')}
        action={
          <Permission
            permission="insert:obligation_assessment_result"
            parentObject={obligation}
          >
            <Button formAction="none" onClick={onAddRating}>
              {st('obligationsAssessments.add_rating_button')}
            </Button>
          </Permission>
        }
      />
    ),
  };
};
