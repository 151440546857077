import { PropertyFilterOption } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import { useMemo } from 'react';

import { TableRecord } from '../types';
import { TableFieldsWithCustomAttributes } from './useAddCustomAttributeFieldData';

export const useCreateFilterOptions = <T extends TableRecord>(
  tableFields: TableFieldsWithCustomAttributes<T>
) => {
  return useMemo((): PropertyFilterOption[] => {
    return Object.entries(tableFields).flatMap(([fieldName, fieldConfig]) => {
      const { filteringOptions } = fieldConfig.filterOptions ?? {};

      return (
        filteringOptions?.map((option) => ({
          ...option,
          propertyKey:
            fieldConfig.filterOptions?.filteringProperties?.key || fieldName,
        })) ?? []
      );
    });
  }, [tableFields]);
};
