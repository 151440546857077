import i18n from '@risksmart-app/components/providers/i18n';
import clsx from 'clsx';
import { FC } from 'react';

import { NoWidgetData } from '../NoWidgetData';
import { WidgetLoading } from '../WidgetLoading';
import styles from './style.module.scss';

type Props = {
  unit?: string;
  loading?: boolean;
  value?: number;
  onClick?: () => void;
};

const NumberWidget: FC<Props> = ({ value, unit, loading, onClick }) => {
  if (loading) {
    return <WidgetLoading />;
  }
  if (value === undefined) {
    return <NoWidgetData />;
  }

  return (
    <div className="flex items-center flex-wrap justify-center h-full">
      <div className={styles.widget}>
        <span
          onClick={onClick}
          className={clsx(styles.number, { [styles.clickable]: !!onClick })}
        >
          {Math.round(value * 10) / 10}
        </span>
        <span className={styles.units}>
          {unit ?? i18n.t('dashboard.units.total')}
        </span>
      </div>
    </div>
  );
};

export default NumberWidget;
