import { FC } from 'react';

import { useGetQuestionnaireTemplateVersionByIdQuery } from '@/generated/graphql';

type Props = {
  questionnaireTemplateVersionId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ questionnaireTemplateVersionId }) => {
  if (!questionnaireTemplateVersionId) {
    throw new Error('Missing questionnaireTemplateVersionId param');
  }

  const { data } = useGetQuestionnaireTemplateVersionByIdQuery({
    variables: {
      Id: questionnaireTemplateVersionId,
    },
  });

  return <>{data?.questionnaire_template_version?.Version || ''}</>;
};

export default Breadcrumb;
