import { MultiselectProps } from '@cloudscape-design/components';
import { FC } from 'react';

import { HidableOption } from '../ControlledMultiselect/types';
import useFilterHiddenOptions from '../ControlledMultiselect/useFilterHiddenOptions';
import MultiSelect from '.';

const HiddenOptionMultiSelect: FC<MultiselectProps> = ({
  options,
  ...rest
}) => {
  const filteredOptions = useFilterHiddenOptions(
    options as HidableOption[] | undefined
  );

  return <MultiSelect {...rest} options={filteredOptions} />;
};

export default HiddenOptionMultiSelect;
