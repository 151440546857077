
    import './styles.scoped.css';
    export default {
  "sticky-scrollbar": "awsui_sticky-scrollbar_faqt8_z3xex_177",
  "sticky-scrollbar-content": "awsui_sticky-scrollbar-content_faqt8_z3xex_186",
  "sticky-scrollbar-visible": "awsui_sticky-scrollbar-visible_faqt8_z3xex_189",
  "sticky-scrollbar-native-invisible": "awsui_sticky-scrollbar-native-invisible_faqt8_z3xex_192",
  "sticky-scrollbar-offset": "awsui_sticky-scrollbar-offset_faqt8_z3xex_195",
  "is-visual-refresh": "awsui_is-visual-refresh_faqt8_z3xex_198"
};
  