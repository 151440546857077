import * as React from 'react';
import { PropsWithChildren } from 'react';
import toast, { Toaster } from 'react-hot-toast';

import { NotificationBanner } from '../NotificationBanner/NotificationBanner';
import { PromiseNotificationBanner } from '../NotificationBanner/PromiseNotificationBanner';

export type NotificationDetail =
  | {
      type?: 'success' | 'error';
      content: React.ReactNode;
    }
  | {
      type: 'promise';
      promise: Promise<unknown>;
      successMessage: React.ReactNode;
      errorMessage: React.ReactNode;
    };

const NotificationProvider = (props: PropsWithChildren) => {
  return (
    <div>
      <Toaster />
      {props.children}
    </div>
  );
};

function useNotifications() {
  const addNotification = (notification: NotificationDetail) => {
    switch (notification.type) {
      case 'success':
        toast.custom(
          (t) => (
            <NotificationBanner {...notification} toast={t} durationMs={3500} />
          ),
          {
            duration: Infinity,
          }
        );
        break;
      case 'error':
        toast.custom(
          (t) => (
            <NotificationBanner
              {...notification}
              toast={t}
              durationMs={Infinity}
              dismissable={true}
            />
          ),
          {
            duration: Infinity,
          }
        );
        break;
      case 'promise':
        toast.custom(
          (t) => <PromiseNotificationBanner {...notification} toast={t} />,
          {
            duration: Infinity,
          }
        );
        break;
      default:
        toast(
          (t) => (
            <NotificationBanner {...notification} toast={t} durationMs={3500} />
          ),
          {
            duration: Infinity,
          }
        );
    }
  };

  return { addNotification };
}

export { NotificationProvider, useNotifications };
