import { useTranslation } from 'react-i18next';

import { GetQuestionnaireTemplateByIdQuery } from '@/generated/graphql';

import { TabsProps } from '../../../../../theme/risksmart-themed/components';
import { useHasPermission } from '../../../rbac/Permission';
import QuestionnaireTemplateCreateTab from '../create/tabs/QuestionnaireTemplateCreateTab';
import Tab from './tabs/details/Tab';
import VersionsTab from './tabs/versions/Tab';

export const useTabs = (
  questionnaireTemplate?: GetQuestionnaireTemplateByIdQuery['questionnaire_template'],
  detailsPath?: string
) => {
  const detailsOnly = !questionnaireTemplate;
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'questionnaire_templates.tabs',
  });

  const canViewQuestionnaireTemplateVersions = useHasPermission(
    'read:questionnaire_template_version',
    questionnaireTemplate,
    true
  );

  const tabs: TabsProps.Tab[] = [
    {
      label: st('details'),
      id: 'details',
      content: detailsOnly ? <QuestionnaireTemplateCreateTab /> : <Tab />,
      href: detailsOnly
        ? undefined
        : `/third-party/questionnaire-templates/${questionnaireTemplate.Id}`,
    },
    ...(canViewQuestionnaireTemplateVersions
      ? [
          {
            label: st('versions'),
            id: 'versions',
            content: questionnaireTemplate && <VersionsTab />,
            href: `${detailsPath}/versions`,
            disabled: detailsOnly,
          },
        ]
      : []),
  ];

  return tabs;
};
