import { ResultOf, VariablesOf } from '@graphql-typed-document-node/core';
import i18n from '@risksmart-app/components/providers/i18n';
import _ from 'lodash';
import { useMemo } from 'react';
import { ControlTestTableFields } from 'src/pages/controls/controlTests/types';

import { Parent_Type_Enum } from '@/generated/graphql';
import { GetWidgetTestResultsDocument } from '@/generated/graphql.typed';
import { controlTestsRegisterUrl } from '@/utils/urls';

import { useGetControlTestSmartWidgetTableProps } from '../../../controls/controlTests/config';
import { UNRATED } from '../../Gigawidget/types';
import {
  dateRangeFilter,
  departmentsFilter,
  tagsFilter,
} from '../../Gigawidget/util/filterHelpers';
import { createDataSource } from '../createDataSource';
import {
  dashboardDateClickthroughFilter,
  dateClickthroughFilter,
  defaultClickthroughFilterWithUnrated,
} from '../dataSourceHelpers';

export default createDataSource<
  ControlTestTableFields,
  VariablesOf<typeof GetWidgetTestResultsDocument>,
  ResultOf<typeof GetWidgetTestResultsDocument>
>({
  parentTypes: [Parent_Type_Enum.TestResult],
  hasAccess: () => true,
  documentNode: GetWidgetTestResultsDocument,
  defaultVariables: {
    where: {
      RatingType: {
        _in: ['assessment', 'rating'],
      },
    },
  },
  useTablePropsHook: (data, options) => {
    const testData = useMemo(
      () => data?.control.flatMap((c) => c.testResults),
      [data?.control]
    );

    return useGetControlTestSmartWidgetTableProps(
      testData,
      data?.form_configuration[0]?.customAttributeSchema ?? null,
      options
    );
  },
  entityNamePlural: 'control_test_other',
  entityNameSingular: 'control_test_one',
  fields: 'testResults.columns',
  dashboardFilterConfig: {
    tagsFilter: (tags) => ({ controlWhere: { tags: tagsFilter(tags) } }),
    departmentsFilter: (departments) => ({
      controlWhere: { departments: departmentsFilter(departments) },
    }),
    dateFilter: (dateRange, precision) => ({
      where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
    }),
    dateClickthroughFilter:
      dashboardDateClickthroughFilter('CreatedAtTimestamp'),
  },
  clickThroughUrl: (filters) => controlTestsRegisterUrl(filters),
  categoryGetters: [
    {
      id: 'testType',
      name: () => i18n.t('testResults.columns.test_type'),
      categoryGetter: (data) => data.TestType ?? null,
      clickthroughFilter: defaultClickthroughFilterWithUnrated('TestType'),
    },
    {
      id: 'result',
      name: () => i18n.t('testResults.columns.overall_effectiveness'),
      categoryGetter: (data) => ({
        key: data.OverallEffectiveness ?? null,
        label: data.OverallEffectivenessLabelled ?? UNRATED,
      }),
      ratingColourKey: 'effectiveness',
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'OverallEffectivenessLabelled'
      ),
    },
    {
      id: 'performedBy',
      name: () => i18n.t('testResults.columns.submitter'),
      categoryGetter: (data) => data.SubmitterNameLabelled,
      clickthroughFilter: defaultClickthroughFilterWithUnrated(
        'SubmitterNameLabelled'
      ),
    },
    {
      id: 'testDate',
      name: () => i18n.t('testResults.columns.date'),
      categoryGetter: (data) => new Date(data.TestDate),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: { TestDate: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('TestDate'),
    },
    {
      id: 'nextTestDate',
      name: () => i18n.t('testResults.columns.next_test_date'),
      categoryGetter: (data) =>
        data.parent?.NextTestDate ? new Date(data.parent.NextTestDate) : null,
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: {
          parent: {
            NextTestDate: dateRangeFilter(dateRange, precision),
          },
        },
      }),
      clickthroughFilter: dateClickthroughFilter('NextTestDate'),
    },
    {
      id: 'createdDate',
      name: () => i18n.t('columns.created_on'),
      categoryGetter: (data) => new Date(data.CreatedAtTimestamp),
      date: true,
      dateFilter: (dateRange, precision) => ({
        where: { CreatedAtTimestamp: dateRangeFilter(dateRange, precision) },
      }),
      clickthroughFilter: dateClickthroughFilter('CreatedAtTimestamp'),
    },
  ],
});
