import {
  PropertyFilterOperator,
  PropertyFilterProperty,
} from '@cloudscape-design/collection-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CommaSeparatedCell from '@/components/CommaSeparatedCell';
import { toSingleCell } from '@/components/CommaSeparatedCell/CommaSeparatedCell';
import { useGetUserGroupsQuery, useGetUsersQuery } from '@/generated/graphql';

import { FieldConfig } from '../types';

const createContributorFieldPropertyFilter = (
  options: { label: string; id: string }[]
): Partial<PropertyFilterProperty> => ({
  operators: (['=', ':'] as PropertyFilterOperator[]).map((operator) => ({
    operator,
    format: (id): string => {
      return options.find((t) => t.id === id)?.label || '-';
    },
    match: (users: unknown, id: string) => {
      return !!((users || []) as { label: string; id: string }[]).find(
        (t) => t.id === id
      );
    },
  })),
});

export function useGetContributorsFieldConfig<
  T extends {
    Id: string;
    allContributors: { label: string; id: string }[];
  },
>(): FieldConfig<T> {
  const { data: users } = useGetUsersQuery();
  const { data: userGroups } = useGetUserGroupsQuery();
  const { t } = useTranslation(['common'], { keyPrefix: 'columns' });
  const userOptions = useMemo(
    () =>
      users?.user.map((u) => ({
        label: u.FriendlyName ?? '',
        id: u.Id ?? '',
      })) ?? [],
    [users?.user]
  );
  const groupOptions = useMemo(
    () =>
      userGroups?.user_group.map((u) => ({ label: u.Name, id: u.Id })) ?? [],
    [userGroups?.user_group]
  );

  return useMemo(
    () => ({
      header: t('contributors'),
      cell: (item) => <CommaSeparatedCell items={item.allContributors} />,
      filterOptions: {
        filteringProperties: createContributorFieldPropertyFilter([
          ...userOptions,
          ...groupOptions,
        ]),
      },
      sortingComparator: (a, b) => {
        const contributorsA = toSingleCell(a.allContributors);
        const contributorsB = toSingleCell(b.allContributors);

        return contributorsA.localeCompare(contributorsB);
      },
      exportVal: (item) =>
        item.allContributors
          .map((contributor) => contributor.label || '')
          .join(','),
    }),
    [groupOptions, t, userOptions]
  );
}
