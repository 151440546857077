import { SelectProps } from '@cloudscape-design/components';
import { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import ControlledAutosuggest from '@/components/Form/ControlledAutosuggest';
import ControlledDatePicker from '@/components/Form/ControlledDatePicker';
import ControlledGroupAndUserContributorMultiSelect from '@/components/Form/ControlledGroupAndUserContributorMultiSelect';
import ControlledRadioGroup from '@/components/Form/ControlledRadioGroup';
import ControlledSelect from '@/components/Form/ControlledSelect';
import ControlledTextarea from '@/components/Form/ControlledTextarea';
import DepartmentSelector from '@/components/Form/DepartmentSelector';
import ConditionalField from '@/components/Form/Form/CustomisableForm/ConditionalField';
import CustomisableForm from '@/components/Form/Form/CustomisableForm/CustomisableForm';
import FieldGroup from '@/components/Form/Form/CustomisableForm/FieldGroup';
import TagSelector from '@/components/Form/TagSelector';
import {
  Contributor_Type_Enum,
  Obligation_Type_Enum,
  Parent_Type_Enum,
  useGetObligationsByTypeQuery,
} from '@/generated/graphql';
import { useLibrary } from '@/hooks/use-library';
import { getFriendlyId } from '@/utils/friendlyId';

import { ObligationFormFieldData } from './obligationSchema';

const getObligationParentType = (type: Obligation_Type_Enum) => {
  switch (type) {
    case Obligation_Type_Enum.Chapter:
      return Obligation_Type_Enum.Standard;
    case Obligation_Type_Enum.Rule:
      return Obligation_Type_Enum.Chapter;
  }
};

interface Props {
  readOnly?: boolean;
  obligationId?: string;
  parentObligationNode?: {
    Id: string;
    SequentialId?: number | null | undefined;
    ObjectType: Parent_Type_Enum;
  } | null;
}

export enum TestIds {
  ParentId = 'parentId',
}

const ObligationDetailsFormFields: FC<Props> = ({
  readOnly,
  obligationId,
  parentObligationNode,
}) => {
  const { t: st } = useTranslation(['common'], {
    keyPrefix: 'obligations',
  });
  const { t } = useTranslation(['common'], {});
  const { t: tr } = useTranslation(['ratings']);
  const { control, watch, setValue, formState } =
    useFormContext<ObligationFormFieldData>();

  const obligationsLibrary = useLibrary('obligations');

  const obligationType: Obligation_Type_Enum =
    watch('Type') || Obligation_Type_Enum.Standard;

  const obligationTitle: string = watch('Title') || '';
  const obligationTitleOptions = obligationsLibrary?.map((libItem) => ({
    value: libItem.title,
  }));

  const frequency = t('frequency');
  const frequencyOptions = Object.keys(frequency).map((key) => ({
    value: key,
    label: frequency[key as keyof typeof frequency],
  }));

  useEffect(() => {
    const libItem = obligationsLibrary.find(
      (obligation) => obligation.title === obligationTitle
    );
    if (libItem?.description && !formState.defaultValues?.Description) {
      setValue('Description', libItem?.description);
    }
    if (libItem?.type && !obligationId) {
      setValue('Type', libItem?.type);
    }
  }, [
    obligationTitle,
    formState.defaultValues?.Description,
    obligationsLibrary,
    setValue,
    obligationId,
  ]);

  const parentObligationType = getObligationParentType(obligationType);
  const { data: obligationsByType } = useGetObligationsByTypeQuery({
    variables: { type: parentObligationType! },
    skip: !parentObligationType,
  });

  const showParentField = obligationType !== Obligation_Type_Enum.Standard;
  const showDescriptionField = obligationType === Obligation_Type_Enum.Rule;

  const parentObligations = useMemo<SelectProps.Options | undefined>(() => {
    const parents =
      obligationsByType?.obligation
        .filter((obl) => obl.Id !== obligationId)
        .map((obl) => ({
          value: obl.Id,
          label: obl.Title,
        })) || [];
    if (parentObligationNode) {
      if (!parents?.find((p) => p.value === parentObligationNode.Id)) {
        parents?.push({
          label: getFriendlyId(
            Parent_Type_Enum.Obligation,
            parentObligationNode.SequentialId
          ),

          value: parentObligationNode?.Id || '',
        });
      }
    }

    return parents;
  }, [obligationId, obligationsByType?.obligation, parentObligationNode]);

  return (
    <CustomisableForm readOnly={readOnly}>
      <ControlledAutosuggest
        key="title"
        forceRequired={true}
        name="Title"
        label={st('fields.Title')}
        placeholder={st('fields.placeholders.Title')}
        description={st('fields.Title_help')}
        control={control}
        options={obligationTitleOptions}
        enableVirtualScroll={true}
        disabled={readOnly}
      />

      <FieldGroup key="typeGroup">
        <ControlledRadioGroup
          key="type"
          forceRequired={true}
          control={control}
          label={st('fields.Type')}
          description={st('fields.Type_help')}
          name="Type"
          onChange={() => {
            setValue('ParentId', null);
          }}
          transform={{
            input: (value) => value ?? Obligation_Type_Enum.Standard,
            output: (value) => value ?? Obligation_Type_Enum.Standard,
          }}
          items={[
            {
              value: Obligation_Type_Enum.Standard,
              label: st('fields.types.standard'),
            },
            {
              value: Obligation_Type_Enum.Chapter,
              label: st('fields.types.chapter'),
            },
            {
              value: Obligation_Type_Enum.Rule,
              label: st('fields.types.rule'),
            },
          ]}
          disabled={readOnly}
        />

        <ConditionalField condition={showParentField} key="parentId">
          <ControlledSelect
            forceRequired={true}
            control={control}
            name="ParentId"
            label={st('fields.ParentId')}
            description={st('fields.ParentId_help')}
            placeholder={st('fields.placeholders.ParentId')}
            options={parentObligations}
            disabled={readOnly}
            testId={TestIds.ParentId}
          />
        </ConditionalField>

        <ConditionalField condition={showDescriptionField} key="description">
          <ControlledTextarea
            name="Description"
            label={st('fields.Description')}
            description={st('fields.Description_help')}
            placeholder={st('fields.placeholders.Description')}
            control={control}
            forceRequired
            disabled={readOnly}
          />
        </ConditionalField>
      </FieldGroup>

      <ControlledTextarea
        key="interpretation"
        name="Interpretation"
        description={st('fields.Interpretation_help')}
        label={st('fields.Interpretation')}
        placeholder={st('fields.placeholders.Interpretation')}
        control={control}
        disabled={readOnly}
      />

      <ControlledSelect
        key="adherence"
        control={control}
        name="Adherence"
        forceRequired={true}
        description={st('fields.Adherence_help')}
        label={st('fields.Adherence')}
        placeholder={st('fields.placeholders.Adherence')}
        options={tr('adherence')}
        disabled={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        forceRequired={true}
        key="owners"
        control={control}
        inheritedContributorsName="ancestorContributors"
        contributorType={Contributor_Type_Enum.Owner}
        includeGroups={true}
        label={t('fields.Owner')}
        name="Owners"
        description={st('fields.Owner_help')}
        placeholder={t('fields.Owner_placeholder')}
        disabled={readOnly}
      />

      <ControlledGroupAndUserContributorMultiSelect
        key="contributors"
        control={control}
        includeGroups={true}
        inheritedContributorsName="ancestorContributors"
        contributorType={Contributor_Type_Enum.Owner}
        label={t('fields.Contributor')}
        name="Contributors"
        placeholder={t('fields.Contributor_placeholder')}
        description={st('fields.Contributor_help')}
        disabled={readOnly}
      />

      <ControlledSelect
        key="testFrequency"
        filteringType="auto"
        label={st('fields.TestFrequency')}
        description={st('fields.TestFrequency_help')}
        name="TestFrequency"
        placeholder="Select"
        control={control}
        addEmptyOption={true}
        options={frequencyOptions}
        disabled={readOnly}
      />

      <ControlledDatePicker
        key="nextTestDate"
        control={control}
        name="NextTestDate"
        label={st('fields.NextTestDate')}
        description={st('fields.NextTestDate_help')}
        disabled={readOnly}
      />

      <TagSelector
        name="tags"
        key="tags"
        control={control}
        disabled={readOnly}
      />
      <DepartmentSelector
        key="departments"
        name="departments"
        control={control}
        disabled={readOnly}
      />
    </CustomisableForm>
  );
};

export default ObligationDetailsFormFields;
