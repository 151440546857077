
    import './styles.scoped.css';
    export default {
  "grid": "awsui_grid_1mfbn_ili0e_145",
  "grid-cell": "awsui_grid-cell_1mfbn_ili0e_150",
  "day-header": "awsui_day-header_1mfbn_ili0e_157",
  "week": "awsui_week_1mfbn_ili0e_167",
  "day": "awsui_day_1mfbn_ili0e_157",
  "day-inner": "awsui_day-inner_1mfbn_ili0e_214",
  "in-first-row": "awsui_in-first-row_1mfbn_ili0e_219",
  "in-previous-month": "awsui_in-previous-month_1mfbn_ili0e_219",
  "last-day-of-month": "awsui_last-day-of-month_1mfbn_ili0e_223",
  "in-next-month": "awsui_in-next-month_1mfbn_ili0e_227",
  "in-first-column": "awsui_in-first-column_1mfbn_ili0e_231",
  "in-current-month": "awsui_in-current-month_1mfbn_ili0e_234",
  "enabled": "awsui_enabled_1mfbn_ili0e_238",
  "in-range": "awsui_in-range_1mfbn_ili0e_244",
  "end-date": "awsui_end-date_1mfbn_ili0e_244",
  "start-date": "awsui_start-date_1mfbn_ili0e_244",
  "no-range": "awsui_no-range_1mfbn_ili0e_244",
  "selected": "awsui_selected_1mfbn_ili0e_254",
  "today": "awsui_today_1mfbn_ili0e_259",
  "range-start-date": "awsui_range-start-date_1mfbn_ili0e_304",
  "in-range-border-bottom": "awsui_in-range-border-bottom_1mfbn_ili0e_307",
  "range-end-date": "awsui_range-end-date_1mfbn_ili0e_310",
  "in-range-border-top": "awsui_in-range-border-top_1mfbn_ili0e_313",
  "in-range-border-block-start": "awsui_in-range-border-block-start_1mfbn_ili0e_326",
  "in-range-border-inline-end": "awsui_in-range-border-inline-end_1mfbn_ili0e_329",
  "in-range-border-block-end": "awsui_in-range-border-block-end_1mfbn_ili0e_332",
  "in-range-border-inline-start": "awsui_in-range-border-inline-start_1mfbn_ili0e_335",
  "disabled-reason-tooltip": "awsui_disabled-reason-tooltip_1mfbn_ili0e_351"
};
  