import {
  CustomAttributeDataSchema,
  InheritedContributorSchema,
  NullableStringDateSchema,
  TagsAndDepartmentsSchema,
  UserOrGroupsSchema,
} from 'src/schemas/global';
import { z } from 'zod';

import { Test_Frequency_Enum } from '@/generated/graphql';

export const AttestationTimeLimitSchema = z.enum([
  '1 mon',
  '2 mons',
  '3 mons',
  '6 mons',
  '1 year',
  '2 years',
]);

export const AttestationFormSchema = z.object({
  requireAttestationFromEveryone: z
    .enum(['true', 'false'])
    .optional()
    .default('false'),
  attestationGroups: UserOrGroupsSchema,
  attestationTimeLimit: AttestationTimeLimitSchema.nullish(),
  attestationPromptText: z.string().nullish(),
});

export type AttestationFormFieldData = z.infer<typeof AttestationFormSchema>;

export const DocumentFormSchema = z
  .object({
    Title: z.string().min(1, { message: 'Required' }),
    Purpose: z.string({ invalid_type_error: 'Required' }).nullish(),
    DocumentType: z
      .string({ invalid_type_error: 'Required' })
      .min(1, { message: 'Required' }),
    ParentDocument: z.string().uuid().nullish(),
    linkedDocuments: z.array(
      z.object({
        value: z.string(),
      })
    ),
    Contributors: UserOrGroupsSchema,
    Owners: UserOrGroupsSchema.min(1, { message: 'Required' }),
    TagTypeIds: z.array(z.string()),
    DepartmentTypeIds: z.array(z.string()),
    NextTestDate: NullableStringDateSchema,
    ancestorContributors: InheritedContributorSchema,
    TestFrequency: z
      .nativeEnum(Test_Frequency_Enum, { invalid_type_error: 'Required' })
      .nullish(),
  })
  .and(AttestationFormSchema)
  .and(TagsAndDepartmentsSchema)
  .and(CustomAttributeDataSchema);

export type DocumentFormFieldData = z.infer<typeof DocumentFormSchema>;

export const defaultValues: DocumentFormFieldData = {
  Title: '',
  Purpose: null,
  ParentDocument: null,
  DocumentType: null as unknown as string,
  TagTypeIds: [],
  DepartmentTypeIds: [],
  linkedDocuments: [],
  tags: [],
  departments: [],
  Owners: [],
  Contributors: [],
  CustomAttributeData: null,
  ancestorContributors: [],
  TestFrequency: null,
  requireAttestationFromEveryone: 'false',
  attestationGroups: [],
  attestationTimeLimit: null,
  attestationPromptText: '',
};
