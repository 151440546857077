import 'pdfmake/build/vfs_fonts';

import { useTranslation } from 'react-i18next';
import useActionExportTable from 'src/pages/actions/update/useActionExportTable';
import useControlsExportTable from 'src/pages/controls/update/useControlsExportTable';
import useIndicatorExportTable from 'src/pages/indicators/update/useIndicatorExportTable';

import {
  Parent_Type_Enum,
  Risk_Assessment_Result_Control_Type_Enum,
  useGetRiskByIdLazyQuery,
} from '@/generated/graphql';
import { getFriendlyId } from '@/utils/friendlyId';
import { getContributorValue } from '@/utils/pdf/contributorValue';
import { getDepartmentsValue } from '@/utils/pdf/departmentValue';
import { createDocument } from '@/utils/pdf/document';
import { download } from '@/utils/pdf/downloader';
import { createField } from '@/utils/pdf/field';
import { createHeading, createSubHeading } from '@/utils/pdf/headings';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable } from '@/utils/pdf/table';
import { optionalTableSection } from '@/utils/pdf/tableSection';
import { getTagsValue } from '@/utils/pdf/tagsValue';
import { twoColumns } from '@/utils/pdf/twoColumns';
import useCustomAttributeDataForExport from '@/utils/pdf/useCustomAttributeDataForExport';

import useAcceptancesExportTable from './useAcceptancesExportTable';
import useAppetitesExportTable from './useAppetitesExportTable';
import useExportAssessmentDetails from './useExportAssessmentDetails';
import useExportComplianceMonitoringDetails from './useExportComplianceMonitoringDetails';
import useExportInternalAuditDetails from './useExportInternalAuditDetails';

const useExporter = (
  riskId: string,
  includeInternalAudit: boolean,
  includeComplianceMonitoring: boolean
): [() => void, { loading: boolean }] => {
  const [getCustomAttribute, customAttributesLoading] =
    useCustomAttributeDataForExport(Parent_Type_Enum.Risk);
  const [createIndicatorTable, indicatorsLoading] =
    useIndicatorExportTable(riskId);
  const [createControlsTable, controlsLoading] = useControlsExportTable(riskId);
  const [createActionTable, actionsLoading] = useActionExportTable(riskId);
  const [createAppetitesTable, appetitesLoading] =
    useAppetitesExportTable(riskId);
  const [createAcceptancesTable, acceptancesLoading] =
    useAcceptancesExportTable(riskId);
  const [createControlledAssessmentDetails, controlledAssessmentLoading] =
    useExportAssessmentDetails(
      riskId,
      Risk_Assessment_Result_Control_Type_Enum.Controlled
    );
  const [createUncontrolledAssessmentDetails, uncontrolledAssessmentLoading] =
    useExportAssessmentDetails(
      riskId,
      Risk_Assessment_Result_Control_Type_Enum.Uncontrolled
    );
  const [createComplianceMonitoringTable, complianceMonitoringLoading] =
    useExportComplianceMonitoringDetails(riskId);
  const [createInternalAuditTable, internalAuditLoading] =
    useExportInternalAuditDetails(riskId);

  const { t } = useTranslation(['common']);
  const { t: riskFields } = useTranslation(['common'], {
    keyPrefix: 'risks.fields',
  });

  const { t: fields } = useTranslation(['common'], { keyPrefix: 'fields' });
  const tiers = t('tiers', { returnObjects: true });
  const treatments = t('treatments', { returnObjects: true });
  const statuses = t('statuses', { returnObjects: true });
  const [getRisk, getRiskResult] = useGetRiskByIdLazyQuery({
    variables: {
      _eq: riskId,
    },
  });

  const loading =
    getRiskResult.loading ||
    indicatorsLoading ||
    customAttributesLoading ||
    actionsLoading ||
    controlledAssessmentLoading ||
    uncontrolledAssessmentLoading ||
    controlsLoading ||
    appetitesLoading ||
    acceptancesLoading ||
    complianceMonitoringLoading ||
    internalAuditLoading;
  const exportFunc = async () => {
    const { data: riskData } = await getRisk();
    const createIndicatorTablePromise = createIndicatorTable();
    const createActionTablePromise = createActionTable();
    const createControlsTablePromise = createControlsTable();
    const createAppetitesTablePromise = createAppetitesTable();
    const createAcceptancesTablePromise = createAcceptancesTable();
    const createControlledAssessmentDetailsPromise =
      createControlledAssessmentDetails();
    const createUncontrolledAssessmentDetailsPromise =
      createUncontrolledAssessmentDetails();
    const risk = riskData?.risk?.[0];
    const indicatorTable = await createIndicatorTablePromise;
    const actionTable = await createActionTablePromise;
    const controlledAssessmentDetails =
      await createControlledAssessmentDetailsPromise;
    const uncontrolledAssessmentDetails =
      await createUncontrolledAssessmentDetailsPromise;
    const controlsTable = await createControlsTablePromise;
    const appetitesTable = await createAppetitesTablePromise;
    const acceptancesTable = await createAcceptancesTablePromise;
    let complianceAssessmentsTable = createTable({ body: [[]] });
    if (includeComplianceMonitoring) {
      const createComplianceAssessmentsTablePromise =
        createComplianceMonitoringTable();
      complianceAssessmentsTable =
        await createComplianceAssessmentsTablePromise;
    }

    let internalAuditsTable = createTable({ body: [[]] });
    if (includeInternalAudit) {
      const createInternalAuditsTablePromise = createInternalAuditTable();
      internalAuditsTable = await createInternalAuditsTablePromise;
    }
    if (!risk) {
      return;
    }

    const title = `${risk.Title} (${getFriendlyId(
      Parent_Type_Enum.Risk,
      risk.SequentialId
    )})`;

    const detailFields = [
      createField(fields('Title'), risk.Title),
      createField(fields('Description'), risk.Description),
      createField(
        riskFields('tier'),
        tiers[String(risk.Tier) as keyof typeof tiers]
      ),
      createField(riskFields('parent'), risk.parent?.Title ?? '-'),
      createField(
        riskFields('treatment'),
        risk.Treatment ? treatments[risk.Treatment] : '-'
      ),
      createField(
        riskFields('status'),
        risk.Status ? statuses[risk.Status] : '-'
      ),
      createField(fields('Owner'), getOwnerValue(risk)),
      createField(fields('Contributor'), getContributorValue(risk)),
      createField(fields('Tags'), getTagsValue(risk)),
      createField(fields('Departments'), getDepartmentsValue(risk)),
      ...(await getCustomAttribute(risk)),
    ];

    const docDefinition = createDocument(title, [
      createHeading(title),
      createSubHeading(t('details')),
      twoColumns(detailFields),
      createSubHeading(t('uncontrolledRiskAssessment.tab_title')),
      uncontrolledAssessmentDetails,
      optionalTableSection(t('controls.tab_title'), controlsTable),
      createSubHeading(t('controlledRiskAssessment.tab_title')),
      controlledAssessmentDetails,
      optionalTableSection(
        t('ratings.complianceRatingSubheading'),
        complianceAssessmentsTable
      ),
      optionalTableSection(
        t('ratings.internalAuditRatingSubheading'),
        internalAuditsTable
      ),
      optionalTableSection(t('appetites.tab_title'), appetitesTable),
      optionalTableSection(t('acceptances.tab_title'), acceptancesTable),
      optionalTableSection(t('actions.tab_title'), actionTable),
      optionalTableSection(t('indicators.tab_title'), indicatorTable),
    ]);
    download(docDefinition);
  };

  return [exportFunc, { loading }];
};

export default useExporter;
