import {
  Badge,
  Icon,
  IconProps,
  Popover as BasePopover,
  SpaceBetween,
} from '@cloudscape-design/components';
import { FC } from 'react';

type Item = {
  label: string;
  onClick: (() => void) | undefined;
  id: string;
};

import styles from './style.module.scss';
interface Props {
  items?: Item[] | null;
  title: string;
  iconName: IconProps.Name | undefined | null;
  icon: JSX.Element | undefined;
}

const Popover: FC<Props> = ({ items, title, icon, iconName }) => {
  return (
    <BasePopover
      dismissButton={true}
      position="left"
      size="small"
      header={title}
      content={
        <SpaceBetween direction="horizontal" size="xs">
          {items?.map((item) => <Badge key={item.id}>{item.label}</Badge>)}
        </SpaceBetween>
      }
    >
      <div>
        {icon ? (
          <div>
            <span className={'align-middle'}>{items?.length || '0'}&nbsp;</span>
            <span className={'align-middle'}>{icon}</span>
          </div>
        ) : (
          <>
            {items?.length || '0'}&nbsp;
            <>
              {iconName && (
                <Icon
                  variant="subtle"
                  size="small"
                  name={iconName}
                  {...{ className: styles.icon }}
                />
              )}
            </>
          </>
        )}
      </div>
    </BasePopover>
  );
};

export default Popover;
