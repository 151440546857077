import Button from '@risksmart-app/components/Button';
import Modal from '@risksmart-app/components/Modal';
import { useTranslation } from 'react-i18next';

export type ChangeRequestsModalProps = {
  onDismiss?: () => void;
  onConfirm?: () => void;
};

export const ConfirmChangeRequestModal = ({
  onConfirm,
  onDismiss,
}: ChangeRequestsModalProps) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'approvals.change_request_modal',
  });

  const handleConfirm = () => {
    onConfirm?.();
    onDismiss?.();
  };

  return (
    <Modal
      visible={true}
      onDismiss={(event) => {
        // don't close modal on overlay click
        if (event.detail.reason === 'overlay') {
          return;
        }
        onDismiss?.();
      }}
      header={t('title')}
      footer={
        <div className={'flex gap-2'}>
          <Button variant={'primary'} onClick={handleConfirm}>
            {t('confirm')}
          </Button>
          <Button onClick={onDismiss}>{t('cancel')}</Button>
        </div>
      }
    >
      {t('body')}
    </Modal>
  );
};
