import { JsonForms } from '@jsonforms/react';

import { Header } from '../../theme/risksmart-themed/components';
import rendererRegistry from '../renderers/registry';
import { FormBuilderAddSection } from './FormBuilderAddSection';
import { withFormStateProvider } from './FormStateProvider';
import { useFormBuilderContext } from './useFormBuilderContext';

export const FormBuilderUnwrapped = () => {
  const {
    formData,
    schema,
    uiSchema,
    isFormEditable,
    setFormData,
    setUISchema,
  } = useFormBuilderContext();

  return (
    <>
      <div key={'form-builder'} className={'flex flex-col gap-y-4 mb-8'}>
        <Header variant={'h1'}>Form Builder</Header>

        <JsonForms
          readonly={!isFormEditable}
          data={formData}
          schema={schema}
          uischema={uiSchema}
          renderers={rendererRegistry}
          onChange={({ data }) => setFormData(data)}
        />

        <FormBuilderAddSection
          schema={schema}
          uiSchema={uiSchema}
          setUISchema={setUISchema}
        />
      </div>

      <div key={'schemata'}>
        <h1>Schema:</h1>
        <pre>{`${JSON.stringify(schema, null, 2)}`}</pre>
        <br />
        <h1>UISchema:</h1>
        <pre>{`${JSON.stringify(uiSchema, null, 2)}`}</pre>
      </div>
    </>
  );
};

export const FormBuilder = withFormStateProvider(FormBuilderUnwrapped);
