import { z } from 'zod';

import { Parent_Type_Enum } from '@/generated/graphql';

export const LinkedItem = z.object({
  Target: z.array(z.object({ value: z.string().uuid(), label: z.string() })),
  Type: z.nativeEnum(Parent_Type_Enum),
});

export type LinkedItemFields = z.infer<typeof LinkedItem>;

export const defaultValues: LinkedItemFields = {
  Target: [],
  Type: Parent_Type_Enum.Control,
};
