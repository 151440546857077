import {
  PropertyFilterProperty,
  PropertyFilterQuery,
} from '@cloudscape-design/collection-hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterPropertyDashboardItem } from '@/components/RegisterDashboard/FilterPropertyDashboardItem';
import RegisterDashboard from '@/components/RegisterDashboard/RegisterDashboard';
import { useRating } from '@/hooks/use-rating';
import { emptyFilterQuery } from '@/utils/collectionUtils';

import { AttestationRegisterFields } from './types';

type Props = {
  items: readonly AttestationRegisterFields[] | undefined;
  propertyFilterQuery: PropertyFilterQuery;
  onFilterQueryChanged: (query: PropertyFilterQuery) => void;
  filteringProperties: readonly PropertyFilterProperty<unknown>[];
};

export const AttestationsSummary: FC<Props> = ({
  items,
  onFilterQueryChanged,
  propertyFilterQuery,
  filteringProperties,
}) => {
  const { t } = useTranslation(['common'], {
    keyPrefix: 'attestations.summary_category_titles',
  });
  const { getLabel: getStatusLabelByValue } = useRating(
    'attestation_record_status'
  );

  return (
    <RegisterDashboard>
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={emptyFilterQuery}
        tableFilterQuery={propertyFilterQuery}
        title={t('all')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'ActiveLabel',
              operator: '=',
              value: 'Yes',
            },
          ],
          operation: 'and',
        }}
        title={t('active')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'AttestationStatusLabel',
              operator: '=',
              value: getStatusLabelByValue('attested'),
            },
          ],
          operation: 'and',
        }}
        title={t('attested')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'AttestationStatusLabel',
              operator: '=',
              value: getStatusLabelByValue('pending'),
            },
          ],
          operation: 'and',
        }}
        title={t('pending')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'AttestationStatusLabel',
              operator: '=',
              value: getStatusLabelByValue('expired'),
            },
          ],
          operation: 'and',
        }}
        tableFilterQuery={propertyFilterQuery}
        title={t('expired')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
      <FilterPropertyDashboardItem
        onClick={onFilterQueryChanged}
        tableFilterQuery={propertyFilterQuery}
        itemFilterQuery={{
          tokens: [
            {
              propertyKey: 'AttestationStatusLabel',
              operator: '=',
              value: getStatusLabelByValue('not_required'),
            },
          ],
          operation: 'and',
        }}
        title={t('not_required')}
        items={items ?? []}
        filteringProperties={filteringProperties}
      />
    </RegisterDashboard>
  );
};
