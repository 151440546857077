import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export type ToolsContent =
  | 'help'
  | 'notifications'
  | 'page-content'
  | `change-request:${string}`
  | undefined;

export const ToolsContext = createContext<
  [ToolsContent, Dispatch<SetStateAction<ToolsContent>>]
>([undefined, () => null]);

type Props = {
  children: ReactNode;
};

export const ToolsProvider: FC<Props> = ({ children }) => {
  const state = useState<ToolsContent>(undefined);
  const location = useLocation();
  const [toolsContent, setToolsContent] = state;
  useEffect(() => {
    // Close right panel on content change
    if (toolsContent !== 'notifications') {
      setToolsContent(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setToolsContent, location.pathname]);

  return (
    <ToolsContext.Provider value={state}>{children}</ToolsContext.Provider>
  );
};

export const useTools = () => useContext(ToolsContext);
