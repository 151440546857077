import { useGetGuidParam } from '@risksmart-app/components/routes/routes.utils';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ControlledTabs from '@/components/ControlledTabs/ControlledTabs';

import { PageLayout } from '../../../../../../layouts';
import DetailsTab from './tabs/details/Tab';

const DocumentFileModal: FC = () => {
  const parentDocumentId = useGetGuidParam('documentId');
  const { t } = useTranslation(['common']);

  return (
    <PageLayout title={'Create Document Version'}>
      <ControlledTabs
        activeTabId={'details'}
        tabs={[
          {
            id: 'details',
            label: t('details'),
            content: <DetailsTab parentDocumentId={parentDocumentId} />,
          },
        ]}
        variant="container"
      />
    </PageLayout>
  );
};

export default DocumentFileModal;
