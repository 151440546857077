import { CollectionActions } from '@cloudscape-design/collection-hooks/cjs/interfaces';
import { useEffect, useState } from 'react';
import { useStorage } from 'src/state/useStorage';

import EmptyEntityCollection from '@/components/EmptyCollection/EmptyEntityCollection';
import NoMatchesCollection from '@/components/EmptyCollection/NoMatchesCollection';
import { Order_By } from '@/generated/graphql';

import { TablePreferences, TablePropsWithActions } from '../types';
import { emptyFilterQuery } from '../types';
import {
  LazyDataset,
  RootTablePropsOptions,
  TableRecord,
  WhereFilter,
} from '../types';
import {
  propertyFilterToGraphQLQuery,
  sortingStateToGraphQLQuery,
} from '../utils/serversideUtils';
import { useBuildTableProps } from './useBuildTableProps';
import { useFiltersFromUrlHash } from './useFiltersFromUrlHash';
import { usePreprocessTableData } from './usePreprocessTableData';

type UseGetTablePropsOptions<T extends TableRecord> = RootTablePropsOptions<
  T,
  LazyDataset<T>
>;

export function useGetLazyTableProps<T extends Record<string, unknown>>(
  options: UseGetTablePropsOptions<T>
): TablePropsWithActions<T> {
  const [currentPage, setCurrentPage] = useState(1);

  const { sortingState, setSortingState, propertyFilter, setPropertyFilter } =
    useFiltersFromUrlHash<T>(options.fields, options.defaultSortingState);

  const [storedPreferences, setPreferences] = useStorage<
    TablePreferences<T> | undefined
  >(undefined, {
    localStorageKey: options.storageKey,
  });

  const [pagesCount, setPageCount] = useState<number | null>(null);
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState(false);

  const {
    columnDefinitions,
    filteringProperties,
    tableData,
    tableFields,
    filteringOptions,
    preferences,
  } = usePreprocessTableData({
    ...options,
    currentPage,
    data,
    preferences: storedPreferences,
  });

  useEffect(() => {
    if (preferences.pageSize === undefined || loading) {
      return;
    }

    if (!Array.isArray(options.data)) {
      if (currentPage === undefined) {
        return;
      }

      setLoading(true);
      const pages = preferences.pageSize;

      options
        .data?.({
          limit: pages,
          offset: (currentPage - 1) * pages,
          orderBy: (sortingState
            ? sortingStateToGraphQLQuery(sortingState)
            : {}) as Record<keyof T, Order_By>,
          where: propertyFilter
            ? propertyFilterToGraphQLQuery(propertyFilter)
            : ({} as WhereFilter<T>),
        })
        .then(({ data, totalCount }) => {
          if (totalCount !== undefined) {
            setPageCount(Math.ceil(totalCount / pages));
          }
          setData(data);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preferences.pageSize,
    loading,
    currentPage,
    sortingState,
    propertyFilter,
  ]);

  return useBuildTableProps({
    items: tableData,
    allPageItems: tableData,
    allItems: tableData,
    preferences: preferences,
    setPreferences: setPreferences,
    entityLabel: options.entityLabel,
    columnDefinitions: columnDefinitions,
    tableFields: tableFields,
    filtersEnabled: options.enableFiltering ?? true,
    propertyFilterProps: {
      filteringProperties,
      filteringOptions,
      // Enforce 'AND' operation for server-side filtering, 'OR' doesn't work.
      query: { ...(propertyFilter ?? emptyFilterQuery), operation: 'and' },
      onChange: ({ detail }) => setPropertyFilter(detail),
      hideOperations: true,
      disableFreeTextFiltering: true,
    },
    paginationProps: {
      pagesCount: pagesCount ?? 0,
      openEnd: pagesCount === null,
      currentPageIndex: currentPage,
      onChange: (event) => setCurrentPage(event.detail.currentPageIndex),
    },
    actions: {} as CollectionActions<T>,
    onPropertyFilterChange: setPropertyFilter,
    onSortingChange: setSortingState,
    extraProps: {
      sortingColumn:
        sortingState?.sortingColumn ??
        (options.defaultSortingState?.sortingColumn
          ? {
              sortingField: options.defaultSortingState.sortingColumn as string,
            }
          : undefined),
      sortingDescending:
        sortingState?.isDescending ??
        options.defaultSortingState?.sortingDirection === 'desc',
      loading,
      empty: !propertyFilter ? (
        <EmptyEntityCollection
          entityLabel={options.entityLabel}
          action={options.emptyCollectionAction || <></>}
        />
      ) : (
        <NoMatchesCollection
          onClearClick={() => setPropertyFilter(emptyFilterQuery)}
        />
      ),
    },
  });
}
