import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomisableFormContext } from '@/components/Form/Form/CustomisableFormContext';
import { FormContextProps } from '@/components/Form/Form/types';

import {
  AssessmentTypeEnum,
  useAssessmentTypeConfig,
} from '../../useAssessmentTypeConfig';
import AssessmentFormFields from './AssessmentFormFields';
import {
  AssessmentFormDataFields,
  AssessmentFormSchema,
  defaultValues,
} from './assessmentSchema';

type Props = Omit<
  FormContextProps<AssessmentFormDataFields>,
  'schema' | 'formId' | 'parentType' | 'defaultValues' | 'i18n'
> & {
  initialTier?: number;
  riskId?: string;
  assessmentMode: AssessmentTypeEnum;
};

const AssessmentForm: FC<Props> = (props) => {
  const { t } = useTranslation(['common']);
  const { user } = useRisksmartUser();
  const { parentType, taxonomyKey } = useAssessmentTypeConfig(
    props.assessmentMode
  );
  const { t: ts } = useTranslation(['common'], { keyPrefix: taxonomyKey });
  const defaultData: AssessmentFormDataFields = {
    ...defaultValues,
    Owners: [
      {
        type: 'user',
        value: user!.userId,
      },
    ],
  };

  return (
    <CustomisableFormContext
      {...props}
      header={t('details')}
      schema={AssessmentFormSchema}
      defaultValues={defaultData}
      i18n={{ entity_name: ts('entity_name') }}
      formId={'assessment-form'}
      parentType={parentType}
      renderTemplate={props.renderTemplate}
    >
      <AssessmentFormFields
        assessmentMode={props.assessmentMode}
        readOnly={props.readOnly}
      />
    </CustomisableFormContext>
  );
};

export default AssessmentForm;
