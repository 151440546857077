import { TabsProps } from '@cloudscape-design/components';
import { useTranslation } from 'react-i18next';

import { filterEmptyTabs } from '@/utils/tabUtils';

import Tab from './tabs/Tab';

export const useTabs = ({
  appetiteId,
  parentId,
}: {
  appetiteId?: string;
  parentId?: string;
}) => {
  const { t } = useTranslation('common');
  const tabs: TabsProps.Tab[] = [
    {
      label: t('details'),
      id: 'details',
      content: <Tab Id={appetiteId} ParentId={parentId} />,
    },
  ];

  return filterEmptyTabs(tabs);
};
