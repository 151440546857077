import { ContentText } from 'pdfmake/interfaces';

import { getCustomAttributeCollections } from '@/components/Form/CustomAttributes/CustomAttributeCollections';
import { CustomAttributeFields } from '@/components/Form/CustomAttributes/CustomAttributeSchema';
import { FieldType } from '@/components/Form/CustomAttributes/EditFields/NewFieldSchema';
import { JSONObject } from '@/types/types';
import { toLocalDate } from '@/utils/dateUtils';
import { createField } from '@/utils/pdf/field';

export function getCustomAttributeDataForExport<
  T extends {
    CustomAttributeData?: JSONObject | null;
  },
>(
  item: T,
  schemas: CustomAttributeFields | undefined | null
): (string | ContentText)[] {
  const fields: (string | ContentText)[] = [];
  if (!schemas?.Schema || !schemas?.UiSchema) {
    return fields;
  }

  // render column collections from schemas.
  const collections = getCustomAttributeCollections(
    schemas?.Schema || {},
    schemas?.UiSchema || {}
  );
  // map collections to config using type.
  collections.forEach(({ label, path, type }) => {
    const matchToField = (data: JSONObject | null) =>
      data ? (data[path] as string) || '' : '';
    if (!item.CustomAttributeData) {
      return fields;
    }
    // Add specific config for each type
    switch (type) {
      case FieldType.Text:
      case FieldType.Textarea:
      case FieldType.Select:
      case FieldType.Link:
        fields.push(
          ...createField(label, matchToField(item.CustomAttributeData))
        );
        break;
      case FieldType.Date:
        fields.push(
          ...createField(
            label,
            toLocalDate(matchToField(item.CustomAttributeData))
          )
        );
        break;
    }
  });

  return fields;
}
