import {
  CustomAttributeDataSchema,
  NewFileSchema,
  NullableStringDateSchema,
  RelationFilesSchema,
  UserOptionSchema,
  validateNewFilesSizeAndExtension,
} from 'src/schemas/global';
import { z } from 'zod';

import {
  Document_File_Type_Enum,
  Version_Status_Enum,
} from '@/generated/graphql';

const Link = z.object({
  Type: z.literal(Document_File_Type_Enum.Link),
  Link: z.string({ invalid_type_error: 'Required' }).url(),
});
const Html = z.object({
  Type: z.literal(Document_File_Type_Enum.Html),
  Content: z.string({ invalid_type_error: 'Required' }),
});

const File = z.object({
  Type: z.literal(Document_File_Type_Enum.File),
  newFiles: z.array(NewFileSchema),
});
const Types = z.discriminatedUnion('Type', [Link, Html, File]);

export const DocumentFileFormSchema = z
  .object({
    files: RelationFilesSchema,
    Version: z.string().min(1, { message: 'Required' }),
    Summary: z.string().nullish(),
    Status: z.nativeEnum(Version_Status_Enum),
    ReasonForReview: z.string().nullish(),
    ReviewedBy: UserOptionSchema.nullish(),
    ReviewDate: NullableStringDateSchema,
    NextReviewDate: NullableStringDateSchema,
  })

  .and(Types)
  .and(CustomAttributeDataSchema)
  .superRefine((values, ctx) => {
    if (values.Type === Document_File_Type_Enum.File) {
      validateNewFilesSizeAndExtension(values.newFiles, ctx);
      if (values.newFiles.length !== 1 && values.files?.length !== 1) {
        ctx.addIssue({
          message: 'Required',
          code: z.ZodIssueCode.custom,
          path: ['newFiles'],
        });
      }
    }
  });

export type DocumentVersionFormFieldData = z.infer<
  typeof DocumentFileFormSchema
>;

export const defaultValues: DocumentVersionFormFieldData = {
  newFiles: [],
  Version: '',
  Summary: null,
  Status: Version_Status_Enum.Draft,
  ReasonForReview: null,
  ReviewedBy: null,
  ReviewDate: null,
  NextReviewDate: null,
  Type: Document_File_Type_Enum.File,
};
