import { ContentTable } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { useGetControlsByParentIdLazyQuery } from '@/generated/graphql';
import { useRating } from '@/hooks/use-rating';
import { getOwnerValue } from '@/utils/pdf/ownerValue';
import { createTable, tableHeaders } from '@/utils/pdf/table';
import { getTagsValue } from '@/utils/pdf/tagsValue';

import { calculateOverallEffectiveness } from '../calculateEffectiveness';

const useControlsExportTable = (
  parentId: string
): [() => Promise<ContentTable>, boolean] => {
  const [getControls, getControlsResult] = useGetControlsByParentIdLazyQuery({
    variables: {
      ParentId: parentId,
    },
  });
  const { getLabel: getEffectivenessLabel } = useRating('effectiveness');
  const { t: columns } = useTranslation(['common'], {
    keyPrefix: 'columns',
  });
  const { t: controlsColumns } = useTranslation(['common'], {
    keyPrefix: 'controls.columns',
  });
  const createExportTable = async () => {
    const { data: controlData } = await getControls();
    const controlsTableData = (controlData?.control ?? []).map((i) => [
      i.Title,
      i.Type,
      getOwnerValue(i),
      getEffectivenessLabel(calculateOverallEffectiveness(i)),
      getTagsValue(i),
    ]);

    return createTable({
      widths: '*',
      body: [
        tableHeaders([
          controlsColumns('title'),
          columns('type'),
          columns('owner'),
          controlsColumns('effectiveness'),
          columns('tags'),
        ]),
        ...controlsTableData,
      ],
    });
  };

  return [createExportTable, getControlsResult.loading];
};

export default useControlsExportTable;
