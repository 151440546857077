import ExpandableSection from '@cloudscape-design/components/expandable-section';
import { useNotifications } from '@risksmart-app/components/Notifications/notification-context';
import Table from '@risksmart-app/components/Table';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useI18NSummaryHelpContent } from '@/components/HelpPanel/useSummaryHelpContent';
import {
  Parent_Type_Enum,
  useGetInternalAuditReportRiskAssessmentResultsByRiskIdQuery,
} from '@/generated/graphql';
import { getCounter } from '@/utils/collectionUtils';

import { ObjectWithContributors } from '../../../../../rbac/Permission';
import AssessmentResultModal from '../../../../assessments/modals/AssessmentResultModal';
import { useGetCollectionTableProps } from './internalAuditRatingConfig';

interface Props {
  risk: ObjectWithContributors;
}

const InternalAuditRatingRegister: FC<Props> = ({ risk }) => {
  useI18NSummaryHelpContent('assessments.tabHelp');
  const { addNotification } = useNotifications();
  const { t } = useTranslation(['common']);
  const { t: st } = useTranslation(['common'], { keyPrefix: 'ratings' });
  const { data, loading } =
    useGetInternalAuditReportRiskAssessmentResultsByRiskIdQuery({
      variables: {
        RiskId: risk.Id,
      },
      onError: (error) => {
        addNotification({
          type: 'error',
          content: <>{error.message}</>,
        });
      },
    });
  const [isEditOpen, setIsEditOpen] = useState(false);

  const [openAssessmentResultId, setOpenAssessmentResultId] = useState<
    string | undefined
  >();

  const handleAssessmentResultModalClose = () => {
    setOpenAssessmentResultId(undefined);
    setIsEditOpen(false);
  };

  const onOpenResult = (id: string) => {
    setOpenAssessmentResultId(id);
    setIsEditOpen(true);
  };

  const tableProps = useGetCollectionTableProps(
    risk.Id,
    onOpenResult,
    data?.risk_assessment_result,
    data?.form_configuration?.flatMap((f) =>
      f && f.customAttributeSchema ? [f.customAttributeSchema] : []
    ) ?? []
  );

  return (
    <>
      <ExpandableSection
        header={
          <div className={'flex space-x-2'}>
            <span>{st('internalAuditRatingSubheading')}</span>
            <span className={'text-grey font-normal'}>
              {getCounter(tableProps.totalItemsCount ?? 0, loading)}
            </span>
          </div>
        }
        defaultExpanded={false}
      >
        <Table
          {...tableProps}
          loading={loading}
          variant={'embedded'}
          data-testid="internal-audit-rating-table"
        />
      </ExpandableSection>
      {isEditOpen && (
        <AssessmentResultModal
          id={openAssessmentResultId}
          onDismiss={handleAssessmentResultModalClose}
          assessedItem={risk}
          resultType={Parent_Type_Enum.RiskAssessmentResult}
          hideTypeSelector={true}
          i18n={t('ratings')}
          assessmentMode={'internal_audit_report'}
        />
      )}
    </>
  );
};
export default InternalAuditRatingRegister;
