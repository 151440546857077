import { Box } from '@cloudscape-design/components';
import ErrorContent from '@risksmart-app/components/ErrorPages/ErrorContent';
import { Feature } from '@risksmart-app/shared/organisation/Feature';
import { Meta } from '@risksmart-app/shared/organisation/Meta';
import { createContext, FC, ReactNode } from 'react';

import Loading from '@/components/Loading';
import { useGetOrganisationQuery } from '@/generated/graphql';

export const FeaturesContext = createContext<Feature[] | null>(null);

type Props = { children: ReactNode };

export const FeaturesProvider: FC<Props> = ({ children }) => {
  const { data, loading, error } = useGetOrganisationQuery();
  const organisation = data?.auth_organisation;
  const meta: Meta | null | undefined = organisation?.[0]?.Meta;

  return (
    <FeaturesContext.Provider
      value={meta?.features ? (meta.features?.split(',') as Feature[]) : []}
    >
      {loading && <Loading />}
      {error && (
        <ErrorContent
          title={'Sorry, we couldn’t make that happen'}
          imgSrc={'/errors/rubiks-cube.png'}
          imgAlt={'binoculars'}
        >
          <Box variant="p">Please try again later</Box>
        </ErrorContent>
      )}
      {!error && !loading && children}
    </FeaturesContext.Provider>
  );
};
