import { FC } from 'react';

import { useGetIssueByIdQuery } from '@/generated/graphql';

type Props = {
  issueId: string | undefined;
};

const Breadcrumb: FC<Props> = ({ issueId }) => {
  const { data } = useGetIssueByIdQuery({
    variables: {
      _eq: issueId!,
    },
    skip: !issueId,
  });

  return <>{data?.issue?.[0]?.Title}</>;
};

export default Breadcrumb;
