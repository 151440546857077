import PropertyFilterPanel from '@/components/PropertyFilterPanel';
import { emptyFilterQuery } from '@/utils/table/types';

import { useGetWidgetData } from '../Gigawidget/hooks/useGetWidgetData';
import { WidgetDataSource } from '../Gigawidget/types';
import styles from './style.module.scss';
import { GigawidgetSettings } from './util';

type FilterPreviewProps = {
  dataSource: WidgetDataSource;
  settings: GigawidgetSettings;
};

export const FilterPreview = ({ dataSource, settings }: FilterPreviewProps) => {
  const { tableProps, loading } = useGetWidgetData({ dataSource });

  return (
    <>
      {!loading && (
        <PropertyFilterPanel
          {...{ className: styles.filterPreview }}
          query={settings.filtering ?? emptyFilterQuery}
          filteringProperties={tableProps.filteringProperties}
          filteringOptions={tableProps.propertyFilterProps.filteringOptions}
          onChange={() => null}
          customControl={<></>}
          customFilterActions={<></>}
          hideOperations={true}
          disabled
        />
      )}
    </>
  );
};
