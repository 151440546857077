import useRisksmartUser from '@risksmart-app/components/hooks/useRisksmartUser';
import {
  hasuraClaimsNamespace,
  hasuraDefaultRole,
} from '@risksmart-app/components/rbac/jwt';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { handleError } from '@/utils/errorUtils';

import { useAnalytics } from './segment.provider';

export const useBaseTracking = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    if (location) {
      void analytics
        .page(
          location.pathname,
          {
            title: 'Removed due to security',
          },
          {
            page: {
              title: 'Removed due to security',
            },
            properties: {
              title: 'Removed due to security',
            },
          }
        )
        .catch((error) => {
          handleError(error);
        });
    }
  }, [location, analytics]);
};

export const useUserTracking = () => {
  const { isLoading, isAuthenticated, user } = useRisksmartUser();
  const analytics = useAnalytics();

  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        void analytics
          .group(
            user?.orgKey,
            {
              name: user?.claims_organization_name,
            },
            {
              page: {
                title: 'Removed due to security',
              },
            }
          )
          .catch((error) => {
            handleError(error);
          });

        void analytics
          .identify(
            user?.userId,
            {
              email: user?.claims_email,
              name: user?.claims_username,
              org_role: user?.[hasuraClaimsNamespace][hasuraDefaultRole],
            },
            {
              page: {
                title: 'Removed due to security',
              },
            }
          )
          .catch((error) => {
            handleError(error);
          });
      }
    }
  }, [isAuthenticated, isLoading, user, analytics]);
};
