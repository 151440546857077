import i18n from '@risksmart-app/components/providers/i18n';
import { RouteObject } from 'react-router-dom';

import { IssuesUpdateBreadcrumb, IssuesUpdatePage } from '../pages';
import { actionRoute } from './actionRoutes.config';

export const issueRoute: RouteObject = {
  path: ':issueId',
  handle: {
    breadcrumb: IssuesUpdateBreadcrumb,
  },
  children: [
    {
      path: '',
      element: (
        <IssuesUpdatePage activeTabId="details" showDeleteButton={true} />
      ),
    },
    {
      path: 'updates',
      handle: {
        title: () => i18n.format(i18n.t('update_other'), 'capitalize'),
      },
      element: <IssuesUpdatePage activeTabId="updates" />,
    },
    {
      path: 'actions',
      handle: {
        title: () => i18n.format(i18n.t('action_other'), 'capitalize'),
      },
      children: [
        {
          path: '',
          element: <IssuesUpdatePage activeTabId="actions" />,
        },
        actionRoute,
      ],
    },
    {
      path: 'causes',
      handle: {
        // TODO: translation
        title: 'Causes',
      },
      element: <IssuesUpdatePage activeTabId="causes" />,
    },
    {
      path: 'consequences',
      handle: {
        // TODO: translation
        title: 'Consequences',
      },
      element: <IssuesUpdatePage activeTabId="consequences" />,
    },
    {
      path: 'assessment',
      handle: {
        // TODO: translation
        title: 'Assessment',
      },
      element: <IssuesUpdatePage activeTabId="assessment" />,
    },
    {
      path: 'linked-items',
      handle: {
        title: () => i18n.t('linkedItems.tab_title'),
      },
      element: <IssuesUpdatePage activeTabId="linkedItems" />,
    },
  ],
};
